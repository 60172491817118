import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import { IconType } from '../../../../../groupware-common/types/icon';

function ComponentNavBasic(): JSX.Element {
  const menu: { id: number; label: string; icon: IconType }[] = [
    { id: 100, label: '보낸메일함', icon: 'send' },
    { id: 200, label: '받은메일함', icon: 'ban' },
    { id: 300, label: '휴지통', icon: 'trash' },
  ];

  return (
    <ComponentSampleUI.Content title="Toggle menu">
      <div>
        <Nav title="메일함">
          {menu.map((x) => (
            <MenuItem key={x.id} label={x.label} icon={x.icon} />
          ))}
        </Nav>
      </div>
    </ComponentSampleUI.Content>
  );
}

export default ComponentNavBasic;
