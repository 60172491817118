/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  FolderBox,
  Language,
  LocateArg,
  Module,
} from '../../../groupware-common/types';
import api from '../../apis/approval/v1/index';
import {
  Caches,
  ListEntity,
  PendingAction,
  RejectedAction,
  ViewEntity,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import { ApprovalLineType } from '../../pages/common/dialogs/ApprovalLineDialogContainer';

const name: Module = 'approval';

interface Item {
  companyId: number;
  // folderId: number; // 폴더 아이디.
  id: number; // 아이디.

  workName: string; // 업무 이름.
  documentNo: string; // 문서번호.
  subject: string; // 제목.
  //  content: string; // 컨텐츠.

  attachments: Array<string>; // 첨부 파일 목록.

  completedAt?: string; // 완료일.

  createAt: string; // 작성일.
  updateAt: string; // 수정일.

  archive: boolean; // 기록물철 표시 여부.
  urgent: boolean; // 긴급 여부.

  creatorCompanyId: number; // 생성자 회사 아이디.
  creatorId: number; // 생성자 아이디.
  creatorNameKey: string; // 생성자 이름 키.
  creatorAvatar: string; // 생성자 아바타.

  creatorOrganizationNameKey: string; // 생성자 부서 이름 키.
  creatorJobPositionNameKey: string; // 생성자 직위 이름 키.
  creatorJobDutyNameKey: string; // 생성자 직책 이름 키.

  folderNameKey?: string; // 폴더 이름 키. (전사게시함/전체폴더에서 값 할당)

  approvalLine: ApprovalLineType;
}

export interface ListItem extends Item {
  checked: boolean; // 체크 여부.
  opinions: number; // 의견 수.
  comments: number; // 댓글 수.
}

export interface ViewItem extends Item {
  retentionPeriod: string; // 보유 기간.
  content: string;

  comments: {
    id: number; // createAt: string;
    comment: string;
    creatorCompanyId: number; // 생성자 회사 아이디.
    creatorId: number; // 생성자 아이디.
    avatar: string; // 아바타.
    updateAt: string;

    creatorNameKey: string; // 생성자 이름 키.
  }[];
}

// const initialState = createPageState<ListItem, ViewItem>();
interface State {
  cache: Caches<ListEntity<ListItem>, ViewEntity<ViewItem>>;

  requests: { id: string; type: string; arg: any }[];
}

const initialState: State = {
  cache: {
    list: {},
    view: {},
  },

  requests: [],
};

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

export interface SaveFolderArg {
  type: FolderBox;

  id: number | undefined;
  parentId: number;
  names: Array<{ language: Language; value: string }>;
  description: string;
  updateAt: string | undefined;

  permissions: {
    id: number;
    permission: number;
    text: string;
  }[];
}

export interface SaveMacroArg {
  macroId: number | undefined;
  approval: { id: string; name: string; organization: string };
  macroName: string;
  updateAt: string | undefined;
}

// 전자결재 일괄승인
const ListBatchApproval = createAsyncThunk(
  `${name}/ListBatchApproval`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
  ) => {
    try {
      await api.ListBatchApproval(arg.data);
    } catch (error) {
      // thunkApi.rejectWithValue(error.response.data);
    }
  },
);

// 전자결재 완전삭제
const ListDeleteApproval = createAsyncThunk(
  `${name}/ListDeleteApproval`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
  ) => {
    try {
      await api.ListDeleteApproval(arg.data);
    } catch (error) {
      // thunkApi.rejectWithValue(error.response.data);
    }
  },
);

// 전자결재 모두 읽은상태로 표시
const ListAllReadApproval = createAsyncThunk(
  `${name}/ListAllReadApproval`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
  ) => {
    try {
      await api.ListAllReadApproval(arg.data);
    } catch (error) {
      // thunkApi.rejectWithValue(error.response.data);
    }
  },
);

// 전자결재 리스트 공개범위 수정
const ListPublicSave = createAsyncThunk(
  `${name}/ListPublicSave`,
  async (
    arg: {
      data: {
        id: number;
        public: boolean;
        updateAt: string;
      }[];
    } & LocateArg,
  ) => {
    try {
      await api.ListPublicSave(arg.data);
    } catch (error) {
      // thunkApi.rejectWithValue(error.response.data);
    }
  },
);

const approvalSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default approvalSlice.reducer;

export const approvalActions = {
  module: name,

  ListBatchApproval,
  ListDeleteApproval,
  ListAllReadApproval,
  ListPublicSave,
};
