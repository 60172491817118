import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { approvalViewPermissionActions } from '../../../../stores/approval/viewPermission';

function ApprovalViewPermissionRoute(props: { pathname: string }): null {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      approvalViewPermissionActions.viewPermission({
        route: {
          pathname: `${props.pathname}`,
          search: `p=1`,
        },
      }),
    );
  }, [dispatch, props.pathname]);

  return null;
}

export default ApprovalViewPermissionRoute;
