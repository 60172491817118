import axios from 'axios';
import {
  apiError,
  EntityIdKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  FolderListItem,
  PermissionOptionType,
} from '../../../../stores/document/folders';

/** 문서함 폴더 목록 */
async function findFolderList(parentId?: number): Promise<
  {
    id: number;
    parentId: number;
    seq: number;
    status: string;
    name: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/document/v1/adminconsole/document/folder/all/simply`;
    if (parentId) url += `&parentid=${parentId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서함 폴더 추가 */
async function createFolder(arg: {
  parentId: number;
  name: string;
  description: string;
  isEnable: boolean;
  forms: number[];
  defaultRetentionPeriod: string;
  retentionPeriods: string[];
  option: {
    isComment: boolean;
    isVersion: boolean;
    isRequiredVersion: boolean;
  };
  excluders: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    options: PermissionOptionType;
  }[];
}): Promise<{ id: number; seq: number; updateAt: string }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서함 폴더 조회 */
async function findFolderView(arg: { id: number }): Promise<{
  id: number; // 폴더 아이디
  parentId: number; // 폴더 부모 아이디
  seq: number; // 폴더 순번
  name: string; // 폴더명
  description: string; // 설명
  forms: { id: number; name: string }[] | null; // 양식 아이디 배열
  retentionPeriods: string[]; // 보존기간 리스트  []: 사용 안함, ["1Y", "6M", FOREVER...]: 사용
  defaultRetentionPeriod: string; // 대표 보존기간 보존기간 - '': 없음, \d{1,3}Y: {d}년, \d{1,3}M: {d}월, \d{1,3}D: {d}일, FOREVER: 영구
  isEnable: boolean; // 사용여부
  updateAt: string; // 수정일
  option: {
    isComment: boolean; // 댓글 사용여부
    isVersion: boolean; // 버전관리 사용 여부
    isRequiredVersion: boolean; // 버전관리 필수 여부
    isCheckout: boolean; // 체크아웃 사용여부
  };
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string; // 참조 유형(ORGANIZATION: 조직, EMPLOYEE: 직원, COMPANY: 회사)
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    permission: PermissionOptionType;
  }[];
  excluders: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/folder`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서함 폴더 삭제 */
async function deleteFolder(data: {
  id: number;
  updateAt: string;
}): Promise<EntityIdKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/folder`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서함 폴더 수정 */
async function updateFolder(arg: {
  id: number;
  parentId: number;
  seq: number;
  name: string;
  description: string;
  forms: number[];
  retentionPeriods: string[];
  defaultRetentionPeriod: string;
  isEnable: boolean;
  updateAt: string;
  option: {
    isComment: boolean;
    isVersion: boolean;
    isRequiredVersion: boolean;
  };
  excluders: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    options: PermissionOptionType;
  }[];
}): Promise<{
  id: number;
  seq: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서함 폴더 순서 변경 */
async function sortFolder(
  arg: {
    parentId: number;
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/folder/sequence/all`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서함 일괄권한 수정 */
async function bulkAuthority(arg: {
  folders: {
    id: number;
    updateAt: string;
  }[];
  excluders: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    options: PermissionOptionType;
  }[];
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/folder/permission/bulk`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 폴더 목록 */
async function findUserList(employeeId: number): Promise<FolderListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/document/folder/all?employeeid=${employeeId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 문서함 폴더 조회 */
async function findUserView(arg: { id: number }): Promise<{
  id: number; // 폴더 아이디
  parentId: number; // 폴더 부모 아이디
  seq: number; // 폴더 순번
  name: string; // 폴더명
  description: string; // 설명
  forms: [{ id: number; name: string }]; // 양식 아이디 배열
  retentionPeriods: string[]; // 보존기간 리스트  []: 사용 안함, ["1Y", "6M", FOREVER...]: 사용
  defaultRetentionPeriod: string; // 대표 보존기간 보존기간 - '': 없음, \d{1,3}Y: {d}년, \d{1,3}M: {d}월, \d{1,3}D: {d}일, FOREVER: 영구
  updateAt: string; // 수정일
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string; // 참조 유형(ORGANIZATION: 조직, EMPLOYEE: 직원, COMPANY: 회사)
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    permission: PermissionOptionType;
  }[];
  excluders: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  isEnable: boolean; // 사용여부
  option: {
    isComment: boolean; // 댓글 사용여부
    isVersion: boolean; // 버전관리 사용 여부
    isRequiredVersion: boolean; // 버전관리 필수 여부
    isCheckout: boolean; // 체크아웃 사용여부
  };
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/document/folder`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const folderApi = {
  list: findFolderList,
  view: findFolderView,
  create: createFolder,
  delete: deleteFolder,
  update: updateFolder,
  sort: sortFolder,
  userList: findUserList,
  userView: findUserView,
  bulkAuthority,
};

export default folderApi;
