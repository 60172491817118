import { useEffect } from 'react';
import { useAppDispatch } from '../../groupware-webapp/app/store';
import { sessionActions } from '../../groupware-webapp/stores/session';

function EmptyLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${EmptyLocateRoute.name}.render(props)`, props);

  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const route = { pathname, search, hash, clearErrors: true };
    dispatch(sessionActions.setRoute(route));
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default EmptyLocateRoute;
