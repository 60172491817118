import React from 'react';

function Loading(): JSX.Element {
  return (
    <div className="ui-content-loading">
      <div className="root" />
    </div>
  );
}

export default Loading;
