/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { BoardListItem } from '../../../../stores/board';
import BoardContentBodyListItem from './BoardContentBodyListItem';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { dateFormat } from '../../../../../groupware-common/utils/ui';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

type Subject = {
  folderId?: number;
  folderName?: string;
  subjectHeader?: string;
  value: string;
  new: boolean;
  comments?: number;
  security: boolean;
  notice: boolean;
};

function BoardContentBodyList(props: {
  pathname: string;
  search: string;
  type?: PostListType;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: BoardListItem[];
  selectedId?: string;
  onFileDownload?(id: number, fileId: number, name: string): void;
  onItemClick?(arg: { id: string; isNotice: boolean }): void;
  onItemCheckedChange?(id: string, checked: boolean): void;
  onItemImportanceChange?(arg: { id: number; isNotice: boolean }): void;
}): JSX.Element {
  const { pathname, search, items, type, density, columns, selectedId } = props;

  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const { pageNo } = getQueryParams(search);
  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const { newPostPeriod } = useSelector(
    (state: RootState) => state.boards.preferences.basic,
  );
  const folders = useSelector((state: RootState) => state.boards.folder.list);

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo]);

  /** 첨부파일 다운로드 이벤트. */
  const handleFileDownload = (id: number, fileId: number, name: string) => {
    if (props.onFileDownload) props.onFileDownload(id, fileId, name);
  };

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <PostList name="board" type={type} density={density}>
      <PostList.Head>
        {columns.map(({ name, type: colunmType, text }) => (
          <PostList.Cell
            key={name}
            name={text}
            type={colunmType}
            align={colunmType === 'count' ? 'center' : undefined}
          />
        ))}
      </PostList.Head>
      <PostList.Body scrollableNodeProps={scrollbar}>
        {items.map((a, i) => {
          // employeeId가 0인 경우 익명, 0이 아닌 경우 익명 X
          const directoryData =
            a.employeeId === 0
              ? undefined
              : getDirectoryData({
                  ...directory,
                  companyId: principal.companyId,
                  employeeId: a.employeeId,
                });
          const iconPeriod = new Date(a.createAt);
          iconPeriod.setDate(iconPeriod.getDate() + newPostPeriod);
          const newIcon = moment(new Date()).isBetween(
            a.createAt,
            iconPeriod,
            'day',
            '[)',
          );
          const folder = folders.find((x) => x.id === a.folderId);
          let subjectHeader: string | undefined;
          let value = a.subject;
          if (folder?.option.useTitleClassification)
            subjectHeader =
              a.titleClassification !== ''
                ? `[${a.titleClassification}]`
                : undefined;
          else
            value =
              a.titleClassification !== ''
                ? `[${a.titleClassification}] ${a.subject}`
                : a.subject;

          let item: Record<
            string,
            string | number | boolean | Subject | User | undefined
          > = {
            checked: a.checked,
            importance: a.isStarred,
            subject: {
              subjectHeader,
              value,
              new: newIcon,
              comments: !folder?.option.useReply ? undefined : a.comments,
              security: a.isSecure,
              notice: a.isNotice,
            },
            thumbnail:
              a.isThumbnail && a.thumbnailPath !== null && !a.isSecure
                ? `/board-static/thumbnail/${principal.companyId}/${a.id}/${a.thumbnailPath}`
                : '',
            views: a.views,
            likes: a.likes,
            drafter: !directoryData
              ? {
                  name: '익명',
                  organization: '',
                  job: '',
                  avatar: '',
                }
              : {
                  name: directoryData.employeeName,
                  organization: directoryData.organizationName,
                  job: directoryData.jobPositionName,
                  avatar: directoryData.avatar ?? '게시판',
                },
            createAt: a.createAt,
          };
          if (!folderId || folderId === 'importance' || folderId === 'all') {
            item = {
              checked: a.checked,
              importance: a.isStarred,
              subject: {
                folderName: folder?.name,
                subjectHeader,
                value,
                new: newIcon,
                comments: !folder?.option.useReply ? undefined : a.comments,
                security: a.isSecure,
                notice: a.isNotice,
              },
              thumbnail:
                a.isThumbnail && a.thumbnailPath !== null && !a.isSecure
                  ? `/board-static/thumbnail/${principal.companyId}/${a.id}/${a.thumbnailPath}`
                  : '',
              views: a.views,
              likes: a.likes,
              drafter: !directoryData
                ? {
                    name: '익명',
                    organization: '',
                    job: '',
                    avatar: '',
                  }
                : {
                    name: directoryData.employeeName,
                    organization: directoryData.organizationName,
                    job: directoryData.jobPositionName,
                    avatar: directoryData.avatar ?? '게시판',
                  },
              createAt: a.createAt,
            };
          }
          if (folderId === 'temp') {
            item =
              display === 'phone'
                ? {
                    checked: a.checked,
                    subject: {
                      subjectHeader,
                      value,
                      new: newIcon,
                      comments: undefined,
                      security: a.isSecure,
                      notice: a.isNotice,
                    },
                    drafter: {
                      name: directoryData?.employeeName ?? '',
                      organization: '',
                      job: '',
                      avatar: directoryData?.avatar ?? '',
                    },
                    createAt: a.createAt,
                  }
                : {
                    checked: a.checked,
                    subject: {
                      subjectHeader,
                      value,
                      new: newIcon,
                      comments: a.comments,
                      security: a.isSecure,
                      notice: a.isNotice,
                    },
                    createAt: a.createAt,
                  };
          }
          const key = `/i${i}/${a.listId}/}`;
          return (
            <BoardContentBodyListItem
              key={key}
              type={props.type}
              id={a.id}
              listId={a.listId}
              isSecure={a.isSecure}
              isNotice={a.isNotice}
              selected={a.checked || a.listId === selectedId}
              columns={columns}
              item={item}
              attachedFiles={
                folderId === 'temp' || a.isSecure ? undefined : a.attachedFiles
              }
              onFileDownload={(fileId, name) =>
                handleFileDownload(a.id, fileId, name)
              }
              onClick={props.onItemClick}
              onCheckedChange={props.onItemCheckedChange}
              onImportanceChange={props.onItemImportanceChange}
            />
          );
        })}
      </PostList.Body>
    </PostList>
  );
}

export default BoardContentBodyList;
