import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPathParams, resourceUpdate } from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import ResourceFolderRoute from '../pages/adminconsole/folder/ResourceFolderRoute';
import ResourcePreferencesRoute from '../pages/adminconsole/preferences/ResourcePreferencesRoute';
import ResourceReservationRoute from '../pages/adminconsole/reservation/ResourceReservationRoute';

function AdminconsoleLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    resourceUpdate(['directory', 'jobclass', 'approval'], [currentLanguage]);
  }, [dispatch]);

  // 자원등록관리
  if (menu === 'folder')
    return <ResourceFolderRoute pathname={pathname} search={search} />;
  // 전사예약내역
  if (menu === 'reservation')
    return <ResourceReservationRoute pathname={pathname} search={search} />;
  return <ResourcePreferencesRoute pathname={pathname} />;
}

export default AdminconsoleLocateRoute;
