import axios from 'axios';
import {
  apiError,
  EntityKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { b62 } from '../../../../../groupware-common/utils';
import { SaveContactItem } from '../../../../stores/contacts';

export interface StarReturnType extends EntityKeyable {
  isStarred: boolean;
}

interface SettingSaveType {
  employeeId: number;
  configs: string;
  updateAt: string;
}

interface LabelSettingSaveType extends SettingSaveType {
  labelId: number;
}

interface ViewData {
  isStarred: boolean;
  id: number;
  name: string;
  nickName: string;
  jobTitle: string;
  department: string;
  company: string;
  labels:
    | {
        labelId: number;
        updateAt: string;
      }[]
    | null;
  birthDay: {
    birthDay: string;
    isSolar: string;
  } | null;
  emails:
    | {
        id: number;
        isDefault: boolean;
        emailType: 'COMPANY' | 'PERSONAL' | 'CUSTOM';
        emailTypeCustomValue?: string;
        email: string;
        updateAt: string;
      }[]
    | null;
  phones:
    | {
        id: number;
        isDefault: boolean;
        phoneType: 'COMPANY' | 'PHONE' | 'HOME' | 'FAX' | 'CUSTOM';
        phoneTypeCustomValue?: string;
        locationCode: number; // 국제전화 코드
        phoneNumber: string;
        updateAt: string;
      }[]
    | null;
  addresses:
    | {
        id: number;
        addressType: 'COMPANY' | 'HOME' | 'CUSTOM';
        addressTypeCustomValue?: string;
        postalCode: string; // 우편번호
        address: string; // 주소
        addressDetail: string; // 상세주소
        updateAt: string;
      }[]
    | null;
  anniversaries:
    | {
        id: number;
        anniversaryType: 'ANNIVERSARY' | 'CUSTOM';
        anniversaryTypeCustomValue?: string;
        anniversary: string;
        isSolar: boolean; // 양력 여부.
        updateAt: string;
      }[]
    | null;
  memo: string;
  updaterId: number;
  updateAt: string;
}

/** 연락처 리스트 조회 */
async function findList(arg: {
  folderId?: string;
  companyId: number;
  word?: string;
  pageno: number;
  rowsperpage: number;
}): Promise<
  {
    id: number; // 아이디
    name: string; // 이름
    nickName: string; // 닉네임
    company: string; // 회사
    department: string; // 부서
    jobTitle: string; // 직급
    representPhone: string; // 대표 전화번호
    representEmail: string; // 대표 이메일
    labels: number[] | null; // 라벨 리스트.
    isStarred: boolean; // 중요 표시
    updateAt: string;
  }[]
> {
  try {
    const params = {
      word: arg.word,
      pageno: arg.pageno,
      rowsperpage: arg.rowsperpage,
    };
    const { headers, host } = getApiConfig();
    let url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples`;
    // 전체 연락처.
    if (!arg.folderId || arg.folderId === 'all') url += '';
    // 휴지통.
    else if (arg.folderId === 'trash') url += '/trashs';
    // 중요 연락처.
    else if (arg.folderId === 'importance') url += '/stars';
    // 라벨 연락처.
    else url += `/labels/${b62(arg.folderId)}`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 리스트 총 개수 조회. */
async function findTotalCount(arg: {
  folderId?: string;
  companyId: number;
  word?: string;
}): Promise<number> {
  try {
    const params = { word: arg.word };
    const { headers, host } = getApiConfig();
    let url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples`;
    // 전체 연락처.
    if (!arg.folderId || arg.folderId === 'all') url += '';
    // 휴지통.
    else if (arg.folderId === 'trash') url += '/trashs';
    // 중요 연락처.
    else if (arg.folderId === 'importance') url += '/stars';
    // 라벨 연락처.
    else url += `/labels/${b62(arg.folderId)}`;
    url += '/totalcount';
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 단일 조회. */
async function findView(arg: {
  companyId: number;
  id: number;
}): Promise<ViewData> {
  try {
    const { companyId, id } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 연락처 중요표시 저장, 해제. */
async function saveUnSaveStar(arg: {
  star: boolean;
  id: number;
  companyId: number;
}): Promise<StarReturnType> {
  try {
    const { headers, host } = getApiConfig();
    let url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples/${arg.id}`;
    if (arg.star) {
      url += '/star';
      const response = await axios.put(url, undefined, { headers });
      return response.data;
    }

    url += '/unstar';
    const response = await axios.put(url, undefined, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 생성 */
async function createContact(arg: {
  companyId: number;
  data: SaveContactItem;
}): Promise<EntityKeyable> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples`;
    const response = await axios.post(url, arg.data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 수정 */
async function updateContact(arg: {
  companyId: number;
  peopleId: number;
  data: SaveContactItem;
}): Promise<EntityKeyable> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples/${arg.peopleId}`;
    const response = await axios.put(url, arg.data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 삭제 (휴지통으로 이동) */
async function deleteContact(arg: {
  companyId: number;
  peopleId?: number; // 단일 삭제 시 존재.
  data:
    | {
        id: number;
        updateAt: string;
      }
    | {
        id: number;
        updateAt: string;
      }[];
}): Promise<EntityKeyable> {
  try {
    const { companyId, peopleId, data } = arg;
    const { headers, host } = getApiConfig();
    let url = `${host}/api/contacts/v1/companies/${companyId}`;
    // 단일 삭제
    if (peopleId && !Array.isArray(data)) url += `/peoples/${peopleId}/trash`;
    // 여러개의 연락처 삭제
    else url += '/peoples/trashs';
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 영구 삭제. */
async function permanentlyDeleteContact(arg: {
  companyId: number;
  data: {
    id: number;
    updateAt: string;
  }[];
}): Promise<EntityKeyable> {
  try {
    const { companyId, data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 복원. */
async function restoreContact(arg: {
  companyId: number;
  data: {
    id: number;
    updateAt: string;
  }[];
}): Promise<EntityKeyable> {
  try {
    const { companyId, data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/restores`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 라벨으로 복사 */
async function copyContactLabel(arg: {
  companyId: number;
  labelId: number;
  peoples: number[];
}): Promise<boolean> {
  try {
    const params = { peoples: arg.peoples };
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples/labels/${arg.labelId}`;
    const response = await axios.post(url, undefined, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 라벨에서 제외 */
async function cutContactLabel(arg: {
  companyId: number;
  labelId: number;
  peoples: number[];
}): Promise<boolean> {
  try {
    const params = { peoples: arg.peoples };
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${arg.companyId}/peoples/labels/${arg.labelId}`;
    const response = await axios.delete(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 내보내기 */
async function contactExport(arg: {
  file: 'google' | 'outlook';
  companyId: number;
  type: 'selected' | 'group' | 'all';
  labelId?: number;
  peopleIds?: number[];
}): Promise<string> {
  try {
    const { file, companyId, type, labelId, peopleIds } = arg;
    let params: { peopleids: number[] } | undefined;
    if (type === 'selected' && peopleIds) params = { peopleids: peopleIds };

    const { host, headers } = getApiConfig();
    let url = `${host}/api/contacts/v1/companies/${companyId}/export/peoples/${file}`;
    // 전체 연락처.
    if (type === 'all') url += '/all';
    // 그룹 연락처.
    if (type === 'group' && labelId) url += `/labels/${labelId}`;
    const response = await axios.get<Blob>(url, {
      headers,
      params,
      responseType: 'blob',
    });
    return response.data.text();
  } catch (e) {
    throw apiError(e);
  }
}

/** 연락처 가져오기 */
async function contactImport(arg: {
  companyId: number;
  data: { id: string; file: File };
  onProgress?: (event: unknown) => void;
}): Promise<boolean> {
  try {
    const { companyId, data, onProgress } = arg;
    const formData = new FormData();
    formData.append('file', data.file, data.file.name);
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/import/peoples`;
    const response = await axios.post(url, formData, {
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    });
    return response.status === 200;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 연락처 라벨 별 사용자 목록 설정 조회. */
async function findLabelSetting(arg: {
  labelId: number;
  companyId: number;
}): Promise<LabelSettingSaveType> {
  try {
    const { labelId, companyId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/labels/${labelId}/config`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 연락처 라벨 별 사용자 목록 설정 저장. */
async function saveLabelSetting(arg: {
  labelId: number;
  companyId: number;
  configs: string;
  updateAt: string;
}): Promise<LabelSettingSaveType> {
  try {
    const { companyId, labelId, configs, updateAt } = arg;
    const data = { labelId, configs, updateAt };
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/labels/${labelId}/config`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 모든 연락처 사용자 목록 설정 조회. */
async function findAllContactSetting(
  companyId: number,
): Promise<SettingSaveType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/config`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 모든 연락처 사용자 목록 설정 저장. */
async function saveAllContactSetting(arg: {
  companyId: number;
  configs: string;
  updateAt: string;
}): Promise<SettingSaveType> {
  try {
    const { companyId, configs, updateAt } = arg;
    const data = { configs, updateAt };
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/config`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요 연락처 사용자 목록 설정 조회. */
async function findStarredSetting(companyId: number): Promise<SettingSaveType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/stars/config`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요 게시함 사용자 목록 설정 저장. */
async function saveStarredSetting(arg: {
  companyId: number;
  configs: string;
  updateAt: string;
}): Promise<SettingSaveType> {
  try {
    const { companyId, configs, updateAt } = arg;
    const data = { configs, updateAt };
    const { headers, host } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/peoples/stars/config`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const contactsApi = {
  list: findList,
  totalCount: findTotalCount,
  view: findView,

  saveUnSaveStar,

  create: createContact,
  update: updateContact,
  delete: deleteContact,
  permanentlyDelete: permanentlyDeleteContact,
  restoreContact,

  copyContactLabel,
  cutContactLabel,

  contactExport,
  contactImport,

  labelSetting: findLabelSetting,
  allContactSetting: findAllContactSetting,
  starredSetting: findStarredSetting,

  saveLabelSetting,
  saveAllContactSetting,
  saveStarredSetting,
};

export default contactsApi;
