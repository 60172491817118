import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { archiveFolderApi } from '../../apis/approval/v1/archive';
import {
  CacheEntity,
  CacheMeta,
  LocateArg,
} from '../../../groupware-common/types';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { thunkCondition } from '../../../groupware-webapp/stores/utils';
import archiveDocumentApi from '../../apis/approval/v1/archive/document';
import { documentActions } from './document';
import { RootState } from '../../../groupware-webapp/app/store';

/** 보관소 */
const NAMESPACE = 'approval/archive';

type FolderItem = {
  // companyId: number;
  organizationId: number;
  parentId: number;
  id: number;
  seq: number;
  name: string;
  description: string;
  createAt: string;
  updateAt: string;
};

interface ArchiveState {
  // 현재 조직 아이디.
  currentOrganizationId: number;
  // 폴더 배열.
  folders: CacheEntity<FolderItem[]>;
}

const initialState: ArchiveState = {
  currentOrganizationId: 0,
  folders: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: [],
  },
};

const findAllFolder = createAsyncThunk(
  `${NAMESPACE}/findAllFolder`,
  async (
    arg: { data: { organizationId: number; current?: true } } & LocateArg,
    { rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    let _response_id_: number | undefined;

    try {
      // meta = { lastUpdateAt: '', timestamp: Date.now() };
      const { organizationId } = arg.data;
      const response = await archiveFolderApi.findAll({ organizationId });
      return { meta, data: response, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findAllFolder.typePrefix, arg, getState);
    },
  },
);

const appendFolder = createAsyncThunk(
  `${NAMESPACE}/appendFolder`,
  async (
    arg: {
      data: {
        organizationId: number;
        parentId: number;
        name: string;
        description: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await archiveFolderApi.append(arg.data);
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const modifyFolder = createAsyncThunk(
  `${NAMESPACE}/modifyFolder`,
  async (
    arg: {
      data: {
        organizationId: number;
        id: number;
        parentId: number;
        name: string;
        description: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const response = await archiveFolderApi.modify(data);
      return response.map((a) => {
        if (a.id === data.id)
          return {
            parentId: data.parentId,
            name: data.name,
            description: data.description,
            ...a,
          };
        return a;
      });
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const removeFolder = createAsyncThunk(
  `${NAMESPACE}/removeFolder`,
  async (
    arg: {
      data: {
        organizationId: number;
        id: number;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await archiveFolderApi.remove(arg.data);
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const changeFolderSequence = createAsyncThunk(
  `${NAMESPACE}/changeFolderSequence`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const response = await archiveFolderApi.change(arg.data);
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 기록물철 문서 이동
const movefetchArchiveDocument = createAsyncThunk(
  `${NAMESPACE}/movefetchArchiveDocument`,
  async (
    arg: {
      data: {
        organizationId: number;
        sourceFolderId: number;
        targetFolderId: number;
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { data, route, location } = arg;
      const response = await archiveDocumentApi.move(data);

      // location으로 경로이동이 없는 경우.
      if (location === undefined) {
        const { sourceFolderId: folderId } = data[0];
        const search =
          route?.search ?? (getState() as RootState).session.route.search;
        dispatch(documentActions.list({ folderId, search }));
      }
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const archiveSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllFolder.fulfilled, (state, { meta, payload }) => {
        if (payload?.meta !== undefined) state.folders.meta = payload.meta;
        if (payload?.data !== undefined) state.folders.data = payload.data;
        if (meta.arg.data.current)
          state.currentOrganizationId = meta.arg.data.organizationId;
      })
      .addCase(appendFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.folders.data = [...state.folders.data, payload];
      })
      .addCase(modifyFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.folders.data = state.folders.data.map((folder) => {
            const data = payload.find((a) => a.id === folder.id);
            if (data) return { ...folder, ...data };
            return folder;
          });
      })
      .addCase(removeFolder.fulfilled, (state, { meta, payload }) => {
        if (payload !== undefined)
          state.folders.data = state.folders.data
            .filter(({ id }) => id !== meta.arg.data.id)
            .map((folder) => {
              const data = payload.find((a) => a.id === folder.id);
              if (data) return { ...folder, ...data };
              return folder;
            });
      })
      .addCase(changeFolderSequence.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.folders.data = state.folders.data.map((folder) => {
            const b = payload.find((a) => folder.id === a.id);
            if (folder.id === b?.id) return { ...folder, ...b };
            return folder;
          });
      });
  },
});

export default archiveSlice.reducer;

export const archiveActions = {
  move: movefetchArchiveDocument,
};

export const archiveFolderActions = {
  findAll: findAllFolder,
  append: appendFolder,
  modify: modifyFolder,
  remove: removeFolder,
  change: changeFolderSequence,
};
