import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
// import dummy from './dummy';

// const { forms } = dummy;

async function fetchFolderList(arg?: {
  parentId?: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number;
    id: number;
    parentId: number;
    seq: number;
    name: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/folder/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function createFolder(arg: { parentId: number; name: string }): Promise<{
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updateFolder(arg: {
  id: number;
  parentId: number;
  name: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteFolder(arg: { id: number; updateAt: string }): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updateFolderSeq(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    companyId: number;
    seq: number;
    id: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 검색 매개변수를 생성합니다.
 * @param searchCode 검색 코드.
 * @param searchWord 검색 단어.
 * @returns
 */
function createSearchParams(arg: {
  searchCode?: string;
  searchWord?: string;
}): { searchcode?: string; searchword?: string } {
  const { searchCode = '', searchWord = '' } = arg;
  if (searchWord.trim() !== '') {
    if (searchCode.trim() === '')
      return { searchcode: undefined, searchword: searchWord };
    return { searchcode: searchCode, searchword: searchWord };
  }
  return { searchcode: undefined, searchword: undefined };
}

async function fetchTotalCount(arg: {
  folderId?: number;
  searchCode?: string; // 'folder' | 'form';
  searchWord?: string;
}): Promise<number> {
  // return forms;
  const { folderId, searchCode, searchWord } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/all/totalcount`;
    const params = {
      folderId,
      ...createSearchParams({ searchCode, searchWord }),
    };
    const response = await axios.get<{
      totalCount: number;
    }>(url, { headers, params });
    return response.data.totalCount;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchList(arg: {
  folderId?: number;
  pageNo: number;
  rowsPerPage: number;
  searchCode?: string; // 'folder' | 'form';
  searchWord?: string;
}): Promise<
  {
    companyId: number;
    id: number;
    folderId: number;
    status: number;
    name: string;
    description: string;
    createAt: string;
    updateAt: string;
  }[]
> {
  // return forms;
  const { folderId, pageNo, rowsPerPage, searchCode, searchWord } = arg;
  try {
    const { host, headers } = getApiConfig();
    const params = {
      folderId,
      pageNo,
      rowsPerPage,
      ...createSearchParams({ searchCode, searchWord }),
    };
    const url = `${host}/api/approval/v1/form/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchView(id: number): Promise<{
  companyId: number;
  id: number;
  folderId: number;
  status: number;
  name: string;
  contents: string;
  description: string;
  createAt: string;
  updateAt: string;
}> {
  // return forms.find((a) => a.id === id) || null;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form?id=${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function createForm(arg: {
  folderId: number;
  status: number;
  name: string;
  description: string;
  contents: string;
}): Promise<{
  companyId: number;
  id: number;
  folderId: number;
  status: number;
  name: string;
  description: string;
  createAt: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updateForm(arg: {
  id: number;
  folderId: number;
  status: number;
  name: string;
  description: string;
  contents: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteForm(arg: {
  id: number;
  updateAt: string;
}): Promise<{ id: number; updateAt: string }>;
async function deleteForm(
  arg: { id: number; updateAt: string }[],
): Promise<{ id: number; updateAt: string }[]>;
async function deleteForm(
  arg: { id: number; updateAt: string } | { id: number; updateAt: string }[],
): Promise<
  | {
      id: number;
      updateAt: string;
    }
  | {
      id: number;
      updateAt: string;
    }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/approval/v1/form/all`
      : `${host}/api/approval/v1/form`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function changeFolder(
  arg: {
    id: number;
    folderId: number;
    updateAt: string;
  }[],
): Promise<{ companyId: number; id: number; updateAt: string }[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/form/move`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export const formFolderApi = {
  fetchList: fetchFolderList,
  create: createFolder,
  update: updateFolder,
  delete: deleteFolder,
  updateSeq: updateFolderSeq,
};

const approvalFormApi = {
  fetchTotalCount,
  fetchList,
  fetchView,
  create: createForm,
  update: updateForm,
  delete: deleteForm,
  changeFolder,
};

export default approvalFormApi;
