import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 대리 결재자의 대리 결재 지정자 목록을 조회합니다.
 * @param companyId 대리 결재자 회사 아이디.
 * @param surrogaterId 대리 결재자 직원 아이디.
 * @returns 대리 결재 지정자 목록 객체.
 */
async function readDesignatorAll({
  companyId: companyid,
  surrogaterId: surrogaterid,
}: {
  companyId: number;
  surrogaterId: number;
}): Promise<
  {
    companyId: number;
    id: number;
    surrogateEmployeeId: number;
    surrogateOrganizationId: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/surrogateapproval/all`;
    const params = { companyid, surrogaterid };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 대리 결재 지정자에 설정된 대리 결재 정보를 조회합니다.
 * @param companyId 대리 결재 지정자 회사 아이디.
 * @param employeeId 대리 결재 지정자 직원 아이디.
 * @returns 대리 결재 정보 객체.
 */
async function readSurrogateApprovalInfo(arg: {
  companyId: number;
  employeeId: number;
}): Promise<{
  companyId: number;
  id: number;
  useSurrogate: boolean;
  surrogateStartDate: string | null;
  surrogateEndDate: string | null;
  surrogateEmployeeId: number | null;
  surrogateOrganizationId: number | null;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/surrogateapproval`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 대리 결재 지정자의 대리 결재 정보를 저장합니다.
 * @param arg 대리 결재 정보 객체.
 * @returns 대리 결재 지정자 정보 객체.
 */
async function createSurrogateApprovalInfo(arg: {
  employeeId: number; // 대리 결재 지정자 아이디.
  useSurrogate: boolean;
  surrogateStartDate: string;
  surrogateEndDate: string;
  surrogateEmployeeId: number;
  surrogateOrganizationId: number;
  updateAt: string;
}): Promise<{
  companyId: number;
  employeeId: number; // 대리 결재 지정자 아이디.
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/surrogateapproval`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const surrogateApprovalApi = {
  findDesignators: readDesignatorAll,
  find: readSurrogateApprovalInfo,
  save: createSurrogateApprovalInfo,
};

export default surrogateApprovalApi;
