import React from 'react';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';

function TabItem(props: {
  label: string; // 라벨
  icon?: IconType; // 아이콘
  disabled?: boolean; // 활성화
  selected: boolean; // 선택됨
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  const { label, icon, disabled, selected, onClick } = props;

  let classname = 'tab-item';
  if (selected) classname += ' selected';

  return (
    <button
      type="button"
      className={classname}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <Icon icon={icon} className="badge" />}
      <span className="label">{label}</span>
    </button>
  );
}

function Tab(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  const { className, children } = props;

  let classname = 'eui-tab';
  if (className) classname += ` ${className}`;

  return (
    <div className={classname}>
      <div className="tab-root">{children}</div>
    </div>
  );
}

Tab.Item = TabItem;

export default Tab;
