import React from 'react';
import Button from '../../../../../../components/button/Button';
import EuiSetting from '../../../../../../components/layout/EuiSetting';
import SimpleSearch from '../../../../../../components/search/SimpleSearch';
import TreeList from '../../../../../../components/tree/TreeList';
import { IconType } from '../../../../../../groupware-common/types/icon';

function DocumentSettingFolderList(props: {
  selectedId?: number;
  items: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  filter: string;
  onItemClick(id: number): void;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
  onFilter(filter: string): void;
}): JSX.Element {
  const { selectedId, items, filter, onItemClick, onAction, onFilter } = props;

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilter(event.target.value);
  };

  return (
    <>
      <EuiSetting.Header title="문서함 목록">
        <Button
          text="추가"
          iconType
          icon="plus"
          onClick={(event) => onAction({ code: 'folder/create', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilter} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <TreeList
          selectedId={selectedId}
          items={items}
          filter={filter}
          onItemClick={(id) => onItemClick(id)}
        />
      </EuiSetting.Content>
    </>
  );
}

export default DocumentSettingFolderList;
