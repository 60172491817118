import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import { RootState, useAppDispatch } from '../app/store';
import { LocationProps } from '../../groupware-common/types';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import NavigationDrawer from '../pages/adminconsole/NavigationDrawer';
import Page from '../pages/test/Page';
import { sessionActions } from '../stores/session';
import EuiHeader from '../../components/layout/EuiHeader';

import DefaultPreferencesContainer from '../pages/adminconsole/default/preferences/DefaultPreferencesContainer';

import DirectoryLocateRoute from '../../groupware-directory/locates/AdminConsoleLocateRoute';
import DirectoryLocateContainer from '../../groupware-directory/locates/AdminConsoleLocateContainer';
import ApprovalLocateRoute from '../../groupware-approval/locates/AdminConsoleLocateRoute';
import ApprovalLocateContainer from '../../groupware-approval/locates/AdminConsoleLocateContainer';
import BoardLocateRoute from '../../groupware-board/locates/AdminConsoleLocateRoute';
import BoardLocateContainer from '../../groupware-board/locates/AdminConsoleLocateContainer';
import CalendarLocateRoute from '../../groupware-calendar/locates/AdminConsoleLocateRoute';
import CalendarLocateContainer from '../../groupware-calendar/locates/AdminConsoleLocateContainer';
import EmptyLocateRoute from '../../groupware-common/locates/EmptyLocateRoute';
import AttendanceLocateRoute from '../../groupware-attendance/locates/AdminConsoleLocateRoute';
import AttendanceLocateContainer from '../../groupware-attendance/locates/AdminConsoleLocateContainer';
import ResourceLocateRoute from '../../groupware-resource/locates/AdminconsoleLocateRoute';
import ResourceLocateContainer from '../../groupware-resource/locates/AdminconsoleLocateContainer';
import DocumentLocateRoute from '../../groupware-document/locates/AdminConsoleLocateRoute';
import DocumentLocateContainer from '../../groupware-document/locates/AdminConsoleLocateContainer';
import SystemLinkLocateRoute from '../../groupware-systemlink/locates/SystemLinkLocateRoute';
import SystemLinkLocateContainer from '../../groupware-systemlink/locates/SystemLinkLocateContainer';
import ServiceLocateRoute from '../../groupware-service/locates/AdminconsoleLocateRoute';
import ServiceLocateContainer from '../../groupware-service/locates/AdminconsoleLocateContainer';
import { CreateRandomString } from '../../groupware-common/utils/ui';
import { attendancePreferencesActions } from '../../groupware-attendance/stores/attendance/preferences';
import { userGroupActions } from '../../groupware-directory/stores/directory/group';

function AdminConsoleRouter({ location }: LocationProps) {
  // console.log(`${AdminConsoleRouter.name}.render`);

  const dispatch = useAppDispatch();
  const display = useSelector((state: RootState) => state.session.display);
  const mobileNav = useSelector((state: RootState) => state.session.mobileNav);
  const route = useSelector((state: RootState) => state.session.route);

  useEffect(() => {
    dispatch(attendancePreferencesActions.findStandard({ isErrorThrow: true }));
    dispatch(userGroupActions.findList({}));
  }, []);

  useEffect(() => {
    if (mobileNav) {
      const { prevModule } = getPathParams<{ prevModule?: string }>(
        '/:prevModule',
        route.pathname,
      );
      const { nextModule } = getPathParams<{ nextModule?: string }>(
        '/:nextModule',
        location.pathname,
      );
      if (prevModule !== nextModule) dispatch(sessionActions.mobileNav(false));
    }
  }, [mobileNav, location.pathname, route.pathname]);

  const locateRoute = useMemo(() => {
    let result: JSX.Element | null = null;

    const { pathname: p, search, hash: h } = location;
    const pathParams = getPathParams('/*/:module', p);
    const s = search.startsWith('?') ? search.substring(1) : search;
    const key = CreateRandomString();
    // console.log(`locateRoute:pathname`, p);
    // console.log(`locateRoute:search`, s);
    // console.log(`locateRoute:hash`, h);
    // console.log(`locateRoute:key`, locationKey);
    // console.log(`locateRoute:pathParams`, pathParams);

    if (pathParams.module === 'directory')
      result = (
        <DirectoryLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'approval') {
      result = (
        <ApprovalLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    }
    if (pathParams.module === 'systemlink')
      result = (
        <SystemLinkLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'board')
      result = <BoardLocateRoute pathname={p} search={s} hash={h} key={key} />;
    if (pathParams.module === 'calendar')
      result = (
        <CalendarLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'attendance')
      result = (
        <AttendanceLocateRoute
          pathname={p}
          search={s}
          hash={h}
          locationKey={key}
        />
      );
    if (pathParams.module === 'resource')
      result = (
        <ResourceLocateRoute pathname={p} search={s} hash={h} key={key} />
      );
    if (pathParams.module === 'document')
      result = <DocumentLocateRoute pathname={p} search={s} key={key} />;
    if (pathParams.module === 'service') {
      return <ServiceLocateRoute pathname={p} search={s} key={key} />;
    }
    if (result === null)
      result = <EmptyLocateRoute pathname={p} search={s} hash={h} key={key} />;

    return result;
  }, [location.pathname, location.search, location.hash, location.key]);

  const locateContainer = useMemo(() => {
    const { pathname: p, search: s, hash: h, key: k } = route;
    const pathParams = getPathParams('/*/:module', p);
    // console.log(`locateContainer:pathname`, p);
    // console.log(`locateContainer:search`, s);
    // console.log(`locateContainer:hash`, h);
    // console.log(`locateContainer:key`, k);
    // console.log(`locateContainer:pathParams`, pathParams);

    if (pathParams.module === 'default') return <DefaultPreferencesContainer />;
    if (pathParams.module === 'directory')
      return (
        <DirectoryLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'approval') {
      return (
        <ApprovalLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    }
    if (pathParams.module === 'systemlink')
      return <SystemLinkLocateContainer pathname={p} search={s} key={k} />;
    if (pathParams.module === 'board')
      return <BoardLocateContainer pathname={p} search={s} hash={h} key={k} />;
    if (pathParams.module === 'calendar')
      return (
        <CalendarLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'test') return <Page />;
    if (pathParams.module === 'attendance')
      return (
        <AttendanceLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'resource')
      return (
        <ResourceLocateContainer pathname={p} search={s} hash={h} key={k} />
      );
    if (pathParams.module === 'document')
      return <DocumentLocateContainer pathname={p} search={s} key={k} />;
    if (pathParams.module === 'service') {
      return <ServiceLocateContainer pathname={p} search={s} key={k} />;
    }
    return null;
  }, [route]);

  const handleCloseNav = () => {
    dispatch(sessionActions.mobileNav(false));
  };

  const getContent = () => {
    const { pathname: p, search } = route;
    const pathParams = getPathParams('/*/:module/:folder', p);
    const queryParams = getQueryParams(search);
    const isNotDetectedSize =
      (queryParams.dialogType === 'form' &&
        (queryParams.dialogMode === 'update' ||
          queryParams.dialogMode === 'create')) ||
      (pathParams.module === 'attendance' &&
        pathParams.folder === 'dayOffSetting');
    if (display !== 'pc' && !isNotDetectedSize)
      return (
        <>
          {mobileNav && (
            <>
              <div className="ui-nav-cover" onClick={handleCloseNav} />
              <NavigationDrawer pathname={route.pathname} hash={route.hash} />
            </>
          )}
          <div className="admin-container">
            {locateContainer || (
              <EuiHeader>
                <EuiHeader.Title>대시보드</EuiHeader.Title>
              </EuiHeader>
            )}
          </div>
        </>
      );
    if (isNotDetectedSize) {
      const style: React.CSSProperties = {
        display: 'none',
      };

      return (
        <>
          {mobileNav ? (
            <>
              <div className="ui-nav-cover" onClick={handleCloseNav} />
              <NavigationDrawer pathname={route.pathname} hash={route.hash} />
            </>
          ) : (
            <SplitPane
              allowResize={false}
              className="ui-split layout-split"
              split="vertical"
              defaultSize={265}
              pane1Style={display !== 'pc' ? style : undefined}
            >
              <NavigationDrawer pathname={route.pathname} hash={route.hash} />
              <div className="admin-container">
                {locateContainer || (
                  <EuiHeader>
                    <EuiHeader.Title>대시보드</EuiHeader.Title>
                  </EuiHeader>
                )}
              </div>
            </SplitPane>
          )}
        </>
      );
    }
    return (
      <SplitPane
        allowResize={false}
        className="ui-split layout-split"
        split="vertical"
        defaultSize={265}
      >
        <NavigationDrawer pathname={route.pathname} hash={route.hash} />
        <div className="admin-container">
          {locateContainer || (
            <EuiHeader>
              <EuiHeader.Title>대시보드</EuiHeader.Title>
            </EuiHeader>
          )}
        </div>
      </SplitPane>
    );
  };

  return (
    <>
      {locateRoute}
      {getContent()}
    </>
  );
}

export default React.memo(AdminConsoleRouter);
