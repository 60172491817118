import React from 'react';
import { getParentItems } from '../../../groupware-common/utils';
import { IconType } from '../../../groupware-common/types/icon';
import DefaultMenuItem from './DefaultMenuItem';

type Props = {
  selectedId?: string;
  items: { id: string; parentId: string; text: string; icon?: IconType }[];
  onMenuClick?(id: string): void;
};

type State = {
  selectedId?: string;
  expandPath: string[];
};

class DefaultMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedId: props.selectedId,
      expandPath: getParentItems(props.items, props.selectedId || '').map(
        (x) => x.id,
      ),
    };
    this.handleExpandToggle = this.handleExpandToggle.bind(this);
  }

  handleExpandToggle(id: string): void {
    const { selectedId, items } = this.props;
    const { expandPath } = this.state;

    let parentPath = getParentItems(items, id).map((x) => x.id);

    if (expandPath[0] === parentPath[0]) {
      // 확장 상태인 경우.
      if (expandPath.indexOf(id) !== -1) {
        const index = parentPath.indexOf(id);
        if (index !== -1) parentPath.splice(index, parentPath.length);
        else parentPath = [];
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      selectedId,
      expandPath: parentPath,
    }));
  }

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    if (state.selectedId === props.selectedId) return null;
    return {
      selectedId: props.selectedId,
      expandPath: getParentItems(props.items, props.selectedId).map(
        (x) => x.id,
      ),
    };
  }

  render(): JSX.Element {
    // console.log(`${DefaultMenu.name}.render`);
    // console.log('props', JSON.stringify(this.props));

    const { selectedId, items, onMenuClick } = this.props;
    const { expandPath } = this.state;

    return (
      <ul>
        {items
          .filter((x) => x.parentId === '')
          .map((x) => {
            return (
              <DefaultMenuItem
                key={x.id}
                id={x.id}
                parentId={x.parentId}
                text={x.text}
                icon={x.icon}
                items={items}
                selectedId={selectedId}
                expandPath={expandPath}
                onExpandToggle={this.handleExpandToggle}
                onClick={onMenuClick}
              />
            );
          })}
      </ul>
    );
  }
}

export default DefaultMenu;
