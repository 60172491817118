import React from 'react';
import Button from '../../../../../components/button/Button';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';

type Props = {
  usePagination: boolean;
  type: 'full' | 'split';
  /** 목록으로 이동 이벤트. */
  onListGo?(): void;
  /** 이전 이벤트. */
  onPrev?(): void;
  /** 다음 이벤트. */
  onNext?(): void;
  /** 도구 모음 버튼 배열. */
  toolbarButtons: ActionEventProps[];
  /** 도구 모음 액션 이벤트. */
  onToolbarAction(arg: { code: string; event: React.MouseEvent }): void;
};

function DocumentContentHeadView(props: Props): JSX.Element {
  const { type, onListGo, onToolbarAction, onPrev, onNext, usePagination } =
    props;

  return (
    <EuiToolbar className={`view-toolbar ${type}`}>
      <EuiToolbar.Left>
        {onListGo && (
          <Button
            text="목록으로 돌아가기"
            iconType
            icon="arrow-left"
            onClick={onListGo}
            className="back-list"
          />
        )}
        <ToolbarAction event={props.toolbarButtons} onClick={onToolbarAction} />
      </EuiToolbar.Left>
      {type === 'full' && usePagination && (
        <EuiToolbar.Right>
          <div className="eui-pagination">
            <Button
              text="다음"
              className="page-action"
              iconType
              icon="chevron-up"
              disabled={onNext === undefined}
              onClick={onNext}
            />
            <Button
              text="이전"
              className="page-action"
              iconType
              icon="chevron-down"
              disabled={onPrev === undefined}
              onClick={onPrev}
            />
          </div>
        </EuiToolbar.Right>
      )}
    </EuiToolbar>
  );
}

export default React.memo(DocumentContentHeadView);
