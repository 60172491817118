import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';
import Print, { PrintClickProps } from '../../../../../components/print/Print';
import PostView from '../../../../../components/post/PostView';
import UserInfo from '../../../../../components/user/UserInfo';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import { CommentItem } from '../../../../../groupware-approval/pages/root/approval/ApprovalType';
import Comment from '../../../../../components/comment/Comment';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';

function ComponentPrint(): JSX.Element {
  const user = {
    key: '123',
    name: '홍길동',
    organization: 'GW팀',
    class: '책임',
    avatar: '/images/faces/faces_1.jpg',
  };

  const [state, setState] = useState<{
    visible: boolean;
    option: { value: string; label: string; checked: boolean }[];
    contentHtml: string;
    attachments: { id: number; name: string; size: number }[];
    attachedDocuments: {
      id: number;
      documentNumber: string;
      name: string;
    }[];
    comments: CommentItem[];
  }>({
    visible: false,
    option: [
      { value: 'info', label: '정보', checked: false },
      { value: 'attachment', label: '첨부파일', checked: false },
      { value: 'attachments', label: '첨부문서', checked: false },
      { value: 'comment', label: '댓글', checked: false },
      { value: 'opinion', label: '의견', checked: false },
    ],
    contentHtml: '컨텐츠입니다',
    attachments: [
      { id: 1001, name: '첨부파일 테스트입니다.txt', size: 1419000 },
      { id: 1002, name: '프로젝트.zip', size: 6554000 },
      { id: 1003, name: 'script.js', size: 5100 },
      { id: 1004, name: 'webpage.html', size: 10600 },
      { id: 1005, name: '웹 콘텐츠 접근성 지침 2.1.doc', size: 10600 },
      { id: 1006, name: '회사 전경.jpg', size: 2433400 },
      { id: 1007, name: '회사홍보동영상.mp4', size: 32000000 },
      { id: 1008, name: '애국가.mp3', size: 3050000 },
      { id: 1009, name: 'LICENSE.emp', size: 1468200 },
    ],
    attachedDocuments: [
      {
        id: 1000,
        documentNumber: '2021-06-GW팀-0034',
        name: '제 17차 정기주주총회 결과보고 제 17차 정기주주총회 결과보고',
      },
      {
        id: 2000,
        documentNumber: '2021-06-GW팀-1',
        name: '지출결의서 (경조금 지원)',
      },
    ],
    comments: [
      {
        id: 1,
        message: '의견 안녕하세요',
        writer: {
          key: '123',
          name: '이성계',
          organization: '이든비즈텍',
          class: '이사',
          avatar: '/images/faces/faces_31.jpg',
        },
        createAt: '2020-10-05T09:57:32.513Z',
        updateAt: '2020-10-05T09:57:32.513Z',
        authModify: false,
        authDelete: false,
        replies: [
          {
            id: 21,
            from: undefined,
            message: '의견 답글 테스트',
            writer: {
              key: '1',
              name: '이상윤',
              organization: '영업팀',
              class: '수석',
              avatar: '/images/faces/faces_22.jpg',
            },
            createAt: '2021-01-14T09:57:32.513Z',
            updateAt: '2021-01-14T09:57:32.513Z',
            authModify: false,
            authDelete: false,
          },
          {
            id: 22,
            from: {
              key: '',
              name: '이상윤',
            },
            message: '안녕하세요',
            writer: {
              key: '2',
              name: '이준호',
              organization: 'GW팀',
              class: '수석',
              avatar: '/images/faces/faces_26.jpg',
            },
            createAt: '2021-09-08T01:23:50.513Z',
            updateAt: '2021-09-08T01:23:50.513Z',
            authModify: false,
            authDelete: false,
          },
        ],
      },
    ],
  });

  const handlePrint = () => {
    setState((prevState) => ({
      ...prevState,
      visible: true,
    }));
  };

  const onChangeOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setState((prevState) => ({
      ...prevState,
      option: prevState.option.map((x) =>
        x.value === value ? { ...x, checked: event.target.checked } : x,
      ),
    }));
  };

  const handlePrintEvent = (code: PrintClickProps) => {
    switch (code) {
      case 'prev':
        break;
      case 'next':
        break;
      case 'close':
        setState((prevState) => ({
          ...prevState,
          visible: false,
        }));
        break;
      default:
        break;
    }
  };

  const getPrint = () => {
    if (state.visible)
      return (
        <Print
          pageNo={1}
          totalPageCount={1}
          option={state.option}
          onChangeOption={onChangeOption}
          onClick={handlePrintEvent}
        >
          <PostView>
            {state.option.some((x) => x.value === 'info' && x.checked) && (
              <PostView.Head>
                <PostView.Title>안년하세요 제목입니다</PostView.Title>
                <UserInfo
                  className="view-author"
                  avatar="/images/faces/faces_1.jpg"
                  name="홍길동 대표이사"
                  from="솔루션개발부"
                  date="2020-10-05T09:57:32.513Z"
                />
                <PostView.Info>
                  <PostView.InfoItem title="보존기간" value="영구" />
                  <PostView.InfoItem
                    title="조회"
                    value={100}
                    onClick={() => alert('이벤트')}
                  />
                  <PostView.InfoItem title="추천" value={50} />
                  <PostView.InfoItem
                    title="공지기간"
                    value="2021-01-14 ~ 2021-02-28"
                  />
                  <PostView.InfoItem
                    title="중요공지"
                    value="2021-01-14 ~ 2021-02-28"
                  />
                </PostView.Info>
              </PostView.Head>
            )}
            <PostView.Content data={state.contentHtml} />
            {state.option.some(
              (x) => x.value === 'attachment' && x.checked,
            ) && (
              <AttachmentsList
                count={state.attachments.length}
                className="view-attachments"
              >
                {state.attachments.map((x) => (
                  <AttachmentsItem key={x.id} name={x.name} size={x.size} />
                ))}
              </AttachmentsList>
            )}
            {state.option.some(
              (x) => x.value === 'attachments' && x.checked,
            ) && (
              <AttachDocumentList count={state.attachedDocuments.length}>
                {state.attachedDocuments.map((x) => (
                  <AttachDocumentItem
                    key={x.id}
                    no={x.documentNumber}
                    subject={x.name}
                  />
                ))}
              </AttachDocumentList>
            )}
            {state.option.some((x) => x.value === 'comment' && x.checked) && (
              <Comment
                count={state.comments.length}
                user={user}
                comments={state.comments}
              />
            )}
            {state.option.some((x) => x.value === 'opinion' && x.checked) && (
              <Comment
                opinion
                count={state.comments.length}
                user={user}
                comments={state.comments}
              />
            )}
          </PostView>
        </Print>
      );

    return null;
  };

  return (
    <>
      <ComponentSampleUI.Content title="Simple alerts">
        <Button text="인쇄" variant="contained" onClick={handlePrint} />
        {getPrint()}
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentPrint;
