import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 연동 직원을 조회합니다.
 *
 * @param companyId 회사 아이디.
 * @param employeeId 직원 아이디.
 * @returns 연동 직원 객체.
 */
async function fetchLinkageEmployee(arg: {
  companyId: number;
  employeeId: number;
}): Promise<{
  companyId: number;
  id: string;
  employeeId: number;
  updateAt: string;
}> {
  try {
    const { companyId, employeeId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/account/linkage/employee?companyid=${companyId}&employeeid=${employeeId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 이메일 가용성을 확인합니다.
 *
 * @param email 이메일.
 * @returns 가용성 결괏값. (Y: 사용 가능, N: 사용 불가, F: 유효하지 않음).
 */
async function fetchEmailAvailAbility(email: string): Promise<string> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/account/emailavailability?email=${email}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const accountApi = {
  fetchLinkageEmployee,
  fetchEmailAvailAbility,
};

export default accountApi;
