import React from 'react';
import Button from '../button/Button';

/**
 * @property add 텍스트 추가 버튼 텍스트
 * @property onAdd 클릭 추가 버튼 클릭 이벤트
 */

function ChipGroup(props: {
  className?: string;
  add?: string;
  onAdd?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  children: React.ReactNode;
}): JSX.Element {
  const { className, add, onAdd, children } = props;
  let classname = 'eui-chip-group';
  if (className) classname += ` ${className}`;
  if (add) classname += ' add';

  return (
    <div className={classname}>
      {add && (
        <Button
          text={add}
          iconType
          icon="plus-circle-fill"
          onClick={onAdd}
          className="selector-toggle"
          size="sm"
        />
      )}
      {children}
    </div>
  );
}

export default ChipGroup;
