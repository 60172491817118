import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../../groupware-webapp/app/store';

import { sessionActions } from '../../../../../../groupware-webapp/stores/session';
import { folderActions } from '../../../../../stores/document/folders';

function DocumentFolderBoxRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    Promise.all([dispatch(folderActions.clearView())]).then(() => {
      if (mount) {
        dispatch(folderActions.list({ route: { pathname, search } }));
        dispatch(
          sessionActions.setRoute({
            pathname,
            search,
            clearErrors: true,
          }),
        );
      }
    });

    return () => {
      mount = false;
    };
  }, [pathname, search]);

  return null;
}

export default DocumentFolderBoxRoute;
