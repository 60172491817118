import { useEffect } from 'react';
import { getQueryParams } from '../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { folderActions, itemActions } from '../../../stores/folders';
import { resourcesActions } from '../../../stores/resources';

function ResourceReservationRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);

  useEffect(() => {
    let mount = true;

    Promise.all([
      dispatch(itemActions.list({})),
      dispatch(folderActions.list({})),
      dispatch(resourcesActions.updateDataClear()),
    ]).then(() => {
      const startDate = timezoneDate();
      startDate.setMonth(timezoneDate().getMonth() - 3);
      startDate.setDate(1);
      const endDate = timezoneDate();
      endDate.setMonth(timezoneDate().getMonth() + 3);
      endDate.setDate(1);
      queryParams.startDate =
        queryParams.startDate ??
        dateFormat(initialDate(startDate), 'yyyy-MM-DD');
      queryParams.endDate =
        queryParams.endDate ?? dateFormat(initialDate(endDate), 'yyyy-MM-DD');
      if (mount)
        dispatch(
          resourcesActions.adminReservationList({
            search: getQueryParams(queryParams),
            isDirectorySelected: false,
            route: {
              pathname,
              search: getQueryParams(queryParams),
            },
          }),
        );
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default ResourceReservationRoute;
