import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { contactsActions } from '../../../../stores/contacts';

function ContactContentRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );

  const route = { pathname, search };

  useEffect(() => {
    dispatch(contactsActions.list({ folderId, search, route }));
  }, [dispatch, pathname, search]);

  return null;
}

export default ContactContentRoute;
