import { DirectoryType } from '../../../groupware-common/types';
import { JobClassType } from './preferences';

export function parseDirectoryType(
  companyId: number,
  organizationId: number | undefined,
  employeeId: number | undefined,
): DirectoryType {
  if (employeeId !== undefined) return 'employee';
  if (companyId === organizationId) return 'company';
  return 'organization';
}

// TypeScript 4.1 부터 지원됨.
// type DirectoryTreeId = `${string}` | `${string}_${string}` | `${string}_${string}_${string}`;
type DirectoryTreeId = string | undefined;

export function parseDirectoryTreeItemId(
  companyId: number,
  organizationId?: number,
  employeeId?: number,
): DirectoryTreeId {
  if (employeeId) {
    return organizationId
      ? `${companyId}_${organizationId}_${employeeId}`
      : `${companyId}_${employeeId}`;
  }
  return organizationId
    ? `${companyId}_${organizationId}`
    : `${companyId}_${companyId}`;
}

export function parseJobClass(
  type: JobClassType,
  jobPosition: string,
  jobDuty: string,
): string {
  switch (type) {
    case 'jobposition':
      return jobPosition;
    case 'jobduty':
      return jobDuty;
    case 'jobposition+jobduty': {
      if (jobPosition !== '' && jobDuty !== '')
        return `${jobPosition}/${jobDuty}`;
      return jobPosition || jobDuty || '';
    }
    default:
      return jobPosition;
  }
}

export default {
  parseJobClass,
  parseDirectoryType,
};
