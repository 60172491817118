import React, { useState } from 'react';
import { getText } from '../../../../../groupware-common/utils';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import DirectoryTreeContainer from '../../../../../groupware-directory/containers/DirectoryTreeContainer';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';

/**
 * @property selectedId 선택된 아이디.(? undefined 허용)
 * @property items 트리 아이템 배열.
 * @property onOrganizationCreate 조직 생성 이벤트.
 * @property onEmployeeCreate 직원 생성 이벤트.
 * @property onItemClick 트리 아이템 클릭 이벤트.
 */
type Props = {
  selectedId?: string;
  onItemClick(arg: DirectoryTreeItemArg): void;
};

function ApprovalApproverProxyContentList(dumy: Props): JSX.Element {
  // console.log(`${OrganizationChartContentList.name}.render`);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    onItemClick: dumy.onItemClick || emptyFunction,
  };

  const { selectedId, onItemClick } = props;

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <EuiSetting.Header title={getText('조직도')} />
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        {/* {treeOrlist} */}
        <DirectoryTreeContainer
          typeToFilter="employee"
          filter={filter}
          selectedId={selectedId}
          onItemClick={onItemClick}
        />
      </EuiSetting.Content>
    </>
  );
}

export default ApprovalApproverProxyContentList;
