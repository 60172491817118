import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';
import {
  approvalWorkActions,
  approvalWorkFolderActions,
} from '../../../../stores/approval/work';

function ApprovalPreferencesRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;

  useEffect(() => {
    async function run() {
      if (hash === '')
        await dispatch(approvaluserPreferencesActions.fetchUserPreferences());
      if (hash === '#notifications')
        await dispatch(
          approvaluserPreferencesActions.fetchUserPreferencesNotice(),
        );
      if (hash === '#approvalline') {
        await dispatch(approvalWorkFolderActions.list());
        await dispatch(approvalWorkActions.list({}));
        await dispatch(
          approvaluserPreferencesActions.findPreferencesApproval({}),
        );
      }
      if (hash === '#folder')
        await dispatch(approvaluserPreferencesActions.fetchPreferencesFolder());

      const route = { pathname, search, hash, clearErrors: true };
      dispatch(sessionActions.setRoute(route));
    }

    run();
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default React.memo(ApprovalPreferencesRoute);
