import React, { useRef, useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldClear(): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);

  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  const handleClear = () => {
    stateValue('');
    ref.current?.focus();
  };

  return (
    <ComponentSampleUI.Content title="Clear value">
      <TextField
        placeholder="Placeholder"
        value={value}
        onChange={handleChange}
        clear
        onClear={handleClear}
        ref={ref}
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldClear;
