/* eslint-disable consistent-return */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  CacheEntity,
  CacheMeta,
  CustomNumbers,
  LocateArg,
} from '../../../groupware-common/types';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import { RootState } from '../../../groupware-webapp/app/store';
import approvalFormApi, { formFolderApi } from '../../apis/approval/v1/form';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../../groupware-common/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../groupware-webapp/stores/session';

/** 양식 */
const NAME = 'approval/form';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${NAME}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${NAME}/`) === 0 && type.endsWith('/rejected');
}

type FolderItem = {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

interface Item {
  checked: boolean;
  companyId: number;
  id: number;
  folderId: number;
  status: number;
  name: string;
  description: string;
  createAt: string;
  updateAt: string;
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  items: Item[];
  item:
    | {
        companyId: number;
        id: number;
        folderId: number;
        status: number;
        name: string;
        contents: string;
        description: string;
        createAt: string;
        updateAt: string;
      }
    | undefined;
  totalCount: number;

  folder: {
    list: CacheEntity<{
      items: FolderItem[];
      // totalCount: number;
      // search: string;
    }>;
  };
}

const initialState: State = {
  requests: [],
  items: [],
  item: undefined,
  totalCount: 0,

  folder: {
    list: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: { items: [] },
    },
  },
};

/** 양식 목록 전체 조회. */
const findFormAll = createAsyncThunk(
  `${NAME}/findFormAll`,
  async (_: void, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;

      const total = await approvalFormApi.fetchTotalCount({});
      const items = (
        await approvalFormApi.fetchList({ pageNo: 1, rowsPerPage: total })
      ).map((v) => ({ ...v, checked: false }));

      return { meta, data: { items, totalCount: items.length } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 목록 조회. */
const findList = createAsyncThunk(
  `${NAME}/findList`,
  async (
    arg: {
      folderId?: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;

      const { folderId, route } = arg;
      const {
        pageNo = 1,
        rowsPerPage = 15,
        searchCode,
        searchWord,
      } = getQueryParams(route?.search ?? '');

      const totalCount = await approvalFormApi.fetchTotalCount({
        folderId,
        searchCode,
        searchWord,
      });

      const items = (
        await approvalFormApi.fetchList({
          folderId,
          pageNo,
          rowsPerPage,
          searchCode,
          searchWord,
        })
      ).map((v) => ({ ...v, checked: false }));

      return { meta, data: { items, totalCount } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 조회 */
const findView = createAsyncThunk(
  `${NAME}/findView`,
  async (
    arg: {
      formId: number;
    },
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;

      const { formId } = arg;
      const item = await approvalFormApi.fetchView(formId);
      return { meta, data: item };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 생성. */
const createForm = createAsyncThunk(
  `${NAME}/createForm`,
  async (
    arg: {
      folderId: number;
      status: number;
      name: string;
      description: string;
      content: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { status, name, description, content: contents } = arg;
      await approvalFormApi.create({
        folderId: arg.folderId,
        status,
        name,
        description,
        contents,
      });

      const { route } = arg;
      if (route !== undefined) {
        const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
        const folderId =
          pathParams.p1 === undefined ? undefined : b62(pathParams.p1);

        await dispatch(findList({ folderId, route }));
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 수정. */
const updateForm = createAsyncThunk(
  `${NAME}/updateForm`,
  async (
    arg: {
      id: number;
      folderId: number;
      status: number;
      name: string;
      description: string;
      content: string;
      updateAt: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const {
        id,
        folderId,
        status,
        name,
        description,
        content: contents,
        updateAt,
      } = arg;

      await approvalFormApi.update({
        id,
        folderId,
        status,
        name,
        description,
        contents,
        updateAt,
      });
      await dispatch(findList({ folderId }));
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 삭제. */
const deleteForm = createAsyncThunk(
  `${NAME}/deleteForm`,
  async (
    arg: {
      data:
        | { id: number; updateAt: string }
        | { id: number; updateAt: string }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      if (Array.isArray(data)) {
        const result = await approvalFormApi.delete(data);
        if (result.length !== data.length) {
          if (route !== undefined) {
            const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
            const folderId =
              pathParams.p1 === undefined ? undefined : b62(pathParams.p1);

            await dispatch(findList({ folderId, route }));
            return rejectWithValue(
              appError({
                error:
                  '사용 중인 양식이 존재하여 일부 양식은 삭제 실패하였습니다.',
              }),
            );
          }
        }
      } else await approvalFormApi.delete(data);

      if (route !== undefined) {
        const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
        const folderId =
          pathParams.p1 === undefined ? undefined : b62(pathParams.p1);

        await dispatch(findList({ folderId, route }));
      }
    } catch (ex) {
      await dispatch(sessionActions.setDialog());
      return rejectWithValue(ex);
    }
  },
);

/** 양식 폴더 변경. */
const changeFolder = createAsyncThunk(
  `${NAME}/changeFolder`,
  async (
    arg: {
      data: { id: number; folderId: number; updateAt: string }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      await approvalFormApi.changeFolder(data);

      const { route } = arg;
      if (route !== undefined) {
        const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
        const folderId =
          pathParams.p1 === undefined ? undefined : b62(pathParams.p1);

        await dispatch(findList({ folderId, route }));
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 목록 조회. */
const findFolderList = createAsyncThunk(
  `${NAME}/findFolderList`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;

      const items = await formFolderApi.fetchList({
        pageNo: 1,
        rowsPerPage: CustomNumbers.SMALLINT_MAX,
      });

      return { meta, data: { items } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 생성. */
const createFolder = createAsyncThunk(
  `${NAME}/createFolder`,
  async (
    arg: {
      parentId: number;
      name: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { parentId, name } = arg;
      const folder = await formFolderApi.create({ parentId, name });

      let _response_id_: number | undefined;
      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = folder.id;

      return { folder, _response_id_ };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 수정. */
const updateFolder = createAsyncThunk(
  `${NAME}/updateFolder`,
  async (
    arg: {
      id: number;
      parentId: number;
      name: string;
      updateAt: string;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const folders = (getState() as RootState).approval2.form.folder.list.data
        .items;

      const { id, parentId, name, updateAt } = arg;

      const folder = folders.find((v) => v.id === id);
      if (!folder) throw new Error('폴더를 찾을 수 없습니다.');

      const response = await formFolderApi.update({
        id,
        parentId,
        name,
        updateAt,
      });

      return { ...folder, ...{ id, parentId, name, updateAt }, ...response };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 삭제. */
const deleteFolder = createAsyncThunk(
  `${NAME}/deleteFolder`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      await formFolderApi.delete({ id, updateAt });
      dispatch(findFolderList());
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 순서 변경. */
const updateFolderSeq = createAsyncThunk(
  `${NAME}/updateFolderSeq`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const response = await formFolderApi.updateSeq(data);
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const formSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{ id: number | 'all'; checked: boolean }>,
    ) {
      const { id, checked } = action.payload;
      // if (state.list.data) {
      //   if (action.payload.itemId === 'all') {
      //     state.list.data.items = state.list.data.items.map((x) => {
      //       if (x.checked === action.payload.checked) return x;
      //       return { ...x, checked: action.payload.checked };
      //     });
      //   } else {
      //     const index = state.list.data.items.findIndex(
      //       (x) => x.id === action.payload.itemId,
      //     );
      //     if (index > -1) {
      //       state.list.data.items[index] = {
      //         ...state.list.data.items[index],
      //         checked: action.payload.checked,
      //       };
      //     }
      //   }
      // }
      if (state.items) {
        if (id === 'all') {
          state.items = state.items.map((x) => {
            if (x.checked === checked) return x;
            return { ...x, checked };
          });
        } else {
          const index = state.items.findIndex((x) => x.id === id);
          if (index > -1) {
            state.items[index] = {
              ...state.items[index],
              checked,
            };
          }
        }
      }
    },
    formListClear(state) {
      state.items = [];
    },
    formViewClear(state) {
      state.item = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.folder.list.meta = payload.meta;
        if (payload?.data !== undefined) state.folder.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.item = payload.data;
      })
      .addCase(createFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.folder.list.data.items = [
            ...state.folder.list.data.items,
            payload.folder,
          ];
          state.items = [];
        }
      })
      .addCase(updateFolder.fulfilled, (state, { payload }) => {
        state.folder.list.data.items = state.folder.list.data.items.map((v) => {
          if (v.id === payload.id) return payload;
          return v;
        });
      })
      .addCase(updateFolderSeq.fulfilled, (state, { payload }) => {
        state.folder.list.data.items = state.folder.list.data.items
          .map((a) => {
            const p = payload.find((b) => a.id === b.id);
            if (!p) return a;
            return { ...a, ...p };
          })
          .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      })
      .addCase(findFormAll.fulfilled, (state, { payload }) => {
        if (payload?.data !== undefined) {
          state.items = payload.data.items;
          state.totalCount = payload.data.totalCount;
        }
      })
      .addCase(findList.fulfilled, (state, { payload }) => {
        // if (payload?.meta !== undefined) state.list.meta = payload.meta;
        // if (payload?.data !== undefined) state.list.data = payload.data;
        if (payload?.data !== undefined) {
          state.items = payload.data.items;
          state.totalCount = payload.data.totalCount;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default formSlice.reducer;

export const formFolderActions = {
  list: findFolderList,
  create: createFolder,
  update: updateFolder,
  delete: deleteFolder,
  updateSeq: updateFolderSeq,
};

export const formActions = {
  clear: formSlice.actions.formListClear,
  viewClear: formSlice.actions.formViewClear,
  checked: formSlice.actions.checked,
  all: findFormAll,
  list: findList,
  view: findView,
  create: createForm,
  update: updateForm,
  delete: deleteForm,
  changeFolder,
};
