import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import PostView from '../../../../../components/post/PostView';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import DropMenu from '../../../../../components/selectField/DropMenu';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import attendanceFormApi from '../../../../apis/attendance/v1/form';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import AttendanceWorkForm from '../work/AttendanceWorkForm';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  DirectoryTreeItemArg,
  getDirectoryTreeId,
} from '../../../../../components/tree/DirectoryTree';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';

function AttendanceNoticeSettingContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const notice = useSelector(
    (state: RootState) => state.attendance.preferences.notice,
  );
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6007)?.name ?? '';
  const directory = useDirectory();

  const directoryData = getDirectoryData({
    ...directory,
    companyId: notice.companyId,
    employeeId: notice.alertEmployeeId,
  });

  const managerEmployeeName =
    directoryData.jobClassName === ''
      ? directoryData.employeeName
      : `${directoryData.employeeName} ${directoryData.jobClassName}`;

  const initialState = () => {
    let userSelectMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;

    return {
      alertFormId: notice.alertFormId === 0 ? 0 : notice.alertFormId,
      alertFormName: notice.alertFormName,
      usePlanFormId: notice.usePlanFormId === 0 ? 0 : notice.usePlanFormId,
      usePlanFormName: notice.usePlanFormName,
      planNotifyFormId:
        notice.planNotifyFormId === 0 ? 0 : notice.planNotifyFormId,
      planNotifyFormName: notice.planNotifyFormName,
      planNotifyFormContents: notice.planNotifyFormContents,
      usePlanFormContents: notice.usePlanFormContents,
      alertFormContents: notice.alertFormContents,
      formSelectDialogVisible: false,
      formType: '',
      validation: '',
      saveing: false,
      change: false,
      option: notice.alertType !== null ? notice.alertType.toString() : '0',
      alertEmployeeId: notice.alertEmployeeId,
      alertManagerName:
        notice.alertEmployeeId !== 0
          ? `${directoryData.organizationName} / ${managerEmployeeName}`
          : '',
      userSelectMenuPoint,
      userSelectMenuSelectedId: `${getDirectoryTreeId(
        directoryData.companyId,
        directoryData.organizationId,
        directoryData.employeeId,
      )}`, // notice.alertEmployeeId,
      organizationId: 0,
    };
  };

  const [state, setState] = useState(initialState);

  const useOption: { value: string; label: string }[] = [
    { value: '0', label: '사용안함' },
    { value: '1', label: '사용함' },
  ];

  useEffect(() => {
    let mount = true;
    async function run() {
      const { alertFormId, usePlanFormId, planNotifyFormId, alertEmployeeId } =
        state;

      let data: {
        alertType: number;
        alertFormId?: number;
        usePlanFormId?: number;
        planNotifyFormId?: number;
        alertEmployeeId?: number;
        updateAt: string;
      } = {
        alertType: 0,
        updateAt: '',
      };

      // 연차촉진알림 사용안함일 경우
      if (option === '0') {
        data = {
          alertType: 0,
          alertFormId: 0,
          usePlanFormId: 0,
          planNotifyFormId: 0,
          alertEmployeeId: 0,
          updateAt: notice.updateAt,
        };
      }
      // 연차사용촉진알림 사용함일 경우
      if (option === '1') {
        data = {
          alertType: 1,
          alertEmployeeId,
          alertFormId,
          usePlanFormId,
          planNotifyFormId,
          updateAt: notice.updateAt,
        };
      }
      const save = await dispatch(
        attendancePreferencesActions.modifyNotice(data),
      );
      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }
      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
      }));
    }
    if (state.saveing) run();
    return () => {
      mount = false;
    };
  }, [state.saveing]);

  /** 양식 선택 대화 상자 확인. */
  const handleFormSelectDialogConfirm = async (arg: {
    id: number;
    name: string;
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  }) => {
    const { id, name } = arg;
    attendanceFormApi
      .view(id)
      .then((response) => {
        if (response === null || response.status !== 1) {
          const message = '양식이 사용 중지되었거나 삭제되었습니다.';
          dispatch(sessionActions.error(message));
          return;
        }
        if (state.formType === 'notice') {
          setState((prev) => ({
            ...prev,
            alertFormId: id,
            alertFormName: name,
            alertFormContents: response.contents,
            formType: '',
            formSelectDialogVisible: false,
            change: true,
          }));
        } else if (state.formType === 'usePlan') {
          setState((prev) => ({
            ...prev,
            usePlanFormId: id,
            usePlanFormName: name,
            usePlanFormContents: response.contents,
            formType: '',
            formSelectDialogVisible: false,
            change: true,
          }));
        } else if (state.formType === 'planNotify') {
          setState((prev) => ({
            ...prev,
            planNotifyFormId: id,
            planNotifyFormName: name,
            planNotifyFormContents: response.contents,
            formType: '',
            formSelectDialogVisible: false,
            change: true,
          }));
        }
      })
      .catch((e) => {
        dispatch(sessionActions.error(e));
      });
  };

  /** 양식 선택 대화 상자 닫기. */
  const handleFormSelectDialogClose = () => {
    setState((prev) => ({ ...prev, formSelectDialogVisible: false }));
  };

  /** 저장. */
  const handleSave = () => {
    if (state.change && option !== '0') {
      if (
        state.alertFormId === 0 ||
        state.usePlanFormId === 0 ||
        state.planNotifyFormId === 0
      ) {
        const validation =
          '연차촉진알림을 사용할 경우 알림문서 양식을 선택해야 합니다.';
        setState((prev) => ({
          ...prev,
          validation,
        }));
        return;
      }

      if (state.alertEmployeeId === 0) {
        const validation = '연차사용촉진알림 담당자를 설정 해주세요.';
        setState((prev) => ({
          ...prev,
          validation,
        }));
        return;
      }
    }

    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  /** 스낵바 닫기. */
  const handleSnackbarClose = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  /** 대화상자 랜더링 */
  const renderDialog = () => {
    if (state.formSelectDialogVisible) {
      return (
        <AttendanceWorkForm
          onConfirm={handleFormSelectDialogConfirm}
          onClose={handleFormSelectDialogClose}
        />
      );
    }
    return null;
  };

  const {
    validation,
    saveing,
    change,
    option,
    alertFormName,
    alertFormContents,
    usePlanFormName,
    usePlanFormContents,
    planNotifyFormName,
    planNotifyFormContents,
    userSelectMenuPoint,
    userSelectMenuSelectedId,
    alertManagerName,
  } = state;

  const handleNoticeOptionChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      option: value,
    }));
  };

  /** 사용자 선택 메뉴 토글. */
  const handleUserSelectMenuToggle = (event?: React.MouseEvent) => {
    const { userSelectMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        userSelectMenuPoint: undefined,
      }));
    }
  };

  /** 사용자 선택. */
  const handleUserSelected = (arg: DirectoryTreeItemArg) => {
    const { item } = arg;
    const { extra } = item;
    if (extra.type === 'employee') {
      const employeeName =
        extra.jobClassName === ''
          ? extra.employeeName
          : `${extra.employeeName} ${extra.jobClassName}`;

      setState((prevState) => ({
        ...prevState,
        alertManagerName: `${extra.organizationName} / ${employeeName}`,
        alertEmployeeId: extra.employeeId,
        organizationId: extra.organizationId,
        userSelectMenuSelectedId: item.id,
        userSelectMenuPoint: undefined,
        change: true,
      }));
    }
  };
  let settingList;
  let formSetting;
  if (option === '1') {
    settingList = (
      <div>
        <EuiSetting.Item title="촉진담당자">
          <DropMenu
            value={alertManagerName}
            label="담당자 선택"
            pressed={userSelectMenuPoint !== undefined}
            onClick={handleUserSelectMenuToggle}
          />
          {userSelectMenuPoint && (
            <DirectoryMenuTreeContainer
              point={userSelectMenuPoint}
              selectedId={userSelectMenuSelectedId}
              typeToFilter="employee"
              onItemClick={handleUserSelected}
              onClose={handleUserSelectMenuToggle}
            />
          )}
        </EuiSetting.Item>
      </div>
    );
    formSetting = (
      <div>
        <EuiSetting.Item title="알림문서 양식">
          <DropMenu
            value={alertFormName}
            label="알림문서 양식"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                formSelectDialogVisible: true,
                formType: 'notice',
                change: true,
              }))
            }
          />
          {alertFormContents !== '' && (
            <div style={{ marginTop: '20px' }}>
              <PostView.Content
                data={FormBuilder.viewingPreview({
                  content: alertFormContents,
                })}
              />
            </div>
          )}
        </EuiSetting.Item>
        <EuiSetting.Item title="사용계획서 양식">
          <DropMenu
            value={usePlanFormName}
            label="사용계획서 양식"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                formSelectDialogVisible: true,
                formType: 'usePlan',
                change: true,
              }))
            }
          />
          {usePlanFormContents !== '' && (
            <div style={{ marginTop: '20px' }}>
              <PostView.Content
                data={FormBuilder.viewingPreview({
                  content: usePlanFormContents,
                })}
              />
            </div>
          )}
        </EuiSetting.Item>
        <EuiSetting.Item title="지정일통보문서 양식">
          <DropMenu
            value={planNotifyFormName}
            label="지정일통보문서 양식"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                formSelectDialogVisible: true,
                formType: 'planNotify',
                change: true,
              }))
            }
          />
          {planNotifyFormContents !== '' && (
            <div style={{ marginTop: '20px' }}>
              <PostView.Content
                data={FormBuilder.viewingPreview({
                  content: planNotifyFormContents,
                })}
              />
            </div>
          )}
        </EuiSetting.Item>
      </div>
    );
  }
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title="사용여부">
          <RadioGroup
            data={useOption}
            name="useOption"
            value={option}
            onChange={handleNoticeOptionChange}
          />
        </EuiSetting.Item>
        {settingList}
        {formSetting}
        <EuiToolbar>
          <EuiToolbar.Left>
            {change && (
              <Button
                text="저장"
                variant="contained"
                loading={saveing}
                onClick={handleSave}
              />
            )}
            {!saveing && change && (
              <Button text="취소" onClick={() => setState(initialState)} />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceNoticeSettingContainer;
