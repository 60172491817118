import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Search, {
  SearchDateProps,
} from '../../../../../components/search/Search';
import EuiBody from '../../../../../components/layout/EuiBody';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  documentActions,
  jsonToApprovalLine,
} from '../../../../stores/approval/document';
import ApprovalLineDialogContainer, {
  ApprovalLineType,
  getApprovalLineDrafter,
  getLastApprover,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import {
  b62,
  getAdvancedSearchParams,
  getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import {
  AttachFile,
  SharedFile,
  documentApi,
} from '../../../../apis/approval/v1/document';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import ApprovalPrint from '../../../common/components/ApprovalPrint';
import ApprovalContentHeadView from '../../../root/approval/ApprovalContentHeadView';
import ApprovalContentBodyViewContainer from '../../../root/approval/ApprovalContentBodyViewContainer';
import Loading from '../../../../../components/loading/Loading';
import ApprovalDocumentInfoDrawer from '../../../common/drawers/ApprovalDocumentInfoDrawer';
import ApprovalStatusDrawer from '../../../common/drawers/ApprovalStatusDrawer';
import ApprovalDocumentViewerDrawer from '../../../common/drawers/ApprovalDocumentViewerDrawer';
import ApprovalChangeHistoryDrawer from '../../../common/drawers/ApprovalChangeHistoryDrawer';
import ApprovalAttachedDocumentDialog from '../../../root/approval/content/ApprovalAttachedDocumentDialog';
import { SharePermissionType } from '../common/containers/WorkApprovalLineDialogContainer';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import ApprovalAttachedFileDialog from '../../../root/approval/content/ApprovalAttachedFileDialog';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Button from '../../../../../components/button/Button';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { documentMacroReplace } from '../../../root/approval/compose/ApprovalComposeContainer';
import ApprovalCommentDrawerContainer from '../../../root/approval/ApprovalCommentDrawerContainer';

function getStateWord(state: string): string {
  switch (state) {
    case '1':
      return 'PROGRESS';
    case '2':
      return 'PROGRESS';
    case '3':
      return 'COMPLETE';
    case '4':
      return 'REJECT';
    case 'all':
    default:
      return 'ALL';
  }
}

function statusLabel(type: string) {
  switch (type) {
    case '1':
    case 'proceeding':
      return '진행중';
    case '4':
    case 'return':
      return '반려';
    case '3':
    case 'done':
      return '완료';
    case 'cancel':
      return '취소';
    default:
      return '';
  }
}

function statusTheme(type: string) {
  switch (type) {
    case 'proceeding':
      return 'primary';
    case 'return':
      return 'error';
    case 'done':
      return 'success';
    case 'cancel':
      return 'cancel';
    default:
      return undefined;
  }
}

function ApprovalDocumentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const pathmap = getPathMap('/*/*/*', pathname);
  const { p1: propsItemId } = getPathParams<{ p1?: number }>(
    `/*/*/*/:p1$base62`,
    pathname,
  );
  const queryParams = getQueryParams(search);

  const directory = useDirectory();
  const principal = useSelector((s: RootState) => s.session.principal);
  const adminconsole = useSelector(
    (s: RootState) => s.approval2.document.adminconsole,
  );
  const view = useSelector((s: RootState) =>
    propsItemId !== undefined &&
    propsItemId === s.approval2.document.adminconsole.view.data?.id
      ? s.approval2.document.adminconsole.view.data
      : undefined,
  );
  const historys = useSelector((s: RootState) =>
    propsItemId !== undefined &&
    propsItemId === s.approval2.document.adminconsole.view.data?.id
      ? s.approval2.document.historys.items
      : undefined,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6002)?.name;
  const toolbarActions: ActionEventProps[] = [
    { code: 'moveToTrash', label: '삭제', type: 'icon', icon: 'trash-full' },
    { code: 'print', label: '인쇄', type: 'icon', icon: 'print' },
    { code: 'save', label: 'PC저장', type: 'icon', icon: 'save' },
  ];

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 56 },
    // { field: 'importance', label: '중요도', width: 68, align: 'center' }, // TODO: 중요도 추가
    { field: 'work', label: '업무', width: 168 },
    { field: 'documentNo', label: '문서번호', width: 220 },
    { field: 'subject', label: '제목', minWidth: 200 },
    { field: 'drafter', label: '기안자', width: 158 },
    { field: 'lastApprover', label: '최종결재자', width: 158 },
    { field: 'date', label: '기안일', width: 118, align: 'right' },
    { field: 'status', label: '상태', width: 98, align: 'right' },
  ]);

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<{
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
    attachFileView?: AttachFile;
    sharedFileView?: SharedFile;
    excel?: { [key: string]: string }[]; // 엑셀 다운로드
  }>({
    dialogs: [],
    attachFileView: undefined,
    sharedFileView: undefined,
  });

  useEffect(() => {
    setLoading(false);
    scrollbar.current?.scrollTo(0, 0);
  }, [props]);

  useEffect(() => {
    async function run() {
      setState((prev) => ({ ...prev, excel: undefined }));
      const { status: queryStatus, searchCode, searchWord } = queryParams;
      const rowsperpage =
        adminconsole.totalCount === 0 ? 15 : adminconsole.totalCount;
      const params =
        searchCode && searchWord
          ? {
              advancedSearch: {
                [searchCode.toLowerCase()]: searchWord,
              },
              state:
                queryStatus !== undefined ? getStateWord(queryStatus) : 'ALL',
              pageno: 1,
              rowsperpage,
            }
          : {
              advancedSearch: getAdvancedSearchParams(search),
              state:
                queryStatus !== undefined ? getStateWord(queryStatus) : 'ALL',
              pageno: 1,
              rowsperpage,
            };
      const totalList = (
        await documentApi.fetchAdminconsoleList(params)
      ).list.map((a) => {
        return {
          affiliatedCompanyId:
            a.affiliatedCompanyId === null ? undefined : a.affiliatedCompanyId,
          workName: a.workName, // 업무 이름.
          documentNo: documentMacroReplace(a.number), // 문서 번호
          subject: a.subject, // 제목.
          approvalLine: jsonToApprovalLine(a.approvalline), // 결재선.
          draftAt: dateTimeFormat(a.draftAt, 'yyyy-MM-DD'),
          linkWait: a.linkWait, // 연동대기 유무
          status: a.status.toString(),
        };
      });
      const excelData = totalList.map((a) => {
        const obj: { [key: string]: string } = {};
        const drafter = getApprovalLineDrafter(a.approvalLine);
        const lastApprover = getLastApprover(a.approvalLine);
        const lastApproverData = Array.isArray(lastApprover)
          ? lastApprover[0]
          : lastApprover;
        let drafterFiled = '';
        let lastApproverFiled = '';
        if (drafter) {
          // eslint-disable-next-line prettier/prettier
          if (drafter.employeeId) drafterFiled = `${drafter.organizationName}/${drafter.employeeName} ${drafter.jobPositionName}`;
          else drafterFiled = drafter.organizationName;
        }
        if (lastApproverData) {
          // eslint-disable-next-line prettier/prettier
          if (lastApproverData.employeeId) lastApproverFiled = `${lastApproverData.organizationName}/${lastApproverData.employeeName} ${lastApproverData.jobPositionName}`;
          else lastApproverFiled = lastApproverData.organizationName;
        }
        columns
          .filter(
            (x) =>
              x.field !== 'checkbox' && (x.visible === undefined || x.visible),
          )
          .forEach((x) => {
            if (x.field === 'work') obj[x.field] = a.workName;
            if (x.field === 'documentNo') obj[x.field] = a.documentNo;
            // eslint-disable-next-line prettier/prettier
            if (x.field === 'subject') obj[x.field] = a.linkWait ? `${a.subject} (연동대기)` : a.subject;
            if (x.field === 'drafter') obj[x.field] = drafterFiled;
            if (x.field === 'lastApprover') obj[x.field] = lastApproverFiled;
            if (x.field === 'date') obj[x.field] = a.draftAt;
            if (x.field === 'status') obj[x.field] = statusLabel(a.status);
          });
        return obj;
      });
      setState((prev) => ({ ...prev, excel: excelData }));
    }
    if (queryParams.pageNo === undefined || queryParams.pageNo === 1) run();
  }, [search]);

  /** 기본검색 */
  const handleSearch = (value: {
    keyword: string;
    filter: string;
    status: string;
  }) => {
    const { keyword, filter, status } = value;
    const params = { ...queryParams };
    delete params.subject;
    delete params.documentNumber;
    delete params.workName;
    delete params.formName;
    delete params.content;
    delete params.attachedFileName;
    delete params.drafterName;
    delete params.documentNumber;
    delete params.approvalTargetName;
    delete params.pageNo;

    params.searchWord = keyword === '' ? undefined : keyword;
    params.searchCode = keyword === '' || filter === '' ? undefined : filter;
    params.status = status === '' ? undefined : status;
    go(pathmap, getQueryParams(params));
  };

  /* 목록개수 변경 */
  const handleChangeRowLength = (value: number) => {
    const params = { ...queryParams };
    delete params.pageNo;
    params.rowsPerPage = value;
    go(pathmap, getQueryParams(params));
  };

  /** 목록 노출 */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  /* 체크박스 선택 */
  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    dispatch(
      documentActions.adminconsoleChecked({
        itemId: id,
        checked: event.target.checked,
      }),
    );
  };
  /* 체크박스 전체선택 */
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      documentActions.adminconsoleChecked({
        itemId: 'all',
        checked: event.target.checked,
      }),
    );
  };

  // 파일 다운로드
  const download = (blob: Blob) => {
    if (!blob) throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  /** 클릭이벤트 */
  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id: number;
  }) => {
    const { code, id } = arg;

    /** 이전 페이지. */
    if (code === 'prePage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) - 1;
      if (params.pageNo > 0) {
        if (params.pageNo === 1) delete params.pageNo;
        go(pathmap, getQueryParams(params));
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) + 1;
      go(pathmap, getQueryParams(params));
    }

    if (code === 'cancelSelected') {
      dispatch(
        documentActions.adminconsoleChecked({ itemId: 'all', checked: false }),
      );
    }

    /** 리스트 아이템 클릭 */
    if (code === 'subject') go(`${pathmap}/${b62(id)}`, search);

    if (code === 'print') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    if (code === 'save') {
      const param = adminconsole.list
        .filter((a) => a.checked)
        .map((a) => {
          const affiliateCompanyId = a.affiliatedCompanyId;
          return {
            id: a.id,
            affiliateCompanyId,
          };
        });
      if (param.length === 0) return;
      documentApi
        .download(param)
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }

    if (code === 'moveToTrash') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    if (code === 'opinions') {
      queryParams.drawerType = 'opinions';
      queryParams.id = id;
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }

    if (code === 'comments') {
      queryParams.drawerType = 'comments';
      queryParams.id = id;
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }
  };

  const handleListGo = () => {
    go(pathmap, queryParams);
  };

  const items = adminconsole.list.map((a) => {
    const statusToNumber = parseInt(a.status, 10);
    let status = '';
    if (statusToNumber === 1) status = 'proceeding';
    else if (statusToNumber === 3) status = 'done';
    else if (statusToNumber === 4) status = 'return';
    return {
      checked: a.checked,
      companyId: principal.companyId,
      id: a.id,
      workName: a.workName,
      status,
      // importance: parseInt(a.importance, 10),
      no: a.no,
      subject: a.subject,
      approvalLine: a.approvalLine,
      draftAt: a.draftAt,
      completeAt: a.completeAt,
      opinions: a.opinions,
      comments: a.comments,
      linkWait: a.linkWait ?? false,
    };
  });

  const rows: DataGridRowsProps<number> = items.map((l) => {
    const drafter = getApprovalLineDrafter(l.approvalLine);
    const lastApprover = getLastApprover(l.approvalLine);
    if (drafter !== undefined) {
      let drafterDirectoryData:
        | {
            companyId: number;
            companyName: string;
            organizationId: number;
            organizationName: string;
            employeeId: number;
            employeeName: string;
            jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
            jobClassName: string;
            jobPositionId: number;
            jobPositionName: string;
            jobDutyId: number;
            jobDutyName: string;
            avatar?: string;
          }
        | undefined;
      if (drafter.employeeId) {
        drafterDirectoryData = getDirectoryData({
          ...directory,
          ...drafter,
        });
      }
      if (lastApprover !== undefined) {
        const data = Array.isArray(lastApprover)
          ? lastApprover[0]
          : lastApprover;
        let lastApproverJobClassName = '';
        if (data.employeeId) {
          const lastApproverDirectoryData = getDirectoryData({
            ...directory,
            ...data,
          });
          lastApproverJobClassName = lastApproverDirectoryData.jobPositionName;
        }
        return [
          {
            type: 'checkbox' as const,
            id: l.id,
            value: l.checked,
          },
          // {
          //   type: 'icon' as const,
          //   value: l.importance === 2 ? '높음' : '보통',
          //   icon: l.importance === 2 ? 'exclamation' : undefined,
          //   theme: l.importance === 2 ? 'danger' : undefined,
          // },
          { type: 'text' as const, value: l.workName },
          { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
          {
            type: 'subject' as const,
            value: l.subject,
            id: l.id,
            approvalLine: l.approvalLine,
            linkWait: l.linkWait,
            opinions: l.opinions,
            comments: l.comments,
          },
          {
            type: 'user' as const,
            avatar: drafterDirectoryData
              ? getAvatarPath(drafterDirectoryData)
              : '',
            icon: drafterDirectoryData ? undefined : 'sitemap-fill',
            value: drafterDirectoryData
              ? `${drafterDirectoryData.employeeName} ${drafterDirectoryData.jobPositionName}`
              : drafter.organizationName,
            from: drafterDirectoryData
              ? drafterDirectoryData.organizationName
              : '',
          },
          {
            type: 'user' as const,
            avatar: data.employeeId ? getAvatarPath(data) : '',
            icon: data.employeeId ? undefined : 'sitemap-fill',
            value: data.employeeId
              ? `${data.employeeName} ${lastApproverJobClassName}`
              : data.organizationName,
            from: data.employeeId ? data.organizationName : '',
          },
          { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
          {
            type: 'chip' as const,
            value: statusLabel(l.status),
            theme: statusTheme(l.status),
          },
        ];
      }
      return [
        {
          type: 'checkbox' as const,
          id: l.id,
          value: l.checked,
        },
        // {
        //   type: 'icon' as const,
        //   value: l.importance === 2 ? '높음' : '보통',
        //   icon: l.importance === 2 ? 'exclamation' : undefined,
        //   theme: l.importance === 2 ? 'danger' : undefined,
        // },
        { type: 'text' as const, value: l.workName },
        { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
        {
          type: 'subject' as const,
          value: l.subject,
          id: l.id,
          opinions: l.opinions,
          comments: l.comments,
        },
        {
          type: 'user' as const,
          avatar: drafterDirectoryData
            ? getAvatarPath(drafterDirectoryData)
            : '',
          icon: drafterDirectoryData ? undefined : 'sitemap-fill',
          value: drafterDirectoryData
            ? `${drafterDirectoryData.employeeName} ${drafterDirectoryData.jobPositionName}`
            : drafter.organizationName,
          from: drafterDirectoryData
            ? drafterDirectoryData.organizationName
            : '',
        },
        {
          type: 'user' as const,
          avatar: '',
          value: '',
          from: '',
        },
        { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
        {
          type: 'chip' as const,
          value: statusLabel(l.status),
          theme: statusTheme(l.status),
        },
      ];
    }
    return [
      {
        type: 'checkbox' as const,
        id: l.id,
        value: l.checked,
      },
      // {
      //   type: 'icon' as const,
      //   value: l.importance === 2 ? '높음' : '보통',
      //   icon: l.importance === 2 ? 'exclamation' : undefined,
      //   theme: l.importance === 2 ? 'danger' : undefined,
      // },
      { type: 'text' as const, value: l.workName },
      { type: 'text' as const, value: l.no.replace(/[{}]/gm, '') },
      {
        type: 'subject' as const,
        value: l.subject,
        id: l.id,
        opinions: l.opinions,
        comments: l.comments,
      },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      {
        type: 'user' as const,
        avatar: '',
        value: '',
        from: '',
      },
      { type: 'date' as const, value: l.draftAt, format: 'dateNumber' },
      {
        type: 'chip' as const,
        value: statusLabel(l.status),
        theme: statusTheme(l.status),
      },
    ];
  });

  /** 결재선 변경 저장 */
  const handleApprovalLineChangeSave = (arg: {
    affiliatedCompanyId?: number;
    companyId: number;
    id: number;
    approvalLine?: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    reason: string;
    updateAt: string;
  }) => {
    const {
      affiliatedCompanyId,
      companyId,
      id,
      approvalLine,
      referencePermission,
      viewPermission,
      reason,
      updateAt,
    } = arg;

    if (
      !(
        principal.companyId === companyId &&
        view?.id === id &&
        view.updateAt === updateAt
      )
    ) {
      return;
    }

    const { content } = view;

    const route = utils.getRoute({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });

    // 결재선이 변경된 경우.
    if (approvalLine !== undefined) {
      const data = {
        affiliatedCompanyId,
        id,
        approvalLine,
        reason,
        content,
        updateAt,
      };
      dispatch(documentActions.changeApprovalLine({ data, route }));
    }

    // 참조권이 변경된 경우.
    if (referencePermission !== undefined) {
      const data = {
        type: 'referencePermission' as const,
        companyId,
        id,
        referencePermission,
        reason,
        contents: content,
        updateAt,
      };
      dispatch(documentActions.changePermission({ data, route }));
    }

    // 조회권이 변경된 경우.
    if (viewPermission !== undefined) {
      const data = {
        type: 'viewPermission' as const,
        companyId,
        id,
        viewPermission,
        reason,
        contents: content,
        updateAt,
      };
      dispatch(documentActions.changePermission({ data, route }));
    }
  };

  /** dialog 닫기 */
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument') {
      if (state.dialogs.length > 2) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  /** 삭제함으로 문서 이동 */
  const handleMoveToTrash = () => {
    delete queryParams.pageNo;
    delete queryParams.dialogType;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: pathmap,
        search: getQueryParams(queryParams),
        mode: 'replace',
      },
    });

    if (view === undefined) {
      const data = adminconsole.list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      if (data.length === 1)
        dispatch(documentActions.updateDocument({ data: data[0], location }));
      else
        dispatch(documentActions.updateDocument({ data })).then((result) => {
          const payload = result.payload as number;
          if (data.length !== payload)
            dispatch(sessionActions.setDialog({ type: 'completeDelete' }));
          else go(pathmap, getQueryParams(queryParams));
        });
    } else {
      const data = {
        affiliatedCompanyId: view.affiliatedCompanyId,
        id: view.id,
        updateAt: view.updateAt,
      };
      dispatch(documentActions.updateDocument({ data, location }));
    }
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 결재선, 참조권, 조회권 변경 대화상자
    if (
      dialogType === 'approvalLine' ||
      dialogType === 'referencePermission' ||
      dialogType === 'viewPermission'
    ) {
      if (view === undefined) return null;
      const {
        affiliatedCompanyId,
        id,
        approvalLine,
        referencePermission,
        viewPermission,
        updateAt,
      } = view;
      return (
        <ApprovalLineDialogContainer
          type="change"
          affiliatedCompanyId={affiliatedCompanyId}
          companyId={principal.companyId}
          id={id}
          approvalLine={
            dialogType === 'approvalLine' ? approvalLine : undefined
          }
          referencePermission={
            dialogType === 'referencePermission'
              ? referencePermission
              : undefined
          }
          viewPermission={
            dialogType === 'viewPermission' ? viewPermission : undefined
          }
          updateAt={updateAt}
          onSave={handleApprovalLineChangeSave}
          onCancel={handleCloseDialog}
        />
      );
    }

    // 인쇄 대화상자
    if (dialogType === 'print') {
      let listId = adminconsole.list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
          };
        });
      if (propsItemId && view) listId = [{ id: view.id }];
      return (
        <ApprovalPrint
          folderId={0}
          onClose={handleCloseDialog}
          listId={listId}
        />
      );
    }

    // 첨부파일 보기
    if (dialogType === 'attachedfile') {
      const { attachFileView } = state;
      if (attachFileView === undefined) return null;
      if (attachFileView.url === undefined) return null;

      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: attachFileView.documentId,
            id: attachFileView.id,
            name: attachFileView.name,
            size: attachFileView.size,
            url: attachFileView.url,
            isFileprotection: attachFileView.isFileprotection,
          }}
        />
      );
    }

    // 공유파일 보기
    if (dialogType === 'sharedfile') {
      const { sharedFileView } = state;
      if (sharedFileView === undefined) return null;
      if (sharedFileView.viewerUrl === undefined) return null;

      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: sharedFileView.documentId,
            id: sharedFileView.id,
            name: sharedFileView.name,
            size: sharedFileView.size,
            url: sharedFileView.viewerUrl,
          }}
        />
      );
    }

    // 삭제 대화상자
    if (dialogType === 'moveToTrash') {
      if (
        view?.hasReceipt ||
        view?.isReceipt ||
        adminconsole.list
          .filter((x) => x.checked)
          .find((a) => a.hasReceipt || a.isReceipt)
      ) {
        return (
          <Dialog size="xs">
            <DialogBody>
              <div className="eui-alert-message">
                선택한 문서 중 <strong>수발신 문서 혹은 접수문서가</strong>가
                포함되어 있습니다. 수발신 문서와 접수문서의 경우{' '}
                <strong>수발신 문서 또는 접수문서가 같이 삭제</strong>됩니다.
                <br />
                또한 첨부 문서로 등록되어 있을 경우 함께 삭제됩니다. 삭제
                하시겠습니까?
              </div>
            </DialogBody>
            <DialogFooter>
              <Button text="취소" onClick={handleCloseDialog} />
              <Button
                text="삭제"
                variant="contained"
                onClick={handleMoveToTrash}
              />
            </DialogFooter>
          </Dialog>
        );
      }

      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              첨부 문서로 등록되어 있을 경우 함께 삭제됩니다. 삭제 하시겠습니까?
            </div>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleCloseDialog} />
            <Button
              text="삭제"
              variant="contained"
              onClick={handleMoveToTrash}
            />
          </DialogFooter>
        </Dialog>
      );
    }

    // 삭제 완료 대화상자
    if (dialogType === 'completeDelete') {
      delete queryParams.pageNo;
      delete queryParams.dialogType;
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              일부 문서는 삭제 실패하였습니다.
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text="확인"
              variant="contained"
              onClick={() => go(pathmap, queryParams)}
            />
          </DialogFooter>
        </Dialog>
      );
    }

    const { dialogs } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onAttachedFilePopup={handleAttachedFileDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }

    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    // 문서정보
    if (drawerType === 'info') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalDocumentInfoDrawer
          view={{ ...view, draftAt: view.draftAt }}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 결재현황
    if (drawerType === 'status') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalStatusDrawer
          language="ko-KR"
          view={{ ...view, content: view.content, draftAt: view.draftAt }}
          approvalLine={view.approvalLine}
          complete={view.completeAt !== undefined}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 조회
    if (drawerType === 'viewer') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalDocumentViewerDrawer
          viewId={view.id}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 변경내역 확인
    if (drawerType === 'history') {
      if (historys === undefined) return <Loading />;
      return (
        <ApprovalChangeHistoryDrawer
          historys={historys}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 의견
    if (drawerType === 'opinions' || drawerType === 'comments') {
      const { companyId = principal.companyId, id } = queryParams;
      return (
        <ApprovalCommentDrawerContainer
          companyId={companyId}
          type={queryParams.drawerType}
          id={id}
          onClose={() => {
            delete queryParams.drawerType;
            delete queryParams.companyId;
            delete queryParams.id;
            dispatch(sessionActions.search(getQueryParams(queryParams)));
          }}
        />
      );
    }

    return null;
  };

  // 이전 결재문서
  const handlePrevView = (arg: { id: number }) => {
    go(`${pathmap}/${b62(arg.id)}`, search);
  };
  // 다음 결재문서
  const handleNextView = (arg: { id: number }) => {
    go(`${pathmap}/${b62(arg.id)}`, search);
  };

  const handleHeadViewAction = (arg: {
    code: string;
    event: React.MouseEvent;
  }) => {
    const { code: type } = arg;

    // 문서 정보 드로워
    if (type === 'info') dispatch(sessionActions.setDrawer({ type }));
    // 결재 현황 드로워
    else if (type === 'status') dispatch(sessionActions.setDrawer({ type }));
    // 조회 드로워 열기
    else if (type === 'viewer') dispatch(sessionActions.setDrawer({ type }));
    // 결재선 변경 대화 상자
    else if (type === 'approvalLine')
      dispatch(sessionActions.setDialog({ type }));
    // 참조권 변경 대화 상자
    else if (type === 'referencePermission')
      dispatch(sessionActions.setDialog({ type }));
    // 조회권 변경 대화 상자
    else if (type === 'viewPermission')
      dispatch(sessionActions.setDialog({ type }));
    // 변경 내역 확인 드로워
    else if (type === 'history') {
      if (propsItemId === undefined) return;
      dispatch(
        documentActions.fetchHistoryList({ documentId: propsItemId }),
      ).then(() => {
        dispatch(sessionActions.setDrawer({ type }));
      });
    }
    // 삭제
    else if (type === 'moveToTrash') {
      dispatch(sessionActions.setDialog({ type }));
    }
    // 인쇄 다이얼로그
    else if (type === 'print') dispatch(sessionActions.setDialog({ type }));
    // 저장
    else if (type === 'save') {
      if (view)
        documentApi
          .download([{ id: view.id }])
          .then(download)
          .catch((ex) => {
            dispatch(sessionActions.error(appError(ex)));
          });
    }
    // 문서 변경
    else if (type === 'change') {
      queryParams.contentMode = 'update';
      go(pathname, getQueryParams(queryParams));
    }
  };

  // 첨부문서 대화상자 열기
  const handleAttachedDocumentDialog = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument'; // 부모 문서, 첨부 문서
    documentId: number;
    affiliateCompanyId?: number | undefined;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      dialogType,
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'parentdocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
            receiptDocumentId,
            receiptAffiliatedCompanyId,
          },
        ],
      }));
    }
    if (dialogType === 'attacheddocument') {
      // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
      if (state.dialogs.length === 0 && view) {
        setState((prev) => ({
          ...prev,
          dialogs: [
            {
              dialogType: 'attacheddocument',
              documentId: view.id,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [
            ...prev.dialogs,
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      }
    }
  };

  // 첨부파일 대화상자
  const handleAttachedFileDialog = (file: AttachFile) => {
    dispatch(sessionActions.setDialog({ type: 'attachedfile' }));
    setState((prev) => ({
      ...prev,
      attachFileView: file,
    }));
  };

  // 공유파일 대화상자
  const handleSharedFileDialog = (file: SharedFile) => {
    dispatch(sessionActions.setDialog({ type: 'sharedfile' }));
    setState((prev) => ({
      ...prev,
      sharedFileView: file,
    }));
  };

  /** 상세 검색 */
  const handleSearchAdvanced = (arg: {
    filters: {
      code: string;
      keyword: string;
    }[];
    date: SearchDateProps;
    status?: string;
  }) => {
    delete queryParams.searchCode;
    delete queryParams.searchWord;
    delete queryParams.pageNo;

    const { start, end, type } = arg.date;

    // 기간 검색
    if (start !== null && end !== null) {
      queryParams.dateType = type;
      queryParams.startDate = dateFormat(initialDate(start), 'yyyy-MM-DD');
      queryParams.endDate = dateFormat(initialDate(end), 'yyyy-MM-DD');
    }
    if (type === 'all') {
      queryParams.dateType = undefined;
      queryParams.startDate = undefined;
      queryParams.endDate = undefined;
    }

    const { status } = arg;
    queryParams.status = status === 'all' ? undefined : status;

    // 상세 검색
    arg.filters.forEach(({ code, keyword }) => {
      if (code === 'subject')
        queryParams.subject = keyword === '' ? undefined : keyword;
      if (code === 'documentNumber')
        queryParams.documentNumber = keyword === '' ? undefined : keyword;
      if (code === 'workName')
        queryParams.workName = keyword === '' ? undefined : keyword;
      if (code === 'formName')
        queryParams.formName = keyword === '' ? undefined : keyword;
      if (code === 'content')
        queryParams.content = keyword === '' ? undefined : keyword;
      if (code === 'attachedFileName')
        queryParams.attachedFileName = keyword === '' ? undefined : keyword;
      if (code === 'drafterName')
        queryParams.drafterName = keyword === '' ? undefined : keyword;
      if (code === 'draftOrganizationName')
        queryParams.draftOrganizationName =
          keyword === '' ? undefined : keyword;
      if (code === 'approvalTargetName')
        queryParams.approvalTargetName = keyword === '' ? undefined : keyword;
    });
    go(pathname, queryParams);
  };

  const advancedOptions = [
    { value: 'subject', label: '제목' },
    // { value: 'subject', label: '상태' },
    { value: 'documentNumber', label: '문서번호' },
    { value: 'workName', label: '업무명' },
    { value: 'formName', label: '양식명' },
    { value: 'content', label: '본문' },
    { value: 'attachedFileName', label: '첨부파일명' },
    { value: 'drafterName', label: '기안자' },
    { value: 'draftOrganizationName', label: '기안부서' },
    { value: 'approvalTargetName', label: '결재대상' },
  ];

  const advancedFilter = [
    { code: 'subject', keyword: queryParams.subject ?? '' },
    { code: 'documentNumber', keyword: queryParams.documentNumber ?? '' },
    { code: 'workName', keyword: queryParams.workName ?? '' },
    { code: 'formName', keyword: queryParams.formName ?? '' },
    { code: 'content', keyword: queryParams.content ?? '' },
    { code: 'attachedFileName', keyword: queryParams.attachedFileName ?? '' },
    { code: 'drafterName', keyword: queryParams.drafterName ?? '' },
    {
      code: 'draftOrganizationName',
      keyword: queryParams.draftOrganizationName ?? '',
    },
    {
      code: 'approvalTargetName',
      keyword: queryParams.approvalTargetName ?? '',
    },
  ];

  const status =
    queryParams.status === undefined || queryParams.status === ''
      ? 'all'
      : queryParams.status;

  /** 날짜 필터 */
  let dateFilter = {
    type: queryParams.dateType ? queryParams.dateType : 'all',
    start: queryParams.startDate ? timezoneDate(queryParams.startDate) : null,
    end: queryParams.endDate ? timezoneDate(queryParams.endDate) : null,
  };

  /** 상세검색 초기화 */
  const handleResetAdvanced = () => {
    advancedFilter.map((a) => ({
      ...a,
      keyword: '',
    }));
    dateFilter = {
      type: 'all',
      start: null,
      end: null,
    };
  };

  const isAdvancedSearch =
    advancedFilter.some((a) => a.keyword !== '') ||
    dateFilter.start !== null ||
    dateFilter.end !== null;

  const renderContent = () => {
    let result: JSX.Element = (
      <>
        <EuiHeader>
          <EuiHeader.Title>{title}</EuiHeader.Title>
          <EuiHeader.Search>
            <Search
              keyword={queryParams.searchWord || ''}
              options={[
                { value: 'subject', label: '제목' },
                { value: 'documentNumber', label: '문서번호' },
                { value: 'workName', label: '업무명' },
                { value: 'drafterName', label: '기안자' },
                { value: 'content', label: '본문' },
              ]}
              filter={queryParams.searchCode || 'subject'}
              statusOptions={[
                { value: 'all', label: '전체' },
                { value: '1', label: '진행중' },
                { value: '4', label: '반려' },
                { value: '3', label: '완료' },
              ]}
              status={status}
              advancedOptions={advancedOptions}
              advancedFilter={advancedFilter}
              onResetAdvanced={handleResetAdvanced}
              onSearchAdvanced={handleSearchAdvanced}
              onSearch={handleSearch}
              date={dateFilter}
            />
          </EuiHeader.Search>
          {isAdvancedSearch ? (
            <EuiHeader.SearchResult>
              {advancedFilter.map((a) => {
                if (a.keyword !== '')
                  return (
                    <>
                      <strong className="category">
                        {`${
                          (
                            advancedOptions.find(
                              (option) => option.value === a.code,
                            ) || {}
                          ).label
                        } : `}
                      </strong>
                      <em className="keyword">{a.keyword}</em>
                    </>
                  );
                return null;
              })}
              {dateFilter.start !== null && dateFilter.end !== null && (
                <div className="category-wrap">
                  <strong className="category">기간 : </strong>
                  <em className="keyword">{`${dateFormat(
                    dateFilter.start,
                    'yyyy-MM-DD',
                  )} ~ ${dateFormat(dateFilter.end, 'yyyy-MM-DD')}`}</em>
                </div>
              )}
            </EuiHeader.SearchResult>
          ) : null}
        </EuiHeader>
        <EuiBody>
          <DataGrid
            scrollbar={scrollbar}
            caption="결재문서"
            columns={columns}
            rows={rows}
            checkedCount={items.filter((i) => i.checked).length}
            pagination={{
              no: queryParams.pageNo || 1,
              total: adminconsole.totalCount,
              row: queryParams.rowsPerPage || 15,
              onChangeRow: handleChangeRowLength,
            }}
            toolbarActions={toolbarActions}
            onChecked={handleChangeChecked}
            onCheckedAll={handleChangeCheckedAll}
            export
            excelFileName={`${dateTimeFormat(new Date(), 'YYMMDD')} 결재문서`}
            excelData={state.excel}
            onClick={handleAction}
            onChangeColumnVisible={handleChangeColumnVisible}
            loading={loading}
          />
        </EuiBody>
      </>
    );
    if (propsItemId) {
      if (view === undefined) return <Loading />;

      const prev = view.prev?.id;
      const next = view.next?.id;

      let toolbarButtons: ActionEventProps[] = [
        // eslint-disable-next-line prettier/prettier
        { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
        // eslint-disable-next-line prettier/prettier
        { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
        // eslint-disable-next-line prettier/prettier
        { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
        // eslint-disable-next-line prettier/prettier
      ];
      if (view.linkType === 'NONE') {
        toolbarButtons = [
          ...toolbarButtons,
          {
            code: 'change',
            label: '문서 변경',
            type: 'text',
            icon: 'document-update',
          },
        ];
      }
      if (
        view.status === 'PROGRESS' &&
        view.approvalLine.groups.filter((a) => a.modify).length > 0
      ) {
        toolbarButtons = [
          ...toolbarButtons,
          // eslint-disable-next-line prettier/prettier
          { code: 'approvalLine', label: '결재선 변경', type: 'text', icon: 'line-dot' },
        ];
      }
      if (
        view.referencePermission &&
        view.referencePermission.groups.filter((a) => a.modify).length > 0
      ) {
        toolbarButtons = [
          ...toolbarButtons,
          // eslint-disable-next-line prettier/prettier
          { code: 'referencePermission', label: '참조권 변경', type: 'text', icon: 'document-search' },
        ];
      }
      if (
        view.viewPermission &&
        view.viewPermission.groups.filter((a) => a.modify).length > 0
      ) {
        toolbarButtons = [
          ...toolbarButtons,
          // eslint-disable-next-line prettier/prettier
          { code: 'viewPermission', label: '조회권 변경', type: 'text', icon: 'document-search' },
        ];
      }
      if (view.change) {
        toolbarButtons = [
          ...toolbarButtons,
          // eslint-disable-next-line prettier/prettier
            { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
        ];
      }
      if (view.linkType === 'NONE') {
        toolbarButtons = [
          ...toolbarButtons,
          {
            code: 'moveToTrash',
            label: '삭제',
            type: 'text',
            icon: 'trash-full',
          },
        ];
      }
      toolbarButtons = [
        ...toolbarButtons,
        // eslint-disable-next-line prettier/prettier
        { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
        { code: 'save', label: '저장', type: 'text', icon: 'save' },
      ];
      result = (
        <>
          <ApprovalContentHeadView
            type="full"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleHeadViewAction}
            onListGo={handleListGo}
            onPrev={prev ? () => handlePrevView({ id: prev }) : undefined}
            onNext={next ? () => handleNextView({ id: next }) : undefined}
          />
          <EuiBody>
            <ApprovalContentBodyViewContainer
              pathname={pathname}
              type="full"
              view={{
                ...view,
                content: view.content,
                draftAt: view.draftAt,
              }}
              onAttachedDocumentPopup={handleAttachedDocumentDialog}
              onAttachedFilePopup={handleAttachedFileDialog}
              onSharedFilePopup={handleSharedFileDialog}
            />
          </EuiBody>
        </>
      );
    }
    return result;
  };
  return (
    <>
      {renderContent()}
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default ApprovalDocumentContainer;
