/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { appError } from '../../groupware-webapp/stores/common/utils';
import serviceBasicApi from '../apis/basic';

const name = 'service/basic';

interface State {
  basic: {
    initialModule: string;
    updateAt: string;
  };
}

const initialState: State = {
  basic: {
    initialModule: 'DASHBOARD',
    updateAt: '',
  },
};

const findPreferences = createAsyncThunk(
  `${name}/findPreferences`,
  async (arg: { companyId: number } & LocateArg, { rejectWithValue }) => {
    try {
      const { companyId } = arg;
      const response = await serviceBasicApi.findPreferences({ companyId });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      data: {
        companyId: number;
        initialModule: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const response = await serviceBasicApi.update({ ...data });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const basicSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findPreferences.fulfilled, (state, { payload }) => {
      if (payload !== undefined) state.basic = payload;
    });
  },
});

export default basicSlice.reducer;

export const serviceBasicActions = {
  findPreferences,
  update,
};
