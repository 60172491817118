import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AttachmentsItem from '../../../components/attachments/AttachmentsItem';
import AttachmentsList from '../../../components/attachments/AttachmentsList';
import Comment from '../../../components/comment/Comment';
import PostView from '../../../components/post/PostView';
import Print, { PrintClickProps } from '../../../components/print/Print';
import UserInfo from '../../../components/user/UserInfo';
import { dateTimeFormat } from '../../../groupware-common/utils/ui';
import { useDirectory } from '../../../groupware-directory/stores/directory';
import { RootState, useAppDispatch } from '../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../groupware-webapp/stores/common/utils';
import { boardActions } from '../../stores/board';
import { replaceRetentionPeriodToString } from '../../stores/folder';
import { getCommentItems } from '../root/board/content/BoardContentBodyView';

function BoardPrintView(props: {
  listId: { id: number }[];
  onClose(): void;
}): JSX.Element {
  const { listId, onClose } = props;
  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const folders = useSelector((state: RootState) => state.boards.folder.list);
  const printData = useSelector(
    (state: RootState) => state.boards.board.printView,
  );
  const folder = folders.find((a) => a.id === printData?.folderId);

  const [state, setState] = useState<{
    pageNo: number;
    loading: boolean;
  }>({
    pageNo: 1,
    loading: true,
  });
  const [option, setOption] = useState<
    { value: string; label: string; checked: boolean }[]
  >([]);

  useEffect(() => {
    setOption(
      folder?.option.useReply
        ? [
            { value: 'info', label: '정보', checked: false },
            { value: 'attachment', label: '첨부파일', checked: false },
            { value: 'comment', label: '댓글', checked: false },
          ]
        : [
            { value: 'info', label: '정보', checked: false },
            { value: 'attachment', label: '첨부파일', checked: false },
          ],
    );
  }, [folder]);
  const { pageNo, loading } = state;
  useEffect(() => {
    if (listId.length === 0) return;
    dispatch(
      boardActions.printView({
        id: listId[pageNo - 1].id,
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
        scrollbar.current?.scrollTo(0, 0);
      } else {
        dispatch(boardActions.printViewClear());
        onClose();
      }
    });
  }, [pageNo, dispatch]);

  const totalCount = listId.length;

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setOption((prev) =>
      prev.map((x) =>
        x.value === value ? { ...x, checked: event.target.checked } : x,
      ),
    );
  };

  const handleClick = (code: PrintClickProps) => {
    if (code === 'prev') {
      const page = (pageNo || 1) - 1;
      if (page < 1) return;
      setState({ pageNo: page, loading: true });
    }
    if (code === 'next') {
      if (pageNo >= totalCount) return;
      const page = (pageNo || 1) + 1;
      setState({ pageNo: page, loading: true });
    }
    if (code === 'close') {
      dispatch(boardActions.printViewClear());
      onClose();
    }
  };

  if (printData === undefined) return <></>;

  /** 글 작성자 */
  const writer =
    printData.employeeId === 0
      ? undefined
      : getDirectoryData({
          ...directory,
          companyId: principal.companyId,
          employeeId: printData.employeeId,
        });
  const comments = getCommentItems(
    printData.commentData,
    principal.companyId,
    directory,
  );

  const { retentionPeriod, notifyStartDate, notifyEndDate } = printData;
  const noticeRange =
    notifyStartDate === '1000-01-01' && notifyEndDate === '9999-12-31'
      ? '상시'
      : `${dateTimeFormat(notifyStartDate, 'yyyy-MM-DD')} ~ ${dateTimeFormat(
          notifyEndDate,
          'yyyy-MM-DD',
        )}`;
  return (
    <Print
      loading={loading}
      pageNo={pageNo}
      totalPageCount={totalCount}
      option={option}
      onChangeOption={handleChangeOption}
      onClick={handleClick}
    >
      <div className="ui-view-root" ref={scrollbar}>
        <PostView type="full">
          {option.some((a) => a.value === 'info' && a.checked) && (
            <>
              <PostView.Head>
                <PostView.Title>
                  {printData.titleClassification !== '' &&
                    `[${printData.titleClassification}]`}
                  {printData.subject}
                </PostView.Title>
                {writer ? (
                  <UserInfo
                    className="view-author"
                    avatar={writer.avatar}
                    name={writer.employeeName}
                    from={writer.organizationName}
                    date={printData.createAt}
                  />
                ) : (
                  <UserInfo
                    className="view-author"
                    icon="person"
                    name="익명"
                    date={printData.createAt}
                  />
                )}
              </PostView.Head>
              <PostView.Info>
                {retentionPeriod !== '' && (
                  <PostView.InfoItem
                    title="보존기간"
                    value={replaceRetentionPeriodToString(retentionPeriod)}
                  />
                )}
                <PostView.InfoItem title="조회" value={printData.views} />
                <PostView.InfoItem title="좋아요" value={printData.likes} />
                {printData.isNotified && (
                  <PostView.InfoItem title="공지기간" value={noticeRange} />
                )}
              </PostView.Info>
            </>
          )}
          <PostView.Body>
            <PostView.Content data={printData.contents} />
            {printData.attachedFiles &&
              printData.attachedFiles.length > 0 &&
              option.some((a) => a.value === 'attachment' && a.checked) && (
                <AttachmentsList
                  count={printData.attachedFiles.length}
                  className="view-attachments"
                >
                  {printData.attachedFiles.map(({ fileId: id, name, size }) => (
                    <AttachmentsItem key={id} name={name} size={size} />
                  ))}
                </AttachmentsList>
              )}
            {option.some((a) => a.value === 'comment' && a.checked) && (
              <Comment count={printData.comments} comments={comments ?? []} />
            )}
          </PostView.Body>
        </PostView>
      </div>
    </Print>
  );
}

export default BoardPrintView;
