import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useSelector } from 'react-redux';
import { CustomNumbers, TreeItem } from '../../../../../groupware-common/types';
import { getParentItems } from '../../../../../groupware-common/utils';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import Drawer from '../../../../../components/drawer/Drawer';
import DropMenu from '../../../../../components/selectField/DropMenu';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import HelperText from '../../../../../components/alert/HelperText';
import Switch from '../../../../../components/switch/Switch';
import AddAttachForm from '../../../../../components/attachments/AddAttachForm';
import BoardWorkForm from './BoardWorkForm';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import { RootState as R } from '../../../../../groupware-webapp/app/store';
import FeedBack from '../../../../../components/alert/FeedBack';
import {
  PermissionType,
  PermissionUserType,
  replaceRetentionPeriodToString,
  SaveFolderArg,
  FolderViewItem,
} from '../../../../stores/folder';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import BoardPermissionDialogContainer from './BoardPermissionDialogContainer';

function BoardFolderBoxEditDrawer(props: {
  search: string;
  items: TreeItem[];
  view?: FolderViewItem;
  parentId: number;
  onSave(arg: SaveFolderArg): void;
  onClose(): void;
}): JSX.Element {
  const { search, items, view, parentId, onSave, onClose } = props;
  const directory = useDirectory();
  const employees = useSelector((s: R) => s.directory.employee.list.data.items);
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );

  const stringReplace = (value: string) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  };

  const initialState = view
    ? {
        id: view.id,
        parentId: view.parentId,
        paths: getParentItems(items, view.parentId).map(({ text }) => text),
        name: view.name,
        description: view.description,
        status: view.status,
        defaultLayout: view.defaultLayout.toString(),
        isComment: view.option.useReply,
        isAnonymous: view.option.useAnonymous,
        isSecurePost: view.option.useSecurePost,
        forms: view.forms,
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',
        retentionPeriods: view.retentionPeriods,
        defaultRetentionPeriod: view.defaultRetentionPeriod,
        useCategory: view.option.useTitleClassification,
        category: '',
        titleClassifications: view.titleClassifications,
        managers: view.managers,
        users: view.users,
        exceptioners: view.exceptioners,
        managerMenuPoint: undefined,
        excluderMenuPoint: undefined,
        dialog: undefined,
        validation: '',
      }
    : {
        parentId,
        paths: getParentItems(items, parentId).map(({ text }) => text),
        name: '',
        description: '',
        status: true,
        defaultLayout: '1',
        isComment: true,
        isAnonymous: false,
        isSecurePost: false,
        forms: [],
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',
        retentionPeriods: [],
        defaultRetentionPeriod: '',
        useCategory: false,
        category: '',
        titleClassifications: [],
        managers: [],
        users: [],
        exceptioners: [],
        managerMenuPoint: undefined,
        excluderMenuPoint: undefined,
        dialog: undefined,
        validation: '',
      };

  const [state, setState] = useState<{
    id?: number;
    parentId: number;
    paths: string[];
    name: string;
    description: string;
    status: boolean;
    defaultLayout: string;
    isComment: boolean;
    isAnonymous: boolean;
    isSecurePost: boolean;
    forms: {
      id: number;
      name: string;
    }[];
    retentionYear: string;
    retentionMonth: string;
    retentionDay: string;
    retentionPeriods: {
      id: string;
      checked: boolean;
    }[];
    defaultRetentionPeriod: string;
    useCategory: boolean; // 말머리 사용여부.
    category: string; // 말머리 입력 필드 텍스트.
    titleClassifications: {
      id?: number;
      titleClassification: string;
    }[]; // 말머리 리스트.

    tempCategory?: string;

    managers: PermissionType[];
    users: PermissionUserType[];
    exceptioners: PermissionType[];

    managerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    excluderMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    dialog:
      | 'parentFolder'
      | 'formSelect'
      | 'sharersPermission'
      | 'deleteCategory'
      | undefined;
    validation: string;
  }>(initialState);

  /** 게시함명 변경 이벤트 */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      name: event.target.value,
    });
  };

  /** 설명 변경 이벤트 */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState({
      ...state,
      description: event.target.value,
    });
  };

  /** 사용여부 변경. */
  const handleIsEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      status: event.target.checked,
    }));
  };

  /** 댓글 사용여부 변경. */
  const handleIsCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      isComment: event.target.checked,
    }));
  };

  /** 말머리 사용여부 변경. */
  const handleUseCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, useCategory: event.target.checked }));
  };

  /** 말머리 텍스트 변경 이벤트. */
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (event.target.value.length > 20) {
      setState((prev) => ({
        ...prev,
        validation: '말머리는 20자 이내로 입력 가능합니다.',
      }));
      return;
    }
    setState((prev) => ({ ...prev, category: event.target.value }));
  };

  /** 말머리 추가 이벤트. */
  const handleCategoryAdd = () => {
    if (state.category.trim() === '') {
      setState((prev) => ({
        ...prev,
        validation: '말머리를 입력해주세요.',
      }));
      return;
    }
    if (
      state.titleClassifications.find(
        (a) => a.titleClassification === state.category,
      )
    ) {
      setState((prev) => ({
        ...prev,
        validation: '등록된 말머리가 존재합니다. 다른 말머리를 추가해주세요.',
      }));
      return;
    }
    if (state.titleClassifications.length === 10) {
      setState((prev) => ({
        ...prev,
        validation: '말머리는 최대 10개까지 생성 가능합니다.',
      }));
      return;
    }
    const titleClassifications = [
      ...state.titleClassifications,
      { titleClassification: state.category },
    ];
    setState((prev) => ({
      ...prev,
      titleClassifications,
      category: '',
    }));
  };

  /** 말머리 순서 변경 이벤트. */
  const handleCategoryListOrderChange = (list: { id: string }[]) => {
    const { titleClassifications } = state;
    let changed = list.length !== titleClassifications.length;
    list.forEach((item, i) => {
      if (list[i].id !== titleClassifications[i].titleClassification) {
        changed = true;
        return false;
      }
      return true;
    });

    if (changed) {
      const result: {
        id?: number;
        titleClassification: string;
      }[] = list.map(({ id }) => {
        const titleClassification = titleClassifications.find(
          (a) => a.titleClassification === id,
        );
        return {
          id: titleClassification?.id,
          titleClassification: id,
        };
      });
      setState((prev) => ({ ...prev, titleClassifications: result }));
    }
  };

  const handleCategoryDeleteDialogOpen = (value: string) => {
    setState((prev) => ({
      ...prev,
      tempCategory: value,
      dialog: 'deleteCategory',
    }));
  };

  const handleCategoryDeleteDialogClose = () => {
    setState((prev) => ({
      ...prev,
      tempCategory: undefined,
      dialog: undefined,
    }));
  };

  /** 말머리 삭제 이벤트. */
  const handleCategoryDelete = () => {
    const titleClassifications = state.titleClassifications.filter(
      (i) => i.titleClassification !== state.tempCategory,
    );
    setState((prev) => ({ ...prev, titleClassifications }));
    handleCategoryDeleteDialogClose();
  };

  /** 익명 사용여부 변경. */
  const handleIsAnonymousChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      isAnonymous: event.target.checked,
      users: prev.users.map((a) => {
        if (event.target.checked)
          return {
            ...a,
            options: {
              ...a.options,
              isMove: false,
              // isCopy: false,
            },
          };
        return {
          ...a,
          options: {
            ...a.options,
            isMove: true,
            // isCopy: true,
          },
        };
      }),
    }));
  };

  /** 보안게시 사용여부 변경. */
  const handleIsSecurePostChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      isSecurePost: event.target.checked,
    }));
  };

  /** 대표 보존기간 변경. */
  const handleRetentionCheckedChange = (
    id: string,
    event: React.MouseEvent,
  ) => {
    const { retentionPeriods } = state;
    event.stopPropagation();
    event.preventDefault();

    setState((prev) => ({
      ...prev,
      retentionPeriods: retentionPeriods.map((a) => {
        if (a.id !== id) return { ...a, checked: false };
        return { ...a, checked: true };
      }),
    }));
  };

  /** 권한 삭제 */
  const handleAdminstratorsDelete = (id: number, referenceType: string) => {
    const { users } = state;
    setState((prev) => ({
      ...prev,
      users: users.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 권한 조회 */
  const handleMatchingPermissions = (arg: {
    isRead: boolean;
    isWrite: boolean;
  }): string => {
    const matchingPermissions: string[] = [];
    if (arg.isRead) {
      matchingPermissions.push('읽기');
    }
    if (arg.isWrite) {
      matchingPermissions.push('쓰기');
    }
    return matchingPermissions.join('/');
  };

  /** 보존기간 변경. */
  const handleRetentionYearChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionYear = stringReplace(event.target.value);
    setState((prev) => ({ ...prev, retentionYear }));
  };

  /** 보존 월 변경. */
  const handleRetentionMonthChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionMonth = stringReplace(event.target.value);
    setState((prev) => ({ ...prev, retentionMonth }));
  };

  /** 보존 일 변경. */
  const handleRetentionDayChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionDay = stringReplace(event.target.value);
    setState((prev) => ({ ...prev, retentionDay }));
  };

  /** 보존기간 추가 */
  const handleRetentionPeriodAdd = () => {
    const retentionYear = state.retentionYear.trim();
    const retentionMonth = state.retentionMonth.trim();
    const retentionDay = state.retentionDay.trim();

    if (retentionYear === '' && retentionMonth === '' && retentionDay === '') {
      setState((prev) => ({
        ...prev,
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',
        validation: '보존기간을 입력 해주세요.',
      }));
      return;
    }

    let defaultRetentionPeriod = '';
    if (retentionYear !== '') defaultRetentionPeriod += `${retentionYear}Y`;
    if (retentionMonth !== '') defaultRetentionPeriod += `${retentionMonth}M`;
    if (retentionDay !== '') defaultRetentionPeriod += `${retentionDay}D`;

    if (state.retentionPeriods.find((a) => a.id === defaultRetentionPeriod)) {
      setState((prev) => ({
        ...prev,
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',
        validation: '보존기간을 중복으로 설정할 수 없습니다.',
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      retentionYear: '',
      retentionMonth: '',
      retentionDay: '',
      retentionPeriods: [
        ...prev.retentionPeriods,
        {
          id: defaultRetentionPeriod,
          checked: false,
        },
      ],
    }));
  };

  /** 보존기간 영구 추가 */
  const handleChangeForeverUse = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setState((prev) => ({
        ...prev,
        retentionPeriods: [
          ...prev.retentionPeriods,
          {
            id: 'FOREVER',
            checked: false,
          },
        ],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        retentionPeriods: prev.retentionPeriods.filter(
          (a) => a.id !== 'FOREVER',
        ),
      }));
    }
  };

  /** 보존기간 순서 변경 */
  const handleRetentionListOrderChange = (
    list: { id: string; checked: boolean }[],
  ) => {
    const { retentionPeriods } = state;
    let changed = list.length !== retentionPeriods.length;
    list.forEach((item, i) => {
      if (list[i].id !== retentionPeriods[i].id) {
        changed = true;
        return false;
      }
      return true;
    });

    if (changed) {
      setState((prev) => ({ ...prev, retentionPeriods: list }));
    }
  };

  /** 보존기간 리스트 아이템 삭제 */
  const handleRetentionPeriodDelete = (id: string) => {
    setState((prev) => ({
      ...prev,
      retentionPeriods: prev.retentionPeriods.filter((x) => x.id !== id),
    }));
  };

  const handleSubmit = () => {
    if (state.name.trim() === '') {
      setState((prev) => ({ ...prev, validation: '게시함명을 입력하세요.' }));
      return;
    }
    if (state.useCategory && state.titleClassifications.length === 0) {
      setState((prev) => ({ ...prev, validation: '말머리를 입력하세요.' }));
      return;
    }
    if (state.managers.length === 0) {
      setState((prev) => ({
        ...prev,
        validation: '관리자를 1명 이상 설정하세요.',
      }));
      return;
    }

    const defaultRetentionPeriod =
      state.retentionPeriods.find((a) => a.checked)?.id ?? '';
    const retentionPeriods = state.retentionPeriods.map((a) => a.id);

    if (retentionPeriods.length > 0 && defaultRetentionPeriod === '') {
      setState((prev) => ({
        ...prev,
        validation:
          '대표로 사용할 보존기간을 선택해주세요. 마우스 오른쪽 클릭으로 대표 설정이 가능합니다.',
      }));
      return;
    }

    const permissions: {
      referenceCompanyId: number; // 사용자 회사 아이디
      referenceId: number; // 사용자 아이디 (organization | employee)
      referenceType: string; // 참조 유형
    }[] = [];
    state.managers.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.users.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.exceptioners.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });

    let isDuplicate = false;
    for (let i = 0; i < permissions.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (
          permissions[i].referenceType === permissions[j].referenceType &&
          permissions[i].referenceId === permissions[j].referenceId
        ) {
          isDuplicate = true;
          break;
        }
      }
    }
    if (isDuplicate) {
      setState((prev) => ({
        ...prev,
        validation:
          '관리자 또는 공유자, 예외자 인원을 중복으로 설정할 수 없습니다.',
      }));
      return;
    }

    onSave({
      id: view ? view.id : undefined,
      parentId: state.parentId,
      name: state.name,
      description: state.description,
      status: state.status,
      defaultLayout: parseInt(state.defaultLayout, 10),
      option: {
        useAnonymous: state.isAnonymous,
        useReply: state.isComment,
        useSecurePost: state.isSecurePost,
        useTitleClassification: state.useCategory,
      },
      forms: state.forms,
      defaultRetentionPeriod,
      retentionPeriods: state.retentionPeriods,
      titleClassifications: state.titleClassifications,
      managers: state.managers,
      users: state.users,
      exceptioners: state.exceptioners,
      updateAt: view ? view.updateAt : undefined,
    });
  };

  const handleDialogOpen = (
    dialog: 'parentFolder' | 'formSelect' | 'sharersPermission',
  ) => {
    setState({ ...state, dialog });
  };

  const handleDialogClose = () => {
    setState({ ...state, dialog: undefined });
  };

  /** 상위 게시함 선택. */
  const handleParentFolderSelect = (
    id: number,
    text: string,
    path: string[],
  ) => {
    setState((prev) => ({
      ...prev,
      dialog: undefined,
      parentId: id,
      paths: path,
    }));
  };

  /** 기본 유형 변경 이벤트. */
  const handleDefaultLayoutChange = (defaultLayout: string) => {
    setState((prev) => ({
      ...prev,
      defaultLayout,
    }));
  };

  /** 관리자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { managerMenuPoint } = state;
    if (event !== undefined && managerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        managerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        managerMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 아이템 추가. */
  const handleManagerAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.managers.some((a) => a.referenceId === employeeId))
          return { ...prev, managerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, managerMenuPoint: undefined };

        return {
          ...prev,
          managers: [
            ...prev.managers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
              options: {
                isRead: true,
                isWrite: true,
                isMove: true,
                // isCopy: true,
              },
            },
          ],
        };
      });
    }
  };

  /** 관리자 아이템 삭제. */
  const handleManagerDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      managers: prev.managers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 예외자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggleExcluder = (event?: React.MouseEvent) => {
    const { excluderMenuPoint } = state;
    if (event !== undefined && excluderMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: undefined,
      }));
    }
  };

  /** 예외자 아이템 추가. */
  const handleExcluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) => a.referenceType === 'COMPANY' && a.referenceId === companyId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };
        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 'COMPANY',
              options: {
                isRead: false,
                isWrite: false,
                isMove: false,
                // isCopy: false,
              },
            },
          ],
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
              options: {
                isRead: false,
                isWrite: false,
                isMove: false,
                // isCopy: false,
              },
            },
          ],
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.exceptioners.some((a) => a.referenceId === employeeId))
          return { ...prev, excluderMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
              options: {
                isRead: false,
                isWrite: false,
                isMove: false,
                // isCopy: false,
              },
            },
          ],
        };
      });
    }
  };

  /** 예외자 아이템 삭제. */
  const handleExcluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      exceptioners: prev.exceptioners.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 양식 선택 */
  const handleFormSelect = (arg: { id: number; name: string }[]) => {
    const forms = arg.map((a) => ({ ...a }));
    setState((prev) => {
      const prevForms = prev.forms;
      let validation = '';

      for (let i = 0; i < forms.length; i += 1) {
        const form = forms[i];
        if (prevForms.findIndex(({ id }) => form.id === id) > -1) {
          validation = `${form.name} 는(은) 이미 선택된 양식 입니다.`;
          break;
        }
      }

      if (validation !== '') return { ...prev, validation };

      return {
        ...prev,
        forms: [...prevForms, ...forms],
        dialog: undefined,
      };
    });
  };

  /** 공유자 권한 설정. */
  const handleSharersPermissionSave = (users: PermissionUserType[]) => {
    setState((prev) => ({
      ...prev,
      users,
      dialog: undefined,
    }));
  };

  const renderDialog = () => {
    const { dialog } = state;
    let result = null;

    if (dialog === 'formSelect')
      return (
        <BoardWorkForm
          onClose={handleDialogClose}
          onConfirm={handleFormSelect}
          search={search}
        />
      );
    if (dialog === 'sharersPermission')
      return (
        <BoardPermissionDialogContainer
          users={state.users}
          onSave={handleSharersPermissionSave}
          onClose={handleDialogClose}
        />
      );
    if (dialog === 'parentFolder')
      result = (
        <TreePicker
          title="상위 게시함 선택"
          list={items}
          selectedId={state.parentId ?? 0}
          rootId={0}
          onSelected={handleParentFolderSelect}
          onClose={handleDialogClose}
        />
      );
    if (dialog === 'deleteCategory')
      result = (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message eui-delete-message">
              <p>
                말머리를 삭제할 경우 해당 말머리를 사용한 게시글의 말머리는
                삭제됩니다.
              </p>
              <p>말머리를 삭제하시겠습니까?</p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleCategoryDeleteDialogClose} />
            <Button
              noDuplication
              text="삭제"
              variant="contained"
              color="danger"
              onClick={handleCategoryDelete}
            />
          </DialogFooter>
        </Dialog>
      );

    return result;
  };

  const title = view !== undefined ? '게시함 수정' : '게시함 등록';

  return (
    <>
      <Drawer title={title} onClose={onClose}>
        <DrawerBody className="ui-board-company">
          <PostWrite>
            <PostWrite.Item title="상위 게시함">
              <DropMenu
                value={state.paths}
                label="상위 게시함 선택"
                onClick={() => handleDialogOpen('parentFolder')}
              />
            </PostWrite.Item>
            <PostWrite.Item required title="게시함명">
              <TextField value={state.name} onChange={handleNameChange} />
            </PostWrite.Item>
            <PostWrite.Item title="설명">
              <TextField
                value={state.description}
                multiline
                rows={2}
                maxLength={200}
                onChange={handleDescriptionChange}
                count
              />
            </PostWrite.Item>
            <PostWrite.Item title="보기모드">
              <RadioGroup
                data={[
                  { value: '1', label: '목록형' },
                  { value: '2', label: '좌우분할형' },
                  { value: '3', label: '상하분할형' },
                  { value: '4', label: '갤러리형' },
                ]}
                name="유형"
                value={state.defaultLayout}
                onChange={handleDefaultLayoutChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="사용여부">
              <Switch checked={state.status} onChange={handleIsEnableChange} />
            </PostWrite.Item>
            <PostWrite.Item title="댓글">
              <Switch
                checked={state.isComment}
                onChange={handleIsCommentChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="보안게시">
              <Switch
                checked={state.isSecurePost}
                onChange={handleIsSecurePostChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="익명">
              <Switch
                disabled={!!props.view}
                checked={state.isAnonymous}
                onChange={handleIsAnonymousChange}
              />
              <HelperText text="※ 익명 설정은 나중에 변경하실 수 없습니다." />
            </PostWrite.Item>
            <PostWrite.Item title="양식">
              <div className="select-action" style={{ marginTop: '8px' }}>
                <AddAttachForm
                  data={state.forms.map((a) => {
                    return { id: a.id, subject: a.name };
                  })}
                  onRemove={(id: number) => {
                    setState((prev) => ({
                      ...prev,
                      forms: prev.forms?.filter((a) => a.id !== id),
                    }));
                  }}
                  onAllRemove={() => {
                    setState((prev) => ({
                      ...prev,
                      forms: [],
                    }));
                  }}
                  onOpen={() => handleDialogOpen('formSelect')}
                />
              </div>
            </PostWrite.Item>
            <PostWrite.Item title="보존기간">
              <div className="ui-retention-input">
                <div>
                  <TextField
                    label="년"
                    value={state.retentionYear}
                    onChange={handleRetentionYearChange}
                    width={80}
                    maxLength={3}
                    pattern="[0-9]*"
                  />
                  <span style={{ marginLeft: '8px' }}>
                    <TextField
                      label="개월"
                      value={state.retentionMonth}
                      onChange={handleRetentionMonthChange}
                      width={80}
                      maxLength={3}
                      pattern="[0-9]*"
                    />
                  </span>
                  <span style={{ marginLeft: '8px' }}>
                    <TextField
                      label="일"
                      value={state.retentionDay}
                      onChange={handleRetentionDayChange}
                      width={80}
                      maxLength={3}
                      pattern="[0-9]*"
                    />
                  </span>
                  <Button
                    text="추가"
                    iconType
                    icon="plus-circle-fill"
                    onClick={handleRetentionPeriodAdd}
                    className="selector-toggle"
                    size="sm"
                  />
                  <div className="ui-retention-input">
                    <Checkbox
                      name="FOREVER"
                      label="영구사용"
                      checked={state.retentionPeriods.some(
                        (a) => a.id === 'FOREVER',
                      )}
                      onChange={handleChangeForeverUse}
                    />
                  </div>
                </div>
                <div>
                  <>
                    <ReactSortable
                      className="ui-change-order"
                      list={state.retentionPeriods}
                      setList={handleRetentionListOrderChange}
                      animation={200}
                    >
                      {state.retentionPeriods.map((a) => (
                        <div
                          key={a.id}
                          className="list-item"
                          onContextMenu={(event) =>
                            handleRetentionCheckedChange(a.id, event)
                          }
                        >
                          <div className="handle" />
                          <input
                            disabled
                            className="input"
                            type="text"
                            value={replaceRetentionPeriodToString(a.id)}
                          />
                          {a.checked && <div className="badge" />}
                          <Button
                            className="delete"
                            text="삭제"
                            iconType
                            icon="trash-full"
                            onClick={() => handleRetentionPeriodDelete(a.id)}
                          />
                        </div>
                      ))}
                    </ReactSortable>
                  </>
                </div>
              </div>
            </PostWrite.Item>
            <PostWrite.Item title="말머리">
              <Switch
                checked={state.useCategory}
                onChange={handleUseCategoryChange}
              />
              {state.useCategory && (
                <div className="ui-retention-input">
                  <div>
                    <TextField
                      width={180}
                      value={state.category}
                      onChange={handleCategoryChange}
                      rows={2}
                    />
                    <Button
                      text="추가"
                      iconType
                      icon="plus-circle-fill"
                      onClick={handleCategoryAdd}
                      className="selector-toggle"
                      size="sm"
                    />
                    <HelperText>
                      <HelperText text="* 말머리는 20자 이내로 입력 가능합니다." />
                      <HelperText text="* 말머리는 문서함 당 10개까지 만들 수 있습니다." />
                    </HelperText>
                  </div>
                  <div>
                    <ReactSortable
                      className="ui-change-order"
                      list={state.titleClassifications.map((a) => ({
                        id: a.titleClassification,
                      }))}
                      setList={handleCategoryListOrderChange}
                      animation={200}
                    >
                      {state.titleClassifications.map((a, i) => {
                        const key = `${a.id}_${i}`;
                        return (
                          <div key={key} className="list-item">
                            <div className="handle" />
                            <input
                              disabled
                              className="input"
                              type="text"
                              value={a.titleClassification}
                            />
                            <Button
                              className="delete"
                              text="삭제"
                              iconType
                              icon="trash-full"
                              onClick={() =>
                                handleCategoryDeleteDialogOpen(
                                  a.titleClassification,
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </ReactSortable>
                  </div>
                </div>
              )}
            </PostWrite.Item>
            <PostWrite.Item
              required
              title="관리자"
              tooltip="관리자는 직원만 선택가능합니다."
            >
              <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
                {state.managers
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc="관리자"
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleManagerDelete(id, referenceType)
                          }
                        />
                      );
                    },
                  )}
                {state.managerMenuPoint && (
                  <DirectoryMenuTreeContainer
                    deduplication
                    point={state.managerMenuPoint}
                    typeToFilter="employee"
                    onItemClick={handleManagerAppend}
                    onClose={handleDirectoryTreeMenuToggle}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>
            <PostWrite.Item title="공유자">
              <ChipGroup
                add="추가"
                onAdd={() => handleDialogOpen('sharersPermission')}
              >
                {state.users
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 'COMPANY') return -1;
                      if (a.referenceType === 'ORGANIZATION') {
                        if (b.referenceType === 'COMPANY') return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                      options: { isRead, isWrite },
                    }) => {
                      if (referenceType === 'COMPANY') {
                        // 회사일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getCompanyName(companyId)}
                            icon="company"
                            etc={handleMatchingPermissions({ isRead, isWrite })}
                            onDelete={() =>
                              handleAdminstratorsDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'ORGANIZATION') {
                        // 부서일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            etc={handleMatchingPermissions({ isRead, isWrite })}
                            icon="sitemap-fill"
                            onDelete={() =>
                              handleAdminstratorsDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'EMPLOYEE') {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            etc={handleMatchingPermissions({ isRead, isWrite })}
                            avatar={DirectoryData.avatar}
                            onDelete={() =>
                              handleAdminstratorsDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      return null;
                    },
                  )}
              </ChipGroup>
            </PostWrite.Item>
            <PostWrite.Item title="예외자">
              <ChipGroup
                add="추가"
                onAdd={handleDirectoryTreeMenuToggleExcluder}
              >
                {state.exceptioners
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 'COMPANY') return -1;
                      if (a.referenceType === 'ORGANIZATION') {
                        if (b.referenceType === 'COMPANY') return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      if (referenceType === 'COMPANY') {
                        // 회사일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getCompanyName(companyId)}
                            icon="company"
                            etc="예외자"
                            onDelete={() =>
                              handleExcluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'ORGANIZATION') {
                        // 부서일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            icon="sitemap-fill"
                            etc="예외자"
                            onDelete={() =>
                              handleExcluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'EMPLOYEE') {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            etc="예외자"
                            avatar={DirectoryData.avatar}
                            onDelete={() =>
                              handleExcluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      return null;
                    },
                  )}
                {state.excluderMenuPoint && (
                  <DirectoryMenuTreeContainer
                    deduplication
                    point={state.excluderMenuPoint}
                    typeToFilter={['organization', 'employee']}
                    onItemClick={handleExcluderAppend}
                    onClose={handleDirectoryTreeMenuToggleExcluder}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>
          </PostWrite>
        </DrawerBody>
        <DrawerAction>
          <Button
            noDuplication={state.validation === ''}
            text="저장"
            variant="contained"
            onClick={handleSubmit}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
      {renderDialog()}
    </>
  );
}

export default BoardFolderBoxEditDrawer;
