import { useEffect } from 'react';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { contactsActions } from '../../../../stores/contacts';

function ContactComposeRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);

  useEffect(() => {
    if (queryParams.contentMode === 'create')
      dispatch(contactsActions.viewClear());
    dispatch(sessionActions.setRoute({ pathname, search }));
  }, [props]);

  return null;
}

export default ContactComposeRoute;
