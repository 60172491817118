import React from 'react';
import { getQueryParams } from '../../groupware-common/utils';
import ContactComposeContainer from '../pages/root/contact/compose/ContactComposeContainer';
import ContactContentContainer from '../pages/root/contact/content/ContactContentContainer';

function ContactLocateContainer(props: {
  pathname: string;
  search: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, locationKey: key } = props;
  const queryParams = getQueryParams(search);

  if (
    queryParams.contentMode === 'create' ||
    queryParams.contentMode === 'update'
  )
    return (
      <ContactComposeContainer pathname={pathname} search={search} key={key} />
    );
  return <ContactContentContainer pathname={pathname} search={search} />;
}

export default ContactLocateContainer;
