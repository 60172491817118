import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { folderActions } from '../../../../stores/document/folders';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { documentsActions } from '../../../../stores/document/document';

function DocumentComposeRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();
  const list = useSelector(
    (state: RootState) => state.document.documents.list.items,
  );

  useEffect(() => {
    let mount = true;

    if (mount) {
      const route = { pathname, search, hash };
      const queryParams = getQueryParams(search);

      const { p1, p2 } = getPathParams<{
        p1?: string;
        p2?: number;
      }>('/document/:p1/:p2$b62', pathname);
      const isFixedFolder =
        p1 === 'all' ||
        p1 === 'temp' ||
        p1 === 'importance' ||
        p1 === 'checkout';

      // 작성 또는 임시보관 작성인 경우.
      if (queryParams.contentMode === 'create') {
        // 임시보관 작성인 경우.
        if (queryParams.contentType === 'temp' && p2) {
          dispatch(documentsActions.view({ id: p2 })).then((result) => {
            if ((result as { error?: string }).error === undefined) {
              const { folderId } = (
                result.payload as { view: { folderId: number } }
              ).view;
              if (folderId !== 0)
                dispatch(folderActions.userFolderView({ id: folderId, route }));
              else {
                dispatch(folderActions.clearView());
                dispatch(sessionActions.setRoute(route));
              }
            }
          });
        } else {
          dispatch(documentsActions.viewClear());
          if (!p1 || isFixedFolder) {
            dispatch(folderActions.clearView());
            dispatch(sessionActions.setRoute({ pathname, search, hash }));
          } else {
            dispatch(folderActions.userFolderView({ id: b62(p1), route }));
          }
        }
        // 수정인 경우.
      } else if (queryParams.contentMode === 'update') {
        if (p1 !== undefined && p2 !== undefined) {
          let folderId = b62(p1);
          // 체크아웃 문서 메뉴일 때 p1이 폴더 아이디가 아니기 때문에 리덕스 데이터에서 폴더 아이디 조회
          if (p1 === 'checkout')
            folderId = list.find((a) => a.id === p2)?.folderId ?? 0;
          dispatch(folderActions.userFolderView({ id: folderId }));
          dispatch(documentsActions.view({ id: p2, route }));
        }
      }
    }
    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default DocumentComposeRoute;
