import React from 'react';
import ComponentMenuBasic from './ComponentMenuBasic';
import ComponentMenuIcon from './ComponentMenuIcon';
import ComponentMenuChecked from './ComponentMenuChecked';

function ComponentMenu(): JSX.Element {
  return (
    <>
      <ComponentMenuBasic />
      <ComponentMenuIcon />
      <ComponentMenuChecked />
    </>
  );
}

export default ComponentMenu;
