import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { documentActions } from '../../../../stores/approval/document';

function ApprovalStatusRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${ApprovalStatusRoute.name}.render`);

  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;

  useEffect(() => {
    let mount = true;
    const route = { pathname, search, hash, clearErrors: true };
    if (mount) {
      dispatch(
        documentActions.list({
          folderId: 1009,
          search,
          route,
        }),
      );
    }
    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default ApprovalStatusRoute;
