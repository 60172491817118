import React from 'react';

function DialogFooter(props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element {
  return (
    <div className="dialog-footer" style={props.style}>
      {props.children}
    </div>
  );
}

export default DialogFooter;
