import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  children?: React.ReactNode;

  onClose?(): void;
}

class CreatePortal extends React.Component<Props> {
  render(): React.ReactPortal {
    return ReactDOM.createPortal(<>{this.props.children}</>, document.body);
  }
}

export default CreatePortal;
