import React from 'react';
import Button from '../../../../components/button/Button';
import EuiSetting from '../../../../components/layout/EuiSetting';
import SimpleSearch from '../../../../components/search/SimpleSearch';
import TreeList from '../../../../components/tree/TreeList';
import { IconType } from '../../../../groupware-common/types/icon';

function ResourceFolderContentList(props: {
  items: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  selectedId?: number;
  filter: string;
  onClick(code: string): void;
  onFilter(filter: string): void;
  onSelect(id: number): void;
}): JSX.Element {
  const { items, selectedId, filter, onClick, onFilter, onSelect } = props;

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilter(event.target.value);
  };

  return (
    <>
      <EuiSetting.Header title="자원등록">
        <Button
          text="폴더 등록"
          iconType
          icon="folder-plus"
          onClick={() => onClick('folder/create')}
        />
        <Button
          text="자원 등록"
          iconType
          icon="document-plus"
          onClick={() => onClick('item/create')}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilter} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <TreeList
          items={items}
          selectedId={selectedId}
          filter={filter}
          onItemClick={onSelect}
        />
      </EuiSetting.Content>
    </>
  );
}

export default ResourceFolderContentList;
