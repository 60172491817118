import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../groupware-webapp/app/store';
import { LocateArg } from '../../../../groupware-common/types';
import { ApiError } from '../../../../groupware-common/types/error';
import { appError } from '../../../../groupware-webapp/stores/common/utils';
import { thunkCondition } from '../../../../groupware-webapp/stores/utils';
import preferencesApi from '../../../apis/directory/v1/preferences';
import roleApi from '../../../apis/directory/v1/role';

const name = 'directory/preferences';

export type JobClassType = 'jobposition' | 'jobduty' | 'jobposition+jobduty';

function parseJobClass(type: number): JobClassType;
function parseJobClass(type: JobClassType): number;
function parseJobClass(type: number | JobClassType): JobClassType | number {
  if (typeof type === 'number') {
    switch (type) {
      case 2:
        return 'jobduty';
      case 3:
        return 'jobposition+jobduty';
      // case 1: return 'jobposition';
      default:
        return 'jobposition';
    }
  }

  switch (type) {
    case 'jobduty':
      return 2;
    case 'jobposition+jobduty':
      return 3;
    // case 'jobposition': return 1;
    default:
      return 1;
  }
}

interface PreferencesState {
  jobClassType: JobClassType;
  updateAt: string;
  administrators: { employeeId: number; updateAt: string }[];
}

const initialState: PreferencesState = {
  jobClassType: 'jobposition',
  updateAt: '1000-01-01T00:00:00.000000',
  administrators: [],
};

const find = createAsyncThunk<
  { jobClassType: JobClassType; updateAt: string },
  LocateArg | void,
  { rejectValue: ApiError }
>(`${name}/find`, async (arg, thunkApi) => {
  try {
    const response = await preferencesApi.find();
    return {
      jobClassType: parseJobClass(response.jobClass),
      updateAt: response.updateAt,
    };
  } catch (ex) {
    return thunkApi.rejectWithValue(appError(ex));
  }
});

interface SaveArg {
  jobClassType: JobClassType;
}

const save = createAsyncThunk<
  (SaveArg & { updateAt: string }) | undefined,
  SaveArg,
  { rejectValue: ApiError }
>(
  `${name}/save`,
  async (arg: SaveArg, thunkApi) => {
    try {
      const { jobClassType } = arg;
      const { preferences } = (thunkApi.getState() as RootState).directory;

      if (jobClassType === preferences.jobClassType) return undefined;

      const response = await preferencesApi.save({
        jobClass: parseJobClass(arg.jobClassType),
        updateAt: preferences.updateAt,
      });

      return {
        ...arg,
        updateAt: response.updateAt,
      };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(save.typePrefix, arg, getState);
    },
  },
);

const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (_: void, { rejectWithValue }) => {
    try {
      // console.log(`findAdministrators()`);
      return await roleApi.find('DIRECTORY_ADMIN');
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue }) => {
    try {
      // console.log(`appendAdministrators(arg)`, arg);
      if (arg.length === 0) return undefined;
      return await roleApi.append(
        arg.map((a) => ({ ...a, role: 'DIRECTORY_ADMIN' as const })),
      );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue },
  ) => {
    try {
      // console.log(`removeAdministrators(arg)`, arg);
      // throw new Error('테스트 에러.');
      if (arg.length === 0) return undefined;
      return await roleApi.remove(
        arg.map((a) => ({ ...a, role: 'DIRECTORY_ADMIN' as const })),
      );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const directoryPreferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(find.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.jobClassType = payload.jobClassType;
          state.updateAt = payload.updateAt;
        }
      })
      .addCase(save.fulfilled, (state, { payload }) => {
        if (payload) {
          state.jobClassType = payload.jobClassType;
          state.updateAt = payload.updateAt;
        }
      })
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        if (payload) state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      });
  },
});

export default directoryPreferencesSlice.reducer;

export const directoryPreferencesActions = {
  find,
  save,
  findAdministrators,
  appendAdministrators,
  removeAdministrators,
};
