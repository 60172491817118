import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 연차 조정 등록 객체 */
export interface AdjustCreateItem {
  modifingDate: string; // 조정 일자.
  employeeId: number; // 직원 아이디.
  modifingLeaves: number; // 조정 수(minutes).
  remark: string; // 사유.
  attachedFiles?: {
    attendanceFileId: number;
    path: string;
    name: string;
    seq: number;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[]; // 첨부파일.
}

/** 대휴 조정 등록 객체 */
export interface AlternativeAdjustCreateItem {
  modifingDate: string; // 조정 일자.
  employeeId: number; // 직원 아이디.
  ammount: number; // 조정 수(minutes).
  remark: string; // 사유
  attachedFile?: {
    attendanceFileId: number;
    path: string;
    name: string;
    seq: number;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[]; // 첨부파일.
}

/** 첨부파일 객체 */
interface AttachedFileType {
  companyId: number;
  id: number;
  attachedFileId: number;
  seq: number;
  name: number;
  size: number;
  updateAt: string;
}

/** 연차 조정 내역 조회. - 리스트 */
async function findList(arg: {
  startDate?: string;
  endDate?: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    modifingId: number; // 연차 조정 아이디.
    modifingDate: string; // 조정 일자.
    employeeId: number; // 직원 아이디.
    modifingLeaves: number; // 조정 수(minutes).
    remark: string; // 사유
    attachedFiles: AttachedFileType | null; // 첨부파일.
  }[]
> {
  const {
    startDate: startdate,
    endDate: enddate,
    searchCode: searchcode,
    searchWord: searchword,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/modifing/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchword,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차 조정 내역 조회. - 총개수 */
async function findTotalCount(arg: {
  startDate?: string;
  endDate?: string;
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    startDate: startdate,
    endDate: enddate,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/modifing/all/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차 조정 등록. */
async function createAdjust(arg: AdjustCreateItem): Promise<AdjustCreateItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/modifing`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 조정 내역 조회. - 리스트 */
async function findAlternativeList(arg: {
  startDate?: string;
  endDate?: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    modifingId: number; // 대휴 조정 아이디.
    modifingDate: string; // 조정 일자.
    employeeId: number; // 직원 아이디.
    ammount: number; // 조정 수(minutes).
    remark: string; // 사유
    attachedFiles: AttachedFileType | null; // 첨부파일.
  }[]
> {
  const {
    startDate: startdate,
    endDate: enddate,
    searchCode: searchcode,
    searchWord: searchword,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/alternative/modifing/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchword,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 조정 내역 조회. - 총 개수 */
async function findAlternativeTotalCount(arg: {
  startDate?: string;
  endDate?: string;
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    startDate: startdate,
    endDate: enddate,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/alternative/modifing/all/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 조정 등록. */
async function createAlternativeAdjust(
  arg: AlternativeAdjustCreateItem,
): Promise<AlternativeAdjustCreateItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/alternative/modifing`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export const dayOffAdjustApi = {
  list: findList,
  totalCount: findTotalCount,
  create: createAdjust,
};

export const substituteAdjustApi = {
  list: findAlternativeList,
  totalCount: findAlternativeTotalCount,
  create: createAlternativeAdjust,
};
