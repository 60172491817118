import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../components/layout/EuiHeader';
import EuiBody from '../../../components/layout/EuiBody';
import EuiSetting from '../../../components/layout/EuiSetting';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import { RootState, useAppDispatch } from '../../../groupware-webapp/app/store';
import { formActions } from '../../stores/systemlink/form';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../groupware-common/utils';
import SplitUnselected from '../../../components/split/SplitUnselected';
import DeleteConfirmation from '../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../components/alert/FeedBack';
import SystemLinkFormList from './SystemLinkFormList';
import SystemLinkFormEdit from './SystemLinkFormEdit';
import SystemLinkFormFolder from './SystemLinkFormFolder';

function SystemLinkFormContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathmap = getPathMap('/*/*/*', props.pathname);
  const pathParams = getPathParams(`/*/*/*/:p1/:p2`, props.pathname);
  const propsFolderId = b62(pathParams.p1);
  const propsFormId = b62(pathParams.p2);
  const queryParams = getQueryParams(props.search);

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [queryParams.pageNo, queryParams.rowsPerPage, queryParams.searchWord]);

  const dispatch = useAppDispatch();
  const categories = useSelector(
    (state: RootState) => state.systemlink.preferences.category,
  );
  const folderList = useSelector(
    (state: RootState) => state.systemlink.form.folder.list.data.items,
  );

  const folderItems = folderList
    .map(({ id, parentId, seq, name: text, updateAt }) => {
      return {
        id,
        parentId,
        seq,
        text,
        strings: hangul.d(text),
        icon: 'folder' as const,
        updateAt,
      };
    })
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  const formList = useSelector(
    (state: RootState) => state.systemlink.form.items,
  );
  const totalCount = useSelector(
    (state: RootState) => state.systemlink.form.totalCount,
  );

  const formItems = formList.map((a) => {
    return {
      checked: a.checked,
      id: a.id,
      category: folderList.find((b) => b.id === a.folderId)?.name ?? '',
      subject: a.name,
      createAt: a.createAt,
      isEnable: a.isEnable,
    };
  });

  const [validation, setValidation] = useState('');
  const { pageNo = 1, rowsPerPage = 15 } = queryParams;

  const handleSelectedFolder = (id: number) => {
    dispatch(
      formActions.list({
        folderId: id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search: createQueryString({ pageNo: 1, rowsPerPage }),
        },
      }),
    );
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleSnackbarClose = () => {
    setValidation('');
  };

  /** 양식 저장. */
  const handleFormSave = (
    arg:
      | {
          id?: undefined;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
        }
      | {
          id: number;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
          updateAt: string;
        },
  ) => {
    const { folderId } = arg;

    const params = { ...queryParams };
    delete params.dialogMode;
    delete params.dialogType;
    const route = {
      pathname: `${pathmap}/${b62(folderId)}`,
      search: arg.id
        ? getQueryParams(queryParams)
        : createQueryString({ pageNo: undefined }, params),
    };

    const data = {
      ...arg,
      isEnable: arg.status === 1,
    };

    if (data.id === undefined) dispatch(formActions.create({ ...data, route }));
    else dispatch(formActions.update({ ...data, route }));
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    const { dialogType, dialogMode } = queryParams;

    if (dialogType === 'form' && dialogMode === 'create') {
      return (
        <SystemLinkFormEdit
          folders={folderItems}
          folderId={propsFolderId}
          onClose={handleCloseDialog}
          onSave={handleFormSave}
        />
      );
    }
    if (
      dialogType === 'form' &&
      dialogMode === 'update' &&
      propsFolderId !== undefined &&
      propsFormId !== undefined
    ) {
      return (
        <SystemLinkFormEdit
          folders={folderItems}
          folderId={propsFolderId}
          id={propsFormId}
          onSave={handleFormSave}
          onClose={() => {
            const params = { ...queryParams };
            delete params.dialogMode;
            delete params.dialogType;
            dispatch(
              sessionActions.setRoute({
                pathname: `${pathmap}/${b62(propsFolderId)}`,
                search: createQueryString(params),
              }),
            );
          }}
        />
      );
    }
    if (dialogType === 'form' && dialogMode === 'delete') {
      const array = formList.filter(({ checked }) => checked);
      if (array.length === 0) return null;

      return (
        <DeleteConfirmation
          onSubmit={handleDeleteForm}
          onCancel={handleCloseDialog}
        >
          {array.length === 1 ? (
            <>
              <strong>&apos;{array[0].name}&apos;</strong> 을(를)
              삭제하시겠습니까?
            </>
          ) : (
            <>
              <strong>&apos;{array[0].name}&apos;</strong> 외 {array.length - 1}{' '}
              건을 삭제하시겠습니까?
            </>
          )}
        </DeleteConfirmation>
      );
    }

    return null;
  };

  /* 양식함 리스트 삭제 */
  const handleDeleteForm = () => {
    // console.log(`handleDeleteForm()`);

    const data = formList
      .filter(({ checked }) => checked)
      .map(({ id, updateAt }) => ({ id, updateAt }));
    if (data.length === 0) return;

    const params = { ...queryParams };
    delete params.dialogType;
    delete params.dialogMode;
    delete params.pageNo;
    const route = {
      pathname: props.pathname,
      search: createQueryString(params),
    };

    if (data.length === 1)
      dispatch(formActions.delete({ data: data[0], route }));
    else dispatch(formActions.delete({ data, route }));
  };

  /* 체크박스 이벤트 */
  const handleChangeCheckbox = (arg: {
    id: number | 'all';
    checked: boolean;
  }) => {
    dispatch(formActions.checked(arg));
  };

  const handleFolderListAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    // console.log(`handleFolderListAction(arg)`, arg);
    const { code } = arg;

    if (code === 'form/create')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'create' }));
  };

  // 모바일에서만 사용.
  const handleFormListClose = () => {
    const pathname = getPathMap('/*/*', props.pathname);
    dispatch(sessionActions.setRoute({ pathname }));
  };

  /** 양식 목록 개수 변경 */
  const handleFormListChangeRowsPerPage = (value: number) => {
    // console.log(`handleFormListChangeRowsPerPage(value: ${value})`);

    if (!propsFolderId) return;

    delete queryParams.pageNo;
    dispatch(
      formActions.list({
        folderId: propsFolderId,
        route: {
          pathname: `${pathmap}/${b62(propsFolderId)}`,
          search: createQueryString({ rowsPerPage: value }, queryParams),
        },
      }),
    );
  };

  const handleFormListSearch = (arg: { keyword: string; filter?: string }) => {
    // console.log(`handleFormListSearch(arg)`, arg);

    if (!propsFolderId) return;

    const { keyword } = arg;
    dispatch(
      formActions.list({
        folderId: propsFolderId,
        route: {
          pathname: `${pathmap}/${b62(propsFolderId)}`,
          search: createQueryString(
            {
              pageNo: 1,
              searchCode: 'NAME',
              searchWord: keyword,
            },
            queryParams,
          ),
        },
      }),
    );
  };

  /** 양식 목록 액션. */
  const handleFormListAction = (arg: { code: string; id?: number }) => {
    // console.log(`handleFormListAction(arg)`, arg);
    const { code, id } = arg;
    if (code === 'form/create')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'create' }));
    if (code === 'subject' && id !== undefined) {
      dispatch(
        sessionActions.setRoute({
          pathname: `${props.pathname}/${b62(id)}`,
          search: createQueryString(
            { dialogType: 'form', dialogMode: 'update' },
            queryParams,
          ),
        }),
      );
    }
    if (code === 'form/delete')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'delete' }));
    // 선택취소 이벤트
    if (code === 'cancelSelected') {
      dispatch(formActions.checked({ id: 'all', checked: false }));
    }

    // 이전 페이지.
    if (code === 'prePage') {
      if (!propsFolderId) return;

      const params = { ...queryParams, pageNo: pageNo - 1 };

      dispatch(
        formActions.list({
          folderId: propsFolderId,
          route: {
            pathname: `${pathmap}/${b62(propsFolderId)}`,
            search: createQueryString(params),
          },
        }),
      );
    }
    // 다음 페이지.
    if (code === 'nextPage') {
      if (!propsFolderId) return;

      const params = { ...queryParams, pageNo: pageNo + 1 };

      dispatch(
        formActions.list({
          folderId: propsFolderId,
          route: {
            pathname: `${pathmap}/${b62(propsFolderId)}`,
            search: createQueryString(params),
          },
        }),
      );
    }
  };

  const title = categories.find((a) => a.id === 6002)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <SystemLinkFormFolder
              items={folderItems}
              selectedId={propsFolderId}
              onSelected={handleSelectedFolder}
              onClick={handleFolderListAction}
            />
          </EuiSetting.Left>
          {propsFolderId === undefined ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleFormListClose}>
              <SystemLinkFormList
                scroll={scrollbar}
                title={
                  folderList.find((a) => a.id === propsFolderId)?.name ?? ''
                }
                items={formItems}
                onSearch={handleFormListSearch}
                onClick={handleFormListAction}
                onChangeChecked={handleChangeCheckbox}
                pagination={{
                  no: pageNo,
                  row: rowsPerPage,
                  total: totalCount,
                  onChangeRow: handleFormListChangeRowsPerPage,
                }}
              />
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default React.memo(SystemLinkFormContainer);
