import React from 'react';
import Chip from '../../../../../components/chip/Chip';

function CalendarItem(props: {
  initialCalendar?: boolean;
  label: React.ReactNode | string;
  status?: boolean;
  color: string;
  width?: string;
  className?: string;
  expanded?: boolean;
  itemDisabled?: boolean; // 선택 불가능한 아이템인 경우 true.
  disabled?: boolean;
  selected?: boolean;
  childrenCount?: number;
  id: number;
  labelChecked?: boolean;
  onClick(id: number, checked: boolean): void;
  onSettingClick?(id: number, event: React.MouseEvent): void;
  onDoubleClick?(event: React.MouseEvent): void;
  onToggleExpanded?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  children?: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-nav-item calendar-item';
  if (props.selected) classname += ' selected';
  if (props.labelChecked !== undefined) classname += ' labelChecked';
  if (props.className) classname += ` ${props.className}`;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    props.onClick(props.id, !props.labelChecked);
  };

  const handleSettingClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onSettingClick !== undefined)
      props.onSettingClick(props.id, event);
  };

  let style: React.CSSProperties | undefined;
  if (props.width) style = { width: props.width };
  if (props.itemDisabled)
    style = {
      ...style,
      color: 'var(--disabled-text-color)',
      cursor: 'default',
    };

  return (
    <a
      style={style}
      className={classname}
      onClick={props.itemDisabled ? undefined : handleClick}
      onDoubleClick={props.onDoubleClick}
      aria-disabled={props.disabled}
    >
      {/* 펼치기 접기 토글 */}
      {props.onToggleExpanded &&
        props.childrenCount !== undefined &&
        props.childrenCount > 0 && (
          <button
            type="button"
            style={props.itemDisabled ? { color: 'initial' } : undefined}
            className="nav-toggle"
            onClick={props.onToggleExpanded}
            aria-expanded={props.expanded}
          >
            <span>{props.expanded ? '메뉴 접기' : '메뉴 펼치기'}</span>
          </button>
        )}
      {/* 체크박스 */}
      <div
        className="nav-label-checkbox"
        data-checked={props.labelChecked}
        style={
          props.status !== undefined
            ? {
                backgroundColor: props.status
                  ? props.color
                  : 'var(--body-bg-color)',
                border: `2px solid ${props.color}`,
              }
            : {
                backgroundColor: props.color,
                border: `2px solid ${props.color}`,
              }
        }
      />
      {/* 라벨 */}
      <span
        style={{ paddingLeft: '30px', display: 'flex' }}
        className="nav-label"
      >
        {props.label}
        {props.initialCalendar && (
          <div style={{ marginLeft: '4px' }}>
            <Chip size="xs" label="기본" />
          </div>
        )}
      </span>
      {/* children */}
      {props.children}
      <button
        style={{ color: 'var(--body-text-color)', padding: '24px' }}
        type="button"
        className="nav-label-setting"
        onClick={handleSettingClick}
        aria-label="setting"
      />
    </a>
  );
}

export default CalendarItem;
