import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { preferencesActions } from '../../../../stores/approval/preferences';

function ApprovalSettingRoute({ pathname }: { pathname: string }): null {
  // console.log(`ApprovalSettingRoute.render(props)`, { pathname });

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    Promise.all([
      dispatch(preferencesActions.findBasic()),
      dispatch(preferencesActions.findAdministrators()),
    ]).then(() => {
      if (mount)
        dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);

  return null;
}

export default ApprovalSettingRoute;
