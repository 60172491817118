/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg, NoLoading } from '../../../groupware-common/types';
import { IconType } from '../../../groupware-common/types/icon';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../../groupware-common/utils';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import { ReadingPaneMode } from '../../../groupware-webapp/stores/types';
import documentApi, { StarReturnType } from '../../apis/document/v1/document';

const name = 'document/documents';

interface CategoryItem {
  type: 'status' | 'organization' | 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}

/** 목록 설정 객체 */
export interface FolderSetting {
  version: '1.0';
  density: 'wide' | 'narrow' | 'normal'; // 화면 밀도.
  readingPaneMode: ReadingPaneMode; // 보기 모드.
  columns: string[]; // 노출하는 목록 컬럼 이름.
  rowsPerPage: number; // 목록 개수.
}

/** 활동 아이템 */
export interface ActivityItem {
  id: number; // 문서 아이디.
  option: string; // 'PC': PC저장, 'FILE': 파일저장, 'DELETE': 삭제, 'RESTORE': 복원
  createAt: string; // 수정일.
}

/** 버전 아이템 */
export interface VersionItem {
  id: number; // 버전 아이디.
  versionSeq: number; // 문서 버전(1부터 백엔드 오름차 채번)
  changeReason: string; // 변경 사유
  creatorId: number; // 생성자 아이디
  createAt: string; // 수정 시간
}

/** 좋아요 아이템 */
export interface LikeItme {
  likeId: number; // 좋아요한 아이디
  createAt: string; // 생성일
}

/** 댓글 및 답글 뷰. */
export interface CommentItem {
  id: number; // 아이디
  postId: number; // 문서 아이디
  parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
  employeeId: number; // 직원 아이디 - employee.id
  comment: string; // 내용
  isAuthorized: boolean; // 수정 권한 여부(작성자 or 해당 게시함 관리자 or 게시 모듈 관리자)
  isDeleted: boolean; // 삭제 여부
  updateAt: string; // 수정 시간
}

/** 첨부 파일 */
export interface AttachFile {
  id: number;
  fileId: number;
  seq: number;
  versionSeq: number; // 첨부파일 버전 아이디.
  name: string;
  size: number;
  updateAt: string;
}

/** 문서 아이템 */
export interface DocumentItem {
  checked: boolean;
  id: number; // 아이디.
  versionSeq: number; // 현재 문서 버전 아이디.
  listId: string; // 문서 아이디.
  isNotice: boolean; // 상단고정 여부.
  folderId: number; // 문서함 아이디.
  subject: string; // 제목
  views: number; // 조회수
  likes: number; // 좋아요 수
  comments: number; // 의견 수
  isStarred: boolean; // 중요 표시 여부
  updaterId: number; // 수정자 아이디
  creatorId: number; // 작성자 아이디
  deleterId?: number; // 삭제자 아이디
  createAt: string; // 작성일
  updateAt: string; // 수정일
  checkoutAt: string; // 체크아웃일
  attachedSummaryFiles: {
    fileId: number; // 파일 자체 내부코드
    name: string; // 파일 명
  }[];
  status: string; // 상태 (ACTIVE, CHECKOUT, TEMPORARY, DELETED)
}

/** 문서 보기 */
export interface DocumentViewItem {
  id: number; // 문서 아이디.
  currentVersionSeq: number; // 현재 버전 순번.
  folderId: number; // 폴더 아이디.
  isAuthorized: boolean; // 수정 권한 여부(작성자 or 해당 문서함 관리자 or 문서 모듈 관리자)
  isStarred: boolean; // 조회자 중요표시 여부
  isLiked: boolean; // 조회자 좋아요 표시 여부
  isViewed: boolean; // 조회자 읽음 표시 여부
  status: string; // 게시글 상태 상수(ACTIVE,TEMPORARY,DELETED)
  subject: string; // 제목
  summary: string; // 문서 요약
  contents: string; // 내용
  isNotified: boolean; // 공지(상단 노출) 여부
  isCheckoutInMine: boolean; // 사용자의 체크아웃 여부
  notifyStartDate: string; // 공지 시작일
  notifyEndDate: string; // 공지 종료일
  views: number; // 조회수
  likes: number; // 좋아요 수
  comments: number; // 댓글 수
  commentData?: CommentItem[]; // 댓글 객체.
  attachedFiles?: AttachFile[]; // 첨부 파일 객체.
  retentionPeriod: string; // 보존 기간
  employeeId: number; // ??
  deleterId: number; // 삭제자 아이디
  creatorId: number; // 작성자 아이디
  updaterId: number; // 수정자 아이디.
  createAt: string; // 생성 시간
  updateAt: string; // 수정 시간
  prev?: {
    companyId?: number; // 회사 아이디.
    id: number; // 문서 아이디.
  };
  next?: {
    companyId?: number; // 회사 아이디.
    id: number; // 문서 아이디.
  };
}

/** 기본 폴더 */
const categories: CategoryItem[] = [
  { type: 'status', id: 'all', name: '모든문서함', icon: 'archive' },
  { type: 'status', id: 'importance', name: '중요문서함', icon: 'star' },
  { type: 'status', id: 'temp', name: '임시보관함', icon: 'document' },
  {
    type: 'status',
    id: 'checkout',
    name: '체크아웃 문서',
    icon: 'document-update',
  },
  // 관리자 - 메뉴.
  { type: 'setting', id: 6001, name: '기본설정', icon: 'folder' },
  { type: 'setting', id: 6002, name: '문서함 관리', icon: 'folder' },
  { type: 'setting', id: 6003, name: '일괄권한 설정', icon: 'folder' },
  { type: 'setting', id: 6004, name: '삭제함 관리', icon: 'folder' },
  { type: 'setting', id: 6005, name: '양식관리', icon: 'folder' },
  { type: 'setting', id: 6006, name: '체크아웃 관리', icon: 'folder' },
];

interface State {
  category: CategoryItem[];
  displayDensity: 'wide' | 'normal' | 'narrow';
  displayFileVisible: 'visible' | 'invisible';
  readingPaneMode: ReadingPaneMode;
  folderSetting: {
    setting?: FolderSetting;
    updateAt: string;
  };
  list: {
    items: DocumentItem[];
    totalCount: number;
  };
  adminconsole: {
    list: {
      items: DocumentItem[];
      totalCount: number;
    };
  };
  view: DocumentViewItem | undefined;
  version: {
    list: VersionItem[];
    view: DocumentViewItem | undefined; // DocumentContent | undefined;
  };

  printView: DocumentViewItem | undefined;
}

const initialState: State = {
  category: categories,
  displayDensity: 'normal',
  displayFileVisible: 'visible',
  readingPaneMode: 'list',
  folderSetting: {
    updateAt: '1000-01-01T00:00:00',
  },

  list: {
    items: [],
    totalCount: 0,
  },
  adminconsole: {
    list: {
      items: [],
      totalCount: 0,
    },
  },
  version: {
    list: [],
    view: undefined,
  },

  view: undefined,
  printView: undefined,
};

/** 문서 저장 */
const createDocument = createAsyncThunk(
  `${name}/createDocument`,
  async (
    arg: {
      param: {
        folderId: number;
        subject: string;
        summary: string;
        contents: string;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          fileId: number;
          path: string;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
          copy?: boolean;
        }[];
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { param } = arg;
      const result = await documentApi.create(param);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 수정 */
const updateDocument = createAsyncThunk(
  `${name}/updateDocument`,
  async (
    arg: {
      param: {
        id: number;
        versionSeq: number;
        folderId: number;
        subject: string;
        summary: string;
        contents: string;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        isVersion?: boolean;
        changeReason?: string;
        attachedFiles?: {
          fileId: number;
          path: string;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
          copy?: boolean;
        }[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { param } = arg;
      const result = await documentApi.update(param);
      const { readingPaneMode } = (getState() as RootState).document.documents;
      const isSplitView =
        readingPaneMode === 'vertical' || readingPaneMode === 'horizontal';
      if (arg.location && isSplitView) {
        const { folderId } = getPathParams<{ folderId: string }>(
          `/*/:folderId`,
          arg.location.pathname,
        );
        if (folderId !== 'temp')
          if (folderId === 'checkout') {
            dispatch(
              documentsActions.list({
                folderId,
                isStarred: false,
                isTemporary: false,
                isCheckout: true,
              }),
            );
          } else {
            dispatch(
              documentsActions.list({
                folderId,
                isStarred: false,
                isTemporary: false,
                isCheckout: false,
              }),
            );
          }
      }
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 삭제 */
const deleteDocument = createAsyncThunk(
  `${name}/deleteDocument`,
  async (
    arg: {
      data:
        | {
            id: number;
            updateAt: string;
          }
        | {
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const sessionRoute = (getState() as RootState).session.route;
      const { folderId } = getPathParams<{ folderId?: string }>(
        '/*/:folderId',
        arg.route ? arg.route.pathname : sessionRoute.pathname,
      );
      if (Array.isArray(arg.data)) {
        const result =
          folderId === 'temp'
            ? await documentApi.forceDelete(arg.data)
            : await documentApi.delete(arg.data);
        if (Array.isArray(result) && result.length !== arg.data.length) {
          await dispatch(sessionActions.setDialog());
          dispatch(
            findList({
              folderId,
              isStarred: folderId === 'importance',
              isTemporary: folderId === 'temp',
              isCheckout: folderId === 'checkout',
            }),
          );
          return rejectWithValue(
            appError({
              error:
                '문서 삭제 중 오류가 발생하여 일부 문서는 삭제 실패하였습니다.',
            }),
          );
        }
      } else {
        await documentApi.delete(arg.data);
      }
      if (arg.route) {
        dispatch(
          findList({
            folderId,
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
            isCheckout: folderId === 'checkout',
          }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 이동 */
const moveDocument = createAsyncThunk(
  `${name}/moveDocument`,
  async (
    arg: {
      data:
        | {
            folderId: number;
            id: number;
            updateAt: string;
          }
        | {
            folderId: number;
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await documentApi.move(arg.data);
      if (arg.route) {
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          arg.route.pathname,
        );
        dispatch(
          findList({
            folderId,
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
            isCheckout: folderId === 'checkout',
          }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 체크아웃 */
const checkout = createAsyncThunk(
  `${name}/checkout`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
        changeReason?: string;
        isCheckout: boolean;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await documentApi.checkout(arg.data);
      if (arg.location) {
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          arg.location.pathname,
        );
        dispatch(
          findList({
            folderId,
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
            isCheckout: folderId === 'checkout',
          }),
        );
      }
      return { ...response, isCheckout: arg.data.isCheckout };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 복사 */
const copyDocument = createAsyncThunk(
  `${name}/copyDocument`,
  async (
    arg: {
      folderId: number;
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { folderId, id, updateAt } = arg;
      await documentApi.copy({ id, folderId, updateAt });
      // const list = await documentApi.list({ id: folderId });
      // return { list };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 임시저장 */
const temporary = createAsyncThunk(
  `${name}/temporary`,
  async (
    arg: {
      param: {
        id?: number;
        folderId: number;
        subject: string;
        summary: string;
        contents: string;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          fileId: number;
          path: string;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
          copy?: boolean;
        }[];
        updateAt?: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { param } = arg;
      const result = await documentApi.temporary({
        ...param,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 임시저장 문서 활성화 */
const temporaryActive = createAsyncThunk(
  `${name}/temporaryActive`,
  async (
    arg: {
      param: {
        id: number;
        folderId: number;
        subject: string;
        summary: string;
        contents: string;
        isNotified: boolean;
        notifyStartDate?: string;
        notifyEndDate?: string;
        retentionPeriod: string;
        attachedFiles?: {
          fileId: number;
          path: string;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
          copy?: boolean;
        }[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { param } = arg;
      const result = await documentApi.temporaryActive({
        id: param.id,
        folderId: param.folderId,
        subject: param.subject,
        summary: param.summary,
        contents: param.contents,
        isNotified: param.isNotified,
        notifyStartDate: param.notifyStartDate,
        notifyEndDate: param.notifyEndDate,
        retentionPeriod: param.retentionPeriod,
        attachedFiles: param.attachedFiles?.map((a) => ({
          fileId: a.fileId,
          path: a.path,
          name: a.name,
          seq: a.seq,
          size: a.size,
          delete: a.delete,
          copy: a.copy,
        })),
        updateAt: param.updateAt,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 리스트 조회 */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      folderId?: string;
      isStarred: boolean;
      isTemporary: boolean;
      isCheckout: boolean;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      let folderid: number | undefined;
      let folderSetting: {
        setting?: FolderSetting;
        updateAt: string;
      } = {
        updateAt: '1000-01-01T00:00:00',
      };
      if (!arg.folderId || arg.folderId === 'all') {
        const allSetting = await documentApi.allFolderSetting(employeeId);
        folderSetting =
          allSetting.configs !== ''
            ? {
                setting: JSON.parse(allSetting.configs),
                updateAt: allSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: allSetting.updateAt,
              };
        folderid = undefined;
      } else if (arg.folderId === 'temp') {
        folderSetting = {
          updateAt: '1000-01-01T00:00:00',
        };
        folderid = 0;
      } else if (arg.folderId === 'checkout') {
        folderid = 0;
        const importSetting = await documentApi.checkoutSetting(employeeId);
        folderSetting =
          importSetting.configs !== ''
            ? {
                setting: JSON.parse(importSetting.configs),
                updateAt: importSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: importSetting.updateAt,
              };
      } else if (arg.folderId === 'importance') {
        folderid = 0;
        const importSetting = await documentApi.starredSetting(employeeId);
        folderSetting =
          importSetting.configs !== ''
            ? {
                setting: JSON.parse(importSetting.configs),
                updateAt: importSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: importSetting.updateAt,
              };
      } else {
        const defaultSetting = await documentApi.folderSetting({
          folderid: b62(arg.folderId),
          employeeid: employeeId,
        });
        folderSetting =
          defaultSetting.configs !== ''
            ? {
                setting: JSON.parse(defaultSetting.configs),
                updateAt: defaultSetting.updateAt,
              }
            : {
                ...folderSetting,
                updateAt: defaultSetting.updateAt,
              };
        folderid = b62(arg.folderId);
      }

      const { setting } = folderSetting;
      const queryParams = getQueryParams(arg.route?.search || '');
      const list = await documentApi.list({
        folderid,
        isStarred: arg.isStarred,
        isTemporary: arg.isTemporary,
        isCheckout: arg.isCheckout,
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? setting?.rowsPerPage ?? 15,
      });
      const totalCount = await documentApi.totalCount({
        folderid,
        isStarred: arg.isStarred,
        isTemporary: arg.isTemporary,
        isCheckout: arg.isCheckout,
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      });

      let displayDensity: 'wide' | 'narrow' | 'normal' | undefined;
      if (arg.route && !queryParams.pageNo)
        displayDensity = setting ? setting.density : 'normal';
      // let displayFileVisible: 'visible' | 'invisible' | undefined;
      // if (arg.route && !queryParams.pageNo)
      //   displayFileVisible = setting ? setting.fileVisible : 'normal';

      return {
        data: {
          list: [
            ...list.map((a) => ({
              checked: false,
              isNotice: false,
              ...a,
              listId: `${a.id}`,
              deleterId: a.deleterId === null ? undefined : a.deleterId,
            })),
          ],
          totalCount,
          readingPaneMode: setting?.readingPaneMode ?? 'list',
          displayDensity,
          folderSetting,
        },
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 조회 */
const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { id: number; updateAt?: string } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { id, updateAt } = arg;
      const pathname =
        arg.route?.pathname ?? (getState() as RootState).session.route.pathname;
      const search =
        arg.route?.search ?? (getState() as RootState).session.route.search;
      const list = (getState() as RootState).document.documents.list.items;
      const { folderId } = getPathParams<{ folderId?: string }>(
        '/*/:folderId',
        pathname,
      );
      const queryParams = getQueryParams(search);

      let commentData: CommentItem[] | undefined;
      let prev: { companyId: number; id: number } | undefined;
      let next: { companyId: number; id: number } | undefined;
      let versions: VersionItem[] = [];
      let response: DocumentViewItem | undefined;
      if (folderId === 'temp') {
        const view = await documentApi.findReadOnlyView({
          id,
          updateAt,
        });
        response = {
          ...view,
          isStarred: false,
          isLiked: false,
          isAuthorized: false,
          deleterId: 0,
        };
      } else {
        response = await documentApi.view({ id, updateAt });
        versions = await documentApi.versionList({ id });
      }

      // 공지글이 아닌 경우, 임시보관함 글 조회가 아닌 경우 이전, 다음아이디 조회.
      if (!queryParams.type && folderId !== 'temp') {
        prev = await documentApi.prev({
          folderid: folderId,
          updateat: folderId === 'checkout' ? undefined : response.updateAt,
          checkoutat: list.find((a) => a.id === id)?.checkoutAt ?? '',
          startdate: queryParams.startDate,
          enddate: queryParams.endDate,
          searchcode: queryParams.searchCode,
          searchword:
            queryParams.directoryFilter === 'true'
              ? queryParams.directoryKeyword
              : queryParams.searchWord,
          isdirectoryselected: queryParams.directoryFilter === 'true',
        });
        next = await documentApi.next({
          folderid: folderId,
          checkoutat: list.find((a) => a.id === id)?.checkoutAt ?? '',
          updateat: folderId === 'checkout' ? undefined : response.updateAt,
          startdate: queryParams.startDate,
          enddate: queryParams.endDate,
          searchcode: queryParams.searchCode,
          searchword:
            queryParams.directoryFilter === 'true'
              ? queryParams.directoryKeyword
              : queryParams.searchWord,
          isdirectoryselected: queryParams.directoryFilter === 'true',
        });
      }
      if (response.comments)
        commentData = await documentApi.findComments({ postid: id });

      const attachedFiles: AttachFile[] = [];
      if (response.attachedFiles) {
        response.attachedFiles.forEach((a) => attachedFiles.push(a));
        attachedFiles.sort(
          (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
        );
      }
      return {
        view: {
          ...response,
          commentData,
          prev,
          next,
        },
        versions,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 인쇄 게시글 조회. */
const findPrintView = createAsyncThunk(
  `${name}/findPrintView`,
  async (
    arg: { id: number; updateAt?: string } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let commentData: CommentItem[] | undefined;
      const response = await documentApi.findReadOnlyView({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      if (response.comments > 0) {
        commentData = await documentApi.findComments({ postid: response.id });
      }
      const attachedFiles: AttachFile[] = [];
      if (response.attachedFiles) {
        response.attachedFiles.forEach((a) => attachedFiles.push(a));
        attachedFiles.sort(
          (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
        );
      }
      return {
        ...response,
        commentData,
        attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 좋아요 생성 */
const createLike = createAsyncThunk(
  `${name}/createLike`,
  async (
    arg: {
      documentId: number;
      employeeId: number;
    } & LocateArg &
      NoLoading,
    { rejectWithValue },
  ) => {
    try {
      const result = await documentApi.createLike(arg);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 좋아요 삭제 */
const deleteLike = createAsyncThunk(
  `${name}/deleteLike`,
  async (
    arg: {
      documentId: number;
      employeeId: number;
    } & LocateArg &
      NoLoading,
    { rejectWithValue },
  ) => {
    try {
      const result = await documentApi.deleteLike(arg);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 중요표시 */
const saveUnSaveStar = createAsyncThunk(
  `${name}/saveUnSaveStar`,
  async (
    arg: {
      star: boolean;
      id: number;
      employeeId: number;
    } & LocateArg &
      NoLoading,
    { rejectWithValue },
  ) => {
    try {
      const { star, id, employeeId } = arg;
      const response = await documentApi.saveUnSaveStar({
        star,
        id,
        employeeId,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 좋아요 표시 */
const saveUnSaveLike = createAsyncThunk(
  `${name}/saveUnSaveLike`,
  async (
    arg: {
      like: boolean;
      id: number;
      employeeId: number;
    } & LocateArg &
      NoLoading,
    { rejectWithValue },
  ) => {
    try {
      const { like, id, employeeId } = arg;
      const response = await documentApi.saveUnSaveLike({
        like,
        id,
        employeeId,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서 댓글 저장. */
const saveComment = createAsyncThunk(
  `${name}/saveComment`,
  async (
    arg: {
      postId: number;
      parentId: number;
      employeeId: number;
      comment: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const { IPv4 } = await ipData.json();
      await documentApi.createComment({
        postId: arg.postId,
        parentId: arg.parentId,
        employeeId: arg.employeeId,
        comment: arg.comment,
        ip: IPv4,
      });
      const commentData = await documentApi.findComments({
        postid: arg.postId,
      });
      return commentData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 댓글 수정. */
const updateComment = createAsyncThunk(
  `${name}/updateComment`,
  async (
    arg: {
      id: number;
      postId: number;
      comment: string;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const { IPv4 } = await ipData.json();
      await documentApi.updateComment({
        id: arg.id,
        postId: arg.postId,
        comment: arg.comment,
        ip: IPv4,
        updateAt: arg.updateAt,
      });
      const commentData = await documentApi.findComments({
        postid: arg.postId,
      });
      return commentData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 게시글 댓글 삭제. */
const deleteComment = createAsyncThunk(
  `${name}/deleteComment`,
  async (
    arg: {
      id: number;
      postId: number;
      parentId: number;
      employeeId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      await documentApi.deleteComment({
        id: arg.id,
        postId: arg.postId,
        parentId: arg.parentId,
        employeeId: arg.employeeId,
        updateAt: arg.updateAt,
      });

      const commentData = await documentApi.findComments({
        postid: arg.postId,
      });
      return commentData;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 문서함 목록 설정 기준 저장. */
const saveFolderSetting = createAsyncThunk(
  `${name}/saveFolderSetting`,
  async (
    arg: {
      folderId?: string;
      setting: FolderSetting;
      updateAt: string;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      const { folderId, updateAt } = arg;
      const configs = JSON.stringify(arg.setting);
      if (!folderId || folderId === 'all') {
        const saveData = { employeeId, configs, updateAt };
        const result = documentApi.saveAllFolderSetting(saveData);
        return result;
      }
      if (folderId === 'importance') {
        const saveData = { employeeId, configs, updateAt };
        const result = documentApi.saveStarredSetting(saveData);
        return result;
      }
      if (folderId === 'checkout') {
        const saveData = { employeeId, configs, updateAt };
        const result = documentApi.saveCheckoutSetting(saveData);
        return result;
      }
      if (folderId === 'temp') {
        return {
          configs: '',
          updateAt: '1000-01-01T00:00:00',
        };
      }
      const saveData = {
        folderId: b62(folderId),
        employeeId,
        configs,
        updateAt,
      };
      const result = documentApi.saveFolderSetting(saveData);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 체크아웃 리스트 조회. */
const findCheckoutList = createAsyncThunk(
  `${name}/findCheckoutList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const queryParams = getQueryParams(arg.search);
      const list = await documentApi.findCheckoutList({
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 15,
      });
      const totalCount = await documentApi.findCheckoutTotalCount({
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      });
      return {
        list: list.map((a) => ({
          checked: false,
          isNotice: false,
          ...a,
          listId: `${a.id}`,
          deleterId: a.deleterId === null ? undefined : a.deleterId,
        })),
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 보기 조회. */
const findAdminView = createAsyncThunk(
  `${name}/findAdminView`,
  async (
    arg: { id: number; updateAt?: string } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { id, updateAt } = arg;
      const pathname =
        arg.route?.pathname ?? (getState() as RootState).session.route.pathname;
      const search =
        arg.route?.search ?? (getState() as RootState).session.route.search;
      const list = (getState() as RootState).document.documents.adminconsole
        .list.items;
      const { folderId } = getPathParams<{ root?: string; folderId?: string }>(
        '/*/:folderId',
        pathname,
      );
      const queryParams = getQueryParams(search);

      const view = await documentApi.findReadOnlyView({
        id,
        updateAt,
      });
      const response = {
        ...view,
        isStarred: false,
        isLiked: false,
        isAuthorized: false,
        deleterId: 0,
      };
      const versions = await documentApi.versionList({ id });

      const prev = await documentApi.prev({
        folderid: folderId,
        isAdmin: true,
        checkoutat: list.find((a) => a.id === id)?.checkoutAt ?? '',
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      });
      const next = await documentApi.next({
        folderid: folderId,
        isAdmin: true,
        checkoutat: list.find((a) => a.id === id)?.checkoutAt ?? '',
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter === 'true'
            ? queryParams.directoryKeyword
            : queryParams.searchWord,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      });

      return {
        view: {
          ...response,
          commentData: undefined,
          prev,
          next,
        },
        versions,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 문서 체크아웃 */
const adminconsoleCheckin = createAsyncThunk(
  `${name}/adminconsoleCheckin`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
        changeReason?: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await documentApi.adminconsoleCheckin(arg.data);
      dispatch(findCheckoutList({ search: arg.location?.pathname ?? '' }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const documentsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setReadingPaneMode(state, action: PayloadAction<ReadingPaneMode>) {
      if (state.readingPaneMode !== action.payload) {
        state.readingPaneMode = action.payload;

        if (state.readingPaneMode === 'list') {
          state.view = undefined;
          state.version.list = [];
          state.version.view = undefined;
        }
      }
    },
    checked(
      state,
      action: PayloadAction<{ itemId: string | 'all'; checked: boolean }>,
    ) {
      if (state.list) {
        if (action.payload.itemId === 'all') {
          state.list.items = state.list.items.map((x) => {
            if (x.isNotice) return { ...x, checked: false };
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
        } else {
          const index = state.list.items.findIndex(
            (x) => x.listId === action.payload.itemId,
          );
          if (index > -1) {
            state.list.items[index] = {
              ...state.list.items[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    adminconsoleChecked(
      state,
      action: PayloadAction<{ itemId: string | 'all'; checked: boolean }>,
    ) {
      if (state.list) {
        if (action.payload.itemId === 'all') {
          state.adminconsole.list.items = state.adminconsole.list.items.map(
            (x) => {
              if (x.checked === action.payload.checked) return x;
              return { ...x, checked: action.payload.checked };
            },
          );
        } else {
          const index = state.adminconsole.list.items.findIndex(
            (x) => x.listId === action.payload.itemId,
          );
          if (index > -1) {
            state.adminconsole.list.items[index] = {
              ...state.adminconsole.list.items[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    viewClear(state) {
      state.view = undefined;
      state.version.list = [];
      state.version.view = undefined;
    },
    displayDensity(state, action: PayloadAction<'wide' | 'normal' | 'narrow'>) {
      if (state.displayDensity !== action.payload)
        state.displayDensity = action.payload;
    },
    displayFileVisible(state, action: PayloadAction<'visible' | 'invisible'>) {
      if (state.displayFileVisible !== action.payload)
        state.displayFileVisible = action.payload;
    },
    printViewClear(state) {
      state.printView = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = undefined;
          state.version.list = [];
          state.version.view = undefined;

          state.list.items = payload.data.list;
          state.list.totalCount = payload.data.totalCount;

          state.folderSetting = payload.data.folderSetting;
          if (payload.data.readingPaneMode)
            state.readingPaneMode = payload.data.readingPaneMode;
          if (payload.data.displayDensity)
            state.displayDensity = payload.data.displayDensity;
        }
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = payload.view;
          state.list.items = state.list.items.map((a) => {
            if (a.id === payload.view.id)
              return {
                ...a,
                views: payload.view.views,
              };
            return a;
          });
          state.version.list = payload.versions;
        }
      })
      .addCase(saveComment.fulfilled, (state, { payload }) => {
        const { view } = state;
        if (payload !== undefined && state.view && view) {
          const comments = payload.filter((a) => !a.isDeleted).length;
          state.view = {
            ...state.view,
            comments,
            commentData: payload,
          };
          state.list.items = state.list.items.map((a) => {
            if (a.id === view.id)
              return {
                ...a,
                comments: a.comments + 1,
              };
            return a;
          });
        }
      })
      .addCase(updateComment.fulfilled, (state, { payload }) => {
        const { view } = state;
        if (payload !== undefined && state.view && view) {
          const comments = payload.filter((a) => !a.isDeleted).length;
          state.view = {
            ...state.view,
            comments,
            commentData: payload,
          };
        }
      })
      .addCase(deleteComment.fulfilled, (state, { payload }) => {
        const { view } = state;
        if (payload !== undefined && state.view && view) {
          const comments = payload.filter((a) => !a.isDeleted).length;
          state.view = {
            ...state.view,
            comments,
            commentData: payload,
          };
          state.list.items = state.list.items.map((a) => {
            if (a.id === view.id)
              return {
                ...a,
                comments: a.comments - 1,
              };
            return a;
          });
        }
      })
      .addCase(saveUnSaveStar.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const result = payload as StarReturnType;
          if (state.view && state.view.id === result.id)
            state.view = {
              ...state.view,
              isStarred: result.isStarred,
            };
          state.list.items = state.list.items.map((a) => {
            if (a.id === result.id)
              return {
                ...a,
                isStarred: result.isStarred,
              };
            return a;
          });
        }
      })
      .addCase(checkout.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          if (state.view && state.view.id === payload.id)
            state.view = {
              ...state.view,
              isCheckoutInMine: !!payload.isCheckout,
              status: payload.isCheckout ? 'CHECKOUT' : 'ACTIVE',
              updateAt: payload.updateAt,
            };
        }
      })
      .addCase(saveFolderSetting.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const { configs, updateAt } = payload;
          state.folderSetting = {
            setting: configs !== '' ? JSON.parse(configs) : undefined,
            updateAt,
          };
        }
      })
      .addCase(findPrintView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.printView = payload;
      })
      .addCase(findCheckoutList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = undefined;
          state.version.list = [];
          state.version.view = undefined;

          state.adminconsole.list.items = payload.list;
          state.adminconsole.list.totalCount = payload.totalCount;
        }
      })
      .addCase(findAdminView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.view = payload.view;
          state.adminconsole.list.items = state.adminconsole.list.items.map(
            (a) => {
              if (a.id === payload.view.id)
                return {
                  ...a,
                  views: payload.view.views,
                };
              return a;
            },
          );
          state.version.list = payload.versions;
        }
      });
  },
});

export default documentsSlice.reducer;

export const documentsActions = {
  checked: documentsSlice.actions.checked,
  setReadingPaneMode: documentsSlice.actions.setReadingPaneMode,
  displayDensity: documentsSlice.actions.displayDensity,
  displayFileVisible: documentsSlice.actions.displayFileVisible,
  list: findList,

  view: findView,
  viewClear: documentsSlice.actions.viewClear,
  printView: findPrintView,
  printViewClear: documentsSlice.actions.printViewClear,

  adminconsoleChecked: documentsSlice.actions.adminconsoleChecked,
  adminconsoleCheckin,
  checkoutList: findCheckoutList,
  adminView: findAdminView,

  create: createDocument,
  update: updateDocument,
  delete: deleteDocument,
  copy: copyDocument,
  move: moveDocument,
  checkout,

  temporary,
  temporaryActive,

  saveComment,
  deleteComment,
  updateComment,

  saveUnSaveStar,
  saveUnSaveLike,
  like: createLike,
  disLike: deleteLike,

  saveFolderSetting,
};
