import axios from 'axios';
import {
  apiError,
  EntityKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**  연차생성기준 조회 객체 */
interface AttendancePreferencesStandard {
  companyId: number; // 회사 키
  accountingStartDate: string; // 회계연도 시작일
  accountingEndDate: string; // 회계연도 종료일
  countsYearList: {
    workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
    isAutoCalculate: number; // 자동 계산 여부 (false 시 지정된 개수 부여)
    fixedCounts: number; // 연차 지정 개수
    updateAt: string; // 수정 날짜/시작
    lookupDeleteAt?: string; // 삭제 시 updateAt.
  }[];
  decimalHandlingType: number; // 소수점 처리 구분 (0: 내림, 1: 반올림, 2: 올림)
  useLeaveExcess: boolean; // 연차초과사용 사용여부
  updateAt: string; // 수정 날짜.
  leaveOccurType: number; // 연차 생성 기준 - (1 :입사일 기준 , 2 : 회계년도 기준)
}

/** 연차사용촉진알림 조회 객체 */
interface AttendanceDayOffAlertStandard {
  companyId: number;
  alertEmployeeId: number; // 촉진 알림 담당자
  alertFormId: number; // 연차촉진 알림 문서 양식 아이디
  workerPlanFormId: number; // 사용계획서 양식 아이디
  noticeFormId: number; // 지정일통보문서 양식 아이디
  updateAt: string; // 수정 날짜
  alertType?: number; // 연차촉진알림 상태값(0 : 사용안함 , 1 : 법적기준 , 2 : 자체 설정)
}

/** 노무수령거부통지 조회 객체. */
interface AttendancePreferencesReject {
  companyId: number; // 회사 아이디.
  laborRejectFormId: number; // 노무수령거부통지 양식 아이디.
  creatorId: number; // 생성자 아이디.
  updaterId: number; // 수정자 아이디.
  createAt: string; // 생성 날짜/시간.
  updateAt: string; // 수정 날짜/시간.
  laborRejectType: number; // 노무수령거부통지서 사용여부(0 : 사용안함 , 1 : 사용함)
}

/** 근태코드 객체 */
interface AttendanceCode {
  companyId: number;
  id: number;
  name: string;
  seq: number;
  operationType: number;
  creatorId: number;
  updaterId: number;
  createAt: string;
  updateAt: string;
}

/** 연차 생성 여부 조회. */
async function isExistsDayOff(standardDate: string): Promise<{
  companyId: number;
  isExists: boolean;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/occurs/exist`;
    const response = await axios.get(url, {
      headers,
      params: { standardDate },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차 생성 기준 값 조회. */
async function selectUserStandard(): Promise<{
  leaveOccurType: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/userconsole/leave/settings`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차기본설정 - 연차설정 저장값 조회. */
async function selectStandard(): Promise<AttendancePreferencesStandard> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/settings`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 연차기본설정 - 연차설정 초기값 조회.
 * [type] 연차 타입
 * [enter] 입사일자
 * [account] 회계일자
 *  */
async function initialStandard(
  type: 'enter' | 'account',
): Promise<AttendancePreferencesStandard> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/settings/initial/${type}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차기본설정 - 연차생성기준 저장. */
async function updateStandard(arg: {
  accountingStartDate?: string; // 회계 시작일
  accountingEndDate?: string; // 회계 종료일
  countsYearList: {
    workingYearCount: number; // 근속연수(현재 - 입사연도 + 1)
    isAutoCalculate: number; // 자동 계산 여부 (false 시 지정된 개수 부여)
    fixedCounts: number; // 연차 지정 개수
    updateAt: string | null; // 수정 날짜/시작
    lookupDeleteAt?: string; // 삭제 시 updateAt.
  }[];
  decimalHandlingType: number; // 소수정 처리 구분
  useMinusLeaves: boolean; // 마이너스 연차 적용 여부
  useLeaveExcess: boolean; // 연차 초과 사용 여부
  updateAt: string; // 수정 날짜
}): Promise<{
  companyId: number;
  id: number;
  subId: number;
  updateAt: number;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/settings`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차기본설정 - 노무수령거부통지 조회. */
async function selectRejection(): Promise<AttendancePreferencesReject> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/labor/reject/settings`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차기본설정 - 노무수령거부통지 저장. */
async function updateRejection(arg: {
  laborRejectFormId: number; // 노무수령거부통지 양식 아이디.
  updateAt: string; // 수정 날짜/시간
}): Promise<AttendancePreferencesReject> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/labor/reject/settings`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차기본설정 - 연차사용촉진알림 저장. */
async function updateNotice(arg: {
  alertType: number;
  alertEmployeeId?: number;
  alertFormId?: number;
  usePlanFormId?: number;
  planNotifyFormId?: number;
  updateAt: string;
}): Promise<AttendanceDayOffAlertStandard> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert`;
    const param = {
      alertEmployeeId: arg.alertEmployeeId,
      alertType: arg.alertType,
      alertFormId: arg.alertFormId,
      workerPlanFormId: arg.usePlanFormId,
      noticeFormId: arg.planNotifyFormId,
      updateAt: arg.updateAt,
    };
    const response = await axios.post(url, param, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차기본설정 - 연차사용촉진알림 조회. */
async function selectNotice(): Promise<AttendanceDayOffAlertStandard> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태수량기준 조회. */
async function findAttendanceBasic(): Promise<{
  companyId: number; // 회사 아이디
  expressionUnit: 'DAY' | 'MINUTE'; // 근태 표현 단위 (MINUTE : 분, DAY : 일)
  updateAt: string; // 수정 날짜/시간
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/preferences`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태수량기준 저장. */
async function updateAttendanceBasic(arg: {
  expressionUnit: 'DAY' | 'MINUTE';
  updateAt: string;
}): Promise<{
  companyId: number; // 회사 아이디
  expressionUnit: 'DAY' | 'MINUTE'; // 근태 표현 단위 (MINUTE : 분, DAY : 일)
  updateAt: string; // 수정 날짜/시간
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/preferences`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 캘린더 연동 근태 코드 목록 조회. */
async function findLinkedAttendanceCode(): Promise<
  {
    id: number;
    isEnable: boolean;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code/linkage/calendars`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 캘린더 연동 근태 코드 목록 조회. */
async function saveLinkedAttendanceCode(
  arg: {
    id: number;
    isEnable: boolean;
  }[],
): Promise<
  {
    id: number;
    isEnable: boolean;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code/linkage/calendars`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태코드 조회. */
async function findAttendanceCode(): Promise<
  {
    id: number;
    name: string;
    seq: number;
    operationType: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code/all`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태코드 등록. */
async function createAttendanceCode(arg: {
  name: string;
  operationType: number;
}): Promise<AttendanceCode> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태코드 수정. */
async function updateAttendanceCode(arg: {
  id: number;
  name: string;
  operationType: number;
  updateAt: string;
}): Promise<AttendanceCode> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태코드 순서변경. */
async function updateAttendanceCodeSeq(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    id: number;
    companyId: number;
    updateAt: string;
    seq: number;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태기본설정 - 근태코드 삭제. */
async function deleteAttendanceCode(arg: {
  id: number;
  updateAt: string;
}): Promise<EntityKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/code`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태 기본 근무 시간 조회. */
async function findDefaultWorktime(): Promise<{
  companyId: number;
  worktimeStart: string;
  worktimeEnd: string;
  resttimeStart: string;
  resttimeEnd: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/worktime/default`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const AttendancePreferencesApi = {
  isExistsDayOff,

  selectUserStandard,
  selectStandard,
  initialStandard,
  updateStandard,

  selectRejection,
  updateRejection,

  selectNotice,
  updateNotice,

  findAttendanceBasic,
  updateAttendanceBasic,

  linkedAttendanceCode: findLinkedAttendanceCode,
  saveLinkedAttendanceCode,

  findAttendanceCode,
  createAttendanceCode,
  updateAttendanceCode,
  updateAttendanceCodeSeq,
  deleteAttendanceCode,

  findDefaultWorktime,
};

export default AttendancePreferencesApi;
