export const IconTypeStrings = [
  '360',
  'accessibility',
  'address-book',
  'address',
  'adjust',
  'alarm-check',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-slash',
  'alarm',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'analytics',
  'android',
  'angle-double-down',
  'angle-double-left',
  'angle-double-right',
  'angle-double-up',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'apple',
  'archive',
  'arrow-circle-down-fill',
  'arrow-circle-down',
  'arrow-circle-left-fill',
  'arrow-circle-left',
  'arrow-circle-right-fill',
  'arrow-circle-right',
  'arrow-circle-up-fill',
  'arrow-circle-up',
  'arrow-down',
  'arrow-empty-down-fill',
  'arrow-empty-down',
  'arrow-empty-from-bottom-fill',
  'arrow-empty-from-bottom',
  'arrow-empty-from-left-fill',
  'arrow-empty-from-left',
  'arrow-empty-from-right-fill',
  'arrow-empty-from-right',
  'arrow-empty-from-top-fill',
  'arrow-empty-from-top',
  'arrow-empty-left-fill',
  'arrow-empty-left',
  'arrow-empty-right-fill',
  'arrow-empty-right',
  'arrow-empty-to-bottom-fill',
  'arrow-empty-to-bottom',
  'arrow-empty-to-left-fill',
  'arrow-empty-to-left',
  'arrow-empty-to-right-fill',
  'arrow-empty-to-right',
  'arrow-empty-to-top-fill',
  'arrow-empty-to-top',
  'arrow-empty-up-fill',
  'arrow-empty-up',
  'arrow-from-bottom',
  'arrow-from-center',
  'arrow-from-left',
  'arrow-from-right',
  'arrow-from-top',
  'arrow-left',
  'arrow-right',
  'arrow-square-down-fill',
  'arrow-square-down',
  'arrow-square-left-fill',
  'arrow-square-left',
  'arrow-square-right-fill',
  'arrow-square-right',
  'arrow-square-up-fill',
  'arrow-square-up',
  'arrow-to-bottom',
  'arrow-to-center',
  'arrow-to-left',
  'arrow-to-right',
  'arrow-to-top',
  'arrow-up',
  'arrows-h',
  'arrows-v',
  'arrows',
  'assessment-fill',
  'assessment',
  'at',
  'award',
  'backspace-fill',
  'backspace',
  'backward',
  'badge-check',
  'badge',
  'bag',
  'ban',
  'bar-cog',
  'barcode',
  'bars',
  'bell-exclamation',
  'bell-fill',
  'bell-on',
  'bell-slash-fill',
  'bell-slash',
  'bell',
  'block',
  'bluetooth',
  'bold',
  'bolt',
  'book-bookmark',
  'book-fill',
  'book-reading',
  'book-text',
  'book',
  'bookmark-fill',
  'bookmark',
  'books',
  'border-al',
  'border-bottom',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-style',
  'border-top',
  'box',
  'brackets-curly',
  'brackets',
  'briefcase-slash',
  'briefcase-time',
  'briefcase',
  'brush',
  'bug',
  'building',
  'bullhorn',
  'bus',
  'button',
  'cake',
  'calculation',
  'calendar-check-fill',
  'calendar-check',
  'calendar-day-fill',
  'calendar-day',
  'calendar-end-fill',
  'calendar-end',
  'calendar-exclamation-fill',
  'calendar-exclamation',
  'calendar-fill',
  'calendar-grid-fill',
  'calendar-grid',
  'calendar-heart-fill',
  'calendar-heart',
  'calendar-minus-fill',
  'calendar-minus',
  'calendar-next-fill',
  'calendar-next',
  'calendar-plus-fill',
  'calendar-plus',
  'calendar-prev-fill',
  'calendar-prev',
  'calendar-star-fill',
  'calendar-star',
  'calendar-start-fill',
  'calendar-start',
  'calendar-text-fill',
  'calendar-text',
  'calendar-tiems-fill',
  'calendar-tiems',
  'calendar-week-fill',
  'calendar-week',
  'calendar',
  'call-ended',
  'call-split',
  'camera-fill',
  'camera-focus',
  'camera-slash-fill',
  'camera-slash',
  'camera',
  'capacity-expansion',
  'car',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'carrier',
  'cctv',
  'chain',
  'chart-area',
  'chart-bubble',
  'chart-column',
  'chart-line-down',
  'chart-line-up',
  'chart-network',
  'chart-pie-fill',
  'chart-pie',
  'chart-scatter',
  'check-all',
  'check-circle-fill',
  'check-circle-todo',
  'check-circle',
  'check-small',
  'check-square-fill',
  'check-square',
  'check-todo',
  'check',
  'cheese',
  'chevron-circle-down-fill',
  'chevron-circle-down',
  'chevron-circle-left-fill',
  'chevron-circle-left',
  'chevron-circle-right-fill',
  'chevron-circle-right',
  'chevron-circle-up-fill',
  'chevron-circle-up',
  'chevron-double-down',
  'chevron-double-left',
  'chevron-double-right',
  'chevron-double-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-square-down-fill',
  'chevron-square-down',
  'chevron-square-left-fill',
  'chevron-square-left',
  'chevron-square-right-fill',
  'chevron-square-right',
  'chevron-square-up-fill',
  'chevron-square-up',
  'chevron-up',
  'child',
  'circle-fill',
  'circle-ring',
  'circle',
  'city',
  'class',
  'clear-all',
  'clipboard-check-fill',
  'clipboard-check',
  'clipboard-circle-check',
  'clipboard-circle-clock',
  'clipboard-circle-dollar',
  'clipboard-cycle',
  'clipboard-edit',
  'clipboard-fill',
  'clipboard-inside',
  'clipboard-list-check-fill',
  'clipboard-list-check',
  'clipboard-list-fill',
  'clipboard-list',
  'clipboard-signature-fill',
  'clipboard-signature',
  'clipboard-stamp',
  'clipboard-text-check',
  'clipboard-text-fill',
  'clipboard-text',
  'clipboard',
  'clock-check',
  'clock-fill',
  'clock-hands',
  'clock-plus',
  'clock',
  'clone',
  'close',
  'closed-captioning',
  'cloud-check-fill',
  'cloud-check',
  'cloud-download-fill',
  'cloud-download',
  'cloud-fill',
  'cloud-music',
  'cloud-slash-fill',
  'cloud-slash',
  'cloud-upload-fill',
  'cloud-upload',
  'cloud',
  'code-branch',
  'code-commit',
  'code-merge',
  'code',
  'coffee',
  'cog-fill-small',
  'cog-fill',
  'cog',
  'coins',
  'color-slash',
  'colors',
  'columns',
  'comment-check-fill',
  'comment-check-right-fill',
  'comment-check-right',
  'comment-check',
  'comment-circle-check-fill',
  'comment-circle-check-right-fill',
  'comment-circle-check-right',
  'comment-circle-check',
  'comment-circle-dots-fill',
  'comment-circle-dots-right-fill',
  'comment-circle-dots-right',
  'comment-circle-dots',
  'comment-circle-exclamation-fill',
  'comment-circle-exclamation-right-fill',
  'comment-circle-exclamation-right',
  'comment-circle-exclamation',
  'comment-circle-fill',
  'comment-circle-minus-fill',
  'comment-circle-minus-right-fill',
  'comment-circle-minus-right',
  'comment-circle-minus',
  'comment-circle-plus-fill',
  'comment-circle-plus-right-fill',
  'comment-circle-plus-right',
  'comment-circle-plus',
  'comment-circle-right-fill',
  'comment-circle-right',
  'comment-circle-slash-fill',
  'comment-circle-slash-right-fill',
  'comment-circle-slash-right',
  'comment-circle-slash',
  'comment-circle-text-fill',
  'comment-circle-text-right-fill',
  'comment-circle-text-right',
  'comment-circle-text',
  'comment-circle-times-fill',
  'comment-circle-times-right-fill',
  'comment-circle-times-right',
  'comment-circle-times',
  'comment-circle',
  'comment-dots-fill',
  'comment-dots-right-fill',
  'comment-dots-right',
  'comment-dots',
  'comment-exclamation-fill',
  'comment-exclamation-right-fill',
  'comment-exclamation-right',
  'comment-exclamation',
  'comment-fill',
  'comment-minus-fill',
  'comment-minus-right-fill',
  'comment-minus-right',
  'comment-minus',
  'comment-plus-fill',
  'comment-plus-right-fill',
  'comment-plus-right',
  'comment-plus',
  'comment-right-fill',
  'comment-right',
  'comment-slash-fill',
  'comment-slash-right-fill',
  'comment-slash-right',
  'comment-slash',
  'comment-text-fill',
  'comment-text-right-fill',
  'comment-text-right',
  'comment-text',
  'comment-times-fill',
  'comment-times-right-fill',
  'comment-times-right',
  'comment-times',
  'comment',
  'comments-circle-fill',
  'comments-circle',
  'comments-fill',
  'comments',
  'company',
  'compare-arrows',
  'compass',
  'compress-arrows',
  'compress-full',
  'compress-wide',
  'compress',
  'conference-fill',
  'conference',
  'connect',
  'cookie',
  'copy',
  'copyright',
  'credit-card-back',
  'credit-card-front',
  'credit-card',
  'crown',
  'csv-logo',
  'cube',
  'cut',
  'dark-mode',
  'dashboard',
  'data-center',
  'database',
  'department-plus',
  'department',
  'desktop-mac',
  'desktop-windows',
  'divide',
  'document-certificate',
  'document-check',
  'document-clock',
  'document-fill',
  'document-invoice',
  'document-number',
  'document-plus',
  'document-question',
  'document-search',
  'document-stamp',
  'document-update',
  'document-write',
  'document',
  'dollar-sign',
  'dot-circle',
  'dot',
  'download',
  'drag',
  'drawer',
  'edit-text',
  'edit',
  'eject',
  'ellipsis-circle-fill',
  'ellipsis-circle',
  'ellipsis-h',
  'ellipsis-v',
  'emoji-angry-fill',
  'emoji-angry',
  'emoji-frown-fill',
  'emoji-frown',
  'emoji-laugh-fill',
  'emoji-laugh',
  'emoji-meh-fill',
  'emoji-meh',
  'emoji-sick',
  'emoji-smile-fill',
  'emoji-smile',
  'empty',
  'equals',
  'eraser',
  'erp',
  'excel-logo',
  'exchange',
  'exclamation-circle',
  'exclamation-fill',
  'exclamation-octagon',
  'exclamation-triangle-fill',
  'exclamation-triangle',
  'exclamation',
  'expand-arrows-full',
  'expand-full',
  'expand-more',
  'expand-wide',
  'expand',
  'expansion',
  'explore-slash',
  'export',
  'external-link',
  'eye-slash',
  'eye',
  'fast-backward',
  'fast-forward',
  'fax',
  'female',
  'file-download-fill',
  'file-download',
  'file-exclamation-fill',
  'file-exclamation',
  'file-export',
  'file-fill',
  'file-import',
  'file-invoice-fill',
  'file-invoice',
  'file-minus-fill',
  'file-minus',
  'file-plus-fill',
  'file-plus',
  'file-text-fill',
  'file-text',
  'file-times-fill',
  'file-times',
  'file-upload-fill',
  'file-upload',
  'file-zip',
  'file',
  'film',
  'filter-fill',
  'filter-list',
  'filter',
  'flag-fill',
  'flag',
  'focus',
  'folder-check-fill',
  'folder-check',
  'folder-download-fill',
  'folder-download',
  'folder-exclamation-fill',
  'folder-exclamation',
  'folder-fill',
  'folder-minus-fill',
  'folder-minus',
  'folder-move-fill',
  'folder-move',
  'folder-open-fill',
  'folder-open',
  'folder-plus-fill',
  'folder-plus',
  'folder-share-fill',
  'folder-share',
  'folder-tab',
  'folder-text-fill',
  'folder-text',
  'folder-times-fill',
  'folder-times',
  'folder-tree-fill',
  'folder-tree',
  'folder-upload-fill',
  'folder-upload',
  'folder',
  'folders-fill',
  'folders',
  'font-case',
  'font-color',
  'font',
  'footprint',
  'forgather',
  'forward',
  'functions',
  'funds',
  'gamepad',
  'gender',
  'gift-card',
  'gift',
  'global',
  'google',
  'grid-4-fill',
  'grid-4',
  'grid-9',
  'grid-list',
  'group',
  'handshake-slash',
  'handshake',
  'hash-sign',
  'hdd',
  'heading',
  'headphone',
  'heart-fill',
  'heart',
  'height',
  'hidden',
  'history',
  'home-fill',
  'home-work',
  'home',
  'horizonta-screen',
  'horizontal-exchange',
  'hot',
  'hourglass',
  'house-laptop',
  'house-leave',
  'house-return',
  'hwp',
  'hyphen',
  'id-badge',
  'id-card',
  'id',
  'image-fill',
  'image',
  'images',
  'no-image',
  'import-export',
  'import',
  'inbox-fill',
  'inbox-full',
  'inbox-in',
  'inbox-out',
  'inbox',
  'indent',
  'industry',
  'infinity',
  'info-circle-fill',
  'info-circle',
  'info-square-fill',
  'info-square',
  'input',
  'inventory',
  'italic',
  'kakao-talk',
  'key-command',
  'key-option',
  'key-windows',
  'key',
  'keyboard',
  'keypad',
  'knowledge',
  'label-fill',
  'label-slash',
  'label',
  'language',
  'laptop',
  'layer-group',
  'layer-minus',
  'layer-plus',
  'layers-fill',
  'layers',
  'layout',
  'level-down',
  'level-up',
  'lifebuoy',
  'lightbulb-fill',
  'lightbulb-slash-fill',
  'lightbulb-slash',
  'lightbulb',
  'line-dot',
  'line-height',
  'link-slash',
  'link',
  'list-check',
  'list-line',
  'list-list',
  'list-ol',
  'list-plus',
  'list-ul',
  'list',
  'lock-fill',
  'lock',
  'long-arrow-bottom-left',
  'long-arrow-bottom-right',
  'long-arrow-down',
  'long-arrow-left',
  'long-arrow-right',
  'long-arrow-top-left',
  'long-arrow-top-right',
  'long-arrow-up',
  'magic',
  'mail-attachment',
  'mail-ban',
  'mail-check',
  'mail-exclamation',
  'mail-fill',
  'mail-minus',
  'mail-open-none',
  'mail-open-text',
  'mail-open',
  'mail-plus',
  'mail-times',
  'mail-unread',
  'mail',
  'male',
  'map-marker-fill',
  'map-marker-minus',
  'map-marker-plus',
  'map-marker',
  'map-signs',
  'map',
  'mars',
  'medal',
  'memo',
  'merge-type',
  'message-fill',
  'message-open',
  'message',
  'microphone-fill',
  'microphone-slash-fill',
  'microphone-slash',
  'microphone',
  'microsoft',
  'mini-layer',
  'minus-circle-fill-small',
  'minus-circle-fill',
  'minus-circle-small',
  'minus-circle',
  'minus-small',
  'minus-square-fill-small',
  'minus-square-fill',
  'minus-square',
  'minus',
  'mobile-android',
  'mobile-iphone',
  'mobile',
  'module-fill',
  'module-plus-fill',
  'module-plus',
  'module',
  'monitoring-fill',
  'monitoring',
  'moon',
  'mouse-pointer',
  'mouse',
  'multi-line',
  'multi-text',
  'music',
  'new-tab',
  'new',
  'newspaper',
  'note',
  'notification',
  'number-0',
  'number-1',
  'number-2',
  'number-3',
  'number-4',
  'number-5',
  'number-6',
  'number-7',
  'number-8',
  'number-9',
  'number',
  'numbering',
  'os',
  'outdent',
  'outlook-logo',
  'packing',
  'pad',
  'page-break',
  'page',
  'pages',
  'paint-roller',
  'palette',
  'palm-tree',
  'paperclip-h',
  'paperclip-v',
  'paragraph-rtl',
  'paragraph',
  'parking-slash',
  'parking',
  'passport',
  'paste',
  'pasuse-circle-fill',
  'pasuse-circle',
  'pause',
  'pc-connection',
  'pdf',
  'pen-fill',
  'pen-line',
  'pen-nib',
  'pen',
  'pencil-ruler',
  'pencil',
  'percent',
  'person-fill',
  'person',
  'phone-fill',
  'phone-office-fill',
  'phone-office',
  'phone',
  'pin-fill',
  'pin',
  'plane-arrival',
  'plane-departure',
  'plane',
  'play-circle-fill',
  'play-circle',
  'play',
  'player-plus',
  'player',
  'plug',
  'plus-circle-dashed',
  'plus-circle-fill-small',
  'plus-circle-fill',
  'plus-circle-small',
  'plus-circle',
  'plus-small',
  'plus-square-dashed',
  'plus-square-fill-small',
  'plus-square-fill',
  'plus-square',
  'plus',
  'pms',
  'podium-star',
  'podium',
  'power-off',
  'powerpoint-logo',
  'presentation',
  'print-slash',
  'print',
  'process',
  'progress-circle',
  'progress-clock',
  'progress-rate',
  'progress',
  'project-diagram',
  'promotion',
  'puzzle',
  'qrcode',
  'question-circle',
  'question-fill',
  'quote-left',
  'quote-right',
  'radio-checked',
  'random',
  'ranking',
  'read-forward-mail',
  'read-send-mail',
  're-send',
  'receipt-fill',
  'receipt-long',
  'receipt',
  'rectangle-landscape',
  'rectangle-portrait',
  'rectangle-wide',
  'recycle',
  'redo-half',
  'redo',
  'registered',
  'relay-fill',
  'relay',
  'remote',
  'remove-format',
  'repeat-one',
  'repeat',
  'reply-all-fill',
  'reply-all',
  'reply-fill',
  'reply',
  'restaurant',
  'rings-wedding',
  'robot',
  'route',
  'rss-square-fill',
  'rss-square',
  'rss',
  'ruler-combined',
  'ruler',
  'sack-dollar',
  'sack-fortune',
  'sack',
  'save-fill',
  'save',
  'sc-colon',
  'sc-dot',
  'scan-circle',
  'scan-line',
  'scissors',
  'screen-sharing',
  'sd-storage',
  'search-location',
  'search-minus',
  'search-plus',
  'search-times',
  'search',
  'selectbox',
  'send-attachment',
  'send-check',
  'send-clock',
  'send',
  'server',
  'server2',
  'service',
  'shapes',
  'share',
  'shield-check-fill',
  'shield-check',
  'shield-fill',
  'shield-full',
  'shield-half',
  'shield-lock-fill',
  'shield-lock',
  'shield-slash-fill',
  'shield-slash',
  'shield',
  'shopping-cart-plus',
  'shopping-cart',
  'short-line',
  'shredder',
  'side',
  'sign-in',
  'sign-out',
  'sign',
  'signal-slash',
  'signal-stream',
  'signal',
  'signature',
  'signboard',
  'sitemap-fill',
  'sitemap',
  'slash',
  'sliders-h',
  'sliders-v',
  'sms',
  'sofa',
  'sort-amount-down',
  'sort-amount-up',
  'sort-amount',
  'sort-down',
  'sort-up',
  'sort',
  'speaker',
  'speedometer',
  'spell-check',
  'spinner-third',
  'spinner',
  'split-left-right',
  'split-list',
  'split-top-bottom',
  'square-fill',
  'square',
  'stamp-check',
  'stamp-exclamation',
  'stamp-fill',
  'stamp-times',
  'stamp',
  'star-fill',
  'star-half',
  'star',
  'step-backward',
  'step-forward',
  'stop-circle-fill',
  'stop-circle',
  'stop',
  'stopwatch',
  'store',
  'stream',
  'street-view',
  'strikethrough',
  'subdirectory-arrow-left',
  'subdirectory-arrow-right',
  'subscript',
  'sun',
  'superscript',
  'suv',
  'symbol',
  'sync',
  'table',
  'tachometer',
  'tag-fill',
  'tag',
  'tags',
  'taxi',
  'template',
  'terminal',
  'text-height',
  'text-size',
  'text-width',
  'thumbnail-list',
  'thumbs-down-fill',
  'thumbs-up-fill',
  'ticket',
  'tie',
  'timeline',
  'times-circle-fill-small',
  'times-circle-fill',
  'times-circle',
  'times-small',
  'times-square-fill',
  'times-square',
  'times',
  'toggle-off-fill',
  'toggle-off',
  'toggle-on-fill',
  'toggle-on',
  'toolbox',
  'tools',
  'trademark',
  'train',
  'trash-fill',
  'trash-full-fill',
  'trash-full',
  'trash-move-fill',
  'trash-move',
  'trash-restore-fill',
  'trash-restore',
  'trash-times-fill',
  'trash-times',
  'trash',
  'trending-down',
  'trending-up',
  'triangle-fill',
  'triangle',
  'trophy',
  'truck',
  'tv',
  'underline',
  'undo-half',
  'undo',
  'unfold-less',
  'unfold-more',
  'unlock',
  'unread',
  'unread-forward-mail',
  'unread-send-mail',
  'up-download',
  'update',
  'upload',
  'user-change',
  'user-chart',
  'user-check',
  'user-circle-fill',
  'user-circle',
  'user-clock',
  'user-cog',
  'user-down',
  'user-fill',
  'user-friends',
  'user-lock',
  'user-minus',
  'user-my',
  'user-plus',
  'user-scan',
  'user-shield',
  'user-slash',
  'user-square-fill',
  'user-tie',
  'user-times',
  'user-unlock',
  'user-up',
  'user-wreath',
  'user',
  'vector-square',
  'venus',
  'verified',
  'version',
  'vertical-exchange',
  'video-fill',
  'video-plus-fill',
  'video-plus',
  'video-slash-fill',
  'video-slash',
  'video',
  'view-agenda',
  'view-array',
  'view-carousel',
  'view-column',
  'vip',
  'voice',
  'voicemail',
  'volume-down-fill',
  'volume-down',
  'volume-fill',
  'volume-mute-fill',
  'volume-mute',
  'volume-off-fill',
  'volume-off',
  'volume-slash-fill',
  'volume-slash',
  'volume-up-fill',
  'volume-up',
  'volume',
  'vote-agree',
  'vote',
  'wallet',
  'warehouse',
  'warning-bell',
  'watch',
  'wave-square',
  'waveform-path',
  'webcam',
  'weight',
  'width',
  'wifi-slash',
  'wifi',
  'window-maximize',
  'window-minimize',
  'window-restore-down',
  'window',
  'won-sign',
  'word-logo',
  'wreath',
  'yen-sign',
  'zip-code',
  'vote-opposition',
] as const;
export type IconType = typeof IconTypeStrings[number];
