import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Switch from '../../../../../components/switch/Switch';

function ComponentSwitchLabel(): JSX.Element {
  return (
    <>
      <ComponentSampleUI.Content title="Label">
        <Switch label="Switch" />
      </ComponentSampleUI.Content>
      <ComponentSampleUI.Content title="Vertical label">
        <Switch label="Switch" vertical />
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentSwitchLabel;
