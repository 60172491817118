import React, { useState } from 'react';
import CommentItem from './CommentItem';
import CommentWrite from './CommentWrite';
import SelectField from '../selectField/SelectField';

interface CommentItem {
  id: number;
  message: string;
  writer: {
    key: string;
    name: string; // 이름
    organization: string; // 부서
    class: string; // 직위
    avatar: string; // 아바타
  };
  isAuthorized?: boolean; // 수정, 삭제 권한 여부.
  createAt: string;
  updateAt: string;
  status?: 'delete';
  isDeleted?: boolean;
  replies: {
    id: number;
    from?: {
      key: string;
      name: string;
    };
    message: string;
    writer: {
      key: string;
      name: string; // 이름
      organization: string; // 부서
      class: string; // 직위 혹은 직책
      avatar: string; // 아바타
    };
    isAuthorized?: boolean; // 수정,삭제 권한 여부.
    createAt: string;
    updateAt: string;
  }[];
}

function Comment(props: {
  opinion?: boolean;
  comments: CommentItem[];
  user?: {
    key: string;
    name: string; // 이름
    organization: string; // 부서
    class: string; // 직위 혹은 직책
    avatar: string; // 아바타
  };
  count: number;
  className?: string;
  onAdd?(arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }): void;
  onDelete?(id: number, parentId: number): void;
}): JSX.Element {
  const { opinion, comments, user, count, className, onAdd, onDelete } = props;

  let classname = 'eui-comment';
  if (className) classname += ` ${className}`;
  if (opinion) classname += ' opinion';

  const sortOption: { value: 'old' | 'new'; label: string }[] = [
    { value: 'old', label: '등록순' },
    { value: 'new', label: '최신순' },
  ];

  const [state, setState] = useState<{
    sort: 'old' | 'new';
    data: CommentItem[];
  }>({
    sort: 'old',
    data: comments,
  });

  const handleChangeSort = (value: 'old' | 'new') => {
    setState((prevState) => ({
      ...prevState,
      sort: value,
    }));
  };

  const handleCancelBasic = () => {
    //
  };

  let listData: CommentItem[];
  if (state.sort === 'new') {
    listData = [...comments].reverse();
  } else {
    listData = [...comments];
  }

  return (
    <div className={classname}>
      <div className="comment-title">
        <div className="title">
          {opinion ? `의견 ${count}개` : `댓글 ${count}개`}
        </div>
        <SelectField
          data={sortOption}
          value={state.sort}
          onChange={handleChangeSort}
          variant="underline"
          size="xs"
        />
      </div>
      <div className="comment-list">
        {listData.map((a) => {
          let useOption =
            a.isAuthorized !== undefined
              ? a.isAuthorized
              : user?.key === a.writer.key;
          if (opinion) useOption = false;
          if (a.isDeleted && a.replies.length === 0) return null;

          return (
            <CommentItem
              key={a.id}
              id={a.id}
              parentId={0}
              message={a.message}
              writer={a.writer}
              user={user}
              createAt={a.createAt}
              updateAt={a.updateAt}
              status={a.status}
              useOption={useOption}
              onAdd={onAdd}
              onDelete={onDelete}
            >
              {a.replies.length > 0 &&
                a.replies.map((b) => (
                  <CommentItem
                    key={b.id}
                    id={b.id}
                    parentId={a.id}
                    from={b.from}
                    message={b.message}
                    writer={b.writer}
                    user={user}
                    createAt={b.createAt}
                    updateAt={b.updateAt}
                    useOption={
                      b.isAuthorized !== undefined
                        ? b.isAuthorized
                        : user?.key === b.writer.key
                    }
                    onAdd={onAdd}
                    onDelete={onDelete}
                  />
                ))}
            </CommentItem>
          );
        })}
      </div>
      {!opinion && user && onAdd && (
        <CommentWrite
          user={user}
          onAdd={(message) => onAdd({ parentId: 0, message })}
          onCancel={handleCancelBasic}
        />
      )}
    </div>
  );
}

export default Comment;
