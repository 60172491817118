import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import DropMenu from '../../../../../components/selectField/DropMenu';
import { getText } from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';
import ApprovalPreferencesHead from './ApprovalPreferencesHead';
import HelperText from '../../../../../components/alert/HelperText';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import { DirectoryType } from '../../../../../groupware-common/types';
import {
  DirectoryTreeItem,
  getDirectoryTreeId,
} from '../../../../../components/tree/DirectoryTree';
import { getEmployeeName } from '../../../../../groupware-directory/stores/directory';
import UploadImage from '../../../../../components/upload/UploadImage';
import FeedBack from '../../../../../components/alert/FeedBack';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

/** 환경 설정 컨테이너. */
function ApprovalPreferencesContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${ApprovalPreferencesContainer.name}.render`);

  const dispatch = useAppDispatch();

  const userPreferencesList = useSelector(
    (state: RootState) => state.approval2.userPreferences.userPreferences,
  );

  let employeeName = '';
  if (userPreferencesList.surrogateEmployeeId !== 0)
    employeeName = getEmployeeName(
      userPreferencesList.companyId,
      userPreferencesList.surrogateEmployeeId,
      '',
    );

  const useLabel: { value: boolean; label: string }[] = [
    { value: true, label: getText('사용함') },
    { value: false, label: getText('사용안함') },
  ];
  // const saveLabel: { value: false | number; label: string }[] = [
  //   { value: false, label: getText('사용안함') },
  //   { value: 1, label: '1분' },
  //   { value: 2, label: '2분' },
  //   { value: 5, label: '5분' },
  // ];

  const datalist = {
    savecheck: false,
    companyId: userPreferencesList.companyId,
    alertMessage: '',
    userSelectedType: null,
    userSelectVisible: false,
    userSelectPoint: { x: 0, y: 0, width: 0, height: 0 },
    useSurrogate: userPreferencesList.useSurrogate && employeeName !== '',
    surrogateStartDate:
      userPreferencesList.surrogateStartDate === '1000-01-01'
        ? null
        : timezoneDate(userPreferencesList.surrogateStartDate),
    surrogateEndDate:
      userPreferencesList.surrogateEndDate === '1000-01-01'
        ? null
        : timezoneDate(userPreferencesList.surrogateEndDate),
    surrogateOrganizationId: userPreferencesList.surrogateOrganizationId,
    surrogateEmployeeId: userPreferencesList.surrogateEmployeeId,
    surrogateEmployeeName: employeeName,
    signaturePath: userPreferencesList.signaturePath,
    updateAt: userPreferencesList.updateAt,
  };

  const [state, setState] = useState<{
    savecheck: boolean;
    companyId: number;
    alertMessage: string;
    userSelectedType: 'assignation' | 'viewers' | null;
    userSelectVisible: boolean;
    userSelectPoint: { x: number; y: number; width: number; height: number };
    useSurrogate: boolean;
    surrogateStartDate: Date | null;
    surrogateEndDate: Date | null;
    surrogateOrganizationId: number;
    surrogateEmployeeId: number;
    surrogateEmployeeName: string;
    signaturePath: string;
    updateAt: string;
  }>(datalist);

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      alertMessage: '',
    }));
  };

  const handleOpenUserSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'assignation' | 'viewers' | null,
  ) => {
    if (userSelectVisible) handleCloseUserSelect();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        userSelectedType: type,
        userSelectPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        userSelectVisible: true,
      }));
    }
  };

  const handleCloseUserSelect = () => {
    setState((prevState) => ({
      ...prevState,
      userSelectedType: null,
      userSelectVisible: false,
    }));
  };

  const handleUserSelected = (arg: {
    item: DirectoryTreeItem;
    paths: {
      type: DirectoryType;
      id: number;
      name: string;
      treeId: string;
      text: string;
    }[];
  }) => {
    if (arg.item.extra.type === 'employee') {
      const parseData = arg.item.id.split('_');

      if (userPreferencesList.employeeId === Number(parseData[2])) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: '대결사용자를 대결자로 선택하실 수 없습니다.',
        }));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        surrogateOrganizationId: Number(parseData[1]),
        surrogateEmployeeId: Number(parseData[2]),
        surrogateEmployeeName: arg.item.text,
        savecheck: true,
      }));
    }

    handleCloseUserSelect();
  };

  const handleChangeAssignation = (value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      useSurrogate: value,
      savecheck: true,
    }));
  };

  const handleChangeStartDate = (value: Date | null) => {
    let { surrogateEndDate } = state;
    if (value !== null && surrogateEndDate !== null)
      surrogateEndDate = value < surrogateEndDate ? surrogateEndDate : value;

    setState((prev) => ({
      ...prev,
      surrogateStartDate: value,
      surrogateEndDate,
      savecheck: true,
    }));
  };

  const handleChangeEndDate = (value: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      surrogateEndDate: value,
      savecheck: true,
    }));
  };

  const handleDeleteAssignation = () => {
    setState((prevState) => ({
      ...prevState,
      surrogateEmployeeId: 0,
      surrogateEmployeeName: '',
      savecheck: true,
    }));
  };

  // const handleChangeSecurityApproval = (value: boolean) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     securityApproval: value,
  //     savecheck: true,
  //   }));
  // };

  const handleChangeSign = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    // eslint-disable-next-line consistent-return
    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (
        base64 &&
        base64.slice(5, 10) === 'image' &&
        (base64.slice(11, 14) === 'jpg' ||
          base64.slice(11, 14) === 'gif' ||
          base64.slice(11, 14) === 'png' ||
          base64.slice(11, 15) === 'jpeg')
      ) {
        setState((prevState) => ({
          ...prevState,
          signaturePath: base64.toString(),
          savecheck: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          alertMessage: '서명 이미지 파일은 JPG, GIF, PNG 파일만 유효합니다.',
        }));
      }
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.

      const eventTargetValue = { ...event };
      eventTargetValue.target.value = ''; // 같은 파일을 다시 받는 경우때문에 value reset
    }
  };

  const handleDeleteSign = () => {
    setState((prevState) => ({
      ...prevState,
      signaturePath: '',
      savecheck: true,
    }));
  };

  // const handleChangeAutoSave = (value: false | number) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     autoSave: value,
  //     savecheck: true,
  //   }));
  // };

  // const handleChangeContentPrint = (value: boolean) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     contentPrint: value,
  //     savecheck: true,
  //   }));
  // };

  // const handleChangeOpinionPrint = (value: boolean) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     opinionPrint: value,
  //     savecheck: true,
  //   }));
  // };

  // eslint-disable-next-line consistent-return
  const handleUserPreferencesSave = () => {
    const {
      useSurrogate,
      surrogateStartDate,
      surrogateEndDate,
      surrogateOrganizationId,
      surrogateEmployeeId,
      surrogateEmployeeName,
      signaturePath,
      updateAt,
    } = state;

    if (useSurrogate) {
      if (surrogateEmployeeName === '') {
        setState((prevState) => ({
          ...prevState,
          alertMessage:
            '대결사용을 선택하신 경우, 대결자를 반드시 지정하셔야 합니다.',
        }));
        return;
      }
      if (surrogateStartDate === null || surrogateEndDate === null) {
        setState((prevState) => ({
          ...prevState,
          alertMessage:
            '대결사용을 선택하신 경우, 대결지정기간을 반드시 지정하셔야 합니다.',
        }));
        return;
      }
    }

    let arg = {
      useSurrogate,
      surrogateStartDate:
        surrogateStartDate !== null
          ? dateFormat(initialDate(surrogateStartDate), 'YYYY-MM-DD')
          : '1000-01-01',
      surrogateEndDate:
        surrogateEndDate !== null
          ? dateFormat(initialDate(surrogateEndDate), 'YYYY-MM-DD')
          : '1000-01-01',
      surrogateEmployeeId,
      surrogateOrganizationId,
      useSecurity: false,
      autosave: 0,
      usePrintInfo: false,
      usePrintOpinion: false,
      updateAt,
      signature: undefined as string | undefined,
    };
    if (signaturePath && signaturePath.slice(0, 1) !== '/')
      arg = {
        ...arg,
        signature: signaturePath,
      };

    dispatch(
      approvaluserPreferencesActions.savefetchUserPreferences1(arg),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const payload = result.payload as { updateAt: string };
        setState((prevState) => ({
          ...prevState,
          savecheck: false,
          updateAt: payload.updateAt,
        }));
      }
    });
  };

  const handleUserPreferencesCancel = () => {
    setState(datalist);
  };

  const { pathname, search, hash } = props;

  const {
    savecheck,
    companyId,
    userSelectVisible,
    userSelectPoint,
    useSurrogate,
    surrogateOrganizationId,
    surrogateEmployeeId,
    surrogateEmployeeName,
    userSelectedType,
    alertMessage,
    signaturePath,
  } = state;

  const { surrogateStartDate, surrogateEndDate } = state;
  return (
    <>
      <ApprovalPreferencesHead
        pathname={pathname}
        search={search}
        hash={hash}
      />
      <EuiBody>
        <EuiSetting.Item title="대결 지정">
          <RadioGroup
            data={useLabel}
            value={useSurrogate}
            name="assignation"
            onChange={handleChangeAssignation}
          />
          {useSurrogate && (
            <>
              <EuiSetting.ItemGroup>
                <CustomDatePicker
                  placeholderText="시작일"
                  selected={surrogateStartDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date: Date) => handleChangeStartDate(date)}
                  selectsStart
                  startDate={surrogateStartDate}
                  endDate={surrogateEndDate}
                  width={120}
                />
                <CustomDatePicker
                  placeholderText="종료일"
                  selected={surrogateEndDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date: Date) => handleChangeEndDate(date)}
                  selectsEnd
                  startDate={surrogateStartDate}
                  endDate={surrogateEndDate}
                  minDate={surrogateStartDate}
                  popperPlacement="bottom-end"
                  width={120}
                />
              </EuiSetting.ItemGroup>
              <EuiSetting.ItemGroup>
                <DropMenu
                  value={surrogateEmployeeName}
                  label="대결자 선택"
                  pressed={
                    userSelectVisible && userSelectedType === 'assignation'
                  }
                  onClick={(event) =>
                    handleOpenUserSelect(event, 'assignation')
                  }
                />
                {userSelectVisible && (
                  <DirectoryMenuTreeContainer
                    point={userSelectPoint}
                    selectedId={
                      surrogateEmployeeName === ''
                        ? undefined
                        : getDirectoryTreeId(
                            companyId,
                            surrogateOrganizationId,
                            surrogateEmployeeId,
                          )
                    }
                    typeToFilter="employee"
                    onItemClick={handleUserSelected}
                    onClose={handleCloseUserSelect}
                  />
                )}
                {surrogateEmployeeId !== 0 && (
                  <Button
                    text="삭제"
                    iconType
                    icon="trash-fill"
                    onClick={handleDeleteAssignation}
                  />
                )}
              </EuiSetting.ItemGroup>
            </>
          )}
        </EuiSetting.Item>
        {/* <EuiSetting.Item title="보안결재">
          <RadioGroup
            data={useLabel}
            value={state.securityApproval}
            name="security"
            onChange={handleChangeSecurityApproval}
          />
          <HelperText text="결재 시 로그인 비밀번호를 입력해야 결재처리를 할 수 있습니다." />
        </EuiSetting.Item> */}
        <EuiSetting.Item title="서명 이미지">
          <UploadImage
            path={signaturePath}
            name="서명 이미지"
            type="profile"
            onChange={handleChangeSign}
            onDelete={handleDeleteSign}
          />
          <HelperText text="서명 이미지 파일은 JPG, GIF, PNG 파일로 제한" />
        </EuiSetting.Item>
        {/* <EuiSetting.Item title="자동저장">
          <RadioGroup
            data={saveLabel}
            value={state.autoSave}
            name="save"
            onChange={handleChangeAutoSave}
          />
        </EuiSetting.Item>
        <EuiSetting.Group title="인쇄 환경설정" />
        <EuiSetting.Item title="본문인쇄">
          <RadioGroup
            data={useLabel}
            value={state.contentPrint}
            name="content"
            onChange={handleChangeContentPrint}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title="의견포함">
          <RadioGroup
            data={useLabel}
            value={state.opinionPrint}
            name="opinion"
            onChange={handleChangeOpinionPrint}
          />
        </EuiSetting.Item> */}
        <EuiToolbar>
          {savecheck && (
            <EuiToolbar.Left>
              <Button
                text="저장"
                variant="contained"
                onClick={handleUserPreferencesSave}
              />
              <Button text="취소" onClick={handleUserPreferencesCancel} />
            </EuiToolbar.Left>
          )}
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalPreferencesContainer;
