import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import AttendanceContentBodyListItem from './AttendanceContentBodyListItem';

type Item = {
  checked: boolean;
  companyId: number; // 회사 아이디.
  applyId: number; // 근태 신청 아이디.
  approvalId: number; // 전자결재 문서 아이디
  applyType: string; // 근태 코드(1 :연차 , 2: 휴가 , 3:출장, 4:교육, 5:경조, 6:대휴 , 7:외근, 8: 휴일근무, 9: 시간외근무, 10: 기타)
  subject: string; // 제목
  startDate: string; // 시작 일자(yyyy-MM-dd)
  endDate?: string; // 종료 일자(yyyy-MM-dd)
  useMinutes: string; // 사용 일(분) 수
  createAt?: string; // 등록 날짜.
  status: string; // 결재상태. (진행, 완료, 반려)
};

/**
 * @property type 유형.
 * @property columns 컬럼 배열.
 * @property items 항목 배열.
 * @property onItemCheckedChange 항목 체크 변경 이벤트.
 * @property onItemClick 항목 클릭 이벤트.
 */
type Props = {
  folderId?: string;
  pageNo?: number;
  rowsPerPage?: number;
  searchCode?: string;
  searchWord?: string;
  type?: PostListType;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: Item[];
  selectedId?: number;
  onItemCheckedChange?(id: number, checked: boolean): void;
  onItemClick?(id: number): void;
  onCountClick?(id: number): void;
};

function AttendanceContentBodyList(props: Props): JSX.Element {
  // console.log(`${AttendanceContentBodyList.name}.render:props`, props);

  const {
    folderId,
    pageNo,
    rowsPerPage,
    searchCode,
    searchWord,
    type,
    density,
    columns,
    items,
    selectedId,
    onItemCheckedChange,
    onItemClick,
    onCountClick,
  } = props;

  const scrollbar = useRef<HTMLDivElement>(null);
  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, folderId, pageNo, rowsPerPage, searchCode, searchWord]);

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <PostList name="attendance" type={type} density={density}>
      <PostList.Head>
        {columns.map(({ name, type: colunmType, text }) => (
          <PostList.Cell
            key={name}
            name={text}
            type={colunmType}
            align={
              colunmType === 'status' || name === 'useMinutes'
                ? 'center'
                : undefined
            }
          />
        ))}
      </PostList.Head>
      <PostList.Body scrollableNodeProps={scrollbar}>
        {items.map(
          (
            {
              applyId: id,
              approvalId,
              checked,
              applyType,
              startDate,
              endDate,
              subject,
              useMinutes,
              createAt,
              status,
            },
            i,
          ) => {
            const directoryData = getDirectoryData({
              ...directory,
              ...principal,
            });
            const item = {
              checked,
              applyType,
              startDate,
              endDate,
              createAt,
              subject,
              useMinutes,
              status,
              drafter: {
                name: directoryData.employeeName,
                organization: directoryData.organizationName,
                job: directoryData.jobPositionName,
                avatar: directoryData.avatar ?? '',
              },
            };
            const key = `/i${i}/${id}/}`;
            return (
              <AttendanceContentBodyListItem
                key={key}
                id={id}
                approvalId={approvalId}
                selected={checked || approvalId === selectedId}
                columns={columns}
                item={item}
                onCheckedChange={onItemCheckedChange}
                onClick={onItemClick}
                onClickCount={onCountClick}
              />
            );
          },
        )}
      </PostList.Body>
    </PostList>
  );
}

export default AttendanceContentBodyList;
