/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 근태 상세 내역 조회 공통 리스트 */
export interface DetailList {
  companyId: number; // 회사 아이디.
  employeeId: number; // 사원 아이디.
  registerDate: string; // 작성일자.
  startDate: string;
  endDate: string;
  ammount: number; // 사용량.
  remark: string; // 비고.
}

/** 대휴 사용 상세 내역 조회 리스트 */
interface AlternativeDetailList {
  id: number; // 상세내역 아이디.
  employeeId: number;
  registerDate: string; // 작성일자.
  startDate: string;
  endDate: string;
  ammount: number; // 사용량.
  remark: string; // 비고.
  updateAt: string;
}

/** 대휴 발생, 조정 상세 내역 조회 리스트. */
interface AlternativeOccurList {
  detailType: string; // 상세내역 구분 , 발생 : occurs , 조정 : modifing , 사용 : use
  id: number; // 상세 내역 아이디
  detailDate: string; // 상세 내역 일자
  employeeId: number; // 상세 내역 기준 사원 아이디
  ammount: number; // 수량
  remark: string; // 비고
  updateAt: string; // 수정 일자/시간
}

/** 근태 상세 현황 내역 총 개수. */
async function findDetailedtotalCount(arg: {
  employeeId: number;
  startDate?: string;
  endDate?: string;
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const params = {
      employeeid: arg.employeeId,
      startdate: arg.startDate,
      enddate: arg.endDate,
      searchcode: arg.searchCode,
      searchWord: arg.searchWord,
    };
    const url = `${host}/api/attendance/v1/use/totalcount`;

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 근태 상세 현황 내역 조회. */
async function findDetailedList(arg: {
  employeeId: number;
  startDate?: string;
  endDate?: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number;
    useId: number;
    useYear: string;
    employeeId: number;
    approvalId: number;
    useType: number; // 근태 코드.
    subject: string;
    status: number;
    startDate: string;
    endDate: string;
    useMinutes: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const params = {
      employeeid: arg.employeeId,
      startdate: arg.startDate,
      enddate: arg.endDate,
      searchcode: arg.searchCode,
      searchWord: arg.searchWord,
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
    };
    const url = `${host}/api/attendance/v1/use/all`;

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 현황 내역 총 개수. */
async function findSubstituteTotalCount(arg: {
  standardYear: string;
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const params = {
      standardyear: arg.standardYear,
      searchcode: arg.searchCode,
      searchword: arg.searchWord,
    };
    const url = `${host}/api/attendance/v1/alternative/common/all/totalcount`;

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 현황 내역 조회. */
async function findSubstituteList(arg: {
  standardYear: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    employeeId: number; // 사원 아이디
    year: string; // 기준 년도
    occursAlternative: number; // 발생
    modifingAlternative: number; // 조정
    useAlternative: number; // 사용
    remainedAlternative: number; // 잔여
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const params = {
      standardyear: arg.standardYear,
      searchcode: arg.searchCode,
      searchword: arg.searchWord,
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
    };
    const url = `${host}/api/attendance/v1/alternative/common/all`;

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 현황 사용 상세 내역 조회. */
async function findSubstituteUseDetailList(arg: {
  standardYear: string;
  employeeId: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<AlternativeDetailList[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/alternative/common/use/all`;

    const params = {
      standardyear: arg.standardYear,
      employeeid: arg.employeeId,
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
    };

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 현황 발생, 조정 상세 내역 조회. */
async function findSubstituteDetailList(arg: {
  detailType: string;
  standardYear: string;
  employeeId: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<AlternativeOccurList[]> {
  try {
    let url = '';

    const { host, headers } = getApiConfig();
    if (arg.detailType === 'occur')
      url = `${host}/api/attendance/v1/alternative/common/occurs/all`;
    else if (arg.detailType === 'adjust')
      url = `${host}/api/attendance/v1/alternative/common/modifing/all`;

    const params = {
      standardyear: arg.standardYear,
      employeeid: arg.employeeId,
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
    };

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대휴 현황 사용, 발생, 조정 상세 내역 총 개수. */
async function findSubstituteDetailTotalCount(arg: {
  detailType: string;
  standardYear: string;
  employeeId: number;
}): Promise<number> {
  try {
    let url = '';

    const { host, headers } = getApiConfig();
    const params = {
      standardyear: arg.standardYear,
      employeeid: arg.employeeId,
    };

    if (arg.detailType === 'occur')
      url = `${host}/api/attendance/v1/alternative/common/occurs/all/totalcount`;
    else if (arg.detailType === 'adjust')
      url = `${host}/api/attendance/v1/alternative/common/modifing/all/totalcount`;
    else if (arg.detailType === 'use')
      url = `${host}/api/attendance/v1/alternative/common/use/all/totalcount`;

    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 기간별 근태현황 조회. */
async function findOrganizationDayList(arg: {
  startDate: string;
  endDate: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number;
    employeeId: number;
    list: {
      attendanceCode: number;
      ammount: number;
    }[];
  }[]
> {
  const {
    startDate: startdate,
    endDate: enddate,
    searchCode: searchcode,
    searchWord: searchword,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/summary/all`;
    const reponse = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchword,
        pageno,
        rowsperpage,
      },
    });
    return reponse.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 기간별 근태현황 총 개수. */
async function findOrganizationDayTotalCount(arg: {
  startDate: string;
  endDate: string;
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    startDate: startdate,
    endDate: enddate,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/summary/all/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 기간별 근태현황 - 근태 코드별 상세조회. */
async function findOrganizationDayDetail(arg: {
  startDate: string;
  endDate: string;
  attendanceCode: number;
  employeeId: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<DetailList[]> {
  const params = {
    startdate: arg.startDate,
    enddate: arg.endDate,
    attendancecode: arg.attendanceCode,
    employeeid: arg.employeeId,
    pageno: arg.pageNo,
    rowsperpage: arg.rowsPerPage,
  };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/summary/detail/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 기간별 근태현황 - 근태 코드별 상세조회 총 개수. */
async function findOrganizationDayDetailTotalCount(arg: {
  startDate: string;
  endDate: string;
  attendanceCode: number;
  employeeId: number;
}): Promise<number> {
  const params = {
    startdate: arg.startDate,
    enddate: arg.endDate,
    attendancecode: arg.attendanceCode,
    employeeid: arg.employeeId,
  };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/summary/detail/all/totalcount`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태 신청(결재 상신 전 호출 임시저장)  */
async function createUseTemp(arg: {
  employeeId: number;
  workId: number;
  startDateTime: string;
  endDateTime: string;
  useMinutes: number;
  useLeaveType: 'LEAVE_DAY' | 'LEAVE_AM' | 'LEAVE_PM' | 'LEAVE_HOUR' | null;
  subject: string;
  remark: string;
}): Promise<{ affiliatedCompanyId: number; id: number }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/temporary`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 월별 근태현황 조회. */
async function findOrganizationMonthList(arg: {
  year: string;
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number;
    employeeId: number;
    attendanceCode: number; // 근태코드
    ammountInJanuary: number;
    ammountInFebuary: number;
    ammountInMarch: number;
    ammountInApril: number;
    ammountInMay: number;
    ammountInJune: number;
    ammountInJuly: number;
    ammountInAugust: number;
    ammountInSeptember: number;
    ammountInOctober: number;
    ammountInNovember: number;
    ammountInDecember: number;
    ammountInTotal: number;
  }[]
> {
  const params = {
    year: arg.year,
    searchcode: arg.searchCode,
    searchword: arg.searchWord,
    pageno: arg.pageNo,
    rowsperpage: arg.rowsPerPage,
  };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/monthly/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 월별 근태현황 총 개수. */
async function findOrganizationMonthTotalCount(arg: {
  year: string;
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const params = {
    year: arg.year,
    searchcode: arg.searchCode,
    searchword: arg.searchWord,
  };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/monthly/all/totalcount`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 월별 근태현황 - 근태 코드별 상세조회. */
async function findOrganizationMonthDetail(arg: {
  employeeId: number;
  attendanceCode: number;
  year: string;
  month: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<DetailList[]> {
  const params = {
    employeeid: arg.employeeId,
    attendancecode: arg.attendanceCode,
    year: arg.year,
    month: arg.month,
    pageno: arg.pageNo,
    rowsperpage: arg.rowsPerPage,
  };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/monthly/detail/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 월별 근태현황 - 근태코드별 상세조회 총 개수. */
async function findOrganizationMonthDetailTotalCount(arg: {
  employeeId: number;
  attendanceCode: number;
  year: string;
  month: number;
}): Promise<number> {
  const params = {
    employeeid: arg.employeeId,
    attendancecode: arg.attendanceCode,
    year: arg.year,
    month: arg.month,
  };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/use/monthly/detail/all/totalcount`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
const attendancesApi = {
  createTemp: createUseTemp,

  detailedList: findDetailedList,
  detailedTotalCount: findDetailedtotalCount,

  substituteList: findSubstituteList,
  substituteTotalCount: findSubstituteTotalCount,
  substituteDetailList: findSubstituteDetailList,
  substituteUseDetailList: findSubstituteUseDetailList,
  substituteDetailTotalCount: findSubstituteDetailTotalCount,

  organizationDayList: findOrganizationDayList,
  organizationDayTotalCount: findOrganizationDayTotalCount,
  organizationDayDetail: findOrganizationDayDetail,
  organizationDayDetailTotalCount: findOrganizationDayDetailTotalCount,

  organizationMonthList: findOrganizationMonthList,
  organizationMonthTotalCount: findOrganizationMonthTotalCount,
  organizationMonthDetail: findOrganizationMonthDetail,
  organizationMonthDetailTotalCount: findOrganizationMonthDetailTotalCount,
};

export default attendancesApi;
