import { useSelector } from 'react-redux';
import { RootState } from '../../../groupware-webapp/app/store';
import { deepEqual, getText } from '../../../groupware-common/utils';
import { JobClassType } from './preferences';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function directorySelector(state: RootState) {
  return {
    companies: state.directory.company.list.data.items,
    organizations: state.directory.organization.list.data.items,
    organizationEmployees: state.directory.organization.employees.data.items,
    employees: state.directory.employee.list.data.items,
    jobClassType: state.directory.preferences.jobClassType,
    jobPositions: state.directory.jobPosition.list.data.items,
    jobDuties: state.directory.jobDuty.list.data.items,
    timestamps: {
      companies: state.directory.company.list.meta.timestamp,
      organizations: state.directory.organization.list.meta.timestamp,
      organizationEmployees:
        state.directory.organization.employees.meta.timestamp,
      employees: state.directory.employee.list.meta.timestamp,
      jobClassType: state.directory.preferences.jobClassType,
      jobPositions: state.directory.jobPosition.list.meta.timestamp,
      jobDuties: state.directory.jobDuty.list.meta.timestamp,
    },
  };
}

interface DirectoryTimestamps {
  companies: number;
  organizations: number;
  organizationEmployees: number;
  employees: number;
  jobClassType: JobClassType;
  jobPositions: number;
  jobDuties: number;
}

function directoryEqualityFn(
  left: { timestamps: DirectoryTimestamps },
  right: { timestamps: DirectoryTimestamps },
): boolean {
  // console.log(
  //   `deepEqual(left.timestamps, right.timestamps)`,
  //   deepEqual(left.timestamps, right.timestamps),
  // );
  return deepEqual(left.timestamps, right.timestamps);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useDirectory() {
  return useSelector(directorySelector, directoryEqualityFn);
}

function getCompanyName(id: number, defaultName?: string): string {
  const key = `directory:company.${id}_${id}`;
  const name = getText(key);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getOrganizationName(
  companyId: number,
  id: number,
  defaultName?: string,
): string;
function getOrganizationName(
  a: {
    companyId: number;
    organizationId: number;
    defaultName?: string;
  },
  b?: undefined,
): string;
function getOrganizationName(
  a:
    | number
    | {
        companyId: number;
        organizationId: number;
        defaultName?: string;
      },
  b?: number,
  c?: string,
): string {
  const { companyId, organizationId, defaultName } =
    typeof a === 'number'
      ? {
          companyId: a,
          organizationId: b,
          defaultName: c,
        }
      : a;

  if (companyId === organizationId)
    return getCompanyName(companyId, defaultName);

  const key = `directory:organization.${companyId}_${organizationId}`;
  const name = getText(key);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getEmployeeName(
  companyId: number,
  id: number,
  defaultName?: string,
): string;
function getEmployeeName(
  a:
    | {
        name: string;
        type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty';
        jobPosition: string;
        jobDuty: string;
        companyId?: undefined;
      }
    | { companyId: number; employeeId: number; defaultName?: string },
  b?: undefined,
): string;
function getEmployeeName(
  a:
    | number
    | {
        name: string;
        type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty';
        jobPosition: string;
        jobDuty: string;
        companyId?: undefined;
      }
    | { companyId: number; employeeId: number; defaultName?: string },
  b?: number,
  c?: string,
): string {
  if (typeof a === 'object' && a.companyId === undefined) {
    const { name, type, jobPosition, jobDuty } = a;

    let jobClass = '';
    switch (type) {
      case 'none':
        jobClass = '';
        break;
      case 'jobposition':
        jobClass = jobPosition;
        break;
      case 'jobduty':
        jobClass = jobDuty;
        break;
      default: {
        if (jobPosition !== '' && jobDuty !== '')
          jobClass = `${jobPosition}/${jobDuty}`;
        jobClass = jobPosition || jobDuty || '';
        break;
      }
    }
    if (jobClass !== '') return `${name} ${jobClass}`;
    return name;
  }

  const { companyId, employeeId, defaultName } =
    typeof a === 'object' && a.companyId !== undefined
      ? a
      : {
          companyId: a,
          employeeId: b,
          defaultName: c,
        };

  const key = `directory:employee.${companyId}_${employeeId}`;
  const name = getText(key);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getJobPositionName(
  companyId: number,
  id: number,
  defaultName?: string,
): string {
  const key = `jobclass:jobposition.${companyId}_${id}`;
  if (id === 0 && defaultName !== undefined) return defaultName;
  const name = getText(key);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getJobDutyName(
  companyId: number,
  id: number,
  defaultName?: string,
): string {
  const key = `jobclass:jobduty.${companyId}_${id}`;
  if (id === 0 && defaultName !== undefined) return defaultName;
  const name = getText(key);
  if (name === key.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

export {
  useDirectory,
  directorySelector,
  directoryEqualityFn,
  getCompanyName,
  getOrganizationName,
  getEmployeeName,
  getJobPositionName,
  getJobDutyName,
};
