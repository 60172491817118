import React, { useState } from 'react';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import ComponentSampleUI from '../ComponentSampleUI';

function TestCalendar(): JSX.Element {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChangeDate = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <ComponentSampleUI.Content title="Range">
      <CustomDatePicker
        selected={startDate}
        onChange={handleChangeDate}
        dateFormat="yyyy/MM/dd"
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={2}
        isClearable
      />
    </ComponentSampleUI.Content>
  );
}

export default TestCalendar;
