import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getPathParams,
  getQueryParams,
  resourceUpdate,
} from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import ResourceApprovalRoute from '../pages/root/resource/approval/ResourceApprovalRoute';
import ResourceComposeRoute from '../pages/root/resource/compose/ResourceComposeRoute';
import ResourceContentRoute from '../pages/root/resource/content/ResourceContentRoute';
import ResourceReservationListRoute from '../pages/root/resource/list/ResourceReservationListRoute';
import ResourcePreferencesRoute from '../pages/root/resource/preferences/ResourcePreferencesRoute';
import { folderActions, itemActions } from '../stores/folders';
import { preferencesActions } from '../stores/preferences';
import { userPreferencesActions } from '../stores/userPreferences';

function ResourceLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey: key } = props;
  const { p1 } = getPathParams('/*/:p1/:p2', pathname);

  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );
  const queryParams = getQueryParams(search);

  useEffect(() => {
    resourceUpdate(['directory', 'jobclass', 'approval'], [currentLanguage]);
    dispatch(preferencesActions.findPreferences());
    dispatch(userPreferencesActions.find({ employeeId: principal.employeeId }));
    dispatch(folderActions.list({}));
    dispatch(
      itemActions.userList({
        organizationId: principal.organizationId,
        employeeId: principal.employeeId,
      }),
    );
  }, [dispatch]);

  if (
    queryParams.contentMode === 'create' ||
    queryParams.contentMode === 'update'
  ) {
    return (
      <ResourceComposeRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }

  if (p1 === undefined || p1 === 'shared')
    return (
      <ResourceReservationListRoute
        pathname={pathname}
        search={search}
        key={key}
      />
    );
  if (p1 === 'approval' || p1 === 'return')
    return (
      <ResourceApprovalRoute pathname={pathname} search={search} key={key} />
    );
  if (p1 === 'preferences')
    return <ResourcePreferencesRoute pathname={pathname} key={key} />;
  return (
    <ResourceContentRoute
      pathname={pathname}
      search={search}
      hash={hash}
      key={key}
    />
  );
}

export default ResourceLocateRoute;
