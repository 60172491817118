import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../components/data/EmptyData';
import Dialog from '../../../components/dialog/Dialog';
import DialogBody from '../../../components/dialog/DialogBody';
import DialogHeader from '../../../components/dialog/DialogHeader';
import DialogTitle from '../../../components/dialog/DialogTitle';
import EuiToolbar from '../../../components/layout/EuiToolbar';
import Loading from '../../../components/loading/Loading';
import PostList, { PostListItemType } from '../../../components/post/PostList';
import Pagination from '../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { Column } from '../../../groupware-common/ui/type';
import { getQueryParams } from '../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  timeFormat,
  timezoneDate,
} from '../../../groupware-common/utils/ui';
import { useDirectory } from '../../../groupware-directory/stores/directory';
import { RootState } from '../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../groupware-webapp/stores/common/utils';
import dayOffStatusApi from '../../apis/attendance/v1/dayOffStatus';
import AttendanceContentBodyListItem from '../root/attendance/content/AttendanceContentBodyListItem';

function AttendanceDayOffDialog(props: {
  type: 'enter' | 'account';
  search: string;
  onClose(): void;
}): JSX.Element {
  const { type, search, onClose } = props;
  const queryParams = getQueryParams(search);
  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const { expressionUnit: unit } = useSelector(
    (state: RootState) => state.attendance.preferences.basic,
  );
  const isPhone = display === 'phone';

  const columns: Column<PostListItemType>[] = [
    { name: 'employeeNo', text: '사번', type: isPhone ? 'post' : 'folder' },
    { name: 'user', text: '이름', type: 'user' },
    { name: 'range', text: '사용기간', type: isPhone ? 'post' : 'number' },
    { name: 'use', text: '사용', type: isPhone ? 'post' : 'folder' },
    { name: 'reason', text: '사유', type: 'post' },
    { name: 'createAt', text: '작성일자', type: 'date' },
  ];

  const [state, setState] = useState<{
    items:
      | {
          companyId: number;
          employeeId: number;
          startDate: string;
          endDate: string;
          useLeaves: string; // 사용일(분)수.
          remark: string; // 사유.
          registerDate: string; // 작성일자.
        }[]
      | undefined;
    totalCount: number;
    pageNo: number;
  }>({
    items: undefined,
    totalCount: 0,
    pageNo: 1,
  });

  useEffect(() => {
    async function run() {
      if (queryParams.id === undefined) return;

      let items = [] as {
        companyId: number;
        employeeId: number;
        startDate: string;
        endDate: string;
        useLeaves: string;
        remark: string;
        registerDate: string;
      }[];
      let totalCount = 0;
      if (type === 'account') {
        items = (
          await dayOffStatusApi.accountUseList({
            employeeId: queryParams.id,
            standardYear:
              queryParams.status ?? `${timezoneDate().getFullYear()}`,
            pageNo: state.pageNo,
            rowsPerPage: 5,
          })
        ).map((a) => {
          return {
            companyId: principal.companyId,
            employeeId: a.employeeId,
            startDate: a.startDate,
            endDate: a.endDate,
            useLeaves: timeFormat(a.useLeaves, unit),
            remark: a.remark,
            registerDate: a.registerDate,
          };
        });
        totalCount = await dayOffStatusApi.accountUseTotalCount({
          employeeId: queryParams.id,
          standardYear: queryParams.status ?? `${timezoneDate().getFullYear()}`,
        });
      } else {
        items = (
          await dayOffStatusApi.enterUseList({
            employeeId: queryParams.id,
            standardDate:
              queryParams.status ?? dateFormat(new Date(), 'yyyy-MM-DD'),
            pageNo: state.pageNo,
            rowsPerPage: 5,
          })
        ).map((a) => {
          return {
            companyId: principal.companyId,
            employeeId: a.employeeId,
            startDate: a.startDate,
            endDate: a.endDate,
            useLeaves: timeFormat(a.useLeaves, unit),
            remark: a.remark,
            registerDate: a.registerDate,
          };
        });
        totalCount = await dayOffStatusApi.enterUseTotalCount({
          employeeId: queryParams.id,
          standardDate:
            queryParams.status ?? dateFormat(new Date(), 'yyyy-MM-DD'),
        });
      }
      setState((prev) => ({ ...prev, items, totalCount }));
    }

    run();
  }, [state.pageNo]);

  const handleAction = (code: string) => {
    if (code === 'prev') {
      const page = state.pageNo - 1;
      if (page < 1) return;
      setState((prev) => ({
        ...prev,
        pageNo: page,
      }));
    }
    if (code === 'next') {
      if (state.pageNo >= state.totalCount) return;
      const page = state.pageNo + 1;
      setState((prev) => ({
        ...prev,
        pageNo: page,
      }));
    }
  };

  if (state.items === undefined || state.totalCount === 0)
    return (
      <Dialog size="lg" onClose={onClose}>
        <DialogHeader>
          <DialogTitle>상세정보</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div style={{ margin: '20px', height: '315px' }}>
            <Loading />
            <EmptyData />
          </div>
        </DialogBody>
      </Dialog>
    );
  return (
    <Dialog size="md" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>상세정보</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <EuiToolbar>
          <EuiToolbar.Left>
            <></>
          </EuiToolbar.Left>
          <EuiToolbar.Right>
            <Pagination
              count={state.totalCount}
              no={state.pageNo}
              rows={5}
              onPrev={() => handleAction('prev')}
              onNext={() => handleAction('next')}
            />
          </EuiToolbar.Right>
        </EuiToolbar>
        <div style={{ margin: '20px', height: '315px' }}>
          <PostList name="상세정보" type="full">
            <PostList.Head>
              {columns.map(({ name: key, type: colunmType, text }) => (
                <PostList.Cell
                  key={key}
                  name={text}
                  type={colunmType}
                  align="center"
                />
              ))}
            </PostList.Head>
            <PostList.Body>
              {state.items.map((a, i) => {
                const employeeNo =
                  directory.employees.find(({ id }) => id === a.employeeId)
                    ?.no ?? ''; // 사번.
                const directoryData = getDirectoryData({
                  ...directory,
                  companyId: a.companyId,
                  employeeId: a.employeeId,
                });
                const range = `${dateTimeFormat(
                  a.startDate,
                  'yyyy-MM-DD',
                )} ~ ${dateTimeFormat(a.endDate, 'yyyy-MM-DD')}`;
                const key = `/i${i}/${a.companyId}/${a.employeeId}/}`;
                const item = {
                  employeeNo,
                  user: {
                    name: directoryData.employeeName,
                    organization: directoryData.organizationName,
                    job: directoryData.jobPositionName,
                    avatar: directoryData.avatar ?? '',
                  },
                  range,
                  use: a.useLeaves,
                  reason: a.remark,
                  createAt: dateTimeFormat(a.registerDate, 'yyyy-MM-DD'),
                };
                return (
                  <AttendanceContentBodyListItem
                    organizationStatus
                    key={key}
                    id={a.employeeId}
                    columns={columns}
                    item={item}
                  />
                );
              })}
            </PostList.Body>
          </PostList>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default AttendanceDayOffDialog;
