/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import testApi from '../../groupware-common/apis/test/v1';
import { RootState } from '../app/store';
import {
  CRUD,
  Language,
  LanguageModules,
  LocateArg,
  Module,
  ModuleItem,
} from '../../groupware-common/types';
import { ApiError } from '../../groupware-common/types/error';
import { base62, convertModuleForamt, getQueryParams, parseUris, resourceUpdate } from '../../groupware-common/utils';
import sessionApi from '../apis/session/v1';
import { appError } from './common/utils';
import { directoryPreferencesActions } from '../../groupware-directory/stores/directory/preferences';
import { companyActions } from '../../groupware-directory/stores/directory/company';
import { organizationActions } from '../../groupware-directory/stores/directory/organization';
import { employeeActions } from '../../groupware-directory/stores/directory/employee';
import { jobPositionActions } from '../../groupware-directory/stores/directory/jobposition';
import { jobDutyActions } from '../../groupware-directory/stores/directory/jobduty';
import { thunkCondition } from './utils';
import directoryRoleApi from '../../groupware-directory/apis/directory/v1/role';
import employeeApi from '../../groupware-directory/apis/directory/v1/employee';
import approvalRoleApi from '../../groupware-approval/apis/approval/v1/role';
import attendanceRoleApi from '../../groupware-attendance/apis/attendance/v1/role';
import resourceRoleApi from '../../groupware-resource/apis/resource/v1/preferences';
import boardRoleApi from '../../groupware-board/apis/board/v1/preferences';
import documentRoleApi from '../../groupware-document/apis/document/v1/preferences';
import { preferencesActions } from '../../groupware-systemlink/stores/systemlink/preferences';
import { CreateRandomString } from '../../groupware-common/utils/ui';
import serviceMenuApi from '../../groupware-service/apis/module';
import serviceBasicApi from '../../groupware-service/apis/basic';
import settingApi, { DesignType, initialPreference } from '../../groupware-setting/apis/setting/v1/setting';

const name = 'session';
const languageModules = ['jobclass', 'translation', 'directory', 'approval', 'error', 'code'] as Array<LanguageModules>;

function themeModeList(type: DesignType) {
  switch (type) {
    case 'SYSTEM':
      return 'auto' as const;
    case 'DARK':
      return 'dark' as const;
    case 'LIGHT':
      return 'light' as const;
    default:
      return 'auto' as const;
  }
}

function designThemeList(theme: 'auto' | 'dark' | 'light') {
  switch (theme) {
    case 'auto':
      return 'SYSTEM' as const;
    case 'dark':
      return 'DARK' as const;
    case 'light':
      return 'LIGHT' as const;
    default:
      return 'SYSTEM' as const;
  }
}

export function jwtDecode(token: string): {
  header: { alg: string; typ: string; };
  payload: {
    exp: number; // 인증 만료 시간.
    cid: number; // 회사 아이디.
    eid: number; // 사원 아이디.
  };
  signature: string;
} | undefined {

  try {
    const array = token.split('.');
    if (array.length !== 3) return undefined;

    const header = JSON.parse(atob(array[0] ?? '{}'));
    const payload = JSON.parse(atob(array[1] ?? '{}')) as {
      exp: number;
      cid: number;
      eid: number;
    };
    const signature = array[2];

    return { header, payload, signature };

  } catch (error) {
    return undefined;
  }
}

// CHECKLIST: 로컬일 때 languages: [en-US] 추가함
// CHECKLIST: 백엔드에서 languages 불러오도록 수정 필요
const signin = createAsyncThunk(
  `${name}/signin`,
  async (
    arg: {
      saveId?: boolean;
      id: string;
      password: string;
      tenantId?: string;
      appModule?: string;
      theme?: 'auto' | 'light' | 'dark';
      teamsHostClientType?: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    // async (_: void, thunkApi)
    // 반환값이 없는 경우 (_: void, thunkApi) 형식으로 작성하여야
    // thunkApi 매개변수 값이 넘어옴.
    try {
      // console.log(`signin(arg)`, arg);

      const isClooworks = Boolean(process.env.REACT_APP_ISCLOOWORKS); // 클루웍스 구분.
      const { id: email, password, appModule, theme = 'auto', tenantId, teamsHostClientType = ''} = arg;

      const resource: 'web' | 'teams' = tenantId === undefined ? 'web' : 'teams';

      // const { session } = thunkApi.getState() as RootState;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      let token: string | undefined;
      
      // 팀즈 로그인
      if (resource === 'teams') {
        const cookie = (document.cookie).split('; ');
        if (document.cookie !== '' && cookie.length > 0)
          cookie.forEach((a) => {
            const keyName = a.split('=')[0];
            const value = a.split('=')[1];
            if (keyName === 'TSID') token = value;
          });
      }
      // 웹 로그인
      else {
        token = await sessionApi.signin({ id: email, password });
        document.cookie = `SID=${token}; SameSite=None; Secure`;
      }
      if (token === undefined)
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      const jwt = jwtDecode(token);

      // console.log(`jwtObject`, jwt);

      // 로그인이 실패한 경우.
      if (jwt === undefined) {
        // 팀즈 연동 로그인인 경우.
        if (resource === 'teams') {
          if(isClooworks)
            throw new Error('본 애플리케이션을 사용하려면 클루웍스에 대한 Active account가 필요합니다.');
          throw new Error('사용자가 이 애플리케이션을 사용하려면 그룹웨어의 활성 계정이 필요합니다.');
        }
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      }
      const cookieName = resource === 'teams' ? 'TSID' : 'SID';
      if (jwt.payload.exp < (Date.now() / 1000)) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
        throw new Error('인증 세션이 만료되었습니다.');
      }

      // 로그인이 성공한 경우.
      const companyId = jwt.payload.cid;
      const employeeId = jwt.payload.eid;
      // 웹 로그인 아이디 쿠키 저장
      if (resource === 'web') {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = arg.saveId
          ? `USER_ID=${email}; path=/; expires=${date.toUTCString()};`
          : `USER_ID=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
      }

      dispatch(sessionSlice.actions.jwt(token));

      const roles = [
        ...(await directoryRoleApi.find()).map(({ role }) => role),
        ...(await approvalRoleApi.find()).map(({ role }) => role),
        ...(await attendanceRoleApi.find()).map(({ role }) => role),
        ...(await resourceRoleApi.find()).map(({ role }) => role),
        ...(await boardRoleApi.find({ isTeams: true })).map(({role}) => role),
        ...(await documentRoleApi.find()).map(({ role }) => role),
      ];

      await dispatch(directoryPreferencesActions.find({}));
      await dispatch(companyActions.findList());
      await dispatch(organizationActions.findList());
      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(employeeActions.findList());
      await dispatch(jobPositionActions.findList());
      await dispatch(jobDutyActions.findList());
      await dispatch(companyActions.findView({ id: companyId }));
      await dispatch(preferencesActions.providerList());
  
      const { organization, employee } = (getState() as RootState).directory;

      const basicSetting = await settingApi.fetchSetting({ companyId, employeeId });

      const organizationId = employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId)?.representativeOrganizationId;
      if (organizationId === undefined) throw new Error('등록되지 않은 계정 계정 입니다.');

      const organizationEmployees = organization.employees.data.items.filter((item) => item.companyId === companyId && item.employeeId === employeeId);
      let affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

      if (organizationEmployees.length === 0) {
        const nameKey = `directory:organization.${companyId}_${organizationId}`;
        const representative = true;
        const manager = organization.list.data.items.find((a) => a.id === organizationId)?.managerId === employeeId;
        affiliatedOrganizations = [{ id: organizationId, nameKey, representative, manager }];
      }
      else {
        affiliatedOrganizations = organizationEmployees.map((v, i, array) => {
          const nameKey = `directory:organization.${v.companyId}_${v.id}`;
          const representative = array.length === 1 ? true : v.id === organizationId;
          const manager = organization.list.data.items.find((a) => a.id === v.id)?.managerId === employeeId;
          return { id: v.id, nameKey, representative, manager };
        })
      }

      const dummyModules = [
        { id: 'APPROVAL', seq: 1, code: 'approval', textId: 'module.APPROVAL' },
        { id: 'DIRECTORY', seq: 2, code: 'directory', textId: 'module.DIRECTORY' },
        { id: 'ATTENDANCE', seq: 3, code: 'attendance', textId: 'module.ATTENDANCE' },
        { id: 'RESOURCE', seq: 4, code: 'resource', textId: 'module.RESOURCE' },
        { id: 'BOARD', seq: 5, code: 'board', textId: 'module.BOARD' },
        { id: 'CALENDAR', seq: 6, code: 'calendar', textId: 'module.CALENDAR' },
        { id: 'DOCUMENT', seq: 7, code: 'document', textId: 'module.DOCUMENT' },
        { id: 'MAIL', seq: 8, code: 'mail', textId: 'module.MAIL' },
        { id: 'CONTACTS', seq: 9, code: 'contacts', textId: 'module.CONTACTS' },
      ] as Array<ModuleItem>;

      const modules = resource === 'teams'
        ? dummyModules.filter((a) => a.code === appModule)
        : [
          { id: 'APPROVAL', seq: 1, code: 'approval', textId: 'module.APPROVAL' },
        { id: 'DIRECTORY', seq: 2, code: 'directory', textId: 'module.DIRECTORY' },
        { id: 'ATTENDANCE', seq: 3, code: 'attendance', textId: 'module.ATTENDANCE' },
        { id: 'RESOURCE', seq: 4, code: 'resource', textId: 'module.RESOURCE' },
        ] as Array<ModuleItem>;

      // TODO 백엔드 API 호출 변경
      const languages = ['ko-KR'] as Array<Language>;
      await resourceUpdate(languageModules, languages);

      return {
        basicSetting: {
          themeColor: `#${basicSetting.colorTheme}`,
          themeMode: theme ?? themeModeList(basicSetting.designTheme),
          currentLanguage: basicSetting.language,
          currentTimeZone: basicSetting.timeZone,
          updateAt: basicSetting.updateAt,
        },
        isSessionLogin: true,
        resource,
        tenantId,
        companyId,
        employeeId,
        organizationId,
        roles,
        affiliatedOrganizations,
        jwt: token,
        modules,
        theme,
        email,
        languages,
        teamsHostClientType,
      }

    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      // console.log(`signin:condition(arg)`, arg);
      const { session: { requests } } = getState() as RootState;
      return requests.find((a) => a.type === signin.typePrefix) === undefined;
    },
  },
);

// 연동결재 상신 로그인
const apiSignin = createAsyncThunk(
  `${name}/apiSignin`,
  async (
    arg: {
      value: string;
      theme?: 'auto' | 'light' | 'dark';
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { value, theme = 'auto' } = arg;
      const { resource } = (getState() as RootState).session;

      const data: {
        token: string;
        provision: {
          provider: string;
          linkType: string;
          linkId: string;
          subject: string;
          content: string;
          writerEmail: string;
          attachedSharedfiles?: {
            id: number;
            path: string;
            name: string;
            size: number;
            feature: number;
          }[];
        };
        form: {
          content: string;
          updateAt: string;
        };
        work: {
          id: number;
          folderId: number;
          name: string;
          formId: number;
          formName: string;
          documentNo: string;
          retentionPeriod: number;
          approvalLine: string;
          referrer: string;
          viewer: string;
          useAttachFile: number;
          useAttachDocument: number;
          useOpinion: boolean;
          useComment: boolean;
          linkType: string;
          updateAt: string;
        }
      } = JSON.parse(value);

      const newData = {
        provision: data.provision,
        form: data.form,
        work: data.work
      };

      const jwt = jwtDecode(data.token);
      // console.log(`jwtObject`, jwt);

      // 로그인이 실패한 경우.
      if (jwt === undefined) {
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      }
      if (jwt.payload.exp < (Date.now() / 1000))
        throw new Error('인증 세션이 만료되었습니다.');

      // 로그인이 성공한 경우.
      const companyId = jwt.payload.cid;
      const employeeId = jwt.payload.eid;

      dispatch(sessionSlice.actions.jwt(data.token));

      const roles = [
        ...(await directoryRoleApi.find()).map(({ role }) => role),
        ...(await approvalRoleApi.find()).map(({ role }) => role),
        ...(await attendanceRoleApi.find()).map(({ role }) => role),
        ...(await resourceRoleApi.find()).map(({ role }) => role),
        ...(await documentRoleApi.find()).map(({ role }) => role),
      ];

      await dispatch(directoryPreferencesActions.find({}));
      await dispatch(companyActions.findList());
      await dispatch(organizationActions.findList());
      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(employeeActions.findList());
      await dispatch(jobPositionActions.findList());
      await dispatch(jobDutyActions.findList());
      await dispatch(companyActions.findView({ id: companyId }));
      const employeeView = await employeeApi.findView(employeeId);

      const { organization, employee } = (getState() as RootState).directory;
      const basicSetting = await settingApi.fetchSetting({ companyId, employeeId });

      const organizationId = employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId)?.representativeOrganizationId;
      if (organizationId === undefined) throw new Error('등록되지 않은 계정 계정 입니다.');
      const { email } = employeeView;

      const organizationEmployees = organization.employees.data.items.filter((item) => item.companyId === companyId && item.employeeId === employeeId);
      let affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

      if (organizationEmployees.length === 0) {
        const nameKey = `directory:organization.${companyId}_${organizationId}`;
        const representative = true;
        const manager = organization.list.data.items.find((a) => a.id === organizationId)?.managerId === employeeId;
        affiliatedOrganizations = [{ id: organizationId, nameKey, representative, manager }];
      }
      else {
        affiliatedOrganizations = organizationEmployees.map((v, i, array) => {
          const nameKey = `directory:organization.${v.companyId}_${v.id}`;
          const representative = array.length === 1 ? true : v.id === organizationId;
          const manager = organization.list.data.items.find((a) => a.id === v.id)?.managerId === employeeId;
          return { id: v.id, nameKey, representative, manager };
        })
      }

      const dummyModules = [
        { id: 'APPROVAL', seq: 1, code: 'approval', textId: 'module.APPROVAL' },
        { id: 'DIRECTORY', seq: 2, code: 'directory', textId: 'module.DIRECTORY' },
        { id: 'ATTENDANCE', seq: 3, code: 'attendance', textId: 'module.ATTENDANCE' },
        { id: 'RESOURCE', seq: 4, code: 'resource', textId: 'module.RESOURCE' },
        { id: 'BOARD', seq: 5, code: 'board', textId: 'module.BOARD' },
        { id: 'CALENDAR', seq: 6, code: 'calendar', textId: 'module.CALENDAR' },
        { id: 'DOCUMENT', seq: 7, code: 'document', textId: 'module.DOCUMENT' },
        { id: 'MAIL', seq: 8, code: 'mail', textId: 'module.MAIL' },
        { id: 'CONTACTS', seq: 9, code: 'contacts', textId: 'module.CONTACTS' },
      ] as Array<ModuleItem>;

      // TODO 백엔드 API 호출 변경
      const languages = ['ko-KR'] as Array<Language>;
      await resourceUpdate(languageModules, languages);

      return {
        basicSetting: {
          themeColor: `#${basicSetting.colorTheme}`,
          themeMode: theme ?? themeModeList(basicSetting.designTheme),
          currentLanguage: basicSetting.language,
          currentTimeZone: basicSetting.timeZone,
          updateAt: basicSetting.updateAt,
        },
        isSessionLogin: false,
        resource,
        tenantId: '',
        companyId,
        employeeId,
        organizationId,
        roles,
        affiliatedOrganizations,
        jwt: data.token,
        modules: dummyModules,
        theme,
        email,
        newData,
        languages,
      }

    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      // console.log(`signin:condition(arg)`, arg);
      const { session: { requests } } = getState() as RootState;
      return requests.find((a) => a.type === apiSignin.typePrefix) === undefined;
    },
  },
);

// 연동결재 보기 로그인
const viewSign =  createAsyncThunk(
  `${name}/viewSign`,
  async (
    arg: {
      value: string;
      theme?: 'auto' | 'light' | 'dark';
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { value, theme = 'auto' } = arg;
      const { resource } = (getState() as RootState).session;

      const data: {
        token: string;
        provision: {
          documentId: number;
          email: string;
        }
      } = JSON.parse(value);

      const jwt = jwtDecode(data.token);
      const newData = { provision: data.provision };
      // console.log(`jwtObject`, jwt);

      // 로그인이 실패한 경우.
      if (jwt === undefined) {
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      }
      if (jwt.payload.exp < (Date.now() / 1000))
        throw new Error('인증 세션이 만료되었습니다.');

      // 로그인이 성공한 경우.
      const companyId = jwt.payload.cid;
      const employeeId = jwt.payload.eid;

      dispatch(sessionSlice.actions.jwt(data.token));

      const roles = [
        ...(await directoryRoleApi.find()).map(({ role }) => role),
        ...(await approvalRoleApi.find()).map(({ role }) => role),
        ...(await attendanceRoleApi.find()).map(({ role }) => role),
        ...(await resourceRoleApi.find()).map(({ role }) => role),
      ];

      await dispatch(directoryPreferencesActions.find({}));
      await dispatch(companyActions.findList());
      await dispatch(organizationActions.findList());
      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(employeeActions.findList());
      await dispatch(jobPositionActions.findList());
      await dispatch(jobDutyActions.findList());
      await dispatch(companyActions.findView({ id: companyId }));
      const employeeView = await employeeApi.findView(employeeId);

      const { organization, employee } = (getState() as RootState).directory;
      const basicSetting = await settingApi.fetchSetting({ companyId, employeeId });

      const organizationId = employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId)?.representativeOrganizationId;
      if (organizationId === undefined) throw new Error('등록되지 않은 계정 계정 입니다.');
      const { email } = employeeView;

      const organizationEmployees = organization.employees.data.items.filter((item) => item.companyId === companyId && item.employeeId === employeeId);
      let affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

      if (organizationEmployees.length === 0) {
        const nameKey = `directory:organization.${companyId}_${organizationId}`;
        const representative = true;
        const manager = organization.list.data.items.find((a) => a.id === organizationId)?.managerId === employeeId;
        affiliatedOrganizations = [{ id: organizationId, nameKey, representative, manager }];
      }
      else {
        affiliatedOrganizations = organizationEmployees.map((v, i, array) => {
          const nameKey = `directory:organization.${v.companyId}_${v.id}`;
          const representative = array.length === 1 ? true : v.id === organizationId;
          const manager = organization.list.data.items.find((a) => a.id === v.id)?.managerId === employeeId;
          return { id: v.id, nameKey, representative, manager };
        })
      }

      const dummyModules = [
        { id: 'APPROVAL', seq: 1, code: 'approval', textId: 'module.APPROVAL' },
        { id: 'DIRECTORY', seq: 2, code: 'directory', textId: 'module.DIRECTORY' },
        { id: 'ATTENDANCE', seq: 3, code: 'attendance', textId: 'module.ATTENDANCE' },
        { id: 'RESOURCE', seq: 4, code: 'resource', textId: 'module.RESOURCE' },
        { id: 'BOARD', seq: 5, code: 'board', textId: 'module.BOARD' },
        { id: 'CALENDAR', seq: 6, code: 'calendar', textId: 'module.CALENDAR' },
        { id: 'DOCUMENT', seq: 7, code: 'document', textId: 'module.DOCUMENT' },
        { id: 'MAIL', seq: 8, code: 'mail', textId: 'module.MAIL' },
        { id: 'CONTACTS', seq: 9, code: 'contacts', textId: 'module.CONTACTS' },
      ] as Array<ModuleItem>;

      // TODO 백엔드 API 호출 변경
      const languages = ['ko-KR'] as Array<Language>;
      await resourceUpdate(languageModules, languages);

      return {
        basicSetting: {
          themeColor: `#${basicSetting.colorTheme}`,
          themeMode: theme ?? themeModeList(basicSetting.designTheme),
          currentLanguage: basicSetting.language,
          currentTimeZone: basicSetting.timeZone,
          updateAt: basicSetting.updateAt,
        },
        isSessionLogin: false,
        resource,
        tenantId: '',
        companyId,
        employeeId,
        organizationId,
        roles,
        affiliatedOrganizations,
        jwt: data.token,
        modules: dummyModules,
        theme,
        email,
        newData,
        languages,
      }

    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      // console.log(`signin:condition(arg)`, arg);
      const { session: { requests } } = getState() as RootState;
      return requests.find((a) => a.type === viewSign.typePrefix) === undefined;
    },
  },
);

export const bypass = createAsyncThunk(
  `${name}/signin`,
  async (
    arg: {
      id: string;
      password: string;
      tenantId: string;
      theme?: 'auto' | 'light' | 'dark';
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    // async (_: void, thunkApi)
    // 반환값이 없는 경우 (_: void, thunkApi) 형식으로 작성하여야
    // thunkApi 매개변수 값이 넘어옴.
    try {
      // console.log(`signin(arg)`, arg);

      const { id: email, tenantId, theme = 'auto' } = arg;

      const resource = 'web';

      // const { session } = thunkApi.getState() as RootState;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      let token: string | undefined;
      const cookie = (document.cookie).split('; ');
      if (document.cookie !== '' && cookie.length > 0)
        cookie.forEach((a) => {
          const key = a.split('=')[0];
          const value = a.split('=')[1];
          if (key === 'BSID') token = value;
        });
      if (token === undefined)
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      const jwt = jwtDecode(token);

      // console.log(`jwtObject`, jwt);

      // 로그인이 실패한 경우.
      if (jwt === undefined) {
        // 팀즈 연동 로그인인 경우.
        // if (resource === 'teams') throw new Error('사용 권한이 없습니다.');
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      }
      if (jwt.payload.exp < (Date.now() / 1000)) {
        document.cookie = `BSID=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
        throw new Error('인증 세션이 만료되었습니다.');
      }

      // 로그인이 성공한 경우.
      const companyId = jwt.payload.cid;
      const employeeId = jwt.payload.eid;

      dispatch(sessionSlice.actions.jwt(token));

      const basicSetting = await settingApi.fetchSetting({ companyId, employeeId });
      const roles = [
        ...(await directoryRoleApi.find()).map(({ role }) => role),
        ...(await approvalRoleApi.find()).map(({ role }) => role),
        ...(await attendanceRoleApi.find()).map(({ role }) => role),
        ...(await resourceRoleApi.find()).map(({ role }) => role),
        ...(await boardRoleApi.find({ isTeams: true })).map(({role}) => role),
        ...(await documentRoleApi.find()).map(({ role }) => role),
      ];

      await dispatch(directoryPreferencesActions.find({}));
      await dispatch(companyActions.findList());
      await dispatch(organizationActions.findList());
      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(employeeActions.findList());
      await dispatch(jobPositionActions.findList());
      await dispatch(jobDutyActions.findList());
      await dispatch(companyActions.findView({ id: companyId }));
      await dispatch(preferencesActions.providerList());


      const { organization, employee } = (getState() as RootState).directory;

      const organizationId = employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId)?.representativeOrganizationId;
      if (organizationId === undefined) throw new Error('등록되지 않은 계정 계정 입니다.');

      const organizationEmployees = organization.employees.data.items.filter((item) => item.companyId === companyId && item.employeeId === employeeId);
      let affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

      if (organizationEmployees.length === 0) {
        const nameKey = `directory:organization.${companyId}_${organizationId}`;
        const representative = true;
        const manager = organization.list.data.items.find((a) => a.id === organizationId)?.managerId === employeeId;
        affiliatedOrganizations = [{ id: organizationId, nameKey, representative, manager }];
      }
      else {
        affiliatedOrganizations = organizationEmployees.map((v, i, array) => {
          const nameKey = `directory:organization.${v.companyId}_${v.id}`;
          const representative = array.length === 1 ? true : v.id === organizationId;
          const manager = organization.list.data.items.find((a) => a.id === v.id)?.managerId === employeeId;
          return { id: v.id, nameKey, representative, manager };
        })
      }

      const allModules = await serviceMenuApi.list({ companyId, employeeId }).then((response) => ([...response]));
      const systemInitialModule = (await serviceBasicApi.findPreferences({ companyId }).then((response) => (response))).initialModule;
      const modules:ModuleItem[] = [];
      let initialModule = '';
      if(allModules && allModules.length > 0) {
        allModules.sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
        allModules.forEach((module) => {
          if(module.module === systemInitialModule)
            initialModule = systemInitialModule.toLocaleLowerCase();
          modules.push(convertModuleForamt(module))
        })
        if (initialModule === '' && modules.length > 0)
          initialModule = modules[0].code;
      }

      // TODO 백엔드 API 호출 변경
      const languages = ['ko-KR'] as Array<Language>;
      await resourceUpdate(languageModules, languages);

      return {
        basicSetting: {
          themeColor: `#${basicSetting.colorTheme}`,
          themeMode: theme ?? themeModeList(basicSetting.designTheme),
          currentLanguage: basicSetting.language,
          currentTimeZone: basicSetting.timeZone,
          updateAt: basicSetting.updateAt,
        },
        isSessionLogin: false,
        resource,
        tenantId,
        companyId,
        employeeId,
        organizationId,
        roles,
        affiliatedOrganizations,
        jwt: token,
        modules,
        initialModule,
        theme,
        email,
        languages,
      }

    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      // console.log(`signin:condition(arg)`, arg);
      const { session: { requests } } = getState() as RootState;
      return requests.find((a) => a.type === signin.typePrefix) === undefined;
    },
  },
);

function asyncThunkPending(action: AnyAction): action is PayloadAction<
  undefined,
  string,
  {
    requestId: string;
    arg?: any;
  }
> {
  return action.type.substr(action.type.lastIndexOf('/')) === '/pending';
}

function asyncThunkFulfilled(action: AnyAction): action is PayloadAction<
  any,
  string,
  {
    requestId: string;
    arg?: any;
  }
> {
  switch (action.type.substr(action.type.lastIndexOf('/'))) {
    case '/fulfilled':
      return true;
    default:
      return false;
  }
}

function asyncThunkRejected(action: AnyAction): action is PayloadAction<
  any,
  string,
  {
    requestId: string;
    arg?: any;
  }
> {
  if (action.type === 'session/signin/rejected') return false;
  switch (action.type.substr(action.type.lastIndexOf('/'))) {
    case '/rejected':
      return true;
    default:
      return false;
  }
}

interface State {
  basicSetting: {
    themeColor: string;
    themeMode: 'auto' | 'light' | 'dark';
    /** 선택한 언어 코드 */
    currentLanguage: Language;
    /** 시간대 */
    currentTimeZone: number;
    updateAt: string;
  };
  display: 'pc' | 'tablet' | 'phone';
  mobileNav: boolean;
  // isAuthenticated
  requestId: string | undefined;
  jwt: string | undefined;
  /** 인증 여부. */
  authenticated: boolean;
  /** 세션 로그인 여부. */
  isSessionLogin: boolean;
  /** 리소스 (web: 웹, teams: 팀즈) */
  resource: 'web' | 'teams';
  /** 팀즈 앱 접속 정보. (desktop, web, android, ios) */
  teamsHostClientType?:  string;
  /** 테넌트 아이디. 팀즈(액티브 디렉터리) 사ㅆ용 시 */
  tenantId: string;

  /** 사용자 정보 */
  principal: {
    // companyId, employeeId jwt 정보.
    companyId: number;
    employeeId: number;

    // 추가 정보
    organizationId: number; // 현재 선택 부서.

    // 소속 조직 배열.
    affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

    /** 역할 배열. */
    roles: ('ADMIN' | 'DIRECTORY_USER' | 'DIRECTORY_ADMIN' | 'APPROVAL_USER' | 'APPROVAL_ADMIN' | 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN' | 'RESOURCE_USER' | 'RESOURCE_ADMIN' | 'DOCUMENT_USER' | 'DOCUMENT_ADMIN' | 'BOARD_USER' | 'BOARD_ADMIN' | 'CALENDAR_USER' | 'CALENDAR_ADMIN')[];
    /** 이메일. */
    email: string;
  };

  /** 사용 가능한 언어 코드 리스트 */
  languages: Array<Language>;
  languageModules: Array<LanguageModules>;

  signin: {
    // status: 'idle' | 'loading' | 'success' | 'failure' | 'unmounted';
    status: 'unmounted' | 'loading' | 'idle' | 'failure';
    errorMessage: string | undefined;
  };
  modules: Array<ModuleItem>;
  initialModule: string;

  defaultCategories: Record<string, number>;

  loading: {
    text: string;
  };

  syncLoading: boolean;

  cache: {
    expires: number;
  };
  requests: { id: string; type: string; arg: any }[];

  route: {
    pathname: string;
    search: string;
    hash: string;
    key: string;
  };

  errors: ApiError[];

  approvalForm: {
    provision: {
      provider: string;
      linkType: string;
      linkId: string;
      subject: string;
      content: string;
      writerEmail: string;
      attachedSharedfiles?: {
        id: number;
        path: string;
        name: string;
        size: number;
        feature: number;
      }[];
    };
    form: {
      content: string;
      updateAt: string;
    };
    work: {
      id: number;
      folderId: number;
      name: string;
      formId: number;
      formName: string;
      documentNo: string;
      retentionPeriod: number;
      approvalLine: string;
      referrer: string;
      viewer: string;
      useAttachFile: number;
      useAttachDocument: number;
      useOpinion: boolean;
      useComment: boolean;
      linkType: string;
      updateAt: string;
    }
  } | undefined;
  viewForm: {
    provision: {
      documentId: number;
      email: string;
    }
  } | undefined;
}

// prettier-ignore
let initialState: State = {
  basicSetting: {
    themeColor: process.env.REACT_APP_PRIMARY_COLOR ?? '#8a48fd',
    themeMode: 'auto',
    currentLanguage: 'ko-KR',
    currentTimeZone: 9,
    updateAt: '1000-01-01T00:00:00.000000',
  },
  display: 'pc',
  mobileNav: false,
  requestId: undefined,
  jwt: undefined,
  authenticated: false,
  isSessionLogin: false,
  resource: 'web',
  tenantId: '',

  principal: {
    companyId: 5001,
    employeeId: 20019,

    organizationId: 10005,

    affiliatedOrganizations: [
      { id: 10005, nameKey: 'directory:organization.5001_10005', representative: true, manager: true },
      // { id: 10004, nameKey: 'directory:organization.5001_10004' },
    ],

    roles: [],
    email: '',
  },

  languages: ['ko-KR'],
  languageModules: ['jobclass', 'translation', 'directory', 'approval'],
  signin: {
    status: 'unmounted',
    errorMessage: undefined,
  },
  modules: [],
  initialModule: '',
  defaultCategories: {},

  loading: {
    text: '',
  },

  syncLoading: false,

  cache: {
    expires: 300000,
  },
  requests: [],

  route: {
    pathname: '',
    search: '',
    hash: '',
    key: '',
  },

  errors: [],

  approvalForm: undefined,
  viewForm: undefined,
};

// bypass
initialState = {
  ...initialState,
  // jwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJncm91cHdhcmUiLCJpYXQiOjE2MzcxMTM5MzYsImV4cCI6MTY3MjQ0NDgwMCwiZWlkIjoyMDAxOSwiY2lkIjo1MDAxfQ.lojVI5PBYmL0SOdJVKK2baM4oWDMuotvL_DWIPa_Hf4',
  modules: [
    { id: 'APPROVAL', seq: 1, code: 'approval' as Module, textId: 'module.APPROVAL' },
    { id: 'MAIL', seq: 2, code: 'mail' as Module, textId: 'module.MAIL' },
    { id: 'BOARD', seq: 3, code: 'board' as Module, textId: 'module.BOARD', defaultFolderId: 4001 },
    { id: 'DOCUMENT', seq: 4, code: 'document' as Module, textId: 'module.DOCUMENT', defaultFolderId: 4001 },
    { id: 'CALENDAR', seq: 5, code: 'calendar' as Module, textId: 'module.CALENDAR' },
    { id: 'SURVEY', seq: 6, code: 'survey' as Module,  textId: 'module.SURVEY' },
    { id: 'CONTACTS', seq: 7, code: 'contacts' as Module, textId: 'module.CONTACTS' },
    { id: 'ATTENDANCE', seq: 8, code: 'attendance' as Module, textId: 'module.ATTENDANCE' },
    { id: 'RESOURCE', seq: 9, code: 'resource' as Module, textId: 'module.RESOURCE' },
  ] as Array<ModuleItem>,
  defaultCategories: { board: 1001 },
};

/** 테스트용 jwt 토큰 생성 */
const authenticate = createAsyncThunk(
  `${name}/authenticate`,
  async (arg: { companyId: number; employeeId: number }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { companyId, employeeId } = arg;

      const token = await testApi.authenticate(arg);

      // 로그인이 성공한 경우.
      dispatch(sessionSlice.actions.jwt(token));
      await dispatch(directoryPreferencesActions.find({}));
      await dispatch(companyActions.findList());
      await dispatch(organizationActions.findList());
      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(employeeActions.findList());
      await dispatch(jobPositionActions.findList());
      await dispatch(jobDutyActions.findList());
      await dispatch(companyActions.findView({ id: companyId }));
      await dispatch(preferencesActions.providerList());

      const { organization, employee } = (getState() as RootState).directory;

      const organizationId = employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId)?.representativeOrganizationId;
      if (organizationId === undefined) throw new Error('등록되지 않은 계정 계정 입니다.');

      const organizationEmployees = organization.employees.data.items.filter((item) => item.companyId === companyId && item.employeeId === employeeId);
      let affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

      if (organizationEmployees.length === 0) {
        const nameKey = `directory:organization.${companyId}_${organizationId}`;
        const representative = true;
        const manager = organization.list.data.items.find((a) => a.id === organizationId)?.managerId === employeeId;
        affiliatedOrganizations = [{ id: organizationId, nameKey, representative, manager }];
      }
      else {
        affiliatedOrganizations = organizationEmployees.map((v, i, array) => {
          const nameKey = `directory:organization.${v.companyId}_${v.id}`;
          const representative = array.length === 1 ? true : v.id === organizationId;
          const manager = organization.list.data.items.find((a) => a.id === v.id)?.managerId === employeeId;
          return { id: v.id, nameKey, representative, manager };
        })
      }
      const basicSetting = await settingApi.fetchSetting({ companyId, employeeId });


      // TODO 백엔드 API 호출 변경
      // TODO 다국어 테스트 위해 en-US 언어 추가
      const languages = ['ko-KR', 'en-US'] as Array<Language>;
      await resourceUpdate(languageModules, languages);

      return {
        basicSetting: {
          themeColor: `#${basicSetting.colorTheme}`,
          themeMode: themeModeList(basicSetting.designTheme),
          currentLanguage: basicSetting.language,
          currentTimeZone: basicSetting.timeZone,
          updateAt: basicSetting.updateAt,
        },
        isSessionLogin: false,
        jwt: token,
        organizationId,
        roles: ['ADMIN'] as 'ADMIN'[],
        affiliatedOrganizations,
        email: employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId)?.email ?? '',
        languages,
        // modules,
        // initialModule,
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(authenticate.typePrefix, arg, getState);
    },
  },
);

/** jwt 토큰 인증 */
const jwtAuthenticate = createAsyncThunk(
  `${name}/jwtAuthenticate`,
  async (arg: { isTeams: boolean; token: string }, { dispatch, getState, rejectWithValue }) => {
    try {
      const isClooworks = Boolean(process.env.REACT_APP_ISCLOOWORKS); // 클루웍스 구분.
      const { isTeams, token } = arg;
      const jwt = jwtDecode(token);

      // 로그인이 실패한 경우.
      if (jwt === undefined) {
        // 팀즈 연동 로그인인 경우.
        if (isTeams) {
          if(isClooworks)
            throw new Error('본 애플리케이션을 사용하려면 클루웍스에 대한 Active account가 필요합니다.');
          throw new Error('사용자가 이 애플리케이션을 사용하려면 그룹웨어의 활성 계정이 필요합니다.');
        }
        throw new Error('잘못된 아이디이거나 비밀번호입니다.');
      }
      // 로그인이 성공한 경우.
      const { cid: companyId, eid: employeeId, exp } = jwt.payload;

      if (exp < (Date.now() / 1000)) {
        document.cookie = `SID=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
        throw new Error('인증 세션이 만료되었습니다.');
      }

      dispatch(sessionSlice.actions.jwt(token));

      const basicSetting = await settingApi.fetchSetting({ companyId, employeeId });
      const roles = [
        ...(await directoryRoleApi.find()).map(({ role }) => role),
        ...(await approvalRoleApi.find()).map(({ role }) => role),
        ...(await attendanceRoleApi.find()).map(({ role }) => role),
        ...(await resourceRoleApi.find()).map(({ role }) => role),
        ...(await boardRoleApi.find({ isTeams: true })).map(({role}) => role),
        ...(await documentRoleApi.find()).map(({ role }) => role),
      ];

      await dispatch(directoryPreferencesActions.find({}));
      await dispatch(companyActions.findList());
      await dispatch(organizationActions.findList());
      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(employeeActions.findList());
      await dispatch(jobPositionActions.findList());
      await dispatch(jobDutyActions.findList());
      await dispatch(companyActions.findView({ id: companyId }));
      await dispatch(preferencesActions.providerList());
  
      const { organization, employee } = (getState() as RootState).directory;

      const principal = employee.list.data.items.find((a) => a.companyId === companyId && a.id === employeeId);
      const organizationId =principal?.representativeOrganizationId;
      const email = principal?.email;
      if (organizationId === undefined || email === undefined) throw new Error('등록되지 않은 계정 계정 입니다.');

      const allModules = await serviceMenuApi.list({ companyId, employeeId }).then((response) => ([...response]));
      const systemInitialModule = (await serviceBasicApi.findPreferences({ companyId }).then((response) => (response))).initialModule;
      const modules:ModuleItem[] = [];
      let initialModule = '';
      if(allModules && allModules.length > 0) {
        allModules.sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
        allModules.forEach((module) => {
          if(module.module === systemInitialModule)
            initialModule = systemInitialModule.toLocaleLowerCase();

            modules.push(convertModuleForamt(module))
        })
        if (initialModule === '' && modules.length > 0)
          initialModule = modules[0].code;
      }

      const organizationEmployees = organization.employees.data.items.filter((item) => item.companyId === companyId && item.employeeId === employeeId);
      let affiliatedOrganizations: { id: number; nameKey: string; representative: boolean; manager: boolean }[];

      if (organizationEmployees.length === 0) {
        const nameKey = `directory:organization.${companyId}_${organizationId}`;
        const representative = true;
        const manager = organization.list.data.items.find((a) => a.id === organizationId)?.managerId === employeeId;
        affiliatedOrganizations = [{ id: organizationId, nameKey, representative, manager }];
      }
      else {
        affiliatedOrganizations = organizationEmployees.map((v, i, array) => {
          const nameKey = `directory:organization.${v.companyId}_${v.id}`;
          const representative = array.length === 1 ? true : v.id === organizationId;
          const manager = organization.list.data.items.find((a) => a.id === v.id)?.managerId === employeeId;
          return { id: v.id, nameKey, representative, manager };
        })
      }


      // TODO 백엔드 API 호출 변경
      const languages = ['ko-KR'] as Array<Language>;
      await resourceUpdate(languageModules, languages);

      return {
        basicSetting: {
          themeColor: `#${basicSetting.colorTheme}`,
          themeMode: themeModeList(basicSetting.designTheme),
          currentLanguage: basicSetting.language,
          currentTimeZone: basicSetting.timeZone,
          updateAt: basicSetting.updateAt,
        },
        isSessionLogin: true,
        resource: (isTeams ? 'teams' : 'web') as 'teams' | 'web',
        companyId,
        employeeId,
        organizationId,
        roles,
        affiliatedOrganizations,
        jwt: token,
        modules,
        initialModule,
        theme: 'auto' as 'auto' | 'light' | 'dark',
        email,
        languages,
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(authenticate.typePrefix, arg, getState);
    },
  },
);

/** 개인 환경설정 조회 */
const fetchBasicSetting = createAsyncThunk(
  `${name}/fetchBasicSetting`,
  async (_: LocateArg, {getState,rejectWithValue}) => {
   try {
     const { employeeId, companyId } = (getState() as RootState).session.principal;
     const result = await settingApi.fetchSetting({ companyId, employeeId });
     return {
      themeColor: `#${result.colorTheme}`,
      themeMode: themeModeList(result.designTheme),
      currentLanguage: result.language,
      currentTimeZone: result.timeZone,
      updateAt: result.updateAt,
     };
   } catch (ex) {
     return rejectWithValue(appError(ex));
   }
 }
);

/** 개인 환경설정 저장. */
const saveBasicSetting = createAsyncThunk(
  `${name}/saveBasicSetting`,
  async (arg: {
    language: Language;
    timeZone: number;
    updateAt: string;
 } & LocateArg, {getState, rejectWithValue}) => {
    try {
      const { employeeId, companyId } = (getState() as RootState).session.principal;
      const result = await settingApi.saveSetting({
        companyId,
        employeeId,
        data: {
          language: arg.language,
          timeZone: arg.timeZone,
          updateAt: arg.updateAt,
        },
      });
      return {
       themeColor: `#${result.colorTheme}`,
       themeMode: themeModeList(result.designTheme),
       currentLanguage: result.language,
       currentTimeZone: result.timeZone,
       updateAt: result.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
   }
 }
);

/** 사용자 색상 테마 수정 */
const themeColor = createAsyncThunk(
  `${name}/themeColor`,
  async (arg: {
    colorTheme: string;
 } & LocateArg, {getState, rejectWithValue}) => {
    try {
      const { employeeId, companyId } = (getState() as RootState).session.principal;
      const result = await settingApi.saveThemeColor({
        companyId,
        employeeId,
        colorTheme: arg.colorTheme.replace(/#/gs, ''),
      });
      return {
       themeColor: `#${result.colorTheme}`,
       updateAt: result.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
   }
 }
);

/** 사용자 디자인 모드 수정 */
const themeMode = createAsyncThunk(
  `${name}/themeMode`,
  async (arg: {
    inTeams?: boolean;
    designTheme: 'auto' | 'dark' | 'light';
 } & LocateArg, {getState, rejectWithValue}) => {
    try {
      const { employeeId, companyId } = (getState() as RootState).session.principal;
      if (arg.inTeams)
        return { themeMode: arg.designTheme };
      const result = await settingApi.saveThemeMode({
        companyId,
        employeeId,
        designTheme: designThemeList(arg.designTheme),
      });
      return {
       themeMode: themeModeList(result.designTheme),
       updateAt: result.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
   }
 }
);

/** 사용자 언어 수정 */
const setLanguage = createAsyncThunk(
  `${name}/setLanguage`,
  async (arg: {
    language: Language;
 } & LocateArg, {getState, rejectWithValue}) => {
    try {
      const { employeeId, companyId } = (getState() as RootState).session.principal;
      const result = await settingApi.saveLanguage({
        companyId,
        employeeId,
        language: arg.language,
      });
      return {
       currentLanguage: result.language,
       updateAt: result.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
   }
 }
);

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    display(
      state,
      action: PayloadAction<'pc' | 'tablet' | 'phone'>,
    ) {
      state.display = action.payload;
    },
    mobileNavVisible(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.mobileNav = action.payload;
    },
    error(state, action: PayloadAction<string | ApiError>) {
      const { payload } = action;
      const error = (typeof payload === 'string') ? {
        error: payload,
        path: '',
        status: 0,
        // message?: string;
        timestamp: `${Date.now()}`,
      } : payload;
      state.errors = [ ...state.errors, error ];
    },
    errorDelete(state, action: PayloadAction<'all' | undefined>) {
      if (state.errors.length > 0) {
        if (action.payload === 'all') state.errors = [];
        else state.errors = state.errors.slice(1);
      }
    },
    jwt(state, action: PayloadAction<string | undefined>) {
      state.jwt = action.payload;
    },
    signout(state) {
      state.jwt = undefined;
      state.authenticated = false;
    },
    loadingToggle(state, action: PayloadAction<{ text: string }>) {
      if (state.loading.text !== action.payload.text) {
        state.loading.text = action.payload.text;
      }
    },
    setSyncLoading(state, action: PayloadAction<boolean>) {
      state.syncLoading = action.payload;
    },
    clearApprovalForm(state) {
      state.approvalForm = undefined;
    },

    setRoute(state, action: PayloadAction<{
      pathname: string;
      search?: string;
      hash?: string;
      key?: string;
      clearErrors?: boolean;
    }>) {
      const { pathname, search = '', hash = '', clearErrors = false } = action.payload;
      const key = action.payload.key ?? state.route.key;

      if (state.route.pathname === pathname && state.route.search === search && (key !== undefined && state.route.key === key) && (hash === '' || hash === '#' || state.route.hash === hash)) {
        state.route.key = CreateRandomString();
      }
      else {
        state.route.key = key;
      }
      state.route.pathname = pathname;
      state.route.search = search;
      state.route.hash = hash;

      if (state.errors.length > 0 && clearErrors) state.errors = [];
    },
    setDialog(state, action: PayloadAction<{ type: string; mode?: CRUD } | { type?: string; mode: CRUD } | undefined>) {
      // console.log(`setDialog:action`, action);
      const qs = { ...getQueryParams(state.route.search) };
      if (action.payload === undefined) {
        delete qs.dialogType;
        delete qs.dialogMode;
      } else {
        const { type, mode } = action.payload;
        if (type !== undefined) qs.dialogType = type;
        else delete qs.dialogType;
        if (mode !== undefined) qs.dialogMode = mode;
        else delete qs.dialogMode;
      }
      let search = '';
      Object.entries(qs).forEach(([key, value]) => {
        search += `&${key}=${value}`;
      });
      state.route.search = search !== '' ? search.substring(1) : '';
    },
    setDrawer(state, action: PayloadAction<{ type: string; mode?: CRUD } | { type?: string; mode: CRUD } | undefined>) {
      // console.log(`setDrawer:action`, action);
      const qs = { ...getQueryParams(state.route.search) };
      if (action.payload === undefined) {
        delete qs.drawerType;
        delete qs.drawerMode;
      } else {
        const { type, mode } = action.payload;
        if (type !== undefined) qs.drawerType = type;
        else delete qs.drawerType;
        if (mode !== undefined) qs.drawerMode = mode;
        else delete qs.drawerMode;
      }
      let search = '';
      Object.entries(qs).forEach(([key, value]) => {
        search += `&${key}=${value}`;
      });
      state.route.search = search !== '' ? search.substring(1) : '';
    },
    search(state, action: PayloadAction<string | undefined>) {
      // console.log(`search:action`, action);
      if (action.payload === undefined) state.route.search = '';
      else state.route.search = action.payload;
    },
    hash(state, action: PayloadAction<string | undefined>) {
      // console.log(`search:action`, action);
      if (action.payload === undefined) state.route.hash = '';
      else state.route.hash = action.payload;
    },
    // prettier-ignore
    // 팀즈용 리소스 변경 테스트 액션.
    setResource(state, action: PayloadAction<'web' | 'teams'>) {
      if (state.resource !== action.payload)
        state.resource = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state, action) => {
        state.requestId = action.meta.requestId;
        state.signin.status = 'loading';
      })
      .addCase(apiSignin.pending, (state, action) => {
        state.requestId = action.meta.requestId;
        state.signin.status = 'loading';
      })
      .addCase(viewSign.pending, (state, action) => {
        state.requestId = action.meta.requestId;
        state.signin.status = 'loading';
      })
      .addCase(jwtAuthenticate.pending, (state, action) => {
        state.requestId = action.meta.requestId;
        state.signin.status = 'loading';
      })
      .addCase(signin.fulfilled, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId && action.payload) {
          const { resource, tenantId = '', jwt, basicSetting } = action.payload;

          state.basicSetting = basicSetting;
          state.resource = resource;
          state.tenantId = tenantId;

          state.principal.companyId = action.payload.companyId;
          state.principal.employeeId = action.payload.employeeId;
          state.principal.organizationId = action.payload.organizationId;
          state.principal.affiliatedOrganizations = action.payload.affiliatedOrganizations;
          state.principal.roles = action.payload.roles;
          state.principal.email = action.payload.email;

          state.jwt = jwt;
          state.authenticated = true;
          state.isSessionLogin = action.payload.isSessionLogin;
          state.languages = action.payload.languages;
          state.modules = action.payload.modules;

          state.requestId = undefined;
          state.signin.status = (jwt) ? 'unmounted' : 'failure';
          state.signin.errorMessage = undefined;

          state.teamsHostClientType = action.payload.teamsHostClientType;
        }
      })
      .addCase(apiSignin.fulfilled, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId && action.payload) {
          const { resource, tenantId = '', jwt, newData, basicSetting } = action.payload;

          state.basicSetting = basicSetting;
          state.resource = resource;
          state.tenantId = tenantId;

          state.principal.companyId = action.payload.companyId;
          state.principal.employeeId = action.payload.employeeId;
          state.principal.organizationId = action.payload.organizationId;
          state.principal.affiliatedOrganizations = action.payload.affiliatedOrganizations;
          state.principal.roles = action.payload.roles;
          state.principal.email = action.payload.email;

          state.jwt = jwt;
          state.authenticated = true;
          state.isSessionLogin = action.payload.isSessionLogin;
          state.languages = action.payload.languages;

          state.requestId = undefined;
          state.signin.status = (jwt) ? 'unmounted' : 'failure';
          state.signin.errorMessage = undefined;

          state.approvalForm = newData;
        }
      })
      .addCase(viewSign.fulfilled, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId && action.payload) {
          const { resource, tenantId = '', jwt, newData, basicSetting } = action.payload;

          state.basicSetting = basicSetting;
          state.resource = resource;
          state.tenantId = tenantId;

          state.principal.companyId = action.payload.companyId;
          state.principal.employeeId = action.payload.employeeId;
          state.principal.organizationId = action.payload.organizationId;
          state.principal.affiliatedOrganizations = action.payload.affiliatedOrganizations;
          state.principal.roles = action.payload.roles;
          state.principal.email = action.payload.email;

          state.jwt = jwt;
          state.authenticated = true;
          state.isSessionLogin = action.payload.isSessionLogin;
          state.languages = action.payload.languages;

          state.requestId = undefined;
          state.signin.status = (jwt) ? 'unmounted' : 'failure';
          state.signin.errorMessage = undefined;

          state.viewForm = newData;
        }
      })
      .addCase(jwtAuthenticate.fulfilled, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId && action.payload) {
          const { resource, jwt, basicSetting } = action.payload;

          state.basicSetting = basicSetting;
          state.resource = resource;
          state.tenantId = '';

          state.principal.companyId = action.payload.companyId;
          state.principal.employeeId = action.payload.employeeId;
          state.principal.organizationId = action.payload.organizationId;
          state.principal.affiliatedOrganizations = action.payload.affiliatedOrganizations;
          state.principal.roles = action.payload.roles;
          state.principal.email = action.payload.email;

          state.jwt = jwt;
          state.authenticated = true;
          state.isSessionLogin = action.payload.isSessionLogin;
          state.languages = action.payload.languages;

          state.requestId = undefined;
          state.signin.status = (jwt) ? 'unmounted' : 'failure';
          state.signin.errorMessage = undefined;

          state.modules = action.payload.modules;
          state.initialModule = action.payload.initialModule;
        }
      })
      .addCase(signin.rejected, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );
          state.requestId = undefined;

          if (action.payload instanceof Object) {
            if (action.meta.arg.tenantId !== undefined)
              state.errors = [ ...state.errors, action.payload as ApiError ];
            else
              state.signin.errorMessage = (action.payload as ApiError).message;
          }

          state.signin.status = 'idle';

          state.jwt = undefined;
          state.authenticated = false;
          state.loading.text = '';
          state.modules = [];
        }
      })
      .addCase(apiSignin.rejected, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );
          state.requestId = undefined;

          state.signin.errorMessage = (action.payload as ApiError).message;
          state.signin.status = 'idle';

          state.jwt = undefined;
          state.authenticated = false;
          state.loading.text = '';
        }
      })
      .addCase(viewSign.rejected, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );
          state.requestId = undefined;

          state.signin.errorMessage = (action.payload as ApiError).message;
          state.signin.status = 'idle';

          state.jwt = undefined;
          state.authenticated = false;
          state.loading.text = '';
        }
      })
      .addCase(jwtAuthenticate.rejected, (state, action) => {
        // 요청 아이디가 현재 요청 아이디와 같은 경우.
        if (state.requestId === action.meta.requestId) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );
          state.requestId = undefined;

          if (action.payload instanceof Object)
            state.signin.errorMessage = (action.payload as ApiError).message;

          state.signin.status = 'idle';

          state.jwt = undefined;
          state.authenticated = false;
          state.loading.text = '';
        }
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.principal = {
          ...state.principal,
          companyId: meta.arg.companyId,
          employeeId: meta.arg.employeeId,

          organizationId: payload.organizationId,
          affiliatedOrganizations: payload.affiliatedOrganizations,
          roles: payload.roles,
          email: payload.email,
        };
        state.basicSetting = payload.basicSetting;
        state.jwt = payload.jwt;
        state.authenticated = true;
        state.isSessionLogin = payload.isSessionLogin;
        state.languages = payload.languages;
        // state.initialModule = payload.initialModule;
        // state.modules = payload.modules;
      })
      .addCase(fetchBasicSetting.fulfilled, (state, { payload }) => {
        state.basicSetting = payload;
      })
      .addCase(saveBasicSetting.fulfilled, (state, { payload }) => {
        state.basicSetting = payload;
      })
      .addCase(themeColor.fulfilled, (state, { payload }) => {
        state.basicSetting = {
          ...state.basicSetting,
          ...payload,
        };
      })
      .addCase(themeMode.fulfilled, (state, { payload }) => {
        state.basicSetting = {
          ...state.basicSetting,
          ...payload,
        };
      })
      .addCase(setLanguage.fulfilled, (state, { payload }) => {
        state.basicSetting = {
          ...state.basicSetting,
          ...payload,
        };
      })

      .addMatcher(asyncThunkPending, (state, action) => {
        // console.log(`session.asyncThunkPending()`, action);
        const typePrefix = action.type.substring(
          0,
          action.type.lastIndexOf('/'),
        );
        const request = state.requests.find((x) => x.type === typePrefix);
        // console.log(`session.asyncThunkPending():typePrefix:'${typePrefix}'`, request);
        if (request !== undefined) {
          request.id = action.meta.requestId;
          request.arg = action.meta.arg;
        } else {
          state.requests.push({
            type: typePrefix,
            id: action.meta.requestId,
            arg: action.meta.arg,
          });
        }
        const metaArg: string[] =
          action.meta.arg ? Object.keys(action.meta.arg) : [];

        if (metaArg.includes('noLoading')) state.loading.text = ''; // 로딩 표시 안함.
        else state.loading.text = 'loading...'; // 로딩 표시 함.
      })
      .addMatcher(asyncThunkFulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
          undefined
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );
          if (state.requests.length === 0) {
            state.loading.text = '';
          }

          if (action.meta.arg instanceof Object) {
            const { route } = action.meta.arg as LocateArg;
            if (route !== undefined) {
              const paths = parseUris(route.pathname).map((x) => {
                if (x === '{response_id}') {
                  if (action.payload?._response_id_ === undefined)
                    return undefined;
                  return `${base62.encode(action.payload?._response_id_)}`;
                }
                return x;
              });
              const pathname = paths.length > 0 ? `/${paths.join('/')}` : '';
              const { search = '', hash = '' } = route;
              let key = route.key ?? '';

              // if (state.route.pathname === route.pathname) {
              //   console.log(`222-3 pathname:: new key route.pathname:${route.pathname}, route.pathname:${route.pathname}`);
              // }
              // if (state.route.search === search) {
              //   console.log(`222-3 search:: new key state.route.search:${state.route.search}, search:${search}`);
              // }
              // if (state.route.hash === hash) {
              //   console.log(`222-3 hash:: new key state.route.hash:${state.route.hash}, hash:${hash}`);
              // }
              // if (state.route.key === key) {
              //   console.log(`222-3 key:: new key state.route.key:${state.route.key}, key:${key}`);
              // }
              if (state.route.pathname === route.pathname && state.route.search === search && state.route.key === route.key && (hash === '' || hash === '#' || state.route.hash === hash)) {
                key = CreateRandomString();
              }

              if (state.errors.length > 0 && (state.route.pathname !== pathname || state.route.search !== search || state.route.hash !== hash)) {
                state.errors = [];
              }

              state.route.pathname = pathname;
              state.route.search = search;
              state.route.hash = hash.charAt(0) !== '#' ? `#${hash}` : hash;
              state.route.key = key;
            }
          }
        }
      })
      .addMatcher(asyncThunkRejected, (state, action) => {
        // console.log(`----------asyncThunkRejected`);
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
          undefined
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );
          if (state.requests.length === 0) {
            state.loading.text = '';
          }

          if (action.payload instanceof Object) {
            state.errors = [ ...state.errors, action.payload as ApiError ];
          }
          if (action.meta.arg instanceof Object) {
            // const { route } = action.meta.arg as LocateArg;
            // if (route !== undefined) {
            //   state.route.pathname = route.pathname ?? '';
            //   state.route.search = route.search ?? '';
            //   state.route.hash = route.hash ?? '';
            // }
          }

        }
      });
  },
});

export default sessionSlice.reducer;

export const { loadingToggle } = sessionSlice.actions;

export const sessionActions = {
  setRoute: sessionSlice.actions.setRoute,

  setDialog: sessionSlice.actions.setDialog,
  setDrawer: sessionSlice.actions.setDrawer,
  search: sessionSlice.actions.search,
  hash: sessionSlice.actions.hash,

  // 팀즈용 리소스 변경 테스트 액션.
  setResource: sessionSlice.actions.setResource,

  themeColor,
  themeMode,
  display: sessionSlice.actions.display,
  mobileNav: sessionSlice.actions.mobileNavVisible,


  error: sessionSlice.actions.error,
  errorDelete: sessionSlice.actions.errorDelete,

  signout: sessionSlice.actions.signout,
  signin,

  apiSignin,
  clearApprovalForm: sessionSlice.actions.clearApprovalForm,
  viewSign,

  bypass,
  jwtAuthenticate,
  authenticate,
  setSyncLoading: sessionSlice.actions.setSyncLoading,

  setLanguage,

  basicSetting: fetchBasicSetting,
  saveBasicSetting,
};

/*
createAsyncThunk 로 대체 가능
// https://blog.woolta.com/categories/1/posts/204
export const fetch = (): AppThunk => async dispatch => {
  try {
    dispatch(search());
    const data = find();
    dispatch(search());
  } catch (err) {
    dispatch(search());
  }
}
*/
