import React from 'react';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import EuiSetting from '../../../components/layout/EuiSetting';
import PostView from '../../../components/post/PostView';

function SystemLinkWorkContentFolderView(props: {
  paths: string[];
  name: string;
}): JSX.Element {
  const { paths, name } = props;
  return (
    <>
      <EuiSetting.Header title="분류정보" />
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title="분류명">
                <PostView.CategoryValue value={name} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default SystemLinkWorkContentFolderView;
