import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CreateRandomString,
  moduleIcon,
} from '../../groupware-common/utils/ui';
import GnbItem from './GnbItem';
import { Module } from '../../groupware-common/types';
import Avatar from '../avatar/Avatar';
import Tooltip from '../tooltip/Tooltip';
import EuiGnb from '../layout/EuiGnb';
import { go } from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';

function Gnb(props: {
  id: string;
  items: Array<{ id: Module; text: string }>;
  employeeName: string;
  avatar?: string;
  onItemClick(id: string): void;
  onMenu(event: React.MouseEvent): void;
}): JSX.Element {
  const person = useSelector(
    (state: RootState) => state.setting.information.person,
  );
  const isSessionLogin = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );
  // 개발 환경 유무.
  const isDevelopment =
    window.location.hostname === 'localhost' || !isSessionLogin;

  const [key, setKey] = useState(CreateRandomString());
  useEffect(() => {
    setKey(CreateRandomString());
  }, [person]);

  const items = props.items
    .filter((a) => a.id !== 'directory')
    .map((a) => {
      return (
        <GnbItem
          key={a.id}
          id={a.id}
          text={a.text}
          icon={moduleIcon(a.id)}
          selected={props.id === a.id}
          onClick={props.onItemClick}
        />
      );
    });

  const { employeeName, avatar } = props;

  return (
    <EuiGnb>
      <div className="nav">{items}</div>
      {/* TODO 삭제 */}
      {/* <div className="service">
        {isDevelopment && (
          <GnbItem id="service" text="전체서비스" icon="grid-9" />
        )}
        {isDevelopment && (
          <GnbItem id="service" text="홈으로" icon="home-fill" />
        )}
      </div> */}
      <div className="utility">
        {(isDevelopment || props.items.some((a) => a.id === 'directory')) && (
          <GnbItem
            id="organizationChart"
            text="조직도"
            icon="sitemap-fill"
            onClick={() => go('/directory')}
          />
        )}
        {isDevelopment && (
          <GnbItem id="notifications" text="알림" icon="bell" />
        )}
        <Tooltip title="기본 정보" placement="right">
          <a className="menu my" onClick={props.onMenu}>
            <Avatar
              key={key}
              image={avatar}
              name={employeeName}
              className="avatar"
            />
          </a>
        </Tooltip>
      </div>
    </EuiGnb>
  );
}

export default Gnb;
