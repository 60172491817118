import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/button/Button';
import EuiSnb from '../../../../components/layout/EuiSnb';
import MenuItem from '../../../../components/menu/MenuItem';
import { IconType } from '../../../../groupware-common/types/icon';
import {
  b62,
  getPathMap,
  getQueryParams,
  getText,
  go,
} from '../../../../groupware-common/utils';
import Tree from '../../../../components/tree/Tree';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import Tab from '../../../../components/tab/Tab';
import DropMenu from '../../../../components/selectField/DropMenu';
import TreePicker from '../../../../groupware-webapp/pages/popup/TreePicker';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import Nav from '../../../../components/menu/Nav';
import { archiveFolderActions } from '../../../stores/approval/archive';
import { getOrganizationName } from '../../../../groupware-directory/stores/directory';
import MenuDivider from '../../../../components/menu/MenuDivider';

function ApprovalDrawer(props: {
  folderId: number | string | undefined;
  pathname: string;
}) {
  // console.log(`${ApprovalDrawer.name}.render`);

  const dispatch = useAppDispatch();

  const resource = useSelector((state: RootState) => state.session.resource);

  const categoryOptions = useSelector(
    (state: RootState) => state.approval2.document.category.options.data,
  );

  const categoryItemStatus = useSelector(
    (state: RootState) => state.approval2.document.category.itemStatus.data,
  );

  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  );

  const listFolder = useSelector(
    (state: RootState) => state.approval2.userPreferences.userPreferencesFolder,
  );

  const archiveOrganizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );

  const archiveFoldersData = useSelector(
    (state: RootState) => state.approval2.archive.folders.data,
  );

  /** 현재 로그인한 사용자 회사, 사원, 부서 키 */
  const principal = useSelector((state: RootState) => state.session.principal);

  /** 소속 조직 항목 배열. */
  const affiliatedOrganizationItems = principal.affiliatedOrganizations;

  const manager = principal.affiliatedOrganizations.find(
    (a) => a.id === archiveOrganizationId,
  )?.manager;

  /** TODO 기록물철 관리자, 회사관리자 전체 트리 구조로 수정해야됨 */
  const treeList = affiliatedOrganizationItems.map((a) => {
    const { id } = a;
    const name = getText(a.nameKey);
    const icon = 'folder' as IconType;
    const parentId = 0;

    return {
      id,
      parentId,
      text: name,
      icon,
    };
  });

  const privateFolders = listFolder
    .filter((x) => x.id !== 0)
    .map((a) => {
      const { id, name, seq } = a;
      const icon = 'folder' as IconType;
      const parentId = 0;

      return {
        id,
        parentId,
        text: name,
        icon,
        seq,
      };
    })
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  // console.log('privateFolders', privateFolders);

  const categoryItems = categories.map((x) => {
    const status = categoryItemStatus.find((y) => y.id === x.id);
    if (status) return { ...x, count: status.unseen };
    return x;
  });

  // 기록물
  const archiveFolders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[] = archiveFoldersData
    .filter(({ seq }) => (manager ? true : seq !== 0))
    .map(({ seq, id, parentId, name: text }) => ({
      seq,
      id,
      parentId,
      text,
      icon: 'folder' as const,
    }))
    .sort((a, b) => {
      // 미지정 폴더 마지막 위치로 정렬.
      if (a.seq === 0) return 1;
      if (b.seq === 0) return -1;
      return +(a.seq > b.seq) || +(a.seq === b.seq) - 1;
    });
  // 고정 폴더 이름
  const getDefaultFolderName = (id: number) => {
    const category = categories
      .filter((a) => a.type === 'default')
      .find((a) => a.id === id);
    if (!category) return undefined;
    return category.name;
  };

  const handleGo = (folderId: number | 'create') => {
    // console.log(`handleGo(folderId: ${folderId})`);
    const { pathname } = props;
    if (folderId === 'create') {
      const queryParams = getQueryParams('');
      queryParams.contentMode = 'create';
      go(pathname, queryParams);
    } else go(`${getPathMap('/*', pathname)}/${b62(folderId)}`);
    dispatch(sessionActions.mobileNav(false));
  };

  const menu: { index: number; label: string }[] = [
    { index: 0, label: '전자결재' },
    { index: 1, label: '기록물' },
  ];

  const [state, setState] = useState<{
    menuIndex: number;
    selectOrganizationVisible: boolean;
  }>({
    menuIndex: 0,
    selectOrganizationVisible: false,
  });

  const handleMenuChange = (index: number) => {
    setState((prevState) => ({
      ...prevState,
      menuIndex: index,
    }));
  };

  const handleOpenSelectOrganization = () => {
    setState((prevState) => ({
      ...prevState,
      selectOrganizationVisible: true,
    }));
  };

  const handleCloseSelectOrganization = () => {
    setState((prevState) => ({
      ...prevState,
      selectOrganizationVisible: false,
    }));
  };

  const handleClickSelectedOrganization = (
    id: number,
    // text: string,
    // path: string[],
  ) => {
    // console.log(id, text, path);
    dispatch(
      archiveFolderActions.findAll({
        data: { organizationId: id, current: true },
      }),
    );
    handleCloseSelectOrganization();
  };

  const getDialog = () => {
    if (state.selectOrganizationVisible)
      return (
        <TreePicker
          title="부서 선택"
          list={treeList}
          onSelected={handleClickSelectedOrganization}
          onClose={handleCloseSelectOrganization}
        />
      );
    return null;
  };

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title="결재" />
        <EuiSnb.Action>
          <Button
            text="결재작성"
            variant="contained"
            block
            onClick={() => handleGo('create')}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Tab>
        <Tab>
          {menu.map((x, index) => (
            <Tab.Item
              key={x.index}
              label={x.label}
              selected={x.index === state.menuIndex}
              onClick={() => handleMenuChange(index)}
            />
          ))}
        </Tab>
        {/** 기록물 탭이고 겸직자인 경우. */}
        {state.menuIndex === 1 && affiliatedOrganizationItems.length > 1 && (
          <DropMenu
            value={getOrganizationName(
              principal.companyId,
              archiveOrganizationId,
            )}
            label="부서 선택"
            onClick={handleOpenSelectOrganization}
            block
          />
        )}
      </EuiSnb.Tab>
      <EuiSnb.Nav>
        {state.menuIndex === 1 ? (
          <Nav>
            <Tree
              selectedId={props.folderId}
              items={archiveFolders}
              onItemClick={handleGo}
            />
            {manager === true && (
              <MenuItem
                label={getDefaultFolderName(5004)}
                icon="cog-fill"
                selected={props.folderId === 'archivesettings'}
                onClick={() => go('/approval/archivesettings')}
              />
            )}
          </Nav>
        ) : (
          <>
            <Nav>
              <MenuItem
                label={getDefaultFolderName(5001)}
                icon="module-fill"
                selected={props.folderId === undefined}
                onClick={() => {
                  go('/approval');
                  dispatch(sessionActions.mobileNav(false));
                }}
              />
              <MenuItem
                label={getDefaultFolderName(5002)}
                icon="line-dot"
                selected={props.folderId === 'status'}
                onClick={() => {
                  go('/approval/status');
                  dispatch(sessionActions.mobileNav(false));
                }}
              />
            </Nav>

            {categoryOptions.status.use && (
              <Nav title="결재진행" expanded={categoryOptions.status.extended}>
                {categoryItems
                  .filter((x) => x.type === 'status')
                  .map((x) => (
                    <MenuItem
                      key={x.id}
                      label={getText(`folder.${x.name}`, 'approval')}
                      icon={x.icon}
                      selected={x.id === props.folderId}
                      onClick={() => handleGo(x.id)}
                      count={x.count}
                    />
                  ))}
              </Nav>
            )}
            {categoryOptions.send.use && (
              <Nav title="부서결재함" expanded={categoryOptions.send.extended}>
                {categoryItems
                  .filter((x) => x.type === 'organization')
                  .map((x) => (
                    <MenuItem
                      key={x.id}
                      label={getText(`folder.${x.name}`, 'approval')}
                      icon={x.icon}
                      selected={x.id === props.folderId}
                      onClick={() => handleGo(x.id)}
                      count={x.count}
                    />
                  ))}
              </Nav>
            )}
            {categoryOptions.shared.use && (
              <Nav title="회람문서" expanded={categoryOptions.shared.extended}>
                {categoryItems
                  .filter((x) => x.type === 'shared')
                  .map((x) => (
                    <MenuItem
                      key={x.id}
                      label={getText(`folder.${x.name}`, 'approval')}
                      icon={x.icon}
                      selected={x.id === props.folderId}
                      onClick={() => handleGo(x.id)}
                    />
                  ))}
              </Nav>
            )}
            {categoryOptions.private.use && privateFolders.length > 0 && (
              <Nav title="개인함" expanded={categoryOptions.private.extended}>
                <Tree
                  selectedId={props.folderId}
                  items={privateFolders}
                  onItemClick={handleGo}
                />
              </Nav>
            )}
            <MenuDivider />
            <MenuItem
              label={getDefaultFolderName(5003)}
              icon="cog-fill"
              selected={props.folderId === 'preferences'}
              onClick={() => {
                go('/approval/preferences');
                dispatch(sessionActions.mobileNav(false));
              }}
            />
            {resource === 'teams' &&
              principal.roles.find(
                (role) => role === 'ADMIN' || role === 'APPROVAL_ADMIN',
              ) && (
                <MenuItem
                  label="결재설정"
                  icon="user-cog"
                  onClick={() => go('/adminconsole/approval')}
                />
              )}
          </>
        )}
      </EuiSnb.Nav>
      {getDialog()}
    </>
  );
}

export default React.memo(ApprovalDrawer);
