import React from 'react';
import FileChip from './FileChip';

function FileChipGroup(props: {
  data: {
    id: number;
    name: string;
  }[];
  className?: string;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onDownload?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id?: number,
    name?: string,
  ): void;
  max?: number;
}): JSX.Element {
  const { className, data, max } = props;

  let classname = 'eui-chip-group file-chip';
  if (className) classname += ` ${className}`;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.onClick) props.onClick(event);
  };

  const handleDownload = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    name: string,
  ) => {
    if (props.onDownload) props.onDownload(event, id, name);
  };

  const more = max !== undefined && max < data.length && (
    <div className="eui-chip more">+{data.length - max}</div>
  );

  const list = () => {
    const items: JSX.Element[] = new Array<JSX.Element>();

    const maxLength =
      max !== undefined && max < data.length ? max : data.length;

    for (let i = 0; i < maxLength; i += 1) {
      items.push(
        <FileChip
          key={`${i}_${data[i].id}`}
          label={data[i].name}
          filename={data[i].name}
          onDownload={(event) =>
            handleDownload(event, data[i].id, data[i].name)
          }
          onClick={handleClick}
        />,
      );
    }
    return items;
  };

  return (
    <div className={classname}>
      {list()}
      {more}
    </div>
  );
}

export default FileChipGroup;
