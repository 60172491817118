import React from 'react';
import Icon from '../../../../../components/icon/Icon';
import { IconType } from '../../../../../groupware-common/types/icon';

function ContactItem(props: {
  label: React.ReactNode | string;
  count?: number;
  icon?: IconType;
  color?: string;
  width?: string;
  className?: string;
  expanded?: boolean;
  itemDisabled?: boolean; // 선택 불가능한 아이템인 경우 true.
  disabled?: boolean;
  selected?: boolean;
  childrenCount?: number;
  id: number;
  onClick(id: number): void;
  onSettingClick?(id: number): void;
  onDeleteClick?(id: number): void;
  onToggleExpanded?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  children?: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-nav-item contacts-item';
  if (props.selected) classname += ' selected';
  if (props.className) classname += ` ${props.className}`;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    props.onClick(props.id);
  };

  const handleSettingClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onSettingClick !== undefined) props.onSettingClick(props.id);
  };

  const handleDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onDeleteClick !== undefined) props.onDeleteClick(props.id);
  };

  let style: React.CSSProperties | undefined;
  if (props.width) style = { width: props.width };
  if (props.itemDisabled)
    style = {
      ...style,
      color: 'var(--disabled-text-color)',
      cursor: 'default',
    };

  return (
    <a
      style={style}
      className={classname}
      onClick={props.itemDisabled ? undefined : handleClick}
      aria-disabled={props.disabled}
    >
      {/* 펼치기 접기 토글 */}
      {props.onToggleExpanded &&
        props.childrenCount !== undefined &&
        props.childrenCount > 0 && (
          <button
            type="button"
            style={props.itemDisabled ? { color: 'initial' } : undefined}
            className="nav-toggle"
            onClick={props.onToggleExpanded}
            aria-expanded={props.expanded}
          >
            <span>{props.expanded ? '메뉴 접기' : '메뉴 펼치기'}</span>
          </button>
        )}
      {/* 아이콘 */}
      {props.icon && (
        <Icon className="nav-badge" icon={props.icon} color={props.color} />
      )}
      {/* 라벨 */}
      <span className="nav-label">{props.label}</span>
      {/* children */}
      {props.children}
      {props.count !== undefined && props.count !== 0 && (
        <span className="nav-label-count">{props.count}</span>
      )}
      <button
        style={{
          color: props.selected
            ? 'var(--primary-color)'
            : 'var(--body-text-color)',
          paddingRight: '12px',
        }}
        type="button"
        className="nav-label-setting"
        onClick={handleSettingClick}
        aria-label="setting"
      />
      <button
        style={{
          color: props.selected
            ? 'var(--primary-color)'
            : 'var(--body-text-color)',
          paddingRight: '24px',
        }}
        type="button"
        className="nav-label-delete"
        onClick={handleDeleteClick}
        aria-label="delete"
      />
    </a>
  );
}

export default ContactItem;
