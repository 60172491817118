import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Alert from '../../../../../components/alert/Alert';
import Button from '../../../../../components/button/Button';

function ComponentAlertActions(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Actions">
      <Alert severity="success" onClose={() => alert('클릭 이벤트')}>
        This is a success alert — check it out!
      </Alert>
      <Alert
        severity="success"
        action={
          <Button
            text="UNDO"
            color="inherit"
            size="sm"
            onClick={() => alert('클릭 이벤트')}
          />
        }
      >
        This is a success alert — check it out!
      </Alert>
    </ComponentSampleUI.Content>
  );
}

export default ComponentAlertActions;
