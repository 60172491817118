/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { DocumentData } from '../document';

/**
 * 조회 문서 조회.
 */
async function viewData(arg: {
  folderId: number;
  documentId: number;
  affiliatedCompanyId?: number;
}) {
  const { affiliatedCompanyId, folderId, documentId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/permission/folders/${folderId}/documents/${documentId}`;
    const response = await axios.get<DocumentData>(url, {
      headers,
      params: {
        affiliatedCompanyId,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 참조 문서 조회.
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @returns 문서 객체.(결재 문서 조회와 반환값 같음).
 */
async function referenceData(arg: {
  folderId: number;
  documentId: number;
  affiliatedCompanyId?: number;
}) {
  const { affiliatedCompanyId, folderId, documentId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/permission/folders/${folderId}/documents/${documentId}`;
    const response = await axios.get<DocumentData>(url, {
      headers,
      params: {
        affiliatedCompanyId,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const viewDocumentsApi = {
  data: viewData,
};

export const referenceDocumentsApi = {
  data: referenceData,
};
