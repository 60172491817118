import { createAsyncThunk } from '@reduxjs/toolkit';
import { LocateArg } from '../../../../../groupware-common/types';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import personalDocumentApi from '../../../../apis/approval/v1/personal/document';
import { documentActions } from '../../document';

const name = 'approval/personal/document';

const copy = createAsyncThunk(
  `${name}/copy`,
  async (
    arg: {
      data: {
        folderId: number;
        affiliatedCompanyId?: number;
        id: number;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      return await personalDocumentApi.copy(data);
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const move = createAsyncThunk(
  `${name}/move`,
  async (
    arg: {
      data: {
        sourceFolderId: number;
        targetFolderId: number;
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route, location } = arg;
      if (data.length === 0) return undefined;
      const responese = await personalDocumentApi.move(data);

      // location 값으로 경로 이동이 없는 경우.
      // (목록에서 호출된 경우 location 값이 없고 보기에서 호출된 경우 location 값이 있음)
      if (location === undefined) {
        const { sourceFolderId: folderId } = data[0];
        const search =
          route?.search ?? (getState() as RootState).session.route.search;
        await dispatch(documentActions.list({ folderId, search }));
      }

      return responese;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const _delete = createAsyncThunk(
  `${name}/delete`,
  async (
    arg: {
      data: {
        folderId: number;
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route, location } = arg;
      if (data.length === 0) return undefined;
      const responese = await personalDocumentApi.delete(data);

      // location 값으로 경로 이동이 없는 경우.
      // (목록에서 호출된 경우 location 값이 없고 보기에서 호출된 경우 location 값이 있음)
      if (location === undefined) {
        const { folderId } = data[0];
        const search =
          route?.search ?? (getState() as RootState).session.route.search;
        await dispatch(documentActions.list({ folderId, search }));
      }

      return responese;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const personalDocumentActions = {
  copy,
  move,
  delete: _delete,
};

export default personalDocumentActions;
