import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconType } from '../../groupware-common/types/icon';
import Button from '../button/Button';
import Menu from '../menu/Menu';
import MenuItem from '../menu/MenuItem';

export interface ActionEventProps {
  code: string;
  label: string;
  type: 'contained' | 'outlined' | 'icon' | 'text';
  icon: IconType;
}

function ToolbarAction(props: {
  event: ActionEventProps[];
  onClick(arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }): void;
}): JSX.Element {
  const actionWrapRef = useRef<HTMLDivElement>(null);
  const actionContentRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useMemo(
    () =>
      Array(props.event.length)
        .fill(0)
        .map(() => React.createRef<HTMLButtonElement>()),
    [],
  );

  const [state, setState] = useState<{
    moreRect:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    actionArea: {
      wrapWidth: number;
      contentWidth: number;
    };
  }>({
    moreRect: undefined,
    actionArea: {
      wrapWidth: actionWrapRef.current?.offsetWidth || 0,
      contentWidth: actionContentRef.current?.offsetWidth || 0,
    },
  });

  const handleMore = (event?: React.MouseEvent) => {
    if (state.moreRect) handleMoreClose();
    else if (event) {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        moreRect: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  const handleMoreClose = () => {
    setState((prevState) => ({
      ...prevState,
      moreRect: undefined,
    }));
  };

  const handleResize = () => {
    setState((prevState) => ({
      ...prevState,
      actionArea: {
        ...prevState.actionArea,
        wrapWidth: actionWrapRef.current?.offsetWidth || 0,
        contentWidth: actionContentRef.current?.offsetWidth || 0,
      },
    }));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    setState((prevState) => ({
      ...prevState,
      actionArea: {
        ...prevState.actionArea,
        wrapWidth: actionWrapRef.current?.offsetWidth || 0,
        contentWidth: actionContentRef.current?.offsetWidth || 0,
      },
    }));

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [state.actionArea.wrapWidth, props.event]);

  const handleClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    props.onClick({ code: arg.code, event: arg.event });
  };

  return (
    <>
      <div className="action-wrap" ref={actionWrapRef}>
        <div className="action-content" ref={actionContentRef}>
          {props.event.map((x, i) => {
            let hidden = false;
            try {
              const ref = buttonRefs[i]?.current;
              if (ref) {
                if (actionWrapRef.current) {
                  hidden =
                    actionWrapRef.current.offsetWidth <
                    ref.offsetLeft + ref.offsetWidth;
                }
              }
              return x.type === 'icon' ? (
                <Button
                  ref={buttonRefs[i]}
                  key={x.code}
                  text={x.label}
                  iconType
                  icon={x.icon}
                  onClick={(event) => handleClick({ code: x.code, event })}
                  clip={hidden}
                />
              ) : (
                <Button
                  ref={buttonRefs[i]}
                  key={x.code}
                  text={x.label}
                  icon={x.icon}
                  variant={x.type as undefined | 'contained' | 'outlined'}
                  onClick={(event) => handleClick({ code: x.code, event })}
                  clip={hidden}
                  size="sm"
                />
              );
            } catch (ex) {
              return null;
            }
          })}
        </div>
      </div>
      {state.actionArea.wrapWidth < state.actionArea.contentWidth && (
        <>
          <Button
            text="더보기"
            iconType
            icon="ellipsis-h"
            onClick={handleMore}
            className="action-more"
          />
          {state.moreRect && (
            <Menu point={state.moreRect} onClose={handleMoreClose}>
              {buttonRefs.map((a, i) => {
                if (
                  actionWrapRef.current &&
                  a.current &&
                  actionWrapRef.current.offsetWidth <
                    a.current.offsetLeft + a.current.offsetWidth
                )
                  return (
                    <MenuItem
                      key={props.event[i].code}
                      label={props.event[i].label}
                      icon={props.event[i].icon}
                      onClick={(event) => {
                        handleClick({
                          code: props.event[i].code,
                          event,
                        });
                        handleMoreClose();
                      }}
                    />
                  );
                return null;
              })}
            </Menu>
          )}
        </>
      )}
    </>
  );
}

export default ToolbarAction;
