import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import SystemEverSettingContainer from '../pages/company/SystemEverSettingContainer';
import SystemLinkFormContainer from '../pages/form/SystemLinkFormContainer';
import SystemLinkWorkContainer from '../pages/work/SystemLinkWorkContainer';

function SystemLinkLocateContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element | null {
  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  if (menu === 'systemever') {
    return <SystemEverSettingContainer />;
  }
  if (menu === 'form') {
    return <SystemLinkFormContainer pathname={pathname} search={search} />;
  }
  if (menu === 'work') {
    return <SystemLinkWorkContainer pathname={pathname} search={search} />;
  }
  return null;
}

export default SystemLinkLocateContainer;
