import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AppDispatchAbort,
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { surrogateApprovalActions } from '../../../../stores/approval/surrogate-approval';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';
import { documentActions } from '../../../../stores/approval/document';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function ApprovallinkageViewRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();
  const viewForm = useSelector((state: RootState) => state.session.viewForm);

  useEffect(() => {
    let mount = true;
    let promise: AppDispatchAbort | undefined;

    Promise.all([
      dispatch(surrogateApprovalActions.designators()),
      dispatch(approvaluserPreferencesActions.fetchPreferencesFolder()),
    ]).then(() => {
      if (mount) {
        if (viewForm) {
          const { documentId } = viewForm.provision;
          promise = dispatch(
            documentActions.linkedView({
              id: documentId,
              route: {
                pathname,
                search,
              },
            }),
          );
        } else dispatch(sessionActions.setRoute({ pathname, search }));
      }
    });

    return () => {
      mount = false;
      if (promise !== undefined) promise.abort();
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default ApprovallinkageViewRoute;
