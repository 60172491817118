import React from 'react';
import ComponentRadioBasic from './ComponentRadioBasic';
import ComponentRadioGroup from './ComponentRadioGroup';

function ComponentRadio(): JSX.Element {
  return (
    <>
      <ComponentRadioBasic />
      <ComponentRadioGroup />
    </>
  );
}

export default ComponentRadio;
