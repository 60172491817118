/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { DocumentItem } from '../permission';
import { DocumentData } from '../document';
import { AdvancedSearchParams } from '../../../../../groupware-common/utils';
/**
 * 문서의 이전 또는 다음 문서 아이디를 조회합니다.
 * @param arg.type 이전 또는 다음.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 이전 또는 다음 문서 아이디 객체 또는 undefined.
 */
async function readPrevIdOrNextId(arg: {
  type: 'PREV' | 'NEXT';
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  const { type, documentId, folderId, advancedSearch } = arg;

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/organization/folders/${folderId}/documents/${documentId}/${type}`;
    const response = await axios.get(url, {
      headers,
      params: {
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서의 이전 문서 아이디를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 이전 문서 아이디 객체 또는 undefined.
 */
async function prevId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'PREV' });
}

/**
 * 문서의 다음 문서 아이디를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 다음 문서 아이디 객체 또는 undefined.
 */
async function nextId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'NEXT' });
}

/**
 * 문서 목록을 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function documents(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<{
  count?: number; // 문서 총 갯수.
  list: DocumentItem[];
}> {
  const { folderId, pageno, rowsperpage, advancedSearch } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/organization/folders/${folderId}/documents`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서 총 개수를 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function totalCount(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}) {
  try {
    const { folderId, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/organization/folders/${folderId}/documents/count`;
    const response = await axios.get<{ count: number }>(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data.count;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서를 조회합니다.
 * @param affiliatedCompanyId 문서 관계사 회사 아이디. (관계사 문서인 경우)
 * @param id 문서 아이디.
 * @param organizationIds 조직 아이디 배열.
 * @returns 문서 객체.
 */
// eslint-disable-next-line no-underscore-dangle
async function data(arg: {
  folderId: number;
  documentId: number;
  affiliatedCompanyId?: number;
}): Promise<DocumentData> {
  const { folderId, documentId, affiliatedCompanyId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/organization/folders/${folderId}/documents/${documentId}`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedCompanyId,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const organizationApi = {
  documents,
  totalCount,
  prevId,
  nextId,
  data,
};

export default organizationApi;
