import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language, LocateArg } from '../../../groupware-common/types';
import i18nApi from '../../apis/i18n/v1';

const name = 'i18n';

export interface LocaleString {
  code: Language;
  value: string;
}

interface State {
  host: string;
  locales: Record<
    Language,
    Record<string, { lastUpdateAt: string; timestamp: number }>
  >;
}

const initialState: State = {
  host: 'http://localhost:8080',
  locales: {} as Record<
    Language,
    Record<string, { lastUpdateAt: string; timestamp: number }>
  >,
};

const findLastupdateAt = createAsyncThunk(
  `${name}/findLastupdateAt`,
  async (
    arg: {
      language: string;
      namespace: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await i18nApi.findLastupdateAt(arg);
      return data.lastUpdateAt;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const i18nSlice = createSlice({
  name,
  initialState,
  reducers: {
    localeTimestampUpdate(
      state,
      action: PayloadAction<{
        language: Language;
        namespace: string;
        lastUpdateAt: string;
        timestamp: number;
      }>,
    ) {
      const { language, namespace, lastUpdateAt, timestamp } = action.payload;

      // 언어 값이 없는 경우 초기 값 설정.
      if (!state.locales[language]) {
        state.locales[language] = {
          [namespace]: {
            lastUpdateAt: '1000-01-01T00:00:00.000000',
            timestamp: 0,
          },
        };
      }

      // 언어 값에 해당 하는 네임스페이스 값 설정.
      state.locales[language] = {
        ...state.locales[language],
        [namespace]: {
          ...state.locales[language][namespace],
          lastUpdateAt,
          timestamp,
        },
      };
    },
  },
});

export default i18nSlice.reducer;

export const i18nActions = {
  localeTimestampUpdate: i18nSlice.actions.localeTimestampUpdate,
  findLastupdateAt,
};
