import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { formFolderActions } from '../../../../stores/form';
import { folderBoxActions } from '../../../../stores/folder';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function BoardFolderBoxRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    Promise.all([
      dispatch(folderBoxActions.clearFolderView()),
      dispatch(formFolderActions.folderList({})),
      dispatch(folderBoxActions.allFolderList()),
    ]).then(() => {
      const route = { pathname, search, clearErrors: true };
      if (mount) dispatch(sessionActions.setRoute(route));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default BoardFolderBoxRoute;
