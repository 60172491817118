import React from 'react';
import { getQueryParams } from '../../groupware-common/utils';
import BoardComposeContainer from '../pages/root/board/compose/BoardComposeContainer';
import BoardContentContainer from '../pages/root/board/content/BoardContentContainer';

function RootLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash, locationKey } = props;
  const { contentMode } = getQueryParams(search);

  if (contentMode === 'create' || contentMode === 'update')
    return (
      <BoardComposeContainer
        pathname={pathname}
        search={search}
        key={locationKey}
      />
    );

  return <BoardContentContainer pathname={pathname} search={search} />;
}

export default RootLocateContainer;
