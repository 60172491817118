import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentButton(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Icon button">
      <Button text="이전" iconType icon="step-backward" />
      <Button text="재생" iconType icon="play" />
      <Button text="다음" iconType icon="step-forward" />
      <Button text="추가" iconType icon="plus" disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentButton;
