/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../groupware-common/apis/common/v1';

async function signin(arg: { id: string; password: string }): Promise<string> {
  try {
    const { id, password } = arg;
    const { host } = getApiConfig();
    const url = `${host}/api/authenticate/v1/users/${id}/token`;
    const response = await axios.post(url, { password });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const sessionApi = { signin };

export default sessionApi;
