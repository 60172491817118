import { useEffect } from 'react';

import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import {
  formActions,
  formFolderActions,
} from '../../../../stores/document/form';

function DocumentFormRoute(props: { pathname: string }): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([dispatch(formActions.listClear())]).then(() => {
      dispatch(formFolderActions.list({ route: { pathname: props.pathname } }));
    });
  }, [dispatch, props.pathname]);
  return null;
}

export default DocumentFormRoute;
