class Editor {
  static getHtml(iframe: HTMLIFrameElement): string {
    if (iframe) {
      const id = iframe.getAttribute('id') ?? '';
      const contentObject = iframe.contentWindow || iframe.contentDocument;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (contentObject) {
        try {
          const editor = (contentObject as any).getDEXT5();
          return editor.getBodyValue(id);
        } catch (error) {
          return '';
        }
      }
      return '';
    }
    return '';
  }

  static getHtmlElement(iframe: HTMLIFrameElement): HTMLElement | null {
    if (iframe) {
      const id = iframe.getAttribute('id') ?? '';
      const contentObject = iframe.contentWindow || iframe.contentDocument;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (contentObject) {
        try {
          const editor = (contentObject as any).getDEXT5();
          return editor.getDext5BodyDom(id) as HTMLElement;
        } catch (error) {
          return null;
        }
      }
      return null;
    }
    return null;
  }
}

export default Editor;
