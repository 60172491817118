import React from 'react';
import { getText } from '../../groupware-common/utils';

function ErrorPage(props: { error?: string; type?: string }): JSX.Element {
  const { type } = props;

  if (props.error) {
    const serverError = 'error:status.INTERNAL_SERVER_ERROR';
    const error = type
      ? `error:status.n_${props.error.toUpperCase()}`
      : `error:status.${props.error.toUpperCase()}`;
    const message = type
      ? getText([error, serverError], {
          n: getText(type.toUpperCase(), 'code'),
        })
      : getText([error, serverError]);

    return (
      <div className="eui-error-page">
        <img src="/images/company_error_img.png" alt={props.error} />
        <h1>{message}</h1>
      </div>
    );
  }

  return (
    <div className="eui-error-page">
      <img src="/images/company_error_img.png" alt="서버 오류" />
      <h1>ERROR</h1>
      <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.</p>
      <p>입력하신 주소가 정확한지 다시 한 번 확인해주세요.</p>
    </div>
  );
}

export default ErrorPage;
