import React from 'react';
import ComponentHelperTextBasic from './ComponentHelperTextBasic';

function ComponentHelperText(): JSX.Element {
  return (
    <>
      <ComponentHelperTextBasic />
    </>
  );
}

export default ComponentHelperText;
