import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import AttendancePreferencesApi from '../../../../apis/attendance/v1/preferences';

function AttendanceCalendarSettingCodeDialog(): JSX.Element {
  const dispatch = useAppDispatch();

  const list = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );

  const [state, setState] = useState<{
    saveing: boolean;
    loading: boolean;
    validation: string;
  }>({
    saveing: false,
    loading: true,
    validation: '',
  });

  const [codeList, setCodeList] = useState(
    [] as {
      id: number;
      name: string;
      operationType: string;
      isEnable: boolean;
    }[],
  );

  useEffect(() => {
    async function run() {
      const calendarLinked =
        await AttendancePreferencesApi.linkedAttendanceCode();
      const data = calendarLinked.map((a) => {
        const code = list.find((x) => x.id === a.id);
        let operationType = '없음';
        if (code?.operationType === 1) operationType = '연차 차감';
        else if (code?.operationType === 2) operationType = '대휴 차감';
        else if (code?.operationType === 3) operationType = '대휴 발생';
        return {
          id: a.id,
          name: code?.name ?? '',
          operationType,
          isEnable: a.isEnable,
        };
      });
      setCodeList(data);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
    run();
  }, []);

  useEffect(() => {
    let mount = true;
    async function run() {
      const saveData = codeList.map((a) => ({
        id: a.id,
        isEnable: a.isEnable,
      }));
      await AttendancePreferencesApi.saveLinkedAttendanceCode(saveData);
      if (!mount) return;
      setState((prev) => ({
        ...prev,
        saveing: false,
        validation: '저장이 완료되었습니다.',
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  const codeColumns: DataGridColDef[] = [
    { field: 'checkbox', label: '', width: 56 },
    { field: 'name', label: '이름', minWidth: 100, align: 'center' },
    { field: 'operate', label: '구분', minWidth: 100, align: 'center' },
  ];

  const codeRows: DataGridRowsProps<number> = codeList.map((a) => {
    return [
      { type: 'checkbox' as const, id: a.id, value: a.isEnable },
      { type: 'text' as const, value: a.name },
      { type: 'text' as const, value: a.operationType },
    ];
  });

  /** 대화상자 닫기. */
  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  /** 체크 변경 이벤트. */
  const handleCodeChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked: isEnable } = event.currentTarget;
    setCodeList(
      codeList.map((a) => {
        if (a.id === id)
          return {
            ...a,
            isEnable,
          };
        return a;
      }),
    );
  };

  /** 리스트 저장 이벤트. */
  const handleSaveUseCalendarCode = () => {
    setState((prev) => ({
      ...prev,
      saveing: true,
    }));
  };

  /** 피드백 닫기 이벤트. */
  const handleCloseFeedBack = () => {
    setState((prev) => ({
      ...prev,
      validation: '',
    }));
  };

  return (
    <Dialog size="sm" onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>캘린더에서 보여줄 근태코드</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <DataGrid
          loading={state.loading}
          caption="visibleCode"
          columns={codeColumns}
          rows={codeRows}
          onChecked={handleCodeChangeChecked}
        />
      </DialogBody>
      <DialogFooter>
        {!state.saveing && (
          <Button text="취소" variant="outlined" onClick={handleDialogClose} />
        )}
        <Button
          loading={state.saveing}
          noDuplication={state.validation === ''}
          text="저장"
          variant="contained"
          onClick={handleSaveUseCalendarCode}
        />
      </DialogFooter>
      <FeedBack text={state.validation} onClose={handleCloseFeedBack} />
    </Dialog>
  );
}

export default AttendanceCalendarSettingCodeDialog;
