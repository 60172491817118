import React, { useRef } from 'react';

function DrawerBody(props: {
  scrollbar?: React.RefObject<HTMLDivElement>;
  className?: string;
  loading?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'drawer-content';
  if (props.className) classname += ` ${props.className}`;

  const rootRef = props.scrollbar
    ? props.scrollbar
    : useRef<HTMLDivElement>(null);
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const top = event.currentTarget.scrollTop;
    const title = document.getElementsByClassName('drawer-title')[0];
    const toolbar = document.getElementsByClassName('drawer-toolbar');

    if (top > 0) {
      if (toolbar.length > 0) {
        toolbar[toolbar.length - 1].setAttribute('data-scrolling', 'true');
      } else {
        title.setAttribute('data-scrolling', 'true');
      }
    }

    if (top === 0) {
      if (toolbar.length > 0) {
        toolbar[toolbar.length - 1].removeAttribute('data-scrolling');
      } else {
        title.removeAttribute('data-scrolling');
      }
    }
  };
  return (
    <div className={classname} ref={rootRef} onScroll={handleScroll}>
      {props.children}
      {props.loading && <div className="ui-inner-loading" />}
    </div>
  );
}

export default DrawerBody;
