import React, { useState } from 'react';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentCalendarTime(): JSX.Element {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <ComponentSampleUI.Content title="Time">
      <CustomDatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        dateFormat="a h:mm"
        showTimeSelect
        showTimeSelectOnly
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentCalendarTime;
