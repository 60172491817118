import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../..';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Divider from '../../../../../components/divider/Divider';
import MenuItem from '../../../../../components/menu/MenuItem';
import PostWrite from '../../../../../components/post/PostWrite';
import NavigationGuard from '../../../../../components/prompt/NavigationGuard';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import {
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { dateFormat } from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { contactsActions } from '../../../../stores/contacts';
import { labelActions } from '../../../../stores/labels';
import ContactExportDialog from '../common/ContactExportDialog';
import ContactImportDialog from '../common/ContactImportDialog';

function ContactComposeContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const display = useSelector((state: RootState) => state.session.display);
  const labels = useSelector((state: RootState) => state.contact.labels.list);
  const view = useSelector((state: RootState) => state.contact.contacts.view);
  const isPhone = display === 'phone';

  const phoneLocationType = [
    { value: '82', label: '대한민국 +82' },
    { value: '1', label: '미국, 캐나다 +1' },
    { value: '81', label: '일본 +81' },
    { value: '86', label: '중국 +86' },
    { value: '44', label: '영국 +44' },
    { value: '65', label: '싱가포르 +65' },
    { value: '233', label: '가나 +233' },
    { value: '241', label: '가봉 +241' },
    { value: '592', label: '가이아나 +592' },
    { value: '220', label: '감비아 +220' },
    { value: '590', label: '과들루프 +590' },
    { value: '502', label: '과테말라 +502' },
    { value: '30', label: '그리스 +30' },
    { value: '234', label: '나이지리아 +234' },
    { value: '31', label: '네덜란드 +31' },
    { value: '64', label: '뉴질랜드 +64' },
    { value: '886', label: '대만 +886' },
    { value: '49', label: '독일 +49' },
    { value: '7', label: '러시아 +7' },
    { value: '60', label: '말레이시아 +60' },
    { value: '52', label: '멕시코 +52' },
    { value: '212', label: '모로코 +212' },
    { value: '230', label: '모리셔스 +230' },
    { value: '84', label: '베트남 +84' },
    { value: '850', label: '북한 +850' },
    { value: '55', label: '브라질 +55' },
    { value: '966', label: '사우디아라비아 +966' },
    { value: '41', label: '스위스 +41' },
    { value: '34', label: '스페인 +34' },
    { value: '421', label: '슬로바키아 +421' },
    { value: '963', label: '시리아 +963' },
    { value: '54', label: '아르헨티나 +54' },
    { value: '213', label: '알제리 +213' },
    { value: '593', label: '에콰도르 +593' },
    { value: '251', label: '에티오피아 +251' },
    { value: '968', label: '오만 +968' },
    { value: '61', label: '오스트레일리아 +61' },
    { value: '43', label: '오스트리아 +43' },
    { value: '962', label: '요르단 +962' },
    { value: '964', label: '이라크 +964' },
    { value: '98', label: '이란 +98' },
    { value: '972', label: '이스라엘 +972' },
    { value: '20', label: '이집트 +20' },
    { value: '39', label: '이탈리아 +39' },
    { value: '91', label: '인도 +91' },
    { value: '62', label: '인도네시아 +62' },
    { value: '420', label: '체코 +420' },
    { value: '56', label: '칠레 +56' },
    { value: '974', label: '카타르 +974' },
    { value: '254', label: '케냐 +254' },
    { value: '57', label: '콜롬피아 +57' },
    { value: '965', label: '쿠웨이트 +965' },
    { value: '66', label: '타이 +66' },
    { value: '90', label: '터키 +90' },
    { value: '216', label: '튀니지 +216' },
    { value: '507', label: '파나마 +507' },
    { value: '595', label: '파라과이 +595' },
    { value: '680', label: '팔라우 +680' },
    { value: '51', label: '페루 +51' },
    { value: '48', label: '폴란드 +48' },
    { value: '33', label: '프랑스 +33' },
    { value: '679', label: '피지 +679' },
    { value: '63', label: '필리핀 +63' },
    { value: '852', label: '홍콩 +852' },
  ];
  const emailType = [
    { value: 'COMPANY', label: '회사' },
    { value: 'PERSONAL', label: '개인' },
    { value: 'CUSTOM', label: '직접 입력' },
  ];
  const phoneType = [
    { value: 'PHONE', label: '휴대폰' },
    { value: 'COMPANY', label: '회사' },
    { value: 'HOME', label: '집' },
    { value: 'FAX', label: 'FAX' },
    { value: 'CUSTOM', label: '직접 입력' },
  ];
  const addressType = [
    { value: 'COMPANY', label: '회사' },
    { value: 'HOME', label: '집' },
    { value: 'CUSTOM', label: '직접 입력' },
  ];
  const anniversaryType = [
    { value: 'ANNIVERSARY', label: '기념일' },
    { value: 'CUSTOM', label: '직접 입력' },
  ];

  const initialState = view
    ? {
        id: view.id,
        labels: view.labels,
        name: view.name,
        nickName: view.nickName,
        jobTitle: view.jobTitle,
        department: view.department,
        company: view.company,
        birthDay: view.birthDay !== '' ? new Date(view.birthDay) : null,
        emails: view.emails,
        phones: view.phones,
        addresses: view.addresses,
        anniversaries: view.anniversaries.map((a) => ({
          ...a,
          anniversary: new Date(a.anniversary),
        })),
        memo: view.memo,

        addLabel: false,
        addLabelName: '',
        addSaveing: false,

        validation: '',
      }
    : {
        labels: [],
        name: '',
        nickName: '',
        jobTitle: '',
        department: '',
        company: '',
        birthDay: null,
        emails: [],
        phones: [],
        addresses: [],
        anniversaries: [],
        memo: '',

        addLabel: false,
        addLabelName: '',
        addSaveing: false,

        validation: '',
      };
  const [state, setState] = useState<{
    id?: number;
    labels: {
      labelId: number;
      updateAt?: string;
    }[];
    name: string;
    nickName: string;
    jobTitle: string;
    department: string;
    company: string;
    birthDay: Date | null;
    emails: {
      id: number;
      isDelete?: boolean;
      isDefault: boolean;
      emailType: string;
      emailTypeCustomValue?: string;
      email: string;
      updateAt?: string;
    }[];
    phones: {
      id: number;
      isDelete?: boolean;
      isDefault: boolean;
      phoneType: string;
      phoneTypeCustomValue?: string;
      locationCode: number;
      phoneNumber: string;
      updateAt?: string;
    }[];
    addresses: {
      id: number;
      isDelete?: boolean;
      addressType: string;
      addressTypeCustomValue?: string;
      postalCode: string;
      address: string;
      addressDetail: string;
      updateAt?: string;
    }[];
    anniversaries: {
      id: number;
      isDelete?: boolean;
      anniversaryType: string;
      anniversaryTypeCustomValue?: string;
      anniversary: Date | null;
      updateAt?: string;
    }[];
    memo: string;

    addLabel: boolean;
    addLabels?: {
      labelId: number;
      updateAt?: string;
    }[];
    addLabelName: string;
    addSaveing: boolean;

    validation: string;
  }>(initialState);

  const [reload, setReload] = useState(true);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록 deprecated
  };

  /** 새로고침 방지 이벤트 */
  useEffect(() => {
    setState(initialState);
    (() => {
      window.addEventListener('beforeunload', handlePrevClose);
    })();
    if (reload) {
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();

      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    return () => {
      window.removeEventListener('beforeunload', handlePrevClose);
    };
  }, [queryParams.contentMode, queryParams.contentType]);

  /** 이메일 형식 체크 정규식 */
  function checkEmail(email: string) {
    const emailRegex = /^[A-Z0-9a-z._%+-]*@[A-Za-z0-9.-]*\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  }

  /** 저장 이벤트. */
  const handleSave = () => {
    const filteredAddresses = state.addresses
      .filter((a) => a.address !== '')
      .map((a) => {
        if (a.updateAt === undefined)
          return {
            id: undefined as number | undefined,
            addressType: a.addressType,
            addressTypeCustomValue: a.addressTypeCustomValue,
            postalCode: a.postalCode,
            address: a.address,
            addressDetail: a.addressDetail,
          };
        return a;
      });
    const filteredEmails = state.emails
      .filter((a) => a.email !== '')
      .map((a) => {
        if (a.updateAt === undefined)
          return {
            id: undefined as number | undefined,
            isDefault: a.isDefault,
            emailType: a.emailType,
            emailTypeCustomValue: a.emailTypeCustomValue,
            email: a.email,
          };
        return a;
      });
    const filteredPhones = state.phones
      .filter((a) => a.phoneNumber !== '')
      .map((a) => {
        if (a.updateAt === undefined)
          return {
            id: undefined as number | undefined,
            isDefault: a.isDefault,
            phoneType: a.phoneType,
            phoneTypeCustomValue: a.phoneTypeCustomValue,
            locationCode: a.locationCode,
            phoneNumber: a.phoneNumber,
          };
        return a;
      });
    const filteredAnniversaries = state.anniversaries
      .filter((a) => a.anniversary !== null)
      .map((a) => {
        const anniversary = dateFormat(a.anniversary ?? '', 'yyyy-MM-DD');
        if (a.updateAt === undefined)
          return {
            id: undefined as number | undefined,
            anniversaryType: a.anniversaryType,
            anniversaryTypeCustomValue: a.anniversaryTypeCustomValue,
            anniversary,
            isSolar: true,
          };
        return {
          ...a,
          anniversary,
          isSolar: true,
        };
      });
    let filteredLabels = state.labels.map((a) => ({
      id: a.labelId,
      isDelete: undefined as boolean | undefined,
      updateAt: a.updateAt,
    }));
    let birthDay:
      | {
          birthDay: string;
          isDelete?: boolean;
          isSolar: boolean;
        }
      | undefined =
      state.birthDay !== null
        ? {
            birthDay: dateFormat(state.birthDay, 'yyyy-MM-DD'),
            isSolar: true,
          }
        : undefined;

    if (state.name.trim() === '') {
      setState((prev) => ({
        ...prev,
        validation: '이름을 입력하세요.',
      }));
      return;
    }
    if (state.addresses.length > 0) {
      let error = false;
      for (let i = 0; i < state.addresses.length; i += 1) {
        const address = state.addresses[i];
        if (
          (address.postalCode !== '' || address.addressDetail !== '') &&
          address.address === ''
        ) {
          error = true;
          break;
        }
      }
      if (error) {
        setState((prev) => ({
          ...prev,
          validation: '주소를 입력하세요.',
        }));
        return;
      }
    }
    if (filteredEmails.length > 0) {
      let error = false;
      for (let i = 0; i < filteredEmails.length; i += 1) {
        const isEmail = checkEmail(filteredEmails[i].email);
        if (!isEmail) {
          error = true;
          break;
        }
      }
      if (error) {
        setState((prev) => ({
          ...prev,
          validation: '이메일 형식이 올바르지 않습니다.',
        }));
        return;
      }
    }
    setReload(false);

    if (view) {
      if (view.phones.length > 0)
        view.phones.forEach((a) => {
          if (filteredPhones.find((b) => b.id === a.id) === undefined)
            filteredPhones.push({
              ...a,
              isDelete: true,
            });
        });
      if (view.emails.length > 0)
        view.emails.forEach((a) => {
          if (filteredEmails.find((b) => b.id === a.id) === undefined)
            filteredEmails.push({
              ...a,
              isDelete: true,
            });
        });
      if (view.addresses.length > 0)
        view.addresses.forEach((a) => {
          if (filteredAddresses.find((b) => b.id === a.id) === undefined)
            filteredAddresses.push({
              ...a,
              isDelete: true,
            });
        });
      if (view.anniversaries.length > 0)
        view.anniversaries.forEach((a) => {
          if (filteredAnniversaries.find((b) => b.id === a.id) === undefined)
            filteredAnniversaries.push({
              ...a,
              isDelete: true,
            });
        });
      if (view.birthDay) {
        if (state.birthDay === null)
          birthDay = {
            birthDay: view.birthDay,
            isDelete: true,
            isSolar: true,
          };
        else if (view.birthDay === dateFormat(state.birthDay, 'yyyy-MM-DD'))
          birthDay = undefined;
      }
      if (view.labels.length > 0)
        view.labels.forEach((a) => {
          const label = state.labels.find((b) => b.labelId === a.labelId);
          // 기존 존재하던 라벨을 삭제한 경우.
          if (!label) {
            filteredLabels.push({
              id: a.labelId,
              isDelete: true,
              updateAt: a.updateAt,
            });
          }
          // 기존 존재하던 라벨인 경우.
          if (label)
            filteredLabels = filteredLabels.filter(
              (b) => b.id !== label.labelId,
            );
        });
    }

    const params = {
      id: view ? view.id : undefined,
      labels: filteredLabels.length === 0 ? undefined : filteredLabels,
      name: state.name,
      nickName: state.nickName,
      company: state.company,
      department: state.department,
      jobTitle: state.jobTitle,
      birthDay,
      memo: state.memo,
      emails: filteredEmails.length === 0 ? undefined : filteredEmails,
      addresses: filteredAddresses.length === 0 ? undefined : filteredAddresses,
      phones: filteredPhones.length === 0 ? undefined : filteredPhones,
      anniversaries:
        filteredAnniversaries.length === 0 ? undefined : filteredAnniversaries,
      updateAt: view ? view.updateAt : undefined,
    };

    delete queryParams.contentMode;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
      },
    });

    if (view)
      dispatch(
        contactsActions.updateContact({
          peopleId: view.id,
          data: params,
          location,
        }),
      );
    else dispatch(contactsActions.createContact({ data: params, location }));
  };

  /** 이름 변경 이벤트. */
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  /** 닉네임 변경 이벤트. */
  const handleChangeNickName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      nickName: event.target.value,
    }));
  };

  /** 이메일 타입 변경 이벤트 */
  const handleChangeEmailType = (arg: { id: number; value: string }) => {
    setState((prev) => ({
      ...prev,
      emails: state.emails.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            emailType: arg.value,
            emailTypeCustomValue: arg.value === 'CUSTOM' ? '' : undefined,
          };
        return a;
      }),
    }));
  };

  /** 이메일 구분 직접 입력 값 변경 이벤트 */
  const handleChangeEmailCustom = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      emails: state.emails.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            emailTypeCustomValue: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 이메일 변경 이벤트 */
  const handleChangeEmail = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      emails: state.emails.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            email: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 이메일 추가 이벤트 */
  const handleAddEmail = () => {
    const emails =
      state.emails.length === 0
        ? [
            {
              id: new Date().getTime(),
              isDefault: true,
              emailType: 'COMPANY',
              email: '',
            },
          ]
        : [
            ...state.emails,
            {
              id: new Date().getTime(),
              isDefault: false,
              emailType: 'COMPANY',
              email: '',
            },
          ];
    setState((prev) => ({ ...prev, emails }));
  };

  /** 이메일 삭제 이벤트 */
  const handleDeleteEmail = (id: number) => {
    let emails = [...state.emails.filter((a) => a.id !== id)];
    if (state.emails.find((a) => a.id === id)?.isDefault === true)
      emails = emails.map((a, i) => {
        if (i === 0) return { ...a, isDefault: true };
        return a;
      });

    setState((prev) => ({ ...prev, emails }));
  };

  /** 전화번호 타입 변경 이벤트 */
  const handleChangePhoneType = (arg: { id: number; value: string }) => {
    setState((prev) => ({
      ...prev,
      phones: state.phones.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            phoneType: arg.value,
            phoneTypeCustomValue: arg.value === 'CUSTOM' ? '' : undefined,
          };
        return a;
      }),
    }));
  };

  /** 전화번호 구분 직접 입력 값 변경 이벤트 */
  const handleChangePhoneCustom = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      phones: state.phones.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            phoneTypeCustomValue: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 지역번호 타입 변경 이벤트. */
  const handleChangeLocationType = (arg: { id: number; value: string }) => {
    setState((prev) => ({
      ...prev,
      phones: state.phones.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            locationCode: parseInt(arg.value, 10),
          };
        return a;
      }),
    }));
  };

  /** 전화번호 변경 이벤트 */
  const handleChangePhone = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      phones: state.phones.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            phoneNumber: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 전화번호 추가 이벤트 */
  const handleAddPhone = () => {
    const phones =
      state.phones.length === 0
        ? [
            {
              id: new Date().getTime(),
              isDefault: true,
              phoneType: 'PHONE',
              locationCode: 82,
              phoneNumber: '',
            },
          ]
        : [
            ...state.phones,
            {
              id: new Date().getTime(),
              isDefault: false,
              phoneType: 'PHONE',
              locationCode: 82,
              phoneNumber: '',
            },
          ];
    setState((prev) => ({ ...prev, phones }));
  };

  /** 전화번호 삭제 이벤트 */
  const handleDeletePhone = (id: number) => {
    let phones = [...state.phones.filter((a) => a.id !== id)];
    if (state.phones.find((a) => a.id === id)?.isDefault === true)
      phones = phones.map((a, i) => {
        if (i === 0) return { ...a, isDefault: true };
        return a;
      });

    setState((prev) => ({ ...prev, phones }));
  };

  /** 회사 이름 변경 이벤트. */
  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      company: event.target.value,
    }));
  };

  /** 부서 이름 변경 이벤트. */
  const handleChangeDepartment = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      department: event.target.value,
    }));
  };

  /** 직급 변경 이벤트. */
  const handleChangeJobTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      jobTitle: event.target.value,
    }));
  };

  /** 라벨 추가 대화상자 취소 이벤트. */
  const handleAddLabelDialogClose = () => {
    setState((prev) => ({ ...prev, addLabel: false, addLabels: undefined }));
  };

  /** 라벨 추가 대화상자 이벤트. */
  const handleAddLabelDialog = (addLabel: boolean) => {
    // 라벨 추가 대화상자 OPEN
    if (addLabel)
      setState((prev) => ({
        ...prev,
        addLabel,
        addLabels: state.labels,
      }));
    // 라벨 추가 대화상자 CLOSE (적용 버튼)
    else {
      setState((prev) => ({
        ...prev,
        labels: state.addLabels
          ? state.addLabels.sort(
              (x, y) =>
                +(x.labelId > y.labelId) || +(x.labelId === y.labelId) - 1,
            )
          : prev.labels,
      }));
      handleAddLabelDialogClose();
    }
  };

  /** 라벨 삭제 이벤트. */
  const handleDeleteLabelId = (id: number) => {
    setState((prev) => ({
      ...prev,
      labels: state.labels.filter((a) => a.labelId !== id),
    }));
  };

  /** 라벨아이디 추가 이벤트. */
  const handleAddLabelId = (id: number) => {
    if (!state.addLabels) return;
    let newLabels = [...state.addLabels];
    if (state.addLabels.some((a) => a.labelId === id))
      newLabels = newLabels.filter((a) => a.labelId !== id);
    else newLabels.push({ labelId: id });
    setState((prev) => ({ ...prev, addLabels: newLabels }));
  };

  /** 라벨 이름 변경 이벤트. */
  const handleChangeAddLabelName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      addLabelName: event.target.value,
    }));
  };

  /** 라벨 생성 이벤트 */
  const handleAddLabel = () => {
    if (state.addLabelName.trim() === '') {
      setState((prev) => ({ ...prev, validation: '라벨명을 입력하세요.' }));
      return;
    }

    setState((prev) => ({ ...prev, addSaveing: true }));
    dispatch(labelActions.saveLabel({ name: state.addLabelName })).then(() => {
      setState((prev) => ({ ...prev, addLabelName: '', addSaveing: false }));
    });
  };

  /** 주소 타입 변경 이벤트 */
  const handleChangeAddressType = (arg: { id: number; value: string }) => {
    setState((prev) => ({
      ...prev,
      addresses: state.addresses.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            addressType: arg.value,
            addressTypeCustomValue: arg.value === 'CUSTOM' ? '' : undefined,
          };
        return a;
      }),
    }));
  };

  /** 주소 구분 직접 입력 값 변경 이벤트 */
  const handleChangeAddressCustom = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      addresses: state.addresses.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            addressTypeCustomValue: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 주소 변경 이벤트 */
  const handleChangeAddress = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      addresses: state.addresses.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            address: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 상세주소 변경 이벤트 */
  const handleChangeAddressDetail = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      addresses: state.addresses.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            addressDetail: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 주소 추가 이벤트 */
  const handleAddAddress = () => {
    const addresses =
      state.addresses.length === 0
        ? [
            {
              id: new Date().getTime(),
              addressType: 'COMPANY',
              postalCode: '',
              address: '',
              addressDetail: '',
            },
          ]
        : [
            ...state.addresses,
            {
              id: new Date().getTime(),
              addressType: 'COMPANY',
              postalCode: '',
              address: '',
              addressDetail: '',
            },
          ];
    setState((prev) => ({ ...prev, addresses }));
  };

  /** 주소 삭제 이벤트 */
  const handleDeleteAddress = (id: number) => {
    setState((prev) => ({
      ...prev,
      addresses: state.addresses.filter((a) => a.id !== id),
    }));
  };

  /** 생일 변경 이벤트. */
  const handleChangeBirthDay = (value: Date | null) => {
    setState((prev) => ({
      ...prev,
      birthDay: value,
    }));
  };

  /** 기념일 타입 변경 이벤트 */
  const handleChangeAnniversaryType = (arg: { id: number; value: string }) => {
    setState((prev) => ({
      ...prev,
      anniversaries: state.anniversaries.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            anniversaryType: arg.value,
            anniversaryTypeCustomValue: arg.value === 'CUSTOM' ? '' : undefined,
          };
        return a;
      }),
    }));
  };

  /** 기념일 구분 직접 입력 값 변경 이벤트 */
  const handleChangeAnniversaryCustom = (arg: {
    id: number;
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    setState((prev) => ({
      ...prev,
      anniversaries: state.anniversaries.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            anniversaryTypeCustomValue: arg.event.target.value,
          };
        return a;
      }),
    }));
  };

  /** 기념일 변경 이벤트 */
  const handleChangeAnniversary = (arg: { id: number; date: Date | null }) => {
    setState((prev) => ({
      ...prev,
      anniversaries: state.anniversaries.map((a) => {
        if (a.id === arg.id)
          return {
            ...a,
            anniversary: arg.date,
          };
        return a;
      }),
    }));
  };

  /** 기념일 추가 이벤트 */
  const handleAddAnniversary = () => {
    const anniversaries =
      state.anniversaries.length === 0
        ? [
            {
              id: new Date().getTime(),
              anniversaryType: 'ANNIVERSARY',
              anniversary: null,
            },
          ]
        : [
            ...state.anniversaries,
            {
              id: new Date().getTime(),
              anniversaryType: 'ANNIVERSARY',
              anniversary: null,
            },
          ];
    setState((prev) => ({ ...prev, anniversaries }));
  };

  /** 기념일 삭제 이벤트 */
  const handleDeleteAnniversary = (id: number) => {
    setState((prev) => ({
      ...prev,
      anniversaries: state.anniversaries.filter((a) => a.id !== id),
    }));
  };

  /** 메모 변경 이벤트. */
  const handleChangeMemo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState((prev) => ({
      ...prev,
      memo: event.target.value,
    }));
  };

  const handleCancel = () => {
    delete queryParams.contentMode;
    go(pathname, queryParams);
  };

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  const renderDialog = () => {
    const { addLabel, addLabels, addLabelName, addSaveing } = state;
    const { dialogMode: mode, dialogType: type } = queryParams;

    if (mode === 'create' && type === 'import')
      return (
        <ContactImportDialog
          pathname={pathname}
          search={search}
          onClose={handleDialogClose}
        />
      );

    if (mode === 'create' && type === 'drawerExport')
      return (
        <ContactExportDialog
          clickType="drawer"
          folderId={folderId}
          onClose={handleDialogClose}
        />
      );
    if (addLabel && addLabels)
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>라벨 추가</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <ul className="eui-tree">
              {labels.map((a) => {
                return (
                  <MenuItem
                    key={a.id}
                    icon="label"
                    className="tree-item"
                    label={a.name}
                    selected={addLabels.some((x) => x.labelId === a.id)}
                    onClick={() => handleAddLabelId(a.id)}
                  />
                );
              })}
            </ul>
          </DialogBody>
          <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
            <TextField
              value={addLabelName}
              onChange={handleChangeAddLabelName}
              placeholder="라벨명을 입력하세요."
            />
            <Button
              style={{ marginLeft: '5px' }}
              noDuplication={addSaveing && state.validation === ''}
              text="생성"
              variant="contained"
              onClick={handleAddLabel}
            />
          </div>
          <Divider />
          <DialogFooter>
            <Button
              variant="outlined"
              text="취소"
              onClick={handleAddLabelDialogClose}
            />
            <Button
              variant="contained"
              text="적용"
              onClick={() => handleAddLabelDialog(false)}
            />
          </DialogFooter>
        </Dialog>
      );

    return null;
  };

  const renderContent = () => {
    return (
      <PostWrite name="contact" fullSize>
        <PostWrite.Toolbar onCancel={handleCancel}>
          <Button
            noDuplication={state.validation === ''}
            text="저장"
            variant="contained"
            onClick={handleSave}
          />
        </PostWrite.Toolbar>
        <PostWrite.Item title="이름" required>
          <TextField
            placeholder="이름"
            value={state.name}
            onChange={handleChangeName}
          />
        </PostWrite.Item>
        <PostWrite.Item title="닉네임">
          <TextField
            placeholder="닉네임"
            value={state.nickName}
            onChange={handleChangeNickName}
          />
        </PostWrite.Item>
        <PostWrite.Item title="이메일">
          {state.emails.map((a) => {
            if (isPhone)
              return (
                <div
                  key={a.id}
                  className="contact-list"
                  style={{ flexDirection: 'column' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '2px',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      <SelectField
                        style={{
                          width: a.emailType === 'CUSTOM' ? '40%' : '100%',
                        }}
                        data={emailType}
                        value={a.emailType}
                        onChange={(value) =>
                          handleChangeEmailType({ id: a.id, value })
                        }
                      />
                      {a.emailType === 'CUSTOM' &&
                        a.emailTypeCustomValue !== undefined && (
                          <>
                            <div style={{ margin: '0 2px' }} />
                            <TextField
                              value={a.emailTypeCustomValue}
                              onChange={(event) =>
                                handleChangeEmailCustom({ id: a.id, event })
                              }
                            />
                          </>
                        )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextField
                      value={a.email}
                      onChange={(event) =>
                        handleChangeEmail({ id: a.id, event })
                      }
                    />
                    <Button
                      iconType
                      icon="times-circle"
                      onClick={() => handleDeleteEmail(a.id)}
                    />
                  </div>
                </div>
              );
            return (
              <div key={a.id} className="contact-list">
                <div style={{ display: 'inline-flex', width: '135px' }}>
                  <SelectField
                    style={{
                      width: a.emailType === 'CUSTOM' ? '61px' : '135px',
                    }}
                    data={emailType}
                    value={a.emailType}
                    onChange={(value) =>
                      handleChangeEmailType({ id: a.id, value })
                    }
                  />
                  {a.emailType === 'CUSTOM' &&
                    a.emailTypeCustomValue !== undefined && (
                      <>
                        <div style={{ margin: '0 2px' }} />
                        <TextField
                          width={70}
                          value={a.emailTypeCustomValue}
                          onChange={(event) =>
                            handleChangeEmailCustom({ id: a.id, event })
                          }
                        />
                      </>
                    )}
                </div>
                <div style={{ margin: '0 2px' }} />
                <TextField
                  value={a.email}
                  onChange={(event) => handleChangeEmail({ id: a.id, event })}
                />
                <Button
                  iconType
                  icon="times-circle"
                  onClick={() => handleDeleteEmail(a.id)}
                />
              </div>
            );
          })}
          <Button
            style={{ marginTop: state.emails.length > 0 ? '8px' : undefined }}
            block
            variant="outlined"
            icon={state.emails.length === 0 ? 'mail' : 'plus'}
            text="이메일 추가"
            onClick={() => handleAddEmail()}
          />
        </PostWrite.Item>
        <PostWrite.Item title="전화번호">
          {isPhone
            ? state.phones.map((a) => {
                return (
                  <div
                    key={a.id}
                    className="contact-list"
                    style={{ flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '2px',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ display: 'inline-flex', width: '60%' }}>
                        <SelectField
                          style={{
                            width: a.phoneType === 'CUSTOM' ? '40%' : '100%',
                          }}
                          data={phoneType}
                          value={a.phoneType}
                          onChange={(value) =>
                            handleChangePhoneType({ id: a.id, value })
                          }
                        />
                        {a.phoneType === 'CUSTOM' &&
                          a.phoneTypeCustomValue !== undefined && (
                            <>
                              <div style={{ margin: '0 2px' }} />
                              <TextField
                                value={a.phoneTypeCustomValue}
                                onChange={(event) =>
                                  handleChangePhoneCustom({ id: a.id, event })
                                }
                              />
                            </>
                          )}
                      </div>
                      <div style={{ margin: '0 2px' }} />
                      <SelectField
                        style={{ width: '40%' }}
                        data={phoneLocationType}
                        value={a.locationCode.toString()}
                        onChange={(value) =>
                          handleChangeLocationType({ id: a.id, value })
                        }
                      />
                    </div>
                    <div style={{ display: 'flex' }}>
                      <TextField
                        value={a.phoneNumber}
                        onChange={(event) =>
                          handleChangePhone({ id: a.id, event })
                        }
                      />
                      <Button
                        iconType
                        icon="times-circle"
                        onClick={() => handleDeletePhone(a.id)}
                      />
                    </div>
                  </div>
                );
              })
            : state.phones.map((a) => {
                return (
                  <div key={a.id} className="contact-list">
                    <div style={{ display: 'inline-flex', width: '135px' }}>
                      <SelectField
                        style={{
                          width: a.phoneType === 'CUSTOM' ? '61px' : '135px',
                        }}
                        data={phoneType}
                        value={a.phoneType}
                        onChange={(value) =>
                          handleChangePhoneType({ id: a.id, value })
                        }
                      />
                      {a.phoneType === 'CUSTOM' &&
                        a.phoneTypeCustomValue !== undefined && (
                          <>
                            <div style={{ margin: '0 2px' }} />
                            <TextField
                              width={70}
                              value={a.phoneTypeCustomValue}
                              onChange={(event) =>
                                handleChangePhoneCustom({ id: a.id, event })
                              }
                            />
                          </>
                        )}
                    </div>
                    <div style={{ margin: '0 2px' }} />
                    <SelectField
                      style={{ width: '150px' }}
                      data={phoneLocationType}
                      value={a.locationCode.toString()}
                      onChange={(value) =>
                        handleChangeLocationType({ id: a.id, value })
                      }
                    />
                    <div style={{ margin: '0 2px' }} />
                    <TextField
                      value={a.phoneNumber}
                      onChange={(event) =>
                        handleChangePhone({ id: a.id, event })
                      }
                    />
                    <Button
                      iconType
                      icon="times-circle"
                      onClick={() => handleDeletePhone(a.id)}
                    />
                  </div>
                );
              })}
          <Button
            style={{ marginTop: state.emails.length > 0 ? '8px' : undefined }}
            block
            variant="outlined"
            icon={state.phones.length === 0 ? 'phone' : 'plus'}
            text="전화번호 추가"
            onClick={() => handleAddPhone()}
          />
        </PostWrite.Item>
        <PostWrite.Item title="회사">
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="회사"
              value={state.company}
              onChange={handleChangeCompany}
            />
            <div style={{ width: '25%' }} />
            <TextField
              placeholder="부서"
              value={state.department}
              onChange={handleChangeDepartment}
            />
            <div style={{ width: '25%' }} />
            <TextField
              placeholder="직급"
              value={state.jobTitle}
              onChange={handleChangeJobTitle}
            />
          </div>
        </PostWrite.Item>
        <PostWrite.Item title="라벨">
          <div
            style={{
              display: 'flex',
              flexDirection: isPhone ? 'column' : 'row',
            }}
          >
            <div>
              {state.labels
                .filter((a) => labels.some((x) => x.id === a.labelId))
                .map((a, i) => {
                  const name = labels.find((x) => x.id === a.labelId)?.name;
                  return (
                    <span key={a.labelId}>
                      <Chip
                        label={name ?? ''}
                        onDelete={() => handleDeleteLabelId(a.labelId)}
                      />
                      {i !== state.labels.length - 1 && (
                        <span style={{ marginRight: '4px' }} />
                      )}
                    </span>
                  );
                })}
            </div>
            <Button
              style={isPhone ? { marginTop: '8px' } : { marginLeft: '4px' }}
              icon="plus"
              text="라벨 추가"
              variant="outlined"
              onClick={() => handleAddLabelDialog(true)}
            />
          </div>
        </PostWrite.Item>
        <PostWrite.Item title="주소">
          {isPhone
            ? state.addresses.map((a) => {
                return (
                  <div
                    key={a.id}
                    className="contact-list"
                    style={{ flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        marginBottom: '2px',
                        width: '100%',
                      }}
                    >
                      <SelectField
                        style={{
                          width: a.addressType === 'CUSTOM' ? '40%' : '100%',
                        }}
                        data={addressType}
                        value={a.addressType}
                        onChange={(value) =>
                          handleChangeAddressType({ id: a.id, value })
                        }
                      />
                      {a.addressType === 'CUSTOM' &&
                        a.addressTypeCustomValue !== undefined && (
                          <>
                            <div style={{ margin: '0 2px' }} />
                            <TextField
                              value={a.addressTypeCustomValue}
                              onChange={(event) =>
                                handleChangeAddressCustom({ id: a.id, event })
                              }
                            />
                          </>
                        )}
                    </div>
                    <TextField
                      placeholder="주소"
                      value={a.address}
                      onChange={(event) =>
                        handleChangeAddress({ id: a.id, event })
                      }
                    />
                    <div style={{ display: 'flex', marginTop: '2px' }}>
                      <TextField
                        placeholder="상세 주소"
                        value={a.addressDetail}
                        onChange={(event) =>
                          handleChangeAddressDetail({ id: a.id, event })
                        }
                      />
                      <Button
                        iconType
                        icon="times-circle"
                        onClick={() => handleDeleteAddress(a.id)}
                      />
                    </div>
                  </div>
                );
              })
            : state.addresses.map((a) => {
                return (
                  <div key={a.id} className="contact-list">
                    <div style={{ display: 'inline-flex', width: '135px' }}>
                      <SelectField
                        style={{
                          width: a.addressType === 'CUSTOM' ? '61px' : '135px',
                        }}
                        data={addressType}
                        value={a.addressType}
                        onChange={(value) =>
                          handleChangeAddressType({ id: a.id, value })
                        }
                      />
                      {a.addressType === 'CUSTOM' &&
                        a.addressTypeCustomValue !== undefined && (
                          <>
                            <div style={{ margin: '0 2px' }} />
                            <TextField
                              width={70}
                              value={a.addressTypeCustomValue}
                              onChange={(event) =>
                                handleChangeAddressCustom({ id: a.id, event })
                              }
                            />
                          </>
                        )}
                    </div>
                    <div style={{ margin: '0 2px' }} />
                    <TextField
                      placeholder="주소"
                      value={a.address}
                      onChange={(event) =>
                        handleChangeAddress({ id: a.id, event })
                      }
                    />
                    <div style={{ margin: '0 2px' }} />
                    <TextField
                      placeholder="상세 주소"
                      value={a.addressDetail}
                      onChange={(event) =>
                        handleChangeAddressDetail({ id: a.id, event })
                      }
                    />
                    <Button
                      iconType
                      icon="times-circle"
                      onClick={() => handleDeleteAddress(a.id)}
                    />
                  </div>
                );
              })}
          <Button
            style={{
              marginTop: state.addresses.length > 0 ? '8px' : undefined,
            }}
            block
            variant="outlined"
            icon={state.addresses.length === 0 ? 'map-marker' : 'plus'}
            text="주소 추가"
            onClick={() => handleAddAddress()}
          />
        </PostWrite.Item>
        <PostWrite.Item title="생일">
          <CustomDatePicker
            isClearable={display !== 'pc'}
            selected={state.birthDay}
            dateFormat="yyyy-MM-dd"
            onChange={handleChangeBirthDay}
          />
        </PostWrite.Item>
        <PostWrite.Item title="기념일">
          {state.anniversaries.map((a) => {
            if (isPhone)
              return (
                <div
                  key={a.id}
                  className="contact-list"
                  style={{ flexDirection: 'column' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '2px',
                    }}
                  >
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      <SelectField
                        style={{
                          width:
                            a.anniversaryType === 'CUSTOM' ? '40%' : '100%',
                        }}
                        data={anniversaryType}
                        value={a.anniversaryType}
                        onChange={(value) =>
                          handleChangeAnniversaryType({ id: a.id, value })
                        }
                      />
                      {a.anniversaryType === 'CUSTOM' &&
                        a.anniversaryTypeCustomValue !== undefined && (
                          <>
                            <div style={{ margin: '0 2px' }} />
                            <TextField
                              value={a.anniversaryTypeCustomValue}
                              onChange={(event) =>
                                handleChangeAnniversaryCustom({
                                  id: a.id,
                                  event,
                                })
                              }
                            />
                          </>
                        )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomDatePicker
                      width="100%"
                      selected={a.anniversary}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date: Date | null) =>
                        handleChangeAnniversary({ id: a.id, date })
                      }
                    />
                    <Button
                      iconType
                      icon="times-circle"
                      onClick={() => handleDeleteAnniversary(a.id)}
                    />
                  </div>
                </div>
              );
            return (
              <div key={a.id} className="contact-list">
                <div style={{ display: 'inline-flex', width: '135px' }}>
                  <SelectField
                    style={{
                      width: a.anniversaryType === 'CUSTOM' ? '61px' : '135px',
                    }}
                    data={anniversaryType}
                    value={a.anniversaryType}
                    onChange={(value) =>
                      handleChangeAnniversaryType({ id: a.id, value })
                    }
                  />
                  {a.anniversaryType === 'CUSTOM' &&
                    a.anniversaryTypeCustomValue !== undefined && (
                      <>
                        <div style={{ margin: '0 2px' }} />
                        <TextField
                          width={70}
                          value={a.anniversaryTypeCustomValue}
                          onChange={(event) =>
                            handleChangeAnniversaryCustom({ id: a.id, event })
                          }
                        />
                      </>
                    )}
                </div>
                <div style={{ margin: '0 2px' }} />
                <CustomDatePicker
                  width="100%"
                  isClearable={display === 'tablet'}
                  selected={a.anniversary}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date: Date | null) =>
                    handleChangeAnniversary({ id: a.id, date })
                  }
                />
                <Button
                  iconType
                  icon="times-circle"
                  onClick={() => handleDeleteAnniversary(a.id)}
                />
              </div>
            );
          })}
          <Button
            style={{
              marginTop: state.anniversaries.length > 0 ? '8px' : undefined,
            }}
            block
            variant="outlined"
            icon={state.anniversaries.length === 0 ? 'calendar-heart' : 'plus'}
            text="기념일 추가"
            onClick={() => handleAddAnniversary()}
          />
        </PostWrite.Item>
        <PostWrite.Item title="메모">
          <TextField
            rows={2}
            multiline
            value={state.memo}
            onChange={handleChangeMemo}
          />
        </PostWrite.Item>
      </PostWrite>
    );
  };

  return (
    <>
      {renderContent()}
      {renderDialog()}
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
      <NavigationGuard
        pathname={pathname}
        search={search}
        reload={reload}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (reload) {
            return true;
          }
          return false;
        }}
      />
    </>
  );
}

export default ContactComposeContainer;
