import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipAdd(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Icon chip">
      <Chip label="관리자" etc="읽기/쓰기" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipAdd;
