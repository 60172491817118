import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentTooltipBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic tooltip">
      <Button text="삭제" iconType icon="trash-full" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTooltipBasic;
