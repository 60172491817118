import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { getPathParams } from '../../../../../groupware-common/utils';
import { boardActions } from '../../../../stores/board';

function BoardContentRoute(props: {
  pathname: string;
  search: string;
}): null | JSX.Element {
  const { pathname, search } = props;
  const { folderId, itemId } = getPathParams<{
    folderId?: string;
    itemId?: number;
  }>('/:module/:folderId/:itemId$base62', pathname);
  const route = { pathname, search };

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    if (mount) {
      if (folderId !== undefined && itemId !== undefined)
        // 단일 조회.
        dispatch(boardActions.isSecretView({ id: itemId, route }));
      else if (folderId !== undefined && itemId === undefined)
        dispatch(
          boardActions.findList({
            folderId,
            search,
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
            route,
          }),
        );
      // 모든 게시함 조회.
      else
        dispatch(
          boardActions.findList({
            search,
            isStarred: false,
            isTemporary: false,
            route,
          }),
        );
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default BoardContentRoute;
