import React from 'react';
import { contrastColor } from '../../groupware-common/utils/ui';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';

function Radio(props: {
  label?: string;
  name?: string;
  icon?: IconType;
  color?: string;
  checked?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}): JSX.Element {
  let classname = 'eui-checkbox';
  if (props.className) classname += ` ${props.className}`;
  if (props.disabled) classname += ' disabled';

  return (
    <label
      className={classname}
      style={
        props.color !== undefined
          ? { backgroundColor: props.color, color: contrastColor(props.color) }
          : {}
      }
      aria-checked={props.checked}
    >
      <input
        type="radio"
        name={props.name}
        checked={props.checked}
        readOnly={props.readonly}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      {props.icon && <Icon icon={props.icon} className="badge" />}
      {props.label && <span className="label">{props.label}</span>}
      {props.children}
    </label>
  );
}

export default Radio;
