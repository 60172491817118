import { useEffect } from 'react';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { folderActions, itemActions } from '../../../stores/folders';

function ResourceFolderRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    if (mount) {
      dispatch(itemActions.list({})).then(() => {
        dispatch(folderActions.list({ route: { pathname, search } }));
      });
    }
    return () => {
      mount = false;
    };
  }, [pathname, search]);
  return null;
}

export default ResourceFolderRoute;
