import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApprovalFormEditContentDialog from '../../../groupware-approval/pages/adminconsole/approval/form/ApprovalFormEditContentDialog';
import { RootState, useAppDispatch } from '../../../groupware-webapp/app/store';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import { ApiError } from '../../../groupware-common/types/error';
import systemLinkFormApi from '../../apis/systemlink/v1/form';
import SystemLinkFormEditInfoDialog from './SystemLinkFormEditInfoDialog';

function SystemLinkFormEdit(props: {
  folders: { id: number; parentId: number; text: string }[];
  folderId?: number;
  id?: number;
  onClose(): void;
  onSave(
    arg:
      | {
          id?: undefined;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
        }
      | {
          id: number;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
          updateAt: string;
        },
  ): void;
}): JSX.Element {
  // console.log(`SystemLinkFormEdit.render(props)`, props);

  const dispatch = useAppDispatch();

  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const [state, setState] = useState(() => {
    return {
      mode: (props.id === undefined ? 'create' : 'loading') as
        | 'create'
        | 'update'
        | 'loading',
      type: 'info' as 'info' | 'content',
      id: props.id,
      folderId: props.folderId ?? 0,
      name: '',
      content: '',
      status: 1,
      description: '',
      updateAt: '',
    };
  });

  useEffect(() => {
    let mount = true;
    async function run(id: number) {
      try {
        const view: {
          companyId: number;
          id: number;
          folderId: number;
          status: number;
          name: string;
          contents: string;
          description: string;
          createAt: string;
          updateAt: string;
        } = await systemLinkFormApi.view(id).then((a) => ({
          ...a,
          status: a.isEnable ? 1 : 2,
          contents: a.content,
          companyId,
        }));

        if (mount) {
          if (view.id === 0) {
            return;
          }
          setState((prevState) => {
            return {
              ...prevState,
              mode: 'update',
              folderId: view.folderId,
              name: view.name,
              content: view.contents,
              status: view.status,
              description: view.description,
              updateAt: view.updateAt,
            };
          });
        }
      } catch (ex) {
        dispatch(sessionActions.error(ex as ApiError));
      }
    }
    if (props.id !== undefined) run(props.id);
    return () => {
      mount = false;
    };
  }, [dispatch, props.id]);

  const handleSave = (arg: { content: string }) => {
    const { content } = arg;
    const { onSave } = props;
    const { id, folderId, name, status, description, updateAt } = state;
    if (id === undefined)
      onSave({ folderId, name, status, content, description });
    else onSave({ id, folderId, name, status, content, description, updateAt });
  };

  const { type } = state;
  if (type === 'content') {
    const { onClose } = props;
    const { name, content } = state;
    return (
      <ApprovalFormEditContentDialog
        systemlink
        name={name}
        content={content}
        goInfo={() => setState((prevState) => ({ ...prevState, type: 'info' }))}
        onCancel={onClose}
        onSave={handleSave}
      />
    );
  }

  const { folders, onClose } = props;
  const { mode, folderId, name, description, status } = state;
  return (
    <SystemLinkFormEditInfoDialog
      mode={mode}
      folders={folders}
      folderId={folderId}
      name={name}
      description={description}
      status={status}
      onCancel={onClose}
      onConfirm={(arg) =>
        setState((prevState) => ({ ...prevState, type: 'content', ...arg }))
      }
    />
  );
}

export default SystemLinkFormEdit;
