import React from 'react';
import { getQueryParams } from '../../groupware-common/utils';
import DocumentComposeContainer from '../pages/root/document/compose/DocumentComposeContainer';
import DocumentContentContainer from '../pages/root/document/content/DocumentContentContainer';

function DocumentLocateContainer(props: {
  pathname: string;
  search: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, locationKey } = props;
  const { contentMode } = getQueryParams(search);
  // 문서작성.
  if (contentMode === 'create' || contentMode === 'update') {
    return (
      <DocumentComposeContainer
        pathname={pathname}
        search={search}
        key={locationKey}
      />
    );
  }
  return <DocumentContentContainer pathname={pathname} search={search} />;
}

export default DocumentLocateContainer;
