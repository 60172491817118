import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldMultiline(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    stateValue(event.target.value);
  };

  return (
    <ComponentSampleUI.Content title="Multiline">
      <TextField label="설명" value={value} multiline onChange={handleChange} />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldMultiline;
