import React from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Tab from '../../../../../components/tab/Tab';
import { b62, getPathParams, go } from '../../../../../groupware-common/utils';
import { RootState } from '../../../../../groupware-webapp/app/store';
import ResourceDailyContentContainer from './ResourceDailyContentContainer';
import ResourceMonthlyContentContainer from './ResourceMonthlyContentContainer';
import ResourceWeeklyContentContainer from './ResourceWeeklyContentContainer';

function ResourceContentContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search } = props;
  const hash = props.hash === '#' ? '' : props.hash;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);

  const viewModeOptions = [
    { label: '일간', id: '' },
    { label: '주간', id: '#weekly' },
    { label: '월간', id: '#monthly' },
  ];

  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  );

  let title = '';
  if (p2) {
    const item = items.find((a) => a.id === b62(p2));
    title = item ? item.name : '';
  } else {
    const folder = folders.find((a) => a.id === b62(p1));
    title = folder ? folder.name : '';
  }
  const renderBody = () => {
    if (hash === '#weekly')
      return (
        <ResourceWeeklyContentContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    if (hash === '#monthly')
      return (
        <ResourceMonthlyContentContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    return (
      <ResourceDailyContentContainer
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
          <Tab>
            {viewModeOptions.map(({ id, label }) => (
              <Tab.Item
                key={id}
                label={label}
                selected={id === hash}
                onClick={() => go(pathname, '', id)}
              />
            ))}
          </Tab>
        </EuiHeader.Content>
      </EuiHeader>
      {renderBody()}
    </>
  );
}

export default ResourceContentContainer;
