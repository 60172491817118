/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { getQueryParams } from '../../groupware-common/utils';
import { dateFormat } from '../../groupware-common/utils/ui';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import approvalApi from '../apis/resource/v1/approval';

const name = 'resource/approval';

export interface ApprovalListItem {
  companyId: number; // 회사 아이디.
  id: number; // 자원 예약 아이디.
  itemId: number; // 자원 아이템 아이디.
  folderId: number; // 폴더 아이디.
  employeeId: number; // 자원 예약자 아이디.
  name: string; // 예약 명.
  startDateTime: string; // 사용 시작 시간.
  endDateTime: string; // 사용 종료 시간.
  isRepeat: boolean; // 반복 여부.
  isApproval: number; // 승인 여부. (0 : 대기 , 1: 승인, 2:반려)
  isTakeback?: boolean;
  repeat?: {
    reservationId: number;
    frequency: number; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    repeatDays: string[]; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    endDate: string; // 반복 종료일
  };
  createAt: string; // 생성 날짜.
  updateAt: string; // 수정 날짜.
}

interface State {
  list: ApprovalListItem[];
  totalCount: number;
}

const initialState: State = {
  list: [],
  totalCount: 0,
};

function statusTolistStatus(status: string): number | undefined {
  switch (status) {
    case 'proceeding':
      return 0;
    case 'done':
      return 1;
    case 'return':
      return 2;
    default:
      return undefined;
  }
}

const findApprovalList = createAsyncThunk(
  `${name}/findApprovalList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const queryParams = getQueryParams(arg.search);
    const { principal } = (getState() as RootState).session;
    try {
      const params = {
        employeeid: principal.employeeId,
        organizationid: principal.organizationId,
        approvalstatus: queryParams.status
          ? statusTolistStatus(queryParams.status)
          : undefined,
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter !== 'true'
            ? queryParams.searchWord
            : queryParams.directoryKeyword,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      };
      const list = (
        await approvalApi.approvalList({
          ...params,
          pageno: queryParams.pageNo ?? 1,
          rowsperpage: queryParams.rowsPerPage ?? 15,
        })
      ).map((a) => ({
        ...a,
        startDateTime: dateFormat(a.startDateTime, 'yyyy-MM-DD HH:mm'),
        endDateTime: dateFormat(a.endDateTime, 'yyyy-MM-DD HH:mm'),
        createAt: dateFormat(a.createAt, 'yyyy-MM-DD'),
      }));
      const totalCount = await approvalApi.approvalTotalCount(params);
      return { list, totalCount };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const updateApproval = createAsyncThunk(
  `${name}/updateApproval`,
  async (
    arg: {
      type: 'reject' | 'approve';
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { type, id, updateAt } = arg;
    try {
      const result = await approvalApi.updateApproval({
        type,
        id,
        updateAt,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const findRentalList = createAsyncThunk(
  `${name}/findRentalList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const queryParams = getQueryParams(arg.search);
    const { principal } = (getState() as RootState).session;
    try {
      const params = {
        employeeid: principal.employeeId,
        organizationid: principal.organizationId,
        rentalstatus: queryParams.status
          ? statusTolistStatus(queryParams.status)
          : undefined,
        startdate: queryParams.startDate,
        enddate: queryParams.endDate,
        searchcode: queryParams.searchCode,
        searchword:
          queryParams.directoryFilter !== 'true'
            ? queryParams.searchWord
            : queryParams.directoryKeyword,
        isdirectoryselected: queryParams.directoryFilter === 'true',
      };
      const list = (
        await approvalApi.rentalList({
          ...params,
          pageno: queryParams.pageNo ?? 1,
          rowsperpage: queryParams.rowsPerPage ?? 15,
        })
      ).map((a) => ({
        ...a,
        startDateTime: dateFormat(a.startDateTime, 'yyyy-MM-DD HH:mm'),
        endDateTime: dateFormat(a.endDateTime, 'yyyy-MM-DD HH:mm'),
        createAt: dateFormat(a.createAt, 'yyyy-MM-DD'),
      }));
      const totalCount = await approvalApi.rentalTotalCount(params);
      return { list, totalCount };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const updateRental = createAsyncThunk(
  `${name}/updateRental`,
  async (
    arg: {
      type: 'takeback' | 'rental';
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { type, id, updateAt } = arg;
    try {
      const result = await approvalApi.updateRental({
        type,
        id,
        updateAt,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const approvalReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findApprovalList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = payload.list;
          state.totalCount = payload.totalCount;
        }
      })
      .addCase(updateApproval.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.list = state.list.map((a) => {
            if (a.id === payload.id) return payload;
            return a;
          });
      })
      .addCase(findRentalList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = payload.list;
          state.totalCount = payload.totalCount;
        }
      })
      .addCase(updateRental.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.list = state.list.map((a) => {
            if (a.id === payload.id) return payload;
            return a;
          });
      });
  },
});

export default approvalReducer.reducer;

export const approvalActions = {
  approvalList: findApprovalList,
  updateApproval,

  rentalList: findRentalList,
  updateRental,
};
