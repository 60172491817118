import React from 'react';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostView from '../../../../../components/post/PostView';

function ApprovalArchiveSettingsFolderView(props: {
  parentNames: string[];
  name: string;
  description: string;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
}): JSX.Element {
  // console.log(`ApprovalArchiveSettingsView(props)`, props);

  const { parentNames, name, description, onAction } = props;

  return (
    <>
      <EuiSetting.Header title="폴더 정보">
        <Button
          text="수정"
          iconType
          icon="edit"
          onClick={(event) => onAction({ code: 'update', event })}
        />
        <Button
          text="순서 변경"
          iconType
          icon="sort-amount"
          onClick={(event) => onAction({ code: 'sequencechange', event })}
        />
        <Button
          text="삭제"
          iconType
          icon="trash-full"
          onClick={(event) => onAction({ code: 'delete', event })}
        />
        {/* TODO 미구현 */}
        {/* <Button
          text="인계"
          iconType
          icon="file-export"
          onClick={() => handleOpen('takingOver')}
        /> */}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title="상위 폴더">
                <PostView.CategoryValue>
                  {parentNames.length === 0
                    ? '없음'
                    : parentNames.map((v, i, a) => {
                        if (i !== a.length - 1)
                          return <span key={v}>{v} &gt;</span>;
                        return <span key={v}>{v}</span>;
                      })}
                </PostView.CategoryValue>
              </PostView.CategoryItem>
              <PostView.CategoryItem title="이름">
                <PostView.CategoryValue value={name} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="설명">
                <PostView.CategoryValue value={description} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default ApprovalArchiveSettingsFolderView;
