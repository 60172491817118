import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  b62,
  createQueryString,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import BoardFolderBoxEditDrawer from './BoardFolderBoxEditDrawer';
import BoardFolderBoxList from './BoardFolderBoxList';
import BoardFolderBoxView from './BoardFolderBoxView';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import { folderBoxActions, SaveFolderArg } from '../../../../stores/folder';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';

function BoardFolderBoxContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const pathmap = getPathMap('/*/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/*/:selectedId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const list = useSelector(
    (state: RootState) => state.boards.folder.adminconsole.list,
  );
  const view = useSelector((state: RootState) => state.boards.folder.item);
  const categories = useSelector(
    (state: RootState) => state.boards.board.category,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6002)?.name ?? '';

  const items = useMemo(() => {
    const folders = list
      .map((x) => {
        return {
          id: x.id,
          parentId: x.parentId,
          seq: x.seq,
          text: x.name,
          icon: 'folder' as const,
          strings: hangul.d(x.name),
        };
      })
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
    return folders;
  }, [list]);

  const handleLink = (id: number) => {
    dispatch(
      folderBoxActions.folderView({
        folderId: id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search,
        },
      }),
    );
  };

  // 모바일에서만 적용.
  const handleCloseView = () => {
    const route = { pathname: pathmap };
    dispatch(sessionActions.setRoute(route));
  };

  /** 순서 변경 드로워. */
  const handleSortChangeDrawer = () => {
    dispatch(sessionActions.setDrawer({ type: 'sortChange' }));
  };

  /** 순서 변경 저장. */
  const handleSortChange = (
    arg: {
      id: number;
      label?: string;
    }[],
  ) => {
    const sortList = arg.map((a, i) => {
      const updateAt = list.find((x) => x.id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: i + 1,
        updateAt,
      };
    });
    const route = {
      pathname,
      search: createQueryString(
        { drawerMode: undefined, drawerType: undefined },
        queryParams,
      ),
    };

    dispatch(folderBoxActions.sort({ data: sortList, route }));
  };

  const handleCRUD = (mode: 'create' | 'read' | 'update' | 'delete') => {
    switch (mode) {
      case 'create':
      case 'update':
        dispatch(sessionActions.setDrawer({ mode }));
        break;
      case 'delete':
        dispatch(sessionActions.setDialog({ mode }));
        break;
      default:
        dispatch(sessionActions.search());
        break;
    }
  };

  const handleSave = (data: SaveFolderArg) => {
    const route = { pathname };
    if (data.id && data.updateAt)
      dispatch(folderBoxActions.update({ data, route }));
    else dispatch(folderBoxActions.create({ data, route }));
  };

  const handleDelete = () => {
    if (!view) return;
    dispatch(
      folderBoxActions.delete({
        data: {
          id: view.id,
          updateAt: view.updateAt,
        },
        route: { pathname: pathmap },
      }),
    );
  };

  const renderList = () => {
    let result = null;
    result = (
      <BoardFolderBoxList
        folderId={selectedId}
        items={items}
        onCreate={() => handleCRUD('create')}
        onItemClick={handleLink}
      />
    );
    return result;
  };

  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId ? '폴더를 찾을 수 없습니다.' : undefined;
      return <SplitUnselected label={message} />;
    }

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <BoardFolderBoxView
          view={view}
          paths={getParentItems(items, view.id).map(({ text }) => text)}
          onSort={handleSortChangeDrawer}
          onUpdate={() => handleCRUD('update')}
          onDelete={() => handleCRUD('delete')}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerMode, drawerType } = queryParams;
    const treeFolders = [
      { id: 0, parentId: -1, text: '상위 게시함', icon: 'folder' as const },
      ...items,
    ];
    if (drawerMode === 'create')
      return (
        <BoardFolderBoxEditDrawer
          search={search}
          items={treeFolders}
          parentId={selectedId ?? 0}
          onSave={handleSave}
          onClose={() => handleCRUD('read')}
        />
      );

    if (drawerMode === 'update' && view !== undefined)
      return (
        <BoardFolderBoxEditDrawer
          search={search}
          items={treeFolders}
          view={view}
          parentId={view.parentId}
          onSave={handleSave}
          onClose={() => handleCRUD('read')}
        />
      );

    if (drawerType === 'sortChange') {
      const folder = list.find(({ id }) => id === selectedId);
      if (folder === undefined) return null;
      const changeList = list
        .filter(({ parentId }) => parentId === folder.parentId)
        .map(({ id, name: label, seq }) => ({ id, label, seq }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

      return (
        <ChangeOrder
          title="순서 변경"
          list={changeList}
          onClose={() => handleCRUD('read')}
          onChange={handleSortChange}
        />
      );
    }

    return null;
  };

  const renderDialog = () => {
    let result = null;

    if (queryParams.dialogMode === 'delete') {
      result = (
        <DeleteConfirmation
          onSubmit={handleDelete}
          onCancel={() => handleCRUD('read')}
        >
          <strong>
            &apos;{items.find((x) => x.id === selectedId)?.text}&apos;
          </strong>{' '}
          게시함을 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    return result;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default BoardFolderBoxContainer;
