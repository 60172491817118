import React, { useRef, useState } from 'react';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Dext5 from '../../../../../components/editor/Dext5';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import FormBuilder from '../../../../stores/approval/FormBuilder';

function ApprovalFormPopupDialog(props: {
  option: {
    id: string;
    name: string;
    width: string;
    title: string;
    content: string;
  };
  onSave(arg: {
    name: string;
    width: string;
    title: string;
    content: string;
  }): void;
  onClose(): void;
}): JSX.Element {
  const { option, onSave, onClose } = props;

  const builder = new FormBuilder();
  const popupIframe = useRef<HTMLIFrameElement>(null);

  const [state, setState] = useState<{
    name: string;
    width: string;
    title: string;
    content: string;
  }>(option);

  const handleDialogButtonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // console.log(`handleDialogButtonNameChange(event)`, event);

    const { value: name } = event.currentTarget;
    setState((prev) => ({ ...prev, name }));
  };

  const handleDialogWidthChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // console.log(`handleDialogWidthChange(event)`, event);

    const width =
      event.currentTarget.value === '' ? '' : `${event.currentTarget.value}px`;
    setState((prev) => ({ ...prev, width }));
  };

  const handleDialogTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const title = event.currentTarget.value;
    setState((prev) => ({ ...prev, title }));
  };

  const handleDialogEditorLoad = (editor: any, DEXT5: any, editorDom: any) => {
    // (this.body as any).addEventListener('click', this.onClick.bind(this), false);
    editorDom.addEventListener('blur', handleDialogEditorBlur, false);
  };

  const handleDialogEditorBlur = () => {
    // console.log(`handlePopupEditorBlur`);

    const editor = builder.getEditor({ iframe: popupIframe.current });
    const content = editor.getBodyValue(`popupEditor`) as string;

    setState((prev) => ({ ...prev, content }));
  };

  const handleSave = () => {
    onSave(state);
  };

  const handleDialogClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>대화상자</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <PostWrite.Item title="명칭">
            <TextField
              value={state.name}
              onChange={handleDialogButtonNameChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title="팝업 너비">
            <TextField
              pattern="[0-9]*"
              value={state.width.replace('px', '')}
              onChange={handleDialogWidthChange}
            />
            <HelperText text="* 팝업의 높이는 내용에 맞춰 자동으로 조절됩니다." />
          </PostWrite.Item>
          <PostWrite.Item title="제목">
            <TextField value={state.title} onChange={handleDialogTitleChange} />
          </PostWrite.Item>
          <PostWrite.Item title="내용">
            <div className="editor" style={{ height: '500px' }}>
              <Dext5
                key={option.id}
                ref={popupIframe}
                editorId="popupEditor"
                editorConfig={{
                  Width: '100%',
                  Height: '100%',
                  ShowTopMenu: '0',
                  RemoveItem: 'new,file_open',
                }}
                value={state.content}
                onLoad={handleDialogEditorLoad}
              />
            </div>
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button text="저장" variant="contained" onClick={handleSave} />
      </DialogFooter>
    </Dialog>
  );
}

export default ApprovalFormPopupDialog;
