import React from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Tab from '../../../../../components/tab/Tab';
import { getPathMap, go } from '../../../../../groupware-common/utils';
import { RootState } from '../../../../../groupware-webapp/app/store';

function CalendarUserPreferencesHead(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const categories = useSelector(
    (state: RootState) => state.calendar.calendars.category,
  ).filter((a) => a.type === 'default');

  const menu = [
    { id: '', label: '기본설정' },
    { id: '#myCalendar', label: '캘린더 관리' },
    { id: '#sharedCalendar', label: '공유 캘린더' },
  ];

  const handleTabClick = (id: string) => {
    const pathmap = getPathMap('/*/*', pathname);
    go(pathmap, search, id);
  };

  const title = categories.find((a) => a.id === 'preferences')?.name ?? '';
  return (
    <EuiHeader>
      <EuiHeader.Content>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <Tab>
          {menu.map((a) => {
            return (
              <Tab.Item
                key={a.id}
                label={a.label}
                selected={hash === a.id}
                onClick={() => handleTabClick(a.id)}
              />
            );
          })}
        </Tab>
      </EuiHeader.Content>
    </EuiHeader>
  );
}

export default CalendarUserPreferencesHead;
