import { ApiError } from '../../../../groupware-common/types/error';
import { getText } from '../../../../groupware-common/utils';
import {
  getCompanyName,
  getEmployeeName,
  getJobDutyName,
  getJobPositionName,
  getOrganizationName,
} from '../../../../groupware-directory/stores/directory';

/**
 * 예외 객체로 오류 객체를 반환.
 * @param exception 예외 객체.
 * @return 오류 객체.
 */
function appError(exception: unknown): ApiError {
  const ex = exception as ApiError;
  const result: ApiError = {
    error: ex.error || '',
    path: ex.path || '',
    status: ex.status || 0,
    timestamp: ex.timestamp || '',
  };
  if (ex.message !== undefined) result.message = ex.message;

  return result;
}

function getParent<T, U extends { id: T; parentId: T }>(
  array: Readonly<Array<U>>,
  id: T,
  result: Array<U> = [],
): Array<U> {
  const item =
    result.length === 0
      ? array.find((a) => a.id === id)
      : array.find((a) => a.parentId === id);

  if (item !== undefined) {
    result.unshift({ ...item });
    return getParent(array, item.parentId, result);
  }
  return result;
}

interface NameKeyItem {
  companyId?: number;
  id: number;
  nameKey: string;
}

// TODO 각각의 함수(getCompanyName, getOrganizationName, getEmployeeName, getJobPositionName, getJobDutyName) 호출 방식으로 변경 예정.
// 변경 후 함수 삭제.
function getName(
  items: NameKeyItem[],
  companyId: number,
  id: number,
  defaultName?: string,
): string {
  const nameKey =
    items.find((x) => x.companyId === companyId && x.id === id)?.nameKey || '';
  const name = getText(nameKey);
  if (name === nameKey.split(':').pop() && defaultName !== undefined)
    return defaultName;
  return name;
}

function getDirectoryData(arg: {
  organizationEmployees: {
    companyId: number;
    id: number;
    employeeId: number;
    jobDutyId: number;
  }[];
  employees: {
    companyId: number;
    id: number;
    representativeOrganizationId: number;
    jobPositionId: number;
    avatar: string;
    nameKey: string;
  }[];
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  companyId: number;
  organizationId?: number;
  employeeId: number;
}): {
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobClassName: string;
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  avatar?: string;
} {
  // console.log(`getDirectoryData(arg)`, arg);
  const {
    organizationEmployees,
    employees,
    jobClassType,
    companyId,
    // organizationId,
    employeeId,
  } = arg;

  const companyName = getCompanyName(companyId, '');

  const employee = employees.find(
    (a) => a.companyId === companyId && a.id === employeeId,
  );

  const { organizationId = employee?.representativeOrganizationId ?? 0 } = arg;
  const organizationName = getOrganizationName(companyId, organizationId, '');

  const employeeName = getEmployeeName(companyId, employeeId, '');
  const jobPositionId = employee?.jobPositionId ?? 0;
  const jobPositionName = getJobPositionName(companyId, jobPositionId, '');

  const organizationEmployee = organizationEmployees.find(
    (a) =>
      a.companyId === companyId &&
      a.id === organizationId &&
      a.employeeId === employeeId,
  );

  const jobDutyId = organizationEmployee?.jobDutyId ?? 0;
  const jobDutyName = getJobDutyName(companyId, jobDutyId, '');

  let jobClassName = '';
  switch (jobClassType) {
    case 'jobposition':
      jobClassName = jobPositionName;
      break;
    case 'jobduty':
      jobClassName = jobDutyName;
      break;
    case 'jobposition+jobduty': {
      if (jobPositionName !== '' && jobDutyName !== '')
        jobClassName = `${jobPositionName}/${jobDutyName}`;
      else jobClassName = jobPositionName || jobDutyName || '';
      break;
    }
    default:
      break;
  }

  const avatar = employee?.avatar;

  return {
    companyId,
    companyName,
    organizationId,
    organizationName,
    employeeId,
    employeeName,
    jobClassType,
    jobClassName,
    jobPositionId,
    jobPositionName,
    jobDutyId,
    jobDutyName,
    avatar,
  };
}

export { appError, getParent, getName, getDirectoryData };
