import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 회계년도 기준 - 전사 연차현황 조회. */
async function findAccountList(arg: {
  standardYear: string; // 기준년도
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number; // 회사 아이디.
    employeeId: number; // 직원 아이디.
    occursYear: number; // 기준년도.
    enterDate: string | null; // 입사일자.
    workingYears: number; // 근속 년수.
    workingDays: number; // 근속 일수.
    revisionOccuredLeavesCount: number; // 개정 연차 개수.
    occuredLeavesCount: number; // 발생 연차 개수.
    lapseLeavesCount: number; // 소멸 연차 개수.
    carryoverLeavesCount: number; // 이월 연차 개수.
    modifiedLeavesCount: number; // 조정 연차 개수.
    usedLeavesCount: number; // 사용 연차 개수.
    remainedLeavesCount: number; // 잔여 연차 개수.
  }[]
> {
  const {
    standardYear: standardyear,
    searchCode: searchcode,
    searchWord: searchword,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        standardyear,
        searchcode,
        searchword,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계연도 기준 - 전사 연차현황 총 개수 조회. */
async function findAccountListTotalCount(arg: {
  standardYear: string; // 기준년도
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    standardYear: standardyear,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        standardyear,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계연도 기준 전사 연차현황 - 사원별 사용내역 조회 */
async function findAccountUseList(arg: {
  employeeId: number;
  standardYear: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number; // 회사 아이디.
    employeeId: number; // 사원 아이디.
    registerDate: string; // 작성일자.
    startDate: string;
    endDate: string;
    useLeaves: number; // 사용량.
    remark: string; // 비고.
  }[]
> {
  const {
    employeeId: employeeid,
    standardYear: standardyear,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/user/use/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        employeeid,
        standardyear,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계연도 기준 전사 연차현황 - 사원별 사용내역 총 개수 조회 */
async function findAccountUseTotalCount(arg: {
  employeeId: number;
  standardYear: string;
}): Promise<number> {
  const { employeeId: employeeid, standardYear: standardyear } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/user/use/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        employeeid,
        standardyear,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 입사일자 기준 - 전사 연차현황 조회. */
async function findEnterList(arg: {
  standardDate: string; // 기준일자
  searchCode?: string;
  searchWord?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number; // 회사 아이디.
    employeeId: number; // 직원 아이디.
    enterDate: string | null; // 입사일자.
    accountingStartDate: string; // 연차 기산 시작일.
    accountingEndDate: string; // 연차 기산 종료일.
    workingYears: number; // 근속 년수.
    revisionOccursLeaves: number; // 개정 연차 (월차) 개수.
    occursLeaves: number; // 발생 연차 개수.
    lapseLeaves: number; // 소멸 연차 개수.
    modifingLeaves: number; // 조정 연차 개수.
    useLeaves: number; // 사용 연차 개수.
    remainingLeaves: number; // 잔여 연차 개수.
  }[]
> {
  const {
    standardDate: standarddate,
    searchCode: searchcode,
    searchWord: searchword,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/enterDate/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        standarddate,
        searchcode,
        searchword,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 입사일자 기준 - 전사 연차현황 총 개수 조회. */
async function findEnterListTotalCount(arg: {
  standardDate: string; // 기준일자
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  const {
    standardDate: standarddate,
    searchCode: searchcode,
    searchWord: searchword,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/enterDate/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        standarddate,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 입사일자 기준 전사 연차현황 - 사원별 사용내역 조회 */
async function findEnterUseList(arg: {
  employeeId: number;
  standardDate: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    companyId: number; // 회사 아이디.
    employeeId: number; // 사원 아이디.
    registerDate: string; // 작성일자.
    startDate: string;
    endDate: string;
    useLeaves: number; // 사용량.
    remark: string; // 비고.
  }[]
> {
  const {
    employeeId: employeeid,
    standardDate: standarddate,
    pageNo: pageno,
    rowsPerPage: rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/enterDate/user/use/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        employeeid,
        standarddate,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 입사일자 기준 전사 연차현황 - 사원별 사용내역 총 개수 조회 */
async function findEnterUseTotalCount(arg: {
  employeeId: number;
  standardDate: string;
}): Promise<number> {
  const { employeeId: employeeid, standardDate: standarddate } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/enterDate/user/use/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        employeeid,
        standarddate,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 직원의 현재 기준 잔여연차 (분으로) 조회. */
async function findRemainCount(arg: { employeeId: number }): Promise<number> {
  const { employeeId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/common/user/remain/current`;
    const respone = await axios.get(url, { headers, params: { employeeId } });
    return respone.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계연도 조회. */
async function findAccountYear(
  currentDate: string,
): Promise<{ companyId: number; accountYear: number }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/common/accountyear`;
    const respone = await axios.get(url, { headers, params: { currentDate } });
    return respone.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 회계연도 기준 연차 발생. */
async function createAccountOccurs(arg: {
  standardDate: string;
  creatorId?: number;
  createAt: string;
}): Promise<
  {
    companyId: number; // 회사 아이디.
    occursYear: string; // 기준년도.
    employeeId: number; // 직원 아이디.
    leaveType: number; // 연차 종류.
    belongingYear: string; // 귀속연도 - 연차 집계시 사용.
    accountingStartDate: string; // 사용 시작일
    accountingEndDate: string; // 사용 종료일
    leaves: number; // 연차.
    creatorId: number;
    updaterId: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/occurs/account`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 입사일자 기준 연차 발생. */
async function createEnterDateOccurs(arg: {
  standardDate: string;
  creatorId?: number;
  createAt: string;
}): Promise<boolean> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/occurs/enterdate`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const dayOffStatusApi = {
  accountList: findAccountList,
  accountTotalCount: findAccountListTotalCount,
  accountUseList: findAccountUseList,
  accountUseTotalCount: findAccountUseTotalCount,

  enterList: findEnterList,
  enterListTotalCount: findEnterListTotalCount,
  enterUseList: findEnterUseList,
  enterUseTotalCount: findEnterUseTotalCount,

  remainCount: findRemainCount,

  accountYear: findAccountYear,

  createAccountOccurs,
  createEnterDateOccurs,
};

export default dayOffStatusApi;
