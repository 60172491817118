import React from 'react';
import { IconTypeStrings } from '../../../../../groupware-common/types/icon';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentIcon(): JSX.Element {
  return (
    <ComponentSampleUI.Content>
      <div className="ui-component-icon">
        {IconTypeStrings.map((x) => {
          return (
            <div className="icon-box">
              <i className={`eui-icon eui-icon-${x}`} />
              <span>{x}</span>
            </div>
          );
        })}
      </div>
    </ComponentSampleUI.Content>
  );
}

export default ComponentIcon;
