import React from 'react';

/**
 * @property label 라벨
 * @property checked 선택
 * @property readonly 읽기전용
 * @property disabled 비활성화
 * @property vertical column
 */
type Props = {
  className?: string;
  label?: string;
  checked?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  vertical?: boolean;
  size?: 'sm';
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
};

function Switch(props: Props): JSX.Element {
  const {
    className,
    label,
    checked,
    readonly,
    disabled,
    vertical,
    size,
    onChange,
  } = props;

  let classname = 'eui-switch';
  if (className) classname += ` ${className}`;
  if (size) classname += ` ${size}`;
  if (vertical) classname += ' vertical';

  return (
    <label className={classname} aria-disabled={disabled}>
      <input
        type="checkbox"
        checked={checked}
        readOnly={readonly}
        disabled={disabled}
        onChange={onChange}
      />
      {label && <span className="label">{label}</span>}
    </label>
  );
}

export default Switch;
