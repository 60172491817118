import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import {
  b62,
  createQueryString,
  getAvatarPath,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../groupware-common/utils';
import { Column } from '../../../../groupware-common/ui/type';
import EuiBody from '../../../../components/layout/EuiBody';
import {
  RootState as S,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { approvalActions } from '../../../stores/approval';
import ApprovalContentHeadList from './ApprovalContentHeadList';
import ApprovalContentBodyList from './ApprovalContentBodyList';
import ApprovalContentBodyViewContainer from './ApprovalContentBodyViewContainer';
import ApprovalStatusDrawer from '../../common/drawers/ApprovalStatusDrawer';
import ApprovalDialogContainer from './ApprovalDialogContainer';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import ApprovalDocumentInfoDrawer from '../../common/drawers/ApprovalDocumentInfoDrawer';
import Security from '../../../../groupware-webapp/pages/popup/Security';
import ListSetting from '../../../../groupware-webapp/pages/popup/ListSetting';
import {
  PostListDensity,
  PostListItemType,
} from '../../../../components/post/PostList';
import ApprovalCommentDrawerContainer from './ApprovalCommentDrawerContainer';
import TreePicker from '../../../../groupware-webapp/pages/popup/TreePicker';
import { IconType } from '../../../../groupware-common/types/icon';
import { documentActions } from '../../../stores/approval/document';
import Menu from '../../../../components/menu/Menu';
import Button from '../../../../components/button/Button';
import RadioGroup from '../../../../components/radio/RadioGroup';
import ApprovalLineDialogContainer, {
  ApprovalLineType,
  getApprovalGroup,
  getApprovalLineDrafter,
  getCurrentApprover,
  SharePermissionType,
  isPreviousApprover,
  getPreviousApproverGroupItemId,
  nonArbitraryDecisionApproving,
  isCurrentApproversReceipting,
} from '../../common/dialogs/ApprovalLineDialogContainer';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import ApprovalContentHeadView from './ApprovalContentHeadView';
import Dialog from '../../../../components/dialog/Dialog';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import FeedBack from '../../../../components/alert/FeedBack';
import {
  appError,
  getDirectoryData,
} from '../../../../groupware-webapp/stores/common/utils';
import { ActionEventProps } from '../../../../components/toolbarAction/ToolbarAction';
import ApprovalPrint from '../../common/components/ApprovalPrint';
import ApprovalAttachedDocumentDialog from './content/ApprovalAttachedDocumentDialog';
import ApprovalCancelDialog from './common/dialog/ApprovalCancelDialog';
import { userSettingsSliceActions } from '../../../stores/approval/user-settings';
import {
  AttachFile,
  SharedFile,
  documentApi,
} from '../../../apis/approval/v1/document';
import { archiveActions } from '../../../stores/approval/archive';
import AccessDenied from '../../../../components/error/AccessDenied';
import ApprovalChangeHistoryDrawer from '../../common/drawers/ApprovalChangeHistoryDrawer';
import ApprovalDocumentViewerDrawer from '../../common/drawers/ApprovalDocumentViewerDrawer';
import personalDocumentActions from '../../../stores/approval/personal/document/actions';
import Confirmation from '../../../../components/alert/Confirmation';
import ApprovalAttachedFileDialog from './content/ApprovalAttachedFileDialog';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import TextField from '../../../../components/textfield/TextField';
import { approvaluserPreferencesActions } from '../../../stores/approval/userPreferences';
import Tree from '../../../../components/tree/Tree';
import { SearchDateProps } from '../../../../components/search/Search';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';

export interface ParentDialog {
  documentId: number;
  affiliatedCompanyId?: number;
  receiptDocumentId: number;
  receiptAffiliatedCompanyId?: number;
}

export interface AttachedDialog {
  documentId: number;
  affiliatedCompanyId?: number;
}

function ApprovalContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${ApprovalContentContainer.name}.render(props)`, props);
  const { pathname, search } = props;
  const pathParams = getPathParams<{ p1?: number; p2?: number }>(
    `/*/:p1$base62/:p2$base62`,
    pathname,
  );
  const propsFolderId = pathParams.p1;
  const propsItemId = pathParams.p2;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const displayDensity = useSelector(
    (state: S) => state.approval2.userSettings.displayDensity,
  );
  const display = useSelector((s: S) => s.session.display);

  const currentRowsPerPage = useSelector(
    (state: S) =>
      queryParams.rowsPerPage ?? state.approval2.userSettings.rowsPerPage,
  );

  /** 임시 보관 폴더 여부. */
  const isTemporaryStorageFolder = propsFolderId === 1007;
  /** 현재 기록물철 조직 아이디. */
  const archiveOrganizationId = useSelector(
    (s: S) => s.approval2.archive.currentOrganizationId,
  );
  const readingPaneMode = useSelector((state: S) =>
    isTemporaryStorageFolder || display === 'phone'
      ? 'list'
      : state.approval2.document.readingPaneMode,
  );

  const principal = useSelector((s: S) => s.session.principal);
  const resource = useSelector((s: S) => s.session.resource);
  const organizationIds = principal.affiliatedOrganizations.map(({ id }) => id);
  const directory = useDirectory();

  const userArchivesList = useSelector(
    (s: S) => s.approval2.archive.folders.data,
  );

  const designators = useSelector(
    (s: S) => s.approval2.surrogateApproval.designators.data,
  );

  const folders = useSelector(
    (s: S) => s.approval2.document.folder.list.data.items,
  );
  const list = useSelector((s: S) => s.approval2.document.list.data);
  const view = useSelector((s: S) =>
    propsItemId !== undefined &&
    propsItemId === s.approval2.document.view.data?.id
      ? s.approval2.document.view.data
      : undefined,
  );
  const historys = useSelector((s: S) =>
    propsItemId !== undefined &&
    propsItemId === s.approval2.document.view.data?.id
      ? s.approval2.document.historys.items
      : undefined,
  );

  const userFolders = useSelector(
    (state: S) => state.approval2.userPreferences.userPreferencesFolder,
  );

  /** 분할 보기 여부. */
  const isSplitView =
    readingPaneMode === 'horizontal' || readingPaneMode === 'vertical';

  /** 개인함 폴더 트리 아이템 배열 */
  const userFolderTreeItems = userFolders.map(({ id, name }) => {
    return {
      id,
      parentId: 0,
      text: name,
      icon: 'folder' as IconType,
    };
  });

  /** 기록물철 폴더 트리 아이템 배열 */
  const userArchivesTreeItems = userArchivesList.map(
    ({ id, name, parentId }) => {
      return {
        id,
        parentId,
        text: name,
        icon: 'folder' as IconType,
      };
    },
  );

  const getFolderType = () => {
    if (propsFolderId === 1009) return 'status';
    const stateFolder = folders.find(({ id }) => id === propsFolderId);
    if (stateFolder) return stateFolder.type;
    const personalFolder = userFolders.find(({ id }) => id === propsFolderId);
    if (personalFolder) return 'personal';
    const archiveFolder = userArchivesList.find(
      ({ id }) => id === propsFolderId,
    );
    if (archiveFolder) return 'archive';
    return undefined;
  };

  /** 리스트 항목 배열 */
  const items =
    list?.items.map((item) => {
      const drafter = getApprovalLineDrafter(item.approvalLine);
      return {
        ...item,
        companyId: principal.companyId,
        attachments: item.attachments.length > 0,
        drafter: drafter?.employeeName || '',
        drafterOrganization: drafter?.organizationName || '',
        drafterJob: drafter?.employeeId ? drafter?.jobPositionName : '',
        drafterAvatar: drafter?.employeeId ? getAvatarPath(drafter) : '',
        draftAt: item.createAt,
        completeAt: item.completeAt || '',
        linkWait: item.linkWait || undefined,
      };
    }) || [];

  const [defaultColumns, setDefaultColumns] = useState<
    Column<PostListItemType>[]
  >([
    { name: 'checked', text: '', type: 'action', visible: true },
    { name: 'work', text: '업무', type: 'form', visible: true },
    { name: 'documentNo', text: '문서번호', type: 'number', visible: true },
    // eslint-disable-next-line prettier/prettier
    {
      name: 'subject',
      text: '제목',
      type: 'post',
      visible: true,
      disable: true,
    },
    { name: 'drafter', text: '기안자', type: 'user', visible: true },
    { name: 'draftAt', text: '기안일', type: 'date', visible: true },
    // { name: 'status', text: '상태', type: '???', visible: true },
    { name: 'completeAt', text: '종료일', type: 'date', visible: true },
  ]);

  const download = (blob: Blob) => {
    if (!blob) throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  // 읽기 창 모드 변경.
  const handleReadingPaneModeChange = (
    mode: 'list' | 'vertical' | 'horizontal',
  ) => {
    if (mode === 'list') {
      const location = utils.getLocation({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          mode: 'replace',
        },
      });
      dispatch(sessionActions.setRoute(location));
    }
    dispatch(documentActions.setReadingPaneMode(mode));
  };

  // 컬럼 보이기 변경.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setDefaultColumns((prevState) =>
      prevState.map((x) => (x.name === name ? { ...x, visible } : x)),
    );
  };

  /** 리스트 아이템 체크 변경. */
  const handleListItemCheckedChange = (itemId: 'all', checked: boolean) => {
    dispatch(documentActions.checked({ itemId, checked }));
  };

  /** 리스트 아이템 클릭. */
  const handleListItemClick = (id: number) => {
    // console.log(`handleListItemClick(id: ${id})`);

    if (propsFolderId === undefined) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '폴더를 찾을 수 없습니다.',
      }));
      return;
    }

    // 폴더가 임시 보관인 경우 글쓰기로 이동.
    if (propsFolderId === 1007) {
      queryParams.contentMode = 'create';
      queryParams.contentType = 'temp';
      go(`${getPathMap('/*/*', pathname)}/${b62(id)}`, queryParams);
      return;
    }

    if (isSplitView) {
      const route = {
        pathname: `${getPathMap('/*/*', pathname)}/${b62(id)}`,
        search,
      };
      dispatch(documentActions.view({ folderId: propsFolderId, id, route }));
    } else {
      go(`${getPathMap('/*/*', pathname)}/${b62(id)}`, search);
    }
  };

  const [state, setState] = useState<{
    publicPopupRect:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    publicMode: boolean;
    alertMessage: string;
    selectedAttachId: number;
    dialogs: {
      dialogType: 'attacheddocument' | 'parentdocument';
      documentId: number;
      affiliatedCompanyId: number;
    }[];
    attachFileView?: AttachFile;
    sharedFileView?: SharedFile;
    personalFolderName: string;
    selectedId?: number;
    saveing?: boolean;
  }>({
    publicPopupRect: undefined,
    publicMode: false,
    selectedAttachId: 0,
    alertMessage: '',
    attachFileView: undefined,
    sharedFileView: undefined,
    dialogs: [],
    personalFolderName: '',
    selectedId: undefined,
    saveing: false,
  });

  useEffect(() => {
    async function run() {
      const { personalFolderName } = state;
      dispatch(
        approvaluserPreferencesActions.savefetchPreferencesFolder({
          status: 1,
          name: personalFolderName,
          description: '',
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined) {
          setState((prevState) => ({
            ...prevState,
            personalFolderName: '',
            selectedId: result.payload[result.payload.length - 1].id,
            saveing: false,
          }));
        }
      });
    }

    if (state.saveing) run();
  }, [state.saveing]);

  // 공개범위
  const publicOption = [
    { value: false, label: '비공개' },
    { value: true, label: '공개' },
  ];

  const handlePublicPopup = (event?: React.MouseEvent) => {
    if (state.publicPopupRect)
      setState((prevState) => ({
        ...prevState,
        publicPopupRect: undefined,
      }));
    else if (event) {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        publicPopupRect: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  const handleChangePublicMode = (value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      publicMode: value,
    }));
  };

  /* 공개범위 수정 이벤트 */
  const handlePublicSave = () => {
    const data = list?.items
      .filter((x) => x.checked)
      .map((a) => {
        return {
          id: a.id,
          public: state.publicMode,
          updateAt: a.updateAt,
        };
      });

    if (data === undefined) return;

    dispatch(approvalActions.ListPublicSave({ data }));
    handlePublicPopup();
  };

  /** 목록 이동. */
  const handleListGo = () => {
    // console.log(`handleListGo`);
    // 결재현황 폴더일 경우
    if (propsFolderId === 1009) {
      go(`${getPathMap('/*', pathname)}/status`, search);
      return;
    }
    go(`${getPathMap('/*/*', pathname)}`, search);
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, alertMessage: '' }));
  };

  /** 문서 보기. */
  const handleViewDocument = (arg: {
    affiliatedCompanyId?: number;
    id: number;
  }) => {
    // console.log(`handleViewDocument(arg)`, arg);
    const { id } = arg;
    go(`${getPathMap('/*/*', pathname)}/${b62(id)}`, search);
  };

  /* 삭제,완전삭제,공개범위,복사,모두읽은상태로표시 이벤트 */

  /** 조회. */
  const handleSearch = (arg: { code: string; word: string }) => {
    const { code, word } = arg;
    let searchPath = pathname;
    delete queryParams.subject;
    delete queryParams.documentNumber;
    delete queryParams.workName;
    delete queryParams.formName;
    delete queryParams.content;
    delete queryParams.attachedFileName;
    delete queryParams.drafterName;
    delete queryParams.documentNumber;

    queryParams.searchWord = word === '' ? undefined : word;
    queryParams.searchCode = code === '' || word === '' ? undefined : code;
    delete queryParams.pageNo;
    if (isSplitView) searchPath = `${getPathMap('/*/*', pathname)}`;

    dispatch(sessionActions.search(getQueryParams(queryParams)));
    go(searchPath, queryParams);
  };

  /** 상세 검색 */
  const handleSearchAdvanced = (arg: {
    filters: {
      code: string;
      keyword: string;
    }[];
    date: SearchDateProps;
  }) => {
    let searchPath = pathname;
    delete queryParams.searchCode;
    delete queryParams.searchWord;
    delete queryParams.pageNo;

    const { start, end, type } = arg.date;

    // 기간 검색
    if (start !== null && end !== null) {
      queryParams.dateType = type;
      queryParams.startDate = dateFormat(initialDate(start), 'yyyy-MM-DD');
      queryParams.endDate = dateFormat(initialDate(end), 'yyyy-MM-DD');
    }
    if (type === 'all') {
      queryParams.dateType = undefined;
      queryParams.startDate = undefined;
      queryParams.endDate = undefined;
    }

    // 상세 검색
    arg.filters.forEach(({ code, keyword }) => {
      if (code === 'subject')
        queryParams.subject = keyword === '' ? undefined : keyword;
      if (code === 'documentNumber')
        queryParams.documentNumber = keyword === '' ? undefined : keyword;
      if (code === 'workName')
        queryParams.workName = keyword === '' ? undefined : keyword;
      if (code === 'formName')
        queryParams.formName = keyword === '' ? undefined : keyword;
      if (code === 'content')
        queryParams.content = keyword === '' ? undefined : keyword;
      if (code === 'attachedFileName')
        queryParams.attachedFileName = keyword === '' ? undefined : keyword;
      if (code === 'drafterName')
        queryParams.drafterName = keyword === '' ? undefined : keyword;
      if (code === 'draftOrganizationName')
        queryParams.draftOrganizationName =
          keyword === '' ? undefined : keyword;
      if (code === 'approvalTargetName')
        queryParams.approvalTargetName = keyword === '' ? undefined : keyword;
    });
    if (isSplitView) searchPath = `${getPathMap('/*/*', pathname)}`;
    go(searchPath, queryParams);
  };

  /** 헤드 액션 */
  const handleHeadAction = (arg: { code: string; event: React.MouseEvent }) => {
    // console.log(`handleHeadAction(arg)`, arg);
    const { code } = arg;

    // 이전 페이지인 경우.
    if (code === 'prevPage') {
      const pageNo = (queryParams.pageNo || 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        if (isSplitView) go(`${getPathMap('/*/*', pathname)}`, queryParams);
        else go(pathname, queryParams);
      }
      return;
    }
    // 다음 페이지인 경우.
    if (code === 'nextPage') {
      const pageNo = (queryParams.pageNo || 1) + 1;
      if ((queryParams.rowsPerPage || 1 * pageNo) < list.totalCount) {
        queryParams.pageNo = pageNo;
        if (isSplitView) go(`${getPathMap('/*/*', pathname)}`, queryParams);
        else go(pathname, queryParams);
      }
      return;
    }
    // 목록 설정인 경우.
    if (code === 'listSetting') {
      dispatch(sessionActions.setDrawer({ type: code }));
    }

    // 개인 폴더로 문서 복사, 개인함 이동, 기록물철 이동, 프린터 인 경우
    if (
      code === 'PERSONAL_DOCUMENTS_COPY' ||
      code === 'PERSONAL_DOCUMENTS_MOVE' ||
      code === 'ARCHIVE_DOCUMENTS_MOVE' ||
      code === 'prints'
    ) {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    // 일괄승인
    if (code === 'batchApproval') {
      const data = list?.items
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      if (data === undefined) return;

      if (data.length === 0) {
        alert('체크박스를 선택하세요.');
        return;
      }

      dispatch(
        approvalActions.ListBatchApproval({
          data,
        }),
      );
    }

    // 공개범위 변경
    if (code === 'public') {
      // handlePublicPopup(event);
    }

    // 반려, 회수, 임시보관 삭제
    if (code === 'listToTrash')
      dispatch(sessionActions.setDialog({ type: code }));
    // 목록에서 개인 폴더 문서 삭제.
    if (code === 'PERSONAL_DOCUMENTS_DELETE') {
      if (propsFolderId === undefined) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: '원본 폴더를 찾을 수 없습니다.',
        }));
        return;
      }

      const data = list.items
        .filter((a) => a.checked)
        .map((a) => ({
          folderId: propsFolderId,
          affiliatedCompanyId: a.affiliatedCompanyId,
          id: a.id,
          updateAt: a.updateAt,
        }));

      if (data.length === 0) return;
      if (queryParams.pageNo) delete queryParams.pageNo;

      const location = utils.getLocation({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          search: getQueryParams(queryParams),
          mode: 'replace',
        },
      });
      const route = utils.getRoute({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          search: getQueryParams(queryParams),
        },
      });
      if (queryParams.pageNo)
        dispatch(personalDocumentActions.delete({ data, location }));
      else dispatch(personalDocumentActions.delete({ data, route }));
    }

    // 완전삭제
    if (code === 'delete') {
      const data = list?.items
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      if (data === undefined) return;

      if (data.length === 0) {
        alert('체크박스를 선택하세요.');
        return;
      }

      dispatch(
        approvalActions.ListDeleteApproval({
          data,
        }),
      );
    }

    // 저장
    if (code === 'save') {
      const param = list?.items
        .filter((a) => a.checked)
        .map((a) => {
          return {
            id: a.id,
            affiliateCompanyId: a.affiliatedCompanyId,
          };
        });
      if (param.length === 0) return;
      documentApi
        .download(param)
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }

    // 모두 읽은 상태로 표시
    if (code === 'read') {
      const data = list?.items
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      if (data === undefined) return;

      if (data.length === 0) {
        alert('체크박스를 선택하세요.');
        return;
      }

      dispatch(
        approvalActions.ListAllReadApproval({
          data,
        }),
      );
    }
  };

  /** 헤드 뷰 액션 */
  const handleHeadViewAction = (arg: {
    code: string;
    event: React.MouseEvent;
  }) => {
    // console.log(`handleHeadViewAction(arg)`, arg);
    const { code: type } = arg;

    // 회수 대화 상자 열기.
    if (type === 'retrieve') dispatch(sessionActions.setDialog({ type }));
    // 결재 대화 상자 열기.
    else if (type === 'approval') {
      if (view) {
        const currentApprover = getCurrentApprover({
          approvalLine: view.approvalLine,
          companyId: principal.companyId,
          organizationIds,
          employeeId: principal.employeeId,
          designatorIds: designators.map(({ designatorId }) => designatorId),
          approvalFolder: propsFolderId === 1002,
        });

        if (currentApprover === undefined)
          dispatch(sessionActions.error('결재자를 찾을 수 없습니다.'));
        else dispatch(sessionActions.setDialog({ type }));
      }
    }
    // 결재 취소 대화 상자 열기.
    else if (type === 'approvalCancel')
      dispatch(sessionActions.setDialog({ type }));
    // 내부 결재.
    else if (type === 'internal' && view) {
      queryParams.contentMode = 'create';
      queryParams.contentType = 'receipt';
      if (view.receiptFormId !== 0) {
        queryParams.receiptFormId = view.receiptFormId;
        go(pathname, queryParams);
      } else
        dispatch(sessionActions.error('내부결재 양식을 찾을 수 없습니다.'));
    }
    // 재기안, 재접수
    else if (type === 'reDraft' || type === 'reReceipt') {
      queryParams.contentMode = 'create';
      queryParams.contentType = 'reDraft';
      go(pathname, queryParams);
    }
    // 문서 정보 드로워 열기.
    else if (type === 'info') dispatch(sessionActions.setDrawer({ type }));
    // 결재 현황 드로워 열기.
    else if (type === 'status') dispatch(sessionActions.setDrawer({ type }));
    // 조회 드로워 열기
    else if (type === 'viewer') dispatch(sessionActions.setDrawer({ type }));
    // TODO 문서 변경.
    else if (type === 'change') {
      queryParams.contentMode = 'update';
      delete queryParams.contentType;
      go(pathname, queryParams);
    }
    // 결재선 변경 대화 상자 열기.
    else if (type === 'approvalLine')
      dispatch(sessionActions.setDialog({ type }));
    // 참조권 변경 대화 상자 열기.
    else if (type === 'referencePermission')
      dispatch(sessionActions.setDialog({ type }));
    // 조회권 변경 대화 상자 열기.
    else if (type === 'viewPermission')
      dispatch(sessionActions.setDialog({ type }));
    // 복사, 개인함 이동, 기록물철 이동, 프린터 인 경우
    else if (
      type === 'PERSONAL_DOCUMENT_COPY' ||
      type === 'PERSONAL_DOCUMENT_MOVE' ||
      type === 'ARCHIVE_DOCUMENT_MOVE' ||
      type === 'print'
    ) {
      dispatch(sessionActions.setDialog({ type }));
      // 변경내역 확인 드로워 열기
    } else if (type === 'history') {
      if (view === undefined) return;
      const documentId = view.id;

      dispatch(documentActions.fetchHistoryList({ documentId })).then(() => {
        dispatch(sessionActions.setDrawer({ type }));
      });
    }
    // 반려, 회수, 임시보관 삭제
    else if (type === 'viewToTrash')
      dispatch(sessionActions.setDialog({ type }));
    // 보기에서 개인 폴더 문서 삭제.
    else if (type === 'PERSONAL_DOCUMENT_DELETE') {
      if (propsFolderId === undefined) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: '폴더를 찾을 수 없습니다.',
        }));
        return;
      }

      if (!view) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: '문서를 찾을 수 없습니다.',
        }));
        return;
      }
      const data = [
        {
          folderId: propsFolderId,
          affiliatedCompanyId: view.affiliatedCompanyId,
          id: view.id,
          updateAt: view.updateAt,
        },
      ];
      if (queryParams.pageNo) delete queryParams.pageNo;

      const location = utils.getLocation({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          search: getQueryParams(queryParams),
          mode: 'replace',
        },
      });
      const route = utils.getRoute({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          search: getQueryParams(queryParams),
        },
      });
      if (queryParams.pageNo) {
        dispatch(personalDocumentActions.delete({ data, location }));
      } else if (isSplitView)
        dispatch(personalDocumentActions.delete({ data, route }));
      else dispatch(personalDocumentActions.delete({ data, location }));
    }
    // 저장.
    else if (type === 'save') {
      if (!view) return;
      documentApi
        .download([{ id: view.id }])
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
    // 반려된 내부문서 - 반려 대화상자 열기
    else if (type === 'receiptReturn')
      dispatch(sessionActions.setDialog({ type }));
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleItemCheckedChange = (id: number, checked: boolean) => {
    // console.log(`handleItemCheckedChange(id: ${id}, checked: ${checked})`);
    dispatch(documentActions.checked({ itemId: id, checked }));
  };

  // 의견 카운트 클릭
  const handleClickOpinionCount = (id: number, type: string) => {
    queryParams.id = id;
    if (type === 'comments') queryParams.drawerType = 'comments';
    else queryParams.drawerType = 'opinions';
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  // 화면밀도 설정
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(userSettingsSliceActions.displayDensity(value));
  };

  // 목록개수 설정
  const handleChangeListCount = (value: string) => {
    queryParams.pageNo = 1;
    go(
      `${getPathMap('/*/*', pathname)}`,
      createQueryString(
        {
          rowsPerPage: parseInt(value, 10),
          drawerMode: undefined,
          drawerType: undefined,
        },
        queryParams,
      ),
    );
  };

  // 정렬

  // 필터

  // 보안결재
  const handleConfirmSecurity = () => {
    // console.log('보안결재');
  };

  /** dialog 닫기 */
  const handleCloseAttachedDialog = (arg: {
    dialogType: 'attacheddocument' | 'parentdocument';
    documentId: number;
    affiliateCompanyId?: number;
  }) => {
    const { dialogType, documentId, affiliateCompanyId } = arg;
    // 첨부파일 문서,
    if (dialogType === 'attacheddocument') {
      if (state.dialogs.length > 2) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else if (
        state.dialogs.length === 2 &&
        state.dialogs[0].dialogType === 'parentdocument'
      ) {
        setState((prev) => ({
          ...prev,
          dialogs: prev.dialogs.filter((a) => a.documentId !== documentId),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [],
        }));
      }
    }
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [],
      }));
    }
  };

  // 첨부문서 대화상자 열기
  const handleAttachedDocumentDialog = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument'; // 부모 문서, 첨부 문서
    documentId: number;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    const {
      dialogType,
      documentId,
      affiliatedCompanyId,
      receiptDocumentId,
      receiptAffiliatedCompanyId,
    } = arg;
    if (dialogType === 'parentdocument') {
      setState((prev) => ({
        ...prev,
        dialogs: [
          ...prev.dialogs,
          {
            dialogType: 'parentdocument',
            documentId,
            affiliatedCompanyId: affiliatedCompanyId ?? 0,
            receiptDocumentId,
            receiptAffiliatedCompanyId,
          },
        ],
      }));
    }
    if (dialogType === 'attacheddocument') {
      // 첨부 문서 조회 시 권한 조회를 위해 첨부 문서의 최상위에 원 문서의 아이디가 포함되어야 함.
      if (state.dialogs.length === 0 && view) {
        setState((prev) => ({
          ...prev,
          dialogs: [
            {
              dialogType: 'attacheddocument',
              documentId: view.id,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          dialogs: [
            ...prev.dialogs,
            {
              dialogType: 'attacheddocument',
              documentId,
              affiliatedCompanyId: affiliatedCompanyId ?? 0,
            },
          ],
        }));
      }
    }
  };

  // 첨부파일 대화상자 보기
  const handleAttachedFileDialog = (file: AttachFile) => {
    dispatch(sessionActions.setDialog({ type: 'attachedfile' }));
    setState((prev) => ({
      ...prev,
      attachFileView: file,
    }));
  };

  // 공유파일 대화상자 보기
  const handleSharedFileDialog = (file: SharedFile) => {
    dispatch(sessionActions.setDialog({ type: 'sharedfile' }));
    setState((prev) => ({
      ...prev,
      sharedFileView: file,
    }));
  };

  // 부서결재함 여부
  const isOrganizationFolder =
    propsFolderId !== undefined && propsFolderId > 3000 && propsFolderId < 4000;
  // 결재 완료 혹은 부서결재함이 아닌 경우 컬럼 설정.
  let columns =
    propsFolderId !== 1004 && !isOrganizationFolder
      ? defaultColumns.filter(
          ({ name }) => !(name === 'completeAt' || name === 'documentNo'),
        )
      : defaultColumns;
  // 임시보관함인 경우 기안자 안보이도록 컬럼 설정.
  if (propsFolderId === 1007)
    columns = columns.filter(({ name }) => name !== 'drafter');

  if (list?.items.filter((x) => x.checked).length) {
    document.documentElement.setAttribute('data-selecting', 'true');
  } else {
    document.documentElement.removeAttribute('data-selecting');
  }

  /** 회수 */
  const handleRetrieve = () => {
    // console.log(`handleRetrieve()`);
    if (propsFolderId === undefined) return;
    if (view === null || view === undefined) return;

    const { affiliatedCompanyId, id, updateAt } = view;
    const data = {
      affiliatedCompanyId,
      id,
      updateAt,
    };
    if (queryParams.pageNo) delete queryParams.pageNo;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        option: 'CLEAR_DIALOG',
      },
    });
    if (getQueryParams(search).pageNo)
      dispatch(documentActions.withdraw({ data, location }));
    else if (isSplitView) dispatch(documentActions.withdraw({ data, route }));
    else dispatch(documentActions.withdraw({ data, location }));
  };

  /** 반려, 회수, 임시보관 문서 삭제 */
  const handleMoveToTrash = () => {
    if (queryParams.pageNo) delete queryParams.pageNo;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    // 리스트일 때
    if (queryParams.dialogType === 'listToTrash' && propsFolderId) {
      const data = list.items
        .filter((x) => x.checked)
        .map((a) => {
          return {
            companyId: principal.companyId,
            id: a.id,
            folderId: propsFolderId,
            updateAt: a.updateAt,
          };
        }); // 회수, 임시보관 문서 삭제 데이터
      const returndocuments = list.items
        .filter((x) => x.checked)
        .map((a) => {
          return {
            affiliatedCompanyId: a.affiliatedCompanyId,
            folderId: propsFolderId,
            id: a.id,
          };
        }); // 반려 문서 삭제 데이터
      // 반려함.
      if (propsFolderId === 1005) {
        if (getQueryParams(search).pageNo)
          dispatch(
            documentActions.returnDocumentListMoveToTrashApproval({
              data: returndocuments,
              location,
            }),
          );
        else
          dispatch(
            documentActions.returnDocumentListMoveToTrashApproval({
              data: returndocuments,
              route,
            }),
          );
      } else if (getQueryParams(search).pageNo)
        dispatch(documentActions.listMoveToTrashApproval({ data, location }));
      else dispatch(documentActions.listMoveToTrashApproval({ data, route }));
    }
    // 뷰화면일 때
    if (queryParams.dialogType === 'viewToTrash' && propsFolderId && view) {
      const data = [
        {
          companyId: principal.companyId,
          id: view.id,
          folderId: propsFolderId,
          updateAt: view.updateAt,
        },
      ]; // 회수, 임시보관 문서 삭제 데이터
      const returndocuments = [
        {
          affiliatedCompanyId: view.affiliatedCompanyId,
          folderId: propsFolderId,
          id: view.id,
        },
      ]; // 반려 문서 삭제 데이터
      // 반려함.
      if (propsFolderId === 1005) {
        if (queryParams.pageNo)
          dispatch(
            documentActions.returnDocumentListMoveToTrashApproval({
              data: returndocuments,
              location,
            }),
          );
        else if (isSplitView)
          dispatch(
            documentActions.returnDocumentListMoveToTrashApproval({
              data: returndocuments,
              route,
            }),
          );
        else
          dispatch(
            documentActions.returnDocumentListMoveToTrashApproval({
              data: returndocuments,
              location,
            }),
          );
      } else if (getQueryParams(search).pageNo) {
        dispatch(documentActions.listMoveToTrashApproval({ data, location }));
      } else if (isSplitView)
        dispatch(documentActions.listMoveToTrashApproval({ data, route }));
      else
        dispatch(documentActions.listMoveToTrashApproval({ data, location }));
    }
  };

  /** 결재 확인 */
  const handleApprovalConfirm = (arg: {
    receiptReturn?: boolean;
    approverType: 'organization' | 'employee' | 'surrogater';
    approvalLineGroupItemId: string;
    organizationId: number | undefined;
    designatorId: number | undefined;
    act:
      | 'approval'
      | 'return'
      | 'back'
      | 'hold'
      | 'meet'
      | 'defer'
      | 'arbitraryDecision';
    meetUsers?: {
      companyId: number;
      id: number;
    }[];
    opinion?: string;
  }) => {
    // console.log(`handleApprovalConfirm(arg)`, arg);

    if (propsFolderId === undefined) {
      // setState((prevState) => ({...prevState, validation}));
      return;
    }

    if (view === null || view === undefined) {
      // setState((prevState) => ({...prevState, validation}));
      return;
    }

    const {
      receiptReturn,
      approverType,
      organizationId,
      designatorId,
      act,
      opinion,
    } = arg;

    const {
      id,
      parentAffiliatedCompanyId,
      parentCompanyId,
      parentId,
      parentUpdateAt,
      updateAt,
    } = view;

    const directoryData =
      approverType === 'organization' || approverType === 'surrogater'
        ? getDirectoryData({
            ...directory,
            ...principal,
            organizationId: organizationId ?? principal.organizationId,
          })
        : undefined;

    const data = {
      approverType,
      companyId: principal.companyId,
      id,
      parentCompanyId,
      parentId,
      updateAt,
      act,
      opinion,

      organizationId,
      designatorId,
      approver: directoryData,

      meetingEmployees:
        act === 'meet' && arg.meetUsers
          ? arg.meetUsers.map((a) => {
              return {
                id: a.id,
                companyId: a.companyId,
              };
            })
          : undefined,
    };

    // 반려된 내부문서 반려 데이터 전송 객체.
    const receiptReturnData = {
      affiliatedCompanyId: parentAffiliatedCompanyId,
      id: parentId ?? 0,
      updateAt: parentUpdateAt ?? '',
      opinion,
      organizationId,
      designatorId,
      approver:
        (organizationId !== undefined || designatorId !== undefined) &&
        directoryData !== undefined
          ? directoryData
          : undefined,
    };

    if (isSplitView) {
      const route = utils.getRoute({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          option: 'CLEAR_DIALOG',
        },
      });
      if (act === 'approval') {
        dispatch(documentActions.approval({ data, route }));
      } else if (act === 'return') {
        // 발신 문서 반려
        if (receiptReturn)
          dispatch(
            documentActions.rejectOutgoingDocument({
              data: receiptReturnData,
              route,
            }),
          );
        else dispatch(documentActions.reject({ data, route }));
      } else if (act === 'meet') {
        dispatch(documentActions.meet({ data, route }));
      } else if (act === 'hold') {
        dispatch(documentActions.holdOn({ data, route }));
      } else if (act === 'back') {
        dispatch(documentActions.cancelPreviousApprobation({ data, route }));
      } else if (act === 'arbitraryDecision') {
        dispatch(documentActions.approvalAll({ data, route }));
      } else if (act === 'defer') {
        // TODO 후결 추가
      }
    } else {
      const location = utils.getLocation({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          mode: 'replace',
          option: 'CLEAR_DIALOG',
        },
      });
      if (act === 'approval') {
        dispatch(documentActions.approval({ data, location }));
      } else if (act === 'return') {
        // 발신 문서 반려
        if (receiptReturn)
          dispatch(
            documentActions.rejectOutgoingDocument({
              data: receiptReturnData,
              location,
            }),
          );
        else dispatch(documentActions.reject({ data, location }));
      } else if (act === 'meet') {
        dispatch(documentActions.meet({ data, location }));
      } else if (act === 'hold') {
        dispatch(documentActions.holdOn({ data, location }));
      } else if (act === 'back') {
        dispatch(documentActions.cancelPreviousApprobation({ data, location }));
      } else if (act === 'arbitraryDecision') {
        dispatch(documentActions.approvalAll({ data, location }));
      } else if (act === 'defer') {
        // TODO 후결 추가
      }
    }
  };

  /** 결재 취소. */
  const handleApprovalCancelDialogConfirm = (arg: { opinion?: string }) => {
    if (view === null || view === undefined) return;

    const { id, approvalLine, updateAt, affiliatedCompanyId } = view;

    const approvalLineGroupItemId = getPreviousApproverGroupItemId(
      approvalLine,
      principal.companyId,
      principal.employeeId,
    );
    if (approvalLineGroupItemId === undefined) {
      dispatch(sessionActions.error('결재 취소 대상을 찾을 수 없습니다.'));
      return;
    }

    const { opinion } = arg;
    delete queryParams.dialogType;

    if (isSplitView) {
      dispatch(
        documentActions.cancelApprobation({
          data: {
            affiliatedCompanyId,
            id,
            opinion,
            updateAt,
          },
          route: utils.getRoute({
            target: props,
            source: {
              pathname: `${getPathMap('/*/*', pathname)}`,
              option: 'CLEAR_DIALOG',
            },
          }),
        }),
      );
    } else {
      dispatch(
        documentActions.cancelApprobation({
          data: {
            affiliatedCompanyId,
            id,
            opinion,
            updateAt,
          },
          location: {
            pathname: `${getPathMap('/*/*', pathname)}`,
            search: getQueryParams(queryParams),
            mode: 'replace',
          },
        }),
      );
    }
  };

  /** 결재선 변경 저장 */
  const handleApprovalLineChangeSave = (arg: {
    affiliatedCompanyId?: number;
    companyId: number;
    id: number;
    approvalLine?: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    reason: string;
    updateAt: string;
  }) => {
    // console.log(`handleApprovalLineChangeSave(arg)`, arg);

    const {
      affiliatedCompanyId,
      companyId,
      id,
      approvalLine,
      referencePermission,
      viewPermission,
      reason,
      updateAt,
    } = arg;

    if (
      !(
        principal.companyId === companyId &&
        view?.id === id &&
        view.updateAt === updateAt
      )
    ) {
      // 데이터가 변경되어 수정할 수 없습니다.
      return;
    }

    const { content } = view;

    const route = utils.getRoute({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });

    // 결재선이 변경된 경우.
    if (approvalLine !== undefined) {
      const data = {
        affiliatedCompanyId,
        id,
        approvalLine,
        reason,
        updateAt,
      };
      dispatch(documentActions.changeApprovalLine({ data, route })).then(
        (result) => {
          if ((result as { error?: string }).error === undefined) {
            delete queryParams.dialogType;

            const currentApprover = getCurrentApprover({
              approvalLine,
              companyId: principal.companyId,
              organizationIds,
              employeeId: principal.employeeId,
              designatorIds: designators.map((a) => a.designatorId),
            });
            // 현재 결재자가 로그인 한 정보와 일치하지 않을 경우 리스트 페이지로 이동
            if (!currentApprover) {
              go(`${getPathMap('/*/*', pathname)}`, queryParams);
            }
          }
        },
      );
    }

    // 참조권이 변경된 경우.
    if (referencePermission !== undefined) {
      const data = {
        type: 'referencePermission' as const,
        companyId,
        id,
        referencePermission,
        reason,
        contents: content,
        updateAt,
      };
      dispatch(documentActions.changePermission({ data, route }));
    }

    // 조회권이 변경된 경우.
    if (viewPermission !== undefined) {
      const data = {
        type: 'viewPermission' as const,
        companyId,
        id,
        viewPermission,
        reason,
        contents: content,
        updateAt,
      };
      dispatch(documentActions.changePermission({ data, route }));
    }
  };

  /** 개인함 복사 */
  const handleCopyPersonalDocument = () => {
    const { selectedId } = state;
    const { dialogType } = queryParams;
    if (selectedId === undefined) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '폴더를 선택 해주세요.',
      }));
      return;
    }
    if (propsFolderId === undefined) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '원본 폴더를 찾을 수 없습니다.',
      }));
      return;
    }

    if (propsFolderId === selectedId) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '원본 폴더와 대상 폴더가 동일합니다.',
      }));
      return;
    }

    const route = utils.getRoute({
      target: props,
      source: { option: 'CLEAR_DIALOG' },
    });
    if (dialogType === 'PERSONAL_DOCUMENT_COPY' && view) {
      const data = [
        {
          folderId: selectedId,
          affiliatedCompanyId: view.affiliatedCompanyId,
          id: view.id,
        },
      ];
      dispatch(personalDocumentActions.copy({ data, route }));
    } else if (dialogType === 'PERSONAL_DOCUMENTS_COPY') {
      const data = list.items
        .filter((a) => a.checked)
        .map((a) => ({
          folderId: selectedId,
          affiliatedCompanyId: a.affiliatedCompanyId,
          id: a.id,
        }));
      dispatch(personalDocumentActions.copy({ data, route }));
    }
  };

  /** 개인함 이동 */
  const handleMovePersonalDocument = () => {
    const { selectedId } = state;
    const { dialogType } = queryParams;
    if (selectedId === undefined) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '폴더를 선택 해주세요.',
      }));
      return;
    }
    if (propsFolderId === undefined) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '원본 폴더를 찾을 수 없습니다.',
      }));
      return;
    }

    if (propsFolderId === selectedId) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '원본 폴더와 대상 폴더가 동일합니다.',
      }));
      return;
    }
    if (queryParams.pageNo) delete queryParams.pageNo;

    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    let data: {
      sourceFolderId: number;
      targetFolderId: number;
      affiliatedCompanyId?: number | undefined;
      id: number;
      updateAt: string;
    }[] = [
      {
        sourceFolderId: 0,
        targetFolderId: 0,
        affiliatedCompanyId: undefined,
        id: 0,
        updateAt: '',
      },
    ];

    // 보기에서 개인 폴더 문서 이동
    if (dialogType === 'PERSONAL_DOCUMENT_MOVE' && view) {
      data = [
        {
          sourceFolderId: propsFolderId,
          targetFolderId: selectedId,
          id: view.id,
          updateAt: view.updateAt,
        },
      ];
      // 목록에서 개인 폴더 문서 이동
    } else if (dialogType === 'PERSONAL_DOCUMENTS_MOVE') {
      data = list.items
        .filter((a) => a.checked)
        .map((a) => ({
          sourceFolderId: propsFolderId,
          targetFolderId: selectedId,
          affiliatedCompanyId: a.affiliatedCompanyId,
          id: a.id,
          updateAt: a.updateAt,
        }));
    }
    if (queryParams.pageNo)
      dispatch(personalDocumentActions.move({ data, location }));
    else if (
      isSplitView ||
      (!isSplitView && dialogType === 'PERSONAL_DOCUMENTS_MOVE')
    )
      dispatch(personalDocumentActions.move({ data, route })).then(() => {
        setState((prev) => ({
          ...prev,
          selectedId: undefined,
          personalFolderName: '',
        }));
      });
    else dispatch(personalDocumentActions.move({ data, location }));
  };

  /** 개인함 이름 변경 */
  const handlePersonalFolderNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      personalFolderName: event.target.value,
    }));
  };

  /** 개인함 생성 */
  const handlePersonalFolderSave = () => {
    if (state.personalFolderName === '') {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '개인함명을 입력해주세요.',
      }));
      return;
    }
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  // 개인함 선택
  const handleSelectedId = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedId: id,
    }));
  };

  // 부서장 check
  const manager = principal.affiliatedOrganizations.find(
    (a) => a.id === archiveOrganizationId,
  )?.manager;

  /** 헤드 렌터링 */
  const renderHead = (arg?: { type: 'list' | 'view' }) => {
    const type =
      arg?.type || (readingPaneMode === 'list' && propsItemId)
        ? 'view'
        : 'list';

    // 뷰 보기인 경우.
    if (type === 'view') {
      if (!view) return null;
      const approvalLineFlat = view.approvalLine.groups
        .map((a) => a.items)
        .flat();
      // 결재자 중 결재 진행여부 판단.
      const isNextApprover =
        approvalLineFlat.filter((a) => a.act !== 'draft' && a.actAt).length !==
        0;
      const drafter = getApprovalLineDrafter(view.approvalLine);
      let toolbarButtons: ActionEventProps[];
      // 폴더 별 툴바 버튼 설정.
      switch (propsFolderId) {
        // 기안
        case 1001: {
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          // 다음 결재자가 결재하지 않은 경우.
          // 내부 결재가 아닌 경우
          if (
            view &&
            isNextApprover === false &&
            !view.parentCompanyId &&
            !view.parentId
          ) {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'retrieve', label: '회수', type: 'text', icon: 'backspace-fill' },
              ...toolbarButtons,
            ];
          }
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }
          if (display !== 'phone' || resource !== 'teams')
            toolbarButtons = [
              ...toolbarButtons,
              { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
              { code: 'save', label: '저장', type: 'text', icon: 'save' },
            ];
          break;
        }
        // 결재.
        case 1002: {
          const approvalGroup = getApprovalGroup(view.approvalLine);
          // console.log('----------approvalGroup', approvalGroup);
          const approver = getCurrentApprover({
            approvalLine: view.approvalLine,
            companyId: principal.companyId,
            organizationIds,
            employeeId: principal.employeeId,
            designatorIds: designators.map((a) => a.designatorId),
            approvalFolder: propsFolderId === 1002,
          });
          // 현재 결재 그룹이 수신 그룹인 경우.
          if (approvalGroup?.type === 'receive') {
            if (approver === undefined || approver.act === 'receipt')
              toolbarButtons = [];
            else if (approver.act === undefined) {
              toolbarButtons = [
                // eslint-disable-next-line prettier/prettier
                { code: 'approval', label: '결재', type: 'contained', icon: 'stamp-check' },
                // eslint-disable-next-line prettier/prettier
                { code: 'internal', label: '내부결재', type: 'text', icon: 'stamp-check' },
              ];
            } else {
              toolbarButtons = [
                // eslint-disable-next-line prettier/prettier
                { code: 'approval', label: '결재', type: 'contained', icon: 'stamp-check' },
              ];
            }
          }
          // 현재 결재 그룹이 수신 그룹이 아닌 경우.
          else {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'approval', label: '결재', type: 'contained', icon: 'stamp-check' },
            ];
          }

          toolbarButtons = [
            ...toolbarButtons,
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          // 접수 중이 아닌 경우. 대결자 아닌 경우
          if (
            !isCurrentApproversReceipting(view.approvalLine) &&
            ((approver?.employeeId === undefined &&
              organizationIds.some((a) => a === approver?.organizationId)) ||
              approver?.employeeId === principal.employeeId)
          ) {
            // 연동문서가 아닌 경우
            if (view.linkType === 'NONE') {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'change', label: '문서 변경', type: 'text', icon: 'document-update', },
              ];
            }
            if (view.approvalLine.groups.filter((a) => a.modify).length > 0) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'approvalLine', label: '결재선 변경', type: 'text', icon: 'line-dot' },
              ];
            }
            if (
              view.referencePermission &&
              view.referencePermission.groups.filter((a) => a.modify).length > 0
            ) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'referencePermission', label: '참조권 변경', type: 'text', icon: 'document-search' },
              ];
            }
            if (
              view.viewPermission &&
              view.viewPermission.groups.filter((a) => a.modify).length > 0
            ) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'viewPermission', label: '조회권 변경', type: 'text', icon: 'document-search' },
              ];
            }
          }
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }
          if (display !== 'phone' || resource !== 'teams')
            toolbarButtons = [
              ...toolbarButtons,
              { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
              { code: 'save', label: '저장', type: 'text', icon: 'save' },
            ];
          break;
        }
        // 진행
        case 1003:
          if (
            nonArbitraryDecisionApproving(view.approvalLine) &&
            isPreviousApprover(
              view.approvalLine,
              principal.companyId,
              principal.employeeId,
            )
          )
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'approvalCancel', label: '결재취소', type: 'contained', icon: 'stamp-check' },
            ];
          else toolbarButtons = [];

          toolbarButtons = [
            ...toolbarButtons,
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          if (
            approvalLineFlat.some(
              (a) =>
                a.employeeId === principal.employeeId ||
                (a.employeeId === undefined &&
                  organizationIds.some((z) => z === a.organizationId)),
            )
          ) {
            if (
              view.referencePermission &&
              view.referencePermission.groups.filter((a) => a.modify).length > 0
            ) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'referencePermission', label: '참조권 변경', type: 'text', icon: 'document-search' },
              ];
            }
            if (
              view.viewPermission &&
              view.viewPermission.groups.filter((a) => a.modify).length > 0
            ) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'viewPermission', label: '조회권 변경', type: 'text', icon: 'document-search' },
              ];
            }
          }
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }
          if (display !== 'phone' || resource !== 'teams')
            toolbarButtons = [
              ...toolbarButtons,
              { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
              { code: 'save', label: '저장', type: 'text', icon: 'save' },
            ];
          break;
        // 완료
        case 1004:
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          // 내부결재 문서가 아닌 경우. 해당 업무,양식이 변경되지 않은 경우.
          if (
            view.isReDraft === false &&
            principal.employeeId === view.creatorId &&
            view.parentId === undefined
          ) {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'reDraft', label: '재기안', type: 'contained', icon: 'stamp-check' },
              ...toolbarButtons,
            ];
          }
          if (
            approvalLineFlat.some(
              (a) =>
                a.employeeId === principal.employeeId ||
                (a.employeeId === undefined &&
                  organizationIds.some((z) => z === a.organizationId)),
            )
          ) {
            if (
              view.referencePermission &&
              view.referencePermission.groups.filter((a) => a.modify).length > 0
            ) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'referencePermission', label: '참조권 변경', type: 'text', icon: 'document-search' },
              ];
            }
            if (
              view.viewPermission &&
              view.viewPermission.groups.filter((a) => a.modify).length > 0
            ) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'viewPermission', label: '조회권 변경', type: 'text', icon: 'document-search' },
              ];
            }
          }
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }

          toolbarButtons = [
            ...toolbarButtons,
            // eslint-disable-next-line prettier/prettier
            { code: 'PERSONAL_DOCUMENT_COPY', label: '복사', type: 'text', icon: 'copy' },
          ];
          if (display !== 'phone' || resource !== 'teams')
            toolbarButtons = [
              ...toolbarButtons,
              { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
              { code: 'save', label: '저장', type: 'text', icon: 'save' },
            ];
          break;
        // 반려
        case 1005:
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          // 내부결재 문서이고, 원문서가 진행 중인 경우.
          // 내부결재 문서 재접수를 하지 않은 경우.
          if (
            view.isReReceipt === false &&
            view.parentStatus === 1 &&
            ((drafter?.employeeId === undefined &&
              organizationIds.some((a) => a === drafter?.organizationId)) ||
              drafter?.employeeId === principal.employeeId ||
              designators.some((a) => a.designatorId === drafter?.employeeId))
          ) {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'reReceipt', label: '재접수', type: 'contained', icon: 'stamp-check' },
              // eslint-disable-next-line prettier/prettier
              { code: 'receiptReturn', label: '반려', type: 'text', icon: 'backspace-fill' },
              ...toolbarButtons,
            ];
          }
          if (
            view.isReDraft === false &&
            principal.employeeId === view.creatorId &&
            view.parentId === undefined
          ) {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'reDraft', label: '재기안', type: 'contained', icon: 'stamp-check' },
              ...toolbarButtons,
            ];
          }
          if (
            view.parentStatus !== 1 &&
            approvalLineFlat.some(
              (a) =>
                a.employeeId === undefined &&
                organizationIds.some((z) => z === a.organizationId),
            ) === false
          ) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'viewToTrash', label: '삭제', type: 'text', icon: 'trash-full' },
            ];
          }
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }
          break;
        // 회수
        case 1006:
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          if (
            view.isReDraft === false &&
            principal.employeeId === view.creatorId &&
            view.parentId === undefined
          ) {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'reDraft', label: '재기안', type: 'contained', icon: 'stamp-check' },
              ...toolbarButtons,
            ];
          }
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }
          toolbarButtons = [
            ...toolbarButtons,
            // eslint-disable-next-line prettier/prettier
            { code: 'viewToTrash', label: '삭제', type: 'text', icon: 'trash-full' },
          ];
          break;
        // 임시보관
        case 1007:
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'viewToTrash', label: '삭제', type: 'text', icon: 'trash-full' },
          ];
          break;
        // 부서결재함, 회람문서
        case 3001:
        case 3002:
        case 3003:
        case 3004:
        case 4001:
        case 4002:
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
            // eslint-disable-next-line prettier/prettier
            { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
            // eslint-disable-next-line prettier/prettier
            { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
          ];
          if (view.change) {
            toolbarButtons = [
              ...toolbarButtons,
              // eslint-disable-next-line prettier/prettier
              { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
            ];
          }
          if (display !== 'phone' || resource !== 'teams')
            toolbarButtons = [
              ...toolbarButtons,
              { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
              { code: 'save', label: '저장', type: 'text', icon: 'save' },
            ];
          break;
        default:
          if (
            userArchivesList.find((x) => propsFolderId === x.id) !== undefined
          ) {
            /** 기록물철 헤더 */
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
              // eslint-disable-next-line prettier/prettier
              { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
              // eslint-disable-next-line prettier/prettier
              { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
            ];
            if (view.change) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
              ];
            }
            if (manager === true) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'ARCHIVE_DOCUMENT_MOVE', label: '이동', type: 'text', icon: 'folder-move', },
              ];
            }
            if (display !== 'phone' || resource !== 'teams')
              toolbarButtons = [
                ...toolbarButtons,
                { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
                { code: 'save', label: '저장', type: 'text', icon: 'save' },
              ];
            break;
          } else {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'info', label: '문서정보', type: 'text', icon: 'info-circle' },
              // eslint-disable-next-line prettier/prettier
              { code: 'status', label: '결재현황', type: 'text', icon: 'line-dot' },
              // eslint-disable-next-line prettier/prettier
              { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
            ];
            if (view.change) {
              toolbarButtons = [
                ...toolbarButtons,
                // eslint-disable-next-line prettier/prettier
                { code: 'history', label: '변경내역 확인', type: 'text', icon: 'history' },
              ];
            }
            /** 개인함 헤더 */
            if (
              userFolderTreeItems.find((a) => a.id === propsFolderId) !==
              undefined
            )
              toolbarButtons = [
                ...toolbarButtons,
                {
                  code: 'PERSONAL_DOCUMENT_DELETE',
                  label: '삭제',
                  type: 'text',
                  icon: 'trash-full',
                },
                {
                  code: 'PERSONAL_DOCUMENT_MOVE',
                  label: '이동',
                  type: 'text',
                  icon: 'folder-move',
                },
                // eslint-disable-next-line prettier/prettier
                { code: 'PERSONAL_DOCUMENT_COPY', label: '복사', type: 'text', icon: 'copy' },
              ];
            if (display !== 'phone' || resource !== 'teams')
              toolbarButtons = [
                ...toolbarButtons,
                { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
                { code: 'save', label: '저장', type: 'text', icon: 'save' },
              ];
            break;
          }
      }

      if (readingPaneMode === 'horizontal' || readingPaneMode === 'vertical')
        return (
          <ApprovalContentHeadView
            type="split"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleHeadViewAction}
          />
        );

      const { prev, next } = view;
      return (
        <ApprovalContentHeadView
          type="full"
          onListGo={handleListGo}
          onPrev={prev ? () => handleViewDocument(prev) : undefined}
          onNext={next ? () => handleViewDocument(next) : undefined}
          toolbarButtons={toolbarButtons}
          onToolbarAction={handleHeadViewAction}
        />
      );
    }

    // 목록 보기인 경우.
    // 전자결재 레프트 메뉴 이름
    let title = folders.find(({ id }) => id === propsFolderId)?.name;
    // 전자결재 개인함 메뉴 이름
    if (title === undefined)
      title = userFolders.find(({ id }) => id === propsFolderId)?.name || '';
    // 전자결재 기록물철 메뉴 이름
    if (title === '')
      title =
        userArchivesList.find(({ id }) => id === propsFolderId)?.name || '';

    let toolbarButtons: ActionEventProps[];
    // 폴더 별 툴바 버튼 설정.
    switch (propsFolderId) {
      // 기안, 결재, 진행
      case 1001:
      case 1002:
      case 1003:
        toolbarButtons = [
          { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
          { code: 'save', label: '저장', type: 'icon', icon: 'save' },
        ];
        if (display === 'phone' && resource === 'teams') toolbarButtons = [];
        break;
      // 완료
      case 1004:
        toolbarButtons = [
          // eslint-disable-next-line prettier/prettier
          { code: 'PERSONAL_DOCUMENTS_COPY', label: '복사', type: 'icon', icon: 'copy' },
          { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
          { code: 'save', label: '저장', type: 'icon', icon: 'save' },
        ];
        if (display === 'phone' && resource === 'teams')
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'PERSONAL_DOCUMENTS_COPY', label: '복사', type: 'icon', icon: 'copy' },
          ];
        break;
      // 반려, 회수, 임시보관
      case 1005:
      case 1006:
      case 1007:
        toolbarButtons = [
          // eslint-disable-next-line prettier/prettier
          { code: 'listToTrash', label: '삭제', type: 'icon', icon: 'trash-full' },
        ];
        break;
      // 부서결재함, 회람문서
      case 3001:
      case 3002:
      case 3003:
      case 3004:
      case 4001:
      case 4002:
        toolbarButtons = [
          { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
          { code: 'save', label: '저장', type: 'icon', icon: 'save' },
        ];
        if (display === 'phone' && resource === 'teams') toolbarButtons = [];
        break;
      default:
        if (
          userArchivesList.find((x) => propsFolderId === x.id) !== undefined
        ) {
          /** 기록물철 헤더 */
          toolbarButtons = [
            { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
            { code: 'save', label: '저장', type: 'icon', icon: 'save' },
          ];
          if (display === 'phone' && resource === 'teams') toolbarButtons = [];
          if (manager === true) {
            toolbarButtons = [
              // eslint-disable-next-line prettier/prettier
              { code: 'ARCHIVE_DOCUMENTS_MOVE', label: '이동', type: 'icon', icon: 'folder-move' },
              ...toolbarButtons,
            ];
          }
          break;
        } else {
          // 개인 폴더 목록 헤드 툴바 버튼.
          toolbarButtons = [
            // eslint-disable-next-line prettier/prettier
            { code: 'PERSONAL_DOCUMENTS_DELETE', label: '삭제', type: 'icon', icon: 'trash-full' },
            // eslint-disable-next-line prettier/prettier
            { code: 'PERSONAL_DOCUMENTS_MOVE', label: '이동', type: 'icon', icon: 'folder-move' },
            // eslint-disable-next-line prettier/prettier
            { code: 'PERSONAL_DOCUMENTS_COPY', label: '복사', type: 'icon', icon: 'copy' },
          ];
          if (display !== 'phone' || resource !== 'teams')
            toolbarButtons = [
              ...toolbarButtons,
              { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
              { code: 'save', label: '저장', type: 'icon', icon: 'save' },
            ];
          break;
        }
    }

    return (
      <ApprovalContentHeadList
        search={search}
        title={title}
        pageNo={queryParams.pageNo || 1}
        rowsPerPage={queryParams.rowsPerPage || 15}
        totalCount={list.totalCount}
        checkedCount={list.items.filter((x) => x.checked).length}
        onCheckedChange={handleListItemCheckedChange}
        // sortOptions={sortOptions}
        // sort={sortList}
        // onChangeSort={handleSortList}
        // filterOptions={filterOptions}
        // filter={filterList}
        // onChangeFilter={handleFilter}
        onSearchAdvanced={handleSearchAdvanced}
        toolbarButtons={toolbarButtons}
        searchCode={queryParams.searchCode}
        searchWord={queryParams.searchWord}
        onSearch={handleSearch}
        onAction={handleHeadAction}
      />
    );
  };
  /** 바디 렌터링 */
  const renderBody = () => {
    let result = null;

    if (propsFolderId) {
      switch (readingPaneMode) {
        // 좌우분할.
        case 'vertical':
          if (!list) return null;
          result = (
            <EuiBody>
              <SplitPane
                className="ui-split eui-post"
                split="vertical"
                minSize={344}
                maxSize={560}
                defaultSize={parseInt(
                  localStorage.getItem('approval-split-vertical-sizes') ||
                    '400',
                  10,
                )}
                onChange={(size) =>
                  localStorage.setItem(
                    'approval-split-vertical-sizes',
                    `${size}`,
                  )
                }
              >
                <ApprovalContentBodyList
                  pathname={pathname}
                  search={search}
                  type="split"
                  density={displayDensity}
                  columns={columns}
                  items={items}
                  selectedId={propsItemId}
                  onItemClick={handleListItemClick}
                  onItemCheckedChange={handleItemCheckedChange}
                  onCountClick={handleClickOpinionCount}
                />
                <>
                  {renderHead({ type: 'view' })}
                  <ApprovalContentBodyViewContainer
                    pathname={pathname}
                    type="split"
                    view={view}
                    onAttachedDocumentPopup={handleAttachedDocumentDialog}
                    onAttachedFilePopup={handleAttachedFileDialog}
                    onSharedFilePopup={handleSharedFileDialog}
                  />
                </>
              </SplitPane>
            </EuiBody>
          );
          break;

        // 상하 분할.
        case 'horizontal':
          if (!list) return null;
          result = (
            <EuiBody>
              <SplitPane
                className="ui-split eui-post"
                split="horizontal"
                minSize={150}
                defaultSize={parseInt(
                  localStorage.getItem('approval-split-horizontal-sizes') ||
                    '250',
                  10,
                )}
                onChange={(size) =>
                  localStorage.setItem(
                    'approval-split-horizontal-sizes',
                    `${size}`,
                  )
                }
              >
                <ApprovalContentBodyList
                  pathname={pathname}
                  search={search}
                  type="full"
                  density={displayDensity}
                  columns={columns.filter(({ visible }) => visible)}
                  items={items}
                  selectedId={propsItemId}
                  onItemClick={handleListItemClick}
                  onItemCheckedChange={handleItemCheckedChange}
                  onCountClick={handleClickOpinionCount}
                />
                <>
                  {renderHead({ type: 'view' })}
                  <ApprovalContentBodyViewContainer
                    pathname={pathname}
                    type="split"
                    view={view}
                    onAttachedDocumentPopup={handleAttachedDocumentDialog}
                    onAttachedFilePopup={handleAttachedFileDialog}
                    onSharedFilePopup={handleSharedFileDialog}
                  />
                </>
              </SplitPane>
            </EuiBody>
          );
          break;
        // 분할 안함.
        default:
          // 뷰인 경우.
          if (propsItemId) {
            result = (
              <EuiBody>
                <ApprovalContentBodyViewContainer
                  pathname={pathname}
                  type="full"
                  view={view}
                  onAttachedDocumentPopup={handleAttachedDocumentDialog}
                  onAttachedFilePopup={handleAttachedFileDialog}
                  onSharedFilePopup={handleSharedFileDialog}
                />
              </EuiBody>
            );
          }
          // 리스트인 경우.
          else {
            result = (
              <EuiBody>
                <ApprovalContentBodyList
                  pathname={pathname}
                  search={search}
                  type="full"
                  density={displayDensity}
                  columns={columns.filter(({ visible }) => visible)}
                  items={items}
                  onItemClick={handleListItemClick}
                  onItemCheckedChange={handleItemCheckedChange}
                  onCountClick={handleClickOpinionCount}
                />
              </EuiBody>
            );
          }
          break;
      }
    }

    return result;
  };
  /** 드로워 렌터링 */
  const renderDrawer = () => {
    const { drawerType } = queryParams;

    // 문서정보
    if (drawerType === 'info') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalDocumentInfoDrawer view={view} onClose={handleCloseDrawer} />
      );
    }
    // 결재현황
    if (drawerType === 'status') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalStatusDrawer
          language="ko-KR"
          view={view}
          approvalLine={view.approvalLine}
          complete={view.completeAt !== undefined}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 조회
    if (drawerType === 'viewer') {
      if (view === null || view === undefined) return null;
      return (
        <ApprovalDocumentViewerDrawer
          viewId={view.id}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 목록설정
    if (drawerType === 'listSetting')
      return (
        <ListSetting
          type={readingPaneMode}
          propsFolderId={propsFolderId}
          columns={columns.filter(({ name }) => name !== 'checked')}
          onColumnVisibleChange={handleColumnVisibleChange}
          readingPaneMode={readingPaneMode}
          onReadingPaneModeChange={handleReadingPaneModeChange}
          density={displayDensity}
          onChangeDensity={handleChangeDensity}
          listCount={`${currentRowsPerPage}`}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
        />
      );
    // 변경내역 확인
    if (drawerType === 'history') {
      if (historys === undefined) return null;
      return (
        <ApprovalChangeHistoryDrawer
          historys={historys}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 의견
    if (drawerType === 'opinions' || drawerType === 'comments') {
      const { companyId = principal.companyId, id } = queryParams;
      return (
        <ApprovalCommentDrawerContainer
          companyId={companyId}
          type={queryParams.drawerType}
          id={id}
          onClose={() => {
            delete queryParams.drawerType;
            delete queryParams.companyId;
            delete queryParams.id;
            dispatch(sessionActions.search(getQueryParams(queryParams)));
          }}
        />
      );
    }

    return null;
  };
  /** 대화 상자 렌터링 */
  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 회수 대화상자 출력
    if (dialogType === 'retrieve') {
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">회수 하시겠습니까?</div>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleCloseDialog} />
            <Button text="회수" variant="contained" onClick={handleRetrieve} />
          </DialogFooter>
        </Dialog>
      );
    }
    // 삭제 대화상자 출력
    if (dialogType === 'viewToTrash' || dialogType === 'listToTrash') {
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">삭제 하시겠습니까?</div>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleCloseDialog} />
            <Button
              text="삭제"
              variant="contained"
              onClick={handleMoveToTrash}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    if (dialogType === 'approval') {
      if (view === null || view === undefined) return null;

      const currentApprover = getCurrentApprover({
        approvalLine: view.approvalLine,
        companyId: principal.companyId,
        organizationIds,
        employeeId: principal.employeeId,
        designatorIds: designators.map(({ designatorId }) => designatorId),
        approvalFolder: propsFolderId === 1002,
      });

      // 대화 상자 경로 설정 부분에서 검증 확인 처리함.
      if (currentApprover === undefined) return null;

      if (currentApprover.employeeId === undefined) {
        return (
          <ApprovalDialogContainer
            approverType="organization"
            approvalLineGroupItemId={currentApprover.id}
            approverCompanyId={currentApprover.companyId}
            approverOrganizationId={currentApprover.organizationId}
            onConfirm={handleApprovalConfirm}
            onClose={handleCloseDialog}
          />
        );
      }
      if (currentApprover.employeeId === principal.employeeId) {
        return (
          <ApprovalDialogContainer
            approverType="employee"
            approvalLineGroupItemId={currentApprover.id}
            approverCompanyId={principal.companyId}
            approverId={principal.employeeId}
            useArbitraryDecision={currentApprover.arbitraryDecision ?? false}
            hasHolding={currentApprover.act === 'hold'}
            approvalLine={view.approvalLine}
            onConfirm={handleApprovalConfirm}
            onClose={handleCloseDialog}
          />
        );
      }
      if (currentApprover.employeeId !== principal.employeeId) {
        return (
          <ApprovalDialogContainer
            approverType="surrogater"
            approvalLineGroupItemId={currentApprover.id}
            approverCompanyId={principal.companyId}
            approverId={principal.employeeId}
            designatorId={currentApprover.employeeId}
            onConfirm={handleApprovalConfirm}
            onClose={handleCloseDialog}
          />
        );
      }
      // 오류.
      return null;
    }
    if (dialogType === 'receiptReturn') {
      if (view?.parentApprovalLine === undefined) return null;
      // 부모문서 접수자 - 현재 기안자.
      const currentApprover = getApprovalLineDrafter(view.approvalLine);
      if (currentApprover === undefined) return null;
      // 조직 결재.
      if (currentApprover.employeeId === undefined) {
        return (
          <ApprovalDialogContainer
            receiptReturn
            approverType="organization"
            approvalLineGroupItemId={currentApprover.id}
            approverCompanyId={currentApprover.companyId}
            approverOrganizationId={currentApprover.organizationId}
            onConfirm={handleApprovalConfirm}
            onClose={handleCloseDialog}
          />
        );
      }
      if (currentApprover.employeeId === principal.employeeId) {
        return (
          <ApprovalDialogContainer
            receiptReturn
            approverType="employee"
            approvalLineGroupItemId={currentApprover.id}
            approverCompanyId={currentApprover.companyId}
            approverId={currentApprover.employeeId}
            useArbitraryDecision={false}
            hasHolding={false}
            approvalLine={view.approvalLine}
            onConfirm={handleApprovalConfirm}
            onClose={handleCloseDialog}
          />
        );
      }
      // 대결.
      if (currentApprover.employeeId !== principal.employeeId) {
        return (
          <ApprovalDialogContainer
            receiptReturn
            approverType="surrogater"
            approvalLineGroupItemId={currentApprover.id}
            approverCompanyId={principal.companyId}
            approverId={principal.employeeId}
            designatorId={currentApprover.employeeId}
            onConfirm={handleApprovalConfirm}
            onClose={handleCloseDialog}
          />
        );
      }
      // 오류.
      return null;
    }
    if (dialogType === 'approvalCancel') {
      if (view === null || view === undefined) return null;

      return (
        <ApprovalCancelDialog
          onConfirm={handleApprovalCancelDialogConfirm}
          onClose={handleCloseDialog}
        />
      );
    }
    // 결재선, 참조권, 조회권 변경 대화상자 출력.
    if (
      dialogType === 'approvalLine' ||
      dialogType === 'referencePermission' ||
      dialogType === 'viewPermission'
    ) {
      if (view === null || view === undefined) return null;
      const {
        affiliatedCompanyId,
        id,
        approvalLine,
        referencePermission,
        viewPermission,
        updateAt,
      } = view;
      return (
        <ApprovalLineDialogContainer
          type="change"
          affiliatedCompanyId={affiliatedCompanyId}
          companyId={principal.companyId}
          id={id}
          approvalLine={
            dialogType === 'approvalLine' ? approvalLine : undefined
          }
          referencePermission={
            dialogType === 'referencePermission'
              ? referencePermission
              : undefined
          }
          viewPermission={
            dialogType === 'viewPermission' ? viewPermission : undefined
          }
          updateAt={updateAt}
          onSave={handleApprovalLineChangeSave}
          onCancel={handleCloseDialog}
        />
      );
    }

    // 복사 대화상자 출력
    if (
      dialogType === 'PERSONAL_DOCUMENT_COPY' ||
      dialogType === 'PERSONAL_DOCUMENTS_COPY'
    ) {
      const style: React.CSSProperties = {
        padding: '0 24px 12px 24px',
        justifyContent: 'center',
      };
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>개인함 복사</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <Tree
              type="full"
              disabledId={propsFolderId}
              selectedId={state.selectedId}
              items={userFolderTreeItems}
              onItemClick={handleSelectedId}
            />
          </DialogBody>
          <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
            <TextField
              value={state.personalFolderName}
              onChange={handlePersonalFolderNameChange}
              placeholder="개인함명을 입력하세요"
            />
            <Button
              noDuplication={state.saveing}
              text="생성"
              variant="contained"
              onClick={handlePersonalFolderSave}
              style={{ marginLeft: '5px' }}
            />
          </div>
          <hr className="eui-divider" />
          <DialogFooter style={style}>
            <Button
              text="복사"
              variant="contained"
              onClick={handleCopyPersonalDocument}
            />
            <Button text="취소" onClick={handleCloseDialog} />
          </DialogFooter>
        </Dialog>
      );
    }

    // 기록물철 이동
    if (
      dialogType === 'ARCHIVE_DOCUMENT_MOVE' ||
      dialogType === 'ARCHIVE_DOCUMENTS_MOVE'
    ) {
      return (
        <TreePicker
          title="기록물철 이동"
          disabledId={propsFolderId}
          list={userArchivesTreeItems}
          onSelected={async (id) => {
            if (propsFolderId === id) {
              setState((prevState) => ({
                ...prevState,
                alertMessage: '원본 폴더와 대상 폴더가 동일합니다.',
              }));
              return;
            }
            if (queryParams.pageNo) delete queryParams.pageNo;

            const route = utils.getRoute({
              target: props,
              source: {
                pathname: `${getPathMap('/*/*', pathname)}`,
                search: getQueryParams(queryParams),
                option: 'CLEAR_DIALOG',
              },
            });
            const location = utils.getLocation({
              target: props,
              source: {
                pathname: `${getPathMap('/*/*', pathname)}`,
                search: getQueryParams(queryParams),
                mode: 'replace',
                option: 'CLEAR_DIALOG',
              },
            });

            let arg: {
              organizationId: number;
              sourceFolderId: number;
              targetFolderId: number;
              id: number;
              updateAt: string;
            }[] = [
              {
                organizationId: 0,
                sourceFolderId: 0,
                targetFolderId: 0,
                id: 0,
                updateAt: '',
              },
            ];

            if (propsFolderId === undefined) return;

            if (dialogType === 'ARCHIVE_DOCUMENT_MOVE' && view) {
              arg = [
                {
                  organizationId: archiveOrganizationId,
                  sourceFolderId: propsFolderId,
                  targetFolderId: id,
                  id: view.id,
                  updateAt: view.updateAt,
                },
              ];
            } else if (dialogType === 'ARCHIVE_DOCUMENTS_MOVE') {
              arg = list.items
                .filter((x) => x.checked)
                .map((d) => ({
                  organizationId: archiveOrganizationId,
                  sourceFolderId: propsFolderId,
                  targetFolderId: id,
                  id: d.id,
                  updateAt: d.updateAt,
                }));
            }
            if (queryParams.pageNo)
              dispatch(archiveActions.move({ data: arg, location }));
            else if (
              isSplitView ||
              (!isSplitView && dialogType === 'ARCHIVE_DOCUMENTS_MOVE')
            )
              dispatch(archiveActions.move({ data: arg, route }));
            else dispatch(archiveActions.move({ data: arg, location }));
          }}
          onClose={handleCloseDialog}
        />
      );
    }

    // 이동 대화상자 출력
    if (
      dialogType === 'PERSONAL_DOCUMENT_MOVE' ||
      dialogType === 'PERSONAL_DOCUMENTS_MOVE'
    ) {
      const style: React.CSSProperties = {
        padding: '0 24px 12px 24px',
        justifyContent: 'center',
      };
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>개인함 이동</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <Tree
              type="full"
              disabledId={propsFolderId}
              selectedId={state.selectedId}
              items={userFolderTreeItems}
              onItemClick={handleSelectedId}
            />
          </DialogBody>
          <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
            <TextField
              value={state.personalFolderName}
              onChange={handlePersonalFolderNameChange}
              placeholder="개인함명을 입력하세요"
            />
            <Button
              noDuplication={state.saveing}
              text="생성"
              variant="contained"
              onClick={handlePersonalFolderSave}
              style={{ marginLeft: '5px' }}
            />
          </div>
          <hr className="eui-divider" />
          <DialogFooter style={style}>
            <Button
              text="이동"
              variant="contained"
              onClick={handleMovePersonalDocument}
            />
            <Button text="취소" onClick={handleCloseDialog} />
          </DialogFooter>
        </Dialog>
      );
    }
    // 인쇄
    if (dialogType === 'print' || dialogType === 'prints') {
      if (propsFolderId === undefined) {
        return (
          <Confirmation onSubmit={handleCloseDialog}>
            폴더를 찾을 수 없습니다.
          </Confirmation>
        );
      }
      let listId:
        | {
            id: number;
            updateAt: string;
          }[]
        | undefined;
      if (dialogType === 'print' && propsItemId && view) {
        listId = [
          {
            id: view.id,
            updateAt: view.updateAt,
          },
        ];
      } else if (dialogType === 'prints') {
        listId = list?.items
          .filter((x) => x.checked)
          .map((a) => {
            return {
              id: a.id,
              updateAt: a.updateAt,
            };
          });
      }
      if (listId === undefined) return null;

      return (
        <ApprovalPrint
          onClose={handleCloseDialog}
          listId={listId}
          folderId={propsFolderId}
        />
      );
    }
    // 첨부파일 보기
    if (dialogType === 'attachedfile') {
      if (state.attachFileView === undefined) return null;
      const { attachFileView } = state;
      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: attachFileView.documentId,
            id: attachFileView.id,
            name: attachFileView.name,
            size: attachFileView.size,
            url: attachFileView.url ?? '',
            isFileprotection: attachFileView.isFileprotection,
          }}
        />
      );
    }

    // 공유파일 보기
    if (dialogType === 'sharedfile') {
      if (state.sharedFileView === undefined) return null;

      const { sharedFileView } = state;
      return (
        <ApprovalAttachedFileDialog
          onCloseDialog={handleCloseDialog}
          file={{
            documentId: sharedFileView.documentId,
            id: sharedFileView.id,
            name: sharedFileView.name,
            size: sharedFileView.size,
            url: sharedFileView.viewerUrl ?? '',
          }}
        />
      );
    }

    const { dialogs } = state;
    const dialog = dialogs.length > 0 ? dialogs[dialogs.length - 1] : undefined;
    if (dialog) {
      return (
        <ApprovalAttachedDocumentDialog
          search={search}
          dialog={dialog}
          dialogs={dialogs}
          onAttachedDocumentPopup={handleAttachedDocumentDialog}
          onAttachedFilePopup={handleAttachedFileDialog}
          onCloseDialog={handleCloseAttachedDialog}
        />
      );
    }
    const { type } = queryParams;

    if (type === 'security')
      return (
        <Security
          title="보안결재"
          onConfirm={handleConfirmSecurity}
          onClose={handleCloseDialog}
        />
      );

    return null;
  };

  if (getFolderType() === undefined) return <AccessDenied />;

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDrawer()}
      {renderDialog()}
      {state.publicPopupRect && (
        <Menu
          point={state.publicPopupRect}
          onClose={handlePublicPopup}
          size="sm"
        >
          <div className="context-form">
            <div className="form-item">
              <RadioGroup
                data={publicOption}
                value={state.publicMode}
                name="public"
                onChange={handleChangePublicMode}
              />
            </div>
            <div className="form-action">
              <Button
                text="변경"
                variant="contained"
                size="sm"
                onClick={handlePublicSave}
              />
              <Button text="취소" size="sm" onClick={handlePublicPopup} />
            </div>
          </div>
        </Menu>
      )}
      <FeedBack text={state.alertMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalContentContainer;
