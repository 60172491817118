import React from 'react';
import Button from '../button/Button';
import Chip from '../chip/Chip';

/**
 * @property value 선택값
 * @property label Placeholder
 * @property pressed 활성화 상태
 * @property onClick 클릭 이벤트
 */

function DropMenu(props: {
  style?: React.CSSProperties;
  useChip?: boolean;
  value: string | string[];
  label: string;
  pressed?: boolean;
  block?: boolean;
  error?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  onCancel?(): void;
}): JSX.Element {
  const { useChip, value } = props;
  let classname = 'eui-drop-menu';
  if (props.block) classname += ' block';
  if (props.error) classname += ' error';

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (props.onCancel) props.onCancel();
  };

  if (useChip && typeof value === 'string')
    return (
      <>
        <button
          style={props.style}
          type="button"
          className={classname}
          onClick={props.onClick}
          aria-pressed={props.pressed}
        >
          {value.length === 0 ? (
            <span className="placeholder">{props.label}</span>
          ) : (
            <>
              <span className="label">
                <Chip
                  style={{ height: '24px', width: '24px', padding: '0' }}
                  label=""
                  color={value}
                />
              </span>
              {props.onCancel && (
                <Button
                  text="비우기"
                  iconType
                  icon="times-circle-fill-small"
                  onClick={handleCancel}
                  size="xs"
                  className="cancel"
                />
              )}
            </>
          )}
        </button>
      </>
    );
  return (
    <>
      <button
        style={props.style}
        type="button"
        className={classname}
        onClick={props.onClick}
        aria-pressed={props.pressed}
      >
        {value.length === 0 ? (
          <span className="placeholder">{props.label}</span>
        ) : (
          <>
            <span className="label">
              {Array.isArray(value)
                ? value.map((v, i, a) => {
                    if (i !== a.length - 1)
                      return (
                        <span key={v} className="cell">
                          <span className="text">{v}</span>
                          <span className="arrow" aria-label="/" />
                        </span>
                      );
                    return (
                      <span key={v} className="cell">
                        <span className="text">{v}</span>
                      </span>
                    );
                  })
                : value}
            </span>
            {props.onCancel && (
              <Button
                text="비우기"
                iconType
                icon="times-circle-fill-small"
                onClick={handleCancel}
                size="xs"
                className="cancel"
              />
            )}
          </>
        )}
      </button>
    </>
  );
}

export default DropMenu;
