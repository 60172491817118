import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentButtonDropDown(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Dropdown Buttons">
      <Button text="Dropdown" dropdown />
      <Button text="Dropdown" variant="outlined" dropdown />
      <Button text="Dropdown" variant="contained" dropdown />
    </ComponentSampleUI.Content>
  );
}

export default ComponentButtonDropDown;
