import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

export interface BoardPreferencesBasic {
  companyId: number;
  defaultFolderId: number; // 기본 게시함 아이디.
  newPostPeriod: number; // 새 글 지속 기간. (day)
  allocableCapacity: number; // 할당 가능 용량. (byte)
  capacityInUse: number; // 사용 중인 용량. (byte)
  capacityLimit: number; // 용량 한도. (byte)
  numberOfAttachments: number; // 첨부 파일 개수.
  attachmentsCapacity: number; // 첨부 파일 용량. (byte)
  updateAt: string;
}

/**
 * 직원 역할을 조회합니다.(현재 세션의 직원)
 * @returns 직원 역할 배열.
 */
async function find(arg: { isTeams?: boolean }): Promise<
  {
    employeeId: number;
    role: 'BOARD_USER' | 'BOARD_ADMIN';
    updateAt: string;
  }[]
>;
/**
 * 역할에 해당되는 직원을 조회합니다.
 * @param role 역할.
 * @returns 직원 역할 배열.
 */
async function find(arg: {
  isTeams?: boolean;
  role: 'BOARD_USER' | 'BOARD_ADMIN';
}): Promise<
  {
    employeeId: number;
    role: 'BOARD_USER' | 'BOARD_ADMIN';
    updateAt: string;
  }[]
>;
async function find(arg: {
  isTeams?: boolean;
  role?: 'BOARD_USER' | 'BOARD_ADMIN';
}): Promise<
  {
    employeeId: number;
    role: 'BOARD_USER' | 'BOARD_ADMIN';
    updateAt: string;
  }[]
> {
  const { isTeams, role } = arg;
  // 조회 역할이 없는 경우 세션의 직원 역할 조회.
  if (role === undefined) {
    try {
      const { host, headers } = getApiConfig();
      const url = `${host}/api/board/v1/role/employee/all`;
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (e) {
      // 팀즈일 경우 오류나면 빈 배열 보내도록.
      if (isTeams) return [];
      throw apiError(e);
    }
  }

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/role/all`;
    const response = await axios.get(url, { headers, params: { role } });
    return response.data;
  } catch (e) {
    // 팀즈일 경우 오류나면 빈 배열 보내도록.
    if (arg.isTeams) return [];
    throw apiError(e);
  }
}

/**
 * 직원 역할을 추가합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function append(
  arg: { employeeId: number; role: 'BOARD_USER' | 'BOARD_ADMIN' }[],
): Promise<
  {
    employeeId: number;
    role: 'BOARD_USER' | 'BOARD_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/role/all`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 직원 역할을 삭제합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function remove(
  arg: {
    employeeId: number;
    role: 'BOARD_USER' | 'BOARD_ADMIN';
    updateAt: string;
  }[],
): Promise<
  {
    employeeId: number;
    role: 'BOARD_USER' | 'BOARD_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/role/all`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 기본설정 조회. */
async function findBasic(): Promise<BoardPreferencesBasic> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/preferences`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 기본설정 저장. */
async function updateBasic(arg: {
  defaultFolderId: number;
  newPostPeriod: number;
  capacityLimit: number;
  numberOfAttachments: number;
  attachmentsCapacity: number;
  updateAt: string;
}): Promise<BoardPreferencesBasic> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/preferences`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const preferencesApi = {
  find,
  append,
  remove,

  findBasic,
  updateBasic,
};

export default preferencesApi;
