import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  getText,
  hangul,
} from '../../../../../groupware-common/utils';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import ServiceMenuItemList from './ServiceMenuItemList';
import { serviceMenuActions } from '../../../../stores/module';
import ServiceMenuView, { UpdateMenuArg } from './ServiceMenuView';
import Chip from '../../../../../components/chip/Chip';
import { moduleIcon } from '../../../../../groupware-common/utils/ui';

function ServiceMenuContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const pathmap = getPathMap('/*/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: string }>(
    '/*/*/*/:selectedId',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const list = useSelector(
    (state: RootState) => state.service.menu.adminconsole.list,
  );
  const view = useSelector(
    (state: RootState) => state.service.menu.adminconsole.view,
  );
  const categories = useSelector(
    (state: RootState) => state.service.menu.category,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 7002)?.name ?? '';
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const items = useMemo(() => {
    const folders = list
      .map((x) => {
        const name = getText(x.textId, 'translation');
        return {
          id: x.id,
          parentId: '0',
          seq: x.seq,
          text: name,
          icon: moduleIcon(x.code),
          strings: hangul.d(name),
          children: !x.status && (
            <Chip label="사용안함" size="xs" theme="cancel" />
          ),
        };
      })
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
    return folders;
  }, [list]);

  /** 메뉴 보기 */
  const handleLink = (module: string) => {
    dispatch(
      serviceMenuActions.findView({
        companyId,
        module,
        route: {
          pathname: `${pathmap}/${module}`,
          search,
        },
      }),
    );
  };

  // 모바일에서만 적용.
  const handleCloseView = () => {
    const route = { pathname: pathmap };
    dispatch(sessionActions.setRoute(route));
  };

  /** 순서 변경 드로워. */
  const handleSortChangeDrawer = () => {
    dispatch(sessionActions.setDrawer({ type: 'sortChange' }));
  };

  /** 서비스 메뉴 저장 */
  const handleUpdate = (data: UpdateMenuArg) => {
    const route = { pathname };
    dispatch(serviceMenuActions.update({ data, route }));
  };

  /** 순서 변경 저장. */
  const handleSortChange = (
    arg: {
      id: string;
      label?: string;
    }[],
  ) => {
    const sortList = arg.map((a, i) => {
      const updateAt = list.find((x) => x.id === a.id)?.updateAt ?? '';
      return {
        module: a.id,
        seq: i + 1,
        updateAt,
      };
    });
    const route = {
      pathname,
      search: createQueryString(
        { drawerMode: undefined, drawerType: undefined },
        queryParams,
      ),
    };

    dispatch(serviceMenuActions.sortMenu({ companyId, list: sortList, route }));
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  /** 메뉴 리스트 */
  const renderList = () => {
    let result = null;
    result = (
      <ServiceMenuItemList
        selectedId={selectedId}
        items={items}
        onSort={handleSortChangeDrawer}
        onItemClick={handleLink}
      />
    );
    return result;
  };

  /** 메뉴 보기 */
  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId ? '메뉴를 찾을 수 없습니다.' : undefined;
      return <SplitUnselected label={message} />;
    }

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <ServiceMenuView view={view} onUpdate={handleUpdate} />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    if (queryParams.drawerType === 'sortChange') {
      const changeList = items
        .map(({ id, text, seq }) => ({ id, label: text, seq }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

      return (
        <ChangeOrder
          title="순서 변경"
          list={changeList}
          onClose={handleCloseDrawer}
          onChange={handleSortChange}
        />
      );
    }

    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
    </>
  );
}

export default ServiceMenuContainer;
