import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import SelectField from '../../../../../components/selectField/SelectField';

function ComponentSelectLabel(): JSX.Element {
  const options: {
    value: string;
    label: string;
  }[] = [
    { value: '0', label: '안녕하세요' },
    { value: '1', label: 'Autocomplete' },
    { value: '2', label: 'Button' },
    { value: '3', label: 'Button Group' },
    { value: '4', label: 'Checkbox' },
    { value: '5', label: 'Floating Action Button' },
    { value: '6', label: 'Radio button' },
    { value: '7', label: 'Rating' },
    { value: '8', label: 'Select' },
    { value: '9', label: 'Slider' },
    { value: '10', label: 'SwitchText field' },
    { value: '11', label: 'Transfer List' },
    { value: '12', label: 'Toggle Button' },
    { value: '13', label: 'Avatar' },
    { value: '14', label: 'Badge' },
    { value: '15', label: 'Chip' },
    { value: '16', label: 'Divider' },
    { value: '17', label: 'Icons' },
    { value: '18', label: 'Material Icons' },
    { value: '19', label: 'List' },
    { value: '20', label: 'Table' },
    { value: '21', label: 'Tooltip' },
    { value: '22', label: 'Typography' },
  ];
  const [state, stateState] = useState('0');

  return (
    <ComponentSampleUI.Content title="Label">
      <SelectField
        label="아이디"
        data={options}
        value={state}
        onChange={stateState}
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentSelectLabel;
