import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldBasic(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <ComponentSampleUI.Content title="Limit">
      <TextField
        placeholder="최대 20자까지 입력 가능합니다"
        value={value}
        onChange={handleChange}
        count
        maxLength={20}
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldBasic;
