import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { informationActions } from '../../../../stores/information';

function SettingInformationRoute(props: {
  pathname: string;
  search: string;
}): null {
  // console.log(`${SettingInformationRoute.name}.render(props)`, props);
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      informationActions.personalInformation({ route: { pathname, search } }),
    );
  }, [dispatch, pathname, search]);
  return null;
}

export default SettingInformationRoute;
