import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 단순 주소록 샘플 파일 다운로드. */
async function simpleSample(): Promise<string> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/download/sample/simply`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data.text();
  } catch (e) {
    throw apiError(e);
  }
}

/** 상세 주소록 샘플 파일 다운로드. */
async function detailSample(): Promise<string> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/download/sample/detail`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data.text();
  } catch (e) {
    throw apiError(e);
  }
}

const contactFileApi = {
  simple: simpleSample,
  detail: detailSample,
};

export default contactFileApi;
