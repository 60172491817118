import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';

function ComponentCheckboxGroup(): JSX.Element {
  const [options, setOptions] = useState<
    { value: string; label: string; checked: boolean; disabled?: boolean }[]
  >([
    { checked: true, value: 'move', label: '이동' },
    { checked: true, value: 'copy', label: '복사' },
    { checked: false, value: 'file', label: '파일첨부' },
    { checked: false, value: 'opinion', label: '의견' },
    { checked: false, value: 'media', label: '동영상' },
    { checked: false, value: 'security', label: '보안게시' },
    { checked: false, value: 'mail', label: '메일발송', disabled: true },
  ]);

  const handleOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions((prevState) =>
      prevState.map((x) =>
        x.value === event.target.name
          ? { ...x, checked: event.target.checked }
          : x,
      ),
    );
  };

  return (
    <ComponentSampleUI.Content title="Group">
      <CheckboxGroup legend="기능">
        {options.map((x) => {
          return (
            <Checkbox
              key={x.value}
              name={x.value}
              label={x.label}
              checked={x.checked}
              onChange={handleOptionsChange}
              disabled={x.disabled}
            />
          );
        })}
      </CheckboxGroup>
    </ComponentSampleUI.Content>
  );
}

export default ComponentCheckboxGroup;
