import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import UploadImage from '../../../../../components/upload/UploadImage';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { preferencesActions } from '../../../../stores/approval/preferences';
import FeedBack from '../../../../../components/alert/FeedBack';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import {
  conversionToBytes,
  readableBytes,
  readableBytesMaxGB,
} from '../../../../../groupware-common/utils/ui';

function stringReplace(value: string) {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
}

function ApprovalSettingPreferencesContainer(): JSX.Element {
  // console.log(`ApprovalSettingPreferencesContainer()`);

  const dispatch = useAppDispatch();

  const byteOptions: {
    value: string;
    label: string;
  }[] = [
    { value: 'KB', label: 'KB' },
    { value: 'MB', label: 'MB' },
    { value: 'GB', label: 'GB' },
  ];
  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const basic = useSelector(
    (state: RootState) => state.approval2.preferences.basic,
  );
  const storeAdministrators = useSelector(
    (state: RootState) => state.approval2.preferences.administrators,
  );

  const initialState = () => {
    return {
      validation: '',
      change: false,
      saveing: false,

      companyLogoPath: basic.companyLogoPath,
      companyLogoData: undefined as string | undefined,
      usePreviousApprovalCancel: basic.usePreviousApprovalCancel, // 이전 승인 취소(전단계 반려) 사용 여부.
      useApprovalCancel: basic.useApprovalCancel, // 결재 취소 사용 여부.
      useApprovalDefer: basic.useApprovalDefer, // 후결 사용 여부.
      useMeet: basic.useMeet, // 대면 사용 여부.

      useExpecterDocumentView: basic.useExpecterDocumentView, // 결재 예정자 문서 보기 사용 여부.

      useSignatureImage: basic.useSignatureImage, // 서명 이미지 사용 여부.
      useDocumentOrganizationPublic: basic.useDocumentOrganizationPublic, // 문서 조직 공개 사용 여부.

      numberOfAttachments: basic.numberOfAttachments.toString(),
      attachmentsCapacity: readableBytesMaxGB(
        basic.attachmentsCapacity,
        1,
      ).capacity.toString(),
      attachmentsCapacitySpec: readableBytesMaxGB(basic.attachmentsCapacity, 1)
        .byte,
      allocableCapacity: readableBytes(basic.allocableCapacity, 1),
      capacityLimit: readableBytesMaxGB(
        basic.capacityLimit,
        1,
      ).capacity.toString(),
      capacityLimitSpec: readableBytesMaxGB(basic.capacityLimit, 1).byte,
      capacityInUse: readableBytes(basic.capacityInUse, 1),

      administrators: employees
        .filter(
          (a) =>
            a.companyId === principal.companyId &&
            storeAdministrators.some((b) => a.id === b.employeeId),
        )
        .map((a) => {
          const {
            companyId,
            id: employeeId,
            representativeOrganizationId: organizationId,
            avatar,
          } = a;
          const updateAt =
            storeAdministrators.find((b) => b.employeeId === employeeId)
              ?.updateAt ?? '1000-01-01';
          return {
            companyId,
            employeeId,
            organizationId,
            avatar,
            updateAt,
          };
        }),
      administratorsMenuPoint: undefined as
        | { x: number; y: number; width: number; height: number }
        | undefined,
    };
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const {
        companyLogoData,
        usePreviousApprovalCancel,
        useApprovalCancel,
        useApprovalDefer,
        useMeet,
        useExpecterDocumentView,
        useSignatureImage,
        useDocumentOrganizationPublic,
        numberOfAttachments,
      } = state;
      const capacityLimit = conversionToBytes(
        Number(state.capacityLimit),
        state.capacityLimitSpec,
      );
      const attachmentsCapacity = conversionToBytes(
        Number(state.attachmentsCapacity),
        state.attachmentsCapacitySpec,
      );
      const save = await dispatch(
        preferencesActions.modifyBasic({
          companyLogoData,
          usePreviousApprovalCancel,
          useApprovalCancel,
          useApprovalDefer,
          useMeet,
          useExpecterDocumentView,
          useSignatureImage,
          useDocumentOrganizationPublic,
          allocableCapacity: basic.allocableCapacity,
          capacityInUse: basic.capacityInUse,
          capacityLimit,
          numberOfAttachments: parseInt(numberOfAttachments, 10),
          attachmentsCapacity,
          updateAt: basic.updateAt,
        }),
      );
      // console.log(`save:`, save);
      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }

      const removeAdministrators = storeAdministrators
        .filter(
          (a) =>
            administrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId, updateAt }) => ({ employeeId, updateAt }));
      const remove = await dispatch(
        preferencesActions.removeAdministrators(removeAdministrators),
      );
      // console.log(`remove:`, remove);
      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const appendAdministrators = administrators
        .filter(
          (a) =>
            storeAdministrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId }) => ({ employeeId }));
      const append = await dispatch(
        preferencesActions.appendAdministrators(appendAdministrators),
      );
      // console.log(`append:`, append);
      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      if (!mount) return;
      const { companyLogoPath: logoData } = save.payload as {
        companyLogoPath: string;
      };
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        companyLogoData:
          logoData !== '' ? prevState.companyLogoData : undefined,
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  if (basic === undefined) return <div>404</div>;

  const handleChangeLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64)
        setState((prevState) => ({
          ...prevState,
          change: true,
          companyLogoPath: 'companylogo',
          companyLogoData: base64.toString(),
        }));
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      reader.readAsDataURL(event.target.files[0]);
      const eventTargetValue = { ...event };
      eventTargetValue.target.value = '';
    }
  };

  const handleRemoveLogo = () => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      companyLogoPath: '',
      companyLogoData: basic.companyLogoPath ? '' : undefined,
    }));
  };

  const handleAdministratorsDelete = (employeeId: number) => {
    // console.log(`handleAdministratorsDelete(employeeId: ${employeeId})`);
    setState((prevState) => ({
      ...prevState,
      change: true,
      administrators: prevState.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
    }));
  };

  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    // console.log(`handleAdministratorsAppend(arg)`, arg);
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prevState) => {
      const { administrators } = prevState;
      const administratorsMenuPoint = undefined;
      if (administrators.some((a) => a.employeeId === employeeId))
        return { ...prevState, administratorsMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined)
        return { ...prevState, administratorsMenuPoint };

      const updateAt =
        storeAdministrators.find((a) => a.employeeId === employeeId)
          ?.updateAt ?? '';

      return {
        ...prevState,
        change: true,
        administrators: [
          ...administrators,
          {
            companyId,
            employeeId,
            organizationId: employee.representativeOrganizationId,
            avatar: employee.avatar,
            updateAt,
          },
        ],
      };
    });
  };

  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    // console.log(`handleAdministratorsAppend(event)`, event);
    const { administratorsMenuPoint } = state;
    if (event !== undefined && administratorsMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/[^0-9.]/g) !== null) return;
    const capacityLimit = stringReplace(event.target.value);

    setState((prevState) => ({ ...prevState, change: true, capacityLimit }));
  };

  const handleFileCountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.match(/[^0-9]/g) !== null) return;
    const numberOfAttachments = stringReplace(event.target.value);
    if (parseInt(numberOfAttachments, 10) > 100) {
      setState((prev) => ({
        ...prev,
        validation: '첨부 개수는 최대 100개까지 입니다.',
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      change: true,
      numberOfAttachments,
    }));
  };

  const handleFileCapacityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.match(/[^0-9.]/g) !== null) return;
    const attachmentsCapacity = stringReplace(event.target.value);
    setState((prevState) => ({
      ...prevState,
      change: true,
      attachmentsCapacity,
    }));
  };

  const handleCapacitySpecChange = (value: 'KB' | 'MB' | 'GB') => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      capacityLimitSpec: value,
    }));
  };
  const handleFileCapacitySpecChange = (value: 'KB' | 'MB' | 'GB') => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      attachmentsCapacitySpec: value,
    }));
  };

  const handleSave = () => {
    const { administrators } = state;
    if (administrators.length === 0) {
      const validation = '관리자를 등록하세요.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const useLabel: { value: boolean; label: string }[] = [
    { value: true, label: '사용' },
    { value: false, label: '사용 안함' },
  ];

  const {
    validation,
    change,
    saveing,
    companyLogoPath,
    companyLogoData,
    usePreviousApprovalCancel,
    useApprovalCancel,
    // useApprovalDefer,
    useMeet,
    useExpecterDocumentView,
    useSignatureImage,
    useDocumentOrganizationPublic,
    administrators,
    administratorsMenuPoint,
    allocableCapacity,
    capacityInUse,
    capacityLimit,
    capacityLimitSpec,
    attachmentsCapacity,
    attachmentsCapacitySpec,
    numberOfAttachments,
  } = state;

  const src = companyLogoData ?? companyLogoPath;

  return (
    <>
      <EuiSetting.Item title="회사로고">
        <UploadImage
          name="회사 로고 이미지"
          style={{ height: '60px' }}
          path={src}
          type="information"
          onChange={handleChangeLogo}
          onDelete={handleRemoveLogo}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title="결재 옵션">
        <CheckboxGroup>
          <Checkbox
            label="전단계 반려"
            checked={usePreviousApprovalCancel}
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                change: true,
                usePreviousApprovalCancel: !prevState.usePreviousApprovalCancel,
              }))
            }
          />
          <Checkbox
            label="결재취소"
            checked={useApprovalCancel}
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                change: true,
                useApprovalCancel: !prevState.useApprovalCancel,
              }))
            }
          />
          {/* <Checkbox
            label="후결"
            checked={useApprovalDefer}
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                change: true,
                useApprovalDefer: !prevState.useApprovalDefer,
              }))
            }
          /> */}
          <Checkbox
            label="대면"
            checked={useMeet}
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                change: true,
                useMeet: !prevState.useMeet,
              }))
            }
          />
        </CheckboxGroup>
      </EuiSetting.Item>
      {/*
      // TODO 구현 안됨.
      <EuiSetting.Item title="결재패스">
        <RadioGroup
          data={useLabel}
          value={state.approvalPass.use}
          name="approvalPass"
          onChange={handleChangeApprovalPassUse}
        />
        {state.approvalPass.use && (
          <EuiSetting.ItemGroup>
            <TextField
              value={state.approvalPass.time}
              onChange={handleChangeApprovalPassTime}
              width={70}
              maxLength={4}
              size="sm"
              pattern="[0-9]*"
            />
            <span>시간 경과 시 결재 패스 처리함</span>
          </EuiSetting.ItemGroup>
        )}
        <HelperText
          text={
            <ul>
              <li>
                중간결재자 일정시간 결재 지연 시 자동으로 후결 처리합니다.
              </li>
              <li>수신자, 합의자, 최종결재자는 결재패스 처리할 수 없습니다.</li>
            </ul>
          }
        />
      </EuiSetting.Item> */}
      <EuiSetting.Item title="문서정보">
        <CheckboxGroup column>
          <Checkbox
            label="상신시점에 모든 결재선상의 사용자에게 결재문서 보여줌"
            checked={useExpecterDocumentView}
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                change: true,
                useExpecterDocumentView: !prevState.useExpecterDocumentView,
              }))
            }
          />
        </CheckboxGroup>
      </EuiSetting.Item>
      {/* 
      // TODO 구현 안됨.
      <EuiSetting.Item title="수발신 결재의견">
        <CheckboxGroup column>
          <Checkbox
            label="결재내용 하단에 결재의견 출력"
            checked={state.opinionVisible}
            onChange={handleChangeOpinionVisible}
          />
          <Checkbox
            label="수신부서의 결재의견 기안부서에 공개"
            checked={state.opinionVisibleOrganization}
            onChange={handleChangeOpinionVisibleOrganization}
          />
        </CheckboxGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title="결재댓글">
        <RadioGroup
          data={useLabel}
          value={state.comment.use}
          name="comment"
          onChange={handleChangeComment}
        />
        {state.comment.use && (
          <CheckboxGroup>
            <Checkbox
              label="수정/삭제 사용여부"
              checked={state.comment.edit}
              onChange={handleChangeCommentEdit}
            />
          </CheckboxGroup>
        )}
      </EuiSetting.Item>
      <EuiSetting.Item title="접수 후 내부결재">
        <CheckboxGroup>
          <Checkbox
            label="반려시 접수자의 반려함으로 반려"
            checked={state.receptionBack}
            onChange={handleChangeReceptionBack}
          />
        </CheckboxGroup>
      </EuiSetting.Item> */}
      <EuiSetting.Item title="결재란에 서명이미지">
        <RadioGroup
          data={useLabel}
          value={useSignatureImage}
          name="useSignatureImage"
          onChange={() =>
            setState((prevState) => ({
              ...prevState,
              change: true,
              useSignatureImage: !prevState.useSignatureImage,
            }))
          }
        />
      </EuiSetting.Item>
      {/*
      // TODO 구현 안됨.
      <EuiSetting.Item title="하위부서 기록물철 조회">
        <RadioGroup
          data={useLabel}
          value={state.lowerRankDepartmentRecord}
          name="lowerRankDepartmentRecord"
          onChange={handleChangeLowerRankDepartmentRecord}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title="사후결재">
        <RadioGroup
          data={useLabel}
          value={state.postApproval}
          name="postApproval"
          onChange={handleChangePostApproval}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title="감사자">
        <RadioGroup
          data={useLabel}
          value={state.auditor}
          name="auditor"
          onChange={handleChangeAuditor}
        />
      </EuiSetting.Item>
      <EuiSetting.Item title="메일 보내기 노출여부">
        <CheckboxGroup>
          <Checkbox
            label="결재진행중 노출"
            checked={state.sendMail.inProgress}
            onChange={(event) => handleChangeSendMail(event, 'inProgress')}
          />
          <Checkbox
            label="결재완료 노출"
            checked={state.sendMail.complete}
            onChange={(event) => handleChangeSendMail(event, 'complete')}
          />
        </CheckboxGroup>
      </EuiSetting.Item> */}
      <EuiSetting.Item title="문서 조직 공개 여부">
        <RadioGroup
          data={[
            { value: true, label: '공개' },
            { value: false, label: '비공개' },
          ]}
          value={useDocumentOrganizationPublic}
          name="public"
          onChange={() =>
            setState((prevState) => ({
              ...prevState,
              change: true,
              useDocumentOrganizationPublic:
                !prevState.useDocumentOrganizationPublic,
            }))
          }
        />
      </EuiSetting.Item>
      <EuiSetting.Item title="저장 용량">
        <EuiSetting.ItemGroup>
          <span className="ui-setting-item-group-capacity-font">
            {`※ 할당 가능 용량 : ${allocableCapacity}`}
          </span>
          <span className="ui-setting-item-group-capacity-font">
            {`※ 사용 중인 용량 : ${capacityInUse}`}
          </span>
        </EuiSetting.ItemGroup>
        <EuiSetting.ItemGroup>
          <div className="ui-setting-item-group-align-items">용량 한도 :</div>
          <TextField
            width={60}
            type="text"
            value={capacityLimit}
            size="sm"
            onChange={handleCapacityChange}
          />
          <SelectField
            data={byteOptions}
            value={capacityLimitSpec}
            onChange={handleCapacitySpecChange}
          />
        </EuiSetting.ItemGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title="파일 첨부">
        <EuiSetting.ItemGroup>
          <div className="ui-setting-item-group-align-items">
            <span>첨부 개수 :</span>
            <span style={{ fontWeight: 'bold', padding: '0 12px' }}>최대</span>
            <TextField
              width={60}
              type="text"
              value={numberOfAttachments}
              size="sm"
              onChange={handleFileCountChange}
            />
            <span style={{ fontWeight: 'bold', padding: '0 5px' }}>개</span>
          </div>
        </EuiSetting.ItemGroup>
        <EuiSetting.ItemGroup>
          <div className="ui-setting-item-group-align-items">첨부 용량 :</div>
          <TextField
            width={70}
            type="text"
            value={attachmentsCapacity}
            size="sm"
            onChange={handleFileCapacityChange}
          />
          <SelectField
            data={byteOptions}
            value={attachmentsCapacitySpec}
            onChange={handleFileCapacitySpecChange}
          />
        </EuiSetting.ItemGroup>
      </EuiSetting.Item>
      <EuiSetting.Item title="관리자">
        <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
          {administrators.map(
            ({ companyId, employeeId, organizationId, avatar }) => (
              <Chip
                key={`${companyId}/${employeeId}`}
                label={getEmployeeName(companyId, employeeId)}
                etc={getOrganizationName(companyId, organizationId)}
                avatar={avatar}
                onDelete={() => handleAdministratorsDelete(employeeId)}
              />
            ),
          )}
          {administratorsMenuPoint && (
            <DirectoryMenuTreeContainer
              point={administratorsMenuPoint}
              typeToFilter="employee"
              onItemClick={handleAdministratorsAppend}
              onClose={handleDirectoryTreeMenuToggle}
            />
          )}
        </ChipGroup>
      </EuiSetting.Item>
      <EuiToolbar>
        <EuiToolbar.Left>
          {change && (
            <Button
              text="저장"
              variant="contained"
              loading={saveing}
              onClick={handleSave}
            />
          )}
          {!saveing && change && (
            <Button text="취소" onClick={() => setState(initialState)} />
          )}
        </EuiToolbar.Left>
      </EuiToolbar>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalSettingPreferencesContainer;
