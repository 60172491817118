import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentTooltipPositioned(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Positioned tooltips">
      <Button text="TOP-START" tooltip="Add" placement="top-start" />
      <Button text="TOP-MIDDLE" tooltip="Add" placement="top" />
      <Button text="TOP-END" tooltip="Add" placement="top-end" />
      <Button text="LEFT-START" tooltip="Add" placement="left-start" />
      <Button text="LEFT-MIDDLE" tooltip="Add" placement="left" />
      <Button text="LEFT-END" tooltip="Add" placement="left-end" />
      <Button text="RIGHT-START" tooltip="Add" placement="right-start" />
      <Button text="RIGHT-MIDDLE" tooltip="Add" placement="right" />
      <Button text="RIGHT-END" tooltip="Add" placement="right-end" />
      <Button text="BOTTOM-START" tooltip="Add" placement="bottom-start" />
      <Button text="BOTTOM-MIDDLE" tooltip="Add" placement="bottom" />
      <Button text="BOTTOM-END" tooltip="Add" placement="bottom-end" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTooltipPositioned;
