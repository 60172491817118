import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import TextField from '../textfield/TextField';
import SelectField from '../selectField/SelectField';
import Button from '../button/Button';
import CustomDatePicker from '../date/CustomDatePicker';
import RadioGroup from '../radio/RadioGroup';
import { timezoneDate } from '../../groupware-common/utils/ui';

export type SearchDateType =
  | 'all'
  | 'today'
  | 'yesterday'
  | '1week'
  | '1month'
  | '3months'
  | '6months'
  | '1year'
  | 'year'
  | 'month'
  | 'custom';

export interface SearchDateProps {
  type: SearchDateType;
  start: Date | null;
  end: Date | null;
}

export interface SearchFiltersProps {
  code: string;
  keyword: string;
  // condition: 'and' | 'or';
}
function Search(props: {
  className?: string;
  keyword: string;
  options?: { label: string; value: string }[];
  filter?: string;
  onSearch(arg: { keyword: string; filter?: string; status?: string }): void;
  advancedOptions?: { label: string; value: string }[];
  advancedFilter?: SearchFiltersProps[];
  date?: SearchDateProps;
  full?: boolean;
  status?: string;
  statusOptions?: { label: string; value: string }[];
  onResetAdvanced?(): void;
  onSearchAdvanced?(arg: {
    filters: SearchFiltersProps[];
    date: SearchDateProps;
    status?: string;
  }): void;
}): JSX.Element {
  const keyWordRef = useRef<HTMLInputElement>(null);
  const filterKeyWordRef = useRef<HTMLInputElement>(null);

  const initialState = {
    view: 'basic' as 'basic' | 'advanced',
    keyword: props.keyword,
    filter: props.filter,
    statusFiltered: props.status,
    date: {
      type: props.date?.type || 'all',
      start: props.date?.start || null,
      end: props.date?.end || null,
    } as SearchDateProps,
    advanced: props.advancedOptions?.map((x) => ({
      code: x.value,
      keyword: '',
    })) as SearchFiltersProps[] | undefined,
  };
  const [state, setState] = useState<{
    view: 'basic' | 'advanced';
    keyword: string;
    filter?: string;
    statusFiltered?: string;
    date: SearchDateProps;
    advanced?: SearchFiltersProps[];
  }>(initialState);

  const searchDateCategory: { value: SearchDateType; label: string }[] = [
    { value: 'all', label: '전체기간' },
    // { value: 'today', label: '오늘' },
    // { value: 'yesterday', label: '어제' },
    { value: '1week', label: '1주일' },
    { value: '1month', label: '1개월' },
    { value: '3months', label: '3개월' },
    { value: '6months', label: '6개월' },
    { value: '1year', label: '1년' },
    // { value: 'year', label: '연간' },
    // { value: 'month', label: '월간' },
    { value: 'custom', label: '직접입력' },
  ];

  const monthOptions = [
    { value: '0', label: '1월' },
    { value: '1', label: '2월' },
    { value: '2', label: '3월' },
    { value: '3', label: '4월' },
    { value: '4', label: '5월' },
    { value: '5', label: '6월' },
    { value: '6', label: '7월' },
    { value: '7', label: '8월' },
    { value: '8', label: '9월' },
    { value: '9', label: '10월' },
    { value: '10', label: '11월' },
    { value: '11', label: '12월' },
  ];

  /** 결재문서관리 상태변경 */
  const handleChangeStatusFilter = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      statusFiltered: value,
    }));
  };

  /** 기본검색 필터 */
  const handleChangeFilter = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      filter: value,
    }));
  };

  /** 기본검색 키워드 */
  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      keyword: event.target.value,
    }));
  };

  /** 기본검색 키워드에서 Enter 누름 */
  const handleSearchKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  /** 기본검색 키워드 비우기 */
  const handleClearKeyword = () => {
    setState((prevState) => ({
      ...prevState,
      keyword: '',
    }));
    keyWordRef.current?.focus();
  };

  /** 기본검색 검색 */
  const handleSearch = () => {
    props.onSearch({
      keyword: state.keyword,
      filter: state.filter,
      status: state.statusFiltered,
    });
    if (props.onSearchAdvanced) handleResetAdvanced(true);
  };

  /** 상세검색으로 전환 */
  const handleToggleAdvanced = () => {
    setState((prevState) => ({
      ...prevState,
      view: 'advanced',
    }));
    document.documentElement.setAttribute('data-advanced-search', 'true');
  };

  /** 상세검색 필터 키워드 */
  const handleChangeFiltersKeyword = (
    code: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      advanced: prevState.advanced?.map((x) =>
        x.code === code ? { ...x, keyword: event.target.value } : x,
      ),
    }));
  };

  /** 상세검색 필터 키워드 비우기 */
  const handleClearFiltersKeyword = (code: string) => {
    setState((prevState) => ({
      ...prevState,
      advanced: prevState.advanced?.map((x) =>
        x.code === code ? { ...x, keyword: '' } : x,
      ),
    }));
  };

  /** 상세검색 */
  const handleSearchAdvanced = () => {
    if (props.onSearchAdvanced && state.advanced)
      props.onSearchAdvanced({
        filters: state.advanced,
        date: {
          type: state.date.type,
          start: state.date.start,
          end: state.date.end,
        },
        status: state.statusFiltered,
      });
    handleCloseAdvanced();
  };

  /** 상세검색 키워드에서 Enter 누름 */
  const handleAdvancedSearchKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') handleSearchAdvanced();
  };

  /** 상세검색 초기화 */
  const handleResetAdvanced = (searchClick?: boolean) => {
    let statusFiltered = props.statusOptions ? 'all' : undefined;
    if (searchClick) statusFiltered = state.statusFiltered;

    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        type: 'all',
        start: null,
        end: null,
      },
      advanced: prevState.advanced?.map((x) => ({
        ...x,
        keyword: '',
      })),
      statusFiltered,
    }));
    if (props.onResetAdvanced) {
      props.onResetAdvanced();
    }
  };

  /** 상세검색 닫기 */
  const handleCloseAdvanced = () => {
    setState({ ...initialState, advanced: props.advancedFilter ?? [] });
    document.documentElement.removeAttribute('data-advanced-search');
  };

  const handleChangePeriodType = (type: SearchDateType) => {
    let start: Date | null;
    let end: Date | null;
    switch (type) {
      case 'all':
        start = null;
        end = null;
        break;
      // case 'today':
      //   start = new Date(moment().format());
      //   end = new Date(moment().format());
      //   break;
      // case 'yesterday':
      //   start = new Date(moment().subtract(1, 'day').format());
      //   end = new Date(moment().subtract(1, 'day').format());
      //   break;
      case '1week':
        start = timezoneDate(moment().subtract(1, 'week').format());
        end = timezoneDate(moment().format());
        break;
      case '1month':
        start = timezoneDate(moment().subtract(1, 'month').format());
        end = timezoneDate(moment().format());
        break;
      case '3months':
        start = timezoneDate(moment().subtract(3, 'month').format());
        end = timezoneDate(moment().format());
        break;
      case '6months':
        start = timezoneDate(moment().subtract(6, 'month').format());
        end = timezoneDate(moment().format());
        break;
      case '1year':
        start = timezoneDate(moment().subtract(1, 'year').format());
        end = timezoneDate(moment().format());
        break;
      // case 'year':
      //   start = new Date(moment(`${new Date().getFullYear()}-01-01`).format());
      //   end = new Date(moment(`${new Date().getFullYear()}-12-31`).format());
      //   break;
      // case 'month':
      //   start = new Date(
      //     moment()
      //       .set({
      //         year: new Date().getFullYear(),
      //         month: new Date().getMonth(),
      //         date: 1,
      //       })
      //       .format(),
      //   );
      //   end = new Date(
      //     moment()
      //       .set({
      //         year: new Date().getFullYear(),
      //         month: new Date().getMonth(),
      //         date: new Date(
      //           new Date().getFullYear(),
      //           new Date().getMonth() + 1,
      //           0,
      //         ).getDate(),
      //       })
      //       .format(),
      //   );
      //   break;
      case 'custom':
        start = timezoneDate();
        end = timezoneDate();
        break;
      default:
        start = null;
        end = null;
        break;
    }

    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        type,
        start,
        end,
      },
    }));
  };

  const handleChangeSelect = (
    type: 'yearYear' | 'monthYear' | 'monthMonth',
    value: string,
  ) => {
    const selected: number = parseInt(value, 10);
    // 연도별
    if (type === 'yearYear') {
      setState((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          start: new Date(moment(`${selected}-01-01`).format()),
          end: new Date(moment(`${selected}-12-31`).format()),
        },
      }));
    }

    // 월별 년
    if (type === 'monthYear') {
      setState((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          start: new Date(selected, moment(prevState.date.start).month(), 1),
          end: new Date(selected, moment(prevState.date.start).month() + 1, 0),
        },
      }));
    }

    // 월별 월
    if (type === 'monthMonth') {
      setState((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          start: new Date(moment(prevState.date.start).year(), selected, 1),
          end: new Date(moment(prevState.date.start).year(), selected + 1, 0),
        },
      }));
    }
  };

  let classname = 'eui-search';
  if (props.className) classname += ` ${props.className}`;
  if (props.full) classname += ' full';

  const handleChangeCustomStartDate = (date: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        start: date,
      },
    }));

    if (state.date.end && date) {
      if (state.date.end < date) {
        setState((prevState) => ({
          ...prevState,
          date: {
            ...prevState.date,
            end: date,
          },
        }));
      }
    }
  };

  const handleChangeCustomEndDate = (date: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        end: date,
      },
    }));
  };

  const dateControl = () => {
    const getYear = new Date().getFullYear();
    const yearOptions: { value: string; label: string }[] = [];

    for (let i = 0; i <= 10; i += 1) {
      const setYear = getYear - i;
      yearOptions.push({ value: `${setYear}`, label: `${setYear}년` });
    }

    switch (state.date.type) {
      case 'year':
        return (
          <SelectField
            data={yearOptions}
            value={`${moment(state.date.start).year() || moment().year()}`}
            onChange={(value) => handleChangeSelect('yearYear', value)}
            size="sm"
          />
        );
      case 'month':
        return (
          <>
            <SelectField
              data={yearOptions}
              value={`${moment(state.date.start).year() || moment().year()}`}
              onChange={(value) => handleChangeSelect('monthYear', value)}
              size="sm"
            />
            <SelectField
              data={monthOptions}
              value={`${moment(state.date.start).month() || moment().month()}`}
              onChange={(value) => handleChangeSelect('monthMonth', value)}
              size="sm"
            />
          </>
        );
      case 'custom':
        return (
          <>
            <CustomDatePicker
              dateFormat="yyyy-MM-dd"
              selected={state.date.start}
              onChange={(date: Date) => handleChangeCustomStartDate(date)}
              startDate={state.date.start}
              endDate={state.date.end}
              selectsStart
              portalId="portals"
            />
            <CustomDatePicker
              dateFormat="yyyy-MM-dd"
              selected={state.date.end}
              onChange={(date: Date) => handleChangeCustomEndDate(date)}
              startDate={state.date.start}
              endDate={state.date.end}
              minDate={state.date.start}
              selectsEnd
              portalId="portals"
            />
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      keyword: props.keyword,
      filter: props.filter,
      statusFiltered: props.status,
      advanced: props.advancedFilter ?? [],
      date: {
        type: props.date?.type || 'all',
        start: props.date?.start || null,
        end: props.date?.end || null,
      },
      view: 'basic',
    }));
  }, [props]);

  return (
    <>
      <div className={classname}>
        {state.view === 'basic' ? (
          <div className="basic-search">
            {props.statusOptions && state.statusFiltered !== undefined && (
              <SelectField
                data={props.statusOptions}
                value={state.statusFiltered}
                onChange={handleChangeStatusFilter}
              />
            )}
            {props.options && state.filter !== undefined && (
              <SelectField
                data={props.options}
                value={state.filter}
                onChange={handleChangeFilter}
                className="filter"
              />
            )}
            <TextField
              type="search"
              placeholder="검색어"
              className="keyword"
              value={state.keyword}
              clear={state.keyword !== ''}
              onChange={handleChangeKeyword}
              onKeyDown={handleSearchKeyDown}
              onClear={handleClearKeyword}
              ref={keyWordRef}
            />
            <Button
              text="검색"
              iconType
              icon="search"
              className="submit"
              onClick={handleSearch}
            />
            {props.onSearchAdvanced && state.advanced && (
              <Button
                text="상세검색"
                iconType
                icon="sliders-h"
                className={`advanced-toggle ${
                  state.advanced.find((a) => a.keyword !== '') ||
                  state.date.type !== 'all' ||
                  (state.statusFiltered !== undefined &&
                    state.statusFiltered !== 'all')
                    ? 'active'
                    : ''
                }`}
                onClick={handleToggleAdvanced}
              />
            )}
          </div>
        ) : (
          <div className="advanced-search">
            <div className="advanced-content">
              {state.advanced && props.statusOptions && state.statusFiltered && (
                <div className="requirement-item">
                  <div className="item-title">상태</div>
                  <RadioGroup
                    data={props.statusOptions}
                    value={state.statusFiltered}
                    name="status"
                    onChange={handleChangeStatusFilter}
                  />
                </div>
              )}
              {state.advanced &&
                state.advanced.map((x) => (
                  <div className="requirement-item" key={x.code}>
                    <div className="item-title">
                      {
                        props.advancedOptions?.find((o) => o.value === x.code)
                          ?.label
                      }
                    </div>
                    <TextField
                      value={`${x.keyword}`}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeFiltersKeyword(x.code, event)
                      }
                      ref={filterKeyWordRef}
                      clear={x.keyword !== ''}
                      onKeyDown={handleAdvancedSearchKeyDown}
                      onClear={() => handleClearFiltersKeyword(x.code)}
                      size="sm"
                    />
                  </div>
                ))}
              <div className="advanced-head">
                <SelectField
                  data={searchDateCategory}
                  value={state.date.type}
                  onChange={handleChangePeriodType}
                  size="sm"
                />
                {dateControl()}
              </div>
            </div>
            <div className="advanced-head" />
            <div className="advanced-action">
              <Button
                text="검색"
                icon="search"
                variant="contained"
                onClick={handleSearchAdvanced}
                size="sm"
              />
              <Button
                text="초기화"
                icon="undo"
                onClick={() => handleResetAdvanced()}
                color="secondary"
                size="sm"
              />
              <Button
                text="닫기"
                iconType
                icon="times"
                onClick={handleCloseAdvanced}
                color="secondary"
                size="sm"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Search;
