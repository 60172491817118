import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useAppDispatch } from '../../groupware-webapp/app/store';
import { sessionActions } from '../../groupware-webapp/stores/session';
import PromptMessage from './PromptMessage';

interface Props {
  pathname: string;
  search: string;
  hash?: string;
  reload: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const NavigationGuard = ({
  pathname,
  search,
  hash,
  reload,
  navigate,
  shouldBlockNavigation,
}: Props): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const dispatch = useAppDispatch();

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    // 모달 창에서 확인을 눌렀을 때 이전 페이지로 이동
    if (confirmedNavigation && lastLocation) {
      let path = lastLocation.pathname;
      if (lastLocation.search) path += lastLocation.search;
      if (lastLocation.hash) path += lastLocation.hash;

      setConfirmedNavigation(false);
      dispatch(sessionActions.setRoute({ pathname, search, hash }));
      navigate(path);
    }
  }, [confirmedNavigation, lastLocation]);

  const renderDialog = () => {
    if (modalVisible) {
      return (
        <PromptMessage
          onSubmit={handleConfirmNavigationClick}
          onCancel={closeModal}
        >
          작성 중인 내용은 저장되지 않습니다
          <br />
          페이지를 벗어나시겠습니까?
        </PromptMessage>
      );
    }
    return null;
  };

  return (
    <>
      <Prompt when={reload} message={handleBlockedNavigation} />
      {renderDialog()}
    </>
  );
};
export default NavigationGuard;
