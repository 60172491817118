import axios from 'axios';
import {
  apiError,
  EntityKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  ShareReservationList,
  ReservationItem,
  ReservationList,
} from '../../../../stores/resources';

/** 자원 예약 리스트 조회. */
async function reservationList(arg: {
  type: 'item' | 'folder';
  id: number;
  fromDate: string;
  toDate: string;
}): Promise<ReservationList[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/${arg.type}/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        id: arg.id,
        fromDate: arg.fromDate,
        toDate: arg.toDate,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 자원 예약 리스트 조회. */
async function adminReservationList(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  pageno: number;
  rowsperpage: number;
  isdirectoryselected: boolean;
}): Promise<ShareReservationList[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/admin/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 자원 예약 리스트 총 개수 */
async function adminReservationToTalCount(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/admin/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 내 예약 리스트 조회. */
async function myReservationList(arg: {
  employeeid: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  pageno: number;
  rowsperpage: number;
}): Promise<ShareReservationList[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/my/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 내 예약 리스트 총 개수 */
async function myReservationToTalCount(arg: {
  employeeid: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/my/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공유받은 예약 리스트 조회. */
async function sharedReservationList(arg: {
  employeeid: number;
  organizationid: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  pageno: number;
  rowsperpage: number;
  isdirectoryselected: boolean;
}): Promise<ShareReservationList[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/share/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 내 예약 리스트 총 개수 */
async function sharedReservationToTalCount(arg: {
  employeeid: number;
  organizationid: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/share/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 예약 단일 조회. */
async function reservationView(arg: {
  id: number;
  updateAt?: string;
}): Promise<{
  companyId: number;
  id: number; // 자원 예약 아이디.
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  name: string;
  remark: string;
  startDateTime: string; // 시작 시간.
  endDateTime: string; // 종료 시간
  isRepeat: boolean; // 반복 여부
  isApproval: number; // 승인 여부
  isTakeback: boolean; // 대여 여부
  sharers: {
    companyId: number;
    reservationId: number;
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    updateAt: string;
  }[];
  repeat?: {
    companyId: number;
    reservationId: number;
    frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard: number | null; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    repeatDays: string | null; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    firstDayOfWeek: number; // 한주의 시작 (1 : 일요일 , 2 : 월요일)
    endDate: string; // 반복 종료일
    creatorId: number; // 생성자 아이디
    updaterId: number; // 수정자 아이디
    createAt: string; // 생성 시간
    updateAt: string; // 수정 시간
  };
  alarms: {
    companyId: number;
    id: number;
    reservationId: number;
    type: string; // mail | alarm
    timeUnit: string; // MINUTE, HOUR, DAY, WEEK
    ammount: number;
    updateAt: string;
  }[];
  creatorId: number; // 생성자 아이디
  updaterId: number; // 수정자 아이디
  createAt: string; // 생성 시간
  updateAt: string; // 수정 시간
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation`;
    const response = await axios.get(url, {
      headers,
      params: {
        id: arg.id,
        updateAt: arg.updateAt,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 예약 등록. */
async function reservationSave(arg: {
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  name: string;
  remark: string;
  startDateTime: string; // 시작 시간.
  endDateTime: string; // 종료 시간
  sharers?: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
  }[];
  repeat?: {
    frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    days?: string; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    endDate: string; // 반복 종료일
  };
  alarms?: {
    type: string; // mail | alarm
    timeUnit: string; // MINUTE, HOUR, DAY, WEEK
    ammount: number;
  }[];
}): Promise<{
  companyId: number;
  id: number; // 자원 예약 아이디.
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  name: string;
  remark: string;
  startDateTime: string;
  endDateTime: string;
  isRepeat: boolean; // 반복 여부
  isApproval: number; // 승인 여부
  isTakeback: boolean; // 대여 여부
  creatorId: number; // 생성자 아이디
  updaterId: number; // 수정자 아이디
  createAt: string; // 생성 시간
  updateAt: string; // 수정 시간
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 일반 예약 삭제. */
async function deleteReservation(arg: {
  id: number;
  updateAt: string;
}): Promise<EntityKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 반복 예약 삭제 */
async function deleteRepeatReservation(arg: {
  type: string; // piece: 이 예약만 삭제, all: 모든 예약 삭제, after: 이 예약 및 향후 예약 삭제
  id: number;
  startDateTime: string;
  updateAt: string;
}): Promise<ReservationItem> {
  try {
    const data = {
      id: arg.id,
      startDateTime: arg.startDateTime,
      updateAt: arg.updateAt,
    };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/repeat/${arg.type}`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 일반 예약 수정. */
async function updateReservation(arg: {
  id: number; // 자원 예약 아이디.
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  name: string;
  remark?: string;
  startDateTime?: string;
  endDateTime?: string;
  repeat?: {
    frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    endDate: string; // 반복 종료일
    days?: string; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    updateAt?: string;
  };
  sharers?: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    lookupDeletAt?: string;
  }[];
  alarms?: {
    id?: number;
    type: string; // mail | alarm
    timeUnit: string; // MINUTE, HOUR, DAY, WEEK
    ammount: number;
    lookupDeletAt?: string;
  }[];
  updateAt: string;
}): Promise<ReservationItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 반복 예약 수정. */
async function updateRepeatReservation(arg: {
  type: string;
  data: {
    id: number; // 자원 예약 아이디.
    itemId: number; // 자원 아이템 아이디.
    employeeId: number;
    name: string;
    remark?: string;
    startDateTime?: string;
    endDateTime?: string;
    repeat?: {
      frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
      cycle: number; // 반복 주기. 1, 2, 3, 4, ...
      monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
      endDate: string; // 반복 종료일
      days?: string; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
      updateAt?: string;
      isDelete?: boolean;
    };
    sharers?: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    alarms?: {
      id?: number;
      type: string; // mail | alarm
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
      updateAt?: string;
      lookupDeleteAt?: string;
    }[];
    lookupStartDateTime?: string;
    lookupUpdateAt?: string;
    updateAt?: string;
  };
}): Promise<ReservationItem> {
  const { type, data } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/reservation/repeat/${type}`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const resourcesApi = {
  reservationList,

  adminReservationList,
  adminReservationToTalCount,

  myReservationList,
  myReservationToTalCount,

  sharedReservationList,
  sharedReservationToTalCount,

  reservationView,

  reservationSave,

  deleteReservation,
  deleteRepeatReservation,

  updateReservation,
  updateRepeatReservation,
};

export default resourcesApi;
