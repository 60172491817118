import axios from 'axios';
import { apiError, getApiConfig } from '../../common/v1';

const getModuleUrl = (module: string): string => {
  let url = '';
  switch (module) {
    case 'document': {
      url = '/api/document/v1/file/';
      break;
    }
    case 'board': {
      url = '/api/board/v1/file/';
      break;
    }
    case 'attendance': {
      url = '/api/attendance/v1/file/';
      break;
    }
    case 'approval':
    default: {
      url = '/api/file/v1/';
      break;
    }
  }
  return url;
};
async function uploadOne(arg: {
  path: string;
  file: { id: number; file: File };
  module: string;
  onProgress?: (event: ProgressEvent, id: string) => void;
}): Promise<boolean> {
  try {
    const { path, file, module, onProgress } = arg;
    const moduleUrl = getModuleUrl(module);

    const formData = new FormData();
    formData.append('files', file.file, `${file.id}`);

    const { host, headers } = getApiConfig();
    const url = `${host}${moduleUrl}${path}`;
    const response = await axios.post(url, formData, {
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      onUploadProgress: onProgress
        ? (event: ProgressEvent) => onProgress(event, file.id.toString())
        : undefined,
    });
    return response.status === 200;
  } catch (e) {
    throw apiError(e);
  }
}

async function upload(
  path: string,
  files: { id: number; file: File }[],
  onProgress?: (event: unknown) => void,
): Promise<boolean> {
  try {
    const formData = new FormData();
    files.forEach(({ id, file }) => {
      formData.append('files', file, `${id}`);
    });

    const { host, headers } = getApiConfig();
    const url = `${host}/api/file/v1/${path}`;
    const response = await axios.post(url, formData, {
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    });
    return response.status === 200;
  } catch (e) {
    throw apiError(e);
  }
}

const fileApi = {
  uploadOne,
  upload,
};

export default fileApi;
