import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../../components/post/PostWrite';
import Radio from '../../../../../components/radio/Radio';
import SelectField from '../../../../../components/selectField/SelectField';
import { b62 } from '../../../../../groupware-common/utils';
import { dateTimeFormat } from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import contactsApi from '../../../../apis/contacts/v1/contacts';

function ContactExportDialog(props: {
  clickType: 'list' | 'drawer';
  folderId?: string;
  onClose(): void;
}): JSX.Element {
  const { clickType, folderId, onClose } = props;
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const labels = useSelector((state: RootState) => state.contact.labels.list);
  const reduxList = useSelector(
    (state: RootState) => state.contact.contacts.list,
  );
  const checkedList =
    folderId === 'trash' || clickType === 'drawer'
      ? []
      : reduxList.filter((a) => a.checked).map((a) => a.id);

  const labelItems = [
    { value: '', label: '라벨' },
    ...labels.map(({ id, name }) => {
      return {
        value: id.toString(),
        label: name,
      };
    }),
  ];

  const [state, setState] = useState<{
    checked: 'selected' | 'group' | 'all';
    fileType: 'google' | 'outlook';
    selectedGroupId?: number;
    validation: string;
  }>(() => {
    const selectedGroupId =
      !folderId ||
      folderId === 'all' ||
      folderId === 'importance' ||
      folderId === 'trash'
        ? undefined
        : b62(folderId);
    let checked: 'selected' | 'group' | 'all' = 'all';
    if (selectedGroupId) checked = 'group';
    if (checkedList.length !== 0) checked = 'selected';

    return {
      checked,
      fileType: 'google',
      selectedGroupId,
      validation: '',
    };
  });

  /** 대상 타입 변경 이벤트 */
  const handleChangeChecked = (checked: 'selected' | 'group' | 'all') => {
    setState((prev) => ({ ...prev, checked }));
  };

  /** 내보낼 파일 형식 변경 이벤트 */
  const handleChangeFileType = (fileType: 'google' | 'outlook') => {
    setState((prev) => ({ ...prev, fileType }));
  };

  /** 라벨 연락처 내보내기 시 내보낼 라벨 선택 이벤트 */
  const handleChangeSelected = (value: string) => {
    if (value === '') return;
    setState((prev) => ({
      ...prev,
      selectedGroupId: parseInt(value, 10),
    }));
  };

  /** 연락처 내보내기 */
  const handleExportContacts = () => {
    const { fileType: file, checked: type } = state;
    const labelId = type === 'group' ? state.selectedGroupId : undefined;
    const peopleIds = type === 'selected' ? checkedList : undefined;

    if (type === 'group' && !labelId) {
      setState((prev) => ({ ...prev, validation: '라벨을 선택해 주세요.' }));
      return;
    }

    contactsApi
      .contactExport({
        file,
        companyId: principal.companyId,
        type,
        labelId,
        peopleIds,
      })
      .then((data) => {
        if (!data)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');
        const blob = new Blob([`\ufeff${data}`], {
          type: 'text/csv;charset=utf-8;',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        const date = dateTimeFormat(new Date(), 'yyyyMMDD');
        a.href = url;
        a.download = `contacts_${date}.csv`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
    onClose();
  };

  const { checked, fileType, selectedGroupId } = state;

  return (
    <Dialog size="sm" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>연락처 내보내기</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <PostWrite.Item title="대상">
            <fieldset
              style={{ marginBottom: '2px' }}
              className="eui-check-group column"
            >
              <div className="group">
                <Radio
                  disabled={checkedList.length === 0}
                  checked={checked === 'selected'}
                  label={`선택된 연락처 (${checkedList.length})`}
                  onChange={() => handleChangeChecked('selected')}
                />
                <Radio
                  disabled={labels.length === 0 || clickType === 'list'}
                  checked={checked === 'group'}
                  label="라벨 내 연락처"
                  onChange={() => handleChangeChecked('group')}
                >
                  <SelectField
                    disabled={checked !== 'group'}
                    data={labelItems}
                    value={selectedGroupId?.toString() ?? ''}
                    onChange={handleChangeSelected}
                  />
                </Radio>
                <Radio
                  disabled={clickType === 'list'}
                  checked={checked === 'all'}
                  label="전체 연락처"
                  onChange={() => handleChangeChecked('all')}
                />
              </div>
            </fieldset>
            <HelperText text="* 선택된 연락처는 주소록에서 원하는 연락처를 체크하여 '내보내기'를 통해 내보낼 수 있습니다." />
          </PostWrite.Item>
          <PostWrite.Item title="파일 형식">
            <fieldset className="eui-check-group column">
              <div className="group">
                <Radio
                  checked={fileType === 'google'}
                  label="Google CSV"
                  onChange={() => handleChangeFileType('google')}
                />
                {/* <Radio
                  checked={fileType === 'outlook'}
                  label="Outlook CSV"
                  onChange={() => handleChangeFileType('outlook')}
                /> */}
              </div>
            </fieldset>
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button variant="outlined" text="취소" onClick={onClose} />
        <Button
          onClick={handleExportContacts}
          className="action-close"
          variant="contained"
          text="내보내기"
          icon="csv-logo"
        />
      </DialogFooter>
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
    </Dialog>
  );
}

export default ContactExportDialog;
