/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'dashboard';

interface State {
  requests: { id: string; type: string; arg: any }[];
}

const initialState: State = {
  requests: [],
};

function asyncThunkPending(action: AnyAction): action is PayloadAction<
  undefined,
  string,
  {
    requestId: string;
    arg: any;
  }
> {
  return (
    action.type.indexOf(`${name}/`) === 0 &&
    action.type.substr(action.type.lastIndexOf('/')) === '/pending'
  );
}

const dashboardSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(asyncThunkPending, (state, action) => {
      state.requests.push({
        type: action.type.substring(0, action.type.lastIndexOf('/')),
        id: action.meta.requestId,
        arg: action.meta.arg,
      });
    });
  },
});

export default dashboardSlice.reducer;

export const DashboardStore = {};
