import React from 'react';

function Item(props: { children: React.ReactNode }): JSX.Element {
  return <div className="drawer-list-item">{props.children}</div>;
}

function DrawerList(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'drawer-list';
  if (props.className) classname += ` ${props.className}`;
  return <div className={classname}>{props.children}</div>;
}

DrawerList.Item = Item;

export default DrawerList;
