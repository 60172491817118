import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipClick(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Action">
      <Chip label="클릭 가능" onClick={() => alert('클릭 이벤트')} />
      <Chip label="삭제 가능" onDelete={() => alert('삭제 이벤트')} />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipClick;
