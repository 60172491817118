import React from 'react';

function EuiBody(props: {
  scrollbar?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}): JSX.Element {
  const { scrollbar, children } = props;
  return (
    <div className="ui-content-body" ref={scrollbar}>
      {children}
    </div>
  );
}

export default EuiBody;
