/* eslint-disable no-param-reassign */
import {
  CacheLists,
  CacheListsItem,
  CacheViews,
  ListEntity,
  ViewEntity,
  PageState,
  AsyncRequest,
  PendingAction,
} from '../types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { deepEqual, getPageNo } from '../../../groupware-common/utils';
import store, { RootState } from '../../app/store';

export function createPageState<L, V>(): PageState<L, V> {
  return {
    readingPaneMode: 'list',
    list: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: {
        items: [],
        totalCount: 0,
        search: '',
      },
    },
    view: {
      meta: undefined,
      data: undefined,
    },
    cache: {
      list: {},
      view: {},
    },
  };
}

export function getCacheListName(category: number, search: string): string {
  // 리덕스 엑션에서 store.getState() 접근 시 오류 발생.

  const regex = /&?(rpp|p|id)=\w+/g;
  const params = search.replace(regex, '');

  if (params === '' || params === '?') return `${category}`;
  // 기본 매개변수를 제외한 검색 내용이 있는 경우.
  return `${category}_${params.substring(1)}`;
}

export function getCacheList<T>(
  caches: CacheLists<T>,
  folderId: number,
  search: string,
): CacheListsItem<T> | undefined;
export function getCacheList<T>(
  caches: CacheLists<T>,
  folderId: number,
  search: string,
  init: CacheListsItem<T>,
): CacheListsItem<T>;
export function getCacheList<T>(
  caches: CacheLists<T>,
  folderId: number,
  search: string,
  init?: CacheListsItem<T>,
): CacheListsItem<T> | undefined {
  const name = getCacheListName(folderId, search);

  if (caches[name] === undefined && init !== undefined) caches[name] = init;

  return caches[name];
}

export function createCacheListPage<T>(): ListEntity<T> {
  return {
    meta: { timestamp: 0 },
    data: null,
  };
}

export function createCacheView<T>(): ViewEntity<T> {
  return {
    meta: { timestamp: 0 },
    data: null,
  };
}

export function getCacheListPage<T>(
  lists: CacheLists<T>,
  category: number,
  search: string,
): T | undefined;
export function getCacheListPage<T>(
  lists: CacheLists<T>,
  category: number,
  search: string,
  init: T,
): T;
export function getCacheListPage<T>(
  lists: CacheLists<T>,
  category: number,
  search: string,
  init?: T,
): T | undefined {
  const pageno = `${getPageNo(search)}`;

  if (init !== undefined) {
    if (lists[getCacheListName(category, search)] === undefined) {
      lists[getCacheListName(category, search)] = {
        lastUpdateAt: '',
        totalCount: 0,
        page: { [pageno]: init },
      };
    } else if (
      lists[getCacheListName(category, search)].page[pageno] === undefined
    ) {
      lists[getCacheListName(category, search)].page[pageno] = init;
    }
  }

  return lists[getCacheListName(category, search)]?.page?.[pageno];
}

export function getCacheView<T>(
  lists: CacheViews<T>,
  item: number,
): T | undefined;
export function getCacheView<T>(lists: CacheViews<T>, item: number, init: T): T;
export function getCacheView<T>(
  views: CacheViews<T>,
  item: number,
  init?: T,
): T | undefined {
  const name = `_${item}`;
  if (init !== undefined && views[name] === undefined) views[name] = init;
  return views[name];
}

/**
 * 상태의 요청 배열 중 요청 인자 값 요청 배열에 추가.
 * @param state 요청 배열이 있는 상태 값.
 * @param action 액션.
 */
export function requestAppend(
  state: { requests: AsyncRequest[] },
  action: PendingAction,
): void {
  // eslint-disable-next-line prettier/prettier
  const { type, meta: { arg, requestId: id } } = action;
  state.requests.push({ type: type.slice(0, -8), arg, id });
}

/*
export function asyncThunkRequestArgCondition(
  arg: any,
  api: { getState: () => unknown },
  type: string,
): boolean {
  const { session } = api.getState() as RootState;
  // 같은 요청이 있는 경우 실행되지 않도록 설정.
  if (
    session.requests.find((x) => x.type === type && deepEqual(x.arg, arg)) !==
    undefined
  ) {
    return false;
  }
  return true;
}
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, prettier/prettier
export function thunkCondition(action: string, arg: any, getState: () => unknown): boolean {
  // eslint-disable-next-line prettier/prettier
  const { session: { requests } } = getState() as RootState;
  return (
    requests.find((x) => x.type === action && deepEqual(x.arg, arg)) ===
    undefined
  );
}

/**
 * 비동기 요청 배열 중 요청 아이디 포함 여부 반환.
 * @param requests 비동기 요청 배열.
 * @param nameId 요청 아이디.
 * @return 포함 여부.
 */
export function asyncRequestContains(
  requests: AsyncRequest[],
  requestId: string,
): boolean {
  return requests.find((x) => x.id === requestId) !== undefined;
}

type Principal = {
  companyId: number;
  employeeId: number;
};

export function getPrincipal(): Principal {
  const { principal } = (store.getState() as RootState).session;
  return principal;
}
