import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';
import {
  CacheEntity,
  CacheMeta,
  Language,
  LocateArg,
} from '../../../../groupware-common/types';
import api from '../../../apis/directory/v1/organization';
import employeeApi from '../../../apis/directory/v1/employee';
import { thunkCondition } from '../../../../groupware-webapp/stores/utils';
import { ApiError } from '../../../../groupware-common/types/error';
import { RootState } from '../../../../groupware-webapp/app/store';
import { appError } from '../../../../groupware-webapp/stores/common/utils';
import { directoryPreferencesActions } from '../preferences';
import { companyActions } from '../company';
import { employeeActions } from '../employee';
import { jobPositionActions } from '../jobposition';
import { jobDutyActions } from '../jobduty';

const name = 'directory/organization';

type Item = {
  companyId: number;
  id: number;
  nameId: number;
  parentId: number;
  updateAt: string;

  nameKey: string;
};

export type View = Item & {
  description: string;
};

/** 조직 인터페이스 */
interface OrganizationItem {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  nameId: number;
  managerId: number;
  updateAt: string;

  nameKey: string;
}

/** 조직 뷰 인터페이스 */
interface OrganizationView {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  nameId: number;
  managerId: number;
  description: string;
  updateAt: string;

  nameKey: string;
  managerNameKey: string;
}

/** 조직 직원 인터페이스 */
interface OrganizationEmployee {
  companyId: number;
  id: number;
  employeeId: number;
  seq: number;
  jobDutyId: number;
  updateAt: string;
}

interface State {
  list: CacheEntity<{ items: OrganizationItem[] }>;
  view: CacheEntity<OrganizationView | null | undefined>;

  employees: CacheEntity<{ items: OrganizationEmployee[] }>;
}

const initialState: State = {
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [] },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },

  employees: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [] },
  },
};

const findList = createAsyncThunk<
  {
    meta: CacheMeta | undefined;
    data: {
      items: OrganizationItem[];
    };
  },
  void,
  { rejectValue: ApiError }
>(
  `${name}/findList`,
  async (_: void, thunkApi) => {
    let meta: CacheMeta | undefined;
    try {
      const response = await api.findList();
      const items = response.map((a) => {
        const { companyId, id } = a;
        return {
          ...a,
          nameKey: api.getNameKey(companyId, id),
        };
      });
      return { meta, data: { items } };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findList.typePrefix, arg, getState);
    },
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (arg: { id: number | 'first' } & LocateArg, { rejectWithValue }) => {
    let meta: CacheMeta | undefined;
    let data: OrganizationView | null | undefined;
    let _response_id_: number | undefined;
    try {
      /*
      const id: number | null =
        arg.id === 'first'
          ? state.jobPosition.list.data[0]?.id ||
            (await Api.firstId())
          : arg.id;
      */
      let id: number | null = null;
      if (arg.id === 'first') {
        id = 1;
      } else id = arg.id;

      if (id !== null && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      const response = await api.findView(id);
      if (response) {
        data = {
          ...response,
          nameKey: api.getNameKey(response.companyId, response.id),
          managerNameKey: employeeApi.getNameKey(
            response.companyId,
            response.managerId,
          ),
        };
      }

      return { meta, data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findView.typePrefix, arg, getState);
    },
  },
);

/*
const save = createAsyncThunk(
  `${name}/save`,
  async (arg: OrganizationSaveArg & LocationArg, thunkApi) => {
    console.log('#### module - save Start #### arg : ', arg);

    let list:
      | CacheEntity<{ items: Item[]; totalCount: number; search: string }>
      | undefined;
    let view: CacheEntity<View | null | undefined> | undefined;
    let _response_id_: number | undefined;
    try {
      const timestamp = Date.now();

      const response = await api.save(arg);

      const lastUpdateAt = response.updateAt;

      const responseList = await api.findList();
      const items = responseList
        .filter((a) => a.id > 0)
        .map((x) => {
          return {
            ...x,
            nameKey: api.getNameKey(x.companyId, x.nameId),
          };
        });
      const totalCount = items.length;
      const search = '';

      list = {
        meta: { timestamp, lastUpdateAt },
        data: { items, totalCount, search },
      };

      const viewData = await api.findView(response.id);
      view = {
        meta: { timestamp, lastUpdateAt },
        data: viewData
          ? {
              ...viewData,
              nameKey: api.getNameKey(response.companyId, response.nameId),
            }
          : viewData,
      };

      if (response.id && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = response.id;

      console.log('#### module - save End 2222222 ####');
      return { list, view, _response_id_ };
    } catch (error) {
      console.log('error : ', error);

      // thunkApi.rejectWithValue(error.response.data);
    }

    console.log('#### module - save End 111111 ####');
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(save.typePrefix, arg, getState);
    },
  },
);
*/

const create = createAsyncThunk(
  `${name}/create`,
  async (
    arg: {
      parentId: number;
      names: { label: Language; value: string }[];
      managerId: number;
      description: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const param = {
        ...arg,
        names: arg.names.map(({ label: code, value }) => ({ code, value })),
      };
      const response = await api.create(param);
      const { companyId, id } = response;

      const nameKey = api.getNameKey(companyId, id, false);
      const i18n = getI18n();
      param.names.forEach(({ code, value }) => {
        i18n.addResource(code, api.namespace, nameKey, value);
      });

      await dispatch(findList());
      await dispatch(findView({ id }));

      return { _response_id_: id };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(create.typePrefix, arg, getState);
    },
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      id: number;
      parentId?: number;
      names?: { label: Language; value: string | null }[];
      managerId?: number;
      description?: string;
      updateAt: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const param = {
        ...arg,
        names: arg.names?.map(({ label: code, value }) => ({ code, value })),
      };

      const response = await api.update(param);
      const { companyId, id } = response;

      const { names } = param;
      if (names) {
        const nameKey = api.getNameKey(companyId, id, false);
        const i18n = getI18n();
        names.forEach(({ code, value }) => {
          i18n.addResource(code, api.namespace, nameKey, value || '');
        });
      }

      await dispatch(findList());
      await dispatch(findView({ id }));

      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(update.typePrefix, arg, getState);
    },
  },
);

/** 조직 순서변경 */
const seq = createAsyncThunk(
  `${name}/seq`,
  async (
    arg: {
      data: { id: number; seq: number; updateAt: string }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      const response = await api.seq(data);

      await dispatch(findList());
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(seq.typePrefix, arg, getState);
    },
  },
);

const remove = createAsyncThunk<
  {
    _response_id_: number | undefined;
  },
  {
    id: number;
    updateAt: string;
  } & LocateArg,
  { rejectValue: ApiError }
>(
  `${name}/remove`,
  async (arg, { dispatch, getState, rejectWithValue }) => {
    const { id, updateAt } = arg;
    const state = (getState() as RootState).directory.organization;
    const _response_id_ = state.list.data.items.find(
      (a) => a.id === id,
    )?.parentId;

    try {
      await api.delete(id, updateAt);
      await dispatch(findList());
      if (_response_id_) await dispatch(findView({ id: _response_id_ }));

      return { _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(remove.typePrefix, arg, getState);
    },
  },
);

const findEmployee = createAsyncThunk<
  {
    meta: CacheMeta | undefined;
    data: {
      items: OrganizationEmployee[];
    };
  },
  { id: 'all' | number },
  { rejectValue: ApiError }
>(
  `${name}/findEmployee`,
  async (arg, thunkApi) => {
    let meta: CacheMeta | undefined;
    let data: { items: OrganizationEmployee[] } | undefined;
    try {
      const id = typeof arg.id === 'number' ? arg.id : undefined;
      const response = await api.findEmployee(id);
      data = { items: response };
      return { meta, data };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findEmployee.typePrefix, arg, getState);
    },
  },
);

const employeeDelete = createAsyncThunk(
  `${name}/employeeDelete`,
  async (
    arg: { id: number; employeeId: number; updateAt: string } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    const { id, employeeId, updateAt } = arg;
    try {
      const response = await api.employeeDelete(id, employeeId, updateAt);
      await dispatch(findEmployee({ id: 'all' }));
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(remove.typePrefix, arg, getState);
    },
  },
);

/** 조직도 연동 */
const updateEexternal = createAsyncThunk(
  `${name}/updateEexternal`,
  async (_: LocateArg | void, { dispatch, rejectWithValue }) => {
    try {
      await api.updateEexternal().then(() => {
        dispatch(directoryPreferencesActions.find({}));
        dispatch(companyActions.findList());
        dispatch(organizationActions.findList());
        dispatch(organizationActions.findEmployee({ id: 'all' }));
        dispatch(employeeActions.findList());
        dispatch(jobPositionActions.findList());
        dispatch(jobDutyActions.findList());
      });
      return true;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(update.typePrefix, arg, getState);
    },
  },
);

const organizationSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 리스트 메타 상태 값 할당.
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 리스트 데이터 상태 값 할당.
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 뷰 메타 상태 값 할당.
        if (payload?.meta !== undefined) state.view.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 뷰 데이터 상태 값 할당.
        if (payload?.data !== undefined) state.view.data = payload.data;
      })
      .addCase(findEmployee.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.meta !== undefined) state.employees.meta = payload.meta;
        if (payload?.data !== undefined) state.employees.data = payload.data;
      });
  },
});

export default organizationSlice.reducer;

export const organizationActions = {
  findList,
  findView,
  create,
  update,
  seq,
  delete: remove,

  updateEexternal,

  findEmployee,
  employeeDelete,
};
