import { useEffect } from 'react';
import { sessionActions } from '../../../../groupware-webapp/stores/session';

import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { companyActions } from '../../../stores/directory/company';
import { organizationActions } from '../../../stores/directory/organization';
import { employeeActions } from '../../../stores/directory/employee';
import { jobPositionActions } from '../../../stores/directory/jobposition';
import { jobDutyActions } from '../../../stores/directory/jobduty';

function DirectoryRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;
  useEffect(() => {
    let mount = true;
    Promise.all([
      dispatch(companyActions.findList()),
      dispatch(organizationActions.findList()),
      dispatch(organizationActions.findEmployee({ id: 'all' })),
      dispatch(employeeActions.findList()),
      dispatch(jobPositionActions.findList()),
      dispatch(jobDutyActions.findList()),
    ]).then(() => {
      const route = { pathname, search, hash, clearErrors: true };
      if (mount) dispatch(sessionActions.setRoute(route));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default DirectoryRoute;
