import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 문서 조회자의 총개수를 조회 합니다.
 * @param arg.affiliatedCompanyId 관계사 회사 아아디.
 * @param arg.id 문서 아아디.
 * @param arg.viewer 조회자 이름.
 * @returns 총개수.
 */
async function totalcount(arg: {
  affiliatedCompanyId?: number;
  id: number;
  viewer?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/documentviewer/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedcompanyid: arg.affiliatedCompanyId,
        id: arg.id,
        viewer: arg.viewer,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 문서 조회자 목록을 조회 합니다.
 * @param arg.affiliatedCompanyId 관계사 회사 아이디.
 * @param arg.id 문서 아이디.
 * @param arg.pageNo 문서 번호.
 * @param arg.rowsPerPage 페이지 당 행 수.
 * @param arg.viewer 조회자 이름.
 * @returns 문서 조회자 데이터 전송 객체 목록.
 */
async function findAll(arg: {
  affiliatedCompanyId?: number;
  id: number;
  pageNo: number;
  rowsPerPage: number;
  viewer?: string;
}): Promise<
  {
    affiliatedCompanyId?: number;
    id: number;
    employeeCompanyId: number;
    employeeId: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/documentviewer/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedcompanyid: arg.affiliatedCompanyId,
        id: arg.id,
        pageno: arg.pageNo,
        rowsperpage: arg.rowsPerPage,
        viewer: arg.viewer,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const documentViewerApi = {
  totalcount,
  findAll,
};

export default documentViewerApi;
