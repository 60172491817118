import React from 'react';
import Button from '../../../../components/button/Button';
import EuiToolbar from '../../../../components/layout/EuiToolbar';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../components/toolbarAction/ToolbarAction';

function ApprovalContentHeadView(
  props: (
    | {
        /** 유형. (full: 전체 보기, split: 분할 보기) */
        type: 'full';
        /** 목록으로 이동 이벤트. */
        onListGo?(): void;
        /** 이전 이벤트. */
        onPrev?(): void;
        /** 다음 이벤트. */
        onNext?(): void;
      }
    | {
        /** 유형. (full: 전체 보기, split: 분할 보기) */
        type: 'split';
        onListGo?: undefined;
        onPrev?: undefined;
        onNext?: undefined;
      }
  ) & {
    /** 도구 모음 버튼 배열. */
    toolbarButtons: ActionEventProps[];
    /** 도구 모음 액션 이벤트. */
    onToolbarAction(arg: { code: string; event: React.MouseEvent }): void;
  },
): JSX.Element {
  // console.log(`${ApprovalContentHeadView.name}.render`);

  const { type, onListGo, onToolbarAction, onPrev, onNext } = props;

  return (
    <EuiToolbar className={`view-toolbar ${type}`}>
      <EuiToolbar.Left>
        {onListGo && (
          <Button
            text="목록으로 돌아가기"
            iconType
            icon="arrow-left"
            onClick={onListGo}
            className="back-list"
          />
        )}
        <ToolbarAction event={props.toolbarButtons} onClick={onToolbarAction} />
      </EuiToolbar.Left>
      {type === 'full' && (
        <EuiToolbar.Right>
          <div className="eui-pagination">
            <Button
              text="다음"
              className="page-action"
              iconType
              icon="chevron-up"
              disabled={onNext === undefined}
              onClick={onNext}
            />
            <Button
              text="이전"
              className="page-action"
              iconType
              icon="chevron-down"
              disabled={onPrev === undefined}
              onClick={onPrev}
            />
          </div>
        </EuiToolbar.Right>
      )}
    </EuiToolbar>
  );
}

export default React.memo(ApprovalContentHeadView);
