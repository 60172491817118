import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../components/data/DataGrid';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import AttendanceSearch, {
  SearchDateProps,
} from '../../../../components/search/AttendanceSearch';
import { ActionEventProps } from '../../../../components/toolbarAction/ToolbarAction';
import { getQueryParams } from '../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../stores/resources';
import ResourceAdminLookupDialog from './ResourceAdminLookupDialog';

function replaceIsApproval(isApproval: number) {
  switch (isApproval) {
    case 0:
      return '승인대기';
    case 1:
      return '예약완료';
    case 2:
      return '반려';
    default:
      return '';
  }
}
function replaceTheme(isApproval: number) {
  switch (isApproval) {
    case 0:
      return 'primary';
    case 1:
      return 'success';
    case 2:
      return 'error';
    default:
      return undefined;
  }
}

function ResourceReservationContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.list,
  );
  const list = useSelector(
    (state: RootState) => state.resource.resources.share.list,
  );
  const totalCount = useSelector(
    (state: RootState) => state.resource.resources.share.totalCount,
  );
  const reservation = useSelector(
    (state: RootState) => state.resource.resources.view,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'setting');
  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const [state, setState] = useState<{
    view: boolean;
    useDirectory: boolean;
  }>({
    view: false,
    useDirectory: false,
  });

  const defaultActions: ActionEventProps[] = [
    {
      code: 'create',
      label: '등록',
      type: 'icon',
      icon: 'plus-circle-fill',
    },
  ];
  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'folderName', label: '폴더명', width: 168, align: 'center' },
    { field: 'itemName', label: '자원명', width: 168, align: 'center' },
    { field: 'subject', label: '제목', minWidth: 250 },
    { field: 'range', label: '사용기간', width: 400, align: 'center' },
    { field: 'useDays', label: '신청일', width: 200, align: 'center' },
    { field: 'type', label: '예약종류', width: 200, align: 'center' },
    { field: 'user', label: '예약자', width: 158, align: 'center' },
    { field: 'status', label: '상태', width: 98, align: 'center' },
  ]);
  const rows: DataGridRowsProps<number> = list.map((a) => {
    const folderName = folders.find(({ id }) => a.folderId === id)?.name ?? '';
    const itemName = items.find(({ id }) => a.itemId === id)?.name ?? '';
    const directoryData = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId: a.employeeId,
    });
    return [
      { type: 'text' as const, value: folderName },
      { type: 'text' as const, value: itemName },
      { type: 'subject' as const, value: a.name, id: a.id },
      // eslint-disable-next-line prettier/prettier
      { type: 'text' as const, value: `${dateTimeFormat(a.startDateTime, 'yyyy-MM-DD HH:mm')} ~ ${dateTimeFormat(a.endDateTime, 'yyyy-MM-DD HH:mm')}` },
      // eslint-disable-next-line prettier/prettier
      { type: 'text' as const, value: dateTimeFormat(a.createAt, 'yyyy-MM-DD HH:mm') },
      { type: 'text' as const, value: a.isRepeat ? '반복 예약' : '일반 예약' },
      {
        type: 'user' as const,
        avatar: directoryData.avatar ?? '',
        icon: directoryData.avatar === '' ? undefined : 'sitemap-fill',
        value: `${directoryData.employeeName} ${directoryData.jobPositionName}`,
        from: directoryData.organizationName,
      },
      // eslint-disable-next-line prettier/prettier
      { type: 'chip' as const, value: replaceIsApproval(a.isApproval), theme: replaceTheme(a.isApproval) },
    ];
  });

  /** 목록 개수 변경. */
  const handleChangeRowLength = (value: number) => {
    queryParams.rowsPerPage = value;
    delete queryParams.pageNo;
    dispatch(
      resourcesActions.adminReservationList({
        search: getQueryParams(queryParams),
        isDirectorySelected: state.useDirectory,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록 노출. */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
    applyType?: string;
    status?: string;
  }) => {
    delete queryParams.pageNo;
    queryParams.searchCode = arg.filter;
    queryParams.searchWord = arg.keyword;
    queryParams.directoryKeyword = arg.directoryKeyword;
    queryParams.startDate = arg.date.start
      ? dateFormat(initialDate(arg.date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = arg.date.end
      ? dateFormat(initialDate(arg.date.end), 'yyyy-MM-DD')
      : undefined;
    dispatch(
      resourcesActions.adminReservationList({
        search: getQueryParams(queryParams),
        isDirectorySelected: !!(arg.keyword !== '' && arg.directoryKeyword),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    ).then(() => {
      setState((prev) => ({
        ...prev,
        useDirectory: !!(arg.keyword !== '' && arg.directoryKeyword),
      }));
    });
  };

  /** 이벤트. */
  const handleClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code, id } = arg;
    /** 예약 등록, */
    if (code === 'create') dispatch(sessionActions.setDialog({ type: code }));

    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = (queryParams.pageNo || 1) - 1;
      if (queryParams.pageNo > 0) {
        if (queryParams.pageNo === 1) {
          delete queryParams.pageNo;
        }
        dispatch(
          resourcesActions.adminReservationList({
            search: getQueryParams(queryParams),
            isDirectorySelected: state.useDirectory,
            route: {
              pathname,
              search: getQueryParams(queryParams),
            },
          }),
        );
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = (queryParams.pageNo || 1) + 1;
      dispatch(
        resourcesActions.adminReservationList({
          search: getQueryParams(queryParams),
          isDirectorySelected: state.useDirectory,
          route: {
            pathname,
            search: getQueryParams(queryParams),
          },
        }),
      );
    }

    /** 예약 보기. */
    if (code === 'subject' && id) {
      queryParams.dialogType = 'view';
      dispatch(
        resourcesActions.reservationView({
          id,
          route: { pathname, search: getQueryParams(queryParams) },
        }),
      );
    }
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    if (dialogType === 'view') {
      if (!reservation) return null;
      const itemName =
        items.find((a) => a.id === reservation.itemId)?.name ?? '';
      return (
        <ResourceAdminLookupDialog
          isDirectorySelected={state.useDirectory}
          pathname={pathname}
          search={search}
          hash=""
          itemId={reservation.itemId}
          itemName={itemName}
          reservationStatus={reservation.isApproval}
          date={{
            start: reservation.startDateTime,
            end: reservation.endDateTime,
          }}
        />
      );
    }
    if (dialogType === 'create') {
      const todayStart = timezoneDate();
      if (todayStart.getMinutes() < 30) todayStart.setMinutes(0);
      else todayStart.setMinutes(30);
      const todayEnd = new Date(todayStart);
      todayEnd.setMinutes(todayStart.getMinutes() + 30);
      return (
        <ResourceAdminLookupDialog
          isDirectorySelected={state.useDirectory}
          pathname={pathname}
          search={search}
          hash=""
          itemId={0}
          itemName=""
          reservationStatus={0}
          date={{
            start: dateFormat(todayStart, 'yyyy-MM-DD HH:mm'),
            end: dateFormat(todayEnd, 'yyyy-MM-DD HH:mm'),
          }}
        />
      );
    }
    return null;
  };

  let start: Date | null = null;
  let end: Date | null = null;
  if (queryParams.startDate) start = timezoneDate(queryParams.startDate);
  if (queryParams.endDate) end = timezoneDate(queryParams.endDate);
  const title = categories.find((a) => a.id === 6003)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceSearch
            directory
            dualSearch
            options={[
              { value: 'folder', label: '폴더명' },
              { value: 'item', label: '자원명' },
              { value: 'subject', label: '제목' },
              { value: 'employee', label: '예약자' },
            ]}
            filter={queryParams.searchCode ?? 'folder'}
            date={{ start, end }}
            keyword={queryParams.searchWord}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          caption={title}
          columns={columns}
          rows={rows}
          pagination={{
            no: queryParams.pageNo || 1,
            total: totalCount,
            row: queryParams.rowsPerPage || 15,
            onChangeRow: handleChangeRowLength,
          }}
          defaultActions={defaultActions}
          onClick={handleClick}
          onChangeColumnVisible={handleChangeColumnVisible}
        />
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default ResourceReservationContainer;
