import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentButtonLoading(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Loading button">
      <Button text="로그인" variant="contained" loading block />
    </ComponentSampleUI.Content>
  );
}

export default ComponentButtonLoading;
