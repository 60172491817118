import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 근태 승인/취소 내역 총 개수. */
async function fatchTotalCount(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
}): Promise<number> {
  try {
    const { startdate, enddate, searchcode, searchword } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/adminconsole/uses/count`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchWord: searchword,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 근태 승인/취소 내역 조회. */
async function fetchList(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  pageno: number;
  rowsperpage: number;
}): Promise<
  {
    companyId: number;
    useId: number;
    useYear: string;
    employeeId: number;
    approvalId: number;
    useType: number; // 근태 코드.
    subject: string;
    status: number;
    startDate: string;
    endDate: string;
    useMinutes: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { startdate, enddate, searchcode, searchword, pageno, rowsperpage } =
      arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/adminconsole/uses`;
    const response = await axios.get(url, {
      headers,
      params: {
        startdate,
        enddate,
        searchcode,
        searchWord: searchword,
        pageno,
        rowsperpage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 근태 상태 취소. */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function cancel(arg: {
  linkedKey: {
    companyId: number; // 근태 신청 회사 키
    id: number; // 근태 신청 아이디
  };
  documentId: number; // 결재 문서 아이디
  linkType: 'CANCEL';
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/adminconsole/use/cancel`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const attendanceCancleStatuslApi = {
  list: fetchList,
  totalCount: fatchTotalCount,
  cancel,
};

export default attendanceCancleStatuslApi;
