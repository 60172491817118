import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Calendar from '@fullcalendar/react';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Tab from '../../../../../components/tab/Tab';
import { getQueryParams, go } from '../../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import CalendarHeadContentContainer from './CalendarHeadContentContainer';
import CalendarBodyContentContainer from './CalendarBodyContentContainer';
import { RootState } from '../../../../../groupware-webapp/app/store';

function CalendarContentContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search } = props;
  const hash = props.hash === '#' ? '' : props.hash;
  const queryParams = getQueryParams(search);
  const calendarRef = useRef<typeof Calendar>(null);
  const display = useSelector((state: RootState) => state.session.display);
  const basic = useSelector(
    (state: RootState) => state.calendar.userPreferences.basic,
  );

  const viewModeOptions = [
    { label: '일간', id: '#daily' },
    { label: '주간', id: '#weekly' },
    { label: '월간', id: '#monthly' },
  ];

  const handleTabClick = (id: string) => {
    go(pathname, search, id);
  };

  const handleDateClick = (ev: React.MouseEvent) => {
    const button = ev.target as HTMLButtonElement;
    if (button.tagName === 'BUTTON') {
      const actionName = (
        button.getAttribute('data-action') ?? 'month'
      ).replace('move-', '');
      const startDate = timezoneDate(queryParams.startDate);
      if (actionName === 'prev') {
        if (hash === '#daily') startDate.setDate(startDate.getDate() - 1);
        else if (hash === '#weekly') startDate.setDate(startDate.getDate() - 7);
        else if (hash === '#monthly')
          startDate.setMonth(startDate.getMonth() - 1);
      } else if (actionName === 'next') {
        if (hash === '#daily') startDate.setDate(startDate.getDate() + 1);
        else if (hash === '#weekly') startDate.setDate(startDate.getDate() + 7);
        else if (hash === '#monthly')
          startDate.setMonth(startDate.getMonth() + 1);
      }
      if (actionName === 'today') delete queryParams.startDate;
      else
        queryParams.startDate = dateFormat(
          initialDate(startDate),
          'yyyy-MM-DD',
        );

      go(pathname, queryParams, hash);
    }
  };

  const renderHead = () => {
    if (hash === '') return null;
    let today = '';
    const startDate = timezoneDate(queryParams.startDate);
    if (hash === '#daily') {
      today =
        display === 'phone'
          ? dateFormat(startDate, 'MM.DD dd')
          : dateFormat(startDate, 'yyyy.MM.DD dddd');
    } else if (hash === '#weekly') {
      if (basic.firstDayOfWeek === 'SUN') {
        if (startDate.getDay() !== 0)
          startDate.setDate(startDate.getDate() - startDate.getDay());
      } else if (startDate.getDay() !== 1) {
        if (startDate.getDay() === 0)
          startDate.setDate(startDate.getDate() - 6);
        else startDate.setDate(startDate.getDate() - (startDate.getDay() - 1));
      }
      const endToday = new Date(startDate);
      endToday.setDate(endToday.getDate() + 6);
      today = `${dateFormat(
        startDate,
        display === 'phone' ? 'M.DD' : 'yyyy년 MM월 DD일',
      )} - ${dateFormat(endToday, display === 'phone' ? 'M.DD' : 'MM월 DD일')}`;
    } else {
      today = dateFormat(startDate, 'MM월 yyyy');
    }

    return (
      <CalendarHeadContentContainer
        today={today}
        onDateClick={handleDateClick}
      />
    );
  };

  const renderBody = () => {
    return (
      <CalendarBodyContentContainer
        calendarRef={calendarRef}
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>일정목록</EuiHeader.Title>
        <Tab>
          {viewModeOptions.map(({ id, label }) => (
            <Tab.Item
              key={id}
              label={label}
              selected={id === hash}
              onClick={() => handleTabClick(id)}
            />
          ))}
        </Tab>
      </EuiHeader>
      <EuiBody>
        {renderHead()}
        {renderBody()}
      </EuiBody>
    </>
  );
}

export default CalendarContentContainer;
