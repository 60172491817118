import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Chip from '../../../../../components/chip/Chip';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import PostSubject from '../../../../../components/post/PostSubject';
import UserInfo from '../../../../../components/user/UserInfo';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';

type Status = 'proceeding' | 'done' | 'return';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function statusLabel(type: string) {
  switch (type) {
    case 'proceeding':
      return '진행중';
    case 'return':
      return '반려';
    case 'cancel':
      return '취소';
    case 'done':
      return '완료';
    default:
      return '';
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function statusTheme(type: string) {
  switch (type) {
    case 'proceeding':
      return 'primary';
    case 'return':
      return 'error';
    case 'done':
      return 'success';
    case 'cancel':
      return 'cancel';
    default:
      return undefined;
  }
}

function leavesStatusLabel(type: string) {
  switch (type) {
    case 'success':
      return '사용가능';
    case 'error':
      return '소멸';
    default:
      return '';
  }
}
function leavesStatusTheme(type: string) {
  switch (type) {
    case 'success':
      return 'success';
    case 'error':
      return 'error';
    default:
      return undefined;
  }
}

/**
 * @property organizationStatus 부서별근태현황 메뉴 구별.
 * @property attendanceCode 부서별근태현황 중 월별근태현황인 경우.
 * @property id 아이디.
 * @property approvalId 전자결재 문서 아이디.
 * @property columns 컬럼 배열.
 * @property item 항목.
 * @property onCheckedChange 체크 변경 이벤트.
 * @property onClick 클릭 이벤트.
 * @property onStatusClick 부서별 근태현황 사용 근태 클릭 이벤트.
 */
type Props = {
  organizationStatus?: boolean; // 부서별근태현황일 경우.
  attendanceCode?: boolean; // 부서별근태현황 - 월별근태현황
  substitute?: boolean; // 대휴 모듈일 경우.
  dayOff?: boolean; // 연차 모듈일 경우.
  id?: number;
  approvalId?: number;
  selected?: boolean;
  columns: Column<PostListItemType>[];
  item: Record<string, Status | User | boolean | string | number | undefined>;
  onCheckedChange?(id: number, checked: boolean): void;
  onClick?(id: number): void;
  onClickCount?(id: number): void;
  onStatusClick?(name: string, month: string, id?: number): void;
};

function AttendanceContentBodyListItem(dumy: Props): JSX.Element {
  // console.log(`-------${AttendanceContentBodyListItem.name}.render:props`, dumy);

  const display = useSelector((state: RootState) => state.session.display);
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    id: dumy.id || 0,
    approvalId: dumy.approvalId || 0,
    selected: dumy.selected || false,
    columns: dumy.columns,
    item: dumy.item,
    onCheckedChange: dumy.onCheckedChange || emptyFunction,
    onClick: dumy.onClick || emptyFunction,
    onClickCount: dumy.onClickCount || emptyFunction,
  };

  const handleCheckedChange = () => {
    const { id, item, onCheckedChange } = props;
    onCheckedChange(id, !item.checked);
  };

  const handleClick = () => {
    const { approvalId, onClick } = props;
    if (timerRef.current) clearTimeout(timerRef.current);
    onClick(approvalId);
  };

  const handleClickCount = () => {
    const { id, onClickCount } = props;
    onClickCount(id);
  };

  const handleStatusClick = (name: string, month: string) => {
    if (organizationStatus && props.onStatusClick)
      props.onStatusClick(name, month, props.id);
  };

  const {
    selected,
    columns,
    item,
    organizationStatus, // 근태관리 - 연차, 대휴, 기간별, 월별
    attendanceCode, // 근태관리 중 기간별현황인 경우 true
    substitute,
    dayOff,
  } = props;

  return (
    <>
      <PostList.Item
        selected={selected}
        onClick={handleClick}
        organizationStatus={organizationStatus}
      >
        {columns.map(({ name, text, type }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;
          if (name === 'checked' && typeof value === 'boolean') {
            children = (
              <Checkbox checked={value} onChange={handleCheckedChange} />
            );
          } else if (type === 'post') {
            const subject = value as string;
            // 근태관리 리스트
            if (organizationStatus) {
              // 컬럼값이 합계인 경우
              if (name === 'total') {
                children =
                  display === 'phone' ? (
                    <span>
                      <PostList.Value value="합계" />
                      <span style={{ fontWeight: 700, paddingLeft: '20px' }}>
                        {subject}
                      </span>
                    </span>
                  ) : (
                    <PostSubject title={subject} />
                  );
              }
              // 컬럼값이 합계가 아닌 경우
              else if (subject === '0일' || subject === '0분') {
                children =
                  display === 'phone' ? (
                    <span>
                      <PostList.Value value={`${text}`} />
                      <span style={{ fontWeight: 700, paddingLeft: '20px' }}>
                        {subject}
                      </span>
                    </span>
                  ) : (
                    <PostSubject title={`${subject}`} />
                  );
              }
              // 월별 근태현황
              else if (attendanceCode) {
                const code = item.attendanceCode as string;
                if (display === 'phone')
                  children = (
                    <span>
                      <PostList.Value value={`${text}`} />
                      <span
                        style={{ fontWeight: 700, paddingLeft: '20px' }}
                        onClick={() => handleStatusClick(code, name)}
                      >
                        {subject}
                      </span>
                    </span>
                  );
                else
                  children = (
                    <PostSubject
                      title={subject}
                      onClick={() => handleStatusClick(code, name)}
                    />
                  );
              } else if (substitute && name === 'rest') {
                if (display === 'phone')
                  children = (
                    <span>
                      <PostList.Value value={`${text}`} />
                      <span style={{ fontWeight: 700, paddingLeft: '20px' }}>
                        {subject}
                      </span>
                    </span>
                  );
                else children = <PostSubject title={subject} />;
              } else if (dayOff && name !== 'use') {
                if (display === 'phone')
                  children = (
                    <span>
                      <PostList.Value value={`${text}`} />
                      <span style={{ fontWeight: 700, paddingLeft: '20px' }}>
                        {subject}
                      </span>
                    </span>
                  );
                else children = <PostSubject title={subject} />;
              } else if (display === 'phone') {
                children = (
                  <span>
                    <PostList.Value value={`${text}`} />
                    <span
                      style={{ fontWeight: 700, paddingLeft: '20px' }}
                      onClick={() => handleStatusClick(name, '')}
                    >
                      {subject}
                    </span>
                  </span>
                );
              } else {
                children = (
                  <PostSubject
                    title={subject}
                    onClick={() => handleStatusClick(name, '')}
                  />
                );
              }
            } else
              children = (
                <PostSubject title={subject} onClickCount={handleClickCount} />
              );
          } else if (type === 'user') {
            const { avatar, name: username, organization } = value as User;
            children = (
              <UserInfo
                avatar={username === '' ? undefined : avatar}
                name={username === '' ? organization : username}
                from={username === '' ? undefined : organization}
                icon={username === '' ? 'sitemap-fill' : undefined}
              />
            );
          } else if (typeof value === 'string' || typeof value === 'number') {
            children = <PostList.Value value={value} />;
            if (name === 'status' && typeof value === 'string') {
              const data = statusLabel(value);
              const theme = statusTheme(value);
              children = (
                <Chip
                  className="cell-tag"
                  label={data}
                  theme={theme}
                  size="xs"
                />
              );
            }
            if (name === 'leavesStatus' && typeof value === 'string') {
              const data = leavesStatusLabel(value);
              const theme = leavesStatusTheme(value);
              children = (
                <Chip
                  className="cell-tag"
                  label={data}
                  theme={theme}
                  size="xs"
                />
              );
            }
            if (
              name === 'attendanceCode' &&
              typeof value === 'string' &&
              organizationStatus &&
              display === 'phone'
            ) {
              children = <Chip className="cell-tag" label={value} size="sm" />;
            }
          }

          let style: React.CSSProperties | undefined;
          if (type === 'post' && organizationStatus) {
            if (display === 'phone') style = { margin: '10px 0' };
            else
              style = {
                minWidth: '110px',
                width: '7%',
                justifyContent: 'center',
              };
          }
          if (
            type === 'leavesStatus' &&
            organizationStatus &&
            display === 'phone'
          )
            style = { margin: '8px 0 0 0' };
          if (
            name === 'attendanceCode' &&
            organizationStatus &&
            display === 'phone'
          )
            style = { margin: '8px 0 5px 0', order: -2 };
          return (
            <PostList.Cell
              key={name}
              type={type}
              align={
                type === 'status' || name === 'useMinutes'
                  ? 'center'
                  : undefined
              }
              style={style}
            >
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
    </>
  );
}

export default AttendanceContentBodyListItem;
