import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import preferencesApi, {
  DocumentPreferencesBasic,
} from '../../apis/document/v1/preferences';

const name = 'document/preferences';

interface State {
  basic: DocumentPreferencesBasic;
  administrators: { employeeId: number; updateAt: string }[]; // 관리자
}

const initialState: State = {
  basic: {
    companyId: 0,
    newPostPeriod: 0,
    defaultFolderId: 0,
    allocableCapacity: 0,
    capacityInUse: 0,
    capacityLimit: 0,
    numberOfAttachments: 0,
    attachmentsCapacity: 0,

    updateAt: '1000-01-01T00:00:00',
  },
  administrators: [],
};

/** 사용자 페이지에서 관리자 환경설정 조회 */
const findPreferencesForUser = createAsyncThunk(
  `${name}/findPreferencesForUser`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      const response = await preferencesApi.findBasicUser();
      return response;
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 환경설정 조회 */
const findPreferences = createAsyncThunk(
  `${name}/findPreferences`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      const response = await preferencesApi.findBasic();
      return response;
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 조회 */
const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      const response = await preferencesApi.find('DOCUMENT_ADMIN');
      return response;
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 추가. */
const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue }) => {
    try {
      const data = await preferencesApi.append(
        arg.map((a) => ({ ...a, role: 'DOCUMENT_ADMIN' as const })),
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 삭제. */
const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue },
  ) => {
    try {
      const data = await preferencesApi.remove(
        arg.map((a) => ({ ...a, role: 'DOCUMENT_ADMIN' as const })),
      );
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 환경설정 저장. */
const updatePreferences = createAsyncThunk(
  `${name}/updatePreferences`,
  async (
    arg: {
      defaultFolderId: number;
      newPostPeriod: number;
      capacityLimit: number;
      numberOfAttachments: number;
      attachmentsCapacity: number;
      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const data = await preferencesApi.updateBasic(arg);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const preferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findPreferences.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(findPreferencesForUser.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(updatePreferences.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      });
  },
});

export default preferencesSlice.reducer;

export const preferencesActions = {
  findPreferencesForUser,

  findPreferences,
  updatePreferences,

  findAdministrators,
  appendAdministrators,
  removeAdministrators,
};
