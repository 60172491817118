import React from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import Tab from '../../../../../components/tab/Tab';
import ApprovalSettingDocumentNoContainer from './ApprovalSettingDocumentNoContainer';
import ApprovalSettingViewer from './ApprovalSettingViewer';
import ApprovalSettingPreferencesContainer from './ApprovalSettingPreferencesContainer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { preferencesActions } from '../../../../stores/approval/preferences';

function ApprovalSettingContainer(props: {
  pathname: string;
  hash: string;
}): JSX.Element {
  // console.log(`ApprovalSettingContainer(props)`, props);

  const dispatch = useAppDispatch();
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6001)?.name;

  const handleMenuChange = (submenu?: string) => {
    const { pathname, hash } = props;
    if (submenu === hash) return;

    if (submenu === undefined) dispatch(sessionActions.hash(submenu));

    if (submenu === '#documentno') {
      const route = { pathname, hash: submenu };
      dispatch(preferencesActions.findDocumentNo({ route }));
    }
  };

  /** 콘텐트 렌더링 */
  const renderContent = () => {
    const { hash } = props;
    if (hash === '#documentno') return <ApprovalSettingDocumentNoContainer />;
    if (hash === '#viewer') return <ApprovalSettingViewer />;

    return <ApprovalSettingPreferencesContainer />;
  };

  const { hash } = props;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <Tab>
          <Tab.Item
            label="기본설정"
            selected={hash === ''}
            onClick={() => handleMenuChange()}
          />
          <Tab.Item
            label="문서번호"
            selected={hash === '#documentno'}
            onClick={() => handleMenuChange('#documentno')}
          />
          {/*
          // TODO 기능 구현 후 활성화.
          <Tab.Item label="조회자" selected={hash === '#viewer'} onClick={() => handleMenuChange('#viewer')} />
          */}
        </Tab>
      </EuiHeader>
      <EuiBody>{renderContent()}</EuiBody>
    </>
  );
}

export default ApprovalSettingContainer;
