import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentButton(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Text Buttons">
      <Button text="Text" />
      <Button text="Disabled" disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentButton;
