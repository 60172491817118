import React from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { archiveFolderActions } from '../../../../stores/approval/archive';
import {
  b62,
  getParentItems,
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import ApprovalArchiveSettingsFolderList from './ApprovalArchiveSettingsFolderList';
import ApprovalArchiveSettingsFolderView from './ApprovalArchiveSettingsFolderView';
import ApprovalArchiveSettingsFolderEditDrawer from './ApprovalArchiveSettingsFolderEditDrawer';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import AccessDenied from '../../../../../components/error/AccessDenied';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';

function ApprovalArchiveSettingsContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${ApprovalArchiveSettingsContainer.name}(props)`, props);

  const map = `/approval/archivesettings`;
  const { id: selectedId } = getPathParams<{ id?: number }>(
    `${map}/:id$base62`,
    props.pathname,
  );

  const dispatch = useAppDispatch();

  /** 세션 사용자 소속 조직 항목 배열. */
  const affiliatedOrganizations = useSelector(
    (state: RootState) => state.session.principal.affiliatedOrganizations,
  );

  const organizationId = useSelector(
    (state: RootState) => state.approval2.archive.currentOrganizationId,
  );

  const folders = useSelector(
    (state: RootState) => state.approval2.archive.folders.data,
  );

  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'default');
  // 고정 폴더 이름
  const title = categories.find((a) => a.id === 5004)?.name;

  const items = folders
    .filter(({ seq }) => seq !== 0)
    .map((a) => ({ ...a, text: a.name, icon: 'folder' as const }))
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  const manager = affiliatedOrganizations.find(
    ({ id }) => id === organizationId,
  )?.manager;

  const handleListItemClick = (arg: { id: number }) => {
    // console.log(`handleListItemClick(arg)`, arg);
    dispatch(sessionActions.setRoute({ pathname: `${map}/${b62(arg.id)}` }));
  };

  const handleListAction = (arg: { code: string; event: React.MouseEvent }) => {
    // console.log(`handleListAction(arg)`, arg);
    const { code } = arg;
    // 기록물철 폴더 생성
    if (code === 'create') dispatch(sessionActions.setDrawer({ mode: code }));
  };

  const handleViewClose = () => {
    // console.log(`handleViewClose()`);
    dispatch(sessionActions.setRoute({ pathname: map }));
  };

  const handleViewAction = (arg: { code: string; event: React.MouseEvent }) => {
    // console.log(`handleViewAction(arg)`, arg);
    const { code } = arg;
    // 기록물철 폴더 수정
    if (code === 'update') dispatch(sessionActions.setDrawer({ mode: code }));
    // 기록물철 순서 변경
    if (code === 'sequencechange')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 기록물철 폴더 삭제
    if (code === 'delete') {
      if (folders.find((a) => a.parentId === selectedId) !== undefined) {
        dispatch(sessionActions.error('하위 폴더가 있어 삭제할 수 없습니다.'));
        return;
      }
      dispatch(sessionActions.setDialog({ mode: code }));
    }
  };

  const handleSequenceChange = (arg: { id: number; updateAt: string }[]) => {
    // console.log(`handleSequenceChange(arg)`, arg);
    const data = arg.map(({ id, updateAt }, index) => ({
      id,
      seq: index + 1,
      updateAt,
    }));
    const { pathname } = props;
    dispatch(archiveFolderActions.change({ data, route: { pathname } }));
  };

  const handleCreate = (arg: {
    parentId: number;
    name: string;
    description: string;
  }) => {
    // console.log(`handleCreate(arg)`, arg);
    const { parentId, name, description } = arg;

    dispatch(
      archiveFolderActions.append({
        data: {
          organizationId,
          parentId,
          name,
          description,
        },
        route: { pathname: `${map}` },
      }),
    );
  };

  const handleUpdate = (arg: {
    id: number;
    parentId: number;
    name: string;
    description: string;
    updateAt: string;
  }) => {
    // console.log(`handleUpdate(arg)`, arg);
    const { pathname } = props;
    dispatch(
      archiveFolderActions.modify({
        data: { ...arg, organizationId },
        route: { pathname },
      }),
    );
  };

  const handleDelete = (arg: {
    organizationId: number;
    id: number;
    updateAt: string;
  }) => {
    // console.log(`handleDelete(arg)`, arg);
    dispatch(
      archiveFolderActions.remove({ data: arg, route: { pathname: `${map}` } }),
    );
  };

  /** 드로워 렌터링 */
  const renderDrawer = () => {
    const { drawerType, drawerMode } = getQueryParams(props.search);

    // 기록물철 순서 변경
    if (drawerType === 'sequencechange') {
      const item = items.find(({ id }) => id === selectedId);

      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (item === undefined) return null;

      /** 순서 변경 항목. */
      const sequenceChangeItems = items
        .filter(({ parentId }) => parentId === item.parentId)
        .map(({ id, text: label, updateAt }) => ({ id, label, updateAt }));

      return (
        <ChangeOrder
          title="순서변경"
          list={sequenceChangeItems}
          onChange={handleSequenceChange}
          onClose={() => dispatch(sessionActions.setDrawer())}
        />
      );
    }
    // 기록물철 폴더 생성
    if (drawerMode === 'create') {
      return (
        <ApprovalArchiveSettingsFolderEditDrawer
          folders={[
            { id: 0, parentId: -1, name: '기록물', icon: 'folder' as const },
            ...items,
          ]}
          onSave={handleCreate}
          onClose={() => dispatch(sessionActions.setDrawer())}
        />
      );
    }
    // 기록물철 폴더 수정
    if (drawerMode === 'update') {
      const item = items.find(({ id }) => id === selectedId);

      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (item === undefined) return null;

      return (
        <ApprovalArchiveSettingsFolderEditDrawer
          id={item.id}
          parentId={item.parentId}
          name={item.name}
          description={item.description}
          updateAt={item.updateAt}
          folders={[
            { id: 0, parentId: -1, name: '기록물', icon: 'folder' as const },
            ...items,
          ]}
          onSave={handleUpdate}
          onClose={() => dispatch(sessionActions.setDrawer())}
        />
      );
    }

    return null;
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    const { dialogMode } = getQueryParams(props.search);
    if (dialogMode === 'delete') {
      const item = items.find(({ id }) => id === selectedId);

      // TODO '폴더가 이동되었거나 삭제되었습니다.'
      if (item === undefined) return null;

      // TODO '하위 폴더가 있어 삭제할 수 없습니다.'
      if (item === undefined) return null;

      const { id, updateAt } = item;
      return (
        <DeleteConfirmation
          onSubmit={() => handleDelete({ organizationId, id, updateAt })}
          onCancel={() => dispatch(sessionActions.setDialog())}
        >
          <strong>&apos;{item.name}&apos;</strong> 폴더를 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    return null;
  };

  if (manager === false) return <AccessDenied />;

  const item = items.find(({ id }) => id === selectedId);
  const parentNames = item
    ? getParentItems(items, item.parentId).map(({ text }) => text)
    : [];

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ApprovalArchiveSettingsFolderList
              selectedId={selectedId}
              items={items}
              onItemClick={handleListItemClick}
              onAction={handleListAction}
            />
          </EuiSetting.Left>
          {item ? (
            <EuiSetting.Right onClose={handleViewClose}>
              <ApprovalArchiveSettingsFolderView
                parentNames={parentNames}
                name={item.name}
                description={item.description}
                onAction={handleViewAction}
              />
            </EuiSetting.Right>
          ) : (
            <SplitUnselected label="폴더를 선택하세요" />
          )}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default ApprovalArchiveSettingsContainer;
