/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { DocumentData } from '../document';
import { DocumentItem } from '../permission';
import { AdvancedSearchParams } from '../../../../../groupware-common/utils';

/** 기록물 문서 조회.
 * @param organizationId 조직 아이디.
 * @param folderId 폴더 아이디.
 * @param id 문서 아이디.
 * @returns 문서 객체. (결재 문서 조회와 반환값 같음)
 */
async function data(arg: { folderId: number; documentId: number }) {
  const { folderId, documentId } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/archive/folders/${folderId}/documents/${documentId}`;
    const response = await axios.get<DocumentData>(url, {
      headers,
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 기록물 폴더 문서의 이전 또는 다음 문서 아이디를 조회 합니다.
 * @param type 이전 또는 다음.
 * @param organizationId 조직 아이디.
 * @param folderId 폴더 아이디.
 * @param id 문서 아이디.
 * @param searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param searchWord 조회 단어.
 * @returns 이전 또는 다음 문서 아이디 객체 또는 undefined.
 */
async function readPrevIdOrNextId(arg: {
  type: 'PREV' | 'NEXT';
  documentId: number;
  folderId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ companyId: number; id: number } | undefined> {
  const { type, folderId, documentId, advancedSearch } = arg;

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/archive/folders/${folderId}/documents/${documentId}/${type}`;
    const response = await axios.get(url, {
      headers,
      params: {
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 기록물 폴더 문서의 이전 아이디를 조회 합니다.
 * @param organizationId 조직 아이디.
 * @param folderId 폴더 아이디.
 * @param id 문서 아이디.
 * @param searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param searchWord 조회 단어.
 * @returns 이전 문서 아이디 객체 또는 undefined.
 */
async function prevId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ companyId: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'PREV' });
}

/**
 * 기록물 폴더 문서의 다음 문서 아이디를 조회 합니다.
 * @param organizationId 조직 아이디.
 * @param folderId 폴더 아이디.
 * @param id 문서 아이디.
 * @param searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param searchWord 조회 단어.
 * @returns 다음 문서 아이디 객체 또는 undefined.
 */
async function nextId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ companyId: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'NEXT' });
}

/** 기록물 문서 이동.
 * @param organizationId 조직 아이디.
 * @param sourceFolderId 소스 폴더 아이디.
 * @param targetFolderId 대상 폴더 아이디.
 * @param id 문서 아이디.
 * @param updateAt 수정 날짜.
 * @returns 폴더 문서 항목 데이터 전송 객체.
 */
async function move(
  arg: {
    organizationId: number;
    sourceFolderId: number;
    targetFolderId: number;
    id: number;
    updateAt: string;
  }[],
) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/document/move`;
    const response = await axios.put<
      {
        folderId: number; // 폴더 아이디.
        id: number; // 문서 아이디.
        updateAt: string; // 수정 날짜.
      }[]
    >(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서 목록을 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function documents(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<{
  count?: number; // 문서 총 갯수.
  list: DocumentItem[];
}> {
  try {
    const { folderId, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/archive/folders/${folderId}/documents`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서 총 개수를 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function totalCount(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}) {
  try {
    const { folderId, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/archive/folders/${folderId}/documents/count`;
    const response = await axios.get<{ count: number }>(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data.count;
  } catch (ex) {
    throw apiError(ex);
  }
}

const archiveDocumentApi = {
  data,
  prevId,
  nextId,
  move,

  documents,
  totalCount,
};

export default archiveDocumentApi;
