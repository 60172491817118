/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { Language } from '../../../../../groupware-common/types';
import { getNameKey as gnk } from '../../../../../groupware-common/utils';
import {
  EntityNameKeyable,
  apiError,
  getApiConfig,
  EntityKeyable,
} from '../../../../../groupware-common/apis/common/v1';

const namespace = 'directory';

// prettier-ignore
const dummy = [
  {companyId: 5001,id: 20001,parentId: 5001, organizationId: 5001, nameId: 20001,organizationNameId: 10001,jobPositionId: 1,jobDutyId: 0,jobRank: 0,chargeWork: '담당업무',privacyTel: '개인전화',privacyEmail: '개인메일',privacyBirth: '생일',privacyMemorialday: '기념일',privacyAddress: '자택주소',privacyGmail: '구글계정',enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '강명호',avatar: '/images/faces/faces_1.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test1',loginPassword: 'test1',employeeNo: '20210806-001',companyEmail: '',},
  {companyId: 5001,id: 20002,parentId: 5001, organizationId: 5001, nameId: 20002,organizationNameId: 10001,jobPositionId: 2,jobDutyId: 0,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '이성계',avatar: '/images/faces/faces_2.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test2',loginPassword: 'test2',employeeNo: '20210806-002',companyEmail: '',},
  {companyId: 5001,id: 20003,parentId: 10001,organizationId: 10001,nameId: 20003,organizationNameId: 10002,jobPositionId: 3,jobDutyId: 0,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '이상윤',avatar: '/images/faces/faces_3.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test3',loginPassword: 'test3',employeeNo: '20210806-003',},
  {companyId: 5001,id: 20004,parentId: 10001,organizationId: 10001,nameId: 20004,organizationNameId: 10003,jobPositionId: 3,jobDutyId: 0,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '정영도',avatar: '/images/faces/faces_4.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test4',loginPassword: 'test4',employeeNo: '20210806-004',},
  {companyId: 5001,id: 20005,parentId: 10002,organizationId: 10002,nameId: 20005,organizationNameId: 10005,jobPositionId: 4,jobDutyId: 0,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '최예정',avatar: '/images/faces/faces_5.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test5',loginPassword: 'test5',employeeNo: '20210806-005',},
  {companyId: 5001,id: 20006,parentId: 10003,organizationId: 10003,nameId: 20006,organizationNameId: 10007,jobPositionId: 4,jobDutyId: 3,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '김우성',avatar: '/images/faces/faces_6.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test6',loginPassword: 'test6',employeeNo: '20210806-006',},
  {companyId: 5001,id: 20007,parentId: 10003,organizationId: 10003,nameId: 20007,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '라용익',avatar: '/images/faces/faces_7.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test7',loginPassword: 'test7',employeeNo: '20210806-007',},
  {companyId: 5001,id: 20008,parentId: 10003,organizationId: 10003,nameId: 20008,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '이지연',avatar: '/images/faces/faces_8.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test8',loginPassword: 'test8',employeeNo: '20210806-008',},
  {companyId: 5001,id: 20009,parentId: 10003,organizationId: 10003,nameId: 20009,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '김태현',avatar: '/images/faces/faces_9.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test9',loginPassword: 'test9',employeeNo: '20210806-009',},
  {companyId: 5001,id: 20010,parentId: 10003,organizationId: 10003,nameId: 20010,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '장미화',avatar: '/images/faces/faces_10.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test10',loginPassword: 'test10',employeeNo: '20210806-010',},
  {companyId: 5001,id: 20011,parentId: 10003,organizationId: 10003,nameId: 20011,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '강병주',avatar: '/images/faces/faces_11.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test11',loginPassword: 'test11',employeeNo: '20210806-011',},
  {companyId: 5001,id: 20012,parentId: 10004,organizationId: 10004,nameId: 20012,organizationNameId: 10004,jobPositionId: 3,jobDutyId: 3,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '안창재',avatar: '/images/faces/faces_12.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test12',loginPassword: 'test12',employeeNo: '20210806-012',},
  {companyId: 5001,id: 20013,parentId: 10004,organizationId: 10004,nameId: 20013,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '송영준',avatar: '/images/faces/faces_13.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test13',loginPassword: 'test13',employeeNo: '20210806-013',},
  {companyId: 5001,id: 20014,parentId: 10004,organizationId: 10004,nameId: 20014,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '조영식',avatar: '/images/faces/faces_14.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test14',loginPassword: 'test14',employeeNo: '20210806-014',},
  {companyId: 5001,id: 20015,parentId: 10004,organizationId: 10004,nameId: 20015,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '남인호',avatar: '/images/faces/faces_15.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test15',loginPassword: 'test15',employeeNo: '20210806-015',},
  {companyId: 5001,id: 20016,parentId: 10004,organizationId: 10004,nameId: 20016,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '김도희',avatar: '/images/faces/faces_16.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test16',loginPassword: 'test16',employeeNo: '20210806-016',},
  {companyId: 5001,id: 20017,parentId: 10004,organizationId: 10004,nameId: 20017,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '이종엽',avatar: '/images/faces/faces_17.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test17',loginPassword: 'test17',employeeNo: '20210806-017',},
  {companyId: 5001,id: 20018,parentId: 10005,organizationId: 10005,nameId: 20018,organizationNameId: 10004,jobPositionId: 3,jobDutyId: 3,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '서종주',avatar: '/images/faces/faces_18.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test18',loginPassword: 'test18',employeeNo: '20210806-018',},
  {companyId: 5001,id: 20019,parentId: 10005,organizationId: 10005,nameId: 20019,organizationNameId: 10004,jobPositionId: 3,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '이준호',avatar: '/images/faces/faces_19.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test19',loginPassword: 'test19',employeeNo: '20210806-019',},
  {companyId: 5001,id: 20020,parentId: 10005,organizationId: 10005,nameId: 20020,organizationNameId: 10004,jobPositionId: 3,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '김승태',avatar: '/images/faces/faces_20.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test20',loginPassword: 'test20',employeeNo: '20210806-020',},
  {companyId: 5001,id: 20021,parentId: 10005,organizationId: 10005,nameId: 20021,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '성민기',avatar: '/images/faces/faces_21.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test21',loginPassword: 'test21',employeeNo: '20210806-021',},
  {companyId: 5001,id: 20022,parentId: 10005,organizationId: 10005,nameId: 20022,organizationNameId: 10004,jobPositionId: 4,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '양광훈',avatar: '/images/faces/faces_22.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test22',loginPassword: 'test22',employeeNo: '20210806-022',},
  {companyId: 5001,id: 20023,parentId: 10005,organizationId: 10005,nameId: 20023,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '윤호영',avatar: '/images/faces/faces_23.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test23',loginPassword: 'test23',employeeNo: '20210806-023',},
  {companyId: 5001,id: 20024,parentId: 10005,organizationId: 10005,nameId: 20024,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '이진규',avatar: '/images/faces/faces_24.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test24',loginPassword: 'test24',employeeNo: '20210806-024',},
  {companyId: 5001,id: 20025,parentId: 10005,organizationId: 10005,nameId: 20025,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '채벼리',avatar: '/images/faces/faces_25.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test25',loginPassword: 'test25',employeeNo: '20210806-025',},
  {companyId: 5001,id: 20026,parentId: 10005,organizationId: 10005,nameId: 20026,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '송규성',avatar: '/images/faces/faces_26.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test26',loginPassword: 'test26',employeeNo: '20210806-026',},
  {companyId: 5001,id: 20027,parentId: 10005,organizationId: 10005,nameId: 20027,organizationNameId: 10004,jobPositionId: 5,jobDutyId: 4,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '임예원',avatar: '/images/faces/faces_27.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test27',loginPassword: 'test27',employeeNo: '20210806-027',},
  {companyId: 5001,id: 20028,parentId: 10007,organizationId: 10007,nameId: 20028,organizationNameId: 10004,jobPositionId: 3,jobDutyId: 0,jobRank: 0,enterDate: '2020-01-01',leaveDate: '9999-12-31',description: '김승찬',avatar: '/images/faces/faces_28.jpg',updateAt: '1000-01-01T00:00:00.000001',loginId: 'test28',loginPassword: 'test28',employeeNo: '20210806-028',},
  ];

/**
 * 직원 이름 키 값을 반환.
 * @param companyId 회사 아이디.
 * @param nameId 이름 아이디.
 * @param includeNs 네임스페이스 포함 여부.
 * @return 직원 이름 키
 */
function getNameKey(
  companyId: number,
  nameId: number,
  includeNs = true,
): string {
  return gnk(companyId, nameId, 'employee', includeNs ? namespace : '');
}

async function findLastUpdateAt() {
  /*
  const url = `https://localhost/${param1}/${param2}`
  const { data } = await axios.get<Board[]>(url)
  return data
  */
  let updateAt = '';
  dummy.forEach((x) => {
    if (updateAt < x.updateAt) updateAt = x.updateAt;
  });
  return updateAt;
}

async function findList(): Promise<
  {
    companyId: number;
    id: number;
    email: string;
    no: string;
    nameId: number;
    representativeOrganizationId: number;
    jobPositionId: number;
    jobRank: number;
    enterDate: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/employee/all`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface EmployeeView {
  companyId: number;
  id: number;
  email: string;
  no: string;
  status: number;
  nameId: number;
  representativeOrganizationId: number;
  jobPositionId: number;
  jobRank: number;
  enterDate: string;
  leaveDate: string;
  companyPhoneNo: string;
  extensionPhoneNo: string;
  mobilePhoneNo: string;
  task: string;
  personalEmail: string;
  birthday: string;
  lunarBirthdayUse: boolean;
  postalCode: string;
  address: string;
  isUserAvatar: boolean; // 사용자 이미지 사용 유무 확인.
  updateAt: string;
}

async function findView(id: number) {
  // return data.find((x) => x.id === id);
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/employee?id=${id}`;
    const response = await axios.get<EmployeeView>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface EmployeeCreate {
  email: string;
  no: string;
  password: string;
  names: { code: Language; value: string }[];
  representativeOrganizationId: number;
  jobPositionId: number;
  jobRank: number;
  enterDate: string; // yyyy-MM-dd
  leaveDate: string; // yyyy-MM-dd
  companyPhoneNo: string;
  extensionPhoneNo: string;
  mobilePhoneNo: string;
  task: string;
  personalEmail: string;
  birthday: string; // yyyy-MM-dd
  lunarBirthdayUse: boolean;
  postalCode: string;
  address: string;
  avatar?: string;
  affiliatedOrganizations: { id: number; jobDutyId: number }[];
  accountLinkageId?: string;
}

async function create(arg: EmployeeCreate): Promise<EntityNameKeyable> {
  // return { companyId: 0, id: 0, nameId: 0, updateAt: '' };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/employee`;
    const response = await axios.post<EntityNameKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

interface EmployeeUpdate {
  id: number;
  no?: string;
  names?: { code: Language; value: string }[];
  organizationId?: number;
  jobPositionId?: number;
  jobRank?: number;
  enterDate?: string; // yyyy-MM-dd
  leaveDate?: string; // yyyy-MM-dd
  companyPhoneNo?: string;
  extensionPhoneNo?: string;
  mobilePhoneNo?: string;
  task?: string;
  personalEmail?: string;
  birthday?: string; // yyyy-MM-dd
  lunarBirthdayUse?: boolean;
  postalCode?: string;
  address?: string;
  avatar?: string;
  updateAt: string;
  affiliatedOrganizations?: {
    id: number;
    jobDutyId: number;
    lookupUpdateAt?: string;
    lookupDeleteAt?: string;
  }[];
  accountLinkageId?: string;
}

async function update(arg: EmployeeUpdate) {
  // console.log('update(arg: EmployeeUpdate)', arg);
  // return { companyId: 0, id: arg.id, nameId: 0,, updateAt: arg.updateAt };
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/employee`;
    const response = await axios.put<EntityNameKeyable>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function remove(id: number, updateAt: string) {
  // return { companyId: 0, id, updateAt };
  try {
    const { host, headers } = getApiConfig();
    const data = { id, updateAt };
    const url = `${host}/api/directory/v1/employee`;
    const response = await axios.delete<EntityKeyable>(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const employeeApi = {
  namespace,
  getNameKey,
  findLastUpdateAt,
  findList,
  findView,
  create,
  update,
  delete: remove,
};

export default employeeApi;
