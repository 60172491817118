import React, { useEffect, useState } from 'react';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Button from '../../../../../components/button/Button';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import Search from '../../../../../components/search/Search';

function BoardFormList(props: {
  pathname: string;
  scroll: React.RefObject<HTMLDivElement>;
  title: string;
  items: {
    checked: boolean;
    id: number;
    category: string;
    subject: string;
    createAt: string;
    updateAt: string;
    status: boolean;
  }[];
  pagination: {
    no: number;
    total: number;
    row: number;
    onChangeRow(value: number): void;
  };
  onSearch(arg: { keyword: string; filter?: string }): void;
  onChangeChecked(arg: { id: number | 'all'; checked: boolean }): void;
  onClick(arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }): void;
}): JSX.Element {
  const { onClick } = props;

  const toolbarActions: ActionEventProps[] = [
    { code: 'form/delete', label: '삭제', type: 'icon', icon: 'trash-full' },
    { code: 'moveForm', label: '양식이동', type: 'icon', icon: 'folder-move' },
  ];

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 80 },
    { field: 'category', label: '분류', width: 130 },
    { field: 'subject', label: '양식명', minWidth: 200 },
    { field: 'createAt', label: '등록일', width: 150 },
    { field: 'state', label: '상태', width: 100, align: 'right' },
  ]);

  const rows: DataGridRowsProps<number> = props.items.map((row) => {
    return [
      {
        type: 'checkbox' as const,
        id: row.id,
        value: row.checked,
      },
      { type: 'text' as const, value: row.category },
      { type: 'subject' as const, value: row.subject, id: row.id },
      { type: 'date' as const, value: row.createAt, format: 'newSimple' },
      {
        type: 'chip' as const,
        value: row.status ? '사용' : '미사용',
        theme: row.status ? 'success' : 'error',
      },
    ];
  });

  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { checked } = event.currentTarget;
    props.onChangeChecked({ id, checked });
  };

  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.currentTarget;
    props.onChangeChecked({ id: 'all', checked });
  };

  const { title, onSearch } = props;

  const [keyword, setKeyword] = useState('');

  const handleSearch = (arg: { keyword: string; filter?: string }) => {
    onSearch(arg);
    setKeyword(arg.keyword);
  };

  useEffect(() => {
    setKeyword('');
  }, [props.pathname]);
  return (
    <>
      <EuiSetting.Header title={title}>
        <Button
          text="수정"
          iconType
          icon="edit"
          onClick={(event) => onClick({ code: 'folder/update', event })}
        />
        <Button
          text="순서변경"
          iconType
          icon="sort-amount"
          onClick={(event) => onClick({ code: 'orderFolder', event })}
        />
        <Button
          text="삭제"
          iconType
          icon="trash-full"
          onClick={(event) => onClick({ code: 'folder/delete', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <Search keyword={keyword} onSearch={handleSearch} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <DataGrid
          scrollbar={props.scroll}
          caption="게시양식"
          columns={columns}
          rows={rows}
          checkedCount={props.items.filter((i) => i.checked).length}
          pagination={props.pagination}
          toolbarActions={toolbarActions}
          onChecked={handleChangeChecked}
          onCheckedAll={handleChangeCheckedAll}
          onClick={onClick}
          onChangeColumnVisible={handleChangeColumnVisible}
        />
      </EuiSetting.Content>
    </>
  );
}

export default React.memo(BoardFormList);
