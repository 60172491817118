import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import sendNoticeApi from '../../apis/attendance/v1/sendNotice';
import { dateFormat } from '../../../groupware-common/utils/ui';

const name = 'attendance/sendNotice';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

/** 연차사용촉진알림 상세
 *
 * @property alertType 연차촉진 알림 구분(1 : 1차, 2 : 2차, 0 :일반연차)
 * @property remainedLeaves 잔여연차.
 * @property noticeId 연차사용촉진알림 문서 아이디.
 * @property usePlanId 연차사용계획서 문서 아이디.
 * @property planNotifyId 지정일통보 문서 아이디.
 * @property noticeDateStart noticeDateStart 연차사용촉진 발송시작.
 * @property noticeDateEnd noticeDateStart 연차사용촉진 발송종료.
 * @property noticeSendDate 연차사용촉진 발송일.
 * @property usePlanDateStart 사용계획서 발송시작.
 * @property usePlanDateEnd 사용계획서 발송종료.
 * @property usePlanSendDate 사용계획서 발송일.
 * @property planNotifyDateStart 지정일통보 발송시작.
 * @property planNotifyDateEnd 지정일통보 발송종료.
 * @property planNotifySendDate 지정일통보 발송일.
 * @property updateAt 수정 일자.
 */
interface dayOffItem {
  alertType: number;
  remainedLeaves: number;
  noticeId: number;
  usePlanId: number;
  planNotifyId: number;
  noticeDateStart: string;
  noticeDateEnd: string;
  noticeSendDate: string;
  noticeStatus: string; // 'create' | 'read' | 'disable'

  usePlanDateStart: string;
  usePlanDateEnd: string;
  usePlanSendDate: string;
  usePlanStatus: string; // 'create' | 'read' | 'disable'

  planNotifyDateStart: string;
  planNotifyDateEnd: string;
  planNotifySendDate: string;
  planNotifyStatus: string; // 'create' | 'read' | 'disable'

  updateAt: string;
}
/** 사용자별 연차사용촉진알림
 * @property employeeId 사원 아이디.
 * @property id 직원 아이디.
 * @property organizationId 직원 조직 아이디.
 * @property enteredDate 입사일자.
 * @property standardDate 기준일자.
 * @property dayOff 연차사용촉진 상세 배열.
 * @property send 연차사용촉진 상세 배열.
 */
interface SendNoticeItem {
  checked: boolean;
  isAlarmEnabled: boolean; // 연차촉진알림 발송가능 여부
  employeeId: number;
  enterDate: string;
  standardDate: string;
  workingYearString: string;
  leaveType: string;
  dayOff: dayOffItem[];
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: {
    items: SendNoticeItem[];
    totalCount: number;
  };
  view: {
    employeeId: number; // 사원 키.
    alertType: number; // 연차촉진 알림 구분.(1 : 1차, 2 : 2차, 0 :일반연차)
    id: number; // 문서 아이디.
    contents: string; // 내용.
    updateAt: string; // 수정 일자.
    standardDate: string;
    isEnableWorkPlan?: boolean; // 팀즈 알림용 - 사용자 지정통보 발송 여부
  } | null;
}

const initialState: State = {
  requests: [],
  list: {
    items: [],
    totalCount: 0,
  },
  view: null,
};

/** 연차사용촉진알림 발송 대상 조회. */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (arg: LocateArg, { rejectWithValue }) => {
    try {
      const search = arg.route?.search ?? '';
      const queryParams = getQueryParams(search);
      const { directoryFilter, searchWord } = queryParams;

      let searchcode = '';
      const datetype = queryParams.searchCode;
      if (directoryFilter === 'organization' || directoryFilter === 'employee')
        searchcode = directoryFilter;
      let searchword =
        queryParams.directoryKeyword !== '' ? queryParams.directoryKeyword : '';
      if (searchcode === '') searchword = searchWord;

      const data = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
        searchcode,
        searchword,
        datetype,
        pageNo: queryParams.pageNo ?? 1,
        rowsPerPage: queryParams.rowsPerPage ?? 15,
      };
      const response = await sendNoticeApi.list(data);
      const totalCount = await sendNoticeApi.totalCount(data);

      const today = moment().format('YYYY-MM-DD');

      const items: SendNoticeItem[] = response.map((a) => {
        return {
          checked: false,
          isAlarmEnabled: false,
          employeeId: a.employeeId,
          enterDate: a.enterDate === null ? '' : a.enterDate,
          standardDate: dateFormat(a.standardDate, 'YYYY-MM-DD'),
          workingYearString: a.workingYearString,
          leaveType: a.leaveType,
          dayOff: a.subjectItem.map((b) => {
            let noticeStatus = 'disable';
            if (b.firstAlarmId !== 0) noticeStatus = 'read';
            else if (
              b.firstAlarmStartDate <= today &&
              b.firstAlarmEndDate >= today &&
              b.remainedLeaves > 0
            )
              noticeStatus = 'create';

            let usePlanStatus = 'disable';
            if (b.workerPlanId !== 0) usePlanStatus = 'read';

            let planNotifyStatus = 'disable';
            if (b.secondAlarmId !== 0) planNotifyStatus = 'read';
            else if (
              b.secondAlarmStartDate <= today &&
              b.secondAlarmEndDate >= today
            )
              planNotifyStatus = 'create';

            return {
              alertType: b.alertType,
              remainedLeaves: b.remainedLeaves,
              noticeId: b.firstAlarmId,
              usePlanId: b.workerPlanId,
              planNotifyId: b.secondAlarmId,
              noticeDateStart: dateFormat(b.firstAlarmStartDate, 'YYYY-MM-DD'),
              noticeDateEnd: dateFormat(b.firstAlarmEndDate, 'YYYY-MM-DD'),
              noticeSendDate: dateFormat(b.firstAlarmSendDate, 'YYYY-MM-DD'),
              noticeStatus,
              usePlanDateStart: dateFormat(b.workerPlanStartDate, 'YYYY-MM-DD'),
              usePlanDateEnd: dateFormat(b.workerPlanEndDate, 'YYYY-MM-DD'),
              usePlanSendDate: dateFormat(b.workerPlanSendDate, 'YYYY-MM-DD'),
              usePlanStatus,
              planNotifyDateStart: dateFormat(
                b.secondAlarmStartDate,
                'YYYY-MM-DD',
              ),
              planNotifyDateEnd: dateFormat(b.secondAlarmEndDate, 'YYYY-MM-DD'),
              planNotifySendDate: dateFormat(
                b.secondAlarmSendDate,
                'YYYY-MM-DD',
              ),
              planNotifyStatus,
              updateAt: b.updateAt,
            };
          }),
        };
      });
      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 문서 조회 (공통) */
const findFormView = createAsyncThunk(
  `${name}/findFormView`,
  async (
    arg: {
      employeeId: number;
      standardDate: string;
      alertType: number;
      alertFormType: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const view = await sendNoticeApi.fetchView(arg);
      return view;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 문서 조회 (공통) */
const findFormTeamsView = createAsyncThunk(
  `${name}/findFormTeamsView`,
  async (
    arg: {
      id: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { id } = arg;
      if (!id) {
        throw new Error('문서를 조회할 수 없습니다.');
      }
      const view = await sendNoticeApi.fetchTeamsView({ id });
      return view;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연차사용촉진알림 발송 */
const saveNotice = createAsyncThunk(
  `${name}/saveNotice`,
  async (
    data: {
      arg: {
        employeeId: number;
        alertType: number;
        standardDate: string;
        contents: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { arg, route } = data;
      const response = await sendNoticeApi.createNotice(arg);
      if (route !== undefined) await dispatch(findList({ route }));
      return response;
    } catch (ex) {
      return rejectWithValue(
        appError({ error: '연차촉진알림 발송 중 오류가 발생하였습니다.' }),
      );
    }
  },
);

/** 지정일통보계획서 발송 */
const savePlanNotify = createAsyncThunk(
  `${name}/savePlanNotify`,
  async (
    data: {
      arg: {
        employeeId: number;
        alertType: number;
        standardDate: string;
        contents: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { arg, route } = data;
      const response = await sendNoticeApi.createPlanNotify(arg);
      if (response.id === undefined)
        return rejectWithValue(appError({ error: '발송 오류 발생.' }));

      if (route !== undefined) await dispatch(findList({ route }));
      return undefined;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const attendanceSendNoticeReducer = createSlice({
  name,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{ employeeId: number | 'all'; checked: boolean }>,
    ) {
      if (action.payload.employeeId === 'all') {
        state.list.items = state.list.items.map((x) => {
          if (!x.isAlarmEnabled) return { ...x, checked: false };
          if (x.checked === action.payload.checked) return x;
          return { ...x, checked: action.payload.checked };
        });
      } else {
        const index = state.list.items.findIndex(
          (x) => x.employeeId === action.payload.employeeId,
        );
        if (index > -1) {
          state.list.items[index] = {
            ...state.list.items[index],
            checked: action.payload.checked,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );

          if (payload !== undefined) {
            state.list.items = payload.items.map((a) =>
              a.dayOff.findIndex((b) => b.noticeStatus === 'create') > -1
                ? { ...a, isAlarmEnabled: true }
                : a,
            );
            state.list.totalCount = payload.totalCount;
          }
        }
      })
      .addCase(findFormView.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.view = payload;
        }
      })
      .addCase(findFormTeamsView.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.view = payload;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default attendanceSendNoticeReducer.reducer;

export const sendNoticeActions = {
  checked: attendanceSendNoticeReducer.actions.checked,

  list: findList,
  saveNotice,
  savePlanNotify,
  view: findFormView,
  findFormTeamsView,
};
