import React, { useState } from 'react';
import { IconType } from '../../../groupware-common/types/icon';
import ComponentSampleUI from './components-sample/ComponentSampleUI';
import ComponentCheckbox from './components-sample/checkbox/ComponentCheckbox';
import ComponentRadio from './components-sample/radio/ComponentRadio';
import ComponentSwitch from './components-sample/switch/ComponentSwitch';
import ComponentButton from './components-sample/button/ComponentButton';
import ComponentTooltip from './components-sample/tooltip/ComponentTooltip';
import TestBreadcrumbs from './components-sample/breadcrumbs/ComponentBreadcrumbs';
import ComponentAvatar from './components-sample/avatar/ComponentAvatar';
import ComponentFlag from './components-sample/flag/ComponentFlag';
import ComponentChip from './components-sample/chip/ComponentChip';
import ComponentAlert from './components-sample/alert/ComponentAlert';
import ComponentCalendar from './components-sample/calendar/ComponentCalendar';
import Tab from '../../../components/tab/Tab';
import ComponentSearch from './components-sample/search/ComponentSearch';
import ComponentSelect from './components-sample/select/ComponentSelect';
import ComponentTextField from './components-sample/textField/ComponentTextField';
import ComponentHelperText from './components-sample/helper-text/ComponentHelperText';
import ComponentMenu from './components-sample/menu/ComponentMenu';
import ComponentNav from './components-sample/nav/ComponentNav';
import ComponentPrint from './components-sample/print/ComponentPrint';
import ComponentIcon from './components-sample/icon/ComponentIcon';

function ComponentSample(): JSX.Element {
  const components: { value: string; label: string; icon?: IconType }[] = [
    { value: 'icon', label: 'Icon', icon: 'plus' },
    { value: 'nav', label: 'Nav', icon: 'bars' },
    { value: 'menu', label: 'Menu', icon: 'mini-layer' },
    { value: 'helperText', label: 'Helper Text', icon: 'exclamation-fill' },
    { value: 'textField', label: 'Text Field', icon: 'input' },
    { value: 'select', label: 'Select', icon: 'selectbox' },
    { value: 'switch', label: 'Switch', icon: 'toggle-on' },
    { value: 'alert', label: 'Alert', icon: 'exclamation-triangle' },
    { value: 'button', label: 'Button', icon: 'button' },
    { value: 'chip', label: 'Chip', icon: 'puzzle' },
    { value: 'radio', label: 'Radio', icon: 'check-circle' },
    { value: 'checkbox', label: 'Checkbox', icon: 'check-square' },
    { value: 'avatar', label: 'Avatar', icon: 'user-circle-fill' },
    { value: 'print', label: 'Print', icon: 'print' },
    { value: 'search', label: 'Search', icon: 'search' },
    { value: 'calendar', label: 'Calendar', icon: 'calendar-grid' },
    { value: 'breadcrumbs', label: 'Breadcrumbs', icon: 'terminal' },
    { value: 'flag', label: 'Flag', icon: 'flag' },
    { value: 'tooltip', label: 'Tooltip', icon: 'comment-text-right' },
  ];

  const [selectedId, setSelectedId] = useState(components[0].value);

  const contents = () => {
    switch (selectedId) {
      case 'icon':
        return (
          <ComponentSampleUI.Item name="Icon">
            <ComponentIcon />
          </ComponentSampleUI.Item>
        );
      case 'nav':
        return (
          <ComponentSampleUI.Item name="Nav">
            <ComponentNav />
          </ComponentSampleUI.Item>
        );
      case 'menu':
        return (
          <ComponentSampleUI.Item name="Menu">
            <ComponentMenu />
          </ComponentSampleUI.Item>
        );
      case 'select':
        return (
          <ComponentSampleUI.Item name="Select">
            <ComponentSelect />
          </ComponentSampleUI.Item>
        );
      case 'button':
        return (
          <ComponentSampleUI.Item name="Button">
            <ComponentButton />
          </ComponentSampleUI.Item>
        );
      case 'chip':
        return (
          <ComponentSampleUI.Item name="Chip">
            <ComponentChip />
          </ComponentSampleUI.Item>
        );
      case 'radio':
        return (
          <ComponentSampleUI.Item name="Radio">
            <ComponentRadio />
          </ComponentSampleUI.Item>
        );
      case 'checkbox':
        return (
          <ComponentSampleUI.Item name="Checkbox">
            <ComponentCheckbox />
          </ComponentSampleUI.Item>
        );
      case 'switch':
        return (
          <ComponentSampleUI.Item name="Switch">
            <ComponentSwitch />
          </ComponentSampleUI.Item>
        );
      case 'avatar':
        return (
          <ComponentSampleUI.Item name="Avatar">
            <ComponentAvatar />
          </ComponentSampleUI.Item>
        );
      case 'textField':
        return (
          <ComponentSampleUI.Item name="TextField">
            <ComponentTextField />
          </ComponentSampleUI.Item>
        );
      case 'alert':
        return (
          <ComponentSampleUI.Item name="Alert">
            <ComponentAlert />
          </ComponentSampleUI.Item>
        );
      case 'print':
        return (
          <ComponentSampleUI.Item name="Print">
            <ComponentPrint />
          </ComponentSampleUI.Item>
        );
      case 'search':
        return (
          <ComponentSampleUI.Item name="Search">
            <ComponentSearch />
          </ComponentSampleUI.Item>
        );
      case 'calendar':
        return (
          <ComponentSampleUI.Item name="Calendar">
            <ComponentCalendar />
          </ComponentSampleUI.Item>
        );
      case 'breadcrumbs':
        return (
          <ComponentSampleUI.Item name="Breadcrumbs">
            <TestBreadcrumbs />
          </ComponentSampleUI.Item>
        );
      case 'flag':
        return (
          <ComponentSampleUI.Item name="Flag">
            <ComponentFlag />
          </ComponentSampleUI.Item>
        );
      case 'tooltip':
        return (
          <ComponentSampleUI.Item name="Tooltip">
            <ComponentTooltip />
          </ComponentSampleUI.Item>
        );
      case 'helperText':
        return (
          <ComponentSampleUI.Item name="Helper Text">
            <ComponentHelperText />
          </ComponentSampleUI.Item>
        );
      default:
        return null;
    }
  };

  return (
    <ComponentSampleUI>
      <nav className="component-nav">
        {components.map((x) => (
          <Tab.Item
            key={x.value}
            label={x.label}
            icon={x.icon}
            selected={selectedId === x.value}
            onClick={() => setSelectedId(x.value)}
          />
        ))}
      </nav>
      <section className="component-container">{contents()}</section>
    </ComponentSampleUI>
  );
}

export default ComponentSample;
