import { useEffect } from 'react';

import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { formFolderActions } from '../../../../stores/attendance/form';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceNoticeSettingRoute(props: { pathname: string }): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    Promise.all([
      dispatch(attendancePreferencesActions.findNotice()),
      dispatch(formFolderActions.list()),
    ]).then(() => {
      if (mount)
        dispatch(
          sessionActions.setRoute({
            pathname,
            clearErrors: true,
          }),
        );
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);

  return null;
}

export default AttendanceNoticeSettingRoute;
