/* eslint-disable consistent-return */
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import { dateFormat, timeFormat } from '../../../groupware-common/utils/ui';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import attendanceCancelStatusApi from '../../apis/attendance/v1/detail';
import AttendancePreferencesApi from '../../apis/attendance/v1/preferences';

const name = 'attendance/detailStatus';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

/** 관리자 - 신청내역 리스트 객체. */
interface AttendanceListItem {
  checked: boolean;
  companyId: number; // 회사 아이디.
  useId: number; // 근태 신청 아이디.
  useYear: string; // 기준 년도.
  employeeId: number; // 근태 신청 사원 아이디.
  approvalId: number; // 전자결재 문서 아이디
  useType: number; // 근태 코드(1 :연차 , 2: 휴가 , 3:출장, 4:교육, 5:경조, 6:대휴 , 7:외근, 8: 휴일근무, 9: 시간외근무, 10: 기타)
  subject: string; // 제목
  status: number; // 전자결재 상태 (1: 기안,  3: 완료, 4: 반려, 12: 회수, 44: 취소)
  startDate: string; // 시작 일자(yyyy-MM-dd)
  endDate: string; // 종료 일자(yyyy-MM-dd)
  useMinutes: string; // 사용시간.
  createAt: string; // 등록 날짜.
  updateAt: string; // 수정 시간(yyyy-MM-dd'T'HH:mm:ss.SSSSSS)
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: {
    items: AttendanceListItem[];
    totalCount: number;
  };
}

const initialState: State = {
  requests: [],
  list: {
    items: [],
    totalCount: 0,
  },
};

/** 신청 현황 목록 조회. */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (arg: LocateArg, { rejectWithValue }) => {
    try {
      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();
      const queryParams = getQueryParams(arg.route?.search ?? '');

      const items = (
        await attendanceCancelStatusApi.list({
          startdate:
            queryParams.startDate ?? dateFormat(new Date(), 'YYYY-MM-DD'),
          enddate: queryParams.endDate ?? dateFormat(new Date(), 'YYYY-MM-DD'),
          searchcode: queryParams.searchCode,
          searchword: queryParams.searchWord,
          pageno: queryParams.pageNo || 1,
          rowsperpage: queryParams.rowsPerPage || 15,
        })
      ).map((a) => ({
        ...a,
        checked: false,
        useMinutes: timeFormat(a.useMinutes, expressionUnit),
      }));
      const totalCount = await attendanceCancelStatusApi.totalCount({
        startdate:
          queryParams.startDate ?? dateFormat(new Date(), 'YYYY-MM-DD'),
        enddate: queryParams.endDate ?? dateFormat(new Date(), 'YYYY-MM-DD'),
        searchcode: queryParams.searchCode,
        searchword: queryParams.searchWord,
      });
      return {
        items,
        totalCount,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 근태 상태 취소 */
const cancelAttendance = createAsyncThunk(
  `${name}/cancelAttendance`,
  async (
    arg: {
      linkedKey: {
        companyId: number; // 근태 신청 회사 키
        id: number; // 근태 신청 아이디
      };
      documentId: number; // 결재 문서 아이디
      linkType: 'CANCEL';
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await attendanceCancelStatusApi.cancel({
        linkedKey: {
          companyId: arg.linkedKey.companyId,
          id: arg.linkedKey.id,
        },
        documentId: arg.documentId,
        linkType: arg.linkType,
      });
      if (arg.route)
        await dispatch(
          attendanceDetailStatusActions.findList({ route: arg.route }),
        );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const attendanceDetailReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload.items !== undefined && payload.totalCount !== undefined) {
          state.list.items = payload.items;
          state.list.totalCount = payload.totalCount;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default attendanceDetailReducer.reducer;

export const attendanceDetailStatusActions = {
  findList,
  cancel: cancelAttendance,
};
