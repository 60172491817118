/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import { ReadingPaneMode } from '../../../../../groupware-webapp/stores/types';
import { boardActions, FolderSetting } from '../../../../stores/board';
import BoardContentBodyList from './BoardContentBodyList';
import BoardContentBodyView from './BoardContentBodyView';
import EuiBody from '../../../../../components/layout/EuiBody';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import BoardContentHeadList from './BoardContentHeadList';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import BoardContentHeadView from './BoardContentHeadView';
import { getHost } from '../../../../../groupware-common/apis/common/v1';
import FeedBack from '../../../../../components/alert/FeedBack';
import { SearchDateProps } from '../../../../../components/search/AttendanceSearch';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import folderBoxApi from '../../../../apis/board/v1/folder';
import BoardPrintView from '../../../common/BoardPrintView';
import BoardViewerDrawer from '../common/BoardViewerDrawer';
import Confirmation from '../../../../../components/alert/Confirmation';
import boardFileApi from '../../../../apis/board/v1/common';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import PromptMessage from '../../../../../components/prompt/PromptMessage';
import TextField from '../../../../../components/textfield/TextField';
import Button from '../../../../../components/button/Button';
import boardsApi from '../../../../apis/board/v1/boards';

function BoardContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { folderId, itemId } = getPathParams<{
    folderId?: string;
    itemId?: number;
  }>('/*/:folderId/:itemId$base62', pathname);
  const queryParams = getQueryParams(search);
  // 고정 폴더 여부 (모든게시함, 중요게시글, 임시보관함)
  const isFixedFolder =
    folderId === 'all' || folderId === 'temp' || folderId === 'importance';

  const dispatch = useAppDispatch();

  const display = useSelector((state: RootState) => state.session.display);
  const resource = useSelector((state: RootState) => state.session.resource);
  const principal = useSelector((state: RootState) => state.session.principal);

  const folders = useSelector((state: RootState) => state.boards.folder.list);
  const categories = useSelector(
    (state: RootState) => state.boards.board.category,
  ).filter((a) => a.type === 'status');
  const list = useSelector((state: RootState) => state.boards.board.list);
  const totalCount = useSelector(
    (state: RootState) => state.boards.board.totalCount,
  );
  const isSecure = useSelector(
    (state: RootState) => state.boards.board.view.isSecure,
  );
  const view = useSelector((state: RootState) =>
    itemId && itemId === state.boards.board.view.data?.id
      ? state.boards.board.view.data
      : undefined,
  );
  const readingPaneMode = useSelector((state: RootState) =>
    folderId === 'temp' || display === 'phone'
      ? 'list'
      : state.boards.board.readingPaneMode,
  );
  const folderSetting = useSelector(
    (state: RootState) => state.boards.board.folderSetting,
  );
  const displayDensity = useSelector(
    (state: RootState) => state.boards.board.displayDensity,
  );

  /** 분할 모드일 경우. */
  const isSplitView =
    readingPaneMode === 'horizontal' || readingPaneMode === 'vertical';
  const rowsPerPage =
    display === 'phone'
      ? queryParams.rowsPerPage ?? 15
      : queryParams.rowsPerPage ?? folderSetting.setting?.rowsPerPage ?? 15;

  /**
   * 폴더 트리 아이템 배열.
   * 쓰기 권한이 없거나 익명 권한인 폴더로 이동 불가능.
   * */
  const foldersTreeItems = folders
    .map((a) => ({
      id: a.id,
      seq: a.seq,
      parentId: a.parentId,
      text: a.name,
      icon: 'folder' as const,
      disabled: !a.permissions?.useWrite || a.option.useAnonymous,
    }))
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  const [defaultColumns, setDefaultColumns] = useState<
    Column<PostListItemType>[]
  >([
    { name: 'checked', text: '', type: 'action', visible: true },
    { name: 'importance', text: '', type: 'importance', visible: true },
    { name: 'thumbnail', text: '썸네일', type: 'thumbnail', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: '제목', type: 'post', visible: true, disable: true },
    { name: 'views', text: '조회', type: 'count', visible: true },
    { name: 'likes', text: '좋아요', type: 'count', visible: true },
    { name: 'drafter', text: '작성자', type: 'user', visible: true },
    { name: 'createAt', text: '작성일', type: 'date', visible: true },
  ]);

  const [state, setState] = useState<{
    tempFolderId: number; // 폴더 변경 전 저장 아이디
    folderConfirmSelectType?: 'view' | 'list';
    folderConfirmSelect: boolean; // 폴더 안내문구 선택 확인 창
    isNotice: boolean; // 공지 게시글 여부.
    secureConfirmClick: boolean; // 보안게시 비밀번호 확인 여부.
    securePassword: string; // 보안게시 비밀번호.
    validation: string;
  }>({
    tempFolderId: 0,
    folderConfirmSelect: false,
    isNotice: false,
    secureConfirmClick: false,
    securePassword: '',
    validation: '',
  });
  const [titleClassification, setTitleClassification] = useState<
    { id: number; titleClassification: string }[]
  >([]);

  useEffect(() => {
    const { setting } = folderSetting;
    if (display !== 'phone' && setting) {
      setDefaultColumns(
        defaultColumns.map((a) => {
          if (setting.columns.some((x) => x === a.name)) {
            return {
              ...a,
              visible: true,
            };
          }
          return {
            ...a,
            visible: false,
          };
        }),
      );
    } else {
      setDefaultColumns(
        defaultColumns.map((a) => ({
          ...a,
          visible: true,
        })),
      );
    }
    async function run() {
      const useTitleClassification = folders.find((a) => a.id === b62(folderId))
        ?.option.useTitleClassification;
      if (folderId && !isFixedFolder && useTitleClassification) {
        const data = await folderBoxApi.titleClassification({
          folderid: b62(folderId),
        });
        setTitleClassification(data);
      } else setTitleClassification([]);
    }
    run();
  }, [folderId]);

  useEffect(() => {
    setState((prev) => ({ ...prev, securePassword: '' }));
  }, [itemId]);

  useEffect(() => {
    async function run() {
      if (!itemId) return;
      const { securePassword } = state;
      const secureCompare = await boardsApi.securalCompare({
        postid: itemId,
        securalpassword: securePassword,
      });
      if (!secureCompare) {
        dispatch(sessionActions.error('비밀번호가 올바르지 않습니다.'));
        setState((prev) => ({
          ...prev,
          secureConfirmClick: false,
          securePassword: '',
        }));
        return;
      }
      dispatch(boardActions.findView({ id: itemId })).then(() => {
        setState((prev) => ({
          ...prev,
          secureConfirmClick: false,
          securePassword: '',
        }));
      });
    }
    if (state.secureConfirmClick) run();
  }, [state.secureConfirmClick]);

  let columns = defaultColumns;
  if (folderId === 'temp')
    columns =
      display === 'phone'
        ? defaultColumns.filter(
            ({ name }) =>
              !(name === 'importance' || name === 'views' || name === 'likes'),
          )
        : defaultColumns.filter(
            ({ name }) =>
              !(
                name === 'importance' ||
                name === 'views' ||
                name === 'likes' ||
                name === 'drafter'
              ),
          );
  if (readingPaneMode !== 'gallery')
    columns = columns.filter(({ name }) => name !== 'thumbnail');

  const filterOptions =
    titleClassification.length > 0
      ? [
          { value: '', label: '전체' },
          ...titleClassification.map((a) => {
            return { value: a.id.toString(), label: a.titleClassification };
          }),
        ]
      : undefined;

  if (list.filter((x) => x.checked).length > 0) {
    document.documentElement.setAttribute('data-selecting', 'true');
  } else {
    document.documentElement.removeAttribute('data-selecting');
  }

  const handleListItemCheckedChange = (
    id: string | 'all',
    checked: boolean,
  ) => {
    dispatch(boardActions.setListItemChecked({ itemId: id, checked }));
  };

  const handleChangeFilter = (value: string) => {
    queryParams.pageNo = 1;
    if (value === '') delete queryParams.status;
    else queryParams.status = value;
    go(`${getPathMap('/*/*', pathname)}`, queryParams);
  };

  const handleListItemImportanceChange = (arg: {
    id: number;
    isNotice: boolean;
  }) => {
    const { id } = arg;
    const item = list.find((a) => a.id === id);
    if (!item) return;
    dispatch(
      boardActions.saveUnSaveStar({
        star: !item.isStarred,
        id,
        employeeId: principal.employeeId,
        noLoading: true, // 로딩바 표시 안함.
      }),
    ).then((result) => {
      if (
        (result as { error?: string }).error === undefined &&
        folderId === 'importance'
      ) {
        const { pageNo = 1 } = queryParams;
        const path = `${getPathMap('/*/*', pathname)}`;
        const currentPage = (pageNo - 1) * rowsPerPage + 1;
        if (totalCount === currentPage && pageNo > 1) {
          queryParams.pageNo = pageNo - 1;
          go(path, queryParams);
        } else if (isSplitView && itemId && view && item.id === view.id) {
          queryParams.pageNo = pageNo;
          const route = { pathname: path, search: getQueryParams(queryParams) };
          dispatch(
            boardActions.findList({
              folderId,
              search: getQueryParams(queryParams),
              isStarred: true,
              isTemporary: false,
              route,
            }),
          );
        } else
          dispatch(
            boardActions.findList({
              folderId,
              search: getQueryParams(queryParams),
              isStarred: true,
              isTemporary: false,
            }),
          );
      }
    });
  };

  /** 첨부파일 파일 다운로드 이벤트. */
  const handleFileDownload = (id: number, fileId: number, name: string) => {
    boardFileApi
      .download(id, fileId)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const handleListItemClick = (arg: { id: string; isNotice: boolean }) => {
    const { isNotice } = arg;
    const id = isNotice
      ? parseInt(arg.id.split('_')[0], 10)
      : parseInt(arg.id, 10);
    // 공지글 여부.
    if (isNotice) queryParams.type = 'notice';
    else delete queryParams.type;
    let searchPath = '';
    if (!folderId || folderId === 'all')
      searchPath = `${getPathMap(`/*`, pathname)}/all/${b62(id)}`;
    else if (folderId === 'temp') {
      searchPath = `${getPathMap(`/*/*`, pathname)}/${b62(id)}`;
      queryParams.contentMode = 'create';
      queryParams.contentType = 'temp';
    } else searchPath = `${getPathMap(`/*/*`, pathname)}/${b62(id)}`;

    if (isSplitView) {
      const route = {
        pathname: searchPath,
        search: getQueryParams(queryParams),
      };
      dispatch(boardActions.isSecretView({ id, route }));
    } else go(searchPath, getQueryParams(queryParams));
  };

  /** 보기모드 변경. */
  const handleReadingPaneModeChange = (mode: ReadingPaneMode) => {
    if (mode === 'list' || mode === 'gallery') {
      const route = utils.getRoute({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          search,
        },
      });
      dispatch(sessionActions.setRoute(route));
    }
    dispatch(boardActions.setReadingPaneMode(mode));
    const setting: FolderSetting = {
      version: '1.0',
      density: displayDensity,
      readingPaneMode: mode,
      columns: defaultColumns.filter((a) => a.visible).map(({ name }) => name),
      rowsPerPage,
    };
    if (folderId !== 'temp')
      dispatch(
        boardActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      );
  };

  /** 파일 다운로드. */
  const download = (blob: Blob) => {
    if (!blob) throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  /** 헤드 뷰 액션. */
  const handleClickToolbarAction = (arg: {
    code: string;
    event?: React.MouseEvent;
  }) => {
    const { code } = arg;
    // URL 저장
    if (code === 'URLcopy') {
      const url = getHost() + pathname;
      window.navigator.clipboard.writeText(url).then(() => {
        setState((prev) => ({
          ...prev,
          validation: '클립보드에 URL이 복사되었습니다.',
        }));
      });
    }
    // 인쇄
    if (code === 'print') dispatch(sessionActions.setDialog({ type: code }));
    // 수정
    if (code === 'modify') {
      queryParams.contentMode = 'update';
      go(pathname, queryParams);
    }
    // 삭제
    if (code === 'remove') dispatch(sessionActions.setDialog({ type: code }));
    // 이동
    if (code === 'move') dispatch(sessionActions.setDialog({ type: code }));
    // HTML 저장
    if (code === 'save' && view) {
      boardFileApi
        .htmlDownload([view.id])
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
    // 조회
    if (code === 'viewer') dispatch(sessionActions.setDrawer({ type: code }));
  };

  const handleListGo = () => {
    delete queryParams.type;
    go(`${getPathMap('/*/*', pathname)}`, queryParams);
  };

  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    const { keyword, directoryKeyword, filter, date } = arg;
    let searchPath = pathname;
    if (isSplitView) searchPath = `${getPathMap('/*/*', pathname)}`;

    delete queryParams.type;
    queryParams.pageNo = 1;
    queryParams.searchCode = keyword === '' ? undefined : filter;
    queryParams.searchWord = keyword === '' ? undefined : keyword;
    queryParams.startDate = date.start
      ? dateFormat(initialDate(date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = date.end
      ? dateFormat(initialDate(date.end), 'yyyy-MM-DD')
      : undefined;
    queryParams.directoryKeyword = directoryKeyword;
    queryParams.directoryFilter =
      directoryKeyword && keyword !== '' ? 'true' : undefined;

    go(searchPath, getQueryParams(queryParams));
  };

  const handleHeadAction = (arg: {
    code: string;
    event: React.MouseEvent<Element, MouseEvent>;
  }) => {
    const { code: type } = arg;
    // 목록 설정.
    if (type === 'listSetting') dispatch(sessionActions.setDrawer({ type }));
    // 이전 페이지.
    if (type === 'prevPage') {
      const pageNo = (queryParams.pageNo || 1) - 1;
      if (pageNo > 0) {
        delete queryParams.type;
        queryParams.pageNo = pageNo;
        if (isSplitView) go(`${getPathMap('/*/*', pathname)}`, queryParams);
        else go(pathname, queryParams);
      }
      return;
    }
    // 다음 페이지.
    if (type === 'nextPage') {
      const pageNo = (queryParams.pageNo || 1) + 1;
      if ((rowsPerPage || 1 * pageNo) < totalCount) {
        delete queryParams.type;
        queryParams.pageNo = pageNo;
        if (isSplitView) go(`${getPathMap('/*/*', pathname)}`, queryParams);
        else go(pathname, queryParams);
      }
    }
    // 인쇄.
    if (type === 'prints') {
      const printList = list.filter((x) => x.checked);
      if (printList.some((a) => a.isSecure)) {
        if (printList.length === 1)
          setState((prev) => ({
            ...prev,
            validation: '보안 설정된 게시글은 인쇄가 불가능합니다.',
          }));
        else
          setState((prev) => ({
            ...prev,
            validation:
              '보안 설정된 게시글이 포함되어 있어 게시글 인쇄가 불가능합니다.',
          }));
        return;
      }
      dispatch(sessionActions.setDialog({ type }));
    }
    // 다중삭제.
    if (type === 'removes') dispatch(sessionActions.setDialog({ type }));
    // 다중이동.
    if (type === 'listMove') {
      const checked = list.filter((a) => a.checked);
      if (checked.some((a) => a.isSecure)) {
        if (checked.length === 1)
          setState((prev) => ({
            ...prev,
            validation: '보안 설정된 게시글은 이동이 불가능합니다.',
          }));
        else
          setState((prev) => ({
            ...prev,
            validation:
              '보안 설정된 게시글이 포함되어 있어 이동이 불가능합니다.',
          }));
        dispatch(sessionActions.setDialog());
        return;
      }
      dispatch(sessionActions.setDialog({ type }));
    }
    // HTML 저장
    if (type === 'save') {
      const saveList = list.filter((x) => x.checked);
      if (saveList.some((a) => a.isSecure)) {
        if (saveList.length === 1)
          setState((prev) => ({
            ...prev,
            validation: '보안 설정된 게시글은 저장이 불가능합니다.',
          }));
        else
          setState((prev) => ({
            ...prev,
            validation:
              '보안 설정된 게시글이 포함되어 있어 게시글 저장이 불가능합니다.',
          }));
        return;
      }
      if (saveList.length === 0) return;
      boardFileApi
        .htmlDownload(saveList.map((a) => a.id))
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
  };

  // 목록 노출 변경
  const handleCloumnVisibleChange = (name: string, visible: boolean) => {
    setDefaultColumns((prev) =>
      prev.map((a) => (a.name === name ? { ...a, visible } : a)),
    );
    const visibleCloumns = defaultColumns.map((a) => {
      if (a.name === name)
        return {
          ...a,
          visible,
        };
      return {
        ...a,
        visible: a.visible,
      };
    });
    const setting: FolderSetting = {
      version: '1.0',
      density: displayDensity,
      readingPaneMode,
      columns: visibleCloumns.filter((a) => a.visible).map((a) => a.name),
      rowsPerPage,
    };
    if (folderId !== 'temp')
      dispatch(
        boardActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      );
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const getFolderTitle = () => {
    switch (folderId) {
      case 'all':
      case 'temp':
      case 'importance':
      case undefined:
        return categories.find((a) => a.id === (folderId ?? 'all'))?.name ?? '';
      default:
        return folders.find((a) => a.id === b62(folderId))?.name ?? '';
    }
  };

  // 게시글 단일 삭제.
  const handleDelete = () => {
    if (!view) return;

    if (isSplitView) {
      dispatch(
        boardActions.deleteBoard({
          data: {
            id: view.id,
            updateAt: view.updateAt,
          },
          route: {
            pathname: `${getPathMap('/*/*', pathname)}`,
            search: createQueryString({
              pageNo: 1,
              rowsPerPage,
              dialogType: undefined,
              type: undefined,
            }),
          },
        }),
      );
    } else {
      dispatch(
        boardActions.deleteBoard({
          data: {
            id: view.id,
            updateAt: view.updateAt,
          },
          location: {
            pathname: `${getPathMap('/*/*', pathname)}`,
            search: createQueryString({
              pageNo: 1,
              rowsPerPage,
              dialogType: undefined,
              type: undefined,
            }),
          },
        }),
      );
    }
  };

  // 게시글 다중 삭제.
  const handleDeletes = () => {
    const deleteList = list.filter((a) => a.checked);
    if (deleteList.some((a) => a.isSecure)) {
      if (deleteList.length === 1)
        setState((prev) => ({
          ...prev,
          validation: '보안 설정된 게시글은 삭제가 불가능합니다.',
        }));
      else
        setState((prev) => ({
          ...prev,
          validation:
            '보안 설정된 게시글이 포함되어 있어 게시글 삭제가 불가능합니다.',
        }));
      return;
    }
    const data = deleteList.map((a) => ({
      id: a.id,
      updateAt: a.updateAt,
    }));

    if (isSplitView || (!isSplitView && queryParams.pageNo === 1)) {
      let path = pathname;
      if (itemId && view && data.some((a) => a.id === view.id))
        path = `${getPathMap('/*/*', pathname)}`;
      dispatch(
        boardActions.deleteBoard({
          data,
          route: {
            pathname: path,
            search: createQueryString({
              pageNo: 1,
              rowsPerPage,
              dialogType: undefined,
              type: undefined,
            }),
          },
        }),
      );
    } else {
      dispatch(
        boardActions.deleteBoard({
          data,
          location: {
            pathname: `${getPathMap('/*/*', pathname)}`,
            search: createQueryString({
              pageNo: 1,
              rowsPerPage,
              dialogType: undefined,
              type: undefined,
            }),
          },
        }),
      );
    }
  };

  // 목록 개수 변경.
  const handleChangeListCount = (value: string) => {
    const path = `${getPathMap('/*/*', pathname)}`;
    const query = createQueryString(
      {
        pageNo: 1,
        rowsPerPage: parseInt(value, 10),
        drawerMode: undefined,
        drawerType: undefined,
        type: undefined,
      },
      queryParams,
    );
    const setting: FolderSetting = {
      version: '1.0',
      density: displayDensity,
      readingPaneMode,
      columns: defaultColumns.filter((a) => a.visible).map(({ name }) => name),
      rowsPerPage: parseInt(value, 10),
    };
    if (folderId !== 'temp')
      dispatch(
        boardActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      ).then(() => go(path, query));
    else go(path, query);
  };

  // 화면밀도 설정
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(boardActions.displayDensity(value));
    const setting: FolderSetting = {
      version: '1.0',
      density: value,
      readingPaneMode,
      columns: defaultColumns.filter((a) => a.visible).map(({ name }) => name),
      rowsPerPage,
    };
    if (folderId !== 'temp')
      dispatch(
        boardActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      );
  };

  /** 게시글 이전, 다음 글 이동. */
  const handleViewBoard = (arg: { companyId: number; id: number }) => {
    const { id } = arg;
    go(`${getPathMap('/*/*', pathname)}/${b62(id)}`, search);
  };

  /** 게시글 폴더 이동 시 안내문구 이벤트. */
  const handleMoveFolderSelect = (id: number) => {
    if (b62(folderId) === id) {
      setState((prev) => ({
        ...prev,
        validation: '원본 폴더와 대상 폴더가 동일합니다.',
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      tempFolderId: id,
      folderConfirmSelectType:
        queryParams.dialogType === 'move' ? 'view' : 'list',
      folderConfirmSelect: true,
    }));
    dispatch(sessionActions.setDialog());
  };

  /** 게시글 폴더 이동 시 안내문구 닫기. */
  const handleMoveFolderClose = () => {
    setState((prev) => ({
      ...prev,
      tempFolderId: 0,
      folderConfirmSelect: false,
    }));
  };

  /** 게시글 폴더 이동. */
  const handleMoveFolder = () => {
    const { folderConfirmSelectType } = state;
    queryParams.pageNo = 1;
    if (state.tempFolderId === 0 || !folderConfirmSelectType) return;

    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
      },
    });
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        mode: 'replace',
      },
    });
    let data:
      | {
          folderId: number;
          id: number;
          updateAt: string;
        }
      | {
          folderId: number;
          id: number;
          updateAt: string;
        }[] = [];
    if (folderConfirmSelectType === 'view' && view) {
      data = {
        folderId: state.tempFolderId,
        id: view.id,
        updateAt: view.updateAt,
      };
    } else {
      const checked = list.filter((a) => a.checked);
      data = checked.map((a) => ({
        folderId: state.tempFolderId,
        id: a.id,
        updateAt: a.updateAt,
      }));
    }
    if (
      (!queryParams.pageNo || queryParams.pageNo === 1) &&
      (isSplitView || (!isSplitView && folderConfirmSelectType === 'list'))
    )
      dispatch(boardActions.moveFolder({ data, route }));
    else dispatch(boardActions.moveFolder({ data, location }));
    setState((prev) => ({
      ...prev,
      tempFolderId: 0,
      folderConfirmSelectType: undefined,
      folderConfirmSelect: false,
    }));
  };

  /** 비밀번호 입력 이벤트. */
  const handleChangeSecurePassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const securePassword = event.target.value;
    setState((prev) => ({ ...prev, securePassword }));
  };

  /** 보안게시 - 비밀번호 확인 이벤트. */
  const handleConfirmSecure = () => {
    setState((prev) => ({ ...prev, secureConfirmClick: true }));
  };

  const renderHead = (arg?: { type: 'list' | 'view' }) => {
    const type =
      arg?.type ||
      ((readingPaneMode === 'list' || readingPaneMode === 'gallery') && itemId)
        ? 'view'
        : 'list';
    // 뷰 보기 인 경우.
    if (type === 'view') {
      if (!view) {
        if (readingPaneMode === 'vertical' || readingPaneMode === 'horizontal')
          return null;
        return (
          <BoardContentHeadView
            usePagination={false}
            type="full"
            onListGo={handleListGo}
            toolbarButtons={[]}
            onToolbarAction={handleClickToolbarAction}
          />
        );
      }
      const folder = folders.find((a) => a.id === view.folderId);
      const useWrite = folder?.permissions?.useWrite ?? false;
      const useAnonymous = folder?.option.useAnonymous ?? false;

      let toolbarButtons: ActionEventProps[] = [];
      if (useWrite && view.isAuthorized)
        toolbarButtons = [
          { code: 'modify', label: '수정', type: 'text', icon: 'pen-line' },
          { code: 'remove', label: '삭제', type: 'text', icon: 'trash-full' },
        ];
      if (!useAnonymous) {
        toolbarButtons = folder?.isAdmin
          ? [
              ...toolbarButtons,
              {
                code: 'move',
                label: '이동',
                type: 'text',
                icon: 'folder-move',
              },
              { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
            ]
          : [
              ...toolbarButtons,
              { code: 'viewer', label: '조회', type: 'text', icon: 'eye' },
            ];
      }

      if (!folderId || folderId === 'all' || folderId === 'importance')
        toolbarButtons = [];

      if (display !== 'phone' || resource !== 'teams')
        toolbarButtons = [
          ...toolbarButtons,
          { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
          { code: 'save', label: '저장', type: 'text', icon: 'save' },
        ];

      toolbarButtons = [
        ...toolbarButtons,
        {
          code: 'URLcopy',
          label: 'URL복사',
          type: 'text',
          icon: 'paperclip-v',
        },
      ];
      if (readingPaneMode === 'vertical' || readingPaneMode === 'horizontal')
        return (
          <BoardContentHeadView
            usePagination={false}
            type="split"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleClickToolbarAction}
          />
        );

      const { prev, next } = view;
      return (
        <BoardContentHeadView
          usePagination={queryParams.type !== 'notice'}
          type="full"
          toolbarButtons={toolbarButtons}
          onToolbarAction={handleClickToolbarAction}
          onListGo={handleListGo}
          onPrev={
            prev && prev.companyId !== 0 && prev.id !== 0
              ? () => handleViewBoard(prev)
              : undefined
          }
          onNext={
            next && next.companyId !== 0 && next.id !== 0
              ? () => handleViewBoard(next)
              : undefined
          }
        />
      );
    }

    if (list === null || !list) return null;

    const title = getFolderTitle();
    const folder = folders.find((a) => a.id === b62(folderId));

    let toolbarButtons: ActionEventProps[] =
      display !== 'phone' || resource !== 'teams'
        ? [
            { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
            { code: 'save', label: '저장', type: 'icon', icon: 'save' },
          ]
        : [];

    let optionToolbarButtons: ActionEventProps[] = [];
    // TODO: 복사 기능 추후 개발 예정.
    // if (folder?.permissions?.useCopy)
    //   optionToolbarButtons = [
    //     { code: 'listCopy', label: '복사', type: 'icon', icon: 'copy' },
    //   ];
    if (folder?.isAdmin)
      optionToolbarButtons = !folder?.option.useAnonymous
        ? [
            ...optionToolbarButtons,
            // eslint-disable-next-line prettier/prettier
            { code: 'removes', label: '삭제', type: 'icon', icon: 'trash-full' },
            // eslint-disable-next-line prettier/prettier
            { code: 'listMove', label: '이동', type: 'icon', icon: 'folder-move' },
          ]
        : [
            ...optionToolbarButtons,
            // eslint-disable-next-line prettier/prettier
            { code: 'removes', label: '삭제', type: 'icon', icon: 'trash-full' },
          ];

    switch (folderId) {
      case 'all':
      case 'importance':
      case undefined:
        toolbarButtons = [...toolbarButtons];
        break;
      case 'temp':
        toolbarButtons = [
          { code: 'removes', label: '삭제', type: 'icon', icon: 'trash-full' },
        ];
        break;
      default:
        toolbarButtons = [...optionToolbarButtons, ...toolbarButtons];
        break;
    }

    return (
      <BoardContentHeadList
        isAnony={folder?.option.useAnonymous ?? false}
        folderId={folderId}
        title={title}
        pageNo={queryParams.pageNo || 1}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        checkedCount={list.filter((x) => x.checked).length}
        onCheckedChange={handleListItemCheckedChange}
        filter={queryParams.status || ''}
        filterOptions={filterOptions}
        onChangeFilter={handleChangeFilter}
        toolbarButtons={toolbarButtons}
        searchCode={queryParams.searchCode}
        searchWord={queryParams.searchWord}
        startDate={queryParams.startDate}
        endDate={queryParams.endDate}
        onSearch={handleSearch}
        onAction={handleHeadAction}
      />
    );
  };

  const renderBody = () => {
    let result = null;
    let type: PostListType = readingPaneMode === 'vertical' ? 'split' : 'full';
    if (readingPaneMode === 'gallery') type = 'gallery';
    const listComponent: JSX.Element = (
      <BoardContentBodyList
        pathname={pathname}
        search={search}
        type={type}
        density={displayDensity}
        columns={
          readingPaneMode === 'list' || readingPaneMode === 'horizontal'
            ? columns.filter(({ visible }) => visible)
            : columns
        }
        items={list}
        selectedId={
          queryParams.type === 'notice'
            ? `${itemId ?? ''}_notice`
            : `${itemId ?? ''}`
        }
        onFileDownload={handleFileDownload}
        onItemClick={handleListItemClick}
        onItemCheckedChange={handleListItemCheckedChange}
        onItemImportanceChange={handleListItemImportanceChange}
      />
    );
    const viewComponent = (viewType: 'full' | 'split') => {
      if (itemId && isSecure)
        return (
          <div
            className="ui-view-root"
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto',
            }}
          >
            이 글은 보안 게시글입니다.{' '}
            <strong>비밀번호를 입력하여 주세요.</strong>
            <div
              style={{
                display: 'flex',
                flexDirection: display === 'phone' ? 'column' : 'row',
                marginTop: '20px',
              }}
            >
              <TextField
                label="비밀번호"
                type="password"
                value={state.securePassword}
                onChange={handleChangeSecurePassword}
              />
              <Button
                style={{ margin: '5px' }}
                text="확인"
                variant="outlined"
                onClick={handleConfirmSecure}
              />
            </div>
          </div>
        );
      return (
        <BoardContentBodyView
          pathname={pathname}
          search={search}
          type={viewType}
        />
      );
    };

    switch (readingPaneMode) {
      // 좌우분할.
      case 'vertical':
        if (!list) return null;
        result = (
          <EuiBody>
            <SplitPane
              className="ui-split eui-post"
              split="vertical"
              minSize={344}
              maxSize={560}
              defaultSize={parseInt(
                localStorage.getItem('approval-split-vertical-sizes') || '400',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem('approval-split-vertical-sizes', `${size}`)
              }
            >
              {listComponent}
              <>
                {renderHead({ type: 'view' })}
                {viewComponent('split')}
              </>
            </SplitPane>
          </EuiBody>
        );
        break;
      // 상하 분할.
      case 'horizontal':
        if (!list) return null;
        result = (
          <EuiBody>
            <SplitPane
              className="ui-split eui-post"
              split="horizontal"
              minSize={150}
              defaultSize={parseInt(
                localStorage.getItem('approval-split-horizontal-sizes') ||
                  '250',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem(
                  'approval-split-horizontal-sizes',
                  `${size}`,
                )
              }
            >
              {listComponent}
              <>
                {renderHead({ type: 'view' })}
                {viewComponent('full')}
              </>
            </SplitPane>
          </EuiBody>
        );
        break;
      // 분할 안함.
      default:
        // 뷰인 경우.
        if (itemId) {
          result = <EuiBody>{viewComponent('full')}</EuiBody>;
        } else {
          result = <EuiBody>{listComponent}</EuiBody>;
        }
        break;
    }

    return result;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    if (drawerType === 'listSetting') {
      const listColumns = columns.filter(
        ({ name }) =>
          name !== 'checked' && name !== 'importance' && name !== 'thumbnail',
      );
      return (
        <ListSetting
          module="board"
          isDisableModeOption={folderId === 'temp'}
          type={readingPaneMode}
          readingPaneMode={readingPaneMode}
          density={displayDensity}
          listCount={`${rowsPerPage}`}
          columns={listColumns}
          onChangeDensity={handleChangeDensity}
          onReadingPaneModeChange={handleReadingPaneModeChange}
          onColumnVisibleChange={handleCloumnVisibleChange}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
          pathname="board"
        />
      );
    }

    if (drawerType === 'viewer')
      return (
        <BoardViewerDrawer
          type="viewer"
          viewId={view?.id ?? 0}
          title="조회 확인"
          onClose={handleCloseDrawer}
        />
      );

    if (drawerType === 'likes')
      return (
        <BoardViewerDrawer
          type="likes"
          viewId={view?.id ?? 0}
          title="좋아요 확인"
          onClose={handleCloseDrawer}
        />
      );

    return null;
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    // 인쇄.
    if (dialogType === 'prints' || dialogType === 'print') {
      let listId:
        | {
            id: number;
            updateAt: string;
          }[]
        | undefined;
      if (dialogType === 'print' && itemId && view) {
        listId = [
          {
            id: view.id,
            updateAt: view.updateAt,
          },
        ];
      } else if (dialogType === 'prints') {
        listId = list
          .filter((x) => x.checked)
          .map((a) => {
            return {
              id: a.id,
              updateAt: a.updateAt,
            };
          });
      }
      if (listId === undefined) return null;

      return <BoardPrintView listId={listId} onClose={handleCloseDialog} />;
    }

    // 단일 삭제.
    if (dialogType === 'remove') {
      if (!view) return null;
      return (
        <Confirmation
          noDuplication={state.validation === ''}
          submitColor="danger"
          submitLabel="삭제"
          onCancel={handleCloseDialog}
          onSubmit={handleDelete}
        >
          <strong>&apos;{view.subject}&apos;</strong> 을(를) 정말
          삭제하시겠습니까?
        </Confirmation>
      );
    }
    // 다중 삭제.
    if (dialogType === 'removes') {
      const deleteList = list.filter((a) => a.checked);
      const message =
        deleteList.length === 1 ? (
          <>
            <strong>&apos;{deleteList[0].subject}&apos;</strong> 을(를) 정말
            삭제하시겠습니까?
          </>
        ) : (
          <>
            <strong>&apos;{deleteList[0].subject}&apos;</strong> 외{' '}
            {deleteList.length - 1}건을 정말 삭제하시겠습니까?
          </>
        );
      return (
        <Confirmation
          noDuplication={state.validation === ''}
          submitColor="danger"
          submitLabel="삭제"
          onCancel={handleCloseDialog}
          onSubmit={handleDeletes}
        >
          {message}
        </Confirmation>
      );
    }

    // 이동
    if (dialogType === 'move' || dialogType === 'listMove') {
      return (
        <TreePicker
          title="폴더 이동"
          disabledId={folderId && !isFixedFolder ? b62(folderId) : undefined}
          list={foldersTreeItems}
          onSelected={(id) => handleMoveFolderSelect(id)}
          onClose={handleCloseDialog}
        />
      );
    }

    if (state.folderConfirmSelect)
      return (
        <PromptMessage
          onSubmit={handleMoveFolder}
          onCancel={handleMoveFolderClose}
        >
          게시글 폴더 이동 시 현재 게시글의 말머리는 삭제됩니다. 정말
          이동하시겠습니까?
        </PromptMessage>
      );

    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDrawer()}
      {renderDialog()}
      <FeedBack
        text={state.validation}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            validation: '',
          }))
        }
      />
    </>
  );
}

export default BoardContentContainer;
