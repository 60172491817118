/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomNumbers, LocateArg } from '../../groupware-common/types';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
} from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import formFolderApi, { formApi } from '../apis/board/v1/form';

const name = 'board/form';

export interface FolderItem {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updaterId: number;
  createAt: string;
  updateAt: string;
}

export interface FormListItem {
  checked: boolean;
  id: number;
  folderId: number;
  status: number;
  name: string;
  path: string;
  description: string;
  createAt: string;
  updateAt: string;
}

export interface FormItem {
  companyId: number;
  id: number;
  folderId: number;
  status: number;
  name: string;
  description: string;
  contents: string;
  createAt: string;
  updateAt: string;
}

interface State {
  folder: {
    list: FolderItem[];
  };
  list: FormListItem[];
  totalCount: number;
  item: FormItem | undefined;
}

const initialState: State = {
  folder: {
    list: [],
  },
  list: [],
  totalCount: 0,
  item: undefined,
};

/** 양식 폴더 조회. */
const findFolderList = createAsyncThunk(
  `${name}/findFolderList`,
  async (arg: { parentId?: number } & LocateArg, { rejectWithValue }) => {
    try {
      const data = await formFolderApi.folderList({
        parentid: arg.parentId,
        pageno: 1,
        rowsperpage: CustomNumbers.SMALLINT_MAX,
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 폴더 등록. */
const createFolder = createAsyncThunk(
  `${name}/createFolder`,
  async (
    arg: {
      name: string;
      parentId: number;
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const data = await formFolderApi.createFolder({
        name: arg.name,
        parentId: arg.parentId,
      });

      const oldPath =
        arg.route?.pathname ?? (getState() as RootState).session.route.pathname;

      const pathname = `${getPathMap('/*/*/*', oldPath)}/${b62(data.id)}`;
      dispatch(
        findFolderList({
          route: {
            pathname,
          },
        }),
      );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 폴더 수정. */
const updateFolder = createAsyncThunk(
  `${name}/updateFolder`,
  async (
    arg: {
      id: number;
      name: string;
      parentId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await formFolderApi.updateFolder({
        id: arg.id,
        name: arg.name,
        parentId: arg.parentId,
        updateAt: arg.updateAt,
      });

      dispatch(findFolderList({}));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 폴더 삭제. */
const deleteFolder = createAsyncThunk(
  `${name}/deleteFolder`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await formFolderApi.deleteFolder({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      dispatch(findFolderList({}));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 폴더 순서변경. */
const sortFolder = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await formFolderApi.sortFolder(arg.data);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 목록 조회. */
const findFormList = createAsyncThunk(
  `${name}/findFormList`,
  async (
    arg: {
      folderId?: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const queryParams = getQueryParams(arg.route?.search ?? '');
    try {
      const items = (
        await formApi.formList({
          folderid: arg.folderId,
          pageno: queryParams.pageNo ?? 1,
          rowsperpage: queryParams.rowsPerPage ?? 15,
          searchcode: queryParams.searchCode,
          searchword: queryParams.searchWord,
        })
      ).map((a) => ({
        checked: false,
        ...a,
      }));
      const totalCount = await formApi.formTotalCount({
        folderid: arg.folderId,
        searchcode: queryParams.searchCode,
        searchword: queryParams.searchWord,
      });

      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 단일 조회. */
const findForm = createAsyncThunk(
  `${name}/findForm`,
  async (
    arg: {
      id: number;
      updateAt?: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const item = await formApi.form({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      return item;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 등록. */
const createForm = createAsyncThunk(
  `${name}/createForm`,
  async (
    arg: {
      data: {
        folderId: number;
        status: boolean;
        name: string;
        description: string;
        content: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const data = {
        folderId: arg.data.folderId,
        status: arg.data.status ? 1 : 2,
        name: arg.data.name,
        description: arg.data.description,
        contents: arg.data.content,
      };
      await formApi.createForm(data);
      if (arg.route) {
        dispatch(findFormList({ folderId: data.folderId, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 수정. */
const updateForm = createAsyncThunk(
  `${name}/updateForm`,
  async (
    arg: {
      data: {
        id: number;
        folderId: number;
        status: boolean;
        name: string;
        description: string;
        content: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const data = {
        id: arg.data.id,
        folderId: arg.data.folderId,
        status: arg.data.status ? 1 : 2,
        name: arg.data.name,
        description: arg.data.description,
        contents: arg.data.content,
        updateAt: arg.data.updateAt,
      };
      await formApi.updateForm(data);
      if (arg.route) {
        const { folderId } = getPathParams<{ folderId: number }>(
          '/*/*/*/:folderId$base62/*',
          arg.route.pathname,
        );
        dispatch(findFormList({ folderId, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 삭제. */
const deleteForm = createAsyncThunk(
  `${name}/deleteForm`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const result = await formApi.deleteForm(arg.data);
      if (Array.isArray(result) && result.length !== arg.data.length) {
        if (arg.route) {
          const { folderId } = getPathParams<{ folderId: number }>(
            '/*/*/*/:folderId$base62/*',
            arg.route.pathname,
          );
          dispatch(findFormList({ folderId, route: arg.route }));
          return rejectWithValue(
            appError({
              error:
                '사용 중인 양식이 존재하여 일부 양식은 삭제 실패하였습니다.',
            }),
          );
        }
      }
      if (arg.route) {
        const { folderId } = getPathParams<{ folderId: number }>(
          '/*/*/*/:folderId$base62/*',
          arg.route.pathname,
        );
        dispatch(findFormList({ folderId, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 양식 폴더 이동. */
const moveForm = createAsyncThunk(
  `${name}/moveForm`,
  async (
    arg: {
      data: {
        id: number;
        folderId: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await formApi.moveForm(arg.data);
      if (arg.route) {
        const { folderId } = getPathParams<{ folderId: number }>(
          '/*/*/*/:folderId$base62/*',
          arg.route.pathname,
        );
        dispatch(findFormList({ folderId, route: arg.route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const formSlice = createSlice({
  name,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{
        id: number | 'all' | number[];
        checked: boolean;
      }>,
    ) {
      const { id, checked } = action.payload;
      if (state.list) {
        if (id === 'all') {
          state.list = state.list.map((x) => {
            if (x.checked === checked) return x;
            return { ...x, checked };
          });
        }
        if (Array.isArray(id)) {
          state.list = state.list.map((x) =>
            id.indexOf(x.id) > -1 ? { ...x, checked } : { ...x },
          );
        } else {
          const index = state.list.findIndex((x) => x.id === id);
          if (index > -1)
            state.list[index] = {
              ...state.list[index],
              checked,
            };
        }
      }
    },
    formListClear(state) {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        state.folder.list = payload;
      })
      .addCase(sortFolder.fulfilled, (state, { payload }) => {
        state.folder.list = state.folder.list.map((a) => {
          const data = payload.find(({ id }) => id === a.id);
          if (data)
            return {
              ...a,
              seq: data.seq,
              updateAt: data.updateAt,
            };
          return a;
        });
      })
      .addCase(findFormList.fulfilled, (state, { payload }) => {
        state.list = payload.items;
        state.totalCount = payload.totalCount;
      })
      .addCase(findForm.fulfilled, (state, { payload }) => {
        state.item = payload;
      });
  },
});

export default formSlice.reducer;

export const formActions = {
  formChecked: formSlice.actions.checked,
  formListClear: formSlice.actions.formListClear,
  formList: findFormList,
  form: findForm,
  createForm,
  updateForm,
  deleteForm,
  moveForm,
};

export const formFolderActions = {
  folderList: findFolderList,
  create: createFolder,
  update: updateFolder,
  delete: deleteFolder,
  sort: sortFolder,
};
