import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldIcon(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <ComponentSampleUI.Content title="Align">
      <TextField
        value={value}
        placeholder="left"
        onChange={handleChange}
        align="left"
        width={150}
      />
      <TextField
        value={value}
        placeholder="center"
        onChange={handleChange}
        align="center"
        width={150}
      />
      <TextField
        value={value}
        placeholder="right"
        onChange={handleChange}
        align="right"
        width={150}
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldIcon;
