import React from 'react';

function BreadcrumbsItem(props: {
  text?: string;
  current?: boolean;
  onClick?(): void;
  children?: React.ReactNode;
}): JSX.Element {
  const { text, current, onClick, children } = props;

  const handle = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (onClick) onClick();
  };

  let content: React.ReactNode;
  if (text) {
    if (current) {
      content = text;
    } else {
      content = (
        <a href="#" type="button" onClick={handle} className="link">
          {text}
        </a>
      );
    }
  } else content = children;

  let classname = 'breadcrumbs-li';
  if (current) classname += ' current';

  return (
    <li className={classname}>
      {current ? (
        content
      ) : (
        <>
          {content}
          <span className="separator">&gt;</span>
        </>
      )}
    </li>
  );
}

function Breadcrumbs(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let className = 'eui-breadcrumbs';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <ol className="breadcrumbs-ol">{props.children}</ol>
    </div>
  );
}

Breadcrumbs.Item = BreadcrumbsItem;

export default Breadcrumbs;
