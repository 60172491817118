import React from 'react';
import { contrastColor } from '../../groupware-common/utils/ui';
import Avatar from '../avatar/Avatar';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';
import Tooltip from '../tooltip/Tooltip';

/**
 * @property label 라벨
 * @property avatar 아바타
 * @property icon 아이콘
 * @property badge 라벨 앞에 붙는 값
 * @property etc 라벨 뒤에 붙는 값
 * @property color 색상
 * @property size 사이즈
 * @property disabled 비활성화
 * @property onDelete 삭제 이벤트
 */

function Chip(props: {
  index?: number;
  ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  label: string;
  avatar?: string;
  icon?: IconType;
  status?: {
    label: string;
    icon?: IconType;
  };
  etc?: string;
  theme?: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  style?: React.CSSProperties;
  color?: string;
  maxWidth?: string;
  fontWeight?: number | 'bold';
  size?: 'sm' | 'xs';
  selected?: boolean;
  disabled?: boolean;
  tooltip?: string;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onDelete?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  // eslint-disable-next-line prettier/prettier
  const { label, color, maxWidth, fontWeight, selected, disabled } = props;

  let classname = 'eui-chip';
  if (props.className) classname += ` ${props.className}`;
  if (props.size) classname += ` ${props.size}`;
  if (props.theme) classname += ` ${props.theme}`;
  if (selected) classname += ' selected';
  if (color) classname += ' color';
  if (props.onClick) classname += ' control';

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (props.onDelete) props.onDelete(event);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disabled && props.onClick) props.onClick(event);
  };

  const content = (
    <>
      {props.avatar && (
        <div
          style={
            props.status?.icon
              ? { position: 'relative', marginRight: '6px' }
              : undefined
          }
        >
          <Avatar name={label} image={props.avatar} className="avatar" />
          {props.status && props.status.icon && (
            <Icon
              className={`status ${props.status.label}`}
              icon={props.status.icon}
            />
          )}
        </div>
      )}
      {props.icon && <Icon icon={props.icon} className="icon" />}
      {props.tooltip ? (
        <Tooltip title={props.tooltip}>
          <span className="text">
            <span className="label">{label}</span>
          </span>
        </Tooltip>
      ) : (
        <span className="text">
          <span className="label">{label}</span>
        </span>
      )}
      {props.etc !== undefined && <em className="etc">{props.etc}</em>}
      {props.onDelete && (
        <button
          type="button"
          className="delete"
          onClick={handleDelete}
          aria-label="삭제"
        />
      )}
    </>
  );

  let { style } = props;
  if (color)
    style = {
      ...style,
      backgroundColor: color,
      color: contrastColor(color),
    };
  if (maxWidth) style = { ...style, maxWidth };
  if (fontWeight) style = { ...style, fontWeight };
  return props.onClick ? (
    <div
      role="button"
      className={classname}
      style={style}
      aria-disabled={disabled}
      onClick={handleClick}
      data-chip-index={props.index}
      tabIndex={0}
      ref={props.ref}
    >
      {content}
    </div>
  ) : (
    <div
      className={classname}
      style={style}
      aria-checked={selected}
      aria-disabled={disabled}
      data-chip-index={props.index}
      ref={props.ref}
    >
      {content}
    </div>
  );
}

export default Chip;
