import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  b62,
  getPathMap,
  getPathParams,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { attendancesActions } from '../../../../stores/attendance/attendances';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceContentRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);

  useEffect(() => {
    let mount = true;
    const pathmap = getPathMap('/*', pathname);
    const { folderId, itemId } = getPathParams<{
      folderId?: string;
      itemId?: number;
    }>(`/*/:folderId/:itemId$base62`, pathname);

    if (mount) {
      Promise.all([
        dispatch(attendancePreferencesActions.attendanceCodeList()), // 근태코드 리스트
      ]).then(() => {
        if (folderId !== undefined && itemId !== undefined)
          dispatch(
            attendancesActions.view({
              id: itemId,
              route: {
                pathname: `${pathmap}/${folderId}/${b62(itemId)}`,
                search,
              },
            }),
          );
        else if (folderId !== undefined) {
          dispatch(
            attendancesActions.list({
              employeeId: principal.employeeId,
              search,
              route: {
                pathname: `${pathmap}/${folderId}`,
                search,
              },
            }),
          );
        }
      });
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default AttendanceContentRoute;
