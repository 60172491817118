/* eslint-disable consistent-return */
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import api, {
  ApprovalLine,
  ApprovalLineView,
  NoticeRead,
} from '../../apis/approval/v1/index';
import { apiError } from '../../../groupware-common/apis/common/v1';
import { RootState } from '../../../groupware-webapp/app/store';
import {
  approvalLineGenerateId,
  approvalLineToJson,
  jsonToApprovalLine,
} from './document';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../pages/common/dialogs/ApprovalLineDialogContainer';

/** 사용자 환경설정 */
const name = 'approval/userPreferences';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  userPreferences: {
    companyId: number;
    employeeId: number;
    useSurrogate: boolean;
    surrogateStartDate: string;
    surrogateEndDate: string;
    surrogateEmployeeId: number;
    surrogateOrganizationId: number;
    useSecurity: boolean;
    autosave: number;
    signaturePath: string;
    usePrintInfo: boolean;
    usePrintOpinion: boolean;
    usePrintComment: string;
    createAt: string;
    updateAt: string;
  };
  userPreferencesNotice: NoticeRead;

  userPreferencesFolder: {
    id: number;
    companyId: number;
    employeeId: number;
    status: number;
    seq: number;
    name: string;
    description: string;
    size: number;
    updateAt: string;
  }[];

  userPreferencesApproval: {
    list: ApprovalLine[];
    view: ApprovalLineView | undefined;
  };
}

const initialState: State = {
  requests: [],
  userPreferences: {
    companyId: 0,
    employeeId: 0,
    useSurrogate: false,
    surrogateStartDate: '',
    surrogateEndDate: '',
    surrogateEmployeeId: 0,
    surrogateOrganizationId: 0,
    useSecurity: false,
    autosave: 0,
    signaturePath: '',
    usePrintInfo: false,
    usePrintOpinion: false,
    usePrintComment: '',
    createAt: '',
    updateAt: '',
  },
  userPreferencesNotice: {
    companyId: 0,
    employeeId: 0,
    noticeEmail: '',
    noticeRequest: 0,
    noticeReturn: 0,
    noticePreviousReturn: 0,
    noticeHold: 0,
    noticeInterview: 0,
    noticeDefer: 0,
    noticeCompleted: 0,
    noticeApprovalPermissionDocumentCancel: 0,
    noticeReferencePermissionDocumentCancel: 0,
    noticeViewPermissionDocumentCancel: 0,
    noticeApprovalPermissionDocumentChange: 0,
    noticeReferencePermissionDocumentChange: 0,
    noticeViewPermissionDocumentChange: 0,
    noticeReferrer: 0,
    noticeViewer: 0,
    noticeOpinion: 0,
    noticeOpinionReply: 0,
    noticeComment: 0,
    noticeCommentReply: 0,
    createAt: '',
    updateAt: '',
  },

  userPreferencesFolder: [
    {
      id: 0,
      companyId: 0,
      employeeId: 0,
      status: 0,
      seq: 0,
      name: '',
      description: '',
      size: 0,
      updateAt: '',
    },
  ],
  userPreferencesApproval: {
    list: [
      {
        id: 0,
        seq: 0,
        name: '',
        updateAt: '',
        workChange: false,
      },
    ],
    view: {
      id: 0,
      workId: 0,
      workName: '',
      name: '',
      workChange: false,
      approvalLine: { version: '0.1', groups: [] },
      referencePermission: { version: '0.1', groups: [] },
      viewPermission: { version: '0.1', groups: [] },
      createAt: '',
      updateAt: '',
      workUpdateAt: '',
    },
  },
};

const fetchUserPreferences = createAsyncThunk(
  `${name}/fetchUserPreferences`,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.fetchUserPreferencesApi();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 알림설정 조회 */
const fetchUserPreferencesNotice = createAsyncThunk(
  `${name}/fetchUserPreferencesNotice`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      const noticeEmail = (getState() as RootState).session.principal.email;
      const response = await api.fetchUserPreferencesNoticeApi();
      if (response.noticeEmail === '') {
        return {
          ...response,
          noticeEmail,
        };
      }

      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 대결 지정 저장 */
const savefetchUserPreferences1 = createAsyncThunk(
  `${name}/savefetchUserPreferences1`,
  async (
    arg: {
      useSurrogate: boolean;
      surrogateStartDate: string;
      surrogateEndDate: string;
      surrogateEmployeeId: number;
      surrogateOrganizationId: number;
      useSecurity: boolean;
      autosave: number | boolean;
      usePrintInfo: boolean;
      usePrintOpinion: boolean;
      updateAt: string;
      signature?: string | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      // console.log(' savefetchUserPreferences1 start :');
      await api.savefetchUserPreferencesApi1(arg);
      const response = await api.fetchUserPreferencesApi();
      return response;
    } catch (ex) {
      return rejectWithValue(apiError(ex));
    }
  },
);

/** 개인 알림설정 저장 */
const savefetchUserPreferencesNotice = createAsyncThunk(
  `${name}/savefetchUserPreferencesNotice`,
  async (
    arg: {
      noticeEmail: string;
      noticeRequest: number;
      noticeReturn: number;
      noticePreviousReturn: number;
      noticeHold: number;
      noticeInterview: number;
      noticeDefer: number;
      noticeCompleted: number;
      noticeApprovalPermissionDocumentCancel: number;
      noticeReferencePermissionDocumentCancel: number;
      noticeViewPermissionDocumentCancel: number;
      noticeApprovalPermissionDocumentChange: number;
      noticeReferencePermissionDocumentChange: number;
      noticeViewPermissionDocumentChange: number;
      noticeReferrer: number;
      noticeViewer: number;
      noticeOpinion: number;
      noticeOpinionReply: number;
      noticeComment: number;
      noticeCommentReply: number;
      updateAt: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      // console.log(' savefetchUserPreferencesNotice(arg)', arg);
      await api.savefetchUserPreferencesNoticeApi(arg);
      const response = await api.fetchUserPreferencesNoticeApi();
      if (response.noticeEmail === '') {
        const noticeEmail = (getState() as RootState).session.principal.email;
        return {
          ...response,
          noticeEmail,
        };
      }
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재함 전체 조회 */
const fetchPreferencesFolder = createAsyncThunk(
  `${name}/fetchPreferencesFolder`,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.fetchPreferencesFolderApi();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재함 등록 */
const savefetchPreferencesFolder = createAsyncThunk(
  `${name}/savefetchPreferencesFolder`,
  async (
    arg: {
      status: number;
      name: string;
      description: string;
    },
    { rejectWithValue },
  ) => {
    try {
      // console.log(' savefetchPreferencesFolder start :');
      await api.savefetchPreferencesFolderApi(arg);
      const response = await api.fetchPreferencesFolderApi();
      return response;
    } catch (ex) {
      return rejectWithValue(apiError(ex));
    }
  },
);

/** 개인 결재함 수정 */
const modefetchPreferencesFolder = createAsyncThunk(
  `${name}/modefetchPreferencesFolder`,
  async (
    arg: {
      id: number;
      status: number;
      name: string;
      description: string;
      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      // console.log(' modefetchPreferencesFolder start :');
      await api.modefetchPreferencesFolderApi(arg);
      const response = await api.fetchPreferencesFolderApi();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재함 삭제 */
const deletefetchPreferencesFolder = createAsyncThunk(
  `${name}/deletefetchPreferencesFolder`,
  async (
    arg: {
      id: number;
      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      // console.log(' deletefetchPreferencesFolder start :');
      await api.deletefetchPreferencesFolderApi(arg);
      const response = await api.fetchPreferencesFolderApi();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재함 순서 변경 */
const modefetchPreferencesFolderSeq = createAsyncThunk(
  `${name}/modefetchPreferencesFolderSeq`,
  async (
    arg: {
      id: number;
      seq: number;
      updateAt: string;
    }[],
    { rejectWithValue },
  ) => {
    try {
      // console.log(' modefetchPreferencesFolderSeq start :');
      await api.modefetchPreferencesFolderSeqApi(arg);
      const response = await api.fetchPreferencesFolderApi();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재선 등록 */
const savePreferencesApprovalLine = createAsyncThunk(
  `${name}/savePreferencesApprovalLine`,
  async (
    arg: {
      id?: number;
      approvalLineName: string;
      workId: number;
      updateAt?: string;
      workUpdateAt: string;
      approvalLine: ApprovalLineType;
      referencePermission?: SharePermissionType;
      viewPermission?: SharePermissionType;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { id, approvalLineName, workId, workUpdateAt, updateAt } = arg;

      const approvalLine = approvalLineToJson(
        approvalLineGenerateId(arg.approvalLine),
      );
      const referencePermission =
        arg.referencePermission !== undefined
          ? JSON.stringify(arg.referencePermission)
          : '{}';
      const viewPermission =
        arg.viewPermission !== undefined
          ? JSON.stringify(arg.viewPermission)
          : '{}';

      const response = await api.savePreferencesApprovalLine({
        id,
        workId,
        updateAt,
        workUpdateAt,
        name: approvalLineName,
        approvalLine,
        referencePermission,
        viewPermission,
      });
      await dispatch(findPreferencesApproval({})).then(() => {
        dispatch(findPreferencesApprovalView({ id: response.id }));
      });
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재선 순서 수정 */
const updatePreferencesApprovalLineOrder = createAsyncThunk(
  `${name}/updatePreferencesApprovalLineOrder`,
  async (
    arg: {
      id: number;
      seq: number;
      updateAt: string;
    }[],
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.updatePreferencesApprovalLineOrder(arg);
      dispatch(findPreferencesApproval({}));
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재선 전체 조회 */
const findPreferencesApproval = createAsyncThunk(
  `${name}/findPreferencesApproval`,
  async (arg: { workId?: number }, { rejectWithValue }) => {
    try {
      const response = await api.PreferencesApprovalLineList();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재선 상세 조회 */
const findPreferencesApprovalView = createAsyncThunk(
  `${name}/findPreferencesApprovalView`,
  async (arg: { id: number }, { rejectWithValue }) => {
    try {
      const { id } = arg;
      const response = await api.PreferencesApprovalLineView(id);
      const data = {
        ...response,
        approvalLine: jsonToApprovalLine(response.approvalline),
        referencePermission:
          response.referencePermission !== '{}'
            ? JSON.parse(response.referencePermission)
            : undefined, // json 참조자',
        viewPermission:
          response.viewPermission !== '{}'
            ? JSON.parse(response.viewPermission)
            : undefined,
      };
      return data;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 개인 결재선 삭제 */
const deletePreferencesApproval = createAsyncThunk(
  `${name}/deletePreferencesApproval`,
  async (
    arg: {
      id: number;
      updateAt: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await api.deletePreferencesApprovalLine(arg);
      dispatch(findPreferencesApproval({}));
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

const userPreferencesSlice = createSlice({
  name,
  initialState,
  reducers: {
    ApprovalLineViewClear(state) {
      state.userPreferencesApproval.view = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPreferences.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferences = action.payload;
        }
      })
      .addCase(fetchUserPreferencesNotice.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesNotice = action.payload;
        }
      })
      .addCase(savefetchUserPreferences1.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferences = action.payload;
        }
      })
      .addCase(savefetchUserPreferencesNotice.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesNotice = action.payload;
        }
      })
      .addCase(fetchPreferencesFolder.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesFolder = action.payload;
        }
      })
      .addCase(savefetchPreferencesFolder.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesFolder = action.payload;
        }
      })
      .addCase(modefetchPreferencesFolder.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesFolder = action.payload;
        }
      })
      .addCase(deletefetchPreferencesFolder.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesFolder = action.payload;
        }
      })
      .addCase(modefetchPreferencesFolderSeq.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesFolder = action.payload;
        }
      })
      .addCase(findPreferencesApproval.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.userPreferencesApproval.list = action.payload;
          state.userPreferencesApproval.view = undefined;
        }
      })
      .addCase(findPreferencesApprovalView.fulfilled, (state, action) => {
        if (action.payload !== undefined)
          state.userPreferencesApproval.view = action.payload;
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default userPreferencesSlice.reducer;

export const approvaluserPreferencesActions = {
  fetchUserPreferences,
  fetchUserPreferencesNotice,
  savefetchUserPreferences1,
  savefetchUserPreferencesNotice,
  savefetchPreferencesFolder,
  fetchPreferencesFolder,
  modefetchPreferencesFolder,
  deletefetchPreferencesFolder,
  modefetchPreferencesFolderSeq,

  findPreferencesApproval,
  findPreferencesApprovalView,
  savePreferencesApprovalLine,
  deletePreferencesApproval,
  updatePreferencesApprovalLineOrder,
  viewClear: userPreferencesSlice.actions.ApprovalLineViewClear,
};
