import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Checkbox from '../../../../../components/checkbox/Checkbox';

function ComponentCheckboxBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic checkboxes">
      <Checkbox />
      <Checkbox checked />
      <Checkbox disabled />
      <Checkbox checked disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentCheckboxBasic;
