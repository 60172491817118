import React, { useState } from 'react';
import Button from '../../../components/button/Button';
import { TreeItem } from '../../../groupware-common/types';
import DialogHeader from '../../../components/dialog/DialogHeader';
import DialogTitle from '../../../components/dialog/DialogTitle';
import DialogBody from '../../../components/dialog/DialogBody';
import Tree from '../../../components/tree/Tree';
import DialogFooter from '../../../components/dialog/DialogFooter';
import Dialog from '../../../components/dialog/Dialog';
import { getParentItems } from '../../../groupware-common/utils';
import FeedBack from '../../../components/alert/FeedBack';

type Props = {
  /** 팝업타이틀 */
  title: string;
  /** "full" 아이템 하위에 아이템이 있을때 부모 아이템 선택 금지 */
  type?: 'full';
  /** tree 데이터 */
  list: TreeItem[];
  /** 선택 불가한 아이템 아이디 */
  disabledId?: number;
  /** 선택된 아이템 아이디 */
  selectedId?: number;
  /** 루트 아이디 */
  rootId?: number;
  /** 선택한 아이템의 id,text,path 값 반환 */
  onSelected(id: number, text: string, path: string[]): void;
  onClose(): void;
};

/**
 * 트리 선택
 *
 * - [title] 팝업타이틀
 * - [type] "group" 아이템 하위에 아이템이 있을때 부모 아이템 선택 금지
 * - [list] tree 데이터
 * - [disabledId] 선택 불가한 아이템 아이디
 * - [selectedId] 선택된 아이템 아이디
 * - [rootId] 루트 아이디
 * - [onSelected] 선택한 아이템의 id,text,path 값 반환
 * */
function TreePicker(props: Props): JSX.Element {
  const [state, setState] = useState({
    selectedId: props.selectedId,
    alertMessage: '',
  });

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      alertMessage: '',
    }));
  };

  const handleSelectedId = (id: number) => {
    // console.log(`handleSelectedId(id: ${id})`);
    setState((prevState) => ({
      ...prevState,
      selectedId: id,
    }));
  };

  const handleSelected = (id: number | undefined) => {
    // console.log(`handleSelected(id: ${id})`);
    if (id === undefined) {
      setState((prevState) => ({
        ...prevState,
        alertMessage: '선택된 아이템이 없습니다.',
      }));
      return;
    }
    const { list, onSelected } = props;
    const parentNames = getParentItems(list, id).map(({ text }) => text);
    onSelected(id, parentNames[parentNames.length - 1], parentNames);
  };

  const { title, type, list, disabledId, onClose } = props;
  const { selectedId, alertMessage } = state;

  return (
    <>
      <Dialog size="xs">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Tree
            type={type}
            disabledId={disabledId}
            selectedId={selectedId}
            rootId={props.rootId}
            items={list}
            onItemClick={handleSelectedId}
          />
        </DialogBody>
        <DialogFooter>
          <Button text="취소" onClick={onClose} color="secondary" />
          <Button
            text="선택"
            variant="contained"
            onClick={() => handleSelected(selectedId)}
            disabled={selectedId === undefined}
          />
        </DialogFooter>
      </Dialog>
      <FeedBack text={alertMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default React.memo(TreePicker);
