import React, { ForwardedRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../icon/Icon';
import { IconType } from '../../groupware-common/types/icon';
import Tooltip from '../tooltip/Tooltip';
import { RootState } from '../../groupware-webapp/app/store';

const Button = React.forwardRef(
  (
    props: {
      noDuplication?: boolean; // 중복 방지 버튼 사용할 경우.
      timeOut?: number; // 중복 방지 버튼 사용할 경우.
      dataAction?: string;
      parentDocument?: boolean;
      submit?: boolean;
      text?: string;
      icon?: IconType;
      iconType?: boolean;
      iconStyle?: React.CSSProperties;
      variant?: 'contained' | 'outlined';
      dropdown?: boolean;
      block?: boolean;
      color?: 'danger' | 'secondary' | 'inherit';
      size?: 'xs' | 'sm';
      className?: string;
      disabled?: boolean;
      loading?: boolean;
      clip?: boolean;
      pressed?: boolean;
      tooltip?: NonNullable<React.ReactNode>;
      placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
      vibrate?: boolean;
      children?: React.ReactNode;
      onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
      style?: React.CSSProperties;
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => {
    const errors = useSelector((state: RootState) => state.session.errors);

    let classname = 'eui-button';
    if (props.className) classname += ` ${props.className}`;
    if (props.iconType) classname += ' icon';
    if (props.variant === undefined && props.iconType !== true)
      classname += ' text';
    if (props.dropdown) classname += ' dropdown';
    if (props.variant !== undefined) classname += ` ${props.variant}`;
    if (props.color !== undefined) classname += ` ${props.color}`;
    if (props.size !== undefined) classname += ` ${props.size}`;
    if (props.loading || props.noDuplication) classname += ' loading';
    if (props.block) classname += ' block';
    if (props.clip) classname += ' clip';
    if (props.submit) classname += ' submit';
    if (props.parentDocument) classname += ' parentDocument';

    const [state, setState] = useState<{
      tooltip: boolean;
      loading: boolean;
    }>({
      tooltip: false,
      loading: false,
    });

    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      setState((prev) => ({ ...prev, loading: true }));
      if (props.onClick) props.onClick(event);
      if (props.vibrate) {
        if ('vibrate' in navigator) {
          window.navigator.vibrate(8);
        }
      }
      // 타임 아웃 지정 시 해당 시간 후 loading = false, disabled 해제
      if (props.noDuplication && props.timeOut)
        setTimeout(() => {
          setState((prev) => ({ ...prev, loading: false }));
        }, props.timeOut);
      tooltipClose();
    };

    const tooltipOpen = () => {
      setState((prevState) => ({
        ...prevState,
        tooltip: true,
      }));
    };

    const tooltipClose = () => {
      setState((prevState) => ({
        ...prevState,
        tooltip: false,
      }));
    };

    useEffect(() => {
      if (errors.length !== 0 || props.noDuplication)
        setState((prev) => ({ ...prev, loading: false }));
    }, [errors, props.noDuplication]);

    const button = (
      <button
        data-action={props.dataAction}
        type={props.submit ? 'submit' : 'button'}
        className={classname}
        disabled={
          props.noDuplication
            ? props.disabled || state.loading
            : props.disabled || props.loading
        }
        onClick={handleClick}
        aria-pressed={props.pressed}
        aria-label={props.icon ? props.text : undefined}
        ref={ref}
        style={props.style}
      >
        {props.icon && <Icon icon={props.icon} style={props.iconStyle} />}
        {!props.iconType && !props.dataAction && <span>{props.text}</span>}
        {!props.iconType && props.dataAction && props.text}
        {props.children}
        {props.noDuplication
          ? state.loading && <Icon icon="spinner-third" className="spin" />
          : props.loading && <Icon icon="spinner-third" className="spin" />}
      </button>
    );

    return (
      <>
        {(props.iconType && props.icon !== 'close') ||
        props.tooltip !== undefined ? (
          <Tooltip
            title={props.tooltip || props.text || ''}
            placement={props.placement}
            open={state.tooltip}
            onClose={tooltipClose}
            onOpen={tooltipOpen}
          >
            {props.disabled ? (
              <span className="Mui-disabled-button">{button}</span>
            ) : (
              button
            )}
          </Tooltip>
        ) : (
          button
        )}
      </>
    );
  },
);

export default React.memo(Button);
