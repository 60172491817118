import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../components/data/EmptyData';
import Dialog from '../../../components/dialog/Dialog';
import DialogBody from '../../../components/dialog/DialogBody';
import DialogHeader from '../../../components/dialog/DialogHeader';
import DialogTitle from '../../../components/dialog/DialogTitle';
import EuiToolbar from '../../../components/layout/EuiToolbar';
import Loading from '../../../components/loading/Loading';
import PostList, { PostListItemType } from '../../../components/post/PostList';
import Pagination from '../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { Column } from '../../../groupware-common/ui/type';
import { getQueryParams } from '../../../groupware-common/utils';
import { dateTimeFormat, timeFormat } from '../../../groupware-common/utils/ui';
import { useDirectory } from '../../../groupware-directory/stores/directory';
import { RootState } from '../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../groupware-webapp/stores/common/utils';
import attendancesApi from '../../apis/attendance/v1/documents';
import AttendanceContentBodyListItem from '../root/attendance/content/AttendanceContentBodyListItem';

function AttendanceSubstituteDialog(props: {
  name: string; // 상태 구분 값. (발생, 사용, 조정일수)
  search: string;
  onClose(): void;
}): JSX.Element {
  const { name: detailType, search, onClose } = props;
  const queryParams = getQueryParams(search);
  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const { expressionUnit } = useSelector(
    (state: RootState) => state.attendance.preferences.basic,
  );

  let codeName = '사용';
  if (detailType === 'adjust') codeName = '조정';
  if (detailType === 'occur') codeName = '발생';

  const columns: Column<PostListItemType>[] = [
    // eslint-disable-next-line prettier/prettier
    { name: 'employeeNo', text: '사번', type: display === 'phone' ? 'post' : 'folder' },
    { name: 'user', text: '이름', type: 'user' },
    {
      name: 'range',
      text: '사용기간',
      type: display === 'phone' ? 'post' : 'number',
      visible: detailType === 'use',
    },
    // eslint-disable-next-line prettier/prettier
    { name: 'use', text: codeName, type: display === 'phone' ? 'post' : 'folder' },
    { name: 'reason', text: '사유', type: 'post' },
    { name: 'detailDate', text: `${codeName}일자`, type: 'date' },
  ];

  const [state, setState] = useState<{
    items:
      | {
          companyId: number;
          employeeId: number;
          startDate?: string;
          endDate?: string;
          useLeaves: string; // 사용일(분)수.
          remark: string; // 사유.
          detailDate: string; // 작성일자.
        }[]
      | undefined;
    totalCount: number;
    pageNo: number;
  }>({
    items: undefined,
    totalCount: 0,
    pageNo: 1,
  });

  useEffect(() => {
    async function run() {
      if (queryParams.id === undefined) return;
      const listParam = {
        employeeId: queryParams.id,
        standardYear: queryParams.status ?? new Date().getFullYear().toString(),
        pageNo: state.pageNo,
        rowsPerPage: 5,
      };
      const totalCountParam = {
        employeeId: queryParams.id,
        standardYear: queryParams.status ?? new Date().getFullYear().toString(),
      };

      let items: {
        companyId: number;
        employeeId: number;
        startDate?: string;
        endDate?: string;
        useLeaves: string;
        remark: string;
        detailDate: string;
      }[] = [];
      let totalCount = 0;
      if (detailType === 'use') {
        items = (await attendancesApi.substituteUseDetailList(listParam)).map(
          (a) => {
            return {
              companyId: principal.companyId,
              employeeId: a.employeeId,
              startDate: a.startDate,
              endDate: a.endDate,
              useLeaves: timeFormat(a.ammount, expressionUnit),
              remark: a.remark,
              detailDate: a.registerDate,
            };
          },
        );
        totalCount = await attendancesApi.substituteDetailTotalCount({
          detailType,
          ...totalCountParam,
        });
      } else {
        items = (
          await attendancesApi.substituteDetailList({
            detailType,
            ...listParam,
          })
        ).map((a) => {
          return {
            companyId: principal.companyId,
            employeeId: a.employeeId,
            useLeaves: timeFormat(a.ammount, expressionUnit),
            remark: a.remark,
            detailDate: dateTimeFormat(a.detailDate, 'yyyy-MM-DD'),
          };
        });
        totalCount = await attendancesApi.substituteDetailTotalCount({
          detailType,
          ...totalCountParam,
        });
      }
      setState((prev) => ({
        ...prev,
        items,
        totalCount,
      }));
    }

    run();
  }, [state.pageNo]);

  const handleAction = (code: string) => {
    if (code === 'prev') {
      const page = state.pageNo - 1;
      if (page < 1) return;
      setState((prev) => ({
        ...prev,
        pageNo: page,
      }));
    }
    if (code === 'next') {
      if (state.pageNo >= state.totalCount) return;
      const page = state.pageNo + 1;
      setState((prev) => ({
        ...prev,
        pageNo: page,
      }));
    }
  };

  const visibleColumns = columns.filter(
    (a) => a.visible || a.visible === undefined,
  );
  if (state.items?.length === 0 || state.items === undefined)
    return (
      <Dialog size="lg" onClose={onClose}>
        <DialogHeader>
          <DialogTitle>상세정보</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div style={{ margin: '20px', height: '315px' }}>
            {state.items === undefined && <Loading />}
            <EmptyData />
          </div>
        </DialogBody>
      </Dialog>
    );
  return (
    <Dialog size="md" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>상세정보</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <EuiToolbar>
          <EuiToolbar.Left>
            <></>
          </EuiToolbar.Left>
          <EuiToolbar.Right>
            <Pagination
              count={state.totalCount}
              no={state.pageNo}
              rows={5}
              onPrev={() => handleAction('prev')}
              onNext={() => handleAction('next')}
            />
          </EuiToolbar.Right>
        </EuiToolbar>
        <div style={{ margin: '20px', height: '315px' }}>
          <PostList name="상세정보" type="full">
            <PostList.Head>
              {visibleColumns.map(({ name, type: colunmType, text }) => (
                <PostList.Cell
                  key={name}
                  name={text}
                  type={colunmType}
                  align="center"
                />
              ))}
            </PostList.Head>
            <PostList.Body>
              {state.items.map((a, i) => {
                const employeeNo =
                  directory.employees.find(({ id }) => id === a.employeeId)
                    ?.no ?? ''; // 사번.
                const directoryData = getDirectoryData({
                  ...directory,
                  companyId: a.companyId,
                  employeeId: a.employeeId,
                });
                const key = `/i${i}/${a.companyId}/${a.employeeId}/}`;
                const range =
                  a.startDate && a.endDate
                    ? `${dateTimeFormat(
                        a.startDate,
                        'yyyy-MM-DD',
                      )} ~ ${dateTimeFormat(a.endDate, 'yyyy-MM-DD')}`
                    : undefined;
                const item = range
                  ? {
                      employeeNo,
                      user: {
                        name: directoryData.employeeName,
                        organization: directoryData.organizationName,
                        job: directoryData.jobPositionName,
                        avatar: directoryData.avatar ?? '',
                      },
                      range,
                      use: a.useLeaves,
                      reason: a.remark,
                      detailDate: a.detailDate,
                    }
                  : {
                      employeeNo,
                      user: {
                        name: directoryData.employeeName,
                        organization: directoryData.organizationName,
                        job: directoryData.jobPositionName,
                        avatar: directoryData.avatar ?? '',
                      },
                      use: a.useLeaves,
                      reason: a.remark,
                      detailDate: a.detailDate,
                    };
                return (
                  <AttendanceContentBodyListItem
                    organizationStatus
                    key={key}
                    id={a.employeeId}
                    columns={visibleColumns}
                    item={item}
                  />
                );
              })}
            </PostList.Body>
          </PostList>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default AttendanceSubstituteDialog;
