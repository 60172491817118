import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Calendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interaction, { DateClickArg } from '@fullcalendar/interaction';
import {
  DateSelectArg,
  EventDropArg,
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/core';
import moment from 'moment';
import { getQueryParams, utils } from '../../../../../groupware-common/utils';
import {
  contrastColor,
  dateFormat,
  dateTimeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import Loading from '../../../../../components/loading/Loading';
import { schedulesActions } from '../../../../stores/calendar/schedules';
import CalendarLookupDialog from './CalendarLookupDialog';
import FeedBack from '../../../../../components/alert/FeedBack';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import CalendarListDialog from './CalendarListDialog';
import { getEmployeeName } from '../../../../../groupware-directory/stores/directory';
import CalendarSubLookupDialog from './CalendarSubLookupDialog';

/** color RGB to HEX */
function rgbToHex(c: number): string {
  const hex = c.toString(16).toString().padStart(2, '0');
  return hex;
}
/** color HEX to RGB */
function hexToRgb(c: string): number[] | undefined {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : undefined;
}

/** 두 가지 색상의 중간 색상 추출 함수. - hex  */
function mixColorToHexType(color1: string, color2: string) {
  const color1ToRgb = hexToRgb(color1);
  const color2ToRgb = hexToRgb(color2);
  let newColor = '';
  if (color1ToRgb && color2ToRgb) {
    // RGB 값의 평균을 계산합니다.
    const mixedR = Math.floor((color1ToRgb[0] + color2ToRgb[0]) / 2);
    const mixedG = Math.floor((color1ToRgb[1] + color2ToRgb[1]) / 2);
    const mixedB = Math.floor((color1ToRgb[2] + color2ToRgb[2]) / 2);
    newColor = `#${rgbToHex(mixedR)}${rgbToHex(mixedG)}${rgbToHex(mixedB)}`;
  }
  return newColor;
}

function CalendarBodyContentContainer(props: {
  calendarRef: React.RefObject<typeof Calendar>;
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash, calendarRef } = props;
  const dispatch = useAppDispatch();
  if (hash === '') return <Loading />;
  const queryParams = getQueryParams(search);
  let initialView = '';
  if (hash === '#monthly') initialView = 'dayGridMonth';
  else if (hash === '#weekly') initialView = 'timeGridWeek';
  else if (hash === '#daily') initialView = 'timeGridDay';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const getCal = useCallback(() => calendarRef.current?.getApi?.(), []);
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const basic = useSelector(
    (state: RootState) => state.calendar.userPreferences.basic,
  );
  const firstDayOfWeek = basic.firstDayOfWeek === 'SUN' ? 0 : 1;
  const myCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.list,
  );
  const sharedCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.sharedList,
  );
  const subCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.subList,
  );
  const totalCals = [
    ...myCals.filter((a) => a.status && a.checked),
    ...sharedCals.filter((a) => a.status && a.checked),
    ...subCals.filter((a) => a.status && a.checked),
  ];
  const list = useSelector((state: RootState) => state.calendar.schedules.list);
  const subList = useSelector(
    (state: RootState) => state.calendar.schedules.subList,
  );
  const view = useSelector((state: RootState) => state.calendar.schedules.view);

  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [validation, setValidation] = useState('');

  const initialEvents: EventSourceInput = useMemo(() => {
    const result = list
      .filter((a) => totalCals.some((x) => x.id === a.calendarId))
      .map((a, i) => {
        const cal = totalCals.find((x) => x.id === a.calendarId);
        let backgroundColor = cal
          ? cal.color
          : 'rgba(var(--primary-color-rgb), 0.95)';
        let borderColor = cal
          ? mixColorToHexType(cal.color, '#FFFFFF')
          : 'var(--primary-color-rgb)';
        let textColor = cal
          ? contrastColor(cal.color)
          : 'var(--primary-text-color)';
        const creatorName = getEmployeeName(a.companyId, a.employeeId);
        let startDate = timezoneDate(a.startDateTime);
        let endDate = timezoneDate(a.endDateTime);
        if (a.isFull) {
          startDate = new Date(a.startDateTime);
          endDate = new Date(a.endDateTime);
          endDate.setDate(endDate.getDate() + 1);
        }
        if (a.reply === 'REJECT') {
          backgroundColor = 'var(--body-bg-color)';
          textColor = cal ? cal.color : 'rgba(var(--primary-color-rgb), 0.95)';
        }
        if (a.reply === 'WAIT') {
          backgroundColor = 'var(--body-bg-color)';
          borderColor = cal
            ? cal.color
            : 'rgba(var(--primary-color-rgb), 0.95)';
          textColor = cal ? cal.color : 'rgba(var(--primary-color-rgb), 0.95)';
        }
        return {
          ...a,
          isSubCals: false,
          className: a.reply ? a.reply.toLowerCase() : undefined,
          id: `${a.id}_${i}`,
          eventId: a.id,
          allDay: a.isFull,
          start: startDate,
          end: endDate,
          title: cal?.useExposeCreator ? `[${creatorName}] ${a.name}` : a.name,
          textColor,
          backgroundColor,
          borderColor,
        };
      });
    const subResult = subList
      .filter((a) => totalCals.some((x) => x.id === a.calendarId))
      .map((a, i) => {
        const cal = totalCals.find((x) => x.id === a.calendarId);
        const backgroundColor = cal
          ? cal.color
          : 'rgba(var(--primary-color-rgb), 0.95)';
        const borderColor = cal
          ? mixColorToHexType(cal.color, '#FFFFFF')
          : 'var(--primary-color-rgb)';
        const textColor = cal
          ? contrastColor(cal.color)
          : 'var(--primary-text-color)';
        const end = new Date(a.endDateTime);
        end.setDate(end.getDate() + 1);
        return {
          ...a,
          isSubCals: true,
          id: `${a.id}_${i}`,
          subEventId: a.id,
          allDay: a.isFull,
          start: new Date(a.startDateTime),
          end,
          title: a.name,
          textColor,
          backgroundColor,
          borderColor,
        };
      });
    return [...result, ...subResult];
  }, [totalCals, list, subList]);

  useEffect(() => {
    const calendar = getCal();
    if (!calendar) return;
    let date = timezoneDate();
    if (queryParams.startDate) date = timezoneDate(queryParams.startDate);
    calendar.gotoDate(date);
  }, [pathname, queryParams, hash]);

  useEffect(() => {
    const calendar = getCal();
    if (!calendar) return;
    calendar.changeView(initialView);
  }, [initialView]);

  /** 날짜 선택. - 단일 */
  const handleSelectDateTime = (event: DateClickArg) => {
    const start = event.date;
    const end = event.date;
    const isAllDay = initialView === 'dayGridMonth' ? false : event.allDay;
    if (
      initialView === 'dayGridMonth' ||
      (initialView !== 'dayGridMonth' && event.allDay)
    ) {
      start.setHours(timezoneDate().getHours());
      start.setMinutes(timezoneDate().getMinutes() < 30 ? 0 : 30);
      end.setHours(
        timezoneDate().getMinutes() < 30
          ? timezoneDate().getHours()
          : timezoneDate().getHours() + 1,
      );
      end.setMinutes(timezoneDate().getMinutes() < 30 ? 30 : 0);
    } else end.setMinutes(event.date.getMinutes() + 30);
    const query: {
      calendarId: number;
      calendarName: string;
      start: string;
      end: string;
      isAllDay: boolean;
    } = {
      calendarId: myCals[0].id,
      calendarName: myCals[0].name,
      start: dateFormat(start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(end, 'yyyy-MM-DD HH:mm'),
      isAllDay,
    };
    queryParams.dialogMode = 'create';
    queryParams.queryWord = JSON.stringify(query);
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  /** 날짜 선택. - 기간 */
  const handleSelectRangeDate = (event: DateSelectArg) => {
    const { start, end } = event;
    let isAllDay = event.allDay;
    if (
      initialView === 'dayGridMonth' ||
      (initialView !== 'dayGridMonth' && event.allDay)
    ) {
      start.setHours(timezoneDate().getHours());
      start.setMinutes(timezoneDate().getMinutes() < 30 ? 0 : 30);
      end.setDate(end.getDate() - 1);
      end.setHours(
        timezoneDate().getMinutes() < 30
          ? timezoneDate().getHours()
          : timezoneDate().getHours() + 1,
      );
      end.setMinutes(timezoneDate().getMinutes() < 30 ? 30 : 0);
      isAllDay =
        initialView === 'dayGridMonth' && moment(start).isSame(end, 'date')
          ? false
          : isAllDay;
    }
    const query: {
      calendarId: number;
      calendarName: string;
      start: string;
      end: string;
      isAllDay: boolean;
    } = {
      calendarId: myCals[0].id,
      calendarName: myCals[0].name,
      start: dateFormat(start, 'yyyy-MM-DD HH:mm'),
      end: dateFormat(end, 'yyyy-MM-DD HH:mm'),
      isAllDay,
    };
    queryParams.dialogMode = 'create';
    queryParams.queryWord = JSON.stringify(query);
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  /** 일정 보기. */
  const handleClick = (res: EventClickArg) => {
    const { _def: def } = res.event;
    const { eventId, subEventId, calendarId, isSubCals } =
      def.extendedProps as {
        eventId: number;
        subEventId: string;
        calendarId: number;
        isSubCals: boolean;
      };

    const contents = document.getElementsByClassName('fc-more-popover');
    if (contents.length > 0) {
      // 더보기 창 있을 경우 더보기창 삭제.
      const content = contents[0];
      const style = content.getAttribute('style');
      content.setAttribute('style', `${style} display: none;`);
    }

    const clickDate = new Date(def.extendedProps.startDateTime);
    const cal = totalCals.find(({ id }) => id === calendarId);

    if (isSubCals) {
      const data = subList.find(
        (a) =>
          a.id === subEventId &&
          moment(clickDate).isSame(new Date(a.startDateTime)),
      );
      if (!data) return;
      const query: { id: string; calendarName: string } = {
        id: data.id,
        calendarName: cal?.name ?? '',
      };
      queryParams.dialogMode = 'read';
      queryParams.dialogType = 'sub';
      queryParams.queryWord = JSON.stringify(query);
      dispatch(sessionActions.search(getQueryParams(queryParams)));
      return;
    }

    const data = list.find(
      (a) =>
        a.id === eventId && moment(clickDate).isSame(new Date(a.startDateTime)),
    );

    if (!data) return;
    const endDateTime = new Date(data.endDateTime);
    if (data.isFull) endDateTime.setHours(0, 30, 0, 0);
    const query: {
      calendarId: number;
      calendarName: string;
      start: string;
      end: string;
      isAllDay: boolean;
    } = {
      calendarId,
      calendarName: cal?.name ?? '',
      start: dateTimeFormat(data.startDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
      end: dateTimeFormat(endDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
      isAllDay: data.isFull,
    };
    queryParams.dialogMode = 'read';
    queryParams.queryWord = JSON.stringify(query);
    dispatch(
      schedulesActions.view({
        calendarId,
        eventId,
        date: dateFormat(data.startDateTime, 'yyyy-MM-DD'),
        route: {
          pathname,
          search: getQueryParams(queryParams),
          hash,
        },
      }),
    );
  };

  /** 일정 드래그 이동. */
  const handleEventDrop = (res: EventDropArg) => {
    const { _def: def } = res.event;
    const { years, months, days, milliseconds } = res.delta;
    const event = def.extendedProps as {
      eventId: number;
      rootEventId: number; // 공유 받은 일정일 경우 원본 일정의 아이디. 0인 경우 원본 일정.
      calendarId: number;
      startDateTime: string;
      endDateTime: string;
      isSubCals: boolean;
      isRepeat: boolean; // 반복 일정 여부.
      isFull: boolean;
      updateAt: string;
    };
    const cal = totalCals.find((a) => a.id === event.calendarId);
    const isNotModifiable =
      event.rootEventId !== 0 ||
      (!cal?.permissions.useWrite ?? false) ||
      event.isSubCals;

    if (isNotModifiable) {
      setValidation('날짜 수정 권한이 없습니다.');
      res.revert();
      return;
    }
    if (event.isRepeat) {
      setValidation('반복일정은 드래그하여 날짜 수정이 불가능합니다.');
      res.revert();
      return;
    }
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
      },
    });
    const start = new Date(event.startDateTime);
    const end = new Date(event.endDateTime);
    if (years !== 0) {
      start.setFullYear(start.getFullYear() + years);
      end.setFullYear(end.getFullYear() + years);
    }
    if (months !== 0) {
      start.setMonth(start.getMonth() + months);
      end.setMonth(end.getMonth() + months);
    }
    if (days !== 0) {
      start.setDate(start.getDate() + days);
      end.setDate(end.getDate() + days);
    }
    if (milliseconds !== 0) {
      start.setMilliseconds(start.getMilliseconds() + milliseconds);
      end.setMilliseconds(end.getMilliseconds() + milliseconds);
    }
    const param = {
      calendarId: event.calendarId,
      eventId: event.eventId,
      data: {
        startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
        endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
        isFull: event.isFull,
        updateAt: event.updateAt,
      },
      location,
    };
    dispatch(schedulesActions.update(param)).then((result) => {
      if ((result as { error?: string }).error !== undefined) res.revert();
    });
  };

  /** 일정 저장 */
  const handleSave = (arg: {
    calendarId: number;
    data: {
      employeeId: number;
      name: string;
      description: string;
      startDateTime: string;
      endDateTime: string;
      resourceItemId?: number;
      isFull: boolean;
    };
  }) => {
    const { calendarId, data } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(
      schedulesActions.create({
        calendarId,
        data: {
          employeeId: data.employeeId,
          name: data.name,
          description: data.description,
          startDateTime: data.startDateTime,
          endDateTime: data.endDateTime,
          resourceItemId: data.resourceItemId,
          isFull: data.isFull,
          participants: [],
          alarms: [],
        },
        location,
      }),
    );
  };

  /** 일정 수정. */
  const handleUpdate = (arg: {
    isRepeat: boolean;
    repeatType: string;
    calendarId: number;
    eventId: number;
    data: {
      employeeId?: number;
      name: string;
      description: string;
      startDateTime: string;
      endDateTime: string;
      isFull: boolean;
      lookupStartDateTime?: string;
      updateAt: string;
    };
  }) => {
    const { isRepeat, repeatType, calendarId, eventId, data } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    // 반복 일정 수정
    if (isRepeat) {
      dispatch(
        schedulesActions.updateRecurringSchedule({
          calendarId,
          eventId,
          repeatType,
          data,
          location,
        }),
      );
    }
    // 일반 일정 수정
    else {
      dispatch(
        schedulesActions.update({ calendarId, eventId, data, location }),
      );
    }
  };

  /** 일정 삭제. */
  const handleDelete = (arg: {
    isRepeat: boolean;
    repeatType: string;
    param: {
      rootEventId: number;
      calendarId: number;
      eventId: number;
      updateAt: string;
      removeStartDate?: string;
      isDeleteResource?: boolean; // 자원 예약 삭제 여부.
    };
  }) => {
    const { isRepeat, repeatType, param } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    // 원본 일정 삭제
    if (param.rootEventId === 0) {
      // 반복 일정.
      if (isRepeat) {
        if (param.removeStartDate === undefined || repeatType === '') return;
        const data = {
          calendarId: param.calendarId,
          eventId: param.eventId,
          repeatType,
          updateAt: param.updateAt,
          startDate: param.removeStartDate,
        };
        dispatch(schedulesActions.deleteRecurringSchedule({ data, location }));
      }
      // 일반 일정.
      else {
        if (param.isDeleteResource === undefined) return;
        dispatch(
          schedulesActions.delete({
            calendarId: param.calendarId,
            eventId: param.eventId,
            data: {
              eventUpdateAt: param.updateAt,
              isDeleteResource: param.isDeleteResource,
            },
            location,
          }),
        );
      }
    }
    // 공유받은 일정 삭제
    else {
      if (!view) return;
      const calCreatorId = sharedCals.find(
        (a) => a.id === view.calendarId,
      )?.creatorId;
      const employeeId = view.participants.find(
        (a) => a.participantId === calCreatorId,
      )?.participantId;
      dispatch(
        schedulesActions.deleteSharedSchedule({
          employeeId: employeeId ?? principal.employeeId,
          eventId: param.eventId,
          eventUpdateAt: param.updateAt,
          location,
        }),
      );
    }
  };

  const renderDialog = () => {
    const { dialogMode: mode, dialogType: type } = queryParams;
    if (mode === 'read') {
      if (type === 'sub') return <CalendarSubLookupDialog search={search} />;

      return (
        <CalendarLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      );
    }
    if (mode === 'create')
      return (
        <CalendarLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onSave={handleSave}
        />
      );

    // 표로 보기.
    if (type === 'list')
      return <CalendarListDialog search={search} hash={hash} />;

    return null;
  };

  let height = windowSize - 184;
  if (height <= 450) height = 450;
  return (
    <>
      <Calendar
        windowResize={() => {
          setWindowSize(window.innerHeight);
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interaction]}
        initialView={initialView}
        events={initialEvents}
        eventClassNames="calendar"
        height={`${height}px`}
        firstDay={firstDayOfWeek}
        headerToolbar={false}
        now={timezoneDate()} // 시간대 설정.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={calendarRef}
        eventDurationEditable={false}
        eventClick={handleClick}
        eventDrop={handleEventDrop}
        editable
        selectable
        dayMaxEvents
        dateClick={display === 'pc' ? undefined : handleSelectDateTime}
        select={handleSelectRangeDate}
        dayHeaderContent={(arg) => {
          if (arg.view.type === 'dayGridMonth')
            return dateFormat(arg.date, 'dd');
          if (arg.view.type === 'timeGridWeek')
            return dateFormat(arg.date, 'DD일 (dd)');
          return dateFormat(arg.date, `MM.DD dddd`);
        }}
        locale="en-GB"
        allDayText="종일"
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          omitZeroMinute: false,
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
      />
      {renderDialog()}
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </>
  );
}

export default CalendarBodyContentContainer;
