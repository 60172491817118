/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { getPathParams } from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import labelsApi from '../apis/contacts/v1/labels';
import { contactsActions } from './contacts';

const name = 'contact/labels';

export interface LabelItem {
  id: number;
  name: string;
  peopleCount: number;
  updateAt: string;
}

interface State {
  list: LabelItem[];
}

const initialState: State = {
  list: [],
};

/** 라벨 리스트 조회 */
const findLabelList = createAsyncThunk(
  `${name}/findLabelList`,
  async (_: LocateArg | undefined, { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const group = await labelsApi.list(companyId);
      return group;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 라벨 저장 */
const saveLabel = createAsyncThunk(
  `${name}/saveLabel`,
  async (arg: { name: string } & LocateArg, { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const data = await labelsApi.create({ companyId, name: arg.name });
      return { ...data, name: arg.name };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 라벨 수정 */
const updateLabel = createAsyncThunk(
  `${name}/updateLabel`,
  async (
    arg: {
      id: number;
      name: string;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const data = await labelsApi.update({
        companyId,
        id: arg.id,
        name: arg.name,
        updateAt: arg.updateAt,
      });
      return {
        ...data,
        name: arg.name,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 라벨 삭제 */
const deleteLabel = createAsyncThunk(
  `${name}/deleteLabel`,
  async (
    arg: {
      id: number;
      removeContacts: boolean; // 연락처 삭제 여부.
      updateAt: string;
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { pathname, search } = (getState() as RootState).session.route;
      const { companyId } = (getState() as RootState).session.principal;
      const { folderId } = getPathParams<{ folderId?: string }>(
        '/*/:folderId',
        pathname,
      );
      const data = await labelsApi.delete({
        companyId,
        id: arg.id,
        removeContacts: arg.removeContacts,
        updateAt: arg.updateAt,
      });
      dispatch(contactsActions.list({ folderId, search }));
      if (arg.removeContacts && (!arg.route || !arg.location))
        dispatch(findLabelList());
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const LabelSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findLabelList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.list = payload;
      })
      .addCase(saveLabel.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const addLabel = [...state.list];
          addLabel.push({ ...payload, peopleCount: 0 });
          state.list = addLabel;
        }
      })
      .addCase(updateLabel.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = state.list.map((a) => {
            if (a.id === payload.id)
              return { ...payload, peopleCount: a.peopleCount };
            return a;
          });
        }
      })
      .addCase(deleteLabel.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = state.list.filter((a) => a.id !== payload.id);
        }
      });
  },
});

export default LabelSlice.reducer;

export const labelActions = {
  list: findLabelList,
  saveLabel,
  updateLabel,
  deleteLabel,
};
