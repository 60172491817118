import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import BoardAuthorityContainer from '../pages/adminconsole/board/authority/BoardAuthorityContainer';
import BoardFolderBoxContainer from '../pages/adminconsole/board/folderbox/BoardFolderBoxContainer';
import BoardFormContainer from '../pages/adminconsole/board/form/BoardFormContainer';
import BoardPreferencesContainer from '../pages/adminconsole/board/preferences/BoardPreferencesContainer';
import BoardTrashContainer from '../pages/adminconsole/board/trash/BoardTrashContainer';

function AdminConsoleLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 전사 관리함
  if (menu === 'folderbox')
    return <BoardFolderBoxContainer pathname={pathname} search={search} />;
  // 일괄권한 설정.
  if (menu === 'authority') return <BoardAuthorityContainer />;
  // 양식 관리.
  if (menu === 'form')
    return <BoardFormContainer pathname={pathname} search={search} />;
  // 삭제함 관리.
  if (menu === 'trash')
    return <BoardTrashContainer pathname={pathname} search={search} />;

  // 기본 설정.
  return <BoardPreferencesContainer />;
}

export default AdminConsoleLocateContainer;
