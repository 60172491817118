import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic chip">
      <Chip label="일정" />
      <Chip label="Disabled" disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipBasic;
