/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { LocateArg } from '../../../groupware-common/types';
import { b62, getPathMap } from '../../../groupware-common/utils';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import folderApi from '../../apis/document/v1/folder';

const name = 'document/folders';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

/** 보존기간 텍스트로 변경 */
export const replaceRetentionPeriodToString = (value: string): string => {
  return value
    .replace(/([0-9])Y/, '$1년 ')
    .replace(/([0-9])M/, '$1개월 ')
    .replace(/([0-9])D/, '$1일 ')
    .replace('FOREVER', '영구')
    .trimRight();
};

/** 권한 옵션 */
export interface PermissionOptionType {
  useRead: boolean;
  useWrite: boolean;
}

/** 문서 폴더 */
export interface FolderListItem {
  id: number;
  parentId: number;
  seq: number;
  name: string;
  status: string;
  option: {
    isComment: boolean;
    isVersion: boolean;
    isRequiredVersion: boolean;
    isCheckout: boolean;
  };
  description: string;
  permissions?: PermissionOptionType;
  isAdmin: boolean;
  updateAt: string;
}
/** 권한 정보 */
export interface PermissionType {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디 (organization | employee)
  referenceType: string; // 참조 유형
}

export interface PermissionUserType {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디 (organization | employee)
  referenceType: string; // 참조 유형
  options: PermissionOptionType;
}

export interface FolderView {
  id: number; // 폴더 아이디
  parentId: number; // 폴더 부모 아이디
  seq: number; // 폴더 순번
  name: string; // 폴더명
  description: string; // 설명
  forms: {
    checked: boolean;
    id: number;
    name: string;
  }[]; // 양식 아이디 배열
  retentionPeriods: {
    id: string;
    checked: boolean;
  }[]; // 보존기간 리스트  []: 사용 안함, ["1Y", "6M", FOREVER...]: 사용
  defaultRetentionPeriod: string; // 대표 보존기간 보존기간 - '': 없음, \d{1,3}Y: {d}년, \d{1,3}M: {d}월, \d{1,3}D: {d}일, FOREVER: 영구
  updateAt: string; // 수정일
  option: {
    isComment: boolean; // 댓글 사용여부
    isVersion: boolean; // 버전관리 사용 여부
    isRequiredVersion: boolean; // 버전관리 필수 여부
    isCheckout: boolean; // 체크아웃 사용여부
  };
  managers: PermissionType[];
  users: PermissionUserType[];
  excluders: PermissionType[];
  isEnable: boolean; // 사용여부
}

export interface SaveFolderArg {
  id?: number;
  parentId: number; // 폴더 부모 아이디
  seq: number; // 폴더 순번
  name: string; // 폴더명
  description: string; // 설명
  forms: {
    id: number;
    name: string;
  }[];
  retentionPeriods: {
    checked: boolean;
    id: string;
  }[];
  defaultRetentionPeriod: string; // 대표 보존기간 보존기간 - '': 없음, \d{1,3}Y: {d}년, \d{1,3}M: {d}월, \d{1,3}D: {d}일, FOREVER: 영구
  managers: PermissionType[];
  users: PermissionUserType[];
  excluders: PermissionType[];
  isEnable: boolean; // 사용여부
  isComment: boolean; // 댓글 사용여부
  isVersion: boolean; // 버전 사용여부
  isCheckout: boolean; // 체크인,체크아웃 사용여부
  isRequiredVersion: boolean; // 버전 사용필수여부
  updateAt?: string; // 수정일
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: FolderListItem[];
  view: FolderView | undefined;
  adminconsole: {
    list: {
      checked: boolean;
      id: number;
      parentId: number;
      seq: number;
      name: string;
      status: string;
      updateAt: string;
    }[];
    totalCount: number;
  };
}

const initialState: State = {
  requests: [],
  list: [],
  view: undefined,
  adminconsole: {
    list: [],
    totalCount: 0,
  },
};

/** 폴더 사용자 전체 리스트 조회. */
const findUserFolderList = createAsyncThunk(
  `${name}/findUserFolderList`,
  async (
    arg: {
      employeeId: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { employeeId } = arg;
      const data = await folderApi.userList(employeeId);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 전체 리스트 조회. */
const findFolderList = createAsyncThunk(
  `${name}/findFolderList`,
  async (_: void | LocateArg, { rejectWithValue }) => {
    try {
      const list = (await folderApi.list()).map((a) => ({
        ...a,
        checked: false,
      }));
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 생성. */
const createFolder = createAsyncThunk(
  `${name}/createFolder`,
  async (
    arg: { data: SaveFolderArg } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = arg;
      const params = {
        parentId: data.parentId,
        seq: data.seq,
        name: data.name,
        description: data.description,
        forms: data.forms.map((a) => {
          return a.id;
        }),
        retentionPeriods: data.retentionPeriods.map((a) => {
          return a.id;
        }),
        defaultRetentionPeriod: data.defaultRetentionPeriod,
        managers: data.managers,
        users: data.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          options: {
            useRead: a.options.useRead,
            useWrite: a.options.useWrite,
          },
        })),
        excluders: data.excluders,
        isEnable: data.isEnable,
        option: {
          isComment: data.isComment,
          isVersion: data.isVersion,
          isRequiredVersion: data.isRequiredVersion,
          isCheckout: data.isCheckout,
        },
      };
      const result = await folderApi.create(params);
      if (arg.route !== undefined) {
        const route = {
          pathname: `${getPathMap('/*/*/*', arg.route.pathname)}/${b62(
            result.id,
          )}`,
        };
        dispatch(findFolderList({}));
        dispatch(findFolderView({ id: result.id, route }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 수정. */
const updateFolder = createAsyncThunk(
  `${name}/updateFolder`,
  async (
    arg: {
      data: SaveFolderArg;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const data = { ...arg.data };
      if (!data.id || !data.updateAt) return;

      const params = {
        id: data.id,
        parentId: data.parentId,
        seq: data.seq,
        name: data.name,
        description: data.description,
        forms: data.forms.map((a) => {
          return a.id;
        }),
        retentionPeriods: data.retentionPeriods.map((a) => {
          return a.id;
        }),
        defaultRetentionPeriod: data.defaultRetentionPeriod,
        isEnable: data.isEnable,
        option: {
          isComment: data.isComment,
          isVersion: data.isVersion,
          isRequiredVersion: data.isRequiredVersion,
          isCheckout: data.isCheckout,
        },
        updateAt: data.updateAt,
        excluders: data.excluders.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        managers: data.managers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType, // === 'EMPLOYEE' ? '3' : '0',
        })),
        users: data.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          options: {
            useRead: a.options.useRead,
            useWrite: a.options.useWrite,
          },
        })),
      };
      const result = await folderApi.update(params);
      if (arg.route !== undefined) {
        dispatch(findFolderView({ id: result.id, route: arg.route }));
        dispatch(findFolderList({}));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 보기 */
const findFolderView = createAsyncThunk(
  `${name}/findFolderView`,
  async (arg: { id: number } & LocateArg, { rejectWithValue, getState }) => {
    const organizations = (getState() as RootState).directory.organization.list
      .data.items;
    const employees = (getState() as RootState).directory.employee.list.data
      .items;
    try {
      const { id } = arg;
      const response = await folderApi.view({ id });

      const view = {
        id: response.id,
        parentId: response.parentId,
        seq: response.seq,
        name: response.name,
        description: response.description,
        forms:
          response.forms !== null && response.forms.length > 0
            ? response.forms.map((a) => ({ ...a, checked: false }))
            : [],
        isEnable: response.isEnable,
        option: response.option,
        users: response.users
          .filter(
            (a) =>
              a.referenceType === 'COMPANY' ||
              (a.referenceType === 'ORGANIZATION' &&
                organizations.some((x) => x.id === a.referenceId)) ||
              (a.referenceType === 'EMPLOYEE' &&
                employees.some((x) => x.id === a.referenceId)),
          )
          .map((a) => ({
            ...a,
            options: {
              useRead: a.permission.useRead,
              useWrite: a.permission.useWrite,
            },
          })),
        excluders: response.excluders.filter(
          (a) =>
            a.referenceType === 'COMPANY' ||
            (a.referenceType === 'ORGANIZATION' &&
              organizations.some((x) => x.id === a.referenceId)) ||
            (a.referenceType === 'EMPLOYEE' &&
              employees.some((x) => x.id === a.referenceId)),
        ),
        managers: response.managers.filter((a) =>
          employees.some((x) => x.id === a.referenceId),
        ),
        defaultRetentionPeriod: response.defaultRetentionPeriod,
        retentionPeriods: response.retentionPeriods.map((a) => ({
          id: a,
          checked: a === response.defaultRetentionPeriod,
        })),
        updateAt: response.updateAt,
      };
      return view;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 삭제 */
const deleteFolder = createAsyncThunk(
  `${name}/deleteFolder`,
  async (
    arg: {
      parentId: number;
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      const result = await folderApi.delete({ id, updateAt });
      dispatch(findFolderList());
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 순서 변경. */
const sortFolder = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      data: {
        parentId: number;
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      await folderApi.sort(arg.data);
      const list = (await folderApi.list()).map((a) => ({
        ...a,
        checked: false,
      }));
      return { list };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 일괄권한 수정. */
const bulkAuthority = createAsyncThunk(
  `${name}/bulkAuthority`,
  async (
    arg: {
      folders: {
        id: number;
        updateAt: string;
      }[];
      excluders: {
        referenceCompanyId: number;
        referenceId: number;
        referenceType: string;
      }[];
      managers: {
        referenceCompanyId: number;
        referenceId: number;
        referenceType: string;
      }[];
      users: {
        referenceCompanyId: number;
        referenceId: number;
        referenceType: string;
        options: PermissionOptionType;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        folders: arg.folders,
        excluders: arg.excluders.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
        })),
        managers: arg.managers.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType, // === 'EMPLOYEE' ? '3' : '0',
        })),
        users: arg.users.map((a) => ({
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          options: {
            useRead: a.options.useRead,
            useWrite: a.options.useWrite,
          },
        })),
      };
      const result = await folderApi.bulkAuthority(params);
      if (arg.folders.length !== result.length)
        return rejectWithValue(
          appError({
            error:
              '선택한 폴더 중 권한이 중복되는 사용자가 존재하여 일부 저장 실패하였습니다.',
          }),
        );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 폴더 보기 */
const findUserFolderView = createAsyncThunk(
  `${name}/findUserFolderView`,
  async (arg: { id: number } & LocateArg, { rejectWithValue }) => {
    try {
      const { id } = arg;
      const response = await folderApi.userView({ id });

      const view = {
        id: response.id,
        parentId: response.parentId,
        seq: response.seq,
        name: response.name,
        description: response.description,
        forms:
          response.forms !== null && response.forms.length > 0
            ? response.forms.map((a) => ({ ...a, checked: false }))
            : [],
        isEnable: response.isEnable,
        option: response.option,
        users: response.users.map((a) => ({
          ...a,
          options: {
            useRead: a.permission.useRead,
            useWrite: a.permission.useWrite,
          },
        })),
        excluders: response.excluders,
        managers: response.managers,
        defaultRetentionPeriod: response.defaultRetentionPeriod,
        retentionPeriods: response.retentionPeriods.map((a) => ({
          id: a,
          checked: a === response.defaultRetentionPeriod,
        })),
        updateAt: response.updateAt,
      };
      return view;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const documentFoldersReducer = createSlice({
  name,
  initialState,
  reducers: {
    clearView(state) {
      state.view = undefined;
    },
    checked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (state.adminconsole.list) {
        if (action.payload.itemId === 'all') {
          state.adminconsole.list = state.adminconsole.list.map((x) => {
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
        } else {
          const index = state.adminconsole.list.findIndex(
            (x) => x.id === action.payload.itemId,
          );
          if (index > -1) {
            state.adminconsole.list[index] = {
              ...state.adminconsole.list[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.adminconsole.list = payload;
      })
      .addCase(findUserFolderList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.list = payload;
      })
      .addCase(findFolderView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(findUserFolderView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(deleteFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = undefined;
      })
      .addCase(sortFolder.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.adminconsole.list = payload.list;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default documentFoldersReducer.reducer;

export const folderActions = {
  checked: documentFoldersReducer.actions.checked,
  clearView: documentFoldersReducer.actions.clearView,

  list: findFolderList,
  view: findFolderView,
  create: createFolder,
  update: updateFolder,
  delete: deleteFolder,
  sort: sortFolder,
  bulkAuthority,

  userFolderList: findUserFolderList,
  userFolderView: findUserFolderView,
};
