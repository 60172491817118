import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 직원 역할을 조회합니다.(현재 세션의 직원)
 * @returns 직원 역할 배열.
 */
async function find(): Promise<
  {
    employeeId: number;
    role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN';
    updateAt: string;
  }[]
>;
/**
 * 역할에 해당되는 직원을 조회합니다.
 * @param role 역할.
 * @returns 직원 역할 배열.
 */
async function find(role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN'): Promise<
  {
    employeeId: number;
    role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN';
    updateAt: string;
  }[]
>;
async function find(role?: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN'): Promise<
  {
    employeeId: number;
    role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN';
    updateAt: string;
  }[]
> {
  // 조회 역할이 없는 경우 세션의 직원 역할 조회.
  if (role === undefined) {
    try {
      const { host, headers } = getApiConfig();
      const url = `${host}/api/attendance/v1/role/employee/all`;
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (ex) {
      throw apiError(ex);
    }
  }

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/role/all`;
    const response = await axios.get(url, { headers, params: { role } });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 직원 역할을 추가합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function append(
  arg: { employeeId: number; role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN' }[],
): Promise<
  {
    employeeId: number;
    role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/role/all`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 직원 역할을 삭제합니다.
 * @param arg 직원 역할 배열.
 * @returns 직원 역할 배열.
 */
async function remove(
  arg: {
    employeeId: number;
    role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN';
    updateAt: string;
  }[],
): Promise<
  {
    employeeId: number;
    role: 'ATTENDANCE_USER' | 'ATTENDANCE_ADMIN';
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/role/all`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const roleApi = {
  find,
  append,
  remove,
};

export default roleApi;
