import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { UserPreferencesItem } from '../../../../stores/userPreferences';

/** 자원관리 사용자 환경설정 조회. */
async function find(employeeId: number): Promise<UserPreferencesItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/preferences/user`;
    const response = await axios.get(url, { headers, params: { employeeId } });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원관리 사용자 환경설정 저장. */
async function save(arg: {
  employeeId: number;
  initianFrame: number;
  firstDayOfWeek: number;
  isReceiveAlarm: boolean;
  updateAt: string;
}): Promise<UserPreferencesItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/preferences/user`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const userPreferencesApi = {
  find,
  save,
};

export default userPreferencesApi;
