import React from 'react';
import Chip from '../chip/Chip';
import Tooltip from '../tooltip/Tooltip';
import Icon from '../icon/Icon';

function Badge(props: { label: string; name: string }): JSX.Element {
  return (
    <i
      className={`post-badge ${props.name}`}
      aria-label={props.label}
      title={props.label}
    />
  );
}

function PostSubject(props: {
  folder?: {
    name: string;
    fontWeight?: number | 'bold';
  }; // 폴더
  style?: React.CSSProperties;
  titleHeader?: string; // 말머리
  title: string; // 제목
  tootip?: string; // 제목 미리보기
  content?: string; // 상세내용
  notice?: boolean; // 공지
  comments?: number; // 댓글 카운트
  opinions?: number; // 의견 카운트
  attachments?: boolean; // 첨부파일
  new?: boolean; // 신규
  security?: boolean; // 보안
  urgent?: boolean; // 긴급
  important?: boolean; // 중요
  chip?: {
    label: string;
    theme: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  };
  onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  onClickCount?(type?: string): void; // 카운트 클릭 이벤트
  onMouseEnter?(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void;
  onMouseLeave?(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void;
}): JSX.Element {
  const handleClickCount = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (props.onClickCount) props.onClickCount(type);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (props.onClick) props.onClick(event);
  };

  const opinions = props.onClickCount ? (
    <span
      className="opinions"
      onClick={(event) => handleClickCount(event, 'opinions')}
    >
      <span className="title">{props.opinions}</span>
    </span>
  ) : (
    <span>
      <Icon icon="comment-text-right" className="icon" />
      <span>{props.opinions}</span>
    </span>
  );

  const comments = props.onClickCount ? (
    <span
      className="comments"
      onClick={(event) => handleClickCount(event, 'comments')}
    >
      <span className="title">{props.comments}</span>
    </span>
  ) : (
    <span>
      <span className="comments">
        <span className="title">{props.comments}</span>
      </span>
    </span>
  );

  const content = (
    <>
      {props.notice && <Badge label="공지" name="notice" />}
      {props.security && <Badge label="보안" name="security" />}
      {props.urgent && <Badge label="높음" name="urgent" />}
      {props.important && <Badge label="중요" name="important" />}
      {props.folder && (
        <Chip
          theme="cancel"
          size="sm"
          label={props.folder.name}
          fontWeight={props.folder.fontWeight}
        />
      )}
      {props.tootip ? (
        <Tooltip title={props.tootip}>
          <>
            {props.titleHeader && (
              <span className="text-header">{props.titleHeader}</span>
            )}
            <span
              style={props.style}
              className="text"
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
            >
              {props.title}
            </span>
          </>
        </Tooltip>
      ) : (
        <>
          {props.titleHeader && (
            <span className="text-header">{props.titleHeader}</span>
          )}
          <span
            style={props.style}
            className="text"
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            {props.title}
          </span>
        </>
      )}
      {props.opinions !== 0 && props.opinions !== undefined && opinions}
      {props.comments !== 0 && props.comments !== undefined && comments}
      {props.attachments && <Badge label="첨부파일" name="file" />}
      {props.new && <Badge label="신규" name="new" />}
      {props.chip && (
        <Chip label={props.chip.label} size="xs" theme={props.chip.theme} />
      )}
    </>
  );

  return (
    <>
      {props.onClick ? (
        <a href="#" className="post-title hyperlink" onClick={handleClick}>
          {content}
        </a>
      ) : (
        <span className="post-title">{content}</span>
      )}
      {props.content !== undefined && (
        <div className="post-detail">{props.content}</div>
      )}
    </>
  );
}

export default PostSubject;
