import React from 'react';

function EmptyData(props: { message?: string }): JSX.Element {
  return (
    <div className="eui-empty-data">
      {props.message || '등록된 데이터가 없습니다.'}
    </div>
  );
}

export default EmptyData;
