import React from 'react';
import EuiToolbar from '../layout/EuiToolbar';

function DialogToolbarLeft(props: { children: React.ReactNode }): JSX.Element {
  return <EuiToolbar.Left>{props.children}</EuiToolbar.Left>;
}

function DialogToolbarRight(props: { children: React.ReactNode }): JSX.Element {
  return <EuiToolbar.Right>{props.children}</EuiToolbar.Right>;
}

function DialogToolbar(props: { children: React.ReactNode }): JSX.Element {
  return <EuiToolbar className="dialog-toolbar">{props.children}</EuiToolbar>;
}

DialogToolbar.Left = DialogToolbarLeft;
DialogToolbar.Right = DialogToolbarRight;

export default DialogToolbar;
