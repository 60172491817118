import React, { useState } from 'react';
import { Language } from '../../groupware-common/types';
import LanguageInput from './LanguageInput';
import Button from '../button/Button';

function MultilingualTextField(props: {
  error?: boolean;
  value: { language: Language; value: string }[];
  onChange(language: Language, value: string): void;
}): JSX.Element {
  const mainLanguage: Language = 'ko-KR';
  const [state, setState] = useState(false);

  return (
    <div className="ui-multilingual-text-field">
      <div className="main">
        {props.value
          .filter((x) => x.language === mainLanguage)
          .map(({ language, value }) => (
            <LanguageInput
              key={language}
              error={props.error}
              language={language}
              value={value}
              onChange={props.onChange}
            />
          ))}
        {props.value.length > 1 && (
          <Button
            text="다국어"
            iconType
            icon="language"
            onClick={() => setState(!state)}
            color={state ? undefined : 'secondary'}
            className="toggle"
          />
        )}
      </div>
      {state && (
        <div className="sub">
          {props.value
            .filter((x) => x.language !== mainLanguage)
            .map(({ language, value }) => (
              <LanguageInput
                key={language}
                language={language}
                value={value}
                onChange={props.onChange}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default MultilingualTextField;
