import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Comment from '../../../../components/comment/Comment';
import { documentActions } from '../../../stores/approval/document';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import PostView from '../../../../components/post/PostView';
import AttachmentsList from '../../../../components/attachments/AttachmentsList';
import AttachmentsItem from '../../../../components/attachments/AttachmentsItem';
import ApprovalLineFlat from './ApprovalLineFlat';
import Avatar from '../../../../components/avatar/Avatar';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../dialogs/ApprovalLineDialogContainer';
import { getAvatarPath } from '../../../../groupware-common/utils';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import Print, { PrintClickProps } from '../../../../components/print/Print';
import AttachDocumentItem from '../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../components/attachments/AttachDocumentList';
import { getCommentItems } from '../../root/approval/ApprovalContentBodyViewContainer';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;
  const items = approvalLine.groups.map((a) => a.items).flat();
  // console.log(`getLastApprovaledAt(approvalLine):items`, items);
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());
  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));
  return `${time} 시간`;
}

function ApprovalPrint(props: {
  onClose(): void;
  listId: { id: number }[];
  folderId: number;
}): JSX.Element {
  // console.log(`${ApprovalPrint.name}.render(props)`, props);
  const dispatch = useAppDispatch();
  const scrollbar = useRef<HTMLDivElement>(null);

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const itemData = useSelector(
    (state: RootState) => state.approval2.document.printView.data,
  );

  const [pageNo, setPageNo] = useState(1);
  const [option, setOption] = useState<
    { value: string; label: string; checked: boolean }[]
  >([
    { value: 'info', label: '정보', checked: false },
    { value: 'attachment', label: '첨부파일', checked: false },
    { value: 'attachments', label: '첨부문서', checked: false },
    { value: 'opinion', label: '의견', checked: false },
    { value: 'comment', label: '댓글', checked: false },
  ]);

  useEffect(() => {
    if (props.listId.length === 0) return;
    dispatch(
      documentActions.printView({
        folderId: props.folderId,
        id: props.listId[pageNo - 1].id,
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined)
        scrollbar.current?.scrollTo(0, 0);
      else {
        dispatch(documentActions.printViewClear());
        props.onClose();
      }
    });
  }, [pageNo, dispatch]);

  const totalCount = props.listId.length;

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setOption((prevState) =>
      prevState.map((x) =>
        x.value === value ? { ...x, checked: event.target.checked } : x,
      ),
    );
  };
  const handleClick = (code: PrintClickProps) => {
    if (code === 'prev') {
      const page = (pageNo || 1) - 1;
      if (page < 1) return;
      setPageNo(page);
    }
    if (code === 'next') {
      if (pageNo >= totalCount) return;
      const page = (pageNo || 1) + 1;
      setPageNo(page);
    }
    if (code === 'close') {
      dispatch(documentActions.printViewClear());
      props.onClose();
    }
  };

  if (itemData === undefined || itemData === null) return <></>;

  const drafter = getApprovalLineDrafter(itemData.approvalLine);
  if (drafter === undefined) return <div>오류</div>;

  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : '';

  const { organizationName: draftOrganizationName, employeeName: drafterName } =
    drafter;
  /** 부모 문서 정보(내부결재인 경우에만 있음) */
  let parentDrafterOrganizationName: string | undefined;
  let parentDrafterName: string | undefined;
  let parentDrafterAvatar: string | undefined;
  let parentLastApprovalAt: string | undefined;
  let parentWaiteAt: string | undefined;
  if (itemData.parentApprovalLine) {
    const parentDrafter = getApprovalLineDrafter(itemData.parentApprovalLine);
    if (parentDrafter === undefined) return <div>오류</div>;

    parentDrafterOrganizationName = parentDrafter.organizationName;
    parentDrafterName = parentDrafter.employeeName;
    parentDrafterAvatar = parentDrafter.employeeId
      ? getAvatarPath(parentDrafter)
      : undefined;
    parentLastApprovalAt =
      itemData.parentCompleteAt === undefined
        ? getLastApprovalAt(itemData.parentApprovalLine)
        : undefined;
    parentWaiteAt =
      parentLastApprovalAt === undefined
        ? undefined
        : getWaitedAt(parentLastApprovalAt);
  }
  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);

  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  const opinions = getCommentItems(itemData.opinions, directory);
  const comments = getCommentItems(itemData.comments, directory);

  /** 마지막 결재 날짜 */
  const lastApprovalAt =
    itemData.completeAt === undefined
      ? getLastApprovalAt(itemData.approvalLine)
      : undefined;

  /** 대기 시간 */
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  return (
    <Print
      pageNo={pageNo}
      totalPageCount={totalCount}
      option={option}
      onChangeOption={handleChangeOption}
      onClick={handleClick}
    >
      <div className="ui-view-root" ref={scrollbar}>
        <PostView type="full">
          {option.some((x) => x.value === 'info' && x.checked) && (
            <>
              <PostView.Head>
                <PostView.Title>{itemData.subject}</PostView.Title>
                {itemData.parentApprovalLine && (
                  <div
                    className="approval-view-info"
                    style={{
                      paddingBottom: '16px',
                      borderBottom: '1px solid var(--line-color)',
                    }}
                  >
                    <Avatar
                      className="avatar"
                      name={
                        parentDrafterName ?? parentDrafterOrganizationName ?? ''
                      }
                      image={parentDrafterAvatar}
                      icon={parentDrafterAvatar ? undefined : 'sitemap-fill'}
                    />
                    <ApprovalLineFlat
                      approvalLine={itemData.parentApprovalLine}
                    />
                    <PostView.Info row>
                      <PostView.InfoItem
                        title="기안자"
                        value={
                          parentDrafterName ??
                          parentDrafterOrganizationName ??
                          ''
                        }
                      />
                      <PostView.InfoItem
                        title="기안부서"
                        value={parentDrafterOrganizationName ?? ''}
                      />
                      <PostView.InfoItem
                        title="기안일"
                        value={itemData.parentDrafteAt ?? ''}
                      />
                      {itemData.parentCompleteAt && (
                        <PostView.InfoItem
                          title="완료일"
                          value={itemData.parentCompleteAt}
                        />
                      )}
                      {parentWaiteAt && (
                        <PostView.InfoItem
                          title="대기일"
                          value={parentWaiteAt}
                        />
                      )}
                    </PostView.Info>
                  </div>
                )}
                <div className="approval-view-info">
                  <Avatar
                    className="avatar"
                    name={drafterName ?? draftOrganizationName}
                    image={drafterAvatar}
                    icon={drafterAvatar === '' ? 'sitemap-fill' : undefined}
                  />
                  <ApprovalLineFlat approvalLine={itemData.approvalLine} />
                  <PostView.Info row>
                    <PostView.InfoItem
                      title="기안자"
                      value={drafterName ?? draftOrganizationName}
                    />
                    <PostView.InfoItem
                      title="기안부서"
                      value={draftOrganizationName}
                    />
                    <PostView.InfoItem
                      title="기안일"
                      value={itemData.draftAt}
                    />
                    {itemData.completeAt && (
                      <PostView.InfoItem
                        title="완료일"
                        value={itemData.completeAt}
                      />
                    )}
                    {waitedAt && (
                      <PostView.InfoItem title="대기일" value={waitedAt} />
                    )}
                  </PostView.Info>
                </div>
              </PostView.Head>
            </>
          )}
          <PostView.Body>
            <PostView.Content data={itemData.contents} />
            {itemData.attachedFiles &&
              itemData.attachedFiles.length > 0 &&
              option.some((x) => x.value === 'attachment' && x.checked) && (
                <AttachmentsList
                  count={itemData.attachedFiles.length}
                  className="view-attachments"
                >
                  {itemData.attachedFiles.map((x) => (
                    <AttachmentsItem key={x.id} name={x.name} size={x.size} />
                  ))}
                </AttachmentsList>
              )}
            {itemData.attachedDocuments &&
              itemData.attachedDocuments.length > 0 &&
              option.some((x) => x.value === 'attachments' && x.checked) && (
                <AttachDocumentList count={itemData.attachedDocuments.length}>
                  {itemData.attachedDocuments.map((x) => (
                    <AttachDocumentItem
                      key={x.id}
                      no={x.no}
                      subject={x.subject}
                    />
                  ))}
                </AttachDocumentList>
              )}
            {opinions &&
              option.some((x) => x.value === 'opinion' && x.checked) && (
                <Comment
                  opinion
                  count={itemData.opinions?.length ?? 0}
                  user={user}
                  comments={opinions}
                />
              )}
            {comments &&
              option.some((x) => x.value === 'comment' && x.checked) && (
                <Comment
                  count={itemData.comments?.length ?? 0}
                  user={user}
                  comments={comments}
                />
              )}
          </PostView.Body>
        </PostView>
      </div>
    </Print>
  );
}

export default React.memo(ApprovalPrint);
