import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipAvatar(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Avatar chip">
      <Chip label="홍길동" avatar="/images/faces/faces_31.jpg" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipAvatar;
