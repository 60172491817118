import React, { useState } from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';
import ChipGroup from '../../../../../components/chip/ChipGroup';

function ComponentChipGroup(): JSX.Element {
  const [state, setState] = useState<{
    fruit: {
      id: number;
      label: string;
    }[];
  }>({
    fruit: [
      { id: 1, label: '복사' },
      { id: 2, label: '수정' },
      { id: 3, label: '삭제' },
    ],
  });

  const handleDelete = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      fruit: prevState.fruit.filter((x) => x.id !== id),
    }));
  };

  return (
    <ComponentSampleUI.Content title="Chip Group">
      <ChipGroup>
        {state.fruit.map((x) => (
          <Chip
            key={x.id}
            label={x.label}
            onDelete={() => handleDelete(x.id)}
          />
        ))}
      </ChipGroup>
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipGroup;
