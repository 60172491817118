import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 연차사용촉진알림 문서함 조회. */
async function fetchList(arg: {
  employeeId: number;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    employeeId: number;
    standardDate: string;
    alertType: number;
    firstAlarmSendDate: string;
    firstAlarmId: number;
    firstAlarmSender: number;
    workerPlanSendDate: string;
    workerPlanId: number;
    secondAlarmSendDate: string;
    secondAlarmId: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/person`;
    const params = {
      employeeid: arg.employeeId,
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차사용촉진알림 문서함 내역 총 개수. */
async function totalCount(arg: { employeeId: number }): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/person/totalcount`;
    const params = {
      employeeid: arg.employeeId,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차 사용 계획서 저장 */
async function createUsePlan(arg: {
  employeeId: number;
  alertType: number;
  standardDate: string;
  contents: string;
  firstAlarmContents?: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  standardDate: string;
  id: number;
  alertType: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/worker`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const noticeApi = {
  fetchList,
  totalCount,
  createUsePlan,
};

export default noticeApi;
