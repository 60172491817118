import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 연동결재 사용여부 조회. */
async function findApproval(): Promise<{
  use: boolean;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/interworking/ylwsystemever/approval/v1/config`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동조직 사용여부 조회. */
async function findDirectory(): Promise<{
  use: boolean;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/linkage?provider=YLWSYSTEMEVER`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동결재 사용여부 수정. */
async function updateApproval(arg: {
  use: boolean;
  updateAt: string;
}): Promise<{
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/interworking/ylwsystemever/approval/v1/config/use`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동조직 사용여부 수정. */
async function updateDirectory(arg: {
  companyId: number;
  provider: string;
  use: boolean;
  updateAt: string;
}): Promise<{
  use: boolean;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/linkage/use`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동결재 구성여부 조회. */
async function findApiKey(): Promise<{
  apiKey: string;
  clientId: string;
  clientSecret: string;
  encryptionKey: string;
  updaterId: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/interworking/ylwsystemever/authenticate/v1/config`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동결재 구성여부 최초 저장. */
async function createApiKey(): Promise<{
  apiKey: string;
  clientId: string;
  clientSecret: string;
  encryptionKey: string;
  updateAt: string;
  updaterId: number;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/interworking/ylwsystemever/authenticate/v1/config`;
    const response = await axios.post(url, {}, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동결재 구성여부 수정. */
async function updateApiKey(arg: { updateAt: string }): Promise<{
  apiKey: string;
  clientId: string;
  clientSecret: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/interworking/ylwsystemever/authenticate/v1/config/apikey/reissue`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동결재 제공자를 조회합니다. */
async function approvalProviderList(): Promise<string[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/interworking/approval/v1/config/provider/all`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연동결재 제공자를 조회합니다. */
async function directoryProviderList(): Promise<string[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/linkage/providers/all`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const preferencesApi = {
  findApiKey,
  createApiKey,
  updateApiKey,

  findApproval,
  findDirectory,

  updateApproval,
  updateDirectory,

  approvalProviderList,
  directoryProviderList,
};

export default preferencesApi;
