import React, { useMemo, useState } from 'react';
import { IconType } from '../../../../../groupware-common/types/icon';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Button from '../../../../../components/button/Button';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import TreeList from '../../../../../components/tree/TreeList';

type Props = {
  items: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  selectedId?: number;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
  onSelect(id: number): void;
};

function AttendanceWorkContentList(props: Props): JSX.Element {
  // console.log(`${AttendanceWorkContentList.name}.render(props)`, props);

  const [filter, setFilter] = useState('');

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const items = useMemo(() => {
    let result = props.items;
    // 필터가 설정된 경우 권한 있는 업무만 아이템 항목으로 설정.
    if (filter !== '')
      result = result.filter(({ icon }) => icon === 'clipboard-edit');
    return result;
  }, [props.items, filter]);
  const { selectedId = NaN, onAction, onSelect } = props;

  return (
    <>
      <EuiSetting.Header title="근태업무">
        <Button
          text="업무 등록"
          iconType
          icon="document-plus"
          onClick={(event) => onAction({ code: 'work/create', event })}
        />
        <Button
          text="분류 등록"
          iconType
          icon="folder-plus"
          onClick={(event) => onAction({ code: 'folder/create', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilter} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <TreeList
          selectedId={selectedId}
          filter={filter}
          items={items}
          onItemClick={onSelect}
        />
      </EuiSetting.Content>
    </>
  );
}

export default AttendanceWorkContentList;
