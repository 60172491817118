/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

const namespace = 'systemlink';

async function fetchFolderList() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approvalinterworking/v1/work/folder/all/simply`;
    const response = await axios.get<
      {
        companyId: number;
        id: number;
        parentId: number;
        seq: number;
        name: string;
        updateAt: string;
      }[]
    >(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/**
 * 업무 옵션.
 * @param option 옵션 값. (bit 연산)
 * @returns useAttachFile 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수
 * @returns useAttachDocument 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수
 * @returns useOpinion 의견 사용 여부 - false: 사용 안 함, true: 사용
 * @returns useComment 댓글 사용 여부 - false: 사용 안 함, true: 사용
 */
function getOption(option: number): {
  useAttachFile: number;
  useAttachDocument: number;
  useOpinion: boolean;
  useComment: boolean;
} {
  // 첨부파일 사용 1 0000 0001
  // 첨부파일 필수 2 0000 0010
  // 첨부문서 사용 4 0000 0100
  // 첨부문서 필수 8 0000 1000
  // 의견 사용    16 0001 0000
  // 의견 필수    32 0010 0000
  // 댓글 사용    64 0100 0000

  // eslint-disable-next-line no-bitwise
  const useAttachFile = option & 1 || option & 2 ? option : 0;
  // eslint-disable-next-line no-bitwise
  const useAttachDocument = option & 4 || option & 8 ? option / 4 : 0;
  // eslint-disable-next-line no-bitwise
  const useOpinion = (option & 16) === 16;
  // eslint-disable-next-line no-bitwise
  const useComment = (option & 64) === 64;

  return {
    useAttachFile,
    useAttachDocument,
    useOpinion,
    useComment,
  };
}

async function fetchList(folderId?: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approvalinterworking/v1/work/all/simply`;
    const params = {
      folderid: folderId,
    };
    const response = await axios.get<
      {
        id: number; // 업무 아이디',
        folderId: number; // 폴더 아이디',
        seq: number;
        status: number;
        name: string;
        updateAt: string;
      }[]
    >(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchView(arg: { id: number }) {
  const { id } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approvalinterworking/v1/work?id=${id}`;
    const response = await axios.get<{
      id: number;
      folderId: number;
      status: number;
      name: string;
      formId: number;
      formName: string;
      receiptFormId: number;
      receiptFormName?: string;
      documentNo: string;
      retentionPeriod: number;
      approvalLine: string;
      referrer: string;
      viewer: string;
      useAttachFile: number;
      useAttachDocument: number;
      useOpinion: boolean;
      useComment: boolean;
      description: string;
      updateAt: string;
    }>(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function save(arg: {
  id: number;
  updateAt: string | undefined;
  status: number;
  formId: number;
  receiptFormId: number | null;
  name: string;
  documentNo: string;
  retentionPeriod: number;
  approvalLine: string;
  referrer: string; // TODO 백엔드 컬럼명 변경 예정. referencePermission
  viewer: string; // TODO 백엔드 컬럼명 변경 예정. viewPermission
  useAttachFile: number;
  useAttachDocument: number;
  useOpinion: boolean;
  useComment: boolean;
  description: string;
}) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approvalinterworking/v1/work`;
    const response = await axios.put<{
      id: number;
      companyId: number;
      updateAt: string;
    }>(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function remove(arg: {
  id: number;
  updateAt: string;
}): Promise<{ companyId: number; id: number; updateAt: string }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/systemlink/v1/work`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const systemLinkWorkFolderApi = {
  namespace,
  fetchList: fetchFolderList,
};

const systemLinkWorkApi = {
  namespace,
  getOption,
  fetchList,
  fetchView,
  save,
  delete: remove,
};

export { systemLinkWorkFolderApi };

export default systemLinkWorkApi;
