import { LinearProgress } from '@material-ui/core';
import React from 'react';

function LiearProgress(props: { data: number }): JSX.Element | null {
  const classname = 'eui-progress';

  return (
    <div className={classname}>
      <div className="progress-wrapper">
        <LinearProgress
          className="progress-bar"
          color="primary"
          variant="determinate"
          value={props.data}
        />
      </div>
      <div className="size-wrapper">
        <em>{props.data}%</em>
      </div>
    </div>
  );
}
export default LiearProgress;
