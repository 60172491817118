import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  documentsActions,
  FolderSetting,
} from '../../../../stores/document/document';
import DocumentLogDrawer from '../common/DocumentLogDrawer';
import DocumentContentBodyList from './DocumentContentBodyList';
import DocumentContentBodyViewContainer from './DocumentContentBodyViewContainer';
import DocumentContentHeadList from './DocumentContentHeadList';
import DocumentContentHeadView from './DocumentContentHeadView';
import { getHost } from '../../../../../groupware-common/apis/common/v1';
import FeedBack from '../../../../../components/alert/FeedBack';
import DocumentViewerDrawer from '../common/DocumentViewerDrawer';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Button from '../../../../../components/button/Button';
import { SearchDateProps } from '../../../../../components/search/Search';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { documentsFileApi } from '../../../../apis/document/v1/common';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { ReadingPaneMode } from '../../../../../groupware-webapp/stores/types';
import DocumentPrintView from '../../../common/DocumentPrintView';
import Confirmation from '../../../../../components/alert/Confirmation';
import DocumentCheckinDialog from '../common/DocumentCheckinDialog';

function DocumentContentContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  const { folderId, documentId } = getPathParams<{
    folderId?: string;
    documentId?: number;
  }>(`/*/:folderId/:documentId$base62`, pathname);
  const queryParams = getQueryParams(search);

  // 고정 폴더 여부 (모든게시함, 중요게시글, 임시보관함)
  const isFixedFolder =
    folderId === 'all' ||
    folderId === 'temp' ||
    folderId === 'importance' ||
    folderId === 'checkout';

  const principal = useSelector((state: RootState) => state.session.principal);
  const folders = useSelector(
    (state: RootState) => state.document.folders.list,
  );
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'status');
  const list = useSelector(
    (state: RootState) => state.document.documents.list.items,
  );
  const totalCount = useSelector(
    (state: RootState) => state.document.documents.list.totalCount,
  );

  const view = useSelector((state: RootState) => state.document.documents.view);

  const display = useSelector((state: RootState) => state.session.display);
  const resource = useSelector((state: RootState) => state.session.resource);

  const readingPaneMode = useSelector((state: RootState) =>
    folderId === 'temp' || display === 'phone'
      ? 'list'
      : state.document.documents.readingPaneMode,
  );
  const folderSetting = useSelector(
    (state: RootState) => state.document.documents.folderSetting,
  );

  const displayDensity = useSelector(
    (state: RootState) => state.document.documents.displayDensity,
  );

  /** 분할 보기 여부. */
  const isSplitView =
    readingPaneMode === 'horizontal' || readingPaneMode === 'vertical';
  const rowsPerPage =
    queryParams.rowsPerPage ?? folderSetting.setting?.rowsPerPage ?? 15;

  const initialState = {
    tempFolderId: 0,
    selectedVersionId: 0,
    validation: '',
  };

  const [state, setState] = useState<{
    tempFolderId: number;
    selectedVersionId: number;
    validation: string;
  }>(initialState);

  /** 리스트 컬럼 */
  const [defaultColumns, setDefaultColumns] = useState<
    Column<PostListItemType>[]
  >([
    { name: 'checked', text: '', type: 'action', visible: true },
    { name: 'importance', text: '', type: 'importance', visible: true },
    {
      name: 'subject',
      text: '제목',
      type: 'post',
      visible: true,
      disable: true,
    },
    { name: 'views', text: '조회', type: 'count', visible: true },
    // { name: 'likes', text: '좋아요', type: 'count', visible: true },
    { name: 'user', text: '수정자', type: 'user', visible: true },
    { name: 'updateAt', text: '수정일', type: 'date', visible: true },
  ]);

  useEffect(() => {
    const { setting } = folderSetting;
    if (setting) {
      setDefaultColumns(
        defaultColumns.map((a) => {
          if (setting.columns.some((x) => x === a.name)) {
            return {
              ...a,
              visible: true,
            };
          }
          return {
            ...a,
            visible: false,
          };
        }),
      );
    } else {
      setDefaultColumns(
        defaultColumns.map((a) => ({
          ...a,
          visible: true,
        })),
      );
    }
  }, [folderId]);

  const columns =
    folderId === 'temp'
      ? defaultColumns.filter(
          ({ name }) =>
            !(
              name === 'importance' ||
              name === 'views' ||
              name === 'likes' ||
              name === 'user'
            ),
        )
      : defaultColumns;

  /**
   * 이동 가능한 폴더 아이템.
   * 이동 권한이 있는 폴더 → 쓰기 권한이 있는 폴더로 이동.
   * */
  const movableFolderItems = folders
    .filter(({ permissions }) => permissions?.useWrite)
    .map((a) => ({
      id: a.id,
      seq: a.seq,
      parentId: a.parentId,
      text: a.name,
      icon: 'folder' as const,
    }))
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  /** 리스트 아이템 체크 변경. */
  const handleListItemCheckedChange = (itemId: 'all', checked: boolean) => {
    dispatch(documentsActions.checked({ itemId, checked }));
  };
  /** 조회. */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    const { keyword, directoryKeyword, filter, date } = arg;
    let searchPath = pathname;
    if (isSplitView) searchPath = `${getPathMap('/*/*', pathname)}`;

    delete queryParams.type;
    queryParams.pageNo = 1;
    queryParams.searchCode = keyword === '' ? undefined : filter;
    queryParams.searchWord = keyword === '' ? undefined : keyword;
    queryParams.startDate = date.start
      ? dateFormat(initialDate(date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = date.end
      ? dateFormat(initialDate(date.end), 'yyyy-MM-DD')
      : undefined;
    queryParams.directoryKeyword = directoryKeyword;
    queryParams.directoryFilter =
      directoryKeyword && keyword !== '' ? 'true' : undefined;

    go(searchPath, getQueryParams(queryParams));
  };

  /** 파일 다운로드. */
  const download = (blob: Blob) => {
    if (!blob) throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  /** 헤드 액션 */
  const handleHeadAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;
    // 이전 페이지인 경우.
    if (code === 'prevPage') {
      const pageNo = (queryParams.pageNo || 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        if (isSplitView) go(`${getPathMap('/*/*', pathname)}`, queryParams);
        else go(pathname, queryParams);
      }
      return;
    }
    // 다음 페이지인 경우.
    if (code === 'nextPage') {
      const pageNo = (queryParams.pageNo || 1) + 1;
      if ((rowsPerPage || 1 * pageNo) < totalCount) {
        queryParams.pageNo = pageNo;
        if (isSplitView) go(`${getPathMap('/*/*', pathname)}`, queryParams);
        else go(pathname, queryParams);
      }
      return;
    }
    // 목록 설정인 경우.
    if (code === 'listSetting') {
      dispatch(sessionActions.setDrawer({ type: code }));
    }
    // 폴더 보기에서 등록할 경우.
    if (code === 'selectWrite') {
      queryParams.contentMode = 'create';
      queryParams.contentType = 'select';
      go(pathname, queryParams);
    }
    // 문서 보기에서 문서 삭제
    if (code === 'viewToTrash') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    // 목록에서 문서 삭제
    if (code === 'listToTrash') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    // 목록에서 문서 이동
    if (code === 'listToMove') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    // 인쇄
    if (code === 'prints') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    // HTML 저장
    if (code === 'saves') {
      const saveList = list.filter((x) => x.checked);
      if (saveList.length === 0) return;
      documentsFileApi
        .htmlDownload(saveList.map((a) => a.id))
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
  };

  /** 리스트 아이템 클릭. */
  const handleListItemClick = (arg: { id: string; isNotice: boolean }) => {
    const { isNotice } = arg;
    const id = isNotice
      ? parseInt(arg.id.split('_')[0], 10)
      : parseInt(arg.id, 10);
    if (isNotice) queryParams.type = 'notice';
    else delete queryParams.type;
    let searchPath = '';
    if (!folderId || folderId === 'all') {
      searchPath = `${getPathMap(`/*`, pathname)}/all/${b62(id)}`;
    }
    // 폴더가 임시 보관인 경우 글쓰기로 이동.
    else if (folderId === 'temp') {
      queryParams.contentMode = 'create';
      queryParams.contentType = 'temp';
      searchPath = `${getPathMap('/*/*', pathname)}/${b62(id)}`;
    } else searchPath = `${getPathMap(`/*/*`, pathname)}/${b62(id)}`;
    if (isSplitView) {
      const route = {
        pathname: searchPath,
        search: getQueryParams(queryParams),
      };
      dispatch(documentsActions.view({ id, route }));
    } else {
      go(searchPath, getQueryParams(queryParams));
    }
  };

  const handleItemCheckedChange = (id: string, checked: boolean) => {
    dispatch(documentsActions.checked({ itemId: id, checked }));
  };

  // 컬럼 보이기 변경.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setDefaultColumns((prevState) =>
      prevState.map((x) => (x.name === name ? { ...x, visible } : x)),
    );

    const visibleCloumns = defaultColumns.map((a) => {
      if (a.name === name)
        return {
          ...a,
          visible,
        };
      return {
        ...a,
        visible: a.visible,
      };
    });
    const setting: FolderSetting = {
      version: '1.0',
      density: displayDensity,
      readingPaneMode,
      columns: visibleCloumns.filter((a) => a.visible).map((a) => a.name),
      rowsPerPage,
    };
    if (folderId !== 'temp')
      dispatch(
        documentsActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      );
  };

  // 화면밀도 설정
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(documentsActions.displayDensity(value));
    const setting: FolderSetting = {
      version: '1.0',
      density: value,
      readingPaneMode,
      columns: defaultColumns.filter((a) => a.visible).map(({ name }) => name),
      rowsPerPage,
    };
    if (folderId !== 'temp')
      dispatch(
        documentsActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      );
  };

  // 드로워 닫기.
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  // 목록 개수 변경.
  const handleChangeListCount = (value: string) => {
    const path = `${getPathMap('/*/*', pathname)}`;
    const query = createQueryString(
      {
        pageNo: 1,
        rowsPerPage: parseInt(value, 10),
        drawerMode: undefined,
        drawerType: undefined,
        type: undefined,
      },
      queryParams,
    );
    const setting: FolderSetting = {
      version: '1.0',
      density: displayDensity,
      readingPaneMode,
      columns: defaultColumns.filter((a) => a.visible).map(({ name }) => name),
      rowsPerPage: parseInt(value, 10),
    };
    if (folderId !== 'temp')
      dispatch(
        documentsActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      ).then(() => go(path, query));
    else go(path, query);
  };

  /** 목록 이동. */
  const handleListGo = () => {
    go(`${getPathMap('/*/*', pathname)}`, search);
  };

  /** 문서 보기. */
  const handleViewDocument = (arg: {
    affiliatedCompanyId?: number;
    id: number;
  }) => {
    const { id } = arg;
    go(`${getPathMap('/*/*', pathname)}/${b62(id)}`, search);
  };

  /** 헤드 뷰 액션 */
  const handleHeadViewAction = (arg: {
    code: string;
    event: React.MouseEvent;
  }) => {
    const { code: type } = arg;
    // console.log(`handleHeadViewAction() arg:`, arg);
    // 수정
    if (type === 'update') {
      queryParams.contentMode = 'update';
      delete queryParams.contentType;
      go(pathname, queryParams);
    }
    // 문서활동
    if (type === 'log') {
      dispatch(sessionActions.setDrawer({ type }));
    }
    // 조회
    if (type === 'viewer') {
      dispatch(sessionActions.setDrawer({ type }));
    }
    // 체크아웃
    if (type === 'checkout') {
      dispatch(sessionActions.setDialog({ type }));
    }
    // 체크인
    if (type === 'checkin') {
      dispatch(sessionActions.setDialog({ type }));
    }
    // 문서 삭제
    if (type === 'viewToTrash') {
      dispatch(sessionActions.setDialog({ type }));
    }
    // // 문서 복사
    // if (type === 'copy') {
    //   dispatch(sessionActions.setDialog({ type }));
    // }
    // 문서 이동
    if (type === 'move') {
      dispatch(sessionActions.setDialog({ type }));
    }
    // 인쇄
    if (type === 'print') dispatch(sessionActions.setDialog({ type }));
    // 저장
    if (type === 'save' && view) {
      documentsFileApi
        .htmlDownload([view.id])
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
    // URL 복사
    if (type === 'URLCopy') {
      const url = getHost() + pathname;
      window.navigator.clipboard.writeText(url).then(() => {
        setState((prev) => ({
          ...prev,
          validation: '클립보드에 URL이 복사되었습니다.',
        }));
      });
    }
  };

  // 보기모드 변경.
  const handleReadingPaneModeChange = (mode: ReadingPaneMode) => {
    if (mode === 'list') {
      const location = utils.getLocation({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*', pathname)}`,
          mode: 'replace',
        },
      });
      dispatch(sessionActions.setRoute(location));
    }
    dispatch(documentsActions.setReadingPaneMode(mode));
    const setting: FolderSetting = {
      version: '1.0',
      density: displayDensity,
      readingPaneMode: mode,
      columns: defaultColumns.filter((a) => a.visible).map(({ name }) => name),
      rowsPerPage,
    };
    if (folderId !== 'temp')
      dispatch(
        documentsActions.saveFolderSetting({
          folderId,
          setting,
          updateAt: folderSetting.updateAt,
        }),
      );
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 대화상자 닫기. */
  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  /** 문서 삭제 */
  const handleDocumentDelete = () => {
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    // 리스트일 때
    if (queryParams.dialogType === 'listToTrash' && folderId) {
      const deleteList = list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            id: a.id,
            updateAt: a.updateAt,
          };
        });
      dispatch(
        documentsActions.delete({
          data: deleteList,
          location,
        }),
      );
    }
    // 뷰화면일 때
    if (queryParams.dialogType === 'viewToTrash' && folderId && view) {
      if (queryParams.pageNo) delete queryParams.pageNo;
      const data = {
        id: view.id,
        updateAt: view.updateAt,
      };
      dispatch(
        documentsActions.delete({
          data,
          location,
        }),
      );
    }
  };

  /** 문서 체크아웃 */
  const handleDocumentCheckout = (changeReason?: string) => {
    if (view === undefined) return;
    const data = {
      id: view.id,
      changeReason:
        changeReason && changeReason !== '' ? changeReason : undefined,
      updateAt: view.updateAt,
      isCheckout: queryParams.dialogType === 'checkout',
    };
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    if (queryParams.pageNo) delete queryParams.pageNo;
    if (queryParams.dialogType) delete queryParams.dialogType;

    const route = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        search: getQueryParams(queryParams),
      },
    });

    if (isSplitView)
      dispatch(
        documentsActions.checkout({
          data,
          location,
        }),
      );
    else
      dispatch(
        documentsActions.checkout({
          data,
          route,
        }),
      );

    setState(initialState);
  };

  /** 문서 폴더 이동. */
  const handleMoveFolder = (id: number) => {
    if (id === view?.folderId) {
      setState((prev) => ({
        ...prev,
        validation: '원본 폴더와 대상 폴더가 동일합니다.',
      }));
      return;
    }

    if (id === 0) return;

    let data:
      | {
          folderId: number;
          id: number;
          updateAt: string;
        }
      | {
          folderId: number;
          id: number;
          updateAt: string;
        }[] = [];

    if (queryParams.dialogType === 'move' && view) {
      data = {
        folderId: id,
        id: view.id,
        updateAt: view.updateAt,
      };
    } else {
      data = list
        .filter((x) => x.checked)
        .map((a) => {
          return {
            folderId: id,
            id: a.id,
            updateAt: a.updateAt,
          };
        });
    }

    queryParams.pageNo = 1;
    if (
      (!queryParams.pageNo || queryParams.pageNo === 1) &&
      (isSplitView || (!isSplitView && queryParams.dialogType === 'listToMove'))
    ) {
      delete queryParams.dialogType;
      dispatch(
        documentsActions.move({
          data,
          route: utils.getRoute({
            target: props,
            source: {
              pathname: `${getPathMap('/*/*', pathname)}`,
              search: getQueryParams(queryParams),
            },
          }),
        }),
      );
    } else {
      delete queryParams.dialogType;
      dispatch(
        documentsActions.move({
          data,
          location: utils.getLocation({
            target: props,
            source: {
              pathname: `${getPathMap('/*/*', pathname)}`,
              search: getQueryParams(queryParams),
              mode: 'replace',
            },
          }),
        }),
      );
    }
    dispatch(sessionActions.setDialog());
  };

  const getFolderTitle = (): string => {
    switch (folderId) {
      case 'all':
      case 'temp':
      case 'importance':
      case 'checkout':
      case undefined:
        return categories.find((a) => a.id === (folderId ?? 'all'))?.name ?? '';
      default:
        return folders.find((a) => a.id === b62(folderId))?.name ?? '';
    }
  };

  /** 중요 표시 변경 이벤트 */
  const handleListItemImportanceChange = (arg: {
    id: number;
    isNotice: boolean;
  }) => {
    const { id } = arg;
    const item = list.find((a) => a.id === id);
    if (!item) return;
    // console.log(`handleListItemImportanceChange() item:`, item);
    dispatch(
      documentsActions.saveUnSaveStar({
        star: !item.isStarred,
        id,
        employeeId: principal.employeeId,
        noLoading: true, // 로딩바 표시 안함.
      }),
    ).then((result) => {
      if (
        (result as { error?: string }).error === undefined &&
        folderId === 'importance'
      ) {
        const { pageNo = 1 } = queryParams;
        const path = `${getPathMap('/*/*', pathname)}`;
        const currentPage = (pageNo - 1) * rowsPerPage + 1;
        if (totalCount === currentPage && pageNo > 1) {
          queryParams.pageNo = pageNo - 1;
          go(path, queryParams);
        } else if (isSplitView && documentId && view && item.id === view.id) {
          queryParams.pageNo = pageNo;
          const route = { pathname: path, search: getQueryParams(queryParams) };
          dispatch(
            documentsActions.list({
              folderId,
              // search: getQueryParams(queryParams),
              isStarred: true,
              isTemporary: false,
              isCheckout: false,
              route,
            }),
          );
        } else
          dispatch(
            documentsActions.list({
              folderId,
              // search: getQueryParams(queryParams),
              isStarred: true,
              isTemporary: false,
              isCheckout: false,
            }),
          );
      }
    });
  };

  /** 첨부파일 파일 다운로드 이벤트. */
  const handleFileDownload = (id: number, fileId: number, name: string) => {
    const versionSeq = list.find((a) => a.id === id)?.versionSeq;
    if (versionSeq === undefined) return;
    documentsFileApi
      .download(id, fileId, versionSeq)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const renderHead = (arg?: { type: 'list' | 'view' }) => {
    const type =
      arg?.type || (readingPaneMode === 'list' && documentId) ? 'view' : 'list';

    // 뷰 보기인 경우
    if (type === 'view') {
      if (!view) {
        if (readingPaneMode === 'vertical' || readingPaneMode === 'horizontal')
          return null;
        return (
          <DocumentContentHeadView
            usePagination={false}
            type="full"
            onListGo={handleListGo}
            toolbarButtons={[]}
            onToolbarAction={handleHeadViewAction}
          />
        );
      }

      const folder = folders.find((a) => a.id === view.folderId);

      const useCheckout = folder?.option.isCheckout ?? false;

      let toolbarButtons: ActionEventProps[] = [];

      // 문서 수정 권한 (관리자, 쓰기/수정 권한을 가진 자)
      const hasModify = folder?.permissions?.useWrite ?? false;

      // 문서 삭제 권한 (최초 작성자 혹은 관리자이면서 쓰기 권한을 가진 자)
      const hasDelete =
        ((view.creatorId === principal.employeeId &&
          folder?.permissions?.useWrite) ||
          folder?.isAdmin) ??
        false;

      // 문서함에서 체크아웃을 사용할 때
      if (useCheckout) {
        // 사용자가 쓰기/수정권한을 가질 때
        if (hasModify) {
          if (view.status === 'ACTIVE') {
            // 문서 상태가 ACTIVE일 때
            toolbarButtons = [
              {
                code: 'checkout',
                label: '체크아웃',
                type: 'text',
                icon: 'document-update',
              },
            ];
            if (hasDelete)
              toolbarButtons = [
                ...toolbarButtons,
                {
                  code: 'viewToTrash',
                  label: '삭제',
                  type: 'text',
                  icon: 'trash-full',
                },
              ];
          }
          // 문서 상태가 체크아웃 상태이고, 사용자가 체크아웃을 했을 때
          if (view.status === 'CHECKOUT' && view.isCheckoutInMine) {
            toolbarButtons = [
              {
                code: 'checkin',
                label: '체크인',
                type: 'text',
                icon: 'document-check',
              },
              { code: 'update', label: '수정', type: 'text', icon: 'pen-line' },
            ];
          }
        }
      }
      // 문서함 체크아웃을 사용하지 않을 때
      if (!useCheckout) {
        // 체크아웃을 사용하지 않고 문서가 체크아웃 상태일 때
        if (view.status === 'CHECKOUT') {
          // 사용자가 체크아웃 권한을 가지고 있을 때
          if (view.isCheckoutInMine) {
            toolbarButtons = [
              {
                code: 'checkin',
                label: '체크인',
                type: 'text',
                icon: 'document-check',
              },
            ];
            // 사용자가 수정 권한을 가지고 있을 때
            if (hasModify)
              toolbarButtons = [
                ...toolbarButtons,
                {
                  code: 'update',
                  label: '수정',
                  type: 'text',
                  icon: 'pen-line',
                },
              ];
          }
        } else {
          // 사용자가 수정 권한을 가지고 있을 때
          if (hasModify)
            toolbarButtons = [
              {
                code: 'update',
                label: '수정',
                type: 'text',
                icon: 'pen-line',
              },
            ];
          // 사용자가 삭제 권한을 가지고 있을 때
          if (hasDelete)
            toolbarButtons = [
              ...toolbarButtons,
              {
                code: 'viewToTrash',
                label: '삭제',
                type: 'text',
                icon: 'trash-full',
              },
            ];
        }
      }
      // 문서가 활동 상태이고 관리자일 때만 이동 버튼
      if (view.status === 'ACTIVE' && folder?.isAdmin)
        toolbarButtons = [
          ...toolbarButtons,
          { code: 'move', label: '이동', type: 'text', icon: 'folder-move' },
        ];
      if (!folderId || folderId === 'all' || folderId === 'importance')
        toolbarButtons = [];

      toolbarButtons = [
        ...toolbarButtons,
        {
          code: 'log',
          label: '문서활동',
          type: 'text',
          icon: 'document-search',
        },
      ];

      if (display !== 'phone' || resource !== 'teams')
        toolbarButtons = [
          ...toolbarButtons,
          { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
          { code: 'save', label: '저장', type: 'text', icon: 'save' },
        ];

      toolbarButtons = [
        ...toolbarButtons,
        {
          code: 'viewer',
          label: '조회',
          type: 'text',
          icon: 'eye',
        },
        {
          code: 'URLCopy',
          label: 'URL복사',
          type: 'text',
          icon: 'paperclip-v',
        },
      ];

      if (readingPaneMode === 'vertical' || readingPaneMode === 'horizontal') {
        return (
          <DocumentContentHeadView
            usePagination={false}
            type="split"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleHeadViewAction}
          />
        );
      }

      const { prev, next } = view;
      return (
        <DocumentContentHeadView
          usePagination={queryParams.type !== 'notice'}
          type="full"
          onListGo={handleListGo}
          onPrev={prev ? () => handleViewDocument(prev) : undefined}
          onNext={next ? () => handleViewDocument(next) : undefined}
          toolbarButtons={toolbarButtons}
          onToolbarAction={handleHeadViewAction}
        />
      );
    }
    if (list === null || !list) return null;

    const title = getFolderTitle();
    const folder = folders.find((a) => a.id === b62(folderId));

    let toolbarButtons: ActionEventProps[] =
      display !== 'phone' || resource !== 'teams'
        ? [
            { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
            { code: 'saves', label: '저장', type: 'icon', icon: 'save' },
          ]
        : [];
    let optionToolbarButtons: ActionEventProps[] = [];
    // TODO: 복사 기능 추후 개발 예정.
    // if (folder?.permissions?.useCopy)
    //   optionToolbarButtons = [
    //     { code: 'listCopy', label: '복사', type: 'icon', icon: 'copy' },
    //   ];

    if (folder?.isAdmin)
      optionToolbarButtons = [
        ...optionToolbarButtons,
        {
          code: 'listToTrash',
          label: '삭제',
          type: 'icon',
          icon: 'trash-full',
        },
        {
          code: 'listToMove',
          label: '이동',
          type: 'icon',
          icon: 'folder-move',
        },
      ];

    switch (folderId) {
      case 'all':
      case 'importance':
      case undefined:
        toolbarButtons = [...toolbarButtons];
        break;
      case 'temp':
        toolbarButtons = [
          {
            code: 'listToTrash',
            label: '삭제',
            type: 'icon',
            icon: 'trash-full',
          },
        ];
        break;
      default: {
        toolbarButtons = [...optionToolbarButtons, ...toolbarButtons];
        break;
      }
    }
    return (
      <DocumentContentHeadList
        folderId={folderId}
        title={title}
        pageNo={queryParams.pageNo || 1}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        checkedCount={list.filter((x) => x.checked).length}
        onCheckedChange={handleListItemCheckedChange}
        toolbarButtons={toolbarButtons}
        startDate={queryParams.startDate}
        endDate={queryParams.endDate}
        searchCode={queryParams.searchCode}
        searchWord={queryParams.searchWord}
        onSearch={handleSearch}
        onAction={handleHeadAction}
      />
    );
  };

  const renderBody = () => {
    let result = null;
    const type: PostListType =
      readingPaneMode === 'vertical' ? 'split' : 'full';
    const listComponent: JSX.Element = (
      <DocumentContentBodyList
        pathname={pathname}
        search={search}
        type={type}
        density={displayDensity}
        columns={
          readingPaneMode === 'list' || readingPaneMode === 'horizontal'
            ? columns.filter(({ visible }) => visible)
            : columns
        }
        items={list}
        selectedId={folderId}
        readingPaneMode={readingPaneMode}
        onItemCheckedChange={handleItemCheckedChange}
        onItemClick={handleListItemClick}
        onItemImportanceChange={handleListItemImportanceChange}
        onFileDownload={handleFileDownload}
      />
    );

    switch (readingPaneMode) {
      // 좌우 분할.
      case 'vertical':
        if (!list) return null;
        result = (
          <EuiBody>
            <SplitPane
              className="ui-split eui-post"
              split="vertical"
              minSize={344}
              maxSize={560}
              defaultSize={parseInt(
                localStorage.getItem('approval-split-vertical-sizes') || '400',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem('approval-split-vertical-sizes', `${size}`)
              }
            >
              {listComponent}
              <>
                {renderHead({ type: 'view' })}
                <DocumentContentBodyViewContainer
                  pathname={pathname}
                  search={search}
                  type="split"
                  view={view}
                />
              </>
            </SplitPane>
          </EuiBody>
        );
        break;
      // 상하 분할.
      case 'horizontal':
        if (!list) return null;
        result = (
          <EuiBody>
            <SplitPane
              className="ui-split eui-post"
              split="horizontal"
              minSize={150}
              defaultSize={parseInt(
                localStorage.getItem('approval-split-horizontal-sizes') ||
                  '250',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem(
                  'approval-split-horizontal-sizes',
                  `${size}`,
                )
              }
            >
              {listComponent}
              <>
                {renderHead({ type: 'view' })}
                <DocumentContentBodyViewContainer
                  pathname={pathname}
                  search={search}
                  type="full"
                  view={view}
                />
              </>
            </SplitPane>
          </EuiBody>
        );
        break;
      // 분할안함.
      default:
        // 뷰인 경우.
        if (documentId) {
          result = (
            <EuiBody>
              <DocumentContentBodyViewContainer
                pathname={pathname}
                search={search}
                type="full"
                view={view}
              />
            </EuiBody>
          );
        }
        // 리스트인 경우.
        else {
          result = <EuiBody>{listComponent}</EuiBody>;
        }
        break;
    }
    return result;
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 체크아웃
    if (dialogType === 'checkout') {
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message">
              문서를 체크아웃 하시겠습니까?
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              text="확인"
              variant="contained"
              onClick={() => handleDocumentCheckout()}
            />
            <Button text="취소" onClick={handleDialogClose} />
          </DialogFooter>
        </Dialog>
      );
    }
    // 체크인
    if (dialogType === 'checkin') {
      return (
        <DocumentCheckinDialog
          onSave={handleDocumentCheckout}
          onClose={handleDialogClose}
        />
      );
    }
    // 문서 삭제.
    if (dialogType === 'viewToTrash') {
      if (!view) return null;
      return (
        <Confirmation
          noDuplication={state.validation === ''}
          submitLabel="삭제"
          submitColor="danger"
          onCancel={handleCloseDialog}
          onSubmit={handleDocumentDelete}
        >
          <>
            <strong>&apos;{view.subject}&apos;</strong> 을(를) 정말
            삭제하시겠습니까?
          </>
        </Confirmation>
      );
    }
    // 문서 삭제.
    if (dialogType === 'listToTrash') {
      const deleteList = list.filter((a) => a.checked);
      const message =
        deleteList.length === 1 ? (
          <>
            <strong>&apos;{deleteList[0].subject}&apos;</strong> 을(를) 정말
            삭제하시겠습니까?
          </>
        ) : (
          <>
            <strong>&apos;{deleteList[0].subject}&apos;</strong> 외{' '}
            {deleteList.length - 1}건을 정말 삭제하시겠습니까?
          </>
        );
      return (
        <Confirmation
          noDuplication={state.validation === ''}
          submitLabel="삭제"
          submitColor="danger"
          onCancel={handleCloseDialog}
          onSubmit={handleDocumentDelete}
        >
          {message}
        </Confirmation>
      );
    }
    if (dialogType === 'move' || dialogType === 'listToMove') {
      return (
        <TreePicker
          title="폴더 선택"
          disabledId={folderId && !isFixedFolder ? b62(folderId) : undefined}
          list={movableFolderItems}
          onSelected={(id) => handleMoveFolder(id)}
          onClose={handleCloseDialog}
        />
      );
    }

    if (dialogType === 'print' || dialogType === 'prints') {
      let listId:
        | {
            id: number;
            updateAt: string;
          }[]
        | undefined;
      if (dialogType === 'print' && documentId && view) {
        listId = [
          {
            id: view.id,
            updateAt: view.updateAt,
          },
        ];
      } else if (dialogType === 'prints') {
        listId = list
          .filter((x) => x.checked)
          .map((a) => {
            return {
              id: a.id,
              updateAt: a.updateAt,
            };
          });
      }
      if (listId === undefined) return null;
      return (
        <DocumentPrintView
          pathname={pathname}
          listId={listId}
          onClose={handleDialogClose}
        />
      );
    }
    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    // 목록설정
    if (drawerType === 'listSetting') {
      const listColumns = columns.filter(
        ({ name }) => name !== 'checked' && name !== 'importance',
      );
      return (
        <ListSetting
          module="document"
          type={readingPaneMode}
          propsFolderId={b62(folderId)}
          columns={listColumns}
          onColumnVisibleChange={handleColumnVisibleChange}
          readingPaneMode={readingPaneMode}
          onReadingPaneModeChange={handleReadingPaneModeChange}
          density={displayDensity}
          onChangeDensity={handleChangeDensity}
          listCount={`${rowsPerPage}`}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
          isDisableModeOption={folderId === 'temp'}
          pathname="document"
        />
      );
    }

    // 문서활동
    if (drawerType === 'log') {
      if (documentId === undefined) return undefined;
      return (
        <DocumentLogDrawer
          documentId={documentId}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 조회
    if (drawerType === 'viewer') {
      if (view === null || view === undefined) return null;
      return (
        <DocumentViewerDrawer
          type="viewer"
          title="조회 확인"
          viewId={view.id}
          onClose={handleCloseDrawer}
        />
      );
    }
    // 추천
    // if (drawerType === 'likes') {
    //   if (view === null || view === undefined) return null;
    //   return (
    //     <DocumentViewerDrawer
    //       type="likes"
    //       title="좋아요 확인"
    //       viewId={view.id}
    //       onClose={handleCloseDrawer}
    //     />
    //   );
    // }
    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDrawer()}
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default DocumentContentContainer;
