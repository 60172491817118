import React from 'react';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import ResourceApprovalContainer from '../pages/root/resource/approval/ResourceApprovalContainer';
import ResourceComposeContainer from '../pages/root/resource/compose/ResourceComposeContainer';
import ResourceContentContainer from '../pages/root/resource/content/ResourceContentContainer';
import ResourceReservationListContainer from '../pages/root/resource/list/ResourceReservationListContainer';
import ResourcePreferencesContainer from '../pages/root/resource/preferences/ResourcePreferencesContainer';

function ResourceLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey } = props;
  const { id } = getPathParams('/*/:id', pathname);
  const { contentMode } = getQueryParams(search);

  if (contentMode === 'create' || contentMode === 'update')
    return (
      <ResourceComposeContainer
        pathname={pathname}
        search={search}
        hash={hash}
        key={locationKey}
      />
    );
  if (id === undefined || id === 'shared')
    return (
      <ResourceReservationListContainer pathname={pathname} search={search} />
    );
  if (id === 'preferences') return <ResourcePreferencesContainer />;
  if (id === 'approval' || id === 'return')
    return <ResourceApprovalContainer pathname={pathname} search={search} />;
  return (
    <ResourceContentContainer pathname={pathname} search={search} hash={hash} />
  );
}

export default ResourceLocateContainer;
