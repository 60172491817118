/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import TextField from '../../../../../components/textfield/TextField';
import PostWrite from '../../../../../components/post/PostWrite';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  goBack,
  utils,
} from '../../../../../groupware-common/utils';
import { FileUploadProps } from '../../../../../groupware-common/types';
import AddAttachDocument from '../../../../../components/attachments/AddAttachDocument';
import ApprovalChange from '../ApprovalChange';
import ApprovalComposeWorkSelectContainer, {
  ApprovalComposeWorkSelectConfirmArg,
} from './ApprovalComposeWorkSelectContainer';
import ApprovalAttachedDocumentSelectDrawerContainer from './ApprovalAttachedDocumentSelectDrawerContainer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ApprovalLineDialogContainer, {
  ApprovalLineGroupItemEmployeeType,
  ApprovalLineGroupItemOrganizationType,
  ApprovalLineGroupItemsAgreeType,
  ApprovalLineGroupItemsApprovalType,
  ApprovalLineType,
  getApprovalLineDrafter,
  getCurrentApprover,
  getLastApprover,
  SharePermissionType,
} from '../../../common/dialogs/ApprovalLineDialogContainer';
import {
  getDirectoryData,
  getName,
} from '../../../../../groupware-webapp/stores/common/utils';
import DraftRequestDialogContainer, {
  DraftRequestConfirmArg,
} from './DraftRequestDialogContainer';
import {
  directoryEqualityFn,
  directorySelector,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { ApiError } from '../../../../../groupware-common/types/error';
import { documentActions } from '../../../../stores/approval/document';
import fileApi from '../../../../../groupware-common/apis/file/v1';
import approvalWorkApi from '../../../../apis/approval/v1/work';
import FormBuilder from '../../../../stores/approval/FormBuilder';
import { dateTimeFormat } from '../../../../../groupware-common/utils/ui';
import { approvalActText } from '../../../common/drawers/ApprovalStatusDrawer';
import MemoizeDivElement from '../common/components/MemoizeDivElement';
import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import { getJobClassName } from '../../../../../groupware-directory/apis/directory/v1';
import FeedBack from '../../../../../components/alert/FeedBack';
import { companyActions } from '../../../../../groupware-directory/stores/directory/company';
import { employeeActions } from '../../../../../groupware-directory/stores/directory/employee';
import approvalFormApi from '../../../../apis/approval/v1/form';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import ApprovalAttachedDocumentSelectDialog from './ApprovalAttachedDocumentSelectDialog';
import ApprovalDocumentPreviewDialog from './ApprovalDocumentPreviewDialog';
import { attachedDocumentActions } from '../../../../stores/approval/attachedDocument';
import NavigationGuard from '../../../../../components/prompt/NavigationGuard';
import { history } from '../../../../..';
import AddProgressAttachments from '../../../../../components/attachments/AddProgressAttachments';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

type Props = {
  pathname: string;
  search: string;
};

/** 채번 전 문서번호 매크로 표시 replace */
function documentMacroReplace(documentNo: string): string {
  return documentNo
    .replace(/{년도}/gm, `년도`)
    .replace(/{월}/gm, `월`)
    .replace(/{순번}/gm, '');
}

function ApprovalComposeContainer(props: Props): JSX.Element {
  // console.log(`${ApprovalComposeContainer.name}.render(props)`, props);

  /** 최상위 부서 찾기. */
  const findTopLevelOrganization = (arg: {
    organizations: { companyId: number; id: number; parentId: number }[];
    companyId: number;
    id: number;
  }) => {
    const { organizations, companyId, id } = arg;
    const parents = getParentItems(organizations, id);

    if (parents.length > 1 && parents[1].id !== id) {
      return {
        id: parents[1].id,
        name: getOrganizationName({ companyId, organizationId: parents[1].id }),
      };
    }
    return undefined;
  };

  /** 문서번호 변경. */
  const replaceDocumentNo = (arg: {
    documentNo: string;
    workName: string;
    formName: string;
    organizationName: string;
    employeeName: string;
  }) => {
    const { documentNo, workName, formName, organizationName, employeeName } =
      arg;
    return documentNo
      .replace(/{(업무명)}/gm, workName)
      .replace(/{(양식명)}/gm, formName)
      .replace(/{(조직명)}/gm, organizationName)
      .replace(/{(기안자)}/gm, employeeName)
      .replace(/┼/gm, '');
  };

  /** 결재상신 시 업로드 파일 객체 생성 */
  const createUploadFiles = (attachedFiles: FileUploadProps[]) => {
    return attachedFiles
      .filter(
        (a): a is { id: string; name: string; size: number; file: File } =>
          a.file !== undefined,
      )
      .map((a) => {
        return {
          id: parseInt(a.id, 10),
          file: a.file,
        };
      });
  };
  /** 첨부파일 객체 생성 */
  const createAttachedFiles = (data: {
    attachedFiles: FileUploadProps[];
    isReDraftDocuments?: boolean;
    documentId?: number;
    uploadPath: string;
  }): {
    path: string;
    id: number;
    documentId?: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[] => {
    const { attachedFiles, isReDraftDocuments, documentId, uploadPath } = data;

    return attachedFiles.map((a, i) => {
      return {
        path: a.file !== undefined ? uploadPath : '',
        id: parseInt(a.id, 10),
        documentId:
          isReDraftDocuments && a.file === undefined && documentId
            ? documentId
            : undefined,
        seq: i + 1,
        name: a.name,
        size: a.size,
        copy: isReDraftDocuments && a.file === undefined ? true : undefined,
      };
    });
  };

  /** 결재그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineApprovalFiltered = (
    items: ApprovalLineGroupItemsApprovalType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 합의그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineAgreeFiltered = (
    items: ApprovalLineGroupItemsAgreeType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재,합의 제외 그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineDefaultFiltered = (
    items: Array<
      ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
    >,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재선 생성 시 결재자 정보, 결재 행위, 날짜 undefined 처리. */
  const createApprovalLineUndefined = () => {
    return {
      act: undefined,
      actAt: undefined,
      approverCompanyId: undefined,
      approverCompanyName: undefined,
      approverOrganizationId: undefined,
      approverOrganizationName: undefined,
      approverEmployeeId: undefined,
      approverEmployeeName: undefined,
      approverJobClassType: undefined,
      approverJobPositionId: undefined,
      approverJobPositionName: undefined,
      approverJobDutyId: undefined,
      approverJobDutyName: undefined,
    };
  };

  /** 결재선 생성 시 직원 정보 생성. */
  const createApprovalLineEmployeeData = (data: {
    companyId: number;
    organizationId?: number;
    employeeId: number;
  }) => {
    const { companyId, organizationId, employeeId } = data;
    const employeeData = getDirectoryData({
      ...directory,
      companyId,
      organizationId,
      employeeId,
    });
    return {
      companyId: employeeData.companyId,
      organizationId: employeeData.organizationId,
      employeeId: employeeData.employeeId,
      companyName: employeeData.companyName,
      organizationName: employeeData.organizationName,
      employeeName: employeeData.employeeName,
      jobPositionId: employeeData.jobPositionId,
      jobPositionName: employeeData.jobPositionName,
      jobClassType: employeeData.jobClassType,
      jobDutyId: employeeData.jobDutyId,
      jobDutyName: employeeData.jobDutyName,
      avatar: employeeData.avatar,
    };
  };

  /** 결재선 생성 시 조직 정보 생성. */
  const createApprovalLineOrganizationData = (
    companyId: number,
    organizationId: number,
  ) => {
    const organizationData = getOrganizationData({
      companyId,
      organizationId,
    });
    return {
      companyId: organizationData.companyId,
      organizationId: organizationData.organizationId,
      companyName: organizationData.companyName,
      organizationName: organizationData.organizationName,
    };
  };

  /** 결재선 생성 시 조직, 대결일 경우 결재자 정보 생성. */
  const createApprovalLineSurrogateApprover = (data: {
    companyId: number;
    organizationId?: number;
    employeeId: number;
  }) => {
    const { companyId, organizationId, employeeId } = data;
    const surrogaterData = getDirectoryData({
      ...directory,
      companyId,
      organizationId,
      employeeId,
    });
    return {
      approverCompanyId: surrogaterData.companyId,
      approverOrganizationId: surrogaterData.organizationId,
      approverEmployeeId: surrogaterData.employeeId,
      approverCompanyName: surrogaterData.companyName,
      approverOrganizationName: surrogaterData.organizationName,
      approverEmployeeName: surrogaterData.employeeName,
      approverJobPositionId: surrogaterData.jobPositionId,
      approverJobPositionName: surrogaterData.jobPositionName,
      approverJobClassType: surrogaterData.jobClassType,
      approverJobDutyId: surrogaterData.jobDutyId,
      approverJobDutyName: surrogaterData.jobDutyName,
    };
  };

  const getOrganizationData = ({
    companyId,
    organizationId,
  }: {
    companyId: number;
    organizationId: number;
  }) => {
    const { organizations } = directory;
    return {
      companyId,
      companyName: getName(organizations, companyId, companyId, ''),
      organizationId,
      organizationName: getName(organizations, companyId, organizationId, ''),
    };
  };
  const isAdminPage =
    getPathParams<{ p1: string }>('/:p1', props.pathname).p1 === 'adminconsole';
  const pathParams = getPathParams(
    isAdminPage ? '/*/*/*/:id' : '/*/:folderId/:id',
    props.pathname,
  );
  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const designators = useSelector(
    (state: RootState) => state.approval2.surrogateApproval.designators.data,
  );
  const organizationIds = principal.affiliatedOrganizations.map(({ id }) => id);
  const directory = useSelector(directorySelector, directoryEqualityFn);
  const directoryTreeItems = useMemo(
    () => getDirectoryTreeItems({ ...directory }),
    [directory],
  );
  const basic = useSelector(
    (state: RootState) => state.approval2.preferences.basic,
  );

  const companyView = useSelector((s: RootState) => {
    return s.directory.company.view.data;
  });

  const employeeView = useSelector((s: RootState) => {
    return s.directory.employee.view.data;
  });

  // 최상위 조직 이름 사용 여부.
  const useTopLevelOrganization = useSelector(
    (state: RootState) =>
      state.approval2.preferences.documentNo?.useTopLevelOrganization ?? false,
  );

  const view = useSelector((s: RootState) => {
    const id = b62(pathParams.id);
    // 임시 보관 작성이거나 접수 후 내부 결재거나 문서 변경 또는 재기안인 경우.
    if (
      (queryParams.contentType === 'temp' ||
        queryParams.contentType === 'receipt' ||
        queryParams.contentMode === 'update' ||
        queryParams.contentType === 'reDraft') &&
      s.approval2.document.view.data &&
      s.approval2.document.view.data.id === id
    )
      return s.approval2.document.view.data;
    if (isAdminPage && s.approval2.document.adminconsole.view.data)
      return s.approval2.document.adminconsole.view.data;
    return undefined;
  });

  const form = useSelector((s: RootState) => {
    if (view && view.linkId !== '' && view.linkType === 'ATTENDANCE')
      return s.attendance.form.item;
    if (view && view.linkId !== '') return s.systemlink.form.item;
    return s.approval2.form.item;
  });

  /** 접수 후 내부 결재 여부. */
  const isInternalApprovalAfterReceipt = queryParams.contentType === 'receipt';

  /** 임시 저장된 문서 여부. */
  const isTemporarilyStoredDocuments = queryParams.contentType === 'temp';

  /** 재기안 문서 여부 */
  const isReDraftDocuments = queryParams.contentType === 'reDraft';

  /** 반려된 내부문서 재접수 여부 */
  const isReceiptReturnDocuments =
    isReDraftDocuments && view?.parentId !== undefined;

  useEffect(() => {
    let drafter:
      | ApprovalLineGroupItemEmployeeType
      | ApprovalLineGroupItemOrganizationType
      | undefined;
    if (
      view &&
      (queryParams.contentType !== undefined ||
        queryParams.contentMode === 'update')
    ) {
      drafter = getApprovalLineDrafter(view.approvalLine);

      // 내부결재: 기안자 = 부모문서의 현재 결재자
      if (queryParams.contentType === 'receipt')
        drafter = getCurrentApprover({
          approvalLine: view.approvalLine,
          companyId: principal.companyId,
          organizationIds,
          employeeId: principal.employeeId,
          designatorIds: designators.map(({ designatorId }) => designatorId),
          approvalFolder: b62(pathParams.folderId) === 1002,
        });
    }
    const id = drafter?.employeeId ?? principal.employeeId;
    dispatch(companyActions.findView({ id }));
    dispatch(employeeActions.findView({ id }));
  }, [props]);

  const maxFileCount = basic.numberOfAttachments;
  const maxFileCapacity = basic.attachmentsCapacity;

  const contentRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<{
    builder: FormBuilder;
    validation: string;
    contenttype: 'select' | 'compose';
    drawertype?: 'attacheddocument';
    dialogtype:
      | 'draftrequest'
      | 'approvalline'
      | 'changesave'
      | 'attacheddocumentdialog'
      | 'preview'
      | undefined;
    subject: string;

    // 개발
    draftOrganizationId: number;
    draftTopLevelOrganizationId: number;
    draftTopLevelOrganizationName?: string;
    workId: number;
    formId: number;
    workName: string;
    formName: string;
    documentNo: string;
    retentionPeriod: number;
    content: string;
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    attachedFiles: FileUploadProps[];
    uploadPath: string;
    attachedDocuments: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[];
    useAttachFile: number; // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
    useAttachDocument: number; // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
    useOpinion: boolean; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
    useComment: boolean; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

    workDocumentNo?: string; // 업무 문서 번호.
    drawerAttachedCompanyId?: number; // 첨부문서 추가 시 팝업 조회 문서 정보.
    drawerAttachedId?: number; // 첨부문서 추가 시 팝업 조회 문서 정보.
    drawerAttachedSelected?: boolean; // 첨부문서 추가 시 팝업 조회 문서 정보.
    ///
    errors: ApiError[];
  }>(() => {
    const builder = new FormBuilder();

    let contenttype: 'select' | 'compose';
    // 작성 또는 임시보관 작성인 경우.
    if (queryParams.contentMode === 'create') {
      // 임시 보관 작성이거나 접수 후 내부 결재 또는 재기안인 경우.
      if (
        isTemporarilyStoredDocuments ||
        isInternalApprovalAfterReceipt ||
        isReDraftDocuments
      )
        contenttype = 'compose';
      // 작성인 경우.
      else contenttype = 'select';
    }
    // 문서 변경인 경우.
    else contenttype = 'compose';

    const {
      useAttachFile = 0,
      useAttachDocument = 0,
      useOpinion = false,
      useComment = false,
    } = view ? approvalWorkApi.getOption(view.option) : {};

    let documentNo = view?.no ?? '';
    let workDocumentNo: string | undefined;
    let draftOrganizationId = principal.organizationId;
    let approvalLine = view?.approvalLine || { version: '0.1', groups: [] };

    // 작성자 최상위 조직 아이디.
    let draftTopLevelOrganizationId = 0;
    let draftTopLevelOrganizationName: string | undefined;

    let content = view?.content || '';
    let formId = view?.formId || 0;
    let formName = view?.formName || '';

    // 접수 후 내부결재일 경우.
    if (isInternalApprovalAfterReceipt && view && form) {
      const approver = getCurrentApprover({
        approvalLine: view.approvalLine,
        companyId: principal.companyId,
        organizationIds,
        employeeId: principal.employeeId,
        designatorIds: designators.map(({ designatorId }) => designatorId),
        approvalFolder: b62(pathParams.folderId) === 1002,
      });
      if (approver) {
        if (view.isTopLevelOrganizationNameNumbering) {
          const topLevelOrganization = findTopLevelOrganization({
            organizations: directory.organizations,
            companyId: approver.companyId,
            id: approver.organizationId,
          });
          if (topLevelOrganization) {
            draftTopLevelOrganizationId = topLevelOrganization.id;
            draftTopLevelOrganizationName = topLevelOrganization.name;
          }
        }
        if (!view.isSyncIncomingAndOutgoing) {
          const documentNoMacro = view.content.match(
            /(<gw-fb-element[^>]+?data-macro="APPROVAL\/DOCUMENT_NO"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          );
          if (documentNoMacro) {
            const dataDefine =
              documentNoMacro[0].match(/data-define="(.*?)"/gs);
            if (dataDefine) {
              documentNo = dataDefine[0]
                .replace(/data-define="(.*?)"/gs, `$1`)
                .toString();
              documentNo = replaceDocumentNo({
                documentNo,
                workName: view.workName,
                formName: form.name,
                organizationName:
                  draftTopLevelOrganizationName ?? approver.organizationName,
                employeeName:
                  approver.employeeName ?? approver.organizationName,
              });
            }
          }
        }
        draftOrganizationId = approver.organizationId;
        // 조직 결재
        if (approver.employeeId === undefined) {
          approvalLine = {
            version: '0.1',
            groups: [
              {
                id: `g1${Date.now()}`,
                type: 'draft',
                approval: false,
                items: [
                  {
                    id: `i1${Date.now()}`,
                    approval: false,
                    ...createApprovalLineOrganizationData(
                      approver.companyId,
                      approver.organizationId,
                    ),
                    employeeId: undefined,
                    employeeName: undefined,
                    ...createApprovalLineSurrogateApprover({
                      companyId: principal.companyId,
                      organizationId: principal.organizationId,
                      employeeId: principal.employeeId,
                    }),
                  },
                ],
              },
              {
                id: `g2${Date.now()}`,
                type: 'approval',
                required: true,
                modify: true,
                items: [],
              },
            ],
          };
        } else {
          approvalLine = {
            version: '0.1',
            groups: [
              {
                id: `g1${Date.now()}`,
                type: 'draft',
                approval: false,
                items: [
                  {
                    id: `i1${Date.now()}`,
                    approval: false,
                    ...createApprovalLineEmployeeData({
                      companyId: approver.companyId,
                      organizationId: approver.organizationId,
                      employeeId: approver.employeeId,
                    }),
                  },
                ],
              },
              {
                id: `g2${Date.now()}`,
                type: 'approval',
                required: true,
                modify: true,
                items: [],
              },
            ],
          };
          // 대결
          if (approver.employeeId !== principal.employeeId) {
            approvalLine = {
              version: '0.1',
              groups: [
                {
                  id: `g1${Date.now()}`,
                  type: 'draft',
                  approval: false,
                  items: [
                    {
                      id: `i1${Date.now()}`,
                      approval: false,
                      ...createApprovalLineEmployeeData({
                        companyId: approver.companyId,
                        organizationId: approver.organizationId,
                        employeeId: approver.employeeId,
                      }),
                      ...createApprovalLineSurrogateApprover({
                        companyId: principal.companyId,
                        organizationId: principal.organizationId,
                        employeeId: principal.employeeId,
                      }),
                    },
                  ],
                },
                {
                  id: `g2${Date.now()}`,
                  type: 'approval',
                  required: true,
                  modify: true,
                  items: [],
                },
              ],
            };
          }
        }
      }
      content = form.contents;
      formId = form.id;
      formName = form.name;
    }

    // 재기안일 경우.
    if (isReDraftDocuments && view) {
      const drafter = getApprovalLineDrafter(view.approvalLine);
      if (drafter) {
        if (view.isTopLevelOrganizationNameNumbering) {
          const topLevelOrganization = findTopLevelOrganization({
            organizations: directory.organizations,
            companyId: drafter.companyId,
            id: drafter.organizationId,
          });
          if (topLevelOrganization) {
            draftTopLevelOrganizationId = topLevelOrganization.id;
            draftTopLevelOrganizationName = topLevelOrganization.name;
          }
        }

        // 재기안 문서이거나 문서번호 승계 안하는 재접수 문서인 경우
        if (!view.isSyncIncomingAndOutgoing || !view.parentId) {
          const documentNoMacro = view.content.match(
            /(<gw-fb-element[^>]+?data-macro="APPROVAL\/DOCUMENT_NO"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          );
          if (documentNoMacro) {
            const dataDefine =
              documentNoMacro[0].match(/data-define="(.*?)"/gs);
            if (dataDefine) {
              documentNo = dataDefine[0]
                .replace(/data-define="(.*?)"/gs, `$1`)
                .toString();
              documentNo = replaceDocumentNo({
                documentNo,
                workName: view.workName,
                formName: view.formName,
                organizationName:
                  draftTopLevelOrganizationName ?? drafter.organizationName,
                employeeName: drafter.employeeName ?? drafter.organizationName,
              });
            }
          }
        }
        draftOrganizationId = drafter.organizationId;
        approvalLine = {
          ...view.approvalLine,
          groups: view.approvalLine.groups.map((group) => {
            switch (group.type) {
              case 'draft':
                if (isReceiptReturnDocuments && view.parentApprovalLine) {
                  // 대결 구분
                  const designator =
                    principal.employeeId !== drafter.employeeId;
                  if (drafter.employeeId && designator) {
                    return {
                      ...group,
                      items: [
                        {
                          id: group.items[0].id,
                          approval: false,
                          ...createApprovalLineEmployeeData({
                            companyId: drafter.companyId,
                            organizationId: drafter.organizationId,
                            employeeId: drafter.employeeId,
                          }),
                          ...createApprovalLineSurrogateApprover({
                            companyId: principal.companyId,
                            organizationId: principal.organizationId,
                            employeeId: principal.employeeId,
                          }),
                        },
                      ],
                    };
                  }
                  if (drafter.employeeId === undefined) {
                    return {
                      ...group,
                      items: [
                        {
                          id: group.items[0].id,
                          approval: false,
                          ...createApprovalLineOrganizationData(
                            drafter.companyId,
                            drafter.organizationId,
                          ),
                          employeeId: undefined,
                          employeeName: undefined,
                          ...createApprovalLineSurrogateApprover({
                            companyId: principal.companyId,
                            organizationId: principal.organizationId,
                            employeeId: principal.employeeId,
                          }),
                        },
                      ],
                    };
                  }
                }
                return {
                  ...group,
                  items: group.items.map((item) => ({
                    id: item.id,
                    approval: item.approval,
                    ...createApprovalLineEmployeeData({
                      companyId: drafter.companyId,
                      organizationId: drafter.organizationId,
                      employeeId: drafter.employeeId ?? 0,
                    }),
                  })),
                };
              case 'approval':
                return {
                  ...group,
                  items: createApprovalLineApprovalFiltered(group.items).map(
                    (item) => {
                      const { companyId, organizationId } = item;
                      if (item.employeeId === undefined) {
                        return {
                          ...item,
                          ...createApprovalLineOrganizationData(
                            companyId,
                            organizationId,
                          ),
                          ...createApprovalLineUndefined(),
                        };
                      }
                      return {
                        ...item,
                        ...createApprovalLineEmployeeData({
                          companyId,
                          organizationId,
                          employeeId: item.employeeId,
                        }),
                        ...createApprovalLineUndefined(),
                      };
                    },
                  ),
                };
              case 'agree':
                return {
                  ...group,
                  items: createApprovalLineAgreeFiltered(group.items).map(
                    (item) => {
                      const { companyId, organizationId } = item;
                      if (item.employeeId === undefined) {
                        return {
                          ...item,
                          ...createApprovalLineOrganizationData(
                            companyId,
                            organizationId,
                          ),
                          ...createApprovalLineUndefined(),
                        };
                      }
                      return {
                        ...item,
                        ...createApprovalLineEmployeeData({
                          companyId,
                          organizationId,
                          employeeId: item.employeeId,
                        }),
                        ...createApprovalLineUndefined(),
                      };
                    },
                  ),
                };
              default:
                return {
                  ...group,
                  items: createApprovalLineDefaultFiltered(group.items).map(
                    (item) => {
                      const { companyId, organizationId } = item;
                      if (item.employeeId === undefined) {
                        return {
                          ...item,
                          ...createApprovalLineOrganizationData(
                            companyId,
                            organizationId,
                          ),
                          ...createApprovalLineUndefined(),
                        };
                      }
                      return {
                        ...item,
                        ...createApprovalLineEmployeeData({
                          companyId,
                          organizationId,
                          employeeId: item.employeeId,
                        }),
                        ...createApprovalLineUndefined(),
                      };
                    },
                  ),
                };
            }
          }),
        };
      }
      // 서명 이미지 삭제.
      content = content
        .replace(
          /(<gw-fb-element[^>]+?data-macro="APPROVAL\/APPROVALLINE_APPROVAL_GROUP"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          `$1</gw-fb-element>`,
        )
        .replace(
          /(<gw-fb-element[^>]+?data-macro="APPROVAL\/APPROVALLINE_APPROVAL_GROUP\/INCLUDE_DRAFTER"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          `$1</gw-fb-element>`,
        )
        .replace(
          /(<gw-fb-element[^>]+?data-macro="APPROVAL\/APPROVALLINE_AGREE_GROUP"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          `$1</gw-fb-element>`,
        )
        .replace(
          /(<gw-fb-element[^>]+?data-macro="APPROVAL\/APPROVALLINE_RECEIVE_GROUP"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          `$1</gw-fb-element>`,
        )
        .replace(
          /(<gw-fb-element[^>]+?data-macro="APPROVAL\/APPROVALLINE_AUDIT_GROUP"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          `$1</gw-fb-element>`,
        );
    }

    // 임시보관 문서일 경우.
    if (isTemporarilyStoredDocuments && view) {
      const drafter = getApprovalLineDrafter(view.approvalLine);
      if (drafter) {
        draftOrganizationId = drafter.organizationId;
        if (useTopLevelOrganization) {
          const topLevelOrganization = findTopLevelOrganization({
            organizations: directory.organizations,
            companyId: drafter.companyId,
            id: drafter.organizationId,
          });
          if (topLevelOrganization) {
            draftTopLevelOrganizationId = topLevelOrganization.id;
            draftTopLevelOrganizationName = topLevelOrganization.name;
          }
        }

        documentNo = replaceDocumentNo({
          documentNo: view.no,
          workName: view.workName,
          formName: view.formName,
          organizationName:
            draftTopLevelOrganizationName ?? drafter.organizationName,
          employeeName: drafter.employeeName ?? drafter.organizationName,
        });
        approvalLine = {
          ...view.approvalLine,
          groups: view.approvalLine.groups.map((group) => {
            switch (group.type) {
              case 'draft':
                return {
                  ...group,
                  items: group.items.map((item) => ({
                    ...item,
                    ...createApprovalLineEmployeeData({
                      companyId: drafter.companyId,
                      organizationId: drafter.organizationId,
                      employeeId: drafter.employeeId ?? principal.employeeId,
                    }),
                  })),
                };
              case 'approval':
                return {
                  ...group,
                  items: createApprovalLineApprovalFiltered(group.items).map(
                    (item) => {
                      const { companyId, organizationId } = item;
                      if (item.employeeId === undefined) {
                        return {
                          ...item,
                          ...createApprovalLineOrganizationData(
                            companyId,
                            organizationId,
                          ),
                        };
                      }
                      return {
                        ...item,
                        ...createApprovalLineEmployeeData({
                          companyId,
                          organizationId,
                          employeeId: item.employeeId,
                        }),
                      };
                    },
                  ),
                };
              case 'agree':
                return {
                  ...group,
                  items: createApprovalLineAgreeFiltered(group.items).map(
                    (item) => {
                      const { companyId, organizationId } = item;
                      if (item.employeeId === undefined) {
                        return {
                          ...item,
                          ...createApprovalLineOrganizationData(
                            companyId,
                            organizationId,
                          ),
                        };
                      }
                      return {
                        ...item,
                        ...createApprovalLineEmployeeData({
                          companyId,
                          organizationId,
                          employeeId: item.employeeId,
                        }),
                      };
                    },
                  ),
                };
              default:
                return {
                  ...group,
                  items: createApprovalLineDefaultFiltered(group.items).map(
                    (item) => {
                      const { companyId, organizationId } = item;
                      if (item.employeeId === undefined) {
                        return {
                          ...item,
                          ...createApprovalLineOrganizationData(
                            companyId,
                            organizationId,
                          ),
                        };
                      }
                      return {
                        ...item,
                        ...createApprovalLineEmployeeData({
                          companyId,
                          organizationId,
                          employeeId: item.employeeId,
                        }),
                      };
                    },
                  ),
                };
            }
          }),
        };
      }
    }
    return {
      builder,
      validation: '',
      dialogtype: undefined,

      subject: view?.subject || '',

      // 개발
      contenttype,
      // workselect --
      draftOrganizationId,
      draftTopLevelOrganizationId,
      draftTopLevelOrganizationName,
      workId: view?.workId || 0,
      formId,
      workName: view?.workName || '',
      formName,
      documentNo,
      retentionPeriod: 0,
      content,
      approvalLine,
      referencePermission: isInternalApprovalAfterReceipt
        ? undefined
        : view?.referencePermission || undefined,
      viewPermission: isInternalApprovalAfterReceipt
        ? undefined
        : view?.viewPermission || undefined,
      // -- workselect

      attachedFiles: view?.attachedFiles
        ? view?.attachedFiles.map((a) => {
            return {
              ...a,
              id: `${a.id}`,
              progress: 100,
              isUploaded: true,
            };
          })
        : [],
      uploadPath: `${Date.now()}`,
      // 뷰에 첨부 문서가 있는 경우.
      // (접수 후 내부 결재 이거나 임시 저장된 문서 또는 문서 변경인 경우)
      attachedDocuments: view?.attachedDocuments ?? [],
      drawerAttachedCompanyId: undefined,
      drawerAttachedId: undefined,
      drawerAttachedSelected: undefined,

      useAttachFile,
      useAttachDocument,
      useOpinion,
      useComment,

      workDocumentNo,
      ///
      errors: [],
    };
  });
  const [reload, setReload] = useState(true);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록; deprecated
  };

  useEffect(() => {
    if (state.contenttype === 'compose') {
      setReload(true);
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();
      if (reload) {
        (() => {
          window.addEventListener('beforeunload', handlePrevClose);
        })();

        return () => {
          window.removeEventListener('beforeunload', handlePrevClose);
        };
      }
      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    setReload(false);
    return undefined;
  }, [state.contenttype]);

  /** 업무 선택 */
  const handleWorkSelected = (arg: ApprovalComposeWorkSelectConfirmArg) => {
    // console.log(`handleWorkSelected(arg)`, arg);
    const {
      draftOrganizationId,
      workId,
      workName,
      formId,
      formName,
      documentNo,
      retentionPeriod,
      approvalLine,
      referencePermission,
      viewPermission,
      useAttachFile,
      useAttachDocument,
      useOpinion,
      useComment,

      content,
    } = arg;

    const { organizationName, employeeName } = getDirectoryData({
      ...directory,
      ...{
        companyId: principal.companyId,
        organizationId: draftOrganizationId,
        employeeId: principal.employeeId,
      },
    });
    // console.log(`organizationName:`, organizationName);
    // console.log(`employeeName:`, employeeName);

    // 작성자 최상위 조직 아이디.
    let draftTopLevelOrganizationId = 0;
    let draftTopLevelOrganizationName: string | undefined;
    if (useTopLevelOrganization) {
      const topLevelOrganization = findTopLevelOrganization({
        organizations: directory.organizations,
        companyId: principal.companyId,
        id: draftOrganizationId,
      });
      if (topLevelOrganization) {
        draftTopLevelOrganizationId = topLevelOrganization.id;
        draftTopLevelOrganizationName = topLevelOrganization.name;
      }
    }

    setState((prevState) => ({
      ...prevState,
      contenttype: 'compose',
      draftOrganizationId,
      draftTopLevelOrganizationId,
      draftTopLevelOrganizationName,
      workId,
      formId,
      workName,
      formName,
      documentNo: replaceDocumentNo({
        documentNo,
        workName,
        formName,
        organizationName: draftTopLevelOrganizationName ?? organizationName,
        employeeName,
      }),
      retentionPeriod,
      content,
      approvalLine: {
        ...approvalLine,
        groups: approvalLine.groups.map((group) => {
          switch (group.type) {
            case 'draft':
              return {
                ...group,
                items: [
                  {
                    id: `${Date.now()}`,
                    approval: false,
                    ...createApprovalLineEmployeeData({
                      companyId: principal.companyId,
                      organizationId: draftOrganizationId,
                      employeeId: principal.employeeId,
                    }),
                  },
                ],
              };
            case 'approval':
              return {
                ...group,
                items: createApprovalLineApprovalFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
            case 'agree':
              return {
                ...group,
                items: createApprovalLineAgreeFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
            default:
              return {
                ...group,
                items: createApprovalLineDefaultFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
          }
        }),
      },
      referencePermission,
      viewPermission,
      useAttachFile,
      useAttachDocument,
      useOpinion,
      useComment,

      workDocumentNo: documentNo,
    }));
  };

  /** 기안 요청 (상신) 대화상자 열기 */
  const handleDraftRequestDialogOpen = () => {
    const {
      subject,
      approvalLine,
      useAttachFile,
      attachedFiles,
      useAttachDocument,
      attachedDocuments,
    } = state;
    let validation = '';
    if (subject.trim() === '') validation = '제목을 입력하세요.';
    else if (
      !approvalLine.groups.some((g) => g.type === 'draft' && g.items.length > 0)
    )
      validation = '기안자를 설정하세요.';
    // 기안자 결재가 아니고 기안자 외 결재자가 없는 경우.
    else if (
      !approvalLine.groups.some(
        (g) => g.type === 'draft' && g.approval && g.items[0]?.approval,
      ) &&
      !approvalLine.groups.some((g) => g.type !== 'draft' && g.items.length > 0)
    )
      validation = '기안자 외 결재자를 선택하여야 합니다.';
    else if (
      approvalLine.groups.find(
        (group) => group.type === 'draft' && group.items[0].approval === false,
      ) &&
      approvalLine.groups.find(
        (group) =>
          group.type !== 'draft' && group.required && group.items.length === 0,
      ) !== undefined
    )
      validation = '필수 결재선을 설정하세요.';
    else if (useAttachFile === 2 && attachedFiles.length === 0)
      validation = '첨부 파일을 추가하세요.';
    else if (useAttachDocument === 2 && attachedDocuments.length === 0)
      validation = '첨부 문서를 추가하세요.';

    if (validation !== '')
      setState((prevState) => ({ ...prevState, validation }));
    else
      setState((prevState) => ({ ...prevState, dialogtype: 'draftrequest' }));
  };

  /** 결재선 대화상자 열기 */
  const handleApprovalLineDialogOpen = () => {
    setState((prevState) => ({ ...prevState, dialogtype: 'approvalline' }));
  };

  const handleApprovalPreviewDialogOpen = () => {
    setState((prevState) => ({ ...prevState, dialogtype: 'preview' }));
  };

  /** 첨부문서 자세히 보기 대화상자 */
  const handleAttachedDocumentSelectDialogOpen = (arg: {
    companyId: number;
    id: number;
    selected: boolean;
  }) => {
    const { companyId, id, selected } = arg;
    setState((prevState) => ({
      ...prevState,
      dialogtype: 'attacheddocumentdialog',
      drawerAttachedCompanyId: companyId,
      drawerAttachedId: id,
      drawerAttachedSelected: selected,
    }));
  };

  /** 첨부문서 자세히 보기 대화상자에서 문서 선택 또는 해제 */
  const handleApprovahAttachedDocumentClick = (arg: {
    id: number;
    checked: boolean;
  }) => {
    const { id, checked } = arg;
    dispatch(
      attachedDocumentActions.setListItemChecked({ id, checked: !checked }),
    );
    setState((prevState) => ({
      ...prevState,
      dialogtype: undefined,
      drawerAttachedCompanyId: undefined,
      drawerAttachedId: undefined,
      drawerAttachedSelected: undefined,
    }));
  };

  /** 임시보관 저장 */
  const handleTemporarySave = () => {
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const content = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    const {
      workId,
      formId,
      subject,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedDocuments,
    } = state;

    if (subject.trim() === '') {
      const validation = '제목을 입력하세요.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    const attachedFiles: {
      path: string;
      id: number;
      documentId?: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean; // 재기안 시 기존 파일 체크
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      isReDraftDocuments,
      documentId: view?.id,
      uploadPath: state.uploadPath,
    });
    if (isTemporarilyStoredDocuments && view?.attachedFiles) {
      view.attachedFiles.forEach((a) => {
        if (state.attachedFiles.find((b) => b.id === `${a.id}`) === undefined) {
          attachedFiles.push({
            path: '',
            id: a.id,
            seq: 0,
            name: '',
            size: 0,
            delete: true,
          });
        }
      });
    }

    const param = {
      workId,
      formId,
      subject,
      contents: content,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
    };
    const reParam = {
      id: view?.id ?? 0,
      updateAt: view?.updateAt ?? '',
      subject,
      contents: content,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
    };

    const { search } = props;
    const pathname = isTemporarilyStoredDocuments
      ? `${getPathMap('/*/*', props.pathname)}`
      : props.pathname;
    const location = utils.getLocation({
      target: props,
      source: { pathname, search, mode: 'replace', option: 'CLEAR_CONTENTS' },
    });

    setReload(false);
    if (isTemporarilyStoredDocuments)
      dispatch(documentActions.reTemporary({ reParam, location }));
    else dispatch(documentActions.temporary({ param, location }));
  };

  /** 취소 */
  const handleCancel = () => {
    // 수정 데이터가 있는 경우. (문서 변경이거나 임시보관 문서 작성인 경우 )
    if (view) {
      goBack();
      return;
    }
    setState((prevState) => ({ ...prevState, contenttype: 'select' }));
  };

  /** 변경 저장 대화상자 열기 */
  const handleChangeSaveDialogOpen = () => {
    setState((prevState) => ({ ...prevState, dialogtype: 'changesave' }));
  };

  /** 대화상자 닫기 */
  const handleDialogClose = () => {
    if (state.drawerAttachedId !== undefined) {
      setState((prevState) => ({
        ...prevState,
        dialogtype: undefined,
        drawerAttachedCompanyId: undefined,
        drawerAttachedId: undefined,
        drawerAttachedSelected: undefined,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      dialogtype: undefined,
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 제목 변경 */
  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('event.target.value', event.target.value);
    setState((prevState) => ({ ...prevState, subject: event.target.value }));
  };

  /** 파일 업로드 진행상태 업데이트 */
  const handleUploadProgress = (event: ProgressEvent, id: string) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    setState((prev) => ({
      ...prev,
      attachedFiles: prev.attachedFiles.map((x) => {
        if (x.id === id) {
          return { ...x, progress };
        }
        return x;
      }),
    }));
  };

  /** 첨부파일 업로드 */
  const handleAttachedFileUpload = async (
    uploadingFiles: FileUploadProps[],
  ) => {
    try {
      const files = createUploadFiles(uploadingFiles);
      setState((prev) => ({
        ...prev,
        attachedFiles: [...prev.attachedFiles, ...uploadingFiles],
      }));
      const fileAsyncFunc = files.map(async (x) => {
        await fileApi.uploadOne({
          path: state.uploadPath,
          file: x,
          module: 'approval',
          onProgress: handleUploadProgress,
        });
      });

      Promise.all(fileAsyncFunc)
        .then((response) => {
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              attachedFiles: prev.attachedFiles.map((attachedFile) => {
                const uploadingFileIndex = uploadingFiles.findIndex(
                  (x) => x.id === attachedFile.id,
                );
                if (uploadingFileIndex !== -1) {
                  return {
                    ...uploadingFiles[uploadingFileIndex],
                    progress: 100,
                    isUploaded: true,
                  };
                }
                return attachedFile;
              }),
            }));
          }, 100);
        })
        .catch((e) => {
          setState((prev) => ({
            ...prev,
            attachedFiles: prev.attachedFiles.map((attachedFile) => {
              const uploadingFileIndex = uploadingFiles.findIndex(
                (x) => x.id === attachedFile.id,
              );
              if (uploadingFileIndex !== -1) {
                return {
                  ...uploadingFiles[uploadingFileIndex],
                  progress: undefined,
                  isUploaded: false,
                  isFail: true,
                };
              }
              return attachedFile;
            }),
          }));
        });
    } catch (e) {
      dispatch(sessionActions.error(e as ApiError));
    }
  };

  /** 첨부 파일 순서 변경 */
  const handleAttachedFileSortable = (arg: FileUploadProps[]) => {
    setState((prevState) => ({
      ...prevState,
      attachedFiles: [...arg],
    }));
  };

  /** 첨부 파일 삭제 */
  const handleAttachedFileRemove = (id: string) => {
    // console.log(`handleAttachedFileRemove(id)`, id);
    // console.log(`handleAttachedFileRemove(id):state`, state);
    setState((prevState) => ({
      ...prevState,
      attachedFiles: prevState.attachedFiles.filter((x) => x.id !== id),
    }));
  };

  /** 첨부 문서 선택 드로워 열기 */
  const handleAttachedDocumentSelectDrawerOpen = () => {
    const drawertype = 'attacheddocument';
    setState((prevState) => ({ ...prevState, drawertype }));
  };

  /** 첨부 문서 선택 드로워 닫기 */
  const handleAttachedDocumentSelectDrawerClose = () => {
    setState((prevState) => ({ ...prevState, drawertype: undefined }));
  };

  /** 첨부 문서 선택 확인 */
  const handleAttachedDocumentSelectDrawerConfirm = (arg: {
    items: { companyId: number; id: number; no: string; subject: string }[];
  }) => {
    // console.log(`handleAttachedDocumentSelectDrawerConfirm(arg)`, arg);
    const { items } = arg;
    setState((prevState) => {
      const { attachedDocuments } = prevState;
      let validation = '';

      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (
          attachedDocuments.findIndex(
            ({ companyId, id }) =>
              companyId === item.companyId && id === item.id,
          ) > -1
        ) {
          validation = `${item.subject} 는(은) 이미 선택된 문서 입니다.`;
          break;
        }
      }

      if (validation !== '') return { ...prevState, validation };
      return {
        ...prevState,
        attachedDocuments: [...attachedDocuments, ...items],
        drawertype: undefined,
      };
    });
  };

  /** 첨부 문서 순서 변경 */
  const handleAttachedDocumentSortable = (
    arg: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      attachedDocuments: [...arg],
    }));
  };

  /** 첨부 문서 삭제 */
  const handleAttachedDocumentRemove = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      attachedDocuments: prevState.attachedDocuments.filter((a) => a.id !== id),
    }));
  };

  /** 첨부 문서 전체 삭제 */
  const handleAttachedDocumentRemoveAll = () => {
    setState((prevState) => ({ ...prevState, attachedDocuments: [] }));
  };

  /** 결재선 저장 */
  const handleApprovalLineSave = (arg: {
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }) => {
    // console.log(`handleApprovalLineSave(arg)`, arg);
    const { approvalLine, referencePermission, viewPermission } = arg;
    setState((prevState) => ({
      ...prevState,
      approvalLine,
      referencePermission,
      viewPermission,
      dialogtype: undefined,
    }));
  };

  /** 기안 요청 (상신) */
  const handleDraftRequest = async (arg: DraftRequestConfirmArg) => {
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = '파일 전송 중에는 문서를 저장할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => x.isFail) > -1
    ) {
      const validation = '전송 실패한 파일을 삭제 후 다시 시도 해주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const content = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    const {
      draftTopLevelOrganizationId,
      workId,
      formId,
      documentNo,
      subject,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedDocuments,
    } = state;
    const notOrganization = organizationIds.some(
      (a) => a === getApprovalLineDrafter(approvalLine)?.organizationId,
    );
    if (notOrganization === false && view?.parentId === undefined) {
      // 기안 조직이 사용자 정보에 일치하는 조직과 없을 경우 결재 상신 오류처리.
      const validation =
        '기안자 정보와 현재 사용자 정보가 일치하지 않아 결재를 상신할 수 없습니다.';
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }
    const attachedFiles: {
      path: string;
      id: number;
      documentId?: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean; // 재기안 시 기존 파일 체크
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      isReDraftDocuments,
      documentId: view?.id,
      uploadPath: state.uploadPath,
    });
    const workView = await approvalWorkApi.fetchView({
      affiliatedCompanyId: undefined,
      id: workId,
    });
    const formView = await approvalFormApi.fetchView(formId);

    // @property — useDefer 후결 여부
    // @property — useArbitraryDecision 전결 여부

    const param = {
      publicOrNot: arg.publicOrNot,
      useUrgent: arg.useUrgent,
      archiveFolderId: arg.archiveFolderId,
      opinion: arg.opinion,
      draftTopLevelOrganizationId,
      workId,
      workUpdateAt: workView.updateAt,
      formId,
      formUpdateAt: formView.updateAt,
      no: documentNo,
      subject,
      contents: content,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
      temporaryDocumentId:
        isTemporarilyStoredDocuments && view ? view.id : undefined,
      previousDocumentId: isReDraftDocuments && view ? view.id : undefined,
    };

    const { search } = props;
    const pathname = `${getPathMap('/*/*', props.pathname)}`;
    const location = utils.getLocation({
      target: props,
      source: { pathname, search, mode: 'replace', option: 'CLEAR_CONTENTS' },
    });
    setReload(false);
    dispatch(documentActions.create({ param, location }));
  };

  /** 접수 후 내부결재 결재 상신 */
  const handleReceiptRequest = (arg: DraftRequestConfirmArg) => {
    if (isReceiptReturnDocuments) {
      // 재접수일 경우.
      handleReReceiptRequest(arg);
      return;
    }
    if (contentRef.current === null || view === undefined) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const content = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    const {
      draftOrganizationId,
      draftTopLevelOrganizationId,
      documentNo,
      subject,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedDocuments,
    } = state;
    const attachedFiles: {
      path: string;
      id: number;
      documentId?: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean; // 재기안 시 기존 파일 체크
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      isReDraftDocuments: isInternalApprovalAfterReceipt,
      documentId: view?.id,
      uploadPath: state.uploadPath,
    });
    const parentCompanyId = principal.companyId;
    const parentId = view.id;
    const parentUpdateAt = view.updateAt;

    const param = {
      publicOrNot: arg.publicOrNot,
      useUrgent: arg.useUrgent,
      archiveFolderId: arg.archiveFolderId,
      opinion: arg.opinion,
      no: documentNo,
      subject,
      contents: content,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
      parentCompanyId,
      parentId,
      parentUpdateAt,
      creatorRootOrganizationId:
        draftTopLevelOrganizationId !== 0
          ? draftTopLevelOrganizationId
          : draftOrganizationId,
    };

    const { search } = props;
    const pathname = queryParams.contentType
      ? `${getPathMap('/*/*', props.pathname)}`
      : props.pathname;
    const location = utils.getLocation({
      target: props,
      source: { pathname, search, mode: 'replace', option: 'CLEAR_CONTENTS' },
    });

    setReload(false);
    dispatch(documentActions.receipt({ param, location }));
  };

  /** 내부결재 문서 재접수 */
  const handleReReceiptRequest = (arg: DraftRequestConfirmArg) => {
    if (contentRef.current === null || view === undefined) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const content = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    if (view === undefined) return;
    const {
      draftOrganizationId,
      draftTopLevelOrganizationId,
      documentNo,
      subject,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedDocuments,
    } = state;
    const uploadPath = `${Date.now()}`;
    const attachedFiles: {
      path: string;
      id: number;
      documentId?: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
      copy?: boolean; // 재기안 시 기존 파일 체크
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      isReDraftDocuments,
      documentId: view?.id,
      uploadPath: state.uploadPath,
    });
    const param = {
      affiliatedCompanyId: view.affiliatedCompanyId,
      id: view.id,
      updateAt: view.updateAt,
      parentCompanyId: view.parentCompanyId ?? 0,
      parentId: view.parentId ?? 0,
      parentUpdateAt: view.parentUpdateAt ?? '',
      useUrgent: arg.useUrgent,
      publicOrNot: arg.publicOrNot,
      no: documentNo,
      subject,
      contents: content,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
      creatorRootOrganizationId:
        draftTopLevelOrganizationId !== 0
          ? draftTopLevelOrganizationId
          : draftOrganizationId,
      archiveFolderId: arg.archiveFolderId,
      opinion: arg.opinion === '' ? undefined : arg.opinion,
    };

    const { search } = props;
    const pathname = queryParams.contentType
      ? `${getPathMap('/*/*', props.pathname)}`
      : props.pathname;
    const location = utils.getLocation({
      target: props,
      source: { pathname, search, mode: 'replace', option: 'CLEAR_CONTENTS' },
    });

    setReload(false);
    dispatch(documentActions.reReceipt({ param, location }));
  };

  /** 문서 변경 확인 */
  const handleDocumentChangeConfirm = (reason: string) => {
    // console.log(`handleDocumentChangeConfirm(reason)`, reason);

    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const content = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    if (reason === '') {
      const validation = '변경 사유는 필수 입력 사항입니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (!view) {
      const validation = '데이터가 변경되어 저장할 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const { subject, attachedDocuments } = state;

    const attachedFiles: {
      path: string;
      id: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      uploadPath: state.uploadPath,
    });
    if (view?.attachedFiles) {
      view.attachedFiles.forEach((a) => {
        if (state.attachedFiles.find((b) => b.id === `${a.id}`) === undefined) {
          attachedFiles.push({
            path: '',
            id: a.id,
            seq: 0,
            name: '',
            size: 0,
            delete: true,
          });
        }
      });

      if (attachedFiles.length === view.attachedFiles.length) {
        let changed = false;
        for (let i = 0; i < attachedFiles.length; i += 1) {
          const a = attachedFiles[i];
          const b = view.attachedFiles[i];
          if (a.id !== b.id || a.delete === true) {
            changed = true;
            break;
          }
        }
        if (changed === false) attachedFiles.splice(0, attachedFiles.length);
      }
    }

    // console.log(`----------files`, files);

    const data = {
      companyId: principal.companyId,
      id: view.id,
      updateAt: view.updateAt,
      subject,
      contents: content,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      attachedDocuments:
        attachedDocuments.length > 0 ? attachedDocuments : undefined,
      reason,
    };

    const { pathname, search } = props;
    const params = getQueryParams(search);

    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(params),
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });

    setReload(false);
    dispatch(documentActions.change({ data, location }));
  };

  /** 콘텐트 로드. */
  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { contentMode } = queryParams;
      const { content } = state;
      const { element } = arg;

      element.innerHTML = FormBuilder.createComposeHtml({
        html: content,
        modify:
          contentMode === 'update' ||
          isTemporarilyStoredDocuments ||
          isReDraftDocuments ||
          isInternalApprovalAfterReceipt,
        getMacro: contentMode === 'update' ? updateGetMacro : getMacro,
      });
    },
    [
      queryParams.contentMode,
      state.documentNo,
      state.content,
      state.draftOrganizationId,
    ],
  );

  /** 문서변경 시 제목 매크로 적용 */
  const updateGetMacro = (arg: { id: string }) => {
    const { subject } = state;
    const { id } = arg;
    // 문서제목. _DOC_TITLE_
    if (id === 'APPROVAL/DOCUMENT_SUBJECT') {
      return subject;
    }
    return undefined;
  };

  const getMacro = (arg: { id: string }) => {
    const {
      draftTopLevelOrganizationName,
      documentNo,
      subject,
      retentionPeriod,
      approvalLine,
      referencePermission,
      viewPermission,
      workDocumentNo,
      workName,
    } = state;

    const { id } = arg;

    // 회사명. _CMP_NAME_
    if (id === 'DIRECTORY/COMAPNY') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.companyName ?? '';
    }
    // 결재 회사 로고.
    if (id === 'APPROVAL/COMPANY_LOGO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.companyId !== undefined
        ? `/approval-static/image/${drafter?.companyId}/companylogo`
        : undefined;
    }
    // 회사 우편번호.
    if (id === 'DIRECTORY/COMPANY_POSTALCODE') {
      return companyView?.postalCode ?? '';
    }
    // 회사주소.
    if (id === 'DIRECTORY/COMPANY_ADDRESS') {
      return companyView?.address ?? '';
    }
    // 회사전화.
    if (id === 'DIRECTORY/COMPANY_PHONE_NO') {
      return companyView?.phoneNo ?? '';
    }
    // 회사팩스.
    if (id === 'DIRECTORY/COMPANY_FAX_NO') {
      return companyView?.faxNo ?? '';
    }
    // 오늘날짜.
    if (id === 'COMMON/TODAY') {
      return dateTimeFormat(new Date(), 'YYYY-MM-DD');
    }
    /* 최종결재자 명칭. */
    if (id === 'APPROVAL/LAST_APPROVER') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.employeeId ? data.employeeName : '';
    }
    /* 최종결재자 부서명. */
    if (id === 'APPROVAL/LAST_APPROVER_ORGANIZATION') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.organizationName ?? '';
    }
    /* 최종결재자 직위. */
    if (id === 'APPROVAL/LAST_APPROVER_JOBPOSITION') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.employeeName ? data.jobPositionName : '';
    }
    /* 최종결재자 사인 */
    // TODO 해야함 (백엔드에서 구현)
    // if (id === 'APPROVAL/LAST_APPROVER_SIGNATURE') {
    //   return '최종결재자 사인';
    // }
    /* 결재 완료일 */
    // TODO 해야함 (백엔드에서 구현)
    // if (id === 'APPROVAL/DOCUMENT_COMPLETEAT') {
    //   return '2021-12-09';
    // }
    /* 공개여부 */
    // TODO 해야함 (백엔드에서 구현)
    // if (id === 'APPROVAL/DOCUMENT_IS_PUBLIC') {
    //   return '공개';
    // }
    // /* 연동본문 */
    // // TODO 해야함
    // if (id === '(_ERP_BODY_)') {
    //   const value = '연동본문 구문';
    //   return { id, value };
    // }
    /* 조직정보 */
    // TODO 해야함
    // if (id === '(_ORG_DEPT_)') {
    //   const value = '조직정보 구문';
    //   return { id, value };
    // }
    /* 기안자 회사 전화번호 */
    if (id === 'APPROVAL/DRAFTER_COMPANY_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.companyPhoneNo : '';
    }
    /* 기안자 폰번호 */
    if (id === 'APPROVAL/DRAFTER_MOBILE_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.mobilePhoneNo : '';
    }
    /* 기안자 내선번호 */
    if (id === 'APPROVAL/DRAFTER_EXTENSION_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.extensionPhoneNo : '';
    }
    /* 기안자 주소 */
    if (id === 'APPROVAL/DRAFTER_ADDRESS') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.address : '';
    }
    /* 기안자 사번 */
    if (id === 'APPROVAL/DRAFTER_EMPLOYEE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees.find((x) => x.id === drafter?.employeeId)?.no ?? ''
      );
    }
    /* 기안자 EMAIL */
    if (id === 'APPROVAL/DRAFTER_EMAIL') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees.find((x) => x.id === drafter?.employeeId)?.email ??
        ''
      );
    }
    /* 기안자 아이디 */
    if (id === 'APPROVAL/DRAFTER_ID') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees
          .find((x) => x.id === drafter?.employeeId)
          ?.email.split('@')[0] ?? ''
      );
    }
    // 기안자. _NAME_
    if (id === 'APPROVAL/DRAFTER') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId
        ? drafter?.employeeName
        : drafter?.organizationName;
    }
    // 기안자 조직. _DEPT_NAME_
    if (id === 'APPROVAL/DRAFTER_ORGANIZATION') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.organizationName ?? '';
    }
    // 기안자 직위. _POSITION_
    if (id === 'APPROVAL/DRAFTER_JOBPOSITION') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? drafter?.jobPositionName : '';
    }
    // 기안일. _TODAY_
    if (id === 'APPROVAL/DRAFT_DATE') {
      const drafter = getApprovalLineDrafter(approvalLine);
      const actAt = drafter?.actAt ?? new Date();
      return dateTimeFormat(actAt, 'YYYY-MM-DD');
    }
    // 문서제목. _DOC_TITLE_
    if (id === 'APPROVAL/DOCUMENT_SUBJECT') {
      return subject;
    }

    // 결재그룹, 합의그룹, 수신그룹, 감사그룹.
    // _APPROV_LINE_, _AGREE_LINE_, _RECEIVE_LINE_, AUDIT_LINE
    if (
      id === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP/INCLUDE_DRAFTER' ||
      id === 'APPROVAL/APPROVALLINE_AGREE_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_RECEIVE_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_AUDIT_GROUP'
    ) {
      const types: ('draft' | 'approval' | 'agree' | 'audit' | 'receive')[] =
        [];

      if (id.endsWith('/INCLUDE_DRAFTER')) types.push('draft');

      switch (id) {
        case 'APPROVAL/APPROVALLINE_APPROVAL_GROUP':
        case 'APPROVAL/APPROVALLINE_APPROVAL_GROUP/INCLUDE_DRAFTER':
          types.push('approval');
          break;
        case 'APPROVAL/APPROVALLINE_AGREE_GROUP':
          types.push('agree');
          break;
        case 'APPROVAL/APPROVALLINE_RECEIVE_GROUP':
          types.push('receive');
          break;
        case 'APPROVAL/APPROVALLINE_AUDIT_GROUP':
          types.push('audit');
          break;
        default:
          break;
      }

      return approvalLine.groups
        .map((group) => {
          if (types.includes(group.type)) return group.items;
          return [];
        })
        .flat()
        .map((a) => {
          let jobClass = '';
          let approvalOfficer = '';
          if (a.employeeId !== undefined) {
            jobClass = getJobClassName(
              a.jobClassType,
              a.jobPositionName,
              a.jobDutyName,
            );
            approvalOfficer = a.macroName ?? '';
          }
          let act = approvalActText(a.act);
          // 대리 결재인 경우. (직원 결재이고 결재 담당자가 아니고 결재자가 있는 경우)
          if (
            a.employeeId !== undefined &&
            a.macroId === undefined &&
            a.approverEmployeeId !== undefined
          ) {
            if (a.act === 'approval')
              act = approvalActText('surrogateApproval');
            else if (a.act === 'return')
              act = approvalActText('surrogateReturn');
          }

          let approverJobClassName = '';
          if (
            a.approverJobClassType &&
            a.approverJobPositionName &&
            a.approverJobDutyName
          )
            approverJobClassName = getJobClassName(
              a.approverJobClassType,
              a.approverJobPositionName,
              a.approverJobDutyName,
            );
          return {
            company: a.companyName,
            organization:
              a.employeeName === undefined && a.approverEmployeeName
                ? `${a.organizationName}<br>${a.approverEmployeeName} ${approverJobClassName}`
                : a.organizationName,
            employee: a.employeeName ?? '',
            jobClass,
            act,
            actAt:
              a.actAt !== undefined
                ? dateTimeFormat(a.actAt, 'YYYY-MM-DD')
                : '',
            approvalOfficer, // 결재 담당자.
            approver: a.approverEmployeeName ?? '',
          };
        });
    }
    // 결재조직, 합의조직, 수신조직, 감사조직.
    // _AGREE_DEPT_, _RECEIVE_DEPT_
    if (
      id === 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST'
    ) {
      let type = '';
      switch (id) {
        case 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST':
          type = 'approval';
          break;
        case 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST':
          type = 'agree';
          break;
        case 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST':
          type = 'receive';
          break;
        case 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST':
          type = 'audit';
          break;
        default:
          type = '';
          break;
      }

      return Array.from(
        new Set(
          approvalLine.groups
            .map((group) => {
              if (group.type !== type || group.items.length === 0) return [];
              return group.items;
            })
            .flat()
            .map((a) => a.organizationName),
        ),
      ).join(',');
    }
    // 참조.
    // if (id === '(_REFER_)') {
    if (id === 'APPROVAL/REFERENCE_PERMISSION_LIST') {
      if (referencePermission === undefined) return '';
      return referencePermission.groups
        .map(({ items }) => items)
        .flat()
        .map((a) => {
          if (a.employeeId === undefined) return a.organizationName;
          return a.macroName || a.employeeName;
        })
        .join(',');
    }
    // 조회.
    // if (id === '(_CHECK_)') {
    if (id === 'APPROVAL/VIEW_PERMISSION_LIST') {
      if (viewPermission === undefined) return '';
      return viewPermission.groups
        .map(({ items }) => items)
        .flat()
        .map((a) => {
          if (a.employeeId === undefined) return a.organizationName;
          return a.macroName || a.employeeName;
        })
        .join(',');
    }
    // 업무명.
    if (id === 'APPROVAL/WORK_NAME') {
      return workName;
    }
    // 결재 항목
    if (id === 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM') {
      return approvalLine.groups
        .filter(({ type }) => type !== 'draft')
        .map((group) => {
          switch (group.type) {
            case 'approval':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'agree':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'receive':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'audit':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            default:
              return group;
          }
        })
        .map((a) => {
          return a.items;
        })
        .flat()
        .map((a) => {
          let jobClass = '';
          let jobPosition = '';
          let jobDuty = '';

          let approvalOfficer = '';
          if (a.employeeId !== undefined) {
            jobClass = getJobClassName(
              a.jobClassType,
              a.jobPositionName,
              a.jobDutyName,
            );
            approvalOfficer = a.macroName ?? '';
            jobPosition = a.jobPositionName;
            jobDuty = a.jobDutyName;
          }
          let act = approvalActText(a.act);
          // 대리 결재인 경우. (직원 결재이고 결재 담당자가 아니고 결재자가 있는 경우)
          if (
            a.employeeId !== undefined &&
            a.macroId === undefined &&
            a.approverEmployeeId !== undefined
          ) {
            if (a.act === 'approval')
              act = approvalActText('surrogateApproval');
            else if (a.act === 'return')
              act = approvalActText('surrogateReturn');
          }

          let approverJobClassName = '';
          if (
            a.approverJobClassType &&
            a.approverJobPositionName &&
            a.approverJobDutyName
          )
            approverJobClassName = getJobClassName(
              a.approverJobClassType,
              a.approverJobPositionName,
              a.approverJobDutyName,
            );
          return {
            company: a.companyName,
            organization:
              a.employeeName === undefined && a.approverEmployeeName
                ? `${a.organizationName}<br>${a.approverEmployeeName} ${approverJobClassName}`
                : a.organizationName,
            employee: a.employeeName ?? '',
            jobClass,
            act,
            actAt:
              a.actAt !== undefined
                ? dateTimeFormat(a.actAt, 'YYYY-MM-DD')
                : '',
            approvalOfficer, // 결재 담당자.
            approver: a.approverEmployeeName ?? '',
            jobPosition,
            jobDuty,
            type: a.id,
          };
        });
    }
    /* 문서번호. */
    // if (id === '(_DOC_SERIAL_)') {
    if (id === 'APPROVAL/DOCUMENT_NO') {
      return {
        value: documentMacroReplace(documentNo),
        define: workDocumentNo,
      };
    }
    /* 보존기간. */
    // if (id === '(_DOC_TIME_)') {
    if (id === 'APPROVAL/DOCUMENT_RETENTION_PERIOD') {
      // console.log('ApprovalComposeContainer retentionPeriod', retentionPeriod);
      let retention = '영구';
      if (retentionPeriod !== 0) {
        const year = Math.floor(retentionPeriod / 365);
        retention = year > 0 ? `${year} 년 ` : '';
        const month = (retentionPeriod % 365) / 30;
        retention += month > 0 ? `${month} 개월` : '';
      }
      return retention;
    }
    // /* 고정결재선(내부) */
    // if (
    //   id.indexOf('AP_P#') > -1 ||
    //   id.indexOf('AP_S#') > -1 ||
    //   id.indexOf('AP_STA#') > -1 ||
    //   id.indexOf('AP_DATE#') > -1
    // ) {
    //   const approvCnt = parseInt(id.replace(/[^0-9]/g, ''), 10);
    //   let value = '';

    //   temp
    //     .filter((x) => x.type === 'approval')
    //     .forEach((a, index) => {
    //       if (approvCnt - 1 === index) {
    //         switch (true) {
    //           case id.indexOf(`_AP_P#${approvCnt}_`) > -1:
    //             value = a.jobPositionName || '';
    //             break;
    //           case id.indexOf(`AP_S#${approvCnt}_`) > -1:
    //             value = a.employeeName || a.organizationName || '';
    //             break;
    //           case id.indexOf(`AP_STA#${approvCnt}_`) > -1:
    //             value = approvalActText(a.act) || '';
    //             break;
    //           case id.indexOf(`AP_DATE#${approvCnt}_`) > -1:
    //             value =
    //               a.actAt !== undefined
    //                 ? dateFormat(a.actAt, 'YYYY-MM-DD')
    //                 : '';
    //             break;
    //           default:
    //             value = '';
    //             break;
    //         }
    //       }
    //     });
    //   return { id, value };
    // }
    // /* 고정결재선(합의) */
    // if (
    //   id.indexOf('AG_P#') > -1 ||
    //   id.indexOf('AG_S#') > -1 ||
    //   id.indexOf('AG_STA#') > -1 ||
    //   id.indexOf('AG_DATE#') > -1
    // ) {
    //   const approvCnt = parseInt(id.replace(/[^0-9]/g, ''), 10);
    //   let value = '';

    //   temp
    //     .filter((x) => x.type === 'agree')
    //     .forEach((a, index) => {
    //       if (approvCnt - 1 === index) {
    //         switch (true) {
    //           case id.indexOf(`_AG_P#${approvCnt}_`) > -1:
    //             value = a.jobPositionName || '';
    //             break;
    //           case id.indexOf(`AG_S#${approvCnt}_`) > -1:
    //             value = a.employeeName || a.organizationName || '';
    //             break;
    //           case id.indexOf(`AG_STA#${approvCnt}_`) > -1:
    //             value = approvalActText(a.act) || '';
    //             break;
    //           case id.indexOf(`AG_DATE#${approvCnt}_`) > -1:
    //             value =
    //               a.actAt !== undefined
    //                 ? dateFormat(a.actAt, 'YYYY-MM-DD')
    //                 : '';
    //             break;
    //           default:
    //             value = '';
    //             break;
    //         }
    //       }
    //     });
    //   return { id, value };
    // }
    // /* 고정결재선(수신)) */
    // if (
    //   id.indexOf('RE_P') > -1 ||
    //   id.indexOf('RE_S') > -1 ||
    //   id.indexOf('RE_STA') > -1 ||
    //   id.indexOf('RE_DATE') > -1
    // ) {
    //   const approvCnt = parseInt(id.replace(/[^0-9]/g, ''), 10);
    //   let value = '';

    //   temp
    //     .filter((x) => x.type === 'receive')
    //     .forEach((a, index) => {
    //       if (approvCnt - 1 === index) {
    //         switch (true) {
    //           case id.indexOf(`_RE_P#${approvCnt}_`) > -1:
    //             value = a.jobPositionName || '';
    //             break;
    //           case id.indexOf(`RE_S#${approvCnt}_`) > -1:
    //             value = a.employeeName || a.organizationName || '';
    //             break;
    //           case id.indexOf(`RE_STA#${approvCnt}_`) > -1:
    //             value = approvalActText(a.act) || '';
    //             break;
    //           case id.indexOf(`RE_DATE#${approvCnt}_`) > -1:
    //             value =
    //               a.actAt !== undefined
    //                 ? dateFormat(a.actAt, 'YYYY-MM-DD')
    //                 : '';
    //             break;
    //           default:
    //             value = '';
    //             break;
    //         }
    //       }
    //     });
    //   return { id, value };
    // }
    // /* 고정결재선(감사)) */
    // if (
    //   id.indexOf('AU_P') > -1 ||
    //   id.indexOf('AU_S') > -1 ||
    //   id.indexOf('AU_STA') > -1 ||
    //   id.indexOf('AU_DATE') > -1
    // ) {
    //   const approvCnt = parseInt(id.replace(/[^0-9]/g, ''), 10);
    //   let value = '';

    //   temp
    //     .filter((x) => x.type === 'audit')
    //     .forEach((a, index) => {
    //       if (approvCnt - 1 === index) {
    //         switch (true) {
    //           case id.indexOf(`_AU_P#${approvCnt}_`) > -1:
    //             value = a.jobPositionName || '';
    //             break;
    //           case id.indexOf(`AU_S#${approvCnt}_`) > -1:
    //             value = a.employeeName || a.organizationName || '';
    //             break;
    //           case id.indexOf(`AU_STA#${approvCnt}_`) > -1:
    //             value = approvalActText(a.act) || '';
    //             break;
    //           case id.indexOf(`AU_DATE#${approvCnt}_`) > -1:
    //             value =
    //               a.actAt !== undefined
    //                 ? dateFormat(a.actAt, 'YYYY-MM-DD')
    //                 : '';
    //             break;
    //           default:
    //             value = '';
    //             break;
    //         }
    //       }
    //     });
    //   return { id, value };
    // }

    // return { id, value: '' };

    if (id === '(_EDITOR_)') return undefined;

    // return undefined;
    return '';
  };

  /** 콘텐트 렌더링 */
  const renderContent = () => {
    const { contentMode: mode } = queryParams;
    const { contenttype, attachedFiles, attachedDocuments } = state;

    if (contenttype === 'select' && mode === 'create') {
      const { draftOrganizationId } = state;
      return (
        <ApprovalComposeWorkSelectContainer
          draftOrganizationId={draftOrganizationId}
          onConfirm={handleWorkSelected}
        />
      );
    }

    // 작성 또는 수정인 경우.
    if (contenttype === 'compose' && (mode === 'create' || mode === 'update')) {
      const { subject, approvalLine, useAttachFile, useAttachDocument } = state;

      const includeEmptyGroup = mode === 'create';
      //
      if (contentRef.current !== null) {
        FormBuilder.binding({
          element: contentRef.current,
          getMacro: mode === 'update' ? updateGetMacro : getMacro,
        });
      }

      return (
        <PostWrite name="posting" fullSize>
          <PostWrite.Toolbar onCancel={handleCancel}>
            {mode === 'create' && (
              <>
                <Button
                  text="결재상신"
                  variant="contained"
                  onClick={handleDraftRequestDialogOpen}
                />
                <Button text="결재선" onClick={handleApprovalLineDialogOpen} />
                {!isInternalApprovalAfterReceipt &&
                  !isReceiptReturnDocuments && (
                    <Button text="임시저장" onClick={handleTemporarySave} />
                  )}
              </>
            )}
            {mode === 'update' && (
              <Button
                text="저장"
                variant="contained"
                onClick={handleChangeSaveDialogOpen}
              />
            )}
            <Button text="미리보기" onClick={handleApprovalPreviewDialogOpen} />
          </PostWrite.Toolbar>
          <PostWrite.Body>
            <PostWrite.Content>
              <PostWrite.Title>
                <TextField
                  value={subject}
                  onChange={handleSubjectChange}
                  placeholder="제목을 입력하세요"
                />
              </PostWrite.Title>
              <PostWrite.Item>
                <ApprovalLineFlat
                  approvalLine={approvalLine}
                  includeEmptyGroup={includeEmptyGroup}
                  className="item-value"
                />
              </PostWrite.Item>
              <PostWrite.Edit>
                <MemoizeDivElement
                  ref={contentRef}
                  className="editor-html"
                  onLoad={handleContentLoad}
                />
              </PostWrite.Edit>
            </PostWrite.Content>
            {useAttachFile !== 0 || useAttachDocument !== 0 ? (
              <PostWrite.Side>
                {useAttachFile !== 0 ? (
                  <AddProgressAttachments
                    data={attachedFiles}
                    maxCount={maxFileCount}
                    maxCapacity={maxFileCapacity}
                    onRemove={handleAttachedFileRemove}
                    onFileUpload={handleAttachedFileUpload}
                    onSortable={handleAttachedFileSortable}
                  />
                ) : null}
                {useAttachDocument !== 0 ? (
                  <PostWrite.Item title="첨부문서">
                    <AddAttachDocument
                      data={attachedDocuments.map((a) => {
                        return {
                          ...a,
                          no: documentMacroReplace(a.no),
                        };
                      })}
                      onRemove={handleAttachedDocumentRemove}
                      onAllRemove={handleAttachedDocumentRemoveAll}
                      onSortable={handleAttachedDocumentSortable}
                      onOpen={handleAttachedDocumentSelectDrawerOpen}
                    />
                  </PostWrite.Item>
                ) : null}
              </PostWrite.Side>
            ) : null}
          </PostWrite.Body>
        </PostWrite>
      );
    }

    return null;
  };
  /** 대화상자 렌더링 */
  const renderDialog = () => {
    const { dialogtype } = state;
    // console.log(`renderDialog():dialogtype: '${dialogtype}'`);

    let result = null;

    if (dialogtype === 'approvalline') {
      const { approvalLine, referencePermission, viewPermission } = state;
      result = (
        <ApprovalLineDialogContainer
          approvalLine={approvalLine}
          referencePermission={referencePermission}
          viewPermission={viewPermission}
          onSave={handleApprovalLineSave}
          onCancel={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'preview') {
      if (contentRef.current === null) {
        const validation = '작성 문서를 읽어올 수 없습니다.';
        setState((prevState) => ({ ...prevState, validation }));
        return null;
      }

      const content = FormBuilder.createViewHtml({
        element: contentRef.current,
      });

      const { attachedDocuments, attachedFiles } = state;
      result = (
        <ApprovalDocumentPreviewDialog
          content={content}
          attachedFiles={attachedFiles}
          attachedDocuments={attachedDocuments}
          onClose={handleDialogClose}
        />
      );
    }
    if (dialogtype === 'changesave') {
      result = (
        <ApprovalChange
          onSave={handleDocumentChangeConfirm}
          onClose={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'draftrequest') {
      const { useOpinion, draftOrganizationId } = state;
      result = (
        <DraftRequestDialogContainer
          organizationId={draftOrganizationId}
          useOpinion={useOpinion}
          onConfirm={
            isInternalApprovalAfterReceipt || isReceiptReturnDocuments
              ? handleReceiptRequest
              : handleDraftRequest
          }
          onCancel={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'attacheddocumentdialog') {
      return (
        <ApprovalAttachedDocumentSelectDialog
          companyId={state.drawerAttachedCompanyId}
          id={state.drawerAttachedId}
          checked={state.drawerAttachedSelected}
          onClick={handleApprovahAttachedDocumentClick}
          onClose={handleDialogClose}
        />
      );
    }

    return result;
  };

  // const { drawertype } = props.params;
  const { validation, drawertype } = state;

  return (
    <>
      {renderContent()}
      {drawertype && (
        <ApprovalAttachedDocumentSelectDrawerContainer
          onClick={handleAttachedDocumentSelectDialogOpen}
          onConfirm={handleAttachedDocumentSelectDrawerConfirm}
          onCancel={handleAttachedDocumentSelectDrawerClose}
        />
      )}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
      <NavigationGuard
        pathname={props.pathname}
        search={props.search}
        reload={reload}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (reload) {
            return true;
          }
          return false;
        }}
      />
    </>
  );
}

export { documentMacroReplace };

export default ApprovalComposeContainer;
