import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getPathParams,
  getQueryParams,
  resourceUpdate,
} from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import { calendarsActions } from '../stores/calendar/calendars';
import CalendarContentRoute from '../pages/root/calendar/content/CalendarContentRoute';
import CalendarUserPreferencesRoute from '../pages/root/calendar/preferences/CalendarUserPreferencesRoute';
import { userPreferencesActions } from '../stores/calendar/userPreferences';
import CalendarComposeRoute from '../pages/root/calendar/compose/CalendarComposeRoute';

function CalendarLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey: key } = props;
  const { menu } = getPathParams<{ menu?: string }>('/*/:menu', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  useEffect(() => {
    resourceUpdate(['directory', 'jobclass'], [currentLanguage]);
    dispatch(userPreferencesActions.basic());
    dispatch(userPreferencesActions.colorList());
    dispatch(calendarsActions.myCalendarList());
    dispatch(calendarsActions.sharedCalendarList());
    dispatch(calendarsActions.subUserCalendarList());
  }, [dispatch]);

  if (
    queryParams.contentMode === 'create' ||
    queryParams.contentMode === 'update'
  )
    return (
      <CalendarComposeRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );

  if (menu === 'preferences')
    return (
      <CalendarUserPreferencesRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );

  return (
    <CalendarContentRoute
      pathname={pathname}
      search={search}
      hash={hash}
      key={key}
    />
  );
}

export default CalendarLocateRoute;
