import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../../components/button/Button';
import {
  getAvatarPath,
  getQueryParams,
  getText,
} from '../../../../groupware-common/utils';
import Dialog from '../../../../components/dialog/Dialog';
import Tab from '../../../../components/tab/Tab';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import SimpleSearch from '../../../../components/search/SimpleSearch';
import Checkbox from '../../../../components/checkbox/Checkbox';
import MenuItem from '../../../../components/menu/MenuItem';
import UserInfo from '../../../../components/user/UserInfo';
import Menu from '../../../../components/menu/Menu';
import MenuDivider from '../../../../components/menu/MenuDivider';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../../../components/tree/DirectoryTree';
import TextField from '../../../../components/textfield/TextField';
import { approverMacroApi } from '../../../apis/approval/v1/approvarmacro';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import Tooltip from '../../../../components/tooltip/Tooltip';
import ApprovalLineFlat from '../components/ApprovalLineFlat';
import Loading from '../../../../components/loading/Loading';
import FeedBack from '../../../../components/alert/FeedBack';
import Icon from '../../../../components/icon/Icon';
import userPreferencesApi, {
  ApprovalLine,
  ApprovalLineView,
} from '../../../apis/approval/v1/index';
import { approvaluserPreferencesActions } from '../../../stores/approval/userPreferences';
import Confirmation from '../../../../components/alert/Confirmation';

function getJobClassName(
  type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty',
  jobPosition: string,
  jobDuty: string,
) {
  switch (type) {
    case 'none':
      return '';
    case 'jobposition':
      return jobPosition;
    case 'jobduty':
      return jobDuty;
    default: {
      if (jobPosition !== '' && jobDuty !== '')
        return `${jobPosition}/${jobDuty}`;
      return jobPosition || jobDuty || '';
    }
  }
}

function getNameText(
  name: string,
  jobClassType: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty',
  jobPositionName: string,
  jobDutyName: string,
) {
  const jobClassName = getJobClassName(
    jobClassType,
    jobPositionName,
    jobDutyName,
  );
  return jobClassName ? `${name} ${jobClassName}` : name;
}

function createApprovalLineGroupItemDraftApprovalOptions(
  approval: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'draftApproval';
      text: string;
    }
> {
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'draftApproval' as const,
      text: approval ? '1인결재 해제' : '1인결재 지정',
    },
  ];
}

function getApprovalLineGroupItemOptions(
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty',
): Array<
  | {
      code: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
      text: string;
      checked: boolean;
    }
  | {
      code: 'divider';
    }
  | {
      code: 'option';
      text: string;
      checked: boolean;
      disabled: boolean;
    }
> {
  return [
    {
      code: 'jobposition' as const,
      text: '직위로 표시',
      checked: jobClassType === 'jobposition',
    },
    {
      code: 'jobduty' as const,
      text: '직책으로 표시',
      checked: jobClassType === 'jobduty',
    },
    {
      code: 'jobposition+jobduty' as const,
      text: '직위/직책으로 표시',
      checked: jobClassType === 'jobposition+jobduty',
    },
  ];
}

function getApprovalLineGroupItemAgreeOptions(
  option: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'option';
      text: string;
    }
> {
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'option' as const,
      text: option ? '선택 해제' : '선택으로 지정',
    },
  ];
}

function getApprovalLineGroupItemApprovalOptions(
  arbitraryDecision: boolean,
  divider = true,
): Array<
  | {
      code: 'divider';
    }
  | {
      code: 'arbitrarydecision';
      text: string;
    }
> {
  return [
    ...(divider ? [{ code: 'divider' as const }] : []),
    {
      code: 'arbitrarydecision' as const,
      text: arbitraryDecision ? '전결권한 해제' : '전결권한 지정',
    },
  ];
}

function ApprovalLineGroupItem(
  props:
    | {
        groupId: string;
        id: string;
        organizationName: string;
        onDelete?(arg: {
          groupId: string;
          id: string;
          event: React.MouseEvent;
        }): void;
        options?: Array<
          | {
              code: 'divider';
            }
          | {
              // code: 'option';
              code: string;
              text: string;
              checked?: boolean;
              disabled?: boolean;
            }
        >;
        onOptionChange?(arg: {
          groupId: string;
          id: string;
          code: string;
          checked: boolean;
        }): void;
        dragable?: boolean;
      }
    | {
        groupId: string;
        id: string;
        employeeText: string;
        organizationName: string;
        avatar?: string;
        macroName?: string;
        onDelete?(arg: {
          groupId: string;
          id: string;
          event: React.MouseEvent;
        }): void;
        options?: Array<
          | {
              code: 'divider';
            }
          | {
              // code:
              //   | 'jobposition'
              //   | 'jobduty'
              //   | 'jobposition+jobduty'
              //   | 'option'
              //   | 'arbitrarydecision';
              code: string;
              text: string;
              checked?: boolean;
              disabled?: boolean;
            }
        >;
        onOptionChange?(arg: {
          groupId: string;
          id: string;
          code: string;
          checked: boolean;
        }): void;
        dragable?: boolean;
      },
) {
  const { dragable = true } = props;
  const [optionMenuPoint, setOptionMenuPoint] = useState<
    { x: number; y: number; width: number; height: number } | undefined
  >(undefined);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { groupId, id, onDelete } = props;
    if (onDelete) onDelete({ groupId, id, event });
  };

  const handleOptionMenuToggle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (optionMenuPoint === undefined) {
      const rect = event.currentTarget.getBoundingClientRect();
      setOptionMenuPoint({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      });
    } else handleOptionMenuClose();
  };

  const handleOptionMenuClose = () => {
    setOptionMenuPoint(undefined);
  };

  const handleOptionMenuItemClick = (code: string, checked: boolean) => {
    // console.log(`handleOptionMenuItemClick(${code}, ${checked})`);
    const { groupId, id, onOptionChange } = props;
    if (onOptionChange) {
      if (
        code === 'jobposition' ||
        code === 'jobduty' ||
        code === 'jobposition+jobduty'
      ) {
        if (checked !== true)
          onOptionChange({ groupId, id, code, checked: true });
      } else onOptionChange({ groupId, id, code, checked });
    }
    handleOptionMenuClose();
  };

  const {
    employeeText = undefined,
    organizationName = '',
    avatar = undefined,
    macroName = undefined,
    onDelete,
    options,
  } = { ...props };

  /** 사용자 정보 렌더링. */
  const renderUserInfo = () => {
    // 직원인 경우.
    if (employeeText !== undefined) {
      // 매크로 이름이 없는 경우.
      if (macroName === undefined)
        return (
          <UserInfo
            name={employeeText}
            from={organizationName}
            avatar={avatar}
          />
        );
      return (
        <UserInfo
          name={employeeText}
          from={organizationName}
          icon="user-tie"
          etc={`[${macroName}]`}
        />
      );
    }
    // 조직인 경우.
    return <UserInfo name={organizationName} icon="sitemap-fill" />;
  };

  return (
    <div className="approval-line-item">
      {dragable && <div className="drag" />}
      {renderUserInfo()}
      {options && (
        <Button
          className="action setting"
          text="설정"
          iconType
          icon="cog-fill-small"
          onClick={handleOptionMenuToggle}
          color="secondary"
        />
      )}
      {onDelete && (
        <Button
          className="action delete"
          text="삭제"
          iconType
          icon="times-circle-fill-small"
          onClick={handleDeleteClick}
          color="secondary"
        />
      )}
      {options && optionMenuPoint && (
        <Menu point={optionMenuPoint} onClose={handleOptionMenuClose} size="sm">
          {options.map((a, i) => {
            const {
              code,
              text = '',
              checked = undefined,
              disabled = undefined,
            } = {
              ...a,
            };
            const key = `${i}_${code}`;
            if (code === 'divider') return <MenuDivider key={key} />;
            return (
              <MenuItem
                key={key}
                label={text}
                onClick={() =>
                  handleOptionMenuItemClick(code, checked ?? false)
                }
                checked={checked}
                disabled={disabled}
              />
            );
          })}
        </Menu>
      )}
    </div>
  );
}

/** 결재선 그룹 항목 정렬 변경 후 사용하지 않는 값 undefined. */
function sortableDataUndefined(data: ApprovalLineGroupItemType) {
  return {
    ...data,
    chosen: undefined,
    selected: undefined,
  };
}

/** 공유 권한 그룹 항목 조직 유형. */
type SharePermissionGroupItemOrganizationType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: undefined;
  employeeName: undefined;
};

/** 공유 권한 그룹 항목 직원 유형. */
type SharePermissionGroupItemEmployeeType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  macroId?: number;
  macroName?: string;
};

/** 공유 권한 그룹 항목 유형. */
type SharePermissionGroupItemType =
  | SharePermissionGroupItemOrganizationType
  | SharePermissionGroupItemEmployeeType;

/** 결재선 그룹 항목 조직 유형 */
type ApprovalLineGroupItemOrganizationType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: undefined;
  employeeName: undefined;
  act?:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none'; // 행위. (결재, 반려, 보류, 대면 등...)
  actAt?: string; // 행위 날짜.
  approverCompanyId?: number;
  approverCompanyName?: string;
  approverOrganizationId?: number;
  approverOrganizationName?: string;
  approverEmployeeId?: number;
  approverEmployeeName?: string;
  approverJobClassType?: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  approverJobPositionId?: number;
  approverJobPositionName?: string;
  approverJobDutyId?: number;
  approverJobDutyName?: string;
};

/** 결재선 그룹 항목 직원 유형 */
type ApprovalLineGroupItemEmployeeType = {
  id: string; // ReactSortable 를 사용하기 위해 설정
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  act?:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none'; // 행위. (결재, 반려, 보류, 대면 등...)
  actAt?: string; // 행위 날짜.
  approverCompanyId?: number;
  approverCompanyName?: string;
  approverOrganizationId?: number;
  approverOrganizationName?: string;
  approverEmployeeId?: number;
  approverEmployeeName?: string;
  approverJobClassType?: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  approverJobPositionId?: number;
  approverJobPositionName?: string;
  approverJobDutyId?: number;
  approverJobDutyName?: string;
  macroId?: number;
  macroName?: string;
};

/** 결재선 그룹 항목 기본 유형 (참조권 및 조회권 사용) */
type ApprovalLineGroupItemDefaultType =
  | ApprovalLineGroupItemOrganizationType
  | ApprovalLineGroupItemEmployeeType;

/** 결재선 그룹 항목 유형 (전체) */
type ApprovalLineGroupItemType =
  | ApprovalLineGroupItemDefaultType
  | (ApprovalLineGroupItemDefaultType & { option: boolean })
  | (ApprovalLineGroupItemEmployeeType & { arbitraryDecision: boolean });

export type ApprovalLineGroupItemOutputType =
  | (ApprovalLineGroupItemOrganizationType & { option?: boolean })
  | (ApprovalLineGroupItemEmployeeType & {
      option?: boolean;
      arbitraryDecision?: boolean;
    });

/** 결재선 그룹 항목 배열 기안 유형 */
type ApprovalLineGroupItemsDraftType = Array<
  ApprovalLineGroupItemEmployeeType & { approval: boolean }
>;

/** 결재선 그룹 항목 배열 결재 유형 */
export type ApprovalLineGroupItemsApprovalType = Array<
  | ApprovalLineGroupItemOrganizationType
  | (ApprovalLineGroupItemEmployeeType & {
      arbitraryDecision: boolean;
    })
>;

/** 결재선 그룹 항목 배열 합의 유형 */
export type ApprovalLineGroupItemsAgreeType = Array<
  (
    | ApprovalLineGroupItemOrganizationType
    | ApprovalLineGroupItemEmployeeType
  ) & { option: boolean }
>;

/** 결재선 그룹 항목 배열 감사 유형 */
type ApprovalLineGroupItemsAuditType = Array<
  ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
>;

/** 결재선 그룹 항목 배열 수신 유형 */
type ApprovalLineGroupItemsReceiveType = Array<
  ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
>;

/** 결재 유형 */
type ApprovalType = 'draft' | 'agree' | 'receive' | 'approval' | 'audit';

/** 결재선 그룹 유형 */
type ApprovalLineGroupType =
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'draft';
      modify?: undefined; // 수정 여부 없음.
      approval: boolean; // 기안자 결재 여부.
      items: Array<
        (
          | ApprovalLineGroupItemEmployeeType
          | ApprovalLineGroupItemOrganizationType
        ) & { approval: boolean }
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'approval';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<
        | ApprovalLineGroupItemOrganizationType
        | (ApprovalLineGroupItemEmployeeType & {
            arbitraryDecision: boolean;
          })
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'agree';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      parallel: boolean; // 병렬 여부
      items: Array<
        (
          | ApprovalLineGroupItemOrganizationType
          | ApprovalLineGroupItemEmployeeType
        ) & { option: boolean }
      >;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'audit';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    }
  | {
      id: string; // ReactSortable 를 사용하기 위해 설정.
      type: 'receive';
      required: boolean; // 필수 여부
      modify: boolean; // 수정 여부
      parallel: boolean; // 병렬 여부
      items: Array<ApprovalLineGroupItemDefaultType>;
    };

/** 결재 라인 유형 */
export type ApprovalLineType = {
  version: '0.1';
  groups: ApprovalLineGroupType[];
};

/** 공유 권한 그룹 유형 */
type SharePermissionGroupType = {
  id: string;
  required: boolean; // 필수 여부
  modify: boolean; // 수정 여부
  items: Array<SharePermissionGroupItemType>;
};

/** 공유 권한 유형 (조회권 및 참조권) */
export type SharePermissionType = {
  version: '0.1';
  groups: SharePermissionGroupType[];
};

/** 공유 권한 그룹 배열 */
function SharePermissionGroups(props: {
  title: string;
  selectedId: string;
  groups: SharePermissionGroupType[];
  dragable?: boolean;
  onSortChange?(groups: SharePermissionGroupType[]): void;
  onSelect?(arg: { id: string; modify: boolean }): void;
  onRequiredChange?(arg: { id: string }): void;
  onModifyChange?(arg: { id: string }): void;
  onDelete?(arg: { id: string; event: React.MouseEvent }): void;
  onItemAppend?(): void;
  onItemSortChange?(arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }): void;
  onItemOptionChange?(arg: {
    groupId: string;
    id: string;
    code: string;
    checked: boolean;
  }): void;
  onItemDelete?(arg: { groupId: string; id: string }): void;
}) {
  const handleSortChange = (groups: SharePermissionGroupType[]) => {
    const { onSortChange } = props;
    if (onSortChange) onSortChange(groups);
  };

  const handleItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    const { onItemSortChange } = props;
    if (onItemSortChange) onItemSortChange(arg);
  };

  const {
    title,
    selectedId,
    groups,
    dragable = false,
    onRequiredChange,
    onModifyChange,
    onDelete,
    onItemAppend,
    onSelect,
    onItemOptionChange,
    onItemDelete,
  } = props;

  return (
    <ReactSortable
      className="line-root"
      list={groups}
      setList={handleSortChange}
      animation={200}
      handle=".group-drag"
    >
      {groups.map((a) => {
        const { id, required, modify } = a;
        let className = 'line-group';
        if (!modify) className += ' lock';
        if (required) className += ' required';
        return (
          <div
            key={a.id}
            className={className}
            onClick={onSelect ? () => onSelect({ id, modify }) : undefined}
            aria-selected={a.id === selectedId}
          >
            <div className="group-head">
              <div className="title">{title}</div>
              {!modify && (
                <Tooltip title="고정 그룹은 편집할 수 없습니다">
                  <em className="tip">고정</em>
                </Tooltip>
              )}
              {required && modify && (
                <Tooltip title="필수 그룹은 최소 1인 이상 필요">
                  <em className="tip">필수</em>
                </Tooltip>
              )}
              {onRequiredChange && (
                <Checkbox
                  label="필수"
                  checked={a.required}
                  onChange={() => onRequiredChange({ id: a.id })}
                  className="group-option"
                />
              )}
              {onModifyChange && (
                <Checkbox
                  label="수정"
                  checked={a.modify}
                  onChange={() =>
                    onModifyChange({
                      id: a.id,
                    })
                  }
                  className="group-option"
                />
              )}
              {modify && (onDelete || dragable) && a.items.length === 0 && (
                <div className="action">
                  {onDelete && (
                    <Button
                      text="삭제"
                      iconType
                      icon="trash-full"
                      onClick={(event) => onDelete({ id: a.id, event })}
                      color="secondary"
                      className="group-delete"
                    />
                  )}
                  {dragable && <div className="drag group-drag" />}
                </div>
              )}
            </div>
            <div className="group-body">
              <ReactSortable
                className="approval-line-group"
                list={a.items}
                setList={(newState, sortable, store) =>
                  handleItemSortChange({
                    groupId: a.id,
                    items: newState,
                    sortable,
                    store,
                  })
                }
                animation={200}
                handle=".drag"
              >
                {a.items.map((b) => {
                  // 직원인 경우
                  if (b.employeeId !== undefined) {
                    const text = getNameText(
                      b.employeeName,
                      b.jobClassType,
                      b.jobPositionName,
                      b.jobDutyName,
                    );
                    const options = onItemOptionChange
                      ? getApprovalLineGroupItemOptions(b.jobClassType)
                      : undefined;
                    return (
                      <ApprovalLineGroupItem
                        key={b.id}
                        groupId={a.id}
                        id={b.id}
                        employeeText={text}
                        organizationName={b.organizationName}
                        avatar={getAvatarPath(b)}
                        macroName={b.macroName}
                        onDelete={modify ? onItemDelete : undefined}
                        options={options}
                        onOptionChange={modify ? onItemOptionChange : undefined}
                        dragable={modify}
                      />
                    );
                  }
                  // 조직인 경우
                  return (
                    <ApprovalLineGroupItem
                      key={b.id}
                      groupId={a.id}
                      id={b.id}
                      organizationName={b.organizationName}
                      onDelete={modify ? onItemDelete : undefined}
                      dragable={modify}
                    />
                  );
                })}
              </ReactSortable>
              {modify && onItemAppend && (
                <Button
                  className="add-approval-line-item"
                  text="편집"
                  onClick={onItemAppend}
                  block
                />
              )}
            </div>
          </div>
        );
      })}
    </ReactSortable>
  );
}

/** 조회권 및 참조권 플랫 */
function SharePermissionFlat({
  items,
  onDeleteAll,
}: {
  items: { id: string; organizationName: string; employeeName?: string }[];
  onDeleteAll?(): void;
}): JSX.Element {
  return (
    <>
      <span className="eui-approval-line">
        {items.map(({ id, organizationName, employeeName }, i) => (
          <span key={id} className="item">
            {i === 0 ? '' : ', '}
            {employeeName || organizationName}
          </span>
        ))}
      </span>
      {onDeleteAll && (
        <Button
          className="delete"
          text="모두삭제"
          iconType
          icon="trash-full"
          onClick={onDeleteAll}
          color="secondary"
          size="xs"
        />
      )}
    </>
  );
}

function approvalLineGroupClassName(type: string) {
  switch (type) {
    case 'draft':
      return 'drafter';
    case 'agree':
      return 'agree';
    case 'receive':
      return 'receiver';
    case 'approval':
      return 'approval';
    case 'audit':
      return 'auditor';
    default:
      return '';
  }
}

/**
 * 결재선 기안자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 직원 유형 또는 undefined.
 */
function getApprovalLineDrafter(
  approvalLine: ApprovalLineType,
):
  | ApprovalLineGroupItemEmployeeType
  | ApprovalLineGroupItemOrganizationType
  | undefined {
  const group = approvalLine.groups.find((a) => a.type === 'draft');
  if (group?.type === 'draft' && group.items.length > 0) {
    return group.items[0];
  }
  return undefined;
}

/** 현재 행위 여부. */
function isCurrentAct(act?: string, actAt?: string) {
  // 행위가 보류 이거나 대면 이거나 수신 이거나 결재 날짜가 없는 경우.
  return (
    act === 'hold' || act === 'meet' || act === 'receipt' || actAt === undefined
  );
}

/**
 * 결재선 현재 결재 그룹.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 유형 또는 undefined.
 */
function getApprovalGroup(
  approvalLine: ApprovalLineType,
): ApprovalLineGroupType | undefined {
  const result = approvalLine.groups.find((group) => {
    const item = group.items.find(({ act, actAt }) => {
      // console.log(`getApprovalGroup:${id}`, { act, actAt });
      return isCurrentAct(act, actAt);
    });
    // console.log(`getApprovalGroup:item`, item);
    return item !== undefined;
  });
  // console.log(`getApprovalGroup:result`, result);
  return result;
}

/**
 * 결재선 이전 결재자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 출력 유형 또는 undefined.
 */
function getPreviousApprover(
  approvalLine: ApprovalLineType,
):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined {
  let previousGroupItemId = '';
  const data = {
    ...approvalLine,
    groups: approvalLine.groups.map((group) => {
      switch (group.type) {
        case 'agree': {
          return {
            ...group,
            items: group.parallel
              ? [
                  ...group.items.filter((item) => item.act !== undefined),
                  ...group.items.filter((item) => item.act === undefined),
                ]
              : group.items,
          };
        }
        case 'receive': {
          return {
            ...group,
            items: group.parallel
              ? [
                  ...group.items.filter((item) => item.act !== undefined),
                  ...group.items.filter((item) => item.act === undefined),
                ]
              : group.items,
          };
        }
        default:
          return {
            ...group,
          };
      }
    }),
  };
  for (let i = 0; i < data.groups.length; i += 1) {
    const loopGroup = data.groups[i];
    let find = false;
    for (let j = 0; j < loopGroup.items.length; j += 1) {
      const loopItem = loopGroup.items[j];
      if (loopItem.act === undefined) {
        find = true;
        break;
      }
      previousGroupItemId = loopItem.id;
    }
    if (find) break;
  }
  // console.log(`getPreviousApprover:approvalLine`, approvalLine);
  // console.log(`previousGroupItemId:${previousGroupItemId}`);

  const group = data.groups.find(
    (a) => a.id === previousGroupItemId.split('/')[0],
  );
  const item = group?.items.find((a) => a.id === previousGroupItemId);

  // console.log(`previousGroup:`, group);
  // console.log(`previousGroupItem:`, item);

  if (group === undefined || item === undefined) return undefined;

  if (group.type === 'draft') return undefined;

  if (group.type === 'receive') return undefined;

  if (group.type === 'agree' && group.parallel) {
    const result = group.items.filter(
      (a) => a.act !== undefined && a.act !== 'hold' && a.act !== 'meet',
    );
    if (result.length === 0) return undefined;
    return result;
  }
  return item;
}

/**
 * 결재선 이전 결재자 그룹 아이템 아이디.
 * @param approvalLine 결재선 유형.
 * @param companyId 결재자 회사 아이디.
 * @param employeeId 결재자 직원 아이디.
 * @returns 결재선 이전 결재자 그룹 아이템 아이디 또는 undefined.
 */
function getPreviousApproverGroupItemId(
  approvalLine: ApprovalLineType,
  companyId: number,
  employeeId: number,
): string | undefined {
  const previousApprover = getPreviousApprover(approvalLine);
  if (Array.isArray(previousApprover))
    return previousApprover.find(
      (a) => a.companyId === companyId && a.employeeId === employeeId,
    )?.id;
  if (
    previousApprover?.companyId === companyId &&
    previousApprover?.employeeId === employeeId
  )
    return previousApprover.id;
  return undefined;
}

/**
 * 결재선 이전 결재자 여부.
 * @param approvalLine 결재선 유형.
 * @param companyId 결재자 회사 아이디.
 * @param employeeId 결재자 직원 아이디.
 * @returns 이전 결재자인 경우 true 이전 결재자가 아닌 경우 false.
 */
function isPreviousApprover(
  approvalLine: ApprovalLineType,
  companyId: number,
  employeeId: number,
): boolean {
  const previousApprover = getPreviousApprover(approvalLine);

  if (Array.isArray(previousApprover)) return false;
  return (
    previousApprover?.companyId === companyId &&
    previousApprover?.employeeId === employeeId
  );
}

/**
 * 결재선 현재 결재자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 출력 유형 또는 undefined.
 */
function getCurrentApprover(arg: {
  approvalLine: ApprovalLineType;
  companyId?: undefined;
}):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined;
/**
 * 매개 변수에 해당되는 결재선 현재 결재자.
 * @param approvalLine 결재선 유형.
 * @param companyId 결재자 회사 아이디.
 * @param organizationIds 결재자 조직 아이디 배열.
 * @param employeeId 결재자 직원 아이디.
 * @param designatorIds 대리 결재 지정자 아이디 배열.
 * @param approvalFolder 결재 폴더.
 */
function getCurrentApprover(arg: {
  approvalLine: ApprovalLineType;
  companyId: number;
  organizationIds: number[];
  employeeId: number;
  designatorIds?: number[]; // 대리 결재 지정자.
  approvalFolder?: boolean; // 결재 폴더.
}): ApprovalLineGroupItemOutputType | undefined;
function getCurrentApprover(arg: {
  approvalLine: ApprovalLineType;
  companyId?: number;
  organizationIds?: number[];
  employeeId?: number;
  designatorIds?: number[]; // 대리 결재 지정자.
  approvalFolder?: boolean; // 결재 폴더.
}):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined {
  const {
    approvalLine,
    companyId,
    organizationIds = [],
    employeeId,
    designatorIds = [],
    approvalFolder,
  } = arg;

  const group = getApprovalGroup(approvalLine);
  if (group === undefined) return undefined;

  let items: ApprovalLineGroupItemType[];

  // 그룹 유형이 합의 또는 수신이고 병렬인 경우.
  if ((group.type === 'agree' || group.type === 'receive') && group.parallel) {
    items = group.items.filter(({ act, actAt }) => {
      // console.log(`getCurrentApprover:${id}`, { act, actAt });
      // 행위가 보류 이거나 대면 이거나 수신 이거나 결재 날짜가 없는 경우.
      return isCurrentAct(act, actAt);
    });
    // 결재 폴더에서 접수 중인 문서는 현재 결재자에서 제외.
    if (approvalFolder) items = items.filter((a) => a.act !== 'receipt');
    // 조직이 직원보다 앞에 있을 경우 직원 먼저 결재 순서가 되도록 아이템 순서 변경.
    const employeeItems = items.filter((a) => a.employeeId !== undefined);
    const organizationItems = items.filter((a) => a.employeeId === undefined);
    items = [...employeeItems, ...organizationItems];
  } else {
    const item = group.items.find(({ act, actAt }) => {
      // console.log(`getCurrentApprover:${id}`, { act, actAt });
      // 행위가 보류 이거나 대면 이거나 수신 이거나 결재 날짜가 없는 경우.
      return isCurrentAct(act, actAt);
    });
    if (item === undefined) return undefined;
    items = [item];
  }

  // 현재 결재자 비교 매개 변수가 포함되지 않은 경우 현재 결재자 반환.
  if (companyId === undefined) {
    if (items.length === 1) return items[0];
    return items;
  }

  // 현재 결재자 매개 변수 값과 비교.
  for (let i = 0; i < items.length; i += 1) {
    const it = items[i];
    // 현재 결재자가 매개 변수 결재자와 같은 경우.
    if (it.companyId === companyId && it.employeeId === employeeId) return it;
    // 현재 결재가 조직이고 매개 변수 결재 조직에 포함 된 경우.
    if (
      it.employeeId === undefined &&
      it.companyId === companyId &&
      organizationIds.indexOf(it.organizationId) !== -1
    )
      return it;
  }

  // 현재 결재자 매개 변수 값과 비교.
  for (let i = 0; i < items.length; i += 1) {
    const it = items[i];
    // 현재 결재자가 매개 변수 결재 지정자에 포함 된 경우.
    if (
      it.employeeId !== undefined &&
      it.companyId === companyId &&
      designatorIds.indexOf(it.employeeId) !== -1
    )
      return it;
  }
  return undefined;
}

/**
 * 결재선 마지막 결재자 결재자.
 * @param approvalLine 결재선 유형.
 * @returns 결재선 그룹 항목 출력 유형, 배열 또는 undefined.
 */
function getLastApprover(
  approvalLine: ApprovalLineType,
):
  | ApprovalLineGroupItemOutputType
  | ApprovalLineGroupItemOutputType[]
  | undefined {
  const group = [...approvalLine.groups]
    .reverse()
    .find((a) => a.items.length > 0);
  if (group === undefined) return undefined;

  // 그룹 유형이 합의 또는 수신이고 병렬인 경우.
  if ((group.type === 'agree' || group.type === 'receive') && group.parallel) {
    return group.items;
  }
  return group.items[group.items.length - 1];
}

/**
 * 결재자 배열을 생성합니다.
 * @param element 결재자 배열을 생성할 때 사용할 요소.
 * @returns 결재자 배열 객체.
 */
function approversOf(
  element:
    | ApprovalLineGroupItemOutputType
    | ApprovalLineGroupItemOutputType[]
    | undefined,
): ApprovalLineGroupItemOutputType[] {
  if (element === undefined) return [];
  if (Array.isArray(element)) return element;
  return [element];
}

/**
 * 전결 결재 진행 중 여부.
 * (전결로 결재되었는데 수신 조직이 있는 경우 수신자 전까지 결재 상태 변경되고 수신자는 대기 상태로 설정됨)
 * @param approvalLine 결재선.
 * @returns 전결 결재 진행 중인 경우 true, 아닌 경우 false.
 */
function isArbitraryDecisionApproving(approvalLine: ApprovalLineType): boolean {
  return approvalLine.groups.some(
    (group) =>
      group.type === 'approval' &&
      group.items.some((item) => item.act === 'arbitraryDecision'),
  );
}

/**
 * 전결 결재 진행 중이 아닌 여부.
 * (전결로 결재되었는데 수신 조직이 있는 경우 수신자 전까지 결재 상태 변경되고 수신자는 대기 상태로 설정됨)
 * @param approvalLine
 * @returns 전결 결재 진행 중이 아닌 경우 true, 아닌 경우 false.
 */
function nonArbitraryDecisionApproving(
  approvalLine: ApprovalLineType,
): boolean {
  return isArbitraryDecisionApproving(approvalLine) === false;
}

/**
 * 현재 결재자들 중 접수 중인 결재자 존재 여부.
 * @param approvalLine 결재선.
 * @returns 현재 결재자들 중 접수 중인 결재자가 있는 경우 true, 아닌 경우 false.
 */
function isCurrentApproversReceipting(approvalLine: ApprovalLineType): boolean {
  const approvers = approversOf(getCurrentApprover({ approvalLine }));
  return approvers.some((a) => a.act === 'receipt');
}

/** 결재선 지정 프롭스. (문서 작성 및 임시 보관 문서 수정) */
type ApprovalLineDesignationProps = {
  type?: undefined;
  approvalLine: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  onCancel(): void;
  onSave(arg: {
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }): void;
};

/** 결재선 변경 프롭스. */
type ApprovalLineChangeProps = {
  type: 'change';
  affiliatedCompanyId?: number;
  companyId: number;
  id: number;
  approvalLine?: ApprovalLineType;
  referencePermission?: SharePermissionType;
  viewPermission?: SharePermissionType;
  updateAt: string;
  onCancel(): void;
  onSave(arg: {
    affiliatedCompanyId?: number;
    companyId: number;
    id: number;
    approvalLine?: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    reason: string;
    updateAt: string;
  }): void;
};

// src/pages/popup/approval/ApprovalLine.tsx 내용 수정.
/**
 * 결재선 대화 상자 컨테이너
 * @param props ApprovalLineDesignationProps 결재선. 지정 (문서 작성 및 임시 보관 문서 수정)
 * @returns JSX.Element
 */
function ApprovalLineDialogContainer(
  props: ApprovalLineDesignationProps | ApprovalLineChangeProps,
): JSX.Element {
  // console.log(`${ApprovalLineDialogContainer.name}.render:props`, props);

  const dispatch = useAppDispatch();

  const mobileChooseListRef = React.useRef<HTMLDivElement>(null);

  const display = useSelector((state: RootState) => state.session.display);

  const directory = useDirectory();

  const arbitraryDecisions = useSelector(
    (state: RootState) => state.approval2.arbitraryDecisions.arbitraryDecisions,
  );

  const view = useSelector(
    (state: RootState) => state.approval2.document.view.data,
  );

  const work = useSelector(
    (state: RootState) => state.approval2.work.view.data,
  );

  const approvalTypes = useSelector(
    (state: RootState) => state.approval2.preferences.approvalType,
  );

  const directoryTreeItems = useMemo(
    () => getDirectoryTreeItems({ ...directory }),
    [directory],
  );
  const queryParams = getQueryParams(
    useSelector((s: RootState) => s.session.route.search),
  );

  /** 접수 후 내부 결재(재접수) 여부. */
  const isInternalApprovalAfterReceipt =
    queryParams.contentType === 'receipt' ||
    (queryParams.contentType === 'reDraft' && view?.parentId !== undefined);

  /** 결재 진행 중인 그룹 인덱스. (결재선 변경인 경우) */
  const proceedIndex =
    props.type === 'change' && props.approvalLine !== undefined
      ? props.approvalLine.groups.findIndex(
          (group) =>
            group.type !== 'draft' &&
            group.items.length !==
              group.items.filter((item) => item.act !== undefined).length,
        )
      : 0;

  const [state, setState] = useState<{
    validation: string;
    /** 선택 사용자 목록 표시 여부 (모바일 화면에서 사용) */
    chooseUserDialogVisible: boolean;
    /** 개인 결재선 이름 */
    personalApprovalLineName: string;
    chooseTabSelectedId:
      | 'organizationchart'
      | 'approvalofficer'
      | 'personalApprovalLine';
    directoryTreeFilter: string;
    directoryTreeSelectedId: string;
    //
    approvalGroupSelectedId: string;
    approvalLineGroupSelectedId: string;
    referencePermissionGroupSelectedId: string;
    viewPermissionGroupSelectedId: string;
    //
    approvalLine: ApprovalLineType;
    referencePermission: {
      version: '0.1';
      groups: {
        id: string;
        required: boolean; // 필수 여부
        modify: boolean; // 수정 여부
        items: Array<ApprovalLineGroupItemDefaultType>;
      }[];
    };
    viewPermission: SharePermissionType;
    changeReason: string;
    userApprovalLineSelectVisible: boolean;
    userApprovalLineSelectedId: number;
  }>(() => {
    let approvalGroupSelectedId = '';
    let approvalLineGroupSelectedId = '';
    let referencePermissionGroupSelectedId = '';
    let viewPermissionGroupSelectedId = '';
    let directoryTreeSelectedId = '';

    // 문서 작성 및 임시 보관 문서 수정인 경우 초기 선택 그룹 설정.
    if (props.type === undefined) {
      // 결재선 그룹 아이디 찾기.
      approvalLineGroupSelectedId =
        props.approvalLine.groups.find(
          (group) => group.type !== 'draft' && group.modify,
        )?.id ?? '';
      approvalGroupSelectedId = approvalLineGroupSelectedId;

      // 결재 그룹 선택 아이디가 없는 경우 참조권 그룹 아이디 찾기.
      if (approvalGroupSelectedId === '' && props.referencePermission) {
        referencePermissionGroupSelectedId =
          props.referencePermission.groups.find((a) => a.modify)?.id ?? '';
        approvalGroupSelectedId = referencePermissionGroupSelectedId;
      }
      // 결재 그룹 선택 아이디가 없는 경우 조회권 그룹 아이디 찾기.
      if (approvalGroupSelectedId === '' && props.viewPermission) {
        viewPermissionGroupSelectedId =
          props.viewPermission.groups.find((a) => a.modify)?.id ?? '';
        approvalGroupSelectedId = viewPermissionGroupSelectedId;
      }
      // 기안자 조직 아이디 찾기.
      const drafter = getApprovalLineDrafter(props.approvalLine);
      if (drafter)
        directoryTreeSelectedId = `${drafter.companyId}_${drafter.organizationId}`;
    }
    // 결재선 또는 참조권 및 조회권 변경인 경우 초기 선택 그룹 설정.
    else {
      // 결재선 변경인 경우.
      if (props.approvalLine) {
        const group = props.approvalLine.groups.find(
          (a) =>
            a.type !== 'draft' &&
            a.items.find((item) => item.act === undefined),
        );
        if (group?.modify) approvalLineGroupSelectedId = group.id;
        approvalGroupSelectedId = approvalLineGroupSelectedId;
      }
      // 참조권 변경인 경우.
      if (props.referencePermission) {
        referencePermissionGroupSelectedId =
          props.referencePermission.groups.find((a) => a.modify)?.id ?? '';
        approvalGroupSelectedId = referencePermissionGroupSelectedId;
      }
      // 조회권 변경인 경우.
      if (props.viewPermission) {
        viewPermissionGroupSelectedId =
          props.viewPermission.groups.find((a) => a.modify)?.id ?? '';
        approvalGroupSelectedId = viewPermissionGroupSelectedId;
      }
    }

    return {
      validation: '',
      chooseUserDialogVisible: false,
      personalApprovalLineName: '',
      chooseTabSelectedId: 'organizationchart',
      directoryTreeFilter: '',
      directoryTreeSelectedId:
        directoryTreeSelectedId !== ''
          ? directoryTreeSelectedId
          : directoryTreeItems[0]?.id || '',
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
      approvalLine: props.approvalLine
        ? {
            ...props.approvalLine,
            groups: props.approvalLine.groups.map((group) => {
              if (group.type === 'approval' && isInternalApprovalAfterReceipt)
                return {
                  ...group,
                  required: false,
                };
              return group;
            }),
          }
        : { version: '0.1', groups: [] },
      referencePermission: props.referencePermission ?? {
        version: '0.1',
        groups: [],
      },
      viewPermission: props.viewPermission ?? { version: '0.1', groups: [] },
      changeReason: '',
      userApprovalLineSelectVisible: false,
      userApprovalLineSelectedId: 0,
    };
  });

  const chooseTabs = useMemo(() => {
    const result: {
      id: 'organizationchart' | 'approvalofficer' | 'personalApprovalLine';
      text: string;
    }[] = [
      { id: 'organizationchart', text: '조직도' },
      { id: 'approvalofficer', text: '결재담당자' },
    ];
    // 결재선 변경이 아닐 때만 개인결재선 탭 추가.
    if (props.type !== 'change') {
      result.push({ id: 'personalApprovalLine', text: '개인결재선' });
    }

    return result;
  }, []);

  const [approvalOfficers, setApprovalOfficers] = useState<
    | {
        companyId: number;
        id: number;
        name: string;
        organizationId: number;
        employeeId: number;
        updateAt: string;
      }[]
    | undefined
  >(undefined);

  const [personalApprovalLine, setPersonalApprovalLine] = useState<
    ApprovalLine[] | undefined
  >(undefined);

  useEffect(() => {
    approverMacroApi.list(1, 100).then((response) => {
      if (response) setApprovalOfficers(response);
    });

    if (queryParams.contentType !== 'receipt') {
      userPreferencesApi
        .PreferencesApprovalLineList(work?.id)
        .then((response) => {
          const approvalLine = [...response].sort(
            (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
          );
          if (response) setPersonalApprovalLine(approvalLine);
        });
    } else {
      setPersonalApprovalLine([]);
    }
  }, []);

  useEffect(() => {
    if (display === 'phone' && chooseUserDialogVisible) {
      mobileChooseListRef.current?.scrollTo(
        mobileChooseListRef.current?.scrollWidth,
        0,
      );
    }
  }, [state.approvalLine]);

  /** 선택 탭 항목 클릭 */
  const handleChooseTabItemClick = ({
    id: chooseTabSelectedId,
  }: {
    id: 'organizationchart' | 'approvalofficer' | 'personalApprovalLine';
  }) => {
    setState((prevState) => ({ ...prevState, chooseTabSelectedId }));
  };

  /** 디렉터리 트리 필터 */
  const handleDirectoryTreeFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const directoryTreeFilter = event.target.value;
    setState((prevState) => ({ ...prevState, directoryTreeFilter }));
  };

  /** 디렉터리 트리 아이템 클릭 */
  const handleDirectoryTreeItemClick = (arg: DirectoryTreeItemArg) => {
    // console.log('handleDirectoryTreeItemClick(arg)', arg);

    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    const approvalLineGroupSelected =
      approvalGroupSelectedId === approvalLineGroupSelectedId;

    const { id: directoryTreeSelectedId, extra } = arg.item;
    const item =
      extra.type === 'employee'
        ? {
            id: approvalLineGroupSelected
              ? `${Date.now()}`
              : `${extra.companyId}_${extra.employeeId}`,
            companyId: extra.companyId,
            companyName: extra.companyName,
            organizationId: extra.organizationId,
            employeeId: extra.employeeId,
            employeeName: extra.employeeName,
            organizationName: extra.organizationName,
            jobClassType: extra.jobClassType,
            jobPositionId: extra.jobPositionId,
            jobPositionName: extra.jobPositionName,
            jobDutyId: extra.jobDutyId,
            jobDutyName: extra.jobDutyName,
            avatar: extra.avatar,
          }
        : {
            id: approvalLineGroupSelected
              ? `${Date.now()}`
              : `${extra.companyId}_${extra.organizationId}`,
            companyId: extra.companyId,
            companyName: extra.companyName,
            organizationId: extra.organizationId,
            organizationName: extra.organizationName,
          };

    // 결재선 그룹이 선택된 경우
    if (approvalLineGroupSelected) {
      const approvalLineGroupType = state.approvalLine.groups.find(
        (a) => a.id === approvalLineGroupSelectedId,
      )?.type;

      const arbitraryDecision =
        approvalLineGroupType === 'approval' && item.employeeId !== undefined
          ? arbitraryDecisions.find(
              (a) =>
                a.companyId === item.companyId &&
                a.organizationId === item.organizationId &&
                a.employeeId === item.employeeId,
            ) !== undefined
          : false;

      // console.log(`arbitraryDecisions:`, arbitraryDecisions);
      // console.log(`arbitraryDecision:`, arbitraryDecision);

      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          directoryTreeSelectedId,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === approvalLineGroupSelectedId) {
                switch (group.type) {
                  case 'draft':
                    return group;
                  case 'agree': {
                    return {
                      ...group,
                      items: [...group.items, { ...item, option: false }],
                    };
                  }
                  case 'approval': {
                    if (item.employeeId === undefined) {
                      return { ...group, items: [...group.items, item] };
                    }
                    return {
                      ...group,
                      items: [...group.items, { ...item, arbitraryDecision }],
                    };
                  }
                  default:
                    return { ...group, items: [...group.items, item] };
                }
              }
              return group;
            }),
          },
        };
      });
      //
    }
    // 참조권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      setState((prevState) => {
        const { referencePermission } = prevState;
        if (
          referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            item.employeeId !== undefined
              ? '참조권에 존재하는 직원입니다.'
              : '참조권에 존재하는 조직입니다.';
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          directoryTreeSelectedId,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((a) => {
              if (a.id === referencePermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 조회권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      setState((prevState) => {
        const { viewPermission } = prevState;
        if (
          viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation =
            item.employeeId !== undefined
              ? '조회권에 존재하는 직원입니다.'
              : '조회권에 존재하는 조직입니다.';
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          directoryTreeSelectedId,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((a) => {
              if (a.id === viewPermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 선택된 그룹이 없는 경우
    else setState((prevState) => ({ ...prevState, directoryTreeSelectedId }));
  };

  /** 결재 담당자 항목 클릭 */
  const handleApprovalOfficerItemClick = (arg: {
    item: {
      companyId: number;
      id: number;
      name: string;
      organizationId: number;
      employeeId: number;
      updateAt: string;
    };
    event: React.MouseEvent;
  }) => {
    // console.log('handleApprovalOfficerItemClick(arg)', arg);

    const { companyId, id: macroId, name: macroName, employeeId } = arg.item;

    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    const approvalLineGroupSelected =
      approvalGroupSelectedId === approvalLineGroupSelectedId;

    const extra = directoryTreeItems.find(
      (a) =>
        a.extra.type === 'employee' &&
        a.extra.companyId === companyId &&
        a.extra.employeeId === employeeId,
    )?.extra;
    if (extra === undefined || extra.type !== 'employee') {
      const validation = '지정된 담당자를 찾을 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const item = {
      id: approvalLineGroupSelected
        ? `${Date.now()}`
        : `${companyId}_${macroId}`,
      companyId: extra.companyId,
      companyName: extra.companyName,
      organizationId: extra.organizationId,
      employeeId: extra.employeeId,
      employeeName: extra.employeeName,
      organizationName: extra.organizationName,
      jobClassType: extra.jobClassType,
      jobPositionId: extra.jobPositionId,
      jobPositionName: extra.jobPositionName,
      jobDutyId: extra.jobDutyId,
      jobDutyName: extra.jobDutyName,
      avatar: extra.avatar,
      macroId,
      macroName,
    };

    // 결재선 그룹이 선택된 경우
    if (approvalLineGroupSelected) {
      const approvalLineGroupType = state.approvalLine.groups.find(
        (a) => a.id === approvalLineGroupSelectedId,
      )?.type;

      const arbitraryDecision =
        approvalLineGroupType === 'approval' && item.employeeId !== undefined
          ? arbitraryDecisions.find(
              (a) =>
                a.companyId === item.companyId &&
                a.organizationId === item.organizationId &&
                a.employeeId === item.employeeId,
            ) !== undefined
          : false;

      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.id === approvalLineGroupSelectedId) {
                switch (group.type) {
                  case 'draft':
                    return group;
                  case 'agree': {
                    return {
                      ...group,
                      items: [...group.items, { ...item, option: false }],
                    };
                  }
                  case 'approval': {
                    if (item.employeeId === undefined) {
                      // return { ...group, items: [...group.items, item] };
                      return group;
                    }
                    return {
                      ...group,
                      items: [...group.items, { ...item, arbitraryDecision }],
                    };
                  }
                  default:
                    return { ...group, items: [...group.items, item] };
                }
              }
              return group;
            }),
          },
        };
      });
    }
    // 참조권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      setState((prevState) => {
        const { referencePermission } = prevState;
        if (
          referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation = '참조권에 존재하는 담당자입니다.';
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((a) => {
              if (a.id === referencePermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
    // 조회권 그룹이 선택된 경우
    else if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      setState((prevState) => {
        const { viewPermission } = prevState;
        if (
          viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .find(({ id }) => id === item.id)
        ) {
          const validation = '조회권에 존재하는 담당자입니다.';
          return { ...prevState, validation };
        }
        return {
          ...prevState,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((a) => {
              if (a.id === viewPermissionGroupSelectedId)
                return { ...a, items: [...a.items, item] };
              return a;
            }),
          },
        };
      });
    }
  };
  /** 개인결재선 항목 바인딩 */
  const handlePersonalApprovalLineClick = () => {
    const { userApprovalLineSelectedId } = state;
    if (userApprovalLineSelectedId === 0) return;
    dispatch(
      approvaluserPreferencesActions.findPreferencesApprovalView({
        id: userApprovalLineSelectedId,
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const payload = result.payload as ApprovalLineView;

        if (payload.workId !== work?.id) {
          setState((prevState) => ({
            ...prevState,
            validation: '업무에 선택할 수 없는 결재선입니다.',
          }));
          return;
        }

        if (payload.workChange) {
          setState((prevState) => ({
            ...prevState,
            validation: '업무가 변경되어 결재선을 선택할 수 없습니다.',
          }));
          return;
        }

        setState((prevState) => ({
          ...prevState,
          approvalLine: payload.approvalLine,
          referencePermission: payload.referencePermission ?? {
            version: '0.1',
            groups: [],
          },
          viewPermission: payload.viewPermission ?? {
            version: '0.1',
            groups: [],
          },
          changeReason: '',
          userApprovalLineSelectVisible: false,
          userApprovalLineSelectedId: 0,
        }));
      }
    });
  };

  /** 결재선 그룹 선택 */
  const handleApprovalLineGroupSelect = (arg: {
    id: string;
    modify: boolean;
  }) => {
    // console.log('handleApprovalLineGroupSelect(arg)', arg);
    const { id, modify } = arg;
    if (modify)
      setState((prevState) => ({
        ...prevState,
        approvalGroupSelectedId: id,
        approvalLineGroupSelectedId: id,
      }));
  };

  /** 결재선 그룹 병렬 변경 (합의, 수신 그룹에서 사용) */
  const handleApprovalLineGroupParallelChange = (arg: { id: string }) => {
    // console.log('handleApprovalLineGroupParallelChange(arg)', arg);
    const { id } = arg;
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLineGroupSelectedId: id,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.map((a) => {
            if ((a.type === 'agree' || a.type === 'receive') && a.id === id)
              return { ...a, parallel: !a.parallel };
            return a;
          }),
        },
      };
    });
  };

  /** 결재선 그룹 삭제 */
  const handleApprovalLineGroupDelete = (arg: {
    index: number;
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  }) => {
    const { index, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: approvalLine.groups.filter((v, i) => i !== index),
        },
      };
    });
  };

  /** 결재선 그룹 항목 정렬 변경 */
  const handleApprovalLineGroupItemSortChange = (
    id: string,
    items: ApprovalLineGroupItemType[],
  ) => {
    // console.log(`handleApprovalLineGroupItemSortChange:id`, id);
    // console.log(`handleApprovalLineGroupItemSortChange:items`, items);
    // console.log(`state.approvalLine.groups`, state.approvalLine.groups);
    let sortItems = items;
    const prevApprovers = props.approvalLine?.groups
      .find((a) => a.id === id)
      ?.items.filter((a) => a.act !== undefined); // 현재 변경할 그룹에서 결재 완료자 체크
    const nextApprovers = sortItems.filter((a) => a.act === undefined); // 현재 변경할 그룹에서 결재 예정자 체크
    let newGroups = [...nextApprovers];
    if (prevApprovers) newGroups = [...prevApprovers, ...newGroups];
    const prevItems = state.approvalLine.groups.find((a) => a.id === id)?.items;
    // console.log(`handleApprovalLineGroupItemSortChange:prevItems`, prevItems);
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== newGroups.length;
    if (prevItems.length === newGroups.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== newGroups[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    // console.log(`id: ${id}, sortChanged: ${sortChanged}`);
    if (sortChanged)
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((z, i) => {
              let status: 'complete' | 'proceed' | 'expected';

              // 결재작성인 경우.
              if (proceedIndex === 0) {
                // 수정 여부에 따라 결재 그룹 드레그 상태 설정.
                status = z.modify !== true ? 'complete' : 'expected';
              }
              // 결재선 변경인 경우.
              else if (proceedIndex === -1 || i < proceedIndex)
                status = 'complete';
              else if (i === proceedIndex) status = 'proceed';
              else status = 'expected';

              const parallelCheck =
                z.items.length === 1 && z.modify && status !== 'proceed';

              if (z.id === id) {
                // 합의 그룹 제외 option값 삭제
                if (z.type !== 'agree') {
                  newGroups = newGroups.map((a) => {
                    return {
                      ...a,
                      option: undefined,
                    };
                  });
                  sortItems = sortItems.map((a) => {
                    return {
                      ...a,
                      option: undefined,
                    };
                  });
                }
                // 결재 그룹 제외 arbitraryDesicion값 삭제
                if (z.type !== 'approval') {
                  newGroups = newGroups.map((a) => {
                    return {
                      ...a,
                      arbitraryDecision: undefined,
                    };
                  });
                }
                newGroups = newGroups.map((a) => {
                  return sortableDataUndefined(a);
                });
                sortItems = sortItems.map((a) => {
                  return sortableDataUndefined(a);
                });
                switch (z.type) {
                  case 'approval':
                    return {
                      ...z,
                      items: (
                        newGroups as ApprovalLineGroupItemsApprovalType
                      ).map((a) => {
                        if (a.employeeId)
                          return {
                            ...a,
                            arbitraryDecision:
                              a.arbitraryDecision ??
                              arbitraryDecisions.some(
                                (x) => x.employeeId === a.employeeId,
                              ),
                          };
                        return {
                          ...a,
                        };
                      }),
                    };
                  case 'agree':
                    return {
                      ...z,
                      parallel: parallelCheck ? false : z.parallel,
                      items: z.parallel
                        ? (sortItems as ApprovalLineGroupItemsAgreeType).map(
                            (a) => {
                              return {
                                ...a,
                                option: a.option ?? false,
                              };
                            },
                          )
                        : (newGroups as ApprovalLineGroupItemsAgreeType).map(
                            (a) => {
                              return {
                                ...a,
                                option: a.option ?? false,
                              };
                            },
                          ),
                    };
                  case 'receive':
                    return {
                      ...z,
                      parallel: parallelCheck ? false : z.parallel,
                      items: z.parallel
                        ? (sortItems as ApprovalLineGroupItemsReceiveType)
                        : (newGroups as ApprovalLineGroupItemsReceiveType),
                    };
                  case 'audit':
                    return {
                      ...z,
                      items: newGroups as ApprovalLineGroupItemsAuditType,
                    };
                  // draft
                  default:
                    return {
                      ...z,
                      items: newGroups as ApprovalLineGroupItemsDraftType,
                    };
                }
              }
              return z;
            }),
          },
        };
      });
  };

  /** 결재선 그룹 항목 옵션 변경 */
  const handleApprovalLineGroupItemOptionChange = (arg: {
    groupId: string;
    id: string; // 그룹 아이템 아이디
    code: string;
    checked: boolean;
  }) => {
    // console.log('handleApprovalLineGroupItemOptionChange(arg)', arg);
    const { groupId, id, code } = arg;
    if (
      code === 'jobposition' ||
      code === 'jobduty' ||
      code === 'jobposition+jobduty'
    ) {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'approval' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'agree' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'audit' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              if (group.type === 'receive' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return { ...item, jobClassType: code };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'option') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'agree' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id)
                      return { ...item, option: !item.option };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'draftApproval') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft')
                return {
                  ...group,
                  items: group.items.map((item) => {
                    const { approval = false } = item;
                    return { ...item, approval: !approval };
                  }),
                };
              return group;
            }),
          },
        };
      });
    } else if (code === 'arbitrarydecision') {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'approval' && group.id === groupId)
                return {
                  ...group,
                  items: group.items.map((item) => {
                    if (item.id === id && item.employeeId !== undefined)
                      return {
                        ...item,
                        arbitraryDecision: !item.arbitraryDecision,
                      };
                    return item;
                  }),
                };
              return group;
            }),
          },
        };
      });
    }
  };

  /** 결재선 그룹 항목 항목 삭제 */
  const handleApprovalLineGroupItemDelete = (arg: {
    groupId: string;
    id: string; // 그룹 아이템 아이디
    event: React.MouseEvent;
  }) => {
    // console.log(`handleApprovalLineGroupItemDelete(arg)`, arg);
    const { groupId, id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      return {
        ...prevState,
        approvalLine: {
          ...prevState.approvalLine,
          groups: prevState.approvalLine.groups.map((a, i) => {
            let status: 'complete' | 'proceed' | 'expected';

            // 결재작성인 경우.
            if (proceedIndex === 0) {
              status = a.modify !== true ? 'complete' : 'expected';
            }
            // 결재선 변경인 경우.
            else if (proceedIndex === -1 || i < proceedIndex)
              status = 'complete';
            else if (i === proceedIndex) status = 'proceed';
            else status = 'expected';

            const parallelCheck =
              a.items.length === 2 && a.modify && status !== 'proceed';
            if (a.id === groupId) {
              switch (a.type) {
                case 'agree':
                  return {
                    ...a,
                    parallel: parallelCheck ? false : a.parallel,
                    items: a.items.filter((b) => b.id !== id),
                  };
                case 'approval':
                  return { ...a, items: a.items.filter((b) => b.id !== id) };
                case 'receive':
                  return {
                    ...a,
                    parallel: parallelCheck ? false : a.parallel,
                    items: a.items.filter((b) => b.id !== id),
                  };
                case 'audit':
                  return { ...a, items: a.items.filter((b) => b.id !== id) };
                default:
                  return a;
              }
            }
            return a;
          }),
        },
      };
    });
  };

  /** 참조 권한 그룹 선택 */
  const handleReferencePermissionGroupSelect = (arg: {
    id: string;
    modify: boolean;
  }) => {
    // console.log('handleReferencePermissionGroupSelect(arg)', arg);
    const { id, modify } = arg;
    if (modify) {
      setState((prevState) => {
        return {
          ...prevState,
          approvalGroupSelectedId: id,
          referencePermissionGroupSelectedId: id,
        };
      });
    }
  };

  /** 참조 권한 그룹 삭제 */
  const handleReferencePermissionGroupDelete = (arg: {
    id: string;
    event: React.MouseEvent;
  }) => {
    const { id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const {
        approvalGroupSelectedId,
        referencePermissionGroupSelectedId,
        referencePermission,
      } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId:
          approvalGroupSelectedId === id ? '' : approvalGroupSelectedId,
        referencePermissionGroupSelectedId:
          referencePermissionGroupSelectedId === id
            ? ''
            : referencePermissionGroupSelectedId,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.filter((a) => a.id !== id),
        },
      };
    });
  };

  /** 참조 권한 그룹 항목 정렬 변경 */
  const handleReferencePermissionGroupItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    // console.log(`handleReferencePermissionGroupItemSortChange(arg)`, arg);
    const { groupId, items } = arg;
    const prevItems = state.referencePermission.groups.find(
      ({ id }) => id === groupId,
    )?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    // console.log(`id: ${id}, sortChanged: ${sortChanged}`);
    if (sortChanged)
      setState((prevState) => {
        const { referencePermission } = prevState;
        return {
          ...prevState,
          referencePermission: {
            ...referencePermission,
            groups: referencePermission.groups.map((group) => {
              if (group.id === groupId) return { ...group, items };
              return group;
            }),
          },
        };
      });
  };

  /** 참조 권한 그룹 항목 삭제 */
  const handleReferencePermissionGroupItemDelete = (arg: {
    groupId: string;
    id: string;
  }) => {
    // console.log(`handleReferencePermissionGroupItemDelete(arg)`, arg);
    const { groupId, id } = arg;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((group) => {
            // if (group.id === groupId) return { ...group, items };
            if (group.id === groupId)
              return {
                ...group,
                items: group.items.filter((item) => item.id !== id),
              };
            return group;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 선택 */
  const handleViewPermissionGroupSelect = (arg: {
    id: string;
    modify: boolean;
  }) => {
    // console.log('handleViewPermissionGroupSelect(arg)', arg);
    const { id, modify } = arg;
    if (modify) {
      setState((prevState) => {
        return {
          ...prevState,
          approvalGroupSelectedId: id,
          viewPermissionGroupSelectedId: id,
        };
      });
    }
  };

  /** 조회 권한 그룹 삭제 */
  const handleViewPermissionGroupDelete = (arg: {
    id: string;
    event: React.MouseEvent;
  }) => {
    const { id, event } = arg;
    event.stopPropagation();
    setState((prevState) => {
      const {
        approvalGroupSelectedId,
        viewPermissionGroupSelectedId,
        viewPermission,
      } = prevState;
      return {
        ...prevState,
        approvalGroupSelectedId:
          approvalGroupSelectedId === id ? '' : approvalGroupSelectedId,
        viewPermissionGroupSelectedId:
          viewPermissionGroupSelectedId === id
            ? ''
            : viewPermissionGroupSelectedId,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.filter((a) => a.id !== id),
        },
      };
    });
  };

  /** 조회 권한 그룹 항목 정렬 변경 */
  const handleViewPermissionGroupItemSortChange = (arg: {
    groupId: string;
    items: ApprovalLineGroupItemDefaultType[];
    sortable: unknown;
    store: unknown;
  }) => {
    // console.log(`handleViewPermissionGroupItemSortChange(arg)`, arg);
    const { groupId, items } = arg;
    const prevItems = state.viewPermission.groups.find(
      ({ id }) => id === groupId,
    )?.items;
    if (prevItems === undefined) return;
    let sortChanged = prevItems.length !== items.length;
    if (prevItems.length === items.length) {
      for (let i = 0; i < prevItems.length; i += 1) {
        if (prevItems[i].id !== items[i].id) {
          sortChanged = true;
          break;
        }
      }
    }
    // console.log(`id: ${id}, sortChanged: ${sortChanged}`);
    if (sortChanged)
      setState((prevState) => {
        const { viewPermission } = prevState;
        return {
          ...prevState,
          viewPermission: {
            ...viewPermission,
            groups: viewPermission.groups.map((group) => {
              if (group.id === groupId) return { ...group, items };
              return group;
            }),
          },
        };
      });
  };

  /** 조회 권한 그룹 항목 삭제 */
  const handleViewPermissionGroupItemDelete = (arg: {
    groupId: string;
    id: string;
  }) => {
    // console.log(`handleViewPermissionGroupItemDelete(arg)`, arg);
    const { groupId, id } = arg;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((group) => {
            // if (group.id === groupId) return { ...group, items };
            if (group.id === groupId)
              return {
                ...group,
                items: group.items.filter((item) => item.id !== id),
              };
            return group;
          }),
        },
      };
    });
  };

  /** 선택 사용자 목록 대화 상자 열기 */
  const handleChooseUserDialogOpen = () => {
    setState((prevState) => ({ ...prevState, chooseUserDialogVisible: true }));
  };

  /** 선택 사용자 목록 대화 상자 닫기 */
  const handleChooseUserDialogClose = () => {
    setState((prevState) => ({ ...prevState, chooseUserDialogVisible: false }));
  };

  function createApprovalLineGroup(type: ApprovalType): ApprovalLineGroupType {
    const id = `${Date.now()}`;
    const modify = true;
    switch (type) {
      case 'agree':
        return {
          id,
          type,
          required: false,
          modify,
          parallel: false,
          items: [],
        };
      case 'receive':
        return {
          id,
          type,
          required: false,
          modify,
          parallel: false,
          items: [],
        };
      case 'approval':
        return { id, type, required: false, modify, items: [] };
      case 'audit':
        return { id, type, required: false, modify, items: [] };
      // draft
      default:
        return { id, type, approval: false, items: [] };
    }
  }

  /** 결재선 그룹 추가 */
  const handleApprovalLineGroupAppend = (
    type: Exclude<ApprovalType, 'draft'>,
  ) => {
    // console.log(`handleApprovalLineGroupAppend(type: '${type}')`);
    setState((prevState) => {
      const { approvalLine } = prevState;
      return {
        ...prevState,
        approvalLine: {
          ...approvalLine,
          groups: [...approvalLine.groups, createApprovalLineGroup(type)],
        },
      };
    });
  };

  /** 참조 권한 그룹 추가 */
  const handleReferencePermissionGroupAppend = () => {
    if (state.referencePermission.groups.length !== 0) return;
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: [
            ...referencePermission.groups,
            {
              id: `${Date.now()}`,
              required: false, // 필수 여부
              modify: true, // 수정 여부
              items: [],
            },
          ],
        },
      };
    });
  };

  /** 조회 권한 그룹 추가 */
  const handleViewPermissionGroupAppend = () => {
    if (state.viewPermission.groups.length !== 0) return;
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: [
            ...viewPermission.groups,
            {
              id: `${Date.now()}`,
              required: false, // 필수 여부
              modify: true, // 수정 여부
              items: [],
            },
          ],
        },
      };
    });
  };

  /** 결재선 그룹 항목 모두 삭제 */
  const handleApprovalLineGroupItemDeleteAll = () => {
    const { type } = props;
    // 결재 작성에서 호출된 경우.
    if (type === undefined) {
      setState((prevState) => {
        const { approvalLine } = prevState;
        return {
          ...prevState,
          approvalLine: {
            ...approvalLine,
            groups: approvalLine.groups.map((group) => {
              if (group.type === 'draft' || !group.modify) return group;
              return { ...group, items: [] };
            }),
          },
        };
      });
    }
    // 결재선 및 조회권, 참조권 변경에서 호출된 경우.
    else {
      setState((prevState) => {
        return {
          ...prevState,
          approvalLine: {
            ...prevState.approvalLine,
            groups: prevState.approvalLine.groups.map((a) => {
              if (a.type === 'draft' || !a.modify) return a;
              switch (a.type) {
                case 'agree':
                  return { ...a, items: a.items.filter((b) => b.act) };
                case 'approval':
                  return { ...a, items: a.items.filter((b) => b.act) };
                case 'receive':
                case 'audit':
                  return { ...a, items: a.items.filter((b) => b.act) };
                default:
                  return a;
              }
            }),
          },
        };
      });
    }
  };

  /** 참조 권한 그룹 항목 모두 삭제 */
  const handleReferencePermissionGroupItemDeleteAll = () => {
    // console.log(`handleReferencePermissionGroupItemDeleteAll`);
    setState((prevState) => {
      const { referencePermission } = prevState;
      return {
        ...prevState,
        referencePermission: {
          ...referencePermission,
          groups: referencePermission.groups.map((group) => {
            if (group.modify) return { ...group, items: [] };
            return group;
          }),
        },
      };
    });
  };

  /** 조회 권한 그룹 항목 모두 삭제 */
  const handleViewPermissionGroupItemDeleteAll = () => {
    // console.log(`handleViewPermissionGroupItemDeleteAll`);
    setState((prevState) => {
      const { viewPermission } = prevState;
      return {
        ...prevState,
        viewPermission: {
          ...viewPermission,
          groups: viewPermission.groups.map((group) => {
            if (group.modify) return { ...group, items: [] };
            return group;
          }),
        },
      };
    });
  };

  /** 변경 사유 변경 */
  const handleChangeReasonChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const changeReason = event.target.value;
    setState((prevState) => ({ ...prevState, changeReason }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 1인 결재인 경우 */
  const isDraftApproval = (approvalLine: ApprovalLineType) => {
    if (
      approvalLine.groups.find(
        (a) => a.type === 'draft' && a.approval && a.items[0].approval,
      )
    )
      return true;
    return false;
  };

  /**
   * 기안자 결재(1인 결재) 유효성 검사.
   * @param approvalLine 결재선.
   * @returns 기안자 결재(1인 결재) 유효성 검사를 통과한 경우 true, 통과하지 못한 경우 false.
   */
  const validateDraftApproval = (
    approvalLine: ApprovalLineType | undefined,
  ) => {
    if (approvalLine === undefined) return true;

    // 기안자 결재(1인 결재)인 경우.
    if (isDraftApproval(approvalLine)) {
      const group = approvalLine.groups.find(
        (g) => g.type !== 'draft' && g.items.length > 0,
      );
      if (group !== undefined) {
        const approvalLineGroupSelectedId = group.id;
        const validation = `1인 결재인 경우 기안자 외 결재 그룹을 선택할 수 없습니다.`;
        if (group.modify) {
          setState((prevState) => ({
            ...prevState,
            approvalGroupSelectedId: approvalLineGroupSelectedId,
            approvalLineGroupSelectedId,
            validation,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            validation,
          }));
        }
        return false;
      }
      return true;
    }
    return true;
  };

  /**
   * 병렬 그룹의 중복 항목 유효성 검사.
   * @param approvalLine 결재선
   * @returns 결재선 병렬 그룹의 중복 항목이 없는 경우 true, 있는 경우 false
   */
  const validateDuplicateItemsInParallelGroups = (
    approvalLine: ApprovalLineType | undefined,
  ) => {
    if (approvalLine === undefined) return true;

    // 병렬 구성 중인 그룹에서 중복 항목이 있는 그룹 찾기.
    const group = approvalLine.groups.find((a) => {
      if ((a.type === 'agree' || a.type === 'receive') && a.parallel) {
        const organizationIds = a.items
          .filter((b) => b.employeeId === undefined)
          .map(({ organizationId }) => organizationId);
        const employeeIds = a.items
          .filter((b) => b.employeeId !== undefined)
          .map(({ employeeId }) => employeeId);

        // console.log(`----------organizationIds`, organizationIds.length);
        // console.log(`----------employeeIds`, employeeIds.length);
        // console.log(`----------Array.from(new Set(organizationIds)).length`, Array.from(new Set(organizationIds)).length);
        // console.log(`----------Array.from(new Set(employeeIds)).length`, Array.from(new Set(employeeIds)).length);

        return (
          organizationIds.length !==
            Array.from(new Set(organizationIds)).length ||
          employeeIds.length !== Array.from(new Set(employeeIds)).length
        );
      }
      return false;
    });
    if (group !== undefined) {
      const approvalGroupSelectedId = group.id;
      const validation = `${getText(
        `approval:approvalLineGroup.${group.type}`,
      )} 그룹 병렬 구성 시 직원 또는 조직을 중복 설정할 수 없습니다.`;
      setState((prevState) => ({
        ...prevState,
        approvalGroupSelectedId,
        approvalLineGroupSelectedId: approvalGroupSelectedId,
        validation,
      }));
      return false;
    }
    return true;
  };

  /** 저장 */
  const handleSave = () => {
    // console.log(`handleSave`);

    if (props.onSave !== undefined) {
      let referencePermission =
        props.referencePermission !== undefined
          ? state.referencePermission
          : undefined;
      let viewPermission =
        props.viewPermission !== undefined ? state.viewPermission : undefined;

      if (isInternalApprovalAfterReceipt) {
        referencePermission =
          state.referencePermission.groups.length > 0
            ? state.referencePermission
            : undefined;
        viewPermission =
          state.viewPermission.groups.length > 0
            ? state.viewPermission
            : undefined;
      }

      if (props.type === undefined) {
        const { approvalLine } = state;
        if (!isDraftApproval(approvalLine)) {
          const group = approvalLine.groups.find(
            (a) => a.type !== 'draft' && a.required && a.items.length === 0,
          );
          if (group !== undefined) {
            const approvalLineGroupSelectedId = group.id;
            const validation = `${getText(
              `approval:approvalLineGroup.${group.type}`,
            )} 그룹의 직원 또는 조직을 선택하셔야 합니다.`;
            setState((prevState) => ({
              ...prevState,
              approvalGroupSelectedId: approvalLineGroupSelectedId,
              approvalLineGroupSelectedId,
              validation,
            }));
            return;
          }
        }

        if (!validateDraftApproval(approvalLine)) return;
        if (!validateDuplicateItemsInParallelGroups(approvalLine)) return;

        props.onSave({ approvalLine, referencePermission, viewPermission });
      }
      // 결재선 또는 조회권, 참조권 변경인 경우.
      else if (props.type === 'change') {
        if (!isDraftApproval(state.approvalLine)) {
          const group = state.approvalLine.groups.find(
            (a) => a.type !== 'draft' && a.required && a.items.length === 0,
          );
          if (group !== undefined) {
            const approvalLineGroupSelectedId = group.id;
            const validation = `${getText(
              `approval:approvalLineGroup.${group.type}`,
            )} 그룹의 직원 또는 조직을 선택하셔야 합니다.`;
            setState((prevState) => ({
              ...prevState,
              approvalGroupSelectedId: approvalLineGroupSelectedId,
              approvalLineGroupSelectedId,
              validation,
            }));
            return;
          }
        }
        const { changeReason: reason } = state;
        if (reason.trim() === '') {
          const validation = `변경사유를 입력하세요.`;
          setState((prevState) => ({ ...prevState, validation }));
          return;
        }

        const approvalLine =
          props.approvalLine !== undefined ? state.approvalLine : undefined;

        if (!validateDraftApproval(approvalLine)) return;
        if (!validateDuplicateItemsInParallelGroups(approvalLine)) return;

        props.onSave({
          affiliatedCompanyId: props.affiliatedCompanyId,
          companyId: props.companyId,
          id: props.id,
          approvalLine,
          referencePermission,
          viewPermission,
          reason,
          updateAt: props.updateAt,
        });
      }
    }
  };

  /** 선택 사용자 목록 렌더링 (모바일 화면 크기에서 사용) */
  const renderChooseUserList = () => {
    const {
      approvalGroupSelectedId,
      approvalLineGroupSelectedId,
      referencePermissionGroupSelectedId,
      viewPermissionGroupSelectedId,
    } = state;

    // 결재선 그룹이 선택된 경우
    if (approvalGroupSelectedId === approvalLineGroupSelectedId) {
      const { approvalLine } = state;
      return (
        <>
          {approvalLine.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    // 참조권 그룹이 선택된 경우
    if (approvalGroupSelectedId === referencePermissionGroupSelectedId) {
      const { referencePermission } = state;
      return (
        <>
          {referencePermission.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    // 조회권 그룹이 선택된 경우
    if (approvalGroupSelectedId === viewPermissionGroupSelectedId) {
      const { viewPermission } = state;
      return (
        <>
          {viewPermission.groups
            .map(({ items }) => items)
            .flat()
            .map((item) => {
              if (item.employeeId !== undefined)
                return (
                  <UserInfo
                    key={item.id}
                    name={item.employeeName}
                    avatar={getAvatarPath(item)}
                  />
                );
              return (
                <UserInfo
                  key={item.id}
                  name={item.organizationName}
                  icon="sitemap-fill"
                />
              );
            })}
        </>
      );
    }
    return null;
  };

  /** 선택 탭 렌더링 */
  const renderChooseTab = () => {
    const { chooseTabSelectedId } = state;

    let content = null;
    switch (chooseTabSelectedId) {
      case 'organizationchart': {
        content = (
          <>
            <SimpleSearch
              keyword={state.directoryTreeFilter}
              onSearch={handleDirectoryTreeFilter}
            />
            <div className="tree-area">
              <DirectoryTree
                compose
                selectedId={state.directoryTreeSelectedId}
                items={directoryTreeItems}
                filter={state.directoryTreeFilter}
                onItemClick={handleDirectoryTreeItemClick}
              />
            </div>
          </>
        );
        break;
      }
      case 'approvalofficer': {
        // TODO 로딩중 퍼블리싱 작업 필요.
        content = (
          <div className="area-content manager-area">
            {approvalOfficers === undefined ? (
              <Loading />
            ) : (
              approvalOfficers.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    label={item.name}
                    icon="user-tie"
                    onClick={(event) =>
                      handleApprovalOfficerItemClick({ item, event })
                    }
                  />
                );
              })
            )}
          </div>
        );
        break;
      }
      case 'personalApprovalLine': {
        content = (
          <div className="area-content custom-area">
            {personalApprovalLine === undefined ? (
              <Loading />
            ) : (
              personalApprovalLine.map((item) => (
                <MenuItem
                  key={item.id}
                  label={item.name}
                  icon="progress-rate"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      userApprovalLineSelectVisible: true,
                      userApprovalLineSelectedId: item.id,
                    }))
                  }
                  disabled={item.workChange}
                />
              ))
            )}
          </div>
        );
        break;
      }
      default:
        break;
    }

    return (
      <>
        <Tab>
          {chooseTabs.map(({ id, text }) => (
            <Tab.Item
              key={id}
              label={text}
              selected={id === chooseTabSelectedId}
              onClick={() => handleChooseTabItemClick({ id })}
            />
          ))}
        </Tab>
        {content}
      </>
    );
  };

  /** 결재선 그룹 바디 항목 렌더링 */
  const renderApprovalLineGroupBodyItem = (
    a: ApprovalLineGroupType,
    b: ApprovalLineGroupItemType,
    modify: boolean,
  ) => {
    const {
      approval: draftApproval = false,
      option = false,
      arbitraryDecision = false,
    } = {
      ...b,
    };

    // 직원인 경우
    if (b.employeeId !== undefined) {
      const isJobClassChange =
        (props.type === undefined && a.type === 'draft') || modify;

      const text = getNameText(
        b.employeeName,
        b.jobClassType,
        b.jobPositionName,
        b.jobDutyName,
      );

      const jobClassOptions = isJobClassChange
        ? getApprovalLineGroupItemOptions(b.jobClassType)
        : [];

      const draftApprovalOptions =
        props.type === undefined && a.type === 'draft' && a.approval === true
          ? createApprovalLineGroupItemDraftApprovalOptions(draftApproval)
          : [];

      const agreeOptions =
        modify && a.type === 'agree'
          ? getApprovalLineGroupItemAgreeOptions(option)
          : [];

      const approvalOptions =
        modify && a.type === 'approval'
          ? getApprovalLineGroupItemApprovalOptions(arbitraryDecision)
          : [];

      const options = [
        ...jobClassOptions,
        ...draftApprovalOptions,
        ...agreeOptions,
        ...approvalOptions,
      ];

      return (
        <ApprovalLineGroupItem
          key={b.id}
          groupId={a.id}
          id={b.id}
          employeeText={text}
          organizationName={b.organizationName}
          avatar={getAvatarPath(b)}
          macroName={b.macroName}
          onDelete={modify ? handleApprovalLineGroupItemDelete : undefined}
          options={options.length ? options : undefined}
          onOptionChange={
            isJobClassChange
              ? handleApprovalLineGroupItemOptionChange
              : undefined
          }
          dragable={modify}
        />
      );
    }

    const options =
      modify && a.type === 'agree'
        ? getApprovalLineGroupItemAgreeOptions(option, false)
        : [];

    // 조직인 경우
    return (
      <ApprovalLineGroupItem
        key={b.id}
        groupId={a.id}
        id={b.id}
        organizationName={b.organizationName}
        onDelete={modify ? handleApprovalLineGroupItemDelete : undefined}
        options={options.length ? options : undefined}
        onOptionChange={
          modify ? handleApprovalLineGroupItemOptionChange : undefined
        }
        dragable={modify}
      />
    );
  };

  /** 결재선 바디 그룹 렌더링. */
  const renderApprovalLineGroupBody = (arg: {
    value: ApprovalLineGroupType;
    status: 'expected' | 'proceed' | 'complete';
    parallel: boolean;
  }) => {
    // console.log(`renderApprovalLineGroupBody(arg)`, arg);
    const { value, status, parallel } = arg;
    if (value.modify) {
      if (status === 'complete' || (status === 'proceed' && !parallel))
        return (
          <>
            <div className="approval-line-group">
              {(value.items as ApprovalLineGroupItemType[])
                .filter((b) => proceedIndex === 0 || b.act)
                .map((b) => {
                  return renderApprovalLineGroupBodyItem(value, b, false);
                })}
            </div>
            {status === 'proceed' && !parallel && (
              <ReactSortable
                className="approval-line-group"
                list={(value.items as ApprovalLineGroupItemType[]).filter(
                  (b) => b.act === undefined,
                )}
                setList={(newState) =>
                  handleApprovalLineGroupItemSortChange(value.id, newState)
                }
                animation={200}
                handle=".drag"
                group="approval-line-group"
              >
                {(value.items as ApprovalLineGroupItemType[])
                  .filter((b) => b.act === undefined)
                  .map((b) => {
                    return renderApprovalLineGroupBodyItem(
                      value,
                      b,
                      value.modify === true,
                    );
                  })}
              </ReactSortable>
            )}
          </>
        );
      return (
        <ReactSortable
          className="approval-line-group"
          list={value.items}
          setList={(newState) =>
            handleApprovalLineGroupItemSortChange(value.id, newState)
          }
          animation={200}
          handle=".drag"
          group="approval-line-group"
        >
          {(value.items as ApprovalLineGroupItemType[]).map((b) => {
            let modifing: boolean;
            if (status === 'expected') modifing = value.modify === true;
            else if (status === 'proceed')
              modifing = value.modify === true && b.act === undefined;
            else modifing = false;

            return renderApprovalLineGroupBodyItem(value, b, modifing);
          })}
        </ReactSortable>
      );
    }

    // 고정 결재선인 경우 sort 적용 불가능.
    return (
      <div className="approval-line-group">
        {(value.items as ApprovalLineGroupItemType[]).map((b) => {
          return renderApprovalLineGroupBodyItem(value, b, false);
        })}
      </div>
    );
  };

  /** 결재선 지정 렌더링 */
  const renderApprovalLineDesignation = () => {
    const {
      approvalGroupSelectedId,
      approvalLine,
      referencePermission,
      viewPermission,
    } = state;
    return (
      <div className="approval-line">
        <div className="line-root">
          {approvalLine.groups.map((a, i) => {
            /** 결재 그룹 진행 상태. (결재선 변경인 경우) - expected: 예정, proceed: 진행, complete: 완료 */
            let status: 'complete' | 'proceed' | 'expected';

            // 결재작성인 경우.
            if (proceedIndex === 0) {
              // 수정 여부에 따라 결재 그룹 드레그 상태 설정.
              status = a.modify !== true ? 'complete' : 'expected';
            }
            // 결재선 변경인 경우.
            else if (proceedIndex === -1 || i < proceedIndex)
              status = 'complete';
            else if (i === proceedIndex) status = 'proceed';
            else status = 'expected';

            // console.log(`grouptype: ${a.type}, i: ${i}, proceedIndex: ${proceedIndex}, status: ${status}`);

            const parallel =
              (a.type === 'agree' || a.type === 'receive') && a.parallel;

            const { id, required = false, modify = false } = { ...a };

            let className = `line-group ${approvalLineGroupClassName(a.type)}`;
            if (!modify) className += ` lock`;
            if (required) className += ` required`;
            return (
              <div
                key={id}
                className={className}
                onClick={() =>
                  handleApprovalLineGroupSelect({
                    id,
                    modify: modify && status !== 'complete',
                  })
                }
                aria-selected={id === approvalGroupSelectedId}
              >
                <div className="group-head">
                  <div className="title">
                    {getText(`approval:approvalLineGroup.${a.type}`)}
                  </div>
                  {(a.type === 'agree' || a.type === 'receive') &&
                    modify &&
                    a.items.length > 1 && (
                      <Checkbox
                        label="병렬"
                        checked={a.parallel}
                        onChange={() =>
                          handleApprovalLineGroupParallelChange({ id })
                        }
                        disabled={!modify || status !== 'expected'}
                        className="group-option"
                      />
                    )}
                  {a.type !== 'draft' && !modify && (
                    <Tooltip title="고정 그룹은 편집할 수 없습니다">
                      <em className="tip">고정</em>
                    </Tooltip>
                  )}
                  {required && modify && (
                    <Tooltip title="필수 그룹은 최소 1인 이상 필요">
                      <em className="tip">필수</em>
                    </Tooltip>
                  )}
                  {isInternalApprovalAfterReceipt && a.items.length === 0 && (
                    <div className="action">
                      <Button
                        text="삭제"
                        iconType
                        icon="trash-full"
                        onClick={(event) =>
                          handleApprovalLineGroupDelete({
                            index: i,
                            event,
                          })
                        }
                        color="secondary"
                        className="group-delete"
                      />
                    </div>
                  )}
                </div>
                <div className="group-body">
                  {renderApprovalLineGroupBody({ value: a, status, parallel })}
                  {display === 'phone' && modify && (
                    <div className="action">
                      <Button
                        className="add-approval-line-item"
                        text="편집"
                        onClick={handleChooseUserDialogOpen}
                        block
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {referencePermission && (
          <SharePermissionGroups
            title="참조"
            selectedId={approvalGroupSelectedId}
            groups={referencePermission.groups}
            onSelect={handleReferencePermissionGroupSelect}
            onItemAppend={
              display === 'phone' ? handleChooseUserDialogOpen : undefined
            }
            onItemSortChange={handleReferencePermissionGroupItemSortChange}
            onItemDelete={handleReferencePermissionGroupItemDelete}
            onDelete={
              isInternalApprovalAfterReceipt
                ? handleReferencePermissionGroupDelete
                : undefined
            }
          />
        )}
        {viewPermission && (
          <SharePermissionGroups
            title="조회"
            selectedId={approvalGroupSelectedId}
            groups={viewPermission.groups}
            onSelect={handleViewPermissionGroupSelect}
            onItemAppend={
              display === 'phone' ? handleChooseUserDialogOpen : undefined
            }
            onItemSortChange={handleViewPermissionGroupItemSortChange}
            onItemDelete={handleViewPermissionGroupItemDelete}
            onDelete={
              isInternalApprovalAfterReceipt
                ? handleViewPermissionGroupDelete
                : undefined
            }
          />
        )}
        {isInternalApprovalAfterReceipt && (
          <dl className="add-line">
            <dt>결재유형 추가</dt>
            <dd>
              {approvalTypes.find((a) => a === 'APPROVAL') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('approval')}
                  className="approval"
                >
                  <Icon icon="check" />
                  <span>결재</span>
                </button>
              )}
              {approvalTypes.find((a) => a === 'AGREEMENT') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('agree')}
                  className="agree"
                >
                  <Icon icon="equals" />
                  <span>합의</span>
                </button>
              )}
              {approvalTypes.find((a) => a === 'AUDIT') && (
                <button
                  type="button"
                  onClick={() => handleApprovalLineGroupAppend('audit')}
                  className="auditor"
                >
                  <Icon icon="user-tie" />
                  <span>감사</span>
                </button>
              )}
              <button
                type="button"
                onClick={handleReferencePermissionGroupAppend}
                className="referrer"
              >
                <Icon icon="search" />
                <span>참조</span>
              </button>
              <br />
              <button
                type="button"
                onClick={handleViewPermissionGroupAppend}
                className="viewer"
              >
                <Icon icon="eye" />
                <span>조회</span>
              </button>
            </dd>
          </dl>
        )}
      </div>
    );
  };

  /** 결재 프로세스 렌더링 */
  const renderApprovalProcess = () => {
    const { approvalLine, referencePermission, viewPermission } = state;

    const useApprovalLineDeleteAll =
      approvalLine.groups.find(
        (group) =>
          group.type !== 'draft' && group.modify && group.items.length > 0,
      ) !== undefined;

    let useReferencePermissionDelete = false;
    const referencePermissionItems = referencePermission.groups
      .map(({ items, modify }) => {
        if (items.length === 0) return [];
        if (modify) useReferencePermissionDelete = true;
        return items;
      })
      .flat();

    let useViewPermissionDelete = false;
    const viewPermissionItems = viewPermission.groups
      .map(({ items, modify }) => {
        if (items.length === 0) return [];
        if (modify) useViewPermissionDelete = true;
        return items;
      })
      .flat();

    return (
      <div className="approval-process">
        {props.approvalLine !== undefined && (
          <dl>
            <dt>결재선:</dt>
            <dd>
              <ApprovalLineFlat
                optionalNotation
                approvalLine={approvalLine}
                ignoreGroupName
              />
              {useApprovalLineDeleteAll && (
                <Button
                  className="delete"
                  text={getText('모두삭제')}
                  iconType
                  icon="trash-full"
                  onClick={handleApprovalLineGroupItemDeleteAll}
                  color="secondary"
                  size="xs"
                />
              )}
            </dd>
          </dl>
        )}
        {referencePermission.groups.length > 0 && (
          <dl>
            <dt>참조권:</dt>
            <dd>
              <SharePermissionFlat
                items={referencePermissionItems}
                onDeleteAll={
                  useReferencePermissionDelete
                    ? handleReferencePermissionGroupItemDeleteAll
                    : undefined
                }
              />
            </dd>
          </dl>
        )}
        {viewPermission.groups.length > 0 && (
          <dl>
            <dt>조회권:</dt>
            <dd>
              <SharePermissionFlat
                items={viewPermissionItems}
                onDeleteAll={
                  useViewPermissionDelete
                    ? handleViewPermissionGroupItemDeleteAll
                    : undefined
                }
              />
            </dd>
          </dl>
        )}
      </div>
    );
  };

  /** 변경 사유 렌더링 */
  const renderChangeReason = () => {
    const { type } = props;

    if (type === 'change') {
      const { changeReason } = state;
      return (
        <div className="change-area">
          <TextField
            rowsMin={3}
            rowsMax={3}
            maxLength={500}
            placeholder="변경사유를 입력하세요"
            multiline
            value={changeReason}
            onChange={handleChangeReasonChange}
            count
          />
        </div>
      );
    }

    return null;
  };

  const handleSelectedCancel = () => {
    setState((prevState) => ({
      ...prevState,
      userApprovalLineSelectVisible: false,
      userApprovalLineSelectedId: 0,
    }));
  };

  const renderDialog = () => {
    const { userApprovalLineSelectVisible } = state;
    if (userApprovalLineSelectVisible) {
      return (
        <Confirmation
          noDuplication
          onCancel={handleSelectedCancel}
          onSubmit={handlePersonalApprovalLineClick}
        >
          <p>
            개인결재선 선택 시 기존에 선택된 결재선이 모두 사라집니다. 결재선을
            선택하시겠습니까?
          </p>
        </Confirmation>
      );
    }
    return null;
  };

  const { onCancel } = props;
  const { chooseUserDialogVisible, validation } = state;
  const title = '결재선 지정';
  return (
    <>
      <Dialog className="ui-approval-line-selection-dialog">
        <div
          className={`ui-approval-line-selection ${
            display === 'phone' && chooseUserDialogVisible ? 'fixed' : ''
          }`}
        >
          <div className="selection-head">
            <div className="title">{title}</div>
          </div>
          <div className="selection-body">
            <div className="selector-area">
              {(display !== 'phone' || chooseUserDialogVisible) && (
                <div className="select-panel">
                  <div className="choose-area">
                    {display === 'phone' && chooseUserDialogVisible && (
                      <div className="mobile-choose-user">
                        <div className="user-list" ref={mobileChooseListRef}>
                          {renderChooseUserList()}
                        </div>
                        <Button
                          text="선택완료"
                          variant="contained"
                          onClick={handleChooseUserDialogClose}
                          className="action-close"
                          block
                        />
                      </div>
                    )}
                    {renderChooseTab()}
                  </div>
                </div>
              )}
              <div className="selected-panel">
                {renderApprovalLineDesignation()}
              </div>
            </div>
            <div className="result-area">{renderApprovalProcess()}</div>
            {renderChangeReason()}
          </div>
          <div className="selection-footer">
            <Button text="취소" color="secondary" onClick={onCancel} />
            <Button
              text={getText('저장')}
              variant="contained"
              onClick={handleSave}
            />
          </div>
        </div>
      </Dialog>
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export {
  getApprovalLineDrafter,
  getApprovalGroup,
  getPreviousApprover,
  getPreviousApproverGroupItemId,
  isPreviousApprover,
  getCurrentApprover,
  getLastApprover,
  approversOf,
  isArbitraryDecisionApproving,
  nonArbitraryDecisionApproving,
  isCurrentApproversReceipting,
};

export type {
  ApprovalLineGroupItemEmployeeType,
  ApprovalLineGroupItemOrganizationType,
};

export default ApprovalLineDialogContainer;
