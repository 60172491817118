import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { AdvancedSearchParams } from '../../../../../groupware-common/utils';

export interface DocumentItem {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디. (관계사인 경우만 출력)
  id: number; // 문서 아이디.
  workName: string; // 업무 이름.
  formName: string; // 양식 이름.
  status: number; // 결재상태. 1 : 진행, 3: 완료, 4: 반려, 10: 임시보관, 12: 회수, 13: 삭제
  number: string; // 문서번호.
  subject: string; // 제목.
  approvalline: string; // 결재선.
  referencePermission: string; // 참조 권한.
  viewPermission: string; // 조회 권한.
  viewCount: number; // 조회 수. (직원별로 최대1 증가)
  opinionCount: number; // 의견 수.
  commentCount: number; // 댓글 수.
  attachedDocumentCount: number; // 첨부문서 수.
  attachedFileCount: number; // 첨부파일 수.
  size: number; // 크기.
  draftAt: string; // 기안 날짜.
  completeAt: string; // 완료 날짜.
  createAt: string; // 생성 날짜.
  updateAt: string; // 수정 날짜.
  linkWait?: boolean; // 연동 대기 여부.
}

/**
 * 문서의 이전 또는 다음 문서 아이디를 조회합니다.
 * @param arg.type 이전 또는 다음.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 이전 또는 다음 문서 아이디 객체 또는 undefined.
 */
async function readPrevIdOrNextId(arg: {
  type: 'PREV' | 'NEXT';
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  const { type, documentId, folderId, advancedSearch } = arg;

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/permission/folders/${folderId}/documents/${documentId}/${type}`;
    const response = await axios.get(url, {
      headers,
      params: {
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서의 이전 문서 아이디를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 이전 문서 아이디 객체 또는 undefined.
 */
async function prevId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'PREV' });
}

/**
 * 문서의 다음 문서 아이디를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 다음 문서 아이디 객체 또는 undefined.
 */
async function nextId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'NEXT' });
}

/**
 * 문서 목록을 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function documents(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<{
  count?: number; // 문서 총 갯수.
  list: DocumentItem[];
}> {
  const { folderId, pageno, rowsperpage, advancedSearch } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/permission/folders/${folderId}/documents`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서 총 개수를 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function totalCount(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<number> {
  try {
    const { folderId, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/shared/permission/folders/${folderId}/documents/count`;
    const response = await axios.get<{ count: number }>(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data.count;
  } catch (ex) {
    throw apiError(ex);
  }
}

const permissionDocumentApi = {
  prevId,
  nextId,

  documents,
  totalCount,
};

export default permissionDocumentApi;
