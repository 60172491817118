import React from 'react';
import Avatar from './Avatar';

function AvatarGroup(props: {
  data: {
    id: number;
    name: string;
    avatar: string;
    color?: string;
  }[];
  max?: number;
  className?: string;
  shape?: 'square' | 'rounded' | 'circle';
}): JSX.Element {
  const { data, max, className, shape } = props;
  let classname = 'eui-avatar-group';
  if (className) classname += ` ${className}`;

  const more = max !== undefined && max < data.length && (
    <div className="more">+{data.length - max + 1}</div>
  );

  const list = () => {
    const items: JSX.Element[] = new Array<JSX.Element>();

    const maxLength =
      max !== undefined && max < data.length ? max - 1 : data.length;

    for (let i = 0; i < maxLength; i += 1) {
      items.push(
        <Avatar
          key={data[i].id}
          name={data[i].name}
          image={data[i].avatar}
          color={data[i].color}
          shape={shape}
        />,
      );
    }
    return items;
  };

  return (
    <div className={classname}>
      {list()}
      {more}
    </div>
  );
}

export default AvatarGroup;
