import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import SelectField from '../../../../../components/selectField/SelectField';
import Switch from '../../../../../components/switch/Switch';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import {
  attendancePreferencesActions,
  AttendanceStandardDate,
} from '../../../../stores/attendance/preferences';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import AttendanceEnteringDayOffCountSetting from './AttendanceEnteringDayOffCountSetting';
import AttendanceAccountDayOffCountSetting from './AttendanceAccountDayOffCountSetting';
import AttendanceDayOffSettingStartContainer from './AttendanceDayOffSettingStartContainer';
import Confirmation from '../../../../../components/alert/Confirmation';
import { dateFormat } from '../../../../../groupware-common/utils/ui';

interface CountsYearListType {
  workingYearCount: number;
  isAutoCalculate: boolean;
  fixedCounts: number;
  updateAt: string | null;
  lookupDeleteAt?: string;
}

function AttendanceDayOffSettingStandardContainer(): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const { isExists, data: standard } = useSelector(
    (state: RootState) => state.attendance.preferences.standard,
  );
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6004)?.name ?? '';

  /** Date 객체 변경 함수 ex ) 0101 -> 2022-01-01 00:00:00 */
  const setDateFormat = (monthDay: string) => {
    const month = Number(monthDay.substring(0, 2));
    const day = Number(monthDay.substring(2, 4));
    const date = moment()
      .set('month', month - 1)
      .set('date', day)
      .toDate();
    return date;
  };

  const initialState = () => {
    return {
      validation: '',
      saveing: false,
      change: false,
      cancel: false,
      isSaveClick: false,
      initialPage: !isExists, // 연차 생성 여부 구분.
      annualGenerationStandardType: standard.annualGenerationStandardType, // 연차생성기준 (1 : 입사일자, 2: 회계연도)
      countsYearList: standard.countsYearList as CountsYearListType[],
      decimalHandlingType: standard.decimalHandlingType.toString(), // 소수점 처리 구분
      useLeaveExcess: standard.useLeaveExcess, // 연차초과사용 사용여부
      startDate:
        standard.startDate !== null && standard.startDate !== ''
          ? setDateFormat(standard.startDate)
          : null, // 회계연도 시작일
      endDate:
        standard.endDate !== null && standard.endDate !== ''
          ? setDateFormat(standard.endDate)
          : null, // 회계연도 종료일
      updateAt: standard.updateAt, // 수정 날짜
    };
  };

  const [state, setState] = useState(initialState);

  // 연차 생성 입사일자 기준인 경우.
  const isEnterDate = state.annualGenerationStandardType === 1;
  // 연차 생성 회계연도 기준인 경우.
  const isAccount = state.annualGenerationStandardType === 2;

  useEffect(() => {
    let mount = true;

    async function run() {
      const { useLeaveExcess, decimalHandlingType, countsYearList } = state;

      // 회계연도 기준일 경우 회계 시작일, 종료일이 null값인 경우 저장 안됨.
      if (isAccount && (state.startDate === null || state.endDate === null))
        return;
      const startDate =
        state.startDate === null || isEnterDate
          ? ''
          : dateFormat(state.startDate, 'MMDD');
      const endDate =
        state.endDate === null || isEnterDate
          ? ''
          : dateFormat(state.endDate, 'MMDD');

      const save = await dispatch(
        attendancePreferencesActions.modifyStandard({
          startDate,
          endDate,
          countsYearList,
          useLeaveExcess,
          useMinusLeaves: useLeaveExcess,
          decimalHandlingType: parseInt(decimalHandlingType, 10),
          updateAt: standard.updateAt,
        }),
      );
      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }
      if (state.isSaveClick) {
        const param = {
          standardDate: dateFormat(new Date(), 'yyyy-MM-DD'),
          creatorId: principal.employeeId,
          createAt: dateFormat(new Date(), 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
        };
        dispatch(
          attendancePreferencesActions.createOccurs({
            type: isEnterDate ? 'enter' : 'account',
            ...param,
          }),
        );
      }
      if (!mount) return;
      const payload = save.payload as {
        updateAt: string;
        countsYearList: {
          workingYearCount: number;
          isAutoCalculate: boolean;
          fixedCounts: number;
          updateAt: string | null;
          lookupDeleteAt?: string;
        }[];
      };
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        isSaveClick: false,
        updateAt: payload.updateAt,
        countsYearList: payload.countsYearList,
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  const handleSaveBefore = () => {
    setState((prev) => ({ ...prev, isSaveClick: true }));
  };

  /** 저장. */
  const handleSave = async () => {
    let nullYear: {
      workingYearCount: number;
      isAutoCalculate: boolean;
      fixedCounts: number;
      updateAt: string | null;
      lookupDeleteAt?: string;
    }[] = [];
    if (isAccount) {
      if (
        state.countsYearList
          .slice(0, 2)
          .some((a) => a.fixedCounts === 0 && !a.isAutoCalculate)
      )
        nullYear = state.countsYearList
          .slice(0, 2)
          .filter((a) => a.fixedCounts === 0 && !a.isAutoCalculate);
      if (state.countsYearList.slice(2).some((a) => a.fixedCounts === 0))
        nullYear = [
          ...nullYear,
          ...state.countsYearList.slice(2).filter((a) => a.fixedCounts === 0),
        ];
      if (state.startDate === null || state.endDate === null) {
        const validation = '회계연도 시작일과 종료일을 입력해주세요.';
        setState((prev) => ({
          ...prev,
          validation,
        }));
        return;
      }
      if (
        state.countsYearList.slice(2).some((a) => a.fixedCounts === 0) ||
        state.countsYearList
          .slice(0, 2)
          .some((a) => a.fixedCounts === 0 && !a.isAutoCalculate)
      ) {
        const validation = `${nullYear[0].workingYearCount}년차 연차 지정 개수를 입력해주세요.`;
        setState((prev) => ({
          ...prev,
          validation,
        }));
        return;
      }
    } else if (
      state.countsYearList.slice(1).some((a) => a.fixedCounts === 0) ||
      state.countsYearList
        .slice(0, 1)
        .some((a) => a.fixedCounts === 0 && !a.isAutoCalculate)
    ) {
      if (
        state.countsYearList
          .slice(0, 1)
          .some((a) => a.fixedCounts === 0 && !a.isAutoCalculate)
      )
        nullYear = state.countsYearList
          .slice(0, 1)
          .filter((a) => a.fixedCounts === 0 && !a.isAutoCalculate);
      if (state.countsYearList.slice(1).some((a) => a.fixedCounts === 0))
        nullYear = [
          ...nullYear,
          ...state.countsYearList.slice(1).filter((a) => a.fixedCounts === 0),
        ];
      const validation =
        nullYear[0].workingYearCount === 1
          ? `1년미만 연차 지정 개수를 입력해주세요.`
          : `${
              nullYear[0].workingYearCount - 1
            }년차 연차 지정 개수를 입력해주세요.`;
      setState((prev) => ({
        ...prev,
        validation,
      }));
      return;
    }
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  /** 스낵바 닫기. */
  const handleSnackbarClose = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  const { validation, saveing, change, useLeaveExcess, decimalHandlingType } =
    state;

  /** 연차 소수점 처리 구분 변경. */
  const handleChangeDecimalHandlingType = (value: string) => {
    if (isExists) {
      setState((prev) => ({
        ...prev,
        validation: '연차 생성 후 기본 설정 변경은 불가능합니다.',
      }));
      return;
    }

    setState((preState) => ({
      ...preState,
      change: true,
      cancel: false,
      decimalHandlingType: value,
    }));
  };

  /** 연차 초과 사용여부 변경 */
  const handleChangeUseLeaveExcess = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isExists) {
      setState((prev) => ({
        ...prev,
        validation: '연차 생성 후 기본 설정 변경은 불가능합니다.',
      }));
      return;
    }

    setState((preState) => ({
      ...preState,
      change: true,
      cancel: false,
      useLeaveExcess: event.target.checked,
    }));
  };

  /** 회계연도 시작일 변경 */
  const handleChangeCustomStartDate = (date: Date | null) => {
    let endDate = date;
    if (date !== null) {
      endDate = moment()
        .set('year', date.getFullYear() + 1)
        .set('month', date.getMonth())
        .set('date', date.getDate())
        .subtract(1, 'days')
        .toDate();
    }

    setState((prevState) => ({
      ...prevState,
      change: true,
      cancel: false,
      startDate: date,
      endDate,
    }));
  };

  /** 회계연도 종료일 변경 */
  const handleChangeCustomEndDate = (date: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      cancel: false,
      endDate: date,
    }));
  };

  /** 근속연수별 연차설정 변경 */
  const handleDayOffCountChange = (arg: {
    data: {
      workingYearCount: number;
      isAutoCalculate: boolean;
      fixedCounts: number | null;
      updateAt: string | null;
      lookupDeleteAt?: string;
    }[];
  }) => {
    const countsYearList = arg.data.map((a) => ({
      ...a,
      fixedCounts: a.fixedCounts === null ? 0 : a.fixedCounts,
    }));
    setState((prevState) => ({
      ...prevState,
      change: true,
      cancel: false,
      countsYearList,
    }));
  };

  const handleNext = (type: number) => {
    const initialType = type === 1 ? 'enter' : 'account';
    dispatch(
      attendancePreferencesActions.findInitialStandard({ type: initialType }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const payload = result.payload as AttendanceStandardDate;
        setState((prev) => ({
          ...prev,
          initialPage: false,
          annualGenerationStandardType: type,
          countsYearList: payload.countsYearList,
          decimalHandlingType: payload.decimalHandlingType.toString(), // 소수점 처리 구분
          useLeaveExcess: payload.useLeaveExcess, // 연차초과사용 사용여부
          startDate:
            payload.startDate !== null && payload.startDate !== ''
              ? setDateFormat(payload.startDate)
              : null, // 회계연도 시작일
          endDate:
            payload.endDate !== null && payload.endDate !== ''
              ? setDateFormat(payload.endDate)
              : null, // 회계연도 종료일
        }));
      }
    });
  };

  /** 취소 이벤트. */
  const handleCancel = () => {
    setState(initialState);
    setState((prev) => ({
      ...prev,
      cancel: true,
      initialPage: !isExists,
    }));
  };

  /** 연차 생성 전 대화상자 취소 이벤트. */
  const handleSaveClickCancel = () => {
    setState((prev) => ({
      ...prev,
      isSaveClick: false,
    }));
  };

  const renderCountSetting = () => {
    if (isEnterDate)
      return (
        <AttendanceEnteringDayOffCountSetting
          isExists={isExists}
          data={standard.countsYearList}
          onChange={handleDayOffCountChange}
          onCancel={state.cancel}
        />
      );
    return (
      <AttendanceAccountDayOffCountSetting
        isExists={isExists}
        data={standard.countsYearList}
        onChange={handleDayOffCountChange}
        onCancel={state.cancel}
      />
    );
  };

  const renderDialog = () => {
    if (state.isSaveClick)
      return (
        <Confirmation
          noDuplication={state.validation === ''}
          onSubmit={handleSave}
          onCancel={state.saveing ? undefined : handleSaveClickCancel}
        >
          <>
            <p>
              연차 생성 후 기본 설정 변경은 불가능합니다.
              <br />전 사원의 입사 일자가 입력되어 있는지 확인 후 진행해 주시길
              바랍니다.
            </p>
            <p>연차를 생성하시겠습니까?</p>
          </>
        </Confirmation>
      );
    return null;
  };

  if (state.initialPage)
    return <AttendanceDayOffSettingStartContainer onNext={handleNext} />;

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        {isAccount && (
          <EuiSetting.Item title="회계연도 시작일">
            <div
              style={{
                display: 'inline-flex',
                width: '220px',
                marginRight: '8px',
              }}
            >
              <div style={{ marginRight: '4px' }}>
                <CustomDatePicker
                  readOnly={isExists}
                  onInputClick={
                    isExists
                      ? () =>
                          setState((prev) => ({
                            ...prev,
                            validation:
                              '연차 생성 후 기본 설정 변경은 불가능합니다.',
                          }))
                      : undefined
                  }
                  selected={state.startDate}
                  onChange={handleChangeCustomStartDate}
                  dateFormat="MM-dd"
                  width={80}
                />
              </div>
              <CustomDatePicker
                readOnly={isExists}
                onInputClick={
                  isExists
                    ? () =>
                        setState((prev) => ({
                          ...prev,
                          validation:
                            '연차 생성 후 기본 설정 변경은 불가능합니다.',
                        }))
                    : undefined
                }
                selected={state.endDate}
                onChange={handleChangeCustomEndDate}
                dateFormat="MM-dd"
                width={80}
              />
            </div>
          </EuiSetting.Item>
        )}
        {renderCountSetting()}
        {isAccount && (
          <EuiSetting.Item title="연차 소수점 처리 구분">
            <SelectField
              data={[
                { value: '0', label: '내림' },
                { value: '1', label: '반올림' },
                { value: '2', label: '올림' },
              ]}
              value={decimalHandlingType}
              onChange={handleChangeDecimalHandlingType}
            />
          </EuiSetting.Item>
        )}
        <EuiSetting.Item title="연차 초과사용">
          <Switch
            checked={useLeaveExcess}
            onChange={handleChangeUseLeaveExcess}
          />
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {!saveing && (!isExists || change) && (
              <Button text="취소" onClick={handleCancel} />
            )}
            {(!isExists || change) && (
              <Button
                text={!isExists ? '생성' : '저장'}
                variant="contained"
                loading={saveing}
                onClick={isExists ? handleSave : handleSaveBefore}
              />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
        <FeedBack text={validation} onClose={handleSnackbarClose} />
        {renderDialog()}
      </EuiBody>
    </>
  );
}

export default AttendanceDayOffSettingStandardContainer;
