import { useEffect } from 'react';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { boardActions } from '../../../../stores/board';
import { folderBoxActions } from '../../../../stores/folder';

function BoardComposeRoute(props: { pathname: string; search: string }): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  const { p1, p2 } = getPathParams<{ p1?: string; p2?: number }>(
    '/*/:p1/:p2$base62',
    pathname,
  );
  const isFixedFolder = p1 === 'all' || p1 === 'temp' || p1 === 'importance';
  const queryParams = getQueryParams(search);
  const route = { pathname, search };
  useEffect(() => {
    let mount = true;

    if (mount) {
      // 작성 또는 임시보관 작성인 경우.
      if (queryParams.contentMode === 'create') {
        // 임시보관
        if (queryParams.contentType === 'temp' && p2) {
          dispatch(boardActions.findView({ id: p2 })).then((result) => {
            if ((result as { error?: string }).error === undefined) {
              const { folderId } = result.payload as { folderId: number };
              if (folderId !== 0)
                dispatch(folderBoxActions.folderView({ folderId, route }));
              else {
                dispatch(folderBoxActions.clearFolderView());
                dispatch(sessionActions.setRoute(route));
              }
            }
          });
        } else {
          dispatch(boardActions.viewClear());
          if (!p1 || isFixedFolder) {
            dispatch(folderBoxActions.clearFolderView());
            dispatch(sessionActions.setRoute({ pathname, search }));
          } else {
            dispatch(folderBoxActions.folderView({ folderId: b62(p1), route }));
          }
        }
      }
      // 수정인 경우.
      else if (queryParams.contentMode === 'update') {
        if (p1 && p2) {
          dispatch(boardActions.findView({ id: p2, route }));
          if (!isFixedFolder)
            dispatch(folderBoxActions.folderView({ folderId: b62(p1) }));
          else dispatch(folderBoxActions.clearFolderView());
        }
      }
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default BoardComposeRoute;
