import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
// import dummy from './dummy';

// const { folders } = dummy;

async function findAllFolder(arg: { organizationId: number }): Promise<
  {
    // companyId: number;
    organizationId: number;
    parentId: number;
    id: number;
    seq: number;
    name: string;
    description: string;
    // updaterId: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  // return new Promise(() => folders.filter((a) => a.organizationId === organizationId));
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function appendFolder(arg: {
  organizationId: number;
  parentId: number;
  name: string;
  description: string;
}): Promise<{
  // companyId: number;
  organizationId: number;
  parentId: number;
  id: number;
  seq: number;
  name: string;
  description: string;
  updaterId: number;
  createAt: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function modifyFolder(arg: {
  organizationId: number;
  id: number;
  parentId: number;
  name: string;
  description: string;
  updateAt: string;
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function removeFolder(arg: {
  organizationId: number;
  id: number;
  updateAt: string;
}): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function changeFolderSequence(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    id: number;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/archive/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const archiveApi = {};

export default archiveApi;

export const archiveFolderApi = {
  findAll: findAllFolder,
  append: appendFolder,
  modify: modifyFolder,
  remove: removeFolder,
  change: changeFolderSequence,
};
