import React from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import Tab from '../../../../../components/tab/Tab';
import AttendanceSettingBasicContainer from './AttendanceSettingBasicContainer';
import AttendanceSettingCodeContainer from './AttendanceSettingCodeContainer';

function AttendanceSettingContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6001)?.name ?? '';

  /** 탭 클릭 이벤트. */
  const handleMenuChange = (submenu?: string) => {
    if (submenu === hash) return;

    if (submenu === undefined) dispatch(sessionActions.hash(submenu));
    if (submenu === '#code') {
      const route = { pathname, hash: submenu };
      dispatch(attendancePreferencesActions.attendanceCodeList({ route }));
    }
  };

  const renderContent = () => {
    if (hash === '') {
      return <AttendanceSettingBasicContainer />;
    }
    return (
      <AttendanceSettingCodeContainer
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <Tab>
          <Tab.Item
            label="기본설정"
            selected={hash === ''}
            onClick={() => handleMenuChange()}
          />
          <Tab.Item
            label="근태코드"
            selected={hash === '#code'}
            onClick={() => handleMenuChange('#code')}
          />
        </Tab>
      </EuiHeader>
      {renderContent()}
    </>
  );
}

export default AttendanceSettingContainer;
