import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';

import { companyActions } from '../../../../stores/directory/company';
import { organizationActions } from '../../../../stores/directory/organization';
import { employeeActions } from '../../../../stores/directory/employee';
import { jobPositionActions } from '../../../../stores/directory/jobposition';
import { jobDutyActions } from '../../../../stores/directory/jobduty';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function OrganizationChartRoute({ pathname }: { pathname: string }): null {
  const display = useSelector((s: RootState) => s.session.display);

  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(companyActions.findList()),
      dispatch(organizationActions.findList()),
      dispatch(organizationActions.findEmployee({ id: 'all' })),
      dispatch(employeeActions.findList()),
      dispatch(jobPositionActions.findList()),
      dispatch(jobDutyActions.findList()),
    ]).then(() => {
      if (display === 'phone') dispatch(sessionActions.setRoute({ pathname }));
      else {
        const route = { pathname: `${pathname}/{response_id}` };
        dispatch(companyActions.findView({ id: 'first', route }));
      }
    });
  }, [dispatch, pathname]);

  return null;
}

export default OrganizationChartRoute;
