import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import HelperText from '../../../../../components/alert/HelperText';
import TextField from '../../../../../components/textfield/TextField';
import Divider from '../../../../../components/divider/Divider';

function ComponentHelperTextBasic(): JSX.Element {
  return (
    <>
      <ComponentSampleUI.Content title="글쓰기">
        <div>
          <TextField value="value" />
          <HelperText text="문구입니다." />
          <Divider />
          <TextField value="value" error />
          <HelperText>오류 메시지</HelperText>
        </div>
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentHelperTextBasic;
