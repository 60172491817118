import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';
import Menu from '../../../../../components/menu/Menu';
import MenuItem from '../../../../../components/menu/MenuItem';
import MenuDivider from '../../../../../components/menu/MenuDivider';

function ComponentMenuChecked(): JSX.Element {
  const [state, setState] = useState<{
    visible: boolean;
    point: { x: number; y: number; width: number; height: number };
    class: { id: number; label: string; checked: boolean }[];
    option: boolean;
  }>({
    visible: false,
    point: { x: 0, y: 0, width: 0, height: 0 },
    class: [
      { id: 1, label: '직위로 표시', checked: true },
      { id: 2, label: '직책으로 표시', checked: false },
      { id: 3, label: '직위/직책으로 표시', checked: false },
    ],
    option: false,
  });

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.visible) handleCloseMenu();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        visible: true,
        point: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  const handleCloseMenu = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleChangeClass = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      class: prevState.class.map((x) => ({ ...x, checked: x.id === id })),
    }));
  };

  const handleChangeOption = () => {
    setState((prevState) => ({
      ...prevState,
      option: !prevState.option,
    }));
  };

  return (
    <ComponentSampleUI.Content title="Checked">
      <Button text="액션" onClick={handleOpenMenu} />
      {state.visible && (
        <Menu point={state.point} onClose={handleCloseMenu}>
          {state.class.map((x) => (
            <MenuItem
              key={x.id}
              label={x.label}
              checked={x.checked}
              onClick={() => handleChangeClass(x.id)}
            />
          ))}
          <MenuDivider />
          <MenuItem
            label={state.option ? '선택 해제' : '선택으로 지정'}
            onClick={handleChangeOption}
          />
        </Menu>
      )}
    </ComponentSampleUI.Content>
  );
}

export default ComponentMenuChecked;
