import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import CreatePortal from '../../../../components/createPortal/CreatePortal';

function LoadingContainer(): JSX.Element | null {
  const text = useSelector((state: RootState) => state.session.loading.text);

  const [status, setStatus] = useState<'mount' | 'unmount'>('unmount');

  useEffect(() => {
    if (text !== '') setStatus('mount');
  }, [text]);

  const handleTransitionEnd = () => {
    if (text === '') setStatus('unmount');
  };

  return status === 'unmount' ? null : (
    <CreatePortal>
      <div className="ui-page-loading">
        <div
          className={`bar ${text === '' ? 'end' : ''}`}
          onAnimationEnd={handleTransitionEnd}
        />
      </div>
    </CreatePortal>
  );
}

export default React.memo(LoadingContainer);
