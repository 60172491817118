import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPathParams } from '../../groupware-common/utils';
import AttendanceDrawer from '../pages/root/attendance/common/AttendanceDrawer';

function AttendanceLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`AttendanceLocateDrawer`);

  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { p1 } = getPathParams('/*/:p1', pathname);

  return (
    <AttendanceDrawer key={i18n.language} folderId={p1} pathname={pathname} />
  );
}

export default AttendanceLocateDrawer;
