import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

interface ApprovalPreferencesBasic {
  companyId: number; // 회사 아이디.
  logoPath: string; // 회사 로고 이미지 경로.
  optionsApproval: number; // 결재옵션. - 1:전단계 반려, 2:결재취소, 4:후결, 8:대결
  optionsConsensusReturn: number; // 합의시 반려처리 옵션. - 0:반려불가, 1:반려가능
  usePass: boolean; // 결재패스 사용여부. - 0:사용안함, 1:사용함
  optionsDocumentInformation: number; // 문서정보 옵션. - 1:상신시점에 모든결재선상의 사용자에게 결재문서 보여줌, 2:열람,미열람 표시, 3:둘 다 사용
  optionsReceiveOpinion: number; // 수발신 결재의견 옵션. - 1:결재내용 하단에 결재의견 출력, 2:수신부서의 결재의견 기안부서에 공개, 3:둘 다 사용
  useComment: boolean; // 결재댓글 사용여부. - 0:사용안함, 1:사용함
  optionsComment: number; // 결재댓글 옵션. - 0:수정/삭제 사용안함, 1:수정/삭제 사용함
  optionsInnerApproval: number; // 접수 후 내부결재 반려 옵션. - 0:반려시 접수자의 반려함으로 반려, 1:최초기안자의 반려함으로 반려
  useSign: boolean; // 결재란에 서명 사용여부. - 0:사용안함, 1:사용함
  useSubDepartmentView: boolean; // 하위부서 기록물철 조회 사용여부. - 0:사용안함, 1:사용함
  useAfterApproval: boolean; // 사후결재 사용여부. - 0:사용안함, 1:사용함
  useAuditor: boolean; // 감사자 사용여부. - 0:사용안함, 1:사용함
  optionsMailView: number; // 메일 보내기 노출 옵션. - 1:결재진행중 노출, 2:결재완료 노출, 3:둘 다 노출
  usePublic: boolean; // 기본 문서공개 사용여부. - 0:비공개, 1:공개
  allocableCapacity: number; // 할당 가능 용량. (byte)
  capacityInUse: number; // 사용 중인 용량. (byte)
  capacityLimit: number; // 용량 한도. (byte)
  numberOfAttachments: number; // 첨부 개수. (기본값 10 개)
  attachmentsCapacity: number; // 첨부 용량. (byte)
  updateAt: string; // 수정 날짜.
}
async function selectBasic(): Promise<ApprovalPreferencesBasic> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/preferences`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updateBasic(arg: {
  logoPath?: string; // 회사 로고 이미지 경로.
  optionsApproval: number; // 결재옵션. - 1:전단계 반려, 2:결재취소, 4:후결, 8:대결
  optionsConsensusReturn: number; // 합의시 반려처리 옵션. - 0:반려불가, 1:반려가능
  usePass: boolean; // 결재패스 사용여부. - 0:사용안함, 1:사용함
  optionsDocumentInformation: number; // 문서정보 옵션. - 1:상신시점에 모든결재선상의 사용자에게 결재문서 보여줌, 2:열람,미열람 표시, 3:둘 다 사용
  optionsReceiveOpinion: number; // 수발신 결재의견 옵션. - 1:결재내용 하단에 결재의견 출력, 2:수신부서의 결재의견 기안부서에 공개, 3:둘 다 사용
  useComment: boolean; // 결재댓글 사용여부. - 0:사용안함, 1:사용함
  optionsComment: number; // 결재댓글 옵션. - 0:수정/삭제 사용안함, 1:수정/삭제 사용함
  optionsInnerApproval: number; // 접수 후 내부결재 반려 옵션. - 0:반려시 접수자의 반려함으로 반려, 1:최초기안자의 반려함으로 반려
  useSign: boolean; // 결재란에 서명 사용여부. - 0:사용안함, 1:사용함
  useSubDepartmentView: boolean; // 하위부서 기록물철 조회 사용여부. - 0:사용안함, 1:사용함
  useAfterApproval: boolean; // 사후결재 사용여부. - 0:사용안함, 1:사용함
  useAuditor: boolean; // 감사자 사용여부. - 0:사용안함, 1:사용함
  optionsMailView: number; // 메일 보내기 노출 옵션. - 1:결재진행중 노출, 2:결재완료 노출, 3:둘 다 노출
  usePublic: boolean; // 기본 문서공개 사용여부. - 0:비공개, 1:공개
  capacityLimit: number; // 용량 한도. (byte)
  numberOfAttachments: number; // 첨부 개수.
  attachmentsCapacity: number; // 첨부 용량. (byte)
  updateAt: string; // 수정 날짜.
}): Promise<{
  companyId: number; // 회사 아이디.
  logoPath: string; // 회사 로고 이미지 경로.
  optionsApproval: number; // 결재옵션. - 1:전단계 반려, 2:결재취소, 4:후결, 8:대결
  optionsConsensusReturn: number; // 합의시 반려처리 옵션. - 0:반려불가, 1:반려가능
  usePass: boolean; // 결재패스 사용여부. - 0:사용안함, 1:사용함
  optionsDocumentInformation: number; // 문서정보 옵션. - 1:상신시점에 모든결재선상의 사용자에게 결재문서 보여줌, 2:열람,미열람 표시, 3:둘 다 사용
  optionsReceiveOpinion: number; // 수발신 결재의견 옵션. - 1:결재내용 하단에 결재의견 출력, 2:수신부서의 결재의견 기안부서에 공개, 3:둘 다 사용
  useComment: boolean; // 결재댓글 사용여부. - 0:사용안함, 1:사용함
  optionsComment: number; // 결재댓글 옵션. - 0:수정/삭제 사용안함, 1:수정/삭제 사용함
  optionsInnerApproval: number; // 접수 후 내부결재 반려 옵션. - 0:반려시 접수자의 반려함으로 반려, 1:최초기안자의 반려함으로 반려
  useSign: boolean; // 결재란에 서명 사용여부. - 0:사용안함, 1:사용함
  useSubDepartmentView: boolean; // 하위부서 기록물철 조회 사용여부. - 0:사용안함, 1:사용함
  useAfterApproval: boolean; // 사후결재 사용여부. - 0:사용안함, 1:사용함
  useAuditor: boolean; // 감사자 사용여부. - 0:사용안함, 1:사용함
  optionsMailView: number; // 메일 보내기 노출 옵션. - 1:결재진행중 노출, 2:결재완료 노출, 3:둘 다 노출
  usePublic: boolean; // 기본 문서공개 사용여부. - 0:비공개, 1:공개
  updateAt: string; // 수정 날짜.
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/preferences`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function selectDocumentNo(): Promise<{
  numberingTime: number; // 채번 시점 - 1: 상신, 2: 완료
  numberingMethod: number; // 채번 방식 - 1: 양식 & 부서별 일련번호, 2: 전사 공통 일련번호, 3: 양식별 일련번호, 4: 부서별 일련번호
  useTopLevelOrganization: boolean; // 최상위 조직 이름 사용 여부 - 1: 사용, 0: 사용안함
  numberingReset: number; // 채번 초기화 - 1: 문서번호에 년도 사용시 매년 문서 일련번호 초기화, 2: 문서번호에 월 사용시 매월 문서 일련번호 초기화
  syncIncomingAndOutgoing: boolean; // 수신 및 발신 동기화 - 1: 사용, 0: 사용안함
  updateAt: string;
}> {
  // return {
  //   numberingTime: 1,  // 채번 시점 - 1: 상신, 2: 완료
  //   numberingMethod: 1,  // 채번 방식 - 1: 양식 & 부서별 일련번호, 2: 전사 공통 일련번호, 3: 양식별 일련번호, 4: 부서별 일련번호
  //   useTopLevelOrganization: false, // 최상위 조직 이름 사용 여부 - 1: 사용, 0: 사용안함
  //   numberingReset: 1, // 채번 초기화 - 1: 문서번호에 년도 사용시 매년 문서 일련번호 초기화, 2: 문서번호에 월 사용시 매월 문서 일련번호 초기화
  //   syncIncomingAndOutgoing: true,
  //   updateAt: '1000-01-01'
  // }
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/preferences/documentno`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function updateDocumentNo(arg: {
  numberingTime: number; // 채번 시점 - 1: 상신, 2: 완료
  numberingMethod: number; // 채번 방식 - 1: 양식 & 부서별 일련번호, 2: 전사 공통 일련번호, 3: 양식별 일련번호, 4: 부서별 일련번호
  useTopLevelOrganization: boolean; // 최상위 조직 이름 사용 여부 - 1: 사용, 0: 사용안함
  numberingReset: number; // 채번 초기화 - 1: 문서번호에 년도 사용시 매년 문서 일련번호 초기화, 2: 문서번호에 월 사용시 매월 문서 일련번호 초기화
  syncIncomingAndOutgoing: boolean; // 수신 및 발신 동기화 - 1: 사용, 0: 사용안함
  updateAt: string;
}): Promise<{
  companyId: number;
  updateAt: string;
}> {
  // return {
  //   companyId: 5001,
  //   updateAt: '1000-01-01'
  // }
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/preferences/documentno`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function selectApprovalType(): Promise<string[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/preferences/approvalgroup/all`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const preferencesApi = {
  selectBasic,
  updateBasic,
  selectDocumentNo,
  updateDocumentNo,

  selectApprovalType,
};

export default preferencesApi;
