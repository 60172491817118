import React, { useEffect, useRef, useState } from 'react';
import EmptyData from '../../../../../components/data/EmptyData';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import DrawerList from '../../../../../components/drawer/DrawerList';
import DrawerToolbar from '../../../../../components/drawer/DrawerToolbar';
import Loading from '../../../../../components/loading/Loading';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import Search from '../../../../../components/search/Search';
import UserInfo from '../../../../../components/user/UserInfo';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import documentApi from '../../../../apis/document/v1/document';

function DocumentViewerDrawer(props: {
  type: 'viewer' | 'likes';
  title: string;
  viewId: number;
  onClose(): void;
}) {
  const { viewId, onClose } = props;
  const scrollbar = useRef<HTMLDivElement>(null);
  const directory = useDirectory();

  const [state, setState] = useState<{
    loading: boolean;
    keyword: string;
    pageNo: number;
    list: {
      id: number;
      employeeName: string;
      employeeJobClassName: string;
      employeeOrganizationName: string;
      employeeAvatar: string;
      createAt: string;
      updateAt?: string;
    }[];
    totalCount: number;
  }>({
    loading: true,
    keyword: '',
    pageNo: 1,
    list: [],
    totalCount: 0,
  });

  const { type } = props;

  useEffect(() => {
    async function run() {
      const list =
        type === 'viewer'
          ? await documentApi.findViewerList({
              postId: viewId,
              viewer: state.keyword,
              pageNo: state.pageNo,
              rowsPerPage: 15,
            })
          : await documentApi.findLikeList({
              postId: viewId,
              viewer: state.keyword,
              pageNo: state.pageNo,
              rowsPerPage: 15,
            });

      const totalCount =
        type === 'viewer'
          ? await documentApi.viewerTotalCount({
              postId: viewId,
              viewer: state.keyword,
            })
          : await documentApi.likeTotalCount({
              postId: viewId,
              viewer: state.keyword,
            });

      setState((prevState) => ({
        ...prevState,
        loading: false,
        list: list.map((a) => {
          const directoryData = getDirectoryData({
            ...directory,
            companyId: a.employeeCompanyId,
            employeeId: a.employeeId,
          });

          return {
            id: directoryData.employeeId,
            employeeName: directoryData.employeeName,
            employeeJobClassName: directoryData.jobClassName,
            employeeOrganizationName: directoryData.organizationName,
            employeeAvatar: directoryData.avatar ?? '',
            createAt: a.createAt,
            updateAt: type === 'viewer' ? a.updateAt : undefined,
          };
        }),
        totalCount,
      }));
    }
    run();
  }, [type, state.pageNo, state.keyword]);

  /** 이전 페이지 */
  const handlePrev = async () => {
    const pageNo = state.pageNo - 1;
    if (pageNo > 0) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        pageNo,
      }));
      scrollbar.current?.scrollTo(0, 0);
    }
  };

  /** 다음 페이지 */
  const handleNext = async () => {
    const pageNo = state.pageNo + 1;
    setState((prevState) => ({
      ...prevState,
      loading: false,
      pageNo,
    }));
    scrollbar.current?.scrollTo(0, 0);
  };

  /** 검색하기 */
  const handleSearch = async (arg: { keyword: string }) => {
    setState((prevState) => ({
      ...prevState,
      keyword: arg.keyword,
      pageNo: 1,
      loading: false,
    }));
    scrollbar.current?.scrollTo(0, 0);
  };

  const { loading, keyword, pageNo, list, totalCount } = state;

  return (
    <Drawer title={props.title} size="xs" onClose={onClose}>
      <Search
        className="drawer-search"
        keyword={keyword}
        onSearch={handleSearch}
        full
      />
      <DrawerToolbar>
        <DrawerToolbar.Left>
          <span className="selected-count">
            <em>{totalCount}</em> <span>명</span>
          </span>
        </DrawerToolbar.Left>
        <DrawerToolbar.Right>
          {totalCount ? (
            <Pagination
              no={pageNo}
              rows={15}
              count={totalCount}
              onPrev={handlePrev}
              onNext={handleNext}
            />
          ) : null}
        </DrawerToolbar.Right>
      </DrawerToolbar>
      <DrawerBody scrollbar={scrollbar}>
        <DrawerList>
          {loading && <Loading />}
          {list.length > 0 ? (
            list.map((a) => {
              return (
                <DrawerList.Item key={a.id}>
                  <div className="ui-viewer">
                    <UserInfo
                      avatar={a.employeeAvatar}
                      name={`${a.employeeName} ${a.employeeJobClassName}`}
                      from={a.employeeOrganizationName}
                    />
                    {type === 'viewer' && (
                      <>
                        <dl className="time">
                          <dt>최초</dt>
                          <dd>{dateSimplify(a.createAt, 'fullNumber')}</dd>
                        </dl>
                        <dl className="time">
                          <dt>최종</dt>
                          <dd>
                            {a.updateAt
                              ? dateSimplify(a.updateAt, 'fullNumber')
                              : ''}
                          </dd>
                        </dl>
                      </>
                    )}
                    {type === 'likes' && (
                      <dl className="time">
                        <dt>추천일</dt>
                        <dd>{dateSimplify(a.createAt, 'fullNumber')}</dd>
                      </dl>
                    )}
                  </div>
                </DrawerList.Item>
              );
            })
          ) : (
            <EmptyData />
          )}
        </DrawerList>
      </DrawerBody>
    </Drawer>
  );
}

export default React.memo(DocumentViewerDrawer);
