import React, { useEffect, useState } from 'react';
import { contrastColor } from '../../groupware-common/utils/ui';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';

function Avatar(props: {
  name: string;
  image?: string;
  icon?: IconType;
  color?: string;
  className?: string;
  shape?: 'square' | 'rounded' | 'circle';
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}): JSX.Element {
  const [imageError, setImageError] = useState(false);

  useEffect(() => setImageError(false), [props.image]);

  const { name, image, icon, color, className, shape, onClick } = props;
  let classname = 'eui-avatar';
  if (className) classname += ` ${className}`;
  if (!image) classname += ' icon';
  if (icon) classname += ' icon';
  if (imageError) classname += ' icon';
  if (shape) classname += ` ${shape}`;

  const style: React.CSSProperties | undefined =
    color !== undefined
      ? { backgroundColor: color, color: contrastColor(color) }
      : undefined;

  let content;
  if (image && imageError === false)
    content = (
      <img src={image} alt={name} onError={() => setImageError(true)} />
    );
  else if (icon) content = <Icon icon={icon} />;
  else content = <Icon icon="person" />;

  return (
    <div className={classname} title={name} style={style} onClick={onClick}>
      {content}
    </div>
  );
}

export default Avatar;
