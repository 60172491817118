import React from 'react';
import CalendarFolderBoxRoute from '../pages/adminconsole/folderbox/CalendarFolderBoxRoute';
import { getPathParams } from '../../groupware-common/utils';
import CalendarPreferencesRoute from '../pages/adminconsole/preferences/CalendarPreferencesRoute';
import CalendarSubFolderRoute from '../pages/adminconsole/subFolder/CalendarSubFolderRoute';

function AdminConsoleLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 구독 캘린더
  if (menu === 'sub')
    return <CalendarSubFolderRoute pathname={pathname} search={search} />;
  // 공유 캘린더
  if (menu === 'all')
    return <CalendarFolderBoxRoute pathname={pathname} search={search} />;
  // 기본 설정
  return <CalendarPreferencesRoute pathname={pathname} search={search} />;
}

export default AdminConsoleLocateRoute;
