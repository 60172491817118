import axios from 'axios';
import {
  apiError,
  EntityIdKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { LabelItem } from '../../../../stores/labels';

/** 라벨 리스트 조회 */
async function findList(companyId: number): Promise<LabelItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/labels`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 라벨 생성 */
async function createLabel(arg: {
  companyId: number;
  name: string;
}): Promise<EntityIdKeyable> {
  try {
    const { companyId, name } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${companyId}/labels`;
    const response = await axios.post(url, { name }, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 라벨 수정 */
async function updateLabel(arg: {
  companyId: number;
  id: number;
  name: string;
  updateAt: string;
}): Promise<EntityIdKeyable> {
  try {
    const data = {
      id: arg.id,
      name: arg.name,
      updateAt: arg.updateAt,
    };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${arg.companyId}/labels/${arg.id}`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 라벨 삭제 */
async function deleteLabel(arg: {
  companyId: number;
  id: number;
  removeContacts: boolean; // 연락처 삭제 여부.
  updateAt: string;
}): Promise<EntityIdKeyable> {
  try {
    const params = { removeContacts: arg.removeContacts };
    const data = {
      id: arg.id,
      updateAt: arg.updateAt,
    };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/contacts/v1/companies/${arg.companyId}/labels/${arg.id}`;
    const response = await axios.delete(url, { headers, params, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const labelsApi = {
  list: findList,
  create: createLabel,
  update: updateLabel,
  delete: deleteLabel,
};

export default labelsApi;
