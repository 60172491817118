import React from 'react';

function CheckboxGroup(props: {
  className?: string;
  legend?: string;
  type?: 'button' | 'simple';
  column?: boolean;
  block?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-check-group';
  if (props.className) classname += ` ${props.className}`;
  if (props.column) classname += ' column';
  if (props.type) classname += ` ${props.type}-type`;
  if (props.block) classname += ' block';

  return (
    <div className={classname}>
      {props.legend && <legend className="legend">{props.legend}</legend>}
      <div className="group">{props.children}</div>
    </div>
  );
}

export default CheckboxGroup;
