import React from 'react';
import { Tooltip as CustomTooltip } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';

function Tooltip(props: TooltipProps): JSX.Element {
  const { ...params } = props;
  params.enterDelay = params.enterDelay === undefined ? 500 : params.enterDelay;
  params.classes = {
    popper: 'eui-tooltip',
    tooltip: 'tooltip-content',
    arrow: 'tooltip-arrow',
  };
  params.arrow = params.arrow === undefined ? true : params.arrow;
  params.placement = params.placement === undefined ? 'top' : params.placement;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CustomTooltip {...params}>{params.children}</CustomTooltip>;
}

export default Tooltip;
