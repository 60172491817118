import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../groupware-common/apis/common/v1';
import { ModulePermission } from '../../stores/module';

export interface ModuleItemArg {
  module: string;
  seq: number;
  status: boolean;
  updateAt: string;
}

async function findList(arg: { companyId: number }): Promise<ModuleItemArg[]> {
  try {
    const { companyId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// TODO module 타입 변경 string -> ModuleType 커스텀 타입 생성 [HOME, DIRECTORY, APPROVAL, MAIL, ATTENDANCE, BOARD, CALENDAR, DOCUMENT, CONTACTS]
async function findView(arg: {
  companyId: number;
  module: string;
  updateAt?: string;
}): Promise<{
  module: string;
  seq: number;
  status: boolean;
  isInitial: boolean;
  includers: ModulePermission[];
  excluders: ModulePermission[];
  updateAt: string;
}> {
  try {
    const { companyId, module } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules/${module}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function update(arg: {
  companyId: number;
  module: string;
  status: boolean;
  includers?: ModulePermission[];
  excluders?: ModulePermission[];
  updateAt: string;
}): Promise<{
  module: string;
  seq: number;
  status: boolean;
  // isInitial: boolean; // 초기 메뉴 여부
  includers: ModulePermission[];
  excluders: ModulePermission[];
  updateAt: string;
}> {
  try {
    const { companyId, ...data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function sortMenu(arg: {
  companyId: number;
  list: {
    module: string;
    seq: number;
    updateAt: string;
  }[];
}): Promise<
  {
    module: string;
    seq: number;
    updateAt: string;
  }[]
> {
  try {
    const { companyId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/preferences/adminconsole/v1/companies/${companyId}/modules/sequence/all`;
    const response = await axios.put(url, arg.list, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function list(arg: {
  companyId: number;
  employeeId: number;
}): Promise<ModuleItemArg[]> {
  try {
    const { companyId, employeeId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/companies/${companyId}/employees/${employeeId}/modules`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const serviceMenuApi = {
  sortMenu,
  update,
  findList,
  findView,
  list,
};

export default serviceMenuApi;
