import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import FileInput from '../../../../../components/attachments/FileInput';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostWrite from '../../../../../components/post/PostWrite';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import contactFileApi from '../../../../apis/contacts/v1/common';
import contactsApi from '../../../../apis/contacts/v1/contacts';
import { labelActions } from '../../../../stores/labels';
import { contactsActions } from '../../../../stores/contacts';

function ContactImportDialog(props: {
  pathname: string;
  search: string;
  onClose(): void;
}): JSX.Element {
  const { pathname, search, onClose } = props;
  const { folderId } = getPathParams<{
    folderId?: string;
  }>('/*/:folderId', pathname);
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);
  const display = useSelector((state: RootState) => state.session.display);

  const [state, setState] = useState<{
    file?: { id: string; name: string; file: File };
    sampleDownload: boolean;
    upload: boolean;
    validation: string;
  }>({
    sampleDownload: false,
    upload: false,
    validation: '',
  });

  /** 샘플파일 다운로드. */
  const handleSampleDownload = (type: 'simple' | 'detail') => {
    if (type === 'simple')
      contactFileApi
        .simple()
        .then((data) => {
          if (!data)
            throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');
          const blob = new Blob([`\ufeff${data}`], {
            type: 'text/csv;charset=utf-8;',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'contact_simple_example';
          document.body.appendChild(a);
          a.click();
          setTimeout(() => window.URL.revokeObjectURL(url), 3000);
          a.remove();
        })
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    else
      contactFileApi
        .detail()
        .then((data) => {
          if (!data)
            throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');
          const blob = new Blob([`\ufeff${data}`], {
            type: 'text/csv;charset=utf-8;',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `contact_detail_example`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => window.URL.revokeObjectURL(url), 3000);
          a.remove();
        })
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
  };

  /** 파일 등록. */
  const handleChangeFile = (name: string, file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) {
        if (base64.toString().indexOf('data:text/csv') === -1) {
          setState((prev) => ({
            ...prev,
            validation: 'csv 파일의 연락처만 가져올 수 있습니다.',
          }));
          return;
        }
        const data = { id: `${Date.now()}`, name: file.name, file };
        setState((prev) => ({ ...prev, file: data }));
      }
    };

    if (file !== null && file !== undefined) {
      reader.readAsDataURL(file); // 1. 파일을 읽어 버퍼에 저장합니다.
    }
  };

  /** 파일 삭제. */
  const handleRemoveFile = () => {
    setState((prev) => ({ ...prev, file: undefined }));
  };

  /** 샘플 파일 다운로드 대화상자 오픈. */
  const handleSampleDownOpen = () => {
    setState((prev) => ({ ...prev, sampleDownload: true }));
  };

  /** 샘플 파일 다운로드 대화상자 닫기. */
  const handleSampleDownClose = () => {
    setState((prev) => ({ ...prev, sampleDownload: false }));
  };

  /** 연락처 가져오기 이벤트 */
  const handleImportContact = () => {
    if (!state.file)
      throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

    const file = { id: state.file.id, file: state.file.file };
    contactsApi
      .contactImport({ companyId: principal.companyId, data: file })
      .then((result) => {
        if ((result as { error?: string }).error === undefined) {
          dispatch(labelActions.list());
          if (!queryParams.contentMode && (!folderId || folderId === 'all'))
            dispatch(contactsActions.list({ folderId, search }));
          setState((prev) => ({ ...prev, upload: true }));
        }
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  const handleCloseSnackbar = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  const renderDialog = () => {
    if (state.sampleDownload)
      return (
        <Dialog size="xs" onClose={handleSampleDownClose}>
          <DialogHeader>
            <DialogTitle>샘플파일 다운로드</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '24px', margin: 'auto' }}>
                <Button
                  icon="csv-logo"
                  variant="outlined"
                  text="간단샘플"
                  onClick={() => handleSampleDownload('simple')}
                />
                <Button
                  icon="csv-logo"
                  variant="outlined"
                  text="상세샘플"
                  onClick={() => handleSampleDownload('detail')}
                />
              </div>
            </div>
          </DialogBody>
        </Dialog>
      );
    return null;
  };

  if (state.upload)
    return (
      <Dialog size="xs">
        <DialogBody>
          <div className="eui-alert-message eui-confirmation-message">
            연락처 가져오기가 완료되었습니다.
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="contained" text="확인" onClick={onClose} />
        </DialogFooter>
      </Dialog>
    );

  return (
    <Dialog size="sm" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>연락처 가져오기</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <PostWrite.Item>
            <FileInput
              accept=".csv"
              value={state.file ? state.file.name : ''}
              onChange={handleChangeFile}
              onRemove={handleRemoveFile}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button variant="outlined" text="취소" onClick={onClose} />
        <Button
          variant="outlined"
          text={display === 'phone' ? '샘플' : '샘플파일'}
          onClick={handleSampleDownOpen}
        />
        <Button
          disabled={state.file === undefined}
          variant="contained"
          text="가져오기"
          onClick={handleImportContact}
        />
      </DialogFooter>
      <FeedBack text={state.validation} onClose={handleCloseSnackbar} />
      {renderDialog()}
    </Dialog>
  );
}

export default ContactImportDialog;
