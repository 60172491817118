import { useEffect } from 'react';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';

import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { attendanceHolidayActions } from '../../../../stores/attendance/holiday';

function AttendanceHolidayYearRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([dispatch(attendanceHolidayActions.clear())]).then(() => {
      const year = timezoneDate().getFullYear().toString();
      dispatch(
        attendanceHolidayActions.yearList({ year, route: { pathname } }),
      );
    });
  }, [dispatch, pathname]);

  return null;
}

export default AttendanceHolidayYearRoute;
