import React from 'react';
import { IconType } from '../../../groupware-common/types/icon';
import EuiSetting from '../../../components/layout/EuiSetting';
import SimpleSearch from '../../../components/search/SimpleSearch';
import TreeList from '../../../components/tree/TreeList';

type Props = {
  items: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  selectedId?: number;
  filter: string;
  onFilter(filter: string): void;
  onSelect(id: number): void;
};

function SystemLinkWorkContentList(props: Props): JSX.Element {
  // console.log(`${SystemLinkWorkContentList.name}.render(props)`, props);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onFilter(event.target.value);
  };

  const { items, selectedId = NaN, filter, onSelect } = props;

  return (
    <>
      <EuiSetting.Header title="결재업무" />
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilter} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>
        <TreeList
          selectedId={selectedId}
          filter={filter}
          items={items}
          onItemClick={onSelect}
        />
      </EuiSetting.Content>
    </>
  );
}

export default SystemLinkWorkContentList;
