import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import { sessionActions } from '../../groupware-webapp/stores/session';

function AccessDenied(props: { nav?: boolean }): JSX.Element {
  const dispatch = useAppDispatch();
  const display = useSelector((state: RootState) => state.session.display);
  const handleVisibleMobileNav = () => {
    dispatch(sessionActions.mobileNav(true));
  };

  return (
    <div className="eui-access-denied">
      {display !== 'pc' && props.nav && (
        <Button
          text="메뉴"
          iconType
          icon="bars"
          onClick={handleVisibleMobileNav}
          className="ui-mobile-nav-toggle"
        />
      )}
      <p>액세스 권한이 없습니다.</p>
    </div>
  );
}

export default AccessDenied;
