import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EmptyData from '../../../../../components/data/EmptyData';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import { CustomNumbers } from '../../../../../groupware-common/types';
import {
  b62,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../../stores/resources';
import {
  formatUseApprove,
  formatUseRental,
  formatUseTimeAvailable,
} from '../../../adminconsole/folder/ResourceFolderItemContentView';
import ReservationListDialog from './ReservationListDialog';
import ResourceLookupDialog from './ResourceLookupDialog';

function formatSetDate(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

function ResourceDailyContentContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();
  const display = useSelector((state: RootState) => state.session.display);
  const directory = useDirectory();
  /** 직위 */
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );
  const adminBasic = useSelector(
    (state: RootState) => state.resource.preferences.basic,
  );
  /** 해당 자원 폴더의 자원 유형 리스트. */
  let items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  ).filter((a) => a.folderId === b62(p1));
  /** 자원유형 클릭 시 */
  if (p1 && p2) items = items.filter((a) => a.id === b62(p2));

  const itemView = useSelector(
    (state: RootState) => state.resource.folder.items.view,
  );

  /** 예약 리스트 */
  const reservationList = useSelector(
    (state: RootState) => state.resource.resources.list,
  ).map((a) => ({
    ...a,
    startDateTime: dateTimeFormat(a.startDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
    endDateTime: dateTimeFormat(a.endDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
  }));

  const current = timezoneDate(queryParams.startDate);
  const time: { value: string; label: string }[] = [];
  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    time.push({ value: timeValue, label: timeValue });
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }

  const handleClick = (arg: {
    date: string;
    id: number;
    itemId: number;
    itemName: string;
  }) => {
    const { date, id, itemId, itemName } = arg;
    if (id !== 0) {
      const reservation = reservationList
        .filter((a) => a.id === id)
        .find((a) =>
          moment(date).isBetween(
            new Date(a.startDateTime),
            new Date(a.endDateTime),
            'days',
            '[]',
          ),
        );
      if (!reservation) return;
      const query: {
        itemId: number;
        itemName: string;
        start: string;
        end: string;
      } = {
        itemId,
        itemName,
        start: reservation.startDateTime,
        end: reservation.endDateTime,
      };
      queryParams.dialogMode = 'read';
      queryParams.queryWord = JSON.stringify(query);
      dispatch(
        resourcesActions.reservationView({
          id,
          route: {
            pathname,
            search: getQueryParams(queryParams),
            hash,
          },
        }),
      );
    } else {
      const newTime = Number(date.split(':')[0]);
      const minute = Number(date.split(':')[1]);
      const start = new Date(current);
      const end = new Date(current);
      start.setHours(newTime);
      start.setMinutes(minute);
      end.setHours(newTime);
      end.setMinutes(minute + 30);
      const query: {
        itemId: number;
        itemName: string;
        start: string;
        end: string;
      } = {
        itemId,
        itemName,
        start: dateFormat(start, 'yyyy-MM-DD HH:mm'),
        end: dateFormat(end, 'yyyy-MM-DD HH:mm'),
      };
      queryParams.dialogMode = 'create';
      queryParams.queryWord = JSON.stringify(query);
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }
  };

  const handleDateClick = (action: string) => {
    if (action === 'next') current.setDate(current.getDate() + 1);
    if (action === 'nnext') current.setDate(current.getDate() + 7);
    if (action === 'prev') current.setDate(current.getDate() - 1);
    if (action === 'pprev') current.setDate(current.getDate() - 7);
    queryParams.startDate = dateFormat(initialDate(current), 'yyyy-MM-DD');
    go(pathname, queryParams, hash);
  };

  const handleTodayClick = () => {
    delete queryParams.startDate;
    go(pathname, queryParams, hash);
  };

  /** 표로 보기. */
  const handleTableClick = () => {
    dispatch(sessionActions.setDialog({ type: 'reservationList' }));
  };

  /** 예약 저장 */
  const handleSave = (arg: {
    itemId: number;
    employeeId: number;
    name: string;
    remark: string;
    startDateTime: string;
    endDateTime: string;
  }) => {
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(
      resourcesActions.saveReservation({
        data: { ...arg, sharers: [], alarms: [] },
        location,
      }),
    );
  };

  /** 예약 수정 */
  const handleUpdate = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      itemId: number;
      employeeId: number;
      name: string;
      remark?: string;
      startDateTime?: string;
      endDateTime?: string;
      lookupStartDateTime?: string;
      lookupUpdateAt?: string;
      updateAt?: string;
    };
  }) => {
    const { isRepeat, repeatType } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    // 반복 예약 수정
    if (isRepeat) {
      dispatch(
        resourcesActions.updateRepeatReservation({
          type: repeatType,
          data: arg.data,
          location,
        }),
      );
    }
    // 일반 예약 수정
    else {
      const data = {
        ...arg.data,
        updateAt: arg.data.updateAt ?? '',
      };
      dispatch(resourcesActions.updateReservation({ data, location }));
    }
  };

  /** 예약 삭제. */
  const handleDelete = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      startDateTime?: string;
      updateAt: string;
    };
  }) => {
    const { isRepeat, repeatType, data } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    if (isRepeat) {
      dispatch(
        resourcesActions.deleteRepeatReservation({
          data: {
            type: repeatType,
            ...data,
            startDateTime: data.startDateTime ?? '',
          },
          location,
        }),
      );
    } else {
      dispatch(
        resourcesActions.deleteReservation({
          id: data.id,
          updateAt: data.updateAt,
          location,
        }),
      );
    }
  };

  const renderDialog = () => {
    const { dialogMode, dialogType } = queryParams;
    if (dialogMode === 'read') {
      return (
        <ResourceLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      );
    }
    if (dialogMode === 'create') {
      return (
        <ResourceLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onSave={handleSave}
        />
      );
    }
    if (dialogType === 'reservationList') {
      return (
        <ReservationListDialog
          type="daily"
          list={reservationList}
          startDate={dateFormat(current, 'yyyy-MM-DD HH:mm')}
        />
      );
    }

    return null;
  };

  if (items.length === 0) return <EmptyData />;
  return (
    <EuiBody>
      <div style={{ minWidth: '700px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px',
          }}
        >
          <Button text="<<" onClick={() => handleDateClick('pprev')} />
          <Button text="<" onClick={() => handleDateClick('prev')} />
          <div
            className="eui-form-field"
            style={{
              margin: '0 5px',
              background: 'none',
              width: 'auto',
            }}
          >
            <h3 className="control">
              {dateFormat(current, 'yyyy.MM.DD dddd')}
            </h3>
          </div>
          <Button text=">" onClick={() => handleDateClick('next')} />
          <Button text=">>" onClick={() => handleDateClick('nnext')} />

          <div style={{ marginLeft: '10px' }}>
            <Button text="오늘" variant="outlined" onClick={handleTodayClick} />
            <Button
              text="표로 보기"
              variant="outlined"
              onClick={handleTableClick}
            />
          </div>
        </div>
        <div className="resource-content">
          <div className="resource-day-content">
            <div
              className="title"
              style={{
                maxWidth: display !== 'phone' ? '200px' : '150px',
                width: '100%',
              }}
            >
              {dateFormat(current, 'yyyy-MM-DD (dd)')}
            </div>
            <div className="header">
              {time.map((a, i) => {
                const key = `${i}_${a.label}`;
                return (
                  <div key={key} className="timeline">
                    {a.label}
                  </div>
                );
              })}
            </div>
          </div>
          {items.map((item) => {
            const endIndex = bottom.findIndex(
              (a) => a.value === item.availableToTime,
            );
            const itemId = item.id; // 유형 아이디.
            const reservation = !p2
              ? reservationList.filter((a) => a.itemId === item.id)
              : reservationList; // 예약 리스트.
            const title = item.name;

            const child: {
              date: string;
              width: number;
              left: number;
              listId: number;
              isApproval: number;
            }[] = [];
            let startBlock = <></>;
            let endBlock = <></>;
            const today = timezoneDate();
            formatSetDate(today);
            const currentFormat = new Date(current);
            formatSetDate(currentFormat);
            if (today <= currentFormat) {
              // 오늘이면서 지난 날짜 예약 불가능일 경우 - 현재보다 지난 시간은 예약 불가능.
              if (
                moment(today).isSame(currentFormat, 'days') &&
                !adminBasic.isAvailablePastReservation
              ) {
                const availableStart = item.availableFromTime.split(':');
                const minute = timezoneDate().getMinutes() < 30 ? '00' : '30';
                const date = timezoneDate()
                  .getHours()
                  .toString()
                  .padStart(2, '0');
                if (item.availableFromTime !== '00:00') {
                  let index = 0;
                  // 현재 시간이 사용가능 시작시간을 아직 안지난 경우
                  if (
                    timezoneDate().getHours() * 60 +
                      timezoneDate().getMinutes() <=
                    Number(availableStart[0]) * 60 + Number(availableStart[1])
                  ) {
                    index = bottom.findIndex(
                      (a) => a.value === item.availableFromTime,
                    );
                  }
                  // 현재 시간이 사용가능 시작시간을 지난 경우
                  else {
                    index = bottom.findIndex(
                      (a) => a.value === `${date}:${minute}`,
                    );
                  }
                  // 현재 시간이 사용가능 종료시간을 지난 경우
                  if (item.availableToTime !== '00:00' && index > endIndex)
                    index = endIndex;
                  const width = 2.083 * index;
                  startBlock = (
                    <div
                      style={{ width: `${width}%`, left: `0%` }}
                      className="block"
                    />
                  );
                } else {
                  const index = bottom.findIndex(
                    (a) => a.value === `${date}:${minute}`,
                  );
                  const width = 2.083 * index;
                  startBlock = (
                    <div
                      style={{ width: `${width}%`, left: `0%` }}
                      className="block"
                    />
                  );
                }
              }
              // 미래일 경우 또는 지난 날짜 예약 가능일 경우
              else if (item.availableFromTime !== '00:00') {
                const index = bottom.findIndex(
                  (a) => a.value === item.availableFromTime,
                );
                const width = 2.083 * index;
                startBlock = (
                  <div
                    key={`start_${item.id}`}
                    style={{ width: `${width}%`, left: `0%` }}
                    className="block"
                  />
                );
              }

              if (item.availableToTime !== '00:00') {
                const index = bottom.findIndex(
                  (a) => a.value === item.availableToTime,
                );
                const width = 2.084 * (bottom.length - index);
                const left = 2.083 * index;
                endBlock = (
                  <div
                    style={{ width: `${width}%`, left: `${left}%` }}
                    className="block"
                  />
                );
              }
            }
            // 과거이면서 지난 날짜 예약 가능일 경우.
            else if (adminBasic.isAvailablePastReservation) {
              if (item.availableFromTime !== '00:00') {
                const index = bottom.findIndex(
                  (a) => a.value === item.availableFromTime,
                );
                startBlock = (
                  <div
                    style={{ width: `${2.083 * index}%`, left: `0%` }}
                    className="block"
                  />
                );
              }
              if (item.availableToTime !== '00:00') {
                const index = bottom.findIndex(
                  (a) => a.value === item.availableToTime,
                );
                const width = 2.084 * (bottom.length - index);
                const left = 2.083 * index;
                endBlock = (
                  <div
                    style={{ width: `${width}%`, left: `${left}%` }}
                    className="block"
                  />
                );
              }
            }
            // 과거이면서 지난 날짜 예약 불가능인 경우.
            else {
              startBlock = (
                <div
                  style={{ width: `${2.083 * bottom.length}%`, left: `0%` }}
                  className="block"
                />
              );
            }

            return (
              <div
                key={item.id}
                className="resource-day-content"
                style={{ height: '30px', lineHeight: '30px' }}
              >
                <div
                  className="title"
                  style={{
                    minWidth: display !== 'phone' ? '200px' : '150px',
                    fontWeight: 'normal',
                    maxWidth: display !== 'phone' ? '200px' : '150px',
                  }}
                >
                  {title}
                </div>
                <div className="bottom">
                  {bottom.map((a, i) => {
                    reservation.forEach((z) => {
                      const start = new Date(z.startDateTime);
                      const end = new Date(z.endDateTime);
                      const formatStart = dateFormat(start, 'yyyy-MM-DD');
                      const formatEnd = dateFormat(end, 'yyyy-MM-DD');
                      const formatCurrent = dateFormat(current, 'yyyy-MM-DD');
                      // 예약일이 하루이면서 조회 날짜와 동일한 경우.
                      // 예약일이 이틀 이상이면서 예약 시작일이 조회 날짜와 동일한 경우.
                      if (
                        formatStart === formatCurrent &&
                        start.getHours() === Number(a.value.split(':')[0]) &&
                        start.getMinutes() === Number(a.value.split(':')[1])
                      ) {
                        let length = bottom.length - i;
                        if (formatEnd === formatCurrent) {
                          const days =
                            (end.getDate() - start.getDate()) * 24 * 60;
                          const hours =
                            (end.getHours() - start.getHours()) * 60;
                          const minutes =
                            Math.round(
                              (end.getMinutes() - start.getMinutes()) / 10,
                            ) * 10;
                          length = (days + hours + minutes) / 30;
                        }
                        const width = 2.084 * length;
                        const left = 2.083 * i;
                        child.push({
                          date: formatCurrent,
                          width,
                          left,
                          listId: Number(z.id),
                          isApproval: z.isApproval,
                        });
                      }
                      // 예약일이 삼일 이상이면서 조회 날짜가 예약 시작, 종료일 중간인 경우.
                      if (
                        moment(current).isBetween(start, end, 'day') &&
                        a.value === '00:00'
                      ) {
                        const width = 2.084 * bottom.length;
                        child.push({
                          date: formatCurrent,
                          width,
                          left: 0,
                          listId: Number(z.id),
                          isApproval: z.isApproval,
                        });
                      }
                      // 예약일이 이틀 이상이면서 예약 종료일이 조회날짜인 경우.
                      if (
                        formatEnd === formatCurrent &&
                        formatEnd !== formatStart &&
                        a.value === '00:00'
                      ) {
                        const hours = end.getHours() * 60;
                        const minutes = end.getMinutes();
                        const index = (hours + minutes) / 30;
                        const width = 2.084 * index;
                        child.push({
                          date: formatCurrent,
                          width,
                          left: 0,
                          listId: Number(z.id),
                          isApproval: z.isApproval,
                        });
                      }
                    });
                    const key = `${i}_${a.label}`;
                    return (
                      <div
                        key={key}
                        className="timeline"
                        onClick={() =>
                          handleClick({
                            date: a.value,
                            id: 0,
                            itemId,
                            itemName: title,
                          })
                        }
                      />
                    );
                  })}
                  {startBlock}
                  {endBlock}
                  {child.length > 0 &&
                    child.map((a) => {
                      return (
                        <div
                          key={a.listId}
                          style={{
                            width: `${a.width}%`,
                            left: `${a.left}%`,
                            borderLeft: a.left === 0 ? '0' : '1px solid #ddd',
                          }}
                          // eslint-disable-next-line prettier/prettier
                          className={a.isApproval === 1 ? 'selected' : 'selected approval'}
                          onClick={() =>
                            handleClick({
                              date: a.date,
                              id: a.listId,
                              itemId,
                              itemName: title,
                            })
                          }
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
        {p2 && itemView && (
          <>
            <div className="resource-day-information">
              <h3 className="header-title">자원정보</h3>
            </div>
            <EuiSetting.Item title="자원">{itemView.name}</EuiSetting.Item>
            <EuiSetting.Item title="예약방식">
              {formatUseApprove(itemView.useApprove)}
            </EuiSetting.Item>
            <EuiSetting.Item title="반납여부">
              {formatUseRental(itemView.useRental)}
            </EuiSetting.Item>
            <EuiSetting.Item title="사용가능시간">
              {formatUseTimeAvailable(
                itemView.useTimeAvailable,
                itemView.availableFromTime,
                itemView.availableToTime,
              )}
            </EuiSetting.Item>
            <EuiSetting.Item title="설명">
              {itemView.description ?? ''}
            </EuiSetting.Item>
            {(itemView.useApprove || itemView.useRental) && (
              <EuiSetting.Item title="담당자">
                <ChipGroup>
                  {itemView.managers
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 3) {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                      return 0;
                    })
                    .map(({ referenceCompanyId, referenceId }) => {
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId: referenceCompanyId,
                        employeeId: referenceId,
                      });
                      return (
                        <Chip
                          key={`${referenceCompanyId}/${referenceId}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                        />
                      );
                    })}
                </ChipGroup>
              </EuiSetting.Item>
            )}
            <EuiSetting.Item title="사용권한">
              <ChipGroup>
                {itemView.users
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 3) {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 1) return -1;
                      if (a.referenceType === 2) {
                        if (b.referenceType === 1) return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 3) {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      if (referenceType !== 3)
                        return (
                          <Chip
                            key={`${referenceCompanyId}/${id}`}
                            label={getOrganizationName(referenceCompanyId, id)}
                            etc={getCompanyName(referenceCompanyId)}
                            icon={
                              referenceType === 2
                                ? ('sitemap-fill' as const)
                                : ('company' as const)
                            }
                          />
                        );
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId: referenceCompanyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${referenceCompanyId}/${id}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                        />
                      );
                    },
                  )}
              </ChipGroup>
            </EuiSetting.Item>
            <EuiSetting.Item title="예외자">
              <ChipGroup>
                {itemView.exceptioners
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 3) {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 1) return -1;
                      if (a.referenceType === 2) {
                        if (b.referenceType === 1) return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 3) {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      if (referenceType !== 3)
                        return (
                          <Chip
                            key={`${referenceCompanyId}/${id}`}
                            label={getOrganizationName(referenceCompanyId, id)}
                            etc={getCompanyName(referenceCompanyId)}
                            icon={
                              referenceType === 2
                                ? ('sitemap-fill' as const)
                                : ('company' as const)
                            }
                          />
                        );
                      const directoryData = getDirectoryData({
                        ...directory,
                        companyId: referenceCompanyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${referenceCompanyId}/${id}`}
                          label={directoryData.employeeName}
                          etc={directoryData.organizationName}
                          avatar={directoryData.avatar}
                        />
                      );
                    },
                  )}
              </ChipGroup>
            </EuiSetting.Item>
            <EuiSetting.Item title="사진정보">
              {itemView.imagePath ? (
                <img
                  src={itemView.imagePath}
                  alt="자원 사진정보"
                  style={{ width: '100px' }}
                />
              ) : (
                <i
                  className="eui-icon eui-icon-no-image"
                  style={{
                    fontSize: '85px',
                    color: 'var(--secondary-text-color)',
                  }}
                />
              )}
            </EuiSetting.Item>
          </>
        )}
        {renderDialog()}
      </div>
    </EuiBody>
  );
}

export default ResourceDailyContentContainer;
