/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg, NoLoading } from '../../groupware-common/types';
import { IconType } from '../../groupware-common/types/icon';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import contactsApi, { StarReturnType } from '../apis/contacts/v1/contacts';
import labelsApi from '../apis/contacts/v1/labels';
import { labelActions } from './labels';

const name = 'contact/contacts';

// 목록 설정 객체
export interface LabelSetting {
  version: '1.0';
  density?: 'wide' | 'narrow' | 'normal'; // 화면 밀도.
  rowsPerPage: number; // 목록 개수.
}

interface CategoryItem {
  type: 'status' | 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}

export interface SaveContactItem {
  id?: number;
  name: string;
  nickName: string;
  jobTitle: string;
  department: string;
  company: string;
  labels?: {
    id: number;
    isDelete?: boolean;
    updateAt?: string;
  }[];
  birthDay?: {
    birthDay: string;
    isDelete?: boolean;
    isSolar: boolean; // 양력 여부.
  };
  emails?: {
    id?: number;
    isDelete?: boolean;
    isDefault: boolean;
    emailType: string;
    emailTypeCustomValue?: string;
    email: string;
    updateAt?: string;
  }[];
  phones?: {
    id?: number;
    isDelete?: boolean;
    isDefault: boolean;
    phoneType: string;
    phoneTypeCustomValue?: string;
    locationCode: number; // 국제전화 코드
    phoneNumber: string;
    updateAt?: string;
  }[];
  addresses?: {
    id?: number;
    isDelete?: boolean;
    addressType: string;
    addressTypeCustomValue?: string;
    postalCode: string; // 우편번호
    address: string; // 주소
    addressDetail: string; // 상세주소
    updateAt?: string;
  }[];
  anniversaries?: {
    id?: number;
    isDelete?: boolean;
    anniversaryType: string;
    anniversaryCustomValue?: string;
    anniversary: string;
    isSolar: boolean; // 양력 여부.
    updateAt?: string;
  }[];
  memo: string;
  updateAt?: string;
}

export interface ContactListItem {
  checked: boolean;
  isStarred: boolean;
  labels: number[];
  id: number;
  name: string;
  nickName: string;
  jobTitle: string;
  department: string;
  company: string;
  representPhone: string; // 대표 전화번호
  representEmail: string; // 대표 이메일
  updateAt: string;
}

export interface ContactViewItem {
  isStarred: boolean;
  id: number;
  name: string;
  nickName: string;
  jobTitle: string;
  department: string;
  company: string;
  birthDay: string;
  labels: {
    labelId: number;
    updateAt: string;
  }[];
  emails: {
    id: number;
    isDefault: boolean;
    emailType: 'COMPANY' | 'PERSONAL' | 'CUSTOM';
    emailTypeCustomValue?: string;
    email: string;
    updateAt: string;
  }[];
  phones: {
    id: number;
    isDefault: boolean;
    phoneType: 'COMPANY' | 'PHONE' | 'HOME' | 'FAX' | 'CUSTOM';
    phoneTypeCustomValue?: string;
    locationCode: number; // 국제전화 코드
    phoneNumber: string;
    updateAt: string;
  }[];
  addresses: {
    id: number;
    addressType: 'COMPANY' | 'HOME' | 'CUSTOM';
    addressTypeCustomValue?: string;
    postalCode: string; // 우편번호
    address: string; // 주소
    addressDetail: string; // 상세주소
    updateAt: string;
  }[];
  anniversaries: {
    id: number;
    anniversaryType: 'ANNIVERSARY' | 'CUSTOM';
    anniversaryTypeCustomValue?: string;
    anniversary: string;
    isSolar: boolean; // 양력 여부.
    updateAt: string;
  }[];
  memo: string;
  updaterId: number;
  updateAt: string;
}

/** 기본 폴더 */
const categories: CategoryItem[] = [
  { type: 'status', id: 'all', name: '전체연락처', icon: 'address-book' },
  { type: 'status', id: 'importance', name: '중요연락처', icon: 'star' },
  { type: 'status', id: 'trash', name: '휴지통', icon: 'trash-fill' },
  { type: 'setting', id: 'import', name: '연락처 가져오기', icon: 'import' },
  { type: 'setting', id: 'export', name: '연락처 내보내기', icon: 'export' },
  // { type: 'setting', id: 'recent', name: '최근 사용 주소', icon: 'send-clock' },
  // { type: 'setting', id: 'merge', name: '중복연락처 정리', icon: 'eraser' },
];

interface State {
  category: CategoryItem[];
  displayDensity: 'wide' | 'normal' | 'narrow';
  labelSetting: {
    setting?: LabelSetting;
    updateAt: string;
  };

  list: ContactListItem[];
  total: number;
  view?: ContactViewItem;
}

const initialState: State = {
  category: categories,
  displayDensity: 'normal',
  labelSetting: {
    updateAt: '1000-01-01T00:00:00',
  },

  list: [],
  total: 0,
};

export function getPhoneType(arg: {
  type: 'COMPANY' | 'PHONE' | 'HOME' | 'FAX' | 'CUSTOM';
  customValue?: string;
}): string {
  const { type, customValue } = arg;
  switch (type) {
    case 'COMPANY':
      return '회사';
    case 'PHONE':
      return '휴대폰';
    case 'HOME':
      return '집';
    case 'FAX':
      return '팩스';
    case 'CUSTOM':
      return customValue ?? '';
    default:
      return '';
  }
}

export function getEmailType(arg: {
  type: 'COMPANY' | 'PERSONAL' | 'CUSTOM';
  customValue?: string;
}): string {
  const { type, customValue } = arg;
  switch (type) {
    case 'COMPANY':
      return '회사';
    case 'PERSONAL':
      return '개인';
    case 'CUSTOM':
      return customValue ?? '';
    default:
      return '';
  }
}

export function getAddressType(arg: {
  type: 'COMPANY' | 'HOME' | 'CUSTOM';
  customValue?: string;
}): string {
  const { type, customValue } = arg;
  switch (type) {
    case 'COMPANY':
      return '회사';
    case 'HOME':
      return '집';
    case 'CUSTOM':
      return customValue ?? '';
    default:
      return '';
  }
}

export function getAnniversaryType(arg: {
  type: 'ANNIVERSARY' | 'CUSTOM';
  customValue?: string;
}): string {
  const { type, customValue } = arg;
  switch (type) {
    case 'ANNIVERSARY':
      return '기념일';
    case 'CUSTOM':
      return customValue ?? '';
    default:
      return '';
  }
}

/** 라벨 목록 설정 기준 저장. */
const saveLabelSetting = createAsyncThunk(
  `${name}/saveLabelSetting`,
  async (
    arg: {
      folderId?: string;
      setting: LabelSetting;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const { folderId, updateAt } = arg;
      const configs = JSON.stringify(arg.setting);
      if (!folderId || folderId === 'all') {
        const saveData = { companyId, configs, updateAt };
        const result = contactsApi.saveAllContactSetting(saveData);
        return result;
      }
      if (folderId === 'importance') {
        const saveData = { companyId, configs, updateAt };
        const result = contactsApi.saveStarredSetting(saveData);
        return result;
      }
      const saveData = {
        labelId: b62(folderId),
        companyId,
        configs,
        updateAt,
      };
      const result = contactsApi.saveLabelSetting(saveData);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 리스트 조회. */
const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      folderId?: string;
      search: string;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { folderId, search } = arg;
      const queryParams = getQueryParams(search);
      const { companyId } = (getState() as RootState).session.principal;
      let labelSetting: {
        setting?: LabelSetting;
        updateAt: string;
      } = {
        updateAt: '1000-01-01T00:00:00',
      };

      // 내 연락처의 라벨이 아닌 경우.
      if (
        folderId !== undefined &&
        folderId !== 'all' &&
        folderId !== 'importance' &&
        folderId !== 'trash' &&
        (await labelsApi.list(companyId)).find(
          (a) => a.id === b62(folderId),
        ) === undefined
      )
        throw new Error('데이터가 이동되었거나 삭제되었습니다.');

      if (!arg.folderId || arg.folderId === 'all') {
        const allSetting = await contactsApi.allContactSetting(companyId);
        labelSetting =
          allSetting.configs !== ''
            ? {
                setting: JSON.parse(allSetting.configs),
                updateAt: allSetting.updateAt,
              }
            : {
                ...labelSetting,
                updateAt: allSetting.updateAt,
              };
      } else if (arg.folderId === 'importance') {
        const importSetting = await contactsApi.starredSetting(companyId);
        labelSetting =
          importSetting.configs !== ''
            ? {
                setting: JSON.parse(importSetting.configs),
                updateAt: importSetting.updateAt,
              }
            : {
                ...labelSetting,
                updateAt: importSetting.updateAt,
              };
      } else if (arg.folderId === 'trash') {
        labelSetting = {
          updateAt: '1000-01-01T00:00:00',
        };
      } else {
        const defaultSetting = await contactsApi.labelSetting({
          labelId: b62(arg.folderId),
          companyId,
        });
        labelSetting =
          defaultSetting.configs !== ''
            ? {
                setting: JSON.parse(defaultSetting.configs),
                updateAt: defaultSetting.updateAt,
              }
            : {
                ...labelSetting,
                updateAt: defaultSetting.updateAt,
              };
      }

      const { setting } = labelSetting;
      const data = await contactsApi.list({
        folderId,
        companyId,
        word: queryParams.searchWord,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? setting?.rowsPerPage ?? 15,
      });
      const total = await contactsApi.totalCount({
        folderId,
        companyId,
        word: queryParams.searchWord,
      });

      let displayDensity: 'wide' | 'narrow' | 'normal' | undefined;
      if (arg.route && !queryParams.pageNo)
        displayDensity = setting ? setting.density : 'normal';
      return {
        list: data.map((a) => ({
          checked: false,
          importance: false,
          ...a,
          labels: a.labels === null ? [] : a.labels,
        })),
        total,
        displayDensity,
        labelSetting,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 단일 조회. */
const findView = createAsyncThunk(
  `${name}/findView`,
  async (arg: { id: number } & LocateArg, { rejectWithValue, getState }) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      const data = await contactsApi.view({ companyId, id: arg.id });
      const view: ContactViewItem = {
        ...data,
        labels: data.labels === null ? [] : data.labels,
        birthDay: data.birthDay === null ? '' : data.birthDay.birthDay,
        emails: data.emails === null ? [] : data.emails,
        phones: data.phones === null ? [] : data.phones,
        addresses: data.addresses === null ? [] : data.addresses,
        anniversaries: data.anniversaries === null ? [] : data.anniversaries,
      };
      return view;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 중요 표시. */
const saveUnSaveStar = createAsyncThunk(
  `${name}/saveUnSaveStar`,
  async (
    arg: {
      star: boolean;
      id: number;
    } & LocateArg &
      NoLoading,
    { getState, rejectWithValue },
  ) => {
    try {
      const { star, id } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      const response = await contactsApi.saveUnSaveStar({
        star,
        id,
        companyId,
      });
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 생성 */
const createContact = createAsyncThunk(
  `${name}/createContact`,
  async (
    arg: {
      data: SaveContactItem;
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.create({
        companyId,
        data: arg.data,
      });
      dispatch(labelActions.list());
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 수정. */
const updateContact = createAsyncThunk(
  `${name}/updateContact`,
  async (
    arg: {
      peopleId: number;
      data: SaveContactItem;
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { peopleId, data } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.update({ companyId, peopleId, data });
      dispatch(labelActions.list());
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 삭제 */
const deleteContact = createAsyncThunk(
  `${name}/deleteContact`,
  async (
    arg: {
      peopleId?: number; // 단일 삭제 시 존재.
      data:
        | {
            id: number;
            updateAt: string;
          }
        | {
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.delete({
        companyId,
        peopleId: arg.peopleId,
        data: arg.data,
      });
      dispatch(labelActions.list());
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 영구 삭제 */
const permanentlyDeleteContact = createAsyncThunk(
  `${name}/permanentlyDeleteContact`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.permanentlyDelete({
        companyId,
        data: arg.data,
      });
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 복원 */
const restoreContact = createAsyncThunk(
  `${name}/restoreContact`,
  async (
    arg: {
      data: {
        id: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.restoreContact({
        companyId,
        data: arg.data,
      });
      dispatch(labelActions.list());
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 라벨으로 복사 */
const copyContactLabel = createAsyncThunk(
  `${name}/copyContactLabel`,
  async (
    arg: {
      labelId: number;
      peoples: number[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { labelId, peoples } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.copyContactLabel({ companyId, labelId, peoples });
      dispatch(labelActions.list());
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연락처 라벨에서 해제 */
const cutContactLabel = createAsyncThunk(
  `${name}/cutContactLabel`,
  async (
    arg: {
      labelId: number;
      peoples: number[];
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { labelId, peoples } = arg;
      const { companyId } = (getState() as RootState).session.principal;
      await contactsApi.cutContactLabel({ companyId, labelId, peoples });
      dispatch(labelActions.list());
      if (arg.route) {
        const { pathname, search = '' } = arg.route;
        const { folderId } = getPathParams<{ folderId?: string }>(
          '/*/:folderId',
          pathname,
        );
        dispatch(findList({ folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const contactsSlice = createSlice({
  name,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (state.list) {
        if (action.payload.itemId === 'all') {
          state.list = state.list.map((x) => {
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
        } else {
          const index = state.list.findIndex(
            (x) => x.id === action.payload.itemId,
          );
          if (index > -1) {
            state.list[index] = {
              ...state.list[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    viewClear(state) {
      state.view = undefined;
    },
    displayDensity(state, action: PayloadAction<'wide' | 'normal' | 'narrow'>) {
      if (state.displayDensity !== action.payload)
        state.displayDensity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = payload.list;
          state.total = payload.total;
          state.view = undefined;
          if (payload.displayDensity)
            state.displayDensity = payload.displayDensity;
          state.labelSetting = payload.labelSetting;
        }
      })
      .addCase(saveUnSaveStar.fulfilled, (state, { payload }) => {
        const { view: data } = state;
        if (payload !== undefined) {
          const result = payload as StarReturnType;
          if (data && data.id === result.id)
            state.view = {
              ...data,
              isStarred: result.isStarred,
            };
          state.list = state.list.map((a) => {
            if (a.id === result.id)
              return {
                ...a,
                isStarred: result.isStarred,
              };
            return a;
          });
        }
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(saveLabelSetting.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          const { configs, updateAt } = payload;
          state.labelSetting = {
            setting: configs !== '' ? JSON.parse(configs) : undefined,
            updateAt,
          };
        }
      });
  },
});

export default contactsSlice.reducer;

export const contactsActions = {
  checked: contactsSlice.actions.checked,
  viewClear: contactsSlice.actions.viewClear,
  displayDensity: contactsSlice.actions.displayDensity,

  list: findList,
  view: findView,

  saveUnSaveStar,

  createContact,
  updateContact,
  deleteContact,
  permanentlyDeleteContact,
  restoreContact,

  copyContactLabel,
  cutContactLabel,

  saveLabelSetting,
};
