import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { schedulesActions } from '../../../../stores/calendar/schedules';

function CalendarContentRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element | null {
  const { pathname, search } = props;
  let { hash } = props;
  const dispatch = useAppDispatch();
  const basic = useSelector(
    (state: RootState) => state.calendar.userPreferences.basic,
  );

  // 캘린더 모듈 처음 들어올 경우 캘린더 초기화면 설정값 적용.
  if (props.hash === '') {
    switch (basic.initialFrame) {
      case 'WEEKLY':
        hash = '#weekly';
        break;
      case 'MONTHLY':
        hash = '#monthly';
        break;
      default:
        hash = '#daily';
        break;
    }
    return <Redirect to={`/calendar${hash}`} />;
  }

  useEffect(() => {
    let mount = true;
    const route = { pathname, search, hash: props.hash };
    if (mount) dispatch(schedulesActions.list({ search, route }));

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, props.hash]);
  return null;
}

export default CalendarContentRoute;
