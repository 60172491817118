import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPathParams } from '../../groupware-common/utils';
import ResourceDrawer from '../pages/root/resource/common/ResourceDrawer';

function ResourceLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { i18n } = useTranslation();
  const { pathname, search, hash } = props;
  const { p1 } = getPathParams('/*/:p1', pathname);

  return (
    <ResourceDrawer
      key={i18n.language}
      folderId={p1}
      pathname={pathname}
      search={search}
      hash={hash}
    />
  );
}

export default ResourceLocateDrawer;
