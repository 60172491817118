import React from 'react';

function EuiToolbarLeft(props: { children: React.ReactNode }): JSX.Element {
  return <div className="toolbar-left">{props.children}</div>;
}

function EuiToolbarRight(props: { children: React.ReactNode }): JSX.Element {
  return <div className="toolbar-right">{props.children}</div>;
}

function EuiToolbar(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-toolbar';
  if (props.className) classname += ` ${props.className}`;

  return <div className={classname}>{props.children}</div>;
}

EuiToolbar.Left = EuiToolbarLeft;
EuiToolbar.Right = EuiToolbarRight;

export default EuiToolbar;
