import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import DocumentAuthorityContainer from '../pages/adminconsole/document/authority/DocumentAuthorityContainer';
import DocumentCheckoutContainer from '../pages/adminconsole/document/checkout/DocumentCheckoutContainer';
import DocumentFolderBoxContainer from '../pages/adminconsole/document/folderbox/company/DocumentFolderBoxContainer';
import DocumentFormContainer from '../pages/adminconsole/document/form/DocumentFormContainer';
import DocumentPreferencesContainer from '../pages/adminconsole/document/preferences/DocumentPreferencesContainer';
import DocumentTrashContainer from '../pages/adminconsole/document/trash/DocumentTrashContainer';

function AdminConsoleLocateContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element | null {
  // console.log(`${AdminConsoleLocateContainer.name}.render(props)`, props);

  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 문서함
  if (menu === 'folderbox')
    return <DocumentFolderBoxContainer pathname={pathname} search={search} />;
  // 양식 관리
  if (menu === 'form')
    return <DocumentFormContainer pathname={pathname} search={search} />;
  // 일괄권한 설정
  if (menu === 'authority') return <DocumentAuthorityContainer />;
  // 삭제함
  if (menu === 'trash')
    return <DocumentTrashContainer pathname={pathname} search={search} />;
  // 체크아웃 관리
  if (menu === 'checkout')
    return <DocumentCheckoutContainer pathname={pathname} search={search} />;

  return <DocumentPreferencesContainer />;
}

export default AdminConsoleLocateContainer;
