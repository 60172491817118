import React from 'react';

function SwitchGroup(props: {
  className?: string;
  legend?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-switch-group';
  if (props.className) classname += ` ${props.className}`;

  return (
    <div className={classname}>
      {props.legend && <legend className="legend">{props.legend}</legend>}
      <div className="group">{props.children}</div>
    </div>
  );
}

export default SwitchGroup;
