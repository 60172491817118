import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import AttendanceDetailSearch from '../../../../../components/search/AttendanceDetailSearch';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { attendancesActions } from '../../../../stores/attendance/attendances';
import AttendanceSubstituteDialog from '../../../common/AttendanceSubsituteDialog';

function AttendanceSubstituteContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const directory = useDirectory();
  const list = useSelector(
    (state: RootState) => state.attendance.attendances.substituteList,
  );
  const principal = useSelector((state: RootState) => state.session.principal);
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6010)?.name ?? '';
  const currentYear = timezoneDate().getFullYear();

  const [type, setType] = useState('');

  const columns: DataGridColDef[] = [
    { field: 'employeeNo', label: '사번', minWidth: 120 },
    { field: 'user', label: '이름', minWidth: 158 },
    { field: 'occur', label: '발생', align: 'center', minWidth: 100 },
    { field: 'adjust', label: '조정', align: 'center', minWidth: 100 },
    { field: 'use', label: '사용', align: 'center', minWidth: 100 },
    { field: 'rest', label: '잔여', align: 'center', minWidth: 100 },
  ];
  const rows: DataGridRowsProps<number> = list.items.map((a) => {
    const directoryData = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId: a.employeeId,
    });
    const employeeNo = directory.employees.find(
      ({ id }) => id === a.employeeId,
    )?.no;

    const occursUsed =
      a.occursAlternative !== '0일' && a.occursAlternative !== '0분';
    const modifingUsed =
      a.modifingAlternative !== '0일' && a.modifingAlternative !== '0분';
    const useUsed = a.useAlternative !== '0일' && a.useAlternative !== '0분';
    return [
      { type: 'text' as const, value: employeeNo ?? '' },
      {
        type: 'user' as const,
        avatar: directoryData.avatar ?? '',
        icon: undefined,
        value: directoryData.employeeName,
        from: directoryData.organizationName,
      },
      // eslint-disable-next-line prettier/prettier
      { type: 'subject' as const, use: occursUsed, value: a.occursAlternative, id: a.employeeId },
      // eslint-disable-next-line prettier/prettier
      { type: 'subject' as const, use: modifingUsed, value: a.modifingAlternative, id: a.employeeId },
      // eslint-disable-next-line prettier/prettier
      { type: 'subject' as const, use: useUsed, value: a.useAlternative, id: a.employeeId },
      { type: 'text' as const, value: a.remainedAlternative },
    ];
  });

  const selectedYearOptions: { value: string; label: string }[] = [];
  const getYear = currentYear - 4;
  for (let i = 0; i < 5; i += 1) {
    const value = getYear + i;
    selectedYearOptions.push({
      value: value.toString(),
      label: value.toString(),
    });
  }
  selectedYearOptions.push({
    value: (currentYear + 1).toString(),
    label: (currentYear + 1).toString(),
  });

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    filter: string;
    periodType?: string;
    dateType?: string;
    directoryFilter?: string;
    directoryKeyword?: string;
    date?: {
      start: Date | null;
      end: Date | null;
    };
  }) => {
    delete queryParams.pageNo;
    queryParams.status = arg.filter;
    queryParams.searchCode = arg.directoryFilter;
    queryParams.searchWord = arg.keyword;
    queryParams.directoryKeyword = arg.directoryKeyword;
    dispatch(
      attendancesActions.substituteList({
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  const handleActionClick = (arg: {
    code: string;
    id?: number;
    name?: string;
  }) => {
    const { code, id, name } = arg;

    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = (queryParams.pageNo || 1) - 1;
      if (queryParams.pageNo > 0) {
        dispatch(
          attendancesActions.substituteList({
            search: getQueryParams(queryParams),
            route: {
              pathname,
              search: getQueryParams(queryParams),
            },
          }),
        );
      }
    }
    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = (queryParams.pageNo || 1) + 1;
      dispatch(
        attendancesActions.substituteList({
          search: getQueryParams(queryParams),
          route: {
            pathname,
            search: getQueryParams(queryParams),
          },
        }),
      );
    }
    if (code === 'subject' && id && name) {
      queryParams.dialogType = 'detailList';
      queryParams.id = id;
      setType(name);
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }
  };

  const handleDialogClose = () => {
    delete queryParams.dialogType;
    delete queryParams.id;
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    if (dialogType === 'detailList') {
      return (
        <AttendanceSubstituteDialog
          name={type}
          search={search}
          onClose={handleDialogClose}
        />
      );
    }
    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceDetailSearch
            directory
            options={selectedYearOptions}
            filter={queryParams.status ?? currentYear.toString()}
            keyword={queryParams.searchWord}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          caption={title}
          columns={columns}
          rows={rows}
          pagination={{
            no: queryParams.pageNo ?? 1,
            row: queryParams.rowsPerPage ?? 15,
            total: rows.length,
          }}
          onClick={handleActionClick}
        />
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default AttendanceSubstituteContainer;
