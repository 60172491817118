import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import Switch from '../../../../../components/switch/Switch';
import TextField from '../../../../../components/textfield/TextField';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import { CustomNumbers } from '../../../../../groupware-common/types';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { RootState as R } from '../../../../../groupware-webapp/app/store';
import {
  CalendarFolderView,
  PermissionType,
  PermissionUserType,
  SaveCalendarData,
} from '../../../../stores/calendar/calendars';
import FeedBack from '../../../../../components/alert/FeedBack';
import CalendarPermissionDialogContainer from '../../../common/CalendarPermissionDialogContainer';

function CalendarUserPreferencesCalEditDrawer(props: {
  type: 'myCal' | 'sharedCal';
  view?: CalendarFolderView;
  originName?: string;
  onSave(data: SaveCalendarData & { isNotManagers?: boolean }): void;
  onClose(): void;
}): JSX.Element {
  const { type, view, onSave, onClose } = props;
  const directory = useDirectory();
  const employees = useSelector((s: R) => s.directory.employee.list.data.items);
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );
  const principal = useSelector((s: R) => s.session.principal);
  // 공유캘린더 수정 시 관리자 권한이 없는 캘린더인 경우 - 제목 수정만 가능함.
  const isNotManagers =
    type === 'sharedCal' &&
    !view?.managers.some((a) => a.referenceId === principal.employeeId);
  const initialState = view
    ? {
        id: view.id,
        name: view.name,
        description: view.description,
        status: view.status,
        managers: view.managers,
        users: view.users,
        exceptioners: view.exceptioners,
        colorMenuPoint: undefined,
        managerMenuPoint: undefined,
        usersDialog: false,
        excluderMenuPoint: undefined,
        validation: '',
      }
    : {
        name: '',
        description: '',
        status: true,
        managers:
          type === 'myCal'
            ? [
                {
                  referenceCompanyId: principal.companyId,
                  referenceId: principal.employeeId,
                  referenceType: 'EMPLOYEE',
                },
              ]
            : [],
        users: [],
        exceptioners: [],
        colorMenuPoint: undefined,
        managerMenuPoint: undefined,
        usersDialog: false,
        excluderMenuPoint: undefined,
        validation: '',
      };

  const [state, setState] = useState<{
    id?: number;
    name: string;
    description: string;
    status: boolean;
    managers: PermissionType[];
    users: PermissionUserType[];
    exceptioners: PermissionType[];

    colorMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    managerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    usersDialog: boolean;
    excluderMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    validation: string;
  }>(initialState);

  /** 캘린더명 변경 이벤트. */
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 50) {
      setState((prev) => ({
        ...prev,
        validation: '캘린더명은 50자를 넘길 수 없습니다.',
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  /** 설명 변경 이벤트. */
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (event.target.value.length > 250) {
      setState((prev) => ({
        ...prev,
        validation: '설명은 250자를 넘길 수 없습니다.',
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  /** 사용여부 변경 이벤트. */
  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      status: event.target.checked,
    }));
  };

  /** 관리자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { managerMenuPoint } = state;
    if (event !== undefined && managerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        managerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        managerMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 아이템 추가. */
  const handleManagerAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.managers.some((a) => a.referenceId === employeeId))
          return { ...prev, managerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, managerMenuPoint: undefined };

        return {
          ...prev,
          managers: [
            ...prev.managers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
            },
          ],
        };
      });
    }
  };

  /** 관리자 아이템 삭제. */
  const handleManagerDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      managers: prev.managers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 예외자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggleExcluder = (event?: React.MouseEvent) => {
    const { excluderMenuPoint } = state;
    if (event !== undefined && excluderMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: undefined,
      }));
    }
  };

  /** 예외자 아이템 추가. */
  const handleExcluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) => a.referenceType === 'COMPANY' && a.referenceId === companyId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };
        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 'COMPANY',
            },
          ],
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
            },
          ],
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.exceptioners.some((a) => a.referenceId === employeeId))
          return { ...prev, excluderMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
            },
          ],
        };
      });
    }
  };

  /** 예외자 아이템 삭제. */
  const handleExcluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      exceptioners: prev.exceptioners.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 공유자 대화상자 오픈. */
  const handleOpenUsersDialog = () => {
    setState((prev) => ({ ...prev, usersDialog: true }));
  };

  /** 공유자 대화상자 닫기. */
  const handleCloseUsersDialog = () => {
    setState((prev) => ({ ...prev, usersDialog: false }));
  };

  /** 공유자 권한 조회 */
  const handleMatchingPermissions = (arg: {
    isRead: boolean;
    isWrite: boolean;
  }): string[] => {
    const matchingPermissions: string[] = [];
    if (arg.isRead) {
      matchingPermissions.push('읽기');
    }
    if (arg.isWrite) {
      matchingPermissions.push('쓰기/수정');
    }
    return matchingPermissions;
  };

  /** 공유자 권한 저장. */
  const handleSaveUsersPermission = (users: PermissionUserType[]) => {
    setState((prev) => ({
      ...prev,
      users,
      usersDialog: false,
    }));
  };

  /** 공유자 권한 삭제 */
  const handleAdminstratorsDelete = (id: number, referenceType: string) => {
    const { users } = state;
    setState((prev) => ({
      ...prev,
      users: users.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 저장 */
  const handleSave = () => {
    if (!isNotManagers) {
      if (state.name.trim() === '') {
        setState((prev) => ({ ...prev, validation: '캘린더명을 입력하세요.' }));
        return;
      }
      if (state.managers.length === 0) {
        setState((prev) => ({
          ...prev,
          validation: '관리자를 1명 이상 설정하세요.',
        }));
        return;
      }
    }

    const permissions: {
      referenceCompanyId: number; // 사용자 회사 아이디
      referenceId: number; // 사용자 아이디 (organization | employee)
      referenceType: string; // 참조 유형
    }[] = [];
    state.managers.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.users.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.exceptioners.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });

    let isDuplicate = false;
    for (let i = 0; i < permissions.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (
          permissions[i].referenceType === permissions[j].referenceType &&
          permissions[i].referenceId === permissions[j].referenceId
        ) {
          isDuplicate = true;
          break;
        }
      }
    }
    if (isDuplicate) {
      setState((prev) => ({
        ...prev,
        validation:
          '관리자 또는 공유자, 예외자 인원을 중복으로 설정할 수 없습니다.',
      }));
      return;
    }
    const name =
      isNotManagers && props.originName && state.name.trim() === ''
        ? props.originName
        : state.name;
    onSave({
      isNotManagers: isNotManagers ? true : undefined,
      id: view ? view.id : undefined,
      name,
      description: state.description,
      status: state.status,
      useExposeCreator: view ? undefined : false,
      managers: state.managers,
      exceptioners: state.exceptioners,
      users: state.users,
      updateAt: view ? view.updateAt : undefined,
    });
  };

  const renderDialog = () => {
    if (state.usersDialog)
      return (
        <CalendarPermissionDialogContainer
          users={state.users}
          onSave={handleSaveUsersPermission}
          onClose={handleCloseUsersDialog}
        />
      );

    return null;
  };

  const renderContent = () => {
    if (isNotManagers)
      return (
        <>
          <PostWrite.Item title="캘린더명" required>
            <TextField
              count
              maxLength={50}
              placeholder={props.originName}
              value={state.name}
              onChange={handleChangeName}
            />
          </PostWrite.Item>
          <PostWrite.Item title="설명">{state.description}</PostWrite.Item>
          <PostWrite.Item title="사용여부">
            <Switch checked={state.status} onChange={handleChangeStatus} />
          </PostWrite.Item>
          {state.managers.length > 0 && (
            <PostWrite.Item title="관리자">
              <ChipGroup>
                {state.managers
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(({ referenceCompanyId: companyId, referenceId: id }) => {
                    const DirectoryData = getDirectoryData({
                      ...directory,
                      companyId,
                      employeeId: id,
                    });
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={DirectoryData.employeeName}
                        etc="관리자"
                        avatar={DirectoryData.avatar}
                      />
                    );
                  })}
              </ChipGroup>
            </PostWrite.Item>
          )}
          <PostWrite.Item title="공유자">
            <ChipGroup>
              {state.users
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                    options: { isRead, isWrite },
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc={handleMatchingPermissions({
                            isRead,
                            isWrite,
                          }).join('/')}
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          etc={handleMatchingPermissions({
                            isRead,
                            isWrite,
                          }).join('/')}
                          icon="sitemap-fill"
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc={handleMatchingPermissions({
                            isRead,
                            isWrite,
                          }).join('/')}
                          avatar={DirectoryData.avatar}
                        />
                      );
                    }
                    return null;
                  },
                )}
            </ChipGroup>
          </PostWrite.Item>
          <PostWrite.Item title="예외자">
            <ChipGroup>
              {state.exceptioners
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc="예외자"
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          icon="sitemap-fill"
                          etc="예외자"
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc="예외자"
                          avatar={DirectoryData.avatar}
                        />
                      );
                    }
                    return null;
                  },
                )}
            </ChipGroup>
          </PostWrite.Item>
        </>
      );
    return (
      <>
        <PostWrite.Item title="캘린더명" required>
          <TextField
            count
            maxLength={50}
            value={state.name}
            onChange={handleChangeName}
          />
        </PostWrite.Item>
        <PostWrite.Item title="설명">
          <TextField
            multiline
            count
            maxLength={250}
            rows={2}
            value={state.description}
            onChange={handleChangeDescription}
          />
        </PostWrite.Item>
        <PostWrite.Item title="사용여부">
          <Switch checked={state.status} onChange={handleChangeStatus} />
        </PostWrite.Item>
        <PostWrite.Item
          title="관리자"
          tooltip="관리자는 직원만 선택가능합니다."
        >
          <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
            {state.managers
              .map((a) => {
                let jobClassSeq = 0;
                if (a.referenceType === 'EMPLOYEE') {
                  const employeeData = getDirectoryData({
                    ...directory,
                    companyId: a.referenceCompanyId,
                    employeeId: a.referenceId,
                  });
                  // 직위 또는 직책 순서로 정렬 순서 결정.
                  jobClassSeq =
                    (jobClassType === 'jobduty'
                      ? jobDuties.find(
                          (v) =>
                            v.companyId === a.referenceCompanyId &&
                            v.id === employeeData.jobDutyId,
                        )?.seq
                      : jobPositions.find(
                          (v) =>
                            v.companyId === a.referenceCompanyId &&
                            employeeData.jobPositionId === v.id,
                        )?.seq) || CustomNumbers.SMALLINT_MAX;
                }
                return {
                  ...a,
                  jobClassSeq,
                };
              })
              // 회사 → 부서 → 직원 순으로 정렬 후
              // 직위 또는 직책 순번이 낮을수록 밑으로.
              .sort((a, b) => {
                if (a.referenceType === 'EMPLOYEE') {
                  if (a.jobClassSeq < b.jobClassSeq) return -1;
                  if (a.jobClassSeq > b.jobClassSeq) return 1;
                }
                return 0;
              })
              .map(
                ({
                  referenceCompanyId: companyId,
                  referenceId: id,
                  referenceType,
                }) => {
                  const DirectoryData = getDirectoryData({
                    ...directory,
                    companyId,
                    employeeId: id,
                  });
                  // 삭제 불가능한 관리자 여부.
                  const isAdminDeleted = view
                    ? view.creatorId === id
                    : principal.employeeId === id;
                  return (
                    <Chip
                      key={`${companyId}/${id}`}
                      label={DirectoryData.employeeName}
                      etc="관리자"
                      avatar={DirectoryData.avatar}
                      onDelete={
                        isAdminDeleted
                          ? undefined
                          : () => handleManagerDelete(id, referenceType)
                      }
                    />
                  );
                },
              )}
            {state.managerMenuPoint && (
              <DirectoryMenuTreeContainer
                deduplication
                point={state.managerMenuPoint}
                typeToFilter="employee"
                onItemClick={handleManagerAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </PostWrite.Item>
        <PostWrite.Item title="공유자">
          <ChipGroup add="추가" onAdd={() => handleOpenUsersDialog()}>
            {state.users
              .map((a) => {
                let jobClassSeq = 0;
                if (a.referenceType === 'EMPLOYEE') {
                  const employeeData = getDirectoryData({
                    ...directory,
                    companyId: a.referenceCompanyId,
                    employeeId: a.referenceId,
                  });
                  // 직위 또는 직책 순서로 정렬 순서 결정.
                  jobClassSeq =
                    (jobClassType === 'jobduty'
                      ? jobDuties.find(
                          (v) =>
                            v.companyId === a.referenceCompanyId &&
                            v.id === employeeData.jobDutyId,
                        )?.seq
                      : jobPositions.find(
                          (v) =>
                            v.companyId === a.referenceCompanyId &&
                            employeeData.jobPositionId === v.id,
                        )?.seq) || CustomNumbers.SMALLINT_MAX;
                }
                return {
                  ...a,
                  jobClassSeq,
                };
              })
              // 회사 → 부서 → 직원 순으로 정렬 후
              // 직위 또는 직책 순번이 낮을수록 밑으로.
              .sort((a, b) => {
                if (a.referenceType !== b.referenceType) {
                  if (a.referenceType === 'COMPANY') return -1;
                  if (a.referenceType === 'ORGANIZATION') {
                    if (b.referenceType === 'COMPANY') return 1;
                    return -1;
                  }
                  return 1;
                }
                if (a.referenceType === 'EMPLOYEE') {
                  if (a.jobClassSeq < b.jobClassSeq) return -1;
                  if (a.jobClassSeq > b.jobClassSeq) return 1;
                }
                return 0;
              })
              .map(
                ({
                  referenceCompanyId: companyId,
                  referenceId: id,
                  referenceType,
                  options: { isRead, isWrite },
                }) => {
                  if (referenceType === 'COMPANY') {
                    // 회사일 경우
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={getCompanyName(companyId)}
                        icon="company"
                        etc={handleMatchingPermissions({
                          isRead,
                          isWrite,
                        }).join('/')}
                        onDelete={() =>
                          handleAdminstratorsDelete(id, referenceType)
                        }
                      />
                    );
                  }
                  if (referenceType === 'ORGANIZATION') {
                    // 부서일 경우
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={getOrganizationName(companyId, id)}
                        etc={handleMatchingPermissions({
                          isRead,
                          isWrite,
                        }).join('/')}
                        icon="sitemap-fill"
                        onDelete={() =>
                          handleAdminstratorsDelete(id, referenceType)
                        }
                      />
                    );
                  }
                  if (referenceType === 'EMPLOYEE') {
                    // 사원일 경우
                    const DirectoryData = getDirectoryData({
                      ...directory,
                      companyId,
                      employeeId: id,
                    });
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={DirectoryData.employeeName}
                        etc={handleMatchingPermissions({
                          isRead,
                          isWrite,
                        }).join('/')}
                        avatar={DirectoryData.avatar}
                        onDelete={() =>
                          handleAdminstratorsDelete(id, referenceType)
                        }
                      />
                    );
                  }
                  return null;
                },
              )}
          </ChipGroup>
        </PostWrite.Item>
        <PostWrite.Item title="예외자">
          <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggleExcluder}>
            {state.exceptioners
              .map((a) => {
                let jobClassSeq = 0;
                if (a.referenceType === 'EMPLOYEE') {
                  const employeeData = getDirectoryData({
                    ...directory,
                    companyId: a.referenceCompanyId,
                    employeeId: a.referenceId,
                  });
                  // 직위 또는 직책 순서로 정렬 순서 결정.
                  jobClassSeq =
                    (jobClassType === 'jobduty'
                      ? jobDuties.find(
                          (v) =>
                            v.companyId === a.referenceCompanyId &&
                            v.id === employeeData.jobDutyId,
                        )?.seq
                      : jobPositions.find(
                          (v) =>
                            v.companyId === a.referenceCompanyId &&
                            employeeData.jobPositionId === v.id,
                        )?.seq) || CustomNumbers.SMALLINT_MAX;
                }
                return {
                  ...a,
                  jobClassSeq,
                };
              })
              // 회사 → 부서 → 직원 순으로 정렬 후
              // 직위 또는 직책 순번이 낮을수록 밑으로.
              .sort((a, b) => {
                if (a.referenceType !== b.referenceType) {
                  if (a.referenceType === 'COMPANY') return -1;
                  if (a.referenceType === 'ORGANIZATION') {
                    if (b.referenceType === 'COMPANY') return 1;
                    return -1;
                  }
                  return 1;
                }
                if (a.referenceType === 'EMPLOYEE') {
                  if (a.jobClassSeq < b.jobClassSeq) return -1;
                  if (a.jobClassSeq > b.jobClassSeq) return 1;
                }
                return 0;
              })
              .map(
                ({
                  referenceCompanyId: companyId,
                  referenceId: id,
                  referenceType,
                }) => {
                  if (referenceType === 'COMPANY') {
                    // 회사일 경우
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={getCompanyName(companyId)}
                        icon="company"
                        etc="예외자"
                        onDelete={() => handleExcluderDelete(id, referenceType)}
                      />
                    );
                  }
                  if (referenceType === 'ORGANIZATION') {
                    // 부서일 경우
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={getOrganizationName(companyId, id)}
                        icon="sitemap-fill"
                        etc="예외자"
                        onDelete={() => handleExcluderDelete(id, referenceType)}
                      />
                    );
                  }
                  if (referenceType === 'EMPLOYEE') {
                    // 사원일 경우
                    const DirectoryData = getDirectoryData({
                      ...directory,
                      companyId,
                      employeeId: id,
                    });
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={DirectoryData.employeeName}
                        etc="예외자"
                        avatar={DirectoryData.avatar}
                        onDelete={() => handleExcluderDelete(id, referenceType)}
                      />
                    );
                  }
                  return null;
                },
              )}
            {state.excluderMenuPoint && (
              <DirectoryMenuTreeContainer
                deduplication
                point={state.excluderMenuPoint}
                ignoreId={`${principal.companyId}_${principal.organizationId}_${principal.employeeId}`}
                typeToFilter={['organization', 'employee']}
                onItemClick={handleExcluderAppend}
                onClose={handleDirectoryTreeMenuToggleExcluder}
              />
            )}
          </ChipGroup>
        </PostWrite.Item>
      </>
    );
  };

  const title = view !== undefined ? '캘린더 수정' : '캘린더 등록';

  return (
    <>
      <Drawer title={title} onClose={onClose}>
        <DrawerBody className="ui-calendar-company">
          <PostWrite>{renderContent()}</PostWrite>
        </DrawerBody>
        <DrawerAction>
          <Button
            noDuplication={state.validation === ''}
            text="저장"
            variant="contained"
            onClick={() => handleSave()}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
      {renderDialog()}
    </>
  );
}

export default CalendarUserPreferencesCalEditDrawer;
