import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldBasic(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <ComponentSampleUI.Content title="Basic">
      <TextField
        placeholder="Placeholder"
        value={value}
        onChange={handleChange}
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldBasic;
