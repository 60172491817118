/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { apiError, getHost } from '../../common/v1';

const namespace = 'test';

async function authenticate(arg: { companyId: number; employeeId: number }) {
  try {
    const { companyId, employeeId } = arg;
    const host = getHost();
    const url = `${host}/api/authenticate/v1?companyId=${companyId}&employeeId=${employeeId}`;
    const response = await axios.get<string>(url);
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const testApi = {
  namespace,
  authenticate,
};

export default testApi;
