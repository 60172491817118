import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { Language } from '../../../../../groupware-common/types';
import {
  b62,
  getAvatarPath,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  getText,
  go,
  resourceReload,
} from '../../../../../groupware-common/utils';
import {
  appError,
  getName,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { companyActions } from '../../../../stores/directory/company';
import { organizationActions } from '../../../../stores/directory/organization';
import { employeeActions } from '../../../../stores/directory/employee';
import {
  parseDirectoryTreeItemId,
  parseDirectoryType,
} from '../../../../stores/directory/utils';
import OrganizationChartContentList from './OrganizationChartContentList';
import OrganizationChartContentCompanyEdit from './OrganizationChartContentCompanyEdit';
import OrganizationChartContentCompanyView from './OrganizationChartContentCompanyView';
import OrganizationChartContentOrganizationView from './OrganizationChartContentOrganizationView';
import OrganizationChartContentEmployeeView from './OrganizationChartContentEmployeeView';
import OrganizationChartContentOrganizationEdit from './OrganizationChartContentOrganizationEdit';
import OrganizationChartContentEmployeeEdit from './OrganizationChartContentEmployeeEdit';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import {
  DirectoryTreeItemArg,
  getDirectoryTreeId,
  getDirectoryTreeItems,
} from '../../../../../components/tree/DirectoryTree';
import FeedBack from '../../../../../components/alert/FeedBack';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import {
  getCompanyName,
  getOrganizationName,
} from '../../../../stores/directory';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import Loading from '../../../../../components/loading/Loading';

function OrganizationChartContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathParams = getPathParams(`/*/*/*/:p1/:p2`, props.pathname);
  const propsOrganizationId = b62(pathParams.p1);
  const propsEmployeeId = b62(pathParams.p2);
  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();
  const resource = useSelector((state: RootState) => state.session.resource);
  const principal = useSelector((state: RootState) => state.session.principal);
  const languages = useSelector((state: RootState) => state.session.languages);

  const useLink = useSelector(
    (state: RootState) =>
      state.directory.company.view.data?.useExternalDirectory,
  );

  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  ).filter(({ id }) => id === principal.companyId);
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );

  const companyView = useSelector(
    (state: RootState) => state.directory.company.view.data,
  );
  const organizationView = useSelector(
    (state: RootState) => state.directory.organization.view.data,
  );

  const employeeView = useSelector(
    (state: RootState) => state.directory.employee.view.data,
  );

  const [validation, setValidation] = useState('');

  const [state, setState] = useState({
    loading: true,
    isSync: false,
  });

  let items = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );
  items = items.map((a) => {
    return {
      ...a,
      avatar: a.avatar ? `${a.avatar}/nocache` : undefined,
    };
  });

  const jobPositionItems = useMemo(() => {
    return jobPositions.map((x) => ({
      value: `${x.id}`,
      label: x.id === 0 ? '미지정' : getText(x.nameKey),
    }));
  }, [jobPositions]);

  const jobRankItems = useMemo(() => {
    const jobRanks: { value: string; label: string }[] = [];
    for (let i = 0; i <= 31; i += 1) {
      if (i === 0) jobRanks.push({ value: `${i}`, label: '미지정' });
      else jobRanks.push({ value: `${i}`, label: `${i} 호봉` });
    }
    return jobRanks;
  }, []);

  useEffect(() => {
    async function run() {
      try {
        const { loading, isSync } = state;
        if (loading && isSync) {
          await dispatch(await organizationActions.updateEexternal()).then(
            async () => {
              await resourceReload();
              if (
                principal.roles.find(
                  (role) => role === 'ADMIN' || role === 'DIRECTORY_ADMIN',
                ) === undefined ||
                employees.find((a) => a.id === principal.employeeId) ===
                  undefined
              ) {
                dispatch(sessionActions.signout());
                dispatch(sessionActions.error('관리자 권한이 없습니다.'));
                go('/signin');
              }
            },
          );
        }
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
      } finally {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          isSync: false,
        }));
        dispatch(sessionActions.setSyncLoading(false));
      }
    }
    run();
  }, [state.isSync]);

  const handleListItemClick = useCallback(
    (arg: DirectoryTreeItemArg) => {
      // console.log(`handleListItemClick(arg: DirectoryTreeItemExtra)`, arg);

      const pathmap = getPathMap('/*/*/*', props.pathname);

      const { extra } = arg.item;
      if (extra.type === 'company') {
        const { companyId } = extra;
        const pathname = `${pathmap}/${b62(companyId)}`;
        const param = { id: companyId, route: { pathname } };
        dispatch(companyActions.findView(param));
      }
      if (extra.type === 'organization') {
        const { organizationId } = extra;
        const pathname = `${pathmap}/${b62(organizationId)}`;
        const param = { id: organizationId, route: { pathname } };
        dispatch(organizationActions.findView(param));
      }
      if (extra.type === 'employee') {
        const { organizationId, employeeId } = extra;
        const pathname = `${pathmap}/${b62(organizationId)}/${b62(employeeId)}`;
        const param = { id: employeeId, route: { pathname } };
        dispatch(employeeActions.findView(param));
      }
    },
    [dispatch, props.pathname],
  );

  // 회사 수정 드로워.
  const handleCompanyUpdateDrawer = () => {
    dispatch(sessionActions.setDrawer({ mode: 'update', type: 'company' }));
  };
  // 회사 수정.
  const handleCompanyUpdate = (arg: {
    names: { code: Language; value: string }[];
    representativesName: string;
    establishedDate: string;
    businessRegistrationNo: string;
    corporationRegistrationNo: string;
    businessType: string;
    businessItem: string;
    phoneNo: string;
    faxNo: string;
    postalCode: string;
    address: string;
    employeeCount: number;
  }) => {
    // console.log('handleCompanyUpdate(arg)', arg);
    if (companyView === null || companyView === undefined) {
      setValidation('데이터가 이동되었거나 삭제되어 수정할 수 없습니다.');
      return;
    }

    let isUndefinedAll = true;
    Object.values(arg).some((v) => {
      isUndefinedAll = v === undefined;
      return !isUndefinedAll;
    });

    if (isUndefinedAll) handleDrawerClose();
    else {
      const pathname = getPathMap('/*/*/*/*', props.pathname);
      const { updateAt } = companyView;
      dispatch(
        companyActions.update({
          ...arg,
          updateAt,
          route: { pathname },
        }),
      );
    }
  };

  // 조직 생성 드로워.
  const handleOrganizationCreateDrawer = () => {
    dispatch(
      sessionActions.setDrawer({ mode: 'create', type: 'organization' }),
    );
  };
  // 조직 수정 드로워.
  const handleOrganizationUpdateDrawer = () => {
    dispatch(
      sessionActions.setDrawer({ mode: 'update', type: 'organization' }),
    );
  };
  // 조직 순서 변경 드로워.
  const handleOrganizationChangeSeqDrawer = () => {
    dispatch(
      sessionActions.setDrawer({ mode: 'update', type: 'organizationChange' }),
    );
  };
  // 조직 삭제 대화 상자.
  const handleOrganizationDeleteDialog = () => {
    dispatch(
      sessionActions.setDialog({ mode: 'delete', type: 'organization' }),
    );
  };
  // 조직 생성.
  const handleOrganizationCreate = (arg: {
    parentId: number;
    names: { label: Language; value: string }[];
    managerId: number;
    description: string;
  }) => {
    // console.log('handleOrganizationCreate(arg)', arg);
    const pathmap = getPathMap('/*/*/*', props.pathname);
    dispatch(
      organizationActions.create({
        ...arg,
        route: { pathname: `${pathmap}/{response_id}` },
      }),
    );
  };
  // 조직 수정.
  const handleOrganizationUpdate = (arg: {
    parentId: number | undefined;
    names: { label: Language; value: string | null }[] | undefined;
    managerId: number | undefined;
    description: string | undefined;
  }) => {
    // console.log('handleOrganizationUpdate(arg)', arg);
    if (organizationView === null || organizationView === undefined) {
      setValidation('데이터가 이동되었거나 삭제되어 수정할 수 없습니다.');
      return;
    }

    let isUndefinedAll = true;
    Object.values(arg).some((v) => {
      isUndefinedAll = v === undefined;
      return !isUndefinedAll;
    });

    if (isUndefinedAll) handleDrawerClose();
    else {
      const pathmap = getPathMap('/*/*/*', props.pathname);
      const { id, updateAt } = organizationView;
      dispatch(
        organizationActions.update({
          id,
          ...arg,
          updateAt,
          route: { pathname: `${pathmap}/${b62(id)}` },
        }),
      );
    }
  };
  // 조직 순서변경.
  const handleOrganizationChangeSeq = (
    list: {
      id: number;
      label?: string;
    }[],
  ) => {
    const data = list.map((a, i) => {
      const updateAt = organizations.find((x) => x.id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: i + 1,
        updateAt,
      };
    });
    dispatch(
      organizationActions.seq({
        data,
        route: { pathname: props.pathname },
      }),
    );
  };
  // 조직 삭제.
  const handleOrganizationDelete = () => {
    // console.log('handleOrganizationDelete()');
    if (organizationView === null || organizationView === undefined) {
      setValidation('데이터가 이동되었거나 삭제되었습니다.');
      return;
    }
    const pathmap = getPathMap('/*/*/*', props.pathname);
    const { id, updateAt } = organizationView;
    const route = { pathname: `${pathmap}/{response_id}` };
    dispatch(organizationActions.delete({ id, updateAt, route }));
  };
  // 조직 직원 삭제.
  const handleOrganizationEmployeeDelete = () => {
    // console.log(`handleOrganizationEmployeeDelete()`);

    const organizationEmployee = organizationEmployees.find(
      (a) =>
        a.companyId === principal.companyId &&
        a.id === propsOrganizationId &&
        a.employeeId === propsEmployeeId,
    );
    if (organizationEmployee === undefined) {
      setValidation('데이터가 이동되었거나 삭제되었습니다.');
      return;
    }
    const pathmap = getPathMap('/*/*/*', props.pathname);
    const { id, employeeId, updateAt } = organizationEmployee;
    const route = { pathname: `${pathmap}/${b62(id)}` };
    dispatch(
      organizationActions.employeeDelete({
        id,
        employeeId,
        updateAt,
        route,
      }),
    );
  };

  // 직원 생성 드로워.
  const handleEmployeeCreateDrawer = () => {
    dispatch(sessionActions.setDrawer({ mode: 'create', type: 'employee' }));
  };
  // 직원 수정 드로워.
  const handleEmployeeUpdateDrawer = () => {
    dispatch(sessionActions.setDrawer({ mode: 'update', type: 'employee' }));
  };
  // 직원 삭제 대화 상자.
  const handleEmployeeDeleteDialog = () => {
    const { companyId } = principal;

    const employee = employees.find(
      (a) => a.companyId === companyId && a.id === propsEmployeeId,
    );
    if (!employee) return;

    const affiliatedOrganizations = organizationEmployees.filter(
      (a) => a.companyId === companyId && a.employeeId === propsEmployeeId,
    );
    if (
      affiliatedOrganizations.length > 1 &&
      employee.representativeOrganizationId === propsOrganizationId
    ) {
      setValidation('겸직 인원의 대표 조직 직원은 삭제할 수 없습니다.');
      return;
    }

    dispatch(
      sessionActions.setDialog({
        mode: 'delete',
        type:
          affiliatedOrganizations.length === 1
            ? 'employee'
            : 'organizationEmployee',
      }),
    );
  };
  // 직원 생성.
  const handleEmployeeCreate = (arg: {
    email: string;
    password: string;
    names: { label: Language; value: string }[];
    enterDate: string;
    no: string;
    companyPhoneNo: string;
    extensionPhoneNo: string;
    mobilePhoneNo: string;
    representativeOrganizationId: number;
    jobPositionId: number;
    jobRank: number;
    task: string;
    personalEmail: string;
    birthday: string;
    lunarBirthdayUse: boolean;
    postalCode: string;
    address: string;
    avatar?: string;
    affiliatedOrganizations: { id: number; jobDutyId: number }[];
    accountLinkage?: {
      provider: string;
      id: string;
    };
  }) => {
    // console.log(`handleEmployeeCreate(arg)`, arg);
    const pathmap = getPathMap('/*/*/*', props.pathname);
    const { representativeOrganizationId: organizationId } = arg;
    const param = {
      ...arg,
      route: { pathname: `${pathmap}/${b62(organizationId)}/{response_id}` },
    };
    // console.log('handleEmployeeCreate:param', param);
    dispatch(employeeActions.create(param));
  };
  // 직원 수정.
  const handleEmployeeUpdate = (arg: {
    avatar?: string;
    names: { label: Language; value: string }[];
    enterDate: string;
    no: string;
    companyPhoneNo: string;
    extensionPhoneNo: string;
    mobilePhoneNo: string;
    representativeOrganizationId: number;
    organizationId: number;
    jobPositionId: number;
    jobRank: number;
    task: string;
    personalEmail: string;
    birthday: string;
    lunarBirthdayUse: boolean;
    postalCode: string;
    address: string;
    affiliatedOrganizations: {
      id: number;
      jobDutyId: number;
      lookupUpdateAt?: string;
      lookupDeleteAt?: string;
    }[];
    accountLinkage?: {
      provider: string;
      id: string;
    };
  }) => {
    // console.log(`handleEmployeeUpdate(arg)`, arg);
    const pathmap = getPathMap('/*/*/*', props.pathname);
    if (
      employeeView === null ||
      employeeView === undefined ||
      employeeView.id !== propsEmployeeId
    ) {
      setValidation('데이터가 이동되었거나 삭제되어 수정할 수 없습니다.');
      return;
    }

    const { id, updateAt } = employeeView;
    const {
      avatar,
      names,
      enterDate,
      no,
      companyPhoneNo,
      extensionPhoneNo,
      mobilePhoneNo,
      representativeOrganizationId,
      jobPositionId,
      jobRank,
      task,
      personalEmail,
      birthday,
      lunarBirthdayUse,
      postalCode,
      address,
      affiliatedOrganizations,
      accountLinkage,
    } = arg;

    if (propsOrganizationId === undefined) return;
    const route = {
      pathname: `${pathmap}/${b62(propsOrganizationId)}/${b62(id)}`,
    };
    const param = {
      id,
      avatar,
      names,
      enterDate,
      no,
      companyPhoneNo,
      extensionPhoneNo,
      mobilePhoneNo,
      representativeOrganizationId,
      jobPositionId,
      jobRank,
      task,
      personalEmail,
      birthday,
      lunarBirthdayUse,
      postalCode,
      address,
      affiliatedOrganizations,
      updateAt,
      accountLinkage,

      route,
    };
    // console.log('handleEmployeeUpdate::param', param);
    dispatch(employeeActions.update(param));
  };
  // 직원 삭제.
  const handleEmployeeDelete = () => {
    // console.log(`handleEmployeeDelete()`);
    if (employeeView === null || employeeView === undefined) {
      setValidation('데이터가 이동되었거나 삭제되었습니다.');
      return;
    }
    const pathmap = getPathMap('/*/*/*', props.pathname);
    const { id, updateAt } = employeeView;
    const route = { pathname: `${pathmap}/{response_id}` };

    dispatch(employeeActions.delete({ id, updateAt, route }));
  };

  const handleDrawerClose = () => {
    dispatch(sessionActions.search());
  };
  const handleDialogClose = handleDrawerClose;

  /** 조직도 동기화 버튼 클릭. */
  const handleOrganizationLinkClick = () => {
    setState((prevState) => ({
      ...prevState,
      isSync: true,
      loading: true,
    }));
    dispatch(sessionActions.setSyncLoading(true));
  };

  const renderList = () => {
    const companyId = queryParams.companyId || principal.companyId;
    return (
      <OrganizationChartContentList
        useLink={useLink}
        selectedId={getDirectoryTreeId(
          companyId,
          propsOrganizationId,
          propsEmployeeId,
        )}
        items={items}
        onItemClick={handleListItemClick}
        onOrganizationCreate={handleOrganizationCreateDrawer}
        onEmployeeCreate={handleEmployeeCreateDrawer}
        onOrganizationLinkClick={handleOrganizationLinkClick}
      />
    );
  };

  const renderView = () => {
    const companyId = queryParams.companyId || principal.companyId;

    const type = propsOrganizationId
      ? parseDirectoryType(companyId, propsOrganizationId, propsEmployeeId)
      : undefined;

    if (type === 'company') {
      if (companyView === undefined || companyView === null)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const {
        representativesName,
        establishedDate,
        businessRegistrationNo,
        corporationRegistrationNo,
        businessType,
        businessItem,
        phoneNo,
        faxNo,
        postalCode,
        address,
        employeeCount,
        accountMaxLimit,
      } = companyView;

      const businessRegExr = /^[0-9]{10}$/;
      const corporationRegExr = /^[0-9]{13}$/;
      let formatBusinessNo = businessRegistrationNo;
      let formatCorporationNo = corporationRegistrationNo;
      if (businessRegExr.test(formatBusinessNo)) {
        const part1 = formatBusinessNo.substring(0, 3);
        const part2 = formatBusinessNo.substring(3, 5);
        const part3 = formatBusinessNo.substring(5);
        formatBusinessNo = `${part1}-${part2}-${part3}`;
      }

      if (corporationRegExr.test(formatCorporationNo)) {
        const part1 = formatCorporationNo.substring(0, 6);
        const part2 = formatCorporationNo.substring(6);
        formatCorporationNo = `${part1}-${part2}`;
      }

      return (
        <OrganizationChartContentCompanyView
          useLink={useLink}
          name={getCompanyName(companyId)}
          representativesName={representativesName}
          businessRegistrationNo={formatBusinessNo}
          corporationRegistrationNo={formatCorporationNo}
          establishedDate={establishedDate}
          businessType={businessType}
          businessItem={businessItem}
          phoneNo={phoneNo}
          faxNo={faxNo}
          postalCode={postalCode}
          address={address}
          employeeCount={employeeCount}
          accountMaxLimit={accountMaxLimit}
          onUpdate={handleCompanyUpdateDrawer}
        />
      );
    }

    if (type === 'organization') {
      if (organizationView === undefined || organizationView === null)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const parentNames = getParentItems(
        items,
        `${companyId}_${organizationView.parentId}`,
      ).map((x) => x.text);

      const { description, nameKey, managerNameKey } = organizationView;
      const names = languages.map((language) => {
        const value = getText([nameKey, '미지정'], language);
        return { label: language, value };
      });
      const managerName = getText([managerNameKey, '미지정']);

      return (
        <OrganizationChartContentOrganizationView
          useLink={useLink}
          parentNames={parentNames}
          names={names}
          description={description}
          managerName={managerName}
          onUpdate={handleOrganizationUpdateDrawer}
          onChangeSeq={handleOrganizationChangeSeqDrawer}
          onDelete={handleOrganizationDeleteDialog}
        />
      );
    }

    if (type === 'employee') {
      if (
        employeeView === undefined ||
        employeeView === null ||
        propsOrganizationId === undefined
      )
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const affiliatedOrganizations = organizationEmployees
        .filter(
          (a) => a.companyId === companyId && a.employeeId === propsEmployeeId,
        )
        .map((a) => {
          return {
            representative: a.id === employeeView.representativeOrganizationId,
            jobDutyName: getName(jobDuties, a.companyId, a.jobDutyId, '미지정'),
            paths: getParentItems(
              items,
              parseDirectoryTreeItemId(a.companyId, a.id),
            ).map((b) => b.text),
          };
        });

      if (affiliatedOrganizations.length === 0)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const treeItemId = parseDirectoryTreeItemId(
        companyId,
        propsOrganizationId,
        propsEmployeeId,
      );
      const treeItem = items.find((a) => a.id === treeItemId);
      if (treeItem === undefined)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const {
        id,
        email,
        no,
        companyPhoneNo,
        extensionPhoneNo,
        mobilePhoneNo,
        task,
        enterDate,
        nameKey,
        jobPositionId,
        jobRank,
        personalEmail,
        birthday,
        postalCode,
        address,
        accountLinkage,
        updateAt,
      } = employeeView;

      const organizationName = getOrganizationName(
        companyId,
        propsOrganizationId,
        '미지정',
      );

      const jobPositionName = getName(
        jobPositions,
        companyId,
        jobPositionId,
        '미지정',
      );

      const jobRankName = jobRank === 0 ? '미지정' : `${jobRank} 호봉`;

      const names = languages.map((language) => {
        const value = getText([nameKey, '미지정'], language);
        return { label: getText(language), value };
      });

      const avatar = `${getAvatarPath(employeeView.companyId, id)}/nocache`;

      return (
        <OrganizationChartContentEmployeeView
          useLink={useLink}
          key={`${id}/${updateAt}`}
          avatar={avatar}
          name={treeItem.text}
          organizationName={organizationName}
          email={email}
          names={names}
          enterDate={enterDate}
          no={no}
          companyPhoneNo={companyPhoneNo}
          extensionPhoneNo={extensionPhoneNo}
          mobilePhoneNo={mobilePhoneNo}
          affiliatedOrganizations={affiliatedOrganizations}
          jobPositionName={jobPositionName}
          jobRankName={jobRankName}
          task={task}
          personalEmail={personalEmail}
          birthday={birthday}
          birthdayLabel="음력"
          postalCode={postalCode}
          address={address}
          accountLinkage={accountLinkage}
          onUpdate={handleEmployeeUpdateDrawer}
          onDelete={handleEmployeeDeleteDialog}
        />
      );
    }

    return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;
  };

  const renderDrawer = () => {
    const rawCompanyId = queryParams.companyid || '';
    const companyId = b62(rawCompanyId) || principal.companyId;

    const { drawerType, drawerMode } = queryParams;

    if (drawerType === 'company' && drawerMode === 'update') {
      if (companyView === null || companyView === undefined)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const {
        representativesName,
        establishedDate,
        businessRegistrationNo,
        corporationRegistrationNo,
        businessType,
        businessItem,
        phoneNo,
        faxNo,
        postalCode,
        address,
        employeeCount,
        //
        nameKey,
      } = companyView;

      const names = languages.map((language) => {
        const value = getText([nameKey, ''], language);
        return { code: language, value };
      });

      return (
        <OrganizationChartContentCompanyEdit
          names={names}
          representativesName={representativesName}
          businessRegistrationNo={businessRegistrationNo}
          corporationRegistrationNo={corporationRegistrationNo}
          establishedDate={establishedDate}
          businessType={businessType}
          businessItem={businessItem}
          phoneNo={phoneNo}
          faxNo={faxNo}
          postalCode={postalCode}
          address={address}
          employeeCount={employeeCount}
          onUpdate={handleCompanyUpdate}
          onClose={handleDrawerClose}
        />
      );
    }

    if (drawerType === 'organization' && drawerMode === 'create') {
      const organizationId = propsOrganizationId ?? companyId;
      const itemId = getDirectoryTreeId(companyId, organizationId);
      const parentNames = getParentItems(items, itemId).map(({ text }) => text);

      const names = languages.map((language) => {
        return { label: language, value: '' };
      });

      return (
        <OrganizationChartContentOrganizationEdit
          companyId={companyId}
          parentId={organizationId}
          parentNames={parentNames}
          onCreate={handleOrganizationCreate}
          onClose={handleDrawerClose}
          names={names}
        />
      );
    }
    if (drawerType === 'organization' && drawerMode === 'update') {
      if (organizationView === null || organizationView === undefined)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const { id, parentId, managerId, description, nameKey } =
        organizationView;

      const itemId = getDirectoryTreeId(companyId, parentId);
      const parentNames = getParentItems(items, itemId).map(({ text }) => text);

      const names = languages.map((language) => {
        const value = getText([nameKey, ''], language);
        return { label: language, value };
      });
      const members = [{ id: 0, text: '미지정', avatar: '' }];
      items.forEach(({ parentId: treeParentId, text, avatar = '', extra }) => {
        if (treeParentId !== `${companyId}_${propsOrganizationId}`) return;
        if (extra.type !== 'employee') return;
        members.push({ id: extra.employeeId, text, avatar });
      });

      return (
        <OrganizationChartContentOrganizationEdit
          companyId={companyId}
          parentNames={parentNames}
          members={members}
          onUpdate={handleOrganizationUpdate}
          onClose={handleDrawerClose}
          id={id}
          parentId={parentId}
          names={names}
          managerId={managerId}
          description={description}
        />
      );
    }
    if (drawerType === 'organizationChange' && drawerMode === 'update') {
      if (organizationView === null || organizationView === undefined)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;
      const parentList = organizations
        .filter((a) => a.parentId === organizationView.parentId)
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

      return (
        <ChangeOrder
          title="순서변경"
          list={parentList.map(({ id, nameKey }) => {
            return {
              id,
              label: getText([nameKey], ''),
            };
          })}
          onChange={handleOrganizationChangeSeq}
          onClose={handleDrawerClose}
        />
      );
    }

    if (drawerType === 'employee' && drawerMode === 'create') {
      const organizationId = propsOrganizationId ?? companyId;
      const names = languages.map((language) => {
        return { label: language, value: '' };
      });
      const affiliatedOrganizations = [
        {
          id: organizationId,
          name: getName(organizations, companyId, organizationId),
          jobDutyId: 0,
          jobDutyName: '미지정',
          paths: getParentItems(
            items,
            parseDirectoryTreeItemId(companyId, organizationId),
          ).map(({ extra: { organizationName: name } }) => ({ name })),
        },
      ];

      return (
        <OrganizationChartContentEmployeeEdit
          email=""
          names={names}
          enterDate=""
          no=""
          companyPhoneNo=""
          extensionPhoneNo=""
          mobilePhoneNo=""
          affiliatedOrganizations={affiliatedOrganizations}
          representativeOrganizationId={organizationId}
          jobPositionId={0}
          jobRank={0}
          task=""
          personalEmail=""
          birthday=""
          lunarBirthdayUse={false}
          postalCode=""
          address=""
          avatar=""
          isUserAvatar={false}
          onCreate={handleEmployeeCreate}
          onClose={handleDrawerClose}
          jobPositionItems={jobPositionItems}
          jobRankItems={jobRankItems}
          inTeams={resource === 'teams'}
        />
      );
    }
    if (drawerType === 'employee' && drawerMode === 'update') {
      if (employeeView === null || employeeView === undefined)
        return <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>;

      const {
        email,
        enterDate,
        no,
        companyPhoneNo,
        extensionPhoneNo,
        mobilePhoneNo,
        representativeOrganizationId,
        jobPositionId,
        jobRank,
        task,
        personalEmail,
        birthday,
        lunarBirthdayUse,
        postalCode,
        address,
        isUserAvatar,
        nameKey,
        accountLinkage,
      } = employeeView;

      const names = languages.map((language) => {
        const value = getText([nameKey, ''], language);
        return { label: language, value };
      });
      const affiliatedOrganizations = organizationEmployees
        .filter(
          (a) => a.companyId === companyId && a.employeeId === propsEmployeeId,
        )
        .map((a) => {
          return {
            id: a.id,
            name: getName(organizations, a.companyId, a.id),
            jobDutyId: a.jobDutyId,
            jobDutyName: getName(jobDuties, a.companyId, a.jobDutyId, '미지정'),
            representative: a.id === employeeView.representativeOrganizationId,
            paths: getParentItems(
              items,
              parseDirectoryTreeItemId(a.companyId, a.id),
            ).map(({ extra: { organizationName: name } }) => ({ name })),
            updateAt: a.updateAt,
          };
        });

      return (
        <OrganizationChartContentEmployeeEdit
          email={email}
          names={names}
          enterDate={enterDate}
          no={no}
          companyPhoneNo={companyPhoneNo}
          extensionPhoneNo={extensionPhoneNo}
          mobilePhoneNo={mobilePhoneNo}
          representativeOrganizationId={representativeOrganizationId}
          jobPositionId={jobPositionId}
          jobRank={jobRank}
          task={task}
          personalEmail={personalEmail}
          birthday={birthday}
          lunarBirthdayUse={lunarBirthdayUse}
          postalCode={postalCode}
          address={address}
          affiliatedOrganizations={affiliatedOrganizations}
          avatar={`${getAvatarPath(
            employeeView.companyId,
            employeeView.id,
          )}/nocache`}
          isUserAvatar={isUserAvatar}
          accountLinkage={accountLinkage}
          onUpdate={handleEmployeeUpdate}
          onClose={handleDrawerClose}
          jobPositionItems={jobPositionItems}
          jobRankItems={jobRankItems}
          inTeams={resource === 'teams'}
        />
      );
    }

    return null;
  };

  const renderDialog = () => {
    const { dialogMode, dialogType } = queryParams;
    if (dialogMode !== 'delete') return null;

    const companyId = queryParams.companyId || principal.companyId;

    let title = '';
    let name = '';
    let onDelete: () => void;

    if (dialogType === 'organization') {
      if (!organizationView) return null;
      title = '조직을';
      name = getText(organizationView.nameKey);
      onDelete = handleOrganizationDelete;
    } else if (dialogType === 'organizationEmployee') {
      if (!employeeView) return null;
      title = '직원을';
      if (propsOrganizationId === undefined) return null;
      const organizationName = getName(
        organizations,
        companyId,
        propsOrganizationId,
      );
      const employeeName = getText(employeeView.nameKey);
      name = `${organizationName} 에서 ${employeeName}`;
      onDelete = handleOrganizationEmployeeDelete;
    } else if (dialogType === 'employee') {
      if (!employeeView) return null;
      title = '직원을';
      name = getText(employeeView.nameKey);
      onDelete = handleEmployeeDelete;
    } else return null;

    return (
      <DeleteConfirmation onSubmit={onDelete} onCancel={handleDialogClose}>
        <strong>&apos;{name}&apos;</strong> {title} 정말 삭제하시겠습니까?
      </DeleteConfirmation>
    );
  };

  const handleCloseView = () => {
    dispatch(
      sessionActions.setRoute({
        pathname: getPathMap('/*/*/*', props.pathname),
      }),
    );
  };

  const { loading } = state;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>조직도</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {propsOrganizationId ? (
            <EuiSetting.Right onClose={handleCloseView}>
              {renderView()}
            </EuiSetting.Right>
          ) : (
            <SplitUnselected />
          )}
        </EuiSetting>
      </EuiBody>
      <FeedBack text={validation} onClose={() => setValidation('')} />
      {renderDrawer()}
      {renderDialog()}
      {loading && <Loading />}
    </>
  );
}

export default OrganizationChartContainer;
