import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { companyActions } from '../../../../stores/directory/company';
import { employeeActions } from '../../../../stores/directory/employee';
import { userGroupActions } from '../../../../stores/directory/group';
import { jobDutyActions } from '../../../../stores/directory/jobduty';
import { jobPositionActions } from '../../../../stores/directory/jobposition';
import { organizationActions } from '../../../../stores/directory/organization';

function DirectoryUserGroupRoute({
  pathname,
  search,
}: {
  pathname: string;
  search: string;
}): null {
  // TODO languages session에서 불러오는 부분 전체 적용 해야함
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(companyActions.findList()),
      dispatch(organizationActions.findList()),
      dispatch(organizationActions.findEmployee({ id: 'all' })),
      dispatch(employeeActions.findList()),
      dispatch(jobPositionActions.findList()),
      dispatch(jobDutyActions.findList()),
    ]).then(() => {
      dispatch(userGroupActions.findList({ route: { pathname } }));
    });
  }, [dispatch, pathname, search]);

  return null;
}

export default DirectoryUserGroupRoute;
