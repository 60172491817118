import { useEffect } from 'react';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { documentActions } from '../../../../stores/approval/document';
import { preferencesActions } from '../../../../stores/approval/preferences';

function ApprovalDocumentRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const dispatch = useAppDispatch();
  const { pathname, search } = props;
  const pathParams = getPathParams<{ p1?: number }>(
    `/*/*/*/:p1$base62`,
    props.pathname,
  );
  const propsItemId = pathParams.p1;
  useEffect(() => {
    const params = getQueryParams(search);
    const { id } = getPathParams<{ id?: number }>('/*/*/*/:id$b62', pathname);
    if (params.contentMode === 'update' && id) {
      Promise.all([
        dispatch(preferencesActions.findBasic()),
        dispatch(preferencesActions.findDocumentNo()),
      ]).then(() => {
        dispatch(
          documentActions.adminconsoleView({
            id,
            route: {
              pathname,
              search: getQueryParams(params),
            },
          }),
        );
      });
    } else {
      if (propsItemId !== undefined) {
        dispatch(
          documentActions.adminconsoleView({
            id: propsItemId,
            route: {
              pathname,
              search: getQueryParams(params),
            },
          }),
        );
        return;
      }
      dispatch(
        documentActions.adminconsoleList({
          search,
          route: {
            pathname,
            search: getQueryParams(params),
          },
        }),
      );
    }
  }, [dispatch, propsItemId, props]);

  return null;
}

export default ApprovalDocumentRoute;
