/* eslint-disable prettier/prettier */
import axios from 'axios';
import {
  apiError,
  EntityIdKeyable,
  getApiConfig
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 검색 매개변수를 생성합니다.
 * @param searchCode 검색 코드.
 * @param searchWord 검색 단어.
 * @returns
 */
 function createSearchParams(arg: {
  searchCode?: string;
  searchWord?: string;
}): { searchcode?: string; searchword?: string } {
  const { searchCode = '', searchWord = '' } = arg;
  if (searchWord.trim() !== '') {
    if (searchCode.trim() === '')
      return { searchcode: undefined, searchword: searchWord };
    return { searchcode: searchCode, searchword: searchWord };
  }
  return { searchcode: undefined, searchword: undefined };
 }

/** 양식 폴더 목록 조회. */
async function findFolderList(
    parentid?: number
): Promise<
  {
    id: number;
    parentId: number;
    seq: number;
    name: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/document/v1/adminconsole/form/folder/all/simply`;
    if (parentid) url += `&parentid=${parentid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 등록. */
async function createFolder(arg: {
  parentId: number;
  name: string;
}): Promise<{
  id: number;
  seq: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 수정. */
async function updateFolder(arg: {
  id: number;
  parentId: number;
  name: string;
  updateAt: string;
}): Promise<{
  id: number;
  seq: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 순서 변경. */
async function sortFolder(arg: {
  parentId: number;
  id: number;
  seq: number;
  updateAt: string;
}[]): Promise<{
  id: number;
  seq: number;
  updateAt: string;
}[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form/folder/sequence/all`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 삭제 */
async function deleteFolder(arg: {
  id: number;
  updateAt: string;
}): Promise<EntityIdKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 총 수 조회. */
async function findTotalCount(arg: {
  folderId?: number;
  searchCode?: string; // 'folder' | 'form';
  searchWord?: string;
}): Promise<number> {
  try {
    const { folderId, searchCode, searchWord } = arg;

    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form/totalcount`;
    const params = {
      parentid: folderId,
      ...createSearchParams({ searchCode, searchWord }),
    };
    const response = await axios.get<{totalCount: number}>(url, { headers, params });
    return response.data.totalCount;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 목록 조회. */
async function findList(arg: {
  folderId?: number;
  pageNo: number;
  rowsPerPage: number;
  searchCode: string;
  searchWord: string;
}): Promise<
  {
    id: number;
    folderId: number;
    name: string;
    isEnable: boolean;
    updateAt: string;
    createAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const { folderId, pageNo, rowsPerPage, searchCode, searchWord } = arg;
    const url = `${host}/api/document/v1/adminconsole/form/all/simply`;
    const params = { 
      folderid: folderId,
      pageno: pageNo,
      rowsperpage: rowsPerPage,
      searchcode: searchCode,
      searchword: searchWord,
    }
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 조회. */
async function findView(id: number): Promise<{
  companyId: number;
  id: number;
  folderId: number;
  name: string;
  isEnable: boolean;
  content: string;
  description: string;
  createAt: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form?id=${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
    } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 양식 조회. */
async function findUserView(id: number): Promise<{
  companyId: number;
  id: number;
  folderId: number;
  name: string;
  isEnable: boolean;
  content: string;
  description: string;
  createAt: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/document/form?id=${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
    } catch (e) {
    throw apiError(e);
  }
}

/** 양식 이름 조회. */
async function findNameList(id: number[]): Promise<{
  id:number;
  name:string
}[]> {
  try {
    const { host, headers } = getApiConfig();
    const ids = id.map((a) => `&ids=${a}`).join('').replace(/&/, '') || ''
    const url = `${host}/api/document/v1/adminconsole/form/name/all?${ids}`;
    const response = await axios.get(url, { headers });
    return response.data;
    } catch (e) {
    throw apiError(e);
  }
}

/** 양식 등록 */
async function createForm(arg: {
  folderId: number;
  isEnable: boolean;
  name: string;
  description: string;
  content: string;
}): Promise<EntityIdKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form`;
    const { folderId, isEnable, name, description, content } = arg;
    const params = {
      folderId,
      isEnable,
      name,
      description,
      content,
    }
    const response = await axios.post(url, params, {headers})
    return response.data;  
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 수정 */
async function updateForm(arg: {
  id: number;
  folderId: number;
  name: string;
  isEnable: boolean;
  description: string;
  content: string;
  updateAt: string;
}): Promise<EntityIdKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form`;
    const params = {
      id: arg.id,
      folderId: arg.folderId,
      name: arg.name,
      isEnable: arg.isEnable,
      description: arg.description,
      content: arg.content,
      updateAt: arg.updateAt
    }
    const response = await axios.put(url, params, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 삭제. */
async function deleteForm(arg: {
  id: number;
  updateAt: string;
}): Promise<{ id: number; updateAt: string }>;
async function deleteForm(
  arg: { id: number; updateAt: string }[],
): Promise<{ id: number; updateAt: string }[]>;
async function deleteForm(
  arg: { id: number; updateAt: string } | { id: number; updateAt: string }[],
): Promise<
  | {
      id: number;
      updateAt: string;
    }
  | {
      id: number;
      updateAt: string;
    }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/document/v1/adminconsole/form/all`
      : `${host}/api/document/v1/adminconsole/form`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 이동. */
async function moveForm(arg: {
  id: number;
  folderId: number;
  updateAt: string;
}[]): Promise<EntityIdKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/form/move/all`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export const formFolderApi = {
  list: findFolderList,
  create: createFolder,
  update: updateFolder,
  sort: sortFolder,
  delete: deleteFolder,
};

const documentFormApi = {
  totalCount: findTotalCount,
  list: findList,
  view: findView,
  userView: findUserView,
  nameList: findNameList,
  create: createForm,
  update: updateForm,
  delete: deleteForm,
  move: moveForm,
};

export default documentFormApi;
