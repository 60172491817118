import React from 'react';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import ResourceFolderContainer from '../pages/adminconsole/folder/ResourceFolderContainer';
import ResourcePreferencesContainer from '../pages/adminconsole/preferences/ResourcePreferencesContainer';
import ResourceAdminComposeContainer from '../pages/adminconsole/reservation/ResourceAdminComposeContainer';
import ResourceReservationContainer from '../pages/adminconsole/reservation/ResourceReservationContainer';

function AdminconsoleLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element | null {
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', props.pathname);
  const { contentMode } = getQueryParams(search);

  // 자원등록관리
  if (menu === 'folder')
    return <ResourceFolderContainer pathname={pathname} search={search} />;
  // 전사예약내역
  if (menu === 'reservation') {
    if (contentMode === 'create')
      return (
        <ResourceAdminComposeContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    return <ResourceReservationContainer pathname={pathname} search={search} />;
  }
  // 기본설정
  return <ResourcePreferencesContainer />;
}

export default AdminconsoleLocateContainer;
