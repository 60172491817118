import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactDrawer from '../pages/root/contact/common/ContactDrawer';

function ContatctLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;

  return (
    <ContactDrawer key={i18n.language} pathname={pathname} search={search} />
  );
}

export default ContatctLocateDrawer;
