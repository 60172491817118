import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldAdd(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <>
      <ComponentSampleUI.Content title="Add start">
        <TextField
          placeholder="website address"
          value={value}
          onChange={handleChange}
          prepend="https://"
        />
      </ComponentSampleUI.Content>
      <ComponentSampleUI.Content title="Add end">
        <TextField
          placeholder="Price"
          value={value}
          onChange={handleChange}
          align="right"
          append="원"
        />
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentTextFieldAdd;
