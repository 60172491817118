import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import MenuItem from '../../../../../components/menu/MenuItem';

function ComponentNavAvatar(): JSX.Element {
  const user: { id: number; name: string; avatar: string }[] = [
    { id: 100, name: '강명호', avatar: '/images/faces/faces_1.jpg' },
    { id: 200, name: '이성계', avatar: '/images/faces/faces_2.jpg' },
    { id: 300, name: '이상윤', avatar: '/images/faces/faces_3.jpg' },
    { id: 500, name: '홍길동', avatar: '' },
  ];

  return (
    <ComponentSampleUI.Content title="Avatar">
      <div>
        {user.map((x) => (
          <MenuItem key={x.id} label={x.name} avatar={x.avatar} />
        ))}
      </div>
    </ComponentSampleUI.Content>
  );
}

export default ComponentNavAvatar;
