import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Switch from '../../../../../components/switch/Switch';

function ComponentSwitchSize(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Size">
      <Switch label="Switch" size="sm" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentSwitchSize;
