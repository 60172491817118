import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Drawer from '../../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../../components/post/PostWrite';
import TextField from '../../../../../../components/textfield/TextField';
import {
  getParentItems,
  getText,
} from '../../../../../../groupware-common/utils';
import DropMenu from '../../../../../../components/selectField/DropMenu';
import TreePicker from '../../../../../../groupware-webapp/pages/popup/TreePicker';
import { IconType } from '../../../../../../groupware-common/types/icon';
import {
  FolderView,
  replaceRetentionPeriodToString,
  PermissionUserType,
  PermissionType,
  SaveFolderArg,
  PermissionOptionType,
} from '../../../../../stores/document/folders';
import ChipGroup from '../../../../../../components/chip/ChipGroup';
import Chip from '../../../../../../components/chip/Chip';
import DocumentPermissionDialogContainer from './DocumentPermissionDialogContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';

import { DirectoryTreeItemArg } from '../../../../../../components/tree/DirectoryTree';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import DirectoryMenuTreeContainer from '../../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import DrawerAction from '../../../../../../components/drawer/DrawerAction';
import Button from '../../../../../../components/button/Button';
import FeedBack from '../../../../../../components/alert/FeedBack';
import DocumentWorkForm from '../../form/DocumentWorkForm';
import AddAttachForm from '../../../../../../components/attachments/AddAttachForm';
import { CustomNumbers } from '../../../../../../groupware-common/types';
import Switch from '../../../../../../components/switch/Switch';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import RadioGroup from '../../../../../../components/radio/RadioGroup';

interface Props {
  search: string;
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  parentId: number | undefined;
  folder?: FolderView;
  onClose(): void;
  onSave(data: SaveFolderArg): void;
}

/** 권한 조회 */
export const handleMatchingPermissions = (
  arg: PermissionOptionType,
): string[] => {
  const matchingPermissions: string[] = [];
  if (arg.useRead) {
    matchingPermissions.push('읽기');
  }
  if (arg.useWrite) {
    matchingPermissions.push('쓰기/수정');
  }
  return matchingPermissions;
};

function DocumentFolderEditDrawer(props: Props): JSX.Element {
  const directory = useDirectory();
  const organizations = useSelector(
    (s: RootState) => s.directory.organization.list.data.items,
  );

  /** 직위 */
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );

  /** 직책 */
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );

  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );

  /** 폴더 수정 시 버전 포맷팅 */
  const handleVersionCheck = (option: {
    isComment: boolean;
    isVersion: boolean;
    isRequiredVersion: boolean;
  }): number => {
    if (!option.isVersion) {
      return 0;
    }
    if (option.isVersion && !folder?.option.isRequiredVersion) {
      return 1;
    }
    if (option.isRequiredVersion) {
      return 2;
    }
    return 0;
  };

  const { folder, folders, parentId } = props;
  const initialState = folder
    ? {
        id: folder.id,
        seq: folder.seq,
        paths: getParentItems(folders, folder.parentId).map(({ text }) => text),
        parentId,
        isEnable: folder.isEnable,
        name: folder.name,
        description: folder.description,
        forms: folder.forms,
        isComment: folder.option.isComment,
        versionType: handleVersionCheck(folder.option),
        isCheckout: folder.option.isCheckout,
        defaultRetentionPeriod: folder.defaultRetentionPeriod,
        retentionPeriods: folder.retentionPeriods,
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',

        users: folder.users,
        managers: folder.managers,
        excluders: folder.excluders,

        updateAt: folder.updateAt,
        parentSelectDialogVisible: false,
        permissionDialogVisible: false,
        managerMenuPoint: undefined,
        excluderMenuPoint: undefined,
        validation: '',
        formSelectDialogVisible: false,
      }
    : {
        id: undefined,
        seq: 0,
        folerId: 0,
        parentId,
        paths: parentId
          ? getParentItems(folders, parentId).map(({ text }) => text)
          : ['상위 문서함'],
        name: '',
        description: '',
        isEnable: true,
        forms: [],
        isComment: false,
        versionType: 0,
        isCheckout: false,
        isRequiredVersion: false,

        defaultRetentionPeriod: '',
        retentionPeriods: [],
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',

        users: [],
        managers: [],
        excluders: [],

        parentSelectDialogVisible: false,
        permissionDialogVisible: false,
        managerMenuPoint: undefined,
        excluderMenuPoint: undefined,
        validation: '',
        updateAt: '',
        formSelectDialogVisible: false,
      };

  const [state, setState] = useState<{
    id?: number;
    parentId?: number;
    seq: number;
    name: string;
    paths: string[];
    description: string;
    isEnable: boolean;
    isComment: boolean;
    versionType: number;
    isCheckout: boolean;
    forms: { id: number; name: string }[];
    updateAt: string;

    retentionYear: string;
    retentionMonth: string;
    retentionDay: string;
    retentionPeriods: { id: string; checked: boolean }[];
    defaultRetentionPeriod: string;

    users: PermissionUserType[];
    managers: PermissionType[];
    excluders: PermissionType[];

    managerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    excluderMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    parentSelectDialogVisible: boolean;
    permissionDialogVisible: boolean;
    validation: string;
    formSelectDialogVisible: boolean;
  }>(initialState);

  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );

  /** 이름 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: event.target.value }));
  };

  /** 설명 변경. */
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };

  /** 상위 선택 대화 상자 열기. */
  const handleParentSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: true,
    }));
  };

  /** 상위 선택 대화 상자 닫기. */
  const handleParentSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: false,
    }));
  };

  /** 상위 선택 대화 상자 확인. */
  const handleParentSelectDialogConfirm = (
    id: number,
    text: string,
    path: string[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      paths: path,
      parentId: id,
      parentSelectDialogVisible: false,
    }));
  };

  /** 공유자 대화 상자 닫기. */
  const handlePermissionDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      permissionDialogVisible: false,
    }));
  };

  /** 관리자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { managerMenuPoint } = state;
    if (event !== undefined && managerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        managerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        managerMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 아이템 추가. */
  const handleManagerAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.managers.some((a) => a.referenceId === employeeId))
          return { ...prev, managerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, managerMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          managers: [
            ...prev.managers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
            },
          ],
        };
      });
    }
  };

  const stringReplace = (value: string) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  };

  /** 관리자 아이템 삭제. */
  const handleManagerDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      managers: prev.managers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 예외자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggleExcluder = (event?: React.MouseEvent) => {
    const { excluderMenuPoint } = state;
    if (event !== undefined && excluderMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: undefined,
      }));
    }
  };

  /** 예외자 아이템 추가. */
  const handleExcluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.excluders.some(
            (a) => a.referenceType === 'COMPANY' && a.referenceId === companyId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };
        return {
          ...prev,
          excluders: [
            ...prev.excluders,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 'COMPANY',
            },
          ],
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.excluders.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          excluders: [
            ...prev.excluders,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
            },
          ],
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.excluders.some((a) => a.referenceId === employeeId))
          return { ...prev, excluderMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          excluders: [
            ...prev.excluders,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
            },
          ],
        };
      });
    }
  };

  /** 예외자 아이템 삭제. */
  const handleExcluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      excluders: prev.excluders.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 공유자 대화 상자 저장 */
  const handlePermissionDialogSave = (users: PermissionUserType[]) => {
    setState((prevState) => ({
      ...prevState,
      permissionDialogVisible: false,
      users,
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 사용여부 변경 */
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, isEnable: event.target.checked }));
  };

  /** 체크인,체크아웃 사용여부 변경 */
  const handleCheckOutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      isCheckout: event.target.checked,
    }));
  };
  /** 권한 등록 */
  const handlePermissionOpenDialog = () => {
    setState((prev) => ({ ...prev, permissionDialogVisible: true }));
  };

  /** 권한 삭제 */
  const handleAdminstratorsDelete = (id: number, referenceType: string) => {
    const { users } = state;
    setState((prev) => ({
      ...prev,
      users: users.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 양식 선택 대화 상자 닫기. */
  const handleFormSelectDialogClose = () => {
    setState((prevState) => ({ ...prevState, formSelectDialogVisible: false }));
  };

  /** 양식 선택 대화 상자 확인. */
  const handleFormSelectDialogConfirm = (
    arg: { id: number; name: string }[],
  ) => {
    const forms = arg.map((a) => ({ ...a }));
    setState((prevState) => {
      const prevForms = prevState.forms;
      let validation = '';

      for (let i = 0; i < forms.length; i += 1) {
        const form = forms[i];
        if (prevForms.findIndex(({ id }) => form.id === id) > -1) {
          validation = `${form.name} 는(은) 이미 선택된 양식 입니다.`;
          break;
        }
      }

      if (validation !== '') return { ...prevState, validation };

      return {
        ...prevState,
        forms: [...prevForms, ...forms],
        formSelectDialogVisible: false,
      };
    });
  };

  const renderDialog = () => {
    const {
      parentSelectDialogVisible,
      permissionDialogVisible,
      formSelectDialogVisible,
    } = state;
    if (parentSelectDialogVisible) {
      const treeFolders = props.folders.filter(
        (v) => v.id !== props.folder?.id,
      );
      return (
        <TreePicker
          title="상위 폴더 선택"
          list={treeFolders}
          selectedId={props.parentId ?? parentId}
          rootId={0}
          onSelected={handleParentSelectDialogConfirm}
          onClose={handleParentSelectDialogClose}
        />
      );
    }
    if (permissionDialogVisible) {
      const { users } = state;
      return (
        <DocumentPermissionDialogContainer
          users={users}
          onSave={handlePermissionDialogSave}
          onClose={handlePermissionDialogClose}
        />
      );
    }
    if (formSelectDialogVisible) {
      return (
        <DocumentWorkForm
          onConfirm={handleFormSelectDialogConfirm}
          onClose={handleFormSelectDialogClose}
          search={props.search}
        />
      );
    }
    return null;
  };

  /** 양식 선택 대화 상자 열기. */
  const handleFormSelectDialogOpen = () => {
    setState((prevState) => ({ ...prevState, formSelectDialogVisible: true }));
  };

  /** 문서 폴더 저장 */
  const handleSave = () => {
    if (state.parentId === undefined) {
      setState((prev) => ({ ...prev, validation: '상위 폴더를 선택하세요.' }));
      return;
    }
    if (state.name.trim() === '') {
      setState((prev) => ({ ...prev, validation: '폴더명을 입력하세요.' }));
      return;
    }
    if (state.managers.length === 0) {
      setState((prev) => ({
        ...prev,
        validation: '관리자를 1명 이상 설정하세요.',
      }));
      return;
    }

    const defaultRetentionPeriod =
      state.retentionPeriods.find((a) => a.checked)?.id ?? '';

    if (retentionPeriods.length > 0 && defaultRetentionPeriod === '') {
      setState((prev) => ({
        ...prev,
        validation:
          '대표로 사용할 보존기간을 선택해주세요. 마우스 오른쪽 클릭으로 대표 설정이 가능합니다.',
      }));
      return;
    }

    const { users, managers, excluders } = state;

    const permissions: {
      referenceCompanyId: number; // 사용자 회사 아이디
      referenceId: number; // 사용자 아이디 (organization | employee)
      referenceType: string; // 참조 유형
    }[] = [];
    state.managers.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.users.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.excluders.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });

    let isDuplicate = false;
    // 중복 확인
    for (let i = 0; i < permissions.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (
          permissions[i].referenceType === permissions[j].referenceType &&
          permissions[i].referenceId === permissions[j].referenceId
        ) {
          isDuplicate = true;
        }
      }
    }

    if (isDuplicate) {
      setState((prev) => ({
        ...prev,
        validation:
          '관리자 또는 공유자, 예외자 인원을 중복으로 설정할 수 없습니다.',
      }));
      return;
    }

    const { onSave } = props;
    const arg = {
      id: state.id,
      seq: state.seq,
      name: state.name.trim(),
      description: state.description,
      parentId: state.parentId,
      isEnable: state.isEnable,
      forms: state.forms !== undefined ? state.forms : [],
      isComment: false, // 문서 댓글 사용안함. 프론트에서만 제거
      isVersion: state.versionType !== 0,
      isCheckout: state.isCheckout,
      isRequiredVersion: state.versionType === 2,
      users,
      managers,
      excluders,
      defaultRetentionPeriod,
      retentionPeriods,
      updateAt: state.updateAt,
    };

    onSave(arg);
  };

  const handleRetentionCheckedChange = (
    id: string,
    event: React.MouseEvent,
  ) => {
    const { retentionPeriods } = state;
    event.stopPropagation();
    event.preventDefault();

    setState((prev) => ({
      ...prev,
      retentionPeriods: retentionPeriods.map((a) => {
        if (a.id !== id) return { ...a, checked: false };
        return { ...a, checked: true };
      }),
    }));
  };

  /** 보존기간 변경. */
  const handleRetentionYearChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionYear = stringReplace(event.target.value);
    setState((prevState) => ({ ...prevState, retentionYear }));
  };

  /** 보존 월 변경. */
  const handleRetentionMonthChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionMonth = stringReplace(event.target.value);
    setState((prevState) => ({ ...prevState, retentionMonth }));
  };

  /** 보존 일 변경. */
  const handleRetentionDayChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const retentionDay = stringReplace(event.target.value);
    setState((prevState) => ({ ...prevState, retentionDay }));
  };

  /** 보존기간 추가 */
  const handleRetentionPeriodAdd = () => {
    const retentionYear = state.retentionYear.trim();
    const retentionMonth = state.retentionMonth.trim();
    const retentionDay = state.retentionDay.trim();

    if (retentionYear === '' && retentionMonth === '' && retentionDay === '') {
      setState((prevState) => ({
        ...prevState,
        retentionYear: '',
        retentionMonth: '',
        retentionDay: '',
        validation: '보존기간을 입력 해주세요.',
      }));
      return;
    }

    let defaultRetentionPeriod = '';
    if (retentionYear !== '') defaultRetentionPeriod += `${retentionYear}Y`;
    if (retentionMonth !== '') defaultRetentionPeriod += `${retentionMonth}M`;
    if (retentionDay !== '') defaultRetentionPeriod += `${retentionDay}D`;

    if (state.retentionPeriods.find((a) => a.id === defaultRetentionPeriod)) {
      setState((prevState) => ({
        ...prevState,
        validation: '보존기간을 중복으로 설정할 수 없습니다.',
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      retentionYear: '',
      retentionMonth: '',
      retentionDay: '',
      retentionPeriods: [
        ...prevState.retentionPeriods,
        {
          id: defaultRetentionPeriod,
          checked: false,
        },
      ],
    }));
  };

  /** 보존기간 영구 추가 */
  const handleChangeForeverUse = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setState((prev) => ({
        ...prev,
        retentionPeriods: [
          ...prev.retentionPeriods,
          {
            id: 'FOREVER',
            checked: false,
          },
        ],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        retentionPeriods: prev.retentionPeriods.filter(
          (a) => a.id !== 'FOREVER',
        ),
      }));
    }
  };

  /** 보존기간 순서 변경 */
  const handleRetentionListOrderChange = (
    list: { id: string; checked: boolean }[],
  ) => {
    const { retentionPeriods } = state;
    let changed = list.length !== retentionPeriods.length;
    list.forEach((item, i) => {
      if (list[i].id !== retentionPeriods[i].id) {
        changed = true;
        return false;
      }
      return true;
    });

    if (changed) {
      setState((prevState) => ({ ...prevState, retentionPeriods: list }));
    }
  };

  /** 보존기간 리스트 아이템 삭제 */
  const handleRetentionPeriodDelete = (id: string) => {
    setState((prevState) => ({
      ...prevState,
      retentionPeriods: prevState.retentionPeriods.filter((x) => x.id !== id),
    }));
  };

  const handleVersionChange = (value: number) => {
    setState((prevState) => ({ ...prevState, versionType: value }));
  };

  const title = folder ? '폴더 수정' : '폴더 등록';
  const {
    forms,
    retentionPeriods,
    retentionYear,
    retentionMonth,
    retentionDay,
  } = state;

  return (
    <>
      <Drawer title={title} size="sm" onClose={props.onClose}>
        <DrawerBody className="ui-document-company">
          <PostWrite>
            <PostWrite.Item title="상위 폴더">
              <DropMenu
                value={state.paths}
                label="상위 폴더 선택"
                onClick={handleParentSelectDialogOpen}
              />
            </PostWrite.Item>
            <PostWrite.Item required title="폴더명">
              <TextField value={state.name} onChange={handleNameChange} />
            </PostWrite.Item>
            <PostWrite.Item title="설명">
              <TextField
                multiline
                rows={2}
                value={state.description}
                onChange={handleDescriptionChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="사용여부">
              <Switch checked={state.isEnable} onChange={handleStatusChange} />
            </PostWrite.Item>
            {/* <PostWrite.Item title="댓글">
              <Switch
                checked={state.isComment}
                onChange={handleUseCommentChange}
              />
            </PostWrite.Item> */}
            <PostWrite.Item title="체크아웃">
              <Switch
                checked={state.isCheckout}
                onChange={handleCheckOutChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="버전관리">
              <RadioGroup
                data={[
                  { value: 0, label: '사용 안함' },
                  { value: 1, label: '사용' },
                  { value: 2, label: '필수' },
                ]}
                value={state.versionType}
                name="version"
                onChange={handleVersionChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="양식">
              <div className="select-action" style={{ marginTop: '8px' }}>
                <AddAttachForm
                  data={
                    forms
                      ? forms.map((a) => {
                          return { id: a.id, subject: a.name };
                        })
                      : []
                  }
                  onRemove={(id: number) => {
                    setState((prev) => ({
                      ...prev,
                      forms: prev.forms?.filter((a) => a.id !== id),
                    }));
                  }}
                  onAllRemove={() => {
                    setState((prev) => ({
                      ...prev,
                      forms: [],
                    }));
                  }}
                  onOpen={handleFormSelectDialogOpen}
                />
              </div>
            </PostWrite.Item>
            <PostWrite.Item title="보존기간">
              <div className="ui-retention-input">
                <div>
                  <TextField
                    label="년"
                    value={retentionYear}
                    onChange={handleRetentionYearChange}
                    width={80}
                    maxLength={3}
                    pattern="[0-9]*"
                  />
                  <span style={{ marginLeft: '8px' }}>
                    <TextField
                      label="개월"
                      value={retentionMonth}
                      onChange={handleRetentionMonthChange}
                      width={80}
                      maxLength={3}
                      pattern="[0-9]*"
                    />
                  </span>
                  <span style={{ marginLeft: '8px' }}>
                    <TextField
                      label="일"
                      value={retentionDay}
                      onChange={handleRetentionDayChange}
                      width={80}
                      maxLength={3}
                      pattern="[0-9]*"
                    />
                  </span>
                  <Button
                    text="추가"
                    iconType
                    icon="plus-circle-fill"
                    onClick={handleRetentionPeriodAdd}
                    className="selector-toggle"
                    size="sm"
                  />
                  <div className="ui-retention-input">
                    <Checkbox
                      name="FOREVER"
                      label="영구사용"
                      checked={retentionPeriods.some((a) => a.id === 'FOREVER')}
                      onChange={handleChangeForeverUse}
                    />
                  </div>
                </div>
                <div>
                  <>
                    <ReactSortable
                      className="ui-change-order"
                      list={retentionPeriods}
                      setList={handleRetentionListOrderChange}
                      animation={200}
                    >
                      {retentionPeriods.map((a) => (
                        <div
                          key={a.id}
                          className="list-item"
                          onContextMenu={(event) =>
                            handleRetentionCheckedChange(a.id, event)
                          }
                        >
                          <div className="handle" />
                          <input
                            disabled
                            className="input"
                            type="text"
                            value={replaceRetentionPeriodToString(a.id)}
                          />
                          {a.checked && <div className="badge" />}
                          <Button
                            className="delete"
                            text="삭제"
                            iconType
                            icon="trash-full"
                            onClick={() => handleRetentionPeriodDelete(a.id)}
                          />
                        </div>
                      ))}
                    </ReactSortable>
                  </>
                </div>
              </div>
            </PostWrite.Item>
            <PostWrite.Item
              required
              title="관리자"
              tooltip="관리자는 직원만 선택가능합니다."
            >
              <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
                {state.managers
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 'COMPANY') return -1;
                      if (a.referenceType === 'ORGANIZATION') {
                        if (b.referenceType === 'COMPANY') return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      if (referenceType === 'EMPLOYEE') {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            etc="관리자"
                            avatar={DirectoryData.avatar}
                            onDelete={() =>
                              handleManagerDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      return null;
                    },
                  )}
                {state.managerMenuPoint && (
                  <DirectoryMenuTreeContainer
                    deduplication
                    point={state.managerMenuPoint}
                    typeToFilter="employee"
                    onItemClick={handleManagerAppend}
                    onClose={handleDirectoryTreeMenuToggle}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>

            <PostWrite.Item title="공유자">
              <ChipGroup add="추가" onAdd={handlePermissionOpenDialog}>
                {state.users
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 'COMPANY') return -1;
                      if (a.referenceType === 'ORGANIZATION') {
                        if (b.referenceType === 'COMPANY') return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                      options,
                    }) => {
                      if (referenceType === 'COMPANY') {
                        // 회사일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getCompanyName(companyId)}
                            icon="company"
                            etc={handleMatchingPermissions(options).join('/')}
                            onDelete={() =>
                              handleAdminstratorsDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'ORGANIZATION') {
                        // 부서일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            etc={handleMatchingPermissions(options).join('/')}
                            icon="sitemap-fill"
                            onDelete={() =>
                              handleAdminstratorsDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'EMPLOYEE') {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            etc={handleMatchingPermissions(options).join('/')}
                            avatar={DirectoryData.avatar}
                            onDelete={() =>
                              handleAdminstratorsDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      return null;
                    },
                  )}
              </ChipGroup>
            </PostWrite.Item>

            <PostWrite.Item title="예외자">
              <ChipGroup
                add="추가"
                onAdd={handleDirectoryTreeMenuToggleExcluder}
              >
                {state.excluders
                  .map((a) => {
                    let jobClassSeq = 0;
                    if (a.referenceType === 'EMPLOYEE') {
                      const employeeData = getDirectoryData({
                        ...directory,
                        companyId: a.referenceCompanyId,
                        employeeId: a.referenceId,
                      });
                      // 직위 또는 직책 순서로 정렬 순서 결정.
                      jobClassSeq =
                        (jobClassType === 'jobduty'
                          ? jobDuties.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                v.id === employeeData.jobDutyId,
                            )?.seq
                          : jobPositions.find(
                              (v) =>
                                v.companyId === a.referenceCompanyId &&
                                employeeData.jobPositionId === v.id,
                            )?.seq) || CustomNumbers.SMALLINT_MAX;
                    }
                    return {
                      ...a,
                      jobClassSeq,
                    };
                  })
                  // 회사 → 부서 → 직원 순으로 정렬 후
                  // 직위 또는 직책 순번이 낮을수록 밑으로.
                  .sort((a, b) => {
                    if (a.referenceType !== b.referenceType) {
                      if (a.referenceType === 'COMPANY') return -1;
                      if (a.referenceType === 'ORGANIZATION') {
                        if (b.referenceType === 'COMPANY') return 1;
                        return -1;
                      }
                      return 1;
                    }
                    if (a.referenceType === 'EMPLOYEE') {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                    }
                    return 0;
                  })
                  .map(
                    ({
                      referenceCompanyId: companyId,
                      referenceId: id,
                      referenceType,
                    }) => {
                      if (referenceType === 'COMPANY') {
                        // 회사일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getCompanyName(companyId)}
                            icon="company"
                            etc="예외자"
                            onDelete={() =>
                              handleExcluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'ORGANIZATION') {
                        // 부서일 경우
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={getOrganizationName(companyId, id)}
                            icon="sitemap-fill"
                            etc="예외자"
                            onDelete={() =>
                              handleExcluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      if (referenceType === 'EMPLOYEE') {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            etc="예외자"
                            avatar={DirectoryData.avatar}
                            onDelete={() =>
                              handleExcluderDelete(id, referenceType)
                            }
                          />
                        );
                      }
                      return null;
                    },
                  )}
                {state.excluderMenuPoint && (
                  <DirectoryMenuTreeContainer
                    point={state.excluderMenuPoint}
                    typeToFilter={['employee', 'organization', 'company']}
                    onItemClick={handleExcluderAppend}
                    onClose={handleDirectoryTreeMenuToggleExcluder}
                  />
                )}
              </ChipGroup>
            </PostWrite.Item>
          </PostWrite>
          {renderDialog()}
        </DrawerBody>
        <DrawerAction>
          <Button
            noDuplication={state.validation === ''}
            text={getText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default DocumentFolderEditDrawer;
