import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import MenuItem from '../../../../../components/menu/MenuItem';
import { IconType } from '../../../../../groupware-common/types/icon';

function ComponentNavBasic(): JSX.Element {
  const [state, setState] = useState<{
    selectedId: number | null;
  }>({
    selectedId: null,
  });
  const menu: {
    id: number;
    label: string;
    icon: IconType;
    count?: number;
  }[] = [
    { id: 100, label: '전체메일', icon: 'archive', count: 9999 },
    { id: 200, label: '보낸메일함', icon: 'send', count: 1 },
    {
      id: 300,
      label: '스팸메일함',
      icon: 'ban',
      count: 0,
    },
    {
      id: 400,
      label: '휴지통',
      icon: 'trash',
      count: 0,
    },
  ];

  const handleLink = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedId: id,
    }));
  };

  return (
    <ComponentSampleUI.Content title="Basic Nav">
      <div>
        {menu.map((x) => (
          <MenuItem
            key={x.id}
            label={x.label}
            icon={x.icon}
            count={x.count}
            selected={x.id === state.selectedId}
            onClick={() => handleLink(x.id)}
          />
        ))}
      </div>
    </ComponentSampleUI.Content>
  );
}

export default ComponentNavBasic;
