import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPathParams } from '../../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../../groupware-webapp/app/store';
import { noticeActions } from '../../../../../stores/attendance/notice';
import { attendancePreferencesActions } from '../../../../../stores/attendance/preferences';
import { sendNoticeActions } from '../../../../../stores/attendance/sendNotice';

function AttendanceTeamsNoticeRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const principal = useSelector((state: RootState) => state.session.principal);
  const dispatch = useAppDispatch();

  const { id } = getPathParams<{
    id: number;
  }>('/*/*/:id', pathname);

  useEffect(() => {
    Promise.all([
      // 연차사용촉진알림 양식 조회
      dispatch(attendancePreferencesActions.findNotice()),
      dispatch(
        noticeActions.list({ employeeId: principal.employeeId, search: '' }),
      ),
    ]).then(() => {
      // 연차사용촉진알림 문서 조회
      dispatch(
        sendNoticeActions.findFormTeamsView({
          id,
          route: {
            pathname,
            search,
          },
        }),
      );
    });
  }, [dispatch, pathname, search]);

  return null;
}

export default AttendanceTeamsNoticeRoute;
