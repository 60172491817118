import React from 'react';
import ComponentSimpleSearch from './ComponentSimpleSearch';

function ComponentSearch(): JSX.Element {
  return (
    <>
      <ComponentSimpleSearch />
    </>
  );
}

export default ComponentSearch;
