import React, { useState } from 'react';
import Dialog from '../../../components/dialog/Dialog';
import DialogHeader from '../../../components/dialog/DialogHeader';
import DialogBody from '../../../components/dialog/DialogBody';
import DialogTitle from '../../../components/dialog/DialogTitle';
import PostWrite from '../../../components/post/PostWrite';
import TextField from '../../../components/textfield/TextField';
import DialogFooter from '../../../components/dialog/DialogFooter';
import Button from '../../../components/button/Button';

function Security(props: {
  title: string;
  key?: string;
  onConfirm(password: string): void;
  onClose(): void;
}) {
  const { title, key, onConfirm, onClose } = props;

  const [password, setPassword] = useState(key || '');

  return (
    <Dialog size="xs">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostWrite>
          <PostWrite.Item>
            <TextField
              label="비밀번호"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button text="취소" onClick={onClose} />
        <Button
          text="확인"
          variant="contained"
          onClick={() => onConfirm(password)}
          disabled={password === ''}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default React.memo(Security);
