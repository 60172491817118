import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { dateFormat } from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { ReservationList } from '../../../../stores/resources';
import ResourceReservationListItem from '../list/ResourceReservationListItem';

function ReservationListDialog(props: {
  type: 'daily' | 'weekly';
  list: ReservationList[];
  startDate: string;
}): JSX.Element {
  const { type, list } = props;
  const startDate = new Date(props.startDate);
  const dispatch = useAppDispatch();
  const directory = useDirectory();

  const items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  );
  const columns: Column<PostListItemType>[] = [
    { name: 'useDays', text: '날짜', type: 'date', visible: true },
    { name: 'range', text: '시간', type: 'folder', visible: true },
    { name: 'itemName', text: '자원명', type: 'folder', visible: true },
    { name: 'subject', text: '제목', type: 'post', visible: true },
    { name: 'user', text: '예약자', type: 'user', visible: true },
    { name: 'status', text: '상태', type: 'reservationStatus', visible: true },
  ];

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  if (type === 'daily') {
    return (
      <Dialog onClose={handleDialogClose}>
        <DialogHeader>
          <DialogTitle>예약 현황</DialogTitle>
        </DialogHeader>
        <DialogBody height={450}>
          <div style={{ margin: '20px 5px' }}>
            <PostList name="list" type="full">
              <PostList.Head>
                {columns.map(({ name, type: columnType, text }) => (
                  <PostList.Cell
                    key={name}
                    name={text}
                    type={columnType}
                    align="center"
                  />
                ))}
              </PostList.Head>
              <PostList.Body>
                {list.length === 0 ? (
                  <EmptyData />
                ) : (
                  list.map((a, i) => {
                    const start = a.startDateTime;
                    const end = a.endDateTime;
                    const directoryData = getDirectoryData({
                      ...directory,
                      companyId: a.companyId,
                      employeeId: a.employeeId,
                    });
                    const itemName =
                      items.find(({ id }) => id === a.itemId)?.name ?? '';
                    let useDays = dateFormat(startDate, 'MM.DD (dd)');
                    if (i !== 0) useDays = '';
                    let range = '';
                    if (
                      moment(startDate).isSame(start, 'day') &&
                      moment(startDate).isSame(end, 'day')
                    )
                      range = `${dateFormat(start, 'HH:mm')} ~ ${dateFormat(
                        end,
                        'HH:mm',
                      )}`;
                    if (
                      moment(startDate).isSame(start, 'day') &&
                      moment(end).isAfter(startDate, 'day')
                    )
                      range = `${dateFormat(start, 'HH:mm')} ~`;
                    if (
                      moment(start).isBefore(startDate, 'day') &&
                      moment(startDate).isSame(end, 'day')
                    )
                      range = `~ ${dateFormat(end, 'HH:mm')}`;
                    if (moment(startDate).isBetween(start, end, 'day', '()'))
                      range = `종일`;

                    let status = 'proceeding';
                    if (a.isApproval === 1) status = 'done';
                    else if (a.isApproval === 2) status = 'return';
                    const item = {
                      useDays,
                      range,
                      itemName,
                      subject: a.name,
                      user: {
                        name: directoryData.employeeName,
                        organization: directoryData.organizationName,
                        job: directoryData.jobPositionName,
                        avatar: directoryData.avatar ?? '',
                      },
                      status,
                    };
                    const key = `/i${i}/${a.id}`;
                    return (
                      <ResourceReservationListItem
                        key={key}
                        align
                        tootip
                        id={a.id}
                        columns={columns}
                        item={item}
                      />
                    );
                  })
                )}
              </PostList.Body>
            </PostList>
          </div>
        </DialogBody>
      </Dialog>
    );
  }

  const weekly: Date[] = [];
  for (let i = 0; i < 7; i += 1) {
    const week = new Date(startDate);
    week.setDate(week.getDate() + i);
    weekly.push(week);
  }
  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>예약 현황</DialogTitle>
      </DialogHeader>
      <DialogBody height={450}>
        <div style={{ margin: '20px 5px' }}>
          <PostList name="list" type="full">
            <PostList.Head>
              {columns.map(({ name, type: columnType, text }) => (
                <PostList.Cell
                  key={name}
                  name={text}
                  type={columnType}
                  align="center"
                />
              ))}
            </PostList.Head>
            <PostList.Body>
              {list.length === 0 ? (
                <EmptyData />
              ) : (
                weekly.map((date) => {
                  let t = false;
                  return (
                    <>
                      {list.map((a, i) => {
                        const start = a.startDateTime;
                        const end = a.endDateTime;
                        const directoryData = getDirectoryData({
                          ...directory,
                          companyId: a.companyId,
                          employeeId: a.employeeId,
                        });
                        const itemName =
                          items.find(({ id }) => id === a.itemId)?.name ?? '';
                        let useDays = dateFormat(date, 'MM.DD (dd)');
                        if (t) useDays = '';
                        let range = '';
                        if (
                          moment(date).isSame(start, 'day') &&
                          moment(date).isSame(end, 'day')
                        )
                          range = `${dateFormat(start, 'HH:mm')} ~ ${dateFormat(
                            end,
                            'HH:mm',
                          )}`;
                        if (
                          moment(date).isSame(start, 'day') &&
                          moment(end).isAfter(date, 'day')
                        )
                          range = `${dateFormat(start, 'HH:mm')} ~`;
                        if (
                          moment(start).isBefore(date, 'day') &&
                          moment(date).isSame(end, 'day')
                        )
                          range = `~ ${dateFormat(end, 'HH:mm')}`;
                        if (moment(date).isBetween(start, end, 'day', '()'))
                          range = `종일`;

                        if (range === '') return <></>;
                        if (range !== '') t = true;

                        let status = 'proceeding';
                        if (a.isApproval === 1) status = 'done';
                        else if (a.isApproval === 2) status = 'return';
                        const item = {
                          useDays,
                          range,
                          itemName,
                          subject: a.name,
                          user: {
                            name: directoryData.employeeName,
                            organization: directoryData.organizationName,
                            job: directoryData.jobPositionName,
                            avatar: directoryData.avatar ?? '',
                          },
                          status,
                        };
                        const key = `/i${i}/${a.id}`;
                        return (
                          <ResourceReservationListItem
                            key={key}
                            align
                            tootip
                            id={a.id}
                            columns={columns}
                            item={item}
                          />
                        );
                      })}
                    </>
                  );
                })
              )}
            </PostList.Body>
          </PostList>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default ReservationListDialog;
