import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Loading from '../../../../../components/loading/Loading';
import PostWrite from '../../../../../components/post/PostWrite';
import AttendanceSearch, {
  SearchDateProps,
} from '../../../../../components/search/AttendanceSearch';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { documentsFileApi } from '../../../../apis/document/v1/common';
import { documentsActions } from '../../../../stores/document/document';
import DocumentPrintView from '../../../common/DocumentPrintView';
import DocumentLogDrawer from '../../../root/document/common/DocumentLogDrawer';
import DocumentContentHeadView from '../../../root/document/content/DocumentContentHeadView';
import DocumentTrashBodyView from './DocumentCheckoutBodyView';

function DocumentCheckoutContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { pathname, search } = props;
  const { itemId } = getPathParams<{ itemId?: number }>(
    '/*/*/*/:itemId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);
  const scrollbar = useRef<HTMLDivElement>(null);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const display = useSelector((state: RootState) => state.session.display);
  const resource = useSelector((state: RootState) => state.session.resource);

  const folders = useSelector(
    (state: RootState) => state.document.folders.adminconsole.list,
  );
  const { items: list, totalCount } = useSelector(
    (state: RootState) => state.document.documents.adminconsole.list,
  );
  const view = useSelector((state: RootState) => state.document.documents.view);
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'setting');

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props]);

  const [validation, setValidation] = useState('');

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 80 },
    { field: 'subject', label: '제목', minWidth: 250 },
    { field: 'views', label: '조회수', width: 80, align: 'center' },
    { field: 'updater', label: '수정자', width: 158 },
    { field: 'creator', label: '작성자', width: 158 },
    { field: 'updateAt', label: '수정일', width: 118, align: 'right' },
  ]);

  const rows: DataGridRowsProps<number> = list.map((a) => {
    const creator =
      a.creatorId === 0
        ? undefined
        : getDirectoryData({
            ...directory,
            companyId: principal.companyId,
            employeeId: a.creatorId,
          });
    const updater =
      a.updaterId === 0
        ? undefined
        : getDirectoryData({
            ...directory,
            companyId: principal.companyId,
            employeeId: a.updaterId,
          });
    const attachedFiles = a.attachedSummaryFiles
      ? a.attachedSummaryFiles.map(({ fileId: id, name }) => ({
          id,
          name,
        }))
      : undefined;
    const folder = folders.find((x) => x.id === a.folderId);

    return [
      { type: 'checkbox' as const, id: a.id, value: a.checked },
      {
        type: 'subject' as const,
        id: a.id,
        value: a.subject,
        folder: folder ? { name: folder.name } : undefined,
        attachedFiles,
      },
      { type: 'text' as const, value: a.views },
      {
        type: 'user' as const,
        avatar: updater ? updater.avatar ?? '' : '',
        icon: undefined,
        value: updater ? updater.employeeName : '',
        from: updater ? updater.organizationName : '',
      },
      {
        type: 'user' as const,
        avatar: creator ? creator.avatar ?? '' : '',
        icon: undefined,
        value: creator ? creator.employeeName : '',
        from: creator ? creator.organizationName : '',
      },
      { type: 'date' as const, value: a.createAt, format: 'dateNumber' },
    ];
  });
  const toolbarActions: ActionEventProps[] | undefined =
    display !== 'phone' || resource !== 'teams'
      ? [
          { code: 'prints', label: '인쇄', type: 'icon', icon: 'print' },
          { code: 'saves', label: '저장', type: 'icon', icon: 'save' },
        ]
      : undefined;
  const searchCategory: { value: string; label: string }[] = [
    { value: 'subject', label: '제목' },
    { value: 'employee', label: '수정자' },
    // { value: 'content', label: '본문' }, // TODO
  ];

  /** 뒤로가기 이벤트. */
  const handleListGo = () => {
    go(`${getPathMap('/*/*/*', pathname)}`, search);
  };

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    const { keyword, directoryKeyword, filter, date } = arg;

    delete queryParams.pageNo;
    queryParams.searchCode = keyword === '' ? undefined : filter;
    queryParams.searchWord = keyword === '' ? undefined : keyword;
    queryParams.startDate = date.start
      ? dateFormat(initialDate(date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = date.end
      ? dateFormat(initialDate(date.end), 'yyyy-MM-DD')
      : undefined;
    queryParams.directoryKeyword = directoryKeyword;
    queryParams.directoryFilter =
      directoryKeyword && keyword !== '' ? 'true' : undefined;
    dispatch(
      documentsActions.checkoutList({
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록 개수 변경 이벤트. */
  const handleChangeRow = (value: number) => {
    delete queryParams.pageNo;
    queryParams.rowsPerPage = value;
    dispatch(
      documentsActions.checkoutList({
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 액션 버튼 이벤트. */
  const handleClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
    name?: string;
    fileId?: number;
  }) => {
    const { code, id, name, fileId } = arg;

    /** 체크 리스트 아이템 해제. */
    if (code === 'cancelSelected')
      dispatch(
        documentsActions.adminconsoleChecked({ itemId: 'all', checked: false }),
      );

    /** 이전 페이지. */
    if (code === 'prePage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) - 1;
      if (params.pageNo > 0) {
        if (params.pageNo === 1) delete params.pageNo;
        dispatch(
          documentsActions.checkoutList({
            search: getQueryParams(params),
            route: {
              pathname,
              search: getQueryParams(params),
            },
          }),
        );
      }
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      const params = { ...queryParams };
      params.pageNo = (params.pageNo || 1) + 1;
      dispatch(
        documentsActions.checkoutList({
          search: getQueryParams(params),
          route: {
            pathname,
            search: getQueryParams(params),
          },
        }),
      );
    }

    /** 제목 클릭 이벤트. */
    if (code === 'subject' && id) {
      go(`${getPathMap('/*/*/*', props.pathname)}/${b62(id)}`, props.search);
    }

    /** 첨부파일 다운로드 이벤트. */
    if (code === 'attachedFile' && id && fileId && name) {
      const versionSeq = list.find((a) => a.id === id)?.versionSeq;
      if (versionSeq === undefined) return;
      documentsFileApi
        .download(id, fileId, versionSeq)
        .then((blob) => {
          if (!blob)
            throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => window.URL.revokeObjectURL(url), 3000);
          a.remove();
        })
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }

    // 인쇄
    if (code === 'prints') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
    // HTML 저장
    if (code === 'saves') {
      const saveList = list.filter((x) => x.checked);
      if (saveList.length === 0) return;
      documentsFileApi
        .htmlDownload(saveList.map((a) => a.id))
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
    }
  };

  /** 리스트 체크 이벤트. */
  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    dispatch(
      documentsActions.adminconsoleChecked({
        itemId: id.toString(),
        checked: event.target.checked,
      }),
    );
  };

  /** 리스트 모두 체크 이벤트. */
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      documentsActions.adminconsoleChecked({
        itemId: 'all',
        checked: event.target.checked,
      }),
    );
  };

  /** 목록 노출. */
  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prev) =>
      prev.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  /** 파일 다운로드. */
  const download = (blob: Blob) => {
    if (!blob) throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

    const date = timezoneDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const filename = `${year}${month}${day}.zip`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 3000);
    a.remove();
  };

  /** 헤드 뷰 액션. */
  const handleClickToolbarAction = (arg: {
    code: string;
    event?: React.MouseEvent;
  }) => {
    const { code } = arg;
    // 문서활동
    if (code === 'log') dispatch(sessionActions.setDrawer({ type: code }));
    // 삭제
    if (code === 'checkin') dispatch(sessionActions.setDialog({ type: code }));
    //  복원
    if (code === 'undo') dispatch(sessionActions.setDialog({ type: code }));
    // 인쇄
    if (code === 'print') dispatch(sessionActions.setDialog({ type: code }));
    // 저장
    if (code === 'save' && view)
      documentsFileApi
        .htmlDownload([view.id])
        .then(download)
        .catch((ex) => {
          dispatch(sessionActions.error(appError(ex)));
        });
  };
  /** 대화상자 닫기. */
  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  // 드로워 닫기.
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  /** 이전 결재문서 */
  const handlePrevView = (arg: { id: number }) => {
    go(`${getPathMap('/*/*/*', props.pathname)}/${b62(arg.id)}`, props.search);
  };

  /** 다음 결재문서 */
  const handleNextView = (arg: { id: number }) => {
    go(`${getPathMap('/*/*/*', props.pathname)}/${b62(arg.id)}`, props.search);
  };

  /** 문서 체크아웃 */
  const handleDocumentCheckout = () => {
    if (view === undefined) return;
    const data = {
      id: view.id,
      changeReason: '',
      updateAt: view.updateAt,
      isCheckout: queryParams.dialogType === 'checkout',
    };
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*/*', pathname)}`,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    if (queryParams.pageNo) delete queryParams.pageNo;
    if (queryParams.dialogType) delete queryParams.dialogType;

    dispatch(
      documentsActions.adminconsoleCheckin({
        data,
        location,
      }),
    );
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    // 체크인
    if (dialogType === 'checkin') {
      return (
        <Dialog size="xs">
          <DialogBody>
            <PostWrite>
              <div className="eui-alert-message">
                문서를 체크인 하시겠습니까?
              </div>
            </PostWrite>
          </DialogBody>
          <DialogFooter>
            <Button
              text="확인"
              variant="contained"
              onClick={handleDocumentCheckout}
            />
            <Button text="취소" onClick={handleCloseDialog} />
          </DialogFooter>
        </Dialog>
      );
    }
    // 인쇄.
    if (dialogType === 'print' || dialogType === 'prints') {
      let listId:
        | {
            id: number;
            updateAt: string;
          }[]
        | undefined;
      if (dialogType === 'print' && itemId && view) {
        listId = [
          {
            id: view.id,
            updateAt: view.updateAt,
          },
        ];
      } else if (dialogType === 'prints') {
        listId = list
          .filter((x) => x.checked)
          .map((a) => {
            return {
              id: a.id,
              updateAt: a.updateAt,
            };
          });
      }
      if (listId === undefined) return null;
      return (
        <DocumentPrintView
          pathname={pathname}
          listId={listId}
          onClose={handleCloseDialog}
        />
      );
    }
    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;
    // 문서활동
    if (drawerType === 'log') {
      if (itemId === undefined) return undefined;
      return (
        <DocumentLogDrawer documentId={itemId} onClose={handleCloseDrawer} />
      );
    }
    return null;
  };

  const renderContent = () => {
    const title = categories.find((a) => a.id === 6006)?.name ?? '';
    let result: JSX.Element = (
      <>
        <EuiHeader>
          <EuiHeader.Title>{title}</EuiHeader.Title>
          <EuiHeader.Search>
            <AttendanceSearch
              dateOptions
              date={{
                start: startDate ? timezoneDate(startDate) : null,
                end: endDate ? timezoneDate(endDate) : null,
              }}
              directory
              dualSearch
              keyword={searchWord}
              options={searchCategory}
              filter={searchCode}
              onSearch={handleSearch}
            />
          </EuiHeader.Search>
        </EuiHeader>
        <EuiBody>
          <DataGrid
            scrollbar={scrollbar}
            caption={title}
            columns={columns}
            rows={rows}
            pagination={{
              no: queryParams.pageNo ?? 1,
              total: totalCount,
              row: queryParams.rowsPerPage ?? 15,
              onChangeRow: handleChangeRow,
            }}
            checkedCount={list.filter((a) => a.checked).length}
            toolbarActions={toolbarActions}
            onClick={handleClick}
            onChecked={handleChangeChecked}
            onCheckedAll={handleChangeCheckedAll}
            onChangeColumnVisible={handleChangeColumnVisible}
          />
        </EuiBody>
      </>
    );

    if (itemId) {
      if (!view) return <Loading />;

      const prev = view.prev?.id;
      const next = view.next?.id;

      const folder = folders.find((a) => a.id === view.folderId);
      let toolbarButtons: ActionEventProps[] = [
        {
          code: 'log',
          label: '문서활동',
          type: 'text',
          icon: 'document-search',
        },
        {
          code: 'checkin',
          label: '체크인',
          type: 'text',
          icon: 'document-check',
        },
      ];

      if (display !== 'phone' || resource !== 'teams')
        toolbarButtons = [
          ...toolbarButtons,
          { code: 'print', label: '인쇄', type: 'text', icon: 'print' },
          { code: 'save', label: '저장', type: 'text', icon: 'save' },
        ];

      result = (
        <>
          <DocumentContentHeadView
            usePagination
            type="full"
            toolbarButtons={toolbarButtons}
            onToolbarAction={handleClickToolbarAction}
            onPrev={prev ? () => handlePrevView({ id: prev }) : undefined}
            onNext={next ? () => handleNextView({ id: next }) : undefined}
            onListGo={handleListGo}
          />
          <EuiBody>
            <DocumentTrashBodyView
              type="full"
              pathname={pathname}
              search={search}
              view={view}
              folderName={folder?.name}
            />
          </EuiBody>
        </>
      );
    }

    return (
      <>
        {result}
        {renderDialog()}
        {renderDrawer()}
        <FeedBack text={validation} onClose={() => setValidation('')} />
      </>
    );
  };

  const {
    startDate,
    endDate,
    searchWord = '',
    searchCode = 'subject',
  } = queryParams;
  return <>{renderContent()}</>;
}

export default DocumentCheckoutContainer;
