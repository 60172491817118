import React, { useState } from 'react';
import { ListItem } from '../../../../../groupware-common/types';
import { hangul } from '../../../../../groupware-common/utils';
import Button from '../../../../../components/button/Button';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EmptyData from '../../../../../components/data/EmptyData';
import MenuItem from '../../../../../components/menu/MenuItem';

function JobDutyContentList(props: {
  id: number | undefined;
  items: ListItem[];
  onCreate(): void;
  onItemClick(id: number): void;
  useLink?: boolean;
}): JSX.Element {
  // console.log(`${JobDutyContentList.name}.render(props)`, props);

  const { items, useLink } = props;

  const [filter, setFilter] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  let list = null;
  if (filter === '') {
    list = items.map((x) => {
      return (
        <MenuItem
          key={x.id}
          label={x.text}
          icon={x.icon}
          selected={props.id === x.id}
          onClick={() => props.onItemClick(x.id)}
        />
      );
    });
  } else {
    const strings = hangul.d(filter);
    list = items
      .filter((x) => x.strings && hangul.test(x.strings, strings))
      .map((x) => {
        return (
          <MenuItem
            key={x.id}
            label={x.text}
            icon={x.icon}
            selected={props.id === x.id}
            onClick={() => props.onItemClick(x.id)}
          />
        );
      });

    if (list.length === 0) list = <EmptyData message="검색결과가 없습니다." />;
  }

  return (
    <>
      <EuiSetting.Header title="직책목록">
        {!useLink && (
          <Button
            text="직책추가"
            iconType
            icon="plus"
            onClick={props.onCreate}
          />
        )}
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={filter} onSearch={handleFilterChange} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>{list}</EuiSetting.Content>
    </>
  );
}

export default JobDutyContentList;
