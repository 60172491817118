import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import MenuItem from '../../../../../components/menu/MenuItem';
import PostView from '../../../../../components/post/PostView';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';
import ApprovalPreferencesFolderEdit from './ApprovalPreferencesFolderEdit';
import ApprovalPreferencesHead from './ApprovalPreferencesHead';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';

/** 결재함 관리 컨테이너. */
function ApprovalPreferencesFolderContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${ApprovalPreferencesFolderContainer.name}.render`);

  const dispatch = useAppDispatch();
  const foderlist1 = useSelector(
    (state: RootState) => state.approval2.userPreferences.userPreferencesFolder,
  );
  // const { foderlist } = dumy;

  const foderlist = [...foderlist1].sort(
    (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
  );

  // console.log(' foderlist: ', foderlist);

  const [state, setState] = useState<{
    selectedId: number;
    items: {
      id: number;
      companyId: number;
      employeeId: number;
      status: number;
      seq: number;
      name: string;
      description: string;
      size: number;
      updateAt: string;
    }[];
    visible: string;
  }>({
    selectedId: 0,
    items: foderlist,
    visible: '',
  });

  let valuedata = foderlist.find((a) => a.id === state.selectedId);
  const foderselected =
    valuedata !== undefined
      ? valuedata
      : (valuedata = {
          id: 0,
          companyId: 0,
          employeeId: 0,
          status: 0,
          seq: 0,
          name: '',
          description: '',
          size: 0,
          updateAt: '',
        });
  const foderItem: {
    id: number;
    label: string;
  }[] = foderlist.map((x) => {
    return {
      id: x.id,
      label: x.name,
    };
  });

  // console.log(
  //   ' foderselected  :',
  //   foderselected,
  //   ' state : ',
  //   state,
  //   '  foderItem :',
  //   foderItem,
  // );

  const handleSelected = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedId: id,
    }));
  };

  const handleCloseRight = () => {
    setState((prevState) => ({
      ...prevState,
      selectedId: 0,
    }));
  };

  const handleOpen = (type: string) => {
    setState((prevState) => ({
      ...prevState,
      visible: type,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      visible: '',
    }));
  };

  const handleSave = (arg: {
    status: number;
    name: string;
    description: string;
  }) => {
    // console.log(' arg:', arg);
    dispatch(
      approvaluserPreferencesActions.savefetchPreferencesFolder(arg),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        setState((prevState) => ({
          ...prevState,
          visible: '',
          selectedId: result.payload[result.payload.length - 1].id,
        }));
      }
    });
  };

  const handleModeSave = (arg: {
    id: number;
    status: number;
    name: string;
    description: string;
    updateAt: string;
  }) => {
    // console.log(' arg:');
    dispatch(
      approvaluserPreferencesActions.modefetchPreferencesFolder(arg),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        setState((prevState) => ({ ...prevState, visible: '' }));
      }
    });
  };

  const getDialog = () => {
    // const foderValue = foderlist.find((a) => a.id === state.selectedId);
    if (state.visible === 'add')
      return (
        <ApprovalPreferencesFolderEdit
          modecheck={state.visible}
          onSave={handleSave}
          onClose={handleClose}
        />
      );
    if (state.visible === 'edit')
      return (
        <ApprovalPreferencesFolderEdit
          modecheck={state.visible}
          foderValue={foderselected}
          onModSave={handleModeSave}
          onClose={handleClose}
        />
      );
    if (state.visible === 'delete')
      return (
        <DeleteConfirmation
          onSubmit={handleDeleteFolder}
          onCancel={handleClose}
        >
          <strong>&apos;{foderselected.name}&apos;</strong> 결재함을 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    if (state.visible === 'order')
      return (
        <ChangeOrder
          title="결재함 순서변경"
          list={foderItem}
          // eslint-disable-next-line no-shadow
          onChange={(list) => {
            let i = 0;
            // console.log('변경된 리스트: ', list);
            const arg: {
              id: number;
              seq: number;
              updateAt: string;
            }[] = list.map((a) => {
              const values = foderlist.filter((b) => a.id === b.id);
              // eslint-disable-next-line no-return-assign
              return {
                id: values[0].id,
                seq: (i += 1),
                updateAt: values[0].updateAt,
              };
            });
            dispatch(
              approvaluserPreferencesActions.modefetchPreferencesFolderSeq(arg),
            ).then((result) => {
              if ((result as { error?: string }).error === undefined) {
                setState((prevState) => ({ ...prevState, visible: '' }));
              }
            });
            handleClose();
          }}
          onClose={handleClose}
        />
      );
    return null;
  };

  const handleDeleteFolder = () => {
    // console.log(' handleDeleteFolder selected : ', foderselected);
    const arg = {
      id: foderselected.id,
      updateAt: foderselected.updateAt,
    };
    if (arg !== undefined) {
      dispatch(
        approvaluserPreferencesActions.deletefetchPreferencesFolder(arg),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined) {
          setState((prevState) => ({
            ...prevState,
            visible: '',
            selectedId: 0,
          }));
        }
      });
    }
  };

  const { pathname, search, hash } = props;

  return (
    <>
      <ApprovalPreferencesHead
        pathname={pathname}
        search={search}
        hash={hash}
      />
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <EuiSetting.Header title="개인 결재함">
              <Button
                text="추가"
                iconType
                icon="plus"
                onClick={() => handleOpen('add')}
              />
              <Button
                text="순서 변경"
                iconType
                icon="sort-amount"
                onClick={() => handleOpen('order')}
              />
            </EuiSetting.Header>
            <EuiSetting.Content>
              {foderlist.map((x) => (
                <MenuItem
                  key={x.id}
                  label={x.name}
                  icon="folder"
                  onClick={() => handleSelected(x.id)}
                  selected={x.id === state.selectedId}
                />
              ))}
            </EuiSetting.Content>
          </EuiSetting.Left>
          {state.selectedId === 0 ? (
            <SplitUnselected label="폴더를 선택하세요" />
          ) : (
            <EuiSetting.Right onClose={handleCloseRight}>
              <EuiSetting.Header title="결재함 정보">
                <Button
                  text="수정"
                  iconType
                  icon="edit"
                  onClick={() => handleOpen('edit')}
                />
                <Button
                  text="삭제"
                  iconType
                  icon="trash-full"
                  onClick={() => handleOpen('delete')}
                />
              </EuiSetting.Header>
              <EuiSetting.Content>
                <PostView>
                  <PostView.Category type="text">
                    <PostView.CategoryList>
                      <PostView.CategoryItem title="명칭">
                        <PostView.CategoryValue value={foderselected.name} />
                      </PostView.CategoryItem>
                      {/* // TODO 추후 추가 예정.
                      <PostView.CategoryItem title="용량">
                        <PostView.CategoryValue
                          value={readableBytes(foderselected.size, 1)}
                        />
                      </PostView.CategoryItem> */}
                    </PostView.CategoryList>
                  </PostView.Category>
                </PostView>
              </EuiSetting.Content>
            </EuiSetting.Right>
          )}
        </EuiSetting>
        {getDialog()}
      </EuiBody>
    </>
  );
}

export default ApprovalPreferencesFolderContainer;
