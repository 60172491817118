import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import JobDutyContainer from '../pages/adminconsole/directory/jobduty/JobDutyContainer';
import JobPositionContainer from '../pages/adminconsole/directory/jobposition/JobPositionContainer';
import OrganizationChartContainer from '../pages/adminconsole/directory/organizationchart/OrganizationChartContainer';
import DirectoryPreferencesContainer from '../pages/adminconsole/directory/preferences/DirectoryPreferencesContainer';
import DirectoryUserGroupContainer from '../pages/adminconsole/directory/usergroup/DirectoryUserGroupContainer';

function AdminConsoleLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element | null {
  // console.log(`${AdminConsoleLocateContainer.name}.render(props)`, props);

  // const languages = ['ko-KR' as const];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', props.pathname);

  if (menu === undefined) return <DirectoryPreferencesContainer />;
  if (menu === 'organizationchart')
    return <OrganizationChartContainer pathname={pathname} search={search} />;
  if (menu === 'jobposition')
    return <JobPositionContainer pathname={pathname} search={search} />;
  if (menu === 'jobduty')
    return <JobDutyContainer pathname={pathname} search={search} />;
  if (menu === 'group')
    return <DirectoryUserGroupContainer pathname={pathname} search={search} />;

  return null;
}

export default AdminConsoleLocateContainer;
