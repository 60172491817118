import { useEffect } from 'react';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { approvalActions } from '../../../../stores/approval';

function ResourceApprovalRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { menu } = getPathParams<{ menu: string }>('/*/:menu', pathname);
  const queryParams = getQueryParams(search);

  useEffect(() => {
    const startDate = timezoneDate();
    startDate.setMonth(timezoneDate().getMonth() - 3);
    startDate.setDate(1);
    const endDate = timezoneDate();
    endDate.setMonth(timezoneDate().getMonth() + 3);
    endDate.setDate(1);
    queryParams.startDate =
      queryParams.startDate ?? dateFormat(initialDate(startDate), 'yyyy-MM-DD');
    queryParams.endDate =
      queryParams.endDate ?? dateFormat(initialDate(endDate), 'yyyy-MM-DD');

    const params = {
      search: getQueryParams(queryParams),
      route: { pathname, search: getQueryParams(queryParams) },
    };
    if (menu === 'approval') dispatch(approvalActions.approvalList(params));
    if (menu === 'return') dispatch(approvalActions.rentalList(params));
  }, [dispatch, pathname, search]);
  return null;
}

export default ResourceApprovalRoute;
