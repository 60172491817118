import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiSetting from '../../../../components/layout/EuiSetting';
import CalendarFolderBoxList from '../../common/CalendarFolderBoxList';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../groupware-common/utils';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import SplitUnselected from '../../../../components/split/SplitUnselected';
import Dialog from '../../../../components/dialog/Dialog';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import Button from '../../../../components/button/Button';
import {
  calendarsActions,
  CalendarSubFolderView as SaveSubCalendarData,
} from '../../../stores/calendar/calendars';
import CalendarSubFolderView from './CalendarSubFolderView';
import CalendarSubFolderEditDrawer from './CalendarSubFolderEditDrawer';
import CalendarSubFolderCreateDialog from './CalendarSubFolderCreateDialog';
import {
  AuthorityType,
  SystemIdType,
} from '../../../apis/calendar/v1/calendars';

function CalendarSubFolderContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  const pathmap = getPathMap('/*/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/*/:selectedId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);
  const list = useSelector(
    (state: RootState) => state.calendar.calendars.sub.list,
  );
  const view = useSelector(
    (state: RootState) => state.calendar.calendars.sub.view,
  );
  const categories = useSelector(
    (state: RootState) => state.calendar.calendars.category,
  ).filter((a) => a.type === 'setting');

  const items = useMemo(() => {
    const folders = list.map((x) => {
      return {
        id: x.id,
        parentId: 0,
        seq: x.seq,
        text: x.name,
        icon: 'folder-fill' as const,
        color: x.color,
        strings: hangul.d(x.name),
      };
    });
    return folders;
  }, [list]);

  /** 모바일에만 적용. */
  const handleCloseView = () => {
    const route = { pathname: pathmap };
    dispatch(sessionActions.setRoute(route));
  };

  /** 폴더 리스트 아이템 클릭 이벤트. */
  const handleListItemClick = (id: number) => {
    const systemId = list.find((a) => a.id === id)?.systemId;
    if (!systemId) return;
    dispatch(
      calendarsActions.subCalendarView({
        systemId,
        id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search,
        },
      }),
    );
  };

  /** 구독 캘린더 생성 이벤트. */
  const handleSubscribe = (systemId: SystemIdType) => {
    const route = { pathname };
    const isAttendance = systemId === 'ATTENDANCE';
    const data = {
      systemId,
      useExposeAnniversary: isAttendance ? undefined : false,
      managerAuthority: isAttendance
        ? ('SUB_ORGANIZATION' as AuthorityType)
        : undefined,
      generalUserAuthority: isAttendance
        ? ('EMPLOYEE' as AuthorityType)
        : undefined,
    };
    dispatch(calendarsActions.saveSubCal({ data, route }));
  };

  /** 구독 캘린더 수정 이벤트. */
  const handleUpdate = (arg: SaveSubCalendarData) => {
    const route = { pathname };
    const data =
      arg.systemId === 'ATTENDANCE'
        ? { ...arg, useExposeAnniversary: undefined }
        : {
            ...arg,
            managerAuthority: undefined,
            generalUserAuthority: undefined,
          };
    dispatch(calendarsActions.updateSubCal({ data, route }));
  };

  /** 구독 캘린더 삭제 이벤트. */
  const handleDelete = () => {
    if (!view) return;
    dispatch(
      calendarsActions.deleteSubCal({
        data: {
          id: view.id,
          updateAt: view.updateAt,
        },
        route: { pathname: pathmap },
      }),
    );
  };

  /** 캘린더 뷰 툴바버튼 이벤트. */
  const handleCRUD = (mode: 'create' | 'read' | 'update' | 'delete') => {
    switch (mode) {
      case 'create':
      case 'delete':
        dispatch(sessionActions.setDialog({ mode }));
        break;
      case 'update':
        dispatch(sessionActions.setDrawer({ mode }));
        break;
      default:
        dispatch(sessionActions.search());
        break;
    }
  };

  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId ? '폴더를 찾을 수 없습니다.' : undefined;
      return <SplitUnselected label={message} />;
    }
    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <CalendarSubFolderView
          paths={getParentItems(items, view.id).map(({ text }) => text)}
          view={view}
          onUpdate={() => handleCRUD('update')}
          onDelete={() => handleCRUD('delete')}
        />
      </EuiSetting.Right>
    );
  };

  const renderDialog = () => {
    if (queryParams.dialogMode === 'create')
      return (
        <CalendarSubFolderCreateDialog
          onSubscribe={handleSubscribe}
          onClose={() => handleCRUD('read')}
        />
      );
    if (queryParams.dialogMode === 'delete' && view)
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message eui-delete-message">
              <p>
                <strong>&apos;{view.name}&apos;</strong> 캘린더를 구독
                해지하시겠습니까?
              </p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={() => handleCRUD('read')} />
            <Button
              noDuplication
              text="해지"
              variant="contained"
              color="danger"
              onClick={handleDelete}
            />
          </DialogFooter>
        </Dialog>
      );

    return null;
  };

  const renderDrawer = () => {
    if (queryParams.drawerMode === 'update' && view)
      return (
        <CalendarSubFolderEditDrawer
          view={view}
          onSave={handleUpdate}
          onClose={() => handleCRUD('read')}
        />
      );

    return null;
  };

  const title = categories.find((a) => a.id === 6002)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <CalendarFolderBoxList
              calendarId={selectedId}
              items={items}
              onItemClick={handleListItemClick}
              onCreate={() => handleCRUD('create')}
            />
          </EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default CalendarSubFolderContainer;
