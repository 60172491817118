import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
// import dummy from './dummy';

// const { approvalLines, workNames, nos, subjects, attachedDocuments } = dummy;

const namespace = 'approval';

async function fetchTotalCount(arg: {
  searchCode?: string;
  searchWord?: string;
}): Promise<number> {
  try {
    const { searchCode: searchcode, searchWord: searchword } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/attachable-document/totalcount`;
    const response = await axios.get<number>(url, {
      headers,
      params: {
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

async function fetchList(arg: {
  pageNo: number;
  rowsPerPage: number;
  searchCode?: string;
  searchWord?: string;
}): Promise<
  {
    affiliatedCompanyId: number; // 관계사 회사 아이디(관계사가 아닌 경우 NULL 값)
    id: number; // 아이디
    workName: string; // 업무 이름
    formName: string; // 양식 이름
    importance: number; // 중요도 - 낮음:0, 보통:1, 높음:2
    no: string; // 문서번호
    subject: string; // 제목
    approvalLine: string;
    views: number; // 조회 수(직원별로 최대1 증가)
    opinions: number; // 의견 수
    comments: number; // 덧글 수
    createAt: string; // 생성 날짜
    updateAt: string; // 수정 날짜
  }[]
> {
  try {
    const {
      pageNo: pageno,
      rowsPerPage: rowsperpage,
      searchCode: searchcode,
      searchWord: searchword,
    } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/document/attachable-document/all/simply`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        searchcode,
        searchword,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

const approvalAttachedDocumentApi = {
  namespace,
  fetchTotalCount,
  fetchList,
};

export default approvalAttachedDocumentApi;
