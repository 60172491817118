import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { appError } from '../../groupware-webapp/stores/common/utils';
import userPreferencesApi from '../apis/resource/v1/userPreferences';

const name = 'resource/userPreferences';

export interface UserPreferencesItem {
  companyId: number;
  employeeId: number;
  initianFrame: number; // 초기화면. (0:일간, 1: 주간, 3: 월간)
  firstDayOfWeek: number; // 한 주의 시작. (0: 일요일, 1: 월요일)
  isReceiveAlarm: boolean; // 자원 예약 알림 수신 여부.
  updateAt: string;
}

interface State {
  preferences: UserPreferencesItem | undefined;
}

const initialState: State = {
  preferences: undefined,
};

/** 자원관리 사용자 환경설정 조회. */
const findUserPreferences = createAsyncThunk(
  `${name}/findUserPreferences`,
  async (
    arg: {
      employeeId: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await userPreferencesApi.find(arg.employeeId);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원관리 사용자 환경설정 저장 */
const saveUserPreferences = createAsyncThunk(
  `${name}/saveUserPreferences`,
  async (
    arg: {
      employeeId: number;
      initianFrame: number;
      firstDayOfWeek: number;
      isReceiveAlarm: boolean;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await userPreferencesApi.save(arg);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const userPreferencesReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findUserPreferences.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.preferences = payload;
      })
      .addCase(saveUserPreferences.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.preferences = payload;
      });
  },
});

export default userPreferencesReducer.reducer;

export const userPreferencesActions = {
  find: findUserPreferences,
  save: saveUserPreferences,
};
