import React, { useEffect, useState } from 'react';
import EuiHeader from '../../../components/layout/EuiHeader';
import EuiBody from '../../../components/layout/EuiBody';
import DataGrid, {
  DataGridColDef,
  DataGridMoreActionProps,
  DataGridRowsProps,
  SortingProps,
} from '../../../components/data/DataGrid';
import { ActionEventProps } from '../../../components/toolbarAction/ToolbarAction';
import { sleep } from '../../../groupware-common/utils';
import DeleteConfirmation from '../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../components/alert/FeedBack';
import Search, { SearchDateProps } from '../../../components/search/Search';

function statusLabel(type: string) {
  switch (type) {
    case 'proceeding':
      return '진행중';
    case 'return':
      return '반려';
    case 'done':
      return '완료';
    default:
      return '';
  }
}

function statusTheme(type: string) {
  switch (type) {
    case 'proceeding':
      return 'primary';
    case 'return':
      return 'error';
    case 'done':
      return 'success';
    default:
      return undefined;
  }
}

function Page(): JSX.Element {
  const searchCategory: { value: string; label: string }[] = [
    { value: 'subject', label: '제목' },
    { value: 'number', label: '문서번호' },
    { value: 'drafter', label: '기안자' },
    { value: 'form', label: '양식명' },
    { value: 'content', label: '본문' },
  ];

  const defaultActions: ActionEventProps[] = [
    { code: 'add', label: '신규', type: 'icon', icon: 'plus-circle-fill' },
  ];

  const toolbarActions: ActionEventProps[] = [
    { code: 'listDelete', label: '삭제', type: 'icon', icon: 'trash-full' },
    { code: 'print', label: '인쇄', type: 'icon', icon: 'print' },
    { code: 'save', label: 'PC저장', type: 'icon', icon: 'save' },
  ];

  const moreActions: DataGridMoreActionProps[] = [
    {
      code: 'itemDelete',
      label: '삭제',
      icon: 'trash-full',
    },
    {
      code: 'update',
      label: '수정',
      icon: 'edit',
    },
    {
      code: 'click',
      label: '클릭이벤트',
      icon: 'dot',
    },
  ];

  const [columns, setColumns] = useState<DataGridColDef[]>([
    { field: 'checkbox', label: '', width: 56 },
    {
      field: 'importance',
      label: '긴급',
      width: 68,
      align: 'center',
    },
    { field: 'work', label: '업무', width: 168 },
    { field: 'subject', label: '제목', minWidth: 200, sorting: 'none' },
    { field: 'image', label: '이미지', width: 124 },
    { field: 'drafter', label: '기안자', width: 158 },
    { field: 'viewer', label: '조회', width: 88, align: 'right' },
    {
      field: 'date',
      label: '기안일',
      width: 118,
      align: 'right',
      sorting: 'descending',
    },
    {
      field: 'status',
      label: '상태',
      width: 98,
      align: 'right',
    },
    { field: 'more', label: '', width: 72 },
  ]);

  const [rows, setRows] = useState<
    {
      id: number;
      checked: boolean;
      importance: string;
      work: string;
      number: string;
      subject: string;
      image: string;
      drafterAvatar: string;
      drafterOrganization: string;
      drafterName: string;
      drafterJob: string;
      finalApprovalAvatar: string;
      finalApprovalOrganization: string;
      finalApprovalName: string;
      finalApprovalJob: string;
      viewer: number;
      date: string;
      status: string;
    }[]
  >([
    {
      id: 1001,
      checked: false,
      importance: 'emergency',
      work: '린나이양식오류',
      number: '2021-린나이양식오류-0001',
      subject:
        '리나이양식 마이너스 연차 테스트 02 리나이양식 마이너스 연차 테스트 02',
      image:
        'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg',
      drafterAvatar: '/images/faces/faces_1.jpg',
      drafterOrganization: '이든비즈텍',
      drafterName: '강명호',
      drafterJob: '대표이사',
      finalApprovalAvatar: '/images/faces/faces_2.jpg',
      finalApprovalOrganization: '이든비즈텍',
      finalApprovalName: '이성계',
      finalApprovalJob: '이사',
      viewer: 999,
      date: '2021-11-12T09:41:43.513',
      status: 'proceeding',
    },
    {
      id: 1002,
      checked: false,
      importance: 'normal',
      work: '레벨결재선',
      number: '2021-레벨결재선-0001',
      subject: '레벨결재선 테스트',
      image:
        'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg',
      drafterAvatar: '/images/faces/faces_3.jpg',
      drafterOrganization: '영업팀',
      drafterName: '이상윤',
      drafterJob: '수석',
      finalApprovalAvatar: '/images/faces/faces_4.jpg',
      finalApprovalOrganization: '사업관리팀',
      finalApprovalName: '최예정',
      finalApprovalJob: '책임',
      viewer: 1,
      date: '2021-11-05T07:41:43.513',
      status: 'return',
    },
    {
      id: 1003,
      checked: false,
      importance: 'normal',
      work: '알박 PDF',
      number: '2021-알박 PDF-0001',
      subject: '알박 PDF 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_5.jpg',
      drafterOrganization: '컨설텅팀',
      drafterName: '김우성',
      drafterJob: '책임',
      finalApprovalAvatar: '/images/faces/faces_6.jpg',
      finalApprovalOrganization: '컨설텅팀',
      finalApprovalName: '라용익',
      finalApprovalJob: '책임',
      viewer: 0,
      date: '2021-10-12T07:41:43.513',
      status: 'done',
    },
    {
      id: 1004,
      checked: false,
      importance: 'normal',
      work: '대원총업 주문서',
      number: '2021-대원총업 주문서-0001',
      subject: '대원총업 주문서 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_7.jpg',
      drafterOrganization: '컨설텅팀',
      drafterName: '이지연',
      drafterJob: '책임',
      finalApprovalAvatar: '/images/faces/faces_8.jpg',
      finalApprovalOrganization: '컨설텅팀',
      finalApprovalName: '김태현',
      finalApprovalJob: '책임',
      viewer: 10,
      date: '2021-09-28T07:41:43.513',
      status: 'done',
    },
    {
      id: 1005,
      checked: false,
      importance: 'normal',
      work: '대원총업 폼빌더',
      number: '2021-대원총업 폼빌더-0001',
      subject: '대원총업 폼빌더 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_9.jpg',
      drafterOrganization: '컨설텅팀',
      drafterName: '장미화',
      drafterJob: '책임',
      finalApprovalAvatar: '/images/faces/faces_10.jpg',
      finalApprovalOrganization: '컨설텅팀',
      finalApprovalName: '강병주',
      finalApprovalJob: '선임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1006,
      checked: false,
      importance: 'normal',
      work: '오토닉스 견적서',
      number: '2021-오토닉스 견적서-0001',
      subject: '오토닉스 견적서 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_11.jpg',
      drafterOrganization: 'ERP팀',
      drafterName: '안창재',
      drafterJob: '수석',
      finalApprovalAvatar: '/images/faces/faces_12.jpg',
      finalApprovalOrganization: 'ERP팀',
      finalApprovalName: '송영준',
      finalApprovalJob: '책임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1007,
      checked: false,
      importance: 'normal',
      work: '통화숫자',
      number: '2021-통화숫자-0001',
      subject: '통화숫자 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_13.jpg',
      drafterOrganization: 'ERP팀',
      drafterName: '박종현',
      drafterJob: '책임',
      finalApprovalAvatar: '/images/faces/faces_14.jpg',
      finalApprovalOrganization: 'ERP팀',
      finalApprovalName: '조영식',
      finalApprovalJob: '선임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1008,
      checked: false,
      importance: 'normal',
      work: '드롭박스',
      number: '2021-드롭박스-0001',
      subject: '드롭박스 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_16.jpg',
      drafterOrganization: 'ERP팀',
      drafterName: '남인호',
      drafterJob: '선임',
      finalApprovalAvatar: '/images/faces/faces_17.jpg',
      finalApprovalOrganization: 'ERP팀',
      finalApprovalName: '김도희',
      finalApprovalJob: '선임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1009,
      checked: false,
      importance: 'normal',
      work: '부국-외출신청서',
      number: '2021-부국-외출신청서-0001',
      subject: '부국-외출신청서 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_18.jpg',
      drafterOrganization: 'ERP팀',
      drafterName: '이종엽',
      drafterJob: '수석',
      finalApprovalAvatar: '/images/faces/faces_19.jpg',
      finalApprovalOrganization: 'GW팀',
      finalApprovalName: '서종주',
      finalApprovalJob: '수석',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1010,
      checked: false,
      importance: 'normal',
      work: '홈앤쇼핑양식',
      number: '2021-홈앤쇼핑양식-0001',
      subject: '홈앤쇼핑양식 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_20.jpg',
      drafterOrganization: 'GW팀',
      drafterName: '이준호',
      drafterJob: '수석',
      finalApprovalAvatar: '/images/faces/faces_21.jpg',
      finalApprovalOrganization: 'GW팀',
      finalApprovalName: '성민기',
      finalApprovalJob: '책임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1011,
      checked: false,
      importance: 'normal',
      work: '자동합계',
      number: '2021-자동합계-0001',
      subject: '자동합계 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_22.jpg',
      drafterOrganization: 'GW팀',
      drafterName: '양광훈',
      drafterJob: '책임',
      finalApprovalAvatar: '/images/faces/faces_23.jpg',
      finalApprovalOrganization: 'GW팀',
      finalApprovalName: '윤호영',
      finalApprovalJob: '선임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1012,
      checked: false,
      importance: 'normal',
      work: '아브노바_재기안',
      number: '2021-아브노바_재기안-0001',
      subject: '아브노바_재기안 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_24.jpg',
      drafterOrganization: 'GW팀',
      drafterName: '이진규',
      drafterJob: '선임',
      finalApprovalAvatar: '/images/faces/faces_25.jpg',
      finalApprovalOrganization: 'GW팀',
      finalApprovalName: '채벼리',
      finalApprovalJob: '선임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1013,
      checked: false,
      importance: 'normal',
      work: '업무일지',
      number: '2021-업무일지-0001',
      subject: '업무일지 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_26.jpg',
      drafterOrganization: 'GW팀',
      drafterName: '송규성',
      drafterJob: '선임',
      finalApprovalAvatar: '/images/faces/faces_27.jpg',
      finalApprovalOrganization: 'GW팀',
      finalApprovalName: '임예원',
      finalApprovalJob: '선임',
      viewer: 5,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1014,
      checked: false,
      importance: 'normal',
      work: '위촉장',
      number: '2021-위촉장-0001',
      subject: '위촉장 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_28.jpg',
      drafterOrganization: '영업팀',
      drafterName: '황윤진',
      drafterJob: '선임',
      finalApprovalAvatar: '/images/faces/faces_29.jpg',
      finalApprovalOrganization: '구축컨설팅팀',
      finalApprovalName: '김승찬',
      finalApprovalJob: '수석',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
    {
      id: 1015,
      checked: false,
      importance: 'normal',
      work: '기안지',
      number: '2021-기안지-0001',
      subject: '기안지 테스트',
      image: '',
      drafterAvatar: '/images/faces/faces_30.jpg',
      drafterOrganization: '구축컨설팅팀',
      drafterName: '박성원',
      drafterJob: '수석',
      finalApprovalAvatar: '/images/faces/faces_31.jpg',
      finalApprovalOrganization: '이든비즈텍',
      finalApprovalName: '홍길동',
      finalApprovalJob: '선임',
      viewer: 10,
      date: '2020-05-01T07:41:43.513',
      status: 'done',
    },
  ]);

  const [state, setState] = useState<{
    dialog: string;
    feedback: string;
    rowLength: number;
    search: {
      keyword: string;
      filter: string;
      date: SearchDateProps;
      filters: { code: string; keyword: string }[];
    };
  }>({
    dialog: '',
    feedback: '',
    rowLength: 5,
    search: {
      keyword: '',
      filter: searchCategory[0].value,
      date: {
        type: 'all',
        start: null,
        end: null,
      },
      filters: [],
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      sleep(3000).then(() => {
        setLoading(false);
      });
    }
  }, [loading]);

  const newRows: DataGridRowsProps<number> = rows.map((row) => {
    return [
      {
        type: 'checkbox' as const,
        id: row.id,
        value: row.checked,
      },
      {
        type: 'icon' as const,
        value: row.importance === 'emergency' ? '긴급' : '보통',
        icon: row.importance === 'emergency' ? 'exclamation' : undefined,
        theme: row.importance === 'emergency' ? 'danger' : undefined,
      },
      { type: 'text' as const, value: row.work },
      { type: 'subject' as const, value: row.subject, id: row.id },
      { type: 'image' as const, value: row.image, width: 100, height: 32 },
      {
        type: 'user' as const,
        avatar: row.drafterAvatar,
        value: `${row.drafterName} ${row.drafterJob}`,
        from: row.drafterOrganization,
      },
      {
        type: 'click' as const,
        id: row.id,
        value: `${row.viewer}`,
        code: 'viewer',
      },
      { type: 'date' as const, value: row.date, format: 'simple' },
      {
        type: 'chip' as const,
        value: statusLabel(row.status),
        theme: statusTheme(row.status),
      },
      {
        type: 'more' as const,
        id: row.id,
      },
    ];
  });

  /* 선택 */
  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    setRows((prevState) =>
      prevState.map((x) =>
        x.id === id ? { ...x, checked: event.target.checked } : x,
      ),
    );
  };

  /* 전체선택 */
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRows((prevState) =>
      prevState.map((x) => ({
        ...x,
        checked: event.target.checked,
      })),
    );
  };

  const cancelChecked = () => {
    setRows((prevState) =>
      prevState.map((x) => ({
        ...x,
        checked: false,
      })),
    );
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      dialog: '',
    }));
  };

  const handleCloseFeedback = () => {
    setState((prevState) => ({
      ...prevState,
      dialog: '',
      feedback: '',
    }));

    cancelChecked();
  };

  /* 목록개수 변경 */
  const handleChangeRowLength = (value: number) => {
    setState((prevState) => ({
      ...prevState,
      rowLength: value,
    }));
  };

  /* onClick 이벤트 */
  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code } = arg;
    // console.log('---Action type---', code);
    // console.log('---Action event---', event);
    // console.log('---Action id---', id);

    if (code === 'cancelSelected') {
      cancelChecked();
      return;
    }

    if (code === 'refresh') {
      setLoading(true);
      return;
    }

    if (code === 'listDelete') {
      setState((prevState) => ({
        ...prevState,
        dialog: 'listDelete',
      }));
    }
  };

  const handleSorting = (field: string, value: SortingProps) => {
    // console.log('---sort field---', field);
    // console.log('---sort state---', value);

    setColumns((prevState) =>
      prevState.map((x) => {
        if (x.field === field) return { ...x, sorting: value };
        if (x.sorting !== undefined) return { ...x, sorting: 'none' };
        return x;
      }),
    );
  };

  const handleChangeColumnVisible = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColumns((prevState) =>
      prevState.map((x) =>
        x.field === field ? { ...x, visible: event.target.checked } : x,
      ),
    );
  };

  const handleDelete = () => {
    setRows((prevState) => prevState.filter((x) => !x.checked));
    setState((prevState) => ({
      ...prevState,
      dialog: 'feedback',
      feedback: '결재문서를 삭제했습니다.',
    }));
  };

  const renderDialog = () => {
    if (state.dialog === 'feedback') {
      // console.log('피드백');
      return <FeedBack text={state.feedback} onClose={handleCloseFeedback} />;
    }

    if (state.dialog === 'listDelete') {
      // console.log('리스트 삭제 dialog');
      const filterItems = rows.filter((x) => x.checked);
      const name =
        filterItems.length > 1
          ? `${filterItems[0].subject} 외 ${filterItems.length - 1} 건`
          : `${filterItems[0].subject}`;
      return (
        <DeleteConfirmation
          onSubmit={handleDelete}
          onCancel={handleCloseDialog}
        >
          <strong>&apos;{name}&apos;</strong> 결재문서를 완전히
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    return null;
  };

  /** 기본검색 */
  const handleSearch = () => {
    //
  };

  /** 상세검색 초기화 */
  const handleResetAdvanced = () => {
    setState((prevState) => ({
      ...prevState,
      search: {
        ...prevState.search,
        date: {
          type: 'all',
          start: null,
          end: null,
        },
        filters: [],
      },
    }));
  };

  /** 상세검색 */
  const handleSearchAdvanced = () => {
    //
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>결재문서 관리</EuiHeader.Title>
        <EuiHeader.Search>
          <Search
            keyword={state.search.keyword}
            options={searchCategory}
            filter={state.search.filter}
            date={state.search.date}
            advancedFilter={state.search.filters}
            onSearch={handleSearch}
            onResetAdvanced={handleResetAdvanced}
            onSearchAdvanced={handleSearchAdvanced}
          />
        </EuiHeader.Search>
      </EuiHeader>
      <EuiBody>
        <DataGrid
          caption="결재문서"
          columns={columns}
          rows={newRows}
          checkedCount={rows.filter((i) => i.checked).length}
          pagination={{
            no: 1,
            total: 50,
            row: 15,
            onChangeRow: handleChangeRowLength,
          }}
          defaultActions={defaultActions}
          toolbarActions={toolbarActions}
          moreActions={moreActions}
          onChecked={handleChangeChecked}
          onCheckedAll={handleChangeCheckedAll}
          onClick={handleAction}
          onSorting={handleSorting}
          onChangeColumnVisible={handleChangeColumnVisible}
          loading={loading}
          refresh
          export
        />
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default Page;
