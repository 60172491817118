import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import AttendanceSearch from '../../../../../components/search/AttendanceSearch';
import { SearchDateProps } from '../../../../../components/search/Search';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { getText } from '../../../../../groupware-common/utils';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import { RootState } from '../../../../../groupware-webapp/app/store';

type Props = {
  folderId?: string;
  title: string;
  pageNo: number;
  rowsPerPage: number;
  totalCount: number;
  checkedCount: number;
  onCheckedChange(itemId: 'all', checked: boolean): void;
  toolbarButtons: ActionEventProps[];
  searchCode?: string;
  searchWord?: string;
  startDate?: string;
  endDate?: string;
  onSearch?(arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }): void;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
};

function DocumentContentHeadList(props: Props): JSX.Element {
  const {
    folderId,
    title,
    pageNo,
    rowsPerPage,
    totalCount,
    checkedCount,
    onCheckedChange,
    searchCode = 'subject',
    searchWord,
    onSearch,
    onAction,
    startDate,
    endDate,
  } = props;

  const display = useSelector((state: RootState) => state.session.display);

  /** 기본검색 */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    if (onSearch) onSearch(arg);
  };

  /** 툴바 버튼 클릭 */
  const handleActionClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    props.onAction({ code: arg.code, event: arg.event });
  };

  const searchCategory: { value: string; label: string }[] = [
    { value: 'subject', label: '제목' },
    // { value: 'content', label: '본문' }, // TODO: 검색엔진 적용 필요
    // { value: 'file', label: '첨부파일명' }, // TODO: 검색엔진 적용 필요
  ];

  if (folderId !== 'temp' && folderId !== 'checkout')
    searchCategory.push({ value: 'employee', label: '수정자' });

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceSearch
            dateOptions
            date={{
              start: startDate ? timezoneDate(startDate) : null,
              end: endDate ? timezoneDate(endDate) : null,
            }}
            directory
            dualSearch
            keyword={searchWord}
            options={searchCategory}
            filter={searchCode}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          {checkedCount === 0 ? (
            <>
              <EuiHeader.ToolbarLeft>
                <Button
                  className="check-all"
                  text="선택"
                  iconType
                  icon="list-check"
                  onClick={() => {
                    onCheckedChange('all', true);
                  }}
                />
              </EuiHeader.ToolbarLeft>
              <EuiHeader.ToolbarRight>
                {totalCount > 0 && (
                  <Pagination
                    no={pageNo}
                    rows={rowsPerPage}
                    count={totalCount}
                    onPrev={(event) => onAction({ code: 'prevPage', event })}
                    onNext={(event) => onAction({ code: 'nextPage', event })}
                  />
                )}
                {totalCount > 0 && display !== 'phone' && (
                  <Divider orientation="vertical" />
                )}
                {display !== 'phone' && (
                  <Button
                    text="목록설정"
                    iconType
                    icon="bar-cog"
                    onClick={(event) =>
                      onAction({ code: 'listSetting', event })
                    }
                  />
                )}
              </EuiHeader.ToolbarRight>
            </>
          ) : (
            <EuiHeader.ToolbarLeft>
              <Button
                className="action-close"
                text={getText('취소')}
                iconType
                icon="close"
                onClick={() => {
                  props.onCheckedChange('all', false);
                }}
                vibrate
              />
              <span className="selected-count">
                <em>{props.checkedCount}</em> <span>개 선택됨</span>
              </span>
              <ToolbarAction
                event={props.toolbarButtons}
                onClick={handleActionClick}
              />
            </EuiHeader.ToolbarLeft>
          )}
        </EuiHeader.Toolbar>
      </EuiHeader>
    </>
  );
}

export default DocumentContentHeadList;
