import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CacheEntity,
  CacheMeta,
  LocateArg,
} from '../../../groupware-common/types';
import { approverMacroApi } from '../../apis/approval/v1/approvarmacro';
import { thunkCondition } from '../../../groupware-webapp/stores/utils';
import { getQueryParams } from '../../../groupware-common/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';

/** 결재자 매크로 */
const name = 'approval/approvermacro';

interface MacroItem {
  companyId: number; // 회사 아이디.
  id: number; // 매크로 아이디.
  name: string; // 매크로 이름.
  organizationId: number; // 매크로 담당자 조직 아이디.
  employeeId: number; // 매크로 담당자 아이디.
  createAt: string; // 생성 날짜.
  updateAt: string; // 수정 날짜.
}

interface State {
  list: CacheEntity<{
    items: MacroItem[];
    totalCount: number;
    search: string;
  }>;
}

const initialState: State = {
  list: {
    data: { items: [], totalCount: 0, search: '' },
    meta: { timestamp: 0, lastUpdateAt: '' },
  },
};

const list = createAsyncThunk(
  `${name}/list`,
  async (
    data: {
      arg: {
        search: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    // console.log('list(arg)');
    let meta: CacheMeta | undefined;
    try {
      const {
        arg: { search },
      } = data;
      const { pageNo = 1, rowsPerPage = 15 } = getQueryParams(search);

      const response = await approverMacroApi.list(pageNo, rowsPerPage);
      const items = response.map((x) => {
        return {
          ...x,
        };
      });
      const totalCount = await approverMacroApi.totalCount();

      return { meta, data: { items, totalCount, search } };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const save = createAsyncThunk(
  `${name}/save`,
  async (
    data: {
      arg:
        | {
            id?: undefined;
            name: string;
            organizationId: number;
            employeeId: number;
          }
        | {
            id: number;
            name: string;
            organizationId: number;
            employeeId: number;
            updateAt: string;
          };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    try {
      const { arg, route } = data;

      const response = await approverMacroApi.save(arg);
      if (arg.id !== undefined && arg.id !== response.id)
        return rejectWithValue(appError({ error: '저장할 수 없습니다.' }));

      if (route !== undefined) {
        const search = route.search || '';
        const { pageNo = 1, rowsPerPage = 15 } = getQueryParams(search);
        return {
          meta,
          data: {
            items: await approverMacroApi.list(pageNo, rowsPerPage),
            totalCount: await approverMacroApi.totalCount(),
            search,
          },
        };
      }
      return undefined;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(save.typePrefix, arg, getState);
    },
  },
);

const _delete = createAsyncThunk(
  `${name}/_delete`,
  async (
    data: {
      arg: {
        id: number;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    try {
      const {
        arg: { id, updateAt },
        route,
      } = data;

      const response = await approverMacroApi.delete(id, updateAt);
      if (id !== response.id)
        return rejectWithValue(appError({ error: '삭제할 수 없습니다.' }));

      if (route !== undefined) {
        const search = route.search || '';
        const { pageNo = 1, rowsPerPage = 15 } = getQueryParams(search);
        return {
          meta,
          data: {
            items: await approverMacroApi.list(pageNo, rowsPerPage),
            totalCount: await approverMacroApi.totalCount(),
            search,
          },
        };
      }
      return undefined;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(_delete.typePrefix, arg, getState);
    },
  },
);

const approverMacroSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // macro
      .addCase(list.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(save.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(_delete.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        if (payload?.data !== undefined) state.list.data = payload.data;
      });
  },
});

export default approverMacroSlice.reducer;

export const approverMacroActions = {
  list,
  save,
  delete: _delete,
};
