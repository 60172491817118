import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { documentsActions } from '../../../../stores/document/document';
import { folderActions } from '../../../../stores/document/folders';

function DocumentCheckoutRoute(props: {
  pathname: string;
  search: string;
}): null {
  const pathParams = getPathParams<{ p1?: number }>(
    `/*/*/*/:p1$base62`,
    props.pathname,
  );
  const itemId = pathParams.p1;
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(folderActions.list()),
      dispatch(
        documentsActions.checkoutList({ search, route: { pathname, search } }),
      ),
    ]).then(() => {
      if (itemId !== undefined) {
        dispatch(documentsActions.adminView({ id: itemId }));
      }
    });
  }, [dispatch, pathname, search]);

  return null;
}

export default DocumentCheckoutRoute;
