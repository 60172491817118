import React from 'react';
import ApprovalStatus from './ApprovalStatus';

function ApprovalStatusContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${ApprovalStatusContainer.name}(props)`, props);
  return <ApprovalStatus pathname={props.pathname} search={props.search} />;
}

export default ApprovalStatusContainer;
