import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { formActions, formFolderActions } from '../../../../stores/form';

function BoardFormRoute(props: { pathname: string }): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    if (mount) {
      Promise.all([dispatch(formActions.formListClear())]).then(() => {
        dispatch(
          formFolderActions.folderList({
            route: { pathname },
          }),
        );
      });
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);
  return null;
}

export default BoardFormRoute;
