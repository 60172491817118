import React from 'react';
import { filenameExtensionIcon } from '../../groupware-common/utils/ui';
import Avatar from '../avatar/Avatar';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';
import Tooltip from '../tooltip/Tooltip';
import Button from '../button/Button';

function FileChipGroup(props: {
  index?: number;
  ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  label: string;
  avatar?: string;
  icon?: IconType;
  etc?: string;
  theme?: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  color?: string;
  size?: 'sm' | 'xs';
  selected?: boolean;
  disabled?: boolean;
  tooltip?: string;
  filename: string;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onDownload?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  const { label, color, selected, disabled } = props;

  let classname = 'eui-chip';
  if (props.className) classname += ` ${props.className}`;
  if (props.size) classname += ` ${props.size}`;
  if (props.theme) classname += ` ${props.theme}`;
  if (selected) classname += ' selected';
  if (color) classname += ' color';
  if (props.onClick) classname += ' control';

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disabled && props.onClick) props.onClick(event);
  };

  const handleDownload = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (props.onDownload) props.onDownload(event);
  };

  const content = (
    <>
      {props.avatar && (
        <Avatar name={label} image={props.avatar} className="avatar" />
      )}
      {props.icon && <Icon icon={props.icon} className="icon" />}
      {props.filename && (
        <i
          className="type"
          data-file-format={filenameExtensionIcon(props.filename)}
        />
      )}
      {props.tooltip ? (
        <Tooltip title={props.tooltip}>
          <span className="text">
            <span className="label">{label}</span>
          </span>
        </Tooltip>
      ) : (
        <span className="text">
          <span className="label">{label}</span>
        </span>
      )}
      {props.etc !== undefined && <em className="etc">{props.etc}</em>}
      {props.onDownload && (
        <Button
          text="다운로드"
          iconType
          icon="import"
          className="action"
          onClick={handleDownload}
          color="secondary"
        />
      )}
    </>
  );

  return props.onClick ? (
    <div
      title={label}
      role="button"
      className={`${classname} file-chip`}
      aria-disabled={disabled}
      onClick={handleClick}
      data-chip-index={props.index}
      tabIndex={0}
      ref={props.ref}
    >
      {content}
    </div>
  ) : (
    <div
      title={label}
      className={`${classname} eui-file-chip`}
      aria-checked={selected}
      aria-disabled={disabled}
      data-chip-index={props.index}
      ref={props.ref}
    >
      {content}
    </div>
  );
}

export default FileChipGroup;
