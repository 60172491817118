import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import SelectField from '../../../../../components/selectField/SelectField';
import { Country } from '../../../../../groupware-common/types';
import { getText } from '../../../../../groupware-common/utils';

function ComponentSelectCustom(): JSX.Element {
  const options: {
    value: string;
    label: string;
    flag: Country;
    add: string;
  }[] = [
    { value: 'us', label: getText('country.US'), flag: 'US', add: '+1' },
    { value: 'kr', label: getText('country.KR'), flag: 'KR', add: '+82' },
    { value: 'cn', label: getText('country.CN'), flag: 'CN', add: '+86' },
  ];

  const newCountrySelect = options.sort((a, b) => (a.label > b.label ? 1 : -1));

  const [state, stateState] = useState('kr');

  const handleChangeCountryCode = (value: string) => {
    stateState(value);
  };

  return (
    <ComponentSampleUI.Content title="지역 선택">
      <SelectField
        data={newCountrySelect}
        value={state}
        onChange={handleChangeCountryCode}
      />
      {options.find((x) => x.value === state)?.add}
    </ComponentSampleUI.Content>
  );
}

export default ComponentSelectCustom;
