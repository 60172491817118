import React, { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { getText } from '../../groupware-common/utils';
import { contrastColor } from '../../groupware-common/utils/ui';
import Button from '../button/Button';
import CreatePortal from '../createPortal/CreatePortal';

function CustomColorPicker(props: {
  id?: number;
  color: string;
  description?: string;
  onSave(color: string, id?: number): void;
  onClose(): void;
}): JSX.Element {
  const [color, setColor] = useState(props.color);

  const handleChangeColor = (value: ColorResult) => {
    setColor(`${value.hex}`);
  };

  return (
    <CreatePortal>
      <div className="eui-custom-color-picker">
        <div className="overlay" />
        <div className="container">
          <h2 className="title">맞춤 색상선택</h2>
          {props.description && (
            <div className="description">{props.description}</div>
          )}
          <div className="picker">
            <ChromePicker color={color} onChange={handleChangeColor} />
            <div
              className="sample"
              style={{
                backgroundColor: color,
                color: contrastColor(color),
              }}
            >
              A
            </div>
          </div>
          <div className="action">
            <Button text={getText('취소')} onClick={() => props.onClose()} />
            <Button
              text={getText('적용')}
              variant="contained"
              onClick={() => props.onSave(color, props.id)}
            />
          </div>
        </div>
      </div>
    </CreatePortal>
  );
}

export default CustomColorPicker;
