import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../../components/alert/FeedBack';
import Button from '../../../../../../components/button/Button';
import PostWrite from '../../../../../../components/post/PostWrite';
import Signature from '../../../../../../components/signature/Signature';
import MemoizeDivElement from '../../../../../../groupware-approval/pages/root/approval/common/components/MemoizeDivElement';
import FormBuilder from '../../../../../../groupware-approval/stores/approval/FormBuilder';
import { getQueryParams } from '../../../../../../groupware-common/utils';
import { dateFormat } from '../../../../../../groupware-common/utils/ui';
import {
  getEmployeeName,
  useDirectory,
} from '../../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../../groupware-webapp/stores/session';
import attendanceFormApi from '../../../../../apis/attendance/v1/form';
import AttendancePreferencesApi from '../../../../../apis/attendance/v1/preferences';

type Props = {
  search: string;
  onClick(contents: string, signature?: string): void;
  rest: string;
  isAlertFormSignature: boolean;
};

function AttendanceTeamsNoticeDocumentEdit(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(props.search);

  const directory = useDirectory();
  const employeeView = useSelector(
    (state: RootState) => state.directory.employee.view.data,
  );
  const principal = useSelector((state: RootState) => state.session.principal);
  const alertEmployeeId = useSelector(
    (state: RootState) => state.attendance.preferences.notice.alertEmployeeId,
  );
  const companyView = useSelector((s: RootState) => {
    return s.directory.company.view.data;
  });

  const { expressionUnit } = useSelector(
    (state: RootState) => state.attendance.preferences.basic,
  );
  const { workingHours } = useSelector(
    (state: RootState) => state.attendance.preferences,
  );

  const contentRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<{
    loading: boolean;
    content: string;
    signature?: string;
    draw: boolean;
    remain: number;
    validation: string;
  }>({
    loading: true,
    content: '',
    signature: props.isAlertFormSignature ? '' : undefined,
    draw: false,
    remain: 0,
    validation: '',
  });

  useEffect(() => {
    async function run() {
      try {
        // 사용계획서 양식 설정.
        const formId = (await AttendancePreferencesApi.selectNotice())
          .workerPlanFormId;
        let content = '';
        await attendanceFormApi.view(formId).then((result) => {
          if (result !== null && result.status === 1) {
            content = result.contents;
          }
          const usePlanSignature = content.match(
            /(<gw-fb-element[^>]+?data-attendance="ATTENDANCE\/SENDNOTICE_SIGNATURE"[^>]*>)(.*?)<\/gw-fb-element>/gs,
          );
          setState((prevState) => ({
            ...prevState,
            loading: false,
            content,
            signature: usePlanSignature ? '' : prevState.signature,
          }));
        });
      } catch (e) {
        // 문서 오류 처리
        setState((prevState) => ({
          ...prevState,
        }));
        dispatch(sessionActions.error((e as Error).message));
      }
    }
    run();
  }, []);

  const handleSnackbarClose = () => {
    setState((prev) => ({
      ...prev,
      validation: '',
    }));
  };

  const getMacro = (arg: { id: string }) => {
    if (employeeView === null || employeeView === undefined) return undefined;
    const { id } = arg;
    const directoryData = getDirectoryData({
      ...directory,
      companyId: principal.companyId,
      employeeId: principal.employeeId,
    });

    // 회사명. _CMP_NAME_
    if (id === 'DIRECTORY/COMAPNY') {
      return directoryData.companyName;
    }
    // 회사 우편번호.
    if (id === 'DIRECTORY/COMPANY_POSTALCODE') {
      return companyView?.postalCode ?? '';
    }
    // 회사주소.
    if (id === 'DIRECTORY/COMPANY_ADDRESS') {
      return companyView?.address ?? '';
    }
    // 회사전화.
    if (id === 'DIRECTORY/COMPANY_PHONE_NO') {
      return companyView?.phoneNo ?? '';
    }
    // 회사팩스.
    if (id === 'DIRECTORY/COMPANY_FAX_NO') {
      return companyView?.faxNo ?? '';
    }
    // 오늘날짜.
    if (id === 'COMMON/TODAY') return dateFormat(new Date(), 'YYYY-MM-DD');
    /** 연차촉진 발송자명 */
    if (id === 'ATTENDANCE/SENDNOTICE_SENDER_NAME')
      return getEmployeeName({
        companyId: principal.companyId,
        employeeId: alertEmployeeId,
      });
    /** 연차촉진 대상자명 */
    if (id === 'ATTENDANCE/SENDNOTICE_NAME') return directoryData.employeeName;
    /** 연차촉진 대상자사번 */
    if (id === 'ATTENDANCE/SENDNOTICE_EMPLOYEENO') return employeeView.no;
    /** 연차촉진 대상자부서 */
    if (id === 'ATTENDANCE/SENDNOTICE_ORGANIZATION')
      return directoryData.organizationName;
    /** 연차촉진 대상자직위 */
    if (id === 'ATTENDANCE/SENDNOTICE_JOBPOSITION')
      return directoryData.jobPositionName;
    /** 연차촉진 대상자입사일자 */
    if (id === 'ATTENDANCE/SENDNOTICE_ENTERDATE') return employeeView.enterDate;
    /** 사용계획 잔여 연차. */
    if (id === 'ATTENDANCE/USEPLAN_REMAIN_COUNT') return props.rest;
    /** 연차촉진 대상자서명 */
    if (id === 'ATTENDANCE/SENDNOTICE_SIGNATURE') return state.signature;
    /** 잔여 연차. */
    if (id === 'ATTENDANCE/REMAIN_COUNT') return state.remain.toString();

    return undefined;
  };

  /** 콘텐트 로드. */
  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { content } = state;
      const { element } = arg;
      if (state.signature)
        element.innerHTML = FormBuilder.binding({
          html: element.innerHTML,
          getMacro,
        });
      else
        element.innerHTML = FormBuilder.createComposeHtml({
          html: content,
          workingHours,
          expressionUnit,
          getMacro,
        });
    },
    [queryParams.contentMode, state.content, state.signature],
  );

  /** 매크로 필수 입력값 입력안했을 경우 */
  function createComposeStyle(
    data: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  ) {
    const element = data;
    element.focus();
    element.style.background = '#ffebee';
  }

  /** 연차사용계획서 발송. */
  const handleClick = () => {
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    // 연차촉진 총사용일수 매크로의 설정이 필수일 때만 validation
    const element = contentRef.current.querySelector(
      'gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL"]',
    );
    if (element && element.getAttribute('required') === 'true') {
      const totalUseElement = contents.match(
        /<gw-fb-element[^>]+?data-attendance="ATTENDANCE\/SENDNOTICE_SCHEDULED_DATE_TOTAL"[^>]*>(.*?)<\/gw-fb-element>/gs,
      );

      const totalUse = totalUseElement
        ? totalUseElement[0].replace(/(<([^>]+)>)/gi, '')
        : '';

      if (totalUse !== props.rest) {
        const input = element.querySelector('input');
        if (input === null) return;
        createComposeStyle(input);
        const validation =
          '사용계획 잔여연차와 연차촉진 총사용일수 값은 다를 수 없습니다.';
        setState((prevState) => ({ ...prevState, validation }));
        return;
      }
    }

    let validation = '';
    contentRef.current
      .querySelectorAll(
        'gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE"]',
      )
      .forEach((elem) => {
        const dateElement = elem.querySelector(
          'input[type="date"]',
        ) as HTMLInputElement | null;
        if (dateElement === null) return;
        const today = moment().format('YYYY-MM-DD');
        const date = dateElement.getAttribute('value');
        if (!date) {
          createComposeStyle(dateElement);
          validation = '날짜가 선택되지 않았습니다.';
        } else if (date < today) {
          createComposeStyle(dateElement);
          validation = '지난 날짜는 선택하실 수 없습니다.';
        }
      });
    if (validation !== '') {
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    props.onClick(contents, state.signature);
  };

  const handleClickSignature = () => {
    setState((prev) => ({
      ...prev,
      draw: true,
    }));
  };
  const handleSignatureSave = (signature: string) => {
    setState((prev) => ({
      ...prev,
      signature,
      draw: false,
    }));
  };

  const renderDialog = () => {
    if (state.draw) {
      return (
        <Signature
          onClose={() => setState((prev) => ({ ...prev, draw: false }))}
          onSave={handleSignatureSave}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="eui-post-view">
        <div className="view-container">
          <PostWrite.Edit>
            <PostWrite.Body>
              <PostWrite.Content>
                <MemoizeDivElement
                  ref={contentRef}
                  className="editor-html dialog-write-html"
                  onLoad={handleContentLoad}
                />
              </PostWrite.Content>
            </PostWrite.Body>

            <div>
              {state.signature !== undefined && (
                <Button
                  text="서명이미지"
                  variant="outlined"
                  placement="top-start"
                  onClick={handleClickSignature}
                />
              )}
              <Button
                disabled={state.signature === ''}
                text="제출"
                variant="contained"
                placement="top-start"
                onClick={handleClick}
              />
            </div>
          </PostWrite.Edit>
        </div>
      </div>
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceTeamsNoticeDocumentEdit;
