import React from 'react';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentBreadcrumbsBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic breadcrumbs">
      <Breadcrumbs>
        <Breadcrumbs.Item text="UI" />
        <Breadcrumbs.Item text="Components" />
        <Breadcrumbs.Item text="Breadcrumbs" current />
      </Breadcrumbs>
    </ComponentSampleUI.Content>
  );
}

export default ComponentBreadcrumbsBasic;
