import React, { useEffect, useRef } from 'react';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import AttendanceNoticeBodyListItem from './AttendanceNoticeBodyListItem';

type Item = {
  companyId: number; // 회사 키
  employeeId: number; // 사원 아이디
  standardDate: string; // 기준 일자
  alertType: number; // 연차촉진 알림 구분(1 : 1차, 2 : 2차, 0 :일반연차)
  firstAlarmSendDate: string; // 1차 촉진 알림 발송일
  firstAlarmId: number; // 1차 촉진 알림 발송 아이디
  firstAlarmSender: number; // 1차 촉진 알림 발송자 아이디 (알림 담당자)
  firstAlarmSenderName: string; // 1차 촉진 알림 발송자 아이디 (알림 담당자)
  workerPlanSendDate: string; // 근로자 연차 사용 계획서 발송 일
  workerPlanId: number; // 근로자 연차 사용 계획서 발송 아이디
  secondAlarmSendDate: string; // 2차 지정 통보 알림 발송일
  secondAlarmId: number; // 2차 지정 통보 알림 발송 아이디
  updateAt: string; // 수정 시간
};

type Props = {
  pageNo?: number;
  rowsPerPage?: number;
  searchCode?: string;
  searchWord?: string;
  type?: PostListType;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: Item[];
  onhandleDocumentPopup(arg: {
    columnsName: string;
    columnsValue: number;
    type: string;
    id: number;
    employeeId: number;
    standardDate: string;
    alertType: number;
    firstAlarmId?: number;
    firstAlarmSendDate: string;
  }): void;
};

function AttendanceNoticeBodyList(props: Props): JSX.Element {
  const {
    pageNo,
    rowsPerPage,
    searchCode,
    searchWord,
    type,
    density,
    columns,
    items,
    onhandleDocumentPopup,
  } = props;

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo, rowsPerPage, searchCode, searchWord]);

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <PostList name="attendance" type={type} density={density}>
      <PostList.Head>
        {columns.map(({ name, type: colunmType, text }) => (
          <PostList.Cell
            key={name}
            name={text}
            type={colunmType}
            align={
              colunmType === 'status' || colunmType === 'count'
                ? 'center'
                : undefined
            }
            autoSize
          />
        ))}
      </PostList.Head>
      <PostList.Body scrollableNodeProps={scrollbar}>
        {items.map(
          (
            {
              companyId,
              employeeId,
              standardDate,
              alertType,
              firstAlarmSendDate,
              firstAlarmId,
              firstAlarmSender,
              workerPlanSendDate,
              workerPlanId,
              secondAlarmSendDate,
              secondAlarmId,
              updateAt,
            },
            i,
          ) => {
            const item = {
              companyId,
              employeeId,
              standardDate,
              alertType,
              firstAlarmSendDate,
              firstAlarmId,
              firstAlarmSender,
              workerPlanSendDate,
              workerPlanId,
              secondAlarmSendDate,
              secondAlarmId,
              updateAt,
            };
            const key = `/i${i}/${alertType}}`;
            return (
              <AttendanceNoticeBodyListItem
                key={key}
                columns={columns}
                item={item}
                onhandleDocumentPopup={onhandleDocumentPopup}
              />
            );
          },
        )}
      </PostList.Body>
    </PostList>
  );
}

export default AttendanceNoticeBodyList;
