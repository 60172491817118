import { useEffect } from 'react';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { attendancesActions } from '../../../../stores/attendance/attendances';

function AttendanceSubstituteRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(search);
  queryParams.id = 0;
  useEffect(() => {
    dispatch(
      attendancesActions.substituteList({
        search: getQueryParams(queryParams),
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  }, [dispatch, props]);
  return null;
}

export default AttendanceSubstituteRoute;
