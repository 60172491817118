import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Switch from '../../../../../components/switch/Switch';

function ComponentSwitchBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic switches">
      <Switch />
      <Switch checked />
      <Switch disabled />
      <Switch checked disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentSwitchBasic;
