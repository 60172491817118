import React from 'react';
import EuiSetting from '../../../../components/layout/EuiSetting';
import Avatar from '../../../../components/avatar/Avatar';
import Button from '../../../../components/button/Button';

function DirectorySelected(props: {
  items: {
    id: string;
    name: string;
    icon: 'company' | 'person' | 'sitemap';
    avatar?: string | undefined;
  }[];
  onDelete(id: string): void;
  onCreateChat?(): void;
  onCreateVideoCall?(): void;
  onCreateMail?(): void;
}): JSX.Element {
  const { items, onDelete, onCreateChat, onCreateVideoCall, onCreateMail } =
    props;
  return (
    <>
      <EuiSetting.Header title="대화상대 선택" />
      <EuiSetting.Content>
        {items.length === 0 ? (
          <div className="unselected">선택된 대화상대가 없습니다</div>
        ) : (
          <div className="selected-list">
            {[...items].reverse().map((x) => {
              return (
                <div className="selected-item" key={x.id}>
                  <Avatar
                    name={x.name}
                    image={x.avatar}
                    icon={x.icon}
                    className="avatar"
                  />
                  <span className="name">{x.name}</span>
                  <Button
                    text="삭제"
                    className="action"
                    iconType
                    icon="times-small"
                    onClick={() => onDelete(x.id)}
                    color="secondary"
                    size="xs"
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className="selected-action">
          {onCreateChat && (
            <Button
              text="대화하기"
              iconType
              icon="comments-fill"
              disabled={items.length === 0}
              onClick={onCreateChat}
            />
          )}
          {onCreateVideoCall && (
            <Button
              text="화상채팅"
              iconType
              icon="video-fill"
              disabled={items.length === 0}
              onClick={onCreateVideoCall}
            />
          )}
          {onCreateMail && (
            <Button
              text="메일보내기"
              iconType
              icon="send"
              disabled={items.length === 0}
              onClick={onCreateMail}
            />
          )}
        </div>
      </EuiSetting.Content>
    </>
  );
}

export default DirectorySelected;
