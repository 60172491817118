import React from 'react';
import EuiToolbar from '../layout/EuiToolbar';

function DrawerToolbarLeft(props: { children: React.ReactNode }): JSX.Element {
  return <EuiToolbar.Left>{props.children}</EuiToolbar.Left>;
}

function DrawerToolbarRight(props: { children: React.ReactNode }): JSX.Element {
  return <EuiToolbar.Right>{props.children}</EuiToolbar.Right>;
}

function DrawerToolbar(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'drawer-toolbar';
  if (props.className) classname += ` ${props.className}`;
  return <EuiToolbar className={classname}>{props.children}</EuiToolbar>;
}

DrawerToolbar.Left = DrawerToolbarLeft;
DrawerToolbar.Right = DrawerToolbarRight;

export default DrawerToolbar;
