import React from 'react';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import Tooltip from '../tooltip/Tooltip';

function PostWrite(props: {
  name?: string;
  fullSize?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-post-write';
  if (props.fullSize) classname += ' full';

  return (
    <div className={classname} data-post-name={props.name}>
      {props.children}
    </div>
  );
}

function PostWriteBody(props: { children: React.ReactNode }): JSX.Element {
  return <div className="write-body">{props.children}</div>;
}

function PostWriteToolbar(props: {
  onCancel?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="write-toolbar">
      {props.onCancel && (
        <Button text="취소" iconType icon="close" onClick={props.onCancel} />
      )}
      {props.children}
    </div>
  );
}

function PostWriteTitle(props: { children: React.ReactNode }): JSX.Element {
  return <div className="write-title">{props.children}</div>;
}

function PostWriteEdit(props: { children: React.ReactNode }): JSX.Element {
  return <div className="write-editor">{props.children}</div>;
}

function PostWriteContent(props: { children: React.ReactNode }): JSX.Element {
  return <div className="body-content">{props.children}</div>;
}

function PostWriteSide(props: { children: React.ReactNode }): JSX.Element {
  return <div className="body-side">{props.children}</div>;
}

function PostWriteItem(props: {
  tooltip?: string;
  title?: string | React.ReactNode;
  required?: boolean;
  direction?: 'row' | 'column';
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'write-item';
  if (props.direction) classname += ` ${props.direction}`;
  if (props.className) classname += ` ${props.className}`;
  if (props.required) classname += ' required';

  return (
    <>
      <div className={classname}>
        {props.title && (
          <div className="item-head">
            {props.title}
            {props.tooltip && (
              <Tooltip title={props.tooltip}>
                <i
                  className="eui-icon eui-icon-info-circle-fill tip"
                  style={{ fontSize: '15px', margin: '3px 0 1px 4px' }}
                />
              </Tooltip>
            )}
          </div>
        )}
        <div className="item-content">{props.children}</div>
      </div>
    </>
  );
}

function PostWriteIconItem(props: {
  title?: string;
  icon?: IconType;
  required?: boolean;
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'write-item icon';
  if (props.className) classname += ` ${props.className}`;
  if (props.required) classname += ' required';

  return (
    <>
      <div className={classname}>
        <div className="item-head">
          {props.icon && (
            <Icon icon={props.icon} label={props.title} tooltip={props.title} />
          )}
        </div>
        <div className="item-content">{props.children}</div>
      </div>
    </>
  );
}

function PostWriteItemArea(props: { children: React.ReactNode }): JSX.Element {
  return <div className="item-content-area">{props.children}</div>;
}

function PostWriteItemValue(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'item-value';
  if (props.className) classname += ` ${props.className}`;
  return <div className={classname}>{props.children}</div>;
}

function PostWriteAction(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'write-action';
  if (props.className) classname += ` ${props.className}`;
  return <div className={classname}>{props.children}</div>;
}

PostWrite.Toolbar = React.memo(PostWriteToolbar);
PostWrite.Body = React.memo(PostWriteBody);
PostWrite.Content = React.memo(PostWriteContent);
PostWrite.Side = React.memo(PostWriteSide);
PostWrite.Title = React.memo(PostWriteTitle);
PostWrite.Edit = React.memo(PostWriteEdit);
PostWrite.Item = React.memo(PostWriteItem);
PostWrite.IconItem = React.memo(PostWriteIconItem);
PostWrite.ItemArea = React.memo(PostWriteItemArea);
PostWrite.Value = React.memo(PostWriteItemValue);
PostWrite.Action = React.memo(PostWriteAction);

export default PostWrite;
