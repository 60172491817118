import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import SelectField from '../../../../../components/selectField/SelectField';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import AttendanceContentBodyListItem from '../content/AttendanceContentBodyListItem';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import AttendanceDetailDialog from '../../../common/AttendanceDetailDialog';
import EmptyData from '../../../../../components/data/EmptyData';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

function AttendanceOrganizationStatusContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const queryParams = getQueryParams(search);

  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const organizationIds = principal.affiliatedOrganizations
    .filter((a) => a.manager === true)
    .map(({ id }) => id);
  const archiveOrganizationId = useSelector(
    (state: RootState) => state.attendance.organization.archiveOrganizationId,
  );
  const dayList = useSelector(
    (state: RootState) => state.attendance.organization.dayList,
  );
  const monthsList = useSelector(
    (state: RootState) => state.attendance.organization.monthList,
  );
  const folders = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  );
  /** 근태코드 */
  const attendanceCodeList = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );
  const title = folders.find((a) => a.id === folderId)?.name;
  const currentYear = timezoneDate().getFullYear();

  const scrollbar = useRef<HTMLDivElement>(null);

  const attendanceCodeColumn: Column<PostListItemType>[] =
    attendanceCodeList.map((a) => {
      return {
        name: a.name,
        text: a.name,
        type: 'post',
        visible: true,
      };
    });
  const monthCodeColumn: Column<PostListItemType>[] = [];
  for (let i = 1; i <= 12; i += 1) {
    monthCodeColumn.push({
      name: i.toString(),
      text: `${i}월`,
      type: 'post',
      visible: true,
    });
  }

  let columns: Column<PostListItemType>[] = [];
  if (folderId === 'organizationDays')
    columns = [
      // eslint-disable-next-line prettier/prettier
      { name: 'employeeNo', text: '사번', type: display === 'phone' ? 'post' : 'form', visible: true },
      { name: 'user', text: '이름', type: 'user', visible: true },
      ...attendanceCodeColumn,
      { name: 'total', text: '합계', type: 'post', visible: true },
    ];
  else
    columns = [
      // eslint-disable-next-line prettier/prettier
      { name: 'employeeNo', text: '사번', type: display === 'phone' ? 'post' : 'form', visible: true },
      { name: 'user', text: '이름', type: 'user', visible: true },
      // eslint-disable-next-line prettier/prettier
      { name: 'attendanceCode', text: '구분(그룹)', type: 'folder', visible: true },
      ...monthCodeColumn,
      { name: 'total', text: '합계', type: 'post', visible: true },
    ];

  /** (부서장 권한 존재) 겸직부서일 경우 겸직부서 리스트.  */
  const selectData = organizationIds.map((a) => {
    const organizationName = getOrganizationName(principal.companyId, a);
    return {
      value: a.toString(),
      label: organizationName,
    };
  });

  const [state, setState] = useState<{
    startDate: Date | null;
    endDate: Date | null;
    selectedYear: string;
    name: string; // 상태 구분 값. (발생, 사용, 조정일수)
    month: string; // 월별근태현황에서 사용하기 위한 월 별 구분 값. (1월, 2월, ...), 월별구분 안 할 경우 ''으로 보냄.
  }>(() => {
    return {
      startDate: null,
      endDate: null,
      selectedYear: queryParams.status ?? currentYear.toString(),
      name: '',
      month: '',
    };
  });
  const selectedYearOptions: { value: string; label: string }[] = [];
  const getYear = currentYear - 4;
  for (let i = 0; i < 5; i += 1) {
    const value = getYear + i;
    selectedYearOptions.push({
      value: value.toString(),
      label: value.toString(),
    });
  }
  selectedYearOptions.push({
    value: (currentYear + 1).toString(),
    label: (currentYear + 1).toString(),
  });

  /** 겸직부서일 경우 부서 변경. */
  const handleChange = (value: string) => {
    queryParams.searchCode = 'organization';
    queryParams.directoryKeyword = `${principal.companyId}_${value}`;
    go(pathname, getQueryParams(queryParams));
  };

  /** 검색. */
  const handleSearch = () => {
    const { startDate, endDate, selectedYear } = state;
    if (folderId === 'organizationDays') {
      if (startDate === null || endDate === null) return;

      queryParams.startDate = dateFormat(initialDate(startDate), 'yyyy-MM-DD');
      queryParams.endDate = dateFormat(initialDate(endDate), 'yyyy-MM-DD');
    } else queryParams.status = selectedYear;
    go(pathname, getQueryParams(queryParams));
  };

  /** 기간별 - 시작 기간 변경. */
  const handleChangeCustomStartDate = (date: Date | null) => {
    if (date === null) return;
    setState((prevState) => ({
      ...prevState,
      startDate: date,
    }));

    if (state.endDate !== null && state.endDate < date)
      setState((prevState) => ({
        ...prevState,
        endDate: date,
      }));
  };

  /** 기간별 - 종료 기간 변경. */
  const handleChangeCustomEndDate = (date: Date | null) => {
    if (date === null) return;
    setState((prevState) => ({
      ...prevState,
      endDate: date,
    }));
  };

  /** 월별 - 년도 변경. */
  const handleChangeSelectedYear = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedYear: value,
    }));
  };

  useEffect(() => {
    const today = timezoneDate();
    today.setDate(1);
    const startDate = queryParams.startDate
      ? timezoneDate(queryParams.startDate)
      : today;
    const endDate = timezoneDate(queryParams.endDate ?? undefined);
    setState((prevState) => ({
      ...prevState,
      startDate,
      endDate,
      selectedYear: queryParams.status ?? currentYear.toString(),
    }));
    scrollbar.current?.scrollTo(0, 0);
  }, [pathname, search]);

  const handleClick = (name: string, month: string, id?: number) => {
    if (id === undefined) return;
    queryParams.dialogType = 'detailStatus';
    queryParams.id = id;
    const data = attendanceCodeList.find((a) => a.name === name);
    if (folderId === 'organizationDays' && data) {
      setState((prev) => ({
        ...prev,
        name: `${data.id}`,
        month,
      }));
    } else if (folderId === 'organizationMonths' && data) {
      setState((prev) => ({
        ...prev,
        name: `${data.id}`,
        month,
      }));
    }
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };
  const { startDate, endDate, selectedYear } = state;

  const renderHead = () => {
    return (
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <span
            style={
              display === 'phone' && folderId === 'organizationDays'
                ? { display: 'flex', width: 'initial' }
                : { display: 'flex', marginLeft: 'auto' }
            }
          >
            <div className="eui-search">
              {folderId === 'organizationDays' && (
                <div
                  style={{
                    display: 'inline-flex',
                    width: '210px',
                  }}
                >
                  <CustomDatePicker
                    placeholderText="연도.월.일"
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={handleChangeCustomStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsStart
                    portalId="portals"
                  />
                  <CustomDatePicker
                    placeholderText="연도.월.일"
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={handleChangeCustomEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    selectsEnd
                    portalId="portals"
                  />
                </div>
              )}
              <div className="basic-search">
                {folderId === 'organizationMonths' && (
                  <SelectField
                    data={selectedYearOptions}
                    value={selectedYear}
                    onChange={handleChangeSelectedYear}
                    className="filter"
                  />
                )}
                <Button
                  text="검색"
                  iconType
                  icon="search"
                  className="submit"
                  onClick={handleSearch}
                />
              </div>
            </div>
          </span>
        </EuiHeader.Search>
      </EuiHeader>
    );
  };

  const renderBody = () => {
    const message = '검색된 데이터가 없습니다.';
    // 기간별근태현황.
    if (folderId === 'organizationDays') {
      return dayList.items.length > 0 ? (
        dayList.items.map((a, i) => {
          const employeeNo =
            directory.employees.find(({ id }) => id === a.employeeId)?.no ?? ''; // 사번.
          const directoryData = getDirectoryData({
            ...directory,
            companyId: a.companyId,
            organizationId: queryParams.directoryKeyword
              ? Number(queryParams.directoryKeyword.split('_')[1])
              : Number(selectData[0].value),
            employeeId: a.employeeId,
          });

          const obj: {
            [key: string]: string;
          } = {};
          attendanceCodeList.forEach((x) => {
            const code = a.list.find((c) => c.attendanceCode === x.id);
            if (code) obj[x.name] = code.ammount;
            else obj[x.name] = '0일';
          });
          const item = {
            employeeNo,
            user: {
              name: directoryData.employeeName,
              organization: directoryData.organizationName,
              job: directoryData.jobPositionName,
              avatar: directoryData.avatar ?? '',
            },
            ...obj,
            total: a.total,
          };
          const key = `/i${i}/${a.companyId}/${a.employeeId}/}`;
          return (
            <AttendanceContentBodyListItem
              organizationStatus
              key={key}
              id={a.employeeId}
              columns={columns}
              item={item}
              onStatusClick={handleClick}
            />
          );
        })
      ) : (
        <EmptyData message={message} />
      );
    }
    // 월별근태현황
    return monthsList.items.length > 0 ? (
      monthsList.items.map((a, i) => {
        const employeeNo =
          directory.employees.find(({ id }) => id === a.employeeId)?.no ?? ''; // 사번.
        const directoryData = getDirectoryData({
          ...directory,
          companyId: a.companyId,
          organizationId: queryParams.directoryKeyword
            ? Number(queryParams.directoryKeyword.split('_')[1])
            : Number(selectData[0].value),
          employeeId: a.employeeId,
        });
        const attendanceCode = attendanceCodeList.find(
          (x) => x.id === a.attendanceCode,
        )?.name;

        const item = {
          employeeNo,
          user: {
            name: directoryData.employeeName,
            organization: directoryData.organizationName,
            job: directoryData.jobPositionName,
            avatar: directoryData.avatar ?? '',
          },
          attendanceCode: attendanceCode ?? '',
          total: a.ammountInTotal,
          '1': a.ammountInJanuary,
          '2': a.ammountInFebuary,
          '3': a.ammountInMarch,
          '4': a.ammountInApril,
          '5': a.ammountInMay,
          '6': a.ammountInJune,
          '7': a.ammountInJuly,
          '8': a.ammountInAugust,
          '9': a.ammountInSeptember,
          '10': a.ammountInOctober,
          '11': a.ammountInNovember,
          '12': a.ammountInDecember,
        };
        const key = `/i${i}/${a.companyId}/${a.employeeId}/}`;
        return (
          <AttendanceContentBodyListItem
            organizationStatus
            attendanceCode
            key={key}
            id={a.employeeId}
            columns={columns}
            item={item}
            onStatusClick={handleClick}
          />
        );
      })
    ) : (
      <EmptyData message={message} />
    );
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    const { name, month } = state;
    if (dialogType === 'detailStatus') {
      return (
        <AttendanceDetailDialog
          folderId={folderId}
          name={name}
          month={month}
          search={search}
          onClose={handleDialogClose}
        />
      );
    }
    return null;
  };

  let minWidth: number | undefined;
  if (display !== 'phone') {
    if (folderId === 'organizationDays')
      minWidth = 310 + 110 * (attendanceCodeList.length + 1);
    else minWidth = 1860;
  }
  return (
    <>
      {renderHead()}
      <EuiToolbar>
        <EuiToolbar.Left>
          {/* 겸직자일 경우 - 겸직부서 선택 가능. */}
          {organizationIds.length > 1 && (
            <SelectField
              data={selectData}
              value={archiveOrganizationId.toString()}
              onChange={handleChange}
            />
          )}
        </EuiToolbar.Left>
      </EuiToolbar>
      <EuiBody>
        <PostList
          name={title ?? ''}
          type="full"
          style={
            display !== 'phone' && minWidth
              ? { minWidth: `${minWidth}px` }
              : undefined
          }
        >
          <PostList.Head>
            {columns.map(({ name, type: colunmType, text }) => (
              <PostList.Cell
                key={name}
                name={text}
                type={colunmType}
                style={
                  colunmType === 'post' && display !== 'phone'
                    ? {
                        minWidth: '110px',
                        width: '7%',
                        justifyContent: 'center',
                      }
                    : undefined
                }
              />
            ))}
          </PostList.Head>
          <PostList.Body scrollableNodeProps={scrollbar}>
            {renderBody()}
          </PostList.Body>
        </PostList>
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default AttendanceOrganizationStatusContainer;
