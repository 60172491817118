import { useEffect } from 'react';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { resourcesActions } from '../../../../stores/resources';

function ResourceReservationListRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const { p1 } = getPathParams<{ p1?: string }>('/*/:p1', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    const startDate = timezoneDate();
    startDate.setMonth(timezoneDate().getMonth() - 3);
    startDate.setDate(1);
    const endDate = timezoneDate();
    endDate.setMonth(timezoneDate().getMonth() + 3);
    endDate.setDate(1);
    queryParams.startDate =
      queryParams.startDate ?? dateFormat(initialDate(startDate), 'yyyy-MM-DD');
    queryParams.endDate =
      queryParams.endDate ?? dateFormat(initialDate(endDate), 'yyyy-MM-DD');

    const params = {
      search: getQueryParams(queryParams),
      route: {
        pathname,
        search: getQueryParams(queryParams),
      },
    };
    if (mount) {
      if (!p1) dispatch(resourcesActions.myReservationList(params));
      else dispatch(resourcesActions.sharedReservationList(params));
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default ResourceReservationListRoute;
