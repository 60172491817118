import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import RadioGroup from '../../../../../components/radio/RadioGroup';

function ComponentRadioGroup(): JSX.Element {
  const options: { value: string; label: string; disabled?: boolean }[] = [
    { value: '', label: '선택 안 함' },
    { value: 'female', label: '여성' },
    { value: 'male', label: '남성' },
    { value: 'other', label: '기타', disabled: true },
  ];

  const [state1, setState1] = useState('');

  const handleChange1 = (value: string) => {
    setState1(value);
  };

  const [state2, setState2] = useState('');

  const handleChange2 = (value: string) => {
    setState2(value);
  };

  return (
    <>
      <ComponentSampleUI.Content title="Radio group">
        <RadioGroup
          data={options}
          name="gender1"
          value={state1}
          onChange={handleChange1}
        />
      </ComponentSampleUI.Content>
      <ComponentSampleUI.Content title="Radio group column">
        <RadioGroup
          data={options}
          legend="Gender"
          name="gender2"
          value={state2}
          onChange={handleChange2}
          column
        />
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentRadioGroup;
