import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

interface HolidayItem {
  checked: boolean;
  companyId: number;
  holiday: string;
  holidayName: string;
  updateAt: string;
}

interface HolidayYearItem {
  checked: boolean;
  companyId: number;
  holidayYear: string;
  holidayYearName: string;
  updateAt: string;
}

async function findBasicList(arg: {
  pageNo: number;
  rowsPerPage: number;
}): Promise<HolidayItem[]> {
  try {
    const { pageNo, rowsPerPage } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidaybasic/all`;
    const params = {
      pageno: pageNo,
      rowsperpage: rowsPerPage,
    };
    const response = await axios.get<HolidayItem[]>(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function findBasicTotal(): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidaybasic/totalcount`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function appendBasic(arg: {
  holiday: string;
  holidayName: string;
}): Promise<{
  holiday: string;
  holidayName: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidaybasic`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteBasic(arg: {
  holiday: string;
  holidayName: string;
  updateAt: string;
}): Promise<{
  holiday: string;
  holidayName: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidaybasic`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function findYearList(arg: {
  year: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<HolidayYearItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidayyear/all`;
    const params = {
      year: arg.year,
      pageno: arg.pageNo || 1,
      rowsperpage: arg.rowsPerPage || 15,
    };
    const response = await axios.get<HolidayYearItem[]>(url, {
      headers,
      params,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function findYearTotal(arg: { year: string }): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidayyear/totalcount`;
    const params = { year: arg.year };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function appendYear(arg: {
  holiday: string;
  holidayName: string;
}): Promise<{
  holidayYear: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidayyear`;
    const data = {
      holidayYear: arg.holiday,
      holidayYearName: arg.holidayName,
    };
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function yearAllSave(arg: {
  holidayYear: string;
  isIncludeSaturday: boolean;
  isIncludeSunday: boolean;
  isIncludeHolidayKorea: boolean;
}): Promise<{
  holidayYear: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidayyear/all`;
    const data = {
      year: arg.holidayYear,
      isIncludeSaturday: arg.isIncludeSaturday,
      isIncludeSunday: arg.isIncludeSunday,
      isIncludeHolidayKorea: arg.isIncludeHolidayKorea,
    };
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteYear(arg: {
  holiday: string;
  holidayName: string;
  updateAt: string;
}): Promise<{
  holidayYear: string;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/holidayyear`;
    const data = {
      holidayYear: arg.holiday,
      holidayName: arg.holidayName,
      updateAt: arg.updateAt,
    };
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const attendanceHolidayApi = {
  findBasicList,
  findBasicTotal,
  appendBasic,
  deleteBasic,
  findYearList,
  findYearTotal,
  appendYear,
  deleteYear,
  yearAllSave,
};

export default attendanceHolidayApi;
