import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { b62 } from '../../../../../groupware-common/utils';
import {
  CommentItem,
  DocumentViewItem,
  VersionItem,
} from '../../../../stores/document/document';

export interface StarReturnType {
  companyId: number;
  id: number;
  employeeId: number;
  isStarred: boolean; // 대상 직원의 중요 표시 여부.
  updateAt: string;
}

export interface LikesReturnType {
  companyId: number;
  id: number;
  employeeId: number;
  likes: number; // 게시글의 좋아요 개수.
  isLiked: boolean; // 대상 직원의 좋아요 표시 여부.
  updateAt: string;
}

interface ViewerItem {
  postId: number;
  employeeCompanyId: number;
  employeeId: number;
  createAt: string;
  updateAt?: string;
}

/** 문서 아이템 */
export interface ListItem {
  id: number; // 문서 아이디.
  versionSeq: number; // 문서의 현재 버전 아이디.
  folderId: number; // 문서함 아이디.
  subject: string; // 제목
  views: number; // 조회수
  likes: number; // 좋아요 수
  comments: number; // 의견 수
  isStarred: boolean; // 중요 표시 여부
  creatorId: number; // 작성자 아이디
  updaterId: number; // 수정자 아이디
  deleterId: number; // 삭제자 아이디
  createAt: string; // 작성일
  updateAt: string; // 수정일
  checkoutAt: string; // 체크아웃일
  attachedSummaryFiles: {
    fileId: number; // 파일 자체 내부코드
    name: string; // 파일 명
  }[];
  status: string; // 상태 (ACTIVE, CHECKOUT, TEMPORARY, DELETED)
}

/* 모든문서함 목록 조회. */
async function findList(arg: {
  folderid?: number;
  isStarred: boolean;
  isTemporary: boolean;
  isCheckout: boolean;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  pageno: number;
  rowsperpage: number;
}): Promise<ListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/document/v1/post/all/all`;
    if (arg.folderid === 0) {
      if (arg.isStarred) url = `${host}/api/document/v1/post/star/all`;
      if (arg.isTemporary) url = `${host}/api/document/v1/post/temporary/all`;
      if (arg.isCheckout) url = `${host}/api/document/v1/checkout/user/all`;
    } else if (arg.folderid && arg.folderid !== 0)
      url = `${host}/api/document/v1/post/folder/all`;
    const params = {
      folderid: arg.folderid === 0 ? undefined : arg.folderid,
      startdate: arg.startdate,
      enddate: arg.enddate,
      searchcode: arg.searchcode,
      searchword: arg.searchword,
      isdirectoryselected: arg.isdirectoryselected,
      pageno: arg.pageno,
      rowsperpage: arg.rowsperpage,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 체크아웃 리스트 조회. */
async function findCheckoutList(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  pageno: number;
  rowsperpage: number;
}): Promise<ListItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/checkout/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 체크아웃 리스트 총 개수 조회. */
async function findCheckoutTotalCount(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/checkout/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 문서 체크아웃 */
async function adminconsoleCheckin(data: {
  id: number;
  updateAt: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/document/checkout/checkin`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서글의 이전 또는 다음 게시글 아이디를 조회 합니다. */
async function readPrevIdOrNextId(arg: {
  type: 'prev' | 'next';
  isAdmin?: boolean; // 관리자 페이지 여부
  folderid?: string;
  updateat?: string; // 수정일
  checkoutat?: string; // 체크아웃 시간
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  titleclassificationid?: number;
}): Promise<{ companyId: number; id: number } | undefined> {
  const {
    type,
    isAdmin,
    folderid,
    updateat,
    startdate,
    checkoutat,
    enddate,
    searchcode,
    searchword,
    isdirectoryselected,
    titleclassificationid,
  } = arg;
  const params = {
    updateat,
    isAdmin,
    checkoutat,
    startdate,
    enddate,
    searchcode,
    searchword,
    isdirectoryselected,
    titleclassificationid,
  };
  try {
    const { host, headers } = getApiConfig();
    // 관리자 페이지 체크아웃 문서함인 경우.
    if (isAdmin) {
      if (checkoutat) {
        const url = `${host}/api/document/v1/adminconsole/document/checkout/${type}`;
        const response = await axios.get(url, { headers, params });
        return response.data;
      }
      return undefined;
    }
    if (!folderid || folderid === 'all') {
      const url = `${host}/api/document/v1/post/all/${type}`;
      const response = await axios.get(url, { headers, params });
      return response.data;
    }
    // 중요 문서함인 경우.
    if (folderid === 'importance') {
      const url = `${host}/api/document/v1/post/star/${type}`;
      const response = await axios.get(url, { headers, params });
      return response.data;
    }
    // 체크아웃 문서함인 경우.
    if (folderid === 'checkout') {
      const url = `${host}/api/document/v1/checkout/user/${type}`;
      const response = await axios.get(url, { headers, params });
      return response.data;
    }
    // 특정 문서함인 경우.
    const folderParams = {
      ...params,
      folderid: b62(folderid),
    };
    const url = `${host}/api/document/v1/post/folder/${type}`;
    const response = await axios.get(url, { headers, params: folderParams });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/* 문서 목록 총 갯수 조회. */
async function documentTotalCount(arg: {
  folderid?: number;
  isStarred: boolean;
  isTemporary: boolean;
  isCheckout: boolean;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/document/v1/post/all/all/totalcount`;
    if (arg.folderid === 0) {
      if (arg.isStarred)
        url = `${host}/api/document/v1/post/star/all/totalcount`;
      if (arg.isTemporary)
        url = `${host}/api/document/v1/post/temporary/all/totalcount`;
      if (arg.isCheckout)
        url = `${host}/api/document/v1/checkout/user/all/totalcount`;
    } else if (arg.folderid && arg.folderid !== 0)
      url = `${host}/api/document/v1/post/folder/all/totalcount`;
    const params = {
      folderid: arg.folderid === 0 ? undefined : arg.folderid,
      startdate: arg.startdate,
      enddate: arg.enddate,
      searchcode: arg.searchcode,
      searchword: arg.searchword,
      isdirectoryselected: arg.isdirectoryselected,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 이전글 조회. */
async function prev(arg: {
  folderid?: string;
  isAdmin?: boolean;
  checkoutat?: string;
  updateat?: string;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<{ companyId: number; id: number } | undefined> {
  try {
    return readPrevIdOrNextId({ ...arg, type: 'prev' });
  } catch (e) {
    throw apiError(e);
  }
}

/* 다음글 조회. */
async function next(arg: {
  folderid?: string;
  isAdmin?: boolean;
  checkoutat?: string;
  updateat?: string;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<{ companyId: number; id: number } | undefined> {
  try {
    return readPrevIdOrNextId({ ...arg, type: 'next' });
  } catch (e) {
    throw apiError(e);
  }
}

/* 읽기 전용 문서 조회. */
async function findReadOnlyView(arg: {
  id: number;
  updateAt?: string;
}): Promise<DocumentViewItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/force`;
    const response = await axios.get(url, {
      headers,
      params: {
        id: arg.id,
        updateAt: arg.updateAt,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 조회. */
async function documentView(arg: {
  id: number;
  updateAt?: string;
}): Promise<DocumentViewItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post`;
    const response = await axios.get(url, {
      headers,
      params: {
        id: arg.id,
        updateAt: arg.updateAt,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 등록 */
async function documentCreate(arg: {
  folderId: number;
  subject: string;
  summary: string;
  contents: string;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: {
    fileId: number;
    path: string;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[];
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 수정 */
async function documentUpdate(arg: {
  folderId: number;
  subject: string;
  summary: string;
  contents: string;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  isVersion?: boolean;
  changeReason?: string;
  attachedFiles?: {
    fileId: number;
    path: string;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  updateAt: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = arg.isVersion
      ? `${host}/api/document/v1/post/renew`
      : `${host}/api/document/v1/post`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isVersion, ...params } = arg;
    const response = await axios.put(url, params, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 삭제 */
async function documentDelete(
  data:
    | {
        id: number;
        updateAt: string;
      }
    | {
        id: number;
        updateAt: string;
      }[],
): Promise<
  | {
      id: number;
      updateAt: string;
    }
  | {
      id: number;
      updateAt: string;
    }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(data)
      ? `${host}/api/document/v1/post/all`
      : `${host}/api/document/v1/post`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 삭제 */
async function documentForceDelete(
  data:
    | {
        id: number;
        updateAt: string;
      }
    | {
        id: number;
        updateAt: string;
      }[],
): Promise<
  | {
      id: number;
      updateAt: string;
    }
  | {
      id: number;
      updateAt: string;
    }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(data)
      ? `${host}/api/document/v1/post/force/all`
      : `${host}/api/document/v1/post/force`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 삭제함 비우기. */
async function vacateDocument(): Promise<
  { companyId: number; id: number; updateAt: string }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/vacate`;
    const response = await axios.delete(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 이동 */
async function documentMove(
  data:
    | {
        folderId: number;
        id: number;
        updateAt: string;
      }
    | {
        folderId: number;
        id: number;
        updateAt: string;
      }[],
): Promise<
  | {
      companyId: number;
      id: number;
      folderId: number;
      updateAt: string;
    }
  | {
      companyId: number;
      id: number;
      folderId: number;
      updateAt: string;
    }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(data)
      ? `${host}/api/document/v1/post/move/all`
      : `${host}/api/document/v1/post/move`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 체크아웃 */
async function checkout(data: {
  id: number;
  updateAt: string;
  isCheckout: boolean;
  changeReason?: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = data.isCheckout
      ? `${host}/api/document/v1/checkout/checkout`
      : `${host}/api/document/v1/checkout/checkin`;
    const params =
      !data.isCheckout && data.changeReason
        ? {
            id: data.id,
            updateAt: data.updateAt,
            changeReason: data.changeReason,
          }
        : {
            id: data.id,
            updateAt: data.updateAt,
          };
    const response = await axios.put(url, params, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 복사 */
async function documentCopy(data: {
  id: number;
  folderId: number;
  updateAt: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/document/copy`;
    const response = await axios.put(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 상단고정 목록 조회. */
async function findNoticeList(folderid: number): Promise<ListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/notified/all?folderid=${folderid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 임시 저장 */
async function temporary(arg: {
  id?: number;
  folderId: number;
  subject: string;
  summary: string;
  contents: string;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: {
    fileId: number;
    path: string;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  updateAt?: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const response = arg.id
      ? await axios.put(`${host}/api/document/v1/post/temporary`, arg, {
          headers,
        })
      : await axios.post(`${host}/api/document/v1/post/temporary`, arg, {
          headers,
        });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 임시 재저장 */
async function temporaryActive(arg: {
  id: number;
  folderId: number;
  subject: string;
  summary: string;
  contents: string;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: {
    fileId: number;
    path: string;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  updateAt: string;
}): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/temporary/active`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 버전 전체 조회. */
async function versionList(arg: { id: number }): Promise<VersionItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/version/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        id: arg.id,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 버전 보기. */
async function versionView(arg: {
  id: number;
  versionSeq: number;
  updateAt?: string; // 최신 문서의 updateAt 값.
}): Promise<
  Pick<
    DocumentViewItem,
    | 'currentVersionSeq'
    | 'updaterId'
    | 'subject'
    | 'updateAt'
    | 'contents'
    | 'attachedFiles'
    | 'id'
  >
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/version`;
    const response = await axios.get(url, {
      headers,
      params: {
        id: arg.id,
        versionseq: arg.versionSeq,
        // updateAt: arg.updateAt,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요표시 저장, 해제. */
async function saveUnSaveStar(arg: {
  star: boolean;
  id: number;
  employeeId: number;
}): Promise<StarReturnType> {
  const data = { id: arg.id, employeeId: arg.employeeId };
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/star`;
    if (arg.star) {
      const response = await axios.post(url, data, { headers });
      return response.data;
    }

    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 좋아요 저장, 해제. */
async function saveUnSaveLike(arg: {
  like: boolean;
  id: number;
  employeeId: number;
}): Promise<LikesReturnType> {
  const data = { id: arg.id, employeeId: arg.employeeId };
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/like`;
    if (arg.like) {
      const response = await axios.post(url, data, { headers });
      return response.data;
    }

    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 좋아요 목록 조회 */
async function findLikeList(arg: {
  postId: number;
  viewer?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<ViewerItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/like/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        postid: arg.postId,
        viewer: arg.viewer,
        pageno: arg.pageNo,
        rowsperpage: arg.rowsPerPage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 추천 총 개수를 조회. */
async function likeTotalCount(arg: {
  postId: number;
  viewer?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/like/all/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        postid: arg.postId,
        viewer: arg.viewer,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 조회 목록 조회 */
async function findViewerList(arg: {
  postId: number;
  viewer?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<ViewerItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/view/all`;
    const response = await axios.get(url, {
      headers,
      params: {
        postid: arg.postId,
        viewer: arg.viewer,
        pageno: arg.pageNo,
        rowsperpage: arg.rowsPerPage,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 조회 총 개수를 조회 합니다. */
async function viewerTotalCount(arg: {
  postId: number;
  viewer?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/view/all/totalcount`;
    const response = await axios.get(url, {
      headers,
      params: {
        postid: arg.postId,
        viewer: arg.viewer,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 추천 추가 */
async function createLike(arg: {
  documentId: number;
  employeeId: number;
}): Promise<{
  documentId: number;
  employeeId: number;
  createAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/like`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 추천 삭제 */
async function deleteLike(arg: {
  documentId: number;
  employeeId: number;
}): Promise<{
  documentId: number;
  employeeId: number;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/like`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 댓글 조회. */
async function findComments(arg: { postid: number }): Promise<CommentItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/comment/all`;
    const response = await axios.get(url, {
      headers,
      params: arg,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 문서 댓글 작성. */
async function createComment(arg: {
  postId: number;
  parentId: number;
  employeeId: number;
  comment: string;
  ip: string;
}): Promise<CommentItem> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/comment`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 댓글 수정 */
async function updateComment(arg: {
  id: number;
  postId: number;
  comment: string;
  updateAt: string;
  ip: string;
}): Promise<CommentItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/comment`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 댓글 삭제 */
async function deleteComment(arg: {
  id: number;
  postId: number;
  parentId: number;
  employeeId: number;
  updateAt: string;
}): Promise<CommentItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/comment`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 문서함 별 사용자 목록 설정 조회. */
async function findFolderSetting(arg: {
  folderid: number;
  employeeid: number;
}): Promise<{
  folderId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/folder/config`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 문서함 별 사용자 목록 설정 저장. */
async function saveFolderSetting(arg: {
  folderId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  folderId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/folder/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요 문서함 사용자 목록 설정 조회. */
async function findStarredSetting(employeeid: number): Promise<{
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/star/config?employeeid=${employeeid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요 문서함 사용자 목록 설정 저장. */
async function saveStarredSetting(arg: {
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/star/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 체크아웃 문서함 사용자 목록 설정 조회. */
async function findCheckoutSetting(employeeid: number): Promise<{
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/checkout/config?employeeid=${employeeid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 체크아웃 문서함 사용자 목록 설정 저장. */
async function saveCheckoutSetting(arg: {
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/checkout/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 모든 게시함 사용자 목록 설정 조회. */
async function findAllFolderSetting(employeeid: number): Promise<{
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/all/config?employeeid=${employeeid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 모든 문서함 사용자 목록 설정 저장. */
async function saveAllFolderSetting(arg: {
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/post/all/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 삭제함 리스트 조회. */
async function findTrashList(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  pageno: number;
  rowsperpage: number;
}): Promise<ListItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/post/deleted/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 삭제함 리스트 총 개수 조회. */
async function findTrashTotalCount(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/document/v1/adminconsole/post/deleted/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 게시글 복원. */
async function restore(arg: { id: number; updateAt: string }): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}>;
async function restore(arg: { id: number; updateAt: string }[]): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}>;
async function restore(
  arg: { id: number; updateAt: string } | { id: number; updateAt: string }[],
): Promise<
  | {
      companyId: number;
      id: number;
      updateAt: string;
    }
  | {
      companyId: number;
      id: number;
      updateAt: string;
    }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/document/v1/adminconsole/post/deleted/restore/all`
      : `${host}/api/document/v1/adminconsole/post/deleted/restore`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const documentApi = {
  list: findList,
  totalCount: documentTotalCount,
  view: documentView,
  create: documentCreate,
  update: documentUpdate,
  delete: documentDelete,
  forceDelete: documentForceDelete,
  vacateDocument,

  copy: documentCopy,
  move: documentMove,
  prev,
  next,

  checkout,

  findNoticeList,
  findReadOnlyView,

  temporary,
  temporaryActive,

  findComments,
  createComment,
  updateComment,
  deleteComment,

  versionView,
  versionList,

  saveUnSaveStar,
  saveUnSaveLike,

  findLikeList,
  likeTotalCount,
  createLike,
  deleteLike,

  findViewerList,
  viewerTotalCount,

  folderSetting: findFolderSetting,
  allFolderSetting: findAllFolderSetting,
  starredSetting: findStarredSetting,
  checkoutSetting: findCheckoutSetting,
  saveFolderSetting,
  saveAllFolderSetting,
  saveStarredSetting,
  saveCheckoutSetting,

  trashList: findTrashList,
  trashTotalCount: findTrashTotalCount,
  restore,

  findCheckoutList,
  findCheckoutTotalCount,
  adminconsoleCheckin,
};

export default documentApi;
