import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../groupware-webapp/app/store';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../components/tree/DirectoryTree';

type Props = {
  selectedId?: string;
  typeToFilter?:
    | ('company' | 'organization' | 'employee')
    | ('company' | 'organization' | 'employee')[];
  filter?: string;
  onItemClick?(arg: DirectoryTreeItemArg): void;
};

function DirectoryTreeContainer(props: Props): JSX.Element {
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );

  const items = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );

  const { selectedId, typeToFilter, filter, onItemClick } = props;
  return (
    <DirectoryTree
      selectedId={selectedId}
      items={items}
      typeToFilter={typeToFilter}
      filter={filter}
      onItemClick={onItemClick}
    />
  );
}

export default DirectoryTreeContainer;
