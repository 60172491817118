import React from 'react';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../components/button/Button';
import EuiSetting from '../../../../components/layout/EuiSetting';
import PostView from '../../../../components/post/PostView';
import { ResourceFolderItem } from '../../../stores/folders';

function ResourceFolderContentView(props: {
  paths: string[];
  folder: ResourceFolderItem;
  parentFolderName: string;
  onAction(code: string): void;
}): JSX.Element {
  const { paths, folder, parentFolderName, onAction } = props;
  return (
    <>
      <EuiSetting.Header title="폴더정보">
        <Button
          text="순서변경"
          iconType
          icon="sort-amount"
          onClick={() => onAction('folder/sort')}
        />
        <Button
          text="수정"
          iconType
          icon="edit"
          onClick={() => onAction('folder/update')}
        />
        <Button
          text="삭제"
          iconType
          icon="trash-full"
          onClick={() => onAction('folder/delete')}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title="상위폴더명">
                <PostView.CategoryValue value={parentFolderName} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="폴더명">
                <PostView.CategoryValue value={folder.name} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="설명">
                <PostView.CategoryValue value={folder.description} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default ResourceFolderContentView;
