import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../groupware-webapp/app/store';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Button from '../../../../../components/button/Button';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DropMenu from '../../../../../components/selectField/DropMenu';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import Menu from '../../../../../components/menu/Menu';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import TreeList from '../../../../../components/tree/TreeList';
import { getParentItems, hangul } from '../../../../../groupware-common/utils';
import Loading from '../../../../../components/loading/Loading';
import { archiveFolderApi } from '../../../../apis/approval/v1/archive';

/**
 * 기안 요청 확인 인자 유형.
 * @property archiveFolderId 보관소 폴더 아이디.
 * @property useDefer 후결 여부.
 * @property useArbitraryDecision 전결 여부.
 * @property publicOrNot 공개 여부.
 * @property useUrgent 긴급 여부.
 * @property opinion 의견.
 */
export type DraftRequestConfirmArg = {
  archiveFolderId: number;
  useDefer: boolean;
  useArbitraryDecision: boolean;
  publicOrNot: boolean;
  useUrgent: boolean;
  opinion: string;
};

/**
 * 기안 요청 대화 상자 컨테이너.
 * @param organizationId 조직 아이디.
 * @param useDefer 후결 여부.
 * @param useArbitraryDecision 전결 여부.
 * @param onConfirm 확인 이벤트.
 * @param onCancel 취소 이벤트.
 * @returns JSX 엘리먼트.
 */
function DraftRequestDialogContainer(props: {
  organizationId: number;
  useDefer?: boolean;
  useArbitraryDecision?: boolean;
  useOpinion?: boolean;
  attendance?: boolean;
  onConfirm(arg: DraftRequestConfirmArg): void;
  onCancel(): void;
}): JSX.Element {
  // console.log(`${DraftRequestDialogContainer.name}.render`, props);

  const { attendance, organizationId } = props;

  const isDocumentOrganizationPublic = useSelector(
    (state: RootState) =>
      state.approval2.preferences.basic.useDocumentOrganizationPublic,
  );
  const errors = useSelector((state: RootState) => state.session.errors);

  const [state, setState] = useState<{
    loading: boolean;
    archiveFolders:
      | {
          id: number;
          parentId: number;
          text: string;
          icon: 'folder';
          strings: string[][];
        }[]
      | undefined;
    archiveMenuTreePoint?: {
      x: number;
      y: number;
      width?: number;
      height?: number;
    };
    archiveMenuTreeFilter: string;
    /** 보관소 폴더 아이디 */
    archiveFolderId: number;
    /** 후결 여부 */
    useDefer: boolean;
    /** 전결 여부 */
    useArbitraryDecision: boolean;
    /** 공개 여부 */
    publicOrNot: boolean;
    /** 긴급 여부 */
    useUrgent: boolean;
    /** 의견 */
    opinion: string;
  }>({
    loading: true,
    archiveFolders: undefined,
    archiveMenuTreePoint: undefined,
    archiveMenuTreeFilter: '',
    archiveFolderId: 0,
    useDefer: props.useDefer || false,
    useArbitraryDecision: props.useArbitraryDecision || false,
    publicOrNot: isDocumentOrganizationPublic,
    useUrgent: false,
    opinion: '',
  });

  useEffect(() => {
    let mount = true;
    async function run() {
      const response = await archiveFolderApi.findAll({ organizationId });
      if (mount) {
        const archiveFolders = response
          .sort((a, b) => {
            // 미지정 폴더 마지막 위치로 정렬.
            if (a.seq === 0) return 1;
            if (b.seq === 0) return -1;
            return +(a.seq > b.seq) || +(a.seq === b.seq) - 1;
          })
          .map((a) => {
            return {
              id: a.id,
              parentId: a.parentId,
              text: a.name,
              icon: 'folder' as const,
              strings: hangul.d(a.name),
            };
          });
        const archiveFolderId = response.find(({ seq }) => seq === 0)?.id ?? 0;
        setState((prevState) => ({
          ...prevState,
          loading: false,
          archiveFolders,
          archiveFolderId,
        }));
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, [organizationId]);

  const [saveing, setSaveing] = useState(false);

  useEffect(() => {
    if (saveing) {
      const { onConfirm } = props;
      const {
        archiveFolderId,
        useDefer,
        useArbitraryDecision,
        publicOrNot,
        useUrgent,
        opinion,
      } = state;
      onConfirm({
        archiveFolderId,
        useDefer,
        useArbitraryDecision,
        publicOrNot,
        useUrgent,
        opinion,
      });
    }
  }, [saveing]);

  useEffect(() => {
    // 결재 상신 오류 날 경우.
    if (saveing && errors.length > 0) {
      setSaveing(false);
    }
  }, [errors]);

  /** 보관소 메뉴 트리 토글. */
  const handleArchiveMenuTreeToggle = (event: React.MouseEvent) => {
    if (state.archiveMenuTreePoint) handleArchiveMenuTreeClose();
    else {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      const archiveMenuTreePoint = { x, y, width, height };
      setState((prevState) => ({ ...prevState, archiveMenuTreePoint }));
    }
  };

  /** 보관소 메뉴 트리 닫기. */
  const handleArchiveMenuTreeClose = () => {
    setState((prevState) => ({
      ...prevState,
      archiveMenuTreePoint: undefined,
    }));
  };

  /** 보관소 메뉴 트리 필터. */
  const handleArchiveMenuTreeFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const archiveMenuTreeFilter = event.currentTarget.value;
    setState((prevState) => ({ ...prevState, archiveMenuTreeFilter }));
  };

  /** 보관소 메뉴 트리 아이템 클릭. */
  const handleArchiveMenuTreeItemClick = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      archiveFolderId: id,
      archiveMenuTreePoint: undefined,
    }));
  };

  /** 후결 여부 변경. */
  const handleDeferChange = () => {
    const useDefer = !state.useDefer;
    setState((prevState) => ({ ...prevState, useDefer }));
  };

  /** 전결 여부 변경. */
  const handleArbitraryDecisionChange = (useArbitraryDecision: boolean) => {
    setState((prevState) => ({ ...prevState, useArbitraryDecision }));
  };

  /** 공개 여부 변경. */
  const handlePublicOrNotChange = (publicOrNot: boolean) => {
    setState((prevState) => ({ ...prevState, publicOrNot }));
  };

  /** 긴급 여부 변경. */
  const handleUrgentChange = (useUrgent: boolean) => {
    setState((prevState) => ({ ...prevState, useUrgent }));
  };

  /** 의견 변경. */
  const handleOpinionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({ ...prevState, opinion: event.target.value }));
  };

  const { useOpinion, onCancel } = props;
  const {
    loading,
    archiveFolders,
    archiveMenuTreePoint,
    archiveMenuTreeFilter,
    archiveFolderId,
    useDefer,
    useArbitraryDecision,
    publicOrNot,
    useUrgent,
    opinion,
  } = state;

  const archiveMenuTreePaths =
    archiveFolders !== undefined
      ? getParentItems(archiveFolders, archiveFolderId).map(({ text }) => text)
      : '';

  return (
    <>
      <Dialog size="sm">
        <DialogHeader>
          <DialogTitle>{attendance ? '근태 상신' : '결재 상신'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            <PostWrite.Item title="기록물철">
              <DropMenu
                label="기록물철 선택"
                value={archiveMenuTreePaths}
                onClick={handleArchiveMenuTreeToggle}
              />
              {archiveMenuTreePoint && archiveFolders !== undefined ? (
                <Menu
                  point={archiveMenuTreePoint}
                  onClose={handleArchiveMenuTreeClose}
                >
                  <div>
                    <div className="head-panel">
                      <SimpleSearch
                        keyword={archiveMenuTreeFilter}
                        onSearch={handleArchiveMenuTreeFilter}
                      />
                    </div>
                    <div className="body-panel">
                      <TreeList
                        selectedId={archiveFolderId}
                        filter={archiveMenuTreeFilter}
                        items={archiveFolders}
                        onItemClick={handleArchiveMenuTreeItemClick}
                      />
                    </div>
                  </div>
                </Menu>
              ) : null}
            </PostWrite.Item>
            {props.useDefer !== undefined ? (
              <PostWrite.Item title="후결">
                <CheckboxGroup>
                  <Checkbox
                    label="다음 결재자 후결처리"
                    checked={useDefer}
                    onChange={handleDeferChange}
                  />
                </CheckboxGroup>
              </PostWrite.Item>
            ) : null}
            {props.useArbitraryDecision !== undefined ? (
              <PostWrite.Item title="기안자 전결">
                <RadioGroup
                  data={[
                    { value: true, label: '사용함' },
                    { value: false, label: '사용 안 함' },
                  ]}
                  value={useArbitraryDecision}
                  name="drafterAppeal"
                  onChange={handleArbitraryDecisionChange}
                />
              </PostWrite.Item>
            ) : null}
            <PostWrite.Item title="문서공개">
              <RadioGroup
                data={[
                  { value: true, label: '공개' },
                  { value: false, label: '비공개' },
                ]}
                value={publicOrNot}
                name="public"
                onChange={handlePublicOrNotChange}
              />
            </PostWrite.Item>
            <PostWrite.Item title="중요도">
              <RadioGroup
                data={[
                  { value: false, label: '보통' },
                  { value: true, label: '높음' },
                ]}
                value={useUrgent}
                name="emergency"
                onChange={handleUrgentChange}
              />
            </PostWrite.Item>
            {useOpinion !== false && (
              <PostWrite.Item className="comment-item" title="상신의견">
                <TextField
                  count
                  maxLength={500}
                  multiline
                  value={opinion}
                  onChange={handleOpinionChange}
                />
              </PostWrite.Item>
            )}
          </PostWrite>
          {loading && <Loading />}
        </DialogBody>
        <DialogFooter>
          {!saveing && <Button text="취소" onClick={onCancel} />}
          <Button
            text="상신"
            variant="contained"
            onClick={() => setSaveing(true)}
            loading={saveing}
            disabled={loading}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default DraftRequestDialogContainer;
