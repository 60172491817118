import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../../../components/alert/Alert';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import { PostListItemType } from '../../../../../components/post/PostList';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  createQueryString,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { noticeActions } from '../../../../stores/attendance/notice';
import AttendanceNoticeBodyList from './AttendanceNoticeBodyList';
import AttendanceNoticeDialog from './AttendanceNoticeDialog';

export interface AttendanceNotice {
  columnsName: string;
  columnsValue: number;
  employeeId: number;
  standardDate: string;
  alertType: number;
  firstAlarmSendDate: string;
  workerPlanId: number;
}

function AttendanceNoticeContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { p1 } = getPathParams<{ p1: string }>('/*/:p1', pathname);
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();
  const directory = useDirectory();

  const principal = useSelector((state: RootState) => state.session.principal);
  const { companyId } = principal;

  const displayDensity = useSelector(
    (state: RootState) => state.attendance.attendances.displayDensity,
  );

  const folders = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  );
  const list = useSelector((state: RootState) => state.attendance.notice.list);
  const title = folders.find(({ id }) => id === p1)?.name ?? '';

  const [view, setView] = useState<AttendanceNotice>({
    columnsName: '',
    columnsValue: 0,
    employeeId: 0,
    standardDate: '',
    alertType: 0,
    firstAlarmSendDate: '',
    workerPlanId: 0,
  });

  const defaultColumns: Column<PostListItemType>[] = [
    {
      name: 'firstAlarmSendDate',
      text: '알림문서 수신일',
      type: 'form',
      visible: true,
    },
    {
      name: 'firstAlarmSender',
      text: '알림담당자',
      type: 'user',
      visible: true,
    },
    { name: 'firstAlarmId', text: '알림문서확인', type: 'form', visible: true },
    {
      name: 'workerPlanId',
      text: '사용계획서 제출',
      type: 'form',
      visible: true,
    },
    {
      name: 'secondAlarmId',
      text: '지정일통보문서',
      type: 'form',
      visible: true,
    },
    {
      name: 'secondAlarmSendDate',
      text: '지정일통보문서 수신일',
      type: 'form',
      visible: true,
    },
  ];

  /** 헤드 리스트 액션. */
  const handleHeadAction = (arg: {
    code: string;
    event: React.MouseEvent<Element, MouseEvent>;
  }) => {
    const { code } = arg;

    const searchPath = pathname;

    // 목록 설정 드로워.
    if (code === 'listSetting')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 이전 페이지
    if (code === 'prevPage') {
      const pageNo = (queryParams.pageNo ?? 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(searchPath, queryParams);
      }
    }
    if (code === 'nextPage') {
      const pageNo = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * pageNo) < list.totalCount) {
        queryParams.pageNo = pageNo;
        go(searchPath, queryParams);
      }
    }
  };

  /**
   * 연차촉진알림 문서보기 대화 상자.
   * @params columnsName 컬럼명
   * @params columnsValue 아이디
   * @params employeeId 사원 아이디
   * @params standardDate: 기준일자
   * @params alertType: 연차 알림 구분
   *
   */
  const handleDocumentDialog = (arg: {
    columnsName: string;
    columnsValue: number;
    employeeId: number;
    standardDate: string;
    alertType: number;
    firstAlarmSendDate: string;
    workerPlanId?: number;
  }) => {
    const params = getQueryParams(props.search);
    params.dialogMode = 'read';

    const {
      columnsName,
      columnsValue,
      employeeId,
      standardDate,
      alertType,
      workerPlanId,
      firstAlarmSendDate,
    } = arg;

    setView((prevState) => ({
      ...prevState,
      columnsName,
      columnsValue,
      employeeId,
      standardDate,
      alertType,
      workerPlanId: workerPlanId === undefined ? 0 : workerPlanId,
      firstAlarmSendDate,
    }));

    dispatch(sessionActions.search(getQueryParams(params)));
  };

  /** 리스트 항목 배열 */
  const items =
    list?.items.map((item) => {
      /** 알람 발송자 정보 */
      const directoryData = getDirectoryData({
        ...directory,
        ...item,
        employeeId: item.firstAlarmSender,
        companyId,
      });
      return {
        ...item,
        companyId,
        firstAlarmSenderName: directoryData.employeeName,
      };
    }) || [];

  const renderHead = () => {
    return (
      <>
        <EuiHeader>
          <EuiHeader.Title>{title}</EuiHeader.Title>
          <EuiHeader.Toolbar>
            <>
              <EuiHeader.ToolbarLeft>{undefined}</EuiHeader.ToolbarLeft>
              <EuiHeader.ToolbarRight>
                {list.totalCount > 0 && (
                  <Pagination
                    no={queryParams.pageNo || 1}
                    rows={queryParams.rowsPerPage || 15}
                    count={list.totalCount}
                    onPrev={(event) =>
                      handleHeadAction({ code: 'prevPage', event })
                    }
                    onNext={(event) =>
                      handleHeadAction({ code: 'nextPage', event })
                    }
                  />
                )}
              </EuiHeader.ToolbarRight>
            </>
          </EuiHeader.Toolbar>
        </EuiHeader>
      </>
    );
  };
  const renderBody = () => {
    let result = <div>연차사용촉진 문서함</div>;
    result = (
      <EuiBody>
        <div style={{ margin: '0 24px' }}>
          <Alert severity="info" size="sm">
            * 해당 메뉴는 한국 내 사용에만 해당하며, 한국 기준으로 표시되어
            있습니다.
          </Alert>
        </div>
        <AttendanceNoticeBodyList
          pageNo={queryParams.pageNo}
          rowsPerPage={queryParams.rowsPerPage}
          searchCode={queryParams.searchCode}
          searchWord={queryParams.searchWord}
          type="full"
          density={displayDensity}
          columns={defaultColumns.filter(({ visible }) => visible)}
          items={items}
          onhandleDocumentPopup={handleDocumentDialog}
        />
      </EuiBody>
    );
    return result;
  };

  /** 연차사용촉진 수신일 + 10일까지 사용계획서 버튼 활성화. */
  const handleButtonVisible = () => {
    const today = moment().format('YYYY-MM-DD');
    const firstAlarmSendEndDate = moment(view.firstAlarmSendDate)
      .add(10, 'days')
      .format('YYYY-MM-DD');
    const visible =
      view.workerPlanId === 0 &&
      moment(today).isSameOrBefore(firstAlarmSendEndDate);
    return visible;
  };

  /** 사용계획서 발송. */
  const handleDocumentSave = (content: string, updateAlertForm?: string) => {
    const arg = {
      employeeId: view.employeeId,
      alertType: view.alertType,
      standardDate: view.standardDate,
      contents: content,
      firstAlarmContents: updateAlertForm,
    };

    const params = { ...queryParams };
    delete params.dialogMode;
    const route = { pathname, search: createQueryString(params) };

    dispatch(noticeActions.saveUsePlan({ arg, route })).then(() => {
      setView((prevState) => ({
        ...prevState,
        columnsName: '',
        columnsValue: 0,
        employeeId: 0,
        standardDate: '',
        alertType: 0,
        firstAlarmSendDate: '',
      }));
    });
  };

  const renderDialog = () => {
    const { dialogMode } = queryParams;
    if (dialogMode === 'read')
      return (
        <AttendanceNoticeDialog
          usePlanButtonVisible={handleButtonVisible()}
          view={view}
          search={props.search}
          onSave={handleDocumentSave}
        />
      );
    return null;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
      {renderDialog()}
    </>
  );
}

export default AttendanceNoticeContainer;
