import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { ApprovalListItem } from '../../../../stores/approval';

/** 예약 승인 관리 리스트 조회. */
async function findApprovalList(params: {
  employeeid: number;
  organizationid: number;
  approvalstatus?: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected?: boolean;
  pageno: number;
  rowsperpage: number;
}): Promise<ApprovalListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/approval/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 예약 승인 관리 리스트 총 개수 조회. */
async function findApprovalTotalCount(params: {
  employeeid: number;
  organizationid: number;
  approvalstatus?: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected?: boolean;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/approval/all/totalcount`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 예약 승인 관리 승인.반려 처리. */
async function updateApproval(arg: {
  type: 'reject' | 'approve';
  id: number;
  updateAt: string;
}): Promise<ApprovalListItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/approval/${arg.type}`;
    const response = await axios.put(
      url,
      {
        id: arg.id,
        updateAt: arg.updateAt,
      },
      { headers },
    );
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대여 반납 관리 리스트 조회. */
async function findRentalList(params: {
  employeeid: number;
  organizationid: number;
  rentalstatus?: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected?: boolean;
  pageno: number;
  rowsperpage: number;
}): Promise<ApprovalListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/rental/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대여 반납 관리 리스트 총 개수 조회. */
async function findRentalTotalCount(params: {
  employeeid: number;
  organizationid: number;
  rentalstatus?: number;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected?: boolean;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/rental/all/totalcount`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 대여 반납 관리 대여.반납 처리. */
async function updateRental(arg: {
  type: 'takeback' | 'rental';
  id: number;
  updateAt: string;
}): Promise<ApprovalListItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/rental/${arg.type}`;
    const response = await axios.put(
      url,
      {
        id: arg.id,
        updateAt: arg.updateAt,
      },
      { headers },
    );
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const approvalApi = {
  approvalList: findApprovalList,
  approvalTotalCount: findApprovalTotalCount,
  updateApproval,

  rentalList: findRentalList,
  rentalTotalCount: findRentalTotalCount,
  updateRental,
};

export default approvalApi;
