import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  FolderListItem,
  PermissionOptionType,
} from '../../../../stores/folder';

interface ReturnType {
  companyId: number;
  id: number;
  updateAt: string;
}

/** 전체 게시함 목록 조회. */
async function findList(arg: { parentid?: number; boxname?: string }): Promise<
  {
    id: number;
    parentId: number;
    seq: number;
    name: string;
    status: boolean;
    defaultLayout: number;
    option: {
      useAnonymous: boolean;
      useReply: boolean;
      useSecurePost: boolean;
      useTitleClassification: boolean;
    };
    permissions: PermissionOptionType | null;
    description: string;
    isAdmin: boolean | null;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 권한 가진 게시함 목록 조회. */
async function findUserList(arg: {
  organizationId: number;
  employeeId: number;
}): Promise<FolderListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder/authority/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 게시함의 말머리 조회. */
async function findTitleClassification(arg: {
  folderid: number;
}): Promise<{ id: number; titleClassification: string }[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder/titleclassification/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 특정 게시함 뷰 조회. */
async function findView(arg: { id: number; updateAt?: string }): Promise<{
  id: number;
  parentId: number;
  name: string;
  status: boolean;
  defaultLayout: number;
  option: {
    useAnonymous: boolean;
    useReply: boolean;
    useSecurePost: boolean;
    useTitleClassification: boolean;
  };
  forms: number[];
  titleClassifications: {
    id: number;
    titleClassification: string;
  }[];
  retentionPeriods: string[];
  defaultRetentionPeriod: string;
  description: string;
  exceptioners: {
    referenceCompanyId: number; // 사용자 회사 아이디
    referenceId: number; // 사용자 아이디 (organization | employee)
    referenceType: string; // 참조 유형 (COMPANY: 회사, ORGANIZATION: 조직, EMPLOYEE: 직원)
  }[];
  managers: {
    referenceCompanyId: number; // 사용자 회사 아이디
    referenceId: number; // 사용자 아이디 (organization | employee)
    referenceType: string; // 참조 유형 (COMPANY: 회사, ORGANIZATION: 조직, EMPLOYEE: 직원)
    status: number; // 상태 (0: 예외, 1: 사용, 3: 관리)
  }[];
  users: {
    referenceCompanyId: number; // 사용자 회사 아이디
    referenceId: number; // 사용자 아이디 (organization | employee)
    referenceType: string; // 참조 유형 (COMPANY: 회사, ORGANIZATION: 조직, EMPLOYEE: 직원)
    permission: PermissionOptionType; // 권한
  }[];
  updaterId: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder`;
    const response = await axios.get(url, { headers, params: arg });
    return {
      ...response.data,
      forms:
        response.data.forms === null ? ([] as number[]) : response.data.forms,
      retentionPeriods:
        response.data.retentionPeriods === null
          ? ([] as string[])
          : response.data.retentionPeriods,
    };
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시함 생성. */
async function creaetFolder(arg: {
  name: string;
  parentId: number;
  status: boolean;
  defaultLayout: number;
  option: {
    useAnonymous: boolean;
    useReply: boolean;
    useSecurePost: boolean;
    useTitleClassification: boolean;
  };
  forms: number[];
  titleClassifications: {
    titleClassification: string;
  }[];
  retentionPeriods: string[];
  defaultRetentionPeriod: string;
  description: string;
  exceptioners: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    options: PermissionOptionType;
  }[];
}): Promise<ReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시함 수정. */
async function updateFolder(arg: {
  id: number;
  name: string;
  parentId: number;
  status: boolean;
  defaultLayout: number;
  option: {
    useAnonymous: boolean;
    useReply: boolean;
    useSecurePost: boolean;
    useTitleClassification: boolean;
  };
  forms: number[];
  titleClassifications: {
    id?: number;
    titleClassification: string;
  }[];
  retentionPeriods: string[];
  defaultRetentionPeriod: string;
  description: string;
  exceptioners: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    options: PermissionOptionType;
  }[];
  updateAt: string;
}): Promise<ReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시함 삭제. */
async function deleteFolder(arg: {
  id: number;
  updateAt: string;
}): Promise<ReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시함 순서변경. */
async function sortFolder(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<{ id: number; seq: number; companyId: number; updateAt: string }[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시함 일괄권한 설정. */
async function bulkAuthority(arg: {
  folders: {
    id: number;
    updateAt: string;
  }[];
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
    options: PermissionOptionType;
  }[];
  exceptioners: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: string;
  }[];
}): Promise<ReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/folder/authority/bulk`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const folderBoxApi = {
  list: findList,
  userList: findUserList,
  titleClassification: findTitleClassification,
  view: findView,
  create: creaetFolder,
  update: updateFolder,
  delete: deleteFolder,
  sort: sortFolder,
  bulk: bulkAuthority,
};
export default folderBoxApi;
