import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getText } from '../../../../../groupware-common/utils';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';
import FeedBack from '../../../../../components/alert/FeedBack';
import SelectField from '../../../../../components/selectField/SelectField';
import { serviceBasicActions } from '../../../../stores/basic';

function ServiceMenuContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const list = useSelector(
    (state: RootState) => state.service.menu.adminconsole.list,
  );
  const basic = useSelector((state: RootState) => state.service.basic.basic);
  const categories = useSelector(
    (state: RootState) => state.service.menu.category,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 7001)?.name ?? '';
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const initialState = {
    validation: '',
    change: false,
    saveing: false,
    initialModule: basic.initialModule,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { initialModule } = state;

      const save = await dispatch(
        serviceBasicActions.update({
          data: { companyId, initialModule, updateAt: basic.updateAt },
        }),
      );

      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }

      if (!mount) return;

      const { initialModule: newModule, updateAt: newUpdateAt } =
        save.payload as { initialModule: string; updateAt: string };
      setState((prev) => ({
        ...prev,
        change: false,
        saveing: false,
        initialModule: newModule,
        updateAt: newUpdateAt,
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  /** 취소. */
  const handleCancel = () => {
    setState(initialState);
  };

  /** 저장. */
  const handleSave = () => {
    setState((prev) => ({ ...prev, saveing: true }));
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({ ...prev, validation: '' }));
  };

  const handleChangeInitialModule = (module: string) => {
    setState((prev) => ({
      ...prev,
      initialModule: module,
      change: module !== state.initialModule,
    }));
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title="초기 메뉴">
          <SelectField
            data={list.map((x) => ({
              label: getText(x.textId, 'translation'),
              value: x.id,
            }))}
            value={state.initialModule}
            onChange={handleChangeInitialModule}
          />
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {state.change && (
              <Button
                noDuplication={state.validation === ''}
                text="저장"
                variant="contained"
                onClick={handleSave}
              />
            )}
            {state.change && !state.saveing && (
              <Button text="취소" variant="outlined" onClick={handleCancel} />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
        <FeedBack text={state.validation} onClose={handleSnackbarClose} />
      </EuiBody>
    </>
  );
}

export default ServiceMenuContainer;
