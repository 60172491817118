import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingDrawer from '../pages/root/setting/common/SettingDrawer';

function SettingLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  return <SettingDrawer key={i18n.language} pathname={pathname} />;
}

export default SettingLocateDrawer;
