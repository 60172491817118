import React, { useState } from 'react';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import UserInfo from '../../../../../components/user/UserInfo';
import { dateSimplify } from '../../../../../groupware-common/utils/ui';
import PostSubject from '../../../../../components/post/PostSubject';
import Menu from '../../../../../components/menu/Menu';
import PostList from '../../../../../components/post/PostList';
import ApprovalLineFlat from '../../../common/components/ApprovalLineFlat';
import { ApprovalLineType } from '../../../common/dialogs/ApprovalLineDialogContainer';
import { IconType } from '../../../../../groupware-common/types/icon';

let timeoutId: NodeJS.Timeout | undefined;

/**
 * @property id 아이디.
 * @property selected 선택 여부.
 * @property items 항목 배열.
 * @property approvalLine 결재선 배열.
 * @property onClick 클릭 이벤트.
 */
type Props = {
  id: number;
  companyId: number;
  selected?: boolean;
  items: Array<
    | {
        type: 'checkbox';
        value: boolean;
        onChange?(arg: {
          id: number;
          checked: boolean;
          event: React.ChangeEvent<HTMLInputElement>;
        }): void;
      }
    | {
        type: 'subject';
        value: string;
        security?: boolean;
        attachments?: boolean;
        urgent?: boolean;
      }
    | {
        type: 'employee';
        value: string;
        organizationName?: string;
        avatar?: string;
        icon?: IconType;
      }
    | {
        type: 'date';
        value: string;
        dateType?: boolean;
      }
    | {
        type: 'state' | 'workname' | 'documentno';
        value: string;
      }
  >;
  approvalLine: ApprovalLineType;
  onClick?(arg: { companyId: number; id: number; selected: boolean }): void;
};

function ApprovalDocumentListItem(props: Props) {
  // console.log(`${ApprovalDocumentListItem.name}.render`);

  // TODO 댓글 화면 구성해야 함.
  const [state, setState] = useState<{
    approvalLineMenuPoint?: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  }>({
    approvalLineMenuPoint: undefined,
  });

  /** 행 클릭 */
  const handleClick = () => {
    const { id, companyId, selected, onClick } = props;
    if (onClick) onClick({ companyId, id, selected: selected ?? false });
  };

  /** 체크 변경 */
  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('handleCheckedChange(event)', event);
    const { id, items } = props;
    const item = items.find((a) => a.type === 'checkbox');
    if (item?.type === 'checkbox' && item.onChange)
      item.onChange({ id, checked: !item.value, event });
  };

  /** 제목 마우스 엔터 */
  const handleSubjectMouseEnter = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
    const { x, y, width, height } = event.currentTarget.getBoundingClientRect();
    timeoutId = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        approvalLineMenuPoint: { x, y, width, height },
      }));
    }, 1000);
  };

  /** 제목 마우스 레이브 */
  const handleSubjectMouseLeave = () => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
    const approvalLineMenuPoint = undefined;
    setState((prevState) => ({ ...prevState, approvalLineMenuPoint }));
  };

  const { selected, items, approvalLine } = props;
  const { approvalLineMenuPoint } = state;
  return (
    <>
      <PostList.Item selected={selected} onClick={handleClick}>
        {items.map((a, i) => {
          const key = `${i}-${a.type}`;
          switch (a.type) {
            case 'checkbox':
              return (
                <PostList.Cell key={key} type="action">
                  <Checkbox checked={a.value} onChange={handleCheckedChange} />
                </PostList.Cell>
              );
            case 'subject':
              return (
                <PostList.Cell key={key} type="post">
                  <PostSubject
                    title={a.value}
                    security={a.security}
                    attachments={a.attachments}
                    urgent={a.urgent}
                    onMouseEnter={handleSubjectMouseEnter}
                    onMouseLeave={handleSubjectMouseLeave}
                  />
                </PostList.Cell>
              );
            case 'employee':
              return (
                <PostList.Cell key={key} type="user">
                  <UserInfo
                    avatar={a.avatar}
                    name={a.value}
                    from={a.organizationName}
                    icon={a.icon}
                  />
                </PostList.Cell>
              );
            case 'date':
              return (
                <PostList.Cell key={key} type={a.type}>
                  {a.dateType ? (
                    <PostList.Value
                      value={dateSimplify(a.value, 'dateNumber')}
                    />
                  ) : (
                    <PostList.Value value={dateSimplify(a.value)} />
                  )}
                </PostList.Cell>
              );
            case 'workname':
              return (
                <PostList.Cell key={key} type="form">
                  <PostList.Value value={a.value} />
                </PostList.Cell>
              );
            case 'documentno':
              return (
                <PostList.Cell key={key} type="number">
                  <PostList.Value value={a.value} />
                </PostList.Cell>
              );
            default:
              return (
                <PostList.Cell key={key} type={a.type}>
                  <PostList.Value value={a.value} />
                </PostList.Cell>
              );
          }
        })}
      </PostList.Item>
      {approvalLineMenuPoint && (
        <Menu point={approvalLineMenuPoint} onClose={handleSubjectMouseLeave}>
          <ApprovalLineFlat approvalLine={approvalLine} />
        </Menu>
      )}
    </>
  );
}

export default React.memo(ApprovalDocumentListItem);
