import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { userPreferencesActions } from '../../../../stores/userPreferences';

function ResourcePreferencesContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const basic = useSelector(
    (state: RootState) => state.resource.userPreferences.preferences,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'status');
  if (!basic) return <div>404</div>;

  const initialState = {
    save: false,
    change: false,
    employeeId: basic.employeeId,
    initianFrame: basic.initianFrame,
    firstDayOfWeek: basic.firstDayOfWeek,
    isReceiveAlarm: basic.isReceiveAlarm,
    updateAt: basic.updateAt,
  };
  const [state, setState] = useState(initialState);

  /** 초기화면 변경. */
  const handleChangeInitianFrame = (initianFrame: number) => {
    setState((prev) => ({ ...prev, change: true, initianFrame }));
  };

  /** 한 주의 시작일 변경. */
  const handleChangeFirstDay = (firstDayOfWeek: number) => {
    setState((prev) => ({ ...prev, change: true, firstDayOfWeek }));
  };

  /** 예약 알림 변경. */
  const handleChangeReceiveAlarm = (isReceiveAlarm: boolean) => {
    setState((prev) => ({ ...prev, change: true, isReceiveAlarm }));
  };

  /** 저장. */
  const handleUserPreferencesSave = () => {
    setState((prev) => ({ ...prev, save: true }));
    const data = {
      employeeId: state.employeeId,
      initianFrame: state.initianFrame,
      firstDayOfWeek: state.firstDayOfWeek,
      isReceiveAlarm: state.isReceiveAlarm,
      updateAt: state.updateAt,
    };
    dispatch(userPreferencesActions.save(data)).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const { updateAt } = result.payload as { updateAt: string };
        setState((prev) => ({
          ...prev,
          save: false,
          change: false,
          updateAt,
        }));
      }
    });
  };

  const title = categories.find((a) => a.id === 'preferences')?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title="초기화면">
          <RadioGroup
            data={[
              { value: 0, label: '일간' },
              { value: 1, label: '주간' },
              { value: 2, label: '월간' },
            ]}
            value={state.initianFrame}
            name="초기화면"
            onChange={handleChangeInitianFrame}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title="한 주의 시작일">
          <RadioGroup
            data={[
              { value: 0, label: '일요일' },
              { value: 1, label: '월요일' },
            ]}
            value={state.firstDayOfWeek}
            name="한 주의 시작"
            onChange={handleChangeFirstDay}
          />
        </EuiSetting.Item>
        <EuiSetting.Item title="예약 알림">
          <RadioGroup
            data={[
              { value: false, label: '수신안함' },
              { value: true, label: '수신함' },
            ]}
            value={state.isReceiveAlarm}
            name="예약 알림"
            onChange={handleChangeReceiveAlarm}
          />
        </EuiSetting.Item>
        <EuiToolbar>
          {state.change && (
            <EuiToolbar.Left>
              <Button
                loading={state.save}
                text="저장"
                variant="contained"
                onClick={handleUserPreferencesSave}
              />
              <Button text="취소" onClick={() => setState(initialState)} />
            </EuiToolbar.Left>
          )}
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default ResourcePreferencesContainer;
