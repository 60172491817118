import React from 'react';
import Button from '../../../../../components/button/Button';
import PostView from '../../../../../components/post/PostView';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import UserInfo from '../../../../../components/user/UserInfo';

type Props = {
  useLink?: boolean; // 조직도 연동 사용 여부
  avatar: string; // 아바타 경로
  name: string; // 이름 (직위 | 직책 | 직위 + 직책)
  organizationName: string; // 조직 이름
  email: string; // 이메일
  names: { label: string; value: string }[]; // 이름
  enterDate: string; // 입사날짜
  no: string; // 사원번호
  companyPhoneNo: string; // 회사전화 번호
  extensionPhoneNo: string; // 내선전화 번호
  mobilePhoneNo: string; // 휴대전화 번호
  // 소속 조직
  affiliatedOrganizations: {
    representative: boolean; // 대표 조직 여부
    jobDutyName: string;
    paths: string[];
  }[];
  jobPositionName: string; // 직위
  jobRankName: string; // 직급
  task: string; // 업무
  personalEmail: string; // 개인 이메일
  birthday: string; // 생일
  birthdayLabel: string; // 음력 또는 공백
  postalCode: string; // 우편번호
  address: string; // 주소

  accountLinkage?: {
    provider: string; // 계정 연동 제공자.
    id: string; // 계정 연동 아이디.
  };
  onUpdate(): void;
  onDelete(): void;
};

function OrganizationChartContentEmployeeView(props: Props): JSX.Element {
  // console.log(`${OrganizationChartContentEmployeeView.name}.render`);

  const {
    useLink,
    email,
    no,
    names,
    jobPositionName,
    jobRankName,

    task,

    avatar,
    enterDate,
    onUpdate,
    onDelete,

    name,
    organizationName,
    affiliatedOrganizations,

    // 추가 항목.
    companyPhoneNo,
    extensionPhoneNo,
    mobilePhoneNo,
    personalEmail,
    birthday,
    birthdayLabel,
    postalCode,
    address,

    accountLinkage,
  } = props;

  return (
    <>
      <EuiSetting.Header title="직원정보">
        {!useLink && (
          <>
            <Button text="수정" iconType icon="edit" onClick={onUpdate} />
            <Button text="삭제" iconType icon="trash-full" onClick={onDelete} />
          </>
        )}
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <UserInfo
              className="view-profile"
              name={name}
              from={organizationName}
              avatar={avatar}
            />
          </PostView.Head>
          <PostView.Body>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title="이메일">
                  <PostView.CategoryValue value={email} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="이름">
                  {names.map(({ label, value }) => {
                    return (
                      <PostView.CategoryValue
                        key={label}
                        value={value}
                        label={label}
                      />
                    );
                  })}
                </PostView.CategoryItem>
                <PostView.CategoryItem title="입사일자">
                  <PostView.CategoryValue value={enterDate} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="사원번호">
                  <PostView.CategoryValue value={no} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="연락처">
                  <PostView.CategoryValue
                    value={companyPhoneNo}
                    label="회사전화"
                  />
                  <PostView.CategoryValue
                    value={extensionPhoneNo}
                    label="내선전화"
                  />
                  <PostView.CategoryValue
                    value={mobilePhoneNo}
                    label="휴대전화"
                  />
                </PostView.CategoryItem>
              </PostView.CategoryList>
              <PostView.CategoryList title="회사/소속">
                <PostView.CategoryItem title="소속">
                  {affiliatedOrganizations.map((a) => {
                    const value = a.paths.join(' > ');
                    const label = a.representative
                      ? `${a.jobDutyName} [대표 조직]`
                      : a.jobDutyName;
                    return (
                      <PostView.CategoryValue
                        key={value}
                        value={value}
                        label={label}
                      />
                    );
                  })}
                </PostView.CategoryItem>
                <PostView.CategoryItem title="직위">
                  <PostView.CategoryValue value={jobPositionName} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="직급">
                  <PostView.CategoryValue value={jobRankName} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="업무">
                  <PostView.CategoryValue value={task} />
                </PostView.CategoryItem>
              </PostView.CategoryList>
              <PostView.CategoryList title="개인정보">
                <PostView.CategoryItem title="이메일">
                  <PostView.CategoryValue value={personalEmail} label="개인" />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="생일">
                  <PostView.CategoryValue
                    value={birthday}
                    label={birthdayLabel}
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="주소">
                  <PostView.CategoryValue value={postalCode} label="우편번호" />
                  <PostView.CategoryValue value={address} />
                </PostView.CategoryItem>
              </PostView.CategoryList>
              {accountLinkage && (
                <PostView.CategoryList title="계정 연동">
                  <PostView.CategoryItem title={accountLinkage.provider}>
                    <PostView.CategoryValue
                      value={accountLinkage.id}
                      label="아이디"
                    />
                  </PostView.CategoryItem>
                </PostView.CategoryList>
              )}
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default OrganizationChartContentEmployeeView;
