/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../groupware-webapp/app/store';
import {
  CacheEntity,
  CacheMeta,
  LocateArg,
} from '../../../groupware-common/types';
import {
  Caches,
  ListEntity,
  PendingAction,
  ReadingPaneMode,
  RejectedAction,
  ViewEntity,
} from '../../../groupware-webapp/stores/types';
import {
  AttachDocument,
  AttachFile,
  CommentDeleteArgApi,
  CommentSaveArgApi,
  documentApi,
  DocumentData,
  getApprovalAct,
  HistoryItem,
  LinkType,
  OpinionDeleteArgApi,
  OpinionSaveArgApi,
  SharedFile,
} from '../../apis/approval/v1/document';
import oldApi from '../../apis/approval/v1/index';
import {
  asyncRequestContains,
  getCacheList,
  getCacheListName,
  getCacheListPage,
  requestAppend,
  thunkCondition,
} from '../../../groupware-webapp/stores/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { IconType } from '../../../groupware-common/types/icon';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
  getLastApprover,
  SharePermissionType,
} from '../../pages/common/dialogs/ApprovalLineDialogContainer';
import {
  getAdvancedSearchParams,
  getPageNo,
  getPathParams,
  getQueryParams,
} from '../../../groupware-common/utils';
import FormBuilder from './FormBuilder';
import { dateTimeFormat } from '../../../groupware-common/utils/ui';
import archiveDocumentApi from '../../apis/approval/v1/archive/document';
import personalDocumentApi from '../../apis/approval/v1/personal/document';
import personalDocumentActions from './personal/document/actions';
import { CategoryOptions } from '../../apis/approval/v1/type';
import {
  referenceDocumentsApi,
  viewDocumentsApi,
} from '../../apis/approval/v1/share-documents';
import { documentMacroReplace } from '../../pages/root/approval/compose/ApprovalComposeContainer';
import approvalWorkApi from '../../apis/approval/v1/work';
import approvalFormApi from '../../apis/approval/v1/form';
import { ApiError } from '../../../groupware-common/types/error';
import approvalTrashApi from '../../apis/approval/v1/trash';
import permissionDocumentApi from '../../apis/approval/v1/permission';
import organizationApi from '../../apis/approval/v1/organization';
import { surrogateApprovalActions } from './surrogate-approval';

const name = 'approval/document';

interface FolderItem {
  type: 'status' | 'organization' | 'shared' | 'default' | 'setting';
  id: number;
  name: string;
  icon: IconType;
  count?: number;
}

interface FolderView {
  id: number;
  name: string;
}

interface DocumentItem {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 아이디.
  workName: string; // 업무 이름.
  documentNo: string; // 문서번호.
  subject: string; // 제목.
  approvalLine: ApprovalLineType; // 결재선.
  attachments: Array<string>; // 첨부 파일 목록.
  completeAt?: string; // 완료일.
  opinions: number; // 의견 수.
  comments: number; // 댓글 수.
  createAt: string; // 작성일.
  updateAt: string; // 수정일.
  // urgent: boolean; // 긴급 여부.
  checked: boolean; // 체크 여부.
  linkWait?: boolean; // 연동 대기 여부.
  isIncommingOrOutgoing?: boolean; // 수발신 문서 여부 (문서 삭제 확인용)
}

/** 문서. */
export interface DocumentView {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 문서 아이디 = documentId
  parentCompanyId?: number; // 부모 문서 회사 아이디
  parentId?: number; // 부모 문서 아이디
  parentStatus?: number; // 부모 문서 상태값
  parentApprovalLine?: ApprovalLineType; // 부모 문서 결재선.
  parentAffiliatedCompanyId?: number; // 부모 문서 관계사 회사 아이디.
  parentUpdateAt?: string; // 부모 문서 수정 날짜.
  parentDrafteAt?: string; // 부모 문서 기안 날짜.
  parentCompleteAt?: string; // 부모 문서 완료 날짜.
  workId: number; // 업무 아이디.
  formId: number; // 양식 아이디.
  workName: string; // 업무 이름.
  formName: string; // 양식 이름.
  status: string; // 결재상태 - DRAFT: 기안, PROGRESS: 진행, REJECT: 반려, COMPLETE: 완료, WITHDRAW: 회수
  no: string; // 문서번호.
  subject: string; // 제목
  content: string; // 내용
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  draftAt: string; // 기안 날짜
  completeAt?: string; // 완료 날짜
  retentionPeriod: string; // 보존기간.
  option: number; // 업무 옵션.
  isTopLevelOrganizationNameNumbering: boolean; // 최상위 조직 이름 채번 여부.
  isNumberingAtCompose: boolean; // 작성 시점에 채번 여부.
  isSyncIncomingAndOutgoing: boolean; // 접수 후 내부 결재 시 부모 문서번호 승계 여부.
  isApprovalboxSignatureImage: boolean; // 결재란에 서명 이미지 사용 여부.
  change: boolean; // 문서 변경 유무
  isReReceipt: boolean; // 반려된 내부문서 재접수 여부.
  isReDraft: boolean; // 재기안 여부.
  linkType: LinkType; // 연동 유형. (NONE: '없음', ATTENDANCE: '근태')
  linkId: string; // 연동 아이디.
  linkWait: boolean; // 연동 대기 여부. (true: 대기, false: 대기 없음)
  attachedFiles?: AttachFile[]; // 첨부 파일 배열.
  sharedFiles?: SharedFile[]; // 연동결재 공유 파일 배열.
  attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
  opinions?: (CommentItem & { act: number })[]; // 의견 배열.
  comments?: CommentItem[]; // 댓글 배열.
  updateAt: string;
  prev?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  next?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  isOutgoing?: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt?: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt?: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  receiptFormId?: number;
  creatorId: number;
  publicOrNot: boolean;
}

/** 관리자 문서 객체. */
export interface AdminconsoleView {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 문서 아이디 = documentId
  formId: number;
  workId: number;
  parentCompanyId?: number; // 부모 문서 회사 아이디
  parentId?: number; // 부모 문서 아이디
  parentStatus?: number; // 부모 문서 상태값
  parentApprovalLine?: ApprovalLineType; // 부모 문서 결재선.
  parentAffiliatedCompanyId?: number; // 부모 문서 관계사 회사 아이디.
  parentUpdateAt?: string; // 부모 문서 수정 날짜.
  parentDrafteAt?: string; // 부모 문서 기안 날짜.
  parentCompleteAt?: string; // 부모 문서 완료 날짜.
  workName: string; // 업무 이름.
  formName: string; // 양식 이름.
  receiptFormId: number; // 내부결재 양식 아이디.
  status: string; // 결재상태 - DRAFT: 기안, PROGRESS: 진행, REJECT: 반려, COMPLETE: 완료, WITHDRAW: 회수
  publicOrNot: boolean; // 공개 여부 - 공개:1, 비공개: 0
  security: boolean; // 보안 - 사용:1, 사용안함: 0
  no: string; // 문서번호.
  subject: string; // 제목
  content: string; // 내용
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  draftAt: string; // 기안 날짜
  completeAt?: string; // 완료 날짜
  retentionPeriod: string; // 보존기간.
  option: number; // 업무 옵션.
  isTopLevelOrganizationNameNumbering: boolean; // 최상위 조직 이름 채번 여부.
  isNumberingAtCompose: boolean; // 작성 시점에 채번 여부.
  isSyncIncomingAndOutgoing: boolean; // 접수 후 내부 결재 시 부모 문서번호 승계 여부.
  isApprovalboxSignatureImage: boolean; // 결재란에 서명 이미지 사용 여부.
  change: boolean; // 문서 변경 유무
  isReReceipt: boolean; // 반려된 내부문서 재접수 여부.
  isReDraft: boolean; // 재기안 여부.
  linkType: LinkType; // 연동 유형. (NONE: '없음', ATTENDANCE: '근태')
  linkId: string; // 연동 아이디.
  linkWait: boolean; // 연동 대기 여부. (true: 대기, false: 대기 없음)
  creatorCompanyId: number; // 생성자 회사 아이디
  creatorOrganizationId: number; // 생성자 조직 아이디
  creatorId: number; // 생성자 아이디
  updaterCompanyId: number; // 수정자 회사 아이디
  updaterId: number; // 수정자 아이디
  updateAt: string; // 수정 날짜
  attachedFiles?: AttachFile[]; // 첨부 파일 배열.
  sharedFiles?: SharedFile[]; // 연동결재 공유 파일 배열.
  attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
  opinions?: (CommentItem & { act: number })[]; // 의견 배열.
  comments?: CommentItem[]; // 댓글 배열.
  prev?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  next?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  isOutgoing?: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt?: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt?: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
}

/** 삭제함 문서. */
export interface TrashView {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 문서 아이디 = documentId
  parentCompanyId?: number; // 부모 문서 회사 아이디
  parentId?: number; // 부모 문서 아이디
  parentStatus?: number; // 부모 문서 상태값
  parentApprovalLine?: ApprovalLineType; // 부모 문서 결재선.
  parentAffiliatedCompanyId?: number; // 부모 문서 관계사 회사 아이디.
  parentUpdateAt?: string; // 부모 문서 수정 날짜.
  parentDrafteAt?: string; // 부모 문서 기안 날짜.
  parentCompleteAt?: string; // 부모 문서 완료 날짜.
  status: string; // 결재상태 - 1:진행,2:요구,3:완료,4:삭제
  subject: string; // 제목
  content: string; // 내용
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  draftAt?: string; // 기안 날짜
  completeAt?: string; // 완료 날짜
  updateAt: string; // 수정 날짜
  attachedFiles?: AttachFile[]; // 첨부 파일 배열.
  sharedFiles?: SharedFile[]; // 연동결재 공유 파일 배열.
  attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
  opinions?: (CommentItem & { act: number })[]; // 의견 배열.
  comments?: CommentItem[]; // 댓글 배열.
  prev?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  next?: {
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
    id: number; // 문서 아이디.
  };
  isOutgoing: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  /** TODO: 추가 요청 */
  receiptFormId?: number;
}

interface PrintView {
  parentApprovalLine?: ApprovalLineType; // 부모 문서 결재선.
  parentDrafteAt?: string; // 부모 문서 기안 날짜.
  parentCompleteAt?: string; // 부모 문서 완료 날짜.
  subject: string; // 제목
  contents: string; // 내용
  approvalLine: ApprovalLineType; // 결재선
  draftAt: string; // 기안 날짜
  completeAt?: string; // 완료 날짜
  attachedFiles?: AttachFile[]; // 첨부 파일 배열.
  attachedDocuments?: AttachDocument[]; // 첨부 문서 배열.
  opinions?: (CommentItem & { act: number })[]; // 의견 배열.
  comments?: CommentItem[]; // 댓글 배열.
}

/** 댓글 및 답글 뷰. */
export interface CommentItem {
  companyId: number; // 문서 회사 아이디
  documentId: number; // 문서 아이디
  parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
  id: number; // 아이디
  employeeCompanyId: number; // 직원 회사 아이디
  employeeId: number; // 직원 아이디 - employee.id
  contents: string; // 내용
  likes: number; // 좋아요 수
  dislikes: number; // 싫어요 수
  loves: number; // 사랑해요 수
  createAt: string; // 생성 날짜
  updateAt: string; // 수정 날짜
  isHidden?: boolean; // 숨김 여부
  isDeleted?: boolean; // 삭제 여부
}

/** 문서변경내역 뷰 */
export interface ChangeHistoryItem {
  id: number;
  contentHTML: string;
  subject: string;
  approvalLine: ApprovalLineType;
  attachments: {
    name: string;
    size: number;
  }[];
  documentFile: {
    no: string;
    subject: string;
  }[];
  createAt: string;
}

export interface AdvancedSearch {
  state?: string;
  subject?: string;
  documentNumber?: string;
  workName?: string;
  formName?: string;
  content?: string;
  attachedFileName?: string;
  drafterName?: string;
  draftOrganizationName?: string;
  startDate?: string;
  endDate?: string;
}

export type OpinionSaveArg = OpinionSaveArgApi;
export type CommentSaveArg = CommentSaveArgApi;
export type OpinionDeleteArg = OpinionDeleteArgApi;
export type CommentDeleteArg = CommentDeleteArgApi;
export type AttachFileItem = AttachFile;
export type AttachDocumentItem = AttachDocument;

interface State {
  requests: { id: string; type: string; arg: any }[];

  readingPaneMode: ReadingPaneMode;

  // 범주.
  category: {
    list: CacheEntity<{
      items: FolderItem[];
    }>;
    // 옵션. (use: 사용 여부, extended: 펼침 여부)
    options: {
      meta: { lastUpdateAt: string; timestamp: number };
      data: CategoryOptions;
    };
    // 항목 상태.
    itemStatus: {
      meta: { lastUpdateAt: string; timestamp: number };
      data: { id: number; unseen: number; count: number }[];
    };
  };

  folder: {
    list: CacheEntity<{
      items: FolderItem[];
      totalCount: number;
      search: string;
    }>;
    view: CacheEntity<FolderView | null | undefined>;
  };

  list: CacheEntity<{
    items: DocumentItem[];
    totalCount: number;
    search: string;
  }>;
  view: CacheEntity<DocumentView | null | undefined>;
  printView: CacheEntity<PrintView | null | undefined>;
  cache: Caches<ListEntity<DocumentItem>, ViewEntity<DocumentView>>;

  historys: { items: HistoryItem[] };

  adminconsole: {
    list: AdminconsoleListTemp[];
    totalCount: number;
    view: CacheEntity<AdminconsoleView | null | undefined>;
  };

  trash: {
    list: AdminconsoleList[];
    totalCount: number;
    view: CacheEntity<TrashView | null | undefined>;
  };
}

/** 기본 폴더 */
const categories: FolderItem[] = [
  // 결재현황.
  { type: 'status', id: 1001, name: '기안', icon: 'clipboard-text', count: 0 },
  { type: 'status', id: 1002, name: '결재', icon: 'clipboard-signature' },
  { type: 'status', id: 1003, name: '진행', icon: 'clipboard-circle-clock' },
  { type: 'status', id: 1004, name: '완료', icon: 'clipboard-circle-check' },
  { type: 'status', id: 1005, name: '반려', icon: 'undo-half', count: 0 },
  { type: 'status', id: 1006, name: '회수', icon: 'backspace-fill', count: 0 },
  { type: 'status', id: 1007, name: '임시보관', icon: 'document', count: 0 },
  // { type: 'status', id: 1008, name: '삭제함', icon: 'trash', count: 0 },
  // { type: 'status', id: 1009, name: '현황', icon: 'line-dot', count: 0 }, // 결재 + 진행
  // 조직결재함.
  { type: 'organization', id: 3001, name: '품의함', icon: 'folder' },
  { type: 'organization', id: 3002, name: '합의함', icon: 'folder' },
  { type: 'organization', id: 3003, name: '발신함', icon: 'folder' },
  { type: 'organization', id: 3004, name: '수신함', icon: 'folder' },
  // 참조문서.
  { type: 'shared', id: 4001, name: '참조문서', icon: 'folder', count: 0 },
  { type: 'shared', id: 4002, name: '조회문서', icon: 'folder' },
  // 사용자 - 고정 메뉴.
  { type: 'default', id: 5001, name: '대시보드', icon: 'module-fill' },
  { type: 'default', id: 5002, name: '결재현황', icon: 'line-dot' },
  { type: 'default', id: 5003, name: '환경설정', icon: 'cog-fill' },
  { type: 'default', id: 5004, name: '기록물 관리', icon: 'cog-fill' },
  // 관리자 - 메뉴.
  { type: 'setting', id: 6001, name: '기본설정', icon: 'folder' },
  { type: 'setting', id: 6002, name: '결재문서 관리', icon: 'folder' },
  { type: 'setting', id: 6003, name: '전결권자설정', icon: 'folder' },
  { type: 'setting', id: 6004, name: '결재담당자', icon: 'folder' },
  { type: 'setting', id: 6005, name: '대결자지정', icon: 'folder' },
  { type: 'setting', id: 6006, name: '조회권 등록', icon: 'folder' },
  { type: 'setting', id: 6007, name: '삭제함 관리', icon: 'folder' },
  { type: 'setting', id: 6008, name: '양식관리', icon: 'folder' },
  { type: 'setting', id: 6009, name: '업무관리', icon: 'folder' },
];

// prettier-ignore
const initialState: State = {
  requests: [],
  
  readingPaneMode: 'list',

  // 범주.
  category: {
    list: {
      data: { items: [...categories] },
      meta: { timestamp: 0, lastUpdateAt: '' },
    },
    // 옵션. (use: 사용 여부, extended: 펼침 여부)
    options: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: {
        status: { use: true, extended: true }, // 결재현황
        receipt: { use: true, extended: false }, // 접수함
        send: { use: true, extended: false }, // 발송함
        shared: { use: true, extended: true }, // 공유함
        archive: { use: true, extended: false }, // 보관함
        private: { use: true, extended: false }, // 개인함
      },
    },
    // 항목 상태.
    itemStatus: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: [],
    },
  },

  folder: {
    list: {
      data: { items: [...categories], totalCount: 0, search: '' },
      meta: { timestamp: 0, lastUpdateAt: '' },
    },
    view: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: undefined,
    },
  },

  list: {
    data: { items: [], totalCount: 0, search: '' },
    meta: { timestamp: 0, lastUpdateAt: '' },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },
  printView: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },
  cache: {
    list: {},
    view: {},
  },

  historys: {
    items: [],
  },
  adminconsole: {
    view: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: undefined,
    },
    list: [],
    totalCount: 0,
  },

  trash: {
    view: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: undefined,
    },
    list: [],
    totalCount: 0,
  },
};

function getStateWord(state: string): string {
  switch (state) {
    case '1':
      return 'PROGRESS';
    case '2':
      return 'PROGRESS';
    case '3':
      return 'COMPLETE';
    case '4':
      return 'REJECT';
    case 'all':
    default:
      return 'ALL';
  }
}

function isArchiveFolder(arg: {
  getState: () => unknown;
  folderId: number;
}): boolean {
  const { getState, folderId } = arg;
  if (folderId < 5000) return false;
  const state = getState() as RootState;
  return state.approval2.archive.folders.data.some(({ id }) => id === folderId);
}

function isPersonalFolder(arg: {
  getState: () => unknown;
  folderId: number;
}): boolean {
  const { getState, folderId } = arg;
  if (folderId < 5000) return false;
  const state = getState() as RootState;
  return state.approval2.userPreferences.userPreferencesFolder.some(
    ({ id }) => id === folderId,
  );
}

function getDocumentId(arg?: {
  affiliatedCompanyId?: number;
  id: number;
}): { affiliatedCompanyId?: number; id: number } | undefined {
  if (arg === undefined) return undefined;
  const { affiliatedCompanyId, id } = arg;
  return { affiliatedCompanyId, id };
}

function draftAt(approvalLine: ApprovalLineType): string | undefined {
  return approvalLine.groups.find((g) => g.type === 'draft')?.items[0]?.actAt;
}

/** 반려 그룹 */
function getReturnApprover(approvalLine: ApprovalLineType) {
  const returnGroup = approvalLine.groups.find((group) => {
    const item =
      group.type === 'agree'
        ? group.items.find(({ option, act }) => {
            return act === 'return' && option !== true;
          })
        : group.items.find(({ act }) => {
            return act === 'return';
          });
    return item !== undefined;
  });

  return returnGroup?.items.find((item) => item.act === 'return');
}

/** 문서 완료일 구하기. */
function getCompleteAt(approvalLine: ApprovalLineType): string | undefined {
  // 반려한 문서일 경우 반려 처리된 날짜 return.
  const returnApprover = getReturnApprover(approvalLine);
  if (returnApprover !== undefined) return returnApprover.actAt;

  const lastApprover = getLastApprover(approvalLine);
  if (Array.isArray(lastApprover)) {
    const sortedlastApprover = lastApprover
      .filter(({ act }) => act !== undefined && act !== 'receipt')
      .sort((a, b) => {
        if (a.actAt === undefined || b.actAt === undefined) return 0;
        if (a.actAt > b.actAt) return -1;
        if (a.actAt < b.actAt) return 1;
        return 0;
      });

    if (sortedlastApprover.length === lastApprover.length)
      return sortedlastApprover[sortedlastApprover.length - 1].actAt;
  } else if (
    lastApprover?.act !== undefined &&
    lastApprover?.act !== 'receipt'
  ) {
    return lastApprover?.actAt;
  }

  return undefined;
}

/** 문서 진행 여부. */
function isProgress(approvalLine: ApprovalLineType): boolean {
  return getCompleteAt(approvalLine) === undefined;
}

/** 문서 관계사 회사 아이디 구하기 */
function getAffiliatedCompanyId(
  documentCompanyId: number,
  companyId: number,
): number | undefined {
  if (documentCompanyId !== companyId) return documentCompanyId;
  return undefined;
}

// 사용자 페이지 문서 읽기.
const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: {
      folderId: number; // 관리자 페이지에서 0으로 전달. (모든 보기 적용 안됨)
      id: number;
    } & LocateArg,
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { session, approval2 } = getState() as RootState;

      const { companyId } = session.principal;
      const queryParams = getQueryParams(arg.route?.search ?? '');
      const affiliatedCompanyId = queryParams.affiliatedCompanyId
        ? parseInt(queryParams.affiliatedCompanyId, 10)
        : undefined;

      let data: DocumentView | null | undefined;
      let _response_id_: number | undefined;
      data = null;

      const { folderId, id } = arg;

      if (typeof id !== 'number')
        return rejectWithValue({
          error: 'error',
          path: '',
          status: 500,
          timestamp: '',
        });

      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      let response: DocumentData;
      let parentResponse:
        | {
            companyId: number;
            id: number;
            approvalLine: string;
            updateAt: string;
          }
        | undefined;
      // 개인함
      if (isPersonalFolder({ getState, folderId })) {
        response = await personalDocumentApi.data({
          folderId,
          affiliatedCompanyId,
          id,
        });
        // 기록물
      } else if (
        approval2.archive.folders.data.find((a) => a.id === folderId) !==
        undefined
      ) {
        response = await archiveDocumentApi.data({
          folderId,
          documentId: id,
        });
        // 회람 문서
      } else if (folderId > 4000) {
        // 참조 문서함
        if (folderId === 4001) {
          response = await referenceDocumentsApi.data({
            folderId,
            documentId: id,
            affiliatedCompanyId,
          });
          // 조회 문서함
        } else if (folderId === 4002) {
          response = await viewDocumentsApi.data({
            folderId,
            documentId: id,
            affiliatedCompanyId,
          });
        } else throw new Error('문서함을 찾을 수 없습니다.');
        // 조직 문서
      } else if (folderId > 3000) {
        response = await organizationApi.data({
          folderId,
          documentId: id,
          affiliatedCompanyId,
        });
        // 상태 문서
      } else {
        response = await documentApi.stateData({
          folderId,
          documentId: id,
          affiliatedCompanyId,
        });
        // 결재 상태문서함일 때만 대결자 조회
        if (folderId === 1002) {
          await dispatch(surrogateApprovalActions.designators());
        }
      }
      if (response) {
        if (response.id !== id) return { data: null };

        let prev: { affiliatedCompanyId?: number; id: number } | undefined;
        let next: { affiliatedCompanyId?: number; id: number } | undefined;

        const rootState = getState() as RootState;
        const { readingPaneMode } = approval2.document;

        // 분할 창이 아닌 경우.
        if (
          readingPaneMode !== 'vertical' &&
          readingPaneMode !== 'horizontal'
        ) {
          const { items, totalCount, search } =
            rootState.approval2.document.list.data;
          const index = items.findIndex(
            (a) => a.affiliatedCompanyId === affiliatedCompanyId && a.id === id,
          );

          const { pageNo = 1, rowsPerPage = 15 } = getQueryParams(search);

          /** 상세검색 파라미터 */
          const advancedSearchParams = getAdvancedSearchParams(search);

          const param = {
            folderId,
            documentId: id,
            advancedSearch: advancedSearchParams,
          };

          // 목록에 해당 아이디가 없는 경우. (보기에서 다음, 이전으로 이동되어 목록에 없는 아이디로 넘어간 경우)
          if (index === -1) {
            if (isArchiveFolder({ getState, folderId })) {
              prev = await archiveDocumentApi.prevId(param);
              next = await archiveDocumentApi.nextId(param);
            } else if (isPersonalFolder({ getState, folderId })) {
              prev = await personalDocumentApi.prevId(param);
              next = await personalDocumentApi.nextId(param);
            } else if (folderId > 4000) {
              prev = await permissionDocumentApi.prevId(param);
              next = await permissionDocumentApi.nextId(param);
            } else if (folderId > 3000) {
              prev = await organizationApi.prevId(param);
              next = await organizationApi.nextId(param);
            } else {
              prev = await documentApi.prevId(param);
              next = await documentApi.nextId(param);
            }
          } else if (index === 0) {
            if (pageNo > 1) {
              if (isArchiveFolder({ getState, folderId })) {
                next = await archiveDocumentApi.nextId(param);
              } else if (isPersonalFolder({ getState, folderId })) {
                next = await personalDocumentApi.nextId(param);
              } else if (folderId > 4000) {
                next = await permissionDocumentApi.nextId(param);
              } else if (folderId > 3000) {
                next = await organizationApi.nextId(param);
              } else {
                next = await documentApi.nextId(param);
              }
            }
            prev = getDocumentId(items[1]);
          } else if (index === items.length - 1) {
            next = getDocumentId(items[index - 1]);

            if (pageNo * rowsPerPage < totalCount) {
              if (isArchiveFolder({ getState, folderId })) {
                prev = await archiveDocumentApi.prevId(param);
              } else if (isPersonalFolder({ getState, folderId })) {
                prev = await personalDocumentApi.prevId(param);
              } else if (folderId > 4000) {
                prev = await permissionDocumentApi.prevId(param);
              } else if (folderId > 3000) {
                prev = await organizationApi.prevId(param);
              } else {
                prev = await documentApi.prevId(param);
              }
            }
          } else if (index > 0) {
            next = getDocumentId(items[index - 1]);
            prev = getDocumentId(items[index + 1]);
          }
        }

        // 첨부파일 사용 1 0000 0001
        // 첨부파일 필수 2 0000 0010
        // 첨부문서 사용 4 0000 0100
        // 첨부문서 필수 8 0000 1000
        // 의견 사용    16 0001 0000
        // 의견 필수    32 0010 0000
        // 댓글 사용    64 0100 0000
        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const {
          attachedSharedFileCount,
          attachedFileCount,
          attachedDocumentCount,
        } = response;

        const opinions = response.opinionCount
          ? await documentApi.fetchOpinionList(companyId, id)
          : [];
        const comments = response.commentCount
          ? await documentApi.fetchCommentList(companyId, id)
          : [];

        let parentStatus: number | undefined;
        let parentApprovalLine: ApprovalLineType | undefined;
        let parentAffiliatedCompanyId: number | undefined;
        let parentDrafteAt: string | undefined;
        let parentCompleteAt: string | undefined;
        const originDraftAt =
          draftAt(jsonToApprovalLine(response.approvalline)) ??
          response.draftAt;
        const originCompleteAt = getCompleteAt(
          jsonToApprovalLine(response.approvalline),
        );
        if (response.parentId) {
          parentResponse = await documentApi.fetchApprovalLine(
            companyId,
            response.parentId,
          );
          if (parentResponse) {
            const approvalLine = jsonToApprovalLine(
              parentResponse.approvalLine,
            );
            parentApprovalLine = approvalLine;
            parentStatus = isProgress(approvalLine) === true ? 1 : undefined;
            parentAffiliatedCompanyId = getAffiliatedCompanyId(
              parentResponse.companyId,
              companyId,
            );
            parentDrafteAt = getApprovalLineDrafter(approvalLine)?.actAt;
            parentCompleteAt = getCompleteAt(approvalLine);
          }
        }

        const attachedFiles: AttachFile[] = [];
        if (attachedFileCount > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          const attachedUrlList = await documentApi.fetchAttachfileURLList(id);

          attachedList.forEach((x) => {
            const attachedURL = attachedUrlList.find((a) => x.id === a.id);
            attachedFiles.push({
              ...x,
              url: attachedURL?.url,
              isFileprotection: attachedURL?.isFileprotection,
            });
          });
          attachedFiles.sort(
            (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
          );
        }

        let sharedFiles: SharedFile[] = [];
        if (attachedSharedFileCount > 0) {
          sharedFiles = await documentApi.fetchSharedFileList(id);
        }

        data = {
          ...response,
          parentId: response.parentId ? response.parentId : undefined,
          publicOrNot: response.isPublic,
          parentStatus,
          parentApprovalLine,
          parentAffiliatedCompanyId,
          parentUpdateAt: parentResponse?.updateAt,
          parentDrafteAt: parentDrafteAt
            ? dateTimeFormat(parentDrafteAt, 'YYYY-MM-DD')
            : undefined,
          parentCompleteAt: parentCompleteAt
            ? dateTimeFormat(parentCompleteAt, 'YYYY-MM-DD')
            : undefined,
          approvalLine: jsonToApprovalLine(response.approvalline),
          referencePermission:
            response.referencePermission === '{}'
              ? undefined
              : JSON.parse(response.referencePermission),
          viewPermission:
            response.viewPermission === '{}'
              ? undefined
              : JSON.parse(response.viewPermission),
          draftAt: dateTimeFormat(originDraftAt, 'yyyy-MM-DD'),
          completeAt: originCompleteAt
            ? dateTimeFormat(originCompleteAt, 'yyyy-MM-DD')
            : undefined,
          attachedFiles,
          sharedFiles,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion ? opinions : undefined,
          comments: useComment ? comments : undefined,
          no: response.number,
          // eslint-disable-next-line no-bitwise
          isTopLevelOrganizationNameNumbering: (response.setting & 8) === 8, // 최상위 조직 이름 채번 사용: 8
          // eslint-disable-next-line no-bitwise
          isNumberingAtCompose: (response.setting & 1) === 1, // 작성 시점에 채번: 1, 완료 시점에 채번: 2
          // eslint-disable-next-line no-bitwise
          isSyncIncomingAndOutgoing: (response.setting & 4) === 4, // 접수 후 내부 결재 시 부모 문서번호 승계: 4
          // eslint-disable-next-line no-bitwise
          isApprovalboxSignatureImage: (response.setting & 16) === 16, // 결재란에 서명 이미지 사용: 16
          // eslint-disable-next-line no-bitwise
          isReReceipt: (response.formWorkChange & 4) === 4, // 반려된 내부문서 재접수: 4,
          // eslint-disable-next-line no-bitwise
          isReDraft: response.formWorkChange !== 0 || response.linkId !== '', // 재기안 여부.
          prev,
          next,
        };
      }

      return { data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 관리자 페이지 문서 읽기.
const findAdminconsoleView = createAsyncThunk(
  `${name}/findAdminconsoleView`,
  async (
    arg: {
      id: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { session } = getState() as RootState;

      const { companyId } = session.principal;
      const organizationIds = session.principal.affiliatedOrganizations.map(
        (a) => a.id,
      );
      const queryParams = getQueryParams(arg.route?.search ?? '');
      const affiliatedCompanyId = queryParams.affiliatedCompanyId
        ? parseInt(queryParams.affiliatedCompanyId, 10)
        : undefined;

      let data: AdminconsoleView | null | undefined;
      let _response_id_: number | undefined;
      data = null;

      const { id } = arg;

      if (typeof id !== 'number')
        return rejectWithValue({
          error: 'error',
          path: '',
          status: 500,
          timestamp: '',
        });

      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      let parentResponse:
        | {
            companyId: number;
            id: number;
            approvalLine: string;
            updateAt: string;
          }
        | undefined;

      const response = await documentApi.adminconsoleData({
        affiliatedCompanyId,
        id,
        organizationIds,
      });
      if (response) {
        if (response.id !== id) return { data: null };

        let prev: { affiliatedCompanyId?: number; id: number } | undefined;
        let next: { affiliatedCompanyId?: number; id: number } | undefined;

        const rootState = getState() as RootState;

        const { list, totalCount } = rootState.approval2.document.adminconsole;
        const index = list.findIndex((a) => a.id === id);

        const {
          status,
          pageNo = 1,
          rowsPerPage = 15,
        } = getQueryParams(arg.route?.search ?? '');

        const advancedSearchParams = getAdvancedSearchParams(
          arg.route?.search ?? '',
        );

        const param = {
          state: status !== undefined ? getStateWord(status) : 'ALL',
          documentId: id,
          advancedSearch: advancedSearchParams,
        };
        // 목록에 해당 아이디가 없는 경우. (보기에서 다음, 이전으로 이동되어 목록에 없는 아이디로 넘어간 경우)
        if (index === -1) {
          const prevItem = await documentApi.fetchAdminconsolePrev(param);
          const nextItem = await documentApi.fetchAdminconsoleNext(param);
          prev = prevItem.id
            ? {
                affiliatedCompanyId: undefined,
                id: prevItem.id,
              }
            : undefined;
          next = nextItem.id
            ? {
                affiliatedCompanyId: undefined,
                id: nextItem.id,
              }
            : undefined;
        } else if (index === 0) {
          if (pageNo > 1) {
            const nextItem = await documentApi.fetchAdminconsoleNext(param);
            next = nextItem.id
              ? {
                  affiliatedCompanyId: undefined,
                  id: nextItem.id,
                }
              : undefined;
          }
          prev = getDocumentId(list[1]);
        } else if (index === list.length - 1) {
          next = getDocumentId(list[index - 1]);
          if (pageNo * rowsPerPage < totalCount) {
            const prevItem = await documentApi.fetchAdminconsolePrev(param);
            prev = prevItem.id
              ? {
                  affiliatedCompanyId: undefined,
                  id: prevItem.id,
                }
              : undefined;
          }
        } else if (index > 0) {
          next = getDocumentId(list[index - 1]);
          prev = getDocumentId(list[index + 1]);
        }

        // 첨부파일 사용 1 0000 0001
        // 첨부파일 필수 2 0000 0010
        // 첨부문서 사용 4 0000 0100
        // 첨부문서 필수 8 0000 1000
        // 의견 사용    16 0001 0000
        // 의견 필수    32 0010 0000
        // 댓글 사용    64 0100 0000
        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const {
          attachedSharedFileCount,
          attachedFileCount,
          attachedDocumentCount,
        } = response;

        const opinions = response.opinionCount
          ? await documentApi.fetchOpinionList(companyId, id)
          : [];
        const comments = response.commentCount
          ? await documentApi.fetchCommentList(companyId, id)
          : [];

        let parentStatus: number | undefined;
        let parentApprovalLine: ApprovalLineType | undefined;
        let parentAffiliatedCompanyId: number | undefined;
        let parentDrafteAt: string | undefined;
        let parentCompleteAt: string | undefined;
        const originDraftAt =
          draftAt(jsonToApprovalLine(response.approvalline)) ??
          response.draftAt;
        const originCompleteAt = getCompleteAt(
          jsonToApprovalLine(response.approvalline),
        );
        if (response.parentId) {
          parentResponse = await documentApi.fetchApprovalLine(
            response.parentCompanyId,
            response.parentId,
          );
          if (parentResponse) {
            const approvalLine = jsonToApprovalLine(
              parentResponse.approvalLine,
            );
            parentApprovalLine = approvalLine;
            parentStatus = isProgress(approvalLine) === true ? 1 : undefined;
            parentAffiliatedCompanyId = getAffiliatedCompanyId(
              parentResponse.companyId,
              companyId,
            );
            parentDrafteAt = getApprovalLineDrafter(approvalLine)?.actAt;
            parentCompleteAt = getCompleteAt(approvalLine);
          }
        }

        const attachedFiles: AttachFile[] = [];
        if (attachedFileCount > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          const attachedUrlList = await documentApi.fetchAttachfileURLList(id);

          attachedList.forEach((x) => {
            const attachedURL = attachedUrlList.find((a) => x.id === a.id);
            attachedFiles.push({
              ...x,
              url: attachedURL?.url,
              isFileprotection: attachedURL?.isFileprotection,
            });
          });
        }

        let sharedFiles: SharedFile[] = [];
        if (attachedSharedFileCount > 0) {
          sharedFiles = await documentApi.fetchSharedFileList(id);
        }

        data = {
          ...response,
          parentStatus,
          parentApprovalLine,
          parentAffiliatedCompanyId,
          parentUpdateAt: parentResponse?.updateAt,
          parentDrafteAt: parentDrafteAt
            ? dateTimeFormat(parentDrafteAt, 'YYYY-MM-DD')
            : undefined,
          parentCompleteAt: parentCompleteAt
            ? dateTimeFormat(parentCompleteAt, 'YYYY-MM-DD')
            : undefined,
          approvalLine: jsonToApprovalLine(response.approvalline),
          no: response.number,
          referencePermission:
            response.referencePermission === '{}'
              ? undefined
              : JSON.parse(response.referencePermission),
          viewPermission:
            response.viewPermission === '{}'
              ? undefined
              : JSON.parse(response.viewPermission),
          draftAt: dateTimeFormat(originDraftAt, 'YYYY-MM-DD'),
          completeAt: originCompleteAt
            ? dateTimeFormat(originCompleteAt, 'YYYY-MM-DD')
            : undefined,
          attachedFiles,
          sharedFiles,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion ? opinions : undefined,
          comments: useComment ? comments : undefined,
          // eslint-disable-next-line no-bitwise
          isTopLevelOrganizationNameNumbering: (response.setting & 8) === 8, // 최상위 조직 이름 채번 사용: 8
          // eslint-disable-next-line no-bitwise
          isNumberingAtCompose: (response.setting & 1) === 1, // 작성 시점에 채번: 1, 완료 시점에 채번: 2
          // eslint-disable-next-line no-bitwise
          isSyncIncomingAndOutgoing: (response.setting & 4) === 4, // 접수 후 내부 결재 시 부모 문서번호 승계: 4
          // eslint-disable-next-line no-bitwise
          isApprovalboxSignatureImage: (response.setting & 16) === 16, // 결재란에 서명 이미지 사용: 16
          // eslint-disable-next-line no-bitwise
          isReReceipt: (response.formWorkChange & 4) === 4, // 반려된 내부문서 재접수: 4,
          // eslint-disable-next-line no-bitwise
          isReDraft: response.formWorkChange !== 0 || response.linkId !== '', // 재기안 여부.
          prev,
          next,
        };
      }

      return { data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 링크 대화상자 문서 읽기.
const linkedView = createAsyncThunk(
  `${name}/linkedView`,
  async (
    arg: {
      id: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { session } = getState() as RootState;
      const { companyId } = session.principal;

      const queryParams = getQueryParams(arg.route?.search ?? '');
      const affiliatedCompanyId = queryParams.affiliatedCompanyId
        ? parseInt(queryParams.affiliatedCompanyId, 10)
        : undefined;

      let data: DocumentView | null | undefined;
      let _response_id_: number | undefined;
      data = null;

      const { id } = arg;

      if (typeof id !== 'number')
        return rejectWithValue({
          error: 'error',
          path: '',
          status: 500,
          timestamp: '',
        });

      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;
      const response: DocumentData = await documentApi.linkedData({
        affiliatedCompanyId,
        documentId: id,
      });
      let parentResponse:
        | {
            companyId: number;
            id: number;
            approvalLine: string;
            updateAt: string;
          }
        | undefined;

      if (response) {
        if (response.id !== id) return { data: null };

        // 첨부파일 사용 1 0000 0001
        // 첨부파일 필수 2 0000 0010
        // 첨부문서 사용 4 0000 0100
        // 첨부문서 필수 8 0000 1000
        // 의견 사용    16 0001 0000
        // 의견 필수    32 0010 0000
        // 댓글 사용    64 0100 0000
        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const {
          attachedSharedFileCount,
          attachedFileCount,
          attachedDocumentCount,
        } = response;

        const opinions = response.opinionCount
          ? await documentApi.fetchOpinionList(companyId, id)
          : [];
        const comments = response.commentCount
          ? await documentApi.fetchCommentList(companyId, id)
          : [];

        let parentStatus: number | undefined;
        let parentApprovalLine: ApprovalLineType | undefined;
        let parentAffiliatedCompanyId: number | undefined;
        let parentDrafteAt: string | undefined;
        let parentCompleteAt: string | undefined;
        const originDraftAt =
          draftAt(jsonToApprovalLine(response.approvalline)) ??
          response.draftAt;
        const originCompleteAt = getCompleteAt(
          jsonToApprovalLine(response.approvalline),
        );
        if (response.parentCompanyId && response.parentId) {
          parentResponse = await documentApi.fetchApprovalLine(
            response.parentCompanyId,
            response.parentId,
          );
          if (parentResponse) {
            const approvalLine = jsonToApprovalLine(
              parentResponse.approvalLine,
            );
            parentApprovalLine = approvalLine;
            parentStatus = isProgress(approvalLine) === true ? 1 : undefined;
            parentAffiliatedCompanyId = getAffiliatedCompanyId(
              parentResponse.companyId,
              companyId,
            );
            parentDrafteAt = getApprovalLineDrafter(approvalLine)?.actAt;
            parentCompleteAt = getCompleteAt(approvalLine);
          }
        }

        const attachedFiles: AttachFile[] = [];
        if (attachedFileCount > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          const attachedUrlList = await documentApi.fetchAttachfileURLList(id);

          attachedList.forEach((x) => {
            const attachedURL = attachedUrlList.find((a) => x.id === a.id);
            attachedFiles.push({
              ...x,
              url: attachedURL?.url,
              isFileprotection: attachedURL?.isFileprotection,
            });
          });
        }

        let sharedFiles: SharedFile[] = [];
        if (attachedSharedFileCount > 0) {
          sharedFiles = await documentApi.fetchSharedFileList(id);
        }

        data = {
          ...response,
          publicOrNot: response.isPublic,
          parentStatus,
          parentApprovalLine,
          parentAffiliatedCompanyId,
          parentUpdateAt: parentResponse?.updateAt,
          parentDrafteAt: parentDrafteAt
            ? dateTimeFormat(parentDrafteAt, 'YYYY-MM-DD')
            : undefined,
          parentCompleteAt: parentCompleteAt
            ? dateTimeFormat(parentCompleteAt, 'YYYY-MM-DD')
            : undefined,
          approvalLine: jsonToApprovalLine(response.approvalline),
          no: response.number,
          referencePermission:
            response.referencePermission === '{}'
              ? undefined
              : JSON.parse(response.referencePermission),
          viewPermission:
            response.viewPermission === '{}'
              ? undefined
              : JSON.parse(response.viewPermission),
          draftAt: dateTimeFormat(originDraftAt, 'yyyy-MM-DD'),
          completeAt: originCompleteAt
            ? dateTimeFormat(originCompleteAt, 'yyyy-MM-DD')
            : undefined,
          attachedFiles,
          sharedFiles,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion ? opinions : undefined,
          comments: useComment ? comments : undefined,
          // eslint-disable-next-line no-bitwise
          isTopLevelOrganizationNameNumbering: (response.setting & 8) === 8, // 최상위 조직 이름 채번 사용: 8
          // eslint-disable-next-line no-bitwise
          isNumberingAtCompose: (response.setting & 1) === 1, // 작성 시점에 채번: 1, 완료 시점에 채번: 2
          // eslint-disable-next-line no-bitwise
          isSyncIncomingAndOutgoing: (response.setting & 4) === 4, // 접수 후 내부 결재 시 부모 문서번호 승계: 4
          // eslint-disable-next-line no-bitwise
          isApprovalboxSignatureImage: (response.setting & 16) === 16, // 결재란에 서명 이미지 사용: 16
          // eslint-disable-next-line no-bitwise
          isReReceipt: (response.formWorkChange & 4) === 4, // 반려된 내부문서 재접수: 4,
          // eslint-disable-next-line no-bitwise
          isReDraft: response.formWorkChange !== 0 || response.linkId !== '', // 재기안 여부.
          prev: undefined,
          next: undefined,
        };
      }

      return { data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const printView = createAsyncThunk(
  `${name}/printView`,
  async (
    arg: {
      folderId: number; // 관리자 페이지에서 0으로 전달. (모든 보기 적용 안됨)
      id: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { session, approval2 } = getState() as RootState;

      const { companyId } = session.principal;
      const organizationIds = session.principal.affiliatedOrganizations.map(
        (a) => a.id,
      );

      const queryParams = getQueryParams(arg.route?.search ?? '');
      const affiliatedCompanyId = queryParams.affiliatedCompanyId
        ? parseInt(queryParams.affiliatedCompanyId, 10)
        : undefined;

      let data: PrintView | null | undefined;
      let _response_id_: number | undefined;
      data = null;

      const { folderId, id } = arg;

      if (typeof id !== 'number')
        return rejectWithValue({
          error: 'error',
          path: '',
          status: 500,
          timestamp: '',
        });

      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      let response: DocumentData;
      let parentResponse:
        | {
            companyId: number;
            id: number;
            approvalLine: string;
            updateAt: string;
          }
        | undefined;

      if (isPersonalFolder({ getState, folderId })) {
        response = await personalDocumentApi.data({
          folderId,
          affiliatedCompanyId,
          id,
        });
      } else if (
        approval2.archive.folders.data.find((a) => a.id === folderId) !==
        undefined
      ) {
        response = await archiveDocumentApi.data({
          folderId,
          documentId: id,
        });
      } else if (folderId > 4000) {
        // 참조 문서함
        if (folderId === 4001) {
          response = await referenceDocumentsApi.data({
            folderId,
            documentId: id,
            affiliatedCompanyId,
          });
          // 조회 문서함
        } else if (folderId === 4002) {
          response = await viewDocumentsApi.data({
            folderId,
            documentId: id,
            affiliatedCompanyId,
          });
        } else throw new Error('데이터가 이동되었거나 삭제되었습니다.');
      } else if (folderId > 3000) {
        response = await organizationApi.data({
          folderId: arg.folderId,
          documentId: id,
        });
        // 관리자 페이지
      } else if (folderId === 0) {
        response = await documentApi.adminconsoleData({
          affiliatedCompanyId,
          id,
          organizationIds,
        });
      } else {
        response = await documentApi.stateData({
          affiliatedCompanyId,
          documentId: id,
          folderId,
        });
      }

      if (response) {
        if (response.id !== id) return { data: null };

        // 첨부파일 사용 1 0000 0001
        // 첨부파일 필수 2 0000 0010
        // 첨부문서 사용 4 0000 0100
        // 첨부문서 필수 8 0000 1000
        // 의견 사용    16 0001 0000
        // 의견 필수    32 0010 0000
        // 댓글 사용    64 0100 0000
        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = (option & 16) === 16; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = (option & 64) === 64; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const { attachedFileCount, attachedDocumentCount } = response;

        const opinions = response.opinionCount
          ? await documentApi.fetchOpinionList(companyId, id)
          : [];
        const comments = response.commentCount
          ? await documentApi.fetchCommentList(companyId, id)
          : [];

        let parentApprovalLine: ApprovalLineType | undefined;
        let parentDrafteAt: string | undefined;
        let parentCompleteAt: string | undefined;
        const originDraftAt =
          draftAt(jsonToApprovalLine(response.approvalline)) ??
          response.draftAt;
        const originCompleteAt = getCompleteAt(
          jsonToApprovalLine(response.approvalline),
        );
        if (response.parentCompanyId && response.parentId) {
          parentResponse = await documentApi.fetchApprovalLine(
            response.parentCompanyId,
            response.parentId,
          );
          if (parentResponse) {
            const approvalLine = jsonToApprovalLine(
              parentResponse.approvalLine,
            );
            parentApprovalLine = approvalLine;
            parentDrafteAt = getApprovalLineDrafter(approvalLine)?.actAt;
            parentCompleteAt = getCompleteAt(approvalLine);
          }
        }
        const attachedFiles: AttachFile[] = [];
        if (attachedFileCount > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          attachedList.forEach((x) => attachedFiles.push(x));
          attachedFiles.sort(
            (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
          );
        }

        data = {
          ...response,
          parentApprovalLine,
          contents: response.content,
          parentDrafteAt: parentDrafteAt
            ? dateTimeFormat(parentDrafteAt, 'YYYY-MM-DD')
            : undefined,
          parentCompleteAt: parentCompleteAt
            ? dateTimeFormat(parentCompleteAt, 'YYYY-MM-DD')
            : undefined,
          approvalLine: jsonToApprovalLine(response.approvalline),
          draftAt: dateTimeFormat(originDraftAt, 'yyyy-MM-DD'),
          completeAt: originCompleteAt
            ? dateTimeFormat(originCompleteAt, 'yyyy-MM-DD')
            : undefined,
          attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
          attachedDocuments:
            attachedDocumentCount > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion ? opinions : undefined,
          comments: useComment ? comments : undefined,
        };
      }

      return { data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const saveOpinion = createAsyncThunk(
  `${name}/saveOpinion`,
  async (
    arg: {
      companyId: number;
      documentId: number;
      parentId: number;
      id?: number;
      act: number;
      contents: string;
      updateAt?: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const saveArg = await documentApi.saveOpinion(arg);

      const { pathname } = (getState() as RootState).session.route;
      const { companyId: employeeCompanyId, employeeId } = (
        getState() as RootState
      ).session.principal;
      const result = {
        isAdmin: pathname.startsWith('/adminconsole'),
        isCreate: arg.id === undefined,
        data: {
          ...arg,
          ...saveArg,
          createAt: saveArg.updateAt,
          employeeCompanyId,
          employeeId,
          likes: 0, // 좋아요 수
          dislikes: 0, // 싫어요 수
          loves: 0, // 사랑해요 수
        },
      };
      return result;
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
);

const deleteOpinion = createAsyncThunk(
  `${name}/deleteOpinion`,
  async (
    arg: {
      companyId: number;
      documentId: number;
      id: number;
      parentId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { pathname } = (getState() as RootState).session.route;
      const deleteArg = await documentApi.deleteOpinion({
        companyId: arg.companyId,
        documentId: arg.documentId,
        id: arg.id,
        parentId: arg.parentId,
        updateAt: arg.updateAt,
      });
      if (!deleteArg) return undefined;
      return {
        isAdmin: pathname.startsWith('/adminconsole'),
        data: {
          ...arg,
        },
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const saveComment = createAsyncThunk(
  `${name}/saveComment`,
  async (arg: CommentSaveArg & LocateArg, { rejectWithValue, getState }) => {
    try {
      const { pathname } = (getState() as RootState).session.route;
      const { companyId, employeeId } = (getState() as RootState).session
        .principal;
      const saveArg = await documentApi.saveComment(arg);
      if (!saveArg) return undefined;

      const data = {
        ...arg,
        employeeId,
        employeeCompanyId: companyId,
        updateAt: saveArg.updateAt,
        createAt: saveArg.updateAt,
        parentId: arg.parentId ?? 0,
        id: arg.id ?? saveArg.id,
        likes: 0, // 좋아요 수
        dislikes: 0, // 싫어요 수
        loves: 0, // 사랑해요 수
      };

      return {
        isAdmin: pathname.startsWith('/adminconsole'),
        isCreate: arg.id === undefined,
        data,
      };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const deleteComment = createAsyncThunk(
  `${name}/deleteComment`,
  async (
    arg: {
      companyId: number;
      documentId: number;
      id: number;
      parentId: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const { pathname } = (getState() as RootState).session.route;

      const deleteArg = await documentApi.deleteComment({
        companyId: arg.companyId,
        documentId: arg.documentId,
        id: arg.id,
        parentId: arg.parentId,
        updateAt: arg.updateAt,
      });
      if (!deleteArg) return undefined;

      const data = {
        ...arg,
        employeeId: 0,
        employeeCompanyId: 0,
        contents: '삭제된 댓글 입니다.',
        isDeleted: true,
        parentId: arg.parentId ?? 0,
        updateAt: deleteArg.updateAt,
        createAt: deleteArg.updateAt,
        likes: 0, // 좋아요 수
        dislikes: 0, // 싫어요 수
        loves: 0, // 사랑해요 수
      };

      return {
        isAdmin: pathname.startsWith('/adminconsole'),
        isReply: arg.parentId !== 0,
        data,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const fetchHistoryList = createAsyncThunk(
  `${name}/fetchHistoryList`,
  async (
    arg: {
      documentId: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    let data: { items: HistoryItem[] } = {
      items: [],
    };
    try {
      const response = await documentApi.fetchHistoryList(arg.documentId);
      if (!response) return undefined;
      data = { items: response };
      return { data };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const fetchHistoryView = createAsyncThunk(
  `${name}/fetchHistoryView`,
  async (
    arg: {
      documentId: number;
      id: number[];
    },
    { rejectWithValue },
  ) => {
    const { documentId, id } = arg;
    try {
      const response = await documentApi.fetchHistoryView(documentId, id);
      return response.map((a) => {
        return {
          id: a.id,
          contentHTML: a.contents,
          subject: a.subject,
          createAt: a.createAt,
          approvalLine: a.approvalLine
            ? jsonToApprovalLine(a.approvalLine)
            : '',
          attachments: JSON.parse(a.attachedFile),
          documentFile: JSON.parse(a.attachedDocument),
        };
      });
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      folderId: number;
      search: string;
      force?: true;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const root = getState() as RootState;
      const state = root.approval2.document;
      const { session } = root;
      const { companyId } = session.principal;

      if (companyId === undefined) throw new Error('companyId is undefined.');

      const timestamp: number = Date.now();

      let meta: CacheMeta | undefined;

      let items: DocumentItem[] | null = null;

      const list = getCacheList(state.cache.list, arg.folderId, arg.search);
      const page = getCacheListPage(state.cache.list, arg.folderId, arg.search);

      if (meta === undefined)
        meta = {
          timestamp,
          lastUpdateAt: await oldApi.findLastUpdateAt(),
        };

      if (meta.lastUpdateAt === list?.lastUpdateAt && page !== undefined) {
        if (page.data)
          return {
            meta,
            data: {
              items: page.data,
              totalCount: list.totalCount,
              search: arg.search,
            },
          };
      }
      const queryParams = getQueryParams(arg.search);

      let folderType:
        | 'personal'
        | 'archive'
        | 'permission'
        | 'organization'
        | undefined;
      if (arg.folderId > 5000) {
        if (
          (
            getState() as RootState
          ).approval2.userPreferences.userPreferencesFolder.find(
            (a) => a.id === arg.folderId,
          ) !== undefined
        )
          folderType = 'personal';
        else if (
          (getState() as RootState).approval2.archive.folders.data.find(
            (a) => a.id === arg.folderId,
          ) !== undefined
        )
          folderType = 'archive';
        else throw new Error('데이터가 이동되었거나 삭제되었습니다.');
      } else if (arg.folderId > 4000) {
        folderType = 'permission';
      } else if (arg.folderId > 3000) {
        folderType = 'organization';
      }

      let totalCount = 0;

      const { searchCode, searchWord } = queryParams;
      const params =
        searchCode && searchWord
          ? {
              advancedSearch: {
                [searchCode.toLowerCase()]: searchWord,
              },
              pageno: queryParams.pageNo ?? 1,
              rowsperpage: queryParams.rowsPerPage ?? 15,
            }
          : {
              advancedSearch: getAdvancedSearchParams(arg.search),
              pageno: queryParams.pageNo ?? 1,
              rowsperpage: queryParams.rowsPerPage ?? 15,
            };

      if (folderType === 'personal') {
        const response = await personalDocumentApi.documents({
          ...params,
          folderId: arg.folderId,
        });
        // 리스트의 count가 null일 경우 totalCount 함수 조회.
        if (response.count === undefined || response.count === null)
          totalCount = await personalDocumentApi.totalCount({
            ...params,
            folderId: arg.folderId,
          });
        else totalCount = response.count;

        items = response.list.map((a) => {
          const approvalLine = jsonToApprovalLine(a.approvalline);
          return {
            affiliatedCompanyId:
              a.affiliatedCompanyId === null
                ? undefined
                : a.affiliatedCompanyId,
            id: a.id, // 아이디.
            workName: a.workName, // 업무 이름.
            documentNo: documentMacroReplace(a.number),
            subject: a.subject, // 제목.
            approvalLine, // 결재선.
            attachments: [], // 첨부 파일 목록.
            opinions: a.opinionCount, // 의견 수.
            comments: a.commentCount, // 댓글 수.
            updateAt: a.updateAt, // 수정일.
            // 전달받지 않은 값 생성.
            createAt: a.createAt ?? '1000-01-01', // 작성일.
            completeAt:
              a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
            urgent: false, // 긴급 여부.
            checked: false, // 체크 여부.
            linkWait: a.linkWait, // 연동 대기 여부.
          };
        });
      } else if (folderType === 'archive') {
        const response = await archiveDocumentApi.documents({
          ...params,
          folderId: arg.folderId,
        });
        // 리스트의 count가 null일 경우 totalCount 함수 조회.
        if (response.count === undefined || response.count === null)
          totalCount = await archiveDocumentApi.totalCount({
            ...params,
            folderId: arg.folderId,
          });
        else totalCount = response.count;

        items = response.list.map((a) => {
          const approvalLine = jsonToApprovalLine(a.approvalline);
          return {
            affiliatedCompanyId:
              a.affiliatedCompanyId === null
                ? undefined
                : a.affiliatedCompanyId,
            id: a.id, // 아이디.
            workName: a.workName, // 업무 이름.
            documentNo: documentMacroReplace(a.number), // 문서 번호
            subject: a.subject, // 제목.
            approvalLine, // 결재선.
            attachments: [], // 첨부 파일 목록.
            opinions: a.opinionCount, // 의견 수.
            comments: a.commentCount, // 댓글 수.
            updateAt: a.updateAt, // 수정일.
            createAt: draftAt(approvalLine) ?? '1000-01-01', // 작성일.
            completeAt:
              a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
            checked: false, // 체크 여부.
            linkWait: a.linkWait,
          };
        });
      } else if (folderType === 'permission') {
        const response = await permissionDocumentApi.documents({
          ...params,
          folderId: arg.folderId,
        });
        if (response.count === undefined || response.count === null)
          totalCount = await permissionDocumentApi.totalCount({
            ...params,
            folderId: arg.folderId,
          });
        else totalCount = response.count;

        items = response.list.map((a) => {
          const approvalLine = jsonToApprovalLine(a.approvalline);
          return {
            affiliatedCompanyId:
              a.affiliatedCompanyId === null
                ? undefined
                : a.affiliatedCompanyId,
            id: a.id, // 아이디.
            workName: a.workName, // 업무 이름.
            documentNo: documentMacroReplace(a.number), // 문서 번호
            subject: a.subject, // 제목.
            approvalLine, // 결재선.
            attachments: [], // 첨부 파일 목록.
            opinions: a.opinionCount, // 의견 수.
            comments: a.commentCount, // 댓글 수.
            updateAt: a.updateAt, // 수정일.
            createAt: draftAt(approvalLine) ?? '1000-01-01', // 작성일.
            linkWait: a.linkWait,
            completeAt:
              a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
            checked: false, // 체크 여부.
          };
        });
      } else if (folderType === 'organization') {
        const response = await organizationApi.documents({
          ...params,
          folderId: arg.folderId,
        });
        if (response.count === undefined || response.count === null)
          totalCount = await organizationApi.totalCount({
            ...params,
            folderId: arg.folderId,
          });
        else totalCount = response.count;

        items = response.list.map((a) => {
          const approvalLine = jsonToApprovalLine(a.approvalline);
          return {
            affiliatedCompanyId:
              a.affiliatedCompanyId === null
                ? undefined
                : a.affiliatedCompanyId,
            id: a.id, // 아이디.
            workName: a.workName, // 업무 이름.
            documentNo: documentMacroReplace(a.number), // 문서 번호
            subject: a.subject, // 제목.
            approvalLine, // 결재선.
            attachments: [], // 첨부 파일 목록.
            opinions: a.opinionCount, // 의견 수.
            comments: a.commentCount, // 댓글 수.
            updateAt: a.updateAt, // 수정일.
            createAt: draftAt(approvalLine) ?? '1000-01-01', // 작성일.
            linkWait: a.linkWait,
            completeAt:
              a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
            checked: false, // 체크 여부.
          };
        });
      } else {
        const response = await oldApi.documents({
          ...params,
          folderId: arg.folderId,
        });
        // 리스트의 count가 null일 경우 totalCount 함수 조회.
        if (response.count === undefined || response.count === null) {
          const { count } = await oldApi.totalCount({
            ...params,
            folderId: arg.folderId,
          });
          totalCount = count;
        } else totalCount = response.count;

        items = response.list.map((a) => {
          return {
            affiliatedCompanyId:
              a.affiliatedCompanyId === null
                ? undefined
                : a.affiliatedCompanyId,
            id: a.id, // 아이디.
            workName: a.workName, // 업무 이름.
            documentNo: documentMacroReplace(a.number), // 문서 번호
            subject: a.subject, // 제목.
            approvalLine: jsonToApprovalLine(a.approvalline), // 결재선.
            attachments: [], // 첨부 파일 목록.
            opinions: a.opinionCount, // 의견 수.
            comments: a.commentCount, // 댓글 수.
            updateAt: a.updateAt, // 수정일.
            createAt: a.createAt, // 작성일.
            linkWait: a.linkWait,
            completeAt:
              a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
            checked: false, // 체크 여부.
          };
        });
      }

      return {
        meta,
        data: {
          items,
          totalCount,
          search: arg.search,
        },
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: ({ folderId, search }, { getState }) => {
      const state = (getState() as RootState).approval2.document;
      // 같은 요청이 있는 경우 실행되지 않도록 설정.
      if (
        state.requests.find(
          (x) =>
            x.type === findList.typePrefix &&
            x.arg.folderId === folderId &&
            x.arg.search === search,
        ) !== undefined
      ) {
        return false;
      }
      return true;
    },
  },
);

/** 매크로 값 가져오기. */
function getMacro(arg: {
  id: string;
  data: {
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  };
}): string | undefined {
  const {
    id,
    data: { referencePermission, viewPermission },
  } = arg;
  // TODO 백엔드에서 구현 여부 확인 후 제거 예정
  // 참조. _REFER_
  if (id === 'APPROVAL/REFERENCE_PERMISSION_LIST') {
    if (referencePermission === undefined) return undefined;
    return referencePermission.groups
      .map(({ items }) => items)
      .flat()
      .map((a) => {
        if (a.employeeId === undefined) return a.organizationName;
        return a.macroName || a.employeeName;
      })
      .join(',');
  }
  // TODO 백엔드에서 구현 여부 확인 후 제거 예정
  // 조회. _CHECK_
  if (id === 'APPROVAL/VIEW_PERMISSION_LIST') {
    if (viewPermission === undefined) return undefined;
    return viewPermission.groups
      .map(({ items }) => items)
      .flat()
      .map((a) => {
        if (a.employeeId === undefined) return a.organizationName;
        return a.macroName || a.employeeName;
      })
      .join(',');
  }

  return undefined;
}

function jsonToApprovalLine(a: string): ApprovalLineType {
  const approvalLine: any = JSON.parse(a);
  return {
    ...approvalLine,
    groups: (
      approvalLine.groups as { items: { act?: number | string }[] }[]
    ).map((group) => {
      if (group.items.length === 0) return group;
      return {
        ...group,
        items: (group.items as { act?: number | string }[]).map((item) => {
          if (typeof item.act === 'number')
            return { ...item, act: getApprovalAct(item.act) };
          return item;
        }),
      };
    }),
  };
}

export function approvalLineToJson(approvalLine: ApprovalLineType): string {
  const result = {
    ...approvalLine,
    groups: approvalLine.groups.map((group) => {
      if (group.items.length === 0) return group;
      switch (group.type) {
        case 'draft':
          return {
            ...group,
            items: group.items.map((item) => {
              return { ...item, act: getApprovalAct(item.act) };
            }),
          };
        case 'approval':
          return {
            ...group,
            items: group.items.map((item) => {
              return { ...item, act: getApprovalAct(item.act) };
            }),
          };
        case 'agree':
          return {
            ...group,
            items: group.items.map((item) => {
              return { ...item, act: getApprovalAct(item.act) };
            }),
          };
        case 'audit':
        case 'receive':
        default:
          return {
            ...group,
            items: group.items.map((item) => {
              return { ...item, act: getApprovalAct(item.act) };
            }),
          };
      }
    }),
  };
  return JSON.stringify(result);
}

/** 결재선 아이디 생성 */
export function approvalLineGenerateId(
  approvalLine: ApprovalLineType,
): ApprovalLineType {
  const result = {
    ...approvalLine,
    groups: approvalLine.groups.map((group, i) => {
      const groupId = `g${i + 1}`;
      switch (group.type) {
        case 'draft':
          return {
            ...group,
            id: groupId,
            items: group.items.map((item, j) => {
              return { ...item, id: `${groupId}/i${j + 1}` };
            }),
          };
        case 'approval':
          return {
            ...group,
            id: groupId,
            items: group.items.map((item, j) => {
              return { ...item, id: `${groupId}/i${j + 1}` };
            }),
          };
        case 'agree':
          return {
            ...group,
            id: groupId,
            items: group.items.map((item, j) => {
              return { ...item, id: `${groupId}/i${j + 1}` };
            }),
          };
        case 'audit':
        case 'receive':
        default:
          return {
            ...group,
            id: groupId,
            items: group.items.map((item, j) => {
              return { ...item, id: `${groupId}/i${j + 1}` };
            }),
          };
      }
    }),
  };
  return result;
}

interface DocumentCreateArg {
  draftTopLevelOrganizationId: number;
  workId: number;
  workUpdateAt: string;
  formId: number;
  formUpdateAt: string;
  no: string;
  subject: string;
  contents: string;
  approvalLine: ApprovalLineType;
  referencePermission: any;
  viewPermission: any;

  publicOrNot: boolean;
  useUrgent: boolean;

  attachedFiles?: {
    path: string;
    id: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
  }[];
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  attachedSharedfiles?: {
    id: number;
    path: string;
    name: string;
    size: number;
    feature: number;
  }[]; // 연동 공유 파일 목록.
  archiveFolderId: number;
  opinion?: string;

  temporaryDocumentId?: number; // 임시보관 문서 상신인 경우만 값이 있습니다.
  previousDocumentId?: number; // 재기안인 경우만 값이 있습니다.

  linkType?: string; // 연동 유형. (NONE: 없음, ATTENDANCE: 근태)
  linkId?: string; // 연동 아이디. (연동 유형이 있는 경우 필수, null 또는 빈값은 아니어야 함)
}

/** 결재 상신 */
const create = createAsyncThunk(
  `${name}/create`,
  async (
    arg: { param: DocumentCreateArg } & LocateArg,
    { rejectWithValue },
  ) => {
    const { param } = arg;
    try {
      const data = {
        workId: param.workId, // smallint unsigned NOT NULL COMMENT '업무 아이디 - approval_work.id',
        workUpdateAt: param.workUpdateAt,
        formId: param.formId, // smallint unsigned NOT NULL COMMENT '양식 아이디 - approval_form.id',
        formUpdateAt: param.formUpdateAt,
        importance: param.useUrgent ? 2 : 1, // tinyint unsigned NOT NULL DEFAULT '1' COMMENT '중요도 - 낮음:0, 보통:1, 높음:2, 긴급: 4 | 5 | 6 | 7',
        publicOrNot: param.publicOrNot, // bit(1) NOT NULL DEFAULT b'1' COMMENT '공개 여부 - 공개:1, 비공개: 0',
        no: param.no, // varchar(128) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci NOT NULL COMMENT '문서번호',
        subject: param.subject, // varchar(128) CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci NOT NULL COMMENT '제목',
        contents: param.contents,
        // contents_path varchar(256) NOT NULL COMMENT '내용 경로',
        approvalLine: approvalLineToJson(
          approvalLineGenerateId(param.approvalLine),
        ), // json NOT NULL COMMENT '결재선',
        referencePermission:
          param.referencePermission !== undefined
            ? JSON.stringify(param.referencePermission)
            : '{}', // json NOT NULL COMMENT '참조권',
        viewPermission:
          param.viewPermission !== undefined
            ? JSON.stringify(param.viewPermission)
            : '{}', // json NOT NULL COMMENT '조회권',
        creatorRootOrganizationId: param.draftTopLevelOrganizationId,

        archiveFolderId: param.archiveFolderId,
        opinion: param.opinion,

        attachedFiles: param.attachedFiles,
        attachedDocuments: param.attachedDocuments,

        temporaryDocumentId: param.temporaryDocumentId,
        previousDocumentId: param.previousDocumentId,
        linkType: param.linkType,
        linkId: param.linkId,
      };

      await documentApi.create(data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(create.typePrefix, arg, getState);
    },
  },
);

/** 결재 상신 */
const createLinked = createAsyncThunk(
  `${name}/create`,
  async (
    arg: { param: DocumentCreateArg } & LocateArg,
    { rejectWithValue },
  ) => {
    const { param } = arg;
    try {
      const data = {
        workId: param.workId,
        workUpdateAt: param.workUpdateAt,
        formId: param.formId,
        formUpdateAt: param.formUpdateAt,
        importance: param.useUrgent ? 2 : 1,
        publicOrNot: param.publicOrNot,
        no: param.no,
        subject: param.subject,
        contents: param.contents,
        approvalLine: approvalLineToJson(
          approvalLineGenerateId(param.approvalLine),
        ),
        referencePermission:
          param.referencePermission !== undefined
            ? JSON.stringify(param.referencePermission)
            : '{}',
        viewPermission:
          param.viewPermission !== undefined
            ? JSON.stringify(param.viewPermission)
            : '{}',
        creatorRootOrganizationId: param.draftTopLevelOrganizationId,

        archiveFolderId: param.archiveFolderId,
        opinion: param.opinion,

        attachedFiles: param.attachedFiles,
        attachedDocuments: param.attachedDocuments,
        attachedSharedfiles: param.attachedSharedfiles,

        linkType: param.linkType,
        linkId: param.linkId,
      };

      await documentApi.createLinked(data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(create.typePrefix, arg, getState);
    },
  },
);

interface DocumentReceiptArg {
  parentCompanyId: number;
  parentId: number;
  parentUpdateAt: string;
  publicOrNot: boolean;
  useUrgent: boolean;
  no: string;
  subject: string;
  contents: string;
  approvalLine: ApprovalLineType;
  referencePermission: any;
  viewPermission: any;
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  attachedFiles?: {
    documentId?: number;
    path: string;
    id: number;
    name: string;
    size: number;
    seq: number;
    delete?: boolean;
    copy?: boolean;
  }[];
  creatorRootOrganizationId: number;
  archiveFolderId: number;
  opinion?: string;
}

export interface AdminconsoleList {
  checked: boolean;
  companyId: number;
  id: number;
  workName: string;
  status: string;
  importance: string;
  no: string;
  subject: string;
  approvalLine: ApprovalLineType;
  draftAt: string;
  completeAt: string;
  updateAt: string;
  deleterId?: number;
  isOutgoing: boolean; // 발신 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  hasReceipt: boolean; // 접수 문서를 가지고 있는지 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
  isReceipt: boolean; // 접수 문서 여부 - 관리자 페이지에서 문서 삭제 시 수발신 문서지 확인용.
}

export interface AdminconsoleListTemp {
  affiliatedCompanyId?: number; // 문서 관계사 회사 아이디.
  id: number; // 아이디.
  workName: string; // 업무 이름
  status: string; // (1: 기안,  3: 완료, 4: 반려, 12: 회수, 44: 취소)
  documentNo: string; // 문서번호.
  subject: string; // 제목.
  approvalLine: ApprovalLineType; // 결재선.
  attachments: Array<string>; // 첨부 파일 목록.
  completeAt?: string; // 완료일.
  opinions: number; // 의견 수.
  comments: number; // 댓글 수.
  createAt: string; // 작성일.
  updateAt: string; // 수정일.
  checked: boolean; // 체크 여부.
  linkWait?: boolean; // 연동 대기 여부.
  draftAt: string;
  no: string;
  isIncommingOrOutgoing?: boolean; // 수발신 문서 여부 (문서 삭제 확인용)
  hasReceipt: boolean;
  isReceipt: boolean;
  isOutgoing: boolean;
}

/** 접수 후 내부결재 */
const receipt = createAsyncThunk(
  `${name}/receipt`,
  async (
    arg: { param: DocumentReceiptArg } & LocateArg,
    { rejectWithValue },
  ) => {
    const { param } = arg;
    try {
      const data = {
        parentCompanyId: param.parentCompanyId,
        parentId: param.parentId,
        parentUpdateAt: param.parentUpdateAt,
        importance: param.useUrgent ? 2 : 1,
        publicOrNot: param.publicOrNot,
        no: param.no,
        subject: param.subject,
        contents: param.contents,
        approvalLine: approvalLineToJson(
          approvalLineGenerateId(param.approvalLine),
        ), // json NOT NULL COMMENT '결재선',
        referencePermission:
          param.referencePermission !== undefined
            ? JSON.stringify(param.referencePermission)
            : '{}', // json NOT NULL COMMENT '참조권',
        viewPermission:
          param.viewPermission !== undefined
            ? JSON.stringify(param.viewPermission)
            : '{}', // json NOT NULL COMMENT '조회권',
        attachedFiles: param.attachedFiles,
        attachedDocuments: param.attachedDocuments,
        creatorRootOrganizationId: param.creatorRootOrganizationId,
        archiveFolderId: param.archiveFolderId,
        opinion: param.opinion,
      };

      await documentApi.receipt(data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 최초의 임시저장 */
const temporary = createAsyncThunk(
  `${name}/temporary`,
  async (
    arg: {
      param: {
        workId: number;
        formId: number;
        subject: string;
        contents: string;
        approvalLine: ApprovalLineType;
        referencePermission?: any;
        viewPermission?: any;
        attachedFiles?: {
          path: string;
          id: number;
          documentId?: number;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
          copy?: boolean;
        }[];
        attachedDocuments?: {
          companyId: number;
          id: number;
        }[];
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { param } = arg;
    try {
      const work = await approvalWorkApi.fetchView({ id: param.workId });
      const form = await approvalFormApi.fetchView(param.formId);

      if (form.id === 0 || form.status !== 1) {
        const error = '양식이 사용 중지되었거나 삭제되었습니다.';
        return rejectWithValue(appError({ error }));
      }

      const data = {
        ...param,
        workUpdateAt: work.updateAt,
        formUpdateAt: form.updateAt,
        contents: param.contents,
        approvalLine: approvalLineToJson(
          approvalLineGenerateId(param.approvalLine),
        ), // json NOT NULL COMMENT '결재선',
        referencePermission:
          param.referencePermission !== undefined
            ? JSON.stringify(param.referencePermission)
            : '{}', // json NOT NULL COMMENT '참조권',
        viewPermission:
          param.viewPermission !== undefined
            ? JSON.stringify(param.viewPermission)
            : '{}', // json NOT NULL COMMENT '조회권',
      };
      await documentApi.temporary(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/** 임시저장 문서를 수정 후 다시 임시저장 */
const reTemporary = createAsyncThunk(
  `${name}/reTemporary`,
  async (
    arg: {
      reParam: {
        id: number;
        updateAt: string;
        subject: string;
        contents: string;
        approvalLine: ApprovalLineType;
        referencePermission?: any;
        viewPermission?: any;
        attachedFiles?: {
          path: string;
          id: number;
          documentId?: number;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
          copy?: boolean;
        }[];
        attachedDocuments?: {
          companyId: number;
          id: number;
        }[];
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { reParam } = arg;
    try {
      if (reParam.id === 0 || reParam.updateAt === '')
        return rejectWithValue(
          appError({ error: '임시저장된 문서를 찾을 수 없습니다.' }),
        );

      const data = {
        ...reParam,
        contents: reParam.contents,
        approvalLine: approvalLineToJson(
          approvalLineGenerateId(reParam.approvalLine),
        ), // json NOT NULL COMMENT '결재선',
        referencePermission:
          reParam.referencePermission !== undefined
            ? JSON.stringify(reParam.referencePermission)
            : '{}', // json NOT NULL COMMENT '참조권',
        viewPermission:
          reParam.viewPermission !== undefined
            ? JSON.stringify(reParam.viewPermission)
            : '{}', // json NOT NULL COMMENT '조회권',
      };
      await documentApi.reTemporary(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
/**
 * 결재
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param companyId 회사 아이디.
 * @param approverType 결재 타입.
 * @param id 문서 아이디.
 * @param updateAt 문서 수정 날짜.
 * @param opinion 결재 의견.
 * @parma organizationId 결재 조직 아이디. (조직 결재인 경우만 값 설정).
 * @param designatorId 대리 결재인 경우 대리 결재 지정자 추가 매개 변수. (대리 결재자인 경우만 값 설정)
 * @param approver 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
 */
const approval = createAsyncThunk(
  `${name}/approval`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        approverType: 'organization' | 'employee' | 'surrogater';
        companyId: number;
        id: number;
        updateAt: string;
        opinion?: string;

        parentCompanyId?: number;
        parentId?: number;

        // 조직 결재와 대리 결재 값은 둘 중 하나만 설정 가능.
        organizationId?: number; // 결재 조직 아이디. (조직 결재인 경우만 값 설정)
        designatorId?: number; // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수. (대리 결재자인 경우만 값 설정)

        // 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
        approver?: {
          companyId: number;
          companyName: string;
          organizationId: number;
          organizationName: string;
          employeeId: number;
          employeeName: string;
          jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
          jobClassName: string;
          jobPositionId: number;
          jobPositionName: string;
          jobDutyId: number;
          jobDutyName: string;
        };
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const {
        affiliatedCompanyId,
        opinion,
        parentId,
        parentCompanyId,
        organizationId,
        designatorId,
        approver,
      } = data;

      const param = {
        affiliatedCompanyId,
        id: data.id,
        updateAt: data.updateAt,
        opinion,
        organizationId,
        parentId,
        parentCompanyId,
        designatorId, // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수.
        // 조직 결재이거나 대리 결재인 경우에만 값 설정.
        approver:
          (organizationId !== undefined || designatorId !== undefined) &&
          approver !== undefined
            ? approver
            : undefined,
      };

      await documentApi.approbate(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 반려
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 문서 수정 날짜.
 * @param opinion 결재 의견.
 * @parma organizationId 결재 조직 아이디. (조직 결재인 경우만 값 설정).
 * @param designatorId 대리 결재인 경우 대리 결재 지정자 추가 매개 변수. (대리 결재자인 경우만 값 설정)
 * @param approver 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
 */
const reject = createAsyncThunk(
  `${name}/reject`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        approverType: 'organization' | 'employee' | 'surrogater';
        companyId: number;
        id: number;
        updateAt: string;
        opinion?: string;
        // 조직 결재와 대리 결재 값은 둘 중 하나만 설정 가능.
        organizationId?: number;
        designatorId?: number;
        approver?: {
          companyId: number;
          companyName: string;
          organizationId: number;
          organizationName: string;
          employeeId: number;
          employeeName: string;
          jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
          jobClassName: string;
          jobPositionId: number;
          jobPositionName: string;
          jobDutyId: number;
          jobDutyName: string;
        };
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const {
        affiliatedCompanyId,
        opinion,
        organizationId,
        designatorId,
        approver,
      } = data;

      const param = {
        affiliatedCompanyId,
        id: data.id,
        updateAt: data.updateAt,
        opinion,
        organizationId,
        designatorId, // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수.
        // 조직 결재이거나 대리 결재인 경우에만 값 설정.
        approver:
          (organizationId !== undefined || designatorId !== undefined) &&
          approver !== undefined
            ? approver
            : undefined,
      };

      await documentApi.reject(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 발신 문서 반려
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 문서 수정 날짜.
 * @param opinion 결재 의견.
 * @parma organizationId 결재 조직 아이디. (조직 결재인 경우만 값 설정).
 * @param designatorId 대리 결재인 경우 대리 결재 지정자 추가 매개 변수. (대리 결재자인 경우만 값 설정)
 * @param approver 결재자 데이터. (조직 결재이거나 대리 결재인 경우에만 값 설정)
 */
const rejectOutgoingDocument = createAsyncThunk(
  `${name}/rejectOutgoingDocument`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
        // 조직 결재와 대리 결재 값은 둘 중 하나만 설정 가능.
        organizationId?: number;
        designatorId?: number;
        approver?: {
          companyId: number;
          companyName: string;
          organizationId: number;
          organizationName: string;
          employeeId: number;
          employeeName: string;
          jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
          jobClassName: string;
          jobPositionId: number;
          jobPositionName: string;
          jobDutyId: number;
          jobDutyName: string;
        };
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const {
        affiliatedCompanyId,
        id,
        opinion,
        organizationId,
        designatorId,
        approver,
        updateAt,
      } = data;

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
        organizationId,
        designatorId, // 대리 결재인 경우 대리 결재 지정자 추가 매개 변수.
        // 조직 결재이거나 대리 결재인 경우에만 값 설정.
        approver:
          (organizationId !== undefined || designatorId !== undefined) &&
          approver !== undefined
            ? approver
            : undefined,
      };

      await documentApi.rejectOutgoingDocument(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 회수
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 문서 수정 날짜.
 * @param opinion 결재 의견.
 */
const withdraw = createAsyncThunk(
  `${name}/withdraw`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const { affiliatedCompanyId, id, updateAt, opinion } = data;

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
      };

      await documentApi.withdraw(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 대면
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 수정 일자.
 * @param opinion 결재 의견.
 * @param meetingEmployees 대면 직원.
 */
const meet = createAsyncThunk(
  `${name}/meet`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
        meetingEmployees?: {
          companyId: number; // 직원 회사 아이디.
          id: number; // 직원 아이디.
        }[];
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const { affiliatedCompanyId, id, updateAt, opinion, meetingEmployees } =
        data;

      if (meetingEmployees === undefined)
        throw new Error('meetingEmployees is not find');

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
        meetingEmployees,
      };

      await documentApi.meet(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 *보류
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 수정 일자.
 * @param opinion 결재 의견.
 */
const holdOn = createAsyncThunk(
  `${name}/holdOn`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const { affiliatedCompanyId, id, updateAt, opinion } = data;

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
      };

      await documentApi.holdOn(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 이전 승인 취소
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 수정 일자.
 * @param opinion 결재 의견.
 */
const cancelPreviousApprobation = createAsyncThunk(
  `${name}/cancelPreviousApprobation`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const { affiliatedCompanyId, id, updateAt, opinion } = data;

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
      };

      await documentApi.cancelPreviousApprobation(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 승인취소(결재 취소)
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 수정 일자.
 * @param opinion 결재 의견.
 */
const cancelApprobation = createAsyncThunk(
  `${name}/cancelApprobation`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const { affiliatedCompanyId, id, updateAt, opinion } = data;

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
      };

      await documentApi.cancelApprobation(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 전결
 * @param affiliatedCompanyId 문서 관계사 회사 아이디.
 * @param id 문서 아이디.
 * @param updateAt 문서 수정 날짜.
 * @param opinion 결재 의견.
 */
const approvalAll = createAsyncThunk(
  `${name}/approvalAll`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        updateAt: string;
        opinion?: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const { affiliatedCompanyId, id, updateAt, opinion } = data;

      const param = {
        affiliatedCompanyId,
        id,
        updateAt,
        opinion,
      };

      await documentApi.approvalAll(param);

      // 라우트 매개 변수가 있는 경우. (분할 창인 경우에만 값이 있음)
      if (route !== undefined) {
        const {
          pathmap = '/*/:folderId$b62/:id$b62',
          pathname,
          search = '',
        } = route;
        // eslint-disable-next-line no-shadow
        const { folderId, id } = getPathParams<{
          folderId?: number;
          id?: number;
        }>(pathmap, pathname);

        if (folderId === undefined) return undefined;

        await dispatch(findList({ folderId, search }));

        if (id !== undefined) await dispatch(findView({ folderId, id }));
        else dispatch(approvalDocumentReducer.actions.clear());
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

/**
 * 문서 변경.
 */
const change = createAsyncThunk(
  `${name}/change`,
  async (
    arg: {
      data: {
        companyId: number;
        id: number;
        subject: string;
        contents: string;
        attachedFiles?: {
          path: string;
          id: number;
          seq: number;
          name: string;
          size: number;
          delete?: boolean;
        }[];
        attachedDocuments?: {
          companyId: number;
          id: number;
        }[];
        reason: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      await documentApi.change(arg.data);
      const { readingPaneMode } = (getState() as RootState).approval2.document;
      const isSplitView =
        readingPaneMode === 'vertical' || readingPaneMode === 'horizontal';
      if (arg.location && isSplitView) {
        const { folderId } = getPathParams<{ folderId: number }>(
          `/*/:folderId$base62`,
          arg.location.pathname,
        );
        dispatch(
          documentActions.list({ folderId, search: arg.location.search ?? '' }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

interface DocumentReReceiptArg {
  affiliatedCompanyId?: number;
  id: number;
  updateAt: string;
  parentCompanyId: number;
  parentId: number;
  parentUpdateAt: string;
  useUrgent: boolean;
  publicOrNot: boolean;
  no: string;
  subject: string;
  contents: string;
  approvalLine: ApprovalLineType;
  referencePermission: any;
  viewPermission: any;
  attachedFiles?: {
    path: string;
    id: number;
    documentId?: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean; // 재기안 시 기존 파일 체크
  }[];
  attachedDocuments?: {
    companyId: number;
    id: number;
  }[];
  creatorRootOrganizationId: number;
  archiveFolderId: number;
  opinion?: string;
}
/** 반려된 내부문서 - 재접수 */
const reReceipt = createAsyncThunk(
  `${name}/reReceipt`,
  async (
    arg: { param: DocumentReReceiptArg } & LocateArg,
    { rejectWithValue },
  ) => {
    const { param } = arg;
    try {
      const data = {
        affiliatedCompanyId: param.affiliatedCompanyId,
        id: param.id,
        updateAt: param.updateAt,
        parentCompanyId: param.parentCompanyId,
        parentId: param.parentId,
        parentUpdateAt: param.parentUpdateAt,
        importance: param.useUrgent ? 2 : 1,
        publicOrNot: param.publicOrNot,
        no: param.no,
        subject: param.subject,
        contents: param.contents,
        approvalLine: approvalLineToJson(
          approvalLineGenerateId(param.approvalLine),
        ), // json NOT NULL COMMENT '결재선',
        referencePermission:
          param.referencePermission !== undefined
            ? JSON.stringify(param.referencePermission)
            : '{}', // json NOT NULL COMMENT '참조권',
        viewPermission:
          param.viewPermission !== undefined
            ? JSON.stringify(param.viewPermission)
            : '{}', // json NOT NULL COMMENT '조회권',
        attachedDocuments: param.attachedDocuments,
        attachedFiles: param.attachedFiles,
        creatorRootOrganizationId: param.creatorRootOrganizationId,
        archiveFolderId: param.archiveFolderId,
        opinion: param.opinion,
      };

      await documentApi.reReceipt(data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 회수, 임시보관 문서 삭제 */
const listMoveToTrashApproval = createAsyncThunk(
  `${name}/listMoveToTrashApproval`,
  async (
    arg: {
      data: {
        companyId: number;
        id: number;
        folderId: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      await oldApi.ListMoveToTrashApproval({
        data: arg.data,
      });

      if (arg.route) {
        const search =
          arg.route.search ?? (getState() as RootState).session.route.search;
        await dispatch(findList({ folderId: arg.data[0].folderId, search }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

// 반려문서 삭제
const returnDocumentListMoveToTrashApproval = createAsyncThunk(
  `${name}/returnDocumentListMoveToTrashApproval`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        folderId: number; // 반려문서 folderId = 1005
        id: number;
      }[];
    } & LocateArg,
    { getState, dispatch, rejectWithValue },
  ) => {
    const { session } = getState() as RootState;
    try {
      const data = arg.data.map((a) => {
        return {
          affiliatedCompanyId: a.affiliatedCompanyId,
          id: a.id,
        };
      });
      await oldApi.ReturnDocumentListMoveToTrashApproval({ data });
      if (arg.route) {
        const search = arg.route.search ?? session.route.search;
        await dispatch(findList({ folderId: arg.data[0].folderId, search }));
      }
    } catch (ex) {
      if ((ex as ApiError).status === 404 || (ex as ApiError).status === 406) {
        const errorMessage = (ex as ApiError).message || '';
        const error: {
          affiliatedCompanyId?: number;
          id: number;
          reason: string;
        } = JSON.parse(errorMessage);
        return rejectWithValue(appError({ error: error.reason }));
      }
      return rejectWithValue(appError(ex));
    }
  },
);

/** 결재자 결재 유형. */
// const getApproverApprovalType = ({
//   type,
//   arbitraryDecision = false,
//   parallel = false,
//   option = false,
// }: {
//   type: string;
//   arbitraryDecision?: boolean;
//   parallel?: boolean;
//   option?: boolean;
// }) => {
//   // 기안 10
//   // 결재 20
//   // 합의 30
//   // 수신 40
//   // 감사 50

//   // 전결 1
//   // 병렬 2
//   // 선택 3
//   // 병렬+선택 4
//   let result = 0;
//   if (type === 'draft') result = 10;
//   else if (type === 'approval') result = 20;
//   else if (type === 'agree') result = 30;
//   else if (type === 'receive') result = 40;
//   else if (type === 'audit') result = 50;

//   if (arbitraryDecision) result += 1;
//   else if (parallel && option) result += 4;
//   else if (parallel) result += 2;
//   else if (option) result += 3;
//   return result;
// };

/** 결재자. */
// function getApprover(item: {
//   seq: number;
//   companyId: number;
//   organizationId: number;
//   employeeId?: number;
//   jobPositionId?: number;
//   jobDutyId?: number;
//   type: number;
// }) {
//   const {
//     seq,
//     companyId: referenceCompanyId,
//     organizationId,
//     employeeId = 0,
//     jobPositionId = 0,
//     jobDutyId = 0,
//     type,
//   } = item;
//   return {
//     seq,
//     referenceCompanyId,
//     referenceId: employeeId || organizationId,
//     organizationId,
//     employeeId,
//     jobPositionId,
//     jobDutyId,
//     type,
//   };
// }

/** 결재자 배열. */
// function getApprovers(approvalLine: ApprovalLineType) {
//   let approverSeq = 0;
//   const result = approvalLine.groups
//     .map((group) => {
//       const parallel =
//         (group.type === 'agree' || group.type === 'receive') &&
//         group.items.length > 1 &&
//         group.parallel;

//       if ((group.type === 'agree' || group.type === 'receive') && parallel)
//         approverSeq += 1;

//       if (group.type === 'draft') {
//         return group.items.map((item) => {
//           approverSeq += 1;
//           const type = getApproverApprovalType({ type: group.type });
//           return getApprover({ seq: approverSeq, ...item, type });
//         });
//       }
//       if (group.type === 'approval') {
//         return group.items.map((item) => {
//           approverSeq += 1;
//           const arbitraryDecision =
//             item.employeeId === undefined ? undefined : item.arbitraryDecision;
//           const type = getApproverApprovalType({
//             type: group.type,
//             arbitraryDecision,
//           });
//           return getApprover({ seq: approverSeq, ...item, type });
//         });
//       }
//       if (group.type === 'agree') {
//         return group.items.map((item) => {
//           if (!parallel) approverSeq += 1;
//           const { option } = item;
//           const type = getApproverApprovalType({
//             type: group.type,
//             parallel,
//             option,
//           });
//           return getApprover({ seq: approverSeq, ...item, type });
//         });
//       }
//       return group.items.map((item) => {
//         if (!parallel) approverSeq += 1;
//         const type = getApproverApprovalType({ type: group.type, parallel });
//         return getApprover({ seq: approverSeq, ...item, type });
//       });
//     })
//     .flat();

//   return result;
// }

const changeApprovalLine = createAsyncThunk(
  `${name}/changeApprovalLine`,
  async (
    arg: {
      data: {
        affiliatedCompanyId?: number;
        id: number;
        approvalLine: ApprovalLineType;
        reason: string;
        updateAt: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      const approvalLine = approvalLineGenerateId(data.approvalLine);
      await documentApi.fetchChangeApprovalLine({
        affiliatedCompanyId: data.affiliatedCompanyId,
        id: data.id,
        approvalline: approvalLineToJson(approvalLine),
        reason: data.reason,
        updateAt: data.updateAt,
      });

      if (route !== undefined) {
        const isAdmin = route.pathname.startsWith('/adminconsole');

        if (isAdmin) await dispatch(findAdminconsoleView({ id: data.id }));
        else {
          const { p1 } = getPathParams<{ p1?: number }>(
            '/*/:p1$base62',
            route.pathname,
          );
          await dispatch(findView({ folderId: p1 ?? 0, id: data.id }));
        }
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

const changePermission = createAsyncThunk(
  `${name}/changePermission`,
  async (
    arg: {
      data:
        | {
            type: 'referencePermission';
            companyId: number;
            id: number;
            referencePermission: SharePermissionType;
            reason: string;
            contents: string;
            updateAt: string;
          }
        | {
            type: 'viewPermission';
            companyId: number;
            id: number;
            viewPermission: SharePermissionType;
            reason: string;
            contents: string;
            updateAt: string;
          };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      if (data.type === 'referencePermission') {
        const { companyId, id, referencePermission, reason, updateAt } = data;
        const contents = FormBuilder.binding({
          html: data.contents,
          data: { referencePermission },
          getMacro,
        });
        await documentApi.fetchChangeReferencePermission({
          companyId,
          id,
          referencePermission: JSON.stringify(referencePermission),
          reason,
          contents,
          updateAt,
        });
      } else {
        const { companyId, id, viewPermission, reason, updateAt } = data;
        const contents = FormBuilder.binding({
          html: data.contents,
          data: { viewPermission },
          getMacro,
        });
        await documentApi.fetchChangeViewPermission({
          companyId,
          id,
          viewPermission: JSON.stringify(viewPermission),
          reason,
          contents,
          updateAt,
        });
      }

      if (route !== undefined) {
        const isAdmin = route.pathname.startsWith('/adminconsole');
        if (isAdmin) await dispatch(findAdminconsoleView({ id: data.id }));
        else {
          const { p1 } = getPathParams<{ p1?: number }>(
            '/*/:p1$base62',
            route.pathname,
          );
          await dispatch(findView({ folderId: p1 ?? 0, id: data.id }));
        }
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(approval.typePrefix, arg, getState);
    },
  },
);

const adminconsoleList = createAsyncThunk(
  `${name}/adminconsoleList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const queryParams = getQueryParams(arg.search);
      const { searchCode, searchWord } = queryParams;
      const params =
        searchCode && searchWord
          ? {
              advancedSearch: {
                [searchCode.toLowerCase()]: searchWord,
              },
              state:
                queryParams.status !== undefined
                  ? getStateWord(queryParams.status)
                  : 'ALL',
              pageno: queryParams.pageNo ?? 1,
              rowsperpage: queryParams.rowsPerPage ?? 15,
            }
          : {
              advancedSearch: getAdvancedSearchParams(arg.search),
              state:
                queryParams.status !== undefined
                  ? getStateWord(queryParams.status)
                  : 'ALL',
              pageno: queryParams.pageNo ?? 1,
              rowsperpage: queryParams.rowsPerPage ?? 15,
            };
      let totalCount = 0;
      const response = await documentApi.fetchAdminconsoleList({ ...params });
      if (response.count === undefined || response.count === null) {
        totalCount = await documentApi.fetchAdminconsoleTotal({ ...params });
      } else totalCount = response.count;
      const list: AdminconsoleListTemp[] = response.list.map((a) => {
        return {
          affiliatedCompanyId:
            a.affiliatedCompanyId === null ? undefined : a.affiliatedCompanyId,
          id: a.id, // 아이디.
          workName: a.workName, // 업무 이름.
          documentNo: documentMacroReplace(a.number), // 문서 번호
          subject: a.subject, // 제목.
          approvalLine: jsonToApprovalLine(a.approvalline), // 결재선.
          attachments: [], // 첨부 파일 목록.
          opinions: a.opinionCount, // 의견 수.
          comments: a.commentCount, // 댓글 수.
          updateAt: a.updateAt, // 수정일.
          createAt: a.createAt, // 작성일.
          draftAt: a.draftAt,
          no: a.number,
          linkWait: a.linkWait,
          completeAt:
            a.completeAt === '1000-01-01T00:00:00' ? undefined : a.completeAt, // 완료일.
          status: a.status.toString(),
          checked: false, // 체크 여부.
          hasReceipt: false,
          isReceipt: false,
          isOutgoing: false,
        };
      });
      return { list, totalCount };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
);

/** 삭제함 목록 보기. */
const trashList = createAsyncThunk(
  `${name}/trashList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const queryParams = getQueryParams(arg.search);

      const response = await approvalTrashApi.documentList({
        pageNo: queryParams.pageNo ?? 1,
        rowsPerPage: queryParams.rowsPerPage ?? 15,
        searchCode:
          queryParams.searchWord !== '' ? queryParams.searchCode : undefined,
        searchWord:
          queryParams.searchWord !== '' ? queryParams.searchWord : undefined,
      });
      const totalCount = await approvalTrashApi.totalCount({
        searchCode:
          queryParams.searchWord !== '' ? queryParams.searchCode : undefined,
        searchWord:
          queryParams.searchWord !== '' ? queryParams.searchWord : undefined,
      });
      const list: AdminconsoleList[] = response.map((a) => {
        return {
          checked: false,
          companyId: a.companyId,
          id: a.id,
          workName: a.workName,
          status: a.status,
          importance: a.importance,
          no: a.no,
          subject: a.subject,
          approvalLine: jsonToApprovalLine(a.approvalLine),
          draftAt: a.draftedAt,
          completeAt: a.completedAt,
          updateAt: a.updateAt,
          deleterId: a.deleterId,
          hasReceipt: a.hasReceipt,
          isReceipt: a.isReceipt,
          isOutgoing: a.isOutgoing,
        };
      });

      return { list, totalCount: totalCount.totalCount };
    } catch (e) {
      return rejectWithValue(appError(e));
    }
  },
);

/** 삭제함 문서 보기. */
const trashView = createAsyncThunk(
  `${name}/trashView`,
  async (
    arg: {
      affiliatedcompanyid?: number;
      id: number;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { session } = getState() as RootState;

      const { companyId } = session.principal;

      // const queryParams = getQueryParams(arg.route?.search ?? '');
      // const affiliatedCompanyId = queryParams.affiliatedCompanyId
      //   ? parseInt(queryParams.affiliatedCompanyId, 10)
      //   : undefined;

      let data: TrashView | null | undefined;
      let _response_id_: number | undefined;
      data = null;

      const { affiliatedcompanyid, id } = arg;

      if (typeof id !== 'number')
        return rejectWithValue({
          error: 'error',
          path: '',
          status: 500,
          timestamp: '',
        });

      if (arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      let parentResponse:
        | {
            companyId: number;
            id: number;
            approvalLine: string;
            updateAt: string;
          }
        | undefined;

      const response = await approvalTrashApi.documentView({
        affiliatedcompanyid,
        id,
      });

      if (response) {
        let prev: { affiliatedCompanyId?: number; id: number } | undefined;
        let next: { affiliatedCompanyId?: number; id: number } | undefined;

        const rootState = getState() as RootState;

        const { list, totalCount } = rootState.approval2.document.trash;
        const { search } = rootState.session.route;
        const index = list.findIndex((a) => a.id === id);
        // 목록에 해당 아이디가 없는 경우. (보기에서 다음, 이전으로 이동되어 목록에 없는 아이디로 넘어간 경우)
        if (index === -1) {
          const { searchCode, searchWord } = getQueryParams(search);
          const param = {
            id,
            searchCode,
            searchWord,
          };
          prev = await approvalTrashApi.prevId(param);
          next = await approvalTrashApi.nextId(param);
        } else if (index === 0) {
          const { pageNo = 1, searchCode, searchWord } = getQueryParams(search);

          const param = {
            id,
            pageNo,
            searchCode,
            searchWord,
          };
          next = await approvalTrashApi.nextId(param);
          prev = getDocumentId(list[1]);
        } else if (index === list.length - 1) {
          next = getDocumentId(list[index - 1]);
          const {
            pageNo = 1,
            rowsPerPage = 15,
            searchCode,
            searchWord,
          } = getQueryParams(search);
          if (pageNo * rowsPerPage < totalCount) {
            prev = await approvalTrashApi.prevId({
              id,
              searchCode,
              searchWord,
            });
          }
        } else {
          next = getDocumentId(list[index - 1]);
          prev = getDocumentId(list[index + 1]);
        }

        // 첨부파일 사용 1 0000 0001
        // 첨부파일 필수 2 0000 0010
        // 첨부문서 사용 4 0000 0100
        // 첨부문서 필수 8 0000 1000
        // 의견 사용    16 0001 0000
        // 의견 필수    32 0010 0000
        // 댓글 사용    64 0100 0000
        const { option } = response;

        // eslint-disable-next-line no-bitwise
        const useOpinion = option ? (option & 16) === 16 : false; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
        // eslint-disable-next-line no-bitwise
        const useComment = option ? (option & 64) === 64 : false; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

        const { attachedSharedFileCount, attachedFile, attachedDocument } =
          response;

        const opinions = response.opinions
          ? await documentApi.fetchOpinionList(companyId, id)
          : [];
        const comments = response.comments
          ? await documentApi.fetchCommentList(companyId, id)
          : [];

        let parentStatus: number | undefined;
        let parentApprovalLine: ApprovalLineType | undefined;
        let parentAffiliatedCompanyId: number | undefined;
        let parentDrafteAt: string | undefined;
        let parentCompleteAt: string | undefined;
        const originDraftAt =
          draftAt(jsonToApprovalLine(response.approvalLine)) ??
          response.draftedAt;
        const originCompleteAt = getCompleteAt(
          jsonToApprovalLine(response.approvalLine),
        );
        if (response.parentCompanyId && response.parentId) {
          parentResponse = await documentApi.fetchApprovalLine(
            response.parentCompanyId,
            response.parentId,
          );
          if (parentResponse) {
            const approvalLine = jsonToApprovalLine(
              parentResponse.approvalLine,
            );
            parentApprovalLine = approvalLine;
            parentStatus = isProgress(approvalLine) === true ? 1 : undefined;
            parentAffiliatedCompanyId = getAffiliatedCompanyId(
              parentResponse.companyId,
              companyId,
            );
            parentDrafteAt = getApprovalLineDrafter(approvalLine)?.actAt;
            parentCompleteAt = getCompleteAt(approvalLine);
          }
        }

        const attachedFiles: AttachFile[] = [];
        if (attachedFile > 0) {
          const attachedList = await documentApi.fetchAttachfileList(id);
          const attachedUrlList = await documentApi.fetchAttachfileURLList(id);

          attachedList.forEach((x) => {
            const attachedURL = attachedUrlList.find((a) => x.id === a.id);
            attachedFiles.push({
              ...x,
              url: attachedURL?.url,
              isFileprotection: attachedURL?.isFileprotection,
            });
            attachedFiles.sort(
              (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
            );
          });
        }

        let sharedFiles: SharedFile[] = [];
        if (attachedSharedFileCount > 0) {
          sharedFiles = await documentApi.fetchSharedFileList(id);
        }
        data = {
          ...response,
          content: response.contents,
          parentStatus,
          parentApprovalLine,
          parentAffiliatedCompanyId,
          parentUpdateAt: parentResponse?.updateAt,
          parentDrafteAt: parentDrafteAt
            ? dateTimeFormat(parentDrafteAt, 'YYYY-MM-DD')
            : undefined,
          parentCompleteAt: parentCompleteAt
            ? dateTimeFormat(parentCompleteAt, 'YYYY-MM-DD')
            : undefined,
          approvalLine: jsonToApprovalLine(response.approvalLine),
          draftAt: dateTimeFormat(originDraftAt, 'yyyy-MM-DD'),
          completeAt: originCompleteAt
            ? dateTimeFormat(originCompleteAt, 'yyyy-MM-DD')
            : undefined,
          attachedFiles,
          sharedFiles,
          attachedDocuments:
            attachedDocument > 0
              ? await documentApi.fetchAttachdocumentList(id)
              : undefined,
          opinions: useOpinion ? opinions : undefined,
          comments: useComment ? comments : undefined,
          prev,
          next,
        };
      }

      return { data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 페이지 문서 삭제 */
const updateDocument = createAsyncThunk(
  `${name}/listMoveToTrashApproval`,
  async (
    arg: {
      data:
        | {
            affiliatedCompanyId?: number;
            id: number;
            updateAt: string;
          }
        | {
            affiliatedCompanyId?: number;
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { data } = arg;
      if (Array.isArray(data)) {
        const result = await approvalTrashApi.moveDocument({
          data: arg.data,
        });
        return result.length;
      }
      await approvalTrashApi.moveDocument({ data: arg.data });
      return undefined;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 페이지 문서 완전 삭제 */
const deleteDocument = createAsyncThunk(
  `${name}/deleteDocument`,
  async (
    arg: {
      data:
        | {
            affiliatedCompanyId?: number;
            id: number;
            updateAt: string;
          }
        | {
            affiliatedCompanyId?: number;
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (Array.isArray(arg.data)) {
        const result = await approvalTrashApi.deleteDocument({
          data: arg.data,
        });
        if (result.length !== arg.data.length) {
          if (arg.route) {
            return rejectWithValue(
              appError({
                error: '일부 문서는 삭제 실패하였습니다.',
              }),
            );
          }
        }
      } else await approvalTrashApi.deleteDocument({ data: arg.data });
      if (arg.route !== undefined) {
        await dispatch(trashList({ search: arg.route.search ?? '' }));
      } else await dispatch(trashList({ search: '' }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 관리자 페이지 문서 복원 */
const restoreDocument = createAsyncThunk(
  `${name}/restoreDocument`,
  async (
    arg: {
      data:
        | { affiliatedCompanyId?: number; id: number; updateAt: string }
        | {
            affiliatedCompanyId?: number;
            id: number;
            updateAt: string;
          }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      if (Array.isArray(arg.data)) {
        const result = await approvalTrashApi.restoreDocument({
          data: arg.data,
        });
        if (result.length !== arg.data.length) {
          if (arg.route) {
            return rejectWithValue(
              appError({
                error: '일부 문서는 복원 실패하였습니다.',
              }),
            );
          }
        }
      } else {
        await approvalTrashApi.restoreDocument({
          data: arg.data,
        });
      }
      if (arg.route) {
        await dispatch(trashList({ search: arg.route.search ?? '' }));
      } else await dispatch(trashList({ search: '' }));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const approvalDocumentReducer = createSlice({
  name,
  initialState,
  reducers: {
    printViewClear(state) {
      state.printView.data = undefined;
    },
    setReadingPaneMode(state, action: PayloadAction<ReadingPaneMode>) {
      if (state.readingPaneMode !== action.payload) {
        state.readingPaneMode = action.payload;

        if (state.readingPaneMode === 'list') {
          state.view.meta = { lastUpdateAt: '', timestamp: 0 };
          state.view.data = undefined;
        }
      }
    },
    checked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (state.list.data) {
        if (action.payload.itemId === 'all') {
          state.list.data.items = state.list.data.items.map((x) => {
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
        } else {
          const index = state.list.data.items.findIndex(
            (x) => x.id === action.payload.itemId,
          );
          if (index > -1) {
            state.list.data.items[index] = {
              ...state.list.data.items[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    adminconsoleChecked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (state.adminconsole.list) {
        if (action.payload.itemId === 'all') {
          state.adminconsole.list = state.adminconsole.list.map((x) => {
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
        } else {
          const index = state.adminconsole.list.findIndex(
            (x) => x.id === action.payload.itemId,
          );
          if (index > -1) {
            state.adminconsole.list[index] = {
              ...state.adminconsole.list[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    trashChecked(
      state,
      action: PayloadAction<{ itemId: number | 'all'; checked: boolean }>,
    ) {
      if (state.trash.list) {
        if (action.payload.itemId === 'all') {
          state.trash.list = state.trash.list.map((x) => {
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
        } else {
          const index = state.trash.list.findIndex(
            (x) => x.id === action.payload.itemId,
          );
          if (index > -1) {
            state.trash.list[index] = {
              ...state.trash.list[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    clear(state) {
      state.view.meta = { lastUpdateAt: '', timestamp: 0 };
      state.view.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findView.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.data !== undefined) {
          state.view.data = payload.data;
        }
      })
      .addCase(findAdminconsoleView.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.data !== undefined) {
          state.adminconsole.view.data = payload.data;
        }
      })
      .addCase(linkedView.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.data !== undefined) {
          state.view.data = payload.data;
        }
      })
      .addCase(printView.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.data !== undefined) {
          state.printView.data = payload.data;
        }
      })
      .addCase(saveComment.fulfilled, (state, { payload }) => {
        if (payload === undefined) return;
        const { isAdmin, isCreate, data } = payload;

        // 사용자일 때
        if (!isAdmin) {
          if (isCreate) {
            state.list.data.items = state.list.data.items.map((x) => {
              if (x.id === payload.data.documentId)
                return {
                  ...x,
                  comments: x.comments + 1,
                };
              return x;
            });
          }
          state.view.data = state.view.data &&
            state.view.data.comments && {
              ...state.view.data,
              comments: isCreate
                ? [...state.view.data.comments, data]
                : state.view.data.comments.map((x) =>
                    x.id === data.id ? data : x,
                  ),
            };
        }
        // 관리자일 때
        else {
          if (payload.isCreate) {
            state.adminconsole.list = state.adminconsole.list.map((x) => {
              if (x.id === payload.data.documentId)
                return {
                  ...x,
                  comments: x.comments + 1,
                };
              return x;
            });
          }
          state.adminconsole.view.data = state.adminconsole.view.data &&
            state.adminconsole.view.data.comments && {
              ...state.adminconsole.view.data,
              comments: isCreate
                ? [...state.adminconsole.view.data.comments, data]
                : state.adminconsole.view.data.comments.map((x) =>
                    x.id === data.id ? data : x,
                  ),
            };
        }
      })
      .addCase(deleteComment.fulfilled, (state, { payload }) => {
        if (payload === undefined) return;
        const { isAdmin, data, isReply } = payload;
        // 사용자일 때
        if (!isAdmin) {
          state.list.data.items = state.list.data.items.map((x) => {
            if (x.id === payload.data.documentId)
              return {
                ...x,
                comments: x.comments - 1,
              };
            return x;
          });
          state.view.data = state.view.data &&
            state.view.data.comments && {
              ...state.view.data,
              comments: isReply
                ? state.view.data.comments.filter((x) => x.id !== data.id)
                : state.view.data.comments.map((x) => {
                    if (x.id === data.id) return data;
                    return x;
                  }),
            };
        }
        // 관리자일 때
        else {
          state.adminconsole.list = state.adminconsole.list.map((x) => {
            if (x.id === data.documentId)
              return {
                ...x,
                comments: x.comments - 1,
              };
            return x;
          });
          state.adminconsole.view.data = state.adminconsole.view.data &&
            state.adminconsole.view.data.comments && {
              ...state.adminconsole.view.data,
              comments: isReply
                ? state.adminconsole.view.data.comments.filter(
                    (x) => x.id !== data.id,
                  )
                : state.adminconsole.view.data.comments.map((x) => {
                    if (x.id === data.id) return data;
                    return x;
                  }),
            };
        }
      })
      .addCase(saveOpinion.fulfilled, (state, { payload }) => {
        if (payload === undefined) return;
        const { isAdmin, isCreate, data } = payload;
        // 사용자일 때
        if (!isAdmin) {
          if (isCreate) {
            state.list.data.items = state.list.data.items.map((x) =>
              x.id === data.documentId
                ? {
                    ...x,
                    opinions: x.opinions + 1,
                  }
                : x,
            );
          }
          state.view.data = state.view.data &&
            state.view.data.opinions && {
              ...state.view.data,
              opinions: isCreate
                ? [...state.view.data.opinions, data]
                : state.view.data.opinions.map((x) =>
                    x.id === data.id ? data : x,
                  ),
            };
        } // 관리자일 때
        else {
          if (isCreate) {
            state.adminconsole.list = state.adminconsole.list.map((x) =>
              x.id === data.documentId ? { ...x, opinions: x.opinions + 1 } : x,
            );
          }

          state.adminconsole.view.data = state.adminconsole.view.data &&
            state.adminconsole.view.data.opinions && {
              ...state.adminconsole.view.data,
              opinions: isCreate
                ? [...state.adminconsole.view.data.opinions, data]
                : state.adminconsole.view.data.opinions.map((x) =>
                    x.id === data.id ? data : x,
                  ),
            };
        }
      })
      .addCase(deleteOpinion.fulfilled, (state, { payload }) => {
        if (payload === undefined) return;
        const { isAdmin, data } = payload;
        // 사용자 페이지일 때
        if (!isAdmin) {
          state.list.data.items = state.list.data.items.map((a) => {
            if (a.id === data.documentId)
              return {
                ...a,
                opinions: a.opinions - 1,
              };
            return a;
          });

          if (state.view.data?.opinions) {
            state.view.data.opinions = state.view.data.opinions.filter(
              (x) => x.id !== payload.data.id,
            );
          }
        } // 관리자 페이지일 때
        else {
          state.adminconsole.list = state.adminconsole.list.map((a) => {
            if (a.id === data.documentId)
              return {
                ...a,
                opinions: a.opinions - 1,
              };
            return a;
          });

          if (state.adminconsole.view.data?.opinions) {
            state.adminconsole.view.data.opinions =
              state.adminconsole.view.data.opinions.filter(
                (x) => x.id !== data.id,
              );
          }
        }
      })
      .addCase(fetchHistoryList.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.data !== undefined) {
          state.historys = payload.data;
        }
      })
      .addCase(findList.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload.meta !== undefined)
            state.list.meta = action.payload.meta;
          if (action.payload.data !== undefined)
            state.list.data = action.payload.data;

          if (action.payload.meta !== undefined) {
            const cacheListName = getCacheListName(
              action.meta.arg.folderId,
              action.meta.arg.search,
            );
            const pageno = `${getPageNo(action.meta.arg.search)}`;
            const init = {
              lastUpdateAt: action.payload.meta.lastUpdateAt,
              totalCount: action.payload.data?.totalCount || 0,
              page: {
                [pageno]: {
                  meta: { timestamp: action.payload.meta.timestamp },
                  data: action.payload.data?.items || null,
                },
              },
            };

            const list = state.cache.list[cacheListName];
            if (
              list === undefined ||
              list?.lastUpdateAt !== action.payload.meta.lastUpdateAt
            ) {
              state.cache.list[cacheListName] = init;
            } else if (list.page[pageno] === undefined)
              list.page[pageno] = init.page[pageno];
          }
        }
      })
      .addCase(adminconsoleList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.adminconsole.list = payload.list;
          state.adminconsole.totalCount = payload.totalCount;
        }
      })
      .addCase(trashList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.trash.list = payload.list;
          state.trash.totalCount = payload.totalCount;
        }
      })
      .addCase(trashView.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.data !== undefined) {
          state.trash.view.data = payload.data;
        }
      })
      .addCase(personalDocumentActions.copy.fulfilled, (state) => {
        state.list.data.items = state.list.data.items.map((a) => {
          return { ...a, checked: false };
        });
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
        // const { payload } = action;
        // if (payload) state.errors = [...state.errors, payload as ApiError];
      });
  },
});

export default approvalDocumentReducer.reducer;

export const documentActions = {
  saveOpinion,
  deleteOpinion,
  saveComment,
  deleteComment,

  fetchHistoryList,
  fetchHistoryView,

  adminconsoleList,
  adminconsoleView: findAdminconsoleView,

  list: findList,
  view: findView,
  linkedView,
  printView,

  checked: approvalDocumentReducer.actions.checked,
  adminconsoleChecked: approvalDocumentReducer.actions.adminconsoleChecked,
  trashChecked: approvalDocumentReducer.actions.trashChecked,
  setReadingPaneMode: approvalDocumentReducer.actions.setReadingPaneMode,
  printViewClear: approvalDocumentReducer.actions.printViewClear,

  create,
  receipt,
  temporary,
  reTemporary,

  createLinked,

  approval,
  rejectOutgoingDocument,
  reject,
  withdraw,
  meet,
  holdOn,
  cancelPreviousApprobation,
  approvalAll,

  cancelApprobation,
  change,
  reReceipt,
  listMoveToTrashApproval,
  returnDocumentListMoveToTrashApproval,
  changeApprovalLine,
  changePermission,

  trashList,
  trashView,
  updateDocument,
  deleteDocument,
  restoreDocument,
};

export { getAffiliatedCompanyId, jsonToApprovalLine };
