import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import PostList, {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';
import AttendanceSearch, {
  SearchDateProps,
} from '../../../../../components/search/AttendanceSearch';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  createQueryString,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import EmptyData from '../../../../../components/data/EmptyData';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { resourcesActions } from '../../../../stores/resources';
import ResourceLookupDialog from '../content/ResourceLookupDialog';
import ResourceReservationListItem from '../list/ResourceReservationListItem';
import { approvalActions } from '../../../../stores/approval';
import Menu from '../../../../../components/menu/Menu';
import MenuItem from '../../../../../components/menu/MenuItem';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';

function ResourceApprovalContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { p1 } = getPathParams<{ p1?: string }>('/*/:p1', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const displayDensity = useSelector(
    (state: RootState) => state.resource.resources.displayDensity,
  );
  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  );
  const list = useSelector((state: RootState) => state.resource.approval.list);
  const totalCount = useSelector(
    (state: RootState) => state.resource.approval.totalCount,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'status');

  const scrollbar = useRef<HTMLDivElement>(null);
  const { pageNo, rowsPerPage, searchCode, searchWord } = queryParams;
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pathname, pageNo, rowsPerPage, searchCode, searchWord]);

  const [state, setState] = useState<{
    filter: boolean;
    filterPoint?: {
      x: number;
      y: number;
      width?: number;
      height?: number;
    };
    id: number;
    type?: string;
  }>({
    filter: false,
    filterPoint: undefined,
    id: 0,
    type: undefined,
  });

  const options = [
    { value: 'folder', label: '폴더명' },
    { value: 'item', label: '자원명' },
    { value: 'subject', label: '제목' },
    { value: 'employee', label: '예약자' },
  ];

  const filterOptions =
    p1 === 'approval'
      ? [
          // eslint-disable-next-line prettier/prettier
          { code: undefined, text: '전체', checked: !queryParams.status },
          // eslint-disable-next-line prettier/prettier
          { code: 'proceeding', text: '대기', checked: queryParams.status === 'proceeding' },
          // eslint-disable-next-line prettier/prettier
          { code: 'done', text: '승인', checked: queryParams.status === 'done' },
          // eslint-disable-next-line prettier/prettier
          { code: 'return', text: '반려', checked: queryParams.status === 'return' },
        ]
      : [
          { code: undefined, text: '전체', checked: !queryParams.status },
          // eslint-disable-next-line prettier/prettier
          { code: 'proceeding', text: '대여', checked: queryParams.status === 'proceeding' },
          // eslint-disable-next-line prettier/prettier
          { code: 'done', text: '반납', checked: queryParams.status === 'done' },
        ];

  const [columns, setColumns] = useState<Column<PostListItemType>[]>([
    { name: 'folderName', text: '폴더명', type: 'folder', visible: true },
    { name: 'itemName', text: '자원명', type: 'folder', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: '제목', type: 'post', visible: true, disable: true },
    { name: 'range', text: '사용기간', type: 'period', visible: true },
    { name: 'useDays', text: '신청일', type: 'date', visible: true },
    { name: 'user', text: '예약자', type: 'user', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'reservation', text: '예약상태', type: 'reservationStatus', visible: true },
    { name: 'status', text: '상태', type: 'reservationStatus', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'reservationStatus', text: '처리', type: 'reservationStatus', visible: true, disable: true },
  ]);

  // 헤드 액션.
  const handleHeadAction = (arg: {
    code: string;
    event: React.MouseEvent<Element, MouseEvent>;
  }) => {
    const { code } = arg;
    // 목록 설정 드로워.
    if (code === 'listSetting')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 이전 페이지
    if (code === 'prevPage') {
      const no = (queryParams.pageNo ?? 1) - 1;
      if (no > 0) {
        if (no > 1) queryParams.pageNo = no;
        else delete queryParams.pageNo;
        go(pathname, queryParams);
      }
    }
    if (code === 'nextPage') {
      const no = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * no) < totalCount) {
        queryParams.pageNo = no;
        go(pathname, queryParams);
      }
    }
  };

  // 컬럼 보이기 변경.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setColumns((prev) =>
      prev.map((x) => (x.name === name ? { ...x, visible } : x)),
    );
  };

  // 화면 밀도 설정.
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(resourcesActions.displayDensity(value));
  };

  // 목록 개수 설정.
  const handleChangeListCount = (value: string) => {
    const rows = parseInt(value, 10);
    go(
      pathname,
      createQueryString(
        {
          pageNo: undefined,
          rowsPerPage: rows === 15 ? undefined : rows,
          drawerMode: undefined,
          drawerType: undefined,
        },
        queryParams,
      ),
    );
  };

  // 드로워 닫기.
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  // 검색.
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    delete queryParams.pageNo;
    queryParams.searchCode = arg.filter;
    queryParams.searchWord = arg.keyword;
    queryParams.startDate = arg.date.start
      ? dateFormat(initialDate(arg.date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = arg.date.end
      ? dateFormat(initialDate(arg.date.end), 'yyyy-MM-DD')
      : undefined;
    queryParams.directoryKeyword = arg.directoryKeyword;
    queryParams.directoryFilter =
      arg.directoryKeyword && arg.keyword !== '' ? 'true' : undefined;
    go(pathname, getQueryParams(queryParams));
  };

  // 리스트 클릭 이벤트.
  const handleListClick = (id: number) => {
    queryParams.dialogType = 'view';
    dispatch(
      resourcesActions.reservationView({
        id,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  const handleApprovalClick = () => {
    const { id, type } = state;
    const approval = list.find((a) => a.id === id);
    if (!approval) return;
    if (type === 'reject' || type === 'approve') {
      dispatch(
        approvalActions.updateApproval({
          type,
          id: approval.id,
          updateAt: approval.updateAt,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined)
          setState((prev) => ({ ...prev, id: 0, type: undefined }));
      });
      return;
    }
    if (type === 'takeback' || type === 'rental') {
      dispatch(
        approvalActions.updateRental({
          type,
          id: approval.id,
          updateAt: approval.updateAt,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined)
          setState((prev) => ({ ...prev, id: 0, type: undefined }));
      });
    }
  };

  const handleApprovalDialogClose = () => {
    setState((prev) => ({
      ...prev,
      id: 0,
      type: undefined,
    }));
  };

  /** 필터 메뉴 닫기. */
  const handleCloseFilter = () => {
    setState((prev) => ({
      ...prev,
      filter: false,
      filterPoint: undefined,
    }));
  };

  /** 필터 메뉴 열기. */
  const handleChangeFilter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.filterPoint) handleCloseFilter();
    else {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      const filterPoint = { x, y, width, height };
      setState((prev) => ({
        ...prev,
        filter: true,
        filterPoint,
      }));
    }
  };

  const handleOptionMenuItemClick = (code?: string) => {
    delete queryParams.pageNo;
    if (code) queryParams.status = code;
    else delete queryParams.status;
    handleCloseFilter();
    go(pathname, getQueryParams(queryParams));
  };
  const listColumns =
    p1 === 'approval'
      ? columns.filter((a) => a.name !== 'reservation')
      : columns;

  const renderDrawer = () => {
    const { drawerType } = queryParams;
    if (drawerType === 'listSetting') {
      return (
        <ListSetting
          type="list"
          propsFolderId={1007}
          columns={listColumns}
          density={displayDensity}
          listCount={`${queryParams.rowsPerPage ?? 15}`}
          onColumnVisibleChange={handleColumnVisibleChange}
          onChangeDensity={handleChangeDensity}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
        />
      );
    }
    return null;
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    if (dialogType === 'view') {
      return (
        <ResourceLookupDialog
          shared
          pathname={pathname}
          search={search}
          hash=""
        />
      );
    }

    if (state.id !== 0 && state.type) {
      let message = '승인';
      if (state.type === 'reject') message = '반려';
      else if (state.type === 'takeback') message = '반납';
      else if (state.type === 'rental') message = '대여';
      return (
        <Dialog size="xs">
          <DialogBody>
            <div className="eui-alert-message eui-confirmation-message">
              요청건을 {message}하시겠습니까?
            </div>
          </DialogBody>
          <DialogFooter>
            <Button text="취소" onClick={handleApprovalDialogClose} />
            <Button
              noDuplication
              text="확인"
              variant="contained"
              onClick={handleApprovalClick}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  };

  let start: Date | null = null;
  let end: Date | null = null;
  if (queryParams.startDate) start = timezoneDate(queryParams.startDate);
  if (queryParams.endDate) end = timezoneDate(queryParams.endDate);
  const title = categories.find((a) => a.id === p1)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceSearch
            directory={!!p1}
            dualSearch={!!p1}
            options={options}
            filter={queryParams.searchCode ?? 'folder'}
            date={{ start, end }}
            keyword={queryParams.searchWord}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          <EuiToolbar.Left>
            <Button iconType icon="filter-list" onClick={handleChangeFilter} />
            {state.filter && state.filterPoint && (
              <Menu point={state.filterPoint} onClose={handleCloseFilter}>
                <div className="context-group">필터</div>
                {filterOptions.map((a, i) => {
                  const {
                    code,
                    text = '',
                    checked = undefined,
                  } = {
                    ...a,
                  };
                  const key = `${i}_${code}`;
                  return (
                    <MenuItem
                      key={key}
                      label={text}
                      onClick={() => handleOptionMenuItemClick(code)}
                      checked={checked}
                    />
                  );
                })}
              </Menu>
            )}
          </EuiToolbar.Left>
          <EuiToolbar.Right>
            {totalCount > 0 && (
              <Pagination
                no={queryParams.pageNo ?? 1}
                rows={queryParams.rowsPerPage ?? 15}
                count={totalCount}
                onPrev={(event) =>
                  handleHeadAction({ code: 'prevPage', event })
                }
                onNext={(event) =>
                  handleHeadAction({ code: 'nextPage', event })
                }
              />
            )}
            {totalCount > 0 && display !== 'phone' && (
              <Divider orientation="vertical" />
            )}
            {display !== 'phone' && (
              <Button
                text="목록설정"
                iconType
                icon="bar-cog"
                onClick={(event) =>
                  handleHeadAction({ code: 'listSetting', event })
                }
              />
            )}
          </EuiToolbar.Right>
        </EuiHeader.Toolbar>
      </EuiHeader>
      <EuiBody>
        <PostList
          name="reservation"
          type="full"
          density={displayDensity}
          style={{ minWidth: display === 'tablet' ? '950px' : undefined }}
        >
          <PostList.Head>
            {listColumns
              .filter(({ visible }) => visible)
              .map(({ name, type: colunmType, text }) => (
                <PostList.Cell
                  key={name}
                  name={text}
                  type={colunmType}
                  // eslint-disable-next-line prettier/prettier
                  style={name === 'status' && p1 === 'return' ? { width: '60px' } : undefined}
                  align={colunmType !== 'post' ? 'center' : undefined}
                />
              ))}
          </PostList.Head>
          <PostList.Body scrollableNodeProps={scrollbar}>
            {list.length === 0 ? (
              <EmptyData />
            ) : (
              list.map((a, i) => {
                const directoryData = getDirectoryData({
                  ...directory,
                  companyId: a.companyId,
                  employeeId: a.employeeId,
                });
                const folderName =
                  folders.find(({ id }) => a.folderId === id)?.name ?? '';
                const data = items.find(({ id }) => a.itemId === id);
                let status = 'proceeding';
                if (p1 === 'approval') {
                  if (a.isApproval === 1) status = 'done';
                  else if (a.isApproval === 2) status = 'return';
                }
                const reservationStatus = {
                  useApprove: data?.useApprove,
                  status: a.isApproval,
                  takeback: a.isTakeback ?? false,
                };
                const range = `${dateTimeFormat(
                  a.startDateTime,
                  'yyyy-MM-DD HH:mm',
                )} ~ ${dateTimeFormat(a.endDateTime, 'yyyy-MM-DD HH:mm')}`;
                const useDays = dateTimeFormat(a.createAt, 'yyyy-MM-DD');
                const item = {
                  folderName,
                  itemName: data?.name ?? '',
                  subject: a.name,
                  range,
                  useDays,
                  user: {
                    name: directoryData.employeeName,
                    organization: directoryData.organizationName,
                    job: directoryData.jobPositionName,
                    avatar: directoryData.avatar ?? '',
                  },
                  status,
                  reservationStatus: JSON.stringify(reservationStatus),
                };
                const key = `/i${i}/${a.id}`;
                if (p1 === 'return') {
                  let returnType = 'proceeding';
                  if (a.isApproval === 1) returnType = 'done';
                  else if (a.isApproval === 2) returnType = 'return';
                  if (a.isTakeback) status = 'done';
                  const returnItem = {
                    folderName,
                    itemName: data?.name ?? '',
                    subject: a.name,
                    range,
                    useDays,
                    user: {
                      name: directoryData.employeeName,
                      organization: directoryData.organizationName,
                      job: directoryData.jobPositionName,
                      avatar: directoryData.avatar ?? '',
                    },
                    reservation: returnType,
                    status,
                    reservationStatus: JSON.stringify(reservationStatus),
                  };
                  return (
                    <ResourceReservationListItem
                      key={key}
                      align
                      menu={p1}
                      id={a.id}
                      columns={listColumns.filter(({ visible }) => visible)}
                      item={returnItem}
                      onClick={handleListClick}
                      onApprovalClick={(id, type) =>
                        setState((prev) => ({ ...prev, id, type }))
                      }
                    />
                  );
                }
                return (
                  <ResourceReservationListItem
                    key={key}
                    align
                    menu={p1}
                    id={a.id}
                    columns={listColumns.filter(({ visible }) => visible)}
                    item={item}
                    onClick={handleListClick}
                    onApprovalClick={(id, type) =>
                      setState((prev) => ({ ...prev, id, type }))
                    }
                  />
                );
              })
            )}
          </PostList.Body>
        </PostList>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default ResourceApprovalContainer;
