import { useEffect } from 'react';
import { resourceReload } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function ApprovalApproverProxyRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const route = { pathname, search, hash, clearErrors: true };
    resourceReload(['ko-KR'], ['directory', 'jobclass', 'approval']);
    dispatch(sessionActions.setRoute(route));
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default ApprovalApproverProxyRoute;
