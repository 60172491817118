import axios from 'axios';
import {
  apiError,
  EntityKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  ResourceFolderItem,
  ResourceItem,
  ResourceListItem,
} from '../../../../stores/folders';

/** 자원 아이템 폴더 리스트 조회. */
async function findFolderList(
  parentId?: number,
): Promise<ResourceFolderItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/folder/all`;
    const response = await axios.get(url, {
      headers,
      params: { parentId },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 폴더 등록. */
async function createFolder(arg: {
  parentId: number;
  name: string;
  description: string;
}): Promise<ResourceFolderItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 폴더 수정. */
async function updateFolder(arg: {
  id: number;
  parentId: number;
  name: string;
  description: string;
  updateAt: string;
}): Promise<EntityKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 폴더 삭제. */
async function deleteFolder(arg: {
  id: number;
  updateAt: string;
}): Promise<EntityKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 폴더 순서 변경. */
async function sortFolder(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<ResourceFolderItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 리스트 조회. */
async function findItemList(folderId?: number): Promise<ResourceListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item/summary/all`;
    const response = await axios.get(url, {
      headers,
      params: { folderId },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 사용자 리스트 조회. */
async function finduserItemList(arg: {
  organizationId: number;
  employeeId: number;
}): Promise<ResourceListItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item/summary/authority/all`;
    const response = await axios.get(url, {
      headers,
      params: arg,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 뷰 조회. */
async function findItemView(arg: {
  id: number;
  updateAt?: string;
}): Promise<ResourceItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item/full`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 등록. */
async function createItem(arg: {
  folderId: number;
  name: string;
  status: boolean;
  useApprove: boolean;
  useRental: boolean;
  useTimeAvailable: boolean;
  availableFromTime: string;
  availableToTime: string;
  description: string;
  managers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    lookupDeleteAt?: string; // 삭제된 객체에 한하여 updateAt을 넣어주면 됩니다.
  }[];
  users: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    lookupDeleteAt?: string; // 삭제된 객체에 한하여 updateAt을 넣어주면 됩니다.
  }[];
  exceptioners: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    lookupDeleteAt?: string; // 삭제된 객체에 한하여 updateAt을 넣어주면 됩니다.
  }[]; // 예외자.
  image?: string;
}): Promise<ResourceListItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 수정. */
async function updateItem(arg: {
  companyId: number; // 회사 아이디.
  id: number; // 자원 아이템 아이디.
  folderId: number; // 폴더 아이디.
  name?: string; // 자원명.
  status?: boolean; // 사용여부.
  seq?: number; // 순번.
  useApprove?: boolean; // 승인 사용 여부. (true: 승인제, false: 예약제)
  useRental?: boolean; // 대여/반납 사용 여부.
  useTimeAvailable?: boolean; // 사용 시간 제한 여부.
  description?: string; // 설명.
  availableFromTime?: string; // 사용 가능 시작 시간.
  availableToTime?: string; // 사용 가능 종료 시간.
  managers?: {
    referenceCompanyId: number; // 사용자 회사 아이디.
    referenceId: number; // 사용자 아이디.
    referenceType: number; // 참조 유형 (1: 회사, 2: 조직, 3: 직원)
    updateAt?: string;
    lookupDeleteAt?: string;
  }[]; // 담당자.
  users?: {
    referenceCompanyId: number; // 사용자 회사 아이디.
    referenceId: number; // 사용자 아이디.
    referenceType: number; // 참조 유형 (1: 회사, 2: 조직, 3: 직원)
    updateAt?: string;
    lookupDeleteAt?: string;
  }[]; // 사용자.
  exceptioners?: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    lookupDeleteAt?: string; // 삭제된 객체에 한하여 updateAt을 넣어주면 됩니다.
  }[]; // 예외자.
  image?: string;
  updateAt: string; // 수정 날짜.
}): Promise<EntityKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 삭제. */
async function deleteItem(arg: {
  id: number;
  updateAt: string;
}): Promise<EntityKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 자원 아이템 순서 변경. */
async function sortItem(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<ResourceFolderItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/resource/v1/item/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const folderApi = {
  list: findFolderList,
  create: createFolder,
  update: updateFolder,
  delete: deleteFolder,
  sort: sortFolder,
};

const itemApi = {
  list: findItemList,
  userList: finduserItemList,
  view: findItemView,
  create: createItem,
  update: updateItem,
  delete: deleteItem,
  sort: sortItem,
};

export { itemApi };
export default folderApi;
