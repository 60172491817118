import React from 'react';
import ApprovalDashboard from './ApprovalDashboard';

function ApprovalDashboardContainer(props: { pathname: string }): JSX.Element {
  // console.log(`${ApprovalDashboardContainer.name}.render(props)`, props);

  return <ApprovalDashboard pathname={props.pathname} />;
}

export default ApprovalDashboardContainer;
