import React, { useRef } from 'react';
import CheckboxGroup from '../checkbox/CheckboxGroup';
import Checkbox from '../checkbox/Checkbox';
import Dialog from '../dialog/Dialog';
import Button from '../button/Button';

export type PrintClickProps = 'prev' | 'next' | 'close';

function Print(props: {
  loading?: boolean; // 페이징 처리 시 loading true일 경우 페이징버튼 비활성화 false 또는 undefined일 경우 페이징버튼 활성화
  pageNo: number;
  totalPageCount: number;
  option: {
    value: string;
    label: string;
    checked: boolean;
  }[];
  onChangeOption(
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ): void;
  onClick(code: PrintClickProps): void;
  children: React.ReactNode;
  onHistory?(): void;
}): JSX.Element {
  const { pageNo, totalPageCount, option, onChangeOption, onClick, children } =
    props;
  document.documentElement.setAttribute('data-print', 'dialog');
  const printRef = useRef<HTMLDivElement>(null);

  const handleBeforePrint = () => {
    if (printRef.current !== null) {
      const viewContent = printRef.current.querySelector('.view-content');
      if (viewContent) viewContent.setAttribute('style', `padding:0;`);
    }
  };
  const handleAfterPrint = () => {
    if (printRef.current !== null) {
      const viewContent = printRef.current.querySelector('.view-content');
      if (viewContent) viewContent.removeAttribute('style');
    }
  };

  const handlePrint = () => {
    if (props.onHistory) props.onHistory();
    window.onbeforeprint = handleBeforePrint;
    window.onafterprint = handleAfterPrint;
    window.print();
  };

  const handleClose = () => {
    document.documentElement.removeAttribute('data-print');
    onClick('close');
  };

  return (
    <Dialog overflow="hidden">
      <div className="ui-print">
        <div className="print-toolbar">
          <div className="print-option">
            <CheckboxGroup>
              {option.map((x) => (
                <Checkbox
                  key={x.value}
                  label={x.label}
                  checked={x.checked}
                  onChange={(event) => onChangeOption(event, x.value)}
                />
              ))}
            </CheckboxGroup>
          </div>
          <div className="print-action">
            <div className="print-pagination">
              <span className="page-count">
                <em>{pageNo}</em>
                <span> / </span>
                <strong>{totalPageCount}</strong>
              </span>
              <Button
                text="이전"
                className="page-action"
                iconType
                icon="chevron-left"
                size="sm"
                disabled={pageNo === 1 || props.loading}
                onClick={() => onClick('prev')}
              />
              <Button
                text="다음"
                className="page-action"
                iconType
                icon="chevron-right"
                size="sm"
                disabled={pageNo === totalPageCount || props.loading}
                onClick={() => onClick('next')}
              />
            </div>
            <Button
              text="인쇄"
              variant="contained"
              size="sm"
              onClick={handlePrint}
            />
            <Button
              text="닫기"
              iconType
              icon="close"
              onClick={handleClose}
              size="sm"
            />
          </div>
        </div>
        <div className="print-content" ref={printRef}>
          {children}
        </div>
      </div>
    </Dialog>
  );
}

export default Print;
