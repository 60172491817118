import { useEffect } from 'react';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { preferencesActions } from '../../../stores/calendar/preferences';

function CalendarPreferencesRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    dispatch(preferencesActions.findAdministrators()).then(() => {
      if (mount) dispatch(sessionActions.setRoute({ pathname, search }));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default CalendarPreferencesRoute;
