import React, { ForwardedRef, useMemo, useState } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Icon from '../icon/Icon';
import { IconType } from '../../groupware-common/types/icon';

type TextFieldType =
  | 'text'
  | 'tel'
  | 'search'
  | 'email'
  | 'hidden'
  | 'number'
  | 'date'
  | 'time'
  | 'color'
  | 'password'
  | 'file';

const TextField = React.forwardRef(
  (
    props: {
      label?: string;
      variant?: 'filled' | 'outlined';
      align?: 'left' | 'center' | 'right';
      type?: TextFieldType;
      multiline?: boolean;
      value: string;
      name?: string;
      size?: 'sm';
      placeholder?: string;
      title?: string;
      icon?: IconType;
      required?: boolean;
      readonly?: boolean;
      disabled?: boolean;
      error?: boolean;
      cols?: number;
      rows?: string | number;
      rowsMax?: string | number;
      rowsMin?: string | number;
      clear?: boolean;
      className?: string;
      minLength?: number;
      maxLength?: number;
      count?: boolean;
      width?: number;
      margin?: string;
      pattern?: string;
      copy?: boolean;
      prepend?: React.ReactNode;
      append?: React.ReactNode;
      onFocus?(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void;
      onChange?(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void;
      onKeyDown?(
        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void;
      onClear?(): void;
      onBlur?(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void;
      onCopy?(value: string): void;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    let classname = 'eui-form-field';

    if (props.className) classname += ` ${props.className}`;
    if (props.variant) classname += ` ${props.variant}`;
    if (props.disabled) classname += ' disabled';
    if (props.required) classname += ' required';
    if (props.readonly) classname += ' readonly';
    if (props.size) classname += ` ${props.size}`;
    if (props.error) classname += ' error';
    if (props.align) classname += ` align-${props.align}`;
    if (props.label) classname += ' hasLabel';
    if (props.placeholder) classname += ' hasPlaceholder';
    if (props.clear || props.type === 'password') classname += ' hasAction';
    if (props.prepend) classname += ' hasPrepend';
    if (props.append) classname += ' hasAppend';

    let style: React.CSSProperties | undefined = props.width
      ? { width: `${props.width}px` }
      : undefined;
    if (props.margin)
      style = style
        ? { ...style, margin: props.margin }
        : { margin: props.margin };

    const label: JSX.Element | undefined = props.label ? (
      <label
        className="label"
        data-shrink={
          !!((props.value !== '' && props.value !== undefined) || props.icon)
        }
      >
        {props.label}
      </label>
    ) : undefined;

    const addStart: JSX.Element | undefined = props.prepend ? (
      <span className="add start">{props.prepend}</span>
    ) : undefined;

    const addEnd: JSX.Element | undefined = props.append ? (
      <span className="add end">{props.append}</span>
    ) : undefined;

    const icon: JSX.Element | undefined = props.icon ? (
      <Icon className="badge" icon={props.icon} />
    ) : undefined;

    const [state, setState] = useState<{
      type: TextFieldType;
      showPassword: boolean;
    }>({
      type: props.type !== undefined ? props.type : 'text',
      showPassword: false,
    });

    const handleCopy = () => {
      if (props.onCopy) props.onCopy(props.value);
    };

    const handleShowPassword = () => {
      setState((prevState) => ({
        ...prevState,
        type: prevState.type === 'text' ? 'password' : 'text',
        showPassword: !prevState.showPassword,
      }));
    };

    const showPassword = useMemo(() => {
      if (props.type === 'password') {
        if (props.value === '')
          return <span style={{ width: '28px', height: '100%' }} />;
        return (
          <span
            className={`action visible ${state.showPassword ? 'hide' : 'show'}`}
            onClick={handleShowPassword}
          >
            <span>{state.showPassword ? '숨김' : '표시'}</span>
          </span>
        );
      }
      return undefined;
    }, [props.value, props.type === 'password', state.showPassword]);

    const showClear = useMemo(() => {
      return (
        props.clear &&
        props.value !== '' && (
          <span className="action clear" onClick={props.onClear}>
            <span>지우기</span>
          </span>
        )
      );
    }, [props.value !== '' && props.clear]);

    const showCopy = useMemo(() => {
      return (
        props.copy &&
        props.onCopy &&
        props.value !== '' && (
          <span className="action copy" onClick={handleCopy}>
            <span>
              <span>복사하기</span>
            </span>
          </span>
        )
      );
    }, [props.value !== '' && props.copy]);

    const handleInvalid = (event: React.FormEvent<HTMLInputElement>) => {
      const title = event.currentTarget.getAttribute('title');
      if (title && event.currentTarget.validity.patternMismatch) {
        event.currentTarget.setCustomValidity(title);
      } else {
        event.currentTarget.setCustomValidity('');
      }
      return true;
    };

    return (
      <>
        <div className={classname} style={style}>
          {props.multiline ? (
            <label className="wrap">
              {label}
              {icon}
              <TextareaAutosize
                cols={props.cols}
                rows={props.rows}
                rowsMin={props.rowsMin}
                rowsMax={props.rowsMax}
                aria-label="empty textarea"
                className="control"
                name={props.name}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
                minLength={props.minLength}
                maxLength={props.maxLength}
                disabled={props.disabled}
                readOnly={props.readonly}
                required={props.required}
                title={props.title}
              />
            </label>
          ) : (
            <>
              {addStart}
              {label}
              {icon}
              <input
                ref={ref}
                type={state.type}
                disabled={props.disabled}
                required={props.required}
                className="control"
                name={props.name}
                readOnly={props.readonly}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
                onInvalid={handleInvalid}
                onInput={handleInvalid}
                minLength={props.minLength}
                maxLength={props.maxLength}
                pattern={props.pattern}
                title={props.title}
              />
              {addEnd}
              {showPassword}
              {showClear}
              {showCopy}
            </>
          )}
          {props.count && (
            <div className="count">
              <em>{props.value?.length}</em>
              <span>/</span>
              <strong>{props.maxLength}</strong>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default React.memo(TextField);
