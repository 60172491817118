import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import FeedBack from '../../../../../components/alert/FeedBack';
import ApprovalFormEdit from '../../../../../groupware-approval/pages/adminconsole/approval/form/ApprovalFormEdit';
import ApprovalFormFolder from '../../../../../groupware-approval/pages/adminconsole/approval/form/ApprovalFormFolder';
import ApprovalFormFolderEdit from '../../../../../groupware-approval/pages/adminconsole/approval/form/ApprovalFormFolderEdit';
import ApprovalFormList from '../../../../../groupware-approval/pages/adminconsole/approval/form/ApprovalFormList';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
  utils,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  formActions,
  formFolderActions,
} from '../../../../stores/attendance/form';

function AttendanceFormContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathmap = getPathMap('/*/*/*', props.pathname);
  const { propsFolderId, propsFormId } = getPathParams<{
    propsFolderId?: number;
    propsFormId?: number;
  }>(`/*/*/*/:propsFolderId$base62/:propsFormId$base62`, props.pathname);
  const queryParams = getQueryParams(props.search);

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [queryParams.pageNo, queryParams.rowsPerPage, queryParams.searchWord]);

  const dispatch = useAppDispatch();
  const folderList = useSelector(
    (state: RootState) => state.attendance.form.folder.list.items,
  );
  const folderItems = folderList
    .map(({ id, parentId, seq, name: text, updateAt }) => {
      return {
        id,
        parentId,
        seq,
        text,
        strings: hangul.d(text),
        icon: 'folder' as const,
        updateAt,
      };
    })
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);

  const formList = useSelector(
    (state: RootState) => state.attendance.form.items,
  );
  const totalCount = useSelector(
    (state: RootState) => state.attendance.form.totalCount,
  );
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6011)?.name ?? '';

  const formItems = formList.map((a) => {
    return {
      checked: a.checked,
      id: a.id,
      category: folderList.find((b) => b.id === a.folderId)?.name ?? '',
      subject: a.name,
      createAt: a.createAt,
      state: a.status === 1,
    };
  });
  const [validation, setValidation] = useState('');
  const { pageNo = 1, rowsPerPage = 15 } = queryParams;

  /** 양식 폴더 선택. */
  const handleSelectedFolder = (id: number) => {
    dispatch(
      formActions.list({
        folderId: id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search: createQueryString({ pageNo: 1, rowsPerPage }),
        },
      }),
    );
  };

  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  const handleSnackbarClose = () => {
    setValidation('');
  };

  /** 폴더 생성. */
  const handleFolderCreate = (arg: { parentId: number; name: string }) => {
    const { parentId, name } = arg;
    const route = { pathname: `${pathmap}/{response_id}` };
    dispatch(formFolderActions.create({ parentId, name, route }));
  };

  /** 폴더 수정. */
  const handleFolderUpdate = (arg: {
    id: number;
    parentId: number;
    name: string;
    updateAt: string;
  }): void => {
    const route = utils.getRoute({
      target: props,
      source: {
        pathname: props.pathname,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DRAWER',
      },
    });
    dispatch(formFolderActions.update({ ...arg, route }));
  };

  /** 폴더 삭제. */
  const handleDeleteFolder = () => {
    const data = folderItems.find((a) => a.id === propsFolderId);
    if (!data) return;

    const { id, updateAt } = data;
    const route = { pathname: pathmap };
    dispatch(formFolderActions.delete({ id, updateAt, route }));
  };

  /** 폴더 순서 변경. */
  const handleChangeFolderOrder = (aList: { id: number }[]) => {
    const data = aList.map((a, i) => {
      const updateAt = folderItems.find((x) => x.id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: i + 1,
        updateAt,
      };
    });
    const route = utils.getRoute({
      target: props,
      source: {
        pathname: props.pathname,
        search: getQueryParams(queryParams),
        option: 'CLEAR_DRAWER',
      },
    });
    dispatch(formFolderActions.changeSeq({ data, route }));
  };

  /** 양식 저장. */
  const handleFormSave = (
    arg:
      | {
          id?: undefined;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
        }
      | {
          id: number;
          folderId: number;
          name: string;
          description: string;
          status: number;
          content: string;
          updateAt: string;
        },
  ) => {
    const { folderId } = arg;
    const route = {
      pathname: `${pathmap}/${b62(folderId)}`,
      search: createQueryString({ pageNo: 1, rowsPerPage: 15 }),
    };
    // 양식 등록
    if (arg.id === undefined) dispatch(formActions.create({ ...arg, route }));
    // 양식 수정
    else dispatch(formActions.update({ ...arg, route }));
  };

  /** 양식 폴더 변경. */
  const handleChangeFormFolder = (folderId: number) => {
    if (propsFolderId === folderId) {
      setValidation('원본 폴더와 대상 폴더가 동일합니다.');
      return;
    }

    const data = formList
      .filter(({ checked }) => checked)
      .map(({ id, updateAt }) => ({ id, folderId, updateAt }));
    if (!data) return;

    const route = utils.getRoute({
      target: props,
      source: {
        pathname: `${pathmap}/${b62(folderId)}`,
        search: createQueryString({ pageNo: 1 }, queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(formActions.move({ data, route }));
  };

  /* 양식함 리스트 삭제 */
  const handleDeleteForm = () => {
    const data = formList
      .filter(({ checked }) => checked)
      .map((a) => ({ id: a.id, updateAt: a.updateAt }));
    const route = utils.getRoute({
      target: props,
      source: {
        pathname: props.pathname,
        search: createQueryString({ pageNo: 1 }, queryParams),
        option: 'CLEAR_DIALOG',
      },
    });
    if (data.length === 1)
      dispatch(formActions.delete({ data: data[0], route }));
    else dispatch(formActions.delete({ data, route }));
  };

  /* 체크박스 이벤트 */
  const handleChangeCheckbox = (arg: {
    id: number | 'all';
    checked: boolean;
  }) => {
    dispatch(formActions.checked(arg));
  };

  /** 양식 폴더 액션 */
  const handleFolderListAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code } = arg;

    if (code === 'folder/create')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'create' }));
    if (code === 'form/create') {
      if (folderList.length === 0) {
        setValidation('양식 분류를 하나 이상 생성해 주세요.');
        return;
      }
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'create' }));
    }
  };

  // 모바일에서만 사용.
  const handleFormListClose = () => {
    dispatch(sessionActions.setRoute({ pathname: pathmap }));
  };

  /** 양식 목록 개수 변경 */
  const handleFormListChangeRowsPerPage = (value: number) => {
    if (!propsFolderId) return;

    const route = {
      pathname: `${pathmap}/${b62(propsFolderId)}`,
      search: createQueryString({ pageNo: 1, rowsPerPage: value }, queryParams),
    };
    dispatch(formActions.list({ folderId: propsFolderId, route }));
  };

  /** 양식 검색 */
  const handleFormListSearch = (arg: { keyword: string; filter?: string }) => {
    if (!propsFolderId) return;
    const { keyword: searchWord } = arg;
    dispatch(
      formActions.list({
        folderId: propsFolderId,
        route: {
          pathname: `${pathmap}/${b62(propsFolderId)}`,
          search: createQueryString(
            { pageNo: 1, searchCode: 'form', searchWord },
            queryParams,
          ),
        },
      }),
    );
  };

  /** 양식 목록 액션. */
  const handleFormListAction = (arg: { code: string; id?: number }) => {
    const { code, id } = arg;

    /** 제목 */
    if (code === 'subject' && id !== undefined) {
      dispatch(
        sessionActions.setRoute({
          pathname: `${props.pathname}/${b62(id)}`,
          search: createQueryString(
            { dialogType: 'form', dialogMode: 'update' },
            queryParams,
          ),
        }),
      );
    }
    /** 체크박스 전체 체크 해제 */
    if (code === 'cancelSelected') {
      dispatch(formActions.checked({ id: 'all', checked: false }));
    }
    /** 다음 페이지 */
    if (code === 'nextPage') {
      if (!propsFolderId) return;

      queryParams.pageNo = pageNo + 1;
      const route = {
        pathname: `${props.pathname}/${b62(propsFolderId)}`,
        search: getQueryParams(queryParams),
      };
      dispatch(formActions.list({ folderId: propsFolderId, route }));
    }
    /** 이전 페이지 */
    if (code === 'prePage') {
      if (!propsFolderId) return;

      queryParams.pageNo = pageNo - 1;
      const route = {
        pathname: `${props.pathname}/${b62(propsFolderId)}`,
        search: getQueryParams(queryParams),
      };
      dispatch(formActions.list({ folderId: propsFolderId, route }));
    }
    /** 양식 삭제 */
    if (code === 'form/delete')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'delete' }));
    /** 폴더 삭제 */
    if (code === 'folder/delete')
      dispatch(sessionActions.setDialog({ type: 'folder', mode: 'delete' }));
    /** 폴더 수정 */
    if (code === 'folder/update')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'update' }));
    /** 양식 이동 */
    if (code === 'moveForm') dispatch(sessionActions.setDialog({ type: code }));
    /** 순서 변경 */
    if (code === 'orderFolder')
      dispatch(sessionActions.setDrawer({ type: code }));
  };

  /** 드로워 렌터링 */
  const renderDrawer = () => {
    const { drawerType, drawerMode } = queryParams;

    if (drawerType === 'folder' && drawerMode === 'create') {
      return (
        <ApprovalFormFolderEdit
          folders={folderItems}
          parentId={propsFolderId}
          onSave={handleFolderCreate}
          onClose={handleCloseDrawer}
        />
      );
    }
    if (drawerType === 'folder' && drawerMode === 'update') {
      const folder = folderItems.find(({ id }) => id === propsFolderId);
      if (folder === undefined) return null;
      return (
        <ApprovalFormFolderEdit
          folders={folderItems}
          onSave={handleFolderUpdate}
          onClose={handleCloseDrawer}
          id={folder.id}
          parentId={folder.parentId}
          name={folder.text}
          updateAt={folder.updateAt}
        />
      );
    }
    if (drawerType === 'orderFolder') {
      const folder = folderItems.find(({ id }) => id === propsFolderId);
      if (!folder) return null;

      const items = folderItems
        .filter(({ parentId }) => parentId === folder.parentId)
        .map(({ id, text: label }) => ({ id, label }));

      return (
        <ChangeOrder
          title="순서변경"
          list={items}
          onChange={handleChangeFolderOrder}
          onClose={handleCloseDrawer}
        />
      );
    }

    return null;
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    const { dialogType, dialogMode } = queryParams;

    if (dialogType === 'folder' && dialogMode === 'delete') {
      const folder = folderItems.find((x) => x.id === propsFolderId);
      if (!folder) return null;
      return (
        <DeleteConfirmation
          onSubmit={handleDeleteFolder}
          onCancel={handleCloseDialog}
        >
          <strong>&apos;{folder.text}&apos;</strong> 분류를 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    if (dialogType === 'form' && dialogMode === 'create') {
      return (
        <ApprovalFormEdit
          attendance
          folders={folderItems}
          folderId={propsFolderId}
          onClose={handleCloseDialog}
          onSave={handleFormSave}
        />
      );
    }
    if (
      dialogType === 'form' &&
      dialogMode === 'update' &&
      propsFolderId !== undefined &&
      propsFormId !== undefined
    ) {
      return (
        <ApprovalFormEdit
          attendance
          folders={folderItems}
          folderId={propsFolderId}
          id={propsFormId}
          onSave={handleFormSave}
          onClose={() => {
            const route = utils.getRoute({
              target: props,
              source: {
                pathname: `${pathmap}/${b62(propsFolderId)}`,
                search: getQueryParams(queryParams),
                option: 'CLEAR_DIALOG',
              },
            });
            dispatch(sessionActions.setRoute(route));
          }}
        />
      );
    }
    if (dialogType === 'form' && dialogMode === 'delete') {
      const array = formList.filter(({ checked }) => checked);
      if (array.length === 0) return null;

      return (
        <DeleteConfirmation
          onSubmit={handleDeleteForm}
          onCancel={handleCloseDialog}
        >
          {array.length === 1 ? (
            <>
              <strong>&apos;{array[0].name}&apos;</strong> 을(를)
              삭제하시겠습니까?
            </>
          ) : (
            <>
              <strong>&apos;{array[0].name}&apos;</strong> 외 {array.length - 1}{' '}
              건을 삭제하시겠습니까?
            </>
          )}
        </DeleteConfirmation>
      );
    }

    if (dialogType === 'moveForm')
      return (
        <TreePicker
          title="양식 이동"
          /* type="group" */
          disabledId={propsFolderId}
          list={folderItems}
          onSelected={handleChangeFormFolder}
          onClose={handleCloseDialog}
        />
      );

    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ApprovalFormFolder
              title="근태양식"
              items={folderItems}
              selectedId={propsFolderId}
              onSelected={handleSelectedFolder}
              onClick={handleFolderListAction}
            />
          </EuiSetting.Left>
          {propsFolderId === undefined ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleFormListClose}>
              <ApprovalFormList
                scroll={scrollbar}
                title={
                  folderList.find((a) => a.id === propsFolderId)?.name ?? ''
                }
                items={formItems}
                onSearch={handleFormListSearch}
                onClick={handleFormListAction}
                onChangeChecked={handleChangeCheckbox}
                pagination={{
                  no: pageNo,
                  row: rowsPerPage,
                  total: totalCount,
                  onChangeRow: handleFormListChangeRowsPerPage,
                }}
                searchWord={queryParams.searchWord}
                searchCode={queryParams.searchCode}
              />
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceFormContainer;
