import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipSize(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Sizes chip">
      <Chip label="보통" theme="success" />
      <Chip label="태그" theme="success" size="sm" />
      <Chip label="성공" theme="success" size="xs" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipSize;
