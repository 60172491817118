import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import AttendanceHolidayYearContainer from '../pages/adminconsole/attendance/holiday/AttendanceHolidayYearContainer';
import AttendanceHolidayBasicContainer from '../pages/adminconsole/attendance/holiday/AttendanceHolidayBasicContainer';
import AttendanceFormContainer from '../pages/adminconsole/attendance/form/AttendanceFormContainer';
import AttendanceSettingContainer from '../pages/adminconsole/attendance/setting/AttendanceSettingContainer';
import AttendanceWorkContainer from '../pages/adminconsole/attendance/work/AttendanceWorkContainer';
import AttendanceAdjustContainer from '../pages/adminconsole/attendance/adjustment/AttendanceAdjustContainer';
import AttendanceDetailStatusContainer from '../pages/adminconsole/attendance/detail/AttendanceDetailStatusContainer';
import AttendanceDayOffStatusContainer from '../pages/adminconsole/attendance/dayOffStatus/AttendanceDayOffStatusContainer';
import AttendanceSendNoticeContainer from '../pages/adminconsole/attendance/sendNotice/AttendanceSendNoticeContainer';
import AttendanceDayOffSettingStandardContainer from '../pages/adminconsole/attendance/dayOffSetting/AttendanceDayOffSettingStandardContainer';
import AttendanceNoticeSettingContainer from '../pages/adminconsole/attendance/sendNotice/AttendanceNoticeSettingContainer';
import AttendanceSubstituteContainer from '../pages/adminconsole/attendance/substitute/AttendanceSubstituteContainer';

function AdminConsoleLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element | null {
  // console.log(`${AdminConsoleLocateContainer.name}.render(props)`, props);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  if (menu === undefined)
    return (
      <AttendanceSettingContainer
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );
  if (menu === 'holidaysave') {
    return (
      <AttendanceHolidayYearContainer pathname={pathname} search={search} />
    );
  }
  if (menu === 'basicHoliday') {
    return (
      <AttendanceHolidayBasicContainer pathname={pathname} search={search} />
    );
  }
  if (menu === 'dayOffSetting') {
    return <AttendanceDayOffSettingStandardContainer />;
  }
  if (menu === 'dayOffAdjust' || menu === 'substituteAdjust') {
    return <AttendanceAdjustContainer pathname={pathname} search={search} />;
  }
  if (menu === 'dayOffStatus')
    return (
      <AttendanceDayOffStatusContainer pathname={pathname} search={search} />
    );
  if (menu === 'noticeSetting') return <AttendanceNoticeSettingContainer />;
  if (menu === 'sendList')
    return (
      <AttendanceSendNoticeContainer pathname={pathname} search={search} />
    );
  if (menu === 'substituteDeadline' || menu === 'substituteStatus')
    return (
      <AttendanceSubstituteContainer pathname={pathname} search={search} />
    );
  if (menu === 'form') {
    return <AttendanceFormContainer pathname={pathname} search={search} />;
  }
  if (menu === 'work') {
    return <AttendanceWorkContainer pathname={pathname} search={search} />;
  }
  if (menu === 'detailStatus') {
    return (
      <AttendanceDetailStatusContainer pathname={pathname} search={search} />
    );
  }

  return null;
}

export default AdminConsoleLocateContainer;
