/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { IconType } from '../../groupware-common/types/icon';
import { getQueryParams } from '../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../groupware-common/utils/ui';
import { RootState } from '../../groupware-webapp/app/store';
import { appError } from '../../groupware-webapp/stores/common/utils';
import resourcesApi from '../apis/resource/v1/resources';
import { RepeatDaysType } from '../../components/repeatDialog/DetailRepeatDialog';

const name = 'resource/resources';

interface CategoryItem {
  type: 'status' | 'organization' | 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}

export interface ReservationList {
  companyId: number;
  id: number; // 자원 예약 아이디.
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  isRepeat: boolean;
  isApproval: number;
  name: string;
  startDateTime: string;
  endDateTime: string;
  updateAt: string;
}

export interface ShareReservationList {
  companyId: number;
  id: number; // 자원 예약 아이디.
  folderId: number; // 자원 폴더 아이디.
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  name: string;
  startDateTime: string;
  endDateTime: string;
  isRepeat: boolean;
  isApproval: number; // 0: 승인대기, 1: 예약완료 2: 반려
  createAt: string; // 신청일.
  updateAt: string;
  repeat?: {
    reservationId: number;
    frequency: number; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    repeatDays: string[]; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    endDate: string; // 반복 종료일
  };
}

export interface ReservationItem {
  companyId: number;
  id: number; // 자원 예약 아이디.
  itemId: number; // 자원 아이템 아이디.
  employeeId: number;
  name: string;
  remark: string;
  startDateTime: string; // 시작 시간.
  endDateTime: string; // 종료 시간
  isRepeat: boolean; // 반복 여부
  isApproval: number; // 승인 여부 (0: 대기, 1: 승인, 2: 반려)
  isTakeback: boolean; // 대여 여부
  sharers: {
    companyId: number;
    reservationId: number;
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    updateAt: string;
  }[];
  repeat?: {
    companyId: number;
    reservationId: number;
    frequency: number; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    repeatDays: string[]; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    firstDayOfWeek: number; // 한주의 시작 (1 : 일요일 , 2 : 월요일)
    endDate: string; // 반복 종료일
    creatorId: number; // 생성자 아이디
    updaterId: number; // 수정자 아이디
    createAt: string; // 생성 시간
    updateAt: string; // 수정 시간
  };
  alarms: {
    companyId: number;
    id: number;
    reservationId: number;
    type: string; // mail | alarm
    timeUnit: string; // MINUTE, HOUR, DAY, WEEK
    ammount: number;
    updateAt: string;
  }[];
  creatorId: number; // 생성자 아이디
  updaterId: number; // 수정자 아이디
  createAt: string; // 생성 시간
  updateAt: string; // 수정 시간
}

export interface UpdateData {
  itemId: number;
  itemName: string;
  start: string;
  end: string;
  title: string;
  remark: string;
  originStart: string;
  originEnd: string;
  repeatType?: string;
}

/** 기본 폴더 */
const categories: CategoryItem[] = [
  { type: 'status', id: 'mine', name: '내예약조회', icon: 'user-my' },
  { type: 'status', id: 'shared', name: '공유받은 예약조회', icon: 'connect' },
  {
    type: 'status',
    id: 'approval',
    name: '예약승인 관리',
    icon: 'clock-check',
  },
  {
    type: 'status',
    id: 'return',
    name: '대여반납 관리',
    icon: 'compare-arrows',
  },
  { type: 'status', id: 'preferences', name: '환경설정', icon: 'cog-fill' },
  // 관리자 - 메뉴.
  { type: 'setting', id: 6001, name: '기본설정', icon: 'folder' },
  { type: 'setting', id: 6002, name: '자원등록 관리', icon: 'folder' },
  { type: 'setting', id: 6003, name: '전사예약내역', icon: 'folder' },
];

interface State {
  category: CategoryItem[];
  displayDensity: 'wide' | 'normal' | 'narrow';
  list: ReservationList[];
  share: {
    list: ShareReservationList[];
    totalCount: number;
  };
  view: ReservationItem | undefined;
  updateData?: UpdateData;
}

const initialState: State = {
  category: categories,
  displayDensity: 'normal',
  list: [],
  share: {
    list: [],
    totalCount: 0,
  },
  view: undefined,
  updateData: undefined,
};

function resourceDate(arg: {
  hash: string;
  date?: string;
  firstDayOfWeek?: number;
  type: 'start' | 'end';
}): string {
  const { hash, firstDayOfWeek, type } = arg;
  let date = timezoneDate(arg.date);
  if (type === 'start') {
    if (hash === '#weekly') {
      if (firstDayOfWeek === 0) {
        // 한 주의 시작일 = 일요일 && 오늘 날짜가 일요일이 아닐 경우 해당 주의 일요일 계산.
        if (date.getDay() !== 0) date.setDate(date.getDate() - date.getDay());
      }
      // 한 주의 시작일 = 월요일 && 오늘 날짜가 월요일이 아닐 경우 해당 주의 월요일 계산.
      else if (date.getDay() !== 1) {
        if (date.getDay() === 0) date.setDate(date.getDate() - 6);
        else date.setDate(date.getDate() - (date.getDay() - 1));
      }
    }
    if (hash === '#monthly') {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      startDate.setDate(-6);
      date = new Date(startDate);
    }
  } else {
    if (hash === '#weekly') date.setDate(date.getDate() + 6);
    if (hash === '#monthly') {
      const endDate = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      endDate.setDate(endDate.getDate() + 12);
      date = new Date(endDate);
    }
  }
  return dateFormat(initialDate(date), 'yyyy-MM-DD');
}

function jsonToRepeatDays(date: Date, a: string | null): string[] {
  if (a === null) {
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const day = new Date(date).getDay();
    return [week[day]];
  }
  const json: {
    days: number[];
  } = JSON.parse(a);
  const dateDiff = date.getDate() - initialDate(date).getDate();
  const repeatDays: string[] = [];
  for (let i = 0; i < Object.values(json)[0].length; i += 1) {
    const value = Object.values(json)[0][i] + dateDiff;
    if (value === 1) repeatDays.push('월');
    if (value === 2) repeatDays.push('화');
    if (value === 3) repeatDays.push('수');
    if (value === 4) repeatDays.push('목');
    if (value === 5) repeatDays.push('금');
    if (value === 6) repeatDays.push('토');
    if (value === 7) repeatDays.push('일');
  }
  return repeatDays;
}
function repeatDaysToJson(days: RepeatDaysType, date: Date): string {
  const dateDiff = date.getDate() - initialDate(date).getDate();
  const object: number[] = [];
  Object.keys(days).forEach((a, i) => {
    if (Object.values(days)[i]) {
      if (a === 'mon') object.push(1);
      else if (a === 'tue') object.push(2);
      else if (a === 'wed') object.push(3);
      else if (a === 'thu') object.push(4);
      else if (a === 'fri') object.push(5);
      else if (a === 'sat') object.push(6);
      else object.push(7);
    }
  });
  const value = {
    days: object.map((a) => {
      let day = a - dateDiff;
      if (day < 1) day += 7;
      if (day > 7) day -= 7;
      return day;
    }),
  };
  return JSON.stringify(value);
}

/** 자원 예약 리스트 조회. */
const findReservationList = createAsyncThunk(
  `${name}/findReservationList`,
  async (
    arg: {
      type: 'item' | 'folder';
      id: number; // 자원 폴더 아이디 또는 자원 아이템 아이디.
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    try {
      const queryParams = getQueryParams(
        arg.route?.search ?? (getState() as RootState).session.route.search,
      );
      const hash =
        arg.route?.hash ?? (getState() as RootState).session.route.hash;
      const basic = (getState() as RootState).resource.userPreferences
        .preferences;

      const fromDate = resourceDate({
        hash,
        date: queryParams.startDate,
        firstDayOfWeek: basic?.firstDayOfWeek ?? 0,
        type: 'start',
      });
      const toDate = resourceDate({
        hash,
        date: fromDate,
        firstDayOfWeek: basic?.firstDayOfWeek ?? 0,
        type: 'end',
      });
      const data = await resourcesApi.reservationList({
        type: arg.type,
        id: arg.id,
        fromDate,
        toDate,
      });
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 예약 모두 조회 (관리자) */
const findReservationAdminList = createAsyncThunk(
  `${name}/findReservationAdminList`,
  async (
    arg: {
      search: string;
      isDirectorySelected: boolean;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const queryParams = getQueryParams(arg.search);
    const params = {
      startdate: queryParams.startDate,
      enddate: queryParams.endDate,
      searchcode: queryParams.searchCode,
      searchword: !arg.isDirectorySelected
        ? queryParams.searchWord
        : queryParams.directoryKeyword,
      isdirectoryselected: arg.isDirectorySelected,
    };
    try {
      const data = await resourcesApi.adminReservationList({
        ...params,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 15,
      });
      const total = await resourcesApi.adminReservationToTalCount(params);
      return { list: data, totalCount: total };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 내 예약 조회. */
const findReservationMyList = createAsyncThunk(
  `${name}/findReservationMyList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const { principal } = (getState() as RootState).session;
    const queryParams = getQueryParams(arg.search);
    const params = {
      employeeid: principal.employeeId,
      startdate: queryParams.startDate,
      enddate: queryParams.endDate,
      searchcode: queryParams.searchCode,
      searchword: queryParams.searchWord,
    };
    try {
      const data = await resourcesApi.myReservationList({
        ...params,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 15,
      });
      const total = await resourcesApi.myReservationToTalCount(params);
      return { list: data, totalCount: total };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 공유받은 예약 조회. */
const findReservationSharedList = createAsyncThunk(
  `${name}/findReservationSharedList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const { principal } = (getState() as RootState).session;
    const queryParams = getQueryParams(arg.search);
    const params = {
      employeeid: principal.employeeId,
      organizationid: principal.organizationId,
      startdate: queryParams.startDate,
      enddate: queryParams.endDate,
      searchcode: queryParams.searchCode,
      searchword:
        queryParams.status !== 'true'
          ? queryParams.searchWord
          : queryParams.directoryKeyword,
      isdirectoryselected: queryParams.status === 'true',
    };
    try {
      const data = await resourcesApi.sharedReservationList({
        ...params,
        pageno: queryParams.pageNo ?? 1,
        rowsperpage: queryParams.rowsPerPage ?? 15,
      });
      const total = await resourcesApi.sharedReservationToTalCount(params);
      return { list: data, totalCount: total };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 예약 단일 조회. */
const findReservationView = createAsyncThunk(
  `${name}/findReservationVieww`,
  async (
    arg: {
      id: number;
      updateAt?: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await resourcesApi.reservationView({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      const item: ReservationItem = {
        ...data,
        repeat: data.repeat
          ? {
              ...data.repeat,
              monthRepeatStandard:
                data.repeat.monthRepeatStandard !== null
                  ? data.repeat.monthRepeatStandard
                  : 0,
              endDate:
                data.repeat.endDate !== '9999-12-31'
                  ? dateTimeFormat(data.repeat.endDate, 'yyyy-MM-DD')
                  : '9999-12-31',
              repeatDays: jsonToRepeatDays(
                new Date(data.startDateTime),
                data.repeat.repeatDays,
              ),
            }
          : undefined,
      };
      return item;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 예약 등록. */
const saveReservation = createAsyncThunk(
  `${name}/saveReservation`,
  async (
    arg: {
      data: {
        itemId: number; // 자원 아이템 아이디.
        employeeId: number;
        name: string;
        remark: string;
        startDateTime: string; // 시작 시간.
        endDateTime: string; // 종료 시간
        sharers?: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
        }[];
        repeat?: {
          frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
          cycle: number; // 반복 주기. 1, 2, 3, 4, ...
          monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
          days?: RepeatDaysType; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
          endDate: string; // 반복 종료일
        };
        alarms?: {
          type: string; // mail | alarm
          timeUnit: string; // MINUTE, HOUR, DAY, WEEK
          ammount: number;
        }[];
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = {
        ...arg.data,
        repeat: arg.data.repeat
          ? {
              ...arg.data.repeat,
              days: arg.data.repeat.days
                ? repeatDaysToJson(
                    arg.data.repeat.days,
                    new Date(arg.data.startDateTime),
                  )
                : undefined,
            }
          : undefined,
      };
      const result = await resourcesApi.reservationSave(data);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 일반 예약 삭제. */
const deleteReservation = createAsyncThunk(
  `${name}/deleteReservation`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await resourcesApi.deleteReservation({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 반복 예약 삭제 */
const deleteRepeatReservation = createAsyncThunk(
  `${name}/deleteRepeatReservation`,
  async (
    arg: {
      data: {
        type: string;
        id: number;
        startDateTime: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await resourcesApi.deleteRepeatReservation(arg.data);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 일반 예약 수정 */
const updateReservation = createAsyncThunk(
  `${name}/updateReservation`,
  async (
    arg: {
      data: {
        id: number;
        itemId: number;
        employeeId: number;
        name: string;
        remark?: string;
        startDateTime?: string;
        endDateTime?: string;
        repeat?: {
          frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
          cycle: number; // 반복 주기. 1, 2, 3, 4, ...
          monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
          endDate: string; // 반복 종료일
          days?: RepeatDaysType; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
          updateAt?: string;
        };
        sharers?: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
          lookupDeletAt?: string;
        }[];
        alarms?: {
          id?: number;
          type: string; // mail | alarm
          timeUnit: string; // MINUTE, HOUR, DAY, WEEK
          ammount: number;
          updateAt?: string;
          lookupDeletAt?: string;
        }[];
        updateAt: string;
      };
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const reservation = (getState() as RootState).resource.resources.view;
      const data = {
        ...arg.data,
        repeat:
          arg.data.repeat && reservation
            ? {
                ...arg.data.repeat,
                days: arg.data.repeat.days
                  ? repeatDaysToJson(
                      arg.data.repeat.days,
                      new Date(
                        arg.data.startDateTime ?? reservation.startDateTime,
                      ),
                    )
                  : undefined,
              }
            : undefined,
      };
      const result = await resourcesApi.updateReservation(data);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 반복 예약 수정 */
const updateRepeatReservation = createAsyncThunk(
  `${name}/updateRepeatReservation`,
  async (
    arg: {
      type: string;
      data: {
        id: number;
        itemId: number;
        employeeId: number;
        name: string;
        remark?: string;
        startDateTime?: string;
        endDateTime?: string;
        repeat?: {
          frequency: number; // 반복 빈도, 일: days 주: weeks 월: months 년: years
          cycle: number; // 반복 주기. 1, 2, 3, 4, ...
          monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
          endDate: string; // 반복 종료일
          days?: RepeatDaysType; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
          updateAt?: string;
          isDelete?: boolean;
        };
        sharers?: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
          lookupDeleteAt?: string;
        }[];
        alarms?: {
          id?: number;
          type: string; // mail | alarm
          timeUnit: string; // MINUTE, HOUR, DAY, WEEK
          ammount: number;
          updateAt?: string;
          lookupDeleteAt?: string;
        }[];
        lookupStartDateTime?: string;
        lookupUpdateAt?: string;
        updateAt?: string;
      };
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const reservation = (getState() as RootState).resource.resources.view;
      const data = {
        ...arg.data,
        repeat:
          arg.data.repeat && reservation
            ? {
                ...arg.data.repeat,
                days: arg.data.repeat.days
                  ? repeatDaysToJson(
                      arg.data.repeat.days,
                      new Date(
                        arg.data.startDateTime ?? reservation.startDateTime,
                      ),
                    )
                  : undefined,
              }
            : undefined,
      };
      const result = await resourcesApi.updateRepeatReservation({
        type: arg.type,
        data,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const resourcesReducer = createSlice({
  name,
  initialState,
  reducers: {
    reservationViewClear(state) {
      state.view = undefined;
    },
    displayDensity(state, action: PayloadAction<'wide' | 'normal' | 'narrow'>) {
      if (state.displayDensity !== action.payload)
        state.displayDensity = action.payload;
    },
    reservationUpdateData(state, action: PayloadAction<UpdateData>) {
      state.updateData = action.payload;
    },
    updateDataClear(state) {
      state.updateData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findReservationList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = payload;
          state.updateData = undefined;
          state.view = undefined;
        }
      })
      .addCase(findReservationAdminList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.share = payload;
      })
      .addCase(findReservationMyList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.share = payload;
          state.updateData = undefined;
          state.view = undefined;
        }
      })
      .addCase(findReservationSharedList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.share = payload;
      })
      .addCase(findReservationView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(saveReservation.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = undefined;
      })
      .addCase(deleteReservation.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = undefined;
      })
      .addCase(deleteRepeatReservation.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = undefined;
      });
  },
});

export default resourcesReducer.reducer;

export const resourcesActions = {
  reservationList: findReservationList,
  adminReservationList: findReservationAdminList,
  myReservationList: findReservationMyList,
  sharedReservationList: findReservationSharedList,
  reservationView: findReservationView,

  saveReservation,

  deleteReservation,
  deleteRepeatReservation,

  updateReservation,
  updateRepeatReservation,

  reservationViewClear: resourcesReducer.actions.reservationViewClear,
  displayDensity: resourcesReducer.actions.displayDensity,
  updateData: resourcesReducer.actions.reservationUpdateData,
  updateDataClear: resourcesReducer.actions.updateDataClear,
};
