/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiError } from '../../../groupware-common/apis/common/v1';
import { LocateArg } from '../../../groupware-common/types';
import api from '../../apis/approval/v1/index';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';

/** 전결권자 */
const name = 'approval/arbitrarydecision';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface State {
  requests: { id: string; type: string; arg: any }[];

  arbitraryDecisions: {
    companyId: number;
    employeeId: number;
    organizationId: number;
    updateAt: string;
  }[];
}

const initialState: State = {
  requests: [],

  arbitraryDecisions: [],
};

/** 전결권자 조회 */
const fetchArbitraryDecision = createAsyncThunk(
  `${name}/fetchArbitraryDecision`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      const response = await api.fetchArbitraryDecisionApi();
      return response;
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

/** 전결권자 수정 */
const modefetchArbitraryDecision = createAsyncThunk(
  `${name}/modefetchArbitraryDecision`,
  async (
    arg: (
      | {
          organizationId: number;
          employeeId: number;
          updateAt: string;
        }
      | undefined
    )[],
    { rejectWithValue },
  ) => {
    try {
      if (arg !== undefined) {
        const removeNulls = <S>(value: S | undefined): value is S =>
          value != null;
        // eslint-disable-next-line array-callback-return
        const insert = arg.filter(removeNulls).map((x) => {
          if (
            x?.organizationId !== undefined &&
            x?.employeeId !== undefined &&
            (x?.updateAt === '' || x?.updateAt === undefined)
          ) {
            const organizationId = x?.organizationId;
            const employeeId = x?.employeeId;
            return {
              organizationId,
              employeeId,
            };
          }
        });
        // eslint-disable-next-line array-callback-return
        const deletes = arg.filter(removeNulls).map((x) => {
          if (x?.updateAt !== '' && x?.updateAt !== undefined) {
            const organizationId = x?.organizationId;
            const employeeId = x?.employeeId;
            const updateAt = x?.updateAt;
            return {
              organizationId,
              employeeId,
              updateAt,
            };
          }
        });

        if (insert.length > 0 && insert[0] !== undefined) {
          await api.savefetchArbitraryDecisionApi(insert);
        }
        if (deletes.length > 0 && deletes[0] !== undefined) {
          await api.delfetchArbitraryDecisionApi(deletes);
        }
        const response = await api.fetchArbitraryDecisionApi();
        return await response;
      }
    } catch (error) {
      return rejectWithValue(apiError(error));
    }
  },
);

const approvalArbitraryDecisionReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArbitraryDecision.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.arbitraryDecisions = action.payload;
        }
      })
      .addCase(modefetchArbitraryDecision.fulfilled, (state, action) => {
        if (
          state.requests.find((x) => x.id === action.meta.requestId) !==
            undefined &&
          action.payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== action.meta.requestId,
          );

          if (action.payload !== undefined)
            state.arbitraryDecisions = action.payload;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default approvalArbitraryDecisionReducer.reducer;

export const approvalArbitraryDecisionActions = {
  fetchArbitraryDecision,
  modefetchArbitraryDecision,
};
