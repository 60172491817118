/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import {
  appError,
  getDirectoryData,
} from '../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../groupware-directory/stores/directory';
import { getAvatarPath } from '../../../../groupware-common/utils';
import {
  ApprovalLineType,
  getApprovalLineDrafter,
} from '../../common/dialogs/ApprovalLineDialogContainer';
import PostView from '../../../../components/post/PostView';
import Avatar from '../../../../components/avatar/Avatar';
import AttachmentsList from '../../../../components/attachments/AttachmentsList';
import AttachmentsItem from '../../../../components/attachments/AttachmentsItem';
import Comment from '../../../../components/comment/Comment';
import {
  TrashView,
  documentActions,
  DocumentView,
} from '../../../stores/approval/document';
import {
  AttachFile,
  SharedFile,
  documentApi,
} from '../../../apis/approval/v1/document';
import ApprovalLineFlat from '../../common/components/ApprovalLineFlat';
import AttachDocumentItem from '../../../../components/attachments/AttachDocumentItem';
import AttachDocumentList from '../../../../components/attachments/AttachDocumentList';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { documentMacroReplace } from './compose/ApprovalComposeContainer';
import Button from '../../../../components/button/Button';
import FeedBack from '../../../../components/alert/FeedBack';
import { DocumentDialog } from './content/ApprovalAttachedDocumentDialog';
import { timezoneDate } from '../../../../groupware-common/utils/ui';

/** 마지막 결재 날짜 가져오기. */
function getLastApprovalAt(approvalLine: ApprovalLineType): string | undefined {
  let result: string | undefined;

  const items = approvalLine.groups.map((a) => a.items).flat();
  // console.log(`getLastApprovaledAt(approvalLine):items`, items);

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.actAt !== undefined) result = item.actAt;
    else break;
  }
  return result;
}

/** 대기 시간 가져오기. */
function getWaitedAt(lastApprovalAt: string): string {
  const date1 = new Date(lastApprovalAt);
  const date2 = new Date(Date.now());
  const time = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600));

  const day = Math.ceil(time / 24);
  const remainder = time % 24;

  if (time >= 24) {
    return `${day}일 ${remainder}시간`;
  }
  return `${time}시간`;
}

interface NameKeyItem {
  companyId?: number;
  id: number;
  nameKey: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function getCommentItems(
  items:
    | {
        companyId: number; // 문서 회사 아이디
        documentId: number; // 문서 아이디
        id: number; // 아이디
        parentId: number; // 부모 아이디 - 0: 댓글, any: 댓글 아이디(답글)
        employeeCompanyId: number; // 직원 회사 아이디
        employeeId: number; // 직원 아이디 - employee.id
        act?: number; // 행위 - 승인,부결,보류
        contents: string; // 내용
        likes: number; // 좋아요 수
        dislikes: number; // 싫어요 수
        loves: number; // 사랑해요 수
        createAt: string; // 생성 날짜
        updateAt: string; // 수정 날짜
        isDeleted?: boolean; // 삭제 여부
      }[]
    | undefined,
  directory: {
    organizations: NameKeyItem[];
    organizationEmployees: {
      companyId: number;
      id: number;
      employeeId: number;
      jobDutyId: number;
    }[];
    employees: {
      companyId: number;
      id: number;
      representativeOrganizationId: number;
      jobPositionId: number;
      avatar: string;
      nameKey: string;
    }[];
    jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
    jobPositions: NameKeyItem[];
    jobDuties: NameKeyItem[];
  },
) {
  if (items === undefined) return undefined;

  const temp: {
    companyId: number; // 문서 회사 아이디.
    documentId: number; // 문서 아이디.
    parentId: number; // 부모 아이디.
    id: number;
    from?: {
      key: string;
      name: string;
    };
    message: string;
    writer: {
      key: string;
      name: string; // 이름
      organization: string; // 부서
      class: string; // 직위 혹은 직책
      avatar: string; // 아바타
    };
    createAt: string;
    updateAt: string;
    authModify?: boolean;
    authDelete?: boolean;
    isDeleted?: boolean;
  }[] = items.map((a) => {
    const {
      companyId,
      documentId,
      parentId,
      id,
      employeeCompanyId,
      employeeId,
      // act,
      contents,
      // likes,
      // dislikes,
      // loves,
      createAt,
      updateAt,
      isDeleted,
    } = a;

    const directoryData = getDirectoryData({
      ...directory,
      companyId: employeeCompanyId,
      employeeId,
    });

    const messages = contents.split('┼');

    let from: { key: string; name: string } | undefined;

    if (messages.length > 1) {
      const ids = messages[0].split('/');
      const fromData = getDirectoryData({
        ...directory,
        companyId: parseInt(ids[0], 10),
        employeeId: parseInt(ids[1], 10),
      });

      from = {
        key: messages[0],
        name: fromData.employeeName,
      };
    }

    const message = (messages.length === 1 ? messages[0] : messages[1]) || '';

    return {
      companyId,
      documentId,
      parentId,
      id,
      from,
      message,
      writer: {
        key: `${directoryData.companyId}/${directoryData.employeeId}`,
        name: directoryData.employeeName, // 이름
        organization: directoryData.organizationName, // 부서
        class: directoryData.jobClassName, // 직위 혹은 직책
        avatar: getAvatarPath(employeeCompanyId, employeeId), // 아바타
      },
      createAt,
      updateAt,
      authModify: false,
      authDelete: false,
      isDeleted,
    };
  });

  return temp
    .filter((a) => a.parentId === 0)
    .map((a) => {
      return {
        ...a,
        replies: temp.filter((b) => b.parentId === a.id),
      };
    });
}

const ApprovalContentBodyViewContainer = (props: {
  pathname: string;
  type: 'full' | 'split';
  view: DocumentView | TrashView | null | undefined;
  isCommentDisable?: boolean;
  onAttachedDocumentPopup(arg: DocumentDialog): void;
  onAttachedFilePopup(file: AttachFile): void;
  onSharedFilePopup(file: SharedFile): void;
}): JSX.Element | null => {
  // console.log(`${ApprovalContentBodyViewContainer.name}.render:props`, props);

  const [validation, setValidation] = useState('');

  const { view } = props;

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const directory = useDirectory();

  const display = useSelector((state: RootState) => state.session.display);

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [props.pathname]);

  if (view === undefined) return null;
  if (view === null) return <div>데이터가 이동되었거나 삭제되었습니다.</div>;

  const opinions = getCommentItems(view.opinions, directory);
  const comments = getCommentItems(view.comments, directory);

  /** 첨부파일 모두 저장. */
  const handleAttachedFileDownloadAll = (arg: {
    companyId: number;
    documentId: number;
  }) => {
    // console.log(`handleAttachedFileDownloadAll(arg)`, arg);
    const { companyId, documentId } = arg;
    documentApi
      .downloadAttachfileAll(companyId, documentId)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const date = timezoneDate();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${year}${month}${day}.zip`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 첨부파일 저장. */
  const handleAttachedFileDownload = (arg: {
    companyId: number;
    documentId: number;
    id: number;
    name: string;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { companyId, documentId, id, name } = arg;
    documentApi
      .downloadAttachfile(companyId, documentId, id)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 공유 파일 저장. */
  const handleSharedFileDownload = (arg: {
    documentId: number;
    id: number;
    name: string;
    affiliatedcompanyid?: number;
  }) => {
    // console.log(`handleAttachedFileDownload(arg)`, arg);
    const { affiliatedcompanyid, documentId, id, name } = arg;
    documentApi
      .downloadSharedfile(documentId, id, affiliatedcompanyid)
      .then((blob) => {
        if (!blob)
          throw new Error('파일이 이동되었거나 이름이 변경되었습니다.');

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 3000);
        a.remove();
      })
      .catch((ex) => {
        dispatch(sessionActions.error(appError(ex)));
      });
  };

  /** 의견 답글 추가. */
  const handleOpinionAppend = (arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }) => {
    // console.log(`handleOpinionAppend(args)`, arg);
    if (opinions === undefined) return;

    const { id, parentId, message, parentWriterKey } = arg;

    // 답글의 답글 여부에 따른 내용 생성.
    const contents =
      parentWriterKey !== undefined ? `${parentWriterKey}┼${message}` : message;

    if (id === undefined)
      dispatch(
        documentActions.saveOpinion({
          companyId: principal.companyId,
          documentId: view.id,
          parentId,
          act: 0,
          contents,
        }),
      );
    else {
      const opinion = view.opinions?.find((a) => a.id === id);
      if (opinion === undefined) return;
      dispatch(
        documentActions.saveOpinion({
          companyId: opinion.companyId,
          documentId: opinion.documentId,
          parentId,
          id,
          act: 0,
          contents,
          updateAt: opinion.updateAt,
        }),
      );
    }
  };

  /** 의견 답글 삭제. */
  const handleOpinionDelete = (id: number) => {
    const opinion = view.opinions?.find((a) => a.id === id);
    if (opinion === undefined) return;
    dispatch(
      documentActions.deleteOpinion({
        companyId: opinion.companyId,
        documentId: opinion.documentId,
        id,
        parentId: opinion.parentId,
        updateAt: opinion.updateAt,
      }),
    );
  };

  /** 댓글 및 답글 추가. */
  const handleCommentAppend = (arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }) => {
    // console.log(`handleCommentAppend(args)`, arg);

    if (comments === undefined) return;

    const { id, parentId, message, parentWriterKey } = arg;

    // 답글의 답글 여부에 따른 내용 생성.
    const contents =
      parentWriterKey !== undefined ? `${parentWriterKey}┼${message}` : message;

    // 등록.
    if (id === undefined) {
      dispatch(
        documentActions.saveComment({
          companyId: principal.companyId,
          documentId: view.id,
          parentId,
          contents,
        }),
      ).then(() => {
        scrollbar.current?.scrollTo(0, scrollbar.current?.scrollHeight);
      });
    }
    // 수정.
    else {
      const comment = view.comments?.find((a) => a.id === id);
      if (comment === undefined) return;
      dispatch(
        documentActions.saveComment({
          companyId: principal.companyId,
          documentId: comment.documentId,
          id,
          parentId,
          contents,
          updateAt: comment.updateAt,
        }),
      );
    }
  };

  /** 댓글 및 답글 삭제. */
  // const handleCommentDelete = (id: number, parentId: number) => {
  const handleCommentDelete = (id: number) => {
    // console.log(`handleCommentDelete(${id},${parentId})`);
    // console.log(`handleCommentDelete:comments`, comments);

    const comment = view.comments?.find((a) => a.id === id);
    if (comment === undefined) return;
    dispatch(
      documentActions.deleteComment({
        companyId: comment.companyId,
        documentId: comment.documentId,
        id,
        parentId: comment.parentId,
        updateAt: comment.updateAt,
      }),
    );
  };

  /*
  // #### head 버튼 리스트 설정
  let isDrafter: boolean; // 기안자 여부
  let isApprovedNow: boolean; // 현재 결재 대기자 여부
  let isApprovedLine: boolean; // 결재 라인에 포함되어 있는지 여부
  let isApprovedComplete: boolean; // 결재가 완료 되었는지 여부
  let isPublicOrNot: boolean; // 공개 여부 - 공개:1, 비공개: 0
  let isSecurity: boolean; // 보안 - 사용:1, 사용안함: 0
  let nowStatus: number; // 결재상태 - 1:진행,2:요구,3:완료,4:삭제
  let nowFolderId: number | null; // 현재 폴더 아이디

  isDrafter = false; // 기안자 여부
  isApprovedNow = false; // 현재 결재 대기자 여부
  isApprovedLine = false; // 결재 라인에 포함되어 있는지 여부
  isApprovedComplete = false; // 결재가 완료 되었는지 여부
  isPublicOrNot = false; // 공개 여부 - 공개:1, 비공개: 0
  isSecurity = false; // 보안 - 사용:1, 사용안함: 0
  nowStatus = 1; // 결재상태 - 1:진행,2:요구,3:완료,4:삭제
  // nowFolderId = props.folderId || 0; // 현재 폴더 아이디

  type ActionEventPropsSort = ActionEventProps & {
    sort: number;
  };

  const actionEventDumy: ActionEventPropsSort[] = [
    { sort: 1, code: 'approval', label: '결재', type: 'contained', icon: 'stamp-check', mode: 'dialog', },
    { sort: 2, code: 'internal', label: '내부결재', type: 'contained', icon: 'stamp-check', mode: 'click', },
    { sort: 3, code: 'reDraft', label: '재기안', type: 'contained', icon: 'stamp-check', mode: 'dialog', },
    { sort: 4, code: 'info', label: '문서정보', type: 'outlined', icon: 'info-circle', mode: 'drawer', },
    { sort: 5, code: 'status', label: '결재현황', type: 'outlined', icon: 'line-dot', mode: 'drawer', },
    { sort: 6, code: 'viewer', label: '조회', type: 'outlined', icon: 'eye', mode: 'drawer', },
    { sort: 7, code: 'change', label: '문서 변경', type: 'outlined', icon: 'document-update', mode: 'click', },
    { sort: 8, code: 'changeApprovalLine', label: '결재선 변경', type: 'outlined', icon: 'line-dot', mode: 'dialog', },
    { sort: 9, code: 'changeReference', label: '참조권 변경', type: 'outlined', icon: 'document-search', mode: 'dialog', },
    { sort: 10, code: 'changeViewers', label: '조회권 변경', type: 'outlined', icon: 'document-search', mode: 'dialog', },
    { sort: 11, code: 'history', label: '변경내역 확인', type: 'outlined', icon: 'history', mode: 'dialog', },
    { sort: 12, code: 'copy', label: '복사', type: 'outlined', icon: 'copy', mode: 'dialog', },
    { sort: 13, code: 'delete', label: '삭제', type: 'outlined', icon: 'trash', mode: 'dialog', },
    { sort: 14, code: 'deleteForever', label: '완전삭제', type: 'outlined', icon: 'trash', mode: 'dialog', },
    { sort: 15, code: 'save', label: 'PC저장', type: 'outlined', icon: 'save', mode: 'click', },
    { sort: 16, code: 'print', label: '인쇄', type: 'outlined', icon: 'print', mode: 'dialog', },
    { sort: 17, code: 'mail', label: '메일발송', type: 'outlined', icon: 'mail', mode: 'dialog', },
  ];

  // prettier-ignore
  const actionEvent: ActionEventPropsSort[] = [];

  // prettier-ignore
  // 모든 문서에 보임.
  actionEvent.push(
    { sort: 4, code: 'info', label: '문서정보', type: 'outlined', icon: 'info-circle', mode: 'drawer', },
    { sort: 5, code: 'status', label: '결재현황', type: 'outlined', icon: 'line-dot', mode: 'drawer', },
    { sort: 6, code: 'viewer', label: '조회', type: 'outlined', icon: 'eye', mode: 'drawer', },
    { sort: 11, code: 'history', label: '변경내역 확인', type: 'outlined', icon: 'history', mode: 'dialog', },
    { sort: 15, code: 'save', label: 'PC저장', type: 'outlined', icon: 'save', mode: 'click', },
    { sort: 16, code: 'print', label: '인쇄', type: 'outlined', icon: 'print', mode: 'dialog', },
  );

  /*
  // 요구(진행)에서만 노출, 결재자여야 하고, 내 차례에서만 보임
  if (nowStatus === 1 && isApprovedLine && isApprovedNow) {
    // prettier-ignore
    actionEvent.push(
      { sort: 1, code: 'approval', label: '결재', type: 'contained', icon: 'stamp-check', mode: 'dialog', },
      // {sort: 2,  code: 'internal',           label: '내부결재',       type: 'contained',  icon: 'stamp-check',      mode: 'click',},
      { sort: 7, code: 'change', label: '문서 변경', type: 'outlined', icon: 'document-update', mode: 'click', },
      { sort: 8, code: 'changeApprovalLine', label: '결재선 변경', type: 'outlined', icon: 'line-dot', mode: 'dialog', },
    );
  }

  // 결재자여야 하고, 내 차례에서만 보임
  if (isApprovedLine && isApprovedNow) {
    // prettier-ignore
    actionEvent.push(
      { sort: 9, code: 'changeReference', label: '참조권 변경', type: 'outlined', icon: 'document-search', mode: 'dialog', },
      { sort: 10, code: 'changeViewers', label: '조회권 변경', type: 'outlined', icon: 'document-search', mode: 'dialog', },
    );
  }

  // 기안자여야 하고
  if (isDrafter) {
    // 개인결재함이라면
    if (nowFolderId >= 123 && nowFolderId <= 456) {
      // 완료된 결재 문서라면
      if (isApprovedComplete) {
        // prettier-ignore
        actionEvent.push(
          { sort: 3, code: 'reDraft', label: '재기안', type: 'contained', icon: 'stamp-check', mode: 'dialog', },
          { sort: 12, code: 'copy', label: '복사', type: 'outlined', icon: 'copy', mode: 'dialog', },
        );
      }

      // 삭제함
      if (nowFolderId !== 0) {
        // prettier-ignore
        actionEvent.push(
          { sort: 13, code: 'delete', label: '삭제', type: 'outlined', icon: 'trash', mode: 'dialog', },
        );
        // 삭제함 제외
      } else {
        // prettier-ignore
        actionEvent.push(
          { sort: 14, code: 'deleteForever', label: '완전삭제', type: 'outlined', icon: 'trash', mode: 'dialog', },
        );
      }
    }
  }
  // sort 순으로 정렬
  const compareSort = (a: ActionEventPropsSort, b: ActionEventPropsSort) => {
    return a.sort - b.sort;
  };
  actionEvent.sort(compareSort);
  */

  const { type } = props;
  const {
    affiliatedCompanyId,
    subject,
    content,
    draftAt,
    completeAt,
    approvalLine,
    attachedFiles,
    sharedFiles,
    attachedDocuments,
    parentAffiliatedCompanyId,
    parentCompanyId,
    parentId,
    parentApprovalLine,
    parentDrafteAt,
    parentCompleteAt,
  } = view;

  const drafter = getApprovalLineDrafter(view.approvalLine);
  if (drafter === undefined) return <div>오류</div>;

  const { organizationName: draftOrganizationName, employeeName: drafterName } =
    drafter;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : undefined;

  /** 부모 문서 정보(내부결재인 경우에만 있음) */
  let parentDrafterOrganizationName: string | undefined;
  let parentDrafterName: string | undefined;
  let parentDrafterAvatar: string | undefined;
  let parentLastApprovalAt: string | undefined;
  let parentWaiteAt: string | undefined;
  if (view.parentApprovalLine) {
    const parentDrafter = getApprovalLineDrafter(view.parentApprovalLine);
    if (parentDrafter === undefined) return <div>오류</div>;

    parentDrafterOrganizationName = parentDrafter.organizationName;
    parentDrafterName = parentDrafter.employeeName;
    parentDrafterAvatar = parentDrafter.employeeId
      ? getAvatarPath(parentDrafter)
      : undefined;
    parentLastApprovalAt =
      parentCompleteAt === undefined
        ? getLastApprovalAt(view.parentApprovalLine)
        : undefined;
    parentWaiteAt =
      parentLastApprovalAt === undefined
        ? undefined
        : getWaitedAt(parentLastApprovalAt);
  }

  const {
    employeeName: userName,
    organizationName: userOrganizationName,
    jobClassName: userJobClassName,
  } = getDirectoryData({
    ...directory,
    ...principal,
  });
  const userAvatar = getAvatarPath(principal);
  const user = {
    key: `${principal.companyId}/${principal.employeeId}`,
    name: userName,
    organization: userOrganizationName,
    class: userJobClassName,
    avatar: userAvatar,
  };

  /** 마지막 결재 날짜 */
  const lastApprovalAt =
    completeAt === undefined ? getLastApprovalAt(view.approvalLine) : undefined;

  /** 대기 시간 */
  const waitedAt =
    lastApprovalAt === undefined ? undefined : getWaitedAt(lastApprovalAt);

  /** 첨부 문서 조회 */
  const handleAttachedDocumentPopup = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument';
    documentId: number;
    affiliatedCompanyId?: number;
  }) => {
    props.onAttachedDocumentPopup(arg);
  };

  /** 부모 문서 조회. */
  const handleParentDocumentPopup = (arg: {
    dialogType: 'parentdocument' | 'attacheddocument';
    documentId: number;
    affiliatedCompanyId?: number;
    receiptDocumentId: number;
    receiptAffiliatedCompanyId?: number;
  }) => {
    props.onAttachedDocumentPopup(arg);
  };

  const handleAttachedFilePopup = (id: number) => {
    const file = view.attachedFiles?.find((a) => a.id === id);
    if (file === undefined) {
      setValidation('미리보기를 불러올 수 없습니다.');
      return;
    }
    if (props.onAttachedFilePopup) props.onAttachedFilePopup(file);
  };

  const handleSharedFilePopup = (id: number) => {
    const file = view.sharedFiles?.find((a) => a.id === id);
    if (file === undefined) {
      setValidation('미리보기를 불러올 수 없습니다.');
      return;
    }
    if (props.onSharedFilePopup) props.onSharedFilePopup(file);
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setValidation('');
  };

  /** 결재문서 관리 객체 타입 가드 */
  const handleIsDocumentView = (arg: any): arg is DocumentView => {
    if (arg === undefined || arg === null) return false;
    return arg.linkWait !== undefined;
  };

  const element = (
    <PostView type={type}>
      <PostView.Head>
        <PostView.Title
          chip={
            handleIsDocumentView(view) && view.linkWait
              ? { label: '연동대기', theme: 'primary' }
              : undefined
          }
        >
          {subject}
        </PostView.Title>
        {parentApprovalLine && parentCompanyId && parentId && (
          <div
            className="approval-view-info"
            style={{
              paddingBottom: '16px',
              borderBottom: '1px solid var(--line-color)',
            }}
          >
            <Avatar
              className="avatar"
              name={parentDrafterName ?? parentDrafterOrganizationName ?? ''}
              image={parentDrafterAvatar}
              icon={parentDrafterAvatar ? undefined : 'sitemap-fill'}
            />
            <ApprovalLineFlat approvalLine={parentApprovalLine} />
            <Button
              parentDocument
              className="action"
              text="원문서 보기"
              iconType
              icon="document-search"
              onClick={() =>
                handleParentDocumentPopup({
                  dialogType: 'parentdocument' as const,
                  documentId: parentId,
                  affiliatedCompanyId: parentAffiliatedCompanyId ?? undefined,
                  receiptAffiliatedCompanyId: view.affiliatedCompanyId,
                  receiptDocumentId: view.id,
                })
              }
              color="secondary"
            />
            <PostView.Info row>
              <PostView.InfoItem
                title="기안자"
                value={parentDrafterName ?? ''}
              />
              <PostView.InfoItem
                title="기안부서"
                value={parentDrafterOrganizationName ?? ''}
              />
              <PostView.InfoItem title="기안일" value={parentDrafteAt ?? ''} />
              {parentCompleteAt && (
                <PostView.InfoItem title="완료일" value={parentCompleteAt} />
              )}
              {parentWaiteAt && (
                <PostView.InfoItem title="대기일" value={parentWaiteAt} />
              )}
            </PostView.Info>
          </div>
        )}
        <div className="approval-view-info">
          <Avatar
            className="avatar"
            name={drafterName ?? draftOrganizationName}
            image={drafterAvatar}
            icon={drafterAvatar ? undefined : 'sitemap-fill'}
          />
          <ApprovalLineFlat approvalLine={approvalLine} />
          <PostView.Info row>
            <PostView.InfoItem
              title="기안자"
              value={drafterName ?? draftOrganizationName}
            />
            <PostView.InfoItem title="기안부서" value={draftOrganizationName} />
            <PostView.InfoItem title="기안일" value={draftAt ?? ''} />
            {completeAt && (
              <PostView.InfoItem title="완료일" value={completeAt} />
            )}
            {waitedAt && <PostView.InfoItem title="대기일" value={waitedAt} />}
          </PostView.Info>
        </div>
      </PostView.Head>
      <PostView.Body>
        <PostView.Content data={content} />
        {attachedFiles && attachedFiles.length > 0 && (
          <AttachmentsList
            count={attachedFiles.length}
            className="view-attachments"
            saveAll={
              display === 'pc'
                ? () =>
                    handleAttachedFileDownloadAll({
                      companyId: attachedFiles[0].companyId,
                      documentId: attachedFiles[0].documentId,
                    })
                : undefined
            }
          >
            {attachedFiles.map(
              ({ companyId, documentId, id, name, size, url }) =>
                url ? (
                  <AttachmentsItem
                    key={id}
                    name={name}
                    size={size}
                    onClick={
                      display === 'pc'
                        ? () =>
                            handleAttachedFileDownload({
                              companyId,
                              documentId,
                              id,
                              name,
                            })
                        : undefined
                    }
                    onView={() => handleAttachedFilePopup(id)}
                  />
                ) : (
                  <AttachmentsItem
                    key={id}
                    name={name}
                    size={size}
                    onClick={() =>
                      handleAttachedFileDownload({
                        companyId,
                        documentId,
                        id,
                        name,
                      })
                    }
                  />
                ),
            )}
          </AttachmentsList>
        )}
        {sharedFiles && sharedFiles.length > 0 && (
          <AttachmentsList
            title="공유파일"
            count={sharedFiles.length}
            className="view-attachments"
          >
            {sharedFiles.map(
              ({ documentId, id, name, size, downloadUrl, viewerUrl }) => (
                <AttachmentsItem
                  key={id}
                  name={name}
                  size={size}
                  onClick={
                    downloadUrl !== undefined &&
                    downloadUrl !== null &&
                    display === 'pc'
                      ? () =>
                          handleSharedFileDownload({
                            documentId,
                            id,
                            name,
                          })
                      : undefined
                  }
                  onView={
                    viewerUrl !== undefined && viewerUrl !== null
                      ? () => handleSharedFilePopup(id)
                      : undefined
                  }
                />
              ),
            )}
          </AttachmentsList>
        )}
        {attachedDocuments && attachedDocuments.length > 0 && (
          <AttachDocumentList count={attachedDocuments.length}>
            {attachedDocuments.map((x) => (
              <AttachDocumentItem
                key={x.id}
                no={documentMacroReplace(x.no)}
                subject={x.subject}
                onClick={() =>
                  handleAttachedDocumentPopup({
                    dialogType: 'attacheddocument',
                    affiliatedCompanyId,
                    documentId: x.id,
                  })
                }
              />
            ))}
          </AttachDocumentList>
        )}
        {opinions && (
          <Comment
            opinion
            count={view.opinions?.filter((x) => !x.isDeleted).length || 0}
            user={user}
            comments={opinions}
            onAdd={handleOpinionAppend}
            onDelete={handleOpinionDelete}
          />
        )}
        {comments && (
          <Comment
            count={view.comments?.filter((x) => !x.isDeleted).length || 0}
            user={user}
            comments={comments}
            onAdd={
              props.isCommentDisable === undefined ||
              props.isCommentDisable === false
                ? handleCommentAppend
                : undefined
            }
            onDelete={handleCommentDelete}
          />
        )}
      </PostView.Body>
    </PostView>
  );

  return (
    <div className="ui-view-root" ref={scrollbar}>
      {element}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </div>
  );
};

export default ApprovalContentBodyViewContainer;

export { getCommentItems };
