import React from 'react';
import CalendarDrawer from '../pages/root/calendar/common/CalendarDrawer';

function CalendarLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;

  return <CalendarDrawer pathname={pathname} search={search} hash={hash} />;
}

export default CalendarLocateDrawer;
