import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import userPreferencesApi from '../../apis/calendar/v1/userPreferences';
import { CalendarColorType } from './preferences';

const name = 'calendar/userPreferences';

export interface UserPreferencesBasic {
  employeeId: number;
  initialFrame: string; // 초기화면. (일간: 'DAILY', 주간: 'WEEKLY', 월간: 'MONTHLY')
  firstDayOfWeek: string; // 한 주의 시작. (일요일: 'SUN', 월요일: 'MON')
  updateAt: string;
}

interface State {
  basic: UserPreferencesBasic;
  color: CalendarColorType[];
}

const initialState: State = {
  basic: {
    employeeId: 0,
    initialFrame: 'MONTHLY',
    firstDayOfWeek: 'SUN',
    updateAt: '1000-01-01T00:00:00',
  },
  color: [],
};

/** 환경설정 - 기본설정 조회. */
const findBasic = createAsyncThunk(
  `${name}/findBasic`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      const { employeeId } = (getState() as RootState).session.principal;
      const data = await userPreferencesApi.find(employeeId);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 환경설정 - 기본설정 저장. */
const saveBasic = createAsyncThunk(
  `${name}/saveBasic`,
  async (
    arg: {
      initialFrame: string;
      firstDayOfWeek: string;
      updateAt: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { initialFrame, firstDayOfWeek, updateAt } = arg;
      const { employeeId } = (getState() as RootState).session.principal;
      const data = { initialFrame, firstDayOfWeek, updateAt };

      const result = await userPreferencesApi.update({ employeeId, data });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 사용자 - 캘린더 색상 조회. */
const findColorList = createAsyncThunk(
  `${name}/findColorList`,
  async (_: void, { rejectWithValue }) => {
    try {
      const result = await userPreferencesApi.colorList();
      const color = result
        .map((a) => ({
          name: a.name,
          rgb: `#${a.rgb}`,
          seq: a.seq,
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return color;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const userPreferencesReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findBasic.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.basic = payload;
      })
      .addCase(saveBasic.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.basic = payload;
      })
      .addCase(findColorList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.color = payload;
      });
  },
});

export default userPreferencesReducer.reducer;

export const userPreferencesActions = {
  basic: findBasic,
  saveBasic,

  colorList: findColorList,
};
