import React from 'react';
import Button from '../../../../../components/button/Button';
import Loading from '../../../../../components/loading/Loading';

type Props = {
  html: string;
  title?: string;
  scrollbar?: React.RefObject<HTMLDivElement>;
  loading: boolean;
  onClick?(): void;
};

function AttendanceWorkFormPreview(props: Props): JSX.Element {
  const { title, html, scrollbar } = props;

  return (
    <>
      {props.loading && <Loading />}
      <div
        style={{
          display: 'flex',
          flexShrink: 0,
          alignItems: 'center',
          padding: '24px',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: 1,
          }}
        >
          {title}
        </div>
        <div style={{ flexShrink: 0, margin: '-8px 0', paddingLeft: '16px' }}>
          <Button text="미리보기 닫기" onClick={props.onClick} />
        </div>
      </div>
      <div
        className="ui-view-root"
        ref={scrollbar}
        style={{
          overflow: 'auto',
          height: 'auto',
          padding: '0 24px 24px',
        }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  );
}

export default AttendanceWorkFormPreview;
