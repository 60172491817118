import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Radio from '../../../../../components/radio/Radio';

function ComponentRadioBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic radios">
      <Radio />
      <Radio disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentRadioBasic;
