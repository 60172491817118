import { resourceReload } from '.';
import i18nApi from '../../groupware-webapp/apis/i18n/v1';
import store from '../../groupware-webapp/app/store';

const i18nIntervalID = setInterval(async () => {
  const ko = store.getState().i18n.locales['ko-KR'];

  if (ko === undefined || ko.directory === undefined) return;

  const directoryLastUpdateAt = ko.directory.lastUpdateAt;
  const jobClassLastUpdateAt = ko.jobclass.lastUpdateAt;

  const langs = ['ko-KR'];
  const namespace: string[] = [];

  const directoryUpdateAt = (
    await i18nApi.findLastupdateAt({
      language: 'ko-KR',
      namespace: 'directory',
    })
  ).lastUpdateAt;

  const jobclassUpdateAt = (
    await i18nApi.findLastupdateAt({
      language: 'ko-KR',
      namespace: 'jobclass',
    })
  ).lastUpdateAt;

  if (directoryLastUpdateAt !== directoryUpdateAt) {
    namespace.push('directory');
  }

  if (jobClassLastUpdateAt !== jobclassUpdateAt) {
    namespace.push('jobclass');
  }

  if (namespace.length > 0) {
    resourceReload(langs, namespace);
  }
}, 3600000);
