import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { LocateArg } from '../../../groupware-common/types';
import { RootState } from '../../../groupware-webapp/app/store';
import { getQueryParams } from '../../../groupware-common/utils';
import schedulesApi from '../../apis/calendar/v1/schedules';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../groupware-common/utils/ui';
import { RepeatDaysType } from '../../../components/repeatDialog/DetailRepeatDialog';
import calendarsApi from '../../apis/calendar/v1/calendars';
import { getEmployeeName } from '../../../groupware-directory/stores/directory';

const name = 'calendar/schedules';

/**
 *  참석자 상태
 * - [WAIT] 대기
 * - [ACCEPT] 수락
 * - [REJECT] 거절
 * - [UNANSWER] 미정
 * - [DONOT]
 * */
export type ParticipantStatusProps =
  | 'WAIT'
  | 'ACCEPT'
  | 'REJECT'
  | 'UNANSWER'
  | 'DONOT';

/** 일정 참석자 객체 */
export interface ParticipantType {
  participantCompanyId: number;
  participantId: number;
  participantReply?: ParticipantStatusProps;
  updateAt: string;
}

export interface ScheduleList {
  companyId: number;
  id: number;
  rootEventId: number; // 공유 받은 일정일 경우 원본 일정의 아이디. 0인 경우 원본 일정.
  calendarId: number;
  name: string;
  startDateTime: string;
  endDateTime: string;
  isFull: boolean; // 종일 여부.
  isRepeat: boolean; // 반복 일정 여부.
  employeeId: number; // 주최자 아이디.
  reply?: ParticipantStatusProps; // 공유 받은 일정일 경우 일정 참석 여부 상태 표시.
  updateAt: string;
}

export interface ScheduleSubList {
  id: string;
  employeeId: number; // 휴일 캘린더인 경우 employeeId 0
  calendarId: number;
  name: string;
  codeName?: string; // 근태 캘린더인 경우 근태 코드.
  startDateTime: string;
  endDateTime: string;
  isFull: boolean; // 종일 여부.
}

export interface ScheduleView {
  id: number;
  rootEventId: number; // 공유 받은 일정일 경우 원본 일정의 아이디. 0인 경우 원본 일정.
  calendarId: number;
  employeeId: number; // 주최자 아이디.
  name: string;
  description: string;
  startDateTime: string;
  endDateTime: string;
  isFull: boolean; // 종일 여부.
  repeat?: {
    eventId: number;
    frequency: number; // 반복 빈도, 일: 1 주: 2 월: 3 년: 4
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
    repeatDays: string[]; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    endDate: string; // 반복 종료일
  };
  alarms: {
    id: number;
    type: string; // mail | alarm
    timeUnit: string; // MINUTE, HOUR, DAY, WEEK
    ammount: number;
    updateAt: string;
  }[];
  resource?: {
    resourceItemId: number;
    resourceReservationId: number;
    status: string;
    updateAt: string;
  };
  participants: ParticipantType[];
  updateAt: string;
}

export interface UpdateData {
  calendarId: number;
  calendarName: string;
  start: string;
  end: string;
  isAllDay: boolean;
  useResource: boolean;
  resourceItemId?: number;
  name: string;
  description: string;
  originStart: string;
  originEnd: string;
  repeatType?: string;
}

interface State {
  list: ScheduleList[];
  subList: ScheduleSubList[];
  view: ScheduleView | undefined;

  updateData: UpdateData | undefined;
}

const initialState: State = {
  list: [],
  subList: [],
  view: undefined,

  updateData: undefined,
};

/** 예약 조회 날짜 포맷 */
function scheduleDate(arg: {
  hash: string;
  date?: string;
  firstDayOfWeek?: string;
  type: 'start' | 'end';
}): string {
  const { hash, firstDayOfWeek, type } = arg;
  let date = timezoneDate(arg.date);
  if (type === 'start') {
    if (hash === '#weekly') {
      if (firstDayOfWeek === 'SUN') {
        // 한 주의 시작일 = 일요일 && 오늘 날짜가 일요일이 아닐 경우 해당 주의 일요일 계산.
        if (date.getDay() !== 0) date.setDate(date.getDate() - date.getDay());
      }
      // 한 주의 시작일 = 월요일 && 오늘 날짜가 월요일이 아닐 경우 해당 주의 월요일 계산.
      else if (date.getDay() !== 1) {
        if (date.getDay() === 0) date.setDate(date.getDate() - 6);
        else date.setDate(date.getDate() - (date.getDay() - 1));
      }
    }
    if (hash === '#monthly') {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      startDate.setDate(-6);
      date = new Date(startDate);
    }
  } else {
    if (hash === '#weekly') date.setDate(date.getDate() + 6);
    if (hash === '#monthly') {
      const endDate = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      endDate.setDate(endDate.getDate() + 12);
      date = new Date(endDate);
    }
  }
  return dateFormat(initialDate(date), 'yyyy-MM-DD');
}

/** 반복 요일 json 객체로 parse */
function jsonToRepeatDays(date: Date, a: string | null): string[] {
  if (a === null) {
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const day = date.getDay();
    return [week[day]];
  }
  const json: {
    days: number[];
  } = JSON.parse(a);
  const dateDiff = date.getDate() - initialDate(date).getDate();
  const repeatDays: string[] = [];
  for (let i = 0; i < Object.values(json)[0].length; i += 1) {
    const value = Object.values(json)[0][i] + dateDiff;
    if (value === 1) repeatDays.push('월');
    if (value === 2) repeatDays.push('화');
    if (value === 3) repeatDays.push('수');
    if (value === 4) repeatDays.push('목');
    if (value === 5) repeatDays.push('금');
    if (value === 6) repeatDays.push('토');
    if (value === 7) repeatDays.push('일');
  }
  return repeatDays;
}

/** 반복 요일 객체 json stringfy */
function repeatDaysToJson(days: RepeatDaysType, date: Date): string {
  const dateDiff = date.getDate() - initialDate(date).getDate();
  const object: number[] = [];
  Object.keys(days).forEach((a, i) => {
    if (Object.values(days)[i]) {
      if (a === 'mon') object.push(1);
      else if (a === 'tue') object.push(2);
      else if (a === 'wed') object.push(3);
      else if (a === 'thu') object.push(4);
      else if (a === 'fri') object.push(5);
      else if (a === 'sat') object.push(6);
      else object.push(7);
    }
  });
  const value = {
    days: object.map((a) => {
      let day = a - dateDiff;
      if (day < 1) day += 7;
      if (day > 7) day -= 7;
      return day;
    }),
  };
  return JSON.stringify(value);
}

/** 반복 빈도 타입 변경 */
function changeFrequency(frequency: string | number): number | string {
  if (typeof frequency === 'string')
    switch (frequency) {
      case 'DAYS':
        return 1;
      case 'WEEKS':
        return 2;
      case 'MONTHS':
        return 3;
      case 'YEARS':
        return 4;
      default:
        return 1;
    }
  else
    switch (frequency) {
      case 1:
        return 'DAYS';
      case 2:
        return 'WEEKS';
      case 3:
        return 'MONTHS';
      case 4:
        return 'YEARS';
      default:
        return 'DAYS';
    }
}

/** 반복 기준 타입 변경 (반복 빈도가 월일 경우) */
function changeMonth(monthRepeatStandard: string | number) {
  if (typeof monthRepeatStandard === 'string')
    switch (monthRepeatStandard) {
      case 'DATE':
        return 0;
      case 'DAY':
        return 1;
      case 'LAST':
        return 2;
      default:
        return 0;
    }
  else
    switch (monthRepeatStandard) {
      case 0:
        return 'DATE';
      case 1:
        return 'DAY';
      case 2:
        return 'LAST';
      default:
        return 'DATE';
    }
}

/** 내 일정 조회 이벤트. */
const findSchedules = createAsyncThunk(
  `${name}/findSchedules`,
  async (
    arg: {
      search: string;
      hash?: string;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { companyId, affiliatedOrganizations, employeeId } = (
        getState() as RootState
      ).session.principal;
      const { basic } = (getState() as RootState).calendar.userPreferences;
      const queryParams = getQueryParams(arg.search);
      const hash =
        arg.route?.hash ?? (getState() as RootState).session.route.hash;
      const fromDate = scheduleDate({
        hash,
        date: queryParams.startDate,
        firstDayOfWeek: basic.firstDayOfWeek,
        type: 'start',
      });
      const toDate = scheduleDate({ hash, date: fromDate, type: 'end' });
      const response = await schedulesApi.findSchedules({
        employeeId,
        fromDate,
        toDate,
      });
      const list = response
        .map((a) => ({
          ...a,
          reply: a.reply === null ? undefined : a.reply,
        }))
        .sort(
          (a, b) => +new Date(a.startDateTime) - +new Date(b.startDateTime),
        );

      const subList: {
        codeName?: string;
        employeeId: number;
        calendarId: number;
        name: string;
        startDateTime: string;
        endDateTime: string;
        isFull: boolean; // 종일 여부.
      }[] = [];
      const subCals = (
        await calendarsApi.userSubCalendarList(employeeId)
      ).filter((a) => a.status);
      for (let i = 0; i < subCals.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const calendar = await calendarsApi.userSubCalView({
          type: subCals[i].systemId,
          employeeId,
          id: subCals[i].id,
        });
        if (calendar.systemId === 'ATTENDANCE') {
          let attendanceCal: {
            employeeId: number;
            calendarId: number;
            codeName: string;
            startDateTime: string;
            endDateTime: string;
            isFull: boolean;
            isDelete: boolean;
          }[] = [];
          for (let j = 0; j < affiliatedOrganizations.length; j += 1) {
            const { id, manager } = affiliatedOrganizations[j];
            const option = manager
              ? calendar.managerAuthority ?? 'SUB_ORGANIZATION'
              : calendar.generalUserAuthority ?? 'EMPLOYEE';

            const result =
              // eslint-disable-next-line no-await-in-loop
              await schedulesApi.findAttendanceSchedules({
                option,
                companyId,
                organizationId: id,
                employeeId,
                fromDate,
                toDate,
              });
            const attendanceSchedules = result.map((x) => ({
              employeeId: x.employeeId,
              calendarId: calendar.id,
              codeName: x.codeName,
              startDateTime: x.startDateTime,
              endDateTime: x.endDateTime,
              isFull: true,
              isDelete: false,
            }));
            for (let k = 0; k < attendanceSchedules.length; k += 1)
              attendanceCal.push(attendanceSchedules[k]);
          }
          for (let k = 0; k < attendanceCal.length; k += 1) {
            for (let j = k + 1; j < attendanceCal.length; j += 1) {
              // 겸직부서 중복된 일정 제거.
              if (
                attendanceCal[k].employeeId === attendanceCal[j].employeeId &&
                attendanceCal[k].startDateTime ===
                  attendanceCal[j].startDateTime &&
                attendanceCal[k].endDateTime === attendanceCal[j].endDateTime &&
                attendanceCal[k].codeName === attendanceCal[j].codeName
              )
                attendanceCal = attendanceCal.map((a, index) => {
                  if (index === j) return { ...a, isDelete: true };
                  return a;
                });
            }
          }
          const filteredAttendance = attendanceCal
            .filter((a) => !a.isDelete)
            .map((a) => {
              const employeeName = getEmployeeName(companyId, a.employeeId);
              let subject = '';
              if (moment(a.startDateTime).isSame(a.endDateTime, 'days'))
                subject = `[${a.codeName}] ${dateFormat(
                  a.startDateTime,
                  'HH:mm',
                )} ~ ${dateFormat(a.endDateTime, 'HH:mm')} ${employeeName}`;
              else
                subject = `[${a.codeName}] ${dateFormat(
                  a.startDateTime,
                  'yyyy-MM-DD HH:mm',
                )} ~ ${dateFormat(
                  a.endDateTime,
                  'yyyy-MM-DD HH:mm',
                )} ${employeeName}`;

              return {
                employeeId: a.employeeId,
                calendarId: a.calendarId,
                codeName: a.codeName,
                name: subject,
                startDateTime: a.startDateTime,
                endDateTime: a.endDateTime,
                isFull: a.isFull,
              };
            });
          for (let k = 0; k < filteredAttendance.length; k += 1)
            subList.push(filteredAttendance[k]);
        } else if (calendar.systemId === 'HOLIDAY_KOREA') {
          // eslint-disable-next-line no-await-in-loop
          const result = await schedulesApi.findHolidaySchedules({
            useExposeAnniversary: calendar.useExposeAnniversary ?? false,
            employeeId,
            fromDate,
            toDate,
          });
          const holidayKoreaSchedules = result.map((a) => {
            const endDateTime = new Date(a.endDateTime);
            endDateTime.setDate(endDateTime.getDate() - 1);
            return {
              ...a,
              endDateTime: dateFormat(endDateTime, 'yyy-MM-DD[T]HH:mm:ss.SSS'),
              employeeId: 0,
            };
          });
          for (let k = 0; k < holidayKoreaSchedules.length; k += 1)
            subList.push(holidayKoreaSchedules[k]);
        }
      }
      const returnSubList = subList
        .map((a, i) => ({
          ...a,
          id: `${a.calendarId}_${i}`,
        }))
        .sort(
          (a, b) => +new Date(a.startDateTime) - +new Date(b.startDateTime),
        );
      return {
        list,
        subList: returnSubList,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 일정 단일 조회. */
const findScheduleView = createAsyncThunk(
  `${name}/findScheduleView`,
  async (
    arg: {
      calendarId: number;
      eventId: number;
      date?: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { calendarId, eventId, date } = arg;
      const data = await schedulesApi.findScheduleView({
        calendarId,
        eventId,
        date,
      });
      let frequency = 1;
      let monthRepeatStandard = 0;
      if (data.repeat !== null) {
        const changeFre = changeFrequency(data.repeat.frequency);
        if (typeof changeFre === 'number') frequency = changeFre;
        if (data.repeat.monthRepeatStandard !== null) {
          const changeMon = changeMonth(data.repeat.monthRepeatStandard);
          if (typeof changeMon === 'number') monthRepeatStandard = changeMon;
        }
      }
      const item: ScheduleView = {
        ...data,
        resource: data.resource !== null ? data.resource : undefined,
        repeat:
          data.repeat !== null
            ? {
                eventId: data.repeat.eventId,
                frequency,
                cycle: data.repeat.cycle,
                monthRepeatStandard,
                repeatDays: jsonToRepeatDays(
                  new Date(data.startDateTime),
                  data.repeat.repeatDays,
                ),
                endDate:
                  data.repeat.endDate !== '9999-12-31'
                    ? dateTimeFormat(data.repeat.endDate, 'yyyy-MM-DD')
                    : data.repeat.endDate,
              }
            : undefined,
        participants: data.participants.map((a) => ({
          ...a,
          participantReply:
            a.participantReply === null ? undefined : a.participantReply,
        })),
      };
      return item;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 일정 저장 */
const createSchedule = createAsyncThunk(
  `${name}/createSchedule`,
  async (
    arg: {
      calendarId: number;
      data: {
        employeeId: number; // 주최자 아이디.
        name: string;
        description: string;
        startDateTime: string;
        endDateTime: string;
        isFull: boolean; // 종일 여부.
        repeat?: {
          frequency: number; // 반복 빈도, 일: 1 주: 2 월: 3 년: 4
          cycle: number; // 반복 주기. 1, 2, 3, 4, ...
          monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
          days?: RepeatDaysType; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
          endDate: string; // 반복 종료일
        };
        resourceItemId?: number;
        participants: {
          companyId: number;
          employeeId: number;
        }[];
        alarms: {
          type: string; // mail | alarm
          timeUnit: string; // MINUTE, HOUR, DAY, WEEK
          ammount: number;
        }[];
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let frequency = 'DAYS';
      let monthRepeatStandard: string | undefined;
      if (arg.data.repeat) {
        const changeFre = changeFrequency(arg.data.repeat.frequency);
        if (typeof changeFre === 'string') frequency = changeFre;
        if (arg.data.repeat.monthRepeatStandard !== undefined) {
          const changeMon = changeMonth(arg.data.repeat.monthRepeatStandard);
          if (typeof changeMon === 'string') monthRepeatStandard = changeMon;
        }
      }
      const data = {
        ...arg.data,
        repeat: arg.data.repeat
          ? {
              frequency,
              cycle: arg.data.repeat.cycle,
              monthRepeatStandard,
              endDate: arg.data.repeat.endDate,
              days: arg.data.repeat.days
                ? repeatDaysToJson(
                    arg.data.repeat.days,
                    new Date(arg.data.startDateTime),
                  )
                : undefined,
            }
          : undefined,
      };
      const result = await schedulesApi.create({
        calendarId: arg.calendarId,
        data,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 공유받은 일정 수정 (알림만 수정) */
const updateSharedSchedule = createAsyncThunk(
  `${name}/updateSharedSchedule`,
  async (
    arg: {
      employeeId: number;
      eventId: number;
      data: {
        type: string;
        timeUnit: string;
        ammount: number;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await schedulesApi.updateSharedSchedule({
        employeeId: arg.employeeId,
        eventId: arg.eventId,
        data: arg.data,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 공유받은 일정 참석 여부 수정. */
const updateScheduleParticipantStatus = createAsyncThunk(
  `${name}/updateScheduleParticipantStatus`,
  async (
    arg: {
      employeeId: number;
      rootEventId: number;
      data: {
        eventStartDateTime: string;
        eventEndDateTime: string;
        answer: ParticipantStatusProps;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await schedulesApi.updateScheduleParticipantStatus({
        employeeId: arg.employeeId,
        rootEventId: arg.rootEventId,
        data: arg.data,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 일반 일정 수정. */
const updateSchedule = createAsyncThunk(
  `${name}/updateSchedule`,
  async (
    arg: {
      calendarId: number;
      eventId: number;
      data: {
        employeeId?: number;
        name?: string;
        description?: string;
        startDateTime: string;
        endDateTime: string;
        isFull: boolean;
        repeat?: {
          frequency: number; // 반복 빈도, 일: 1 주: 2 월: 3 년: 4
          cycle: number; // 반복 주기. 1, 2, 3, 4, ...
          monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
          days?: RepeatDaysType; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
          endDate: string; // 반복 종료일
          isDelete: boolean; // 삭제 여부
        };
        isResourceDelete?: boolean; // 자원 예약 삭제 여부.
        resourceItemId?: number; // 예약된 자원 아이템 아이디.
        participants?: {
          companyId: number;
          employeeId: number;
          updateAt?: string;
          isDelete: boolean; // 삭제 여부
        }[];
        alarms?: {
          id?: number;
          type: string; // MAIL | ALARM
          timeUnit: string; // MINUTE, HOUR, DAY, WEEK
          ammount: number;
          isDelete: boolean; // 삭제 여부
        }[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let frequency = 'DAYS';
      let monthRepeatStandard: string | undefined;
      if (arg.data.repeat) {
        const changeFre = changeFrequency(arg.data.repeat.frequency);
        if (typeof changeFre === 'string') frequency = changeFre;
        if (arg.data.repeat.monthRepeatStandard !== undefined) {
          const changeMon = changeMonth(arg.data.repeat.monthRepeatStandard);
          if (typeof changeMon === 'string') monthRepeatStandard = changeMon;
        }
      }
      const result = await schedulesApi.update({
        calendarId: arg.calendarId,
        eventId: arg.eventId,
        data: {
          ...arg.data,
          repeat: arg.data.repeat
            ? {
                frequency,
                cycle: arg.data.repeat.cycle,
                monthRepeatStandard,
                endDate: arg.data.repeat.endDate,
                days: arg.data.repeat.days
                  ? repeatDaysToJson(
                      arg.data.repeat.days,
                      new Date(arg.data.startDateTime),
                    )
                  : undefined,
                isDelete: arg.data.repeat.isDelete,
              }
            : undefined,
        },
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 일반 일정 삭제 */
const deleteSchedule = createAsyncThunk(
  `${name}/deleteSchedule`,
  async (
    arg: {
      calendarId: number;
      eventId: number;
      data: {
        eventUpdateAt: string;
        isDeleteResource: boolean; // 자원 예약 삭제 여부.
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { calendarId, eventId, data } = arg;
      const result = await schedulesApi.delete({ calendarId, eventId, data });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 반복 일정 삭제. */
const deleteRecurringSchedule = createAsyncThunk(
  `${name}/deleteReccuringSchedule`,
  async (
    arg: {
      data: {
        calendarId: number;
        eventId: number;
        repeatType: string;
        updateAt: string;
        startDate: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await schedulesApi.deleteRecurringSchedule(arg.data);
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 반복 일정 수정. */
const updateRecurringSchedule = createAsyncThunk(
  `${name}/updateRecurringSchedule`,
  async (
    arg: {
      calendarId: number;
      eventId: number;
      repeatType: string;
      data: {
        employeeId?: number;
        name?: string;
        description?: string;
        startDateTime: string;
        endDateTime: string;
        isFull: boolean;
        repeat?: {
          frequency: number; // 반복 빈도, 일: 1 주: 2 월: 3 년: 4
          cycle: number; // 반복 주기. 1, 2, 3, 4, ...
          monthRepeatStandard?: number; // 반복 기준(반복빈도가 월), [0 : 매월NN일, 1 : N번째 A요일, 2 : 마지막 A요일]
          days?: RepeatDaysType; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
          endDate: string; // 반복 종료일
          isDelete: boolean; // 삭제 여부
        };
        participants?: {
          companyId: number;
          employeeId: number;
          isDelete: boolean; // 삭제 여부
        }[];
        alarms?: {
          id?: number;
          type: string; // MAIL | ALARM
          timeUnit: string; // MINUTE, HOUR, DAY, WEEK
          ammount: number;
          isDelete: boolean; // 삭제 여부
        }[];
        lookupStartDateTime?: string; // 변경 전 시작 시간.(이 일정, 이 일정 이후)
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let frequency = 'DAYS';
      let monthRepeatStandard: string | undefined;
      if (arg.data.repeat) {
        const changeFre = changeFrequency(arg.data.repeat.frequency);
        if (typeof changeFre === 'string') frequency = changeFre;
        if (arg.data.repeat.monthRepeatStandard !== undefined) {
          const changeMon = changeMonth(arg.data.repeat.monthRepeatStandard);
          if (typeof changeMon === 'string') monthRepeatStandard = changeMon;
        }
      }
      const result = await schedulesApi.updateRecurringSchedule({
        calendarId: arg.calendarId,
        eventId: arg.eventId,
        repeatType: arg.repeatType,
        data: {
          ...arg.data,
          repeat: arg.data.repeat
            ? {
                frequency,
                cycle: arg.data.repeat.cycle,
                monthRepeatStandard,
                endDate: arg.data.repeat.endDate,
                days: arg.data.repeat.days
                  ? repeatDaysToJson(
                      arg.data.repeat.days,
                      new Date(arg.data.startDateTime),
                    )
                  : undefined,
                isDelete: arg.data.repeat.isDelete,
              }
            : undefined,
        },
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 공유받은 일정 삭제. */
const deleteSharedSchedule = createAsyncThunk(
  `${name}/deleteSharedSchedule`,
  async (
    arg: {
      eventId: number;
      employeeId: number;
      eventUpdateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const result = await schedulesApi.deleteSharedSchedule({
        employeeId: arg.employeeId,
        eventId: arg.eventId,
        eventUpdateAt: arg.eventUpdateAt,
      });
      return result;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const schedulesSlice = createSlice({
  name,
  initialState,
  reducers: {
    viewClear(state) {
      state.view = undefined;
    },
    updateData(state, action: PayloadAction<UpdateData>) {
      state.updateData = action.payload;
    },
    updateDataClear(state) {
      state.updateData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findSchedules.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.list = payload.list;
          state.subList = payload.subList;
          state.view = undefined;
          state.updateData = undefined;
        }
      })
      .addCase(findScheduleView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = payload;
      })
      .addCase(createSchedule.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.view = undefined;
      });
  },
});

export default schedulesSlice.reducer;

export const schedulesActions = {
  viewClear: schedulesSlice.actions.viewClear,

  updateData: schedulesSlice.actions.updateData,
  updateDataClear: schedulesSlice.actions.updateDataClear,

  list: findSchedules,
  view: findScheduleView,

  create: createSchedule,

  updateSharedSchedule,
  updateScheduleParticipantStatus,

  update: updateSchedule,
  updateRecurringSchedule,

  delete: deleteSchedule,
  deleteRecurringSchedule,
  deleteSharedSchedule,
};
