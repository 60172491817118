import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import PostWrite from '../../../../../components/post/PostWrite';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import TreeList from '../../../../../components/tree/TreeList';
import { CustomNumbers } from '../../../../../groupware-common/types';
import { hangul } from '../../../../../groupware-common/utils';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState as R,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import {
  folderBoxActions,
  PermissionType,
  PermissionUserType,
} from '../../../../stores/folder';
import BoardPermissionDialogContainer from '../folderbox/BoardPermissionDialogContainer';

function BoardAuthorityContainer(): JSX.Element {
  const dispatch = useAppDispatch();

  const directory = useDirectory();
  const employees = useSelector((s: R) => s.directory.employee.list.data.items);
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );
  const list = useSelector((s: R) => s.boards.folder.adminconsole.list);
  const display = useSelector((s: R) => s.session.display);
  const categories = useSelector((s: R) => s.boards.board.category).filter(
    (a) => a.type === 'setting',
  );
  const title = categories.find((a) => a.id === 6003)?.name ?? '';

  const initialState = {
    items: list
      .map((x) => {
        return {
          id: x.id,
          parentId: x.parentId,
          seq: x.seq,
          text: x.name,
          icon: 'folder' as const,
          strings: hangul.d(x.name),
          checked: false,
          anonymous: x.option.useAnonymous,
          updateAt: x.updateAt,
        };
      })
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    managers: [],
    users: [],
    exceptioners: [],
    managerMenuPoint: undefined,
    excluderMenuPoint: undefined,
    sharersPermission: false,
    change: false,
    saveing: false,
    isView: display !== 'phone',
  };

  const [state, setState] = useState<{
    filter: string;
    items: {
      id: number;
      parentId: number;
      seq: number;
      text: string;
      icon: 'folder';
      strings: string[][];
      checked: boolean;
      anonymous: boolean;
      updateAt: string;
    }[];
    managers: PermissionType[];
    users: PermissionUserType[];
    exceptioners: PermissionType[];

    managerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    excluderMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    sharersPermission: boolean;

    change: boolean;
    saveing: boolean;
    isView: boolean;
    validation: string;
  }>({
    filter: '',
    ...initialState,
    validation: '',
  });

  useEffect(() => {
    async function run() {
      const { managers, users, exceptioners } = state;
      const folders = state.items
        .filter(({ checked }) => checked)
        .map((a) => ({
          id: a.id,
          updateAt: a.updateAt,
        }));
      dispatch(
        folderBoxActions.bulkAuthority({
          folders,
          managers,
          users,
          exceptioners,
        }),
      ).then(() => {
        setState((prev) => ({
          ...prev,
          ...initialState,
        }));
      });
    }

    if (state.saveing) run();
  }, [state.saveing]);

  const handleCancel = () => {
    setState((prev) => ({
      ...prev,
      ...initialState,
    }));
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      filter: event.target.value,
    }));
  };

  const handleFolderClick = (id: number) => {
    const items = state.items.map((a) => {
      if (a.id === id) return { ...a, checked: !a.checked };
      return a;
    });

    if (items.some(({ checked }) => checked === true))
      setState((prev) => ({
        ...prev,
        items,
      }));
    else
      setState((prev) => ({
        ...prev,
        items,
        managers: [],
        users: [],
        exceptioners: [],
        change: false,
      }));
  };

  const handleFolderCheck = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const items = state.items.map((a) => {
      if (a.id === id) return { ...a, checked: event.target.checked };
      return a;
    });

    if (items.some(({ checked }) => checked === true))
      setState((prev) => ({
        ...prev,
        items,
      }));
    else
      setState((prev) => ({
        ...prev,
        items,
        managers: [],
        users: [],
        exceptioners: [],
        change: false,
      }));
  };

  /** 저장 */
  const handleSave = () => {
    const permissions: {
      referenceCompanyId: number; // 사용자 회사 아이디
      referenceId: number; // 사용자 아이디 (organization | employee)
      referenceType: string; // 참조 유형
    }[] = [];
    state.managers.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.users.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    state.exceptioners.forEach((a) => {
      permissions.push({
        referenceCompanyId: a.referenceCompanyId,
        referenceId: a.referenceId,
        referenceType: a.referenceType,
      });
    });
    let isDuplicate = false;
    for (let i = 0; i < permissions.length; i += 1) {
      for (let j = 0; j < i; j += 1) {
        if (
          permissions[i].referenceType === permissions[j].referenceType &&
          permissions[i].referenceId === permissions[j].referenceId
        ) {
          isDuplicate = true;
          break;
        }
      }
    }
    if (isDuplicate) {
      setState((prev) => ({
        ...prev,
        validation:
          '관리자 또는 공유자, 예외자 인원을 중복으로 설정할 수 없습니다.',
      }));
      return;
    }

    setState((prev) => ({ ...prev, saveing: true }));
  };

  /** 관리자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { managerMenuPoint } = state;
    if (event !== undefined && managerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        managerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        managerMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 아이템 추가. */
  const handleManagerAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.managers.some((a) => a.referenceId === employeeId))
          return { ...prev, managerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, managerMenuPoint: undefined };

        return {
          ...prev,
          managers: [
            ...prev.managers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
              options: {
                isRead: true,
                isWrite: true,
                isMove: true,
                // isCopy: true,
              },
            },
          ],
          change: true,
        };
      });
    }
  };

  /** 관리자 아이템 삭제. */
  const handleManagerDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      managers: prev.managers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
      change: true,
    }));
  };

  /** 공유자 권한 조회 */
  const handleMatchingPermissions = (arg: {
    isRead: boolean;
    isWrite: boolean;
  }): string => {
    const matchingPermissions: string[] = [];
    if (arg.isRead) {
      matchingPermissions.push('읽기');
    }
    if (arg.isWrite) {
      matchingPermissions.push('쓰기');
    }
    return matchingPermissions.join('/');
  };

  /** 공유자 대화 상자 열기. */
  const handlePermissionOpenDialog = () => {
    setState((prev) => ({ ...prev, sharersPermission: true }));
  };

  /** 공유자 저장 */
  const handlePermissionDialogSave = (users: PermissionUserType[]) => {
    setState((prevState) => ({
      ...prevState,
      users,
      sharersPermission: false,
      change: true,
    }));
  };

  /** 공유자 삭제 */
  const handleSharersDelete = (id: number, referenceType: string) => {
    const { users } = state;
    setState((prev) => ({
      ...prev,
      users: users.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
      change: true,
    }));
  };

  /** 공유자 대화 상자 닫기. */
  const handlePermissionDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      sharersPermission: false,
    }));
  };

  /** 예외자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggleExcluder = (event?: React.MouseEvent) => {
    const { excluderMenuPoint } = state;
    if (event !== undefined && excluderMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        excluderMenuPoint: undefined,
      }));
    }
  };

  /** 예외자 아이템 삭제. */
  const handleExcluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      exceptioners: prev.exceptioners.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
      change: true,
    }));
  };

  /** 예외자 아이템 추가. */
  const handleExcluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) => a.referenceType === 'COMPANY' && a.referenceId === companyId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };
        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 'COMPANY',
              options: {
                isRead: false,
                isWrite: false,
                isMove: false,
                // isCopy: false,
              },
            },
          ],
          change: true,
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.exceptioners.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, excluderMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
              options: {
                isRead: false,
                isWrite: false,
                isMove: false,
                // isCopy: false,
              },
            },
          ],
          change: true,
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.exceptioners.some((a) => a.referenceId === employeeId))
          return { ...prev, excluderMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, excluderMenuPoint: undefined };

        return {
          ...prev,
          exceptioners: [
            ...prev.exceptioners,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
              options: {
                isRead: false,
                isWrite: false,
                isMove: false,
                // isCopy: false,
              },
            },
          ],
          change: true,
        };
      });
    }
  };

  // 모바일에서만 적용
  const handleCloseView = () => {
    setState((prev) => ({
      ...prev,
      items: prev.items.map((a) => ({
        ...a,
        managers: [],
        users: [],
        exceptioners: [],
        checked: false,
      })),
      isView: display !== 'phone',
    }));
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({
      ...prev,
      validation: '',
    }));
  };

  const renderView = () => {
    const checked = state.items.filter((a) => a.checked === true);
    if (checked.length === 0 || !state.isView) return <SplitUnselected />;

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <EuiSetting.Header title="선택목록">
          {!state.saveing && state.change && (
            <Button
              text="취소"
              variant="outlined"
              loading={state.saveing}
              onClick={handleCancel}
            />
          )}
          {state.change && (
            <Button
              noDuplication={state.saveing}
              text="저장"
              variant="contained"
              loading={state.saveing}
              onClick={handleSave}
            />
          )}
        </EuiSetting.Header>
        <PostWrite>
          <PostWrite.Item
            title="관리자"
            tooltip="관리자는 직원만 선택가능합니다."
          >
            <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
              {state.managers
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                  }) => {
                    const DirectoryData = getDirectoryData({
                      ...directory,
                      companyId,
                      employeeId: id,
                    });
                    return (
                      <Chip
                        key={`${companyId}/${id}`}
                        label={DirectoryData.employeeName}
                        etc="관리자"
                        avatar={DirectoryData.avatar}
                        onDelete={() => handleManagerDelete(id, referenceType)}
                      />
                    );
                  },
                )}
              {state.managerMenuPoint && (
                <DirectoryMenuTreeContainer
                  deduplication
                  point={state.managerMenuPoint}
                  typeToFilter="employee"
                  onItemClick={handleManagerAppend}
                  onClose={handleDirectoryTreeMenuToggle}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>
          <PostWrite.Item title="공유자">
            <ChipGroup add="추가" onAdd={handlePermissionOpenDialog}>
              {state.users
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                    options: { isRead, isWrite },
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc={handleMatchingPermissions({ isRead, isWrite })}
                          onDelete={() =>
                            handleSharersDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          etc={handleMatchingPermissions({ isRead, isWrite })}
                          icon="sitemap-fill"
                          onDelete={() =>
                            handleSharersDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc={handleMatchingPermissions({ isRead, isWrite })}
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleSharersDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    return null;
                  },
                )}
            </ChipGroup>
          </PostWrite.Item>
          <PostWrite.Item title="예외자">
            <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggleExcluder}>
              {state.exceptioners
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType !== b.referenceType) {
                    if (a.referenceType === 'COMPANY') return -1;
                    if (a.referenceType === 'ORGANIZATION') {
                      if (b.referenceType === 'COMPANY') return 1;
                      return -1;
                    }
                    return 1;
                  }
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                  }) => {
                    if (referenceType === 'COMPANY') {
                      // 회사일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getCompanyName(companyId)}
                          icon="company"
                          etc="예외자"
                          onDelete={() =>
                            handleExcluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          icon="sitemap-fill"
                          etc="예외자"
                          onDelete={() =>
                            handleExcluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          etc="예외자"
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleExcluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    return null;
                  },
                )}
              {state.excluderMenuPoint && (
                <DirectoryMenuTreeContainer
                  deduplication
                  point={state.excluderMenuPoint}
                  typeToFilter={['organization', 'employee']}
                  onItemClick={handleExcluderAppend}
                  onClose={handleDirectoryTreeMenuToggleExcluder}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
      </EuiSetting.Right>
    );
  };

  const renderDialog = () => {
    if (state.sharersPermission)
      return (
        <BoardPermissionDialogContainer
          users={state.users}
          onSave={handlePermissionDialogSave}
          onClose={handlePermissionDialogClose}
        />
      );

    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <EuiSetting.Header title="게시함 목록" />
            <EuiSetting.Toolbar>
              <SimpleSearch
                keyword={state.filter}
                onSearch={handleFilterChange}
              />
            </EuiSetting.Toolbar>
            <EuiSetting.Content>
              <TreeList
                items={state.items}
                filter={state.filter}
                useCheck
                onItemClick={handleFolderClick}
                onItemCheck={handleFolderCheck}
              />
            </EuiSetting.Content>
            {!state.isView && state.items.some((a) => a.checked === true) && (
              <EuiToolbar>
                <EuiToolbar.Left>{/*  */}</EuiToolbar.Left>
                <EuiToolbar.Right>
                  <Button
                    text="선택"
                    size="sm"
                    iconType
                    icon="arrow-from-left"
                    onClick={() =>
                      setState((prev) => ({ ...prev, isView: true }))
                    }
                  />
                </EuiToolbar.Right>
              </EuiToolbar>
            )}
          </EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default BoardAuthorityContainer;
