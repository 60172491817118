import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { folderActions } from '../../../../stores/document/folders';
import { preferencesActions } from '../../../../stores/document/preferences';

function DocumentUserPreferencesRoute(props: { pathname: string }): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    Promise.all([
      dispatch(folderActions.list()),
      dispatch(preferencesActions.findPreferences()),
      dispatch(preferencesActions.findAdministrators()),
    ]).then(() => {
      if (mount)
        dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
    });

    return () => {
      mount = false;
    };
  }, [pathname]);
  return null;
}

export default DocumentUserPreferencesRoute;
