/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SplitPane from 'react-split-pane';
import { useHotkeys } from 'react-hotkeys-hook';
import { RootState } from '../app/store';
import { LocationProps, Language } from '../../groupware-common/types';
import { getPathParams, parseUriModule } from '../../groupware-common/utils';
import GnbContainer from '../pages/common/containers/GnbContainer';
import EuiNav from '../../components/layout/EuiNav';
import EuiContainer from '../../components/layout/EuiContainer';

import EuiSnb from '../../components/layout/EuiSnb';
import Button from '../../components/button/Button';
// import ApprovalContentRoute from '../groupware-approval/pages/root/content/ContentRoute';
// import ApprovalContentContainer from '../groupware-approval/pages/root/content/ContentContainer';

const LocateRoute = React.memo(
  ({
    pathname,
    search,
    hash,
  }: {
    pathname: string;
    search: string;
    hash: string;
  }): null => {
    // console.log(`${LocateRoute.name}.render`);

    const uris = pathname
      .substring(1)
      .split('/')
      .map((i) => i.toLowerCase());

    const module = (uris[0] !== 'test' ? uris[0] : uris[1]) || '';
    // if (module === 'approval') {
    //   return (
    //     <ApprovalContentRoute pathname={pathname} search={search} hash={hash} />
    //   );
    // }
    // return <TestRoute pathname={pathname} search={search} hash={hash} />;
    return null;
  },
);

const LocateDrawer = React.memo(
  ({
    pathname,
    search,
    hash,
  }: {
    pathname: string;
    search: string;
    hash: string;
  }): JSX.Element | null => {
    // console.log(`${LocateDrawer.name}.render`);
    const uris = pathname
      .substring(1)
      .split('/')
      .map((i) => i.toLowerCase());

    const module = (uris[0] !== 'test' ? uris[0] : uris[1]) || '';
    if (module === 'approval') {
      return null;
    }
    return null;
  },
);

const LocateContent = React.memo(
  ({
    pathname,
    search,
    hash,
  }: {
    pathname: string;
    search: string;
    hash: string;
  }): JSX.Element => {
    const uris = pathname
      .substring(1)
      .split('/')
      .map((i) => i.toLowerCase());

    const module = (uris[0] !== 'test' ? uris[0] : uris[1]) || '';
    // if (module === 'approval') {
    //   return (
    //     <ApprovalContentContainer
    //       pathname={pathname}
    //       search={search}
    //       hash={hash}
    //     />
    //   );
    // }
    return <div>404</div>;
  },
);

function TestRouter({ location }: LocationProps): JSX.Element {
  // console.log(`${TestRouter.name}.render:location`, location);

  const { i18n } = useTranslation();

  const resource = useSelector((state: RootState) => state.session.resource);
  const display = useSelector((state: RootState) => state.session.display);
  const route = useSelector((state: RootState) => state.session.route);

  // console.log('----------location', location);
  // console.log('----------route', route);

  const handleChangeLanguage = useCallback(
    (language: Language) => {
      if (i18n.language !== language) i18n.changeLanguage(language);
    },
    [i18n],
  );

  let drawer: JSX.Element | null = null;
  let content: JSX.Element | null = null;

  const [state, setState] = useState<{
    windowSize: {
      width: number;
      height: number;
    };
    scrollY: number;
    navHidden: boolean;
    toggleLabel: string;
    snbHidden: boolean;
  }>({
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    scrollY: 0,
    navHidden: true,
    toggleLabel: '메뉴 접기',
    snbHidden: false,
  });

  const getContent = () => {
    const { pathname } = route;
    const { module } = getPathParams('/:module', pathname);

    const gnb =
      resource !== 'teams' ? (
        <GnbContainer
          module={parseUriModule(module)}
          onChangeLanguage={handleChangeLanguage}
        />
      ) : null;

    if (drawer) {
      // TODO 개발 중 반응형 주석 처리 배포 또는 화면 개발 시 주석 해제
      // if (false) {
      if (display !== 'pc') {
        return (
          <>
            <div className="ui-top">
              <Button
                text="메뉴"
                iconType
                icon="bars"
                className="ui-nav-toggle"
                onClick={handleOpenNav}
              />
            </div>
            {!state.navHidden && (
              <>
                <div className="ui-nav-cover" onClick={handleCloseNav} />
                <EuiNav>
                  {gnb}
                  <EuiSnb>{drawer}</EuiSnb>
                </EuiNav>
              </>
            )}
            <EuiContainer>{content}</EuiContainer>
          </>
        );
      }

      return (
        <>
          {state.snbHidden ? (
            <div
              className={`ui-root layout-split${
                state.snbHidden ? ' hidden' : ''
              }`}
            >
              <EuiNav>
                {gnb}
                <EuiSnb
                  toggleLabel={state.toggleLabel}
                  onToggle={handleSnbToggle}
                >
                  {drawer}
                </EuiSnb>
              </EuiNav>
              <EuiContainer>{content}</EuiContainer>
            </div>
          ) : (
            <SplitPane
              className={`ui-split layout-split${
                state.snbHidden ? ' hidden' : ''
              }`}
              split="vertical"
              minSize={resource !== 'teams' ? 333 : 265}
              maxSize={560}
              defaultSize={parseInt(
                localStorage.getItem('layout-split-sizes') ||
                  resource !== 'teams'
                  ? '333'
                  : '265',
                10,
              )}
              onChange={(size) =>
                localStorage.setItem('layout-split-sizes', `${size}`)
              }
            >
              <EuiNav>
                {gnb}
                <EuiSnb
                  toggleLabel={state.toggleLabel}
                  onToggle={handleSnbToggle}
                >
                  {drawer}
                </EuiSnb>
              </EuiNav>
              <EuiContainer>{content}</EuiContainer>
            </SplitPane>
          )}
        </>
      );
    }
    return (
      <>
        <EuiNav>{gnb}</EuiNav>
        <EuiContainer>
          {content}
          <ul>
            <li>
              <strong>os:</strong>{' '}
              {document.documentElement.getAttribute('data-os')}
            </li>
            <li>
              <strong>device:</strong>{' '}
              {document.documentElement.getAttribute('data-device')}
            </li>
            <li>
              <strong>browser:</strong>{' '}
              {document.documentElement.getAttribute('data-browser')}
            </li>
          </ul>
        </EuiContainer>
      </>
    );
  };

  const handleOpenNav = () => {
    setState((prevState) => ({
      ...prevState,
      navHidden: false,
    }));
  };

  const handleCloseNav = () => {
    setState((prevState) => ({
      ...prevState,
      navHidden: true,
    }));
  };

  const handleSnbToggle = () => {
    setState((prevState) => ({
      ...prevState,
      toggleLabel: prevState.snbHidden ? '메뉴 접기' : '메뉴 펼치기',
      snbHidden: !prevState.snbHidden,
    }));
  };

  drawer = (
    <LocateDrawer
      pathname={route.pathname}
      search={route.search}
      hash={route.hash}
    />
  );
  content = (
    <LocateContent
      pathname={route.pathname}
      search={route.search}
      hash={route.hash}
    />
  );

  const logo: {
    name: string;
    image: string;
  } = {
    name: '이든비즈텍',
    image: '/images/logo.svg',
  };

  useHotkeys('ctrl+.,command+.', handleSnbToggle);

  return (
    <>
      <div className="ui-splash">
        <div className="logo">
          <img src={logo.image} alt={logo.name} />
        </div>
      </div>
      <LocateRoute
        pathname={location.pathname}
        search={location.search}
        hash={location.hash}
      />
      {getContent()}
    </>
  );
}

/*
function areEqual(prevProps: RouteComponentProps, nextProps: RouteComponentProps) {
  //nextProp가 prevProps와 동일한 값을 가지면 true를 반환하고, 그렇지 않다면 false를 반환
  console.log('areEqual', `${prevProps.location.pathname} !== ${nextProps.location.pathname}`);

  if (prevProps.location.pathname !== nextProps.location.pathname) {
    const prevUrls = prevProps.location.pathname.split('/');
    const nextUrls = nextProps.location.pathname.split('/');
    console.log('prevProps.location.pathname !== nextProps.location.pathname', prevUrls[1] === nextUrls[1] && prevUrls[2] === nextUrls[2]);
    return prevUrls[1] === nextUrls[1] && prevUrls[2] === nextUrls[2];
  }
  else
    return true;
}

export default React.memo(TestRouter, areEqual);
*/
export default TestRouter;
