import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { b62, getPathParams } from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { itemActions } from '../../../../stores/folders';
import { preferencesActions } from '../../../../stores/preferences';
import { resourcesActions } from '../../../../stores/resources';
import { userPreferencesActions } from '../../../../stores/userPreferences';

function ResourceContentRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);

  useEffect(() => {
    Promise.all([
      dispatch(
        userPreferencesActions.find({ employeeId: principal.employeeId }),
      ),
      dispatch(preferencesActions.findPreferences()),
    ]).then(() => {
      const route = { pathname, search, hash, clearErrors: true };
      if (p1 && !p2) {
        dispatch(
          resourcesActions.reservationList({
            type: 'folder',
            id: b62(p1),
            route,
          }),
        );
      }
      if (p1 && p2) {
        if (hash === '') {
          dispatch(itemActions.view({ id: b62(p2) })).then(() => {
            dispatch(
              resourcesActions.reservationList({
                type: 'item',
                id: b62(p2),
                route,
              }),
            );
          });
        } else {
          dispatch(
            resourcesActions.reservationList({
              type: 'item',
              id: b62(p2),
              route,
            }),
          );
        }
      }
    });
  }, [dispatch, pathname, search, hash]);
  return null;
}

export default ResourceContentRoute;
