import React from 'react';
import { Country } from '../../groupware-common/types';

function Flag(props: {
  code?: Country; // 국가코드
  className?: string;
}): JSX.Element {
  let classname = 'eui-flag';
  if (props.className) classname += ` ${props.className}`;

  return <i className={classname} data-country-flag={props.code} />;
}

export default Flag;
