import { useEffect } from 'react';

import { useAppDispatch } from '../../../groupware-webapp/app/store';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import { preferencesActions } from '../../stores/systemlink/preferences';

function SystemEverSettingRoute({
  pathname,
}: {
  pathname: string;
  search: string;
}): null {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let mount = true;
    Promise.all([dispatch(preferencesActions.findPreferences())]).then(() => {
      if (mount) {
        dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
      }
    });
    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);

  return null;
}

export default SystemEverSettingRoute;
