import React from 'react';

function HelperText(props: {
  className?: string;
  text?: React.ReactNode;
  children?: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-helper-text';
  if (props.className) classname += ` ${props.className}`;
  if (props.children) classname += ' error';

  return (
    <>
      {(props.text || props.children) && (
        <div className={classname} aria-live="assertive">
          {props.children || props.text}
        </div>
      )}
    </>
  );
}

export default React.memo(HelperText);
