/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint @typescript-eslint/no-explicit-any: ["warn", { "fixToUnknown": true, "ignoreRestArgs": true }] */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-continue */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
import { getI18n } from 'react-i18next';
import { StringMap, TOptions } from 'i18next';
import { history } from '../..';
import {
  CRUD,
  CRUDStrings,
  Language,
  LanguageModules,
  LanguageStrings,
  Locationable,
  Module,
  ModuleItem,
  ModuleStrings,
  Routable,
} from '../types';
import { SearchDateType } from '../../components/search/Search';
import { apiError } from '../apis/common/v1';
import { ModuleItemArg } from '../../groupware-service/apis/module';

export function equal<T>(a: T, b: T) {
  if (a === b) return true;
  if (Array.isArray(a)) return arrayEqual(a, b);
}

function arrayEqual(a: any, b: any) {
  if (a.length !== b.length) return false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function isEmpty(o: Object) {
  return Object.keys(o).length === 0 && o.constructor === Object;
}

function isObject(object: any) {
  return object !== null && typeof object === 'object';
}

export function deepEqual(objA: any, objB: any): boolean {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    const valA = objA[key];
    const valB = objB[key];
    const areObjects = isObject(valA) && isObject(valB);
    if (
      (areObjects && !deepEqual(valA, valB)) ||
      (!areObjects && valA !== valB)
    ) {
      return false;
    }
  }

  return true;
}

export async function sleep(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

export function getNameKey(
  companyId: number,
  nameId: number,
  prefix: string,
  namespace: string,
) {
  let result = `${prefix}.${companyId}_${nameId}`;
  if (namespace !== '') result = `${namespace}:${result}`;
  return result;
}

export async function resourceUpdate(
  namespace: Array<LanguageModules>,
  languages: Array<Language>,
) {
  const i18n = getI18n();

  const resource: { namespace: string; language: Language }[] = [];

  if (Array.isArray(namespace)) {
    languages.forEach((x) => {
      namespace.forEach((y) => {
        resource.push({ namespace: y, language: x });
      });
    });
  } else {
    languages.forEach((x) => {
      resource.push({ namespace, language: x });
    });
  }

  const promises = resource
    .map((x) => {
      if (!i18n.hasResourceBundle(x.language, x.namespace))
        return i18n.reloadResources(x.language, x.namespace);
      return undefined;
    })
    .filter((x) => x !== undefined);

  await Promise.all(promises);
}

export async function resourceReload(
  lng?: string | readonly string[],
  ns?: string | readonly string[],
) {
  const i18n = getI18n();
  const lang = lng ?? ['ko-KR'];
  const namespace = ns ?? ['directory', 'jobclass'];
  await i18n.reloadResources(lang, namespace);
}

/**
 * i18n 스토어에 데이터를 추가하는 함수입니다.
 * @param data - 언어 코드, 다국어 값
 * @param namespace
 * @param nameKey
 */
export async function addResourceValue(arg: {
  data:
    | {
        language: string;
        value: string;
      }[]
    | {
        language: string;
        value: string;
      };
  namespace: string;
  nameKey: string;
}) {
  try {
    const { data, namespace, nameKey } = arg;
    const i18n = getI18n();
    if (Array.isArray(data)) {
      data.forEach((name) => {
        i18n.addResource(name.language, namespace, nameKey, name.value);
      });
    } else {
      i18n.addResource(data.language, namespace, nameKey, data.value);
    }
  } catch (e) {
    throw apiError(e);
  }
}

export function getParentItems<T, U extends { id: T; parentId: T }>(
  array: Readonly<Array<U>>,
  id: T,
  parentId?: T,
  result?: Array<U>,
): Array<U> {
  if (result === undefined) result = [];

  const item =
    parentId === undefined
      ? array.find((x) => x.id === id)
      : array.find((x) => x.id === id && x.parentId === parentId);
  if (item !== undefined) {
    result.unshift({ ...item });
    return getParentItems(array, item.parentId, parentId, result);
  }
  return result;
}

export function getChildren<T, U extends { id: T; parentId: T }>(
  array: Readonly<Array<U>>,
  id: T | T[],
  result?: Array<U>,
): Array<U> {
  if (result === undefined) result = [];

  const ids = Array.isArray(id) ? id : [id];
  const items =
    result.length === 0
      ? array.filter((a) => ids.find((b) => a.id === b) !== undefined)
      : array.filter((a) => ids.find((b) => a.parentId === b) !== undefined);

  if (items.length > 0) {
    result.push(...items);
    return getChildren(
      array,
      items.map((item) => item.id),
      result,
    );
  }
  return result;
}

// id: string, namespace?: string, language?: string
export function getText(
  key: string | string[],
  ns: string | string[],
  lang?: string | string[],
): string;
export function getText<TInterpolationMap extends object = StringMap>(
  key: string | string[],
  options?: TOptions<TInterpolationMap>,
): string;
export function getText<TInterpolationMap extends object = StringMap>(
  key: string | string[],
  ns?: string | string[],
  lang?: string | string[],
  options?: TOptions<TInterpolationMap>,
): string {
  if (key === '') return '';

  const i18n = getI18n();

  if (arguments.length === 2) {
    if (typeof ns === 'object' && !Array.isArray(ns)) {
      options = ns as TOptions<TInterpolationMap>;
      ns = undefined;
    } else if (
      Array.isArray(ns) &&
      ns.find((x) => LanguageStrings.find((y) => x === y)) !== undefined
    ) {
      lang = ns;
      ns = undefined;
    } else if (
      typeof ns === 'string' &&
      LanguageStrings.findIndex((x) => x === ns) > -1
    ) {
      lang = ns;
      ns = undefined;
    }
  }

  lang = lang || i18n.language;
  // console.log('key', key, 'ns', ns, 'lang', lang, 'options', options);

  // console.log('----------key', key);
  const result = i18n.getFixedT(lang, ns)(key, options);
  // console.log('----------result', result);

  if (
    Array.isArray(key) &&
    key.length === 2 &&
    key[0].split(':').pop() === result
  )
    return key[1];

  return result;
}

export function getPageNo(search: string) {
  const params = new URLSearchParams(search);
  return parseInt(params.get('p') || '1', 10);
}

export function getRowsPerPage(search: string) {
  const params = new URLSearchParams(search);
  return parseInt(params.get('rpp') || '15', 10);
}

export function getSearchParams(params: string, ignore: string[]) {
  if (params === '') return '';

  const urlSearchParams = new URLSearchParams(params);
  ignore.forEach((x) => urlSearchParams.delete(x));
  return urlSearchParams.toString();
}

function parseCRUD(value: string | null | undefined): CRUD | undefined;
function parseCRUD(value: string | null | undefined, defaultValue?: CRUD): CRUD;
function parseCRUD(
  value: string | null | undefined,
  defaultValue?: CRUD,
): CRUD | undefined {
  let result = CRUDStrings.find((x) => x === value);
  if (result === undefined && defaultValue !== undefined) result = defaultValue;
  return result;
}

export function parseModule(
  string: string | undefined,
  defaultValue?: Module,
): Module | undefined {
  // console.log(`parseModule(string: ${string}, defaultValue: ${defaultValue})`);
  const s = string?.toLowerCase();
  return ModuleStrings.find((x) => x === s) || defaultValue;
}

export function parseUris(pathname: string) {
  if (pathname[0] !== '/') return pathname.split('/');
  return pathname.substring(1).split('/');
}

export function parseUriModule(
  module: string | undefined,
  subfix?: string,
): Module {
  if (module === undefined) return '404';

  const s = (
    subfix === undefined ? module : `${module}/${subfix}`
  ).toLowerCase();
  return ModuleStrings.find((x) => x === s) || '404';
}

export function parseDate(value: string): Date | null;
export function parseDate(value: Date | null): string;
export function parseDate(value: any): any {
  if (typeof value === 'string') {
    if (!Number.isNaN(Date.parse(value))) return new Date(value);
    return null;
  }

  if (value instanceof Date) {
    const year = value.getFullYear();
    const month = String(value.getMonth() + 1).padStart(2, '0');
    const date = String(value.getDate()).padStart(2, '0');
    return `${year}-${month}-${date}`;
  }
  return '';
}

export function parseDateTime(value: string): Date | null;
export function parseDateTime(value: Date | null): string;
export function parseDateTime(value: any): any {
  if (typeof value === 'string') {
    if (!Number.isNaN(Date.parse(value))) return new Date(value);
    return null;
  }

  if (value instanceof Date) {
    const year = value.getFullYear();
    const month = String(value.getMonth() + 1).padStart(2, '0');
    const date = String(value.getDate()).padStart(2, '0');
    const hour = String(value.getHours()).padStart(2, '0');
    const minute = String(value.getMinutes()).padStart(2, '0');
    const second = String(value.getSeconds()).padStart(2, '0');
    const millisecond = String(value.getMilliseconds()).padEnd(6, '0');
    return `${year}-${month}-${date}T${hour}:${minute}:${second}.${millisecond}`;
  }
  return '';
}

export function go(
  pathname: string,
  search: string | QueryParams | undefined = undefined,
  hash: string | undefined = undefined,
  mode: 'auto' | 'push' | 'replace' = 'auto',
) {
  // console.log(`go(pathname: "${pathname}", search:, mode: ${mode})`, search);

  let urn = pathname;

  if (typeof search === 'string') urn += `?${search}`;
  if (typeof search === 'object') urn += `?${getQueryParams(search)}`;

  if (hash !== undefined) urn += hash;

  // console.log(' utils index ======= history :', history, ' urn :', urn);

  switch (mode) {
    case 'auto':
      if (
        history.location.pathname +
          history.location.search +
          history.location.hash ===
        urn
      )
        history.replace(urn);
      else history.push(urn);
      break;
    case 'push':
      history.push(urn);
      break;
    case 'replace':
      history.replace(urn);
      break;
    default:
      alert('error');
      break;
  }
}

export function goBack() {
  // console.log('goBack()');
  history.goBack();
}

export const base62 = {
  charset:
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split(''),
  encode: (integer: number) => {
    if (integer === 0) {
      return 0;
    }
    let s: Array<string> = [];
    while (integer > 0) {
      s = [base62.charset[integer % 62], ...s];
      integer = Math.floor(integer / 62);
    }
    return s.join('');
  },
  decode: (chars: string) =>
    chars
      .split('')
      .reverse()
      .reduce(
        (prev, curr, i) => prev + base62.charset.indexOf(curr) * 62 ** i,
        0,
      ),
};

export function b62(value: number): string;
export function b62(value: string): number;
export function b62(value: string | undefined): number | undefined;
export function b62(
  value: number | string | undefined,
): number | string | undefined;
export function b62(
  value: number | string | undefined,
): string | number | undefined {
  if (typeof value === 'number') return base62.encode(value);
  if (value === undefined) return undefined;
  return base62.decode(value);
}

/*
//import { Itemsable } from "../types";

export function flatFilter<T extends Itemsable>(arr: Array<T>, predicate: (value: T) => boolean, res?: Array<T>): Array<T> {
  if (res === undefined)
    res = [];

  arr.forEach((item) => {
    if (predicate(item))
      res!.push(item);
    if (item.children !== undefined)
      flatFilter(item.children, predicate, res);
  });

  return res;
};
*/

class Hangul {
  private CHOSEONG = [
    'ㄱ',
    'ㄲ',
    'ㄴ',
    'ㄷ',
    'ㄸ',
    'ㄹ',
    'ㅁ',
    'ㅂ',
    'ㅃ',
    'ㅅ',
    'ㅆ',
    'ㅇ',
    'ㅈ',
    'ㅉ',
    'ㅊ',
    'ㅋ',
    'ㅌ',
    'ㅍ',
    'ㅎ',
  ];

  private JUNGSEONG = [
    'ㅏ',
    'ㅐ',
    'ㅑ',
    'ㅒ',
    'ㅓ',
    'ㅔ',
    'ㅕ',
    'ㅖ',
    'ㅗ',
    'ㅘ',
    'ㅙ',
    'ㅚ',
    'ㅛ',
    'ㅜ',
    'ㅝ',
    'ㅞ',
    'ㅟ',
    'ㅠ',
    'ㅡ',
    'ㅢ',
    'ㅣ',
  ];

  private JONGSEONG = [
    '',
    'ㄱ',
    'ㄲ',
    'ㄳ',
    'ㄴ',
    'ㄵ',
    'ㄶ',
    'ㄷ',
    'ㄹ',
    'ㄺ',
    'ㄻ',
    'ㄼ',
    'ㄽ',
    'ㄾ',
    'ㄿ',
    'ㅀ',
    'ㅁ',
    'ㅂ',
    'ㅄ',
    'ㅅ',
    'ㅆ',
    'ㅇ',
    'ㅈ',
    'ㅊ',
    'ㅋ',
    'ㅌ',
    'ㅍ',
    'ㅎ',
  ];

  disassemble(str: string, choseongs?: Array<string>) {
    let cho;
    let jung;
    let jong;
    const cnt = str.length;
    const chars = [];
    let cCode;
    for (let i = 0; i < cnt; i += 1) {
      cCode = str.charCodeAt(i);
      if (cCode === 32) {
        continue;
      } // 한글이 아닌 경우
      if (cCode < 0xac00 || cCode > 0xd7a3) {
        const char = str.charAt(i);
        if (choseongs !== undefined && this.CHOSEONG.indexOf(char) > -1)
          choseongs.push(char);

        chars.push(str.charAt(i));
        continue;
      }
      cCode = str.charCodeAt(i) - 0xac00;
      jong = cCode % 28; // 종성
      jung = ((cCode - jong) / 28) % 21; // 중성
      cho = ((cCode - jong) / 28 - jung) / 21; // 초성

      // console.log('jong:', jong);
      // console.log('jung:', jung);
      // console.log('cho:', cho);

      if (choseongs !== undefined) {
        choseongs.push(this.CHOSEONG[cho]);
      }

      chars.push(this.CHOSEONG[cho], this.JUNGSEONG[jung]);
      if (this.JONGSEONG[jong] !== '') {
        chars.push(this.JONGSEONG[jong]);
      }
    }
    return chars;
  }

  d(str: string) {
    let choseong;
    let jungseong;
    let jongseong;
    let code;

    const res: Array<Array<string>> = [];

    // 영어 대소문자 구분 X
    const stringData = str.toLowerCase();
    const { length } = stringData;
    for (let i = 0; i < length; i += 1) {
      code = stringData.charCodeAt(i);
      if (code === 32) {
        continue;
      } // 공백(space)인 경우.

      const chars = [];
      if (code < 0xac00 || code > 0xd7a3) {
        res.push([stringData.charAt(i)]);
        continue;
      }
      code -= 0xac00;
      jongseong = code % 28; // 종성
      jungseong = ((code - jongseong) / 28) % 21; // 중성
      choseong = ((code - jongseong) / 28 - jungseong) / 21; // 초성
      /*
      console.log('jong:', jong);
      console.log('jung:', jung);
      console.log('cho:', cho);
      */
      chars.push(this.CHOSEONG[choseong], this.JUNGSEONG[jungseong]);
      if (this.JONGSEONG[jongseong] !== '') {
        chars.push(this.JONGSEONG[jongseong]);
      }
      res.push(chars);
    }

    return res;
  }

  // a 조회 대상, b 조회 내용
  test(a: Array<Array<string>>, b: Array<Array<string>>): boolean {
    // 조회 대상보다 조회 내용이 큰 경우 false 반환.
    if (a.length < b.length) return false;

    // console.log(`a.flat().join('')`, a.flat().join(''));
    // console.log(`b.flat().join('')`, b.flat().join(''));

    let compare = false;
    let n = 0;
    for (let i = 0; i < a.length; i += 1) {
      compare = true;
      for (let j = 0; j < b.length; j += 1) {
        if (compare) {
          n = i + j;
          // console.log(a, b, b[j], n, a.length);
          if (n === a.length) {
            compare = false;
          } else {
            for (let k = 0; k < b[j].length; k += 1) {
              if (b[j][k] !== a[n][k]) {
                // 검색내용 문자의 종성인 경우.
                if (k > 1 && n + 1 < a.length && b[j][k] === a[n + 1][0]) {
                  compare = true;
                } else compare = false;
              }
            }
          }
        }
      }
      // console.log(`compare`, compare);
      if (compare) break;
    }

    // compare = a.flat().join('').indexOf(b.flat().join('')) > -1;
    /*
    console.log(`a.length`, a.length);
    console.log(`b.length`, b.length);

    console.log(`a.join('')`, a.join(''));
    console.log(`b.join('')`, b.join(''));
*/

    // console.log('a, b, flag', a, b, compare);

    return compare;
  }
}

export const hangul = new Hangul();

/**
 * 맵에 해당 하는 경로 가져오기.
 * @param map 경로 맵. ex) '/module/:p1/:p2'
 * @param path 경로 값. ex) '/module/1/2'
 * @returns 매핑된 경로. ex) /module
 */
export function getPathMap(map: string, path: string): string {
  // console.log(`getPathMap(map:'${map}', path:'${path}')`);

  const result: string[] = [];

  const maps = map.substring(1).split('/');
  const paths = path.substring(1).split('/');

  for (let i = 0; i < maps.length; i += 1) {
    if (!maps[i].startsWith(':') && paths[i] !== undefined)
      result.push(paths[i]);
  }

  return `/${result.join('/')}`;
}

/**
 * 경로 매개변수 가져오기.
 *
 * 경로 맵 매개변수 :매개변수이름$유형(생략시 문자열)
 *
 * :p1 또는 :p1$base64 형식으로 작성.
 *
 * @param map 경로 맵. ex) '/module/:p1/:p2'
 * @param path 경로 값. ex) '/module/1/2'
 * @returns 매핑된 제네릭 유형 객체. ex) { p1: '1', p2: '2' }
 */
export function getPathParams<
  T extends { [K in keyof T]?: string | number | undefined } = Record<
    string,
    string | undefined
  >,
>(map: string, path: string): T {
  // console.log(`getPathParams(map:'${map}', path:'${path}')`);

  const result: Record<string, string | number | undefined> = {};

  const maps = map.substring(1).split('/');
  const paths = path.substring(1).split('/');
  const n = paths[0] === 'test' ? 1 : 0;

  for (let i = 0; i < maps.length; i += 1) {
    if (maps[i].startsWith(':')) {
      if (maps[i].endsWith('$base62'))
        result[maps[i].slice(1, -7)] = b62(paths[i + n]);
      else if (maps[i].endsWith('$b62'))
        result[maps[i].slice(1, -4)] = b62(paths[i + n]);
      else result[maps[i].substring(1)] = paths[i + n] || undefined;
    }
  }

  return result as T;
}

// 쿼리 매개변수 키 값 소문자 맵핑. (키 값 대소문자 비교 없음)
type QueryParams = {
  pageNo?: number;
  rowsPerPage?: number;
  queryCode?: string;
  queryWord?: string;
  contentMode?: CRUD;
  contentType?: string;
  sheetMode?: CRUD;
  sheetType?: string;
  drawerMode?: CRUD;
  drawerType?: string;
  dialogMode?: CRUD;
  dialogType?: string;
  searchCode?: string;
  searchWord?: string;
  directoryFilter?: string;
  directoryKeyword?: string;
  companyId?: number;
  id?: number;
  receiptFormId?: number;
  startDate?: string;
  endDate?: string;
  devision?: string;
  status?: string;
  type?: string;
  /** 상세 검색 */
  dateType?: SearchDateType;
  subject?: string;
  documentNumber?: string;
  workName?: string;
  formName?: string;
  content?: string;
  attachedFileName?: string;
  drafterName?: string;
  draftOrganizationName?: string;
  approvalTargetName?: string;
} & Record<string, string>;

export interface AdvancedSearchParams {
  state?: string;
  subject?: string;
  documentnumber?: string;
  workname?: string;
  formname?: string;
  content?: string;
  attachedfilename?: string;
  draftername?: string;
  draftorganizationname?: string;
  startdate?: string;
  enddate?: string;
  approvaltargetname?: string;
}

/**
 * 쿼리 매개변수 가져오기.
 * @param search 검색 문자열. ex) 'p1=1&p2=2'
 * @returns 쿼리 매개변수 객체. ex) { p1: '1', p2: '2' }
 */
export function getQueryParams(search: string): QueryParams;
export function getQueryParams(params: QueryParams): string;
export function getQueryParams(
  arg: string | QueryParams,
): QueryParams | string {
  if (isObject(arg)) {
    let result = '';
    Object.entries(arg).forEach(([key, value]) => {
      if (value !== undefined) {
        let k = key.toLowerCase();
        const v = encodeURIComponent(value);
        if (k === 'pageno') k = 'p';
        else if (k === 'rowsperpage') k = 'rpp';
        else if (k === 'companyid') k = 'cid';
        else if (k === 'receiptformid') k = 'rfi';
        else if (k === 'startdate') k = 'sd';
        else if (k === 'enddate') k = 'ed';
        result += `&${k}=${v}`;
      }
    });
    return result !== '' ? result.substring(1) : '';
  }

  const result: QueryParams = {};
  if (typeof arg === 'string' && arg !== '') {
    new URLSearchParams(arg).forEach((value, key) => {
      if (value !== undefined) {
        // 매개변수 키 값 소문자 맵핑. (키 값 대소문자 비교 없음)
        const k = key.toLowerCase();
        switch (k) {
          case 'p':
          case 'pageno':
            result.pageNo = parseInt(value, 10);
            break;
          case 'rpp':
          case 'rowsperpage':
            result.rowsPerPage = parseInt(value, 10);
            break;
          case 'qc':
          case 'querycode':
            result.queryCode = value;
            break;
          case 'qw':
          case 'queryword':
            result.queryWord = value;
            break;
          case 'cm':
          case 'contentmode':
            result.contentMode = parseCRUD(value);
            break;
          case 'ct':
          case 'contenttype':
            result.contentType = value;
            break;
          case 'sm':
          case 'sheetmode':
            result.sheetMode = parseCRUD(value);
            break;
          case 'st':
          case 'sheettype':
            result.sheetType = value;
            break;
          case 'dm':
          case 'drawermode':
            result.drawerMode = parseCRUD(value);
            break;
          case 'dt':
          case 'drawertype':
            result.drawerType = value;
            break;
          case 'pm':
          case 'dialogmode':
            result.dialogMode = parseCRUD(value);
            break;
          case 'pt':
          case 'dialogtype':
            result.dialogType = value;
            break;
          case 'sc':
          case 'searchcode':
            result.searchCode = value;
            break;
          case 'sw':
          case 'searchword':
            result.searchWord = value;
            break;
          case 'directoryfilter':
            result.directoryFilter = value;
            break;
          case 'directorykeyword':
            result.directoryKeyword = value;
            break;
          case 'cid':
          case 'companyid':
            result.companyId = parseInt(value, 10);
            break;
          case 'datetype':
            result.dateType = value as SearchDateType;
            break;
          case 'id':
            result.id = parseInt(value, 10);
            break;
          case 'rfi':
          case 'receiptformid':
            result.receiptFormId = parseInt(value, 10);
            break;
          case 'sd':
          case 'startdate':
            result.startDate = value;
            break;
          case 'ed':
          case 'enddate':
            result.endDate = value;
            break;
          case 'documentnumber':
            result.documentNumber = value;
            break;
          case 'workname':
            result.workName = value;
            break;
          case 'formname':
            result.formName = value;
            break;
          case 'attachedfilename':
            result.attachedFileName = value;
            break;
          case 'draftername':
            result.drafterName = value;
            break;
          case 'draftorganizationname':
            result.draftOrganizationName = value;
            break;
          case 'approvaltargetname':
            result.approvalTargetName = value;
            break;
          default:
            result[k] = value;
            break;
        }
      }
    });
  }
  return result;
}

/**
 * 상세검색 매개변수 가져오기
 * @param arg
 * @returns AdvancedSearchParams ex ) { documentnumber, attachedfilename.. }
 */
export function getAdvancedSearchParams(arg: string): AdvancedSearchParams {
  const result: AdvancedSearchParams = {};
  if (typeof arg === 'string' && arg !== '') {
    new URLSearchParams(arg).forEach((value, key) => {
      const k = key.toLowerCase();
      switch (k) {
        case 'state':
          result.state = value;
          break;
        case 'subject':
          result.subject = value;
          break;
        case 'documentnumber':
          result.documentnumber = value;
          break;
        case 'workname':
          result.workname = value;
          break;
        case 'formname':
          result.formname = value;
          break;
        case 'content':
          result.content = value;
          break;
        case 'attachedfilename':
          result.attachedfilename = value;
          break;
        case 'draftername':
          result.draftername = value;
          break;
        case 'draftorganizationname':
          result.draftorganizationname = value;
          break;
        case 'sd':
        case 'startdate':
          result.startdate = value;
          break;
        case 'ed':
        case 'enddate':
          result.enddate = value;
          break;
        case 'approvaltargetname':
          result.approvaltargetname = value;
          break;
        default:
          break;
      }
    });
  }
  return result;
}

/**
 * 쿼리 문자열 생성.
 * @param params 쿼리 매개변수.
 * @param data 기본 데이터.
 * @returns 쿼리 문자열. ex) 'p1=1&p2=2'
 */
export function createQueryString(
  params:
    | {
        pageNo?: number;
        rowsPerPage?: number;
        queryCode?: string;
        queryWord?: string;
        contentMode?: CRUD;
        contentType?: string;
        sheetMode?: CRUD;
        sheetType?: string;
        drawerMode?: CRUD;
        drawerType?: string;
        dialogMode?: CRUD;
        dialogType?: string;
        searchCode?: string;
        searchWord?: string;
        companyId?: number;
        id?: number;
        type?: string;
      }
    | Record<string, string>,
  data?: QueryParams,
): string {
  const proxy = data ? { ...data, ...params } : params;
  return getQueryParams(proxy as QueryParams);
}

/** 아바타 경로 가져오기.
 * @param companyId 회사 아이디.
 * @param employeeId 직원 아이디.
 * @return 아바타 경로.
 */
export function getAvatarPath(companyId: number, employeeId: number): string;
export function getAvatarPath(
  companyId: number,
  employeeId: number,
  timestamp: string,
): string;
export function getAvatarPath(arg: {
  companyId: number;
  employeeId: number;
}): string;
export function getAvatarPath(
  a: number | { companyId: number; employeeId: number },
  b?: number,
  c?: string,
): string {
  if (typeof a === 'object') {
    const { companyId, employeeId } = a;
    return `/custom-static/avatar/${companyId}/${employeeId}`;
  }
  if (typeof a === 'number' && b !== undefined) {
    const url = `/custom-static/avatar/${a}/${b}`;
    if (c === undefined) return url;
    return `${url}?t=${new Date(c).getTime()}`;
  }

  return '';
}

/**
 * 테스트 용 마지막 수정시간 조회.
 * @return 테스트 용 마지막 수정시간
 */
export function getLastUpdateAt(): string {
  // 10분 간격으로 동일한 시간 나오도록 테스트 값 작성.
  const date = new Date();
  date.setMinutes(Math.floor(date.getMinutes() / 10) * 10);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString().replace('Z', '000');
}

/**
 * 대상 라우트 객체와 대상 라우트 객체에 반영하고자 하는 라우트 위치 객체 및 옵션 값을
 * 사용하여 대상 라우트 객체를 반환합니다.
 * @param arg.target 대상 라우트 객체.
 * @param arg.source 소스 라우트 객체.
 * @returns 대상 라우트 객체.
 */
function getRoute(arg: {
  target: Routable;
  source?: Omit<Routable, 'pathname' | 'search'> & {
    pathname?: string;
    search?: string;
    option?: 'CLEAR_DIALOG' | 'CLEAR_DRAWER' | 'CLEAR_CONTENTS';
  };
}): Routable {
  const target = {
    pathname: arg.target.pathname,
    search: arg.target.search,
    hash: arg.target.hash,
    key: arg.target.key,
  };

  if (arg.source === undefined) return target;

  let search = arg.source.search ?? target.search;
  if (arg.source.option === 'CLEAR_DIALOG') {
    const qp1 = getQueryParams(search ?? '');
    const qp2 = { dialogMode: undefined, dialogType: undefined };
    search = createQueryString(qp2, qp1);
  }
  if (arg.source.option === 'CLEAR_DRAWER') {
    const qp1 = getQueryParams(search ?? '');
    const qp2 = { drawerMode: undefined, drawerType: undefined };
    search = createQueryString(qp2, qp1);
  }
  if (arg.source.option === 'CLEAR_CONTENTS') {
    const qp1 = getQueryParams(search ?? '');
    const qp2 = { contentType: undefined, contentMode: undefined };
    search = createQueryString(qp2, qp1);
  }

  const source = {
    pathname: arg.source.pathname ?? target.pathname,
    search,
    hash: arg.source.hash ?? target.hash,
    key: arg.source.key ?? target.key,
  };

  return { ...target, ...source };
}

/**
 * 대상 위치 객체와 대상 위치 객체에 반영하고자 하는 소스 위치 객체 및 옵션 값을
 * 사용하여 대상 위치 객체를 반환합니다.
 * @param arg.target 대상 위치 객체.
 * @param arg.source 소스 위치 객체.
 * @returns 대상 위치 객체.
 */
function getLocation(arg: {
  target: Locationable;
  source?: Omit<Locationable, 'pathname' | 'search'> & {
    pathname?: string;
    search?: string;
    option?: 'CLEAR_DIALOG' | 'CLEAR_CONTENTS';
  };
}): Locationable {
  const target = {
    pathname: arg.target.pathname,
    search: arg.target.search,
    hash: arg.target.hash,
    mode: arg.target.mode,
  };

  if (arg.source === undefined) return target;

  let search = arg.source.search ?? target.search;
  if (arg.source.option === 'CLEAR_DIALOG') {
    const qp1 = getQueryParams(search ?? '');
    const qp2 = { dialogMode: undefined, dialogType: undefined };
    search = createQueryString(qp2, qp1);
  }

  if (arg.source.option === 'CLEAR_CONTENTS') {
    const qp1 = getQueryParams(search ?? '');
    const qp2 = {
      contentType: undefined,
      contentMode: undefined,
      receiptFormId: undefined,
    };
    search = createQueryString(qp2, qp1);
  }

  const source = {
    pathname: arg.source.pathname ?? target.pathname,
    search,
    hash: arg.source.hash ?? target.hash,
    mode: arg.source.mode ?? target.mode,
  };

  return { ...target, ...source };
}

export function convertModuleForamt(arg: ModuleItemArg): ModuleItem {
  return {
    ...arg,
    id: arg.module,
    code: arg.module.toLowerCase() as Module,
    textId: `module.${arg.module}`,
  };
}

export const utils = {
  getRoute,
  getLocation,
};
