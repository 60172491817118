import React from 'react';
import ComponentSelectBasic from './ComponentSelectBasic';
import ComponentSelectLabel from './ComponentSelectLabel';
import ComponentSelectCustom from './ComponentSelectCustom';

function ComponentSelect(): JSX.Element {
  return (
    <>
      <ComponentSelectBasic />
      <ComponentSelectLabel />
      <ComponentSelectCustom />
    </>
  );
}

export default ComponentSelect;
