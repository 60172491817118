import React from 'react';
import { IconType } from '../../../../../groupware-common/types/icon';
import Icon from '../../../../../components/icon/Icon';

type Props = {
  label: string;
  icon: IconType;
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
};

function DocumentFormEditMenu(dumy: Props): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};
  const props = {
    ...dumy,
    label: dumy.label || '',
    onClick: dumy.onClick || emptyFunction,
  };

  const { label, icon, onClick } = props;

  return (
    <button type="button" onClick={onClick} className="toolbar-button">
      <Icon icon={icon} />
      <span>{label}</span>
    </button>
  );
}

export default React.memo(DocumentFormEditMenu);
