import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import attendanceHolidayApi from '../../apis/attendance/v1/holiday';
import {
  CacheEntity,
  CacheMeta,
  LocateArg,
} from '../../../groupware-common/types';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import { getQueryParams } from '../../../groupware-common/utils';
import { timezoneDate } from '../../../groupware-common/utils/ui';

const name = 'attendace/holiday';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: CacheEntity<{
    items: HolidayList[];
    totalCount: number;
    search: string;
    year: string;
  }>;
}

const initialState: State = {
  requests: [],
  list: {
    data: {
      items: [],
      totalCount: 0,
      search: '',
      year: new Date().getFullYear().toString(),
    },
    meta: { lastUpdateAt: '', timestamp: 0 },
  },
};
interface HolidayList {
  checked: boolean; // 체크 여부
  companyId: number; // 회사 아이디
  holiday: string; // 휴일 일자
  holidayName: string; // 휴일명
  updateAt: string; // 수정 날짜
}

/* 기본공휴일 목록 조회 */
const basicList = createAsyncThunk(
  `${name}/findBasicList`,
  async (arg: LocateArg, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;
      const { route } = arg;
      const { pageNo = 1, rowsPerPage = 15 } = getQueryParams(
        route?.search ?? '',
      );
      const response = await attendanceHolidayApi.findBasicList({
        pageNo,
        rowsPerPage,
      });
      const totalCount = await attendanceHolidayApi.findBasicTotal();
      const list: HolidayList[] = response.map((a) => {
        return {
          checked: false,
          companyId: a.companyId,
          holiday: a.holiday,
          holidayName: a.holidayName,
          updateAt: a.updateAt,
        };
      });
      return { meta, data: { list, totalCount } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/* 기본공휴일 추가 */
const basicAppend = createAsyncThunk(
  `${name}/appendBasic`,
  async (
    arg: {
      holiday: string;
      holidayName: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await attendanceHolidayApi.appendBasic(arg);
      const { route } = arg;
      if (route !== undefined) {
        await dispatch(basicList({ route }));
      }
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/* 기본공휴일 삭제 */
const basicDelete = createAsyncThunk(
  `${name}/basicDelete`,
  async (
    arg: {
      holiday: string;
      holidayName: string;
      updateAt: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await attendanceHolidayApi.deleteBasic(arg);
      const { route } = arg;
      if (route !== undefined) await dispatch(basicList({ route }));
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/* 휴일등록 조회 */
const yearList = createAsyncThunk(
  `${name}/yearList`,
  async (
    arg: {
      year: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;
      const { year, route } = arg;
      const { pageNo = 1, rowsPerPage = 15 } = getQueryParams(
        route?.search ?? '',
      );
      const response = await attendanceHolidayApi.findYearList({
        year: arg.year,
        pageNo,
        rowsPerPage,
      });
      const totalCount = await attendanceHolidayApi.findYearTotal({
        year: arg.year,
      });
      const list: HolidayList[] = response.map((a) => {
        return {
          checked: false,
          companyId: a.companyId,
          holiday: a.holidayYear,
          holidayName: a.holidayYearName,
          updateAt: a.updateAt,
        };
      });
      return { meta, data: { list, totalCount, year } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/* 휴일 개별 생성 */
const yearAppend = createAsyncThunk(
  `${name}/appendYear`,
  async (
    arg: {
      holiday: string;
      holidayName: string;
      holidayYear: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { route } = arg;
      const response = await attendanceHolidayApi.appendYear(arg);
      if (route !== undefined) {
        await dispatch(
          yearList({ year: timezoneDate().getFullYear().toString(), route }),
        );
      }
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/* 휴일 일괄 생성 */
const yearAllSave = createAsyncThunk(
  `${name}/yearAllSave`,
  async (
    arg: {
      holidayYear: string;
      isIncludeSaturday: boolean;
      isIncludeSunday: boolean;
      isIncludeHolidayKorea: boolean;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { route } = arg;
      const response = await attendanceHolidayApi.yearAllSave(arg);
      if (route !== undefined) {
        await dispatch(yearList({ year: arg.holidayYear, route }));
      }
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/* 휴일 삭제 */
const yearDelete = createAsyncThunk(
  `${name}/deleteYear`,
  async (
    arg: {
      holiday: string;
      holidayName: string;
      holidayYear: string;
      updateAt: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await attendanceHolidayApi.deleteYear(arg);
      const { holidayYear, route } = arg;
      if (route !== undefined) {
        await dispatch(
          yearList({
            year: holidayYear,
            route,
          }),
        );
      }
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const attendanceHolidayReducer = createSlice({
  name,
  initialState,
  reducers: {
    holidayListClear(state) {
      state.list.data.items = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(basicList.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload !== undefined) {
          state.list.data.items = payload.data.list;
          state.list.data.totalCount = payload.data.totalCount;
        }
      })
      .addCase(yearList.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload !== undefined) {
          state.list.data.items = payload.data.list;
          state.list.data.totalCount = payload.data.totalCount;
          state.list.data.year = payload.data.year;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default attendanceHolidayReducer.reducer;

export const attendanceHolidayActions = {
  clear: attendanceHolidayReducer.actions.holidayListClear,
  basicList,
  basicAppend,
  basicDelete,
  yearList,
  yearAppend,
  yearDelete,
  yearAllSave,
};
