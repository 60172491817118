import React from 'react';
import BoardDrawer from '../pages/root/board/common/BoardDrawer';

function RootLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;

  return <BoardDrawer pathname={pathname} search={search} />;
}

export default RootLocateDrawer;
