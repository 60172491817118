import React from 'react';
import ComponentTooltipBasic from './ComponentTooltipBasic';
import ComponentTooltipPositioned from './ComponentTooltipPositioned';

function ComponentTooltip(): JSX.Element {
  return (
    <>
      <ComponentTooltipBasic />
      <ComponentTooltipPositioned />
    </>
  );
}

export default ComponentTooltip;
