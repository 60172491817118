import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentButtonSize(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Sizes">
      <Button text="x small" size="xs" />
      <Button text="small" size="sm" />
      <Button text="삭제" iconType icon="times-small" size="xs" />
      <Button text="삭제" iconType icon="trash-fill" size="sm" />
      <Button text="삭제" iconType icon="trash-fill" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentButtonSize;
