import React, { useEffect, useState } from 'react';
import Dialog from '../../../../components/dialog/Dialog';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import PostView from '../../../../components/post/PostView';
import Avatar from '../../../../components/avatar/Avatar';
import ApprovalLineFlat from '../components/ApprovalLineFlat';
import { getApprovalLineDrafter } from './ApprovalLineDialogContainer';
import { getAvatarPath } from '../../../../groupware-common/utils';
import AttachmentsList from '../../../../components/attachments/AttachmentsList';
import AttachDocumentList from '../../../../components/attachments/AttachDocumentList';
import AttachDocumentItem from '../../../../components/attachments/AttachDocumentItem';
import AttachmentsItem from '../../../../components/attachments/AttachmentsItem';
import Loading from '../../../../components/loading/Loading';
import EmptyData from '../../../../components/data/EmptyData';
import {
  ChangeHistoryItem,
  documentActions,
} from '../../../stores/approval/document';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';

function ApprovalChangeHistoryBeforeDialog(props: {
  data: { documentId: number; id: number };
  onClose(): void;
}) {
  const dispatch = useAppDispatch();

  const { data, onClose } = props;
  const [state, setState] = useState<{
    loading: boolean;
    itemData: ChangeHistoryItem | undefined;
  }>({
    loading: true,
    itemData: undefined,
  });

  useEffect(() => {
    async function run() {
      if (data) {
        const { documentId, id } = data;
        dispatch(
          documentActions.fetchHistoryView({ documentId, id: [id] }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined) {
            const payload = result.payload as ChangeHistoryItem[];
            if (payload)
              setState((prevState) => ({ ...prevState, itemData: payload[0] }));
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        });
      }
    }
    run();
  }, []);

  const { loading, itemData } = state;
  if (itemData === undefined)
    return (
      <Dialog size="lg" onClose={onClose}>
        <DialogHeader>
          <DialogTitle>변경 전 문서 보기</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostView type="full">
            <PostView.Body>
              {loading && <Loading />}
              <EmptyData />
            </PostView.Body>
          </PostView>
        </DialogBody>
      </Dialog>
    );

  const drafter = getApprovalLineDrafter(itemData.approvalLine);
  if (drafter === undefined) return <div>오류</div>;

  const { organizationName: draftOrganizationName, employeeName: drafterName } =
    drafter;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : '';
  return (
    <Dialog size="lg" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>변경 전 문서 보기</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostView type="full">
          <PostView.Head>
            <PostView.Title>{itemData.subject}</PostView.Title>
            <div className="approval-view-info">
              <Avatar
                className="avatar"
                name={drafterName ?? draftOrganizationName}
                image={drafterAvatar}
                icon={drafterAvatar === '' ? 'sitemap-fill' : undefined}
              />
              <ApprovalLineFlat approvalLine={itemData.approvalLine} />
              <PostView.Info row>
                <PostView.InfoItem
                  title="기안자"
                  value={drafterName ?? draftOrganizationName}
                />
                <PostView.InfoItem
                  title="기안부서"
                  value={draftOrganizationName}
                />
              </PostView.Info>
            </div>
          </PostView.Head>
          <PostView.Body>
            <PostView.Content data={itemData.contentHTML} />
            {itemData.attachments.length > 0 && (
              <AttachmentsList
                count={itemData.attachments.length}
                className="view-attachments"
              >
                {itemData.attachments.map((x, i) => {
                  const key = `i${i}/${x.name}`;
                  return (
                    <AttachmentsItem key={key} name={x.name} size={x.size} />
                  );
                })}
              </AttachmentsList>
            )}
            {itemData.documentFile.length > 0 && (
              <AttachDocumentList count={itemData.documentFile.length}>
                {itemData.documentFile.map((x, i) => {
                  const key = `i${i}/${x.subject}`;
                  return (
                    <AttachDocumentItem
                      key={key}
                      no={x.no}
                      subject={x.subject}
                    />
                  );
                })}
              </AttachDocumentList>
            )}
          </PostView.Body>
        </PostView>
      </DialogBody>
    </Dialog>
  );
}

export default React.memo(ApprovalChangeHistoryBeforeDialog);
