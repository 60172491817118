import React, { useState } from 'react';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentCalendarDateScroll(): JSX.Element {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <ComponentSampleUI.Content title="Scroll">
      <CustomDatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="scroll"
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentCalendarDateScroll;
