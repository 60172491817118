import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { userPreferencesActions } from '../../../../stores/userPreferences';

function ResourcePreferencesRoute(props: { pathname: string }): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();
  const { employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  useEffect(() => {
    let mount = true;

    Promise.all([dispatch(userPreferencesActions.find({ employeeId }))]).then(
      () => {
        if (mount)
          dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
      },
    );

    return () => {
      mount = false;
    };
  }, [pathname]);
  return null;
}

export default ResourcePreferencesRoute;
