import React from 'react';
import ComponentAvatarImage from './ComponentAvatarImage';
import ComponentAvatarIcon from './ComponentAvatarIcon';
import ComponentAvatarGrouped from './ComponentAvatarGrouped';

function ComponentAvatar(): JSX.Element {
  return (
    <>
      <ComponentAvatarImage />
      <ComponentAvatarIcon />
      <ComponentAvatarGrouped />
    </>
  );
}

export default ComponentAvatar;
