import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Alert from '../../../../../components/alert/Alert';

function ComponentAlert(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Filled">
      <Alert variant="filled" onClose={() => alert('클릭 이벤트')}>
        This is a filled alert — check it out!
      </Alert>
      <Alert
        variant="filled"
        severity="success"
        onClose={() => alert('클릭 이벤트')}
      >
        This is a success alert — check it out!
      </Alert>
      <Alert variant="filled" severity="success">
        This is a success alert — check it out!
      </Alert>
      <Alert variant="filled" severity="warning">
        This is a warning alert — check it out!
      </Alert>
      <Alert variant="filled" severity="info">
        This is a info alert — check it out!
      </Alert>
      <Alert variant="filled" severity="error">
        This is a error alert — check it out!
      </Alert>
    </ComponentSampleUI.Content>
  );
}

export default ComponentAlert;
