import React, { useState } from 'react';
import { Language } from '../../../../../groupware-common/types';
import Button from '../../../../../components/button/Button';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import SelectField from '../../../../../components/selectField/SelectField';
import MultilingualTextField from '../../../../../components/textfield/MultilingualTextField';
import Drawer from '../../../../../components/drawer/Drawer';

/**
 * 저장 인수
 * @property parentId 상위 아이디.
 * @property names 언어별 이름.
 * @property description 설명.
 */
export type SaveArg = {
  parentId: number;
  names: { language: Language; value: string }[];
  description: string;
};

/**
 * @property items 항목 배열.
 * @property parentId 상위 아이디.
 * @property names 언어별 이름.
 * @property description 설명.
 * @property onUpdate 수정 이벤트.
 * @property onSave 저장 이벤트.
 * @property onClose 닫기 이벤트.
 */
type Props = {
  type: 'create' | 'update';
  items: { label: string; value: string }[];
  parentId: number;
  names: { language: Language; value: string }[];
  description: string;
  onUpdate?(arg: {
    parentId: number;
    names?: { language: Language; value: string }[];
    description?: string;
  }): void;
  onSave?({ parentId, names, description }: SaveArg): void;
  onClose(): void;
};

function JobDutyContentEdit(props: Props): JSX.Element {
  // console.log(`${JobDutyContentAtEdit.name}.render`);

  const [state, setState] = useState(() => {
    const { parentId, names, description } = props;
    return { parentId, names, description, validation: '' };
  });

  const handleParentChange = (value: string) => {
    setState((prevState) => ({ ...prevState, parentId: parseInt(value, 10) }));
  };

  const handleNameChange = (language: Language, value: string) => {
    setState((prevState) => {
      const names = prevState.names.map((name) =>
        name.language === language ? { ...name, value } : name,
      );
      return { ...prevState, names };
    });
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const description = event.target.value;
    setState((prevState) => ({ ...prevState, description }));
  };

  const handleSave = () => {
    const { onUpdate, onSave } = props;
    const { parentId, description } = state;

    let newValidation = state.validation;
    if (state.names[0] === undefined || state.names[0].value.trim() === '')
      newValidation = '이름은 빈 값 일 수 없습니다.';
    else newValidation = '';

    if (newValidation !== '') {
      setState((prev) => ({ ...prev, validation: newValidation }));
      return;
    }

    if (onSave) onSave({ parentId, names, description });
    else if (onUpdate) {
      const names: { language: Language; value: string }[] = [];
      props.names.forEach((a, i) => {
        const b = state.names[i];
        if (a.language === b.language && a.value !== b.value) {
          if (b.value.trim() !== '')
            names.push({ language: b.language, value: b.value });
        }
      });
      onUpdate({
        parentId,
        names: names.length === 0 ? undefined : names,
        description:
          props.description === description ? undefined : description,
      });
    }
  };

  const { type, items, onClose } = props;
  const { parentId, names, description, validation } = state;
  const title = type === 'update' ? '직책수정' : '직책등록';

  return (
    <Drawer title={title} onClose={onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title="상위직책">
            <SelectField
              data={items}
              value={`${parentId}`}
              onChange={handleParentChange}
              block
            />
          </PostWrite.Item>
          <PostWrite.Item title="직책명">
            <MultilingualTextField
              value={names}
              onChange={handleNameChange}
              error={validation !== ''}
            />
          </PostWrite.Item>
          <PostWrite.Item title="직책설명">
            <TextField
              multiline
              value={description}
              onChange={handleDescriptionChange}
            />
          </PostWrite.Item>
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button text="저장" variant="contained" onClick={handleSave} />
      </DrawerAction>
    </Drawer>
  );
}

export default JobDutyContentEdit;
