import React from 'react';
import ComponentCheckboxBasic from './ComponentCheckboxBasic';
import ComponentCheckboxGroup from './ComponentCheckboxGroup';
import ComponentCheckboxButton from './ComponentCheckboxButton';
import ComponentCheckboxButtonSimple from './ComponentCheckboxButtonSimple';
import ComponentCheckboxAll from './ComponentCheckboxAll';

function ComponentCheckbox(): JSX.Element {
  return (
    <>
      <ComponentCheckboxBasic />
      <ComponentCheckboxGroup />
      <ComponentCheckboxButton />
      <ComponentCheckboxButtonSimple />
      <ComponentCheckboxAll />
    </>
  );
}

export default ComponentCheckbox;
