/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { DocumentData } from '../document';
import { DocumentItem } from '../permission';
import { AdvancedSearchParams } from '../../../../../groupware-common/utils';

/**
 * 문서를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @returns 문서 객체. (결재 문서 조회와 반환값 같음)
 */
async function data(arg: {
  folderId: number;
  affiliatedCompanyId?: number;
  id: number;
}): Promise<DocumentData> {
  const { folderId, affiliatedCompanyId, id } = arg;

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/users/me/folders/${folderId}/documents/${id}`;
    const response = await axios.get(url, {
      headers,
      params: {
        affiliatedcompanyid: affiliatedCompanyId,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서의 이전 또는 다음 문서 아이디를 조회합니다.
 * @param arg.type 이전 또는 다음.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 이전 또는 다음 문서 아이디 객체 또는 undefined.
 */
async function readPrevIdOrNextId(arg: {
  type: 'PREV' | 'NEXT';
  documentId: number;
  folderId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  const { type, folderId, documentId, advancedSearch } = arg;

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/users/me/folders/${folderId}/documents/${documentId}/${type}`;
    const response = await axios.get(url, {
      headers,
      params: {
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서의 이전 문서 아이디를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 이전 문서 아이디 객체 또는 undefined.
 */
async function prevId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'PREV' });
}

/**
 * 문서의 다음 문서 아이디를 조회합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.searchCode 조회 코드.  (work: 업무 이름, no: 문서 번호, subject: 제목, drafterid: 기안자 아이디)
 * @param arg.searchWord 조회 단어.
 * @returns 다음 문서 아이디 객체 또는 undefined.
 */
async function nextId(arg: {
  folderId: number;
  documentId: number;
  advancedSearch: AdvancedSearchParams;
}): Promise<{ affiliatedCompanyId?: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'NEXT' });
}

/**
 * 폴더에 문서를 복사 합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @returns 폴더 문서 항목 데이터 전송 객체.
 */
async function copy(
  arg: {
    folderId: number;
    affiliatedCompanyId?: number;
    id: number;
  }[],
): Promise<
  {
    folderId: number; // 폴더 아이디.
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디. (관계사인 경우만 출력)
    id: number; // 문서 아이디.
    updateAt: string; // 수정 날짜.
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/personal/document/copy`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 소스 폴더에서 대상 폴더로 문서를 이동합니다.
 * @param arg.sourceFolderId 소스 폴더 아이디.
 * @param arg.targetFolderId 대상 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.updateAt 수정 날짜.
 * @returns 폴더 문서 항목 데이터 전송 객체.
 */
async function move(
  arg: {
    sourceFolderId: number;
    targetFolderId: number;
    affiliatedCompanyId?: number;
    id: number;
    updateAt: string;
  }[],
): Promise<
  {
    folderId: number; // 폴더 아이디.
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디. (관계사인 경우만 출력)
    id: number; // 문서 아이디.
    updateAt: string; // 수정 날짜.
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/personal/document/move`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 폴더에서의 문서를 삭제합니다.
 * @param arg.folderId 폴더 아이디.
 * @param arg.affiliatedCompanyId 문서 관계사 회사 아이디. (관계사인 경우만 출력)
 * @param arg.id 문서 아이디.
 * @param arg.updateAt 수정 날짜.
 * @returns 폴더 문서 항목 데이터 전송 객체.
 */
async function _delete(
  arg: {
    folderId: number;
    affiliatedCompanyId?: number;
    id: number;
    updateAt: string;
  }[],
): Promise<
  {
    folderId: number; // 폴더 아이디.
    affiliatedCompanyId?: number; // 문서 관계사 회사 아이디. (관계사인 경우만 출력)
    id: number; // 문서 아이디.
    updateAt: string; // 수정 날짜.
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/personal/document/delete`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서 목록을 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function documents(arg: {
  folderId: number;
  pageno: number;
  rowsperpage: number;
  advancedSearch?: AdvancedSearchParams;
}): Promise<{
  count?: number; // 문서 총 갯수.
  list: DocumentItem[];
}> {
  try {
    const { folderId, pageno, rowsperpage, advancedSearch } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/users/me/folders/${folderId}/documents`;
    const response = await axios.get(url, {
      headers,
      params: {
        pageno,
        rowsperpage,
        ...advancedSearch,
      },
    });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/**
 * 문서 총 개수를 조회 합니다.
 * @returns 문서 항목 데이터 전송 객체 배열.
 */
async function totalCount(arg: {
  folderId: number;
  subject?: string;
  documentNumber?: string;
  workName?: string;
  formName?: string;
  content?: string;
  attachedFileName?: string;
  drafterName?: string;
  draftOrganizationName?: string;
  startDate?: string;
  endDate?: string;
  pageno: number;
  rowsperpage: number;
}) {
  const {
    folderId,
    subject,
    documentNumber: documentnumber,
    workName: workname,
    formName: formname,
    content,
    attachedFileName: attachedfilename,
    drafterName: draftername,
    draftOrganizationName: draftorganizationname,
    startDate: startdate,
    endDate: enddate,
    pageno,
    rowsperpage,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/users/me/folders/${folderId}/documents/count`;
    const response = await axios.get<{ count: number }>(url, {
      headers,
      params: {
        subject,
        documentnumber,
        workname,
        formname,
        content,
        attachedfilename,
        draftername,
        draftorganizationname,
        startdate,
        enddate,
        pageno,
        rowsperpage,
      },
    });
    return response.data.count;
  } catch (ex) {
    throw apiError(ex);
  }
}

const personalDocumentApi = {
  data,
  prevId,
  nextId,
  copy,
  move,
  delete: _delete,

  documents,
  totalCount,
};

export default personalDocumentApi;
