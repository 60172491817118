/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import EmptyData from '../../../../../components/data/EmptyData';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import Loading from '../../../../../components/loading/Loading';
import PostView from '../../../../../components/post/PostView';
import { ApiError } from '../../../../../groupware-common/types/error';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import sendNoticeApi from '../../../../apis/attendance/v1/sendNotice';

function AttendanceSendNoticeDocumentViewDialog(props: {
  employeeId: number;
  standardDate: string;
  alertFormType: string;
  alertType: number;
  onClose(): void;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<{
    loading: boolean;
    form:
      | {
          id: number;
          contents: string; // 내용
          updateAt: string; // 수정 날짜
        }
      | undefined;
  }>({
    loading: true,
    form: undefined,
  });
  useEffect(() => {
    if (props.employeeId === 0 || props.standardDate === '') return;
    async function run() {
      try {
        const form = await sendNoticeApi.fetchView({
          employeeId: props.employeeId,
          standardDate: props.standardDate,
          alertType: props.alertType,
          alertFormType: props.alertFormType,
        });
        setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            form: {
              id: form.id,
              contents: form.contents,
              updateAt: form.updateAt,
            },
          };
        });
      } catch (ex) {
        dispatch(sessionActions.error(ex as ApiError));
      }
    }
    run();
  }, []);

  if (!state.form) {
    return (
      <Dialog onClose={props.onClose}>
        <DialogBody>
          {state.loading && <Loading />}
          <EmptyData />
        </DialogBody>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={props.onClose}>
      <DialogBody>
        <PostView type="full">
          <PostView.Body>
            <PostView.Content data={state.form.contents} />
          </PostView.Body>
        </PostView>
      </DialogBody>
    </Dialog>
  );
}

export default AttendanceSendNoticeDocumentViewDialog;
