import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';
import { RootState } from '../../../../groupware-webapp/app/store';
import {
  CacheEntity,
  CacheMeta,
  Language,
  LocateArg,
} from '../../../../groupware-common/types';
import api from '../../../apis/directory/v1/company';
import { thunkCondition } from '../../../../groupware-webapp/stores/utils';
import { appError } from '../../../../groupware-webapp/stores/common/utils';

const name = 'directory/company';

interface CompanyItem {
  id: number;
  updateAt: string;
  //
  nameKey: string;
}

interface CompanyView {
  id: number;
  representativesName: string;
  establishedDate: string;
  businessRegistrationNo: string;
  corporationRegistrationNo: string;
  businessType: string;
  businessItem: string;
  phoneNo: string;
  faxNo: string;
  postalCode: string;
  address: string;
  employeeCount: number;
  accountMaxLimit: number;
  updateAt: string;
  nameKey: string;
  primaryColor: string;
  useExternalDirectory: boolean;
}

interface State {
  list: CacheEntity<{
    items: CompanyItem[];
    totalCount: number;
    search: string;
  }>;
  view: CacheEntity<CompanyView | null | undefined>;
}

const initialState: State = {
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [], totalCount: 0, search: '' },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (_: void, thunkApi) => {
    let meta: CacheMeta | undefined;
    let data:
      | { items: CompanyItem[]; totalCount: number; search: string }
      | undefined;
    try {
      const rootState = thunkApi.getState() as RootState;
      // eslint-disable-next-line prettier/prettier
      const { list } = rootState.directory.employee;

      const timestamp = Date.now();
      if (list.meta.timestamp > timestamp - 300000) return { meta, data };

      const { updateAt: lastUpdateAt } = await api.findLastUpdateAt();
      meta = { timestamp, lastUpdateAt };

      if (list.meta.lastUpdateAt !== meta.lastUpdateAt) {
        const response = await api.findList();
        const items = response.map(({ id, updateAt }) => {
          const nameKey = api.getCompanyNameKey(id);
          return { id, updateAt, nameKey };
        });
        const totalCount = 0;
        const search = '';
        data = { items, totalCount, search };
      }

      return { meta, data };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findList.typePrefix, arg, getState);
    },
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { id: number | 'first' } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    let meta: CacheMeta | undefined;
    let data: CompanyView | null | undefined;
    let _response_id_: number | undefined;
    try {
      let id: number | null = null;
      if (arg.id === 'first') {
        id =
          (getState() as RootState).directory.company.list.data.items[0]?.id ??
          0;
      } else id = arg.id;

      if (id !== null && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      const response = await api.findView();
      if (response) {
        const nameKey = api.getCompanyNameKey(id);
        data = { ...response, nameKey };
      }

      return { meta, data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findView.typePrefix, arg, getState);
    },
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      names?: { code: Language; value: string }[];
      representativesName?: string;
      establishedDate?: string;
      businessRegistrationNo?: string;
      corporationRegistrationNo?: string;
      businessType?: string;
      businessItem?: string;
      phoneNo?: string;
      faxNo?: string;
      postalCode?: string;
      address?: string;
      employeeCount?: number;
      updateAt: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const param = { ...arg, ...{ route: undefined } };

      const response = await api.update(param);
      const { id } = response;

      const companyNameKey = api.getCompanyNameKey(id, false);
      const organizationNameKey = api.getOrganizationNameKey(id, false);

      const i18n = getI18n();
      param.names?.forEach(({ code, value }) => {
        if (value !== null) {
          i18n.addResource(code, api.namespace, companyNameKey, value);
          i18n.addResource(code, api.namespace, organizationNameKey, value);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const i18nResource: any =
            i18n.services.resourceStore.data[code][api.namespace];
          const organizationResourceId = 'organization';
          const companyResourceId = 'company';
          const resourceName = `${id}_${id}`;
          delete i18nResource[organizationResourceId][resourceName];
          delete i18nResource[companyResourceId][resourceName];
        }
      });

      await dispatch(findList());
      await dispatch(findView({ id }));

      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(update.typePrefix, arg, getState);
    },
  },
);

const companySlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 리스트 메타 상태 값 할당.
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 리스트 데이터 상태 값 할당.
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 뷰 메타 상태 값 할당.
        if (payload?.meta !== undefined) state.view.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 뷰 데이터 상태 값 할당.
        if (payload?.data !== undefined) state.view.data = payload.data;
      });
  },
});

export default companySlice.reducer;

export const companyActions = {
  findList,
  findView,
  update,
};
