import React, { useState } from 'react';
import Dialog from '../../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../../components/dialog/DialogFooter';
import Button from '../../../../../../components/button/Button';
import PostWrite from '../../../../../../components/post/PostWrite';
import TextField from '../../../../../../components/textfield/TextField';

/** 결재 취소 대화 상자. */
function ApprovalCancelDialog(props: {
  onConfirm(arg: { opinion?: string }): void;
  onClose(): void;
}): JSX.Element {
  // console.log(`${ApprovalCancelDialog.name}.render(props)`, props);

  const [opinion, setOpinion] = useState('');

  /** 의견 변경. */
  const handleOpinionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setOpinion(event.currentTarget.value);
  };

  /** 확인. */
  const handleConfirm = () => {
    const { onConfirm } = props;
    onConfirm({ opinion: opinion.trim() || undefined });
  };

  const { onClose } = props;

  return (
    <>
      <Dialog size="sm">
        <DialogHeader>
          <DialogTitle>결재 취소</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            <PostWrite.Item className="comment-item" title="결재의견">
              <TextField
                count
                maxLength={500}
                multiline
                rows="2"
                value={opinion}
                onChange={handleOpinionChange}
              />
            </PostWrite.Item>
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          <Button text="취소" onClick={onClose} />
          <Button text="확인" variant="contained" onClick={handleConfirm} />
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ApprovalCancelDialog;
