import { useEffect } from 'react';
import { useAppDispatch } from '../../../groupware-webapp/app/store';

import { formFolderActions } from '../../stores/systemlink/form';
import { formActions } from '../../../groupware-approval/stores/approval/form';

function SystemLinkFormRoute({ pathname }: { pathname: string }): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([dispatch(formActions.clear())]).then(() => {
      dispatch(formFolderActions.list({ route: { pathname } }));
    });
  }, [dispatch, pathname]);

  return null;
}

export default SystemLinkFormRoute;
