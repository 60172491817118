import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import {
  GroupItem,
  GroupView,
  SaveGroupArg,
} from '../../../../stores/directory/group';

interface ReturnType {
  companyId: number;
  id: number;
  updateAt: string;
}
async function findList(): Promise<GroupItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/directory/v1/groups`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function findView(arg: { id: number }): Promise<{
  id: number;
  updateAt: string;
  includers: {
    referenceCompanyId: number;
    referenceId: number;
    referenceType: 'ORGANIZATION' | 'EMPLOYEE';
    createAt: string;
  }[];
}> {
  try {
    const { id } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/directory/v1/groups/${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function create(arg: SaveGroupArg): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/directory/v1/groups`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function update(arg: GroupView): Promise<{
  nameId: number;
  id: number;
  companyId: number;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/directory/v1/groups`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function deleteGroup(arg: {
  id: number;
  updateAt: string;
}): Promise<ReturnType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/directory/v1/groups`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const groupApi = {
  create,
  update,
  deleteGroup,
  findList,
  findView,
};

export default groupApi;
