import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Alert from '../../../../../components/alert/Alert';

function ComponentAlertBasic(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Basic alerts">
      <Alert severity="error">This is an error alert — check it out!</Alert>
      <Alert severity="warning">This is a warning alert — check it out!</Alert>
      <Alert severity="info">This is an info alert — check it out!</Alert>
      <Alert severity="success">This is a success alert — check it out!</Alert>
    </ComponentSampleUI.Content>
  );
}

export default ComponentAlertBasic;
