import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import DialogToolbar from '../../../../../components/dialog/DialogToolbar';
import Search from '../../../../../components/search/Search';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import BoardWorkFormPreview from './BoardWorkFormPreview';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';
import { formApi } from '../../../../apis/board/v1/form';

type Props = {
  onConfirm(arg: { id: number; name: string }[]): void;
  onClose(): void;
  search: string;
};

function BoardWorkForm(props: Props): JSX.Element {
  const scrollbar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [state, setState] = useState(() => {
    return {
      loading: true,
      searchCode: 'form',
      searchWord: '',
      items: [] as {
        checked: boolean;
        id: number;
        folderName: string;
        name: string;
      }[],
      pagination: {
        no: 1,
        row: 15,
        total: -1,
      },
      preview: false,
      previewId: 0,
      content: '',
      formName: '',
    };
  });

  /** 검색 코드, 검색 단어 유효성 검사 */
  const handleSearchFormat = (code: string, word: string) => {
    const searchWordFormat = word.trim() === '' ? '' : word;
    const searchCodeFormat = word.trim() === '' ? '' : code;
    return { searchWordFormat, searchCodeFormat };
  };

  const formFolderList = useSelector(
    (s: RootState) => s.boards.form.folder.list,
  );

  const queryParams = getQueryParams(props.search ?? '');

  useEffect(() => {
    let mount = true;
    async function run() {
      if (mount) {
        const { searchCode, searchWord, pagination } = state;
        const { searchWordFormat, searchCodeFormat } = handleSearchFormat(
          searchCode,
          searchWord,
        );
        queryParams.searchCode = searchCodeFormat;
        queryParams.searchWord = searchWordFormat;
        queryParams.pageNo = pagination.no;
        queryParams.rowsPerPage = pagination.row;

        const list = await formApi.formList({
          pageno: queryParams.pageNo,
          rowsperpage: queryParams.rowsPerPage,
          searchcode: queryParams.searchCode,
          searchword: queryParams.searchWord,
        });
        const total = await formApi.formTotalCount({
          searchcode: queryParams.searchCode,
          searchword: queryParams.searchWord,
        });
        setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            items: list
              .filter((a) => a.status === 1)
              .map((a) => ({
                checked: false,
                id: a.id,
                folderName:
                  formFolderList.find((v) => v.id === a.folderId)?.name ?? '',
                name: a.name,
              })),
            pagination: {
              ...pagination,
              total,
            },
          };
        });
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, [
    state.pagination.no,
    state.pagination.row,
    state.searchCode,
    state.searchWord,
  ]);

  useEffect(() => {
    const { previewId } = state;
    let mount = true;

    async function run() {
      try {
        if (previewId === 0) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
          return;
        }
        const form = await formApi.form({ id: previewId });
        if (!mount) return;
        setState((prevState) => ({
          ...prevState,
          loading: false,
          content: form.contents,
          formName: form.name,
        }));
        scrollbar.current?.scrollTo(0, 0);
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, [state.preview]);

  const handleSearch = (arg: { keyword: string; filter: string }) => {
    const { keyword, filter } = arg;
    const { searchCode, searchWord } = state;
    if (searchCode !== filter || searchWord !== keyword)
      setState((prevState) => ({
        ...prevState,
        loading: true,
        searchCode: filter,
        searchWord: keyword,
        pagination: {
          ...prevState.pagination,
          no: 1,
        },
      }));
  };

  /* 체크박스 이벤트 */
  const handleChangeCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    setState((prevState) => ({
      ...prevState,
      items: items.map((a) => {
        if (a.id === id) {
          return {
            ...a,
            checked: event.target.checked,
          };
        }
        return a;
      }),
    }));
  };

  /* 체크박스 전체선택 이벤트 */
  const handleChangeCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      items: items.map((a) => {
        return {
          ...a,
          checked: event.target.checked,
        };
      }),
    }));
  };

  /** 선택 완료 */
  const handleConfirmClick = () => {
    const forms = state.items
      .filter((a) => a.checked)
      .map((b) => ({ id: b.id, name: b.name }));
    if (forms.length === 0) return; // TODO: 유효성 구현 필요
    props.onConfirm(forms);
  };

  /** 목록개수 변경. */
  const handleChangeRowLength = (row: number) => {
    const pagination = { ...state.pagination, no: 1, row };
    setState((prevState) => ({ ...prevState, pagination }));
  };

  /** 데이터 그리드 클릭. */
  const handleDataGridClick = (arg: {
    code: string;
    id?: number;
    event: React.MouseEvent;
  }) => {
    const { code } = arg;

    if (code === 'prePage') {
      const no = state.pagination.no - 1;
      const pagination = { ...state.pagination, no };
      setState((prevState) => ({ ...prevState, loading: true, pagination }));
      return;
    }
    if (code === 'nextPage') {
      const no = state.pagination.no + 1;
      const pagination = { ...state.pagination, no };
      setState((prevState) => ({ ...prevState, loading: true, pagination }));
    }
    if (code === 'subject') {
      const { id } = arg;
      if (id === undefined) return;
      setState((prevState) => ({
        ...prevState,
        loading: true,
        preview: true,
        previewId: id,
      }));
    }
    if (code === 'cancelSelected') {
      setState((prevState) => ({
        ...prevState,
        items: items.map((a) => {
          return {
            ...a,
            checked: false,
          };
        }),
      }));
    }
  };

  const { loading, searchCode, searchWord, items, pagination } = state;

  const columns: DataGridColDef[] = [
    { field: 'checkbox', label: '', width: 56 },
    { field: 'category', label: '분류', width: 130 },
    { field: 'subject', label: '양식명', minWidth: 200 },
  ];

  const rows: DataGridRowsProps<number> = items.map((v) => {
    return [
      {
        type: 'checkbox' as const,
        id: v.id,
        value: v.checked,
      },
      {
        type: 'text' as const,
        value: v.folderName,
      },
      { type: 'subject' as const, value: v.name, id: v.id },
    ];
  });

  const renderPreviewBody = () => {
    const { formName, content } = state;
    const html = FormBuilder.viewingPreview({ content });

    return (
      <>
        <BoardWorkFormPreview
          html={html}
          title={formName}
          scrollbar={scrollbar}
          loading={loading}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              preview: false,
              previewId: 0,
              content: '',
              formName: '',
            }));
          }}
        />
      </>
    );
  };

  const renderListBody = () => {
    return (
      <>
        <DialogHeader>
          <DialogTitle>게시양식 선택</DialogTitle>
        </DialogHeader>
        <DialogToolbar>
          <DialogToolbar.Left>{null}</DialogToolbar.Left>
          <DialogToolbar.Right>
            <Search
              keyword={searchWord}
              options={[{ value: 'form', label: '양식명' }]}
              filter={searchCode}
              onSearch={handleSearch}
            />
          </DialogToolbar.Right>
        </DialogToolbar>
        <DialogBody>
          <DataGrid
            scrollbar={scrollbar}
            caption="게시양식 리스트"
            loading={loading}
            checkedCount={state.items.filter((i) => i.checked).length}
            columns={columns}
            rows={rows}
            pagination={{
              ...pagination,
              onChangeRow: handleChangeRowLength,
            }}
            onChecked={handleChangeCheck}
            onCheckedAll={handleChangeCheckAll}
            onClick={handleDataGridClick}
          />
        </DialogBody>
        <DialogFooter>
          <Button text="취소" color="secondary" onClick={props.onClose} />
          <Button
            text="추가"
            variant="contained"
            onClick={handleConfirmClick}
            disabled={loading || items.filter((a) => a.checked).length <= 0}
          />
        </DialogFooter>
      </>
    );
  };

  const { preview } = state;

  return (
    <Dialog overflow="hidden" size="lg">
      {preview ? renderPreviewBody() : renderListBody()}
    </Dialog>
  );
}

export default BoardWorkForm;
