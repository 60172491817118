import React from 'react';
import ComponentAlertBasic from './ComponentAlertBasic';
import ComponentAlertDescription from './ComponentAlertDescription';
import ComponentAlertActions from './ComponentAlertActions';
import ComponentAlertFilled from './ComponentAlertFilled';

function ComponentAlert(): JSX.Element {
  return (
    <>
      <ComponentAlertBasic />
      <ComponentAlertDescription />
      <ComponentAlertActions />
      <ComponentAlertFilled />
    </>
  );
}

export default ComponentAlert;
