import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { folderBoxActions } from '../../../../stores/folder';
import { trashActions } from '../../../../stores/trash';

function BoardTrashRoute(props: { pathname: string; search: string }): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(folderBoxActions.allFolderList()).then(() => {
      dispatch(trashActions.list({ search, route: { pathname, search } }));
    });
  }, [dispatch, pathname, search]);

  return null;
}

export default BoardTrashRoute;
