import React from 'react';
import ComponentBreadcrumbsBasic from './ComponentBreadcrumbsBasic';

function ComponentBreadcrumbs(): JSX.Element {
  return (
    <>
      <ComponentBreadcrumbsBasic />
    </>
  );
}

export default ComponentBreadcrumbs;
