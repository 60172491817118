import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import ServiceMenuContainer from '../pages/adminconsole/service/menu/ServiceMenuContainer';
import ServiceBasicContainer from '../pages/adminconsole/service/basic/ServiceBasicContainer';

function AdminconsoleLocateContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element | null {
  const { pathname, search } = props;
  const { menu } = getPathParams('/*/*/:menu', props.pathname);

  // 운영 메뉴
  if (menu === 'menu') {
    return <ServiceMenuContainer pathname={pathname} search={search} />;
  }
  // 기본 설정
  return <ServiceBasicContainer pathname={pathname} search={search} />;
}

export default AdminconsoleLocateContainer;
