import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../../groupware-common/types';
import { appError } from '../../../../groupware-webapp/stores/common/utils';
import groupApi from '../../../apis/directory/v1/group';
import { LocaleString } from '../../../../groupware-webapp/stores/i18n';
import { RootState } from '../../../../groupware-webapp/app/store';
import {
  addResourceValue,
  b62,
  getPathMap,
} from '../../../../groupware-common/utils';

const name = 'directory/group';

export interface GroupItem {
  id: number;
  updateAt: string;
}
export interface GroupView extends GroupItem {
  names: LocaleString[];
  includers: GroupUserArg[];
}

export interface GroupUserArg {
  referenceCompanyId: number;
  referenceId: number;
  referenceType: 'ORGANIZATION' | 'EMPLOYEE';
  isDeleted?: boolean;
  updateAt?: string;
}

export interface SaveGroupArg {
  names: LocaleString[];
  includers: GroupUserArg[];
}

interface State {
  list: GroupItem[];
  view: GroupView | undefined;
}

const initialState: State = {
  list: [],
  view: undefined,
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (_: LocateArg, { rejectWithValue }) => {
    try {
      const list = await groupApi.findList();
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (arg: { id: number } & LocateArg, { rejectWithValue, getState }) => {
    const { languages } = (getState() as RootState).session;
    const { companyId } = (getState() as RootState).session.principal;
    try {
      const view = await groupApi.findView({ id: arg.id });
      return {
        ...view,
        names: languages.map((language) => ({
          code: language,
          value: `${companyId}_${view.id}`,
        })),
        includers: view.includers.map((x) => ({ ...x, updateAt: x.createAt })),
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const save = createAsyncThunk(
  `${name}/save`,
  async (
    arg: {
      data: SaveGroupArg;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const data = { ...arg.data, excluders: [] };
      const response = await groupApi.create(data);

      const prefix = 'group';
      const namespace = 'directory';
      const nameKey = `${prefix}.${response.companyId}_${response.id}`;
      addResourceValue({
        data: data.names.map((x) => ({ language: x.code, value: x.value })),
        namespace,
        nameKey,
      });

      if (arg.route) {
        const route = {
          pathname: `${getPathMap('/*/*/*', arg.route.pathname)}/${b62(
            response.id,
          )}`,
        };
        dispatch(findList({ route }));
        dispatch(findView({ id: response.id }));
      }

      return { response };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: { data: GroupView } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { route } = arg;
      const data = { ...arg.data, excluders: [] };
      const response = await groupApi.update(data);

      const prefix = 'group';
      const namespace = 'directory';
      const nameKey = `${prefix}.${response.companyId}_${response.id}`;
      addResourceValue({
        data: data.names.map((x) => ({ language: x.code, value: x.value })),
        namespace,
        nameKey,
      });

      if (route) dispatch(findList({ route }));
      dispatch(findView({ id: data.id }));

      return { data: response };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const deleteGroup = createAsyncThunk(
  `${name}/deleteGroup`,
  async (
    arg: { id: number; updateAt: string } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await groupApi.deleteGroup(arg);
      if (arg.route) dispatch(findList({ route: arg.route }));
      return response;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const userGroupSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findList.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload) {
        state.list = payload;
      }
    });
    builder.addCase(findView.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload) {
        state.view = payload;
      }
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload) {
        state.view = undefined;
      }
    });
  },
});

export default userGroupSlice.reducer;

export const userGroupActions = {
  findList,
  findView,
  save,
  update,
  deleteGroup,
};
