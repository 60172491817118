import { useEffect } from 'react';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { calendarsActions } from '../../../stores/calendar/calendars';
import { preferencesActions } from '../../../stores/calendar/preferences';

function CalendarSubFolderRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    Promise.all([
      dispatch(preferencesActions.colorList()),
      dispatch(calendarsActions.subCalendarViewClear()),
      dispatch(calendarsActions.subCalendarList()),
    ]).then(() => {
      if (mount) dispatch(sessionActions.setRoute({ pathname, search }));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default CalendarSubFolderRoute;
