import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '../textfield/TextField';
import SelectField from '../selectField/SelectField';
import Button from '../button/Button';
import CustomDatePicker from '../date/CustomDatePicker';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../tree/DirectoryTree';
import { RootState } from '../../groupware-webapp/app/store';
import Menu from '../menu/Menu';
import useNoInitialEffect from '../../groupware-common/hooks/useDidMountEffect';
import DropMenu from '../selectField/DropMenu';
import { dateFormat, timezoneDate } from '../../groupware-common/utils/ui';
import Radio from '../radio/Radio';

export interface SearchDateProps {
  start: Date | null;
  end: Date | null;
}

function AttendanceSearch(props: {
  className?: string;
  applyType?: string;
  directory?: boolean; // 조직도 검색할 경우 ture
  dualSearch?: boolean; // 조직도와 일반 검색 둘 다 사용할 경우 true
  keyword?: string;
  filter?: string;
  status?: string;
  options?: { label: string; value: string }[]; // 조직도 검색만 할 경우 undefined 조직도,일반 | 일반 검색할 경우 object
  applyTypeOptions?: { label: string; value: string }[];
  statusOptions?: { label: string; value: string }[];
  dateOptions?: boolean; // 기간 검색 조건 추가 시.
  date?: SearchDateProps;
  onSearch(arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
    applyType?: string;
    status?: string;
  }): void;
  full?: boolean;
}): JSX.Element {
  const display = useSelector((s: RootState) => s.session.display);
  const principal = useSelector((s: RootState) => s.session.principal);
  const companies = useSelector(
    (s: RootState) => s.directory.company.list.data.items,
  ).filter(({ id }) => id === principal.companyId);
  const organizations = useSelector(
    (s: RootState) => s.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (s: RootState) => s.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );

  const items = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );

  const keyWordRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<{
    keyword: string;
    isDirectoryClick: boolean;
    directoryKeyword: string;
    filter: string;
    applyType?: string;
    status?: string;
    dateType: string;
    date: SearchDateProps;
    visible: boolean;
    point: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    datePoint:
      | {
          x: number;
          y: number;
          width: number;
          height: number;
        }
      | undefined;
  }>({
    keyword: props.keyword ?? '',
    isDirectoryClick: false,
    directoryKeyword: '',
    filter: props.filter ?? '',
    applyType: props.applyType,
    status: props.status,
    dateType: '',
    date: {
      start: props.date && props.date.start !== null ? props.date.start : null,
      end: props.date && props.date.end !== null ? props.date.end : null,
    },
    visible: !!props.directory && !props.dualSearch,
    point: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    datePoint: undefined,
  });

  /** 중복 사원(겸직부서 있는 경우 대표부서만 보이도록) 제거. */
  const list = items.filter((a, index) => {
    if (a.extra.type === 'employee') {
      const id = a.extra.employeeId;
      const organizationId = employees.find(
        (x) => x.id === id,
      )?.representativeOrganizationId;
      if (organizationId) {
        return (
          items.findIndex((x) => {
            return (
              x.id.split('_')[2] === a.id.split('_')[2] &&
              x.id.split('_')[1] === organizationId.toString()
            );
          }) === index
        );
      }
    }
    return (
      items.findIndex((x) => {
        return (
          x.id.split('_')[x.id.split('_').length - 1] ===
          a.id.split('_')[a.id.split('_').length - 1]
        );
      }) === index
    );
  });
  /** 기본검색 필터 */
  const handleChangeFilter = (value: string) => {
    if (state.filter === value) return;
    if (props.dualSearch) {
      setState((prevState) => ({
        ...prevState,
        filter: value,
        applyType: props.applyType,
        status: props.status,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filter: value,
        applyType: props.applyType,
        status: props.status,
        keyword: props.keyword ?? '',
      }));
    }
  };

  /** 구분 필터 */
  const handleChangeApplyType = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      applyType: value,
    }));
  };

  /** 결재상태 필터 */
  const handleChangeStatus = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  /** 기본검색 키워드 */
  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      filter: props.directory && !props.dualSearch ? '' : state.filter,
      keyword: event.target.value,
    }));

    if (props.directory && event.target.value !== '') {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        isDirectoryClick: false,
        visible: props.dualSearch ? state.filter === 'employee' : true,
        point: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  /** 조직도 아이템 선택. */
  const handleItemClick = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    let search = '';
    let directoryKeyword = '';
    if (extra.type === 'organization') {
      search = extra.organizationName;
      directoryKeyword = `${extra.companyId}_${extra.organizationId}`;
    }
    if (extra.type === 'employee') {
      search = extra.employeeName;
      directoryKeyword = `${extra.companyId}_${extra.organizationId}_${extra.employeeId}`;
    }
    setState((prevState) => ({
      ...prevState,
      filter: extra.type,
      keyword: search,
      isDirectoryClick: true,
      directoryKeyword,
      visible: false,
    }));
  };

  /** 기본검색 키워드에서 Enter 누름 */
  const handleSearchKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  /** 기본검색 키워드 비우기 */
  const handleClearKeyword = () => {
    setState((prevState) => ({
      ...prevState,
      filter: !!props.directory && !props.dualSearch ? '' : prevState.filter,
      isDirectoryClick: false,
      keyword: '',
    }));
    keyWordRef.current?.focus();
  };

  /** 기본검색 검색 */
  const handleSearch = () => {
    props.onSearch({
      keyword: state.keyword,
      directoryKeyword:
        props.directory && state.filter !== '' && state.isDirectoryClick
          ? state.directoryKeyword
          : undefined,
      filter: state.filter,
      date: state.date,
      applyType: state.applyType === 'all' ? undefined : state.applyType,
      status: state.status === 'all' ? undefined : state.status,
    });
    setState((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  /** 검색 기준 시작일 변경. */
  const handleChangeCustomStartDate = (date: Date | null) => {
    const { start, end } = state.date;
    let newEnd = state.date.end;
    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        start: date !== null ? date : prevState.date.start,
      },
    }));
    if (end && date !== null && end < date) {
      newEnd = new Date(date);
      setState((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          end: date,
        },
      }));
    }
    if (start !== null && end !== null) {
      setState((prev) => ({
        ...prev,
        dateType: `${dateFormat(
          date !== null ? date : start,
          'yyyy-MM-DD',
        )} ~ ${dateFormat(newEnd !== null ? newEnd : end, 'yyyy-MM-DD')}`,
      }));
    }
  };

  /** 검색 기준 종료일 변경. */
  const handleChangeCustomEndDate = (date: Date | null) => {
    const { start, end } = state.date;
    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        end: date !== null ? date : prevState.date.end,
      },
    }));
    if (start !== null && end !== null) {
      setState((prev) => ({
        ...prev,
        dateType: `${dateFormat(start, 'yyyy-MM-DD')} ~ ${dateFormat(
          date !== null ? date : end,
          'yyyy-MM-DD',
        )}`,
      }));
    }
  };

  /** 기간 선택 메뉴 닫기. */
  const handleCustomDateClose = () => {
    setState((prev) => ({
      ...prev,
      datePoint: undefined,
    }));
  };

  /** 기간 드롭메뉴 클릭 이벤트. */
  const handleCustomDateClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    const { datePoint } = state;
    if (event !== undefined && datePoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        datePoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        datePoint: undefined,
      }));
    }
  };

  /** 기간 타입 변경 이벤트. */
  const handleChangeDateRadioType = (value: string) => {
    if (value === '') {
      const { date } = state;
      const start = date.start === null ? timezoneDate() : date.start;
      if (date.start === null) start.setFullYear(start.getFullYear(), 0, 1);
      const end = date.end === null ? timezoneDate() : date.end;
      setState((prev) => ({
        ...prev,
        date: {
          start,
          end,
        },
        dateType: `${dateFormat(start, 'yyyy-MM-DD')} ~ ${dateFormat(
          end,
          'yyyy-MM-DD',
        )}`,
      }));
    } else
      setState((prev) => ({
        ...prev,
        date: {
          start: null,
          end: null,
        },
        dateType: '전체기간',
      }));
  };

  let classname = 'eui-search';
  if (props.className) classname += ` ${props.className}`;
  if (props.full) classname += ' full';

  useEffect(() => {
    const date: SearchDateProps = props.date
      ? {
          start: props.date.start !== null ? props.date.start : null,
          end: props.date.end !== null ? props.date.end : null,
        }
      : state.date;
    let dateTypeString = '';
    if (props.date && props.date.start !== null && props.date.end !== null)
      dateTypeString = `${dateFormat(
        props.date.start,
        'yyyy-MM-DD',
      )} ~ ${dateFormat(props.date.end, 'yyyy-MM-DD')}`;
    else {
      dateTypeString = '전체기간';
    }
    setState((prevState) => ({
      ...prevState,
      keyword: props.keyword ?? '',
      filter: props.filter ?? '',
      date,
      dateType: dateTypeString,
    }));
  }, [props]);

  useNoInitialEffect(() => {
    handleSearch();
  }, [state.directoryKeyword]);

  return (
    <>
      <div
        className={classname}
        style={display === 'phone' ? { flexDirection: 'column' } : undefined}
      >
        {props.date && (
          <DropMenu
            style={{ width: 'fit-content', fontWeight: 'normal' }}
            onClick={handleCustomDateClick}
            pressed={state.datePoint !== undefined}
            value={state.dateType}
            label="기간"
          />
        )}
        {state.datePoint && (
          <Menu
            overflow="overflow:visible !important"
            containerClassname={
              display === 'phone' ? 'none-overflow' : undefined
            }
            className="ui-search-period-layer"
            point={state.datePoint}
            onClose={handleCustomDateClose}
          >
            {props.dateOptions ? (
              <fieldset className="eui-check-group column">
                <div
                  className="group"
                  style={{
                    margin: '5px',
                    width: display === 'phone' ? '270px' : undefined,
                  }}
                >
                  <Radio
                    label="전체기간"
                    checked={state.dateType === '전체기간'}
                    onChange={() => handleChangeDateRadioType('all')}
                  />
                  <Radio
                    label="기간"
                    checked={state.dateType !== '전체기간'}
                    onChange={() => handleChangeDateRadioType('')}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        width: '210px',
                        marginRight: '8px',
                      }}
                    >
                      <div
                        style={{
                          background: 'var(--fill-color)',
                          borderRadius: '4px',
                          lineHeight: 1,
                          padding: '0 16px',
                          height: '36px',
                        }}
                      >
                        <CustomDatePicker
                          disabled={state.dateType === '전체기간'}
                          placeholderText="연도.월.일"
                          dateFormat="yyyy-MM-dd"
                          selected={state.date.start}
                          onChange={(date: Date) =>
                            handleChangeCustomStartDate(date)
                          }
                          startDate={state.date.start}
                          endDate={state.date.end}
                          selectsStart
                        />
                      </div>
                      <div style={{ marginRight: '4px' }} />

                      <div
                        style={{
                          background: 'var(--fill-color)',
                          borderRadius: '4px',
                          lineHeight: 1,
                          padding: '0 16px',
                          height: '36px',
                        }}
                      >
                        <CustomDatePicker
                          disabled={state.dateType === '전체기간'}
                          placeholderText="연도.월.일"
                          dateFormat="yyyy-MM-dd"
                          selected={state.date.end}
                          onChange={(date: Date) =>
                            handleChangeCustomEndDate(date)
                          }
                          startDate={state.date.start}
                          endDate={state.date.end}
                          minDate={state.date.start}
                          selectsEnd
                        />
                      </div>
                    </div>
                  </Radio>
                </div>
              </fieldset>
            ) : (
              <div
                style={{
                  display: 'inline-flex',
                  width: display !== 'phone' ? '270px' : undefined,
                  marginRight: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 600,
                    margin: 'auto 10px',
                  }}
                >
                  기간
                </div>
                <CustomDatePicker
                  placeholderText="연도.월.일"
                  dateFormat="yyyy-MM-dd"
                  selected={state.date.start}
                  onChange={(date: Date) => handleChangeCustomStartDate(date)}
                  startDate={state.date.start}
                  endDate={state.date.end}
                  selectsStart
                />
                <div style={{ marginRight: '4px' }} />
                <CustomDatePicker
                  placeholderText="연도.월.일"
                  dateFormat="yyyy-MM-dd"
                  selected={state.date.end}
                  onChange={(date: Date) => handleChangeCustomEndDate(date)}
                  startDate={state.date.start}
                  endDate={state.date.end}
                  minDate={state.date.start}
                  selectsEnd
                />
              </div>
            )}
          </Menu>
        )}
        <div
          className="basic-search"
          style={
            display === 'phone'
              ? { flexGrow: 'initial', width: 'fit-content', marginTop: '3px' }
              : undefined
          }
        >
          {props.options && (
            <SelectField
              data={props.options}
              value={state.filter}
              onChange={handleChangeFilter}
              className="filter"
            />
          )}
          {state.filter !== 'status' && state.filter !== 'code' && (
            <TextField
              type="search"
              placeholder="검색어"
              className="keyword"
              value={state.keyword}
              clear={state.keyword !== ''}
              onChange={handleChangeKeyword}
              onKeyDown={handleSearchKeyDown}
              onClear={handleClearKeyword}
              ref={keyWordRef}
            />
          )}
          {props.applyTypeOptions && state.filter === 'code' && (
            <SelectField
              data={props.applyTypeOptions}
              value={state.applyType ?? props.applyTypeOptions[0].value}
              onChange={handleChangeApplyType}
              className="filter"
            />
          )}
          {props.statusOptions && state.filter === 'status' && (
            <SelectField
              data={props.statusOptions}
              value={state.status ?? props.statusOptions[0].value}
              onChange={handleChangeStatus}
              className="filter"
            />
          )}
          {state.visible && state.keyword !== '' && list.length > 0 && (
            <Menu
              point={state.point}
              onClose={() => {
                setState((prevState) => ({
                  ...prevState,
                  visible: false,
                }));
              }}
            >
              <div className="tree-area">
                <DirectoryTree
                  typeToFilter={
                    props.dualSearch ? 'employee' : ['organization', 'employee']
                  }
                  selectedId={undefined}
                  items={list}
                  filter={state.keyword}
                  onItemClick={handleItemClick}
                />
              </div>
            </Menu>
          )}
          <Button
            text="검색"
            iconType
            icon="search"
            className="submit"
            onClick={handleSearch}
          />
        </div>
      </div>
    </>
  );
}

export default AttendanceSearch;
