/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import DirectoryContainer from '../pages/root/directory/DirectoryContainer';

function RootLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // console.log(`${RootLocateContainer.name}.render(props)`, props);
  // const languages = ['ko-KR' as const];

  const { pathname, search } = props;

  return <DirectoryContainer pathname={pathname} search={search} />;
}

export default RootLocateContainer;
