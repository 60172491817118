import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../../../components/alert/Alert';
import HelperText from '../../../../../components/alert/HelperText';
import Button from '../../../../../components/button/Button';
import EmptyData from '../../../../../components/data/EmptyData';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Divider from '../../../../../components/divider/Divider';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import PostView from '../../../../../components/post/PostView';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import UploadImage from '../../../../../components/upload/UploadImage';
import UserInfo from '../../../../../components/user/UserInfo';
import {
  getParentItems,
  getQueryParams,
  getText,
} from '../../../../../groupware-common/utils';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { parseDirectoryTreeItemId } from '../../../../../groupware-directory/stores/directory/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  appError,
  getName,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import informationApi from '../../../../apis/setting/v1/information';
import { informationActions } from '../../../../stores/information';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SettingInformationContainer(arg: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = arg;
  const dispatch = useAppDispatch();

  const queryParams = getQueryParams(search);
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  ).filter(({ id }) => id === principal.companyId);
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );
  const categories = useSelector(
    (state: RootState) => state.setting.information.categories,
  );
  const person = useSelector(
    (state: RootState) => state.setting.information.person,
  );
  const directoryItems = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );

  if (!person)
    return (
      <EmptyData message="데이터가 이동되었거나 삭제되어 조회할 수 없습니다." />
    );

  const initialState = {
    dialog: undefined as 'password' | undefined,
    isUserAvatar: person.isUserAvatar,
    avatar: person.isUserAvatar ? person.avatar : '',
    avatarError: false,
    personalEmail: person.personalEmail,
    companyPhoneNo: person.companyPhoneNo,
    extensionPhoneNo: person.extensionPhoneNo,
    mobilePhoneNo: person.mobilePhoneNo,
    task: person.task,
    password: false,
    changePW: {
      originPW: '',
      newPW: '',
      newPWConfirm: '',
    },
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [person]);

  useEffect(() => {
    async function changePassword() {
      const { originPW: currentPassword, newPW: newPassword } = state.changePW;
      await informationApi
        .changePassword({
          currentPassword,
          newPassword,
        })
        .then((result) => {
          const initialPW = {
            originPW: '',
            newPW: '',
            newPWConfirm: '',
          };
          const status = result.status as number | undefined;
          if (status === 200) {
            setValidation(initialPW);
          } else if (status === 400) {
            setValidation((prev) => ({
              ...prev,
              newPW:
                '비밀번호는 8~16자리의 영문, 숫자, 특수문자 조합으로 사용해야 합니다.',
            }));
          } else if (status === 401) {
            setValidation((prev) => ({
              ...prev,
              originPW: '현재 비밀번호가 일치하지 않습니다.',
            }));
          } else {
            setValidation(initialPW);
            dispatch(
              sessionActions.error(
                appError({
                  message: getText('error:status.INTERNAL_SERVER_ERROR'),
                }),
              ),
            );
          }

          setState((prev) =>
            status === 200
              ? {
                  ...prev,
                  dialog: undefined,
                  changePW: initialPW,
                  password: false,
                }
              : { ...prev, password: false },
          );
        });
    }

    if (state.password) changePassword();
  }, [state.password]);

  const [validation, setValidation] = useState({
    originPW: '',
    newPW: '',
    newPWConfirm: '',
  });

  const affiliatedOrganizations = organizationEmployees
    .filter(
      (a) =>
        a.companyId === principal.companyId &&
        a.employeeId === principal.employeeId,
    )
    .map((a) => {
      return {
        representative: a.id === person.representativeOrganizationId,
        jobDutyName: getName(jobDuties, a.companyId, a.jobDutyId, '미지정'),
        paths: getParentItems(
          directoryItems,
          parseDirectoryTreeItemId(a.companyId, a.id),
        ).map((b) => b.text),
      };
    });
  const employeeName = getEmployeeName(
    principal.companyId,
    principal.employeeId,
    '',
  );
  const organizationName = getOrganizationName(
    principal.companyId,
    principal.organizationId,
    '미지정',
  );
  const jobPositionName = getName(
    jobPositions,
    principal.companyId,
    person.jobPositionId,
    '미지정',
  );

  /** 아바타 오류 이벤트.  */
  const handleErrorAvatar = () => {
    setState((prev) => ({ ...prev, avatar: '', avatarError: true }));
  };

  /** 아바타 변경 이벤트. */
  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) setState((prev) => ({ ...prev, avatar: base64.toString() }));
    };

    if (event.target.files !== null && event.target.files[0] !== undefined) {
      reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.

      const eventTargetValue = { ...event };
      eventTargetValue.target.value = ''; // 같은 파일을 다시 받는 경우때문에 value reset
    }
    setState((prev) => ({ ...prev, isUserAvatar: true }));
  };

  /** 아바타 삭제 이벤트. */
  const handleDeleteAvatar = () => {
    setState((prev) => ({
      ...prev,
      avatar: '',
      isUserAvatar: false,
    }));
  };

  /** 개인 이메일 변경 이벤트. */
  const handleChangePersonalEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      personalEmail: event.target.value,
    }));
  };

  /** 비밀번호 변경 클릭 이벤트. */
  const handleChangePassWordClick = () => {
    setState((prev) => ({ ...prev, dialog: 'password' }));
    setValidation({
      originPW: '',
      newPW: '',
      newPWConfirm: '',
    });
  };

  /** 비밀번호 필드에서 Enter 누름 */
  const handlePWKeyDonw = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') handleChangePassWord();
  };

  /** 현재 비밀번호 변경 이벤트. */
  const handleChangeOriginPW = (event: React.ChangeEvent<HTMLInputElement>) => {
    const originPW = event.target.value;
    setState((prev) => ({
      ...prev,
      changePW: { ...prev.changePW, originPW },
    }));
    setValidation((prev) => ({ ...prev, originPW: '' }));
  };

  /** 새 비밀번호 변경 이벤트. */
  const handleChangeNewPW = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPW = event.target.value;
    setState((prev) => ({
      ...prev,
      changePW: { ...prev.changePW, newPW },
    }));
    setValidation((prev) => ({ ...prev, newPW: '' }));
  };

  /** 새 비밀번호 입력 시 유효상 검사 이벤트. */
  const handleBlurNewPW = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const passwordLength = /^.{8,16}$/;
    const denyPattern =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]+$/g;
    const newPW = event.target.value;
    const { changePW } = state;
    const newValidation = { ...validation };

    if (newPW.trim() === '') {
      setValidation((prev) => ({ ...prev, newPW: '', newPWConfirm: '' }));
      return;
    }

    if (!denyPattern.test(newPW))
      newValidation.newPW =
        '비밀번호는 영문, 숫자, 특수문자(@, $, !, %, *, ?, &)조합으로 사용해야 합니다.';
    else if (!passwordLength.test(newPW))
      newValidation.newPW = '비밀번호는 8자리 이상 16자리 이하여야 합니다.';
    if (
      newValidation.newPW === '' &&
      changePW.newPWConfirm.trim() !== '' &&
      changePW.newPWConfirm !== newPW
    )
      newValidation.newPWConfirm = '새 비밀번호와 일치하지 않습니다.';
    setValidation(newValidation);
  };

  /** 새 비밀번호 확인 변경 이벤트. */
  const handleChangeNewPWConfirm = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPWConfirm = event.target.value;
    setState((prev) => ({
      ...prev,
      changePW: { ...prev.changePW, newPWConfirm },
    }));
    setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
  };

  /** 새 비밀번호 확인 입력 시 유효상 검사 이벤트. */
  const handleBlurNewPWConfirm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { changePW } = state;
    const newPWConfirm = event.target.value;
    if (
      newPWConfirm.trim() === '' ||
      changePW.newPW.trim() === '' ||
      validation.newPW.trim() !== ''
    ) {
      setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
      return;
    }

    if (changePW.newPW !== event.target.value)
      setValidation((prev) => ({
        ...prev,
        newPWConfirm: '새 비밀번호와 일치하지 않습니다.',
      }));
    else setValidation((prev) => ({ ...prev, newPWConfirm: '' }));
  };

  const handleCloseDialog = () => {
    const initialPW = {
      originPW: '',
      newPW: '',
      newPWConfirm: '',
    };
    setState((prev) => ({
      ...prev,
      dialog: undefined,
      changePW: initialPW,
    }));
    setValidation(initialPW);
  };

  /** 비밀번호 변경 이벤트. */
  const handleChangePassWord = () => {
    const { changePW } = state;
    const newValidation = { ...validation };
    if (changePW.originPW === '' || changePW.originPW.trim() === '')
      newValidation.originPW = '현재 비밀번호는 빈 값일 수 없습니다.';

    if (changePW.newPW === '' || changePW.newPW.trim() === '')
      newValidation.newPW = '새 비밀번호는 빈 값일 수 없습니다.';
    else if (
      newValidation.originPW !== '' ||
      changePW.newPWConfirm.trim() === '' ||
      (changePW.newPWConfirm !== '' && changePW.newPW !== changePW.newPWConfirm)
    )
      newValidation.newPWConfirm = '새 비밀번호와 일치하지 않습니다.';

    if (
      newValidation.originPW !== '' ||
      newValidation.newPW !== '' ||
      newValidation.newPWConfirm !== ''
    ) {
      setValidation(newValidation);
      return;
    }

    setState((prev) => ({ ...prev, password: true }));
  };

  /** 회사전화 변경 이벤트. */
  const handleChangeCompanyPhoneNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      companyPhoneNo: event.target.value,
    }));
  };

  /** 내선전화 변경 이벤트. */
  const handleChangeExtensionPhoneNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      extensionPhoneNo: event.target.value,
    }));
  };

  /** 휴대전화 변경 이벤트. */
  const handleChangeMobilePhoneNo = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      mobilePhoneNo: event.target.value,
    }));
  };

  /** 업무 변경 이벤트. */
  const handleChangeTask = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      task: event.target.value,
    }));
  };

  const handleUpdateClick = (type: 'update' | 'cancel') => {
    if (type === 'update')
      dispatch(sessionActions.setDialog({ mode: 'update' }));
    else dispatch(sessionActions.setDialog());
  };

  /** 저장 이벤트. */
  const handleSave = () => {
    let avatar: string | undefined;
    if (person.avatar === state.avatar) avatar = undefined;
    else if (state.avatar.indexOf('data:') === 0) avatar = state.avatar;
    else if (!person.isUserAvatar && state.avatar === '') avatar = undefined;
    else avatar = '';

    const data = {
      id: person.id,
      avatar,
      personalEmail:
        state.personalEmail === person.personalEmail
          ? undefined
          : state.personalEmail,
      companyPhoneNo:
        state.companyPhoneNo === person.companyPhoneNo
          ? undefined
          : state.companyPhoneNo,
      extensionPhoneNo:
        state.extensionPhoneNo === person.extensionPhoneNo
          ? undefined
          : state.extensionPhoneNo,
      mobilePhoneNo:
        state.mobilePhoneNo === person.mobilePhoneNo
          ? undefined
          : state.mobilePhoneNo,
      task: state.task === person.task ? undefined : state.task,
      updateAt: person.updateAt,
    };
    delete queryParams.dialogMode;
    dispatch(
      informationActions.updateInformation({
        ...data,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  const renderDialog = () => {
    const { dialog } = state;
    const { originPW, newPW, newPWConfirm } = state.changePW;
    if (dialog === 'password')
      return (
        <Dialog size="xs" onClose={handleCloseDialog}>
          <DialogHeader>
            <DialogTitle>비밀번호 변경</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <PostWrite>
              <div style={{ padding: '12px 24px' }}>
                <Alert severity="info" size="sm">
                  비밀번호는 8~16자의 영문, 숫자, 특수문자(@, $, !, %, *, ?,
                  &)조합으로 사용해야 합니다.
                </Alert>
              </div>
              <PostWrite.Item title="현재 비밀번호">
                <TextField
                  type="password"
                  value={originPW}
                  error={validation.originPW !== ''}
                  onKeyDown={handlePWKeyDonw}
                  onChange={handleChangeOriginPW}
                />
                {validation.originPW !== '' && (
                  <HelperText>{validation.originPW}</HelperText>
                )}
              </PostWrite.Item>
              <PostWrite.Item title="새 비밀번호">
                <TextField
                  type="password"
                  value={newPW}
                  error={validation.newPW !== ''}
                  onKeyDown={handlePWKeyDonw}
                  onChange={handleChangeNewPW}
                  onBlur={handleBlurNewPW}
                />
                {validation.newPW !== '' && (
                  <HelperText>{validation.newPW}</HelperText>
                )}
              </PostWrite.Item>
              <PostWrite.Item title="새 비밀번호 확인">
                <TextField
                  type="password"
                  value={newPWConfirm}
                  error={validation.newPWConfirm !== ''}
                  onKeyDown={handlePWKeyDonw}
                  onChange={handleChangeNewPWConfirm}
                  onBlur={handleBlurNewPWConfirm}
                />
                {validation.newPWConfirm !== '' && (
                  <HelperText>{validation.newPWConfirm}</HelperText>
                )}
              </PostWrite.Item>
            </PostWrite>
          </DialogBody>
          <DialogFooter>
            <Button
              block
              noDuplication={state.password}
              variant="contained"
              text="변경하기"
              onClick={handleChangePassWord}
            />
          </DialogFooter>
        </Dialog>
      );

    return null;
  };

  const title = categories.find((a) => a.id === 'information')?.name ?? '';
  const renderContent = () => {
    const { dialogMode } = queryParams;
    if (dialogMode === 'update')
      return (
        <>
          <EuiHeader>
            <EuiHeader.Title>{title}</EuiHeader.Title>
          </EuiHeader>
          <EuiBody>
            <PostView type="full">
              <PostView.Body>
                <div style={{ display: 'flex' }}>
                  <div className="user-info view-profile">
                    <UploadImage
                      path={state.avatar}
                      name={employeeName}
                      type="profile"
                      style={{ width: '64px', height: '64px' }}
                      onError={handleErrorAvatar}
                      onChange={handleChangeAvatar}
                      onDelete={handleDeleteAvatar}
                    />
                    <div className="info">
                      <span className="name">{employeeName}</span>
                      <span className="from">{organizationName}</span>
                    </div>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      text="취소"
                      variant="outlined"
                      onClick={() => handleUpdateClick('cancel')}
                    />
                    <Button
                      noDuplication
                      text="저장"
                      variant="contained"
                      onClick={handleSave}
                    />
                  </div>
                </div>
                <Divider />
                <PostView.Category type="text">
                  <PostView.CategoryList>
                    <PostView.CategoryItem
                      className="my-page-list"
                      title="이메일"
                    >
                      <PostView.CategoryValue value={person.email} />
                      <div className="item-value">
                        <TextField
                          value={state.personalEmail}
                          onChange={handleChangePersonalEmail}
                        />
                        <em className="value-label">개인</em>
                      </div>
                      <PostView.CategoryValue>
                        <Button
                          text="비밀번호 변경"
                          variant="outlined"
                          size="sm"
                          onClick={handleChangePassWordClick}
                        />
                      </PostView.CategoryValue>
                    </PostView.CategoryItem>
                    <PostView.CategoryItem
                      className="my-page-list"
                      title="연락처"
                    >
                      <div className="item-value">
                        <TextField
                          value={state.companyPhoneNo}
                          onChange={handleChangeCompanyPhoneNo}
                        />
                        <em className="value-label">회사전화</em>
                      </div>
                      <div className="item-value">
                        <TextField
                          value={state.extensionPhoneNo}
                          onChange={handleChangeExtensionPhoneNo}
                        />
                        <em className="value-label">내선전화</em>
                      </div>
                      <div className="item-value">
                        <TextField
                          value={state.mobilePhoneNo}
                          onChange={handleChangeMobilePhoneNo}
                        />
                        <em className="value-label">휴대전화</em>
                      </div>
                    </PostView.CategoryItem>
                  </PostView.CategoryList>
                  <PostView.CategoryList title="회사/소속">
                    <PostView.CategoryItem
                      className="my-page-list"
                      title="소속"
                    >
                      {affiliatedOrganizations.map((a) => {
                        const value = a.paths.join(' > ');
                        const label = a.representative
                          ? `${a.jobDutyName} [대표 조직]`
                          : a.jobDutyName;
                        return (
                          <PostView.CategoryValue
                            key={value}
                            value={value}
                            label={label}
                          />
                        );
                      })}
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title="입사일자">
                      <PostView.CategoryValue value={person.enterDate} />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title="사원번호">
                      <PostView.CategoryValue value={person.no} />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title="직위">
                      <PostView.CategoryValue value={jobPositionName} />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title="업무">
                      <TextField
                        value={state.task}
                        onChange={handleChangeTask}
                      />
                    </PostView.CategoryItem>
                  </PostView.CategoryList>
                </PostView.Category>
              </PostView.Body>
            </PostView>
          </EuiBody>
        </>
      );

    return (
      <>
        <EuiHeader>
          <EuiHeader.Title>기본 정보</EuiHeader.Title>
        </EuiHeader>
        <EuiBody>
          <PostView type="full">
            <PostView.Body>
              <div style={{ display: 'flex' }}>
                <UserInfo
                  className="view-profile"
                  name={employeeName}
                  from={organizationName}
                  avatar={person.avatar}
                />
                <Button
                  style={{ marginLeft: 'auto', padding: '0px' }}
                  text={display === 'phone' ? undefined : '수정'}
                  icon="edit"
                  onClick={() => handleUpdateClick('update')}
                />
              </div>
              <Divider />
              <PostView.Category type="text">
                <PostView.CategoryList>
                  <PostView.CategoryItem
                    className="my-page-list"
                    title="이메일"
                  >
                    <PostView.CategoryValue value={person.email} />
                    <PostView.CategoryValue
                      value={person.personalEmail}
                      label="개인"
                    />
                    <PostView.CategoryValue>
                      <Button
                        text="비밀번호 변경"
                        variant="outlined"
                        size="sm"
                        onClick={handleChangePassWordClick}
                      />
                    </PostView.CategoryValue>
                  </PostView.CategoryItem>
                  <PostView.CategoryItem
                    className="my-page-list"
                    title="연락처"
                  >
                    <PostView.CategoryValue
                      value={person.companyPhoneNo}
                      label="회사전화"
                    />
                    <PostView.CategoryValue
                      value={person.extensionPhoneNo}
                      label="내선전화"
                    />
                    <PostView.CategoryValue
                      value={person.mobilePhoneNo}
                      label="휴대전화"
                    />
                  </PostView.CategoryItem>
                </PostView.CategoryList>
                <PostView.CategoryList title="회사/소속">
                  <PostView.CategoryItem className="my-page-list" title="소속">
                    {affiliatedOrganizations.map((a) => {
                      const value = a.paths.join(' > ');
                      const label = a.representative
                        ? `${a.jobDutyName} [대표 조직]`
                        : a.jobDutyName;
                      return (
                        <PostView.CategoryValue
                          key={value}
                          value={value}
                          label={label}
                        />
                      );
                    })}
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title="입사일자">
                    <PostView.CategoryValue value={person.enterDate} />
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title="사원번호">
                    <PostView.CategoryValue value={person.no} />
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title="직위">
                    <PostView.CategoryValue value={jobPositionName} />
                  </PostView.CategoryItem>
                  <PostView.CategoryItem title="업무">
                    <PostView.CategoryValue value={person.task} />
                  </PostView.CategoryItem>
                </PostView.CategoryList>
              </PostView.Category>
            </PostView.Body>
          </PostView>
        </EuiBody>
      </>
    );
  };

  return (
    <>
      {renderContent()}
      {renderDialog()}
    </>
  );
}

export default SettingInformationContainer;
