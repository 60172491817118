/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Locationable } from '../../groupware-common/types';
import { base62, go, parseUris } from '../../groupware-common/utils';

const locator = (store: any) => (next: any) => async (action: any) => {
  const result = next(action);

  if (action.type.endsWith('/fulfilled')) {
    if (action?.meta?.arg?.location) {
      const location = action.meta.arg.location as Locationable;
      const paths = parseUris(location.pathname).map((a) => {
        if (a === '{response_id}') {
          if (action.payload?._response_id_ === undefined) return undefined;
          return `${base62.encode(action.payload?._response_id_)}`;
        }
        return a;
      });
      const pathname = paths.length > 0 ? `/${paths.join('/')}` : '';
      const { search = '', hash = '', mode = 'auto' } = location;
      go(pathname, search, hash, mode);
    }
  }

  return result;
};

export default locator;
