import { ApprovalLineCodeProps } from './ApprovalType';

export default function approvalCodeLabel(type: ApprovalLineCodeProps): string {
  switch (type) {
    case 'drafter':
      return '기안';
    case 'approval':
      return '결재';
    case 'agree':
      return '합의';
    case 'auditor':
      return '감사';
    case 'receiver':
      return '수신';
    default:
      return '결재';
  }
}
