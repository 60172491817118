import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { approvalArbitraryDecisionActions } from '../../../../stores/approval/arbitraryDecisions';

function ApprovalArbitraryDecisionRoute({
  pathname,
}: {
  pathname: string;
}): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      approvalArbitraryDecisionActions.fetchArbitraryDecision({
        route: { pathname },
      }),
    );
  }, [dispatch, pathname]);

  return null;
}

export default ApprovalArbitraryDecisionRoute;
