import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../groupware-webapp/app/store';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';

export type PostListItemType =
  | 'action'
  | 'importance'
  | 'post'
  | 'folder'
  | 'user'
  | 'date'
  | 'period'
  | 'size'
  | 'count'
  | 'email'
  | 'tel'
  | 'company'
  | 'position'
  | 'thumbnail'
  | 'state'
  | 'type'
  | 'meta'
  | 'form'
  | 'number'
  | 'status'
  | 'leavesStatus'
  | 'reservationStatus'
  | 'contact'
  | 'contact-column';

/**
 * 목록 타입
 * - [full] 기본목록
 * - [split] 분할 타입
 * - [media] 미디어 타입
 * - [gallery] 갤러리 타입
 * - [card] 카드 타입
 * */
export type PostListType = 'full' | 'split' | 'media' | 'gallery' | 'card';

/**
 * 화면 밀도
 * - [normal] 기본
 * - [narrow] 좁게
 * */
export type PostListDensity = 'wide' | 'normal' | 'narrow';

function PostList(props: {
  className?: string;
  type?: PostListType;
  density?: PostListDensity;
  name: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element {
  const display = useSelector((state: RootState) => state.session.display);

  let classname = 'eui-post-list';
  if (props.className) classname += ` ${props.className}`;
  if (props.density) classname += ` ${props.density}`;

  let newType: PostListType = 'full';
  if (props.type !== undefined)
    if (props.type === 'full' && display === 'phone') {
      newType = 'split';
    } else {
      newType = props.type;
    }

  return (
    <div
      style={props.style}
      className={classname}
      data-post-type={props.type === undefined ? 'full' : newType}
      data-post-name={props.name}
    >
      {props.children}
    </div>
  );
}

function PostListHead(props: { children: React.ReactNode }): JSX.Element {
  return (
    <div className="post-list-head">
      <div className="post-item">{props.children}</div>
    </div>
  );
}

function PostListBody(props: {
  disableScroll?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  scrollableNodeProps?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      {props.disableScroll ? (
        <div className="post-list-body">
          <div className="post-list-root">{props.children}</div>
        </div>
      ) : (
        <div className="post-list-body" ref={props.scrollableNodeProps}>
          <div className="post-list-root">{props.children}</div>
        </div>
      )}
    </>
  );
}

function PostListCell(props: {
  name?: string;
  type: PostListItemType;
  autoSize?: boolean;
  align?: 'left' | 'center' | 'right';
  hidden?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}): JSX.Element {
  const type = props.type === 'leavesStatus' ? 'status' : props.type;
  let classname = 'item-cell';
  if (type) classname += ` cell-${type}`;
  if (props.align) classname += ` cell-${props.align}`;
  if (props.autoSize) classname += ' auto-size';

  return (
    <>
      {props.hidden || (
        <div style={props.style} className={classname} onClick={props.onClick}>
          {props.name && props.name}
          {props.children}
        </div>
      )}
    </>
  );
}

function PostListCellValue(props: {
  value?: string | number;
  icon?: IconType;
  className?: string;
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}): JSX.Element {
  let classname = 'cell-value';
  if (props.className) classname += ` ${props.className}`;
  if (props.onClick) classname += ' hyperlink';
  if (props.icon) classname += ' icon';

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onClick) props.onClick(event);
  };

  const content = (
    <>
      {props.icon && <Icon icon={props.icon} className="badge" />}
      {props.value && props.value}
    </>
  );

  return props.onClick ? (
    <a href="#" className={classname} onClick={handleClick}>
      {content}
    </a>
  ) : (
    <span className={classname}>{content}</span>
  );
}

function PostListItem(props: {
  organizationStatus?: boolean; // 부서별 근태현황일 경우 하이퍼링크 안들어가도록.
  selected?: boolean;
  state?: string;
  className?: string;
  children: React.ReactNode;
  onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  onContextMenu?(event: React.MouseEvent): void;
}): JSX.Element {
  let classname = 'post-item';
  if (props.className) classname += ` ${props.className}`;
  if (props.selected) classname += ' selected';
  if (props.onClick) classname += ' hyperlink';

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (props.onClick) props.onClick(event);
  };

  return props.onClick && !props.organizationStatus ? (
    <a
      className={classname}
      data-state={props.state}
      onClick={handleClick}
      onContextMenu={props.onContextMenu}
    >
      {props.children}
    </a>
  ) : (
    <div
      className={classname}
      data-state={props.state}
      onContextMenu={props.onContextMenu}
    >
      {props.children}
    </div>
  );
}

PostList.Head = PostListHead;
PostList.Body = PostListBody;
PostList.Cell = PostListCell;
PostList.Item = PostListItem;
PostList.Value = PostListCellValue;

export default PostList;
