import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { CalendarColorType } from '../../../../stores/calendar/preferences';
import { UserPreferencesBasic } from '../../../../stores/calendar/userPreferences';

/** 사용자 환경설정 조회. */
async function findUserPreferences(
  employeeId: number,
): Promise<UserPreferencesBasic> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/settings`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 환경설정 저장 */
async function updateUserPreferences(arg: {
  employeeId: number;
  data: {
    initialFrame: string;
    firstDayOfWeek: string;
    updateAt: string;
  };
}): Promise<UserPreferencesBasic> {
  try {
    const { employeeId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/settings`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 색상 리스트 조회. */
async function findColorList(): Promise<CalendarColorType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/colors`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const userPreferencesApi = {
  find: findUserPreferences,
  update: updateUserPreferences,

  colorList: findColorList,
};

export default userPreferencesApi;
