import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import AvatarGroup from '../../../../../components/avatar/AvatarGroup';

function ComponentAvatarGrouped(): JSX.Element {
  const userList: { id: number; name: string; avatar: string }[] = [
    { id: 1, name: '홍길동', avatar: '/images/faces/faces_1.jpg' },
    { id: 2, name: '이길동', avatar: '/images/faces/faces_2.jpg' },
    { id: 3, name: '성길동', avatar: '/images/faces/faces_3.jpg' },
    { id: 4, name: '박길동', avatar: '/images/faces/faces_4.jpg' },
    { id: 5, name: '안길동', avatar: '/images/faces/faces_5.jpg' },
  ];

  return (
    <ComponentSampleUI.Content title="Grouped">
      <AvatarGroup data={userList} max={4} />
    </ComponentSampleUI.Content>
  );
}

export default ComponentAvatarGrouped;
