import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

export interface LogItem {
  id: number;
  employeeId: number;
  type: string; // CREATE: 생성, UPDATE: 수정, RENEW: 새로운 버전 작성, CHECKOUT: 체크아웃, CHECKIN: 체크인, DELETE: 삭제, RESTORE: 복원, MOVE: 이동, COPY: 복사, SAVINGCONTENTS: 저장, SAVINGFILES: 파일 저장, PRINT: 인쇄
  createAt: string;
  detailsMap?: Record<string, unknown>;
}

/**
 * 문서 로그의 총개수를 조회 합니다.
 * @param arg.id 문서 아아디.
 * @returns 총개수.
 */
async function totalcount(arg: { id: number; type?: string }): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = arg.type
      ? `${host}/api/document/v1/post/history/all/totalcount?postid=${arg.id}&type=${arg.type}`
      : `${host}/api/document/v1/post/history/all/totalcount?postid=${arg.id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/* 문서 로그 목록을 조회 합니다. */
async function findAll(arg: {
  id: number;
  type?: string; // 문서 이력 활동 구분
  pageNo: number;
  rowsPerPage: number;
}): Promise<LogItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const params = {
      postid: arg.id,
      type: arg.type,
      pageno: arg.pageNo,
      rowsperpage: arg.rowsPerPage,
    };
    const url = `${host}/api/document/v1/post/history/all`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const documentLogApi = {
  totalcount,
  findAll,
};

export default documentLogApi;
