import React from 'react';

function AttachDocumentList(props: {
  count: number;
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'eui-document-file-list';
  if (props.className) classname += ` ${props.className}`;

  return (
    <div className={classname}>
      <div className="list-head">
        <div className="title">첨부문서 {props.count}개</div>
      </div>
      <div className="list-body">{props.children}</div>
    </div>
  );
}

export default AttachDocumentList;
