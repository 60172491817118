import React, { useState } from 'react';
import Button from '../../../../../components/button/Button';
import Menu from '../../../../../components/menu/Menu';
import ChooseOrganizationChart from '../../../../../groupware-webapp/pages/popup/ChooseOrganizationChart';
import PostWrite from '../../../../../components/post/PostWrite';
import DropMenu from '../../../../../components/selectField/DropMenu';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import { getText } from '../../../../../groupware-common/utils';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import Drawer from '../../../../../components/drawer/Drawer';

interface UserProps {
  id: string;
  text: string;
  companyId: number;
  referenceType: number;
}

type Props = {
  onSave?(
    drafterCompanyId: number | undefined,
    drafterId: number | undefined,
    referenceCompanyId: number | undefined,
    referenceId: number | undefined,
    referenceType: number | undefined,
  ): void;
  onClose(): void;
};

function ApprovalViewPermissionContentWrite(dumy: Props): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyFunction = (): void => {};

  const props = {
    ...dumy,
    onSave: dumy.onSave || emptyFunction,
    onClose: dumy.onClose || emptyFunction,
  };

  const { onClose, onSave } = props;

  const [state, setState] = useState<{
    type: 'drafter' | 'viewers' | null;
    userSelectVisible: boolean;
    userSelectPoint: { x: number; y: number; width: number; height: number };
    drafter: UserProps;
    viewers: UserProps;
  }>({
    type: null,
    userSelectVisible: false,
    userSelectPoint: { x: 0, y: 0, width: 0, height: 0 },
    drafter: {
      companyId: 0,
      id: '',
      text: '',
      referenceType: 2,
    },
    viewers: {
      companyId: 0,
      id: '',
      text: '',
      referenceType: 2,
    },
  });

  const handleOpenUserSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'drafter' | 'viewers',
  ) => {
    if (state.userSelectVisible) handleCloseUserSelect();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        type,
        userSelectPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        userSelectVisible: true,
      }));
    }
  };

  const handleCloseUserSelect = () => {
    setState((prevState) => ({
      ...prevState,
      type: null,
      userSelectVisible: false,
    }));
  };

  const handleUserSelected = (arg: {
    type: 'employee' | 'organization' | 'company';
    item: {
      id: string;
      name: string;
      organization: string;
      avatar: string;
    };
    companyId: number;
  }) => {
    if (arg.type === 'employee') {
      switch (state.type) {
        case 'drafter':
          setState((prevState) => ({
            ...prevState,
            drafter: {
              id: arg.item.id,
              text:
                arg.item.organization === ''
                  ? arg.item.name
                  : `${arg.item.organization} / ${arg.item.name}`,
              companyId: arg.companyId,
              referenceType: 2,
            },
          }));
          break;
        case 'viewers':
          setState((prevState) => ({
            ...prevState,
            viewers: {
              id: arg.item.id,
              text:
                arg.item.organization === ''
                  ? arg.item.name
                  : `${arg.item.organization} / ${arg.item.name}`,
              companyId: arg.companyId,
              referenceType: 2,
            },
          }));
          break;
        default:
          break;
      }
      handleCloseUserSelect();
    }
    if (arg.type === 'organization') {
      switch (state.type) {
        case 'drafter':
          setState((prevState) => ({
            ...prevState,
            drafter: {
              id: arg.item.id,
              text: arg.item.name,
              companyId: arg.companyId,
              referenceType: 1,
            },
          }));
          break;
        case 'viewers':
          setState((prevState) => ({
            ...prevState,
            viewers: {
              id: arg.item.id,
              text: arg.item.name,
              companyId: arg.companyId,
              referenceType: 1,
            },
          }));
          break;
        default:
          break;
      }
      handleCloseUserSelect();
    }
  };

  const handleSave = () => {
    /*
    const [drafterorg, drafterId] = state.drafter.id.split('_').map((x) => {
      const result = parseInt(x, 10);
      if (Number.isNaN(result)) return undefined;
      return result;
    });

    const [referenceorg, referenceId] = state.viewers.id.split('_').map((x) => {
      const result = parseInt(x, 10);
      if (Number.isNaN(result)) return undefined;
      return result;
    });
    */
    /* 기안자,기안부서 */
    let drafterId = parseInt(state.drafter.id.split('_')[1], 10);
    const drafterReferenceType = state.drafter.referenceType;
    if (drafterReferenceType === 2) {
      drafterId = parseInt(state.drafter.id.split('_')[2], 10);
    }
    const drafterCompanyId = state.drafter.companyId;

    /* 조회자,조회부서 */
    let referenceId = parseInt(state.viewers.id.split('_')[1], 10);
    const viewersreferenceType = state.viewers.referenceType;
    if (viewersreferenceType === 2) {
      referenceId = parseInt(state.viewers.id.split('_')[2], 10);
    }
    const referenceCompanyId = state.viewers.companyId;

    onSave(
      drafterId,
      drafterCompanyId,
      referenceId,
      referenceCompanyId,
      viewersreferenceType,
    );
  };

  return (
    <Drawer title="조회권 등록" onClose={onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title="기안자/기안부서">
            <DropMenu
              value={state.drafter.text}
              label="선택"
              onClick={(event) => handleOpenUserSelect(event, 'drafter')}
            />
          </PostWrite.Item>
          <PostWrite.Item title="조회권한 받을 대상">
            <DropMenu
              value={state.viewers.text}
              label="선택"
              onClick={(event) => handleOpenUserSelect(event, 'viewers')}
            />
          </PostWrite.Item>
        </PostWrite>

        {state.userSelectVisible && (
          <Menu point={state.userSelectPoint} onClose={handleCloseUserSelect}>
            <ChooseOrganizationChart onSelected={handleUserSelected} />
          </Menu>
        )}
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default React.memo(ApprovalViewPermissionContentWrite);
