import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Alert from '../../../../../components/alert/Alert';

function ComponentAlertDescription(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Description">
      <Alert severity="error" title="Error">
        This is an error alert — check it out!
      </Alert>
      <Alert severity="warning" title="Warning">
        This is a warning alert — check it out!
      </Alert>
      <Alert severity="info" title="Info">
        This is an info alert — check it out!
      </Alert>
      <Alert severity="success" title="Success">
        This is a success alert — check it out!
      </Alert>
    </ComponentSampleUI.Content>
  );
}

export default ComponentAlertDescription;
