import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';

function ComponentButton(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Block">
      <Button text="추가" block />
      <Button text="로그인" variant="contained" block />
      <Button text="Disabled" variant="contained" block disabled />
    </ComponentSampleUI.Content>
  );
}

export default ComponentButton;
