import React from 'react';
import ComponentChipBasic from './ComponentChipBasic';
import ComponentChipClick from './ComponentChipClick';
import ComponentChipColor from './ComponentChipColor';
import ComponentChipAvatar from './ComponentChipAvatar';
import ComponentChipIcon from './ComponentChipIcon';
import ComponentChipGroup from './ComponentChipGroup';
import ComponentChipSize from './ComponentChipSize';
import ComponentChipFilter from './ComponentChipFilter';
import ComponentChipAdd from './ComponentChipAdd';

function ComponentChip(): JSX.Element {
  return (
    <>
      <ComponentChipBasic />
      <ComponentChipClick />
      <ComponentChipAdd />
      <ComponentChipAvatar />
      <ComponentChipIcon />
      <ComponentChipColor />
      <ComponentChipGroup />
      <ComponentChipSize />
      <ComponentChipFilter />
    </>
  );
}

export default ComponentChip;
