import React from 'react';
import { useSelector } from 'react-redux';
import EuiToolbar from './EuiToolbar';
import Button from '../button/Button';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import { sessionActions } from '../../groupware-webapp/stores/session';

function EuiHeaderContent(props: { children: React.ReactNode }): JSX.Element {
  return <div className="header-content">{props.children}</div>;
}

function EuiHeaderTitle(props: {
  navDisabled?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'header-title';
  const dispatch = useAppDispatch();
  const display = useSelector((state: RootState) => state.session.display);
  const handleVisibleMobileNav = () => {
    dispatch(sessionActions.mobileNav(true));
  };
  if (props.navDisabled === true) classname += ' none-menu';
  return (
    <h2 className={classname} data-title={props.children}>
      {display !== 'pc' && props.navDisabled !== true && (
        <Button
          text="메뉴"
          iconType
          icon="bars"
          onClick={handleVisibleMobileNav}
          className="ui-mobile-nav-toggle"
        />
      )}
      <span className="text">{props.children}</span>
    </h2>
  );
}

function EuiHeaderSearch(props: { children: React.ReactNode }): JSX.Element {
  return <div className="header-search">{props.children}</div>;
}

function EuiHeaderSearchResult(props: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="header-search-result">
      <div className="result-wrap">{props.children}</div>
    </div>
  );
}

function EuiHeaderToolbar(props: { children: React.ReactNode }): JSX.Element {
  return <EuiToolbar className="header-toolbar">{props.children}</EuiToolbar>;
}

function EuiHeaderToolbarContent(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <EuiToolbar.Left>{props.children}</EuiToolbar.Left>;
}

function EuiHeaderToolbarSide(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <EuiToolbar.Right>{props.children}</EuiToolbar.Right>;
}

function EuiHeaderSideFixed(props: { children: React.ReactNode }): JSX.Element {
  return <div className="header-side-fixed">{props.children}</div>;
}

function EuiHeader(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'ui-content-header';
  if (props.className) classname += ` ${props.className}`;

  return <div className={classname}>{props.children}</div>;
}

EuiHeader.Content = EuiHeaderContent;
EuiHeader.Title = EuiHeaderTitle;
EuiHeader.Search = EuiHeaderSearch;
EuiHeader.SearchResult = EuiHeaderSearchResult;
EuiHeader.Toolbar = EuiHeaderToolbar;
EuiHeader.ToolbarLeft = EuiHeaderToolbarContent;
EuiHeader.ToolbarRight = EuiHeaderToolbarSide;
EuiHeader.SideFixed = EuiHeaderSideFixed;

export default EuiHeader;
