import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

export interface subjectItem {
  alertType: number; // 연차촉진 알림 구분(1 : 1차, 2 : 2차, 0 :일반연차)
  remainedLeaves: number; // 잔여연차(minutes)
  firstAlarmStartDate: string; // 1차 촉진 알림 발송 가능 시작일
  firstAlarmEndDate: string; // 1차 촉진 알림 발송 가능 종료일
  firstAlarmSendDate: string; // 1차 촉진 알림 발송일
  firstAlarmId: number; // 1차 촉진 알림 발송 아이디
  workerPlanStartDate: string; // 근로자 연차 사용 계획서 발송 가능 시작일
  workerPlanEndDate: string; // 근로자 연차 사용 계획서 발송 종료일
  workerPlanId: number; // 근로자 연차 사용 계획서 발송 아이디
  workerPlanSendDate: string; // 근로자 연차 사용 계획서 발송일
  secondAlarmStartDate: string; // 2차 지정 통보 알림 발송 가능 시작일
  secondAlarmEndDate: string; // 2차 지정 통보 알림 발송 가능 종료일
  secondAlarmSendDate: string; // 2차 지정 통보 알림 발송일
  secondAlarmId: number; // 2차 지정 통보 알림 발송 아이디
  updateAt: string; // 수정 날짜/시간
}

/** 연차사용촉진알림 리스트 */
async function findSendNoticeList(arg: {
  startDate?: string;
  endDate?: string;
  searchcode?: string;
  searchword?: string;
  datetype?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<
  {
    employeeId: number;
    standardDate: string;
    enterDate: string | null;
    workingYearString: string;
    leaveType: string;
    subjectItem: subjectItem[];
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/subjects/all`;
    const params = {
      fromdate: arg.startDate,
      todate: arg.endDate,
      searchcode: arg.searchcode,
      searchword: arg.searchword,
      datetype: arg.datetype,
      pageno: arg.pageNo || 1,
      rowsperpage: arg.rowsPerPage || 15,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 연차사용촉진알림 총 갯수 */
async function totalCount(arg: {
  startDate?: string;
  endDate?: string;
  searchcode?: string;
  searchword?: string;
  datetype?: string;
  enterperiodtype?: string;
  pageNo: number;
  rowsPerPage: number;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/subjects/all/totalcount`;
    const params = {
      fromdate: arg.startDate,
      todate: arg.endDate,
      searchcode: arg.searchcode,
      searchword: arg.searchword,
      datetype: arg.datetype,
      enterperiodtype: arg.enterperiodtype,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// TODO: 문서 id로 조회할 수 있도록 리팩터링 예정
/** 공통 문서 조회 */
async function fetchView(arg: {
  employeeId: number;
  standardDate: string;
  alertType: number;
  alertFormType: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  standardDate: string;
  alertType: number;
  id: number;
  contents: string;
  updateAt: string;
}> {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/attendance/v1/leave/alert/form`;
  const response = await axios.get(url, {
    headers,
    params: {
      ...arg,
      alertFormType: arg.alertFormType.toUpperCase(),
    },
  });
  return response.data;
}
/** 팀즈 공통 문서 조회 */
async function fetchTeamsView(arg: { id: number }): Promise<{
  companyId: number;
  employeeId: number;
  standardDate: string;
  alertType: number;
  id: number;
  contents: string;
  updateAt: string;
  isEnableWorkPlan: boolean;
}> {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/attendance/v1/leave/alert/form/${arg.id}`;
  const response = await axios.get(url, { headers });
  return response.data;
}

/** 연차사용촉진알림 발송 */
async function createNotice(arg: {
  employeeId: number;
  alertType: number;
  standardDate: string;
  contents: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  standardDate: string;
  alertType: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/first`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 지정일통보서 발송 */
async function createPlanNotify(arg: {
  employeeId: number;
  alertType: number;
  standardDate: string;
  contents: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  standardDate: string;
  alertType: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/leave/alert/second`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const sendNoticeApi = {
  list: findSendNoticeList,
  totalCount,
  createNotice,
  createPlanNotify,
  fetchView,
  fetchTeamsView,
};

export default sendNoticeApi;
