import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import {
  b62,
  getAvatarPath,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  getText,
  go,
} from '../../../../groupware-common/utils';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import EuiBody from '../../../../components/layout/EuiBody';
import OrganizationChartContentList from './DirectoryList';
import DirectoryCompanyView from './DirectoryCompanyView';
import DirectoryOrganizationView from './DirectoryOrganizationView';
import DirectoryEmployeeView from './DirectoryEmployeeView';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import {
  DirectoryTreeItemArg,
  getDirectoryTreeId,
  getDirectoryTreeItems,
} from '../../../../components/tree/DirectoryTree';
import { companyActions } from '../../../stores/directory/company';
import { organizationActions } from '../../../stores/directory/organization';
import { employeeActions } from '../../../stores/directory/employee';
import {
  parseDirectoryTreeItemId,
  parseDirectoryType,
} from '../../../stores/directory/utils';
import { getName } from '../../../../groupware-webapp/stores/common/utils';
import Button from '../../../../components/button/Button';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import DirectorySelected from './DirectorySelected';
import SplitUnselected from '../../../../components/split/SplitUnselected';
import { getCompanyName, getOrganizationName } from '../../../stores/directory';

function DirectoryContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const resource = useSelector((state: RootState) => state.session.resource);
  const principal = useSelector((state: RootState) => state.session.principal);
  const pathParams = getPathParams(`/*/:p1/:p2`, props.pathname);
  const propsOrganizationId = b62(pathParams.p1);
  const propsEmployeeId = b62(pathParams.p2);
  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();
  const languages = useSelector((state: RootState) => state.session.languages);

  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  ).filter(({ id }) => id === principal.companyId);
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );

  const companyView = useSelector(
    (state: RootState) => state.directory.company.view.data,
  );
  const organizationView = useSelector(
    (state: RootState) => state.directory.organization.view.data,
  );

  const employeeView = useSelector(
    (state: RootState) => state.directory.employee.view.data,
  );

  const items = useMemo(
    () =>
      getDirectoryTreeItems({
        companies,
        organizations,
        organizationEmployees,
        employees,
        jobClassType,
        jobPositions,
        jobDuties,
      }),
    [
      companies,
      organizations,
      organizationEmployees,
      employees,
      jobClassType,
      jobPositions,
      jobDuties,
    ],
  );

  const [state, setState] = useState<{
    selectable: boolean;
    selected: {
      id: string;
      name: string;
      icon: 'company' | 'person' | 'sitemap';
      avatar?: string | undefined;
    }[];
  }>({
    selectable: false,
    selected: [],
  });

  const handleListItemSelection = () => {
    setState((prevState) => ({
      ...prevState,
      selectable: !prevState.selectable,
      selected: [],
    }));
  };

  const handleListItem = (arg: DirectoryTreeItemArg) => {
    if (state.selectable) {
      handleListItemSelected(arg);
    } else {
      handleListItemClick(arg);
    }
  };

  const handleListItemSelected = (arg: DirectoryTreeItemArg) => {
    const item = state.selected.find((x) => x.id === arg.item.id)?.id;
    if (arg.item.id !== item) {
      let name = '';

      switch (arg.item.extra.type) {
        case 'employee':
          name = arg.item.extra.employeeName;
          break;
        case 'organization':
          name = arg.item.extra.organizationName;
          break;
        case 'company':
          name = arg.item.extra.companyName;
          break;
        default:
          break;
      }

      setState((prevState) => ({
        ...prevState,
        selected: [
          ...prevState.selected,
          {
            id: arg.item.id,
            name,
            icon: arg.item.icon,
            avatar: arg.item.avatar,
          },
        ],
      }));
    }
  };

  const handleDeleteContact = (id: string) => {
    setState((prevState) => ({
      ...prevState,
      selected: prevState.selected.filter((x) => x.id !== id),
    }));
  };

  const handleListItemClick = useCallback(
    (arg: DirectoryTreeItemArg) => {
      // console.log(`handleListItemClick(arg: DirectoryTreeItemExtra)`, arg);

      const pathmap = getPathMap('/*', props.pathname);

      const { extra } = arg.item;
      if (extra.type === 'company') {
        const { companyId } = extra;
        const pathname = `${pathmap}/${b62(companyId)}`;
        const param = { id: companyId, route: { pathname } };
        dispatch(companyActions.findView(param));
      }
      if (extra.type === 'organization') {
        const { organizationId } = extra;
        const pathname = `${pathmap}/${b62(organizationId)}`;
        const param = { id: organizationId, route: { pathname } };
        dispatch(organizationActions.findView(param));
      }
      if (extra.type === 'employee') {
        const { organizationId, employeeId } = extra;
        const pathname = `${pathmap}/${b62(organizationId)}/${b62(employeeId)}`;
        const param = { id: employeeId, route: { pathname } };
        dispatch(employeeActions.findView(param));
      }
    },
    [dispatch, props.pathname],
  );

  const selectedEmails = () => {
    const { selected } = state;
    const emails = [
      principal.email,
      ...items
        .filter((a) => selected.some((b) => a.id === b.id))
        .map(({ extra }) => {
          if (extra.type === 'employee') {
            return [
              employees.find(
                (employee) =>
                  employee.companyId === extra.companyId &&
                  employee.id === extra.employeeId,
              )?.email ?? '',
            ];
          }
          if (extra.type === 'organization') {
            return organizationEmployees
              .filter(
                (organization) =>
                  organization.companyId === extra.companyId &&
                  organization.id === extra.organizationId,
              )
              .map((organization) => {
                return (
                  employees.find(
                    (employee) =>
                      employee.companyId === organization.companyId &&
                      employee.id === organization.employeeId,
                  )?.email ?? ''
                );
              });
          }
          if (extra.type === 'company') {
            return employees
              .filter((employee) => employee.companyId === extra.companyId)
              .map(({ email }) => email);
          }
          return [];
        })
        .flat()
        .filter((a) => a !== ''),
    ];

    return Array.from(new Set(emails));
  };

  const handleCreateChat = () => {
    const users = selectedEmails().join(',');
    if (resource === 'teams') {
      microsoftTeams.initialize();
      microsoftTeams.executeDeepLink(
        `https://teams.microsoft.com/l/chat/0/0?users=${users}`,
      );
    }
  };

  const handleCreateVideoCall = () => {
    const users = selectedEmails().join(',');
    if (resource === 'teams') {
      microsoftTeams.initialize();
      microsoftTeams.executeDeepLink(
        `https://teams.microsoft.com/l/call/0/0?users=${users}&withVideo=true`,
      );
    }
  };

  const renderList = () => {
    const companyId = queryParams.companyId || principal.companyId;
    const treeId =
      propsOrganizationId === undefined
        ? undefined
        : getDirectoryTreeId(companyId, propsOrganizationId, propsEmployeeId);

    return (
      <OrganizationChartContentList
        selectedId={state.selectable ? undefined : treeId}
        items={items}
        onItemClick={handleListItem}
        selectable={state.selectable}
        onOrganizationSelection={handleListItemSelection}
      />
    );
  };

  const renderView = () => {
    if (propsOrganizationId !== undefined) {
      const companyId = queryParams.companyId || principal.companyId;

      const type = parseDirectoryType(
        companyId,
        propsOrganizationId,
        propsEmployeeId,
      );

      if (type === 'company') {
        if (companyView === undefined || companyView === null)
          return (
            <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>
          );

        const {
          representativesName,
          establishedDate,
          businessRegistrationNo,
          corporationRegistrationNo,
          businessType,
          businessItem,
          phoneNo,
          faxNo,
          postalCode,
          address,
          employeeCount,
        } = companyView;

        const businessRegExr = /^[0-9]{10}$/;
        const corporationRegExr = /^[0-9]{13}$/;
        let formatBusinessNo = businessRegistrationNo;
        let formatCorporationNo = corporationRegistrationNo;
        if (businessRegExr.test(formatBusinessNo)) {
          const part1 = formatBusinessNo.substring(0, 3);
          const part2 = formatBusinessNo.substring(3, 5);
          const part3 = formatBusinessNo.substring(5);
          formatBusinessNo = `${part1}-${part2}-${part3}`;
        }

        if (corporationRegExr.test(formatCorporationNo)) {
          const part1 = formatCorporationNo.substring(0, 6);
          const part2 = formatCorporationNo.substring(6);
          formatCorporationNo = `${part1}-${part2}`;
        }

        return (
          <DirectoryCompanyView
            name={getCompanyName(companyId)}
            representativesName={representativesName}
            businessRegistrationNo={formatBusinessNo}
            corporationRegistrationNo={formatCorporationNo}
            establishedDate={establishedDate}
            businessType={businessType}
            businessItem={businessItem}
            phoneNo={phoneNo}
            faxNo={faxNo}
            postalCode={postalCode}
            address={address}
            employeeCount={employeeCount}
          />
        );
      }

      if (type === 'organization') {
        if (organizationView === undefined || organizationView === null)
          return (
            <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>
          );

        const parentNames = getParentItems(
          items,
          `${companyId}_${organizationView.parentId}`,
        ).map((x) => x.text);

        const { description, nameKey, managerNameKey } = organizationView;
        const names = languages.map((language) => {
          const value = getText([nameKey, '미지정'], language);
          return { label: language, value };
        });
        const managerName = getText([managerNameKey, '미지정']);

        return (
          <DirectoryOrganizationView
            parentNames={parentNames}
            names={names}
            description={description}
            managerName={managerName}
          />
        );
      }

      if (type === 'employee') {
        if (employeeView === undefined || employeeView === null)
          return (
            <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>
          );

        const affiliatedOrganizations = organizationEmployees
          .filter(
            (a) =>
              a.companyId === companyId && a.employeeId === propsEmployeeId,
          )
          .map((a) => {
            return {
              representative:
                a.id === employeeView.representativeOrganizationId,
              jobDutyName: getName(
                jobDuties,
                a.companyId,
                a.jobDutyId,
                '미지정',
              ),
              paths: getParentItems(
                items,
                parseDirectoryTreeItemId(a.companyId, a.id),
              ).map((b) => b.text),
            };
          });

        if (affiliatedOrganizations.length === 0)
          return (
            <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>
          );

        const treeItemId = parseDirectoryTreeItemId(
          companyId,
          propsOrganizationId,
          propsEmployeeId,
        );
        const treeItem = items.find((a) => a.id === treeItemId);
        if (treeItem === undefined)
          return (
            <span>데이터가 이동되었거나 삭제되어 조회할 수 없습니다.</span>
          );

        const {
          id,
          email,
          no,
          companyPhoneNo,
          extensionPhoneNo,
          mobilePhoneNo,
          task,
          enterDate,
          nameKey,
          jobPositionId,
          jobRank,
          personalEmail,
          updateAt,
        } = employeeView;

        const organizationName = getOrganizationName(
          companyId,
          propsOrganizationId,
          '미지정',
        );

        const jobPositionName = getName(
          jobPositions,
          companyId,
          jobPositionId,
          '미지정',
        );

        const names = languages.map((language) => {
          const value = getText([nameKey, '미지정'], language);
          return { label: getText(language), value };
        });

        const avatar = getAvatarPath(employeeView.companyId, id, updateAt);

        return (
          <DirectoryEmployeeView
            key={`${id}/${updateAt}`}
            avatar={avatar}
            name={treeItem.text}
            organizationName={organizationName}
            email={email}
            names={names}
            enterDate={enterDate}
            no={no}
            companyPhoneNo={companyPhoneNo}
            extensionPhoneNo={extensionPhoneNo}
            mobilePhoneNo={mobilePhoneNo}
            affiliatedOrganizations={affiliatedOrganizations}
            jobPositionName={jobPositionName}
            task={task}
            personalEmail={personalEmail}
          />
        );
      }
    }
    return null;
  };

  const handleCloseView = () => {
    const pathname = getPathMap(`/*`, props.pathname);
    dispatch(sessionActions.setRoute({ pathname }));

    setState((prevState) => ({
      ...prevState,
      selectable: false,
      selected: [],
    }));
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title navDisabled={resource === 'teams'}>
          조직도
        </EuiHeader.Title>
        <EuiHeader.SideFixed>
          {resource === 'teams' &&
            principal.roles.find(
              (role) => role === 'ADMIN' || role === 'DIRECTORY_ADMIN',
            ) && (
              <Button
                text="디렉터리 설정"
                iconType
                icon="cog-fill"
                color="secondary"
                onClick={() => go('/adminconsole/directory')}
              />
            )}
        </EuiHeader.SideFixed>
      </EuiHeader>
      <EuiBody>
        <EuiSetting
          className={`ui-directory ${state.selectable ? 'expanded' : ''}`}
        >
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {propsOrganizationId === undefined && !state.selectable ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleCloseView}>
              {state.selectable ? (
                <DirectorySelected
                  items={state.selected}
                  onDelete={handleDeleteContact}
                  onCreateChat={handleCreateChat}
                  onCreateVideoCall={handleCreateVideoCall}
                />
              ) : (
                renderView()
              )}
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
    </>
  );
}

export default DirectoryContainer;
