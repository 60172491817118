import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';

import { jobPositionActions } from '../../../../stores/directory/jobposition';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function JobPositionRoute({ pathname }: { pathname: string }): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([dispatch(jobPositionActions.findList())]).then(() => {
      dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
    });
  }, [dispatch, pathname]);

  return null;
}

export default JobPositionRoute;
