/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import DataGrid, {
  DataGridColDef,
} from '../../../../../components/data/DataGrid';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import AttendanceDetailSearch from '../../../../../components/search/AttendanceDetailSearch';
import SelectField from '../../../../../components/selectField/SelectField';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import {
  createQueryString,
  getPathMap,
  getQueryParams,
  utils,
} from '../../../../../groupware-common/utils';
import {
  getEmployeeName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  appError,
  getDirectoryData,
} from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { sendNoticeActions } from '../../../../stores/attendance/sendNotice';
import AttendanceSendNoticeDocumentViewDialog from './AttendanceSendNoticeDocumentViewDialog';
import AttendanceSendNoticeDocumentWriteDialog from './AttendanceSendNoticeDocumentWriteDialog';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Loading from '../../../../../components/loading/Loading';
import {
  dateFormat,
  timeFormat,
} from '../../../../../groupware-common/utils/ui';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import Alert from '../../../../../components/alert/Alert';
import EmptyData from '../../../../../components/data/EmptyData';

function AttendanceSendNoticeContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const list = useSelector((s: RootState) => s.attendance.sendNotice.list);
  const notice = useSelector((s: RootState) => s.attendance.preferences.notice);
  const principal = useSelector((s: RootState) => s.session.principal);

  const { expressionUnit } = useSelector(
    (state: RootState) => state.attendance.preferences.basic,
  );
  const categories = useSelector(
    (s: RootState) => s.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');

  const companyView = useSelector((s: RootState) => {
    return s.directory.company.view.data;
  });
  const employees = useSelector((s: RootState) => {
    return s.directory.employee.list.data.items;
  });

  /** 오류 배열. */
  const errors = useSelector((state: RootState) => state.session.errors);

  const title = categories.find((a) => a.id === 6008)?.name ?? '';

  const dispatch = useAppDispatch();

  const { totalCount } = list;
  const { companyId } = principal;

  const [state, setState] = useState<{
    pageNo: number;
    rowsPerPage: number;
    selectedEmployee?: {
      employeeId: number;
      standardDate: string;
      alertType: number;
      alertFormType: string; // fisrt: 1차촉진, plan: 연차 사용 계획서, second : 지정일 통보서
      rest: number;
    };
    descriptionVisible: boolean;
    validation: string;
    loading: boolean;
  }>({
    pageNo: 1,
    rowsPerPage: 15,
    descriptionVisible: false,
    validation: '',
    loading: false,
  });

  const { pathname, search } = props;
  const queryParams = getQueryParams(search);
  const directory = useDirectory();

  /** 알림 담당자 이름 */
  const senderName = getEmployeeName({
    companyId,
    employeeId: notice.alertEmployeeId,
  });

  const getLabel = (status: string): string => {
    if (status === 'create') return '발송';
    if (status === 'read') return '조회';
    return '통보불가';
  };

  const getTheme = (
    status: string,
  ): 'primary' | 'success' | 'error' | 'warning' | 'cancel' | undefined => {
    if (status === 'create') return 'primary';
    if (status === 'read') return undefined;
    return undefined;
  };

  const getMacro = (arg: {
    id: string;
    data: { employeeId: number; rest: string };
  }) => {
    const { id, data } = arg;
    const { employeeId, rest } = data;
    const directoryData = getDirectoryData({
      ...directory,
      companyId,
      employeeId,
    });

    if (employeeId === 0) return;

    const employeeData = employees.find((a) => a.id === employeeId);
    if (employeeData === undefined) return;

    // 회사명. _CMP_NAME_
    if (id === 'DIRECTORY/COMAPNY') {
      return directoryData.companyName;
    }
    // 회사 우편번호.
    if (id === 'DIRECTORY/COMPANY_POSTALCODE') {
      return companyView?.postalCode ?? '';
    }
    // 회사주소.
    if (id === 'DIRECTORY/COMPANY_ADDRESS') {
      return companyView?.address ?? '';
    }
    // 회사전화.
    if (id === 'DIRECTORY/COMPANY_PHONE_NO') {
      return companyView?.phoneNo ?? '';
    }
    // 회사팩스.
    if (id === 'DIRECTORY/COMPANY_FAX_NO') {
      return companyView?.faxNo ?? '';
    }
    /** 연차촉진 발송자명 */
    if (id === 'ATTENDANCE/SENDNOTICE_SENDER_NAME') return senderName;
    /** 연차촉진 대상자명 */
    if (id === 'ATTENDANCE/SENDNOTICE_NAME') return directoryData.employeeName;
    /** 연차촉진 대상자사번 */
    if (id === 'ATTENDANCE/SENDNOTICE_EMPLOYEENO') return employeeData.no;
    /** 연차촉진 대상자부서 */
    if (id === 'ATTENDANCE/SENDNOTICE_ORGANIZATION')
      return directoryData.organizationName;
    /** 연차촉진 대상자직위 */
    if (id === 'ATTENDANCE/SENDNOTICE_JOBPOSITION')
      return directoryData.jobPositionName;
    /** 연차촉진 대상자입사일자 */
    if (id === 'ATTENDANCE/SENDNOTICE_ENTERDATE') return employeeData.enterDate;
    /** 연차촉진 잔여연차 */
    if (id === 'ATTENDANCE/SENDNOTICE_REMAIN_COUNT') return rest;
    /** 연차촉진 대상자서명 */
    if (id === 'ATTENDANCE/SENDNOTICE_SIGNATURE') return '';
    /** 오늘 날짜 */
    if (id === 'COMMON/TODAY') return dateFormat(new Date(), 'yyyy-MM-DD');

    return undefined;
  };

  useEffect(() => {
    async function run() {
      try {
        const { loading } = state;
        if (loading) {
          const selectedList = list.items.filter((x) => x.checked);
          const sendList: {
            employeeId: number;
            alertType: number;
            standardDate: string;
            contents: string;
          }[] = [];
          for (let i = 0; i < selectedList.length; i += 1) {
            const today = moment().format('YYYY-MM-DD');
            const item = selectedList[i].dayOff.find(
              (x) =>
                x.noticeId === 0 &&
                x.noticeDateStart <= today &&
                x.noticeDateEnd >= today &&
                x.remainedLeaves > 0,
            );
            if (item === undefined) {
              dispatch(
                sessionActions.error(
                  appError({ error: '발송 데이터가 없습니다.' }),
                ),
              );
              return;
            }
            const contents = FormBuilder.binding({
              html: notice.alertFormContents,
              data: {
                employeeId: selectedList[i].employeeId,
                rest: timeFormat(item.remainedLeaves, expressionUnit),
              },
              getMacro,
            });
            sendList.push({
              employeeId: selectedList[i].employeeId,
              alertType: item.alertType,
              standardDate: selectedList[i].standardDate,
              contents,
            });
          }
          Promise.all(
            sendList.map((send) => {
              return dispatch(sendNoticeActions.saveNotice({ arg: send }));
            }),
          ).then((response) => {
            const isRejected =
              response.findIndex((x) => x.type.endsWith('rejected')) > -1;
            if (!isRejected) {
              const route = utils.getRoute({
                target: props,
                source: {
                  pathname: `${getPathMap('/*/*/*', pathname)}`,
                  option: 'CLEAR_DIALOG',
                },
              });
              dispatch(sendNoticeActions.list({ route }));
              setState((prevState) => ({
                ...prevState,
                loading: false,
              }));
              dispatch(sessionActions.setSyncLoading(false));
            }
          });
        }
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
      }
    }
    run();
  }, [state.loading]);

  useEffect(() => {
    // 일괄 발송 중 오류 발생 후 확인 버튼을 눌렀을 때 리스트를 다시 불러옴.
    if (errors.length === 0 && state.loading) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      const route = utils.getRoute({
        target: props,
        source: {
          pathname: `${getPathMap('/*/*/*', pathname)}`,
        },
      });
      dispatch(sendNoticeActions.list({ route }));
    }
  }, [errors]);

  const columns: DataGridColDef[] = [
    { field: 'checkbox', label: '', width: 80 },
    { field: 'employeeNo', label: '사번', align: 'center', minWidth: 120 },
    { field: 'name', label: '이름', align: 'center', minWidth: 158 },
    { field: 'organization', label: '소속부서', align: 'center', width: 120 },
    { field: 'enterDate', label: '입사일자', align: 'center', width: 118 },
    // eslint-disable-next-line prettier/prettier
    { field: 'workingYearString', label: '근속연수', align: 'center', width: 118 },
    { field: 'round', label: '회차', align: 'center', width: 120 },
    { field: 'rest', label: '잔여', align: 'center', minWidth: 100 },
    { field: 'noticeDate', label: '촉진일', align: 'center', width: 118 },
    { field: 'notice', label: '알림문서', align: 'center', width: 100 },
    { field: 'usePlan', label: '사용계획서', align: 'center', width: 100 },
    {
      field: 'planNotifyDate',
      label: '지정일통보발송일',
      align: 'center',
      width: 118,
    },
    { field: 'planNotify', label: '통보', align: 'center', width: 100 },
  ];

  const pageCountOptions = [
    { value: '5', label: '5 개' },
    { value: '10', label: '10 개' },
    { value: '15', label: '15 개' },
    { value: '20', label: '20 개' },
    { value: '25', label: '25 개' },
    { value: '30', label: '30 개' },
    { value: '35', label: '35 개' },
    { value: '50', label: '50 개' },
    { value: '100', label: '100 개' },
  ];

  /** 알림 대화상자 닫기. */
  const handleCloseDialog = () => {
    setState((prev) => ({
      ...prev,
      selectedEmployee: undefined,
      descriptionVisible: false,
    }));
    dispatch(sessionActions.setDialog());
  };

  /** 알림 발송일 자세히 대화상자 열기. */
  const handleOpenDescription = () => {
    setState((prev) => ({ ...prev, descriptionVisible: true }));
  };
  /** 알림 대화상자 열기. */
  const handleOpenDialog = (arg: {
    mode: string; // 'create', 'read', 'disable'
    type: 'notice' | 'usePlan' | 'planNotify';
    alertType: number; // 1, 2
    employeeId: number;
    standardDate: string;
    rest: number;
  }) => {
    const { mode, type, alertType, employeeId, standardDate, rest } = arg;

    if (type === 'notice' && mode === 'create') {
      if (notice.alertFormId === 0) {
        setState((prev) => ({
          ...prev,
          validation:
            '연차촉진알림 양식이 설정되어 있지 않아 알림을 발송할 수 없습니다.',
        }));
        return;
      }
    }
    if (type === 'planNotify' && mode === 'create') {
      if (notice.usePlanFormId === 0) {
        setState((prev) => ({
          ...prev,
          validation:
            '사용계획 양식이 설정되어 있지 않아 알림을 발송할 수 없습니다.',
        }));
        return;
      }
    }

    if (mode === 'create' || mode === 'read') {
      let alertFormType = '';
      switch (type) {
        case 'notice':
          alertFormType = 'first';
          break;
        case 'usePlan':
          alertFormType = 'plan';
          break;
        case 'planNotify':
          alertFormType = 'second';
          break;
        default:
          alertFormType = '';
      }

      setState((prev) => ({
        ...prev,
        selectedEmployee: {
          employeeId,
          alertType,
          standardDate,
          alertFormType,
          rest,
        },
      }));
      dispatch(sessionActions.setDialog({ type, mode }));
    }
  };

  /** 알림 발송 버튼 클릭. */
  const handleSend = (contents: string) => {
    const { dialogType, dialogMode } = queryParams;

    if (
      dialogType === undefined ||
      dialogMode === undefined ||
      state.selectedEmployee === undefined
    )
      return;
    const { employeeId, standardDate, alertType, alertFormType } =
      state.selectedEmployee;

    /** 연차사용촉진알림 발송. */
    if (dialogType === 'notice' && dialogMode === 'create') {
      const arg = {
        employeeId,
        alertType,
        standardDate,
        contents,
        alertFormType,
      };

      const params = { ...queryParams };
      delete params.dialogMode;
      delete params.dialogType;

      const route = { pathname, search: createQueryString(params) };
      dispatch(sendNoticeActions.saveNotice({ arg, route })).then(() => {
        dispatch(sendNoticeActions.list({ route }));
      });
    }

    /** 지정일통보 발송. */
    if (dialogType === 'planNotify' && dialogMode === 'create') {
      const arg = {
        employeeId,
        alertType,
        standardDate,
        contents,
      };

      const params = { ...queryParams };
      delete params.dialogMode;
      delete params.dialogType;

      const route = { pathname, search: createQueryString(params) };
      dispatch(sendNoticeActions.savePlanNotify({ arg, route }));
    }
    /** 공통 알림문서 조회. */
    if (dialogMode === 'read') {
      dispatch(sessionActions.setDialog());
    }
  };

  /** 이전 페이지 */
  const handlePrev = async () => {
    queryParams.pageNo = (queryParams.pageNo || 1) - 1;
    if (queryParams.pageNo > 0) {
      if (queryParams.pageNo === 1) {
        delete queryParams.pageNo;
      }
      dispatch(
        sendNoticeActions.list({
          route: {
            pathname,
            search: getQueryParams(queryParams),
          },
        }),
      );
    }
  };

  /** 다음 페이지 */
  const handleNext = async () => {
    queryParams.pageNo = (queryParams.pageNo || 1) + 1;
    dispatch(
      sendNoticeActions.list({
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 목록개수 변경. */
  const handleChangeRowsLength = (value: string) => {
    const rowsPerPage = parseInt(value, 10);
    delete queryParams.pageNo;
    queryParams.rowsPerPage = rowsPerPage;
    dispatch(
      sendNoticeActions.list({
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  const renderDialog = () => {
    const { dialogMode } = queryParams;

    if (state.selectedEmployee) {
      const { employeeId, standardDate, alertType, alertFormType, rest } =
        state.selectedEmployee;

      /** 알림문서 발송. */
      if (dialogMode === 'create') {
        return (
          <AttendanceSendNoticeDocumentWriteDialog
            search={search}
            employeeId={employeeId}
            senderName={senderName}
            onClick={handleSend}
            onClose={handleCloseDialog}
            rest={rest ?? 0}
          />
        );
      }
      /** 알림문서 읽기. */
      if (dialogMode === 'read') {
        return (
          <AttendanceSendNoticeDocumentViewDialog
            employeeId={employeeId}
            standardDate={standardDate}
            alertFormType={alertFormType}
            alertType={alertType}
            onClose={handleCloseDialog}
          />
        );
      }
    }

    /** 촉진일 발송기준 보기. */
    if (state.descriptionVisible) {
      return (
        <Dialog
          className="attendance-notice-description"
          onClose={handleCloseDialog}
          size="lg"
        >
          <DialogHeader>
            <DialogTitle>연차사용촉진 절차</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="description">
              <p>※ 개정법에 따른 1년 미만 근무자 연차사용촉진 절차</p>
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>연차부여기준</span>
                    </th>
                    <th>
                      <span>횟수</span>
                    </th>
                    <th>
                      <span>촉진알림발송</span>
                    </th>
                    <th>
                      <span>사용계획서</span>
                    </th>
                    <th>
                      <span>지정일통보발송</span>
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td rowSpan={2}>
                    <span>회계연도</span>
                  </td>
                  <td>
                    <span>1차</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 3개월 전 ~ 10일</span>
                  </td>
                  <td>
                    <span>촉진받은 날짜로부터 10일 이내 상신</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 1개월 전까지</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>2차</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 1개월 전 ~ 5일</span>
                  </td>
                  <td>
                    <span>촉진받은 날짜로부터 10일 이내 상신</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 10일 전까지</span>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <span>입사일</span>
                  </td>
                  <td>
                    <span>1차</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 3개월 전 ~ 10일</span>
                  </td>
                  <td>
                    <span>촉진받은 날짜로부터 10일 이내 상신</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 1개월 전까지</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>2차</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 1개월 전 ~ 5일</span>
                  </td>
                  <td>
                    <span>촉진받은 날짜로부터 10일 이내 상신</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 10일 전까지</span>
                  </td>
                </tr>
              </table>
              <p>
                <b>※ 1년 이상 근무자 연차사용촉진 절차</b>
              </p>
              <table>
                <tr>
                  <th>
                    <span>연차부여기준</span>
                  </th>
                  <th>
                    <span>횟수</span>
                  </th>
                  <th>
                    <span>촉진알림발송</span>
                  </th>
                  <th>
                    <span>사용계획서</span>
                  </th>
                  <th>
                    <span>지정일통보발송</span>
                  </th>
                </tr>
                <tr>
                  <td>
                    <span>회계연도</span>
                  </td>
                  <td>
                    <span>1차</span>
                  </td>
                  <td>
                    <span>회계연도 종료일로부터 6개월 전 ~ 10일</span>
                  </td>
                  <td>
                    <span>촉진받은 날짜로부터 10일 이내에 상신</span>
                  </td>
                  <td>
                    <span>회계연도 종료일로부터 2개월 전까지</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>입사일</span>
                  </td>
                  <td>
                    <span>1차</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 6개월 전 ~ 10일</span>
                  </td>
                  <td>
                    <span>촉진받은 날짜로부터 10일 이내 상신</span>
                  </td>
                  <td>
                    <span>다음 입사일로부터 2개월 전까지</span>
                  </td>
                </tr>
              </table>
            </div>
          </DialogBody>
        </Dialog>
      );
    }

    return null;
  };

  // 스낵 바 닫기.
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 검색. */
  const handleSearch = (arg: {
    keyword: string;
    filter: string;
    directoryFilter?: string; // 조직도 검색필터.
    directoryKeyword?: string; // 조직도 검색어.
    date?: {
      start: Date | null;
      end: Date | null;
    };
  }) => {
    const { keyword, filter, date, directoryFilter, directoryKeyword } = arg;

    delete queryParams.pageNo;
    queryParams.searchCode = filter;
    queryParams.searchWord = keyword;
    queryParams.directoryKeyword = directoryKeyword;
    queryParams.directoryFilter = directoryFilter;
    if (date !== undefined) {
      queryParams.startDate = date.start
        ? dateFormat(date.start, 'yyyy-MM-DD')
        : undefined;
      queryParams.endDate = date.end
        ? dateFormat(date.end, 'yyyy-MM-DD')
        : undefined;
    }
    dispatch(
      sendNoticeActions.list({
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /* 체크박스 전체선택 */
  const handleChangeCheckedAll = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      sendNoticeActions.checked({
        employeeId: 'all',
        checked: event.target.checked,
      }),
    );
  };

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    dispatch(
      sendNoticeActions.checked({
        employeeId: id,
        checked: event.target.checked,
      }),
    );
  };

  const handleCancelChecked = () => {
    dispatch(
      sendNoticeActions.checked({
        employeeId: 'all',
        checked: false,
      }),
    );
  };

  /** 일괄발송 이벤트 */
  const handleBulkSend = () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    dispatch(sessionActions.setSyncLoading(true));
  };

  const checkedCount = list.items.filter((x) => x.checked).length;

  // 날짜 검색 값 설정.
  const start = queryParams.startDate
    ? new Date(queryParams.startDate)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const end = queryParams.endDate ? new Date(queryParams.endDate) : new Date();

  if (!notice.alertType || notice.alertType === 0)
    return <EmptyData message="연차사용촉진알림을 사용여부를 확인해 주세요." />;
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceDetailSearch
            directory
            filter={queryParams.searchCode ?? 'accel'}
            options={[
              { label: '촉진일자', value: 'accel' },
              { label: '통보일자', value: 'notice' },
            ]}
            date={{ start, end }}
            keyword={queryParams.searchWord}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
      </EuiHeader>
      <EuiBody>
        <div style={{ margin: '0 24px' }}>
          <Alert severity="info" size="sm">
            * 해당 메뉴는 한국 내 사용에만 해당하며, 한국 기준으로 표시되어
            있습니다.
          </Alert>
        </div>
        <div className="eui-data-grid">
          <div className="grid-root">
            <table className="grid attendance-notice-table">
              <colgroup>
                {columns.map((column) => {
                  if (column.visible === false) return null;
                  return (
                    <col
                      key={column.field}
                      style={
                        column.minWidth !== undefined
                          ? { minWidth: column.minWidth, width: 'auto' }
                          : { width: column.width }
                      }
                      id={`grid-col-${column.field}`}
                    />
                  );
                })}
              </colgroup>
              <thead className="grid-head">
                <DataGrid.Row>
                  {columns.map((column) => {
                    if (column.visible === false) return null;
                    return (
                      <DataGrid.Column
                        type="th"
                        align={column.align}
                        key={column.field}
                        scope="col"
                      >
                        {column.field === 'checkbox' ? (
                          <Checkbox
                            className="row-checked"
                            checked={
                              checkedCount !== 0 &&
                              checkedCount ===
                                list.items.filter((x) => x.isAlarmEnabled)
                                  .length
                            }
                            onChange={handleChangeCheckedAll}
                          />
                        ) : (
                          <span className="head-cell">
                            <span className="cell-label">{column.label}</span>
                            {column.field === 'noticeDate' && (
                              <Button
                                iconType
                                icon="exclamation-triangle-fill"
                                color="secondary"
                                onClick={handleOpenDescription}
                                size="sm"
                              />
                            )}
                          </span>
                        )}
                      </DataGrid.Column>
                    );
                  })}
                </DataGrid.Row>
              </thead>
              <tbody className="grid-body">
                {list.items.map((a) => {
                  const rowspan = a.dayOff.length;
                  const directoryData = getDirectoryData({
                    ...directory,
                    ...a,
                    employeeId: a.employeeId,
                    companyId,
                  });

                  const employeeNo =
                    directory.employees.find(({ id }) => id === a.employeeId)
                      ?.no ?? '';
                  return a.dayOff.map((b, i) => {
                    let rowData: React.ReactNode | null = null;

                    const noticeDate =
                      b.noticeId === 0 ? b.noticeDateStart : b.noticeSendDate;

                    const planNotifyDate =
                      b.planNotifyId === 0
                        ? b.planNotifyDateStart
                        : b.planNotifySendDate;

                    const key = `row-${i + 1}`;

                    const rest = timeFormat(b.remainedLeaves, expressionUnit);
                    if (i === 0) {
                      rowData = (
                        <tr className="one-row" key={key}>
                          <td
                            className="one-data"
                            rowSpan={rowspan}
                            style={{ paddingLeft: '24px' }}
                          >
                            <Checkbox
                              className="row-checked"
                              checked={a.checked}
                              onChange={(event) =>
                                handleChangeChecked(event, a.employeeId)
                              }
                              disabled={!a.isAlarmEnabled}
                            />
                          </td>
                          <td className="one-data" rowSpan={rowspan}>
                            {employeeNo}
                          </td>
                          <td className="one-data" rowSpan={rowspan}>
                            <strong>{directoryData.employeeName}</strong>
                          </td>
                          <td className="one-data" rowSpan={rowspan}>
                            {directoryData.organizationName}
                          </td>
                          <td className="one-data" rowSpan={rowspan}>
                            {a.enterDate}
                          </td>
                          <td className="one-data" rowSpan={rowspan}>
                            {a.workingYearString}
                          </td>
                          <td className="two-data">
                            {b.alertType !== 0
                              ? `개정${b.alertType}차`
                              : '일반연차'}
                          </td>
                          <td className="two-data">{rest}</td>
                          <td className="two-data">{noticeDate}</td>
                          <td className="two-data">
                            <Chip
                              className="cell-tag"
                              label={getLabel(b.noticeStatus)}
                              disabled={b.noticeStatus === 'disable'}
                              size="sm"
                              theme={getTheme(b.noticeStatus)}
                              onClick={
                                b.noticeStatus !== 'disable'
                                  ? () =>
                                      handleOpenDialog({
                                        mode: b.noticeStatus,
                                        type: 'notice',
                                        alertType: b.alertType,
                                        employeeId: a.employeeId,
                                        standardDate: a.standardDate,
                                        rest: b.remainedLeaves,
                                      })
                                  : undefined
                              }
                            />
                          </td>
                          <td className="two-data">
                            <Chip
                              className="cell-tag"
                              label={
                                b.usePlanStatus === 'disable'
                                  ? '미제출'
                                  : getLabel(b.usePlanStatus)
                              }
                              disabled={b.usePlanStatus === 'disable'}
                              size="sm"
                              theme={getTheme(b.usePlanStatus)}
                              onClick={() =>
                                handleOpenDialog({
                                  mode: b.usePlanStatus,
                                  type: 'usePlan',
                                  alertType: b.alertType,
                                  employeeId: a.employeeId,
                                  standardDate: a.standardDate,
                                  rest: b.remainedLeaves,
                                })
                              }
                            />
                          </td>
                          <td className="two-data">
                            {b.planNotifyDateStart !== '9999-12-31'
                              ? planNotifyDate
                              : ''}
                          </td>
                          <td className="two-data">
                            <Chip
                              className="cell-tag"
                              label={getLabel(b.planNotifyStatus)}
                              disabled={b.planNotifyStatus === 'disable'}
                              size="sm"
                              theme={getTheme(b.planNotifyStatus)}
                              onClick={() =>
                                handleOpenDialog({
                                  mode: b.planNotifyStatus,
                                  type: 'planNotify',
                                  alertType: b.alertType,
                                  employeeId: a.employeeId,
                                  standardDate: a.standardDate,
                                  rest: b.remainedLeaves,
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    } else {
                      rowData = (
                        <tr className="one-row" key={key}>
                          <td className="two-data">
                            {b.alertType !== 0
                              ? `개정${b.alertType}차`
                              : '일반연차'}
                          </td>
                          <td className="two-data">{rest}</td>
                          <td className="two-data">{noticeDate}</td>
                          <td className="two-data">
                            <Chip
                              className="cell-tag"
                              label={getLabel(b.noticeStatus)}
                              disabled={b.noticeStatus === 'disable'}
                              size="sm"
                              theme={getTheme(b.noticeStatus)}
                              onClick={() =>
                                handleOpenDialog({
                                  mode: b.noticeStatus,
                                  type: 'notice',
                                  alertType: b.alertType,
                                  employeeId: a.employeeId,
                                  standardDate: a.standardDate,
                                  rest: b.remainedLeaves,
                                })
                              }
                            />
                          </td>
                          <td className="two-data">
                            <Chip
                              className="cell-tag"
                              label={
                                b.usePlanStatus === 'disable'
                                  ? '미제출'
                                  : getLabel(b.usePlanStatus)
                              }
                              disabled={b.usePlanStatus === 'disable'}
                              size="sm"
                              theme={getTheme(b.usePlanStatus)}
                              onClick={() =>
                                handleOpenDialog({
                                  mode: b.usePlanStatus,
                                  type: 'usePlan',
                                  alertType: b.alertType,
                                  employeeId: a.employeeId,
                                  standardDate: a.standardDate,
                                  rest: b.remainedLeaves,
                                })
                              }
                            />
                          </td>
                          <td className="two-data">
                            {b.planNotifyDateStart !== '9999-12-31'
                              ? planNotifyDate
                              : ''}
                          </td>
                          <td className="two-data">
                            <Chip
                              className="cell-tag"
                              label={getLabel(b.planNotifyStatus)}
                              disabled={b.planNotifyStatus === 'disable'}
                              size="sm"
                              theme={getTheme(b.planNotifyStatus)}
                              onClick={() =>
                                handleOpenDialog({
                                  mode: b.planNotifyStatus,
                                  type: 'planNotify',
                                  alertType: b.alertType,
                                  employeeId: a.employeeId,
                                  standardDate: a.standardDate,
                                  rest: b.remainedLeaves,
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    }
                    return rowData;
                  });
                })}
              </tbody>
            </table>
          </div>
          <EuiToolbar className="grid-toolbar">
            <>
              {checkedCount === 0 ? (
                <EuiToolbar.Left>{undefined}</EuiToolbar.Left>
              ) : (
                <EuiToolbar.Left>
                  <Button
                    className="action-close"
                    text="취소"
                    iconType
                    icon="close"
                    onClick={() => handleCancelChecked()}
                  />
                  <span className="selected-count">
                    <em>{checkedCount}</em> <span>개 선택됨</span>
                  </span>
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={handleBulkSend}
                    text="촉진 일괄발송"
                    variant="contained"
                    icon="send"
                  />
                </EuiToolbar.Left>
              )}
              <SelectField
                className="rows"
                data={pageCountOptions}
                value={`${queryParams.rowsPerPage ?? '15'}`}
                onChange={handleChangeRowsLength}
                size="sm"
              />
              <EuiToolbar.Right>
                {totalCount ? (
                  <Pagination
                    no={queryParams.pageNo ?? 1}
                    rows={queryParams.rowsPerPage ?? 15}
                    count={totalCount}
                    onPrev={handlePrev}
                    onNext={handleNext}
                  />
                ) : null}
              </EuiToolbar.Right>
            </>
          </EuiToolbar>
        </div>
      </EuiBody>
      {renderDialog()}
      {state.loading && <Loading />}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceSendNoticeContainer;
