import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../components/alert/DeleteConfirmation';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../components/split/SplitUnselected';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import ChangeOrder from '../../../../groupware-webapp/pages/popup/ChangeOrder';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import {
  folderActions,
  itemActions,
  ResourceFolderItem,
  ResourceItem,
} from '../../../stores/folders';
import ResourceFolderContentList from './ResourceFolderContentList';
import ResourceFolderContentView from './ResourceFolderContentView';
import ResourceFolderEditDrawer from './ResourceFolderEditDrawer';
import ResourceFolderItemContentView from './ResourceFolderItemContentView';
import ResourceFolderItemEditDrawer from './ResourceFolderItemEditDrawer';

function ResourceFolderContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/*/:selectedId$base62',
    props.pathname,
  );
  const queryParams = getQueryParams(props.search);

  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.list,
  );
  const item = useSelector(
    (state: RootState) => state.resource.folder.items.view,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'setting');

  const [state, setState] = useState<{ filter: string }>({ filter: '' });

  /** 트리 아이템 생성. */
  const treeItems = useMemo(() => {
    const result = [
      ...folders.map((a) => {
        return {
          id: a.id,
          parentId: a.parentId,
          text: a.name,
          strings: hangul.d(a.name),
          icon: 'folder' as const,
        };
      }),
      ...items
        .filter(({ seq }) => seq !== 0)
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1)
        .map((a) => {
          return {
            id: a.id,
            parentId: a.folderId,
            text: a.name,
            strings: hangul.d(a.name),
            // eslint-disable-next-line prettier/prettier
            icon: a.useApprove ? 'document-check' as const : 'document-clock' as const,
          };
        }),
    ];
    return result;
  }, [folders, items]);

  const handleFilterChange = (filter: string) => {
    setState((prevState) => ({ ...prevState, filter }));
  };

  /** 트리 아이템 선택 */
  const handleTreeItemClick = (id: number) => {
    const treeItem = treeItems.find((a) => a.id === id);
    const route = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/${b62(id)}`,
    };
    if (treeItem?.icon === 'folder') dispatch(sessionActions.setRoute(route));
    if (treeItem?.icon !== 'folder') dispatch(itemActions.view({ id, route }));
  };

  const handleAction = (code: string) => {
    if (code === 'folder/create')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'create' }));
    if (code === 'folder/update')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'update' }));
    if (code === 'folder/delete')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'delete' }));
    if (code === 'folder/sort')
      dispatch(
        sessionActions.setDrawer({ type: 'folderSort', mode: 'update' }),
      );

    if (code === 'item/create')
      dispatch(sessionActions.setDrawer({ type: 'item', mode: 'create' }));
    if (code === 'item/update')
      dispatch(sessionActions.setDrawer({ type: 'item', mode: 'update' }));
    if (code === 'item/delete')
      dispatch(sessionActions.setDrawer({ type: 'item', mode: 'delete' }));
    if (code === 'item/sort')
      dispatch(sessionActions.setDrawer({ type: 'itemSort', mode: 'update' }));
  };

  /** 폴더 저장(수정) */
  const handleFolderSave = (arg: {
    id: number;
    parentId: number;
    name: string;
    description: string;
  }) => {
    const { id, parentId, name, description } = arg;
    // 등록인 경우.
    if (id === 0) {
      const relayLocation = {
        pathname: getPathMap('/*/*/*:id', props.pathname),
        search: '',
      };
      dispatch(
        folderActions.create({ parentId, name, description, relayLocation }),
      );
    }
    // 수정인 경우.
    else {
      const route = { pathname: props.pathname, search: '' };
      const updateAt = folders.find((a) => a.id === id)?.updateAt;
      if (!updateAt) return;
      const data = {
        id,
        parentId,
        name,
        description,
        updateAt,
      };
      dispatch(folderActions.update({ data, route }));
    }
  };

  /** 폴더 삭제. */
  const handleDelete = (folder: ResourceFolderItem) => {
    const route = {
      pathname: getPathMap('/*/*/*:id', props.pathname),
      search: '',
    };
    dispatch(
      folderActions.delete({
        id: folder.id,
        updateAt: folder.updateAt,
        route,
      }),
    );
  };

  /** 폴더 순서 변경. */
  const handleFolderSortChange = (sortList: { id: number }[]) => {
    const data = sortList.map((a, index) => {
      const updateAt = folders.find(({ id }) => id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: index + 1,
        updateAt,
      };
    });
    const route = { pathname: props.pathname, search: '' };
    dispatch(folderActions.sort({ data, route }));
  };

  /** 아이템 저장(수정) */
  const handleItemSave = (arg: {
    id?: number;
    folderId: number;
    name: string;
    status: boolean;
    useApprove: boolean;
    useRental: boolean;
    useTimeAvailable: boolean;
    availableFromTime: string;
    availableToTime: string;
    description: string;
    managers: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    users: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    exceptioners: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    image?: string;
  }) => {
    const route = {
      pathname: props.pathname,
      search: '',
    };
    if (arg.id && item) {
      item.managers.forEach((a) => {
        const index = arg.managers.findIndex(
          (b) => b.referenceId === a.referenceId && !b.lookupDeleteAt,
        );
        if (index !== -1) arg.managers.splice(index, 1);
      });
      item.users.forEach((a) => {
        const index = arg.users.findIndex(
          (b) => b.referenceId === a.referenceId && !b.lookupDeleteAt,
        );
        if (index !== -1) arg.users.splice(index, 1);
      });
      item.exceptioners.forEach((a) => {
        const index = arg.exceptioners.findIndex(
          (b) => b.referenceId === a.referenceId && !b.lookupDeleteAt,
        );
        if (index !== -1) arg.exceptioners.splice(index, 1);
      });
      const { useTimeAvailable, availableFromTime, availableToTime } = arg;
      const data = {
        ...arg,
        id: arg.id,
        companyId: item.companyId,
        updateAt: item.updateAt,
        name: arg.name === item.name ? undefined : arg.name,
        status: arg.status === item.status ? undefined : arg.status,
        useApprove:
          arg.useApprove === item.useApprove ? undefined : arg.useApprove,
        useRental: arg.useRental === item.useRental ? undefined : arg.useRental,
        availableFromTime: useTimeAvailable ? availableFromTime : undefined,
        availableToTime: useTimeAvailable ? availableToTime : undefined,
        description:
          arg.description === item.description ? undefined : arg.description,
      };
      dispatch(itemActions.update({ data, route }));
    } else {
      dispatch(itemActions.create({ data: arg, relayLocation: route }));
    }
  };

  /** 아이템 삭제. */
  const handleItemDelete = (data: ResourceItem) => {
    const route = {
      pathname: getPathMap('/*/*/*:id', props.pathname),
      search: '',
    };
    dispatch(
      itemActions.delete({
        id: data.id,
        updateAt: data.updateAt,
        route,
      }),
    );
  };

  /** 아이템 순서 변경. */
  const handleItemSortChange = (sortList: { id: number }[]) => {
    const data = sortList.map((a, index) => {
      const updateAt = items.find(({ id }) => id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: index + 1,
        updateAt,
      };
    });
    const route = { pathname: props.pathname, search: '' };
    dispatch(itemActions.sort({ data, route }));
  };

  const handleCloseView = () => {
    const route = {
      pathname: `${getPathMap('/*/*/*', props.pathname)}`,
    };
    dispatch(sessionActions.setRoute(route));
  };

  /** 드로워 닫기. */
  const handleDrawerClose = () => {
    dispatch(sessionActions.setDrawer());
  };

  const renderDrawer = () => {
    const { drawerType: type, drawerMode: mode } = queryParams;
    const treeFolders = [
      { id: 0, parentId: -1, text: '자원등록', icon: 'folder' as const },
      ...treeItems.filter((a) => a.icon === 'folder'),
    ];
    // 폴더 등록인 경우.
    if (type === 'folder' && mode === 'create') {
      const data = treeItems.find((a) => a.id === selectedId);
      const parentId = data?.icon === 'folder' ? data.id : data?.parentId;
      return (
        <ResourceFolderEditDrawer
          parentId={parentId}
          folders={treeFolders}
          onClose={handleDrawerClose}
          onSave={handleFolderSave}
        />
      );
    }
    // 폴더 수정인 경우.
    if (type === 'folder' && mode === 'update') {
      const folder = folders.find(({ id }) => id === selectedId);
      if (!folder) return null;
      return (
        <ResourceFolderEditDrawer
          folders={treeFolders.filter((a) => a.id !== folder.id)}
          folder={folder}
          onClose={handleDrawerClose}
          onSave={handleFolderSave}
        />
      );
    }
    // 폴더 삭제인 경우.
    if (type === 'folder' && mode === 'delete') {
      const folder = folders.find(({ id }) => id === selectedId);
      if (!folder) return null;
      return (
        <DeleteConfirmation
          onCancel={handleDrawerClose}
          onSubmit={() => handleDelete(folder)}
        >
          <strong>{`'${folder.name}'`}</strong>
          폴더를 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }
    // 폴더 순서변경인 경우.
    if (type === 'folderSort' && mode === 'update') {
      const folder = folders.find(({ id }) => id === selectedId);
      if (!folder) return null;
      const list = folders
        .filter((a) => a.parentId === folder.parentId)
        .map(({ id, name: label }) => ({ id, label }));
      return (
        <ChangeOrder
          title="순서 변경"
          list={list}
          onChange={handleFolderSortChange}
          onClose={handleDrawerClose}
        />
      );
    }

    // 아이템 등록인 경우.
    if (type === 'item' && mode === 'create') {
      const data = treeItems.find((a) => a.id === selectedId);
      const parentId = data?.icon === 'folder' ? data.id : data?.parentId;
      return (
        <ResourceFolderItemEditDrawer
          parentId={parentId}
          folders={treeItems.filter((a) => a.icon === 'folder')}
          onClose={handleDrawerClose}
          onSave={handleItemSave}
        />
      );
    }
    // 아이템 수정인 경우.
    if (type === 'item' && mode === 'update' && item) {
      return (
        <ResourceFolderItemEditDrawer
          folders={treeItems.filter((a) => a.icon === 'folder')}
          item={item}
          onClose={handleDrawerClose}
          onSave={handleItemSave}
        />
      );
    }
    // 아이템 삭제인 경우.
    if (type === 'item' && mode === 'delete' && item) {
      return (
        <DeleteConfirmation
          onCancel={handleDrawerClose}
          onSubmit={() => handleItemDelete(item)}
        >
          <strong>{`'${item.name}'`}</strong>
          자원을 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }
    // 아이템 순서변경인 경우.
    if (type === 'itemSort' && mode === 'update' && item) {
      const list = items
        .filter((a) => a.folderId === item.folderId)
        .map(({ id, name: label }) => ({ id, label }));
      return (
        <ChangeOrder
          title="순서 변경"
          list={list}
          onChange={handleItemSortChange}
          onClose={handleDrawerClose}
        />
      );
    }
    return null;
  };

  const renderContent = () => {
    const treeItem = treeItems.find(({ id }) => id === selectedId);
    if (treeItem === undefined) return <SplitUnselected />;
    if (treeItem.icon === 'folder') {
      const folder = folders.find(({ id }) => id === selectedId);
      if (!folder) return <SplitUnselected label="찾을 수 없습니다." />;
      const parentFolderName =
        folders.find(({ id }) => id === folder.parentId)?.name ?? '';
      return (
        <EuiSetting.Right onClose={handleCloseView}>
          <ResourceFolderContentView
            paths={getParentItems(treeItems, selectedId).map(
              ({ text }) => text,
            )}
            folder={folder}
            parentFolderName={parentFolderName}
            onAction={handleAction}
          />
        </EuiSetting.Right>
      );
    }
    if (!item) return <SplitUnselected label="찾을 수 없습니다." />;
    const folderName =
      folders.find(({ id }) => id === item.folderId)?.name ?? '';
    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <ResourceFolderItemContentView
          paths={getParentItems(treeItems, item.id).map(({ text }) => text)}
          folderName={folderName}
          item={item}
          onAction={handleAction}
        />
      </EuiSetting.Right>
    );
  };
  const title = categories.find((a) => a.id === 6002)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <ResourceFolderContentList
              items={treeItems}
              filter={state.filter}
              selectedId={selectedId}
              onClick={handleAction}
              onFilter={handleFilterChange}
              onSelect={handleTreeItemClick}
            />
          </EuiSetting.Left>
          {renderContent()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
    </>
  );
}

export default ResourceFolderContainer;
