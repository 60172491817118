/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { Language } from '../../../../../groupware-common/types';
import { getNameKey as gnk } from '../../../../../groupware-common/utils';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

const namespace = 'jobclass';

/**
 * 이름 키 값을 반환.
 * @param companyId 회사 아이디.
 * @param nameId 이름 아이디.
 * @param includeNs 네임스페이스 포함 여부.
 * @return 직원 이름 키
 */
function getNameKey(
  companyId: number,
  nameId: number,
  includeNs = true,
): string {
  return gnk(companyId, nameId, 'jobposition', includeNs ? namespace : '');
}

async function fetchLastUpdateAt() {
  return '';
}

async function fetchFirstId() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/jobposition/all`;
    const response = await axios.get<{ id: number }[]>(url, { headers });
    return response.data.filter((x) => x.id > 0)[0]?.id || null;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchList(): Promise<
  {
    companyId: number;
    id: number;
    seq: number;
    nameId: number;
    description: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/jobposition/all`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchView(id: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/jobposition?id=${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export interface JobPositionSaveArg {
  precedenceSeq: number;
  names: Array<{ language: Language; value: string }>;
  description: string;
}
export interface JobPositionUpdateArg {
  id: number;
  precedenceSeq: number;
  names?: Array<{ language: Language; value: string }>;
  description?: string;
  updateAt: string;
}

async function fetchSave(arg: JobPositionSaveArg): Promise<{
  id: number;
  companyId: number;
  seq: number;
  nameId: number;
  updateAt: string;
}> {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/directory/v1/jobposition`;
  const data = {
    precedenceSeq: arg.precedenceSeq,
    names: arg.names
      .filter((a) => a.value)
      .map((a) => ({ code: a.language, value: a.value })),
    description: arg.description,
  };

  const response = await axios.post(url, data, { headers });
  return response.data;
}

async function fetchUpdate(arg: JobPositionUpdateArg): Promise<{
  id: number;
  companyId: number;
  seq: number;
  nameId: number;
  updateAt: string;
}> {
  const { host, headers } = getApiConfig();
  const url = `${host}/api/directory/v1/jobposition`;
  const data = {
    id: arg.id,
    precedenceSeq: arg.precedenceSeq,
    names: arg.names
      ? arg.names.map((a) => ({ code: a.language, value: a.value }))
      : undefined,
    description: arg.description,
    updateAt: arg.updateAt,
  };

  const response = await axios.put(url, data, { headers });
  return response.data;
}

async function fetchDelete(id: number, seq: number, updateAt: string) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/jobposition`;
    const response = await axios.delete(url, {
      headers,
      data: {
        id,
        seq,
        updateAt,
      },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export const jobPositionApi = {
  namespace,
  getNameKey,
  lastUpdateAt: fetchLastUpdateAt,
  firstId: fetchFirstId,
  list: fetchList,
  view: fetchView,
  save: fetchSave,
  update: fetchUpdate,
  delete: fetchDelete,
};
