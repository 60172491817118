import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../..';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import Menu from '../../../../../components/menu/Menu';
import PostWrite from '../../../../../components/post/PostWrite';
import NavigationGuard from '../../../../../components/prompt/NavigationGuard';
import PromptMessage from '../../../../../components/prompt/PromptMessage';
import Radio from '../../../../../components/radio/Radio';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import Switch from '../../../../../components/switch/Switch';
import TextField from '../../../../../components/textfield/TextField';
import MemoizeDivElement from '../../../../../groupware-approval/pages/root/approval/common/components/MemoizeDivElement';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import { FileUploadProps } from '../../../../../groupware-common/types';
import { ApiError } from '../../../../../groupware-common/types/error';
import {
  getPathMap,
  getQueryParams,
  go,
  goBack,
  hangul,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { formApi } from '../../../../apis/board/v1/form';
import { boardActions } from '../../../../stores/board';
import {
  folderBoxActions,
  replaceRetentionPeriodToString,
} from '../../../../stores/folder';
import AddProgressAttachments from '../../../../../components/attachments/AddProgressAttachments';
import fileApi from '../../../../../groupware-common/apis/file/v1';

/** 업로드 파일 객체 생성 */
const createUploadFiles = (attachedFiles: FileUploadProps[]) => {
  return attachedFiles
    .filter(
      (a): a is { id: string; name: string; size: number; file: File } =>
        a.file !== undefined,
    )
    .map((a) => {
      return {
        id: parseInt(a.id, 10),
        file: a.file,
      };
    });
};
/** 첨부파일 객체 생성 */
const createAttachedFiles = (data: {
  attachedFiles: FileUploadProps[];
  boardId?: number;
  uploadPath: string;
}): {
  path: string;
  fileId: number;
  seq: number;
  name: string;
  size: number;
  delete?: boolean;
}[] => {
  const { attachedFiles, uploadPath } = data;
  return attachedFiles.map((a, i) => {
    return {
      path: a.file !== undefined ? uploadPath : '',
      fileId: parseInt(a.id, 10),
      seq: i + 1,
      name: a.name,
      size: a.size,
    };
  });
};

function BoardComposeContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const queryParams = getQueryParams(props.search);
  const dispatch = useAppDispatch();

  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const isPhone = display === 'phone';
  const currentTimeZone = useSelector(
    (state: RootState) => state.session.basicSetting.currentTimeZone,
  );
  const principal = useSelector((state: RootState) => state.session.principal);
  const employeeData = getDirectoryData({
    ...directory,
    ...principal,
  });
  const basic = useSelector(
    (state: RootState) => state.boards.preferences.basic,
  );
  const folders = useSelector((state: RootState) => state.boards.folder.list);
  const folder = useSelector((state: RootState) => state.boards.folder.item);
  const view = useSelector((state: RootState) => state.boards.board.view.data);
  /** 임시 저장된 문서 여부. */
  const isTemporarilyStored = queryParams.contentType === 'temp';

  /** 보존기간 리스트, 보존기간 값 가져오기. */
  const createRetentionPeriod = () => {
    // 보존기간
    let retentionPeriod = folder?.defaultRetentionPeriod ?? '';
    // 보존기간 리스트
    let retentionPeriodOption =
      folder && folder.retentionPeriods.length > 0
        ? folder.retentionPeriods.map(({ id: value }) => ({
            value,
            label: replaceRetentionPeriodToString(value),
          }))
        : [];

    if (view) {
      // 임시저장 글 수정 시 보존기간 정보 불러오기.
      if (isTemporarilyStored) {
        retentionPeriod = retentionPeriodOption.some(
          (a) => a.value === view.retentionPeriod,
        )
          ? view.retentionPeriod
          : folder?.defaultRetentionPeriod ?? '';
      }
      // 글 수정 시 보존기간 정보 불러오기. - 기존 글 보존 기간 있는 경우
      else if (view.retentionPeriod !== '') {
        if (retentionPeriodOption.length === 0) {
          retentionPeriod = view.retentionPeriod;
          retentionPeriodOption = [
            {
              value: '',
              label: '사용안함',
            },
            {
              value: view.retentionPeriod,
              label: replaceRetentionPeriodToString(view.retentionPeriod),
            },
          ];
        } else if (
          !retentionPeriodOption.some((a) => a.value === view.retentionPeriod)
        ) {
          retentionPeriod = view.retentionPeriod;
          retentionPeriodOption.push({
            value: view.retentionPeriod,
            label: replaceRetentionPeriodToString(view.retentionPeriod),
          });
        }
      }
      // 글 수정 시 보존기간 정보 불러오기. - 기존 글 보존 기간 없는 경우
      else if (view.retentionPeriod === '')
        if (retentionPeriodOption.length > 0) {
          retentionPeriod = '';
          retentionPeriodOption = [
            {
              value: '',
              label: '사용안함',
            },
            ...retentionPeriodOption,
          ];
        }
    }
    return { retentionPeriod, retentionPeriodOption };
  };

  const contentRef = useRef<HTMLDivElement>(null);

  const maxFileCount = basic.numberOfAttachments;
  const maxFileCapacity = basic.attachmentsCapacity;

  const treeFolders = useMemo(() => {
    const result = [
      ...folders
        .filter((a) => a.permissions?.useRead)
        .map((a) => ({
          id: a.id,
          seq: a.seq,
          parentId: a.parentId,
          text: a.name,
          strings: hangul.d(a.name),
          icon: 'folder' as const,
          disabled: !(a.permissions?.useWrite === true || a.isAdmin === true),
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];

    return result;
  }, [folders]);

  const categoryOption =
    folder && folder.option.useTitleClassification
      ? [
          { value: '0', label: '말머리 선택' },
          ...folder.titleClassifications.map((a) => ({
            value: a.id.toString(),
            label: a.titleClassification,
          })),
        ]
      : [{ value: '0', label: '말머리 선택' }];

  const { retentionPeriod: selectedRetentionPeriod, retentionPeriodOption } =
    createRetentionPeriod();

  const initialState = view
    ? {
        selectedId: view.folderId,
        category:
          categoryOption.find((a) => a.label === view.titleClassification)
            ?.value ?? categoryOption[0].value,
        subject: view.subject,
        contents: view.contents,

        tempFolderId: 0,
        tempFormId: 0,

        selectedFormId: folder?.forms.some(({ id }) => id === view.formId)
          ? view.formId
          : 0,
        selectedRetentionPeriod,
        security: view.isSecure,
        secretCode: '',
        notification: view.isAlarmSend,
        mailSend: false,

        notice: view.isNotified,
        noticeStart:
          view.notifyStartDate !== null && view.notifyStartDate !== '1000-01-01'
            ? timezoneDate(view.notifyStartDate)
            : timezoneDate(),
        noticeEnd:
          view.notifyEndDate !== null && view.notifyEndDate !== '9999-12-31'
            ? timezoneDate(view.notifyEndDate)
            : timezoneDate(),

        datePoint: undefined,
        dateOptions:
          (view.notifyStartDate === '1000-01-01' ||
            view.notifyStartDate === null) &&
          (view.notifyEndDate === '9999-12-31' || view.notifyEndDate === null)
            ? '상시'
            : '',
        dateType:
          view.notifyStartDate !== null &&
          view.notifyStartDate !== '1000-01-01' &&
          view.notifyEndDate !== null &&
          view.notifyEndDate !== '9999-12-31'
            ? `${dateTimeFormat(
                view.notifyStartDate,
                'yyyy-MM-DD',
              )} ~ ${dateTimeFormat(view.notifyEndDate, 'yyyy-MM-DD')}`
            : '상시',

        attachments: view.attachedFiles
          ? view.attachedFiles.map((a) => ({
              id: a.fileId.toString(),
              name: a.name,
              size: a.size,
              progress: 100,
              isUploaded: true,
            }))
          : [],
        uploadPath: `${Date.now()}`,

        formConfirmSelect: false,
        folderConfirmSelect: false,
        folderSelect: false,

        validation: '',
      }
    : {
        selectedId: folder?.id ?? 0,
        category: categoryOption[0].value,
        subject: '',
        contents: `<gw-fb-element data-role="control" data-option="default-editor" data-control="CONTROL/EDITOR" contenteditable="false" style="position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; width:100%;"><gw-fb-element-editor style="box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:100%; line-height:200px; text-align:center; background:#eee;" contenteditable="false">editor</gw-fb-element-editor></gw-fb-element>`,

        tempFolderId: 0,
        tempFormId: 0,

        selectedFormId: 0,
        selectedRetentionPeriod,
        security: false,
        secretCode: '',
        notification: false,
        mailSend: false,

        notice: false,
        noticeStart: timezoneDate(),
        noticeEnd: timezoneDate(),

        datePoint: undefined,
        dateOptions: '상시',
        dateType: '상시',

        attachments: [],
        uploadPath: `${Date.now()}`,

        formConfirmSelect: false,
        folderConfirmSelect: false,
        folderSelect: false,

        validation: '',
      };
  const [state, setState] = useState<{
    selectedId: number; // 폴더.
    category: string;
    subject: string;
    contents: string;

    tempFolderId: number; // 폴더 변경 전 저장 아이디.
    tempFormId: number; // 양식 변경 전 저장 아이디.

    selectedFormId: number; // 양식
    selectedRetentionPeriod: string; // 보존기간
    security: boolean; // 보안게시 여부.
    secretCode: string; // 보안 패스워드.
    notification: boolean; // 게시알림.
    mailSend: boolean; // 메일발송.

    notice: boolean; // 공지.
    noticeStart: Date | null; // 공지 시작일.
    noticeEnd: Date | null; // 공지 종료일.

    datePoint:
      | {
          x: number;
          y: number;
          width: number;
          height: number;
        }
      | undefined;
    dateOptions: string;
    dateType: string;

    attachments: FileUploadProps[];
    uploadPath: string;

    formConfirmSelect: boolean; // 양식 안내문구 선택 확인 창
    folderConfirmSelect: boolean; // 폴더 안내문구 선택 확인 창
    folderSelect: boolean; // 폴더 선택

    validation: string;
  }>(initialState);

  const [reload, setReload] = useState(true);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록 deprecated
  };
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  /** 새로고침 방지 이벤트 */
  useEffect(() => {
    setState(initialState);
    (() => {
      window.addEventListener('beforeunload', handlePrevClose);
      window.addEventListener('resize', handleResize);
    })();
    if (reload) {
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();

      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    return () => {
      window.removeEventListener('beforeunload', handlePrevClose);
      window.removeEventListener('resize', handleResize);
    };
  }, [queryParams.contentMode, queryParams.contentType]);

  // 폴더 선택 이벤트.
  const handleFolderSelectDialogClick = () => {
    setState((prev) => ({ ...prev, folderSelect: true }));
  };

  /** 폴더 변경 이벤트. */
  const handleFolderSelect = (id: number) => {
    if (id === 0) return;
    setState((prev) => ({
      ...prev,
      tempFolderId: id,
      folderConfirmSelect: true,
      folderSelect: false,
    }));
  };

  /** 폴더 변경 확인 이벤트. */
  const handleFolderSelectConfirm = () => {
    dispatch(
      folderBoxActions.folderView({ folderId: state.tempFolderId }),
    ).then((result) => {
      const { defaultRetentionPeriod } = result.payload as {
        defaultRetentionPeriod: string;
      };
      setState((prev) => ({
        ...prev,
        tempFolderId: 0,
        selectedId: state.tempFolderId,
        folderConfirmSelect: false,
        selectedRetentionPeriod: defaultRetentionPeriod,
        category: categoryOption[0].value,
        selectedFormId: 0,
        contents: `<gw-fb-element data-role="control" data-option="default-editor" data-control="CONTROL/EDITOR" contenteditable="false" style="position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; width:100%;"><gw-fb-element-editor style="box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:100%; line-height:200px; text-align:center; background:#eee;" contenteditable="false">editor</gw-fb-element-editor></gw-fb-element>`,
        security: false,
        secretCode: '',
      }));
    });
  };

  // 말머리 변경 이벤트.
  const handleChangeCategory = (value: string) => {
    setState((prev) => ({ ...prev, category: value }));
  };

  // 제목 변경 이벤트.
  const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      subject: event.target.value,
    }));
  };

  /** 양식 변경 이벤트. */
  const handleFormSelectChange = (value: string) => {
    setState((prev) => ({
      ...prev,
      tempFormId: Number(value),
      formConfirmSelect: true,
    }));
  };

  /** 양식 변경 확인 이벤트. */
  const handleFormSelectConfirm = () => {
    async function run() {
      const form = await formApi.form({ id: state.tempFormId });
      setState((prev) => ({
        ...prev,
        tempFormId: 0,
        selectedFormId: prev.tempFormId,
        formConfirmSelect: false,
        contents: form.contents,
      }));
    }
    if (state.tempFormId !== 0) run();
    else
      setState((prev) => ({
        ...prev,
        tempFormId: 0,
        selectedFormId: state.tempFormId,
        formConfirmSelect: false,
        contents: `<gw-fb-element data-role="control" data-option="default-editor" data-control="CONTROL/EDITOR" contenteditable="false" style="position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; width:100%;"><gw-fb-element-editor style="box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:100%; line-height:200px; text-align:center; background:#eee;" contenteditable="false">editor</gw-fb-element-editor></gw-fb-element>`,
      }));
  };

  /** 보존기간 변경 이벤트. */
  const handleChangeRetentionPeriod = (value: string) => {
    setState((prev) => ({
      ...prev,
      selectedRetentionPeriod: value,
    }));
  };

  /** 보안게시 설정. */
  const handleChangeSecurity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const security = event.target.checked;
    setState((prev) => ({
      ...prev,
      security,
      secretCode: security ? prev.secretCode : '',
    }));
  };

  /** 보안게시 비밀번호 설정 이벤트. */
  const handleChangeSecretCode = (secretCode: string) => {
    if (secretCode.length > 16) {
      const validation = '보안게시 비밀번호는 16자를 초과할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    setState((prev) => ({ ...prev, secretCode }));
  };

  /** 게시 알림 변경 이벤트. - TODO */
  // const handleChangeNotification = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setState((prev) => ({
  //     ...prev,
  //     notification: event.target.checked,
  //   }));
  // };

  /** 메일 발송 변경 이벤트. - TODO */
  // const handleChangeMailSend = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState((prev) => ({
  //     ...prev,
  //     mailSend: event.target.checked,
  //   }));
  // };

  /** 상단고정 설정 이벤트. */
  const handleChangeNotice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      notice: event.target.checked,
    }));
  };

  /** 상단고정 설정 드롭 메뉴 열기 이벤트. */
  const handleDropMenuClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    const { datePoint } = state;
    if (event !== undefined && datePoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        datePoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        datePoint: undefined,
      }));
    }
  };

  /** 상단고정 설정 드롭 메뉴 닫기 이벤트. */
  const handleDropMenuClose = () => {
    setState((prev) => ({
      ...prev,
      datePoint: undefined,
    }));
  };

  /** 상단고정 기간 타입 변경 이벤트. */
  const handleChangeDateRadioType = (value: string) => {
    setState((prev) => ({
      ...prev,
      dateOptions: value,
    }));
  };

  /** 상단고정 시작일 변경 */
  const handleChangeFixStartDate = (noticeStart: Date) => {
    setState((prev) => ({
      ...prev,
      noticeStart,
      noticeEnd:
        noticeStart !== null &&
        state.noticeEnd !== null &&
        noticeStart > state.noticeEnd
          ? noticeStart
          : prev.noticeEnd,
    }));
  };

  /** 상단고정 종료일 변경 */
  const handleChangeFixEndDate = (noticeEnd: Date) => {
    setState((prev) => ({ ...prev, noticeEnd }));
  };

  /** 상단고정 기간 타입 변경 후 확인 이벤트. */
  const handleChangeDateType = () => {
    const { noticeStart, noticeEnd } = state;
    if (state.dateOptions === '상시') {
      setState((prev) => ({
        ...prev,
        noticeStart: timezoneDate(),
        noticeEnd: timezoneDate(),
        dateType: '상시',
      }));
      handleDropMenuClose();
    } else {
      if (noticeStart === null || noticeEnd === null) {
        setState((prev) => ({
          ...prev,
          validation: '기간 입력 시 시작일, 종료일을 입력해주세요.',
        }));
        return;
      }
      setState((prev) => ({
        ...prev,
        dateType: `${dateFormat(noticeStart, 'yyyy-MM-DD')} ~ ${dateFormat(
          noticeEnd,
          'yyyy-MM-DD',
        )}`,
      }));
      handleDropMenuClose();
    }
  };

  /** 첨부파일 삭제 이벤트. */
  const handleFileRemove = (id: string) => {
    setState((prev) => ({
      ...prev,
      attachments: prev.attachments.filter((a) => a.id !== id),
    }));
  };

  /** 파일 업로드 진행상태 업데이트 */
  const handleUploadProgress = (event: ProgressEvent, id: string) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    setState((prev) => ({
      ...prev,
      attachments: prev.attachments.map((x) => {
        if (x.id === id) {
          return { ...x, progress };
        }
        return x;
      }),
    }));
  };

  /** 첨부파일 업로드 이벤트. */
  const handleFileUpload = (uploadingFiles: FileUploadProps[]) => {
    try {
      const files = createUploadFiles(uploadingFiles);
      setState((prev) => ({
        ...prev,
        attachments: [...prev.attachments, ...uploadingFiles],
      }));
      const fileAsyncFunc = files.map(async (x) => {
        await fileApi.uploadOne({
          path: state.uploadPath,
          file: x,
          module: 'board',
          onProgress: handleUploadProgress,
        });
      });

      Promise.all(fileAsyncFunc)
        .then(() => {
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              attachments: prev.attachments.map((attachedFile) => {
                const uploadingFileIndex = uploadingFiles.findIndex(
                  (x) => x.id === attachedFile.id,
                );
                if (uploadingFileIndex !== -1) {
                  return {
                    ...uploadingFiles[uploadingFileIndex],
                    progress: 100,
                    isUploaded: true,
                  };
                }
                return attachedFile;
              }),
            }));
          }, 100);
        })
        .catch((e) => {
          setState((prev) => ({
            ...prev,
            attachments: prev.attachments.map((attachedFile) => {
              const uploadingFileIndex = uploadingFiles.findIndex(
                (x) => x.id === attachedFile.id,
              );
              if (uploadingFileIndex !== -1) {
                return {
                  ...uploadingFiles[uploadingFileIndex],
                  progress: undefined,
                  isUploaded: false,
                  isFail: true,
                };
              }
              return attachedFile;
            }),
          }));
          dispatch(sessionActions.error(e as ApiError));
        });
    } catch (e) {
      dispatch(sessionActions.error(e as ApiError));
    }
  };

  /** 첨부파일 순서변경 이벤트. */
  const handleFileSortable = (arg: FileUploadProps[]) => {
    setState((prev) => ({ ...prev, attachments: [...arg] }));
  };

  /** 취소. */
  const handleCancel = () => {
    // 수정 데이터가 있는 경우. (문서 변경 or 임시보관 문서 작성)
    if (view) {
      goBack();
      return;
    }
    delete queryParams.contentMode;
    delete queryParams.contentType;
    go(props.pathname, queryParams);
  };

  /** 글쓰기 저장. */
  const handleSave = () => {
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (state.selectedId === 0) {
      const validation = '게시함을 선택해 주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (state.subject.trim() === '') {
      const validation = '제목을 입력해 주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.notice &&
      state.dateOptions === '' &&
      (state.noticeStart === null || state.noticeEnd === null)
    ) {
      const validation = '상단 고정 기간을 입력해 주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachments.length > 0 &&
      state.attachments.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = '파일 전송 중에는 문서를 저장할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachments.length > 0 &&
      state.attachments.findIndex((x) => x.isFail) > -1
    ) {
      const validation = '전송 실패한 파일을 삭제 후 다시 시도 해주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    // 보안 설정 게시글인 경우.
    if (state.security) {
      // 영어, 숫자, @, $, !, %, *, ?, &를 제외한 문자인 경우
      const denyPattern = /[^A-Za-z0-9@$!%*?&]/g;
      let validation = '';
      if (state.secretCode.trim() === '')
        validation = '보안게시 비밀번호를 입력해 주세요.';
      // 영어, 숫자, @, $, !, %, *, ?, &를 제외한 문자인 경우
      if (denyPattern.test(state.secretCode))
        validation =
          '보안게시 비밀번호는 영문자, 숫자, 특수문자(@, $, !, %, *, ?, &)만 입력 가능 합니다.';
      if (state.secretCode.trim().length < 4)
        validation = '보안게시 비밀번호는 4자 이상이어야 합니다.';
      if (validation !== '') {
        setState((prev) => ({ ...prev, validation }));
        return;
      }
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });
    const attachedFiles: {
      path: string;
      fileId: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachments,
      uploadPath: state.uploadPath,
    });

    const titleClassificationId =
      state.category === '0'
        ? '0'
        : categoryOption.find((a) => a.value === state.category)?.value ?? '0';

    let notifyStartDate: string | undefined;
    let notifyEndDate: string | undefined;
    if (state.notice && currentTimeZone !== 9) {
      if (state.noticeStart === null || state.noticeEnd === null) return;
      notifyStartDate = dateFormat(
        initialDate(state.noticeStart),
        'yyyy-MM-DD',
      );
      notifyEndDate = dateFormat(initialDate(state.noticeEnd), 'yyyy-MM-DD');
    }
    if (state.dateOptions === '상시' && state.notice) {
      notifyStartDate = '1000-01-01';
      notifyEndDate = '9999-12-31';
    }
    const pathname = `${getPathMap('/*/*', props.pathname)}`;
    delete queryParams.type;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });
    setReload(false);
    const param = {
      folderId: state.selectedId,
      titleClassificationId: parseInt(titleClassificationId, 10),
      formId: state.selectedFormId,
      subject: state.subject,
      contents,
      isSecure: state.security,
      securalPassword: state.security ? state.secretCode : undefined,
      isAlarmSend: state.notification,
      isNotified: state.notice,
      notifyStartDate,
      notifyEndDate,
      retentionPeriod: state.selectedRetentionPeriod,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
    };

    dispatch(boardActions.create({ data: param, location }));
  };

  /** 글쓰기 수정. */
  const handleUpdate = () => {
    if (!view) {
      const validation = '문서를 읽을 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (state.selectedId === 0) {
      const validation = '게시함을 선택해 주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (state.subject === '') {
      const validation = '제목을 입력해 주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.notice &&
      state.dateOptions === '' &&
      (state.noticeStart === null || state.noticeEnd === null)
    ) {
      const validation = '상단 고정 기간을 입력해 주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachments.length > 0 &&
      state.attachments.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = '파일 전송 중에는 문서를 저장할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachments.length > 0 &&
      state.attachments.findIndex((x) => x.isFail) > -1
    ) {
      const validation = '전송 실패한 파일을 삭제 후 다시 시도 해주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    // 보안 설정 게시글인 경우.
    if (state.security) {
      // 영어, 숫자, @, $, !, %, *, ?, &를 제외한 문자인 경우
      const denyPattern = /[^A-Za-z0-9@$!%*?&]/g;
      let validation = '';
      if (state.secretCode.trim() === '')
        validation = '보안게시 비밀번호를 입력해 주세요.';
      // 영어, 숫자, @, $, !, %, *, ?, &를 제외한 문자인 경우
      if (denyPattern.test(state.secretCode))
        validation =
          '보안게시 비밀번호는 영문자, 숫자, 특수문자(@, $, !, %, *, ?, &)만 입력 가능 합니다.';
      if (state.secretCode.trim().length < 4)
        validation = '보안게시 비밀번호는 4자 이상이어야 합니다.';
      if (validation !== '') {
        setState((prev) => ({ ...prev, validation }));
        return;
      }
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });
    const attachedFiles: {
      path: string;
      fileId: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachments,
      uploadPath: state.uploadPath,
    });
    if (view.attachedFiles) {
      view.attachedFiles.forEach((a) => {
        if (!state.attachments.find((b) => b.id === `${a.fileId}`)) {
          attachedFiles.push({
            path: '',
            fileId: a.fileId,
            seq: 0,
            name: '',
            size: 0,
            delete: true,
          });
        }
      });

      if (attachedFiles.length === view.attachedFiles.length) {
        let changed = false;
        for (let i = 0; i < attachedFiles.length; i += 1) {
          const a = attachedFiles[i];
          const b = view.attachedFiles[i];
          if (a.fileId !== b.fileId || a.delete === true) {
            changed = true;
            break;
          }
        }
        if (changed === false) attachedFiles.splice(0, attachedFiles.length);
      }
    }
    let notifyStartDate: string | undefined;
    let notifyEndDate: string | undefined;
    if (state.notice && currentTimeZone !== 9) {
      if (state.noticeStart === null || state.noticeEnd === null) return;
      notifyStartDate = dateFormat(
        initialDate(state.noticeStart),
        'yyyy-MM-DD',
      );
      notifyEndDate = dateFormat(initialDate(state.noticeEnd), 'yyyy-MM-DD');
    }
    const titleClassificationId =
      state.category === '0'
        ? '0'
        : categoryOption.find((a) => a.value === state.category)?.value ?? '0';
    if (state.dateOptions === '상시' && state.notice) {
      notifyStartDate = '1000-01-01';
      notifyEndDate = '9999-12-31';
    }

    let { pathname } = props;
    if (isTemporarilyStored) pathname = `${getPathMap('/*/*', pathname)}`;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });
    setReload(false);
    const param = {
      id: view.id,
      folderId: state.selectedId,
      titleClassificationId: parseInt(titleClassificationId, 10),
      formId: state.selectedFormId,
      subject: state.subject,
      contents,
      isSecure: state.security,
      securalPassword: state.security ? state.secretCode : undefined,
      isAlarmSend: state.notification,
      isNotified: state.notice,
      notifyStartDate,
      notifyEndDate,
      retentionPeriod: state.selectedRetentionPeriod,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
      updateAt: view.updateAt,
    };
    if (isTemporarilyStored) {
      dispatch(boardActions.tempToActive({ data: param, location }));
    } else {
      dispatch(boardActions.update({ data: param, location }));
    }
  };

  /** 임시저장. */
  const handleTemporary = () => {
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (state.security) {
      const validation = '보안게시 설정된 게시글은 임시저장 할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachments.length > 0 &&
      state.attachments.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = '파일 전송 중에는 문서를 저장할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachments.length > 0 &&
      state.attachments.findIndex((x) => x.isFail) > -1
    ) {
      const validation = '전송 실패한 파일을 삭제 후 다시 시도 해주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });
    const attachedFiles: {
      path: string;
      fileId: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachments,
      uploadPath: state.uploadPath,
    });
    if (isTemporarilyStored && view?.attachedFiles) {
      view.attachedFiles.forEach((a) => {
        if (!state.attachments.find((x) => x.id === `${a.fileId}`))
          attachedFiles.push({
            path: '',
            fileId: a.fileId,
            seq: 0,
            name: '',
            size: 0,
            delete: true,
          });
      });

      if (attachedFiles.length === view.attachedFiles.length) {
        let changed = false;
        for (let i = 0; i < attachedFiles.length; i += 1) {
          const a = attachedFiles[i];
          const b = view.attachedFiles[i];
          if (a.fileId !== b.fileId || a.delete === true) {
            changed = true;
            break;
          }
        }
        if (changed === false) attachedFiles.splice(0, attachedFiles.length);
      }
    }

    let notifyStartDate: string | undefined;
    let notifyEndDate: string | undefined;
    if (state.notice && currentTimeZone !== 9) {
      if (state.noticeStart === null || state.noticeEnd === null) return;
      notifyStartDate = dateFormat(
        initialDate(state.noticeStart),
        'yyyy-MM-DD',
      );
      notifyEndDate = dateFormat(initialDate(state.noticeEnd), 'yyyy-MM-DD');
    }
    const titleClassificationId =
      state.category === '0'
        ? '0'
        : categoryOption.find((a) => a.value === state.category)?.value ?? '0';
    if (state.dateOptions === '상시' && state.notice) {
      notifyStartDate = '1000-01-01';
      notifyEndDate = '9999-12-31';
    }

    const location = utils.getLocation({
      target: props,
      source: {
        pathname: `${getPathMap('/*/*', props.pathname)}`,
        search: props.search,
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });

    setReload(false);
    const param = {
      folderId: state.selectedId,
      titleClassificationId: parseInt(titleClassificationId, 10),
      formId: state.selectedFormId,
      subject: state.subject,
      contents,
      isAlarmSend: state.notification,
      isNotified: state.notice,
      notifyStartDate,
      notifyEndDate,
      retentionPeriod: state.selectedRetentionPeriod,
      attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
    };
    if (view)
      dispatch(
        boardActions.temporary({
          data: {
            id: view.id,
            ...param,
            updateAt: view.updateAt,
          },
          location,
        }),
      );
    else dispatch(boardActions.temporary({ data: param, location }));
  };

  const getMacro = (arg: { id: string }) => {
    const { id } = arg;
    const {
      companyName,
      organizationName,
      employeeName,
      jobPositionName,
      jobDutyName,
    } = employeeData;
    if (id === 'BOARD/TODAY') return dateTimeFormat(new Date(), 'yyyy-MM-DD');
    if (id === 'DIRECTORY/COMPANY') return companyName;
    if (id === 'BOARD/AUTHOR') return employeeName;
    if (id === 'BOARD/AUTHOR_ORGANIZATION') return organizationName;
    if (id === 'BOARD/AUTHOR_JOBPOSITION') return jobPositionName;
    if (id === 'BOARD/AUTHOR_JOBDUTY') return jobDutyName;
    if (id === 'BOARD/AUTHOR_JOBTITLE') {
      let jobtitle = '';
      if (jobPositionName !== '' && jobDutyName !== '')
        jobtitle = `${jobPositionName}/${jobDutyName}`;
      else if (jobPositionName !== '' && jobDutyName === '')
        jobtitle = jobPositionName;
      else if (jobPositionName === '' && jobDutyName !== '')
        jobtitle = jobDutyName;
      return jobtitle;
    }
    return undefined;
  };

  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { contentMode } = queryParams;
      const { contents } = state;
      const { element } = arg;
      element.innerHTML = FormBuilder.createComposeHtml({
        html: contents,
        editorHeight: '100%',
        modify: contentMode === 'update' || isTemporarilyStored,
        getMacro,
      });
    },
    [queryParams.contentMode, state.contents, state.selectedId],
  );

  const renderContent = () => {
    const editorRegExr =
      /^<gw-fb-element[^>]+?data-control="CONTROL\/EDITOR"[^>]*>(.*?)<\/gw-fb-element>/;
    let editorClassName = 'editor-html';
    if (editorRegExr.test(state.contents)) editorClassName += ' no-overflow';
    return (
      <PostWrite name="posting" fullSize>
        <PostWrite.Toolbar onCancel={handleCancel}>
          {queryParams.contentMode === 'create' && (
            <>
              <Button text="저장" variant="contained" onClick={handleSave} />
              <Button
                text="임시저장"
                variant="outlined"
                onClick={handleTemporary}
              />
            </>
          )}
          {queryParams.contentMode === 'update' && (
            <Button text="저장" variant="contained" onClick={handleUpdate} />
          )}
        </PostWrite.Toolbar>
        <PostWrite.Body>
          <PostWrite.Content>
            <PostWrite.Item>
              {queryParams.contentMode !== 'update' && (
                <DropMenu
                  label="폴더 선택"
                  value={folder?.name ?? ''}
                  onClick={handleFolderSelectDialogClick}
                />
              )}
            </PostWrite.Item>
            <PostWrite.Title>
              <SelectField
                data={categoryOption}
                value={state.category}
                onChange={handleChangeCategory}
              />
              <TextField
                value={state.subject}
                placeholder="제목을 입력하세요"
                onChange={handleChangeSubject}
              />
            </PostWrite.Title>
            <PostWrite.Edit>
              <MemoizeDivElement
                ref={contentRef}
                className={editorClassName}
                onLoad={handleContentLoad}
              />
            </PostWrite.Edit>
          </PostWrite.Content>
          <PostWrite.Side>
            {folder?.forms !== undefined && folder?.forms.length > 0 && (
              <PostWrite.Item title="양식" direction="row">
                <SelectField
                  data={[
                    ...folder.forms.map((a) => {
                      return {
                        value: a.id.toString(),
                        label: a.name,
                      };
                    }),
                    { label: '사용안함', value: '0' },
                  ]}
                  value={state.selectedFormId.toString()}
                  onChange={handleFormSelectChange}
                />
              </PostWrite.Item>
            )}
            {retentionPeriodOption.length > 0 && (
              <PostWrite.Item title="보존기간" direction="row">
                <SelectField
                  data={retentionPeriodOption}
                  value={state.selectedRetentionPeriod}
                  onChange={handleChangeRetentionPeriod}
                />
              </PostWrite.Item>
            )}
            {folder?.option.useSecurePost && (
              <PostWrite.Item title="보안게시" direction="row">
                <div style={{ float: 'right' }}>
                  <Switch
                    checked={state.security}
                    onChange={handleChangeSecurity}
                  />
                </div>
                {state.security && (
                  <div
                    style={
                      isPhone && windowSize < 331
                        ? { width: 'min-content', paddingTop: '10px' }
                        : { paddingTop: '10px' }
                    }
                  >
                    <TextField
                      type="password"
                      width={150}
                      margin="10px 0 0 0"
                      value={state.secretCode}
                      onChange={(event) =>
                        handleChangeSecretCode(event.target.value)
                      }
                    />
                  </div>
                )}
              </PostWrite.Item>
            )}
            <PostWrite.Item title="상단고정" direction="row">
              <div style={{ float: 'right' }}>
                <Switch checked={state.notice} onChange={handleChangeNotice} />
              </div>
              {state.notice && (
                <div
                  style={
                    isPhone && windowSize < 331
                      ? { width: 'min-content' }
                      : undefined
                  }
                >
                  <DropMenu
                    style={{
                      width: 'fit-content',
                      fontWeight: 'normal',
                      marginTop: '10px',
                    }}
                    onClick={handleDropMenuClick}
                    pressed={state.datePoint !== undefined}
                    value={state.dateType}
                    label="기간"
                  />
                </div>
              )}
              {state.datePoint && (
                <Menu
                  containerClassname={isPhone ? 'none-overflow' : undefined}
                  overflow="overflow:visible !important"
                  className="ui-search-period-layer"
                  point={state.datePoint}
                  onClose={handleDropMenuClose}
                >
                  <fieldset className="eui-check-group column">
                    <div
                      className="group"
                      style={{
                        margin: '5px',
                        width: isPhone ? '270px' : undefined,
                      }}
                    >
                      <Radio
                        label="상시"
                        checked={state.dateOptions === '상시'}
                        onChange={() => handleChangeDateRadioType('상시')}
                      />
                      <Radio
                        label="기간"
                        checked={state.dateOptions === ''}
                        onChange={() => handleChangeDateRadioType('')}
                      >
                        <div
                          style={{
                            display: 'inline-flex',
                            width: '220px',
                            marginRight: '8px',
                          }}
                        >
                          <div
                            style={{
                              background: 'var(--fill-color)',
                              borderRadius: '4px',
                              lineHeight: 1,
                              padding: '0 16px',
                              height: '36px',
                            }}
                          >
                            <CustomDatePicker
                              placeholderText="연도.월.일"
                              dateFormat="yyyy-MM-dd"
                              selected={state.noticeStart}
                              onChange={(date: Date) =>
                                handleChangeFixStartDate(date)
                              }
                              startDate={state.noticeStart}
                              endDate={state.noticeEnd}
                              selectsStart
                            />
                          </div>
                          <div style={{ marginRight: '4px' }} />
                          <div
                            style={{
                              background: 'var(--fill-color)',
                              borderRadius: '4px',
                              lineHeight: 1,
                              padding: '0 16px',
                              height: '36px',
                            }}
                          >
                            <CustomDatePicker
                              placeholderText="연도.월.일"
                              dateFormat="yyyy-MM-dd"
                              selected={state.noticeEnd}
                              onChange={(date: Date) =>
                                handleChangeFixEndDate(date)
                              }
                              startDate={state.noticeStart}
                              endDate={state.noticeEnd}
                              minDate={state.noticeStart}
                              selectsEnd
                            />
                          </div>
                        </div>
                      </Radio>
                    </div>
                  </fieldset>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '8px 0',
                    }}
                  >
                    <Button
                      text="확인"
                      variant="contained"
                      onClick={handleChangeDateType}
                    />
                    <Button
                      text="취소"
                      variant="outlined"
                      onClick={handleDropMenuClose}
                    />
                  </div>
                </Menu>
              )}
            </PostWrite.Item>
            <PostWrite.Item>
              <AddProgressAttachments
                data={state.attachments}
                maxCount={maxFileCount}
                maxCapacity={maxFileCapacity}
                onRemove={handleFileRemove}
                onFileUpload={handleFileUpload}
                onSortable={handleFileSortable}
              />
            </PostWrite.Item>
          </PostWrite.Side>
        </PostWrite.Body>
      </PostWrite>
    );
  };

  const renderDialog = () => {
    if (state.folderSelect)
      return (
        <TreePicker
          title="폴더 선택"
          list={[
            {
              id: 0,
              parentId: -1,
              text: '게시함',
              icon: 'folder' as const,
            },
            ...treeFolders,
          ]}
          selectedId={state.selectedId}
          rootId={0}
          onSelected={(id) => handleFolderSelect(id)}
          onClose={() => setState((prev) => ({ ...prev, folderSelect: false }))}
        />
      );
    if (state.folderConfirmSelect)
      return (
        <PromptMessage
          onSubmit={handleFolderSelectConfirm}
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              tempFolderId: 0,
              folderConfirmSelect: false,
            }))
          }
        >
          폴더 변경 시 입력된 내용이 모두 사라집니다. 폴더를 변경하시겠습니까?
        </PromptMessage>
      );
    if (state.formConfirmSelect)
      return (
        <PromptMessage
          onSubmit={handleFormSelectConfirm}
          onCancel={() =>
            setState((prev) => ({
              ...prev,
              tempFormId: 0,
              formConfirmSelect: false,
            }))
          }
        >
          양식 선택 시 입력된 내용이 모두 사라집니다. 양식을 선택하시겠습니까?
        </PromptMessage>
      );

    return null;
  };

  return (
    <>
      {renderContent()}
      {renderDialog()}
      <FeedBack
        text={state.validation}
        onClose={() => setState((prev) => ({ ...prev, validation: '' }))}
      />
      <NavigationGuard
        pathname={props.pathname}
        search={props.search}
        reload={reload}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (reload) {
            return true;
          }
          return false;
        }}
      />
    </>
  );
}

export default BoardComposeContainer;
