import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import Button from '../../../../../components/button/Button';
import DataGrid, {
  DataGridColDef,
  DataGridMoreActionProps,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import EuiBody from '../../../../../components/layout/EuiBody';
import PostWrite from '../../../../../components/post/PostWrite';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import { ActionEventProps } from '../../../../../components/toolbarAction/ToolbarAction';
import { getQueryParams } from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import AttendanceCalendarSettingCodeDialog from './AttendanceCalendarSettingCodeDialog';

function AttendanceSettingCodeContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const modules = useSelector((state: RootState) => state.session.modules);
  const list = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );
  const view = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.view,
  );

  const [saveing, setSaveing] = useState(false);
  const [state, setState] = useState<{
    id: number;
    name: string;
    operationType: number;
    updateAt: string;
  }>({
    id: 0,
    name: '',
    operationType: 0,
    updateAt: '',
  });

  useEffect(() => {
    if (!view) {
      setState({
        id: 0,
        name: '',
        operationType: 0,
        updateAt: '',
      });
    } else {
      setState({
        id: view.id,
        name: view.name,
        operationType: view.operationType,
        updateAt: view.updateAt,
      });
    }
  }, [search]);

  const columns: DataGridColDef[] = [
    { field: 'name', label: '이름', minWidth: 158 },
    { field: 'operate', label: '구분', minWidth: 200 },
    { field: 'more', label: '', width: 100, align: 'right' },
  ];
  const rows: DataGridRowsProps<number> = list.map((a) => {
    let type = '없음';
    if (a.operationType === 1) type = '연차 차감';
    else if (a.operationType === 2) type = '대휴 차감';
    else if (a.operationType === 3) type = '대휴 발생';

    return [
      { type: 'subject' as const, value: a.name, id: a.id },
      { type: 'text' as const, value: type },
      { type: 'more', label: '', width: 100, id: a.id },
    ];
  });

  const defaultActions: ActionEventProps[] = modules.some(
    (a) => a.code === 'calendar',
  )
    ? [
        // eslint-disable-next-line prettier/prettier
        { code: 'create', label: '신규', type: 'icon', icon: 'plus-circle-fill' },
        // eslint-disable-next-line prettier/prettier
        { code: 'orderList', label: '순서변경', type: 'icon', icon: 'sort-amount' },
        // eslint-disable-next-line prettier/prettier
        { code: 'visibleCode', label: '캘린더', type: 'icon', icon: 'calendar-fill' },
      ]
    : [
        // eslint-disable-next-line prettier/prettier
        { code: 'create', label: '신규', type: 'icon', icon: 'plus-circle-fill' },
        // eslint-disable-next-line prettier/prettier
        { code: 'orderList', label: '순서변경', type: 'icon', icon: 'sort-amount' },
      ];
  const moreActions: DataGridMoreActionProps[] = [
    { code: 'delete', label: '삭제', icon: 'trash-full' },
  ];

  /** 대화상자 닫기. */
  const handleDialogClose = () => {
    dispatch(attendancePreferencesActions.attendanceCodeViewClear());
    dispatch(sessionActions.setDialog());
  };

  /** 드로워 닫기. */
  const handleDrawerClose = () => {
    dispatch(sessionActions.setDrawer());
  };

  /** 근태 이름 변경. */
  const handleChangeName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  /** 근태 구분 변경. */
  const handleChangeOperationType = (value: string) => {
    setState((prev) => ({
      ...prev,
      operationType: parseInt(value, 10),
    }));
  };

  /** 근태 코드 저장. */
  const handleAttendanceSave = () => {
    setSaveing(true);
    const route = {
      pathname,
      search: '',
      hash,
    };
    if (state.id === 0) {
      const data = {
        operationType: state.operationType,
        name: state.name,
      };
      dispatch(
        attendancePreferencesActions.attendanceCreate({ ...data, route }),
      ).then(() => {
        setSaveing(false);
      });
    } else {
      dispatch(
        attendancePreferencesActions.attendanceUpdate({ ...state, route }),
      ).then(() => {
        setSaveing(false);
      });
    }
  };

  /** 근태 코드 삭제. */
  const handleDeleteAttendanceCode = () => {
    const data = list.find((a) => a.id === queryParams.id);
    if (!data) return;
    const route = {
      pathname,
      search: '',
      hash,
    };

    dispatch(
      attendancePreferencesActions.attendanceDelete({
        id: data.id,
        updateAt: data.updateAt,
        route,
      }),
    );
  };

  /** 근태 코드 리스트 순서 변경. */
  const handleOrderList = (orderList: { id: number }[]) => {
    const param = orderList.map((a, index) => {
      const updateAt = list.find((b) => b.id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: index + 1,
        updateAt,
      };
    });
    const route = {
      pathname,
      search: '',
      hash,
    };
    dispatch(
      attendancePreferencesActions.attendanceUpdateSeq({ param, route }),
    );
  };

  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id: number;
  }) => {
    const { code, id } = arg;

    /** 근태코드 생성 대화상자 열기. */
    if (code === 'create') {
      dispatch(sessionActions.setDialog({ type: code }));
    }

    /** 근태코드 수정 대화상자 열기. */
    if (code === 'subject') {
      queryParams.dialogType = 'update';
      dispatch(
        attendancePreferencesActions.attendanceCodeView({
          id,
          route: { pathname, search: getQueryParams(queryParams), hash },
        }),
      );
    }

    /** 근태코드 리스트 순서변경 드로워 열기. */
    if (code === 'orderList') {
      dispatch(sessionActions.setDrawer({ type: code }));
    }

    /** 근태코드 삭제 대화상자 열기. */
    if (code === 'delete') {
      queryParams.id = id;
      queryParams.dialogType = 'delete';
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }

    /** 일정 근태 캘린더에서 사용할 근태코드 사용여부 변경 대화상자 열기. */
    if (code === 'visibleCode') {
      dispatch(sessionActions.setDialog({ type: code }));
    }
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;

    if (dialogType === 'create' || dialogType === 'update') {
      return (
        <Dialog size="sm" onClose={handleDialogClose}>
          <DialogHeader>
            <DialogTitle>근태 코드</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <PostWrite>
              <PostWrite.Item title="구분">
                <SelectField
                  data={[
                    { value: '0', label: '없음' },
                    { value: '1', label: '연차 차감' },
                    { value: '2', label: '대휴 차감' },
                    { value: '3', label: '대휴 발생' },
                  ]}
                  value={state.operationType.toString()}
                  onChange={handleChangeOperationType}
                />
              </PostWrite.Item>
              <PostWrite.Item title="이름">
                <TextField value={state.name} onChange={handleChangeName} />
              </PostWrite.Item>
            </PostWrite>
          </DialogBody>
          <DialogFooter>
            {!saveing && (
              <Button
                text="취소"
                variant="outlined"
                onClick={handleDialogClose}
              />
            )}
            <Button
              text="저장"
              variant="contained"
              onClick={handleAttendanceSave}
              loading={saveing}
            />
          </DialogFooter>
        </Dialog>
      );
    }

    if (dialogType === 'delete') {
      return (
        <DeleteConfirmation
          delete
          onCancel={handleDialogClose}
          onSubmit={handleDeleteAttendanceCode}
        >
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    if (dialogType === 'visibleCode')
      return <AttendanceCalendarSettingCodeDialog />;

    return null;
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;

    if (drawerType === 'orderList') {
      const items = list.map(({ id, name: label }) => ({ id, label }));
      return (
        <ChangeOrder
          title="순서변경"
          list={items}
          onChange={handleOrderList}
          onClose={handleDrawerClose}
        />
      );
    }
    return null;
  };

  return (
    <>
      <EuiBody>
        <DataGrid
          caption="근태 기본설정"
          columns={columns}
          rows={rows}
          defaultActions={defaultActions}
          moreActions={moreActions}
          onClick={handleAction}
        />
      </EuiBody>
      {renderDialog()}
      {renderDrawer()}
    </>
  );
}

export default AttendanceSettingCodeContainer;
