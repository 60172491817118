import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../groupware-webapp/app/store';
import { CacheEntity, CacheMeta } from '../../../../groupware-common/types';
import {
  PendingAction,
  RejectedAction,
} from '../../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
  thunkCondition,
} from '../../../../groupware-webapp/stores/utils';
import surrogateApprovalApi from '../../../apis/approval/v1/surrogate-approval';

/** 대결자 */
const name = 'approval/surrogate-approval';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

/** 대리 결재 지정자 항목 객체. */
type DesignatorItem = {
  companyId: number; // 대리 결재자 회사 아이디
  organizationId: number; // 대리 결재자 조직 아이디.
  designatorId: number; // 대리 결재 지정자 아이디.
  updateAt: string; // 수정 날짜.
};

interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requests: { id: string; type: string; arg: any }[];
  designators: CacheEntity<DesignatorItem[]>;
  approver: {
    companyId: number;
    id: number;
    useSurrogate: boolean;
    surrogateStartDate: string;
    surrogateEndDate: string;
    surrogateEmployeeId: number;
    surrogateOrganizationId: number;
    updateAt: string;
  };
}

const initialState: State = {
  requests: [],
  designators: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: [],
  },
  approver: {
    companyId: 0,
    id: 0,
    useSurrogate: false,
    surrogateStartDate: '1000-01-01',
    surrogateEndDate: '1000-01-01',
    surrogateEmployeeId: 0,
    surrogateOrganizationId: 0,
    updateAt: '1000-01-01',
  },
};

/**
 * 대리 결재자의 대리 결재 지정자 목록을 조회합니다.
 */
const findDesignators = createAsyncThunk(
  `${name}/findDesignators`,
  async (_: void, { getState, rejectWithValue }) => {
    try {
      // await sleep(300);
      // const timestamp = Date.now();
      let meta: CacheMeta | undefined;
      // let data: { items: SurrogateItem[] } | undefined;

      const { companyId, employeeId: surrogaterId } = (getState() as RootState)
        .session.principal;

      // const data = [
      //   {
      //     companyId: 5001, // 대리 결재자 회사 아이디
      //     organizationId: 10005, // 대리 결재자 조직 아이디.
      //     designatorId: 20019, // 대리 결재 지정자 아이디.
      //     updateAt: '1000-01-01', // 수정 날짜.
      //   },
      //   {
      //     companyId: 5001, // 대리 결재자 회사 아이디
      //     organizationId: 10005, // 대리 결재자 조직 아이디.
      //     designatorId: 20027, // 대리 결재 지정자 아이디.
      //     updateAt: '1000-01-01', // 수정 날짜.
      //   },
      // ];

      const response = await surrogateApprovalApi.findDesignators({
        companyId,
        surrogaterId,
      });
      const data = response.map((a) => {
        return {
          companyId: a.companyId, // 대리 결재자 회사 아이디
          organizationId: a.surrogateOrganizationId, // 대리 결재자 조직 아이디.
          designatorId: a.id, // 대리 결재 지정자 아이디.
          updateAt: a.updateAt, // 수정 날짜.
        };
      });

      return { meta, data };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findDesignators.typePrefix, arg, getState);
    },
  },
);

/**
 * 대리 결재 지정자에 설정된 대리 결재 정보를 조회합니다.
 */
const find = createAsyncThunk(
  `${name}/find`,
  async (arg: { companyId: number; id: number }, { rejectWithValue }) => {
    try {
      const { companyId, id: employeeId } = arg;
      const response = await surrogateApprovalApi.find({
        companyId,
        employeeId,
      });
      return {
        companyId: response.companyId,
        id: response.id,
        useSurrogate: response.useSurrogate,
        surrogateStartDate: response.surrogateStartDate || '1000-01-01',
        surrogateEndDate: response.surrogateEndDate || '1000-01-01',
        surrogateEmployeeId: response.surrogateEmployeeId || 0,
        surrogateOrganizationId: response.surrogateOrganizationId || 0,
        updateAt: response.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/**
 * 대리 결재 지정자의 대리 결재 정보를 저장합니다.
 */
const save = createAsyncThunk(
  `${name}/save`,
  async (
    arg: {
      employeeId: number;
      useSurrogate: boolean;
      surrogateStartDate: string;
      surrogateEndDate: string;
      surrogateEmployeeId: number;
      surrogateOrganizationId: number;
      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await surrogateApprovalApi.save(arg);
      return {
        companyId: response.companyId,
        id: response.employeeId,
        useSurrogate: arg.useSurrogate,
        surrogateStartDate: arg.surrogateStartDate,
        surrogateEndDate: arg.surrogateEndDate,
        surrogateEmployeeId: arg.surrogateEmployeeId,
        surrogateOrganizationId: arg.surrogateOrganizationId,
        updateAt: response.updateAt,
      };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const surrogateApprovalSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findDesignators.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.designators.meta = payload.meta;
        if (payload?.data !== undefined) state.designators.data = payload.data;
      })
      .addCase(find.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.approver = payload;
      })
      .addCase(save.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.approver = payload;
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default surrogateApprovalSlice.reducer;

export const surrogateApprovalActions = {
  designators: findDesignators,
  find,
  save,
};
