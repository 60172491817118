import React from 'react';
import DocumentDrawer from '../pages/root/document/common/DocumentDrawer';

function DocumentLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search } = props;
  return (
    <>
      <DocumentDrawer pathname={pathname} search={search} />
    </>
  );
}

export default DocumentLocateDrawer;
