import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CacheEntity, CacheMeta } from '../../../groupware-common/types';
import { ApiError } from '../../../groupware-common/types/error';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { RootState } from '../../../groupware-webapp/app/store';
import { deepEqual, getQueryParams } from '../../../groupware-common/utils';
import approvalAttachedDocumentApi from '../../apis/approval/v1/attacheddocument';
import { ApprovalLineType } from '../../pages/common/dialogs/ApprovalLineDialogContainer';

/** 첨부 문서 */
const name = 'approval/attachedDocument';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface Item {
  affiliatedCompanyId?: number; // 관계사 회사 아이디(관계사가 아닌 경우 NULL 값)
  id: number; // 아이디
  workName: string; // 업무 이름
  formName: string; // 양식 이름
  importance: number; // 중요도 - 낮음:0, 보통:1, 높음:2
  no: string; // 문서번호
  subject: string; // 제목
  approvalLine: ApprovalLineType;
  views?: number; // 조회 수(직원별로 최대1 증가)
  opinions: number; // 의견 수
  comments: number; // 덧글 수
  createAt: string; // 생성 날짜
  updateAt: string; // 수정 날짜
  // 추가 항목
  checked: boolean;
}

interface View {
  /** 회사 아이디 */
  companyId: number;
  /** 아이디 */
  id: number;
  /** 수정 날짜 */
  updateAt: string;
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: CacheEntity<{ items: Item[]; totalCount: number; search: string }>;
  view: CacheEntity<View | null | undefined>;
  errors: ApiError[];
}

const initialState: State = {
  requests: [],
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [], totalCount: 0, search: '' },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },
  errors: [],
};

const list = createAsyncThunk(
  `${name}/list`,
  async (arg: { search: string }, { rejectWithValue }) => {
    let meta: CacheMeta | undefined;
    let data: { items: Item[]; totalCount: number; search: string } | undefined;
    try {
      const { search } = arg;
      const queryParams = getQueryParams(search);

      meta = { lastUpdateAt: '', timestamp: Date.now() };

      const searchWord =
        queryParams.searchWord?.trim() === ''
          ? undefined
          : queryParams.searchWord;
      const searchCode =
        searchWord === undefined ? undefined : queryParams.searchCode;
      const totalCount = approvalAttachedDocumentApi.fetchTotalCount({
        searchCode,
        searchWord,
      });
      const response = await approvalAttachedDocumentApi.fetchList({
        pageNo: queryParams.pageNo || 1,
        rowsPerPage: queryParams.rowsPerPage || 15,
        searchCode,
        searchWord,
      });
      const items = response.map((a) => {
        return {
          ...a,
          affiliatedCompanyId: a.affiliatedCompanyId,
          id: a.id,
          workName: a.workName, // 업무.
          no: a.no, // 문서번호.
          urgent: a.importance === 2, // 긴급 여부.
          subject: a.subject, // 제목.
          approvalLine: JSON.parse(a.approvalLine), // 결재선.
          opinions: a.opinions, // 의견 수.
          comments: a.comments, // 덧글 수.
          createAt: a.createAt, // 기안일.
          updateAt: a.updateAt,
          // 추가 항목
          checked: false,
        };
      });

      data = { items, totalCount: await totalCount, search };

      return { meta, data };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      const { requests } = (getState() as RootState).session;
      const type = list.typePrefix;
      return !requests.find((a) => a.type === type && deepEqual(a.arg, arg));
    },
  },
);

const attachedDocumentSlice = createSlice({
  name,
  initialState,
  reducers: {
    setListItemChecked(
      state,
      action: PayloadAction<{ id: number | 'all'; checked: boolean }>,
    ) {
      if (state.list.data) {
        if (action.payload.id === 'all') {
          state.list.data.items = state.list.data.items.map((a) => {
            if (a.checked === action.payload.checked) return a;
            return { ...a, checked: action.payload.checked };
          });
        } else {
          const index = state.list.data.items.findIndex(
            (x) => x.id === action.payload.id,
          );
          if (index > -1) {
            state.list.data.items[index] = {
              ...state.list.data.items[index],
              checked: action.payload.checked,
            };
          }
        }
      }
    },
    listClear(state) {
      state.list.data.items = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 폴더 배열에 상태 값에 할당.
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 폴더 배열에 상태 값에 할당.
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(list.rejected, (state, action) => {
        const { payload } = action;
        if (payload) state.errors = [...state.errors, payload as ApiError];
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);

          const { payload } = action;
          if (payload) state.errors = [...state.errors, payload as ApiError];
        }
      });
  },
});

export default attachedDocumentSlice.reducer;

export const attachedDocumentActions = {
  setListItemChecked: attachedDocumentSlice.actions.setListItemChecked,
  listClear: attachedDocumentSlice.actions.listClear,
  list,
};
