import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IconType } from '../../../groupware-common/types/icon';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import preferencesApi from '../../apis/systemlink/v1/preferences';

const name = 'systemlink/preferences';

interface CategoryItem {
  type: 'setting';
  id: number;
  name: string;
  icon: IconType;
}

/** 기본 폴더 */
const categories: CategoryItem[] = [
  { type: 'setting', id: 6001, name: '영림원시스템에버', icon: 'folder' },
  { type: 'setting', id: 6002, name: '양식관리', icon: 'folder' },
  { type: 'setting', id: 6003, name: '업무관리', icon: 'folder' },
];

interface State {
  basic: {
    updaterId: number;
    apiKey: string;
    clientId: string;
    clientSecret: string;
    encryptionKey: string;
    updateAt: string;
  };
  category: CategoryItem[];
  approval?: {
    use: boolean;
    updateAt: string;
  };
  directory?: {
    use: boolean;
    updateAt: string;
  };
  provider: {
    approval: string[];
    directory: string[];
  };
}

const initialState: State = {
  basic: {
    updaterId: 0,
    apiKey: '',
    clientId: '',
    clientSecret: '',
    encryptionKey: '',
    updateAt: '',
  },
  category: categories,
  approval: undefined,
  directory: undefined,
  provider: {
    approval: [],
    directory: [],
  },
};

/** 환경설정 조회. */
const findPreferences = createAsyncThunk(
  `${name}/findPreferences`,
  async (_: void, { rejectWithValue, getState }) => {
    try {
      const { modules, resource } = (getState() as RootState).session;
      const basic = await preferencesApi.findApiKey();
      let approval;
      let directory;
      if (resource === 'teams') {
        if (modules.find((a) => a.code === 'approval'))
          approval = await preferencesApi.findApproval();
        if (modules.find((a) => a.code === 'directory'))
          directory = await preferencesApi.findDirectory();
      } else {
        approval = await preferencesApi.findApproval();
        directory = await preferencesApi.findDirectory();
      }
      return { basic, approval, directory };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연동 결재 사용 여부 수정. */
const updateApproval = createAsyncThunk(
  `${name}/updateApproval`,
  async (
    arg: {
      use: boolean;
      updateAt: string;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const use = (getState() as RootState).systemlink.preferences.approval
        ?.use;
      if (use === undefined || arg.use === use) return undefined;

      const data = await preferencesApi.updateApproval(arg);
      return { updateAt: data.updateAt, use: arg.use };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연동 조직 사용 여부 수정. */
const updateDirectory = createAsyncThunk(
  `${name}/updateDirectory`,
  async (
    arg: {
      companyId: number;
      provider: string;
      use: boolean;
      updateAt: string;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const use = (getState() as RootState).systemlink.preferences.directory
        ?.use;
      if (use === undefined || arg.use === use) return undefined;

      const data = await preferencesApi.updateDirectory(arg);
      return { updateAt: data.updateAt, use: data.use };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연동 API 키 최초 생성. */
const saveApiKey = createAsyncThunk(
  `${name}/saveApiKey`,
  async (_: void, { rejectWithValue }) => {
    try {
      const data = await preferencesApi.createApiKey();
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 연동 API 키 재발급. */
const updateApiKey = createAsyncThunk(
  `${name}/updateApiKey`,
  async (
    arg: {
      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const data = await preferencesApi.updateApiKey(arg);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 결재 연동 제공자 조회 */
const providerList = createAsyncThunk(
  `${name}/provider`,
  async (_: void, { rejectWithValue }) => {
    try {
      const approvalList = await preferencesApi.approvalProviderList();
      const directoryList = await preferencesApi.directoryProviderList();
      return {
        approval: approvalList,
        directory: directoryList,
      };
    } catch (ex) {
      return {
        approval: [],
        directory: [],
      };
    }
  },
);

const preferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findPreferences.fulfilled, (state, { payload }) => {
        if (payload) {
          state.basic = payload.basic;
          state.approval = payload.approval;
          state.directory = payload.directory;
        }
      })
      .addCase(saveApiKey.fulfilled, (state, { payload }) => {
        if (payload) state.basic = payload;
      })
      .addCase(updateApproval.fulfilled, (state, { payload }) => {
        if (payload) {
          state.approval = payload;
        }
      })
      .addCase(updateDirectory.fulfilled, (state, { payload }) => {
        if (payload) {
          state.directory = payload;
        }
      })
      .addCase(updateApiKey.fulfilled, (state, { payload }) => {
        if (payload) {
          state.basic = {
            ...state.basic,
            ...payload,
          };
        }
      })
      .addCase(providerList.fulfilled, (state, { payload }) => {
        if (payload) state.provider = payload;
      });
  },
});

export default preferencesSlice.reducer;

export const preferencesActions = {
  findPreferences,

  updateApproval,
  updateDirectory,

  saveApiKey,
  updateApiKey,

  providerList,
};
