import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/** 파일 업로드. */
async function upload(
  path: string,
  files: { id: number; file: File }[],
  onProgress?: (event: unknown) => void,
): Promise<boolean> {
  try {
    const formData = new FormData();
    files.forEach(({ id, file }) => {
      formData.append('files', file, `${id}`);
    });

    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/file/${path}`;
    const response = await axios.post(url, formData, {
      headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      onUploadProgress: onProgress,
    });
    return response.status === 201;
  } catch (e) {
    throw apiError(e);
  }
}

/** 파일 다운로드. */
async function download(
  id: number,
  fileId: number,
  versionseq: number,
): Promise<Blob> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/common/attachedfile/download?id=${id}&fileId=${fileId}&versionseq=${versionseq}`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 파일 모두 다운로드. */
async function downloadAll(id: number, versionseq: number): Promise<Blob> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/common/attachedfile/download/all?id=${id}&versionseq=${versionseq}`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, { headers, responseType });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 본문(html) 다운로드. */
async function htmlDownload(arg: number[]): Promise<Blob> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/download`;
    const responseType = 'blob';
    const response = await axios.get<Blob>(url, {
      headers,
      responseType,
      params: { data: arg },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function print(id: number) {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/document/v1/post/history/print?ids=${id}`;
    await axios.post(url, {}, { headers });
    return;
  } catch (e) {
    throw apiError(e);
  }
}

export const documentsFileApi = {
  upload,
  download,
  downloadAll,

  htmlDownload,
};

export const documentsHistoryApi = {
  print,
};
