import React from 'react';

function SplitUnselected(props: { label?: string }): JSX.Element {
  return (
    <div className="ui-split-unselected">
      {props.label || '선택된 항목이 없습니다'}
    </div>
  );
}

export default SplitUnselected;
