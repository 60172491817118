import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Button from '../../../../../components/button/Button';
import Menu from '../../../../../components/menu/Menu';
import MenuItem from '../../../../../components/menu/MenuItem';

function ComponentMenuItem(): JSX.Element {
  const [state, setState] = useState<{
    visible: boolean;
    point: { x: number; y: number; width: number; height: number };
  }>({
    visible: false,
    point: { x: 0, y: 0, width: 0, height: 0 },
  });

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.visible) handleCloseMenu();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        visible: true,
        point: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  const handleCloseMenu = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  return (
    <ComponentSampleUI.Content title="Basic menu">
      <Button text="액션" onClick={handleOpenMenu} />
      {state.visible && (
        <Menu point={state.point} onClose={handleCloseMenu}>
          <MenuItem label="Profile" />
          <MenuItem label="My account" />
          <MenuItem label="Logout" />
        </Menu>
      )}
    </ComponentSampleUI.Content>
  );
}

export default ComponentMenuItem;
