import React, { useEffect, useState } from 'react';
import Dialog from '../../../../components/dialog/Dialog';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../components/dialog/DialogFooter';
import Button from '../../../../components/button/Button';
import PostWrite from '../../../../components/post/PostWrite';
import TextField from '../../../../components/textfield/TextField';
import RadioGroup from '../../../../components/radio/RadioGroup';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import CheckboxGroup from '../../../../components/checkbox/CheckboxGroup';
import DirectoryMenuTreeContainer from '../../../../groupware-directory/containers/DirectoryMenuTreeContainer'; // ChooseOrganizationChart 에서 변경.
import { DirectoryTreeItemArg } from '../../../../components/tree/DirectoryTree';
import { preferencesActions } from '../../../stores/approval/preferences';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import Loading from '../../../../components/loading/Loading';
import { ApprovalLineType } from '../../common/dialogs/ApprovalLineDialogContainer';
import FeedBack from '../../../../components/alert/FeedBack';

/** 결재 행위 유형 */
type Act =
  | 'none' // none
  | 'approval' // 승인
  | 'return' // 반려
  | 'back' // 전단계 반려
  | 'hold' // 보류
  | 'meet' // 대면
  | 'defer' // 후결
  | 'arbitraryDecision'; // 전결

// {
//   /** 결재자 유형 */
//   approverType: 'organization' | 'employee' | 'surrogater';
//   /** 결재 조직 아이디 */
//   organizationId?: number;
//   /** 대리 결재 지정자 아이디 */
//   designatorId?: number;
//   approverCompanyId: number;
//   approverId: number;
//   /** 후결 사용 여부 */
//   useDefer?: boolean;
//   onConfirm(arg: {
//     /** 결재자 유형 */
//     approverType: 'organization' | 'employee' | 'surrogater';
//     /** 대리 결재 지정자 아이디 */
//     designatorId?: number;
//     /** 결재 행위 값 */
//     act: Act;
//     /** 대면 사용자 배열 */
//     meetUsers?: {
//       companyId: number;
//       id: number;
//     }[];
//     /** 의견 */
//     opinion?: string;
//   }): void;
//   onClose(): void;
// }

/** 결재 대화 상자 컨테이너. */
function ApprovalDialogContainer(
  props: (
    | {
        receiptReturn?: boolean;
        approverType: 'organization';
        approvalLineGroupItemId: string;
        approverCompanyId: number;
        approverOrganizationId: number;

        approverId?: undefined;
        designatorId?: undefined;
        useArbitraryDecision?: undefined;
        hasHolding?: undefined;
        useDefer?: undefined;
        approvalLine?: undefined;
      }
    | {
        receiptReturn?: boolean;
        approverType: 'employee';
        approvalLineGroupItemId: string;
        approverCompanyId: number;
        approverOrganizationId?: undefined;
        approverId: number;
        useArbitraryDecision: boolean;
        hasHolding: boolean;
        useDefer?: boolean;
        approvalLine: ApprovalLineType;

        designatorId?: undefined;
      }
    | {
        receiptReturn?: boolean;
        approverType: 'surrogater';
        approvalLineGroupItemId: string;
        approverCompanyId: number;
        approverId: number;
        designatorId: number;

        approverOrganizationId?: undefined;
        useArbitraryDecision?: undefined;
        hasHolding?: undefined;
        useDefer?: undefined;
        approvalLine?: undefined;
      }
  ) & {
    onConfirm(arg: {
      /** 결재자 유형. */
      approverType: 'organization' | 'employee' | 'surrogater';
      /** 결재선 그룹 항목 아이디. */
      approvalLineGroupItemId: string;
      /** 결재자 부서 아이디. */
      organizationId?: number;
      /** 대리 결재 지정자 아이디. */
      designatorId?: number;
      /** 결재 행위 값. */
      act: Act;
      /** 대면 사용자 배열. */
      meetUsers?: {
        companyId: number;
        id: number;
      }[];
      /** 의견. */
      opinion?: string;
    }): void;
    onClose(): void;
  },
): JSX.Element {
  // console.log(`${ApprovalDialogContainer.name}.render(props)`, props);

  const dispatch = useAppDispatch();

  const { receiptReturn } = props;
  const [state, setState] = useState(() => {
    let meetUserSelectMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    const meetUsers: {
      companyId: number;
      id: number;
      name: string;
      organization: string;
      avatar: string;
    }[] = [];
    return {
      loading: true,
      /** 없음 및 후결은 버튼으로 표시되지 않아 제외 */
      buttons: [] as { value: Exclude<Act, 'none' | 'defer'>; label: string }[],
      previousApprovalLineGroupItemId: undefined as string | undefined,
      act: 'none' as Act,
      useDefer: props.useDefer,
      opinion: '',
      meetUserSelectMenuPoint,
      meetUsers,
      validation: '',
    };
  });

  useEffect(() => {
    let mount = true;

    const { approverType } = props;

    if (receiptReturn) {
      setState((prevState) => {
        return {
          ...prevState,
          loading: false,
          buttons: [{ value: 'return' as const, label: '반려' }],
          act: 'return' as const,
        };
      });
    } else {
      // 결재자 유형이 조직 또는 대결인 경우.
      if (approverType === 'organization' || approverType === 'surrogater') {
        setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            buttons: [
              { value: 'approval' as const, label: '승인' },
              { value: 'return' as const, label: '반려' },
            ],
            act: 'approval' as const,
          };
        });
      }
      // 결재자 유형이 직원인 경우.
      if (approverType === 'employee') {
        const {
          useArbitraryDecision,
          hasHolding,
          approvalLineGroupItemId,
          approvalLine,
        } = props;

        dispatch(preferencesActions.findBasic()).then((a) => {
          if (!mount) return;
          if (a.type.endsWith('rejected')) {
            return;
          }
          const payload = a.payload as {
            usePreviousApprovalCancel: boolean; // 이전 승인 취소(전단계 반려) 사용 여부.
            useApprovalCancel: boolean; // 결재 취소 사용 여부.
            useApprovalDefer: boolean; // 후결 사용 여부.
            useMeet: boolean; // 대면 사용 여부.
          };

          const buttons: {
            value: Exclude<Act, 'none' | 'defer'>;
            label: string;
          }[] = [];

          buttons.push({ value: 'approval' as const, label: '승인' });

          if (useArbitraryDecision)
            buttons.push({
              value: 'arbitraryDecision' as const,
              label: '전결',
            });

          if (!hasHolding)
            buttons.push({ value: 'hold' as const, label: '보류' });

          if (payload.useMeet)
            buttons.push({ value: 'meet' as const, label: '대면' });

          buttons.push({ value: 'return' as const, label: '반려' });

          let previousApprovalLineGroupItemId: string | undefined;

          if (payload.usePreviousApprovalCancel && approvalLine) {
            let hasPreviousApprovalCancel = true;
            let previousGroupId = '';
            let previousGroupItemId = '';

            for (let i = 0; i < approvalLine.groups.length; i += 1) {
              const group = approvalLine.groups[i];
              previousGroupId = group.id;
              let find = false;
              for (let j = 0; j < group.items.length; j += 1) {
                const item = group.items[j];
                if (item.id === approvalLineGroupItemId) {
                  if (group.type !== 'approval')
                    hasPreviousApprovalCancel = false;
                  else if (item.act === 'hold' || item.act === 'meet')
                    hasPreviousApprovalCancel = false;
                  find = true;
                  break;
                }
                previousGroupItemId = item.id;
              }
              if (find) break;
            }

            if (approvalLineGroupItemId === previousGroupItemId)
              hasPreviousApprovalCancel = false;

            const previousGroup = approvalLine.groups.find(
              ({ id }) => id === previousGroupId,
            );
            const previousGroupItem = previousGroup?.items.find(
              ({ id }) => id === previousGroupItemId,
            );

            if (
              previousGroup !== undefined &&
              previousGroupItem !== undefined
            ) {
              if (previousGroup.type !== 'approval')
                hasPreviousApprovalCancel = false;
            } else hasPreviousApprovalCancel = false;

            if (hasPreviousApprovalCancel) {
              previousApprovalLineGroupItemId = previousGroupItemId;
              buttons.push({ value: 'back' as const, label: '전단계반려' });
            }
          }

          setState((prevState) => {
            return {
              ...prevState,
              loading: false,
              buttons,
              previousApprovalLineGroupItemId,
              act: buttons.length > 0 ? buttons[0].value : 'none',
            };
          });
        });
      }
    }

    return () => {
      mount = false;
    };
  }, []);

  /** 결재 처리 값 변경. */
  const handleApprovalProcessValueChange = (act: Act) => {
    setState((prevState) => ({ ...prevState, act }));
  };

  /** 후결 변경. */
  const handleDeferChange = () => {
    setState((prevState) => ({ ...prevState, useDefer: !prevState.useDefer }));
  };

  /** 대면 사용자 선택 메뉴 토글. */
  const handleMenuUserSelectMenuToggle = (event?: React.MouseEvent) => {
    const { meetUserSelectMenuPoint: point } = state;
    if (event !== undefined && point === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        meetUserSelectMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        meetUserSelectMenuPoint: undefined,
      }));
    }
  };

  /** 대면 사용자 선택. */
  const handleMenuUserSelected = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;
    if (props.approverType !== 'employee') return;

    const { approverCompanyId, approverId } = props;
    if (
      approverCompanyId === extra.companyId &&
      approverId === extra.employeeId
    ) {
      setState((prevState) => ({
        ...prevState,
        validation: '본인을 대면 대상자로 지정할 수 없습니다.',
      }));
    } else {
      setState((prevState) => {
        const { meetUsers } = prevState;
        if (
          meetUsers.find(
            (a) => a.companyId === extra.companyId && a.id === extra.employeeId,
          ) !== undefined
        ) {
          return { ...prevState, validation: '이미 존재하는 사원입니다.' };
        }
        return {
          ...prevState,
          meetUsers: [
            ...meetUsers,
            {
              companyId: extra.companyId,
              id: extra.employeeId,
              name: extra.employeeName,
              organization: extra.organizationName,
              avatar: extra.avatar,
            },
          ],
        };
      });
    }
  };

  /** 대면 사용자 삭제. */
  const handleMeetUserDelete = (companyId: number, id: number) => {
    setState((prevState) => ({
      ...prevState,
      meetUsers: prevState.meetUsers.filter(
        (a) => !(a.companyId === companyId && a.id === id),
      ),
    }));
  };

  /** 의견 변경. */
  const handleOpinionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({ ...prevState, opinion: event.target.value }));
  };

  /** 스낵바 닫기. */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 확인. */
  const handleConfirm = () => {
    // console.log(`handleConfirm():state`, state);
    const { previousApprovalLineGroupItemId, act, meetUsers, opinion } = state;

    if (act === 'none') {
      const validation = '결재 처리 값을 선택 하셔야 합니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (act === 'meet' && meetUsers.length === 0) {
      const validation = '대면 대상을 선택 하셔야 합니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    if (act === 'back' && previousApprovalLineGroupItemId === undefined) {
      const validation = '이전 결재자를 찾을 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const {
      approverType,
      approvalLineGroupItemId,
      approverOrganizationId: organizationId,
      designatorId,
      onConfirm,
    } = props;

    const param = {
      receiptReturn,
      approverType,
      approvalLineGroupItemId:
        act === 'back' && previousApprovalLineGroupItemId
          ? previousApprovalLineGroupItemId
          : approvalLineGroupItemId,
      organizationId,
      designatorId,
      act: useDefer ? 'defer' : act,
      meetUsers: act === 'meet' ? meetUsers : undefined,
      opinion: opinion.trim() !== '' ? opinion : undefined,
    };
    // console.log(`param`, param);
    onConfirm(param);
  };

  const { useDefer, onClose } = props;
  const {
    loading,
    buttons,
    act,
    meetUserSelectMenuPoint,
    meetUsers,
    opinion,
    validation,
  } = state;

  return (
    <>
      <Dialog size="sm">
        <DialogHeader>
          <DialogTitle>{receiptReturn ? `반려` : `결재`}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <PostWrite>
            {loading && <Loading />}
            {!receiptReturn && (
              <>
                <PostWrite.Item title="결재처리">
                  {useDefer ? (
                    <PostWrite.Value>승인</PostWrite.Value>
                  ) : (
                    <RadioGroup
                      data={buttons}
                      value={act}
                      name="type"
                      onChange={handleApprovalProcessValueChange}
                    />
                  )}
                </PostWrite.Item>
                {act === 'meet' && (
                  <PostWrite.Item title="대면 대상지정">
                    <ChipGroup
                      add="대면 대상자 추가"
                      onAdd={handleMenuUserSelectMenuToggle}
                    >
                      {meetUserSelectMenuPoint && (
                        <DirectoryMenuTreeContainer
                          point={meetUserSelectMenuPoint}
                          onItemClick={handleMenuUserSelected}
                          onClose={handleMenuUserSelectMenuToggle}
                        />
                      )}
                      {meetUsers.map((a) => (
                        <Chip
                          key={a.id}
                          label={a.name}
                          etc={a.organization}
                          avatar={a.avatar}
                          onDelete={() =>
                            handleMeetUserDelete(a.companyId, a.id)
                          }
                        />
                      ))}
                    </ChipGroup>
                  </PostWrite.Item>
                )}
              </>
            )}
            {useDefer !== undefined && (
              <PostWrite.Item title="후결">
                <CheckboxGroup>
                  <Checkbox
                    label="다음 결재자 후결처리"
                    checked={useDefer}
                    onChange={handleDeferChange}
                  />
                </CheckboxGroup>
              </PostWrite.Item>
            )}
            <PostWrite.Item
              className="comment-item"
              title={receiptReturn ? '반려의견' : '결재의견'}
            >
              <TextField
                count
                maxLength={500}
                multiline
                rows="2"
                value={opinion}
                onChange={handleOpinionChange}
              />
            </PostWrite.Item>
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          <Button text="취소" onClick={onClose} />
          <Button
            text={receiptReturn ? '반려' : '결재'}
            variant="contained"
            loading={loading}
            onClick={handleConfirm}
          />
        </DialogFooter>
      </Dialog>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalDialogContainer;
