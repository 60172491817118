import React from 'react';
import { getParentItems } from '../../groupware-common/utils';
import { TreeItem } from '../../groupware-common/types';
import TreeNode from './TreeNode';
import { IconType } from '../../groupware-common/types/icon';

type Props<T> = {
  type?: 'full';
  disabledId?: T;
  selectedId?: T;
  rootId?: T;
  items: TreeItem<T>[];
  useCheck?: boolean;
  onItemClick?(id: T, parentId?: T, icon?: IconType): void;
  onItemCheck?(id: T, event: React.ChangeEvent<HTMLInputElement>): void;
};

class Tree<T extends number | string> extends React.Component<Props<T>> {
  expands: Array<T> = [];

  constructor(props: Props<T>) {
    super(props);

    if (
      props.selectedId !== undefined &&
      props.items.find((x) => x.id === props.selectedId) !== undefined
    ) {
      getParentItems(props.items, props.selectedId).forEach((x) => {
        this.expands.push(x.id);
      });
    } else {
      const items = props.items.filter((x) => x.parentId === 0);
      if (items.length === 1) this.expands.push(items[0].id);
    }

    this.getExpand = this.getExpand.bind(this);
    this.setExpand = this.setExpand.bind(this);
  }

  shouldComponentUpdate(nextProps: Readonly<Props<T>>): boolean {
    const { items } = this.props;
    // 아이템이 추가된 경우 트리 항목 넓힘 상태로 설정.
    if (
      nextProps.selectedId &&
      items.findIndex((x) => x.id === nextProps.selectedId) === -1
    ) {
      const id: T = nextProps.selectedId;
      getParentItems(nextProps.items, id).forEach((x) => {
        if (!this.getExpand(x.id)) this.setExpand(x.id);
      });
    }
    return true;
  }

  getExpand(id: T): boolean {
    return this.expands.indexOf(id) > -1;
  }

  setExpand(id: T): void {
    const index = this.expands.indexOf(id);
    if (index === -1) this.expands.push(id);
    else this.expands.splice(index, 1);
  }

  render(): JSX.Element {
    const {
      type,
      disabledId,
      selectedId,
      rootId,
      items,
      onItemClick,
      useCheck,
      onItemCheck,
    } = this.props;

    const selectedPath =
      selectedId !== undefined
        ? getParentItems(items, selectedId).map((x) => x.id)
        : [];
    const disabledPath =
      disabledId !== undefined
        ? getParentItems(items, disabledId).map((x) => x.id)
        : [];

    // console.log(`${Tree.name}.selectedPath`, selectedPath);
    // console.log(`${Tree.name}.disabledPath`, disabledPath);

    return (
      <ul className="eui-tree">
        {items
          .filter((item) =>
            rootId !== undefined
              ? item.id === rootId
              : item.parentId === 0 || item.parentId === '0',
          )
          .map((item) => {
            return (
              <TreeNode
                key={item.id}
                id={item.id}
                parentId={item.parentId}
                text={item.text}
                icon={item.icon}
                color={item.color}
                avatar={item.avatar}
                items={items}
                type={type}
                disabledPath={disabledPath}
                selectedPath={selectedPath}
                getExpand={this.getExpand}
                setExpand={this.setExpand}
                itemCheck={item.checked}
                onClick={onItemClick}
                onCheck={onItemCheck}
                useCheck={useCheck}
                disabled={item.disabled}
              >
                {item.children && item.children}
              </TreeNode>
            );
          })}
      </ul>
    );
  }
}

export default Tree;
