import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import {
  formActions,
  formFolderActions,
} from '../../../../stores/approval/form';
import {
  approvalWorkActions,
  approvalWorkFolderActions,
} from '../../../../stores/approval/work';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { preferencesActions } from '../../../../stores/approval/preferences';

function ApprovalWorkRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${ApprovalWorkRoute.name}.render`);

  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;

  useEffect(() => {
    let mount = true;
    Promise.all([
      dispatch(formFolderActions.list()),
      dispatch(formActions.all()),
      dispatch(approvalWorkFolderActions.list()),
      dispatch(approvalWorkActions.list({})),
      dispatch(preferencesActions.findApprovalType()),
    ]).then(() => {
      const route = { pathname, search, hash, clearErrors: true };
      if (mount) dispatch(sessionActions.setRoute(route));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default ApprovalWorkRoute;
