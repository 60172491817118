import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import MenuItem from '../../../../../components/menu/MenuItem';
import PostView from '../../../../../components/post/PostView';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { approvaluserPreferencesActions } from '../../../../stores/approval/userPreferences';
import ApprovalPreferencesHead from './ApprovalPreferencesHead';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import ApprovalLinePreferencesDialogContainer, {
  ApprovalLineType,
  SharePermissionType,
} from './ApprovalLinePreferencesDialogContainer';
import {
  SharePermissionFlat,
  WorkApprovalLineFlat,
} from '../../../adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import FeedBack from '../../../../../components/alert/FeedBack';

function ApprovalPreferencesApprovalLineContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  const list = useSelector(
    (state: RootState) =>
      state.approval2.userPreferences.userPreferencesApproval.list,
  );

  const listItem = [...list].sort(
    (a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1,
  );

  const view = useSelector(
    (state: RootState) =>
      state.approval2.userPreferences.userPreferencesApproval.view,
  );

  const [state, setState] = useState<{
    dialogType: string;
    validation: string;
  }>({
    dialogType: '',
    validation: '',
  });

  /** 트리 클릭 */
  const handleItemClick = (id: number) => {
    dispatch(
      approvaluserPreferencesActions.findPreferencesApprovalView({ id }),
    );
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const handleDialogCloseRight = () => {
    dispatch(approvaluserPreferencesActions.viewClear());
  };

  const handleDialogOpen = (
    dialogType: 'create' | 'edit' | 'delete' | 'sort',
  ) => {
    if (dialogType === 'edit' && view?.workChange) {
      setState((prevState) => ({
        ...prevState,
        validation: '업무가 수정되어 결재선을 변경할 수 없습니다.',
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      dialogType,
    }));
  };

  const handleDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      dialogType: '',
    }));
  };

  /** 결재선 저장 */
  const handleApprovalLineSave = (arg: {
    id?: number;
    name: string;
    workId: number;
    updateAt?: string;
    workUpdateAt: string;
    approvalLine: ApprovalLineType;
    referencePermission: SharePermissionType;
    viewPermission: SharePermissionType;
  }) => {
    const {
      id,
      name,
      workId,
      workUpdateAt,
      updateAt,
      approvalLine,
      referencePermission,
      viewPermission,
    } = arg;

    // 등록인 경우.
    if (id === undefined) {
      dispatch(
        approvaluserPreferencesActions.savePreferencesApprovalLine({
          approvalLineName: name,
          workId,
          workUpdateAt,
          approvalLine,
          referencePermission,
          viewPermission,
        }),
      );
      setState((prevState) => ({
        ...prevState,
        dialogType: '',
      }));
    }
    // 수정인 경우.
    else {
      dispatch(
        approvaluserPreferencesActions.savePreferencesApprovalLine({
          id,
          approvalLineName: name,
          workId,
          updateAt,
          workUpdateAt,
          approvalLine,
          referencePermission,
          viewPermission,
        }),
      );
      setState((prevState) => ({
        ...prevState,
        dialogType: '',
      }));
    }
  };

  const renderDialog = () => {
    const { dialogType } = state;

    if (dialogType === 'create') {
      return (
        <ApprovalLinePreferencesDialogContainer
          onSave={handleApprovalLineSave}
          onCancel={handleDialogClose}
        />
      );
    }

    if (state.dialogType === 'edit' && view !== undefined) {
      return (
        <ApprovalLinePreferencesDialogContainer
          type={'change' as const}
          id={view.id}
          name={view.name}
          workId={view.workId}
          workName={view.workName}
          workChange={view.workChange}
          approvalLine={view.approvalLine}
          referencePermission={view.referencePermission}
          workUpdateAt={view.workUpdateAt}
          viewPermission={view.viewPermission}
          updateAt={view.updateAt}
          onSave={handleApprovalLineSave}
          onCancel={handleDialogClose}
        />
      );
    }

    if (state.dialogType === 'delete') {
      if (view === undefined) return null;
      return (
        <DeleteConfirmation
          onSubmit={handleDeleteApproval}
          onCancel={handleDialogClose}
        >
          <strong>&apos;{view.name}&apos;</strong> 결재선을 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    if (state.dialogType === 'sort') {
      return (
        <ChangeOrder
          title="결재함 순서변경"
          list={listItem.map((a) => ({ id: a.id, label: a.name }))}
          onChange={(item) => {
            let i = 0;
            const arg: {
              id: number;
              seq: number;
              updateAt: string;
            }[] = item.map((a) => {
              const values = listItem.filter((b) => a.id === b.id);
              // eslint-disable-next-line no-return-assign
              return {
                id: values[0].id,
                seq: (i += 1),
                updateAt: values[0].updateAt,
              };
            });
            dispatch(
              approvaluserPreferencesActions.updatePreferencesApprovalLineOrder(
                arg,
              ),
            ).then((result) => {
              if ((result as { error?: string }).error === undefined) {
                setState((prevState) => ({ ...prevState, visible: '' }));
              }
            });
            handleDialogClose();
          }}
          onClose={handleDialogClose}
        />
      );
    }

    return null;
  };

  /** 삭제 버튼 클릭 */
  const handleDeleteApproval = () => {
    if (view === undefined) return;
    const arg = {
      id: view.id,
      updateAt: view.updateAt,
    };
    dispatch(
      approvaluserPreferencesActions.deletePreferencesApproval(arg),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const completeMessage = '결재업무를 삭제했습니다.';
        setState((prevState) => ({
          ...prevState,
          completeMessage,
          dialogType: '',
        }));
      }
    });
  };

  const renderContent = () => {
    if (view === null || view === undefined) return <SplitUnselected />;

    return (
      <>
        <EuiSetting.Header title="결재선 정보">
          <Button
            text="수정"
            iconType
            icon="edit"
            onClick={() => handleDialogOpen('edit')}
          />
          <Button
            text="삭제"
            iconType
            icon="trash-full"
            onClick={() => handleDialogOpen('delete')}
          />
        </EuiSetting.Header>
        <EuiSetting.Content>
          <PostView>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title="결재선명">
                  <PostView.CategoryValue value={view.name} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="업무명">
                  <PostView.CategoryValue value={view.workName} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="결재선">
                  <WorkApprovalLineFlat approvalLine={view.approvalLine} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="참조권">
                  {view.referencePermission && (
                    <SharePermissionFlat
                      items={view.referencePermission.groups
                        .map(({ items }) => {
                          if (items.length === 0) return [];
                          return items;
                        })
                        .flat()}
                    />
                  )}
                </PostView.CategoryItem>
                <PostView.CategoryItem title="조회권">
                  {view.viewPermission && (
                    <SharePermissionFlat
                      items={view.viewPermission.groups
                        .map(({ items }) => {
                          if (items.length === 0) return [];
                          return items;
                        })
                        .flat()}
                    />
                  )}
                </PostView.CategoryItem>
              </PostView.CategoryList>
            </PostView.Category>
          </PostView>
        </EuiSetting.Content>
      </>
    );
  };

  return (
    <>
      <ApprovalPreferencesHead
        pathname={pathname}
        search={search}
        hash={hash}
      />
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <EuiSetting.Header title="개인 결재선">
              <Button
                text="추가"
                iconType
                icon="plus"
                onClick={() => handleDialogOpen('create')}
              />
              <Button
                text="순서 변경"
                iconType
                icon="sort-amount"
                onClick={() => handleDialogOpen('sort')}
              />
            </EuiSetting.Header>
            <EuiSetting.Content>
              {listItem.map((x) => (
                <MenuItem
                  key={x.id}
                  label={x.name}
                  icon="progress-rate"
                  onClick={() => handleItemClick(x.id)}
                  selected={x.id === view?.id}
                  className={x.workChange ? 'changed' : undefined}
                />
              ))}
            </EuiSetting.Content>
          </EuiSetting.Left>
          {view?.id === undefined ? (
            <SplitUnselected label="업무를 선택하세요" />
          ) : (
            <EuiSetting.Right onClose={handleDialogCloseRight}>
              {renderContent()}
            </EuiSetting.Right>
          )}
        </EuiSetting>
        {renderDialog()}
      </EuiBody>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalPreferencesApprovalLineContainer;
