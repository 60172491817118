import { configureStore, Action } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
// import logger from 'redux-logger';
// import stater from '../middlewares/stater';
// import i18nResourcer from '../middlewares/i18nResourcer';
import locator from '../middlewares/locator';
import rootReducer from '../stores';

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(logger)
  // middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(stater)
  // middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(i18nResourcer)
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(locator),
  // middleware: (getDefaultMiddlewares) => getDefaultMiddlewares(),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../stores', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const newRootReducer = require('../stores').default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export function mapDispatch(dispatch: AppDispatch): { dispatch: AppDispatch } {
  return { dispatch };
}

export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>;

export type AppDispatchAbort = { abort(reason?: string): void };

export default store;
