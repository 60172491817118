import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import {
  Language,
  ListItem,
  LocateArg,
} from '../../../../../groupware-common/types';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  getText,
  hangul,
} from '../../../../../groupware-common/utils';
import JobDutyContentEdit, { SaveArg } from './JobDutyContentEdit';
import JobDutyContentList from './JobDutyContentList';
import JobDutyContentView from './JobDutyContentView';
import { IconType } from '../../../../../groupware-common/types/icon';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import { jobDutyActions } from '../../../../stores/directory/jobduty';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';

function JobDutyContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathParams = getPathParams(`/*/*/*/:id`, props.pathname);
  const propsId = b62(pathParams.id);

  const dispatch = useAppDispatch();
  const languages = useSelector((state: RootState) => state.session.languages);

  const useLink = useSelector(
    (state: RootState) =>
      state.directory.company.view.data?.useExternalDirectory,
  );

  const list = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data,
  );
  const view = useSelector((state: RootState) => {
    return propsId !== undefined
      ? state.directory.jobDuty.view.data
      : undefined;
  });

  const items = useMemo(() => {
    return list.items
      .filter((x) => x.id !== 0)
      .map<ListItem>((x) => {
        const text = getText(x.nameKey);
        return {
          id: x.id,
          text,
          icon: 'tie' as IconType,
          strings: hangul.d(text),
        };
      });
  }, [list]);

  const handleLink = useCallback(
    (id: number) => {
      dispatch(
        jobDutyActions.findView({
          id,
          route: {
            pathname: `${getPathMap('/*/*/*', props.pathname)}/${b62(id)}`,
          },
        }),
      );
    },
    [dispatch, props.pathname],
  );

  const handleCreate = () => {
    dispatch(sessionActions.setDrawer({ mode: 'create' }));
  };

  const handleRead = () => {
    dispatch(sessionActions.search());
  };

  const handleUpdate = () => {
    dispatch(sessionActions.setDrawer({ mode: 'update' }));
  };

  const handleDelete = () => {
    const { dialogMode } = getQueryParams(props.search);

    if (dialogMode === 'delete') {
      if (view) {
        dispatch(
          jobDutyActions.delete({
            id: view.id,
            seq: view.seq,
            updateAt: view.updateAt,
            route: {
              pathname: `${getPathMap('/*/*/*', props.pathname)}/{response_id}`,
            },
          }),
        );
      }
    } else dispatch(sessionActions.setDialog({ mode: 'delete' }));
  };

  const handleSaveUpdate = (arg: {
    parentId: number;
    names?: { language: Language; value: string }[];
    description?: string;
  }) => {
    if (view === null || view === undefined) return;

    const { id, updateAt } = view;
    const { parentId, names, description } = arg;
    const precedenceSeq = list.items.find((x) => x.id === parentId)?.seq || 0;
    const data: {
      id: number;
      precedenceSeq: number;
      names?: Array<{ language: Language; value: string }>;
      description?: string;
      updateAt: string;
    } & LocateArg = {
      id,
      precedenceSeq,
      names,
      description,
      updateAt,
      route: {
        pathname: `${getPathMap('/*/*/*', props.pathname)}/{response_id}`,
      },
    };
    dispatch(jobDutyActions.update(data));
  };

  const handleSave = ({ parentId, names, description }: SaveArg) => {
    const precedenceSeq = list.items.find((x) => x.id === parentId)?.seq || 0;

    dispatch(
      jobDutyActions.save({
        precedenceSeq,
        names,
        description,
        route: {
          pathname: `${getPathMap('/*/*/*', props.pathname)}/{response_id}`,
        },
      }),
    );
  };

  const renderList = () => {
    return (
      <JobDutyContentList
        id={propsId}
        items={items}
        onCreate={handleCreate}
        onItemClick={handleLink}
        useLink={useLink}
      />
    );
  };

  const renderView = () => {
    if (view === undefined) return null;
    if (view === null) return <span>404</span>;

    const { nameKey, description } = view;
    const parentId = list.items.find((x) => x.seq === view.seq - 1)?.id || 0;
    const parent = items.find((x) => x.id === parentId)?.text || '없음';
    const names = languages.map((language) => {
      const value = getText([nameKey, '미지정'], language);
      return { label: language, value };
    });

    return (
      <JobDutyContentView
        parent={parent}
        names={names}
        description={description}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        useLink={useLink}
      />
    );
  };

  const renderDrawer = () => {
    const { drawerMode } = getQueryParams(props.search);

    if (drawerMode === undefined) return null;

    const type = drawerMode === 'create' ? 'create' : 'update';

    const tempItems =
      type === 'update' ? items.filter((x) => x.id !== view?.id) : items;

    const options = [
      { label: getText('없음'), value: `${0}` },
      ...tempItems.map((x) => ({ label: x.text, value: `${x.id}` })),
    ];

    let parentId = 0;
    let names = languages.map((language) => ({ language, value: '' }));
    let description = '';

    if (drawerMode === 'create' && list.items.length > 0)
      parentId = list.items[list.items.length - 1].id;
    if (drawerMode === 'update' && view) {
      parentId = list.items.find((x) => x.seq === view.seq - 1)?.id || 0;

      const { nameKey } = view;
      names = names.map(({ language }) => {
        const value = getText([nameKey, ''], language);
        return { language, value };
      });

      description = view.description;

      return (
        <JobDutyContentEdit
          type={type}
          items={options}
          parentId={parentId}
          names={names}
          description={description}
          onUpdate={handleSaveUpdate}
          onClose={handleRead}
        />
      );
    }

    return (
      <JobDutyContentEdit
        type={type}
        items={options}
        parentId={parentId}
        names={names}
        description={description}
        onSave={handleSave}
        onClose={handleRead}
      />
    );
  };

  const renderDialog = () => {
    const { dialogMode } = getQueryParams(props.search);

    let result = null;

    if (dialogMode === 'delete') {
      result = (
        <DeleteConfirmation onSubmit={handleDelete} onCancel={handleRead}>
          <strong>
            &apos;{items.find((x) => x.id === propsId)?.text}&apos;
          </strong>{' '}
          직책를 정말 삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    return result;
  };

  const handleCloseView = () => {
    dispatch(
      sessionActions.setRoute({
        pathname: getPathMap('/*/*/*', props.pathname),
      }),
    );
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>직책</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {propsId === undefined ? (
            <SplitUnselected />
          ) : (
            <EuiSetting.Right onClose={handleCloseView}>
              {renderView()}
            </EuiSetting.Right>
          )}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default JobDutyContainer;
