import React, { useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import HyperLink from '../hyperLink/HyperLink';
import { IconType } from '../../groupware-common/types/icon';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import FeedBack from '../alert/FeedBack';
import Chip from '../chip/Chip';

function PostView(props: {
  name?: string;
  type?: 'full' | 'split';
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div
      className="eui-post-view"
      data-post-name={props.name}
      data-post-type={props.type}
    >
      {props.children}
    </div>
  );
}

function PostViewHead(props: { children: React.ReactNode }): JSX.Element {
  return <div className="view-header">{props.children}</div>;
}

function PostViewTitle(props: {
  children: React.ReactNode;
  chip?: {
    label: string;
    theme: 'primary' | 'success' | 'error' | 'warning' | 'cancel';
  };
}): JSX.Element {
  return (
    <div className="view-title-wrapper">
      <h1 className="view-title">{props.children}</h1>
      {props.chip && (
        <Chip
          className="view-title-chip"
          label={props.chip.label}
          size="xs"
          theme={props.chip.theme}
        />
      )}
    </div>
  );
}

function PostViewLabel(props: { children: React.ReactNode }): JSX.Element {
  return <div className="view-label">{props.children}</div>;
}

function PostViewBody(props: { children: React.ReactNode }): JSX.Element {
  return <div className="view-container">{props.children}</div>;
}

function PostViewInfo(props: {
  className?: string;
  row?: boolean;
  column?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'view-info';
  if (props.className) classname += ` ${props.className}`;
  if (props.row) classname += ' row';
  if (props.column) classname += ' column';

  return <div className={classname}>{props.children}</div>;
}

function PostViewInfoItem(props: {
  title: string;
  value: string | number;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}): JSX.Element {
  return (
    <dl>
      <dt>{props.title}</dt>
      <dd>
        {props.onClick !== undefined ? (
          <HyperLink onClick={props.onClick}>{props.value}</HyperLink>
        ) : (
          props.value
        )}
      </dd>
    </dl>
  );
}

function PostViewExcerptItem(props: {
  title: string;
  value: string | number;
}): JSX.Element {
  return (
    <div className="excerpt">
      <span className="title"> {props.title}</span>
      <span className="text">{props.value}</span>
    </div>
  );
}

function PostViewCategory(props: {
  type?: 'text' | 'icon';
  vertical?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  let classname = 'view-category';
  if (props.type) classname += ` category-${props.type}-type`;
  if (props.vertical) classname += ' category-vertical-type';
  return <div className={classname}>{props.children}</div>;
}

function PostViewCategoryList(props: {
  title?: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      {props.title && (
        <div className="category-title">
          <h4 className="title">{props.title}</h4>
        </div>
      )}
      <div className="category-list">{props.children}</div>
    </>
  );
}

function PostViewCategoryItem(props: {
  title: string;
  icon?: IconType;
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let className = 'category-item';
  if (props.className) className += ` ${props.className}`;
  return (
    <div className={className}>
      <div className="item-title">
        {props.icon !== undefined ? (
          <Icon icon={props.icon} label={props.title} tooltip={props.title} />
        ) : (
          props.title
        )}
      </div>
      <div className="item-content">{props.children}</div>
    </div>
  );
}

function PostViewCategoryItemValue(props: {
  value?: string | number;
  label?: string;
  link?: string;
  copy?: boolean;
  children?: React.ReactNode;
}): JSX.Element {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [alertMessage, setAlertMessage] = useState('');

  const handleCopy = () => {
    if (textRef.current === null) {
      setAlertMessage('복사할 값이 없습니다.');
      return;
    }
    window.navigator.clipboard.writeText(textRef.current.value);
    setAlertMessage('클립보드에 복사됨');
  };

  const setValue = () => {
    if (props.children !== undefined) {
      return <span className="value-text">{props.children}</span>;
    }
    if (props.link !== undefined) {
      return (
        <a
          href={props.link}
          rel="noreferrer"
          target="_blank"
          className="value-text link"
        >
          {props.value}
        </a>
      );
    }
    return <span className="value-text">{props.value}</span>;
  };

  return (
    <div className="item-value">
      {setValue()}
      {props.label && <em className="value-label">{props.label}</em>}
      {props.copy !== undefined && (
        <>
          <Button
            text="복사"
            iconType
            icon="copy"
            size="xs"
            className="value-copy"
            onClick={handleCopy}
            color="secondary"
          />
          <textarea
            ref={textRef}
            className="copy-textarea"
            defaultValue={props.value}
            readOnly
          />
          <FeedBack
            text={alertMessage}
            anchorOrigin="bottomLeft"
            onClose={() => setAlertMessage('')}
          />
        </>
      )}
    </div>
  );
}

function PostViewContent(props: { data: string }): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentHtmlRef = useRef<HTMLDivElement>(null);

  const [zoom, setZoom] = useState(true);

  /** 모바일 일 때 축소해서 보여주는 기능 */
  useLayoutEffect(() => {
    if (isMobile && contentRef.current && contentHtmlRef.current) {
      const contentRefWidth = contentRef.current.offsetWidth;
      const contentHtmlRefWidth = contentHtmlRef.current.scrollWidth;
      const contentHtmlRefHeight = contentHtmlRef.current.offsetHeight;

      if (contentRefWidth < contentHtmlRefWidth && zoom) {
        const scale = contentRefWidth / contentHtmlRefWidth;
        contentHtmlRef.current.style.transform = `scale(${scale})`;
        contentRef.current.style.height = `${contentHtmlRefHeight * scale}px`;
      } else {
        contentHtmlRef.current.removeAttribute('style');
        contentRef.current.removeAttribute('style');
      }
    }
  }, [zoom]);

  return (
    <div className="view-content">
      {isMobile && (
        <Button
          text={zoom ? '확대' : '축소'}
          iconType
          icon={zoom ? 'expand' : 'compress'}
          onClick={() => setZoom(!zoom)}
          color="secondary"
          className="zoom-toggle"
        />
      )}
      <div className="content-root" ref={contentRef}>
        <div
          className="root-text"
          dangerouslySetInnerHTML={{ __html: props.data }}
          ref={contentHtmlRef}
        />
      </div>
    </div>
  );
}

PostView.Head = PostViewHead;
PostView.Title = PostViewTitle;
PostView.Label = PostViewLabel;
PostView.Body = PostViewBody;
PostView.Info = PostViewInfo;
PostView.InfoItem = PostViewInfoItem;
PostView.ExcerptItem = PostViewExcerptItem;
PostView.Category = PostViewCategory;
PostView.CategoryList = PostViewCategoryList;
PostView.CategoryItem = PostViewCategoryItem;
PostView.CategoryValue = PostViewCategoryItemValue;
PostView.Content = PostViewContent;

export default PostView;
