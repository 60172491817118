import React, { useRef } from 'react';

function DialogBody(props: {
  noneOverFlow?: boolean;
  height?: number;
  loading?: boolean;
  children: React.ReactNode;
  scrollbar?: React.RefObject<HTMLDivElement>;
  className?: string;
}): JSX.Element {
  const style: React.CSSProperties | undefined = { height: props.height };
  let classname = 'dialog-content';
  if (props.className) classname += ` ${props.className}`;
  if (props.noneOverFlow) classname += ` none-overflow`;
  const rootRef = props.scrollbar
    ? props.scrollbar
    : useRef<HTMLDivElement>(null);
  return (
    <div className={classname} style={style} ref={rootRef}>
      {props.children}
      {props.loading && <div className="ui-inner-loading" />}
    </div>
  );
}

export default DialogBody;
