import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '../../../../../components/avatar/Avatar';
import EmptyData from '../../../../../components/data/EmptyData';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import DrawerList from '../../../../../components/drawer/DrawerList';
import DrawerToolbar from '../../../../../components/drawer/DrawerToolbar';
import Loading from '../../../../../components/loading/Loading';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import documentLogApi from '../../../../apis/document/v1/document-log';
import { IconType } from '../../../../../groupware-common/types/icon';
import Icon from '../../../../../components/icon/Icon';
import { RootState } from '../../../../../groupware-webapp/app/store';
import FileChipGroup from '../../../../../components/chip/FileChip';
import { dateTimeFormat } from '../../../../../groupware-common/utils/ui';

/** 프로퍼티 유효성 검사 */
const getPropertyIfExists = (
  detailsMap: Record<string, unknown> | undefined,
  propertyName: string,
): unknown | undefined => {
  if (detailsMap && propertyName in detailsMap) {
    return detailsMap[propertyName];
  }
  return undefined;
};

function DocumentLogDrawer(props: { documentId: number; onClose(): void }) {
  const { documentId: id, onClose } = props;

  const scrollbar = useRef<HTMLDivElement>(null);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);

  const [state, setState] = useState<{
    loading: boolean;
    logList:
      | {
          id: number;
          companyId: number;
          employeeId: number;
          employeeName: string;
          employeeJobClassName: string;
          employeeAvatar: string;
          type: string;
          createAt: string;
          isExpand: boolean;
          detailsMap?: Record<string, unknown>;
        }[]
      | undefined;
    pageNo: number;
    totalCount: number | undefined;
  }>({
    loading: true,
    logList: undefined,
    pageNo: 1,
    totalCount: undefined,
  });

  const { loading, logList } = state;

  const setLogList = (
    list: {
      id: number;
      employeeId: number;
      type: string;
      createAt: string;
      detailsMap?: Record<string, unknown>;
    }[],
  ) => {
    return list.map((a) => {
      const employeeData = getDirectoryData({
        ...directory,
        companyId: principal.companyId,
        employeeId: a.employeeId,
      });
      return {
        id: a.id,
        companyId: principal.companyId,
        employeeId: a.employeeId,
        employeeName: employeeData.employeeName,
        employeeJobClassName: employeeData.jobClassName,
        employeeAvatar: employeeData.avatar ?? '',
        type: a.type,
        createAt: a.createAt,
        isExpand: false,
        detailsMap: a.detailsMap,
      };
    });
  };
  useEffect(() => {
    async function run() {
      const totalCount = await documentLogApi.totalcount({ id });
      const list = setLogList(
        (await documentLogApi.findAll({ id, pageNo: 1, rowsPerPage: 15 })).map(
          (a) => {
            if (a.detailsMap !== null && a.detailsMap !== undefined) {
              return {
                ...a,
              };
            }
            return a;
          },
        ),
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        logList: list,
        totalCount,
      }));
    }
    run();
  }, []);

  /** 이전 페이지 */
  const handlePrev = async () => {
    const pageNo = state.pageNo - 1;
    if (pageNo > 0) {
      const totalCount = await documentLogApi.totalcount({
        id,
      });
      const list = setLogList(
        (await documentLogApi.findAll({ id, pageNo, rowsPerPage: 15 })).map(
          (a) => {
            if (a.detailsMap !== null && a.detailsMap !== undefined) {
              return {
                ...a,
              };
            }
            return a;
          },
        ),
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        pageNo,
        logList: list,
        viewTotalCount: totalCount,
      }));
      scrollbar.current?.scrollTo(0, 0);
    }
  };

  /** 다음 페이지 */
  const handleNext = async () => {
    const pageNo = state.pageNo + 1;
    const totalCount = await documentLogApi.totalcount({ id });
    const list = setLogList(
      (await documentLogApi.findAll({ id, pageNo, rowsPerPage: 15 })).map(
        (a) => {
          if (a.detailsMap !== null && a.detailsMap !== undefined) {
            return {
              ...a,
            };
          }
          return a;
        },
      ),
    );
    setState((prevState) => ({
      ...prevState,
      loading: false,
      pageNo,
      logList: list,
      viewTotalCount: totalCount,
    }));
    scrollbar.current?.scrollTo(0, 0);
  };

  /** 아이콘, 텍스트 가져오기 */
  const getIconAndText = (value: string): { icon: IconType; text: string } => {
    switch (value) {
      case 'CREATE':
        return { icon: 'document' as const, text: '생성' };
      case 'UPDATE':
        return { icon: 'document-write' as const, text: '수정' };
      case 'RENEW':
        return { icon: 'document-stamp' as const, text: '새 버전 작성' };
      case 'CHECKOUT':
        return { icon: 'document-update' as const, text: '체크아웃' };
      case 'CHECKIN':
        return { icon: 'document-check' as const, text: '체크인' };
      case 'SAVINGFILES':
        return { icon: 'file-download' as const, text: '파일 저장' };
      case 'SAVINGCONTENTS':
        return { icon: 'file-zip' as const, text: '저장' };
      case 'PRINT':
        return { icon: 'print' as const, text: '인쇄' };
      case 'RESTORE':
        return { icon: 'redo' as const, text: '복원' };
      // case 'COPY':
      //   return { icon: 'copy' as const, text: '복사' };
      case 'MOVE':
        return { icon: 'folder-move' as const, text: '이동' };
      case 'DELETE':
        return { icon: 'trash-full' as const, text: '삭제' };
      default:
        return { icon: 'window-restore-down' as const, text: '' };
    }
  };

  const handleExpandVisible = (selectedId: number) => {
    const newLogList = state.logList?.map((a) => {
      if (a.id === selectedId) {
        return { ...a, isExpand: !a.isExpand };
      }
      return a;
    });
    setState((prev) => ({
      ...prev,
      logList: newLogList,
    }));
  };

  return (
    <>
      <Drawer title="문서활동" size="xs" onClose={onClose}>
        <DrawerToolbar>
          <DrawerToolbar.Left>{null}</DrawerToolbar.Left>
          <DrawerToolbar.Right>
            {state.totalCount ? (
              <Pagination
                no={state.pageNo}
                rows={15}
                count={state.totalCount}
                onPrev={handlePrev}
                onNext={handleNext}
              />
            ) : null}
          </DrawerToolbar.Right>
        </DrawerToolbar>
        <DrawerBody scrollbar={scrollbar}>
          <DrawerList>
            {loading && <Loading />}
            {logList && logList.length > 0 ? (
              logList.map((a) => {
                const employeeId = a.employeeAvatar.split('/').slice(-1)[0];
                const { text, icon } = getIconAndText(a.type);
                const listname = `ui-loglist ${a.type.toLowerCase()}`;

                const detailChildren = ():
                  | JSX.Element
                  | JSX.Element[]
                  | null => {
                  if (a.type === 'MOVE') {
                    const fromFolderName = getPropertyIfExists(
                      a.detailsMap,
                      'fromFolderName',
                    );
                    const toFolderName = getPropertyIfExists(
                      a.detailsMap,
                      'toFolderName',
                    );
                    return (
                      <span style={{ color: 'var(--secondary-text-color)' }}>
                        {fromFolderName} → {toFolderName}
                      </span>
                    );
                  }
                  if (a.type === 'RENEW') {
                    const versionSeq = getPropertyIfExists(
                      a.detailsMap,
                      'versionSeq',
                    );
                    const changeReason = getPropertyIfExists(
                      a.detailsMap,
                      'changeReason',
                    );
                    return (
                      <>
                        <span style={{ color: 'var(--secondary-text-color)' }}>
                          버전: VER{versionSeq}
                        </span>
                        <span style={{ color: 'var(--secondary-text-color)' }}>
                          변경사유:{changeReason}
                        </span>
                      </>
                    );
                  }
                  if (a.type === 'UPDATE') {
                    const changeReason = getPropertyIfExists(
                      a.detailsMap,
                      'changeReason',
                    );
                    return (
                      <span style={{ color: 'var(--secondary-text-color)' }}>
                        변경사유: {changeReason}
                      </span>
                    );
                  }
                  if (a.type === 'CHECKIN') {
                    const checkOutDate = getPropertyIfExists(
                      a.detailsMap,
                      'checkOutDate',
                    ) as string;
                    const changeReason = getPropertyIfExists(
                      a.detailsMap,
                      'changeReason',
                    ) as string | null;
                    return (
                      <>
                        {changeReason && (
                          <span
                            style={{ color: 'var(--secondary-text-color)' }}
                          >
                            사유: {changeReason}
                          </span>
                        )}
                        <span style={{ color: 'var(--secondary-text-color)' }}>
                          체크아웃일:
                          {dateTimeFormat(checkOutDate, 'YYYY-MM-DD(dd) H:mm')}
                        </span>
                      </>
                    );
                  }
                  if (a.type === 'SAVINGFILES') {
                    const attachedFileNames = getPropertyIfExists(
                      a.detailsMap,
                      'attachedFileNames',
                    ) as string[];
                    return attachedFileNames.map((b) => (
                      <span
                        key={a.id}
                        style={{ color: 'var(--secondary-text-color)' }}
                      >
                        <FileChipGroup key={a.id} label={b} filename={b} />
                      </span>
                    ));
                  }
                  return null;
                };
                return (
                  <>
                    <DrawerList.Item key={`${a.createAt}_${employeeId}`}>
                      <div className={listname}>
                        <div className="photo">
                          <Avatar
                            name={`${a.employeeName} ${a.employeeJobClassName}`}
                            image={`${a.employeeAvatar}`}
                            className="avatar"
                          />
                          <Icon className="action" icon={icon} tooltip={text} />
                        </div>
                        <div className="info">
                          <span
                            className="text"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {a.employeeName} {a.employeeJobClassName}님이{' '}
                            <b>{text}</b>했습니다.
                          </span>
                          <span className="date">
                            {dateTimeFormat(a.createAt, 'YYYY-MM-DD(dd) H:mm')}
                          </span>
                        </div>
                        <div
                          className="ui-loglist more"
                          style={{
                            display: 'flex',
                            position: 'absolute',
                            right: 0,
                            marginRight: '10px',
                          }}
                        >
                          {a.detailsMap && (
                            <div className="more">
                              <button
                                type="button"
                                className="button toggle"
                                aria-label={a.isExpand ? '접기' : '펼치기'}
                                onClick={() => handleExpandVisible(a.id)}
                                aria-expanded={a.isExpand}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {a.detailsMap && a.isExpand && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            paddingLeft: '52px',
                          }}
                        >
                          {detailChildren()}
                        </div>
                      )}
                    </DrawerList.Item>
                  </>
                );
              })
            ) : (
              <EmptyData />
            )}
          </DrawerList>
        </DrawerBody>
      </Drawer>
    </>
  );
}

export default React.memo(DocumentLogDrawer);
