import React from 'react';
import { useSelector } from 'react-redux';
import { getPathParams } from '../../groupware-common/utils';
import SystemEverSettingRoute from '../pages/company/SystemEverSettingRoute';
import SystemLinkFormRoute from '../pages/form/SystemLinkFormRoute';
import SystemLinkWorkRoute from '../pages/work/SystemLinkWorkRoute';
import { RootState } from '../../groupware-webapp/app/store';

function SystemLinkLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element | null {
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  const provider = useSelector(
    (state: RootState) => state.systemlink.preferences.provider,
  );

  if (provider.approval.length > 0 || provider.directory.length > 0) {
    if (menu === 'systemever')
      return <SystemEverSettingRoute pathname={pathname} search={search} />;
  }
  if (provider.approval.length > 0) {
    if (menu === 'form') return <SystemLinkFormRoute pathname={pathname} />;
    if (menu === 'work')
      return (
        <SystemLinkWorkRoute pathname={pathname} search={search} hash={hash} />
      );
  }
  return null;
}

export default SystemLinkLocateRoute;
