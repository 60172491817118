import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import EmptyLocateRoute from '../../groupware-common/locates/EmptyLocateRoute';
import DirectoryPreferencesRoute from '../pages/adminconsole/directory/preferences/DirectoryPreferencesRoute';
import OrganizationChartRoute from '../pages/adminconsole/directory/organizationchart/OrganizationChartRoute';
import JobPositionRoute from '../pages/adminconsole/directory/jobposition/JobPositionRoute';
import JobDutyRoute from '../pages/adminconsole/directory/jobduty/JobDutyRoute';
import DirectoryUserGroupRoute from '../pages/adminconsole/directory/usergroup/DirectoryUserGroupRoute';

function AdminConsoleLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${AdminConsoleLocateRoute.name}.render(props)`, props);

  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', props.pathname);

  if (menu === undefined)
    return <DirectoryPreferencesRoute pathname={pathname} />;
  if (menu === 'organizationchart')
    return <OrganizationChartRoute pathname={pathname} />;
  if (menu === 'jobposition') return <JobPositionRoute pathname={pathname} />;
  if (menu === 'jobduty') return <JobDutyRoute pathname={pathname} />;
  if (menu === 'group')
    return <DirectoryUserGroupRoute pathname={pathname} search={search} />;

  return <EmptyLocateRoute pathname={pathname} search={search} hash={hash} />;
}

export default AdminConsoleLocateRoute;
