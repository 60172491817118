import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { noticeActions } from '../../../../stores/attendance/notice';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceNoticeRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const principal = useSelector((state: RootState) => state.session.principal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(attendancePreferencesActions.findNotice());
    dispatch(
      noticeActions.list({
        employeeId: principal.employeeId,
        search,
        route: {
          pathname,
          search,
        },
      }),
    );
  }, [dispatch, pathname, search]);

  return null;
}

export default AttendanceNoticeRoute;
