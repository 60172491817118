import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import PostList, {
  PostListDensity,
  PostListItemType,
} from '../../../../../components/post/PostList';
import AttendanceSearch, {
  SearchDateProps,
} from '../../../../../components/search/AttendanceSearch';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  createQueryString,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import EmptyData from '../../../../../components/data/EmptyData';
import ListSetting from '../../../../../groupware-webapp/pages/popup/ListSetting';
import { resourcesActions } from '../../../../stores/resources';
import ResourceLookupDialog from '../content/ResourceLookupDialog';
import ResourceReservationListItem from './ResourceReservationListItem';

function ResourceReservationListContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { p1 } = getPathParams<{ p1?: string }>('/*/:p1', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const displayDensity = useSelector(
    (state: RootState) => state.resource.resources.displayDensity,
  );
  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  );
  const list = useSelector(
    (state: RootState) => state.resource.resources.share.list,
  );
  const totalCount = useSelector(
    (state: RootState) => state.resource.resources.share.totalCount,
  );
  const categories = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'status');

  const scrollbar = useRef<HTMLDivElement>(null);
  const { pageNo, rowsPerPage, searchCode, searchWord } = queryParams;

  const options = [
    { value: 'folder', label: '폴더명' },
    { value: 'item', label: '자원명' },
    { value: 'subject', label: '제목' },
  ];
  if (p1) options.push({ value: 'employee', label: '예약자' });

  const [columns, setColumns] = useState<Column<PostListItemType>[]>([
    { name: 'folderName', text: '폴더명', type: 'folder', visible: true },
    { name: 'itemName', text: '자원명', type: 'folder', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: '제목', type: 'post', visible: true, disable: true },
    { name: 'range', text: '사용기간', type: 'period', visible: true },
    { name: 'useDays', text: '신청일', type: 'date', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'user', text: '예약자', type: 'user', visible: p1 !== undefined || display === 'phone', disable: !p1 },
    { name: 'status', text: '상태', type: 'reservationStatus', visible: true },
  ]);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pathname, pageNo, rowsPerPage, searchCode, searchWord]);

  useEffect(() => {
    setColumns(
      columns.map((a) => {
        if (a.name === 'user')
          return {
            ...a,
            visible: p1 !== undefined || display === 'phone',
          };
        return a;
      }),
    );
  }, [pathname, dispatch]);

  // 헤드 액션.
  const handleHeadAction = (arg: {
    code: string;
    event: React.MouseEvent<Element, MouseEvent>;
  }) => {
    const { code } = arg;
    // 목록 설정 드로워.
    if (code === 'listSetting')
      dispatch(sessionActions.setDrawer({ type: code }));
    // 이전 페이지
    if (code === 'prevPage') {
      const no = (queryParams.pageNo ?? 1) - 1;
      if (no > 0) {
        if (no > 1) queryParams.pageNo = no;
        else delete queryParams.pageNo;
        go(pathname, queryParams);
      }
    }
    if (code === 'nextPage') {
      const no = (queryParams.pageNo ?? 1) + 1;
      if ((queryParams.rowsPerPage ?? 1 * no) < totalCount) {
        queryParams.pageNo = no;
        go(pathname, queryParams);
      }
    }
  };

  // 컬럼 보이기 변경.
  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    setColumns((prev) =>
      prev.map((x) => (x.name === name ? { ...x, visible } : x)),
    );
  };

  // 화면 밀도 설정.
  const handleChangeDensity = (value: PostListDensity) => {
    dispatch(resourcesActions.displayDensity(value));
  };

  // 목록 개수 설정.
  const handleChangeListCount = (value: string) => {
    const rows = parseInt(value, 10);
    go(
      pathname,
      createQueryString(
        {
          pageNo: undefined,
          rowsPerPage: rows === 15 ? undefined : rows,
          drawerMode: undefined,
          drawerType: undefined,
        },
        queryParams,
      ),
    );
  };

  // 드로워 닫기.
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  // 검색.
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    delete queryParams.pageNo;
    queryParams.searchCode = arg.filter;
    queryParams.searchWord = arg.keyword;
    queryParams.startDate = arg.date.start
      ? dateFormat(initialDate(arg.date.start), 'yyyy-MM-DD')
      : undefined;
    queryParams.endDate = arg.date.end
      ? dateFormat(initialDate(arg.date.end), 'yyyy-MM-DD')
      : undefined;
    // 공유 받은 내역 조회.
    if (p1) {
      queryParams.directoryKeyword = arg.directoryKeyword;
      queryParams.status =
        arg.directoryKeyword && arg.keyword !== '' ? 'true' : undefined;
    }
    go(pathname, getQueryParams(queryParams));
  };

  // 리스트 클릭 이벤트.
  const handleListClick = (id: number) => {
    queryParams.dialogType = 'view';
    dispatch(
      resourcesActions.reservationView({
        id,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 예약 수정 */
  const handleUpdate = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      itemId: number;
      employeeId: number;
      name: string;
      remark?: string;
      startDateTime?: string;
      endDateTime?: string;
      lookupStartDateTime?: string;
      lookupUpdateAt?: string;
      updateAt?: string;
    };
  }) => {
    const { isRepeat, repeatType } = arg;
    const query = getQueryParams(search);
    if (isRepeat) delete query.pageNo;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(query),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    const route = utils.getRoute({
      target: props,
      source: {
        pathname,
        search: getQueryParams(query),
        option: 'CLEAR_DIALOG',
      },
    });
    // 반복 예약 수정
    if (isRepeat) {
      if (queryParams.pageNo)
        dispatch(
          resourcesActions.updateRepeatReservation({
            type: repeatType,
            data: arg.data,
            location,
          }),
        );
      else
        dispatch(
          resourcesActions.updateRepeatReservation({
            type: repeatType,
            data: arg.data,
          }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined)
            dispatch(
              resourcesActions.myReservationList({
                search: getQueryParams(query),
                route,
              }),
            );
        });
    }
    // 일반 예약 수정
    else {
      const data = { ...arg.data, updateAt: arg.data.updateAt ?? '' };
      dispatch(resourcesActions.updateReservation({ data })).then((result) => {
        if ((result as { error?: string }).error === undefined)
          dispatch(
            resourcesActions.myReservationList({
              search: getQueryParams(query),
              route,
            }),
          );
      });
    }
  };

  /** 예약 삭제. */
  const handleDelete = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      startDateTime?: string;
      updateAt: string;
    };
  }) => {
    const { isRepeat, repeatType, data } = arg;
    const query = getQueryParams(search);
    delete query.pageNo;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(query),
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    const route = utils.getRoute({
      target: props,
      source: {
        pathname,
        search: getQueryParams(query),
        option: 'CLEAR_DIALOG',
      },
    });
    if (isRepeat) {
      if (queryParams.pageNo)
        dispatch(
          resourcesActions.deleteRepeatReservation({
            data: {
              type: repeatType,
              ...data,
              startDateTime: data.startDateTime ?? '',
            },
            location,
          }),
        );
      else
        dispatch(
          resourcesActions.deleteRepeatReservation({
            data: {
              type: repeatType,
              ...data,
              startDateTime: data.startDateTime ?? '',
            },
          }),
        ).then((result) => {
          if ((result as { error?: string }).error === undefined)
            dispatch(
              resourcesActions.myReservationList({
                search: getQueryParams(query),
                route,
              }),
            );
        });
    } else if (queryParams.pageNo)
      dispatch(
        resourcesActions.deleteReservation({
          id: data.id,
          updateAt: data.updateAt,
          location,
        }),
      );
    else
      dispatch(
        resourcesActions.deleteReservation({
          id: data.id,
          updateAt: data.updateAt,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined)
          dispatch(
            resourcesActions.myReservationList({
              search: getQueryParams(query),
              route,
            }),
          );
      });
  };

  const renderDrawer = () => {
    const { drawerType } = queryParams;
    if (drawerType === 'listSetting') {
      return (
        <ListSetting
          type="list"
          propsFolderId={1007}
          columns={columns}
          density={displayDensity}
          listCount={`${queryParams.rowsPerPage ?? 15}`}
          onColumnVisibleChange={handleColumnVisibleChange}
          onChangeDensity={handleChangeDensity}
          onChangeListCount={handleChangeListCount}
          onClose={handleCloseDrawer}
        />
      );
    }
    return null;
  };

  const renderDialog = () => {
    const { dialogType } = queryParams;
    if (dialogType === 'view') {
      return (
        <ResourceLookupDialog
          myReservation={!p1}
          shared={p1 === 'shared'}
          pathname={pathname}
          search={search}
          hash=""
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      );
    }
    return null;
  };

  let start: Date | null = null;
  let end: Date | null = null;
  if (queryParams.startDate) start = timezoneDate(queryParams.startDate);
  if (queryParams.endDate) end = timezoneDate(queryParams.endDate);
  const title = categories.find((a) => a.id === (p1 ?? 'mine'))?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceSearch
            directory={!!p1}
            dualSearch={!!p1}
            options={options}
            filter={queryParams.searchCode ?? 'folder'}
            date={{ start, end }}
            keyword={queryParams.searchWord}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          <EuiToolbar.Left>
            <></>
          </EuiToolbar.Left>
          <EuiToolbar.Right>
            {totalCount > 0 && (
              <Pagination
                no={queryParams.pageNo ?? 1}
                rows={queryParams.rowsPerPage ?? 15}
                count={totalCount}
                onPrev={(event) =>
                  handleHeadAction({ code: 'prevPage', event })
                }
                onNext={(event) =>
                  handleHeadAction({ code: 'nextPage', event })
                }
              />
            )}
            {totalCount > 0 && display !== 'phone' && (
              <Divider orientation="vertical" />
            )}
            {display !== 'phone' && (
              <Button
                text="목록설정"
                iconType
                icon="bar-cog"
                onClick={(event) =>
                  handleHeadAction({ code: 'listSetting', event })
                }
              />
            )}
          </EuiToolbar.Right>
        </EuiHeader.Toolbar>
      </EuiHeader>
      <EuiBody>
        <PostList
          name="reservation"
          type="full"
          density={displayDensity}
          // eslint-disable-next-line prettier/prettier
          style={{ minWidth: display === 'tablet' && p1 !== undefined ? `810px` : undefined }}
        >
          <PostList.Head>
            {columns
              .filter(({ visible }) => visible)
              .map(({ name, type: colunmType, text }) => (
                <PostList.Cell
                  key={name}
                  name={text}
                  type={colunmType}
                  align={colunmType !== 'post' ? 'center' : undefined}
                />
              ))}
          </PostList.Head>
          <PostList.Body scrollableNodeProps={scrollbar}>
            {list.length === 0 ? (
              <EmptyData />
            ) : (
              list.map((a, i) => {
                const directoryData = getDirectoryData({
                  ...directory,
                  companyId: a.companyId,
                  employeeId: a.employeeId,
                });
                const folderName =
                  folders.find(({ id }) => a.folderId === id)?.name ?? '';
                const itemName =
                  items.find(({ id }) => a.itemId === id)?.name ?? '';
                let status = 'proceeding';
                if (a.isApproval === 1) status = 'done';
                else if (a.isApproval === 2) status = 'return';
                const item = {
                  folderName,
                  itemName,
                  subject: a.name,
                  range: `${dateTimeFormat(
                    a.startDateTime,
                    'yyyy-MM-DD',
                  )} ~ ${dateTimeFormat(a.endDateTime, 'yyyy-MM-DD')}`,
                  useDays: dateTimeFormat(a.createAt, 'yyyy-MM-DD'),
                  user: {
                    name: directoryData.employeeName,
                    organization: directoryData.organizationName,
                    job: directoryData.jobPositionName,
                    avatar: directoryData.avatar ?? '',
                  },
                  status,
                };
                const key = `/i${i}/${a.id}`;
                return (
                  <ResourceReservationListItem
                    key={key}
                    align
                    menu={p1}
                    id={a.id}
                    columns={columns.filter(({ visible }) => visible)}
                    item={item}
                    onClick={handleListClick}
                  />
                );
              })
            )}
          </PostList.Body>
        </PostList>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default ResourceReservationListContainer;
