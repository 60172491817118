import React from 'react';
import { useSelector } from 'react-redux';
import EuiSetting from '../../../../../../components/layout/EuiSetting';
import PostView from '../../../../../../components/post/PostView';
import Breadcrumbs from '../../../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../../../components/button/Button';
import {
  FolderView,
  replaceRetentionPeriodToString,
} from '../../../../../stores/document/folders';
import ChipGroup from '../../../../../../components/chip/ChipGroup';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../../../groupware-directory/stores/directory';
import Chip from '../../../../../../components/chip/Chip';
import { getDirectoryData } from '../../../../../../groupware-webapp/stores/common/utils';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { CustomNumbers } from '../../../../../../groupware-common/types';
import { handleMatchingPermissions } from './DocumentFolderEditDrawer';

type Props = {
  paths: string[];
  folder: FolderView;
  isEnable: boolean;
  name: string;
  description: string;
  forms: {
    id: number;
    name: string;
  }[];
  defaultRetentionPeriod: string;
  onAction(arg: { code: string }): void;
};

function DocumentFolderContentView(props: Props): JSX.Element {
  const directory = useDirectory();

  const { folder, paths, onAction } = props;

  /** 직위 */
  const jobPositions = useSelector(
    (s: RootState) => s.directory.jobPosition.list.data.items,
  );

  /** 직책 */
  const jobDuties = useSelector(
    (s: RootState) => s.directory.jobDuty.list.data.items,
  );

  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: RootState) => s.directory.preferences.jobClassType,
  );

  return (
    <>
      <EuiSetting.Header title="폴더정보">
        <Button
          text="순서변경"
          iconType
          icon="sort-amount"
          onClick={() => onAction({ code: 'folder/sort' })}
        />
        <Button
          text="수정"
          iconType
          icon="edit"
          onClick={() => onAction({ code: 'folder/update' })}
        />
        <Button
          text="삭제"
          iconType
          icon="trash-full"
          onClick={() => onAction({ code: 'folder/delete' })}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
            <PostView.Title>{folder.name}</PostView.Title>
          </PostView.Head>
          <PostView.Info>
            <PostView.InfoItem
              title="보존기간"
              value={
                props.defaultRetentionPeriod !== ''
                  ? replaceRetentionPeriodToString(props.defaultRetentionPeriod)
                  : '사용안함'
              }
            />
            <PostView.InfoItem
              title="사용여부"
              value={folder.isEnable ? '사용함' : '사용안함'}
            />
            {/* <PostView.InfoItem
              title="댓글"
              value={folder.option.isComment ? '사용함' : '사용안함'}
            /> */}
            <PostView.InfoItem
              title="체크아웃"
              value={folder.option.isCheckout ? '사용' : '사용안함'}
            />
            <PostView.InfoItem
              title="버전관리"
              value={folder.option.isVersion ? '사용' : '사용안함'}
            />
            <PostView.InfoItem
              title="버전관리 필수"
              value={folder.option.isRequiredVersion ? '사용함' : '사용안함'}
            />
          </PostView.Info>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title="설명">
                <PostView.CategoryValue value={folder.description} />
              </PostView.CategoryItem>

              <PostView.CategoryItem title="양식">
                <PostView.CategoryValue
                  value={folder.forms.length > 0 ? '사용함' : '사용안함'}
                />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="관리자">
                <ChipGroup>
                  {folder.managers
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 'EMPLOYEE') {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.jobClassSeq < b.jobClassSeq) return -1;
                      if (a.jobClassSeq > b.jobClassSeq) return 1;
                      return 0;
                    })
                    .map(
                      ({ referenceCompanyId: companyId, referenceId: id }) => {
                        // 사원일 경우
                        const DirectoryData = getDirectoryData({
                          ...directory,
                          companyId,
                          employeeId: id,
                        });
                        return (
                          <Chip
                            key={`${companyId}/${id}`}
                            label={DirectoryData.employeeName}
                            etc="관리자"
                            avatar={DirectoryData.avatar}
                          />
                        );
                      },
                    )}
                </ChipGroup>
              </PostView.CategoryItem>
              <PostView.CategoryItem title="공유자">
                <ChipGroup>
                  {folder.users
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 'EMPLOYEE') {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.referenceType !== b.referenceType) {
                        if (a.referenceType === 'COMPANY') return -1;
                        if (a.referenceType === 'ORGANIZATION') {
                          if (b.referenceType === 'COMPANY') return 1;
                          return -1;
                        }
                        return 1;
                      }
                      if (a.referenceType === 'EMPLOYEE') {
                        if (a.jobClassSeq < b.jobClassSeq) return -1;
                        if (a.jobClassSeq > b.jobClassSeq) return 1;
                      }
                      return 0;
                    })
                    .map(
                      ({
                        referenceCompanyId: companyId,
                        referenceId: id,
                        referenceType,
                        options,
                      }) => {
                        if (referenceType === 'COMPANY') {
                          // 회사일 경우
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={getCompanyName(companyId)}
                              icon="company"
                              etc={handleMatchingPermissions(options).join('/')}
                            />
                          );
                        }
                        if (referenceType === 'ORGANIZATION') {
                          // 부서일 경우
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={getOrganizationName(companyId, id)}
                              etc={handleMatchingPermissions(options).join('/')}
                              icon="sitemap-fill"
                            />
                          );
                        }
                        if (referenceType === 'EMPLOYEE') {
                          // 사원일 경우
                          const DirectoryData = getDirectoryData({
                            ...directory,
                            companyId,
                            employeeId: id,
                          });
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={DirectoryData.employeeName}
                              etc={handleMatchingPermissions(options).join('/')}
                              avatar={DirectoryData.avatar}
                            />
                          );
                        }
                        return null;
                      },
                    )}
                </ChipGroup>
              </PostView.CategoryItem>
              <PostView.CategoryItem title="예외자">
                <ChipGroup>
                  {folder.excluders
                    .map((a) => {
                      let jobClassSeq = 0;
                      if (a.referenceType === 'EMPLOYEE') {
                        const employeeData = getDirectoryData({
                          ...directory,
                          companyId: a.referenceCompanyId,
                          employeeId: a.referenceId,
                        });
                        // 직위 또는 직책 순서로 정렬 순서 결정.
                        jobClassSeq =
                          (jobClassType === 'jobduty'
                            ? jobDuties.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  v.id === employeeData.jobDutyId,
                              )?.seq
                            : jobPositions.find(
                                (v) =>
                                  v.companyId === a.referenceCompanyId &&
                                  employeeData.jobPositionId === v.id,
                              )?.seq) || CustomNumbers.SMALLINT_MAX;
                      }
                      return {
                        ...a,
                        jobClassSeq,
                      };
                    })
                    // 회사 → 부서 → 직원 순으로 정렬 후
                    // 직위 또는 직책 순번이 낮을수록 밑으로.
                    .sort((a, b) => {
                      if (a.referenceType !== b.referenceType) {
                        if (a.referenceType === 'COMPANY') return -1;
                        if (a.referenceType === 'ORGANIZATION') {
                          if (b.referenceType === 'COMPANY') return 1;
                          return -1;
                        }
                        return 1;
                      }
                      if (a.referenceType === 'EMPLOYEE') {
                        if (a.jobClassSeq < b.jobClassSeq) return -1;
                        if (a.jobClassSeq > b.jobClassSeq) return 1;
                      }
                      return 0;
                    })
                    .map(
                      ({
                        referenceCompanyId: companyId,
                        referenceId: id,
                        referenceType,
                      }) => {
                        if (referenceType === 'COMPANY') {
                          // 회사일 경우
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={getCompanyName(companyId)}
                              icon="company"
                              etc="예외자"
                            />
                          );
                        }
                        if (referenceType === 'ORGANIZATION') {
                          // 부서일 경우
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={getOrganizationName(companyId, id)}
                              icon="sitemap-fill"
                              etc="예외자"
                            />
                          );
                        }
                        if (referenceType === 'EMPLOYEE') {
                          // 사원일 경우
                          const DirectoryData = getDirectoryData({
                            ...directory,
                            companyId,
                            employeeId: id,
                          });
                          return (
                            <Chip
                              key={`${companyId}/${id}`}
                              label={DirectoryData.employeeName}
                              etc="예외자"
                              avatar={DirectoryData.avatar}
                            />
                          );
                        }
                        return null;
                      },
                    )}
                </ChipGroup>
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default React.memo(DocumentFolderContentView);
