import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../components/layout/EuiHeader';
import EuiBody from '../../../components/layout/EuiBody';
import EuiSetting from '../../../components/layout/EuiSetting';
import Switch from '../../../components/switch/Switch';
import EuiToolbar from '../../../components/layout/EuiToolbar';
import Button from '../../../components/button/Button';
import { RootState, useAppDispatch } from '../../../groupware-webapp/app/store';
import HelperText from '../../../components/alert/HelperText';
import Confirmation from '../../../components/alert/Confirmation';
import TextField from '../../../components/textfield/TextField';
import FeedBack from '../../../components/alert/FeedBack';
import { preferencesActions } from '../../stores/systemlink/preferences';
import { dateFormat } from '../../../groupware-common/utils/ui';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import { appError } from '../../../groupware-webapp/stores/common/utils';

function SystemEverSettingContainer(): JSX.Element {
  const dispatch = useAppDispatch();

  const preferences = useSelector(
    (state: RootState) => state.systemlink.preferences.basic,
  );

  const approval = useSelector(
    (state: RootState) => state.systemlink.preferences.approval,
  );

  const directory = useSelector(
    (state: RootState) => state.systemlink.preferences.directory,
  );

  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );

  const principal = useSelector((state: RootState) => state.session.principal);
  const categories = useSelector(
    (state: RootState) => state.systemlink.preferences.category,
  );

  const initialState = () => {
    return {
      approvalUse: approval?.use ?? false,
      directoryUse: directory?.use ?? false,

      change: false,
      saveing: false,

      reissue: false,
      reissueChange: false,

      validation: '',
    };
  };

  const [state, setState] = useState<{
    approvalUse?: boolean;
    directoryUse?: boolean;

    change: boolean;
    saveing: boolean;

    reissue: boolean;
    reissueChange: boolean;

    validation: string;
  }>(initialState);

  useEffect(() => {
    async function run() {
      try {
        const { approvalUse, directoryUse } = state;
        if (approval !== undefined && approvalUse !== undefined) {
          await dispatch(
            preferencesActions.updateApproval({
              use: approvalUse,
              updateAt: approval.updateAt,
            }),
          );

          if (directory !== undefined && directoryUse !== undefined) {
            await dispatch(
              preferencesActions.updateDirectory({
                companyId: principal.companyId,
                provider: 'YLWSYSTEMEVER',
                use: directoryUse,
                updateAt: directory.updateAt,
              }),
            );
          }
        }
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
      } finally {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
          change: false,
        }));
      }
    }
    if (state.saveing) run();
  }, [state.saveing]);

  useEffect(() => {
    async function run() {
      try {
        if (
          preferences.clientId !== '' &&
          preferences.clientSecret !== '' &&
          preferences.apiKey !== ''
        ) {
          await dispatch(
            preferencesActions.updateApiKey({
              updateAt: preferences.updateAt,
            }),
          );
          setState((prevState) => ({
            ...prevState,
            reissue: false,
            reissueChange: false,
          }));
        } else {
          await dispatch(preferencesActions.saveApiKey());
          setState((prevState) => ({
            ...prevState,
            reissue: false,
            reissueChange: false,
          }));
        }
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
      }
    }
    if (state.reissue) run();
  }, [state.reissue]);

  /** 연동 사용 변경 */
  const handleisUseChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (type === 'approval') {
      setState((prevState) => ({
        ...prevState,
        approvalUse: event.target.checked,
        change: true,
      }));
      return;
    }
    if (type === 'directory') {
      setState((prevState) => ({
        ...prevState,
        directoryUse: event.target.checked,
        change: true,
      }));
    }
  };

  /** 설정 저장 */
  const handleSave = () => {
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  /** 비밀번호 재생성 대화상자 닫기. */
  const handleCreateCancel = () => {
    setState((prev) => ({ ...prev, reissue: false, reissueChange: false }));
  };

  /** 비밀번호 재생성 대화상자 확인. */
  const handleCreateSubmit = () => {
    setState((prev) => ({ ...prev, reissue: true }));
  };

  /** 값 복사 */
  const handleCopy = (value: string) => {
    window.navigator.clipboard.writeText(value);
    setState((prevState) => ({
      ...prevState,
      validation: '클립보드에 복사됨',
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const renderDialog = () => {
    if (reissueChange) {
      return (
        <Confirmation
          noDuplication
          onCancel={handleCreateCancel}
          onSubmit={handleCreateSubmit}
        >
          <p>
            액세스 토큰이 재발급 되면 <strong>SystemEver 연동</strong> 재설정이
            필요합니다.
          </p>
          <p>액세스 토큰을 재발급 하시겠습니까?</p>
        </Confirmation>
      );
    }

    return null;
  };

  const { change, saveing, reissueChange } = state;
  const title = categories.find((a) => a.id === 6001)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title="인증 키 발급">
          {preferences.clientId !== '' &&
          preferences.clientSecret !== '' &&
          preferences.apiKey !== '' ? (
            <div>
              <div>
                <Button
                  text="재발급"
                  variant="contained"
                  loading={reissueChange}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      reissueChange: true,
                    }))
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  padding: '24px 0',
                  borderBottom: '1px solid var(--line-color)',
                }}
              >
                <div className="setting-item-title">API 키</div>
                <div className="setting-item-content">{preferences.apiKey}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  padding: '24px 0',
                  borderBottom: '1px solid var(--line-color)',
                }}
              >
                <div className="setting-item-title">클라이언트 아이디</div>
                <div className="setting-item-content">
                  {preferences.clientId}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  padding: '24px 0',
                }}
              >
                <div className="setting-item-title">클라이언트 비밀번호</div>
                <div className="setting-item-content">
                  <TextField
                    type="password"
                    value={preferences.clientSecret}
                    disabled
                    copy
                    onCopy={handleCopy}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <HelperText
                  text={`인증키 발급 : ${
                    employees.find((a) => a.id === preferences.updaterId)
                      ?.email ?? ''
                  } / ${dateFormat(
                    new Date(preferences.updateAt),
                    'YYYY-MM-DD',
                  )}`}
                />
              </div>
            </div>
          ) : (
            <div>
              <div>
                <Button
                  text="발급"
                  variant="contained"
                  loading={reissueChange}
                  onClick={handleCreateSubmit}
                />
              </div>
              <HelperText text="※ 인증키 발급 전입니다." />
            </div>
          )}
        </EuiSetting.Item>
        {approval && (
          <EuiSetting.Item title="결재 연동 사용">
            <Switch
              checked={state.approvalUse}
              onChange={(e) => handleisUseChange('approval', e)}
            />
          </EuiSetting.Item>
        )}
        {directory && (
          <EuiSetting.Item title="조직 연동 사용">
            <Switch
              checked={state.directoryUse}
              onChange={(e) => handleisUseChange('directory', e)}
            />
          </EuiSetting.Item>
        )}
      </EuiBody>
      <EuiToolbar>
        {change && (
          <Button
            text="저장"
            variant="contained"
            loading={saveing}
            onClick={handleSave}
          />
        )}
        {!saveing && change && (
          <Button text="취소" onClick={() => setState(initialState)} />
        )}
      </EuiToolbar>
      {renderDialog()}
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default SystemEverSettingContainer;
