import React from 'react';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';

function DeleteConfirmation(props: {
  children: React.ReactNode;
  noDuplication?: boolean; // 버튼 클릭 중복 가능 여부. (true: 중복클릭 불가능, false, undefined: 중복클릭 가능)
  delete?: boolean;
  message?: string;
  onSubmit(): void;
  onCancel(): void;
}): JSX.Element {
  return (
    <Dialog size="xs">
      <DialogBody>
        <div className="eui-alert-message eui-delete-message">
          <p>{props.children}</p>
          {!props.delete && (
            <p>
              {props.message ? (
                <>
                  <em>{props.message}</em>되며 <em>영구 삭제</em>되어 복구할 수
                  없습니다.
                </>
              ) : (
                <>
                  <em>영구 삭제</em>되어 복구할 수 없습니다.
                </>
              )}
            </p>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button text="취소" onClick={props.onCancel} />
        <Button
          noDuplication={props.noDuplication ?? true}
          text="삭제"
          variant="contained"
          color="danger"
          onClick={props.onSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default DeleteConfirmation;
