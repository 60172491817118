import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Avatar from '../../../../../components/avatar/Avatar';

function ComponentAvatarIcon(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Icon avatars">
      <Avatar name="홍길동" icon="sitemap-fill" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentAvatarIcon;
