import React from 'react';

function Links(props: {
  className?: string;
  children: React.ReactNode;
  onClick?(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void;
}): JSX.Element {
  const { className, children, onClick } = props;
  let classname = 'eui-hyperlink';
  if (className) classname += ` ${className}`;
  return (
    <span role="button" tabIndex={0} className={classname} onClick={onClick}>
      {children}
    </span>
  );
}

export default Links;
