import React from 'react';
import Drawer from '../../../components/drawer/Drawer';
import DrawerBody from '../../../components/drawer/DrawerBody';
import { Module } from '../../../groupware-common/types';
import RadioGroup from '../../../components/radio/RadioGroup';
import SelectField from '../../../components/selectField/SelectField';
import { Column } from '../../../groupware-common/ui/type';
import Checkbox from '../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import { ReadingPaneMode } from '../../stores/types';
import { IconType } from '../../../groupware-common/types/icon';
import { PostListItemType } from '../../../components/post/PostList';
import { getPathParams } from '../../../groupware-common/utils';

function ListSettingItem(props: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className="setting-item">
      <div className="item-head">{props.title}</div>
      <div className="item-content">{props.children}</div>
    </div>
  );
}

interface ModuleProps {
  value: ReadingPaneMode;
  label: string;
  icon: IconType;
}

function ListSetting(props: {
  module?: Module;
  type: ReadingPaneMode;
  propsFolderId?: number;
  columns?: Column<PostListItemType>[];
  onColumnVisibleChange?(name: string, visible: boolean): void;
  readingPaneMode?: ReadingPaneMode;
  onReadingPaneModeChange?(readingPaneMode: ReadingPaneMode): void;
  density: string;
  onChangeDensity?(value: string): void;
  listCount: string;
  onChangeListCount?(value: string): void;
  onClose(): void;
  isDisableModeOption?: boolean;
  pathname?: string;
}): JSX.Element {
  /** 임시 보관 폴더 여부. */
  const isTemporaryStorageFolder = props.propsFolderId === 1007;
  /** 보기 모드 사용 안함. (임시 보관, 휴지통) */
  const isDisableModeOption = props.isDisableModeOption ?? false;
  const {
    module,
    type,
    columns,
    onColumnVisibleChange,
    readingPaneMode,
    onReadingPaneModeChange,
    density,
    onChangeDensity,
    listCount,
    onChangeListCount,
    onClose,
  } = props;
  const { pathname = '' } = props;
  const { p1 } = getPathParams<{ p1?: string }>('/*/:p1', pathname);

  const densityOptions = [
    { value: 'normal', label: '기본' },
    { value: 'wide', label: '넓게' },
    { value: 'narrow', label: '좁게' },
  ];

  const normalAndNarrowOptions = densityOptions.filter(
    (option) => option.value === 'normal' || option.value === 'narrow',
  );

  let modeOptions: ModuleProps[] = [
    { value: 'list', label: '목록', icon: 'list-list' },
    { value: 'vertical', label: '좌우분할', icon: 'split-left-right' },
    { value: 'horizontal', label: '상하분할', icon: 'split-top-bottom' },
    { value: 'media', label: '미디어', icon: 'thumbnail-list' },
    { value: 'grid', label: '그리드', icon: 'grid-4-fill' },
    { value: 'gallery', label: '갤러리', icon: 'grid-list' },
  ];

  switch (module) {
    case 'approval':
      modeOptions = [
        { value: 'list', label: '목록', icon: 'list-list' },
        { value: 'vertical', label: '좌우분할', icon: 'split-left-right' },
        { value: 'horizontal', label: '상하분할', icon: 'split-top-bottom' },
      ];
      break;
    case 'board':
      modeOptions = [
        { value: 'list', label: '목록', icon: 'list-list' },
        { value: 'vertical', label: '좌우분할', icon: 'split-left-right' },
        { value: 'horizontal', label: '상하분할', icon: 'split-top-bottom' },
        { value: 'gallery', label: '갤러리', icon: 'grid-list' },
      ];
      break;
    default:
      modeOptions = [
        { value: 'list', label: '목록', icon: 'list-list' },
        { value: 'vertical', label: '좌우분할', icon: 'split-left-right' },
        { value: 'horizontal', label: '상하분할', icon: 'split-top-bottom' },
      ];
      break;
  }

  const countOptions = [
    { value: '5', label: '5 개' },
    { value: '10', label: '10 개' },
    { value: '15', label: '15 개' },
    { value: '20', label: '20 개' },
    { value: '25', label: '25 개' },
    { value: '30', label: '30 개' },
    { value: '35', label: '35 개' },
    { value: '50', label: '50 개' },
    { value: '100', label: '100 개' },
  ];

  const handleChangeDensity = (value: string) => {
    if (onChangeDensity) onChangeDensity(value);
  };

  const handleChangeListCount = (value: string) => {
    if (onChangeListCount) onChangeListCount(value);
  };

  const handleColumnVisibleChange = (name: string, visible: boolean) => {
    if (onColumnVisibleChange) onColumnVisibleChange(name, visible);
  };

  const handleChangeReadingPaneMode = (mode: ReadingPaneMode) => {
    if (onReadingPaneModeChange) onReadingPaneModeChange(mode);
  };

  return (
    <Drawer title="목록 설정" size="xs" onClose={onClose}>
      <DrawerBody>
        <div className="ui-setting-list">
          {type !== 'gallery' && (
            <ListSettingItem title="화면 밀도">
              <RadioGroup
                data={
                  (pathname === 'board' || pathname === 'document') &&
                  !isDisableModeOption
                    ? densityOptions
                    : normalAndNarrowOptions
                }
                value={density}
                name="density"
                onChange={handleChangeDensity}
              />
            </ListSettingItem>
          )}
          {!isTemporaryStorageFolder && !isDisableModeOption && (
            <ListSettingItem title="보기 모드">
              <RadioGroup
                data={modeOptions}
                value={readingPaneMode || 'list'}
                name="mode"
                onChange={handleChangeReadingPaneMode}
                column
              />
            </ListSettingItem>
          )}
          {columns && (type === 'list' || type === 'horizontal') && (
            <ListSettingItem title="목록 노출">
              <CheckboxGroup column>
                {columns.map(({ name, text, visible, disable }) => {
                  const checked = visible || false;
                  return (
                    <Checkbox
                      key={name}
                      label={text}
                      checked={checked}
                      onChange={() => handleColumnVisibleChange(name, !checked)}
                      disabled={disable}
                    />
                  );
                })}
              </CheckboxGroup>
            </ListSettingItem>
          )}
          <ListSettingItem title="목록 개수">
            <SelectField
              data={countOptions}
              value={listCount}
              onChange={handleChangeListCount}
            />
          </ListSettingItem>
        </div>
      </DrawerBody>
    </Drawer>
  );
}

export default React.memo(ListSetting);
