import React from 'react';
import Chip from '../chip/Chip';
import { IconType } from '../../groupware-common/types/icon';
import UserInfo from '../user/UserInfo';

function TimeLineItem(props: {
  onClick?(id: number): void;
  item: {
    id: number;
    label: string;
    subject: string;
    updateAt: string;
    etc?: string;
  };
  user: {
    name: string;
    avatar?: string;
    icon?: IconType;
    from?: string;
  };
  selected?: boolean;
}): JSX.Element {
  let className = `timeline-dot`;
  if (props.selected) className += ` selected`;

  /** 아이템 클릭. */
  const handleClick = () => {
    if (props.onClick !== undefined) props.onClick(item.id);
  };

  const { item } = props;

  return (
    <li className="timeline-item">
      <div className="timeline-separator">
        <span className={className} />
        <span className="timeline-connector" />
      </div>
      <div className="timeline-content">
        <Chip
          label={item.label}
          size="xs"
          theme={props.selected ? 'primary' : undefined}
        />
        {item.etc && <em>{item.etc}</em>}
        <span className="subject">
          <a className="eui-hyperlink" onClick={handleClick}>
            {item.subject}
          </a>
        </span>
      </div>
      <div className="timeline-user-info">
        <UserInfo
          avatar={props.user.avatar}
          icon={props.user.icon}
          name={props.user.name}
          date={props.item.updateAt}
          dateType="dateNumber"
        />
      </div>
    </li>
  );
}

export default TimeLineItem;
