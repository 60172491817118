import { useEffect } from 'react';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { dateFormat } from '../../../../../groupware-common/utils/ui';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import { sendNoticeActions } from '../../../../stores/attendance/sendNotice';

function AttendanceSendNoticeRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    if (mount) {
      const start = new Date();
      start.setDate(1);
      queryParams.startDate = dateFormat(start, 'yyyy-MM-DD');
      queryParams.endDate = dateFormat(new Date(), 'yyyy-MM-DD');
      queryParams.searchcode = 'accel';
      const route = {
        pathname,
        search: getQueryParams(queryParams),
      };
      dispatch(
        sendNoticeActions.list({
          route,
        }),
      );
      dispatch(attendancePreferencesActions.findNotice());
    }
    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);
  return null;
}

export default AttendanceSendNoticeRoute;
