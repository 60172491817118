import React from 'react';
import { contrastColor } from '../../groupware-common/utils/ui';

function ColorPicker(props: {
  color: string;
  className?: string;
}): JSX.Element {
  let classname = 'eui-color-picker';
  if (props.className) classname += ` ${props.className}`;

  const style: React.CSSProperties | undefined =
    props.color !== undefined
      ? {
          borderColor: props.color,
          backgroundColor: props.color,
          color: contrastColor(props.color),
        }
      : undefined;

  return (
    <span className={classname}>
      <i className="color-area" style={style} />
    </span>
  );
}

export default ColorPicker;
