import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';
import { RootState } from '../../../../groupware-webapp/app/store';
import {
  CacheEntity,
  CacheEntityMeta,
  Language,
  LocateArg,
} from '../../../../groupware-common/types';
import { deepEqual, getAvatarPath } from '../../../../groupware-common/utils';
import api from '../../../apis/directory/v1/employee';
import { organizationActions } from '../organization';
import { thunkCondition } from '../../../../groupware-webapp/stores/utils';
import { appError } from '../../../../groupware-webapp/stores/common/utils';

const name = 'directory/employee';

/**
 * @property companyId 회사 아이디.
 * @property id 아이디.
 * @property email 이메일.
 * @property no 사원번호.
 * @property representativeOrganizationId 대표 조직 아이디.
 * @property jobPositionId 직위 아이디.
 * @property jobRank 직급.
 * @property enterDate 입사일자.
 * @property avatar 아바타 경로.
 * @property updateAt 수정 날짜.
 * @property nameKey 국제화 이름 키.
 */
type EmployeeItem = {
  companyId: number;
  id: number;
  email: string;
  no: string;
  representativeOrganizationId: number;
  jobPositionId: number;
  jobRank: number;
  enterDate: string;
  avatar: string;
  updateAt: string;
  nameKey: string;
};

export type EmployeeView = {
  companyId: number;
  id: number;
  email: string;
  no: string;
  status: number;
  nameId: number;
  representativeOrganizationId: number;
  jobPositionId: number;
  jobRank: number;
  enterDate: string;
  leaveDate: string;
  companyPhoneNo: string;
  extensionPhoneNo: string;
  mobilePhoneNo: string;
  task: string;
  personalEmail: string;
  birthday: string;
  lunarBirthdayUse: boolean;
  postalCode: string;
  address: string;
  isUserAvatar: boolean; // 사용자 이미지 사용 유무 확인.
  updateAt: string;
  nameKey: string;

  /** 계정 연동 객체. */
  accountLinkage?: {
    provider: string;
    id: string;
  };
};

interface State {
  list: CacheEntity<{
    items: EmployeeItem[];
    totalCount: number;
    search: string;
  }>;
  view: CacheEntity<EmployeeView | null | undefined>;
}

const initialState: State = {
  list: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: { items: [], totalCount: 0, search: '' },
  },
  view: {
    meta: { lastUpdateAt: '', timestamp: 0 },
    data: undefined,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (_: void, { rejectWithValue }) => {
    let meta: CacheEntityMeta | undefined;
    let data:
      | { items: EmployeeItem[]; totalCount: number; search: string }
      | undefined;
    try {
      /*
      const rootState = thunkApi.getState() as RootState;
      // eslint-disable-next-line prettier/prettier
      const { list } = rootState.directory.employee;

      const timestamp = Date.now();
      if (list.meta.timestamp > timestamp - 300000) return { meta, data };

      meta = {
        timestamp,
        lastUpdateAt: await api.findLastUpdateAt(),
      };

      if (list.meta.lastUpdateAt !== meta.lastUpdateAt) {
        const response = await api.findList();
        const items = response.map((x) => {
          return {
            ...x,
            nameKey: api.getNameKey(x.companyId, x.nameId),
          };
        });
        const totalCount = 0;
        const search = '';
        data = { items, totalCount, search };
      }
      */
      const response = await api.findList();
      const items = response.map((x) => {
        return {
          ...x,
          avatar: getAvatarPath(x.companyId, x.id),
          nameKey: api.getNameKey(x.companyId, x.id),
        };
      });
      const totalCount = 0;
      const search = '';
      data = { items, totalCount, search };

      return { meta, data };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      const {
        session: { requests },
      } = getState() as RootState;
      return (
        requests.find(
          (x) => x.type === findList.typePrefix && deepEqual(x.arg, arg),
        ) === undefined
      );
    },
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (arg: { id: number | 'first' } & LocateArg, { rejectWithValue }) => {
    let meta: CacheEntityMeta | undefined;
    let data: EmployeeView | null | undefined;
    let _response_id_: number | undefined;

    try {
      let id: number | null = null;
      if (arg.id === 'first') {
        id = 1;
      } else id = arg.id;

      if (id !== null && arg.route?.pathname.indexOf('{response_id}') !== -1)
        _response_id_ = id;

      const response = await api.findView(id);
      if (response) {
        data = {
          ...response,
          enterDate:
            response.enterDate === '1000-01-01' ? '' : response.enterDate,
          birthday: response.birthday === '1000-01-01' ? '' : response.birthday,

          nameKey: api.getNameKey(response.companyId, response.id),
        };
      }

      return { meta, data, _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(findView.typePrefix, arg, getState);
    },
  },
);

const create = createAsyncThunk(
  `${name}/create`,
  async (
    arg: {
      email: string;
      password: string;
      names: { label: Language; value: string }[];
      enterDate: string;
      no: string;
      companyPhoneNo: string;
      extensionPhoneNo: string;
      mobilePhoneNo: string;
      representativeOrganizationId: number;
      jobPositionId: number;
      jobRank: number;
      task: string;
      personalEmail: string;
      birthday: string;
      lunarBirthdayUse: boolean;
      postalCode: string;
      address: string;
      avatar?: string;
      affiliatedOrganizations: { id: number; jobDutyId: number }[];
      accountLinkage?: {
        provider: string;
        id: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    // console.log(`${create.typePrefix}(arg)`, arg);
    try {
      const param = {
        ...arg,
        names: arg.names.map(({ label: code, value }) => ({ code, value })),
        enterDate: arg.enterDate === '' ? '1000-01-01' : arg.enterDate,
        leaveDate: '9999-12-31',
        birthday: arg.birthday === '' ? '1000-01-01' : arg.birthday,
        accountLinkageId: arg.accountLinkage?.id,
      };

      // console.log(`${create.typePrefix}::param`, param);

      const response = await api.create(param);

      // console.log(`${create.typePrefix}::response`, response);

      const { companyId, id } = response;
      const nameKey = api.getNameKey(companyId, id, false);
      const i18n = getI18n();
      param.names.forEach(({ code, value }) => {
        i18n.addResource(code, api.namespace, nameKey, value);
      });

      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(findList());
      await dispatch(findView({ id }));

      return { _response_id_: id };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(create.typePrefix, arg, getState);
    },
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      id: number;
      no?: string;
      names?: { label: Language; value: string }[];
      organizationId?: number;
      jobPositionId?: number;
      jobRank?: number;
      enterDate?: string; // yyyy-MM-dd
      companyPhoneNo?: string;
      extensionPhoneNo?: string;
      mobilePhoneNo?: string;
      task?: string;
      personalEmail?: string;
      birthday?: string; // yyyy-MM-dd
      lunarBirthdayUse?: boolean;
      postalCode?: string;
      address?: string;
      avatar?: string;
      updateAt: string;
      affiliatedOrganizations?: {
        id: number;
        jobDutyId: number;
        lookupUpdateAt?: string;
        lookupDeleteAt?: string;
      }[];
      accountLinkage?: {
        provider: string;
        id: string;
      };
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    // console.log(`${update.typePrefix}(arg)`, arg);
    try {
      const param = {
        ...arg,
        names: arg.names?.map(({ label: code, value }) => ({ code, value })),
        enterDate: arg.enterDate === '' ? '1000-01-01' : arg.enterDate,
        birthday: arg.birthday === '' ? '1000-01-01' : arg.birthday,
        accountLinkageId: arg.accountLinkage?.id,
      };

      // console.log(`${update.typePrefix}::param`, param);

      const response = await api.update(param);

      // console.log(`${update.typePrefix}::response`, response);

      const { companyId, id } = response;

      const nameKey = api.getNameKey(companyId, id, false);
      const i18n = getI18n();
      param.names?.forEach(({ code, value }) => {
        if (value !== null)
          i18n.addResource(code, api.namespace, nameKey, value);
        else {
          // console.log(
          //   'i18n.services.resourceStore',
          //   i18n.services.resourceStore.data[code][api.namespace],
          // );

          const i18nResource = i18n.services.resourceStore.data[code][
            api.namespace
          ] as Record<string, Record<string, string>>;
          const resourceId = 'employee';
          const resourceName = `${companyId}_${id}`;

          // console.log(
          //   `i18nResource[resourceId:${resourceId}][resourceName:${resourceName}]`,
          //   i18nResource[resourceId][resourceName],
          // );
          delete i18nResource[resourceId][resourceName];
          // console.log(
          //   `i18nResource[resourceId:${resourceId}][resourceName:${resourceName}]`,
          //   i18nResource[resourceId][resourceName],
          // );

          // console.log(
          //   'i18n.services.resourceStore',
          //   i18n.services.resourceStore.data[code][api.namespace],
          // );
        }
      });

      await dispatch(findList());
      await dispatch(findView({ id }));
      await dispatch(organizationActions.findEmployee({ id: 'all' }));

      return { _response_id_: id };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(create.typePrefix, arg, getState);
    },
  },
);

const remove = createAsyncThunk(
  `${name}/remove`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { dispatch, getState, rejectWithValue },
  ) => {
    const { id, updateAt } = arg;
    const { organization } = (getState() as RootState).directory;
    const _response_id_ = organization.employees.data.items.find(
      (a) => a.employeeId === id,
    )?.id;

    try {
      await api.delete(id, updateAt);

      await dispatch(organizationActions.findEmployee({ id: 'all' }));
      await dispatch(findList());
      if (_response_id_)
        await dispatch(organizationActions.findView({ id: _response_id_ }));

      return { _response_id_ };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
  {
    condition: (arg, { getState }): boolean => {
      return thunkCondition(remove.typePrefix, arg, getState);
    },
  },
);

const employeeSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 폴더 배열에 상태 값에 할당.
        if (payload?.meta !== undefined) state.list.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 폴더 배열에 상태 값에 할당.
        if (payload?.data !== undefined) state.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, action) => {
        const { payload } = action;
        // 페이로드에 메타가 있는 경우 해당 폴더 배열에 상태 값에 할당.
        if (payload?.meta !== undefined) state.view.meta = payload.meta;
        // 페이로드에 데이터가 있는 경우 해당 폴더 배열에 상태 값에 할당.
        if (payload?.data !== undefined) state.view.data = payload.data;
      });
  },
});

export default employeeSlice.reducer;

export const employeeActions = {
  findList,
  findView,
  create,
  update,
  delete: remove,
};
