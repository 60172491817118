import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { getPathMap, getQueryParams } from '../../../groupware-common/utils';
import {
  dateFormat,
  timeFormat,
  timezoneDate,
} from '../../../groupware-common/utils/ui';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import dayOffStatusApi from '../../apis/attendance/v1/dayOffStatus';
import AttendancePreferencesApi from '../../apis/attendance/v1/preferences';

const name = 'attendance/dayOffStatus';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

/** 회계연도 연차현황 리스트 객체 */
export interface AccountDayOffStatusListItem {
  companyId: number; // 회사 아이디.
  employeeId: number; // 직원 아이디.
  occursYear: number; // 기준년도.
  enterDate: string; // 입사일자.
  workingYears: number; // 근속 년수.
  workingDays: number; // 근속 일수.
  revisionOccuredLeavesCount: string; // 개정 연차 개수.
  occuredLeavesCount: string; // 발생 연차 개수.
  totalOccuredLeavesCount: string; // 총 연차 개수.
  lapseLeavesCount: string; // 소멸 연차 개수.
  carryoverLeavesCount: string; // 이월 연차 개수.
  modifiedLeavesCount: string; // 조정 연차 개수.
  usedLeavesCount: string; // 사용 연차 개수.
  remainedLeavesCount: string; // 잔여 연차 개수.
}

/** 입사일자 연차현황 리스트 객체 */
export interface EnterDayOffStatusListItem {
  companyId: number; // 회사 아이디.
  employeeId: number; // 직원 아이디.
  enterDate: string; // 입사일자.
  accountingStartDate: string; // 연차 기산 시작일.
  accountingEndDate: string; // 연차 기산 종료일.
  workingYears: number; // 근속 년수.
  revisionOccuredLeavesCount: string; // 개정 연차 개수.
  occuredLeavesCount: string; // 발생 연차 개수.
  totalOccuredLeavesCount: string; // 총 연차 개수.
  lapseLeavesCount: string; // 소멸 연차 개수.
  modifiedLeavesCount: string; // 조정 연차 개수.
  usedLeavesCount: string; // 사용 연차 개수.
  remainedLeavesCount: string; // 잔여 연차 개수.
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: {
    items: AccountDayOffStatusListItem[] | EnterDayOffStatusListItem[];
    totalCount: number;
  };
}

const initialState: State = {
  requests: [],
  list: {
    items: [],
    totalCount: 0,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: { search: string } & LocateArg,
    { rejectWithValue, getState },
  ) => {
    const { search } = arg;
    const isAdmin =
      getPathMap('/*', arg.route?.pathname ?? '') === '/adminconsole';
    const queryParams = getQueryParams(search);
    const { principal } = (getState() as RootState).session;
    const basicSetting = (getState() as RootState).attendance.preferences.basic;
    const organizationIds = principal.affiliatedOrganizations
      .filter((a) => a.manager === true)
      .map(({ id }) => id);

    try {
      if (!isAdmin && organizationIds.length === 0)
        throw new Error('접근 권한이 없습니다.');

      const unit =
        basicSetting.updateAt === ''
          ? (await AttendancePreferencesApi.findAttendanceBasic())
              .expressionUnit
          : basicSetting.expressionUnit;
      const standardType = (await AttendancePreferencesApi.selectUserStandard())
        .leaveOccurType;
      const searchCode = isAdmin ? queryParams.searchCode : 'organization';
      let searchWord: string | undefined;
      if (isAdmin)
        searchWord =
          queryParams.searchCode === '' || queryParams.searchCode === undefined
            ? queryParams.searchWord
            : queryParams.directoryKeyword;
      else
        searchWord =
          queryParams.directoryKeyword ??
          `${principal.companyId}_${organizationIds[0]}`;

      // 입사일자 기준
      if (standardType === 1) {
        const data = {
          standardDate:
            queryParams.status ?? dateFormat(new Date(), 'yyyy-MM-DD'),
          searchCode,
          searchWord,
          pageNo: queryParams.pageNo ?? 1,
          rowsPerPage: queryParams.rowsPerPage ?? 15,
        };
        const items = (await dayOffStatusApi.enterList(data)).map((a) => {
          let modifiedLeavesCount = timeFormat(a.modifingLeaves, unit);
          if (a.modifingLeaves > 0)
            modifiedLeavesCount = `+${modifiedLeavesCount}`;

          return {
            companyId: a.companyId,
            employeeId: a.employeeId,
            enterDate: a.enterDate === null ? '' : a.enterDate,
            accountingStartDate: a.accountingStartDate,
            accountingEndDate: a.accountingEndDate,
            workingYears: a.workingYears,
            revisionOccuredLeavesCount: timeFormat(
              a.revisionOccursLeaves,
              unit,
            ),
            occuredLeavesCount: timeFormat(a.occursLeaves, unit),
            lapseLeavesCount: timeFormat(a.lapseLeaves, unit),
            modifiedLeavesCount,
            usedLeavesCount: timeFormat(a.useLeaves, unit),
            remainedLeavesCount: timeFormat(a.remainingLeaves, unit),
            totalOccuredLeavesCount: timeFormat(
              a.revisionOccursLeaves + a.occursLeaves,
              unit,
            ),
          };
        });
        const totalCount: number = await dayOffStatusApi.enterListTotalCount({
          standardDate:
            queryParams.status ?? dateFormat(new Date(), 'yyyy-MM-DD'),
          searchCode,
          searchWord,
        });

        return { items, totalCount };
      }

      // 회계연도 기준
      const data = {
        standardYear:
          queryParams.status ?? timezoneDate().getFullYear().toString(),
        searchCode,
        searchWord,
        pageNo: queryParams.pageNo ?? 1,
        rowsPerPage: queryParams.rowsPerPage ?? 15,
      };
      const items = (await dayOffStatusApi.accountList(data)).map((a) => {
        let modifiedLeavesCount = timeFormat(a.modifiedLeavesCount, unit);
        if (a.modifiedLeavesCount > 0)
          modifiedLeavesCount = `+${modifiedLeavesCount}`;
        return {
          ...a,
          enterDate: a.enterDate === null ? '' : a.enterDate,
          revisionOccuredLeavesCount: timeFormat(
            a.revisionOccuredLeavesCount,
            unit,
          ),
          occuredLeavesCount: timeFormat(a.occuredLeavesCount, unit),
          lapseLeavesCount: timeFormat(a.lapseLeavesCount, unit),
          carryoverLeavesCount: timeFormat(a.carryoverLeavesCount, unit),
          modifiedLeavesCount,
          usedLeavesCount: timeFormat(a.usedLeavesCount, unit),
          remainedLeavesCount: timeFormat(a.remainedLeavesCount, unit),
          totalOccuredLeavesCount: timeFormat(
            a.revisionOccuredLeavesCount + a.occuredLeavesCount,
            unit,
          ),
        };
      });
      const totalCount: number = await dayOffStatusApi.accountTotalCount({
        standardYear:
          queryParams.status ?? timezoneDate().getFullYear().toString(),
        searchCode,
        searchWord,
      });
      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 회계연도 기준 수동 연차 발생. */
const createAccountOccurs = createAsyncThunk(
  `${name}/createAccountOccurs`,
  async (
    arg: {
      search: string;
      standardDate: string;
      creatorId?: number;
      createAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    const { search, standardDate, creatorId, createAt } = arg;
    try {
      const response = await dayOffStatusApi.createAccountOccurs({
        standardDate,
        creatorId,
        createAt,
      });
      await dispatch(findList({ search }));
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 입사일자 기준 수동 연차 발생. */
const createEnterDateOccurs = createAsyncThunk(
  `${name}/createEnterDateOccurs`,
  async (
    arg: {
      search: string;
      standardDate: string;
      creatorId?: number;
      createAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    const { search, standardDate, creatorId, createAt } = arg;
    try {
      const response = await dayOffStatusApi.createEnterDateOccurs({
        standardDate,
        creatorId,
        createAt,
      });
      await dispatch(findList({ search }));
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const dayOffStatusReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );

          if (payload !== undefined) state.list = payload;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default dayOffStatusReducer.reducer;

export const statusActions = {
  list: findList,

  createAccountOccurs,
  createEnterDateOccurs,
};
