import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import CalendarFolderBoxContainer from '../pages/adminconsole/folderbox/CalendarFolderBoxContainer';
import CalendarPreferencesContainer from '../pages/adminconsole/preferences/CalendarPreferencesContainer';
import CalendarSubFolderContainer from '../pages/adminconsole/subFolder/CalendarSubFolderContainer';

function AdminConsoleLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  // 구독 캘린더
  if (menu === 'sub')
    return <CalendarSubFolderContainer pathname={pathname} search={search} />;
  // 공유 캘린더
  if (menu === 'all')
    return <CalendarFolderBoxContainer pathname={pathname} search={search} />;
  return <CalendarPreferencesContainer />;
}

export default AdminConsoleLocateContainer;
