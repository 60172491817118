import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { folderBoxActions } from '../../../../stores/folder';
import { preferencesActions } from '../../../../stores/preferences';

function BoardPreferencesRoute(props: { pathname: string }): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    if (mount)
      Promise.all([
        dispatch(preferencesActions.findPreferences()),
        dispatch(preferencesActions.findAdministrators()),
        dispatch(folderBoxActions.allFolderList()),
      ]).then(() => {
        dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
      });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);
  return null;
}

export default BoardPreferencesRoute;
