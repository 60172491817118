import React from 'react';
import Dialog from '../../../../../components/dialog/Dialog';
import PostView from '../../../../../components/post/PostView';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import AttachmentsList from '../../../../../components/attachments/AttachmentsList';
import AttachDocumentList from '../../../../../components/attachments/AttachDocumentList';
import AttachDocumentItem from '../../../../../components/attachments/AttachDocumentItem';
import AttachmentsItem from '../../../../../components/attachments/AttachmentsItem';

function PreviewDialog(props: {
  content: string;
  attachedDocuments?: {
    companyId: number;
    id: number;
    no: string;
    subject: string;
  }[];
  attachedFiles?: {
    id: string;
    name: string;
    size: number;
    file?: File;
  }[];
  onClose(): void;
}): JSX.Element {
  const { onClose, content, attachedDocuments, attachedFiles } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} size="md">
      <DialogHeader>
        <DialogTitle>미리보기</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <PostView>
          <PostView.Body>
            <PostView.Content data={content} />
            {attachedFiles && attachedFiles.length > 0 && (
              <AttachmentsList
                count={attachedFiles.length}
                className="view-attachments"
              >
                {attachedFiles &&
                  attachedFiles.map(({ id, name, size }) => (
                    <AttachmentsItem key={id} name={name} size={size} />
                  ))}
              </AttachmentsList>
            )}
            {attachedDocuments && attachedDocuments.length > 0 && (
              <AttachDocumentList count={attachedDocuments.length}>
                {attachedDocuments.map((x) => (
                  <AttachDocumentItem
                    key={x.id}
                    no={x.no}
                    subject={x.subject}
                  />
                ))}
              </AttachDocumentList>
            )}
          </PostView.Body>
        </PostView>
      </DialogBody>
    </Dialog>
  );
}

export default PreviewDialog;
