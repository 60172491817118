import React from 'react';
import ComponentButtonBasic from './ComponentButtonBasic';
import ComponentButtonText from './ComponentButtonText';
import ComponentButtonContained from './ComponentButtonContained';
import ComponentButtonOutlined from './ComponentButtonOutlined';
import ComponentButtonIcon from './ComponentButtonIcon';
import ComponentButtonBlock from './ComponentButtonBlock';
import ComponentButtonDropDown from './ComponentButtonDropDown';
import ComponentButtonLoading from './ComponentButtonLoading';
import ComponentButtonSize from './ComponentButtonSize';

function ComponentButton(): JSX.Element {
  return (
    <>
      <ComponentButtonBasic />
      <ComponentButtonText />
      <ComponentButtonContained />
      <ComponentButtonOutlined />
      <ComponentButtonIcon />
      <ComponentButtonBlock />
      <ComponentButtonDropDown />
      <ComponentButtonSize />
      <ComponentButtonLoading />
    </>
  );
}

export default ComponentButton;
