import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

const namespace = 'attendance';

async function fetchFolderList(
  pageNo: number,
  rowsPerPage: number,
): Promise<
  {
    companyId: number;
    id: number;
    parentId: number;
    seq: number;
    name: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work/folder/all?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}`;
    const response = await axios.get<
      {
        companyId: number;
        id: number;
        parentId: number;
        seq: number;
        name: string;
        updateAt: string;
      }[]
    >(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchList(
  folderId?: number,
  status?: string,
): Promise<
  {
    id: number; // 업무 아이디',
    folderId: number; // 폴더 아이디',
    seq: number;
    status: number; // 상태 - 1: 사용, 2: 중지',
    name: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    let search = '';
    if (folderId && status === undefined) search = `?folderid=${folderId}`;
    if (folderId === undefined && status) search = `?status=${status}`;
    if (folderId && status) search = `?folderid=${folderId}&status=${status}`;
    const url = `${host}/api/attendance/v1/work/all${search}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchView(arg: {
  affiliatedCompanyId?: number;
  id: number;
}): Promise<{
  id: number;
  folderId: number;
  seq: number;
  status: number;
  name: string;
  attendanceCode: number;
  formId: number;
  formName: string;
  receiptFormId: number;
  receiptFormName?: string;
  documentNo: string;
  retentionPeriod: number;
  approvalLine: string;
  referrer: string;
  viewer: string;
  useAttachFile: number;
  useAttachDocument: number;
  useOpinion: boolean;
  useComment: boolean;
  description: string;
  updateAt: string;
}> {
  const { affiliatedCompanyId, id } = arg;
  try {
    const { host, headers } = getApiConfig();
    let url = `${host}/api/attendance/v1/work?id=${id}`;
    if (affiliatedCompanyId)
      url += `affiliatedcompanyid=${affiliatedCompanyId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function saveFolder(arg: {
  id: number | undefined;
  updateAt?: string;
  parentId: number;
  name: string;
}): Promise<{
  companyId: number;
  id: number;
  parentId: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work/folder`;
    const response =
      arg.id === undefined
        ? await axios.post(url, arg, { headers })
        : await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function removeFolder(arg: {
  id: number;
  updateAt: string;
}): Promise<{ companyId: number; id: number; updateAt: string }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function sortFolder(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<{
  id: number;
  seq: number;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function save(arg: {
  id: number | undefined;
  updateAt: string | undefined;
  folderId: number;
  status: number;
  formId: number;
  receiptFormId: number | null;
  name: string;
  attendanceCode: number;
  documentNo: string;
  retentionPeriod: number;
  approvalLine: string;
  referrer: string; // TODO 백엔드 컬럼명 변경 예정. referencePermission
  viewer: string; // TODO 백엔드 컬럼명 변경 예정. viewPermission
  useAttachFile: number;
  useAttachDocument: number;
  useOpinion: boolean;
  useComment: boolean;
  description: string;
}): Promise<{
  id: number;
  companyId: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work`;
    const response =
      arg.id === undefined
        ? await axios.post<{
            id: number;
            seq: number;
            updateAt: string;
          }>(url, arg, { headers })
        : await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function remove(arg: {
  id: number;
  updateAt: string;
}): Promise<{ companyId: number; id: number; updateAt: string }> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function sortWork(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<{
  id: number;
  seq: number;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/attendance/v1/work/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const attendanceWorkFolderApi = {
  namespace,
  fetchList: fetchFolderList,
  save: saveFolder,
  delete: removeFolder,
  sort: sortFolder,
};

const attendanceWorkApi = {
  namespace,
  fetchList,
  fetchView,
  save,
  delete: remove,
  sort: sortWork,
};

export { attendanceWorkFolderApi };

export default attendanceWorkApi;
