import React, { useState } from 'react';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentSimpleSearch(): JSX.Element {
  const [state, setState] = useState<{
    keyword: string;
  }>({
    keyword: '',
  });

  const handleSimpleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      keyword: event.target.value,
    }));
  };

  return (
    <ComponentSampleUI.Content title="Simple">
      <SimpleSearch keyword={state.keyword} onSearch={handleSimpleSearch} />
    </ComponentSampleUI.Content>
  );
}

export default ComponentSimpleSearch;
