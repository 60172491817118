import React from 'react';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Button from '../../../../../components/button/Button';

function DefaultPreferencesContainer(): JSX.Element {
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>기본설정</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiToolbar>
          <EuiToolbar.Left>
            <Button text="저장" variant="contained" />
            <Button text="취소" />
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
    </>
  );
}

export default DefaultPreferencesContainer;
