import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { b62 } from '../../../../../groupware-common/utils';
import {
  BoardReadOnlyItem,
  BoardViewItem,
  CommentItem,
} from '../../../../stores/board';

interface ReturnType {
  companyId: number;
  id: number;
  updateAt: string;
}

interface ListItem {
  folderId: number; // 폴더 아이디.
  id: number; // 아이디.
  employeeId: number; // 작성자 아이디.
  titleClassification: string; // 말머리.
  subject: string; // 제목.
  views: number; // 조회 수.
  likes: number; // 좋아요 수.
  comments: number; // 덧글 수.
  attachedFiles?: {
    fileId: number;
    name: string;
  }[];
  isSecure: boolean; // 보안게시 여부.
  isStarred: boolean; // 중요 표시 여부.
  isViewed: boolean; // 조회 표시 여부.
  isThumbnail: boolean; // 썸네일 여부.
  thumbnailPath: string | null;
  deleterId: number | null;
  createAt: string; // 작성일.
  updateAt: string; // 수정일.
}

interface ViewerItem {
  postId: number;
  employeeCompanyId: number;
  employeeId: number;
  createAt: string;
  updateAt?: string;
}

interface AttachFile {
  path: string;
  fileId: number; // 파일 자체 내부코드.
  seq: number;
  name: string;
  size: number;
  copy?: boolean;
  delete?: boolean; // 임시 보관 수정 저장 또는 수정 상신인 경우만 값이 있습니다.
}

export interface LikesReturnType extends ReturnType {
  employeeId: number;
  likes: number; // 게시글의 좋아요 개수.
  isLiked: boolean; // 대상 직원의 좋아요 표시 여부.
}

export interface StarReturnType extends ReturnType {
  employeeId: number;
  isStarred: boolean; // 대상 직원의 중요 표시 여부.
}

interface MoveReturnType extends ReturnType {
  folderId: number;
}

/** 게시글 목록 조회. */
async function findList(arg: {
  folderid?: number;
  isStarred: boolean;
  isTemporary: boolean;
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  titleclassificationid?: number;
  pageno: number;
  rowsperpage: number;
}): Promise<ListItem[]> {
  try {
    const { headers, host } = getApiConfig();
    let url = `${host}/api/board/v1/post/all/all`;
    if (arg.folderid === 0) {
      if (arg.isStarred) url = `${host}/api/board/v1/post/starred/all`;
      if (arg.isTemporary) url = `${host}/api/board/v1/post/temporary/all`;
    } else if (arg.folderid && arg.folderid !== 0)
      url = `${host}/api/board/v1/post/folder/all`;
    const params = {
      folderid: arg.folderid === 0 ? undefined : arg.folderid,
      startdate: arg.startdate,
      enddate: arg.enddate,
      searchcode: arg.searchcode,
      searchword: arg.searchword,
      isdirectoryselected: arg.isdirectoryselected,
      titleclassificationid: arg.titleclassificationid,
      pageno: arg.pageno,
      rowsperpage: arg.rowsperpage,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 목록 총 개수 조회. */
async function findTotalCount(arg: {
  folderid?: number;
  startdate?: string;
  enddate?: string;
  isStarred: boolean;
  isTemporary: boolean;
  titleclassificationid?: number;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { headers, host } = getApiConfig();
    let url = `${host}/api/board/v1/post/all/all/totalcount`;
    if (arg.folderid === 0) {
      if (arg.isStarred)
        url = `${host}/api/board/v1/post/starred/all/totalcount`;
      if (arg.isTemporary)
        url = `${host}/api/board/v1/post/temporary/all/totalcount`;
    } else if (arg.folderid && arg.folderid !== 0)
      url = `${host}/api/board/v1/post/folder/all/totalcount`;
    const params = {
      folderid: arg.folderid === 0 ? undefined : arg.folderid,
      startdate: arg.startdate,
      enddate: arg.enddate,
      searchcode: arg.searchcode,
      searchword: arg.searchword,
      titleclassificationid: arg.titleclassificationid,
      isdirectoryselected: arg.isdirectoryselected,
    };
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공지에 등록된 게시글 목록 조회. */
async function findNoticeList(folderid: number): Promise<ListItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/notified/all?folderid=${folderid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 삭제함 리스트 조회. */
async function findTrashList(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  pageno: number;
  rowsperpage: number;
}): Promise<ListItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/deleted/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 삭제함 리스트 총 개수 조회. */
async function findTrashTotalCount(arg: {
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
}): Promise<number> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/deleted/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 게시글 복원. */
async function restore(arg: {
  id: number;
  updateAt: string;
}): Promise<ReturnType>;
async function restore(
  arg: { id: number; updateAt: string }[],
): Promise<ReturnType[]>;
async function restore(
  arg: { id: number; updateAt: string } | { id: number; updateAt: string }[],
): Promise<ReturnType | ReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/board/v1/post/restore/all`
      : `${host}/api/board/v1/post/restore`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}
/** 보안게시글 조회를 위한 비밀번호 비교. */
async function securalCompare(arg: {
  postid: number;
  securalpassword: string;
}): Promise<boolean> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/secural/compare`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 보안 여부. */
async function isSecretView(id: number): Promise<{
  companyId: number;
  id: number;
  isSecure: boolean;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/issecure?id=${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 단일 조회. */
async function findView(arg: {
  id: number;
  updateAt?: string;
}): Promise<BoardViewItem> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 읽기 전용 단일 조회. (조회수 update X) */
async function findReadOnlyView(arg: {
  id: number;
  updateAt?: string;
}): Promise<BoardReadOnlyItem> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/force`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글의 이전 또는 다음 게시글 아이디를 조회 합니다. */
async function readPrevIdOrNextId(arg: {
  type: 'prev' | 'next';
  folderId?: string; // 폴더 아이디.
  id: number; // 게시글 아이디.
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  titleclassificationid?: number;
}): Promise<{ companyId: number; id: number } | undefined> {
  const {
    type,
    folderId,
    id,
    startdate,
    enddate,
    searchcode,
    searchword,
    isdirectoryselected,
    titleclassificationid,
  } = arg;
  const params = {
    id,
    startdate,
    enddate,
    searchcode,
    searchword,
    isdirectoryselected,
    titleclassificationid,
  };

  try {
    const { host, headers } = getApiConfig();
    // 모든 게시함인 경우.
    if (!folderId || folderId === 'all') {
      const url = `${host}/api/board/v1/post/all/${type}`;
      const response = await axios.get(url, { headers, params });
      return response.data;
    }
    // 중요 게시함인 경우.
    if (folderId === 'importance') {
      const url = `${host}/api/board/v1/post/starred/${type}`;
      const response = await axios.get(url, { headers, params });
      return response.data;
    }
    // return undefined;
    // 특정 게시함인 경우.
    const folderParams = {
      ...params,
      folderid: b62(folderId),
    };
    const url = `${host}/api/board/v1/post/folder/${type}`;
    const response = await axios.get(url, { headers, params: folderParams });
    return response.data;
  } catch (ex) {
    throw apiError(ex);
  }
}

/** 결재 문서의 이전 아이디를 조회 합니다. */
async function prevId(arg: {
  folderId?: string; // 폴더 아이디.
  id: number; // 게시글 아이디.
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  titleclassificationid?: number;
}): Promise<{ companyId: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'prev' });
}

/** 결재 문서의 다음 아이디를 조회 합니다. */
async function nextId(arg: {
  folderId?: string; // 폴더 아이디.
  id: number; // 게시글 아이디.
  startdate?: string;
  enddate?: string;
  searchcode?: string;
  searchword?: string;
  isdirectoryselected: boolean;
  titleclassificationid?: number;
}): Promise<{ companyId: number; id: number } | undefined> {
  return readPrevIdOrNextId({ ...arg, type: 'next' });
}

/** 게시글 폴더 이동. */
async function moveFolder(
  arg:
    | {
        folderId: number;
        id: number;
        updateAt: string;
      }
    | {
        folderId: number;
        id: number;
        updateAt: string;
      }[],
): Promise<MoveReturnType | MoveReturnType[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/board/v1/post/move/all`
      : `${host}/api/board/v1/post/move`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 댓글 조회. */
async function findComments(arg: { postid: number }): Promise<CommentItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/comment/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 댓글 작성. */
async function createComments(arg: {
  postId: number;
  parentId: number;
  employeeId: number;
  comment: string;
  ip: string;
}): Promise<CommentItem> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/comment`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 댓글 수정. */
async function updateComments(arg: {
  id: number;
  postId: number;
  comment: string;
  ip: string;
  updateAt: string;
}): Promise<CommentItem> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/comment`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 댓글 삭제. */
async function deleteComments(arg: {
  id: number;
  postId: number;
  parentId: number;
  employeeId: number;
  updateAt: string;
}): Promise<CommentItem> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/comment`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요표시 저장, 해제. */
async function saveUnSaveStar(arg: {
  star: boolean;
  id: number;
  employeeId: number;
}): Promise<StarReturnType> {
  const data = { id: arg.id, employeeId: arg.employeeId };
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/star`;
    if (arg.star) {
      const response = await axios.post(url, data, { headers });
      return response.data;
    }

    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 좋아요 표시 저장, 해제. */
async function saveUnSaveLike(arg: {
  like: boolean;
  id: number;
  employeeId: number;
}): Promise<LikesReturnType> {
  const data = { id: arg.id, employeeId: arg.employeeId };
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/like`;
    if (arg.like) {
      const response = await axios.post(url, data, { headers });
      return response.data;
    }

    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 조회자 조회. */
async function findViewerList(arg: {
  postid: number;
  viewer?: string;
  pageno: number;
  rowsperpage: number;
}): Promise<ViewerItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/view/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 조회자 총 개수 조회. */
async function findViewerTotalCount(arg: {
  postid: number;
  viewer?: string;
}): Promise<number> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/view/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 좋아요 확인 조회. */
async function findLikerList(arg: {
  postid: number;
  viewer?: string;
  pageno: number;
  rowsperpage: number;
}): Promise<ViewerItem[]> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/like/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 좋아요 확인 총 개수 조회. */
async function findLikerTotalCount(arg: {
  postid: number;
  viewer?: string;
}): Promise<number> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/like/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 작성. */
async function create(arg: {
  folderId: number;
  titleClassificationId: number;
  formId: number;
  subject: string;
  contents: string;
  isSecure: boolean;
  securalPassword?: string;
  isAlarmSend: boolean;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: AttachFile[];
}): Promise<ReturnType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 수정 */
async function update(arg: {
  id: number;
  folderId: number;
  titleClassificationId: number;
  formId: number;
  subject: string;
  contents: string;
  isSecure: boolean;
  securalPassword?: string;
  isAlarmSend: boolean;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: AttachFile[];
  updateAt: string;
}): Promise<ReturnType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 최초의 임시저장. */
async function temporary(arg: {
  folderId: number;
  titleClassificationId: number;
  formId: number;
  subject: string;
  contents: string;
  isSecure: boolean;
  securalPassword?: string;
  isAlarmSend: boolean;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: AttachFile[];
}): Promise<ReturnType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/temporary`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 임시저장 게시글 수정 후 임시저장. */
async function reTemporary(arg: {
  id: number;
  folderId: number;
  titleClassificationId: number;
  formId: number;
  subject: string;
  contents: string;
  isSecure: boolean;
  securalPassword?: string;
  isAlarmSend: boolean;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: AttachFile[];
  updateAt: string;
}): Promise<ReturnType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/temporary`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 임시저장 게시글을 활성화. */
async function tempToActive(arg: {
  id: number;
  folderId: number;
  titleClassificationId: number;
  formId: number;
  subject: string;
  contents: string;
  isSecure: boolean;
  securalPassword?: string;
  isAlarmSend: boolean;
  isNotified: boolean;
  notifyStartDate?: string;
  notifyEndDate?: string;
  retentionPeriod: string;
  attachedFiles?: AttachFile[];
  updateAt: string;
}): Promise<ReturnType> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/temporary/active`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 삭제. */
async function deleteBoard(
  arg:
    | {
        id: number;
        updateAt: string;
      }
    | {
        id: number;
        updateAt: string;
      }[],
): Promise<ReturnType | ReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/board/v1/post/all`
      : `${host}/api/board/v1/post`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 게시글 완전 삭제. */
async function forceDeleteBoard(
  arg:
    | {
        id: number;
        updateAt: string;
      }
    | {
        id: number;
        updateAt: string;
      }[],
): Promise<ReturnType | ReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = Array.isArray(arg)
      ? `${host}/api/board/v1/post/force/all`
      : `${host}/api/board/v1/post/force`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 삭제함 비우기. */
async function vacateBoard(): Promise<ReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/post/vacate`;
    const response = await axios.delete(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 게시함 별 사용자 목록 설정 조회. */
async function findFolderSetting(arg: {
  folderid: number;
  employeeid: number;
}): Promise<{
  companyId: number;
  folderId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/folder/config`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 특정 게시함 별 사용자 목록 설정 저장. */
async function saveFolderSetting(arg: {
  folderId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  folderId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/folder/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 모든 게시함 사용자 목록 설정 조회. */
async function findAllFolderSetting(employeeid: number): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/all/config?employeeid=${employeeid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 모든 게시함 사용자 목록 설정 저장. */
async function saveAllFolderSetting(arg: {
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/all/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요 게시함 사용자 목록 설정 조회. */
async function findStarredSetting(employeeid: number): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/starred/config?employeeid=${employeeid}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 중요 게시함 사용자 목록 설정 저장. */
async function saveStarredSetting(arg: {
  employeeId: number;
  configs: string;
  updateAt: string;
}): Promise<{
  companyId: number;
  employeeId: number;
  configs: string;
  updateAt: string;
}> {
  try {
    const { headers, host } = getApiConfig();
    const url = `${host}/api/board/v1/post/starred/config`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const boardsApi = {
  findList,
  findTotalCount,
  findNoticeList,

  trashList: findTrashList,
  trashTotalCount: findTrashTotalCount,
  restore,

  securalCompare,
  isSecretView,
  findView,
  findReadOnlyView,
  prevId,
  nextId,

  moveFolder,

  findComments,
  createComments,
  updateComments,
  deleteComments,

  viewerList: findViewerList,
  viewerTotalCount: findViewerTotalCount,

  likerList: findLikerList,
  likerTotalCount: findLikerTotalCount,

  saveUnSaveStar,
  saveUnSaveLike,
  create,
  update,

  temporary,
  reTemporary,
  tempToActive,

  deleteBoard,
  forceDeleteBoard,
  vacateBoard,

  folderSetting: findFolderSetting,
  allFolderSetting: findAllFolderSetting,
  starredSetting: findStarredSetting,

  saveFolderSetting,
  saveAllFolderSetting,
  saveStarredSetting,
};

export default boardsApi;
