import React from 'react';

function HTTP404(props: { message?: string }): JSX.Element {
  const { message } = props;

  return (
    <div className="eui-error-page">
      <img src="/images/404.svg" alt="404에러 일러스트" />
      <h1>페이지를 찾을 수 없습니다.</h1>
      {!message ? (
        <>
          <p>
            존재하지 않는 주소를 입력하였거나, 요청하신 페이지의 주소가 변경,
            삭제되어 찾을 수 없습니다.
          </p>
        </>
      ) : (
        <>
          <p>{message}</p>
        </>
      )}
    </div>
  );
}

export default HTTP404;
