import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { documentsActions } from '../../../../stores/document/document';

function DocumentContentRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const { folderId, itemId } = getPathParams<{
    folderId?: string;
    itemId?: number;
  }>('/:module/:folderId/:itemId$base62', pathname);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    if (mount) {
      if (folderId !== undefined && itemId !== undefined) {
        // 문서 단일 조회.
        dispatch(
          documentsActions.view({
            id: itemId,
            route: {
              pathname,
              search,
            },
          }),
        );
      } else if (folderId !== undefined && itemId === undefined) {
        dispatch(
          documentsActions.list({
            folderId,
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
            isCheckout: folderId === 'checkout',
            route: {
              pathname,
              search,
            },
          }),
        );
      } else {
        // 모든 문서함 조회.
        dispatch(
          documentsActions.list({
            isStarred: folderId === 'importance',
            isTemporary: folderId === 'temp',
            isCheckout: folderId === 'checkout',
            route: {
              pathname,
              search,
            },
          }),
        );
      }
    }
    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default DocumentContentRoute;
