import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiError } from '../../../../../groupware-common/types/error';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { formApi } from '../../../../apis/board/v1/form';
import BoardFormEditContentDialog from './BoardFormEditContentDialog';
import BoardFormEditInfoDialog from './BoardFormEditInfoDialog';

function BoardFormEdit(props: {
  folders: { id: number; parentId: number; text: string }[];
  folderId?: number;
  id?: number;
  onClose(): void;
  onSave(
    arg:
      | {
          id?: undefined;
          folderId: number;
          name: string;
          description: string;
          status: boolean;
          content: string;
        }
      | {
          id: number;
          folderId: number;
          name: string;
          description: string;
          status: boolean;
          content: string;
          updateAt: string;
        },
  ): void;
}): JSX.Element {
  const dispatch = useDispatch();
  const [state, setState] = useState(() => {
    return {
      mode: (props.id === undefined ? 'create' : 'loading') as
        | 'create'
        | 'update'
        | 'loading',
      type: 'info' as 'info' | 'content',
      id: props.id,
      folderId: props.folderId ?? 0,
      name: '',
      content: '',
      status: true,
      description: '',
      updateAt: '',
    };
  });

  useEffect(() => {
    let mount = true;
    async function run(id: number) {
      try {
        const view: {
          companyId: number;
          id: number;
          folderId: number;
          status: number;
          name: string;
          contents: string;
          description: string;
          createAt: string;
          updateAt: string;
        } = await formApi.form({ id });
        if (mount) {
          // console.log(`-----view`, view);
          if (view.id === 0) {
            return;
          }
          setState((prev) => {
            return {
              ...prev,
              mode: 'update',
              folderId: view.folderId,
              name: view.name,
              content: view.contents,
              status: view.status === 1,
              description: view.description,
              updateAt: view.updateAt,
            };
          });
        }
      } catch (ex) {
        dispatch(sessionActions.error(ex as ApiError));
      }
    }
    if (props.id !== undefined) run(props.id);
    return () => {
      mount = false;
    };
  }, []);

  const { mode, folderId, name, description, status, type } = state;

  const handleSave = (arg: { content: string }) => {
    if (props.id)
      props.onSave({
        id: props.id,
        folderId: state.folderId,
        content: arg.content,
        description: state.description,
        name: state.name,
        status: state.status,
        updateAt: state.updateAt,
      });
    else
      props.onSave({
        folderId: state.folderId,
        content: arg.content,
        description: state.description,
        name: state.name,
        status: state.status,
      });
  };

  if (type === 'content') {
    const { onClose } = props;
    return (
      <BoardFormEditContentDialog
        name={state.name}
        content={state.content}
        goInfo={() => setState((prevState) => ({ ...prevState, type: 'info' }))}
        onCancel={onClose}
        onSave={handleSave}
      />
    );
  }

  const { folders, onClose } = props;
  return (
    <BoardFormEditInfoDialog
      mode={mode}
      folders={folders}
      folderId={folderId}
      name={name}
      description={description}
      status={status}
      onCancel={onClose}
      onConfirm={(arg) =>
        setState((prevState) => ({ ...prevState, type: 'content', ...arg }))
      }
    />
  );
}

export default BoardFormEdit;
