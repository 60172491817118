import axios from 'axios';
import {
  EntityCompanyKeyable,
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';

/**
 * 디렉터리 기본설정.
 * @property companyId 회사 아이디.
 * @property jobClass 직위, 직책 표시 종류.
 * @property updateAt 수정 날짜.
 */
type DirectoryPreferences = {
  companyId: number;
  jobClass: number;
  updateAt: string;
};

async function find(): Promise<DirectoryPreferences> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/preferences`;
    const response = await axios.get<DirectoryPreferences>(url, { headers });
    // console.log('response: ', response);
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function save(arg: {
  jobClass: number;
  updateAt: string;
}): Promise<EntityCompanyKeyable> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/preferences`;
    const response = await axios.put<EntityCompanyKeyable>(url, arg, {
      headers,
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export default {
  find,
  save,
};
