import React from 'react';
import { IconType } from '../../groupware-common/types/icon';
import Tooltip from '../tooltip/Tooltip';

function Icon(props: {
  icon: IconType;
  style?: React.CSSProperties;
  label?: string;
  color?: string;
  theme?: 'primary' | 'danger' | 'warning' | 'success' | 'info';
  className?: string;
  tooltip?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}): JSX.Element {
  let classname = `eui-icon eui-icon-${props.icon}`;
  if (props.className) classname += ` ${props.className}`;
  if (props.theme) classname += ` ${props.theme}`;
  let style: React.CSSProperties | undefined = props.color
    ? { color: `${props.color}` }
    : undefined;
  if (props.style) style = { ...style, ...props.style };

  return (
    <>
      {props.tooltip !== undefined ? (
        <Tooltip title={props.tooltip} placement={props.placement}>
          <i
            className={classname}
            aria-label={props.label}
            style={props.style}
          />
        </Tooltip>
      ) : (
        <i className={classname} aria-label={props.label} style={style} />
      )}
    </>
  );
}

export default Icon;
