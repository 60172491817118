import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { sleep } from '../../../groupware-common/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import preferencesApi from '../../apis/approval/v1/preferences';
import roleApi from '../../apis/approval/v1/role';

/** 기본설정 */
const name = 'approval/preferences';

interface PreferencesState {
  basic: {
    companyLogoPath: string; // 회사 로고 경로.
    usePreviousApprovalCancel: boolean; // 이전 승인 취소(전단계 반려) 사용 여부.
    useApprovalCancel: boolean; // 결재 취소 사용 여부.
    useApprovalDefer: boolean; // 후결 사용 여부.
    useMeet: boolean; // 대면 사용 여부.

    useExpecterDocumentView: boolean; // 결재 예정자 문서 보기 사용 여부.
    useShowReadStatus: boolean; // 읽음 상태(열람, 미열람) 표시 사용 여부.

    useSignatureImage: boolean; // 서명 이미지 사용 여부.
    useDocumentOrganizationPublic: boolean; // 문서 조직 공개.
    updateAt: string; // 수정 날짜.

    allocableCapacity: number; // 할당 가능 용량. (byte)
    capacityInUse: number; // 사용 중인 용량. (byte)
    capacityLimit: number; // 용량 한도. (byte)
    numberOfAttachments: number; // 첨부 개수.
    attachmentsCapacity: number; // 첨부 용량. (byte)
  };
  documentNo?: {
    numberingTime: number; // 채번 시점 - 1: 상신, 2: 완료
    numberingMethod: number; // 채번 방식 - 1: 양식 & 부서별 일련번호, 2: 전사 공통 일련번호, 3: 양식별 일련번호, 4: 부서별 일련번호
    useTopLevelOrganization: boolean; // 최상위 조직 이름 사용 여부 - 1: 사용, 0: 사용안함
    numberingReset: number; // 채번 초기화 - 1: 매년 문서번호 초기화, 2: 매월 문서번호 초기화
    syncIncomingAndOutgoing: boolean; // 수신 및 발신 동기화 - 1: 사용, 0: 사용안함
    updateAt: string; // 수정 날짜.
  };

  approvalType: string[]; // 결재 유형 옵션. [DRAFT, APPROVAL, AGREEMENT, RECIPIENT, AUDIT]

  administrators: { employeeId: number; updateAt: string }[];
}

const initialState: PreferencesState = {
  basic: {
    companyLogoPath: '',
    usePreviousApprovalCancel: false,
    useApprovalCancel: false,
    useApprovalDefer: false,
    useMeet: false,

    useExpecterDocumentView: true,
    useShowReadStatus: false,

    useSignatureImage: false,
    useDocumentOrganizationPublic: false,

    allocableCapacity: 0,
    capacityInUse: 0,
    capacityLimit: 0,
    numberOfAttachments: 0,
    attachmentsCapacity: 0,

    updateAt: '1000-01-01T00:00:00',
  },

  approvalType: [],
  administrators: [],
};

const findBasic = createAsyncThunk(
  `${name}/findBasic`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      const response = await preferencesApi.selectBasic();
      // console.log(`findBasic(arg):response`, response);
      const {
        companyId,
        logoPath,
        optionsApproval,
        optionsDocumentInformation,
        useSign,
        usePublic,
        allocableCapacity,
        capacityInUse,
        capacityLimit,
        numberOfAttachments,
        attachmentsCapacity,
        updateAt,
      } = response;

      return {
        companyLogoPath:
          logoPath !== ''
            ? `/approval-static/image/${companyId}/${logoPath}`
            : '', // 회사 로고 경로.

        // eslint-disable-next-line no-bitwise
        usePreviousApprovalCancel: (optionsApproval & 1) === 1, // 이전 승인 취소(전단계 반려) 사용 여부.
        // eslint-disable-next-line no-bitwise
        useApprovalCancel: (optionsApproval & 2) === 2, // 결재 취소 사용 여부.
        // eslint-disable-next-line no-bitwise
        useApprovalDefer: (optionsApproval & 4) === 4, // 후결 사용 여부.
        // eslint-disable-next-line no-bitwise
        useMeet: (optionsApproval & 8) === 8, // 대면 사용 여부.

        // 결재 예정자 문서 보기 사용 여부.
        useExpecterDocumentView:
          optionsDocumentInformation === 1 || optionsDocumentInformation === 3,
        // 읽음 상태(열람, 미열람) 표시 사용 여부.
        useShowReadStatus:
          optionsDocumentInformation === 2 || optionsDocumentInformation === 3,

        useSignatureImage: useSign, // 서명 이미지 사용 여부.
        useDocumentOrganizationPublic: usePublic, // 문서 조직 공개.

        allocableCapacity, // 할당 가능 용량.
        capacityInUse, // 사용 중인 용량.
        capacityLimit, // 용량 한도.
        numberOfAttachments, // 첨부 개수.
        attachmentsCapacity, // 첨부 용량.

        updateAt, // 수정 날짜.
      };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

const modifyBasic = createAsyncThunk(
  `${name}/modifyBasic`,
  async (
    arg: {
      companyLogoData?: string;
      usePreviousApprovalCancel: boolean;
      useApprovalCancel: boolean;
      useApprovalDefer: boolean;
      useMeet: boolean;

      useExpecterDocumentView: boolean;

      useSignatureImage: boolean;
      useDocumentOrganizationPublic: boolean;

      allocableCapacity: number;
      capacityInUse: number;
      capacityLimit: number;
      numberOfAttachments: number;
      attachmentsCapacity: number;

      updateAt: string;
    },
    { rejectWithValue },
  ) => {
    try {
      let optionsDocumentInformation = 0;
      if (arg.useExpecterDocumentView) optionsDocumentInformation = 1;

      const optionsApproval =
        // eslint-disable-next-line no-bitwise
        (arg.usePreviousApprovalCancel ? 1 : 0) ^
        (arg.useApprovalCancel ? 2 : 0) ^
        (arg.useApprovalDefer ? 4 : 0) ^
        (arg.useMeet ? 8 : 0);

      const param = {
        logoPath: arg.companyLogoData, // 회사 로고 이미지 경로.
        optionsApproval, // 결재옵션. - 1:전단계 반려, 2:결재취소, 4:후결, 8:대결
        // TODO 값 사용 안함. 백엔드 필드 삭제 필요.
        optionsConsensusReturn: 1, // 합의시 반려처리 옵션. - 0:반려불가, 1:반려가능
        usePass: false, // 결재패스 사용여부. - 0:사용안함, 1:사용함
        optionsDocumentInformation, // 문서정보 옵션. - 1:상신시점에 모든결재선상의 사용자에게 결재문서 보여줌, 2:열람,미열람 표시, 3:둘 다 사용
        optionsReceiveOpinion: 0, // 수발신 결재의견 옵션. - 1:결재내용 하단에 결재의견 출력, 2:수신부서의 결재의견 기안부서에 공개, 3:둘 다 사용
        useComment: false, // 결재댓글 사용여부. - 0:사용안함, 1:사용함
        optionsComment: 1, // 결재댓글 옵션. - 0:수정/삭제 사용안함, 1:수정/삭제 사용함
        optionsInnerApproval: 1, // 접수 후 내부결재 반려 옵션. - 0:반려시 접수자의 반려함으로 반려, 1:최초기안자의 반려함으로 반려
        useSign: arg.useSignatureImage, // 결재란에 서명 사용여부. - 0:사용안함, 1:사용함
        useSubDepartmentView: false, // 하위부서 기록물철 조회 사용여부. - 0:사용안함, 1:사용함
        useAfterApproval: false, // 사후결재 사용여부. - 0:사용안함, 1:사용함
        useAuditor: false, // 감사자 사용여부. - 0:사용안함, 1:사용함
        optionsMailView: 0, // 메일 보내기 노출 옵션. - 1:결재진행중 노출, 2:결재완료 노출, 3:둘 다 노출
        usePublic: arg.useDocumentOrganizationPublic, // 기본 문서공개 사용여부. - 0:비공개, 1:공개
        capacityLimit: arg.capacityLimit, // 용량 한도. (byte)
        numberOfAttachments: arg.numberOfAttachments, // 첨부 개수.
        attachmentsCapacity: arg.attachmentsCapacity, // 첨부 용량. (byte)
        updateAt: arg.updateAt, // 수정 날짜.
      };

      const response = await preferencesApi.updateBasic(param);

      const companyLogoPath =
        response.logoPath !== ''
          ? `/approval-static/image/${response.companyId}/${response.logoPath}`
          : ''; // 회사 로고 경로.

      return {
        companyLogoPath, // 회사 로고 경로.
        // eslint-disable-next-line no-bitwise
        usePreviousApprovalCancel: (optionsApproval & 1) === 1, // 이전 승인 취소(전단계 반려) 사용 여부.
        // eslint-disable-next-line no-bitwise
        useApprovalCancel: (optionsApproval & 2) === 2, // 결재 취소 사용 여부.
        // eslint-disable-next-line no-bitwise
        useApprovalDefer: (optionsApproval & 4) === 4, // 후결 사용 여부.
        // eslint-disable-next-line no-bitwise
        useMeet: (optionsApproval & 8) === 8, // 대면 사용 여부.

        // 결재 예정자 문서 보기 사용 여부.
        useExpecterDocumentView:
          optionsDocumentInformation === 1 || optionsDocumentInformation === 3,
        // 읽음 상태(열람, 미열람) 표시 사용 여부.
        useShowReadStatus: false,
        useSignatureImage: response.useSign, // 서명 이미지 사용 여부.
        useDocumentOrganizationPublic: response.usePublic, // 문서 조직 공개.

        allocableCapacity: arg.allocableCapacity,
        capacityInUse: arg.capacityInUse,
        capacityLimit: arg.capacityLimit,
        numberOfAttachments: arg.numberOfAttachments,
        attachmentsCapacity: arg.attachmentsCapacity,

        updateAt: response.updateAt, // 수정 날짜.
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const findDocumentNo = createAsyncThunk(
  `${name}/findDocumentNo`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      return await preferencesApi.selectDocumentNo();
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

const modifyDocumentNo = createAsyncThunk(
  `${name}/modifyDocumentNo`,
  async (
    arg: {
      numberingTime: number;
      numberingMethod: number;
      useTopLevelOrganization: boolean;
      numberingReset: number;
      syncIncomingAndOutgoing: boolean;
      updateAt: string;
    },
    thunkApi,
  ) => {
    try {
      await sleep(3000);
      const { updateAt } = await preferencesApi.updateDocumentNo(arg);
      return { ...arg, updateAt };
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

const findAdministrators = createAsyncThunk(
  `${name}/findAdministrators`,
  async (_: void, { rejectWithValue }) => {
    try {
      // console.log(`findAdministrators()`);
      return await roleApi.find('APPROVAL_ADMIN');
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const appendAdministrators = createAsyncThunk(
  `${name}/appendAdministrators`,
  async (arg: { employeeId: number }[], { rejectWithValue }) => {
    try {
      // console.log(`appendAdministrators(arg)`, arg);
      if (arg.length === 0) return undefined;
      return await roleApi.append(
        arg.map((a) => ({ ...a, role: 'APPROVAL_ADMIN' as const })),
      );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const removeAdministrators = createAsyncThunk(
  `${name}/removeAdministrators`,
  async (
    arg: { employeeId: number; updateAt: string }[],
    { rejectWithValue },
  ) => {
    try {
      // console.log(`removeAdministrators(arg)`, arg);
      // throw new Error('테스트 에러.');
      if (arg.length === 0) return undefined;
      return await roleApi.remove(
        arg.map((a) => ({ ...a, role: 'APPROVAL_ADMIN' as const })),
      );
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 결재유형 리스트 */
const findApprovalType = createAsyncThunk(
  `${name}/findApprovalType`,
  async (arg: LocateArg | undefined, thunkApi) => {
    try {
      const response = await preferencesApi.selectApprovalType();
      return response;
    } catch (ex) {
      return thunkApi.rejectWithValue(appError(ex));
    }
  },
);

const preferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findBasic.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(modifyBasic.fulfilled, (state, { payload }) => {
        state.basic = payload;
      })
      .addCase(findDocumentNo.fulfilled, (state, { payload }) => {
        state.documentNo = payload;
      })
      .addCase(modifyDocumentNo.fulfilled, (state, { payload }) => {
        state.documentNo = payload;
      })
      .addCase(findAdministrators.fulfilled, (state, { payload }) => {
        if (payload) state.administrators = payload;
      })
      .addCase(appendAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = [...state.administrators, ...payload];
      })
      .addCase(removeAdministrators.fulfilled, (state, { payload }) => {
        if (payload && payload.length > 0)
          state.administrators = state.administrators.filter(
            (a) => payload.some((b) => a.employeeId === b.employeeId) === false,
          );
      })
      .addCase(findApprovalType.fulfilled, (state, { payload }) => {
        state.approvalType = payload;
      });
  },
});

export default preferencesSlice.reducer;

export const preferencesActions = {
  findBasic,
  modifyBasic,
  findDocumentNo,
  modifyDocumentNo,
  findAdministrators,
  appendAdministrators,
  removeAdministrators,

  findApprovalType,
};
