import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { FolderItem, FormItem } from '../../../../stores/form';

interface FormReturnType {
  companyId: number;
  id: number;
  folderId: number;
  updateAt: string;
}

interface FormFolderReturnType {
  companyId: number;
  id: number;
  updateAt: string;
}

/** 양식 폴더 모두 조회 */
async function findFolderList(arg: {
  parentid?: number;
  pageno: number;
  rowsperpage: number;
}): Promise<FolderItem[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/folder/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 등록 */
async function createFolder(arg: {
  name: string;
  parentId: number;
}): Promise<FormFolderReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/folder`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 수정 */
async function updateFolder(arg: {
  id: number;
  parentId: number;
  name: string;
  updateAt: string;
}): Promise<FormFolderReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/folder`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 삭제. */
async function deleteFolder(arg: {
  id: number;
  updateAt: string;
}): Promise<FormFolderReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/folder`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 순서변경. */
async function sortFolder(
  arg: {
    id: number;
    seq: number;
    updateAt: string;
  }[],
): Promise<
  {
    id: number;
    updateAt: string;
    companyId: number;
    seq: number;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/folder/seq`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 목록 조회. */
async function findFormList(arg: {
  folderid?: number;
  pageno: number;
  rowsperpage: number;
  searchcode?: string;
  searchword?: string;
}): Promise<
  {
    id: number;
    folderId: number;
    status: number;
    name: string;
    path: string;
    description: string;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/all`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 목록 총 개수 조회. */
async function findFormTotalCount(arg: {
  folderid?: number;
  searchcode?: string;
  searchword?: string;
}): Promise<number> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/all/totalcount`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 단일 조회. */
async function findForm(arg: {
  id: number;
  updateAt?: string;
}): Promise<FormItem> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form`;
    const response = await axios.get(url, { headers, params: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 등록. */
async function createForm(arg: {
  folderId: number;
  status: number;
  name: string;
  description: string;
  contents: string;
}): Promise<FormReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 수정. */
async function updateForm(arg: {
  id: number;
  folderId: number;
  status: number;
  name: string;
  description: string;
  contents: string;
  updateAt: string;
}): Promise<FormReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 삭제. */
async function deleteForm(
  arg: {
    id: number;
    updateAt: string;
  }[],
): Promise<FormReturnType | FormReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url =
      arg.length === 1
        ? `${host}/api/board/v1/form`
        : `${host}/api/board/v1/form/all`;
    const data = arg.length === 1 ? arg[0] : arg;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 양식 폴더 이동. */
async function moveForm(
  arg: {
    id: number;
    folderId: number;
    updateAt: string;
  }[],
): Promise<FormReturnType[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/board/v1/form/move`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

export const formApi = {
  formList: findFormList,
  formTotalCount: findFormTotalCount,
  form: findForm,
  createForm,
  updateForm,
  deleteForm,
  moveForm,
};

const formFolderApi = {
  folderList: findFolderList,
  createFolder,
  updateFolder,
  deleteFolder,
  sortFolder,
};

export default formFolderApi;
