import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../components/alert/DeleteConfirmation';
import EuiBody from '../../../../components/layout/EuiBody';
import EuiHeader from '../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../components/split/SplitUnselected';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import {
  calendarsActions,
  SaveCalendarData,
} from '../../../stores/calendar/calendars';
import CalendarFolderBoxEditDrawer from './CalendarFolderBoxEditDrawer';
import CalendarFolderBoxList from '../../common/CalendarFolderBoxList';
import CalendarFolderBoxView from '../../common/CalendarFolderBoxView';

function CalendarFolderBoxContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  const pathmap = getPathMap('/*/*/*', pathname);
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/*/:selectedId$base62',
    pathname,
  );
  const queryParams = getQueryParams(search);
  const list = useSelector((state: RootState) => state.calendar.calendars.list);
  const view = useSelector((state: RootState) => state.calendar.calendars.view);
  const categories = useSelector(
    (state: RootState) => state.calendar.calendars.category,
  ).filter((a) => a.type === 'setting');

  const items = useMemo(() => {
    const folders = list.map((x) => {
      return {
        id: x.id,
        parentId: 0,
        seq: x.seq,
        text: x.name,
        icon: 'folder-fill' as const,
        color: x.color,
        strings: hangul.d(x.name),
      };
    });
    return folders;
  }, [list]);

  /** 모바일에만 적용. */
  const handleCloseView = () => {
    const route = { pathname: pathmap };
    dispatch(sessionActions.setRoute(route));
  };

  const handleCRUD = (mode: 'create' | 'read' | 'update' | 'delete') => {
    switch (mode) {
      case 'create':
      case 'update':
        dispatch(sessionActions.setDrawer({ mode }));
        break;
      case 'delete':
        dispatch(sessionActions.setDialog({ mode }));
        break;
      default:
        dispatch(sessionActions.search());
        break;
    }
  };

  /** 캘린더 클릭 이벤트 */
  const handleItemClick = (id: number) => {
    dispatch(
      calendarsActions.calendarView({
        id,
        route: {
          pathname: `${pathmap}/${b62(id)}`,
          search,
        },
      }),
    );
  };

  /** 캘린더 저장(수정) 이벤트. */
  const handleSave = (data: SaveCalendarData) => {
    const route = { pathname };
    if (data.id !== undefined && data.updateAt !== undefined)
      dispatch(calendarsActions.updateCalendar({ data, route }));
    else dispatch(calendarsActions.saveCalendar({ data, route }));
  };

  /** 캘린더 삭제 이벤트. */
  const handleDeleteCalendar = () => {
    if (!view) return;
    dispatch(
      calendarsActions.deleteCalendar({
        id: view.id,
        updateAt: view.updateAt,
        route: { pathname: pathmap },
      }),
    );
  };

  const renderList = () => {
    let result = null;
    result = (
      <CalendarFolderBoxList
        calendarId={selectedId}
        items={items}
        onCreate={() => handleCRUD('create')}
        onItemClick={handleItemClick}
      />
    );
    return result;
  };

  const renderView = () => {
    if (view === undefined || selectedId === undefined) {
      const message =
        !view && selectedId ? '폴더를 찾을 수 없습니다.' : undefined;
      return <SplitUnselected label={message} />;
    }
    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <CalendarFolderBoxView
          isAdmin
          paths={getParentItems(items, view.id).map(({ text }) => text)}
          view={view}
          onUpdate={() => handleCRUD('update')}
          onDelete={() => handleCRUD('delete')}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerMode: mode } = queryParams;
    if (mode === 'create')
      return (
        <CalendarFolderBoxEditDrawer
          onSave={handleSave}
          onClose={() => handleCRUD('read')}
        />
      );
    if (mode === 'update' && view)
      return (
        <CalendarFolderBoxEditDrawer
          onSave={handleSave}
          view={view}
          onClose={() => handleCRUD('read')}
        />
      );
    return null;
  };

  const renderDialog = () => {
    const { dialogMode: mode } = queryParams;

    let result = null;
    if (mode === 'delete' && view)
      result = (
        <DeleteConfirmation
          onSubmit={handleDeleteCalendar}
          onCancel={() => handleCRUD('read')}
        >
          <strong>&apos;{view.name}&apos;</strong> 캘린더를 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );

    return result;
  };

  const title = categories.find((a) => a.id === 6003)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>{renderList()}</EuiSetting.Left>
          {renderView()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      {renderDialog()}
    </>
  );
}

export default CalendarFolderBoxContainer;
