import React from 'react';
import ComponentTextFieldBasic from './ComponentTextFieldBasic';
import ComponentTextFieldMultiline from './ComponentTextFieldMultiline';
import ComponentTextFieldMultilineForbid from './ComponentTextFieldMultilineForbid';
import ComponentTextFieldLabel from './ComponentTextFieldLabel';
import ComponentTextFieldCount from './ComponentTextFieldCount';
import ComponentTextFieldShow from './ComponentTextFieldShow';
import ComponentTextFieldClear from './ComponentTextFieldClear';
import ComponentTextFieldIcon from './ComponentTextFieldIcon';
import ComponentTextFieldAlign from './ComponentTextFieldAlign';
import ComponentTextFieldSize from './ComponentTextFieldSize';
import ComponentTextFieldAdd from './ComponentTextFieldAdd';
import ComponentTextFieldDisabled from './ComponentTextFieldDisabled';
import ComponentTextFieldError from './ComponentTextFieldError';

function TestTextField(): JSX.Element {
  return (
    <>
      <ComponentTextFieldBasic />
      <ComponentTextFieldLabel />
      <ComponentTextFieldSize />
      <ComponentTextFieldIcon />
      <ComponentTextFieldCount />
      <ComponentTextFieldShow />
      <ComponentTextFieldClear />
      <ComponentTextFieldAlign />
      <ComponentTextFieldMultiline />
      <ComponentTextFieldMultilineForbid />
      <ComponentTextFieldAdd />
      <ComponentTextFieldDisabled />
      <ComponentTextFieldError />
    </>
  );
}

export default TestTextField;
