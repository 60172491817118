import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldLabel(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <>
      <ComponentSampleUI.Content title="Label">
        <TextField label="아이디" value={value} onChange={handleChange} />
      </ComponentSampleUI.Content>
      <ComponentSampleUI.Content title="Label + Placeholder">
        <TextField
          required
          label="아이디"
          placeholder="Placeholder"
          value={value}
          onChange={handleChange}
        />
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentTextFieldLabel;
