import React from 'react';
import Icon from '../icon/Icon';
import { IconType } from '../../groupware-common/types/icon';
import Tooltip from '../tooltip/Tooltip';

class GnbItem extends React.PureComponent<{
  id: string;
  text: string;
  selected?: boolean;
  icon: IconType;
  onClick?(id: string): void;
}> {
  handleClick(): void {
    if (this.props.onClick !== undefined) this.props.onClick(this.props.id);
  }

  render(): JSX.Element {
    const { icon, text, selected } = this.props;
    return (
      <Tooltip title={text} placement="right">
        <button
          type="button"
          className={`menu ${selected ? 'selected' : ''}`}
          onClick={this.handleClick.bind(this)}
        >
          <Icon className="badge" icon={icon} />
          <span className="name">{text}</span>
        </button>
      </Tooltip>
    );
  }
}

export default GnbItem;
