import React from 'react';
import EuiBody from '../../../../../components/layout/EuiBody';
import CalendarUserPreferencesBasic from './CalendarUserPreferencesBasic';
import CalendarUserPreferencesHead from './CalendarUserPreferencesHead';
import CalendarUserPreferencesMyCal from './CalendarUserPreferencesMyCal';
import CalendarUserPreferencesSharedCal from './CalendarUserPreferencesSharedCal';

function CalendarUserPreferencesContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;

  const renderHead = () => {
    return (
      <CalendarUserPreferencesHead
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );
  };

  const renderBody = () => {
    let result = <></>;
    if (hash === '') result = <CalendarUserPreferencesBasic />;
    if (hash === '#myCalendar')
      result = (
        <CalendarUserPreferencesMyCal
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    if (hash === '#sharedCalendar')
      result = (
        <CalendarUserPreferencesSharedCal
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );

    return <EuiBody>{result}</EuiBody>;
  };

  return (
    <>
      {renderHead()}
      {renderBody()}
    </>
  );
}

export default CalendarUserPreferencesContainer;
