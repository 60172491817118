import React, { useState } from 'react';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Button from '../../../../../components/button/Button';

function DocumentCheckinDialog(props: {
  onSave(changeReason: string): void;
  onClose(): void;
}) {
  const { onSave, onClose } = props;

  const [state, setState] = useState<{
    changeReason: string;
  }>({
    changeReason: '',
  });

  /** 체크인 사유 변경. */
  const handleChangeCheckinReason = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      changeReason: event.target.value,
    }));
  };

  return (
    <Dialog size="xs">
      <DialogBody>
        <PostWrite>
          <div className="eui-alert-message">문서를 체크인 하시겠습니까?</div>
          <PostWrite.Item>
            <TextField
              value={state.changeReason}
              onChange={handleChangeCheckinReason}
              label="사유"
            />
          </PostWrite.Item>
        </PostWrite>
      </DialogBody>
      <DialogFooter>
        <Button
          text="확인"
          variant="contained"
          onClick={() => onSave(state.changeReason)}
        />
        <Button text="취소" onClick={onClose} />
      </DialogFooter>
    </Dialog>
  );
}

export default React.memo(DocumentCheckinDialog);
