import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import EmptyLocateRoute from '../../groupware-common/locates/EmptyLocateRoute';
import ApprovalApproverMacroRoute from '../pages/adminconsole/approval/approvermacro/ApprovalApproverMacroRoute';
import ApprovalArbitraryDecisionRoute from '../pages/adminconsole/approval/arbitrarydecision/ApprovalArbitraryDecisionRoute';
import ApprovalFormRoute from '../pages/adminconsole/approval/form/ApprovalFormRoute';
import ApprovalViewPermissionRoute from '../pages/adminconsole/approval/viewpermission/ApprovalViewPermissionRoute';
import ApprovalWorkRoute from '../pages/adminconsole/approval/work/ApprovalWorkRoute';
import ApprovalSettingRoute from '../pages/adminconsole/approval/setting/ApprovalSettingRoute';
import ApprovalDocumentRoute from '../pages/adminconsole/approval/document/ApprovalDocumentRoute';
import ApprovalTrashRoute from '../pages/adminconsole/approval/trash/ApprovalTrashRoute';
import ApprovalApproverProxyRoute from '../pages/adminconsole/approval/approverproxy/ApprovalApproverProxyRoute';

function AdminConsoleLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${AdminConsoleLocateRoute.name}.render(props)`, props);

  const { pathname, search, hash } = props;
  const { menu } = getPathParams('/*/*/:menu', pathname);

  if (menu === undefined) return <ApprovalSettingRoute pathname={pathname} />;

  if (menu === 'arbitrarydecision')
    return <ApprovalArbitraryDecisionRoute pathname={pathname} />;

  if (menu === 'approvermacro')
    return <ApprovalApproverMacroRoute pathname={pathname} search={search} />;

  if (menu === 'approverproxy')
    return (
      <ApprovalApproverProxyRoute
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );

  if (menu === 'viewpermission')
    return <ApprovalViewPermissionRoute pathname={pathname} />;

  if (menu === 'form') return <ApprovalFormRoute pathname={pathname} />;

  if (menu === 'work') {
    return (
      <ApprovalWorkRoute pathname={pathname} search={search} hash={hash} />
    );
  }
  if (menu === 'document') {
    return (
      <ApprovalDocumentRoute pathname={pathname} search={search} hash={hash} />
    );
  }
  if (menu === 'trash') {
    return (
      <ApprovalTrashRoute pathname={pathname} search={search} hash={hash} />
    );
  }
  return <EmptyLocateRoute pathname={pathname} search={search} hash={hash} />;
}

export default AdminConsoleLocateRoute;
