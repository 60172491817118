import React from 'react';
import { TreeItem } from '../../groupware-common/types';
import { IconType } from '../../groupware-common/types/icon';
import MenuItem from '../menu/MenuItem';

type Props<T> = {
  type?: 'full';
  id: T;
  parentId: T;
  text: string;
  icon?: IconType;
  color?: string;
  avatar?: string;
  disabledPath: Array<T>;
  selectedPath: Array<T>;
  items: TreeItem<T>[];
  useCheck?: boolean;
  itemCheck?: boolean;
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  getExpand?(id: T): boolean;
  setExpand?(id: T): void;
  onClick?(id: T, parentId?: T, icon?: IconType): void;
  onCheck?(id: T, event: React.ChangeEvent<HTMLInputElement>): void;
};

type State = { expand: boolean };

class TreeNode<T extends number | string> extends React.Component<
  Props<T>,
  State
> {
  expand = false;

  constructor(props: Props<T>) {
    super(props);
    this.state = { expand: false };

    this.handleExpandToggle = this.handleExpandToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  static getDerivedStateFromProps(
    props: Props<number | string>,
    state: State,
  ): State | null {
    // 아이템 사용 안함일 경우 하위 아이템 보이도록 설정.
    if (props.disabled) return { expand: true };
    if (props.getExpand && props.getExpand(props.id) !== state.expand)
      return { expand: !state.expand };
    return null;
  }

  shouldComponentUpdate(nextProps: Props<T>, nextState: State): boolean {
    const thisProps = this.props;
    if (
      thisProps.id !== nextProps.id ||
      thisProps.parentId !== nextProps.parentId ||
      thisProps.text !== nextProps.text ||
      thisProps.itemCheck !== nextProps.itemCheck ||
      thisProps.color !== nextProps.color
    )
      return true;

    const { getExpand } = this.props;
    if (getExpand && getExpand(this.props.id)) return true;

    const { id, selectedPath } = this.props;
    return (
      this.state !== nextState ||
      selectedPath.indexOf(id) !== -1 ||
      nextProps.selectedPath.indexOf(nextProps.id) !== -1
    );
  }

  handleExpandToggle(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    const { id, setExpand } = this.props;
    const { expand } = this.state;

    if (setExpand) setExpand(id);
    this.setState({ expand: !expand });

    event.stopPropagation();
    event.preventDefault();
  }

  handleClick(): void {
    const { id, parentId, icon, onClick } = this.props;
    if (onClick) onClick(id, parentId, icon);
  }

  handleCheck(id: T, event: React.ChangeEvent<HTMLInputElement>): void {
    const { onCheck } = this.props;
    if (onCheck) {
      onCheck(id, event);
    }
  }

  render(): JSX.Element {
    // console.log(`${TreeNode.name}.render:id:${this.props.id}`);
    const {
      type,
      id,
      text,
      icon,
      color,
      avatar,
      disabledPath,
      selectedPath,
      items,
      useCheck,
      getExpand,
      setExpand,
      onClick,
      itemCheck,
      disabled: isDisabled,
      children,
    } = this.props;
    const { expand } = this.state;

    const child = items.filter((x) => x.parentId === id);
    const selected = id === selectedPath[selectedPath.length - 1];
    const disabled = id === disabledPath[disabledPath.length - 1];

    let classname = 'tree-item';
    if (type === 'full' && child.length) classname += ' full';
    if (child.length > 0 && expand) classname += ' expanded';
    const { updateAt } = items.filter((a) => a.id === id)[0];
    return (
      <li>
        <MenuItem
          key={updateAt ? `${id}/${updateAt}` : `${id}`}
          label={text}
          className={classname}
          childrenCount={child.length}
          avatar={avatar}
          icon={avatar ? undefined : icon}
          color={color}
          expanded={expand}
          itemDisabled={disabled}
          disabled={isDisabled}
          selected={selected}
          onToggleExpanded={this.handleExpandToggle}
          onClick={this.handleClick}
          onCheck={this.handleCheck}
          useCheck={useCheck}
          itemCheck={itemCheck}
          id={id}
        >
          {children && children}
        </MenuItem>
        {expand && child.length > 0 && (
          <ul>
            {child.map((x) => {
              return (
                <TreeNode
                  key={x.updateAt ? `${x.id}/${x.updateAt}` : `${x.id}`}
                  id={x.id}
                  parentId={id}
                  text={x.text}
                  icon={x.icon}
                  color={x.color}
                  avatar={x.avatar}
                  items={items}
                  type={type}
                  disabledPath={disabledPath}
                  selectedPath={selectedPath}
                  getExpand={getExpand}
                  setExpand={setExpand}
                  onClick={onClick}
                  onCheck={this.handleCheck}
                  useCheck={useCheck}
                  itemCheck={x.checked}
                  disabled={x.disabled}
                />
              );
            })}
          </ul>
        )}
      </li>
    );
  }
}

export default TreeNode;
