import React from 'react';
import ComponentNavBasic from './ComponentNavBasic';
import ComponentNavToggle from './ComponentNavToggle';
import ComponentNavAvatar from './ComponentNavAvatar';

function ComponentMenu(): JSX.Element {
  return (
    <>
      <ComponentNavBasic />
      <ComponentNavToggle />
      <ComponentNavAvatar />
    </>
  );
}

export default ComponentMenu;
