/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
import axios from 'axios';
import { apiError, getApiConfig } from '../../../../../groupware-common/apis/common/v1';

async function fetchLastUpdateAt() {
  return '';
}

async function fetchTotalCount() {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/macro/all/totalcount`;
    const response = await axios.get<{ companyId: number; totalCount: number }>(url, { headers });
    return response.data.totalCount;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchList(pageNo: number, rowsPerPage: number): Promise<{
  companyId: number;
  id: number;
  name: string;
  organizationId: number;
  employeeId: number;
  createAt: string;
  updateAt: string;
}[]> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/macro/all?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchSave(arg: {
  id?: undefined;
  name: string;
  organizationId: number;
  employeeId: number;
} | {
  id: number;
  name: string;
  organizationId: number;
  employeeId: number;
  updateAt: string;
}): Promise<{ companyId: number; id: number; updateAt: string }> {
  // console.log(`fetchSave(arg)`, arg);

  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/macro`;
    const response =
      arg.id === undefined
        ? await axios.post(url, arg, { headers })
        : await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function fetchDelete(id: number, updateAt: string): Promise<{
  companyId: number;
  id: number;
  updateAt: string;
}> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/approval/v1/macro`;
    const data = { id, updateAt };
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const approverMacroApi = {
  lastUpdateAt: fetchLastUpdateAt,
  totalCount: fetchTotalCount,
  list: fetchList,
  save: fetchSave,
  delete: fetchDelete,
};
