import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/** 결재 사용자 설정. */
const namespace = 'approval/userSettings';

/**
 * 사용자 설정 상태 인테페이스.
 * @property displayDensity 화면 밀도. (normal: 기본, narrow: 좁게)
 * @property rowsPerPage 페이지당 행 수.
 */
interface UserSettingsState {
  displayDensity: 'wide' | 'normal' | 'narrow';
  rowsPerPage: number;
}

const initialState: UserSettingsState = {
  displayDensity: 'normal',
  rowsPerPage: 15,
};

const userSettingsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    /**
     * 화면 밀도를 변경 합니다.
     * @param payload 화면 밀도 값. (normal: 기본, narrow: 좁게)
     */
    displayDensity(state, action: PayloadAction<'wide' | 'normal' | 'narrow'>) {
      if (state.displayDensity !== action.payload)
        state.displayDensity = action.payload;
    },
  },
});

export default userSettingsSlice.reducer;

export const userSettingsSliceActions = {
  ...userSettingsSlice.actions,
};
