import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { formFolderActions } from '../../../../stores/attendance/form';
import {
  attendanceWorkActions,
  attendanceWorkFolderActions,
} from '../../../../stores/attendance/work';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceWorkRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${AttendanceWorkRoute.name}.render`);

  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;

  useEffect(() => {
    Promise.all([
      dispatch(formFolderActions.list()),
      dispatch(attendanceWorkFolderActions.list()),
      dispatch(attendanceWorkActions.list({})),
      dispatch(attendancePreferencesActions.attendanceCodeList()), // 근태 코드 조회.
    ]).then(() => {
      const route = { pathname, search, hash, clearErrors: true };
      dispatch(sessionActions.setRoute(route));
    });
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default AttendanceWorkRoute;
