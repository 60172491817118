import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../app/store';
import {
  getPathParams,
  go,
  parseModule,
} from '../../../groupware-common/utils';
import DefaultMenu from './DefaultMenu';
import { IconType } from '../../../groupware-common/types/icon';
import { moduleIcon } from '../../../groupware-common/utils/ui';
import { sessionActions } from '../../stores/session';
import Button from '../../../components/button/Button';

function NavigationDrawer(props: { pathname: string; hash: string }) {
  // console.log(`${NavigationDrawer.name}.render(props)`, props);

  const pathParams = getPathParams('/*/:module/:menuId', props.pathname);
  const module =
    pathParams.module !== undefined ? `adminconsole/${pathParams.module}` : '';
  const { menuId = '_' } = pathParams;

  const dispatch = useAppDispatch();

  // 세션 리소스 - teams: 팀즈, web: 웹
  const resource = useSelector((state: RootState) => state.session.resource);
  const isSessionLogin = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );
  // 개발 환경 유무.
  const isDevelopment =
    window.location.hostname === 'localhost' || !isSessionLogin;
  const modules = useSelector((state: RootState) => state.session.modules);
  const providers = useSelector(
    (state: RootState) => state.systemlink.preferences.provider,
  );

  const syncLoading = useSelector(
    (state: RootState) => state.session.syncLoading,
  );
  const { isExists } = useSelector(
    (state: RootState) => state.attendance.preferences.standard,
  );

  // 결재 메뉴
  const approvalMenu = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  // 근태 메뉴
  const attendanceMenu = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  // 게시 메뉴
  const boardMenu = useSelector(
    (state: RootState) => state.boards.board.category,
  ).filter((a) => a.type === 'setting');
  // 자원 메뉴
  const resourceMenu = useSelector(
    (state: RootState) => state.resource.resources.category,
  ).filter((a) => a.type === 'setting');
  // 문서 메뉴
  const documentMenu = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'setting');
  // 시스템연동 메뉴
  const systemlinkMenu = useSelector(
    (state: RootState) => state.systemlink.preferences.category,
  );

  /** 개발 중인 메뉴 아이디 배열. */
  const menuIdsInDevelopment = [
    'adminconsole/approval/viewpermission', // 조회권 등록.
    'adminconsole/directory/group', // 사용자 그룹.
    'adminconsole/service',
  ];

  const dayOffSettingMenu: {
    id: string;
    parentId: string;
    text: string;
    icon?: IconType;
    link?: boolean;
  }[] = isExists
    ? [
        {
          id: 'adminconsole/attendance/dayOffSetting',
          text: `${attendanceMenu.find((a) => a.id === 6004)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
        {
          id: 'adminconsole/attendance/dayOffAdjust',
          text: `${attendanceMenu.find((a) => a.id === 6005)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
        {
          id: 'adminconsole/attendance/dayOffStatus',
          text: `${attendanceMenu.find((a) => a.id === 6006)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
      ]
    : [
        {
          id: 'adminconsole/attendance/dayOffSetting',
          text: `${attendanceMenu.find((a) => a.id === 6014)?.name}`,
          parentId: 'adminconsole/attendance/dayOff',
          link: true,
        },
      ];
  let menus: {
    id: string;
    parentId: string;
    text: string;
    icon?: IconType;
    link?: boolean;
  }[] = [
    {
      id: 'adminconsole/service',
      parentId: '',
      text: '서비스',
      icon: 'cog-fill',
    },
    {
      id: 'adminconsole/service/default',
      parentId: 'adminconsole/service',
      text: '기본 설정',
      link: true,
    },
    {
      id: 'adminconsole/service/menu',
      parentId: 'adminconsole/service',
      text: '메뉴 관리',
      link: true,
    },
    {
      id: 'adminconsole/service/link',
      parentId: 'adminconsole/service',
      text: '링크메뉴 관리',
      link: true,
    },
    {
      id: 'adminconsole/directory',
      parentId: '',
      text: '조직도',
      icon: 'sitemap-fill',
    },
    {
      id: 'adminconsole/directory/_',
      parentId: 'adminconsole/directory',
      text: '기본설정',
      link: true,
    },
    {
      id: 'adminconsole/directory/organizationchart',
      parentId: 'adminconsole/directory',
      text: '조직도',
      link: true,
    },
    {
      id: 'adminconsole/directory/jobposition',
      parentId: 'adminconsole/directory',
      text: '직위',
      link: true,
    },
    {
      id: 'adminconsole/directory/jobduty',
      parentId: 'adminconsole/directory',
      text: '직책',
      link: true,
    },
    {
      id: 'adminconsole/directory/group',
      parentId: 'adminconsole/directory',
      text: '사용자 그룹',
      link: true,
    },
    {
      id: 'adminconsole/approval',
      text: '결재',
      parentId: '',
      icon: moduleIcon('approval'),
    },
    {
      id: 'adminconsole/approval/_',
      text: `${approvalMenu.find((a) => a.id === 6001)?.name}`,
      parentId: 'adminconsole/approval',
      link: true,
    },
    {
      id: 'adminconsole/approval/document/management',
      text: '결재문서 관리',
      parentId: 'adminconsole/approval',
    },
    {
      id: 'adminconsole/approval/document',
      text: `${approvalMenu.find((a) => a.id === 6002)?.name}`,
      parentId: 'adminconsole/approval/document/management',
      link: true,
    },
    {
      id: 'adminconsole/approval/arbitrarydecision',
      text: `${approvalMenu.find((a) => a.id === 6003)?.name}`,
      parentId: 'adminconsole/approval/document/management',
      link: true,
    },
    {
      id: 'adminconsole/approval/approvermacro',
      text: `${approvalMenu.find((a) => a.id === 6004)?.name}`,
      parentId: 'adminconsole/approval/document/management',
      link: true,
    },
    {
      id: 'adminconsole/approval/approverproxy',
      text: `${approvalMenu.find((a) => a.id === 6005)?.name}`,
      parentId: 'adminconsole/approval/document/management',
      link: true,
    },
    {
      id: 'adminconsole/approval/viewpermission',
      text: `${approvalMenu.find((a) => a.id === 6006)?.name}`,
      parentId: 'adminconsole/approval/document/management',
      link: true,
    },
    {
      id: 'adminconsole/approval/trash',
      text: `${approvalMenu.find((a) => a.id === 6007)?.name}`,
      parentId: 'adminconsole/approval/document/management',
      link: true,
    },
    {
      id: 'adminconsole/approval/form',
      text: `${approvalMenu.find((a) => a.id === 6008)?.name}`,
      parentId: 'adminconsole/approval',
      link: true,
    },
    {
      id: 'adminconsole/approval/work',
      text: `${approvalMenu.find((a) => a.id === 6009)?.name}`,
      parentId: 'adminconsole/approval',
      link: true,
    },
    {
      id: 'adminconsole/board',
      parentId: '',
      text: '게시',
      icon: 'file-fill',
    },
    {
      id: 'adminconsole/board/_',
      text: `${boardMenu.find((a) => a.id === 6001)?.name}`,
      parentId: 'adminconsole/board',
      link: true,
    },
    {
      id: 'adminconsole/board/folderbox',
      parentId: 'adminconsole/board',
      text: `${boardMenu.find((a) => a.id === 6002)?.name}`,
      link: true,
    },
    {
      id: 'adminconsole/board/authority',
      parentId: 'adminconsole/board',
      text: `${boardMenu.find((a) => a.id === 6003)?.name}`,
      link: true,
    },
    {
      id: 'adminconsole/board/trash',
      text: `${boardMenu.find((a) => a.id === 6004)?.name}`,
      parentId: 'adminconsole/board',
      link: true,
    },
    {
      id: 'adminconsole/board/form',
      text: `${boardMenu.find((a) => a.id === 6005)?.name}`,
      parentId: 'adminconsole/board',
      link: true,
    },
    {
      id: 'adminconsole/calendar',
      text: '일정',
      parentId: '',
      icon: moduleIcon('calendar'),
    },
    {
      id: 'adminconsole/calendar/_',
      text: '기본설정',
      parentId: 'adminconsole/calendar',
      link: true,
    },
    {
      id: 'adminconsole/calendar/sub',
      text: '구독 캘린더',
      parentId: 'adminconsole/calendar',
      link: true,
    },
    {
      id: 'adminconsole/calendar/all',
      text: '공유 캘린더',
      parentId: 'adminconsole/calendar',
      link: true,
    },
    {
      id: 'adminconsole/attendance',
      text: '근태',
      parentId: '',
      icon: moduleIcon('attendance'),
    },
    {
      id: 'adminconsole/attendance/_',
      text: `${attendanceMenu.find((a) => a.id === 6001)?.name}`,
      parentId: 'adminconsole/attendance',
      link: true,
    },
    {
      id: 'adminconsole/attendance/detailStatus',
      text: `${attendanceMenu.find((a) => a.id === 6013)?.name}`,
      parentId: 'adminconsole/attendance',
      link: true,
    },
    {
      id: 'adminconsole/attendance/holiday',
      text: '휴일관리',
      parentId: 'adminconsole/attendance',
    },
    {
      id: 'adminconsole/attendance/basicHoliday',
      text: `${attendanceMenu.find((a) => a.id === 6002)?.name}`,
      parentId: 'adminconsole/attendance/holiday',
      link: true,
    },
    {
      id: 'adminconsole/attendance/holidaysave',
      text: `${attendanceMenu.find((a) => a.id === 6003)?.name}`,
      parentId: 'adminconsole/attendance/holiday',
      link: true,
    },
    {
      id: 'adminconsole/attendance/dayOff',
      text: '연차관리',
      parentId: 'adminconsole/attendance',
    },
    ...dayOffSettingMenu,
    {
      id: 'adminconsole/attendance/sendNotice',
      text: '연차사용촉진알림',
      parentId: 'adminconsole/attendance',
    },
    {
      id: 'adminconsole/attendance/noticeSetting',
      text: `${attendanceMenu.find((a) => a.id === 6007)?.name}`,
      parentId: 'adminconsole/attendance/sendNotice',
      link: true,
    },
    {
      id: 'adminconsole/attendance/sendList',
      text: `${attendanceMenu.find((a) => a.id === 6008)?.name}`,
      parentId: 'adminconsole/attendance/sendNotice',
      link: true,
    },
    {
      id: 'adminconsole/attendance/substitute',
      text: '대휴관리',
      parentId: 'adminconsole/attendance',
    },
    {
      id: 'adminconsole/attendance/substituteAdjust',
      text: `${attendanceMenu.find((a) => a.id === 6009)?.name}`,
      parentId: 'adminconsole/attendance/substitute',
      link: true,
    },
    {
      id: 'adminconsole/attendance/substituteStatus',
      text: `${attendanceMenu.find((a) => a.id === 6010)?.name}`,
      parentId: 'adminconsole/attendance/substitute',
      link: true,
    },
    {
      id: 'adminconsole/attendance/form',
      text: `${attendanceMenu.find((a) => a.id === 6011)?.name}`,
      parentId: 'adminconsole/attendance',
      link: true,
    },
    {
      id: 'adminconsole/attendance/work',
      text: `${attendanceMenu.find((a) => a.id === 6012)?.name}`,
      parentId: 'adminconsole/attendance',
      link: true,
    },
    {
      id: 'adminconsole/resource',
      text: '자원',
      parentId: '',
      icon: moduleIcon('resource'),
    },
    {
      id: 'adminconsole/resource/_',
      text: `${resourceMenu.find((a) => a.id === 6001)?.name}`,
      parentId: 'adminconsole/resource',
      link: true,
    },
    {
      id: 'adminconsole/resource/folder',
      text: `${resourceMenu.find((a) => a.id === 6002)?.name}`,
      parentId: 'adminconsole/resource',
      link: true,
    },
    {
      id: 'adminconsole/resource/reservation',
      text: `${resourceMenu.find((a) => a.id === 6003)?.name}`,
      parentId: 'adminconsole/resource',
      link: true,
    },
    {
      id: 'adminconsole/document',
      text: '문서',
      parentId: '',
      icon: moduleIcon('document'),
    },
    {
      id: 'adminconsole/document/_',
      text: `${documentMenu.find((a) => a.id === 6001)?.name}`,
      parentId: 'adminconsole/document',
      link: true,
    },
    {
      id: 'adminconsole/document/folderbox',
      parentId: 'adminconsole/document',
      text: `${documentMenu.find((a) => a.id === 6002)?.name}`,
      link: true,
    },
    {
      id: 'adminconsole/document/authority',
      parentId: 'adminconsole/document',
      text: `${documentMenu.find((a) => a.id === 6003)?.name}`,
      link: true,
    },
    {
      id: 'adminconsole/document/trash',
      text: `${documentMenu.find((a) => a.id === 6004)?.name}`,
      parentId: 'adminconsole/document',
      link: true,
    },
    {
      id: 'adminconsole/document/form',
      text: `${documentMenu.find((a) => a.id === 6005)?.name}`,
      parentId: 'adminconsole/document',
      link: true,
    },
    {
      id: 'adminconsole/document/checkout',
      text: `${documentMenu.find((a) => a.id === 6006)?.name}`,
      parentId: 'adminconsole/document',
      link: true,
    },
    { id: 'adminconsole/test', parentId: '', text: '테스트', link: true },
  ];

  // 팀즈 앱에서 실행 모듈만 표시 되도록 설정.
  if (resource === 'teams') {
    menus = menus.filter(({ id }) => {
      if (menuIdsInDevelopment.some((v) => v === id)) return false;
      const moduleId = id.split('/')[1];
      return modules.find(({ code }) => code === moduleId) !== undefined;
    });
  }
  // 웹에서 사용 설정된 모듈만 표시 되도록 설정. - 개발 환경이 아닌 경우.
  // 서비스 메뉴 보이도록 설정.
  if (!isDevelopment && resource === 'web') {
    menus = menus.filter(({ id }) => {
      if (menuIdsInDevelopment.some((v) => v === id)) return false;
      const moduleId = id.split('/')[1];
      if (moduleId === 'service') return true;
      return modules.find(({ code }) => code === moduleId) !== undefined;
    });
  }

  /** 시스템 연동 메뉴 */
  if (providers.approval.length > 0 || providers.directory.length > 0) {
    let isLinkApproval = providers.approval.length > 0;
    let isLinkDirecotry = providers.directory.length > 0;
    let isYLWApproval = providers.approval.find((a) => a === 'YLWSYSTEMEVER');
    let isYLWDirectory = providers.directory.find((a) => a === 'YLWSYSTEMEVER');

    // 팀즈 앱일 경우
    if (resource === 'teams') {
      // 실행 모듈이 전자결재 또는 조직도일 때만 시스템 연동 메뉴 보임.
      isLinkApproval =
        modules.findIndex((a) => a.code === 'approval') > -1 && isLinkApproval;
      isLinkDirecotry =
        modules.findIndex((a) => a.code === 'directory') > -1 &&
        isLinkDirecotry;
      isYLWApproval = isLinkApproval === false ? undefined : isYLWApproval;
      isYLWDirectory = isLinkDirecotry === false ? undefined : isYLWDirectory;
    }
    if (isLinkApproval || isLinkDirecotry) {
      menus.splice(0, 0, {
        id: 'adminconsole/systemlink',
        text: '시스템 연동',
        parentId: '',
        icon: moduleIcon('systemlink'),
      });
    }

    /** 영림원 암호화 키 세팅 페이지. */
    if (isYLWApproval || isYLWDirectory) {
      menus.push({
        id: `adminconsole/systemlink/systemever`,
        text: `${systemlinkMenu.find((a) => a.id === 6001)?.name}`,
        parentId: 'adminconsole/systemlink',
        link: true,
      });
    }

    /** 결재 구성일 경우. */
    if (isLinkApproval && menus.find((a) => a.id === 'adminconsole/approval')) {
      menus.push(
        {
          id: 'adminconsole/systemlink/form',
          text: `${systemlinkMenu.find((a) => a.id === 6002)?.name}`,
          parentId: 'adminconsole/systemlink',
          link: true,
        },
        {
          id: 'adminconsole/systemlink/work',
          text: `${systemlinkMenu.find((a) => a.id === 6003)?.name}`,
          parentId: 'adminconsole/systemlink',
          link: true,
        },
      );
    }
  }

  const handleLink = (id: string) => {
    const menu = menus.find((x) => x.id === id);
    if (menu?.link === true) {
      const [adminconsole, b] = menu.id.split('/');

      // 모듈 기본 메뉴 경로인지 확인하여 경로 설정.
      const pathname = menu.id.endsWith('/_')
        ? `/${menu.id.slice(0, -2)}`
        : `/${menu.id}`;

      const a = parseModule(`${adminconsole}/${b}`);
      if (a !== undefined) {
        /*
        switch (a) {
          case 'adminconsole/organizationunit':
            namespace = 'organization';
            break;
          case 'adminconsole/jobposition':
          case 'adminconsole/jobduty':
            namespace = 'jobclass';
            break;
        }
        */
        go(pathname);
        dispatch(sessionActions.mobileNav(false));
      }
    }

    /*
    const a = parseModule(module);
    if (a !== undefined) {
      let namespace = 'translation';
      switch (a) {
        case 'adminconsole/organizationunit':
          namespace = 'organization';
          break;
        case 'adminconsole/jobposition':
        case 'adminconsole/jobduty':
          namespace = 'jobclass';
          break;
      }

      resourceUpdate(namespace, languages).then(() => {
        gogogo({ a });
      });
    }
    */
  };

  const { hash } = props;
  const selectedId =
    hash !== '' && menus.some((v) => v.id === `${module}/${menuId}/${hash}`)
      ? `${module}/${menuId}/${hash}`
      : `${module}/${menuId}`;
  return (
    <div className={`admin-header ${syncLoading ? 'loading' : ''}`}>
      <div className="header-title">
        <h1 className="title">관리자 페이지</h1>
        <Button
          text="사용자 페이지로 이동"
          iconType
          icon="home"
          className="home"
          onClick={() => {
            if (modules.length === 1) go(`/${modules[0].code}`);
            else go('/');
          }}
        />
      </div>
      <div className="header-nav">
        <DefaultMenu
          selectedId={selectedId}
          items={menus}
          onMenuClick={handleLink}
        />
      </div>
    </div>
  );
}

export default React.memo(NavigationDrawer);
