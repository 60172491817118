import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import EmptyData from '../../../../../components/data/EmptyData';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import Divider from '../../../../../components/divider/Divider';
import PostView from '../../../../../components/post/PostView';
import PromptMessage from '../../../../../components/prompt/PromptMessage';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  contactsActions,
  getAddressType,
  getAnniversaryType,
  getEmailType,
  getPhoneType,
} from '../../../../stores/contacts';

function ContactContentViewDialog(props: {
  pathname: string;
  search: string;
  onClose(): void;
  onUpdate(): void;
  onDelete(): void;
}): JSX.Element {
  const { pathname, search } = props;
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const pathmap = getPathMap('/*', pathname);
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();
  const display = useSelector((state: RootState) => state.session.display);
  const labels = useSelector((state: RootState) => state.contact.labels.list);
  const view = useSelector((state: RootState) => state.contact.contacts.view);
  const total = useSelector((state: RootState) => state.contact.contacts.total);
  const labelSetting = useSelector(
    (state: RootState) => state.contact.contacts.labelSetting,
  );
  const isPhone = display === 'phone';

  if (!view) return <EmptyData message="연락처를 찾을 수 없습니다." />;

  const [isDelete, setIsDelete] = useState(false);

  /** 대화상자 닫기 이벤트. */
  const handleClose = () => {
    dispatch(contactsActions.viewClear());
    props.onClose();
  };

  const handleDelete = () => {
    if (!view) return;
    props.onDelete();
  };

  /** 이메일 클릭 이벤트 */
  const handleClickEmail = (arg: {
    event: React.MouseEvent<HTMLDivElement>;
    email: string;
  }) => {
    const { event, email } = arg;
    event.preventDefault();
    event.stopPropagation();
    console.log('handleClickEvent(emeil)', email);
  };

  /** 라벨 클릭 이벤트. */
  const handleLabelClick = (id: number) => {
    go(`${pathmap}/${b62(id)}`);
  };

  /** 중요표시 기능 이벤트. */
  const handleItemImportanceChange = () => {
    dispatch(
      contactsActions.saveUnSaveStar({
        star: !view.isStarred,
        id: view.id,
        noLoading: true,
      }),
    ).then((result) => {
      if (
        (result as { error?: string }).error === undefined &&
        folderId === 'importance'
      ) {
        delete queryParams.dialogMode;
        const { pageNo = 1 } = queryParams;
        const rowsPerPage =
          queryParams.rowsPerPage ?? labelSetting.setting?.rowsPerPage ?? 15;
        const currentPage = (pageNo - 1) * rowsPerPage + 1;
        if (total === currentPage && pageNo > 1)
          queryParams.pageNo = pageNo - 1;
        go(pathname, queryParams);
      }
    });
  };

  const renderContent = () => {
    /** 모바일 화면이거나 라벨 5개 이상 설정되어 있는 경우 flex-direction: column */
    const titleStyle: React.CSSProperties =
      display === 'phone' || view.labels.length > 4
        ? { flexDirection: 'column' }
        : { flexDirection: 'row', alignItems: 'center' };
    return (
      <>
        <PostView type="full">
          <PostView.Title>
            <div style={{ display: 'flex', ...titleStyle }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: display === 'phone' ? undefined : '8px',
                }}
              >
                <Button
                  style={{ marginRight: '4px' }}
                  iconType
                  icon={view.isStarred ? 'star-fill' : 'star'}
                  onClick={handleItemImportanceChange}
                />
                {view.name}
              </div>
              <div style={{ fontSize: '14px' }}>
                {view.labels.map((a, i) => {
                  const name =
                    labels.find(({ id }) => id === a.labelId)?.name ?? '';
                  return (
                    <span
                      key={a.labelId}
                      style={{
                        marginRight:
                          i !== view.labels.length - 1 ? '4px' : undefined,
                      }}
                    >
                      <Chip
                        className="contact-label"
                        size="sm"
                        label={name}
                        onClick={() => handleLabelClick(a.labelId)}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          </PostView.Title>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title="닉네임">
                <PostView.CategoryValue value={view.nickName} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="회사">
                <PostView.CategoryValue value={view.company} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="직급">
                <PostView.CategoryValue value={view.jobTitle} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="부서">
                <PostView.CategoryValue value={view.department} />
              </PostView.CategoryItem>
              <Divider />
              <PostView.CategoryItem title="이메일">
                {view.emails.map((a) => {
                  const type = getEmailType({
                    type: a.emailType,
                    customValue: a.emailTypeCustomValue,
                  });

                  if (isPhone)
                    return (
                      <div
                        key={a.id}
                        className="item-contact"
                        style={{ flexDirection: 'column' }}
                      >
                        {type !== '' && <span className="title">{type}</span>}
                        <div style={{ display: 'flex', marginRight: 'auto' }}>
                          <div
                            className="default"
                            style={{
                              top: '10px',
                              background: a.isDefault ? 'red' : 'none',
                            }}
                          />
                          <div
                            className="value link"
                            onClick={(event) =>
                              handleClickEmail({ event, email: a.email })
                            }
                          >
                            {a.email}
                          </div>
                        </div>
                      </div>
                    );

                  return (
                    <div key={a.id} className="item-contact">
                      <div
                        className="default"
                        style={{ background: a.isDefault ? 'red' : 'none' }}
                      />
                      {type !== '' && <span className="title">{type}</span>}
                      <div
                        className="value link"
                        onClick={(event) =>
                          handleClickEmail({ event, email: a.email })
                        }
                      >
                        {a.email}
                      </div>
                    </div>
                  );
                })}
              </PostView.CategoryItem>
              <PostView.CategoryItem title="전화번호">
                {view.phones.map((a) => {
                  const type = getPhoneType({
                    type: a.phoneType,
                    customValue: a.phoneTypeCustomValue,
                  });

                  if (isPhone)
                    return (
                      <div
                        key={a.id}
                        className="item-contact"
                        style={{ flexDirection: 'column' }}
                      >
                        {type !== '' && <span className="title">{type}</span>}
                        <div style={{ display: 'flex', marginRight: 'auto' }}>
                          <div
                            className="default"
                            style={{
                              top: '10px',
                              background: a.isDefault ? 'red' : 'none',
                            }}
                          />
                          <div className="value">{`+${a.locationCode} ${a.phoneNumber}`}</div>
                        </div>
                      </div>
                    );
                  return (
                    <div key={a.id} className="item-contact">
                      <div
                        className="default"
                        style={{ background: a.isDefault ? 'red' : 'none' }}
                      />
                      {type !== '' && <span className="title">{type}</span>}
                      <div className="value">{`+${a.locationCode} ${a.phoneNumber}`}</div>
                    </div>
                  );
                })}
              </PostView.CategoryItem>
              <PostView.CategoryItem title="주소">
                {view.addresses.map((a) => {
                  const type = getAddressType({
                    type: a.addressType,
                    customValue: a.addressTypeCustomValue,
                  });
                  let address = `${a.address} ${a.addressDetail}`;
                  if (a.postalCode !== '')
                    address = `[${a.postalCode}] ${address}`;

                  return (
                    <div
                      key={a.id}
                      className="item-contact"
                      style={{ flexDirection: isPhone ? 'column' : undefined }}
                    >
                      {type !== '' && <span className="title">{type}</span>}
                      <div
                        className="value"
                        style={{ marginRight: isPhone ? 'auto' : undefined }}
                      >
                        {address}
                      </div>
                    </div>
                  );
                })}
              </PostView.CategoryItem>
              <Divider />
              <PostView.CategoryItem title="생일">
                <PostView.CategoryValue value={view.birthDay} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="기념일">
                {view.anniversaries.map((a) => {
                  const type = getAnniversaryType({
                    type: a.anniversaryType,
                    customValue: a.anniversaryTypeCustomValue,
                  });

                  return (
                    <div
                      key={a.id}
                      className="item-contact"
                      style={{ flexDirection: isPhone ? 'column' : undefined }}
                    >
                      {type !== '' && <span className="title">{type}</span>}
                      <div
                        className="value"
                        style={{ marginRight: isPhone ? 'auto' : undefined }}
                      >
                        {a.anniversary}
                      </div>
                    </div>
                  );
                })}
              </PostView.CategoryItem>
              <Divider />
              <PostView.CategoryItem title="메모">
                <PostView.CategoryValue value={view.memo} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </>
    );
  };

  const renderDialog = () => {
    if (isDelete)
      return (
        <PromptMessage
          submitColor="danger"
          submitLabel="삭제"
          submitNoDuplication
          onCancel={() => setIsDelete(false)}
          onSubmit={handleDelete}
        >
          {view?.isStarred && <p>중요 연락처가 포함되어 있습니다.</p>}
          <p>선택하신 연락처를 삭제하시겠습니까?</p>
        </PromptMessage>
      );
    return null;
  };

  return (
    <Dialog onClose={handleClose}>
      <DialogHeader>
        <DialogTitle>연락처</DialogTitle>
      </DialogHeader>
      <DialogBody>{renderContent()}</DialogBody>
      <DialogFooter>
        <Button variant="contained" text="수정" onClick={props.onUpdate} />
        <Button
          variant="outlined"
          text="삭제"
          onClick={() => setIsDelete(true)}
        />
      </DialogFooter>
      {renderDialog()}
    </Dialog>
  );
}

export default ContactContentViewDialog;
