import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Divider from '../../../../../components/divider/Divider';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Menu from '../../../../../components/menu/Menu';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import MenuGroup from '../../../../../components/menu/MenuGroup';
import MenuItem from '../../../../../components/menu/MenuItem';
import AttendanceSearch, {
  SearchDateProps,
} from '../../../../../components/search/AttendanceSearch';
import ToolbarAction, {
  ActionEventProps,
} from '../../../../../components/toolbarAction/ToolbarAction';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import { RootState } from '../../../../../groupware-webapp/app/store';

type Props = {
  isAnony: boolean;
  folderId?: string;
  title: string;
  pageNo: number;
  rowsPerPage: number;
  totalCount: number;
  checkedCount: number;
  onCheckedChange(itemId: 'all', checked: boolean): void;
  sortOptions?: { value: string; label: string }[];
  sort?: string;
  onChangeSort?(value: string): void;
  filterOptions?: { value: string; label: string }[];
  filter?: string;
  onChangeFilter?(value: string): void;

  toolbarButtons: ActionEventProps[];
  searchCode?: string;
  searchWord?: string;
  startDate?: string;
  endDate?: string;
  onSearch?(arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }): void;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
};

function BoardContentHeadList(props: Props): JSX.Element {
  const display = useSelector((state: RootState) => state.session.display);

  const [state, setState] = useState<{
    filterPopupRect:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>({
    filterPopupRect: undefined,
  });

  // 필터
  const handleFilterPopup = (event?: React.MouseEvent) => {
    if (state.filterPopupRect)
      setState((prevState) => ({
        ...prevState,
        filterPopupRect: undefined,
      }));
    else if (event) {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        filterPopupRect: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
      }));
    }
  };

  const {
    isAnony,
    folderId,
    onCheckedChange,
    sortOptions,
    sort,
    onChangeSort,
    filterOptions,
    filter,
    onChangeFilter,
    searchCode = 'subject',
    searchWord = '',
    startDate,
    endDate,
  } = props;

  const handleFilter = (value: string) => {
    handleFilterPopup();
    if (onChangeFilter) onChangeFilter(value);
  };

  const handleSort = (value: string) => {
    handleFilterPopup();
    if (onChangeSort) onChangeSort(value);
  };

  /** 기본검색 */
  const handleSearch = (arg: {
    keyword: string;
    directoryKeyword?: string;
    filter: string;
    date: SearchDateProps;
  }) => {
    const { onSearch } = props;
    if (onSearch) onSearch(arg);
  };

  /** 툴바 버튼 클릭 */
  const handleActionClick = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    props.onAction({ code: arg.code, event: arg.event });
  };

  const { title, pageNo, rowsPerPage, totalCount, checkedCount, onAction } =
    props;

  const searchCategory: { value: string; label: string }[] =
    isAnony || folderId === 'temp'
      ? [
          { value: 'subject', label: '제목' },
          // { value: 'content', label: '본문' }, // TODO
        ]
      : [
          { value: 'subject', label: '제목' },
          { value: 'employee', label: '작성자' },
          // { value: 'content', label: '본문' }, // TODO
        ];

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <AttendanceSearch
            dateOptions
            date={{
              start: startDate ? timezoneDate(startDate) : null,
              end: endDate ? timezoneDate(endDate) : null,
            }}
            directory
            dualSearch
            keyword={searchWord}
            options={searchCategory}
            filter={searchCode}
            onSearch={handleSearch}
          />
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          {checkedCount === 0 ? (
            <>
              <EuiHeader.ToolbarLeft>
                <Button
                  className="check-all"
                  text="선택"
                  iconType
                  icon="list-check"
                  onClick={() => {
                    onCheckedChange('all', true);
                  }}
                />
                {(sortOptions !== undefined || filterOptions !== undefined) && (
                  <Button
                    text="필터"
                    iconType
                    icon="filter-list"
                    onClick={handleFilterPopup}
                  />
                )}
              </EuiHeader.ToolbarLeft>
              <EuiHeader.ToolbarRight>
                {totalCount > 0 && (
                  <Pagination
                    no={pageNo}
                    rows={rowsPerPage}
                    count={totalCount}
                    onPrev={(event) => onAction({ code: 'prevPage', event })}
                    onNext={(event) => onAction({ code: 'nextPage', event })}
                  />
                )}
                {totalCount > 0 && display !== 'phone' && (
                  <Divider orientation="vertical" />
                )}
                {display !== 'phone' && (
                  <Button
                    text="목록설정"
                    iconType
                    icon="bar-cog"
                    onClick={(event) =>
                      onAction({ code: 'listSetting', event })
                    }
                  />
                )}
              </EuiHeader.ToolbarRight>
            </>
          ) : (
            <EuiHeader.ToolbarLeft>
              <Button
                className="action-close"
                text="취소"
                iconType
                icon="close"
                onClick={() => {
                  props.onCheckedChange('all', false);
                }}
                vibrate
              />
              <span className="selected-count">
                <em>{props.checkedCount}</em> <span>개 선택됨</span>
              </span>
              <ToolbarAction
                event={props.toolbarButtons}
                onClick={handleActionClick}
              />
            </EuiHeader.ToolbarLeft>
          )}
        </EuiHeader.Toolbar>
      </EuiHeader>
      {state.filterPopupRect && (
        <Menu
          point={state.filterPopupRect}
          onClose={handleFilterPopup}
          size="sm"
        >
          {filterOptions && (
            <>
              <MenuGroup>필터</MenuGroup>
              {filterOptions.map((x) => (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  checked={x.value === filter}
                  onClick={() => handleFilter(x.value)}
                />
              ))}
            </>
          )}
          {sortOptions && (
            <>
              <MenuDivider />
              <MenuGroup>정렬</MenuGroup>
              {sortOptions.map((x) => (
                <MenuItem
                  key={x.value}
                  label={x.label}
                  checked={x.value === sort}
                  onClick={() => handleSort(x.value)}
                />
              ))}
            </>
          )}
        </Menu>
      )}
    </>
  );
}

export default BoardContentHeadList;
