import { useEffect } from 'react';
import { getQueryParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { schedulesActions } from '../../../../stores/calendar/schedules';

function CalendarComposeRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    if (mount) {
      if (queryParams.contentMode === 'create') {
        dispatch(schedulesActions.viewClear());
        dispatch(sessionActions.setRoute({ pathname, search, hash }));
      } else if (queryParams.contentMode === 'update')
        dispatch(sessionActions.setRoute({ pathname, search, hash }));
    }
    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default CalendarComposeRoute;
