/* eslint-disable consistent-return */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiConfig } from '../../../groupware-common/apis/common/v1';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';

/** 조회권 */
const name = 'approval/viewpermission';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface State {
  requests: { id: string; type: string; arg: any }[];
  viewpermission: {
    companyId: number;
    id: number;
    referenceCompanyId: number;
    referenceId: number;
    referenceType: number;
    createAt: string;
    updateAt: string;
    subject: string;
    drafterId: number;
    drafterOrganizationId: number;
    checked: boolean;
  }[];
  totalCount: number;
}

const initialState: State = {
  requests: [],
  viewpermission: [],
  totalCount: 0,
};

/* 조회권 리스트 */
const viewPermission = createAsyncThunk(
  `${name}/viewPermission`,
  async (arg: LocateArg) => {
    try {
      const searchParams = getQueryParams(arg.route?.search || '');
      const { pageNo = 1, rowsPerPage = 15, category, keyword } = searchParams;

      let params:
        | {
            pageNo: number;
            rowsPerPage: number;
            searchCode: string;
            searchWord: string;
          }
        | { pageNo: number };

      if (keyword === undefined) {
        params = { pageNo, rowsPerPage };
      } else {
        params = {
          pageNo,
          rowsPerPage: 15,
          searchCode: category,
          searchWord: keyword,
        };
      }

      // console.log('viewPermission 조회');
      const { host, headers } = getApiConfig();
      const listurl = `${host}/api/approval/v1/viewpermission`;
      const listresponse = await axios.get(listurl, { headers, params });

      // console.log('viewPermission 카운트');
      const totalcnturl = `${host}/api/approval/v1/viewpermission/all/totalcount`;
      const totalcntresponse = await axios.get(totalcnturl, { headers });

      const data = {
        items: listresponse.data,
        totalCount: totalcntresponse.data.totalCount,
      };

      return data;
    } catch (error) {
      // console.log('error : ', error);
    }
  },
);

/* 조회권 등록 */
const viewPermissionSave = createAsyncThunk(
  `${name}/viewPermissionSave`,
  async (
    arg: {
      drafterId: number | undefined;
      drafterCompanyId: number | undefined;
      referenceId: number | undefined;
      referenceCompanyId: number | undefined;
      referenceType: number | undefined;
    } & LocateArg,
  ) => {
    try {
      const params = {
        drafterId: arg.drafterId,
        drafterCompanyId: arg.drafterCompanyId,
        referenceId: arg.referenceId,
        referenceCompanyId: arg.referenceCompanyId,
        referenceType: arg.referenceType,
      };

      // console.log('viewPermissionSave 등록');
      // console.log(params);
      const { host, headers } = getApiConfig();
      const url = `${host}/api/approval/v1/viewpermission/all`;
      const response = await axios.post(url, params, { headers });
      // console.log(response);
    } catch (error) {
      // console.log('error : ', error);
    }
  },
);

/* 조회권 삭제 */
const viewPermissionDel = createAsyncThunk(
  `${name}/viewPermissionDel`,
  async (
    arg: {
      data: {
        id: number;
        referenceCompanyId: number;
        referenceId: number;
        referenceType: number;
        updateAt: string;
      }[];
    } & LocateArg,
  ) => {
    try {
      if (arg.data.length === 0) {
        window.alert('체크박스를 선택하세요.');
        return;
      }

      // console.log('viewPermissionDel 삭제');
      const { host, headers } = getApiConfig();
      const url = `${host}/api/approval/v1/viewpermission`;
      // console.log(arg.data);
      const response = await axios.delete(url, { headers, data: arg.data });
      // console.log(response);
    } catch (error) {
      // console.log('error : ', error);
    }
  },
);

const viewPermissionSlice = createSlice({
  name,
  initialState,
  reducers: {
    setViewPermissionListItemChecked(
      state,
      action: PayloadAction<{
        id: number;
        referenceId: number;
        checked: boolean;
      }>,
    ) {
      if (state.viewpermission) {
        /*
        if (action.payload.id === 'all') {
          state.list.data.items = state.list.data.items.map((x) => {
            if (x.checked === action.payload.checked) return x;
            return { ...x, checked: action.payload.checked };
          });
          
        } else {
        */
        const { id, referenceId } = action.payload;
        const index = state.viewpermission.findIndex(
          (x) => x.id === id && x.referenceId === referenceId,
        );

        if (index > -1) {
          state.viewpermission[index] = {
            ...state.viewpermission[index],
            checked: action.payload.checked,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewPermission.fulfilled, (state, action) => {
        const { requests } = state;
        // eslint-disable-next-line prettier/prettier
        const {
          payload,
          meta: { requestId },
        } = action;

        // 페이로드가 있고 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (payload && asyncRequestContains(requests, requestId)) {
          if (payload.items !== undefined) state.viewpermission = payload.items;
          if (payload.totalCount !== undefined)
            state.totalCount = payload.totalCount;

          // 요청 완료로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      })

      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default viewPermissionSlice.reducer;

export const approvalViewPermissionActions = {
  setViewPermissionListItemChecked:
    viewPermissionSlice.actions.setViewPermissionListItemChecked,

  viewPermissionDel,
  viewPermissionSave,
  viewPermission,
};
