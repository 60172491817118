import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Switch from '../../../../../components/switch/Switch';
import SwitchGroup from '../../../../../components/switch/SwitchGroup';

function ComponentSwitchGrouped(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Switch group">
      <SwitchGroup legend="Assign responsibility">
        <Switch label="동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세" />
        <Switch label="Jason Killian" />
        <Switch label="Antoine Llorca" />
      </SwitchGroup>
    </ComponentSampleUI.Content>
  );
}

export default ComponentSwitchGrouped;
