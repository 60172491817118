import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { useSelector } from 'react-redux';
import Button from '../../../../../../components/button/Button';
import Dialog from '../../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../../components/dialog/DialogFooter';
import {
  getPathParams,
  getQueryParams,
  goBack,
} from '../../../../../../groupware-common/utils';
import { RootState } from '../../../../../../groupware-webapp/app/store';
import { jwtDecode } from '../../../../../../groupware-webapp/stores/session';

function ErrorDialog(props: {
  pathname?: string;
  search?: string;
  error?: {
    error: string;
    path: string;
    message?: string;
    onConfirm(deleteAll?: boolean): void;
  };
}): JSX.Element | null {
  // console.log(`${ErrorDialog.name}.render`);
  const isSession = useSelector(
    (state: RootState) => state.session.isSessionLogin,
  );
  const reduxToken = useSelector((state: RootState) => state.session.jwt);
  const jwt = jwtDecode(reduxToken ?? '');
  const teams =
    useSelector((state: RootState) => state.session.resource) === 'teams';
  const search = props.search ? getQueryParams(props.search) : undefined;
  // 인증 만료 유무
  const expSession = isSession && jwt && jwt.payload.exp < Date.now() / 1000;
  if (props.error === undefined) return null;

  const { error, path } = props.error;
  const message = expSession
    ? '인증 세션이 만료되었습니다.'
    : props.error.message;
  const onConfirm = () => {
    props.error?.onConfirm(expSession);
    if (teams) {
      // 팀즈 - 결재하기 오류 시.
      if (props.pathname && path === '/api/approval/v1/document/approval') {
        const { p1, p2 } = getPathParams<{
          p1?: string;
          p2?: string;
        }>('/:p1/:p2/*/*', props.pathname);
        if (p1 === 'approval' && p2 === 'approve') {
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask();
        }
      }
      // 팀즈 - 문서 읽기 실패 시.
      if (props.pathname && path === '/api/approval/v1/document') {
        const { p1, p2 } = getPathParams<{
          p1?: string;
          p2?: string;
        }>('/:p1/:p2/*/*', props.pathname);
        if (p1 === 'approval' && p2 === 'document') {
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask();
        }
      }
      // 팀즈 - 연차촉진알림 문서 읽기 실패 시.
      if (props.pathname && path === '/api/attendance/v1/leave/alert/form') {
        const { p1, p2 } = getPathParams<{
          p1?: string;
          p2?: string;
        }>('/:p1/:p2/*/*', props.pathname);
        if (p1 === 'attendance' && p2 === 'alert') {
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask();
        }
      }
      return;
    }
    // 문서 읽기 실패 시 goBack.
    if (
      props.pathname &&
      (path === '/api/approval/v1/document' ||
        path === '/api/approval/v1/share/reference/document' ||
        path === '/api/approval/v1/share/view/document') &&
      search?.contentMode === undefined &&
      search?.dialogType === undefined
    ) {
      const { p1, p2, p3, p4 } = getPathParams<{
        p1?: string;
        p2?: string;
        p3?: string;
        p4?: string;
      }>('/:p1/:p2/:p3/:p4', props.pathname);
      // 연동 문서 읽기 실패 시 윈도우 창 닫기.
      if (
        p1 === 'approval' &&
        p2 === 'approvalinterworking' &&
        p3 === 'document'
      ) {
        window.close();
        return;
      }
      if (
        (p1 === 'adminconsole' && p4) ||
        (p1 === 'approval' && p3) ||
        (p1 === 'attendance' && p3)
      )
        goBack();
    }
  };

  if (teams && props.pathname) {
    const { p1, p2 } = getPathParams<{
      p1?: string;
      p2?: string;
    }>('/:p1/:p2/*/*', props.pathname);
    if (p1 === 'approval' && (p2 === 'document' || p2 === 'approve')) {
      return (
        <div
          className="eui-dialog sm"
          style={{
            padding: 0,
            bottom: 'auto',
          }}
        >
          <div className="dialog-container">
            <DialogBody>
              <div className="eui-alert-message">{message || error}</div>
            </DialogBody>
            <DialogFooter>
              <Button text="확인" variant="contained" onClick={onConfirm} />
            </DialogFooter>
          </div>
        </div>
      );
    }
  }
  return (
    <Dialog size="xs">
      <DialogBody>
        <div className="eui-alert-message">{message || error}</div>
      </DialogBody>
      <DialogFooter>
        <Button text="확인" variant="contained" onClick={onConfirm} />
      </DialogFooter>
    </Dialog>
  );
}

export default ErrorDialog;
