import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';

import {
  formActions,
  formFolderActions,
} from '../../../../stores/approval/form';
import { preferencesActions } from '../../../../stores/approval/preferences';

function ApprovalFormRoute({ pathname }: { pathname: string }): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(preferencesActions.findApprovalType()),
      dispatch(formActions.clear()),
    ]).then(() => {
      dispatch(formFolderActions.list({ route: { pathname } }));
    });
  }, [dispatch, pathname]);

  return null;
}

export default ApprovalFormRoute;
