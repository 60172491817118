/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint-disable no-underscore-dangle */
import { IconType } from '../../../groupware-common/types/icon';
import { dateFormat, dateTimeFormat } from '../../../groupware-common/utils/ui';

class FormBuilder {
  // prettier-ignore
  private _item: {
    controls: { id: string; text: string; icon: IconType; description: string; }[];
    approvalMacros: { id: string; text: string; icon: IconType; description: string; }[];
    attendanceMacros: { id: string; text: string; icon: IconType; description: string; }[];
    documentMacros: { id: string; text: string; icon: IconType; description: string; }[];
    boardMacros: { id: string; text: string; icon: IconType; description: string; }[];
    systemlinkMacros: { id: string; text: string; icon: IconType; description: string; }[];
  } = {
    controls: [
      { id: 'CONTROL/TEXT', text: '입력필드', icon: 'input', description: '한 줄의 텍스트를 입력할수 있습니다.' },
      { id: 'CONTROL/TEXTAREA', text: '입력영역', icon: 'multi-text', description: '여러줄의 텍스트를 입력할수 있습니다.' },
      { id: 'CONTROL/EDITOR', text: '에디터', icon: 'edit', description: '텍스트,그림,표 등을 추가할수 있는 에디터입니다.' },
      { id: 'CONTROL/NUMBER', text: '숫자', icon: 'number', description: '최대 12자리의 숫자를 입력할수 있습니다.(1,000단위로 \',\' 자동입력)' },
      { id: 'CONTROL/RADIO', text: '라디오버튼', icon: 'radio-checked', description: '하나의 옵션만 선택할 수 있는 입력항목입니다.' },
      { id: 'CONTROL/CHECKBOX', text: '체크박스', icon: 'check-square', description: '여러개의 옵션을 선택할 수 있는 입력항목입니다.' },
      { id: 'CONTROL/SELECT', text: '펼침목록', icon: 'selectbox', description: '하나의 옵션만 선택할 수 있는 입력항목입니다.' },
      { id: 'CONTROL/DATE', text: '날짜', icon: 'calendar-day', description: '날짜를 설정할 수 있는 입력항목입니다.' },
      { id: 'CONTROL/TIME', text: '시간', icon: 'clock', description: '시간을 설정할 수 있는 입력항목입니다.(10분단위)' },
      { id: 'CONTROL/DATE_RANGE', text: '기간', icon: 'calendar-week', description: '기간을 설정할 수 있는 입력항목입니다.' },
      { id: 'CONTROL/DIALOG', text: '대화상자', icon: 'new-tab', description: '팝업을 설정할 수 있는 입력항목입니다.' },
      { id: 'CONTROL/ROW_ADD', text: '행추가', icon: 'list-plus', description: '행을 설정할 수 있는 입력항목입니다.' },
    ],
    approvalMacros: [
      { id: 'DIRECTORY/COMAPNY', text: '회사명', icon: 'company', description: '회사명이 표기됩니다.' }, // id: 'CMP_NAME'
      { id: 'APPROVAL/COMPANY_LOGO', text: '회사로고', icon: 'company', description: '회사로고가 표기됩니다.' }, // id: 'CMP_LOGO'
      { id: 'DIRECTORY/COMPANY_POSTALCODE', text: '우편번호', icon: 'zip-code', description: '우편번호가  표기됩니다.' }, // id: 'CMP_POST'
      { id: 'DIRECTORY/COMPANY_ADDRESS', text: '회사주소', icon: 'address', description: '회사주소가  표기됩니다.' }, // id: 'CMP_ADDR'
      { id: 'DIRECTORY/COMPANY_PHONE_NO', text: '회사전화', icon: 'phone-office', description: '회사전화번호가 표기됩니다.' }, // id: 'CMP_TEL'
      { id: 'DIRECTORY/COMPANY_FAX_NO', text: '회사팩스', icon: 'fax', description: '회사팩스번호가 표기됩니다.' },  // id: 'CMP_FAX'
      { id: 'COMMON/TODAY', text: '오늘날짜', icon: 'calendar-day', description: '오늘날짜가 표기됩니다.' }, // id: 'TODAY'
      { id: 'APPROVAL/WORK_NAME', text: '업무명', icon: 'clipboard-text', description: '업무명이 표기됩니다.' },
      { id: 'APPROVAL/DOCUMENT_NO', text: '문서번호', icon: 'document-number', description: '문서번호가 표기됩니다.' }, // id: 'DOC_SERIAL'
      { id: 'APPROVAL/DOCUMENT_SUBJECT', text: '문서제목', icon: 'multi-text', description: '제목입력란으로 한 줄의 텍스트를 입력할수 있습니다.' }, // id: 'DOC_TITLE'
      { id: 'APPROVAL/DOCUMENT_RETENTION_PERIOD', text: '보존기간', icon: 'calendar-week', description: '보존기간이 표기됩니다.' }, // id: 'DOC_TIME'
      // { id: 'APPROVAL/DOCUMENT_IS_PUBLIC', text: '공개여부', icon: 'global', description: '문서의 공개여부가 표기됩니다.' },  // id: 'OPEN_YN'
      { id: 'APPROVAL/DRAFT_DATE', text: '기안날짜', icon: 'calendar-day', description: '기안날짜가 표기됩니다.' },
      { id: 'APPROVAL/DRAFTER', text: '기안자명', icon: 'id-badge', description: '기안자명이 표기됩니다.' },  // id: 'NAME'
      { id: 'APPROVAL/DRAFTER_EMPLOYEE_NO', text: '기안자 사번', icon: 'number', description: '기안자의 사번이 표기됩니다.' }, // id: 'OFFICE_ID'
      { id: 'APPROVAL/DRAFTER_ADDRESS', text: '기안자 주소', icon: 'address', description: '기안자의 주소가 표기됩니다.' }, // id: 'ADDR'
      { id: 'APPROVAL/DRAFTER_ORGANIZATION', text: '기안자 조직', icon: 'sitemap', description: '기안자의 조직(부서 또는 팀) 이름이 표기됩니다.' }, // id: 'DEPT_NAME'
      { id: 'APPROVAL/DRAFTER_JOBPOSITION', text: '기안자 직위', icon: 'tie', description: '기안자의 직위가 표기됩니다.' }, // id: 'POSITION'
      { id: 'APPROVAL/DRAFTER_ID', text: '기안자 아이디', icon: 'id', description: '기안자 아이디가 표기됩니다.' },  // id: 'DRAFTER_ID'
      { id: 'APPROVAL/DRAFTER_EMAIL', text: '기안자 이메일', icon: 'at', description: '기안자 이메일주소가 표기됩니다.' }, // id: 'DRAFTER_EMAIL'
      { id: 'APPROVAL/DRAFTER_COMPANY_PHONE_NO', text: '기안자 회사전화', icon: 'phone-office', description: '기안자 회사전화 번호가 표기됩니다.' }, // id: 'DRAFTER_CMPNO'
      { id: 'APPROVAL/DRAFTER_MOBILE_PHONE_NO', text: '기안자 휴대전화', icon: 'mobile', description: '기안자의 휴대전화 번호가 표기됩니다.' },  // id: 'PHONE'
      { id: 'APPROVAL/DRAFTER_EXTENSION_PHONE_NO', text: '기안자 내선전화', icon: 'phone-office', description: '기안자의 내선전화 번호가 표기됩니다.' },  // id: 'EXT_NO'
      // { id: 'APPROVAL/DOCUMENT_COMPLETEAT', text: '결재완료일', icon: 'calendar-day', description: '결재완료일이 표기됩니다.' },  // id: 'END_DATE'
      { id: 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST', text: '결재조직', icon: 'sitemap', description: '결재 조직(부서 또는 팀)이 표기됩니다.' },
      { id: 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST', text: '합의조직', icon: 'sitemap', description: '합의 조직(부서 또는 팀)이 표기됩니다.' }, // id: 'AGREE_DEPT'
      { id: 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST', text: '수신조직', icon: 'sitemap', description: '수신 조직(부서 또는 팀)이 표기됩니다.' }, // id: 'RECEIVE_DEPT'
      { id: 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST', text: '감사조직', icon: 'sitemap', description: '감사 조직(부서 또는 팀)이 표기됩니다.' },
      { id: 'APPROVAL/LAST_APPROVER', text: '최종결재자명', icon: 'id-badge', description: '최종결재자의 이름이 표기됩니다.' },  // id: 'LASTAPPR_NAME'
      { id: 'APPROVAL/LAST_APPROVER_ORGANIZATION', text: '최종결재자 조직', icon: 'sitemap', description: '최종결재자의 조직(부서 또는 팀) 이름이 표기됩니다.' },  // id: 'LASTAPPR_DEPT'
      { id: 'APPROVAL/LAST_APPROVER_JOBPOSITION', text: '최종결재자 직위', icon: 'tie', description: '최종결재자의 직위가 표기됩니다.' }, // id: 'LASTAPPR_POS'
      // { id: 'APPROVAL/LAST_APPROVER_SIGNATURE', text: '최종결재자 서명', icon: 'signature', description: '최종결재자의 서명이 표기됩니다.' },  // id: 'LASTAPPR_SIGN'
      // { id: 'ERP_BODY', text: '연동본문', icon: 'chain', description: '문서연동시 입력하는 항목입니다.' },  // id: 'ERP_BODY'
      // { id: 'ORG_DEPT', text: '조직정보', icon: 'info-circle', description: '조직정보를 설정하는 항목입니다.' },  // id: 'ORG_DEPT'
      { id: 'APPROVAL/APPROVALLINE_APPROVAL_GROUP', text: '결재그룹', icon: 'stamp',  description: '결재선의 결재그룹이 표시됩니다.' }, // id: 'APPROV_LINE'
      { id: 'APPROVAL/APPROVALLINE_AGREE_GROUP', text: '합의그룹', icon: 'stamp', description: '결재선의 합의그룹이 표시됩니다.' },  // id: 'AGREE_LINE'
      { id: 'APPROVAL/APPROVALLINE_RECEIVE_GROUP', text: '수신그룹', icon: 'stamp', description: '결재선의 수신그룹이 표시됩니다.' },  // id: 'RECEIVE_LINE'
      { id: 'APPROVAL/APPROVALLINE_AUDIT_GROUP', text: '감사그룹', icon: 'stamp', description: '결재선의 감사그룹이 표시됩니다.'},  // id: 'AUDIT_LINE'
      { id: 'APPROVAL/VIEW_PERMISSION_LIST', text: '조회', icon: 'eye', description: '조회자 또는 조회조직(부서 또는 팀) 이름이 표기됩니다.' },  // id: 'CHECK'
      { id: 'APPROVAL/REFERENCE_PERMISSION_LIST', text: '참조', icon: 'user-check', description: '참조자 또는 참조조직(부서 또는 팀) 이름이 표기됩니다.' }, // id: 'REFER'
      // TODO 미구현
      // { id: 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM', text: '결재항목', icon: 'stamp', description: '결재선에서 지정된 결재 항목이 표시됩니다.' }, // id: 'AP_P#1'
      // TODO 사후결재는 후결로 처리하는 방법 검토 필요.
      // { id : 'MultiLine1', text: '다단계결재선', icon : 'stamp', description : '다단계결재선이 표시됩니다.' },
      // { id : 'AUDITOR_#1', text: '사후결재선', icon : 'stamp', description : '사후결재자가 표시됩니다.' }
      ],
      attendanceMacros: [
        { id: 'ATTENDANCE/DATE_RANGE', text: '기간', icon: 'calendar-week', description: '근태문서 작성 시 설정하는 항목입니다.' },
        { id: 'ATTENDANCE/START', text: '시작일', icon: 'calendar-day', description: '근태문서 작성 시 설정하는 항목입니다. (연차 매크로 사용 시 기간 매크로 사용.)' },
        { id: 'ATTENDANCE/END', text: '종료일', icon: 'calendar-day', description: '근태문서 작성 시 설정하는 항목입니다. (연차 매크로 사용 시 기간 매크로 사용.)' },
        { id :'ATTENDANCE/WORKTIME', text: '시작시간', icon: 'clock', description: '근태문서 작성 시 설정하는 항목입니다.' },
        { id: 'ATTENDANCE/OFFTIME', text: '종료시간', icon: 'clock', description: '근태문서 작성 시 설정하는 항목입니다.' },
        { id: 'ATTENDANCE/USETIME', text: '연차사용시간', icon: 'clock-check', description: '근태 시간 단위 연차 문서 작성 시 설정하는 항목입니다.' },
        // { id: 'ATTENDANCE/TOTAL_WORKTIME', text: '근무시간', icon: 'clock-check', description: '근태문서 작성 시 설정하는 항목입니다.' },
        { id: 'ATTENDANCE/DAYOFF', text: '연차', icon: 'selectbox', description: '근태 연차 문서 작성 시 설정하는 항목입니다. (연차 매크로 사용 시 기간 매크로와 함께 사용)'},
        { id: 'ATTENDANCE/REASON', text: '사유', icon: 'input', description: '근태문서 작성 시 설정하는 항목입니다.'},
        { id: 'ATTENDANCE/REMAIN_COUNT', text: '잔여연차', icon: 'note', description: '대상자의 잔여연차가 표기됩니다.'},
        { id: 'ATTENDANCE/REMAIN_SUBSTITUTE', text: '잔여대휴', icon: 'note', description: '대상자의 잔여대휴가 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_REMAIN_COUNT', text: '연차촉진 잔여연차', icon: 'note', description: '연차촉진알림 발송 시점 대상자의 잔여연차가 표기됩니다.'},
        { id: 'ATTENDANCE/USEPLAN_REMAIN_COUNT', text: '사용계획 잔여연차', icon: 'note', description: '사용계획 잔여연차가 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_NAME', text: '연차촉진 대상자명', icon: 'id-badge', description: '연차촉진알림 대상자의 이름이 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_SENDER_NAME', text: '연차촉진 발송자명', icon: 'id-badge', description: '연차촉진알림 발송자의 이름이 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_EMPLOYEENO', text: '연차촉진 대상자사번', icon: 'number', description: '연차촉진알림 대상자의 사번이 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_ORGANIZATION', text: '연차촉진 대상자부서', icon: 'sitemap', description: '연차촉진알림 대상자의 부서가 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_JOBPOSITION', text: '연차촉진 대상자직위', icon: 'tie', description: '연차촉진알림 대상자의 직위가 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_ENTERDATE', text: '연차촉진 대상자 입사일자', icon: 'calendar-next', description: '연차촉진알림 대상자의 입사일자가 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_SIGNATURE', text: '연차촉진 대상자서명', icon: 'signature', description: '연차촉진알림 대상자의 서명이 표기됩니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE', text: '연차사용 예정기간', icon: 'calendar-day', description: '연차사용계획서, 지정일통보문서에서 사용합니다.'},
        { id: 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL', text: '예정기간 총 합계', icon: 'note', description: '연차사용 지정기간 매크로에서 설정한 총 사용 일수의 합계를 표시합니다. (연차사용 지정기간 매크로와 함께 사용합니다.)'},
    ],
    documentMacros: [
      { id: 'DOCUMENT/TODAY', text: '오늘 날짜', icon: 'calendar-day', description: '오늘 날짜가 표기됩니다.' },
      { id: 'DIRECTORY/COMAPNY', text: '회사명', icon: 'company', description: '회사명이 표기됩니다.' }, // id: 'CMP_NAME'
      { id: 'DOCUMENT/AUTHOR', text: '작성자명', icon: 'id-badge', description: '작성자명이 표기됩니다.' }, 
      { id: 'DOCUMENT/AUTHOR_ORGANIZATION', text: '작성자 조직', icon: 'sitemap', description: '작성자의 조직(부서 또는 팀) 이름이 표기됩니다.' },
      { id: 'DOCUMENT/AUTHOR_JOBPOSITION', text: '작성자 직위', icon: 'tie', description: '작성자의 직위가 표기됩니다.' }, 
      { id: 'DOCUMENT/AUTHOR_JOBDUTY', text: '작성자 직책', icon: 'tie', description: '작성자의 직위가 표기됩니다.' },
      { id: 'DOCUMENT/AUTHOR_JOBTITLE', text: '작성자 직함', icon: 'tie', description: '작성자의 직함(직위+직책)이 표기됩니다.' },
    ],
    boardMacros: [
      { id: 'BOARD/TODAY', text: '오늘 날짜', icon: 'calendar-day', description: '오늘 날짜가 표기됩니다.' },
      { id: 'DIRECTORY/COMPANY', text: '회사명', icon: 'company', description: '회사명이 표기됩니다.' }, // id: 'CMP_NAME'
      { id: 'BOARD/AUTHOR', text: '작성자명', icon: 'id-badge', description: '작성자명이 표기됩니다.' }, 
      { id: 'BOARD/AUTHOR_ORGANIZATION', text: '작성자 조직', icon: 'sitemap', description: '작성자의 조직(부서 또는 팀) 이름이 표기됩니다.' },
      { id: 'BOARD/AUTHOR_JOBPOSITION', text: '작성자 직위', icon: 'tie', description: '작성자의 직위가 표기됩니다.' }, 
      { id: 'BOARD/AUTHOR_JOBDUTY', text: '작성자 직책', icon: 'tie', description: '작성자의 직위가 표기됩니다.' },
      { id: 'BOARD/AUTHOR_JOBTITLE', text: '작성자 직함', icon: 'tie', description: '작성자의 직함(직위+직책)이 표기됩니다.' },
    ],
    systemlinkMacros: [
      { id: 'SYSTEMLINK/ERPBODY', text: '연동 본문', icon: 'document', description: '연동 본문을 가져옵니다.' },
    ],
  };

  private _iframe: HTMLIFrameElement | null = null;

  private _editor: any;

  private _editorId: string;

  private _body: HTMLElement | null;

  private _selectedElement: HTMLElement | null = null;

  private _select?(element: HTMLElement | null): void;

  private handleClick: any;

  constructor() {
    // console.log(`FormBuilder:constructor()`);

    this._editorId = '';
    this._body = null;

    this.handleClick = this.onClick.bind(this);
  }

  private onClick(event: React.MouseEvent): void {
    // console.log(`FormBuilder:onClick(event)`, event);
    // console.log(`FormBuilder:onClick:this`, this);
    if (this._select) this._select(this.getElement(event));
  }

  set onSelect(select: (element: HTMLElement) => void) {
    this._select = select;
  }

  setEditor(arg: { iframe: HTMLIFrameElement | null }): void {
    // console.log(`FormBuilder:setEditor(arg)`, arg);

    if (this._body !== null) {
      (this._body as any).removeEventListener('click', this.handleClick, false);
    }

    const { iframe } = arg;

    if (iframe === null) return;
    const iframeWindow = iframe.contentWindow || iframe.contentDocument;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (iframeWindow) {
      try {
        this._editor = (iframeWindow as any).getDEXT5() as any;
        this._editorId = iframe.getAttribute('id') ?? '';
        this._body = (iframeWindow as any).getDom(this._editorId);
      } catch (error) {
        //
      }

      if (this._body !== null)
        (this._body as any).addEventListener('click', this.handleClick, false);
    }
  }

  getItems(
    arg:
      | { role: 'control' }
      | { role: 'macro'; module: string }
      | { role: 'attendance' }
      | { role: 'systemlink' }
      | { role: 'document' }
      | { role: 'board' },
  ): { id: string; text: string; icon: IconType; description: string }[] {
    if (arg.role === 'control') return this._item.controls;
    if (arg.role === 'macro' && arg.module === 'approval')
      return this._item.approvalMacros;
    if (arg.role === 'attendance') return this._item.attendanceMacros;
    if (arg.role === 'document') return this._item.documentMacros;
    if (arg.role === 'board') return this._item.boardMacros;
    if (arg.role === 'systemlink') return this._item.systemlinkMacros;
    return [];
  }

  getItemText(arg: { role: string; type: string }): string {
    const { role, type } = arg;
    if (role === 'control') {
      if (
        type === 'CONTROL/NUMBER' ||
        type === 'CONTROL/CALCULATION_VALUE' ||
        type === 'CONTROL/FORMULA'
      )
        return (
          this._item.controls.find((a) => a.id === 'CONTROL/NUMBER')?.text || ''
        );
      return this._item.controls.find((a) => a.id === type)?.text || '';
    }
    if (role === 'macro')
      return this._item.approvalMacros.find((a) => a.id === type)?.text || '';
    if (role === 'attendance')
      return this._item.attendanceMacros.find((a) => a.id === type)?.text || '';
    if (role === 'systemlink')
      return this._item.systemlinkMacros.find((a) => a.id === type)?.text || '';
    if (role === 'document')
      return this._item.documentMacros.find((a) => a.id === type)?.text || '';
    if (role === 'board')
      return this._item.boardMacros.find((a) => a.id === type)?.text || '';
    return '';
  }

  getItemDescription(arg: { role: string; type: string }): string {
    const { role, type } = arg;
    if (role === 'control') {
      if (
        type === 'CONTROL/NUMBER' ||
        type === 'CONTROL/CALCULATION_VALUE' ||
        type === 'CONTROL/FORMULA'
      )
        return (
          this._item.controls.find((a) => a.id === 'CONTROL/NUMBER')
            ?.description || ''
        );
      return this._item.controls.find((a) => a.id === type)?.description || '';
    }

    if (role === 'macro')
      return (
        this._item.approvalMacros.find((a) => a.id === type)?.description || ''
      );
    if (role === 'attendance')
      return (
        this._item.attendanceMacros.find((a) => a.id === type)?.description ||
        ''
      );
    if (role === 'document')
      return (
        this._item.documentMacros.find((a) => a.id === type)?.description || ''
      );
    if (role === 'board')
      return (
        this._item.boardMacros.find((a) => a.id === type)?.description || ''
      );
    if (role === 'systemlink')
      return (
        this._item.systemlinkMacros.find((a) => a.id === type)?.description ||
        ''
      );
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  private getTagName(arg: { role: string; type: string }) {
    const { role, type } = arg;
    if (role === 'control') {
      switch (type) {
        case 'CONTROL/TEXTAREA':
          return 'TEXTAREA';
        case 'CONTROL/EDITOR':
          return 'GW-FB-ELEMENT-EDITOR';
        case 'CONTROL/SELECT':
          return 'SELECT';
        // case 'CONTROL/TEXT':
        // case 'CONTROL/NUMBER':
        // case 'CONTROL/RADIO':
        // case 'CONTROL/CHECKBOX':
        // case 'CONTROL/DATE':
        // case 'CONTROL/TIME':
        // case 'CONTROL/DATE_RANGE':
        // case 'CONTROL/DIALOG':
        // case 'CONTROL/ROW_ADD':
        default:
          return 'INPUT';
      }
    }
    if (role === 'macro') {
      switch (type) {
        case 'APPROVAL/COMPANY_LOGO':
          return 'GW-FB-ELEMENT-COMPANYLOGO';
        default:
          return 'GW-FB-ELEMENT';
      }
    }
    if (role === 'attendance') {
      switch (type) {
        case 'ATTENDANCE/REASON':
          return 'TEXTAREA';
        case 'ATTENDANCE/WORKTIME':
        case 'ATTENDANCE/OFFTIME':
        case 'ATTENDANCE/DAYOFF':
        case 'ATTENDANCE/USETIME':
        case 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE':
          return 'SELECT';
        case 'ATTENDANCE/DATE_RANGE':
        case 'ATTENDANCE/START':
        case 'ATTENDANCE/END':
        case 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL':
          return 'INPUT';
        default:
          return 'GW-FB-ELEMENT';
      }
    }
    if (role === 'systemlink') {
      switch (type) {
        case 'SYSTEMLINK/ERPBODY':
          return 'GW-FB-ELEMENT-ERPBODY';
        default:
          return 'GW-FB-ELEMENT';
      }
    }

    return 'GW-FB-ELEMENT';
  }

  private getElement(event: React.MouseEvent) {
    // console.log(`FormBuilder:getElement(event)`, event);
    // console.log(`event.currentTarget`, event.currentTarget);
    // console.log(`event.target`, event.target);

    const target = event.target as Element;

    this._selectedElement = target?.closest('gw-fb-element');
    if (this._selectedElement === null) {
      event.currentTarget
        .querySelector(`gw-fb-element-selection[style="display:inline;"]`)
        ?.removeAttribute('style');
      return null;
    }

    if (target.tagName === 'SPAN') {
      this._selectedElement.remove();
      this._selectedElement = null;
      return null;
    }

    event.currentTarget
      .querySelector(`gw-fb-element-selection[style="display:inline;"]`)
      ?.removeAttribute('style');
    this._selectedElement
      .querySelector(`gw-fb-element-selection`)
      ?.setAttribute('style', 'display:inline;');
    return this._selectedElement;
  }

  append(
    arg:
      | { id?: undefined; role: 'control'; type: string }
      | { id?: undefined; role: 'attendance'; type: string; text: string }
      | { id?: undefined; role: 'document'; type: string; text: string }
      | { id?: undefined; role: 'macro'; type: string; text: string }
      | { id?: undefined; role: 'board'; type: string; text: string }
      | { id?: undefined; role: 'systemlink'; type: string; text: string }
      | { id: string; type: string; value: string },
  ): void {
    if (!this._editor) return;

    const document = new DOMParser().parseFromString('', 'text/html');

    // 컨트롤 또는 매크로인 경우.
    if (arg.id === undefined) {
      const { role, type } = arg;
      const tagName = this.getTagName({ role, type });

      const element = document.createElement('gw-fb-element');
      element.setAttribute('data-role', role);
      element.setAttribute(`data-${role}`, type);
      element.setAttribute('contenteditable', 'false');
      const elementStyle =
        'display:inline-block; position:relative; box-sizing:border-box; line-height:1.2;';

      // console.log(`${role}:arg`, { type, tagName });

      if (role === 'attendance') {
        if (
          type === 'ATTENDANCE/DATE_RANGE' ||
          type === 'ATTENDANCE/START' ||
          type === 'ATTENDANCE/END' ||
          type === 'ATTENDANCE/WORKTIME' ||
          type === 'ATTENDANCE/OFFTIME' ||
          type === 'ATTENDANCE/DAYOFF' ||
          type === 'ATTENDANCE/REASON' ||
          type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL'
        )
          element.setAttribute('required', 'true');
      }
      if (tagName === 'INPUT') {
        const input = document.createElement('input');
        const style =
          'box-sizing:border-box; display:block; width:100%; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:inherit; font-size:12px; color:inherit;';
        if (type === 'CONTROL/TEXT') {
          input.type = 'text';
          input.setAttribute('style', style);
          element.setAttribute('style', `${elementStyle} width:100%;`);
          element.appendChild(input);
        }
        if (
          type === 'ATTENDANCE/START' ||
          type === 'ATTENDANCE/END' ||
          type === 'ATTENDANCE/DATE_RANGE'
        ) {
          input.type = 'date';
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          element.setAttribute('style', `${elementStyle}`);
          if (type === 'ATTENDANCE/DATE_RANGE')
            element.innerHTML = `${input.outerHTML} ~ ${input.outerHTML}`;
          else element.appendChild(input);
        }
        if (type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL') {
          input.type = 'text';
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          input.setAttribute('readOnly', 'true');
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(input);
        }
        if (type === 'CONTROL/NUMBER') {
          input.type = 'text';
          input.setAttribute('style', `${style} text-align:right;`);
          element.setAttribute('style', `${elementStyle} width:150px;`);
          element.appendChild(input);
        }
        if (type === 'CONTROL/RADIO' || type === 'CONTROL/CHECKBOX') {
          input.type = type === 'CONTROL/RADIO' ? 'radio' : 'checkbox';
          element.setAttribute('style', `${elementStyle}`);
          const timestamp = Date.now();
          ['A', 'B'].forEach((a, i) => {
            const control = input.cloneNode(true) as HTMLInputElement;
            control.id = `${type}/${timestamp + i}`;
            control.name = `${type}/${timestamp}`;
            control.setAttribute('value', a);
            element.appendChild(control);
            const label = document.createElement('label');
            label.textContent = a;
            element.appendChild(label);
          });
        }
        if (type === 'CONTROL/DATE' || type === 'CONTROL/TIME') {
          input.type = type === 'CONTROL/DATE' ? 'date' : 'time';
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(input);
        }
        if (type === 'CONTROL/DATE_RANGE') {
          input.type = 'date';
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          element.setAttribute('style', `${elementStyle}`);
          element.innerHTML = `${input.outerHTML} ~ ${input.outerHTML}`;
        }
        if (type === 'CONTROL/DIALOG') {
          input.type = 'button';
          input.setAttribute(
            'style',
            '-webkit-appearance:none; -webkit-tap-highlight-color:transparent; box-sizing:border-box; display:block; min-width:24px; max-width:100%; height:24px; margin:0; padding:4px; border-radius:2px; border:1px solid #888888; background:#eee; line-height:14px; text-align:center;',
          );
          input.setAttribute('value', '대화상자');
          input.setAttribute('id', `dialog${Date.now()}`);
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(input);
        }
        if (type === 'CONTROL/ROW_ADD') {
          input.type = 'button';
          input.setAttribute(
            'style',
            '-webkit-appearance:none; -webkit-tap-highlight-color:transparent; box-sizing:border-box; min-width:24px; max-width:100%; height:24px; margin:0; padding:4px; border-radius:2px; border:1px solid #888888; background:#eee; line-height:14px; text-align:center;',
          );
          input.setAttribute('value', '+');
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(input);
        }
      }
      if (tagName === 'TEXTAREA') {
        const textarea = document.createElement('textarea');
        textarea.setAttribute(
          'style',
          'resize:none; box-sizing:border-box; display:block; width:100%; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:inherit; font-size:12px; color:inherit; text-align:left;',
        );
        textarea.setAttribute('rows', '2');
        textarea.setAttribute('spellcheck', 'false');
        element.setAttribute('style', `${elementStyle} width:100%;`);
        element.appendChild(textarea);
      }
      if (tagName === 'GW-FB-ELEMENT-EDITOR') {
        const editor = document.createElement('gw-fb-element-editor');
        editor.setAttribute(
          'style',
          'box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; height:200px; line-height:200px; text-align:center; background:#eee;',
        );
        editor.setAttribute('contenteditable', 'false');
        editor.textContent = '에디터';
        element.setAttribute('style', `${elementStyle} width:100%;`);
        element.appendChild(editor);
      }

      if (tagName === 'SELECT') {
        if (type === 'ATTENDANCE/DAYOFF') {
          const select = document.createElement('select');
          const timestamp = Date.now();
          ['연차', '오전반차', '오후반차'].forEach((a, i) => {
            const option = document.createElement('option');
            option.id = `option/${timestamp + i}`;
            option.value = a;
            option.textContent = a;
            if (i === 0) option.setAttribute('selected', 'selected');
            select.appendChild(option);
          });
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(select);
        } else if (
          type === 'ATTENDANCE/WORKTIME' ||
          type === 'ATTENDANCE/OFFTIME'
        ) {
          element.setAttribute('data-time-interval', '30');
          const select = document.createElement('select');
          select.setAttribute(
            'style',
            'font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          const timestamp = Date.now();
          const hour = new Date().getHours();
          const minute = new Date().getMinutes();

          for (let i = 0; i < 24; i += 1) {
            for (let x = 0; x < 60; x += 30) {
              const hours: string = i.toString().padStart(2, '0');
              const minutes: string = x.toString().padStart(2, '0');
              const option = document.createElement('option');
              option.id = `option/${timestamp + i}`;
              option.value = `${hours}:${minutes}`;
              option.textContent = `${hours}:${minutes}`;
              if (i === hour && x < minute)
                option.setAttribute('selected', 'selected');
              select.appendChild(option);
            }
          }
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(select);
        } else if (type === 'ATTENDANCE/USETIME') {
          element.setAttribute('data-time-type', 'hour');
          const select = document.createElement('select');
          select.setAttribute(
            'style',
            'font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          const timestamp = Date.now();
          // 한 시간 단위로 사용.
          for (let i = 0; i < 8; i += 1) {
            const option = document.createElement('option');
            option.id = `option/${timestamp + i}`;
            option.value = `${i + 1}`;
            option.textContent = `${i + 1}`;
            if (i === 0) option.setAttribute('selected', 'selected');
            select.appendChild(option);
          }
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(select);
        } else if (type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE') {
          const select = document.createElement('select');
          select.setAttribute(
            'data-attendance',
            'ATTENDANCE/SENDNOTICE_DATE_OPTION',
          );
          const timestamp = Date.now();
          ['종일', '반차', '시간'].forEach((a, i) => {
            const option = document.createElement('option');
            option.id = `option/${timestamp + i}`;
            option.value = a;
            option.textContent = a;
            if (i === 0) option.setAttribute('selected', 'selected');
            select.appendChild(option);
          });
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(select);

          const scheduledPeriod = document.createElement('span');
          scheduledPeriod.setAttribute('data-role', role);
          scheduledPeriod.setAttribute('contenteditable', 'false');
          const scheduledPeriodStyle =
            'display:inline-block; position:relative; box-sizing:border-box; line-height:1.2; vertical-align:middle; margin-left:5px';

          const input = document.createElement('input');
          input.type = 'date';
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          input.setAttribute('min', dateFormat(new Date(), 'yyyy-MM-DD'));

          scheduledPeriod.setAttribute('required', 'true');
          scheduledPeriod.setAttribute('style', `${scheduledPeriodStyle}`);
          scheduledPeriod.setAttribute(
            `data-${role}`,
            'ATTENDANCE/SENDNOTICE_DATE',
          );
          scheduledPeriod.appendChild(input);

          element.insertBefore(scheduledPeriod, element.firstChild);
        } else {
          const select = document.createElement('select');
          const timestamp = Date.now();
          ['A', 'B'].forEach((a, i) => {
            const option = document.createElement('option');
            option.id = `option/${timestamp + i}`;
            option.value = a;
            option.textContent = a;
            if (i === 0) option.setAttribute('selected', 'selected');
            select.appendChild(option);
          });
          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(select);
        }
      }

      if (arg.role === 'attendance') {
        if (tagName === 'GW-FB-ELEMENT') {
          element.setAttribute('contenteditable', 'false');
          element.textContent = `{_${arg.text.replaceAll(' ', '_')}_}`;
          element.setAttribute(
            'style',
            'display:inline-block; position:relative; box-sizing:border-box; line-height:1.2;',
          );
        }
      }
      if (arg.role === 'macro') {
        element.setAttribute('contenteditable', 'false');

        switch (type) {
          case 'APPROV_LINE':
          case 'AGREE_LINE':
          case 'RECEIVE_LINE':
          case 'AUDIT_LINE':
            element.setAttribute(
              'style',
              `${elementStyle.replace(
                'display:inline-block',
                'display:block',
              )} width:100%;`,
            );
            break;
          default:
            element.setAttribute('style', `${elementStyle}`);
            break;
        }

        // 결재 그룹인 경우.
        if (type === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP') {
          element.setAttribute('data-include-drafter', 'true');
          element.textContent = `{_${arg.text.replaceAll(' ', '_')}_}`;
        }
        // 결재 항목인 경우.
        else if (type === 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM') {
          element.setAttribute('data-group', 'approval');
          element.setAttribute('data-type', 'name');
          element.setAttribute('data-seq', '1');
          element.textContent = `{_결재_이름_1_}`;
        } else if (type === 'APPROVAL/COMPANY_LOGO') {
          const companylogo = document.createElement(
            'gw-fb-element-companylogo',
          );
          companylogo.setAttribute(
            'style',
            'box-sizing:border-box; display:block; height:40px; line-height:40px; width:130px; text-align:center; background:#eee;',
          );
          companylogo.textContent = `회사로고`;

          element.setAttribute('style', `${elementStyle}`);
          element.appendChild(companylogo);
        } else element.textContent = `{_${arg.text.replaceAll(' ', '_')}_}`;
      }
      if (arg.role === 'document') {
        element.setAttribute('contenteditable', 'false');

        element.setAttribute('style', `${elementStyle}`);
        element.textContent = `{_${arg.text.replaceAll(' ', '_')}_}`;
      }
      if (arg.role === 'board') {
        element.setAttribute('contenteditable', 'false');

        element.setAttribute('style', `${elementStyle}`);
        element.textContent = `{_${arg.text.replaceAll(' ', '_')}_}`;
      }
      if (arg.role === 'systemlink') {
        if (tagName === 'GW-FB-ELEMENT-ERPBODY') {
          const body = document.createElement('gw-fb-element-erpbody');
          body.setAttribute(
            'style',
            'box-sizing:border-box; display:block; margin:0; padding:4px; width:100%; text-align:center; background:#eee;',
          );
          body.setAttribute('contenteditable', 'false');
          body.textContent = '연동 본문';
          element.setAttribute('style', `${elementStyle} width:100%;`);
          element.appendChild(body);
        }
      }

      const selection = document.createElement('gw-fb-element-selection');
      const remove = document.createElement('gw-fb-element-remove');
      remove.setAttribute('style', 'display:none;');
      remove.innerHTML = '<i></i><span>삭제</span>';
      element.appendChild(selection);
      element.appendChild(remove);

      this._editor.setInsertHTML(element.outerHTML, this._editorId);
      // this.editor.setFocusToEditor(editorId);
    } else {
      const element = this._selectedElement;
      if (element === null) return;

      const { id, type, value } = arg;

      if (type === 'CONTROL/RADIO' || type === 'CONTROL/CHECKBOX') {
        const control = element.querySelector('input');

        const input = document.createElement('input');
        input.id = id;
        input.name = control?.name || id;
        input.type = type === 'CONTROL/RADIO' ? 'radio' : 'checkbox';
        input.value = value;

        element.appendChild(input);

        const label = document.createElement('label');
        label.textContent = value;
        element.appendChild(label);
      }
      if (type === 'option') {
        const control = element.querySelector('select');
        if (control !== null) {
          const option = document.createElement('option');
          option.id = id;
          option.value = value;
          option.textContent = value;
          control.appendChild(option);
        }
      }
    }
  }

  remove(id: string): void {
    // console.log(`FormBuilder:remove(id: "${id}")`);
    const element = this._selectedElement;
    if (element === null) return;

    const targetId = id.indexOf('/') ? id.replaceAll('/', '\\/') : id;
    const control = element.querySelector(`#${targetId}`);
    if (control === null) return;

    // console.log(`control?.nodeType: `, control.tagName);

    if (control.tagName === 'INPUT') {
      const { type } = control as HTMLInputElement;
      if (type === 'radio' || type === 'checkbox') {
        // 라벨 엘리먼트 삭제.
        control.nextElementSibling?.remove();
        control.remove();
        return;
      }
    }

    control.remove();
  }

  // setRole(value: string): void {
  //   console.log(`FormBuilder:setRole(value)`, value);

  //   const element = this.selectedElement;
  //   if (element === null) return;

  //   if (value !== '') element.setAttribute('data-role', value);
  //   else element.removeAttribute('data-role');
  // }

  setType(arg: { role: string; type: string }): void {
    // console.log(`FormBuilder:setType(value)`, arg);
    const element = this._selectedElement;
    if (element === null) return;
    const { role, type } = arg;
    if (role !== '' && type !== '') element.setAttribute(`data-${role}`, type);
  }

  setAttribute(arg: { id?: string; name: string; value: string }): void {
    // console.log(`FormBuilder:setAttribute(arg)`, arg);
    // console.log(`this`, this);
    const { id, name, value } = arg;

    const element = this._selectedElement;
    // console.log(`element`, element);
    if (element === null) return;

    if (id === undefined) {
      const role = element.getAttribute('data-role') ?? '';
      const type = element.getAttribute(`data-${role}`) ?? '';
      const tagName = this.getTagName({ role, type });

      // console.log(`id === undefined`, { role, type, tagName });

      if (role === 'control') {
        if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
          // console.log(`(tagName === 'INPUT' || tagName === 'TEXTAREA')`);

          const control = element.querySelector(tagName);
          // console.log(`control`, control);
          if (control === null) return;

          if (name === 'value' || name === 'rows' || name === 'disabled') {
            if (tagName === 'TEXTAREA' && name === 'value') {
              control.textContent = value;
            } else if (value !== '') control.setAttribute(name, value);
            else control.removeAttribute(name);
          }
          // 특정 요소인 경우.
          else if (
            name === 'data-calculate-id' ||
            name === 'data-formula' ||
            name === 'id' ||
            name === 'data-width' ||
            name === 'data-title' ||
            name === 'data-content'
          ) {
            control.setAttribute(name, value);
          }
          // 통화 기호인 경우.
          else if (name === 'data-currency-symbol') {
            const text = (control.getAttribute('value') ?? '').replace(
              /[^\d]/g,
              '',
            );
            if (text !== '') control.setAttribute('value', value + text);
            control.setAttribute(name, value);
          }
          // 스타일 속성인 경우.
          else {
            // 기본 패턴 설정.
            const pattern = `${name}:.*?;`;

            if (name === 'text-align' || name === 'font-size') {
              const target = control;

              const style = target.getAttribute('style') ?? '';

              if (value !== '') {
                const regexp = new RegExp(pattern, 'g');
                if (regexp.test(style))
                  target.setAttribute(
                    'style',
                    style.replace(regexp, `${name}:${value};`),
                  );
                else target.setAttribute('style', `${style} ${name}:${value};`);
              } else {
                target.setAttribute(
                  'style',
                  style.replace(new RegExp(`\\s?${pattern}`, 'g'), ''),
                );
              }
            }

            if (name === 'width') {
              const style = element.getAttribute('style') ?? '';

              if (value !== '') {
                const regexp = new RegExp(pattern, 'g');
                if (regexp.test(style))
                  element.setAttribute(
                    'style',
                    style.replace(regexp, `${name}:${value};`),
                  );
                else
                  element.setAttribute('style', `${style} ${name}:${value};`);
              } else {
                element.setAttribute(
                  'style',
                  style.replace(new RegExp(`\\s?${pattern}`, 'g'), ''),
                );
              }
            }
          }
        }
        if (type === 'CONTROL/EDITOR') {
          if (name === 'height') {
            const control = element.querySelector(tagName);
            if (control === null) return;

            const pattern = `${name}:.*?;`;
            const style = control.getAttribute('style') ?? '';

            const height = value === '' ? '200px' : value;

            const regexp = new RegExp(pattern, 'g');
            if (regexp.test(style))
              control.setAttribute(
                'style',
                style.replace(regexp, `${name}:${height};`),
              );
            else control.setAttribute('style', `${style} ${name}:${height};`);
          }
        }
      }
      if (role === 'attendance') {
        if (name === 'required') element.setAttribute(name, value);
        if (name === 'data-time-interval' || name === 'data-time-type')
          element.setAttribute(name, value);
        if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
          const control = element.querySelector(tagName);
          if (control === null) return;

          if (name === 'value' || name === 'rows' || name === 'disabled') {
            if (tagName === 'TEXTAREA' && name === 'value') {
              control.textContent = value;
            } else if (value !== '') control.setAttribute(name, value);
            else control.removeAttribute(name);
          }
          // 스타일 속성인 경우.
          else {
            // 기본 패턴 설정.
            const pattern = `${name}:.*?;`;

            if (name === 'text-align' || name === 'font-size') {
              const target = control;

              const style = target.getAttribute('style') ?? '';

              if (value !== '') {
                const regexp = new RegExp(pattern, 'g');
                if (regexp.test(style))
                  target.setAttribute(
                    'style',
                    style.replace(regexp, `${name}:${value};`),
                  );
                else target.setAttribute('style', `${style} ${name}:${value};`);
              } else {
                target.setAttribute(
                  'style',
                  style.replace(new RegExp(`\\s?${pattern}`, 'g'), ''),
                );
              }
            }

            if (name === 'width') {
              const style = element.getAttribute('style') ?? '';

              if (value !== '') {
                const regexp = new RegExp(pattern, 'g');
                if (regexp.test(style))
                  element.setAttribute(
                    'style',
                    style.replace(regexp, `${name}:${value};`),
                  );
                else
                  element.setAttribute('style', `${style} ${name}:${value};`);
              } else {
                element.setAttribute(
                  'style',
                  style.replace(new RegExp(`\\s?${pattern}`, 'g'), ''),
                );
              }
            }
          }
        }
      }
      if (role === 'macro') {
        if (type === 'APPROVAL/COMPANY_LOGO') {
          const companylogo = element.querySelector(tagName);
          if (companylogo === null) return;
          const pattern = `${name}:.*?;`;
          const style = companylogo.getAttribute('style') ?? '';
          const regexp = new RegExp(pattern, 'g');
          if (name === 'height') {
            const height = value === '' ? '40px' : value;
            if (regexp.test(style))
              companylogo.setAttribute(
                'style',
                style.replace(regexp, `${name}:${height};`),
              );
            else
              companylogo.setAttribute('style', `${style} ${name}:${height};`);
          }
          if (name === 'width' && value !== '')
            if (regexp.test(style))
              companylogo.setAttribute(
                'style',
                style.replace(regexp, `${name}:${value};`),
              );
            else
              companylogo.setAttribute('style', `${style} ${name}:${value};`);
        }
        if (
          name === 'data-include-drafter' ||
          name === 'data-group' ||
          name === 'data-type' ||
          name === 'data-seq'
        )
          element.setAttribute(name, value);

        if (name === 'data-group-text') {
          element.innerHTML = element.innerHTML.replace(
            /({_)(.+?)(_)/gm,
            `$1${value}$3`,
          );
        }
        if (name === 'data-type-text') {
          element.innerHTML = element.innerHTML.replace(
            /({_.+?_)(.+?)(_)/gm,
            `$1${value}$3`,
          );
        }
        if (name === 'data-seq-text')
          element.innerHTML = element.innerHTML.replace(
            /({_.+?_.+?_)(.+?)(_})/gm,
            `$1${value}$3`,
          );
      }
    } else {
      const targetId = id.indexOf('/') ? id.replaceAll('/', '\\/') : id;
      const control = element.querySelector(`#${targetId}`);
      if (control === null) return;

      // 라벨 변경인 경우. (라디오, 체크박스, 셀렉트)
      if (name === 'label') {
        if (control.tagName === 'INPUT') {
          if (value !== '') {
            control.setAttribute('value', value);
            if (control.nextElementSibling)
              control.nextElementSibling.textContent = value;
          } else {
            control.removeAttribute('value');
            if (control.nextElementSibling)
              control.nextElementSibling.textContent = '';
          }
        } else if (control.tagName === 'OPTION') {
          if (value !== '') {
            control.setAttribute('value', value);
            control.textContent = value;
          } else {
            control.removeAttribute('value');
            control.textContent = '';
          }
        }
      } else if (value !== '')
        element.querySelector(`#${targetId}`)?.setAttribute(name, value);
      else element.querySelector(`#${targetId}`)?.removeAttribute(name);
    }
  }

  removeAttribute(arg: { name: string }): void {
    // console.log(`FormBuilder:removeAttribute(arg)`, arg);

    const element = this._selectedElement;
    // console.log(`element`, element);
    if (element === null) return;

    const { name } = arg;

    const role = element.getAttribute('data-role') ?? '';
    const type = element.getAttribute(`data-${role}`) ?? '';
    const tagName = this.getTagName({ role, type });

    if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
      const control = element.querySelector(tagName);
      control?.removeAttribute(name);
    } else element.removeAttribute(name);
  }

  // eslint-disable-next-line prettier/prettier, lines-between-class-members
  getAttribute(arg: { element: HTMLElement; name: 'data-calculate-id' | 'data-formula'; }): string | undefined;
  // eslint-disable-next-line prettier/prettier, lines-between-class-members
  getAttribute(arg: { element: HTMLElement; name: 'check-items'; }): { id: string; label: string; checked: boolean }[];
  // eslint-disable-next-line prettier/prettier, lines-between-class-members
  getAttribute(arg: { element: HTMLElement; name: 'data-time-interval'; }): string | undefined;
  // eslint-disable-next-line prettier/prettier, lines-between-class-members
  getAttribute(arg: { element: HTMLElement; name: Omit<string, 'data-calculate-id' | 'data-formula' | 'check-items' | 'data-time-interval'>; }): string;
  // eslint-disable-next-line prettier/prettier, lines-between-class-members
  getAttribute(arg: { element: HTMLElement; name: string; }): string | undefined | { id: string; label: string; checked: boolean }[] {
    // console.log(`FormBuilder:getAttribute(arg)`, arg);
    const { element, name } = arg;

    const role = element.getAttribute('data-role') ?? '';
    const type = element.getAttribute(`data-${role}`) ?? '';
    const tagName = this.getTagName({ role, type });
    // console.log(`FormBuilder:getAttribute(arg):tagName`, tagName);

    if (
      (type === 'CONTROL/RADIO' || type === 'CONTROL/CHECKBOX') &&
      name === 'check-items'
    ) {
      const items: { id: string; label: string; checked: boolean }[] = [];
      element.querySelectorAll('input').forEach((a) => {
        const { id, value: label, checked } = a;
        items.push({ id, label, checked });
      });
      return items;
    }
    if (
      (type === 'CONTROL/SELECT' ||
        type === 'ATTENDANCE/DAYOFF' ||
        type === 'SENDNOTICE_SCHEDULED_DATE') &&
      name === 'check-items'
    ) {
      const items: { id: string; label: string; checked: boolean }[] = [];
      element
        .querySelectorAll<HTMLOptionElement>('select>option')
        .forEach((a) => {
          const { id, value: label, selected: checked } = a;
          items.push({ id, label, checked });
        });
      return items;
    }
    if (
      (type === 'ATTENDANCE/WORKTIME' || type === 'ATTENDANCE/OFFTIME') &&
      name === 'data-time-interval'
    ) {
      const time = element.getAttribute(name);
      if (time === null) return undefined;
      return time;
    }
    if (type === 'ATTENDANCE/USETIME' && name === 'data-time-type') {
      const usetime = element.getAttribute(name);
      if (usetime === null) return undefined;
      return usetime;
    }

    if (tagName === 'INPUT') {
      if (name === 'width') {
        const style = element.getAttribute('style') ?? '';
        return (
          Array.from(style.matchAll(/width:\s*(.*?);/g), (m) => m[1])[0] || ''
        );
      }

      const control = element.querySelector(tagName);
      if (control === null) return '';

      if (name === 'text-align') {
        const style = control.getAttribute('style') ?? '';
        const regexp = new RegExp(`${name}:\\s*(.*?);`, 'gm');
        return Array.from(style.matchAll(regexp), (m) => m[1])[0] || 'left';
      }

      if (name === 'font-size') {
        const style = control.getAttribute('style') ?? '';
        const regexp = new RegExp(`${name}:\\s*(.*?);`, 'gm');
        return Array.from(style.matchAll(regexp), (m) => m[1])[0] || '';
      }

      if (type === 'CONTROL/TEXT' && name === 'value')
        return control.getAttribute(name) ?? '';

      if (
        type === 'CONTROL/NUMBER' ||
        type === 'CONTROL/CALCULATION_VALUE' ||
        type === 'CONTROL/FORMULA'
      ) {
        if (name === 'data-calculate-id') {
          if (
            type === 'CONTROL/CALCULATION_VALUE' ||
            type === 'CONTROL/FORMULA'
          )
            return control.getAttribute(name) ?? '';
          return undefined;
        }

        if (name === 'data-formula') {
          if (type === 'CONTROL/FORMULA')
            return control.getAttribute(name) ?? '';
          return undefined;
        }

        if (name === 'data-currency-symbol')
          return control.getAttribute(name) ?? '';
      }
      if (type === 'CONTROL/DIALOG') {
        if (name === 'name') return control.getAttribute('value') ?? '';
        if (
          name === 'data-width' ||
          name === 'data-title' ||
          name === 'data-content'
        )
          return control.getAttribute(name) ?? '';
      }
    }
    if (tagName === 'TEXTAREA') {
      if (name === 'width') {
        const style = element.getAttribute('style') ?? '';
        return (
          Array.from(style.matchAll(/width:\s*(.*?);/g), (m) => m[1])[0] || ''
        );
      }

      const control = element.querySelector(tagName);
      if (control === null) return '';

      if (name === 'text-align') {
        const style = control.getAttribute('style') ?? '';
        const regexp = new RegExp(`${name}:\\s*(.*?);`, 'gm');
        return Array.from(style.matchAll(regexp), (m) => m[1])[0] || 'left';
      }

      if (name === 'font-size') {
        const style = control.getAttribute('style') ?? '';
        const regexp = new RegExp(`${name}:\\s*(.*?);`, 'gm');
        return Array.from(style.matchAll(regexp), (m) => m[1])[0] || '';
      }
      if (name === 'rows') return control.getAttribute('rows') ?? '';
    }

    if (tagName === 'GW-FB-ELEMENT-EDITOR') {
      if (name === 'height') {
        const control = element.querySelector(tagName);
        if (control === null) return '200px';

        const style = control.getAttribute('style') ?? '';
        const match = style.match(/[^-]height:(.+?);/);
        return match && match[1] ? match[1] : '200px';
      }
    }

    if (tagName === 'GW-FB-ELEMENT-COMPANYLOGO') {
      const companylogo = element.querySelector(tagName);
      if (name === 'height') {
        if (companylogo === null) return '40px';
        const style = companylogo.getAttribute('style') ?? '';
        const match = style.match(/[^-]height:(.+?);/);
        return match && match[1] ? match[1] : '40px';
      }
      if (name === 'width' && companylogo) {
        const style = companylogo.getAttribute('style') ?? '';
        return (
          Array.from(style.matchAll(/width:\s*(.*?);/g), (m) => m[1])[0] || ''
        );
      }
    }

    if (role === 'macro') {
      if (
        type === 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM' &&
        (name === 'data-group' || name === 'data-type' || name === 'data-seq')
      )
        return element.getAttribute(name) ?? '';
      return element.getAttribute(name) ?? '';
    }

    return '';
  }

  get content(): string {
    if (this._body === null) return '';
    return this._body.innerHTML;
  }

  // eslint-disable-next-line class-methods-use-this
  getEditor(arg: { iframe: HTMLIFrameElement | null }): any {
    const { iframe } = arg;

    let result: any | null = null;
    if (iframe === null) return result;
    const iframeWindow = iframe.contentWindow || iframe.contentDocument;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (iframeWindow) result = (iframeWindow as any).getDEXT5() as any;
    return result;
  }

  unSelect(): void {
    if (this._body === null) return;
    this._body.click();
  }

  /** 편집기 양식 Html 생성. */
  // eslint-disable-next-line class-methods-use-this
  createEditorFormHtml(arg: { html: string }): string {
    const { html } = arg;

    const domParser = new DOMParser();
    const document = domParser.parseFromString(html, 'text/html');

    // 컨트롤 및 메크로 항목 선택 영역 및 삭제 엘리먼트 생성.
    document.querySelectorAll('gw-fb-element').forEach((element) => {
      const selection = document.createElement('gw-fb-element-selection');
      const remove = document.createElement('gw-fb-element-remove');
      remove.setAttribute('style', 'display:none;');
      remove.innerHTML = '<span></span>';
      element.appendChild(selection);
      element.appendChild(remove);
    });

    return document.body.innerHTML;
  }

  static createFormHtml(html: string): string {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(html, 'text/html');

    // 컨트롤 및 메크로 항목 선택 영역 및 삭제 엘리먼트 제거.
    document
      .querySelectorAll('gw-fb-element-selection, gw-fb-element-remove')
      .forEach((a) => a.remove());

    return document.body.innerHTML;
  }

  static binding(arg: {
    html: string;
    data?: undefined;
    getMacro(a: { id: string }):
      | string
      | {
          value: string;
          define?: string;
        }
      | {
          company: string;
          organization: string;
          employee: string;
          jobClass: string;
          act: string;
          actAt: string;
          approvalOfficer: string;
          approver: string;
          jobPosition?: string;
          jobDuty?: string;
          type?: string;
        }[]
      | undefined;
  }): string;

  static binding<T>(arg: {
    html: string;
    data: T;
    getMacro(a: { id: string; data: T }):
      | string
      | {
          value: string;
          define?: string;
        }
      | {
          company: string;
          organization: string;
          employee: string;
          jobClass: string;
          act: string;
          actAt: string;
          approvalOfficer: string;
          approver: string;
          jobPosition?: string;
          jobDuty?: string;
          type?: string;
        }[]
      | undefined;
  }): string;

  static binding(arg: {
    html?: undefined;
    data?: undefined;
    element: HTMLElement;
    getMacro(a: { id: string }):
      | string
      | {
          value: string;
          define?: string;
        }
      | {
          company: string;
          organization: string;
          employee: string;
          jobClass: string;
          act: string;
          actAt: string;
          approvalOfficer: string;
          approver: string;
          jobPosition?: string;
          jobDuty?: string;
          type?: string;
        }[]
      | undefined;
  }): void;

  // eslint-disable-next-line consistent-return
  static binding<T>(
    arg: (
      | { html: string; data?: T }
      | { html?: undefined; data?: T; element: HTMLElement }
    ) & {
      getMacro(a: { id: string; data?: T }):
        | string
        | {
            value: string;
            define?: string;
          }
        | {
            company: string;
            organization: string;
            employee: string;
            jobClass: string;
            act: string;
            actAt: string;
            approvalOfficer: string;
            approver: string;
            currentApprover?: boolean;
            jobPosition?: string;
            jobDuty?: string;
            type?: string;
          }[]
        | undefined;
    },
  ): string | void {
    // console.log(`FormBuilder.binding(arg)`, arg);
    const { data, getMacro } = arg;

    const element =
      arg.html === undefined
        ? arg.element
        : new DOMParser().parseFromString(arg.html, 'text/html').body;

    element
      .querySelectorAll('gw-fb-element[data-role="macro"]')
      .forEach((a) => {
        const macro = a.getAttribute('data-macro');
        const id =
          macro === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP' &&
          a.getAttribute('data-include-drafter') === 'true'
            ? `${macro}/INCLUDE_DRAFTER`
            : macro;

        const value = id === null ? '' : getMacro({ id, data });

        // console.log(`getMacro(id: '${id}'):value`, value);

        if (typeof value === 'string') {
          if (id === 'APPROVAL/COMPANY_LOGO') {
            const style =
              a
                .querySelector('gw-fb-element-companylogo')
                ?.getAttribute('style') ?? '';
            const styleMatch = style.match(/[^-]height:(.+?);/);
            const height = styleMatch && styleMatch[1] ? styleMatch[1] : '40px';
            const width =
              Array.from(style.matchAll(/width:\s*(.*?);/g), (m) => m[1])[0] ||
              '130px';
            if (a.firstElementChild?.getAttribute('src') !== value) {
              a.innerHTML = `<img src='${value}' alt='회사 로고' style='width: ${width}; height: ${height};'>`;
            }
          } else a.textContent = value;
        } else if (!Array.isArray(value) && typeof value === 'object') {
          if (id === 'APPROVAL/DOCUMENT_NO') {
            a.textContent = value.value;
            if (value.define !== undefined) {
              a.setAttribute('data-define', value.define);
            }
          }
        } else if (Array.isArray(value)) {
          if (id === 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM') {
            const group = a.getAttribute('data-group');
            const type = a.getAttribute('data-type') ?? '';
            const seq = a.getAttribute('data-seq')
              ? Number(a.getAttribute('data-seq')) - 1
              : 0;
            const items = value.filter((x) => x.type === group);
            const item = items[seq];
            let text = '';
            if (items.length > 0 && item) {
              switch (type) {
                case 'name':
                  text =
                    item.employee !== '' ? item.employee : item.organization;
                  break;
                case 'jobposition':
                  text =
                    item.employee !== '' && item.jobPosition
                      ? item.jobPosition
                      : '';
                  break;
                case 'jobduty':
                  text =
                    item.employee !== '' && item.jobDuty ? item.jobDuty : '';
                  break;
                case 'jobposition+jobduty':
                  if (item.jobPosition && !item.jobDuty)
                    text = item.jobPosition;
                  else if (item.jobPosition && item.jobDuty)
                    text = `${item.jobPosition}/${item.jobDuty}`;
                  else if (!item.jobPosition && item.jobDuty)
                    text = item.jobDuty;
                  break;
                default:
                  text = '';
                  break;
              }
            }
            a.textContent = text;
          } else {
            const items = a.children.item(0)?.children;

            const html = value
              .map((b, index) => {
                /** 조직 결재 여부. */
                const isOrganizationApproval = b.employee === '';
                /** 직원 결재 여부. */
                const isEmployeeApproval = !isOrganizationApproval; // b.employee !== ''
                /** 결재 담당자 여부. */
                const isApprovalOfficer =
                  isEmployeeApproval && b.approvalOfficer !== '';
                /** 대리 결재자 여부. */
                const isSurrogateApprover =
                  isEmployeeApproval && !isApprovalOfficer && b.approver !== '';
                /** 직원 여부. */
                const isEmployee = isEmployeeApproval && !isSurrogateApprover;

                let head = ''; // 직위 또는 직책, 이름 직위 또는 직책.
                let body = ''; // 서명 또는 이름.
                const foot = b.act ?? ''; // 결재 행위 또는 열람, 미열람.
                const date = b.actAt ?? '';

                // 현재 결재자이고 직원(결재 담당자, 대리 결재자, 직원) 결재인 경우. (조직 결재가 아닌 경우)
                // 백엔드에서 결재 서명 유무에 따른 태그 수정 작업 진행.
                if (b.currentApprover && isEmployeeApproval) {
                  // 전단계 반려인 경우.
                  if (b.act === '') {
                    head = b.jobClass;
                    body = b.employee;
                  }
                }
                // 현재 결재자가 아니거나 조직 결재인 경우.
                else {
                  const src = items
                    ?.item(index)
                    ?.querySelector('img')
                    ?.getAttribute('src');

                  // 서명이 있는 경우. (직원만 서명값 받음)
                  if (src) {
                    if (isApprovalOfficer) {
                      head = `${b.employee} ${b.jobClass}`;
                      body = b.employee;
                    }
                    if (isSurrogateApprover) {
                      head = `${b.employee} ${b.jobClass}`;
                      body = b.approver;
                    }
                    if (isEmployee) {
                      head = `${b.employee} ${b.jobClass}`;
                      body = b.employee;
                    }
                    body = `<img src='${src}' title='${body}' alt='${body}' style='height:40px; margin-top:2px; vertical-align:top;'>`; // 서명.
                  }
                  // 서명이 없는 경우.
                  else {
                    if (isApprovalOfficer) {
                      head = b.jobClass;
                      body = b.employee;
                    }
                    if (isSurrogateApprover) {
                      head = `${b.employee} ${b.jobClass}`;
                      body = b.approver;
                    }
                    if (isEmployee) {
                      head = b.jobClass;
                      body = b.employee;
                    }
                    if (isOrganizationApproval) {
                      head = '조직';
                      body = b.organization;
                    }
                    body = `<span style='display:block; white-space:nowrap;'>${body}</span>`;
                  }
                }

                return `
                  <span style='display:inline-flex; flex-direction:column; min-width:84px; margin:-1px 0 0 -1px; border:1px solid #c1c1c1; vertical-align:top; font-size:12px; color:#555555; line-height:1.5; text-align:center;'>
                    <span style='display:block; height:18px; padding:0 4px; background:#f5f5f5; white-space:nowrap;'>${head}</span>
                    <span style='display:flex; justify-content:center; align-items:center; flex-direction:column; height:40px; padding:4px 4px 2px; border-top:1px solid #c1c1c1;'>${body}</span>
                    <span style='display:block; height:18px; padding:0 4px; font-weight:bold; color:red; white-space:nowrap;'>${foot}</span>
                    <span style='display:block; height:18px; padding:0 4px; white-space:nowrap;'>${date}</span>
                  </span>`;
              })
              .join('');

            a.innerHTML = `<span style='overflow:hidden; display:inline-flex; flex-wrap:wrap; max-width:100%; padding:1px 0 0 1px;'>${html}</span>`;
          }
        }
      });

    element
      .querySelectorAll('gw-fb-element[data-role="attendance"]')
      .forEach((a) => {
        const macro = a.getAttribute('data-attendance');
        const value = macro === null ? '' : getMacro({ id: macro, data });
        if (typeof value === 'string') {
          if (macro === 'ATTENDANCE/SENDNOTICE_SIGNATURE' && value !== '') {
            if (a.firstElementChild?.getAttribute('src') !== value)
              a.innerHTML = `<img src='${value}' alt='서명이미지' style='width:100px; height:40px;'>`;
          } else a.textContent = value;
        }
      });

    element
      .querySelectorAll('gw-fb-element[data-role="document"]')
      .forEach((a) => {
        const macro = a.getAttribute('data-document');
        const value = macro === null ? '' : getMacro({ id: macro, data });
        if (typeof value === 'string') {
          a.textContent = value;
        }
      });

    element
      .querySelectorAll('gw-fb-element[data-role="board"]')
      .forEach((a) => {
        const macro = a.getAttribute('data-board');
        const value = macro === null ? '' : getMacro({ id: macro, data });
        if (typeof value === 'string') {
          a.textContent = value;
        }
      });

    element
      .querySelectorAll('gw-fb-element[data-role="systemlink"]')
      .forEach((a) => {
        const { parentElement } = a;
        const macro = a.getAttribute('data-systemlink');
        const value = macro === null ? '' : getMacro({ id: macro, data });

        // 부모 엘리먼트가 P 이고 자식 엘리먼트가 DIV 인 경우 P 엘리먼트가 밖으로
        // 빠지는 현상으로 DIV 엘리먼트로 변경합니다.
        if (parentElement?.tagName === 'P') {
          const parentDiv = document.createElement('div');
          parentDiv.setAttribute('data-element', 'p');
          const pStyle = parentElement.getAttribute('style');
          if (pStyle) parentDiv.setAttribute('style', pStyle);
          parentDiv.innerHTML = parentElement.innerHTML;
          parentElement.replaceWith(parentDiv);
        }

        if (typeof value === 'string') {
          a.innerHTML = value;
        }
      });
    if (arg.html !== undefined) return element.innerHTML;
  }

  static createComposeHtml(arg: {
    html: string;
    // 글 작성 시 기본 값으로 에디터 사용할 경우 높이 설정값. (게시관리, 문서관리)
    // 컨텐츠의 경우 에디터 매크로에 data-option="default-editor" 속성 추가 필요.
    editorHeight?: string;
    isDayOff?: boolean; // 연차 상신 문서 여부.
    workingHours?: {
      workTime: string;
      offTime: string;
      breakeTime: {
        start: string;
        end: string;
      }[];
      totalWorkTime: number;
    };
    modify?: boolean;
    expressionUnit?: 'DAY' | 'MINUTE';
    getMacro?(a: { id: string }):
      | string
      | {
          value: string;
          define?: string;
        }
      | {
          company: string;
          organization: string;
          employee: string;
          jobClass: string;
          act: string;
          actAt: string;
          approvalOfficer: string;
          approver: string;
        }[]
      | undefined;
  }): string {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(arg.html, 'text/html');

    // 컨트롤 및 메크로 항목 선택 영역 및 삭제 엘리먼트 제거.
    document
      .querySelectorAll('gw-fb-element-selection, gw-fb-element-remove')
      .forEach((a) => a.remove());

    if (arg.getMacro !== undefined)
      FormBuilder.binding({ element: document.body, getMacro: arg.getMacro });

    // 수정인 경우 컨트롤을 생성합니다.
    if (arg.modify) {
      document
        .querySelectorAll('gw-fb-element[data-role="control"]')
        .forEach((a) => {
          const type = a.getAttribute('data-control');

          if (
            type === 'CONTROL/TEXT' ||
            type === 'CONTROL/DATE' ||
            type === 'CONTROL/TIME'
          ) {
            const data = a.querySelector('gw-fb-element-control-data');
            if (data === null) return;

            const control = document.createElement('input');
            const style =
              data
                .getAttribute('style')
                ?.replace(' border-color:transparent;', '') ?? '';

            if (type === 'CONTROL/DATE') control.type = 'date';
            else if (type === 'CONTROL/TIME') control.type = 'time';
            else control.type = 'text';

            control.setAttribute('style', style);
            control.setAttribute('value', data.textContent ?? '');
            data.replaceWith(control);
          }
          if (type === 'CONTROL/TEXTAREA') {
            const data = a.querySelector('gw-fb-element-control-data');
            if (data === null) return;

            const control = document.createElement('textarea');
            const style =
              data
                .getAttribute('style')
                ?.replace(' border-color:transparent;', '') ?? '';
            const match = style.match(/[^-]text-align:(.+?);/);

            const textAlignStyle = match && match[1] ? '' : `text-align:left;`;

            const rows = data.getAttribute('rows');
            if (rows !== null) control.setAttribute('rows', rows);

            if (textAlignStyle !== '') {
              control.setAttribute('style', `${style} ${textAlignStyle}`);
            } else control.setAttribute('style', style);
            control.textContent = data.innerHTML.replaceAll('<br>', '\n') ?? '';
            data.replaceWith(control);
          }
          if (
            type === 'CONTROL/NUMBER' ||
            type === 'CONTROL/CALCULATION_VALUE' ||
            type === 'CONTROL/FORMULA'
          ) {
            const data = a.querySelector('gw-fb-element-control-data');
            if (data === null) return;

            const control = document.createElement('input');
            const style =
              data
                .getAttribute('style')
                ?.replace(' border-color:transparent;', '') ?? '';

            if (type !== 'CONTROL/NUMBER') {
              const calculateId = data.getAttribute('data-calculate-id');
              if (calculateId !== null)
                control.setAttribute('data-calculate-id', calculateId);
            }

            if (type === 'CONTROL/FORMULA') {
              const formula = data.getAttribute('data-formula');
              if (formula !== null)
                control.setAttribute('data-formula', formula);
              control.disabled = true;
            }

            const currencySymbol = data.getAttribute('data-currency-symbol');
            if (currencySymbol !== null)
              control.setAttribute('data-currency-symbol', currencySymbol);

            control.type = 'text';
            control.setAttribute('style', style);
            control.setAttribute('value', data.textContent ?? '');
            data.replaceWith(control);
          }

          if (type === 'CONTROL/RADIO' || type === 'CONTROL/CHECKBOX') {
            for (let i = 0; i < a.children.length; i += 1) {
              const control = a.children[i];
              const controlType = control.getAttribute('type');
              if (controlType === 'radio' || controlType === 'checkbox')
                control.removeAttribute('onclick');
            }
          }
          if (type === 'CONTROL/SELECT') {
            const control = a.querySelector('select');
            if (control === null) return;
            control.hidden = false;
            const text = a.querySelector('gw-fb-element-control-data');
            if (text === null) return;
            text.remove();
          }
          if (type === 'CONTROL/DATE_RANGE') {
            for (let i = 0; i < a.children.length; i += 1) {
              if (a.children[i].tagName === 'GW-FB-ELEMENT-CONTROL-DATA') {
                const control = document.createElement('input');
                const style =
                  a.children[i]
                    .getAttribute('style')
                    ?.replace(' border-color:transparent;', '') ?? '';

                control.type = 'date';
                control.setAttribute('style', style);
                control.setAttribute('value', a.children[i].textContent ?? '');
                a.children[i].replaceWith(control);
              }
            }
          }
          if (type === 'CONTROL/DIALOG' || type === 'CONTROL/ROW_ADD') {
            const control = a.querySelector('input');
            if (control === null) return;

            const style =
              a.getAttribute('style')?.replace(' visibility:hidden;', '') ?? '';
            control.setAttribute('style', style);
          }
        });

      document
        .querySelectorAll('div[data-control="CONTROL/EDITOR"]')
        .forEach((a, i) => {
          const editorId = `editor_${i}`;

          const style = a.getAttribute('style') ?? '';
          const match = style.match(/min-height:(.+?);/);
          const dataOption = a.getAttribute('data-option');
          let height = match && match[1] ? match[1] : '200px';
          if (dataOption === 'default-editor' && arg.editorHeight)
            height = arg.editorHeight;

          const editor = document.createElement('iframe');
          const editorBodyValue = encodeURIComponent(a.innerHTML);
          editor.setAttribute('frameborder', '0');
          editor.setAttribute('scrolling', 'no');
          editor.setAttribute('id', `${editorId}`);
          editor.setAttribute('title', '에디터');
          editor.setAttribute('style', `width:100%; height:${height};`);
          editor.setAttribute(
            'src',
            `/editor/dext5/editorFormbuilder.html?id=${editorId}`,
          );
          editor.setAttribute(
            'onload',
            `(function(el){
            var decodeValue = decodeURIComponent("${editorBodyValue}");
            var ifw = (el.contentWindow) ? el.contentWindow : el.contentDocument;
            if (ifw) {
              ifw.dext_editor_loaded_event = function(){
                this.DEXT5.setBodyValue(decodeValue, '${editorId}');
              };
            }
          })(this)`,
          );

          const newStyle =
            dataOption === 'default-editor' && arg.editorHeight
              ? 'display:initial; position:relative; box-sizing:border-box; line-height:1.2; width:100%;'
              : 'display:inline-block; position:relative; box-sizing:border-box; line-height:1.2; width:100%;';
          const element = document.createElement('gw-fb-element');
          element.setAttribute('data-role', 'control');
          if (dataOption) element.setAttribute('data-option', dataOption);
          element.setAttribute(`data-control`, 'CONTROL/EDITOR');
          element.setAttribute('contenteditable', 'false');
          element.setAttribute('style', newStyle);
          element.appendChild(editor);

          a.replaceWith(element);
        });
    }

    document.querySelectorAll('gw-fb-element-editor').forEach((a, i) => {
      const editorId = `editor_${i}`;

      const style = a.getAttribute('style') ?? '';
      const match = style.match(/[^-]height:(.+?);/);
      const dataOption = a.parentElement?.getAttribute('data-option');
      let height = match && match[1] ? match[1] : '200px';
      if (dataOption === 'default-editor' && arg.editorHeight)
        height = arg.editorHeight;

      const editor = document.createElement('iframe');
      editor.setAttribute('frameborder', '0');
      editor.setAttribute('scrolling', 'no');
      editor.setAttribute('id', `${editorId}`);
      editor.setAttribute('title', '에디터');
      editor.setAttribute('style', `width:100%; height:${height};`);
      editor.setAttribute(
        'src',
        `/editor/dext5/editorFormbuilder.html?id=${editorId}`,
      );
      a.replaceWith(editor);
    });

    document
      .querySelectorAll<HTMLElement>('gw-fb-element[data-role="control"]')
      .forEach((a) => {
        // console.log(`document.querySelectorAll<HTMLElement>('gw-fb-element[data-role="control"]'):a`, a);
        const role = a.getAttribute('data-role');
        const type = a.getAttribute(`data-${role}`);

        if (
          type === 'CONTROL/TEXT' ||
          type === 'CONTROL/DATE' ||
          type === 'CONTROL/TIME'
        ) {
          const control = a.querySelector('input');
          if (control === null) return;
          control.setAttribute(
            'onblur',
            `this.setAttribute('value', this.value)`,
          );
        }
        if (type === 'CONTROL/TEXTAREA') {
          const control = a.querySelector('textarea');
          if (control === null) return;
          control.setAttribute(
            'onkeyup',
            `(function(el){
              var offset = el.offsetHeight - el.clientHeight;
              el.style.height = 'auto';
              el.style.height = (el.scrollHeight + offset) + 'px';
            })(this)`,
          );
          control.setAttribute(
            'oninput',
            `(function(el){
              var offset = el.offsetHeight - el.clientHeight;
              el.style.height = 'auto';
              el.style.height = (el.scrollHeight + offset) + 'px';
            })(this)`,
          );
          control.setAttribute('onblur', `this.textContent = this.value`);
        }
        if (type === 'CONTROL/NUMBER') {
          const control = a.querySelector('input');
          if (control === null) return;

          const currencySymbol =
            control.getAttribute('data-currency-symbol') ?? '';

          control.setAttribute(
            'oninput',
            `(function(el){
            var value = el.value.replace(/[^\\d]/g, '');
            if (value !== '') value = '${currencySymbol}' + (value * 1).toLocaleString();
            el.setAttribute('value', value);
            el.value = value;
          })(this)`,
          );
        }
        if (type === 'CONTROL/CALCULATION_VALUE') {
          const control = a.querySelector('input');
          if (control === null) return;
          const id = control.getAttribute('data-calculate-id') ?? '';

          const currencySymbol =
            control.getAttribute('data-currency-symbol') ?? '';

          control.setAttribute(
            'oninput',
            `(function(el){
            console.log("el.value.replace(/[^\\d]/g, '') * 1", el.value.replace(/[^\\d]/g, '') * 1);

            document.querySelectorAll("[data-formula]").forEach((a) => {
              console.log(a);
            });
          
            var tr = el.closest('tr');
            var element = (tr !== null) ? tr : document;
          
            var value = el.value.replace(/[^\\d]/g, '');
            if (value !== '') {
              var num = (value * 1);
              value = '${currencySymbol}' + (value * 1).toLocaleString();

              element.querySelectorAll('[data-formula]').forEach((a) => {
                var formula = a.getAttribute('data-formula');
                if (formula === null) return;
                if (formula.search(/{${id}}/g) === -1) return;

                var matches = formula.matchAll(/{([a-zA-Z0-9]+)}/g);
                for (var match of matches) {
                  console.log('match', match);
                  if (match[1] === '${id}') {
                    console.log('----------num', num);
                    formula = formula.replace(/{${id}}/g, num + '');
                  }
                  else {
                    var selectors = '[data-calculate-id="' + match[1] + '"]';
                    var target = element.querySelector(selectors);
                    if (target === null) {
                      var regexp = new RegExp(match[0], 'g');
                      formula = formula.replace(regexp, NaN);
                    }
                    else {
                      var temp = target.value.replace(/[^\\d]/g, '');
                      console.log('----------temp', temp);
                      if (temp === '') {
                        var regexp = new RegExp(match[0], 'g');
                        formula = formula.replace(regexp, NaN);
                      }
                      else {
                        var regexp = new RegExp(match[0], 'g');
                        formula = formula.replace(regexp, temp);
                      }
          
                    }
          
                  }
                }
          
                console.log('CONTROL/FORMULA', formula);
                console.log('CONTROL/FORMULA', new Function('return ' + formula)());

                var calculatedValue = NaN;
                try { calculatedValue = new Function('return ' + formula)(); } catch (e) {}
                calculatedValue = isNaN(calculatedValue) ? '0' : calculatedValue.toLocaleString();

                var currencySymbol = a.getAttribute('data-currency-symbol') ?? '';
                calculatedValue = currencySymbol + calculatedValue;
  
                a.setAttribute('value', calculatedValue);
                a.value = calculatedValue;

              });
            }
            else {
              element.querySelectorAll('[data-formula]').forEach((a) => {
                var formula = a.getAttribute('data-formula');
                if (formula === null) return;
                if (formula.search(/{${id}}/g) === -1) return;

                var currencySymbol = a.getAttribute('data-currency-symbol') ?? '';

                a.setAttribute('value', currencySymbol + '0');
                a.value = currencySymbol + '0';
              });
            }
          
            el.setAttribute('value', value);
            el.value = value;

            // 총 계산.
            document.querySelectorAll('[data-formula]').forEach((a) => {
              var formula = a.getAttribute('data-formula');
              if (formula === null) return;
              if (formula.search(/{(.+?)}/g) === -1) return;
          
              var matches = formula.matchAll(/{(.+?)}/g);
              for (var match of matches) {
                var targetValue = 0;
                if (match[0].indexOf('*') === -1) {
                  var selectors = '[data-calculate-id="' + match[1] + '"]';
                  var element = document.querySelector([selectors]);
                  if (element !== null && element.parentElement?.getAttribute('data-control') === 'CONTROL/FORMULA')
                    targetValue = element.value.replace(/[^\\d]/g, '') * 1;
                  else return;
                } else {
                  var selectors = '[data-calculate-id="' + match[1].replaceAll('*', '') + '"]';
                  var elements = document.querySelectorAll([selectors]);
                  for (let i = 0; i < elements.length; i++)
                    targetValue += elements[i].value.replace(/[^\\d]/g, '') * 1;
                }
                formula = formula.replaceAll(match[0], targetValue + '');
              }

              var calculatedValue = NaN;
              try { calculatedValue = new Function('return ' + formula)(); } catch (e) {}
              calculatedValue = isNaN(calculatedValue) ? '0' : calculatedValue.toLocaleString();

              var currencySymbol = a.getAttribute('data-currency-symbol') ?? '';
                calculatedValue = currencySymbol + calculatedValue;

              a.setAttribute('value', calculatedValue);
              a.value = calculatedValue;
            });
          

          })(this)`,
          );
        }
        if (type === 'CONTROL/ROW_ADD') {
          const control = a.querySelector('input');
          if (control === null) return;

          control.setAttribute(
            'onclick',
            `(function(el){

            if (el.value === '-') {
              var tr = el.closest('tr');
              if (tr === null) return;
              tr.remove();
            }
            else {
              var tbody = el.closest('tbody');
              var tr = el.closest('tr');
              if (tbody === null || tr === null) return;

              var tempTr = tr.cloneNode(true);
              el.value = '-';
              el.setAttribute('value', '-');
              tbody.insertBefore(tempTr, tr.nextSibling);
            }

            // 총 계산.
            document.querySelectorAll('[data-formula]').forEach((a) => {
              var formula = a.getAttribute('data-formula');
              if (formula === null) return;
              if (formula.search(/{(.+?)}/g) === -1) return;
          
              var matches = formula.matchAll(/{(.+?)}/g);
              for (var match of matches) {
                var targetValue = 0;
                if (match[0].indexOf('*') === -1) {
                  var selectors = '[data-calculate-id="' + match[1] + '"]';
                  var element = document.querySelector([selectors]);
                  if (element !== null && element.parentElement?.getAttribute('data-control') === 'CONTROL/FORMULA')
                    targetValue = element.value.replace(/[^\\d]/g, '') * 1;
                  else return;
                } else {
                  var selectors = '[data-calculate-id="' + match[1].replaceAll('*', '') + '"]';
                  var elements = document.querySelectorAll([selectors]);
                  for (let i = 0; i < elements.length; i++)
                    targetValue += elements[i].value.replace(/[^\\d]/g, '') * 1;
                }
                formula = formula.replaceAll(match[0], targetValue + '');
              }

              var calculatedValue = NaN;
              try { calculatedValue = new Function('return ' + formula)(); } catch (e) {}
              calculatedValue = isNaN(calculatedValue) ? '0' : calculatedValue.toLocaleString();

              var currencySymbol = a.getAttribute('data-currency-symbol') ?? '';
              calculatedValue = currencySymbol + calculatedValue;

              a.setAttribute('value', calculatedValue);
              a.value = calculatedValue;
            });

            const totalElement = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL"]');
            const totalInput = totalElement.querySelector('input');

            if(totalElement === null || totalElement === undefined) return;
              totalInput.dispatchEvent(new Event('change'));
          })(this)`,
          );
        }
        if (type === 'CONTROL/RADIO') {
          const controls = a.querySelectorAll('input');
          for (let i = 0; i < controls.length; i += 1) {
            // 작성에서 값 변경 시 변경 값 적용되도록 이벤트 설정.
            controls[i].setAttribute(
              'onchange',
              `(function(el){ parentElement.querySelectorAll('[name="' + el.name + '"]').forEach(a => { if (el.value === a.value) el.setAttribute('checked', 'checked'); else a.removeAttribute('checked'); } ); })(this)`,
            );
          }
        }
        if (type === 'CONTROL/CHECKBOX') {
          const controls = a.querySelectorAll('input');
          for (let i = 0; i < controls.length; i += 1) {
            // 작성에서 값 변경 시 변경 값 적용되도록 이벤트 설정.
            controls[i].setAttribute(
              'onchange',
              `(function(el){ if (el.checked) el.setAttribute('checked', 'checked'); else el.removeAttribute('checked'); })(this)`,
            );
          }
        }
        if (type === 'CONTROL/SELECT') {
          const control = a.querySelector('select');
          if (control === null) return;
          control.setAttribute(
            'onblur',
            `(function(el){ for (var i = 0; i < el.children.length; i++) if (el.value === el.children[i].value) el.children[i].setAttribute('selected', 'selected'); else el.children[i].removeAttribute('selected'); })(this)`,
          );
        }
        if (type === 'CONTROL/DATE_RANGE') {
          const controls = a.querySelectorAll('input');
          for (let i = 0; i < controls.length; i += 1) {
            // 작성에서 값 변경 시 변경 값 적용되도록 이벤트 설정.
            controls[i].setAttribute(
              'onblur',
              `this.setAttribute('value', this.value)`,
            );
          }
        }
        if (type === 'CONTROL/DIALOG') {
          const control = a.querySelector('input');
          if (control === null) return;

          const style =
            (control.getAttribute(`data-width`) ?? '') !== ''
              ? ` style="width: ${control.getAttribute(`data-width`)};"`
              : '';

          const html = `
        <div class="eui-dialog popup" data-state="enter">
          <div class="overlay dialog-overlay" aria-expanded="true"></div>
          <div class="dialog-container"${style}>
            <button type="button" class="eui-button dialog-close icon secondary" onclick="(function(el){ var dialog = el.closest('.ui-dialog'); if (dialog !== null) dialog.remove(); })(this)">
              <i class="eui-icon eui-icon-close"></i>
            </button>
            <div class="dialog-head">
              <div class="title">${
                control.getAttribute(`data-title`) ?? ''
              }</div>
            </div>
            <div class="dialog-content">
              ${(control.getAttribute(`data-content`) ?? '').replaceAll(
                /`(?=[^>]*<)/gm,
                '&#96;',
              )}
            </div>
          </div>
        </div>
        `;

          control.setAttribute(
            'onclick',
            `(function(el){ const dialog = document.createElement('div'); dialog.className = 'ui-dialog'; dialog.innerHTML = \`${html}\`; document.body.appendChild(dialog); })(this)`,
          );
        }
      });

    document
      .querySelectorAll<HTMLElement>('gw-fb-element[data-role="attendance"]')
      .forEach((a) => {
        const role = a.getAttribute('data-role');
        const type = a.getAttribute(`data-${role}`);
        const required = a.getAttribute('required');
        if (type === 'ATTENDANCE/REASON') {
          const control = a.querySelector('textarea');
          if (control === null) return;
          control.setAttribute(
            'onkeyup',
            `(function(el){
              var offset = el.offsetHeight - el.clientHeight;
              el.style.height = 'auto';
              el.style.height = (el.scrollHeight + offset) + 'px';
            })(this)`,
          );
          control.setAttribute(
            'oninput',
            `(function(el){
              var offset = el.offsetHeight - el.clientHeight;
              el.style.height = 'auto';
              el.style.height = (el.scrollHeight + offset) + 'px';
            })(this)`,
          );
          control.setAttribute('onblur', `this.textContent = this.value`);
          if (required === 'true') {
            control.setAttribute('onclick', `this.style.background = 'none'`);
            control.setAttribute('onkeydown', `this.style.background = 'none'`);
          }
        }
        if (type === 'ATTENDANCE/DAYOFF') {
          const control = a.querySelector('select');
          const input = document.createElement('input');
          input.type = 'date';
          input.setAttribute('value', dateTimeFormat(new Date(), 'yyyy-MM-DD'));
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          input.setAttribute(
            'onblur',
            `this.setAttribute("value", this.value)`,
          );
          input.setAttribute('onclick', `this.style.background = "none"`);
          input.setAttribute('onkeydown', `this.style.background = "none"`);
          if (control === null) return;
          control.setAttribute(
            'onchange',
            `(function(el){
               for (var i = 0; i < el.children.length; i++){ 
               if (el.value === el.children[i].value) {
                 el.children[i].setAttribute('selected', 'selected');
                 const dayoff = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/DATE_RANGE"]');
                 
                  if (el.children[i].value === '연차') {
                    dayoff.innerHTML = '${input.outerHTML} ~ ${input.outerHTML}';
                  } else {
                    dayoff.innerHTML = '${input.outerHTML}';
                  }
                } else el.children[i].removeAttribute('selected');
              }
            })(this)`,
          );
          control.hidden = false;
          const text = a.querySelector('gw-fb-element-control-data');
          if (text === null) return;
          text.remove();
        }
        if (type === 'ATTENDANCE/DATE_RANGE') {
          const controls = a.querySelectorAll('input');
          for (let i = 0; i < controls.length; i += 1) {
            // 작성에서 값 변경 시 변경 값 적용되도록 이벤트 설정.
            controls[i].setAttribute(
              'onblur',
              `this.setAttribute('value', this.value)`,
            );
            controls[i].setAttribute(
              'value',
              dateTimeFormat(new Date(), 'yyyy-MM-DD'),
            );
            if (required === 'true') {
              controls[i].setAttribute(
                'onclick',
                `this.style.background = 'none';`,
              );
              controls[i].setAttribute(
                'onkeydown',
                `this.style.background = 'none'`,
              );
            }
          }
        }
        if (type === 'ATTENDANCE/WORKTIME' || type === 'ATTENDANCE/OFFTIME') {
          const time = a.getAttribute('data-time-interval');
          const control = a.querySelector('select');
          const style = control?.getAttribute('style');
          if (control === null || time === null) return;
          control.remove();
          const select = document.createElement('select');
          if (style) select.setAttribute('style', style);
          select.setAttribute(
            'onblur',
            `(function(el){ for (var i = 0; i < el.children.length; i++) if (el.value === el.children[i].value) el.children[i].setAttribute('selected', 'selected'); else el.children[i].removeAttribute('selected'); })(this)`,
          );

          // 연차 업무 매크로인 경우
          if (arg.isDayOff) {
            select.setAttribute(
              'onclick',
              `(function () {
                const worktime = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/WORKTIME"]')?.querySelector('select');
                const offtime = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/OFFTIME"]')?.querySelector('select');
                if(worktime) worktime.style.background = 'none';
                if(offtime) offtime.style.background = 'none';
              })(this)`,
            );
            if (type === 'ATTENDANCE/WORKTIME') {
              select.setAttribute(
                'onchange',
                `(function(el){
                  var usetime = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/USETIME"]')?.firstChild;
                  var worktimeVal = (el.options[el.selectedIndex].value);
                  const offtime = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/OFFTIME"]')?.firstChild;
                  var offtimeVal = (offtime.options[offtime.selectedIndex].value);
                  const hour = parseInt(offtimeVal.split(':')[0], 10) - 1 - parseInt(worktimeVal.split(':')[0], 10);
                  const minute = parseInt(offtimeVal.split(':')[1], 10) + 60 - parseInt(worktimeVal.split(':')[1], 10);
                  let selected = Math.floor((hour * 60 + minute) / 60) * 60;
                  if (hour <= 0) selected = 60;
                  if (hour >= 8) selected = 480;
                  for (var i=0; i < usetime.options.length; i++){
                    const option = usetime.options[i];
                    if (option.value === selected.toString())
                      option.setAttribute('selected', 'selected');
                    else option.removeAttribute('selected');
                  }
                 })(this)`,
              );
            } else {
              select.setAttribute(
                'onchange',
                `(function(el){
                  var usetime = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/USETIME"]')?.firstChild;
                  var offtimeVal = (el.options[el.selectedIndex].value);
                  const worktime = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/WORKTIME"]')?.firstChild;
                  var worktimeVal = (worktime.options[worktime.selectedIndex].value);
                  const hour = parseInt(offtimeVal.split(':')[0], 10) - 1 - parseInt(worktimeVal.split(':')[0], 10);
                  const minute = parseInt(offtimeVal.split(':')[1], 10) + 60 - parseInt(worktimeVal.split(':')[1], 10);
                  let selected = Math.floor((hour * 60 + minute) / 60) * 60;
                  if (hour <= 0) selected = 60;
                  if (hour >= 8) selected = 480;
                  for (var i=0; i < usetime.options.length; i++){
                    const option = usetime.options[i];
                    if (option.value === selected.toString())
                      option.setAttribute('selected', 'selected');
                    else option.removeAttribute('selected');
                  }
                 })(this)`,
              );
            }
          }

          const timestamp = Date.now();
          const hour = new Date().getHours();
          const minute = new Date().getMinutes();
          if (time === '60') {
            for (let i = 0; i < 24; i += 1) {
              const hours: string = i.toString().padStart(2, '0');
              const option = document.createElement('option');
              option.id = `option/${timestamp + i}`;
              option.value = `${hours}:00`;
              option.textContent = `${hours}:00`;
              if (arg.isDayOff && arg.workingHours) {
                if (type === 'ATTENDANCE/WORKTIME') {
                  if (`${hours}:00` === arg.workingHours.workTime)
                    option.setAttribute('selected', 'selected');
                  else option.removeAttribute('selected');
                } else if (`${hours}:00` === arg.workingHours.offTime)
                  option.setAttribute('selected', 'selected');
                else option.removeAttribute('selected');
              } else if (i === hour)
                option.setAttribute('selected', 'selected');
              else option.removeAttribute('selected');
              select.appendChild(option);
            }
          } else if (time === '15') {
            for (let i = 0; i < 24; i += 1) {
              for (let x = 0; x < 60; x += 15) {
                const hours: string = i.toString().padStart(2, '0');
                const minutes: string = x.toString().padStart(2, '0');
                const option = document.createElement('option');
                option.id = `option/${timestamp + i}`;
                option.value = `${hours}:${minutes}`;
                option.textContent = `${hours}:${minutes}`;
                if (arg.isDayOff && arg.workingHours) {
                  if (type === 'ATTENDANCE/WORKTIME') {
                    if (`${hours}:${minutes}` === arg.workingHours.workTime)
                      option.setAttribute('selected', 'selected');
                    else option.removeAttribute('selected');
                  } else if (`${hours}:${minutes}` === arg.workingHours.offTime)
                    option.setAttribute('selected', 'selected');
                  else option.removeAttribute('selected');
                } else if (i === hour && x < minute)
                  option.setAttribute('selected', 'selected');
                else option.removeAttribute('selected');
                select.appendChild(option);
              }
            }
          } else if (time === '30') {
            for (let i = 0; i < 24; i += 1) {
              for (let x = 0; x < 60; x += 30) {
                const hours: string = i.toString().padStart(2, '0');
                const minutes: string = x.toString().padStart(2, '0');
                const option = document.createElement('option');
                option.id = `option/${timestamp + i}`;
                option.value = `${hours}:${minutes}`;
                option.textContent = `${hours}:${minutes}`;
                if (arg.isDayOff && arg.workingHours) {
                  if (type === 'ATTENDANCE/WORKTIME') {
                    if (`${hours}:${minutes}` === arg.workingHours.workTime)
                      option.setAttribute('selected', 'selected');
                    else option.removeAttribute('selected');
                  } else if (`${hours}:${minutes}` === arg.workingHours.offTime)
                    option.setAttribute('selected', 'selected');
                  else option.removeAttribute('selected');
                } else if (i === hour && x < minute)
                  option.setAttribute('selected', 'selected');
                else option.removeAttribute('selected');
                select.appendChild(option);
              }
            }
          }
          a.appendChild(select);
        }
        if (type === 'ATTENDANCE/USETIME') {
          const totalWorkTime = arg.workingHours
            ? arg.workingHours.totalWorkTime
            : undefined;
          const usetime = a.getAttribute('data-time-type');
          const control = a.querySelector('select');
          const style = control?.getAttribute('style');
          if (control === null || usetime === null) return;
          control.remove();
          const select = document.createElement('select');
          if (style) select.setAttribute('style', style);
          select.setAttribute(
            'onblur',
            `(function(el){ for (var i = 0; i < el.children.length; i++) if (el.value === el.children[i].value) el.children[i].setAttribute('selected', 'selected'); else el.children[i].removeAttribute('selected'); })(this)`,
          );
          const timestamp = Date.now();
          if (usetime === 'hour') {
            for (let i = 0; i < 8; i += 1) {
              const option = document.createElement('option');
              option.id = `option/${timestamp + i}`;
              option.value = `${(i + 1) * 60}`;
              option.textContent = `${i + 1}`;
              if (totalWorkTime) {
                if (totalWorkTime.toString() === `${(i + 1) * 60}`)
                  option.setAttribute('selected', 'selected');
                else option.removeAttribute('selected');
              } else if (i === 0) option.setAttribute('selected', 'selected');
              select.appendChild(option);
            }
          } else if (usetime === 'minute') {
            for (let i = 30; i <= 480; i += 30) {
              const option = document.createElement('option');
              option.id = `option/${timestamp + i}`;
              option.value = i.toString();
              option.textContent = i.toString();
              if (totalWorkTime) {
                if (totalWorkTime.toString() === `${(i + 1) * 60}`)
                  option.setAttribute('selected', 'selected');
                else option.removeAttribute('selected');
              } else if (i === 60) option.setAttribute('selected', 'selected');
              select.appendChild(option);
            }
          }
          a.appendChild(select);
        }
        if (type === 'ATTENDANCE/START' || type === 'ATTENDANCE/END') {
          const control = a.querySelector('input');
          if (control === null) return;
          if (type === 'ATTENDANCE/START' || type === 'ATTENDANCE/END')
            control.setAttribute(
              'value',
              dateTimeFormat(new Date(), 'yyyy-MM-DD'),
            );
          control.setAttribute(
            'onblur',
            `this.setAttribute('value', this.value)`,
          );
          if (required === 'true') {
            control.setAttribute('onclick', `this.style.background = 'none';`);
            control.setAttribute('onkeydown', `this.style.background = 'none'`);
          }
        }
        if (type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL') {
          const input = a.querySelector('input');
          if (input === null) return;

          const expressionUnit = arg.expressionUnit ?? 'DAY';
          input.setAttribute('time-format', expressionUnit);
          input.setAttribute(
            'onchange',
            `(function (el) {

            let minutes = 0;
        
            document.querySelectorAll('gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE"]').forEach((a, i) => {
                const dateOption = a.querySelector('select[data-attendance="ATTENDANCE/SENDNOTICE_DATE_OPTION"]');
        
                if (dateOption) {
                    const value = dateOption.options[dateOption.selectedIndex].value;
                    if (value === '종일')
                        minutes = minutes + 480;
                    if (value === '반차')
                        minutes = minutes + 240;
                    if (value === '시간') {
                        const timeInput = a.querySelector('select[data-attendance="ATTENDANCE/SENDNOTICE_TIME"]');
                        if (timeInput === null || timeInput === undefined) return;
                        minutes = minutes + Number(timeInput.value);
                    }
                }
            });
        
            if(minutes === 0 || minutes === NaN) return;
        
            const format = el.getAttribute('time-format');
            const absMinutes = Math.abs(minutes);
        
            if (format === 'MINUTE') {
                let formatTime = '';
                const day = Math.trunc(absMinutes / 480);
                if (day !== 0) formatTime = day + '일';
                const timeDiffDay = absMinutes - day * 480;
                const hour = Math.trunc(timeDiffDay / 60);
                const min = timeDiffDay - hour * 60;
                if (hour !== 0)
                    formatTime =
                        formatTime === '' ? hour + '시간' : formatTime + ' ' + hour + '시간';
                if (min !== 0)
                    formatTime =
                        formatTime === '' ? min + '분' : formatTime + ' ' + min + '분';
        
                if (formatTime === '') {
                  formatTime = '0일';
                  el.setAttribute('value', formatTime);
                  return;
                }
                if (minutes < 0) {
                  formatTime = '-' + formatTime;
                  el.setAttribute('value', formatTime);
                  return;
                } 
                el.setAttribute('value', formatTime);
            }
            else {
                let fixedMinutes = parseFloat((absMinutes / 480).toFixed(2));
                if (minutes < 0) {
                  fixedMinutes = '- ' + fixedMinutes + '일';
                  el.setAttribute('value', fixedMinutes);
                  return;
                }
                fixedMinutes = fixedMinutes + '일';
                el.setAttribute('value', fixedMinutes);
            }
        })(this)`,
          );
        }
        if (type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE') {
          const select = a.querySelector('select');
          const input = document.createElement('input');

          input.type = 'date';
          input.setAttribute('value', dateFormat(new Date(), 'yyyy-MM-DD'));
          input.setAttribute(
            'style',
            'box-sizing:border-box; width:130px; height:24px; margin:0; padding:4px; border:1px solid #888888; border-radius:2px; font-family:Arial,Sans-Serif; font-size:12px; color:#1e293b; line-height:14px;',
          );
          input.setAttribute(
            'onblur',
            `this.setAttribute("value", this.value)`,
          );
          input.setAttribute('onclick', `this.style.background = "none"`);
          input.setAttribute('onkeydown', `this.style.background = "none"`);
          if (select === null) return;
          a.querySelectorAll('input[type="date"]').forEach((z) => {
            z.setAttribute('value', dateFormat(new Date(), 'yyyy-MM-DD'));
            z.setAttribute('onblur', 'this.setAttribute("value", this.value)');
            z.setAttribute('onclick', `this.style.background = 'none'`);
            z.setAttribute('onkeydown', `this.style.background = 'none'`);
          });

          const timeSelect = document.createElement('select');
          timeSelect.setAttribute(
            'data-attendance',
            'ATTENDANCE/SENDNOTICE_TIME',
          );

          let totalWorkTime = 480;
          if (arg.workingHours) totalWorkTime = arg.workingHours.totalWorkTime;
          const totalWorkHour = totalWorkTime / 60;

          for (let i = 0; i < totalWorkHour; i += 1) {
            const option = document.createElement('option');
            const timestamp = Date.now();
            option.id = `option/${timestamp}${i}`;
            option.value = `${(i + 1) * 60}`;
            option.textContent = `${i + 1}`;
            if (i === 0) option.setAttribute('selected', 'selected');
            timeSelect.appendChild(option);
          }

          a.insertBefore(timeSelect, select);
          timeSelect.setAttribute('style', 'display:none;');

          select.setAttribute(
            'onchange',
            `(function (el) {
              for (var i = 0; i < el.children.length; i++) {
                if (el.value === el.children[i].value) {
                  el.children[i].setAttribute('selected', 'selected')
                  const timeElement = el.parentNode.querySelector('select[data-attendance="ATTENDANCE/SENDNOTICE_TIME"]');
                  if(timeElement === null && timeElement === undefined) break;
                  if (el.children[i].value === '시간') {
                    timeElement.setAttribute('style', 'display:inline-block;');
                  } else {
                    timeElement.setAttribute('style', 'display:none;');
                  }
                } else el.children[i].removeAttribute('selected');
              }
            })(this)`,
          );

          select.setAttribute(
            'onblur',
            `(function(el){
              const totalElement = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL"]');
              if(totalElement === null && totalElement === undefined) return;
              
              const totalInput = totalElement.querySelector('input');
              totalInput.dispatchEvent(new Event('change'));
            })(this)`,
          );

          timeSelect.setAttribute(
            'onblur',
            `(function(el){
              for (let i = 0; i < el.children.length; i++) {
                if (el.value === el.children[i].value) {
                  el.children[i].setAttribute('selected', 'selected')
                } else el.children[i].removeAttribute('selected');
              }
              
              const totalElement = document.querySelector('gw-fb-element[data-attendance="ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL"]');
              if(totalElement === null && totalElement === undefined) return;

              const totalInput = totalElement.querySelector('input');
              totalInput.dispatchEvent(new Event('change'));
            })(this)`,
          );
        }
      });

    return document.body.innerHTML;
  }

  static createViewHtml(arg: { element: HTMLElement }): string {
    const tags: { id: string; html: string }[] = [];

    arg.element
      .querySelectorAll<HTMLIFrameElement>(
        `gw-fb-element[data-control="CONTROL/EDITOR"] > iframe`,
      )
      .forEach((el) => {
        const target = el.contentWindow || el.contentDocument;
        if (target) {
          const dext5 = (target as any).DEXT5;
          tags.push({ id: el.id, html: dext5.getBodyValue(el.id) });
        }
      });

    const domParser = new DOMParser();
    const document = domParser.parseFromString(
      arg.element.innerHTML,
      'text/html',
    );

    tags.forEach(({ id, html }) => {
      const editor = document.querySelector(`#${id}`);
      if (editor && editor.parentElement) {
        const element = editor.parentElement;
        const dataOption = element.getAttribute('data-option');
        const role = element.getAttribute('data-role') ?? '';

        const style = editor.getAttribute('style') ?? '';
        const match = style.match(/[^-]height:(.+?);/);
        const height = match && match[1] ? match[1] : '200px';

        const div = document.createElement('div');
        div.setAttribute('data-role', role);
        if (dataOption) div.setAttribute('data-option', dataOption);
        div.setAttribute(
          `data-${role}`,
          element.getAttribute(`data-${role}`) ?? '',
        );
        div.setAttribute(
          'style',
          `min-height:${height}; word-break: break-all;`,
        );

        div.innerHTML = html;
        const { parentElement } = element;
        element.replaceWith(div);

        // 부모 엘리먼트가 P 이고 자식 엘리먼트가 DIV 인 경우 P 엘리먼트가 밖으로
        // 빠지는 현상으로 DIV 엘리먼트로 변경합니다.
        if (parentElement?.tagName === 'P') {
          const parentDiv = document.createElement('div');
          parentDiv.setAttribute('data-element', 'p');
          const pStyle = parentElement.getAttribute('style');
          if (pStyle) parentDiv.setAttribute('style', pStyle);
          parentDiv.innerHTML = parentElement.innerHTML;
          parentElement.replaceWith(parentDiv);
        }
      }
    });

    document
      .querySelectorAll<HTMLElement>('gw-fb-element[data-role="control"]')
      .forEach((a) => {
        // console.log(`document.querySelectorAll<HTMLElement>('gw-fb-element[data-role="control"]'):a`, a);
        const role = a.getAttribute('data-role');
        const type = a.getAttribute(`data-${role}`);

        if (
          type === 'CONTROL/TEXT' ||
          type === 'CONTROL/DATE' ||
          type === 'CONTROL/TIME'
        ) {
          const control = a.querySelector('input');
          if (control === null) return;

          const data = document.createElement('gw-fb-element-control-data');

          const style = control.getAttribute('style') ?? '';
          data.setAttribute(
            'style',
            `${style} border-color:transparent; white-space: pre-wrap; word-break: break-all;`,
          );

          data.textContent = control.getAttribute('value');
          control.replaceWith(data);
        }
        if (type === 'CONTROL/TEXTAREA') {
          const control = a.querySelector('textarea');
          if (control === null) return;

          const data = document.createElement('gw-fb-element-control-data');

          let style = control.getAttribute('style') ?? '';

          const match = style.match(/[^-]text-align:(.+?);/);
          const textAlignStyle = match && match[1] ? '' : ' text-align:left;';

          style +=
            ' border-color:transparent; white-space:pre-wrap; word-break:break-all;';
          if (textAlignStyle !== '') style += textAlignStyle;

          data.setAttribute('style', style);

          const rows = control.getAttribute('rows');
          if (rows !== null) data.setAttribute('rows', rows);

          data.innerHTML = control.value.replaceAll('\n', '<br>');
          control.replaceWith(data);
        }
        if (
          type === 'CONTROL/NUMBER' ||
          type === 'CONTROL/CALCULATION_VALUE' ||
          type === 'CONTROL/FORMULA'
        ) {
          const control = a.querySelector('input');
          if (control === null) return;

          const data = document.createElement('gw-fb-element-control-data');

          const style = control.getAttribute('style') ?? '';
          data.setAttribute('style', `${style} border-color:transparent;`);

          if (type !== 'CONTROL/NUMBER') {
            const calculateId = control.getAttribute('data-calculate-id');
            if (calculateId !== null)
              data.setAttribute('data-calculate-id', calculateId);
          }

          if (type === 'CONTROL/CALCULATION_VALUE') {
            const calculateId = control.getAttribute('data-calculate-id');
            if (calculateId !== null)
              data.setAttribute('data-calculate-id', calculateId);
          }

          if (type === 'CONTROL/FORMULA') {
            const formula = control.getAttribute('data-formula');
            if (formula !== null) data.setAttribute('data-formula', formula);
            const calculateId = control.getAttribute('data-calculate-id');
            if (calculateId !== null)
              data.setAttribute('data-calculate-id', calculateId);
          }

          const currencySymbol = control.getAttribute('data-currency-symbol');
          if (currencySymbol !== null)
            data.setAttribute('data-currency-symbol', currencySymbol);

          data.textContent = control.getAttribute('value');
          control.replaceWith(data);
        }
        if (type === 'CONTROL/RADIO' || type === 'CONTROL/CHECKBOX') {
          for (let i = 0; i < a.children.length; i += 1) {
            const control = a.children[i];
            const controlType = control.getAttribute('type');
            if (controlType === 'radio' || controlType === 'checkbox') {
              control.removeAttribute('onchange');
              control.setAttribute('onclick', 'return false');
            }
          }
        }
        if (type === 'CONTROL/SELECT') {
          const control = a.querySelector('select');
          if (control === null) return;
          const viewStyle = a.getAttribute('view-temp-style') ?? '';
          const data = document.createElement('gw-fb-element-control-data');
          data.textContent = control.options[control.selectedIndex].text;
          if (viewStyle !== '') data.setAttribute('style', viewStyle);
          control.hidden = true;
          a.appendChild(data);
        }
        if (type === 'CONTROL/DATE_RANGE') {
          a.querySelectorAll('input').forEach((b) => {
            const style = b.getAttribute('style') ?? '';
            const value = b.getAttribute('value') ?? '';

            const data = document.createElement('gw-fb-element-control-data');
            data.setAttribute('style', `${style} border-color:transparent;`);
            data.textContent = value;

            b.replaceWith(data);
          });
        }
        if (type === 'CONTROL/DIALOG' || type === 'CONTROL/ROW_ADD') {
          const control = a.querySelector('input');
          if (control === null) return;

          const style = control.getAttribute('style') ?? '';
          control.setAttribute('style', `${style} visibility:hidden;`);
        }
      });

    document
      .querySelectorAll<HTMLElement>('gw-fb-element[data-role="attendance"]')
      .forEach((a) => {
        const role = a.getAttribute('data-role');
        const type = a.getAttribute(`data-${role}`);

        if (
          type === 'ATTENDANCE/DAYOFF' ||
          type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE'
        ) {
          const control = a.querySelector('select');
          const style = a.getAttribute('style');
          if (control === null) return;
          const data = document.createElement('gw-fb-element-control-data');
          data.textContent = control.options[control.selectedIndex].text;
          control.hidden = true;
          a.setAttribute('style', `${style} vertical-align:initial`);
          a.insertBefore(data, a.firstChild);
        }
        if (type === 'ATTENDANCE/DATE_RANGE') {
          const style = a.getAttribute('style');
          a.querySelectorAll('input').forEach((b) => {
            const value = b.getAttribute('value') ?? '';

            const data = document.createElement(
              'gw-fb-element-attendance-data',
            );
            data.textContent = value;
            data.removeAttribute('style');
            b.replaceWith(data);
          });
          a.setAttribute('style', `${style} vertical-align:initial`);
        }
        if (type === 'ATTENDANCE/REASON') {
          const control = a.querySelector('textarea');
          if (control === null) return;

          const data = document.createElement('gw-fb-element-attendance-data');

          const rows = control.getAttribute('rows');
          if (rows !== null) data.setAttribute('rows', rows);

          data.innerHTML = control.value.replaceAll('\n', '<br>');
          control.replaceWith(data);
        }
        if (
          type === 'ATTENDANCE/START' ||
          type === 'ATTENDANCE/END' ||
          type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE_TOTAL'
        ) {
          const control = a.querySelector('input');
          const style = a.getAttribute('style');
          if (control === null) return;

          const data = document.createElement('gw-fb-element-attendance-data');

          data.textContent = control.getAttribute('value');
          a.setAttribute('style', `${style} vertical-align:initial`);
          control.replaceWith(data);
        }
        if (
          type === 'ATTENDANCE/WORKTIME' ||
          type === 'ATTENDANCE/OFFTIME' ||
          type === 'ATTENDANCE/USETIME'
        ) {
          const control = a.querySelector('select');
          const style = a.getAttribute('style') ?? '';
          if (control === null) return;
          const data = document.createElement('gw-fb-element-attendance-data');

          const { value, textContent } = control.options[control.selectedIndex];
          data.textContent =
            type === 'ATTENDANCE/USETIME' ? textContent : value;
          a.setAttribute('style', `${style} vertical-align:initial`);
          control.replaceWith(data);
        }
        if (type === 'ATTENDANCE/SENDNOTICE_SCHEDULED_DATE') {
          const style = a.getAttribute('style');

          const input = a.querySelector('input');
          if (input === null) return;
          const inputValue = input.getAttribute('value');

          const dateOption = a.querySelector(
            'select[data-attendance="ATTENDANCE/SENDNOTICE_DATE_OPTION"]',
          ) as HTMLSelectElement | null;
          if (dateOption === null) return;
          const optionValue =
            dateOption.options[dateOption.selectedIndex].value;

          const timeOption = a.querySelector(
            'select[data-attendance="ATTENDANCE/SENDNOTICE_TIME"]',
          ) as HTMLSelectElement | null;
          const timeValue =
            timeOption === null || optionValue !== '시간'
              ? ''
              : timeOption.options[timeOption.selectedIndex].textContent;

          const data = document.createElement('gw-fb-element-attendance-data');

          if (timeValue !== '') {
            data.textContent = `${inputValue} ${timeValue}${optionValue}`;
          } else {
            data.textContent = `${inputValue} ${optionValue}`;
          }

          data.setAttribute('style', `${style} vertical-align:initial`);
          a.replaceWith(data);
        }
      });
    return document.body.innerHTML;
  }

  /** 콘텐츠 미리보기 */
  static viewingPreview(arg: { content: string }): string {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(arg.content, 'text/html');
    document.querySelectorAll<HTMLElement>('gw-fb-element').forEach((a) => {
      const textControl = a.querySelector('textarea');
      if (textControl !== null) textControl.disabled = true;

      const controls = a.querySelectorAll('input');
      if (controls !== null) {
        controls.forEach((b) => {
          b.disabled = true;
        });
      }

      const selectControl = a.querySelector('select');
      if (selectControl !== null) {
        selectControl.disabled = true;
      }
    });
    return document.body.innerHTML;
  }
}

export default FormBuilder;
