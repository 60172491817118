import React from 'react';
import { useTranslation } from 'react-i18next';
import { b62, getPathParams } from '../../groupware-common/utils';
import ApprovalDrawer from '../pages/root/approval/ApprovalDrawer';

function RootLocateDrawer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  // console.log(`${RootLocateDrawer.name}(props)`, props);

  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, hash } = props;
  const { p1 } = getPathParams('/*/:p1', pathname);

  return (
    <ApprovalDrawer
      key={i18n.language}
      folderId={
        p1 === 'preferences' || p1 === 'status' || p1 === 'archivesettings'
          ? p1
          : b62(p1)
      }
      pathname={pathname}
    />
  );
}

export default RootLocateDrawer;
