import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import DialogHeader from '../dialog/DialogHeader';
import DialogTitle from '../dialog/DialogTitle';

const Signature = (props: {
  onClose(): void;
  onSave(signature: string): void;
}): JSX.Element => {
  const canvasRef = useRef<SignatureCanvas>(null);
  const [isSigned, setIsSigned] = useState(false);

  const handleClear = () => {
    setIsSigned(false);
    canvasRef.current?.clear();
  };

  const handleSave = () => {
    if (!canvasRef.current) return;
    const image = canvasRef.current.getTrimmedCanvas().toDataURL('image/png');
    props.onSave(image);
  };

  return (
    <Dialog size="sm" onClose={props.onClose}>
      <DialogHeader>
        <DialogTitle>서명 이미지</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <SignatureCanvas
          ref={canvasRef}
          onBegin={() => setIsSigned(true)}
          canvasProps={{
            className: 'signature-canvas',
            width: 600,
            height: 250,
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button text="초기화" onClick={handleClear} />
        <Button
          disabled={!isSigned}
          variant="contained"
          text="저장"
          onClick={handleSave}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default Signature;
