import axios from 'axios';
import {
  apiError,
  EntityKeyable,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { ParticipantStatusProps } from '../../../../stores/calendar/schedules';
import { AuthorityType } from '../calendars';

/** 일정 리스트 조회. */
async function findSchedules(arg: {
  employeeId: number;
  fromDate: string;
  toDate: string;
}): Promise<
  {
    companyId: number;
    id: number;
    rootEventId: number; // 공유 받은 일정일 경우 원본 일정의 아이디. 0인 경우 원본 일정.
    calendarId: number;
    name: string;
    startDateTime: string;
    endDateTime: string;
    isFull: boolean; // 종일 여부.
    isRepeat: boolean; // 반복 일정 여부.
    employeeId: number; // 주최자 아이디.
    reply: ParticipantStatusProps | null; // 공유 받은 일정일 경우 일정 참석 여부 상태 표시.
    updateAt: string;
  }[]
> {
  try {
    const params = { fromDate: arg.fromDate, toDate: arg.toDate };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${arg.employeeId}/events`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 구독 중인 휴일 캘린더 일정 리스트 조회. */
async function findHolidaySchedules(arg: {
  useExposeAnniversary: boolean;
  employeeId: number;
  fromDate: string;
  toDate: string;
}): Promise<
  {
    calendarId: number;
    name: string;
    startDateTime: string;
    endDateTime: string;
    isFull: boolean; // 종일 여부.
  }[]
> {
  try {
    const { useExposeAnniversary, employeeId, fromDate, toDate } = arg;
    const params = { fromDate, toDate };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/events/${
      useExposeAnniversary ? 'anniversarys' : 'holidays'
    }`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 구독 중인 근태 캘린더 일정 리스트 조회. */
async function findAttendanceSchedules(arg: {
  option: AuthorityType;
  companyId: number;
  organizationId: number;
  employeeId: number;
  fromDate: string;
  toDate: string;
}): Promise<
  {
    employeeId: number;
    codeName: string;
    startDateTime: string;
    endDateTime: string;
  }[]
> {
  try {
    const { option, companyId, organizationId, employeeId, fromDate, toDate } =
      arg;
    const params = { startdate: fromDate, enddate: toDate };
    const { host, headers } = getApiConfig();
    let url = `${host}/api/interworking/calendar/v1/companys/${companyId}`;
    if (option === 'ORGANIZATION')
      url = `${url}/organizations/${organizationId}`;
    if (option === 'SUB_ORGANIZATION')
      url = `${url}/organizations/${organizationId}/childs`;
    if (option === 'EMPLOYEE')
      url = `${url}/organizations/${organizationId}/employees/${employeeId}`;
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 일정 단일 조회. */
async function findScheduleView(arg: {
  calendarId: number;
  eventId: number;
  date?: string;
}): Promise<{
  id: number;
  rootEventId: number; // 공유 받은 일정일 경우 원본 일정의 아이디. 0인 경우 원본 일정.
  calendarId: number;
  employeeId: number; // 주최자 아이디.
  name: string;
  description: string;
  startDateTime: string;
  endDateTime: string;
  isFull: boolean; // 종일 여부.
  resource: {
    resourceItemId: number;
    resourceReservationId: number;
    status: string;
    updateAt: string;
  } | null;
  repeat: {
    eventId: number;
    frequency: string; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
    cycle: number; // 반복 주기. 1, 2, 3, 4, ...
    monthRepeatStandard: string | null; // 반복 기준(반복빈도가 월), [DATE: 매월NN일, DAY : N번째 A요일, LAST : 마지막 A요일]
    repeatDays: string | null; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
    endDate: string; // 반복 종료일
  } | null;
  alarms: {
    id: number;
    type: string; // mail | alarm
    timeUnit: string; // MINUTE, HOUR, DAY, WEEK
    ammount: number;
    updateAt: string;
  }[];
  participants: {
    participantCompanyId: number;
    participantId: number;
    participantReply: ParticipantStatusProps | null;
    updateAt: string;
  }[];
  updateAt: string;
}> {
  try {
    const { calendarId, eventId, date } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${calendarId}/events/${eventId}`;
    const response = await axios.get(url, { headers, params: { date } });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 일정 저장. */
async function createSchedule(arg: {
  calendarId: number;
  data: {
    employeeId: number;
    name: string;
    description: string;
    startDateTime: string; // 시작 시간.
    endDateTime: string; // 종료 시간.
    isFull: boolean;
    repeat?: {
      frequency: string; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
      cycle: number; // 반복 주기. 1, 2, 3, 4, ...
      monthRepeatStandard?: string; // 반복 기준(반복빈도가 월), [DATE : 매월NN일, DAY : N번째 A요일, LAST : 마지막 A요일]
      days?: string; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
      endDate: string; // 반복 종료일
    };
    alarms?: {
      type: string; // MAIL | ALARM
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
    }[];
  };
}): Promise<EntityKeyable> {
  try {
    const { calendarId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${calendarId}/events`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공유받은 일정 수정. */
async function updateSharedSchedule(arg: {
  employeeId: number;
  eventId: number;
  data: {
    type: string;
    timeUnit: string;
    ammount: number;
  }[];
}): Promise<
  {
    id: number;
    type: string;
    timeUnit: string;
    ammount: number;
    updateAt: string;
  }[]
> {
  try {
    const { employeeId, eventId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/events/${eventId}/alarms`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공유받은 일정 참석 여부 변경. */
async function updateScheduleParticipantStatus(arg: {
  employeeId: number;
  rootEventId: number;
  data: {
    eventStartDateTime: string;
    eventEndDateTime: string;
    answer: ParticipantStatusProps;
    updateAt: string;
  };
}): Promise<EntityKeyable> {
  try {
    const { employeeId, rootEventId: eventId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/events/${eventId}/reply`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 일반 일정 수정. */
async function updateSchedule(arg: {
  calendarId: number;
  eventId: number;
  data: {
    employeeId?: number;
    name?: string;
    description?: string;
    startDateTime: string;
    endDateTime: string;
    isFull: boolean;
    repeat?: {
      frequency: string; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
      cycle: number; // 반복 주기. 1, 2, 3, 4, ...
      monthRepeatStandard?: string; // 반복 기준(반복빈도가 월), [DATE : 매월NN일, DAY : N번째 A요일, LAST : 마지막 A요일]
      days?: string; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
      endDate: string; // 반복 종료일
      isDelete: boolean; // 삭제 여부
    };
    isResourceDelete?: boolean; // 자원 예약 삭제 여부.
    resourceItemId?: number; // 예약된 자원 아이템 아이디.
    participants?: {
      companyId: number;
      employeeId: number;
      updateAt?: string;
      isDelete: boolean; // 삭제 여부
    }[];
    alarms?: {
      id?: number;
      type: string; // MAIL | ALARM
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
      isDelete: boolean; // 삭제 여부
    }[];
    updateAt: string;
  };
}): Promise<EntityKeyable> {
  try {
    const { calendarId, eventId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${calendarId}/events/${eventId}`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 일반 일정 삭제. */
async function deleteSchedule(arg: {
  calendarId: number;
  eventId: number;
  data: {
    eventUpdateAt: string;
    isDeleteResource: boolean;
  };
}): Promise<EntityKeyable> {
  try {
    const { calendarId, eventId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${calendarId}/events/${eventId}`;
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 반복 일정 삭제. */
async function deleteRecurringSchedule(arg: {
  calendarId: number;
  eventId: number;
  repeatType: string;
  updateAt: string;
  startDate: string;
}): Promise<EntityKeyable> {
  try {
    const { calendarId, eventId, repeatType } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${calendarId}/events/${eventId}/${repeatType}`;
    if (repeatType === 'piece') {
      const data = { removedDate: arg.startDate, updateAt: arg.updateAt };
      const response = await axios.delete(url, { headers, data });
      return response.data;
    }
    if (repeatType === 'after') {
      const data = { removedStartDate: arg.startDate, updateAt: arg.updateAt };
      const response = await axios.delete(url, { headers, data });
      return response.data;
    }
    const data = { eventUpdateAt: arg.updateAt };
    const response = await axios.delete(url, { headers, data });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 반복 일정 수정. */
async function updateRecurringSchedule(arg: {
  calendarId: number;
  eventId: number;
  repeatType: string;
  data: {
    employeeId?: number;
    name?: string;
    description?: string;
    startDateTime: string;
    endDateTime: string;
    isFull: boolean;
    repeat?: {
      frequency: string; // 반복 빈도, 일: DAYS 주: WEEKS 월: MONTHS 년: YEARS
      cycle: number; // 반복 주기. 1, 2, 3, 4, ...
      monthRepeatStandard?: string; // 반복 기준(반복빈도가 월), [DATE : 매월NN일, DAY : N번째 A요일, LAST : 마지막 A요일]
      days?: string; // 반복 요일 배열String(반복빈도가 주), 월요일이 1 , 화요일이 2 , .. 일요일이 7
      endDate: string; // 반복 종료일
      isDelete: boolean; // 삭제 여부
    };
    participants?: {
      companyId: number;
      employeeId: number;
      isDelete: boolean; // 삭제 여부
    }[];
    alarms?: {
      id?: number;
      type: string; // MAIL | ALARM
      timeUnit: string; // MINUTE, HOUR, DAY, WEEK
      ammount: number;
      isDelete: boolean; // 삭제 여부
    }[];
    lookupStartDateTime?: string; // 변경 전 시작 시간.(이 일정, 이 일정 이후)
    updateAt: string;
  };
}): Promise<EntityKeyable> {
  try {
    const { calendarId, eventId, repeatType, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${calendarId}/events/${eventId}/${repeatType}`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 공유받은 일정 삭제. */
async function deleteSharedSchedule(arg: {
  employeeId: number;
  eventId: number;
  eventUpdateAt: string;
}): Promise<EntityKeyable> {
  try {
    const { employeeId, eventId, eventUpdateAt } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/events/${eventId}`;
    const response = await axios.delete(url, {
      headers,
      data: { eventUpdateAt },
    });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const schedulesApi = {
  findSchedules,
  findHolidaySchedules,
  findAttendanceSchedules,
  findScheduleView,

  create: createSchedule,

  updateSharedSchedule,
  updateScheduleParticipantStatus,

  update: updateSchedule,
  updateRecurringSchedule,

  delete: deleteSchedule,
  deleteRecurringSchedule,
  deleteSharedSchedule,
};

export default schedulesApi;
