import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { documentActions } from '../../../../stores/approval/document';

function ApprovalTrashRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const dispatch = useAppDispatch();
  const { pathname, search } = props;
  const pathParams = getPathParams<{ p1?: number }>(
    `/*/*/*/:p1$base62`,
    props.pathname,
  );

  const propsItemId = pathParams.p1;
  useEffect(() => {
    dispatch(
      documentActions.trashList({ search, route: { pathname, search } }),
    );
  }, [dispatch, propsItemId, props]);

  return null;
}

export default ApprovalTrashRoute;
