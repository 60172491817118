import React from 'react';
import { useSelector } from 'react-redux';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import ApprovalContentContainer from '../pages/root/approval/ApprovalContentContainer';
import ApprovalArchiveSettingsContainer from '../pages/root/approval/archive-settings/ApprovalArchiveSettingsContainer';
import ApprovalComposeContainer from '../pages/root/approval/compose/ApprovalComposeContainer';
import ApprovallinkageComposeContainer from '../pages/root/approval/approvallinkage/ApprovallinkageComposeContainer';
import ApprovalTeamsApproveContainer from '../pages/root/approval/content/ApprovalTeamsApproveContainer';
import ApprovalOnlyViewContentContainer from '../pages/root/approval/content/ApprovalOnlyViewContentContainer';
import ApprovalDashboardContainer from '../pages/root/approval/dashboard/ApprovalDashboardContainer';
import ApprovalPreferencesApprovalLineContainer from '../pages/root/approval/preferences/ApprovalPreferencesApprovalLineContainer';
import ApprovalPreferencesContainer from '../pages/root/approval/preferences/ApprovalPreferencesContainer';
import ApprovalPreferencesFolderContainer from '../pages/root/approval/preferences/ApprovalPreferencesFolderContainer';
import ApprovalPreferencesNotificationsContainer from '../pages/root/approval/preferences/ApprovalPreferencesNotificationsContainer';
import ApprovalStatusContainer from '../pages/root/approval/status/ApprovalStatusContainer';

function RootLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // console.log(`${RootLocateContainer.name}.render(props)`, props);
  // const languages = ['ko-KR' as const];

  const { pathname, search, hash, locationKey } = props;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const { contentMode } = getQueryParams(search);

  const teams =
    useSelector((state: RootState) => state.session.resource) === 'teams';

  // 결재작성.
  if (contentMode === 'create' || contentMode === 'update') {
    return (
      <ApprovalComposeContainer
        pathname={pathname}
        search={search}
        key={locationKey}
      />
    );
  }
  // 연동결재
  if (p1 === 'approvalinterworking') {
    // 연동결재 상신.
    if (p2 === 'compose')
      return (
        <ApprovallinkageComposeContainer pathname={pathname} search={search} />
      );
    // 연동결재 문서 보기.
    return (
      <ApprovalOnlyViewContentContainer pathname={pathname} search={search} />
    );
  }
  // 대시보드.
  if (p1 === undefined) {
    return <ApprovalDashboardContainer pathname={pathname} />;
  }
  // 환경설정.
  if (p1 === 'preferences') {
    // 개인환경.
    if (hash === '')
      return (
        <ApprovalPreferencesContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    // 알림설정.
    if (hash === '#notifications')
      return (
        <ApprovalPreferencesNotificationsContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    // 결재선관리.
    if (hash === '#approvalline')
      return (
        <ApprovalPreferencesApprovalLineContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );
    // 결재함관리.
    if (hash === '#folder')
      return (
        <ApprovalPreferencesFolderContainer
          pathname={pathname}
          search={search}
          hash={hash}
        />
      );

    return <div>403</div>;
  }
  // 결재현황.
  if (p1 === 'status') {
    return <ApprovalStatusContainer pathname={pathname} search={search} />;
  }
  // 기록물관리.
  if (p1 === 'archivesettings') {
    return (
      <ApprovalArchiveSettingsContainer pathname={pathname} search={search} />
    );
  }

  // 팀즈 알림 - 결재문서보기
  if (teams && p1 === 'document')
    return (
      <ApprovalOnlyViewContentContainer pathname={pathname} search={search} />
    );
  // 팀즈 알림 - 결재하기
  if (teams && p1 === 'approve')
    return (
      <ApprovalTeamsApproveContainer pathname={pathname} search={search} />
    );

  return <ApprovalContentContainer pathname={pathname} search={search} />;
}

export default RootLocateContainer;
