import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timeFormat,
  timezoneDate,
} from '../../../groupware-common/utils/ui';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import {
  AdjustCreateItem,
  AlternativeAdjustCreateItem,
  dayOffAdjustApi,
  substituteAdjustApi,
} from '../../apis/attendance/v1/adjust';
import AttendancePreferencesApi from '../../apis/attendance/v1/preferences';

const name = 'attendance/adjust';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

interface AdjustListItem {
  modifingId: number; // 연차조정 아이디.
  modifingDate: string; // 조정 일자.
  employeeId: number; // 직원 아이디.
  // type: 'add' | 'minus'; // 조정 타입 (추가 | 제외)
  modifingLeaves: string; // 조정 수 (minutes).
  remark: string; // 사유.
  attachedFiles: {
    companyId: number;
    id: number;
    attachedFileId: number;
    seq: number;
    name: number;
    size: number;
    updateAt: string;
  } | null; // 첨부파일.
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  list: {
    items: AdjustListItem[];
    totalCount: number;
  };
}

const initialState: State = {
  requests: [],
  list: {
    items: [],
    totalCount: 0,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (
    arg: {
      folderId?: string;
      search: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    const { expressionUnit } =
      await AttendancePreferencesApi.findAttendanceBasic();
    const queryParams = getQueryParams(arg.search);
    const newYearDate = timezoneDate();
    newYearDate.setMonth(0);
    newYearDate.setDate(1);
    const data = {
      startDate:
        queryParams.startDate ??
        dateFormat(initialDate(newYearDate), 'yyyy-MM-DD'),
      endDate: queryParams.endDate ?? dateFormat(new Date(), 'yyyy-MM-DD'),
      searchCode: queryParams.searchCode,
      searchWord:
        queryParams.searchCode === ''
          ? queryParams.searchWord
          : queryParams.directoryKeyword,
      pageNo: queryParams.pageNo ?? 1,
      rowsPerPage: queryParams.rowsPerPage ?? 15,
    };
    try {
      if (arg.folderId === 'dayOffAdjust') {
        // 연차조정.
        const items: AdjustListItem[] = (await dayOffAdjustApi.list(data)).map(
          (a) => {
            const modifingLeaves = timeFormat(a.modifingLeaves, expressionUnit);
            return {
              ...a,
              modifingLeaves:
                a.modifingLeaves > 0 ? `+${modifingLeaves}` : modifingLeaves,
            };
          },
        );
        const totalCount = await dayOffAdjustApi.totalCount(data);
        return { items, totalCount };
      }
      // 대휴조정.
      const items: AdjustListItem[] = (
        await substituteAdjustApi.list(data)
      ).map((a) => {
        const modifingLeaves = timeFormat(a.ammount, expressionUnit);
        return {
          modifingId: a.modifingId,
          modifingDate: a.modifingDate,
          employeeId: a.employeeId,
          modifingLeaves: a.ammount > 0 ? `+${modifingLeaves}` : modifingLeaves,
          remark: a.remark,
          attachedFiles: a.attachedFiles,
        };
      });
      const totalCount = await substituteAdjustApi.totalCount(data);
      return { items, totalCount };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const createAdjust = createAsyncThunk(
  `${name}/createAdjust`,
  async (
    arg: { folderId?: string; data: AdjustCreateItem } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    const { folderId, data, route } = arg;
    try {
      /** 연차 조정 등록 */
      if (folderId === 'dayOffAdjust') {
        const items: AdjustCreateItem = await dayOffAdjustApi.create(data);
        // 라우트 매개 변수가 있는 경우.
        if (route !== undefined) {
          await dispatch(findList({ folderId, search: route.search ?? '' }));
        }
        return items;
      }

      /** 대휴 조정 등록 */
      const items: AlternativeAdjustCreateItem =
        await substituteAdjustApi.create({
          modifingDate: data.modifingDate,
          employeeId: data.employeeId,
          ammount: data.modifingLeaves,
          remark: data.remark,
          attachedFile: data.attachedFiles,
        });
      if (route !== undefined) {
        await dispatch(findList({ folderId, search: route.search ?? '' }));
      }
      return items;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const adjustReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { meta, payload }) => {
        if (
          state.requests.find((x) => x.id === meta.requestId) !== undefined &&
          payload
        ) {
          state.requests = state.requests.filter(
            (x) => x.id !== meta.requestId,
          );
          if (payload !== undefined) state.list = payload;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default adjustReducer.reducer;

export const adjustActions = {
  list: findList,
  create: createAdjust,
};
