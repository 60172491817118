import React, { useEffect } from 'react';

/** 메모이즈 div 요소 */
const MemoizeDivElement = React.forwardRef(
  (
    props: {
      className?: string;
      onLoad(arg: { element: HTMLDivElement }): void;
    },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { className, onLoad } = props;

    useEffect(() => {
      if (ref && typeof ref !== 'function' && ref.current)
        onLoad({ element: ref.current });
    }, [ref, onLoad]);

    return <div ref={ref} className={className} />;
  },
);

MemoizeDivElement.displayName = 'MemoizeDiv';

export default React.memo(MemoizeDivElement);
