import React, { useState } from 'react';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import { getParentItems, getText } from '../../../../../groupware-common/utils';
import DropMenu from '../../../../../components/selectField/DropMenu';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { IconType } from '../../../../../groupware-common/types/icon';
import FeedBack from '../../../../../components/alert/FeedBack';

type CreateProps = {
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  parentId?: number;
  onSave(arg: { parentId: number; name: string }): void;
  onClose(): void;
  id?: undefined;
};

type UpdateProps = {
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  onSave(arg: {
    id: number;
    parentId: number;
    name: string;
    updateAt: string;
  }): void;
  onClose(): void;
  id: number;
  parentId: number;
  name: string;
  updateAt: string;
};

function BoardFormFolderEdit(props: CreateProps | UpdateProps): JSX.Element {
  const folders = [
    { id: 0, parentId: -1, text: '양식분류', icon: 'folder' as const },
    ...props.folders.filter((v) => v.id !== props.id),
  ];
  const [state, setState] = useState(() => {
    if (props.id === undefined)
      return {
        validation: '',
        paths: props.parentId
          ? getParentItems(folders, props.parentId).map(({ text }) => text)
          : (['양식분류'] as string[]),
        parentSelectDialogVisible: false,
        parentId: props.parentId ?? 0,
        name: '',
      };
    return {
      validation: '',
      paths: getParentItems(folders, props.parentId).map(({ text }) => text),
      parentSelectDialogVisible: false,
      parentId: props.parentId,
      name: props.name,
    };
  });

  /** 이름 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: event.target.value }));
  };

  /** 저장. */
  const handleSave = () => {
    const { parentId, name } = state;

    let validation = '';
    if (name.trim() === '') validation = '이름을 입력하세요.';
    if (validation !== '') {
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    const { onSave } = props;
    if (props.id === undefined) props.onSave({ parentId, name });
    else onSave({ id: props.id, parentId, name, updateAt: props.updateAt });
  };

  /** 상위 선택 대화 상자 열기. */
  const handleParentSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: true,
    }));
  };

  /** 상위 선택 대화 상자 닫기. */
  const handleParentSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: false,
    }));
  };

  /** 상위 선택 대화 상자 확인. */
  const handleParentSelectDialogConfirm = (
    id: number,
    text: string,
    path: string[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      paths: path,
      parentId: id,
      parentSelectDialogVisible: false,
    }));
  };

  /** 스낵바 닫기. */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 대화 상자 렌터링. */
  const renderDialog = () => {
    const { parentSelectDialogVisible, parentId } = state;
    if (parentSelectDialogVisible) {
      return (
        <TreePicker
          title="상위 분류 선택"
          list={folders}
          selectedId={parentId}
          rootId={0}
          onSelected={handleParentSelectDialogConfirm}
          onClose={handleParentSelectDialogClose}
        />
      );
    }
    return null;
  };

  const { id, onClose } = props;
  const { validation, paths, name } = state;

  const title = id === undefined ? '분류 등록' : '분류 수정';

  return (
    <>
      <Drawer title={title} size="sm" onClose={onClose}>
        <DrawerBody>
          <PostWrite>
            <PostWrite.Item title="상위 분류">
              <DropMenu
                value={paths}
                label="상위 분류 선택"
                onClick={handleParentSelectDialogOpen}
              />
            </PostWrite.Item>
            <PostWrite.Item required title="분류명">
              <TextField value={name} onChange={handleNameChange} />
            </PostWrite.Item>
          </PostWrite>
          {renderDialog()}
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default BoardFormFolderEdit;
