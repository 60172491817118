import React from 'react';

function Checkbox(props: {
  label?: string | React.ReactElement;
  style?: React.CSSProperties;
  name?: string;
  checked?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  indeterminate?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onClick?(event: React.MouseEvent<HTMLInputElement, MouseEvent>): void;
}): JSX.Element {
  let classname = 'eui-checkbox';
  if (props.className) classname += ` ${props.className}`;
  if (props.disabled) classname += ' disabled';
  if (props.indeterminate) classname += ' indeterminate';

  const handleClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (props.onClick) props.onClick(event);
  };

  return (
    <label
      className={classname}
      aria-checked={props.checked}
      style={props.style}
    >
      <input
        type="checkbox"
        name={props.name}
        checked={props.checked}
        readOnly={props.readonly}
        disabled={props.disabled}
        onChange={props.onChange}
        onClick={handleClick}
        data-indeterminate={props.indeterminate}
      />
      {typeof props.label === 'string' ? (
        <span className="label">{props.label}</span>
      ) : (
        props.label
      )}
    </label>
  );
}

export default Checkbox;
