import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { LocateArg } from '../../../groupware-common/types';
import { getQueryParams } from '../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timeFormat,
  timezoneDate,
} from '../../../groupware-common/utils/ui';
import { RootState } from '../../../groupware-webapp/app/store';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import attendancesApi from '../../apis/attendance/v1/documents';
import AttendancePreferencesApi from '../../apis/attendance/v1/preferences';

const name = 'attendance/organizationStatus';

interface organizaionStatusDays {
  companyId: number; // 회사 아이디.
  employeeId: number; // 직원 아이디.
  list: {
    attendanceCode: number; // 근태 코드 아이디.
    ammount: string; // 근태 코드 별 사용량.
  }[];
  total: string;
}

export interface organizatinoStatusMonths {
  companyId: number;
  employeeId: number;
  attendanceCode: number; // 근태코드
  ammountInJanuary: string;
  ammountInFebuary: string;
  ammountInMarch: string;
  ammountInApril: string;
  ammountInMay: string;
  ammountInJune: string;
  ammountInJuly: string;
  ammountInAugust: string;
  ammountInSeptember: string;
  ammountInOctober: string;
  ammountInNovember: string;
  ammountInDecember: string;
  ammountInTotal: string;
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  dayList: {
    items: organizaionStatusDays[];
    totalCount: number;
  };
  monthList: {
    items: organizatinoStatusMonths[];
    totalCount: number;
  };
  archiveOrganizationId: number; // 현재 해당 부서 - 겸직자일 때 필요.
}

const initialState: State = {
  requests: [],
  dayList: {
    items: [],
    totalCount: 0,
  },
  monthList: {
    items: [],
    totalCount: 0,
  },
  archiveOrganizationId: 0,
};

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${name}/`) === 0 && type.endsWith('/rejected');
}

/** 기간별 근태현황 리스트 조회. */
const findDayList = createAsyncThunk(
  `${name}/findDayList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();
      const { companyId, affiliatedOrganizations } = (getState() as RootState)
        .session.principal;
      const organizationIds = affiliatedOrganizations
        .filter((a) => a.manager === true)
        .map(({ id }) => id);
      const queryParams = getQueryParams(arg.search);

      const start = timezoneDate();
      start.setDate(1);
      const startDate =
        queryParams.startDate ?? dateFormat(initialDate(start), 'YYYY-MM-DD');
      const endDate =
        queryParams.endDate ?? dateFormat(new Date(), 'YYYY-MM-DD');
      const searchCode = 'organization';
      const searchWord =
        queryParams.directoryKeyword ?? `${companyId}_${organizationIds[0]}`;

      if (organizationIds.length === 0)
        throw new Error('접근 권한이 없습니다.');
      const items = (
        await attendancesApi.organizationDayList({
          startDate,
          endDate,
          searchCode,
          searchWord,
          pageNo: queryParams.pageNo ?? 1,
          rowsPerPage: queryParams.rowsPerPage ?? 15,
        })
      ).map((a) => {
        let total = 0;
        return {
          ...a,
          list: a.list.map((x) => {
            total += x.ammount;
            return {
              ...x,
              ammount: timeFormat(x.ammount, expressionUnit),
            };
          }),
          total: timeFormat(total, expressionUnit),
        };
      });
      const totalCount = await attendancesApi.organizationDayTotalCount({
        startDate,
        endDate,
        searchCode,
        searchWord,
      });

      return {
        items,
        totalCount,
        archiveOrganizationId: Number(searchWord.split('_')[1]),
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const findMonthList = createAsyncThunk(
  `${name}/findMonthList`,
  async (
    arg: {
      search: string;
    } & LocateArg,
    { getState, rejectWithValue },
  ) => {
    try {
      const { companyId, affiliatedOrganizations } = (getState() as RootState)
        .session.principal;
      const organizationIds = affiliatedOrganizations
        .filter((a) => a.manager === true)
        .map(({ id }) => id);
      if (organizationIds.length === 0)
        throw new Error('접근 권한이 없습니다.');

      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();

      const queryParams = getQueryParams(arg.search);

      const year =
        queryParams.status ?? timezoneDate().getFullYear().toString();
      const searchCode = 'organization';
      const searchWord =
        queryParams.directoryKeyword ?? `${companyId}_${organizationIds[0]}`;

      const data = (
        await attendancesApi.organizationMonthList({
          year,
          searchCode,
          searchWord,
          pageNo: queryParams.pageNo ?? 1,
          rowsPerPage: queryParams.rowsPerPage ?? 15,
        })
      ).map((a) => {
        return {
          ...a,
          ammountInJanuary: timeFormat(a.ammountInJanuary, expressionUnit),
          ammountInFebuary: timeFormat(a.ammountInFebuary, expressionUnit),
          ammountInMarch: timeFormat(a.ammountInMarch, expressionUnit),
          ammountInApril: timeFormat(a.ammountInApril, expressionUnit),
          ammountInMay: timeFormat(a.ammountInMay, expressionUnit),
          ammountInJune: timeFormat(a.ammountInJune, expressionUnit),
          ammountInJuly: timeFormat(a.ammountInJuly, expressionUnit),
          ammountInAugust: timeFormat(a.ammountInAugust, expressionUnit),
          ammountInSeptember: timeFormat(a.ammountInSeptember, expressionUnit),
          ammountInOctober: timeFormat(a.ammountInOctober, expressionUnit),
          ammountInNovember: timeFormat(a.ammountInNovember, expressionUnit),
          ammountInDecember: timeFormat(a.ammountInDecember, expressionUnit),
          ammountInTotal: timeFormat(a.ammountInTotal, expressionUnit),
        };
      });
      const totalCount = await attendancesApi.organizationMonthTotalCount({
        year,
        searchCode,
        searchWord,
      });

      return {
        items: data,
        totalCount,
        archiveOrganizationId: Number(searchWord.split('_')[1]),
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const organizationStatusReducer = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findDayList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.dayList = {
            items: payload.items,
            totalCount: payload.totalCount,
          };
          state.archiveOrganizationId = payload.archiveOrganizationId;
        }
      })
      .addCase(findMonthList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.monthList = {
            items: payload.items,
            totalCount: payload.totalCount,
          };
          state.archiveOrganizationId = payload.archiveOrganizationId;
        }
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default organizationStatusReducer.reducer;

export const organizationActions = {
  dayList: findDayList,
  monthList: findMonthList,
};
