import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipIcon(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Icon chip">
      <Chip label="편집가능" icon="edit" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipIcon;
