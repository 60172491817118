import React, { useEffect, useRef, useState } from 'react';
import Dialog from '../../../../components/dialog/Dialog';
import DialogHeader from '../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../components/dialog/DialogTitle';
import SelectField from '../../../../components/selectField/SelectField';
import { getAvatarPath } from '../../../../groupware-common/utils';
import PostView from '../../../../components/post/PostView';
import Avatar from '../../../../components/avatar/Avatar';
import { dateSimplify } from '../../../../groupware-common/utils/ui';
import AttachmentsItem from '../../../../components/attachments/AttachmentsItem';
import AttachDocumentList from '../../../../components/attachments/AttachDocumentList';
import AttachDocumentItem from '../../../../components/attachments/AttachDocumentItem';
import AttachmentsList from '../../../../components/attachments/AttachmentsList';
import Loading from '../../../../components/loading/Loading';
import EmptyData from '../../../../components/data/EmptyData';
import DialogBody from '../../../../components/dialog/DialogBody';
import { useAppDispatch } from '../../../../groupware-webapp/app/store';
import {
  ChangeHistoryItem,
  documentActions,
} from '../../../stores/approval/document';
import { getApprovalLineDrafter } from './ApprovalLineDialogContainer';
import ApprovalLineFlat from '../components/ApprovalLineFlat';

function ApprovalChangeHistoryCompareDialog(props: {
  data: { documentId: number; id: number; checked: boolean; date: string }[];
  onClose(): void;
}) {
  const dispatch = useAppDispatch();

  const leftScrollbar = useRef<HTMLDivElement>(null);
  const rightScrollbar = useRef<HTMLDivElement>(null);

  const { data, onClose } = props;
  const selectedFieldDate: { value: string; label: string }[] = data.map(
    (x) => {
      return { value: `${x.id}`, label: dateSimplify(x.date, 'full') };
    },
  );
  const [state, setState] = useState<{
    loading: boolean;
    prevItem: string;
    nextItem: string;
    prevItemData: ChangeHistoryItem | undefined;
    nextItemData: ChangeHistoryItem | undefined;
  }>({
    loading: true,
    prevItem: '',
    nextItem: '',
    prevItemData: undefined,
    nextItemData: undefined,
  });

  useEffect(() => {
    async function run() {
      const compareData = data.filter((x) => x.checked === true);
      const [nextItem, prevItem] = compareData;

      dispatch(
        documentActions.fetchHistoryView({
          documentId: prevItem.documentId,
          id: [nextItem.id, prevItem.id],
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined) {
          const payload = result.payload as ChangeHistoryItem[];
          if (payload) {
            const [nextItemData, prevItemData] = payload;
            setState((prevState) => ({
              ...prevState,
              prevItem: `${prevItem.id}`,
              nextItem: `${nextItem.id}`,
              prevItemData,
              nextItemData,
            }));
          }
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      });
    }
    run();
  }, []);

  const handleChangeLeftItem = async (value: string) => {
    const item = data.find((x) => `${x.id}` === value);
    if (item === undefined) return;

    dispatch(
      documentActions.fetchHistoryView({
        documentId: item.documentId,
        id: [item.id],
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const payload = result.payload as ChangeHistoryItem[];
        if (payload) {
          leftScrollbar.current?.scrollTo(0, 0);
          setState((prevState) => ({
            ...prevState,
            prevItem: value,
            prevItemData: payload[0],
          }));
        }
      }
    });
  };

  const handleChangeRightItem = async (value: string) => {
    const item = data.find((x) => `${x.id}` === value);
    if (item === undefined) return;

    dispatch(
      documentActions.fetchHistoryView({
        documentId: item.documentId,
        id: [item.id],
      }),
    ).then((result) => {
      if ((result as { error?: string }).error === undefined) {
        const payload = result.payload as ChangeHistoryItem[];
        if (payload) {
          rightScrollbar.current?.scrollTo(0, 0);
          setState((prevState) => ({
            ...prevState,
            nextItem: value,
            nextItemData: payload[0],
          }));
        }
      }
    });
  };

  const { loading, prevItem, nextItem, prevItemData, nextItemData } = state;
  if (prevItemData === undefined || nextItemData === undefined)
    return (
      <Dialog size="full" overflow="hidden" onClose={onClose}>
        <DialogHeader>
          <DialogTitle>문서 변경이력 비교</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {loading && <Loading />}
          <EmptyData />
        </DialogBody>
      </Dialog>
    );

  const drafter = getApprovalLineDrafter(prevItemData.approvalLine);
  if (drafter === undefined) return <div>오류</div>;

  const { organizationName: draftOrganizationName, employeeName: drafterName } =
    drafter;
  const drafterAvatar = drafter.employeeId ? getAvatarPath(drafter) : '';

  const element = (item: ChangeHistoryItem) => {
    return (
      <PostView type="full">
        <PostView.Head>
          <PostView.Title>{item.subject}</PostView.Title>
          <div className="approval-view-info">
            <Avatar
              className="avatar"
              name={drafterName ?? draftOrganizationName}
              image={drafterAvatar}
              icon={drafterAvatar === '' ? 'sitemap-fill' : undefined}
            />
            <ApprovalLineFlat approvalLine={item.approvalLine} />
            <PostView.Info row>
              <PostView.InfoItem
                title="기안자"
                value={drafterName ?? draftOrganizationName}
              />
              <PostView.InfoItem
                title="기안부서"
                value={draftOrganizationName}
              />
            </PostView.Info>
          </div>
        </PostView.Head>
        <PostView.Body>
          <PostView.Content data={item.contentHTML} />
          {item.attachments.length > 0 && (
            <AttachmentsList
              count={item.attachments.length}
              className="view-attachments"
            >
              {item.attachments.map((x, i) => {
                const key = `i${i}/${x.name}`;
                return (
                  <AttachmentsItem key={key} name={x.name} size={x.size} />
                );
              })}
            </AttachmentsList>
          )}
          {item.documentFile.length > 0 && (
            <AttachDocumentList count={item.documentFile.length}>
              {item.documentFile.map((x, i) => {
                const key = `i${i}/${x.subject}`;
                return (
                  <AttachDocumentItem key={key} no={x.no} subject={x.subject} />
                );
              })}
            </AttachDocumentList>
          )}
        </PostView.Body>
      </PostView>
    );
  };
  return (
    <Dialog size="full" overflow="hidden" onClose={onClose}>
      <DialogHeader>
        <DialogTitle>문서 변경이력 비교</DialogTitle>
      </DialogHeader>
      <div className="ui-compare">
        <div className="split-item">
          <div className="item-head">
            <SelectField
              data={selectedFieldDate}
              value={prevItem}
              onChange={handleChangeLeftItem}
            />
          </div>
          <div className="item-body" ref={leftScrollbar}>
            {element(prevItemData)}
          </div>
        </div>
        <div className="split-item">
          <div className="item-head">
            <SelectField
              data={selectedFieldDate}
              value={nextItem}
              onChange={handleChangeRightItem}
            />
          </div>
          <div className="item-body" ref={rightScrollbar}>
            {element(nextItemData)}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default React.memo(ApprovalChangeHistoryCompareDialog);
