import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import { getQueryParams } from '../../../../../groupware-common/utils';
import {
  dateFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import CalendarListItem from './CalendarListItem';

function CalendarListDialog(props: {
  search: string;
  hash: string;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(props.search);

  const directory = useDirectory();
  const myCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.list,
  );
  const sharedCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.sharedList,
  );
  const subCals = useSelector(
    (state: RootState) => state.calendar.calendars.user.subList,
  );
  const totalCals = [
    ...myCals.filter((a) => a.status && a.checked),
    ...sharedCals.filter((a) => a.status && a.checked),
    ...subCals.filter((a) => a.status && a.checked),
  ];
  const list = useSelector((state: RootState) => state.calendar.schedules.list);
  const subList = useSelector(
    (state: RootState) => state.calendar.schedules.subList,
  );
  const columns: Column<PostListItemType>[] = [
    { name: 'useDays', text: '날짜', type: 'folder', visible: true },
    { name: 'time', text: '시간', type: 'folder', visible: true },
    { name: 'calendar', text: '캘린더명', type: 'folder', visible: true },
    { name: 'subject', text: '제목', type: 'post', visible: true },
    { name: 'user', text: '작성자', type: 'user', visible: true },
  ];
  let totalList = [
    ...list
      .filter((a) => totalCals.some((x) => x.id === a.calendarId))
      .map((a) => ({
        companyId: a.companyId,
        employeeId: a.employeeId,
        calendarId: a.calendarId,
        id: a.id,
        name: a.name,
        isFull: a.isFull,
        startDateTime: a.isFull
          ? a.startDateTime
          : timezoneDate(a.startDateTime),
        endDateTime: a.isFull ? a.endDateTime : timezoneDate(a.endDateTime),
        isSub: false,
      })),
    ...subList
      .filter((a) => totalCals.some((x) => x.id === a.calendarId))
      .map((a) => ({
        companyId: 0,
        employeeId: a.employeeId,
        calendarId: a.calendarId,
        id: a.id,
        name: a.name,
        isFull: a.isFull,
        startDateTime: !a.codeName
          ? a.startDateTime
          : timezoneDate(a.startDateTime),
        endDateTime: !a.codeName ? a.endDateTime : timezoneDate(a.endDateTime),
        isSub: true,
      })),
  ].sort((a, b) => +new Date(a.startDateTime) - +new Date(b.startDateTime));

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  if (props.hash === '#monthly') {
    const setDate = timezoneDate(queryParams.startDate);
    // : new Date();
    const start = new Date(setDate.getFullYear(), setDate.getMonth(), 1);
    const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    totalList = totalList.filter((a) =>
      moment(a.startDateTime).isBetween(start, end, 'days', '[]'),
    );
  }
  return (
    <Dialog onClose={handleDialogClose}>
      <DialogHeader>
        <DialogTitle>일정보기</DialogTitle>
      </DialogHeader>
      <DialogBody height={450}>
        <div>
          <PostList name="list" type="full">
            <PostList.Head>
              {columns.map(({ name, type, text }) => (
                <PostList.Cell
                  key={name}
                  name={text}
                  type={type}
                  align="center"
                />
              ))}
            </PostList.Head>
            <PostList.Body>
              {totalList.length === 0 ? (
                <EmptyData />
              ) : (
                totalList.map((a, i) => {
                  const { startDateTime: start, endDateTime: end } = a;
                  const directoryData = !a.isSub
                    ? getDirectoryData({
                        ...directory,
                        companyId: a.companyId,
                        employeeId: a.employeeId,
                      })
                    : undefined;
                  const calendar = totalCals.find(
                    ({ id }) => id === a.calendarId,
                  );
                  const calendarName = calendar?.name ?? '';
                  const calendarColor = calendar?.color ?? '';

                  let useDays = '';
                  if (moment(start).isSame(end, 'day'))
                    useDays = dateFormat(start, 'MM.DD');
                  else
                    useDays = `${dateFormat(start, 'MM.DD')} ~ ${dateFormat(
                      end,
                      'MM.DD',
                    )}`;
                  const time =
                    (a.isFull && !a.isSub) || (a.employeeId === 0 && a.isSub)
                      ? '종일'
                      : `${dateFormat(start, 'HH:mm')} ~ ${dateFormat(
                          end,
                          'HH:mm',
                        )}`;
                  const item = {
                    useDays,
                    time,
                    calendar: {
                      name: calendarName,
                      color: calendarColor,
                    },
                    subject: a.name,
                    user: directoryData
                      ? {
                          name: directoryData?.employeeName ?? '',
                          organization: directoryData?.organizationName ?? '',
                          job: directoryData?.jobPositionName ?? '',
                          avatar: directoryData?.avatar ?? '',
                        }
                      : undefined,
                  };
                  const key = `/i${i}/${a.id}`;

                  return (
                    <CalendarListItem
                      key={key}
                      align
                      tootip
                      columns={columns}
                      item={item}
                    />
                  );
                })
              )}
            </PostList.Body>
          </PostList>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default CalendarListDialog;
