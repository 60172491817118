import React from 'react';
import EuiSetting from '../../../components/layout/EuiSetting';
import PostView from '../../../components/post/PostView';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../components/button/Button';
import {
  WorkApprovalLineFlat,
  SharePermissionFlat,
  ApprovalLineType,
  SharePermissionType,
} from '../../../groupware-approval/pages/adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';

/** 보존 기간 가져오기. */
function getRetentionPeriodText(retentionPeriod: number): string {
  if (retentionPeriod === 0) return '영구';

  const year = Math.floor(retentionPeriod / 365);
  const month = (retentionPeriod % 365) / 30;

  if (year > 0 && month > 0) return `${year} 년 ${month} 개월`;
  if (year > 0) return `${year} 년`;
  return `${month} 개월`;
}

type Props = {
  paths: string[];
  name: string;
  isEnable: boolean;
  useAttachFile: number;
  retentionPeriod: number;
  formName: string;
  receiptFormName?: string;
  approvalLine: ApprovalLineType; // 결재선
  referencePermission?: SharePermissionType; // 조회권
  viewPermission?: SharePermissionType; // 참조권
  description: string;
  onAction(arg: { code: string; event: React.MouseEvent }): void;
};

function SystemLinkWorkContentView(props: Props): JSX.Element {
  // console.log(`${SystemLinkWorkContentView.name}.render:props`, props);

  const {
    paths,
    name,
    isEnable,
    useAttachFile,
    retentionPeriod,
    formName,
    receiptFormName,
    approvalLine,
    referencePermission,
    viewPermission,
    description,
    onAction,
  } = props;

  return (
    <>
      <EuiSetting.Header title="업무정보">
        <Button
          text="수정"
          iconType
          icon="edit"
          onClick={(event) => onAction({ code: 'work/update', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
            <PostView.Title>{name}</PostView.Title>
          </PostView.Head>
          <PostView.Info>
            <PostView.InfoItem
              title="사용여부"
              value={isEnable ? '사용함' : '사용안함'}
            />
            <PostView.InfoItem
              title="첨부파일 필수"
              value={useAttachFile === 1 ? '사용함' : '사용안함'}
            />
            <PostView.InfoItem
              title="보존기간"
              value={getRetentionPeriodText(retentionPeriod)}
            />
          </PostView.Info>
          <PostView.Category type="text">
            <PostView.CategoryList>
              <PostView.CategoryItem title="양식명">
                <PostView.CategoryValue value={formName} />
              </PostView.CategoryItem>
              {approvalLine.groups.some((a) => a.type === 'receive') &&
                receiptFormName && (
                  <PostView.CategoryItem title="내부결재 양식명">
                    <PostView.CategoryValue value={receiptFormName} />
                  </PostView.CategoryItem>
                )}
              <PostView.CategoryItem title="결재선">
                <WorkApprovalLineFlat approvalLine={approvalLine} />
              </PostView.CategoryItem>
              <PostView.CategoryItem title="참조권">
                {referencePermission && (
                  <SharePermissionFlat
                    items={referencePermission.groups
                      .map(({ items }) => {
                        if (items.length === 0) return [];
                        return items;
                      })
                      .flat()}
                  />
                )}
              </PostView.CategoryItem>
              <PostView.CategoryItem title="조회권">
                {viewPermission && (
                  <SharePermissionFlat
                    items={viewPermission.groups
                      .map(({ items }) => {
                        if (items.length === 0) return [];
                        return items;
                      })
                      .flat()}
                  />
                )}
              </PostView.CategoryItem>
              <PostView.CategoryItem title="설명">
                <PostView.CategoryValue value={description} />
              </PostView.CategoryItem>
            </PostView.CategoryList>
          </PostView.Category>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default React.memo(SystemLinkWorkContentView);
