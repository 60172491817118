import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceSettingBasicContainer(): JSX.Element {
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const basic = useSelector(
    (state: RootState) => state.attendance.preferences.basic,
  );
  const storeAdministrators = useSelector(
    (state: RootState) => state.attendance.preferences.administrators,
  );

  const initialState = () => {
    return {
      preferences: basic,

      administrators: employees
        .filter(
          (a) =>
            a.companyId === principal.companyId &&
            storeAdministrators.some((b) => a.id === b.employeeId),
        )
        .map((a) => {
          const {
            companyId,
            id: employeeId,
            representativeOrganizationId: organizationId,
            avatar,
          } = a;
          const updateAt =
            storeAdministrators.find((b) => b.employeeId === employeeId)
              ?.updateAt ?? '1000-01-01';
          return {
            companyId,
            employeeId,
            organizationId,
            avatar,
            updateAt,
          };
        }),
      administratorsMenuPoint: undefined as
        | { x: number; y: number; width: number; height: number }
        | undefined,

      saveing: false,
      change: false,
      validation: '',
    };
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const { preferences, administrators } = state;
      const save = await dispatch(
        attendancePreferencesActions.updateAttendanceBasic(preferences),
      );
      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prev) => ({ ...prev, saveing: false }));
        return;
      }

      const removeAdministrators = storeAdministrators.filter(
        (a) =>
          administrators.find((x) => a.employeeId === x.employeeId) ===
          undefined,
      );
      const remove = await dispatch(
        attendancePreferencesActions.removeAdministrators(removeAdministrators),
      );
      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prev) => ({
          ...prev,
          saveing: false,
          preferences,
        }));
        return;
      }

      const appendAdministrators = administrators.filter(
        (a) =>
          storeAdministrators.find((x) => a.employeeId === x.employeeId) ===
          undefined,
      );
      const append = await dispatch(
        attendancePreferencesActions.appendAdministrators(appendAdministrators),
      );
      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prev) => ({
          ...prev,
          saveing: false,
          preferences,
        }));
        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        preferences: {
          ...preferences,
          updateAt: (save.payload as { updateAt: string }).updateAt,
        },
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { administratorsMenuPoint } = state;
    if (event !== undefined && administratorsMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  const handleAdministratorsDelete = (employeeId: number) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      administrators: prevState.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
    }));
  };

  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prevState) => {
      const { administrators } = prevState;
      const administratorsMenuPoint = undefined;
      if (administrators.some((a) => a.employeeId === employeeId))
        return { ...prevState, administratorsMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined)
        return { ...prevState, administratorsMenuPoint };

      const updateAt =
        storeAdministrators.find((a) => a.employeeId === employeeId)
          ?.updateAt ?? '';

      return {
        ...prevState,
        change: true,
        administrators: [
          ...administrators,
          {
            companyId,
            employeeId,
            organizationId: employee.representativeOrganizationId,
            avatar: employee.avatar,
            updateAt,
          },
        ],
      };
    });
  };

  const handleSnackbarClose = () => {
    setState((prev) => ({
      ...prev,
      validation: '',
    }));
  };

  const handleSave = () => {
    if (state.administrators.length === 0) {
      const validation = '관리자를 등록하세요.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  const {
    administrators,
    administratorsMenuPoint,
    saveing,
    change,
    validation,
  } = state;
  return (
    <>
      <EuiBody>
        <EuiSetting.Item title="관리자">
          <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
            {administrators.map(
              ({ companyId, employeeId, organizationId, avatar }) => (
                <Chip
                  key={`${companyId}/${employeeId}`}
                  label={getEmployeeName(companyId, employeeId)}
                  etc={getOrganizationName(companyId, organizationId)}
                  avatar={avatar}
                  onDelete={() => handleAdministratorsDelete(employeeId)}
                />
              ),
            )}
            {administratorsMenuPoint && (
              <DirectoryMenuTreeContainer
                point={administratorsMenuPoint}
                typeToFilter="employee"
                onItemClick={handleAdministratorsAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {change && (
              <Button
                text="저장"
                variant="contained"
                loading={saveing}
                onClick={handleSave}
              />
            )}
            {!saveing && change && (
              <Button text="취소" onClick={() => setState(initialState)} />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceSettingBasicContainer;
