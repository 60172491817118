import React, { useEffect, useState } from 'react';
import DocumentFormEditInfoDialog from './DocumentFormEditInfoDialog';
import DocumentFormEditContentDialog from './DocumentFormEditContentDialog';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { ApiError } from '../../../../../groupware-common/types/error';
import documentFormApi from '../../../../apis/document/v1/form';

function DocumentFormEdit(props: {
  role: string; // 'document' | 'board' 등
  folders: { id: number; parentId: number; text: string }[];
  folderId?: number;
  id?: number;
  onClose(): void;
  onSave(
    arg:
      | {
          id?: undefined;
          folderId: number;
          name: string;
          description: string;
          isEnable: boolean;
          content: string;
        }
      | {
          id: number;
          folderId: number;
          name: string;
          description: string;
          isEnable: boolean;
          content: string;
          updateAt: string;
        },
  ): void;
}): JSX.Element {
  // console.log(`DocumentFormEdit.render(props)`, props);

  const dispatch = useAppDispatch();

  const [state, setState] = useState(() => {
    return {
      mode: (props.id === undefined ? 'create' : 'loading') as
        | 'create'
        | 'update'
        | 'loading',
      type: 'info' as 'info' | 'content',
      id: props.id,
      folderId: props.folderId ?? 0,
      name: '',
      content: '',
      isEnable: true,
      description: '',
      updateAt: '',
    };
  });

  useEffect(() => {
    let mount = true;
    async function run(id: number) {
      try {
        const data = await documentFormApi.view(id);
        const view = {
          id: data.id,
          folderId: data.folderId,
          name: data.name,
          content: data.content,
          description: data.description,
          createAt: data.createAt,
          updateAt: data.updateAt,
          isEnable: data.isEnable,
        };
        if (mount) {
          setState((prevState) => {
            return {
              ...prevState,
              mode: 'update',
              folderId: view.folderId,
              name: view.name,
              content: view.content,
              isEnable: view.isEnable,
              description: view.description,
              updateAt: view.updateAt,
            };
          });
        }
      } catch (ex) {
        dispatch(sessionActions.error(ex as ApiError));
      }
    }
    if (props.id !== undefined) run(props.id);
    return () => {
      mount = false;
    };
  }, [dispatch, props.id]);

  const handleSave = (arg: { content: string }) => {
    const { content } = arg;
    const { onSave } = props;
    const { id, folderId, name, isEnable, description, updateAt } = state;
    if (id === undefined)
      onSave({ folderId, name, isEnable, content, description });
    else
      onSave({ id, folderId, name, isEnable, content, description, updateAt });
  };

  const { type } = state;
  if (type === 'content') {
    const { role, onClose } = props;
    const { name, content } = state;
    return (
      <DocumentFormEditContentDialog
        role={role}
        name={name}
        content={content}
        goInfo={() => setState((prevState) => ({ ...prevState, type: 'info' }))}
        onCancel={onClose}
        onSave={handleSave}
      />
    );
  }

  const { folders, onClose } = props;
  const { mode, folderId, name, description, isEnable } = state;
  return (
    <DocumentFormEditInfoDialog
      mode={mode}
      folders={folders}
      folderId={folderId}
      name={name}
      description={description}
      isEnable={isEnable}
      onCancel={onClose}
      onConfirm={(arg) =>
        setState((prevState) => ({ ...prevState, type: 'content', ...arg }))
      }
    />
  );
}

export default DocumentFormEdit;
