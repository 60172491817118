import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { attendancesActions } from '../../../../stores/attendance/attendances';

function AttendanceSubstituteRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      attendancesActions.substituteList({
        search,
        route: { pathname, search },
      }),
    );
  }, [dispatch, props]);
  return null;
}

export default AttendanceSubstituteRoute;
