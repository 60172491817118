import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../../../components/icon/Icon';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import PostSubject from '../../../../../components/post/PostSubject';
import UserInfo from '../../../../../components/user/UserInfo';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';

type Status = 'proceeding' | 'done' | 'return';

type User = {
  name: string;
  organization: string;
  job: string;
  avatar: string;
};

type Calendar = {
  name: string;
  color: string;
};

/**
 * @property columns 컬럼 배열.
 * @property item 항목.
 * @property onCheckedChange 체크 변경 이벤트.
 * @property onClick 클릭 이벤트.
 */
type Props = {
  tootip?: boolean;
  align?: boolean;
  menu?: string;
  selected?: boolean;
  columns: Column<PostListItemType>[];
  item: Record<
    string,
    Status | User | Calendar | boolean | string | number | undefined
  >;
};

function CalendarListItem(dumy: Props): JSX.Element {
  // console.log(`-------${CalendarListItem.name}.render:props`, dumy);

  const display = useSelector((state: RootState) => state.session.display);
  const timerRef = React.useRef<number>();
  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const props = {
    ...dumy,
    selected: dumy.selected || false,
    columns: dumy.columns,
    item: dumy.item,
  };

  const { selected, columns, item } = props;

  return (
    <>
      <PostList.Item selected={selected}>
        {columns.map(({ name, text, type }) => {
          const value = item[name];
          let children: React.ReactNode | null = null;
          if (type === 'post') {
            const subject = value as string;
            children = (
              <PostSubject
                tootip={props.tootip ? subject : undefined}
                title={subject}
              />
            );
          } else if (type === 'user') {
            if (!value)
              children =
                display === 'phone' ? (
                  <Icon className="avatar" icon="calendar" />
                ) : (
                  <></>
                );
            else {
              const { avatar, name: username, organization } = value as User;
              children = (
                <UserInfo
                  avatar={username === '' ? undefined : avatar}
                  name={username === '' ? organization : username}
                  from={username === '' ? undefined : organization}
                  icon={username === '' ? 'sitemap-fill' : undefined}
                />
              );
            }
          } else if (name === 'calendar') {
            const { name: calendarName, color } = value as Calendar;
            const style: React.CSSProperties = {
              border: `5px solid ${color}`,
              borderRadius: '50%',
              marginRight: '8px',
              width: '8px',
              height: '8px',
              display: 'inline-flex',
            };
            const style2: React.CSSProperties = {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            };
            children =
              display === 'phone' ? (
                <span style={{ display: 'flex' }}>
                  <PostList.Value value={text} />
                  <div style={{ ...style2, paddingLeft: '20px' }}>
                    <div style={style} />
                    <span>{calendarName}</span>
                  </div>
                </span>
              ) : (
                <div style={style2}>
                  <div style={style} />
                  <PostList.Value value={calendarName} />
                </div>
              );
          } else if (typeof value === 'string' || typeof value === 'number') {
            children = <PostList.Value value={value} />;
            if ((type === 'folder' || type === 'form') && display === 'phone') {
              children = (
                <span>
                  <PostList.Value value={text} />
                  <span style={{ paddingLeft: '20px' }}>{value}</span>
                </span>
              );
            }
            if (name === 'range' && type === 'period' && display === 'phone') {
              children = (
                <span>
                  <PostList.Value value={text} />
                  <span style={{ paddingLeft: '30px' }}>{value}</span>
                </span>
              );
            }
          }

          let style: React.CSSProperties | undefined;
          let align: 'center' | 'left' | 'right' | undefined;
          if (props.align && type !== 'post') align = 'center';
          else if (type === 'status' || type === 'count') align = 'center';
          return (
            <PostList.Cell key={name} type={type} align={align} style={style}>
              {children}
            </PostList.Cell>
          );
        })}
      </PostList.Item>
    </>
  );
}

export default CalendarListItem;
