import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import EuiSnb from '../../../../../components/layout/EuiSnb';
import MenuDivider from '../../../../../components/menu/MenuDivider';
import MenuItem from '../../../../../components/menu/MenuItem';
import Nav from '../../../../../components/menu/Nav';
import PostWrite from '../../../../../components/post/PostWrite';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import TextField from '../../../../../components/textfield/TextField';
import {
  b62,
  getPathMap,
  getPathParams,
  getQueryParams,
  go,
} from '../../../../../groupware-common/utils';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { labelActions } from '../../../../stores/labels';
import ContactItem from './ContactItem';

function ContactDrawer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const { menu } = getPathParams<{ menu?: string }>('/*/:menu', pathname);
  const pathmap = getPathMap('/*', pathname);
  const queryParams = getQueryParams(search);
  const dispatch = useAppDispatch();
  const categories = useSelector(
    (state: RootState) => state.contact.contacts.category,
  );
  const groupLabel = useSelector(
    (state: RootState) => state.contact.labels.list,
  );
  // 고정 폴더 이름.
  const getDefaultFolderName = (id: string) => {
    const category = categories.find((a) => a.id === id);
    if (!category) return undefined;
    return category.name;
  };

  const initialState = {
    labelEventType: undefined as 'create' | 'update' | 'delete' | undefined,
    labelDeleteType: 'onlyLabel' as 'onlyLabel' | 'allDelete',
    originLabel: undefined as
      | {
          id: number;
          name: string;
          updateAt: string;
        }
      | undefined,
    labelName: '',
  };

  const [state, setState] = useState<{
    labelEventType?: 'create' | 'update' | 'delete'; // 라벨 이벤트 타입
    labelDeleteType: 'onlyLabel' | 'allDelete'; // 라벨 삭제 시 연락처 삭제 유무
    originLabel?: {
      id: number;
      name: string;
      updateAt: string;
    }; // 라벨 수정, 삭제 시에 객체 존재.
    labelName: string; // 라벨 등록, 수정 시 라벨 입력 값
  }>(initialState);
  const [validation, setValidation] = useState('');

  /** 내 연락처 라벨 추가 취소 이벤트. */
  const handleContactCancel = () => {
    setState(initialState);
  };

  /** 연락처 라벨 텍스트필드 추가 이벤트. */
  const handleAddContactEvent = () => {
    setState((prev) => ({
      ...prev,
      labelEventType: 'create',
    }));
  };

  /** 연락처 추가할 이름 변경 이벤트. */
  const handleChangeAddContactName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      labelName: event.target.value,
    }));
  };

  /** 연락처 라벨 수정 대화상자 이벤트. */
  const handleUpdateLabelEvent = (id: number) => {
    const selectedLabel = groupLabel.find((a) => a.id === id);
    if (!selectedLabel) return;
    setState((prev) => ({
      ...prev,
      labelEventType: 'update',
      originLabel: {
        id: selectedLabel.id,
        name: selectedLabel.name,
        updateAt: selectedLabel.updateAt,
      },
      labelName: selectedLabel.name,
    }));
  };

  /** 연락처 라벨 삭제 대화상자 이벤트. */
  const handleDeleteLabelEvent = (id: number) => {
    const selectedLabel = groupLabel.find((a) => a.id === id);
    if (!selectedLabel) return;
    setState((prev) => ({
      ...prev,
      labelEventType: 'delete',
      originLabel: {
        id: selectedLabel.id,
        name: selectedLabel.name,
        updateAt: selectedLabel.updateAt,
      },
    }));
  };

  /** 연락처 존재하는 라벨 삭제 시 연락처 삭제 유무 선택 이벤트. */
  const handleChangeLabelDeleteType = (value: 'onlyLabel' | 'allDelete') => {
    setState((prev) => ({ ...prev, labelDeleteType: value }));
  };

  /** 연락처 라벨 추가(수정) 이벤트. */
  const handleContactAdd = () => {
    if (state.labelEventType === 'create') {
      if (state.labelName.trim() === '') {
        setValidation('라벨명을 입력하세요.');
        return;
      }
      dispatch(labelActions.saveLabel({ name: state.labelName }));
    } else if (state.labelEventType === 'update' && state.originLabel) {
      const name =
        state.labelName.trim() === ''
          ? state.originLabel.name
          : state.labelName;
      dispatch(
        labelActions.updateLabel({
          id: state.originLabel.id,
          name,
          updateAt: state.originLabel.updateAt,
        }),
      );
    }
    handleContactCancel();
  };

  /** 연락처 존재하지 않는 라벨 삭제 이벤트. */
  const handleDeleteLabel = (type?: 'onlyLabel' | 'allDelete') => {
    if (!state.originLabel) return;

    const data =
      b62(menu) === state.originLabel.id
        ? {
            id: state.originLabel.id,
            removeContacts: !type || type === 'allDelete',
            updateAt: state.originLabel.updateAt,
            location: { pathname: '/contacts' },
          }
        : {
            id: state.originLabel.id,
            removeContacts: !type || type === 'allDelete',
            updateAt: state.originLabel.updateAt,
          };
    dispatch(labelActions.deleteLabel(data));
    handleContactCancel();
  };

  const handleClick = (id: number | string | 'create') => {
    if (id === 'create') {
      queryParams.contentMode = 'create';
      go(pathname, queryParams);
      return;
    }
    if (typeof id === 'number') {
      go(`${pathmap}/${b62(id)}`);
      return;
    }
    go(`${pathmap}/${id}`);
  };

  /** 연락처 내보내기 */
  const handleExport = () => {
    dispatch(
      sessionActions.setDialog({
        mode: 'create',
        type: 'drawerExport',
      }),
    );
  };

  /** 연락처 가져오기 */
  const handleImport = () => {
    dispatch(sessionActions.setDialog({ mode: 'create', type: 'import' }));
  };

  const renderDialog = () => {
    const { labelEventType: eventType, originLabel } = state;
    if (eventType === 'create' || eventType === 'update') {
      const title = eventType === 'create' ? '라벨 만들기' : '라벨 이름 변경';
      return (
        <Dialog size="xs">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <PostWrite>
              <PostWrite.Item>
                <TextField
                  placeholder={
                    originLabel ? originLabel.name : '라벨명을 입력하세요.'
                  }
                  value={state.labelName}
                  onChange={handleChangeAddContactName}
                />
              </PostWrite.Item>
            </PostWrite>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="outlined"
              text="취소"
              onClick={() => handleContactCancel()}
            />
            <Button
              noDuplication={validation === ''}
              variant="contained"
              text="저장"
              onClick={() => handleContactAdd()}
            />
          </DialogFooter>
        </Dialog>
      );
    }

    if (eventType === 'delete') {
      const label = groupLabel.find((a) => a.id === state.originLabel?.id);
      if (!label) return null;

      if (label.peopleCount !== 0)
        return (
          <Dialog size="xs" onClose={handleContactCancel}>
            <DialogHeader>
              <DialogTitle>라벨 삭제</DialogTitle>
            </DialogHeader>
            <DialogBody>
              <div style={{ padding: '0 24px' }}>
                <RadioGroup
                  value={state.labelDeleteType}
                  data={[
                    // eslint-disable-next-line prettier/prettier
                    { value: 'onlyLabel', label: '라벨의 연락처를 전체 연락처에 유지하고 라벨만 삭제합니다.' },
                    // eslint-disable-next-line prettier/prettier
                    { value: 'allDelete', label: '라벨과 연락처를 모두 삭제합니다.' }
                  ]}
                  name="delete"
                  onChange={handleChangeLabelDeleteType}
                />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                variant="outlined"
                text="취소"
                onClick={() => handleContactCancel()}
              />
              <Button
                noDuplication
                variant="contained"
                text="삭제"
                onClick={() => handleDeleteLabel(state.labelDeleteType)}
              />
            </DialogFooter>
          </Dialog>
        );
      return (
        <Dialog size="xs" onClose={handleContactCancel}>
          <DialogBody>
            <div className="eui-alert-message">해당 라벨을 삭제하겠습니까?</div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="outlined"
              text="취소"
              onClick={() => handleContactCancel()}
            />
            <Button
              noDuplication
              variant="contained"
              text="삭제"
              onClick={() => handleDeleteLabel()}
            />
          </DialogFooter>
        </Dialog>
      );
    }
    return null;
  };

  return (
    <>
      <EuiSnb.Header>
        <EuiSnb.Title title="주소록" />
        <EuiSnb.Action>
          <Button
            block
            text="연락처 등록"
            variant="contained"
            onClick={() => handleClick('create')}
          />
        </EuiSnb.Action>
      </EuiSnb.Header>
      <EuiSnb.Nav>
        <Nav>
          <MenuItem
            selected={menu === 'importance'}
            icon="star-fill"
            label={getDefaultFolderName('importance')}
            onClick={() => handleClick('importance')}
          />
          <MenuItem
            selected={menu === 'trash'}
            icon="trash-full"
            label={getDefaultFolderName('trash')}
            onClick={() => handleClick('trash')}
          />
        </Nav>
        <Nav title="내 연락처">
          <MenuItem
            icon="address-book"
            selected={!menu || menu === 'all'}
            label={getDefaultFolderName('all')}
            onClick={() => handleClick('all')}
          />
          {groupLabel.map((a) => {
            return (
              <ContactItem
                key={a.id}
                selected={b62(menu) === a.id}
                id={a.id}
                icon="label"
                label={a.name}
                count={a.peopleCount}
                onClick={handleClick}
                onSettingClick={handleUpdateLabelEvent}
                onDeleteClick={handleDeleteLabelEvent}
              />
            );
          })}
          <MenuItem
            icon="plus"
            label="추가"
            onClick={() => handleAddContactEvent()}
          />
        </Nav>
        <MenuDivider />
        <Nav>
          <MenuItem
            icon="import"
            label={getDefaultFolderName('import')}
            onClick={handleImport}
          />
          <MenuItem
            icon="export"
            label={getDefaultFolderName('export')}
            onClick={handleExport}
          />
        </Nav>
      </EuiSnb.Nav>
      {renderDialog()}
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </>
  );
}

export default React.memo(ContactDrawer);
