import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AppDispatchAbort,
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import {
  createQueryString,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { preferencesActions } from '../../../../../groupware-approval/stores/approval/preferences';
import { companyActions } from '../../../../../groupware-directory/stores/directory/company';
import { employeeActions } from '../../../../../groupware-directory/stores/directory/employee';
import {
  attendanceWorkActions,
  attendanceWorkFolderActions,
} from '../../../../stores/attendance/work';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import { attendanceHolidayActions } from '../../../../stores/attendance/holiday';
import { CustomNumbers } from '../../../../../groupware-common/types';

// TODO 근태 양식,업무 개발 후 redux데이터 받아오기 수정해야 함.
function AttendanceComposeRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;

  const principal = useSelector((state: RootState) => state.session.principal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let promise: AppDispatchAbort | undefined;

    const route = { pathname, search, hash };
    const queryParams = getQueryParams(search);

    // 작성인 경우.
    if (queryParams.contentMode === 'create') {
      Promise.all([
        dispatch(preferencesActions.findBasic()),
        dispatch(preferencesActions.findDocumentNo()),
        dispatch(attendanceWorkActions.clear()),
        dispatch(attendanceWorkFolderActions.list()),
        dispatch(companyActions.findView({ id: principal.employeeId })),
        dispatch(employeeActions.findView({ id: principal.employeeId })),
        dispatch(attendancePreferencesActions.attendanceCodeList()),
        dispatch(
          attendanceHolidayActions.yearList({
            year: `${new Date().getFullYear()}`,
            route: {
              ...route,
              search: createQueryString({
                rowsPerPage: CustomNumbers.SMALLINT_MAX,
              }),
            },
          }),
        ),
      ]).then(() => {
        dispatch(attendanceWorkActions.list({ route }));
      });
    }

    return () => {
      if (promise !== undefined) promise.abort();
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default AttendanceComposeRoute;
