import React, { useState } from 'react';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import Button from '../../../../../components/button/Button';
import { IconType } from '../../../../../groupware-common/types/icon';
import Tree from '../../../../../components/tree/Tree';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import EmptyData from '../../../../../components/data/EmptyData';
import MenuItem from '../../../../../components/menu/MenuItem';
import { hangul } from '../../../../../groupware-common/utils';

function ApprovalFormFolder(props: {
  title?: string;
  items: {
    id: number;
    parentId: number;
    text: string;
    strings: string[][];
    icon: IconType;
  }[];
  selectedId?: number;
  onSelected(id: number): void;
  onClick(arg: { code: string; event: React.MouseEvent }): void;
}): JSX.Element {
  // console.log(`${ApprovalFormFolder.name}.render(props)`, props);

  const { title, items, selectedId, onSelected, onClick } = props;

  const [state, setState] = useState({ keyword: '' });

  const handleSearchWork = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      keyword: event.target.value,
    }));
  };

  let treeOrlist = null;
  if (state.keyword === '') {
    treeOrlist = (
      <Tree selectedId={selectedId} items={items} onItemClick={onSelected} />
    );
  } else {
    const strings = hangul.d(state.keyword);
    treeOrlist = items
      .filter((x) => x.strings && hangul.test(x.strings, strings))
      .map((x) => {
        return (
          <MenuItem
            key={x.id}
            label={x.text}
            className="tree-item"
            icon={x.icon}
            selected={x.id === selectedId}
            onClick={() => onSelected(x.id)}
          />
        );
      });

    if (treeOrlist.length === 0)
      treeOrlist = <EmptyData message="검색결과가 없습니다." />;
  }

  return (
    <>
      <EuiSetting.Header title={title || '결재양식'}>
        <Button
          text="양식 등록"
          iconType
          icon="document-plus"
          onClick={(event) => onClick({ code: 'form/create', event })}
        />
        <Button
          text="분류 등록"
          iconType
          icon="folder-plus"
          onClick={(event) => onClick({ code: 'folder/create', event })}
        />
      </EuiSetting.Header>
      <EuiSetting.Toolbar>
        <SimpleSearch keyword={state.keyword} onSearch={handleSearchWork} />
      </EuiSetting.Toolbar>
      <EuiSetting.Content>{treeOrlist}</EuiSetting.Content>
    </>
  );
}

export default React.memo(ApprovalFormFolder);
