import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { calendarsActions } from '../../../../stores/calendar/calendars';
import { userPreferencesActions } from '../../../../stores/calendar/userPreferences';

function CalendarUserPreferencesRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;
  const dispatch = useAppDispatch();
  const { selectedId } = getPathParams<{ selectedId?: number }>(
    '/*/*/:selectedId$base62',
    pathname,
  );

  useEffect(() => {
    let mount = true;

    async function run() {
      // 기본설정
      if (hash === '') await dispatch(userPreferencesActions.basic());
      // 내 캘린더 관리
      if (hash === '#myCalendar') {
        if (selectedId)
          await dispatch(
            calendarsActions.calendarView({ type: 'mine', id: selectedId }),
          );
        else await dispatch(calendarsActions.myCalendarList());
      }
      // 공유 캘린더 관리
      if (hash === '#sharedCalendar')
        await dispatch(calendarsActions.sharedCalendarList());

      const route = { pathname, search, hash, clearError: true };
      dispatch(sessionActions.setRoute(route));
    }
    if (mount) run();

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);
  return null;
}

export default CalendarUserPreferencesRoute;
