import React from 'react';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import CalendarComposeContainer from '../pages/root/calendar/compose/CalendarComposeContainer';
import CalendarContentContainer from '../pages/root/calendar/content/CalendarContentContainer';
import CalendarUserPreferencesContainer from '../pages/root/calendar/preferences/CalendarUserPreferencesContainer';

function CalendarLocateContainer(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey } = props;
  const { menu } = getPathParams<{ menu?: string }>('/*/:menu', pathname);
  const { contentMode } = getQueryParams(search);

  if (contentMode === 'create' || contentMode === 'update')
    return (
      <CalendarComposeContainer
        pathname={pathname}
        search={search}
        hash={hash}
        key={locationKey}
      />
    );

  if (menu === 'preferences')
    return (
      <CalendarUserPreferencesContainer
        pathname={pathname}
        search={search}
        hash={hash}
      />
    );

  return (
    <CalendarContentContainer pathname={pathname} search={search} hash={hash} />
  );
}

export default CalendarLocateContainer;
