import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import PostWrite from '../../../../../components/post/PostWrite';
import MultilingualTextField from '../../../../../components/textfield/MultilingualTextField';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import { CustomNumbers, Language } from '../../../../../groupware-common/types';
import { getText } from '../../../../../groupware-common/utils';
import { RootState as R } from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { LocaleString } from '../../../../../groupware-webapp/stores/i18n';
import DirectoryMenuTreeContainer from '../../../../containers/DirectoryMenuTreeContainer';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../stores/directory';
import { GroupUserArg, GroupView } from '../../../../stores/directory/group';

// TODO 삭제된 유저 보이지 않게 처리
function DirectoryUserGroupView(props: {
  view: GroupView;
  onUpdate(arg: GroupView): void;
  onDelete(arg: { id: number; updateAt: string }): void;
}): JSX.Element {
  const { view, onUpdate, onDelete } = props;

  const directory = useDirectory();
  /** 직위 */
  const jobPositions = useSelector(
    (s: R) => s.directory.jobPosition.list.data.items,
  );
  /** 직책 */
  const jobDuties = useSelector((s: R) => s.directory.jobDuty.list.data.items);
  /** 정렬 타입 */
  const jobClassType = useSelector(
    (s: R) => s.directory.preferences.jobClassType,
  );
  const employees = useSelector((s: R) => s.directory.employee.list.data.items);
  const organizations = useSelector(
    (s: R) => s.directory.organization.list.data.items,
  );

  const initialState = {
    id: view.id,
    names: view.names,
    includers: view.includers.map((x) => ({
      ...x,
      isDeleted: false,
      isDuple: false,
      updateAt: x.updateAt,
    })),
    includerMenuPoint: undefined,
    validation: '',
  };

  const [state, setState] = useState<{
    id?: number;
    names: LocaleString[];
    includers: GroupUserArg[];
    includerMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    validation: string;
  }>(initialState);

  useEffect(() => {
    setState(initialState);
  }, [view]);

  /** 이름 변경 */
  const handleNameChange = (code: Language, value: string) => {
    setState((prev) => ({
      ...prev,
      names: state.names.map((a) =>
        a.code === code ? { ...a, ...{ code, value } } : a,
      ),
    }));
  };

  /** 사용자 디렉터리 트리 열기. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { includerMenuPoint } = state;
    if (event !== undefined && includerMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        includerMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        includerMenuPoint: undefined,
      }));
    }
  };

  /** 사용자 아이템 삭제. */
  const handleIncluderDelete = (id: number, referenceType: string) => {
    setState((prev) => ({
      ...prev,
      includers: prev.includers.filter(
        (a) => !(a.referenceId === id && a.referenceType === referenceType),
      ),
    }));
  };

  /** 사용자 아이템 추가. */
  const handleIncluderAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.includers.some((a) => a.referenceId === employeeId))
          return { ...prev, includerMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, includerMenuPoint: undefined };

        return {
          ...prev,
          includers: [
            ...prev.includers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 'EMPLOYEE',
              isDuple: false,
            },
          ],
        };
      });
    } else if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.includers.some(
            (a) =>
              a.referenceType === 'ORGANIZATION' &&
              a.referenceId === organizationId,
          )
        )
          return { ...prev, includerMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, includerMenuPoint: undefined };

        return {
          ...prev,
          includers: [
            ...prev.includers,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 'ORGANIZATION',
              isDuple: false,
            },
          ],
          change: true,
        };
      });
    }
  };

  const handleUpdatedUser = (
    list: GroupUserArg[],
    item: GroupUserArg,
    isDeleted?: boolean,
    updateAt?: string,
  ) => {
    list.push({
      referenceCompanyId: item.referenceCompanyId,
      referenceId: item.referenceId,
      referenceType: item.referenceType,
      ...(isDeleted && updateAt && { isDeleted, updateAt }),
    });
  };

  /** 그룹 수정 */
  const handleUpdate = () => {
    const includers: GroupUserArg[] = [];

    view.includers.forEach((x) => {
      if (
        state.includers.findIndex(
          (a) =>
            a.referenceId === x.referenceId &&
            a.referenceType === x.referenceType,
        ) < 0
      )
        handleUpdatedUser(includers, x, true, x.updateAt);
    });

    state.includers.forEach((x) => {
      if (
        view.includers.findIndex(
          (a) =>
            a.referenceId === x.referenceId &&
            a.referenceType === x.referenceType,
        ) < 0
      )
        handleUpdatedUser(includers, x);
    });

    onUpdate({
      id: view.id,
      names: state.names,
      updateAt: view.updateAt,
      includers,
    });
  };

  /** 그룹 삭제 */
  const handleDelete = () => {
    onDelete({
      id: view.id,
      updateAt: view.updateAt,
    });
  };

  return (
    <>
      <EuiSetting.Header title="그룹 정보">
        <Button
          text={getText('저장')}
          onClick={handleUpdate}
          variant="contained"
        />
        <Button
          text={getText('삭제')}
          icon="trash-full"
          onClick={handleDelete}
          variant="outlined"
          color="danger"
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostWrite>
          <PostWrite.Item title="그룹명">
            <MultilingualTextField
              value={state.names.map((x) => ({
                language: x.code,
                value: x.value,
              }))}
              onChange={handleNameChange}
            />
          </PostWrite.Item>
          <PostWrite.Item title="사용자">
            <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
              {state.includers
                .map((a) => {
                  let jobClassSeq = 0;
                  if (a.referenceType === 'EMPLOYEE') {
                    const employeeData = getDirectoryData({
                      ...directory,
                      companyId: a.referenceCompanyId,
                      employeeId: a.referenceId,
                    });
                    // 직위 또는 직책 순서로 정렬 순서 결정.
                    jobClassSeq =
                      (jobClassType === 'jobduty'
                        ? jobDuties.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              v.id === employeeData.jobDutyId,
                          )?.seq
                        : jobPositions.find(
                            (v) =>
                              v.companyId === a.referenceCompanyId &&
                              employeeData.jobPositionId === v.id,
                          )?.seq) || CustomNumbers.SMALLINT_MAX;
                  }
                  return {
                    ...a,
                    jobClassSeq,
                  };
                })
                // 회사 → 부서 → 직원 순으로 정렬 후
                // 직위 또는 직책 순번이 낮을수록 밑으로.
                .sort((a, b) => {
                  if (a.referenceType === 'EMPLOYEE') {
                    if (a.jobClassSeq < b.jobClassSeq) return -1;
                    if (a.jobClassSeq > b.jobClassSeq) return 1;
                  }
                  return 0;
                })
                .map(
                  ({
                    referenceCompanyId: companyId,
                    referenceId: id,
                    referenceType,
                  }) => {
                    if (referenceType === 'ORGANIZATION') {
                      // 부서일 경우
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={getOrganizationName(companyId, id)}
                          icon="sitemap-fill"
                          onDelete={() =>
                            handleIncluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    if (referenceType === 'EMPLOYEE') {
                      // 사원일 경우
                      const DirectoryData = getDirectoryData({
                        ...directory,
                        companyId,
                        employeeId: id,
                      });
                      return (
                        <Chip
                          key={`${companyId}/${id}`}
                          label={DirectoryData.employeeName}
                          avatar={DirectoryData.avatar}
                          onDelete={() =>
                            handleIncluderDelete(id, referenceType)
                          }
                        />
                      );
                    }
                    return null;
                  },
                )}
              {state.includerMenuPoint && (
                <DirectoryMenuTreeContainer
                  deduplication
                  point={state.includerMenuPoint}
                  typeToFilter="employee"
                  onItemClick={handleIncluderAppend}
                  onClose={handleDirectoryTreeMenuToggle}
                />
              )}
            </ChipGroup>
          </PostWrite.Item>
        </PostWrite>
      </EuiSetting.Content>
    </>
  );
}

export default DirectoryUserGroupView;
