import React from 'react';
import Flag from '../../../../../components/flag/Flag';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentFlag(): JSX.Element {
  return (
    <>
      <ComponentSampleUI.Content>
        <Flag code="KR" />
        <Flag code="US" />
      </ComponentSampleUI.Content>
    </>
  );
}

export default ComponentFlag;
