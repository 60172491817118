import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { jobDutyActions } from '../../../../stores/directory/jobduty';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function JobDutyRoute({ pathname }: { pathname: string }): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([dispatch(jobDutyActions.findList())]).then(() => {
      dispatch(sessionActions.setRoute({ pathname, clearErrors: true }));
    });
  }, [dispatch, pathname]);

  return null;
}

export default JobDutyRoute;
