import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import EmptyData from '../../../../../components/data/EmptyData';
import PostList, {
  PostListDensity,
  PostListItemType,
  PostListType,
} from '../../../../../components/post/PostList';
import { Column } from '../../../../../groupware-common/ui/type';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { DocumentItem } from '../../../../stores/document/document';
import DocumentContentBodyListItem from './DocumentContentBodyListItem';

export type Item = {
  companyId: number;
  id: number;
  subject: string;
  authorId: number;
  authorName: string;
  organizationName: string;
  avatar?: string;
  comments: number;
  updateAt: string;
  checked: boolean;
  likes: number;
  notice: boolean;

  attachedFiles?: {
    id: number;
    seq: number;
    name: string;
    size: number;
    updateAt: string;
  }[];
};

type Subject = {
  id: number;
  subject: string;
  comments: number;
  notice: boolean;
  new: boolean;
  folderName?: string;
  status: string;
};

type User = {
  companyId: number;
  id: number;
  name: string;
  organization: string;
  avatar?: string;
};

type Props = {
  pathname: string;
  search: string;
  type?: PostListType;
  density?: PostListDensity;
  columns: Column<PostListItemType>[];
  items: DocumentItem[]; //
  selectedId?: string;
  readingPaneMode: string;
  onItemCheckedChange(id: string, checked: boolean): void;
  onItemClick(arg: { id: string; isNotice: boolean }): void;
  onItemImportanceChange?(arg: { id: number }): void;
  onFileDownload?(id: number, fileId: number, name: string): void;
};

function DocumentContentBodyList(props: Props): JSX.Element {
  const {
    pathname,
    search,
    type,
    density,
    columns,
    items,
    selectedId,
    onItemCheckedChange,
    onItemClick,
    onFileDownload,
  } = props;

  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );

  const { pageNo } = getQueryParams(search);

  const directory = useDirectory();
  const principal = useSelector((state: RootState) => state.session.principal);
  const { newPostPeriod } = useSelector(
    (state: RootState) => state.document.preferences.basic,
  );
  const folders = useSelector(
    (state: RootState) => state.document.folders.list,
  );
  /** 첨부파일 다운로드 이벤트. */
  const handleFileDownload = (id: number, fileId: number, name: string) => {
    if (onFileDownload) onFileDownload(id, fileId, name);
  };

  const isPersonalFolder =
    folderId === 'importance' || folderId === 'temp' || folderId === 'checkout';

  const scrollbar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [scrollbar, pageNo]);

  return items.length === 0 ? (
    <EmptyData />
  ) : (
    <>
      <PostList name="document" type={type} density={density}>
        <PostList.Head>
          {columns.map(({ name, type: colunmType, text }) => (
            <PostList.Cell key={name} name={text} type={colunmType} />
          ))}
        </PostList.Head>
        <PostList.Body scrollableNodeProps={scrollbar}>
          {items.map((a, i) => {
            const directoryData =
              a.updaterId === 0
                ? undefined
                : getDirectoryData({
                    ...directory,
                    companyId: principal.companyId,
                    employeeId: a.updaterId,
                  });
            const iconPeriod = new Date(a.updateAt);
            iconPeriod.setDate(iconPeriod.getDate() + newPostPeriod);
            const newIcon = !isPersonalFolder
              ? moment(new Date()).isBetween(
                  a.updateAt,
                  iconPeriod,
                  'day',
                  '[)',
                )
              : false;
            let item: Record<
              string,
              string | number | boolean | Subject | User | undefined
            > = {
              checked: a.checked,
              importance: a.isStarred,
              subject: {
                id: a.id,
                subject: a.subject,
                comments: a.comments,
                new: newIcon,
                notice: a.isNotice,
                status: a.status,
              },
              user: directoryData
                ? {
                    companyId: principal.companyId,
                    id: a.updaterId,
                    name: directoryData.employeeName,
                    organization: directoryData.organizationName,
                    avatar: directoryData.avatar,
                  }
                : {
                    companyId: 0,
                    id: 0,
                    name: '',
                    organization: '',
                    avatar: '',
                  },
              views: a.views,
              likes: a.likes,
              updateAt: a.updateAt,
            };

            if (
              !folderId ||
              folderId === 'importance' ||
              folderId === 'all' ||
              folderId === 'checkout'
            ) {
              const folder = folders.find((x) => x.id === a.folderId);
              item = {
                checked: a.checked,
                importance: a.isStarred,
                user: directoryData
                  ? {
                      companyId: principal.companyId,
                      id: a.updaterId,
                      name: directoryData.employeeName,
                      organization: directoryData.organizationName,
                      avatar: directoryData.avatar,
                    }
                  : {
                      companyId: 0,
                      id: 0,
                      name: '',
                      organization: '',
                      avatar: '',
                    },
                subject: {
                  folderName: folder?.name,
                  id: a.id,
                  subject: a.subject,
                  comments: a.comments,
                  new: newIcon,
                  notice: a.isNotice,
                  status: a.status,
                },
                views: a.views,
                likes: a.likes,
                updateAt: a.updateAt,
              };
            }
            if (folderId === 'temp') {
              item = {
                checked: a.checked,
                subject: {
                  id: a.id,
                  subject: a.subject,
                  comments: a.comments,
                  new: newIcon,
                  notice: a.isNotice,
                  status: a.status,
                },
                views: a.views,
                likes: a.likes,
                updateAt: a.updateAt,
              };
            }
            const key = `/i${i}/${a.listId}/}`;
            return (
              <DocumentContentBodyListItem
                key={key}
                id={a.id}
                columns={columns}
                item={item}
                listId={a.listId}
                isNotice={a.isNotice}
                selected={a.checked || a.listId === selectedId}
                attachedFiles={
                  folderId === 'temp' ? undefined : a.attachedSummaryFiles
                }
                onCheckedChange={onItemCheckedChange}
                onClick={onItemClick}
                readingPaneMode={props.readingPaneMode}
                onImportanceChange={props.onItemImportanceChange}
                onFileDownload={(fileId, name) =>
                  handleFileDownload(a.id, fileId, name)
                }
              />
            );
          })}
        </PostList.Body>
      </PostList>
    </>
  );
}

export default DocumentContentBodyList;
