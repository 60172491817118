import React from 'react';
import Radio from './Radio';
import { IconType } from '../../groupware-common/types/icon';

function RadioGroup(props: {
  data: {
    value: string | boolean | number;
    label: string;
    icon?: IconType;
    color?: string;
    disabled?: boolean;
  }[];
  name: string;
  value: string | boolean | number;
  className?: string;
  legend?: string;
  type?: 'button';
  column?: boolean;
  block?: boolean;
  disabled?: boolean;
  onChange(value: string | boolean | number): void;
}): JSX.Element {
  let classname = 'eui-check-group';
  if (props.className) classname += ` ${props.className}`;
  if (props.column) classname += ' column';
  if (props.block) classname += ' block';
  if (props.type) classname += ` ${props.type}-type`;

  return (
    <fieldset className={classname} aria-disabled={props.disabled}>
      {props.legend && <legend className="legend">{props.legend}</legend>}
      <div className="group">
        {props.data.map((label) => (
          <Radio
            key={label.value.toString()}
            label={label.label}
            name={props.name}
            icon={label.icon}
            color={label.color}
            checked={label.value === props.value}
            onChange={() => props.onChange(label.value)}
            disabled={props.disabled ? true : !!label.disabled}
          />
        ))}
      </div>
    </fieldset>
  );
}

export default RadioGroup;
