import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { serviceMenuActions } from '../../../../stores/module';

function ServiceMenuRoute(props: { pathname: string; search: string }): null {
  const { companyId } = useSelector(
    (state: RootState) => state.session.principal,
  );
  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    const route = { pathname, search, clearErrors: true };
    if (mount) {
      dispatch(serviceMenuActions.findList({ companyId, route }));
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default ServiceMenuRoute;
