import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EmptyData from '../../../../../components/data/EmptyData';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  b62,
  getPathParams,
  getQueryParams,
  go,
  utils,
} from '../../../../../groupware-common/utils';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../../stores/resources';
import ReservationListDialog from './ReservationListDialog';
import ResourceLookupDialog from './ResourceLookupDialog';

function formatSetDate(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

function ResourceWeeklyContentContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;
  const { p1, p2 } = getPathParams('/*/:p1/:p2', pathname);
  const queryParams = getQueryParams(search);

  const display = useSelector((state: RootState) => state.session.display);
  const basic = useSelector(
    (state: RootState) => state.resource.userPreferences.preferences,
  );
  const adminBasic = useSelector(
    (state: RootState) => state.resource.preferences.basic,
  );
  /** 해당 자원 분류의 자원 유형 리스트. */
  let items = useSelector(
    (state: RootState) => state.resource.folder.items.userList,
  ).filter((a) => a.folderId === b62(p1));
  /** 자원유형 클릭 시 */
  if (p1 && p2) items = items.filter((a) => a.id === b62(p2));

  /** 예약 - 자원유형 클릭 시 */
  const reservationList = useSelector(
    (state: RootState) => state.resource.resources.list,
  ).map((a) => ({
    ...a,
    startDateTime: dateTimeFormat(a.startDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
    endDateTime: dateTimeFormat(a.endDateTime, 'yyyy-MM-DD[T]HH:mm:ss'),
  }));

  const startDate = timezoneDate(queryParams.startDate);
  if (basic?.firstDayOfWeek === 0) {
    if (startDate.getDay() !== 0)
      startDate.setDate(startDate.getDate() - startDate.getDay());
  } else if (startDate.getDay() !== 1) {
    if (startDate.getDay() === 0) startDate.setDate(startDate.getDate() - 6);
    else startDate.setDate(startDate.getDate() - (startDate.getDay() - 1));
  }

  const handleClick = (arg: {
    date: string;
    time?: string;
    id: number;
    itemId: number;
    itemName: string;
  }) => {
    const { time, id, itemId, itemName } = arg;
    if (id !== 0) {
      const reservation = reservationList
        .filter((a) => a.id === id)
        .find((a) =>
          moment(arg.date).isBetween(
            new Date(a.startDateTime),
            new Date(a.endDateTime),
            'days',
            '[]',
          ),
        );
      if (!reservation) return;
      const query: {
        itemId: number;
        itemName: string;
        start: string;
        end: string;
      } = {
        itemId,
        itemName,
        start: reservation.startDateTime,
        end: reservation.endDateTime,
      };
      queryParams.dialogMode = 'read';
      queryParams.queryWord = JSON.stringify(query);
      dispatch(
        resourcesActions.reservationView({
          id,
          route: {
            pathname,
            search: getQueryParams(queryParams),
            hash,
          },
        }),
      );
    } else if (time && id === 0) {
      const newTime = Number(time.split(':')[0]);
      const minute = Number(time.split(':')[1]);
      const start = new Date(arg.date);
      const end = new Date(arg.date);
      start.setHours(newTime);
      start.setMinutes(minute);
      end.setHours(newTime);
      end.setMinutes(minute + 30);
      const query: {
        itemId: number;
        itemName: string;
        start: string;
        end: string;
      } = {
        itemId,
        itemName,
        start: dateFormat(start, 'yyyy-MM-DD HH:mm'),
        end: dateFormat(end, 'yyyy-MM-DD HH:mm'),
      };
      queryParams.dialogMode = 'create';
      queryParams.queryWord = JSON.stringify(query);
      dispatch(sessionActions.search(getQueryParams(queryParams)));
    }
  };

  const handleDateClick = (action: string) => {
    const newDate = timezoneDate(queryParams.startDate);
    if (action === 'pprev') newDate.setDate(newDate.getDate() - 28);
    else if (action === 'prev') newDate.setDate(newDate.getDate() - 7);
    else if (action === 'next') newDate.setDate(newDate.getDate() + 7);
    else newDate.setDate(newDate.getDate() + 28);
    queryParams.startDate = dateFormat(initialDate(newDate), 'yyyy-MM-DD');
    go(pathname, queryParams, hash);
  };

  const handleTodayClick = () => {
    delete queryParams.startDate;
    go(pathname, queryParams, hash);
  };

  const handleTableClick = () => {
    dispatch(sessionActions.setDialog({ type: 'reservationList' }));
  };

  /** 예약 저장 */
  const handleSave = (arg: {
    itemId: number;
    employeeId: number;
    name: string;
    remark: string;
    startDateTime: string;
    endDateTime: string;
  }) => {
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    dispatch(
      resourcesActions.saveReservation({
        data: { ...arg, sharers: [], alarms: [] },
        location,
      }),
    );
  };

  /** 예약 수정 */
  const handleUpdate = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      itemId: number;
      employeeId: number;
      name: string;
      remark?: string;
      startDateTime?: string;
      endDateTime?: string;
      lookupStartDateTime?: string;
      lookupUpdateAt?: string;
      updateAt?: string;
    };
  }) => {
    const { isRepeat, repeatType } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname: props.pathname,
        search: getQueryParams(queryParams),
        hash: props.hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });
    // 반복 예약 수정
    if (isRepeat) {
      dispatch(
        resourcesActions.updateRepeatReservation({
          type: repeatType,
          data: arg.data,
          location,
        }),
      );
    }
    // 일반 예약 수정
    else {
      const data = {
        ...arg.data,
        updateAt: arg.data.updateAt ?? '',
      };
      dispatch(resourcesActions.updateReservation({ data, location }));
    }
  };

  /** 예약 삭제. */
  const handleDelete = (arg: {
    isRepeat: boolean;
    repeatType: string;
    data: {
      id: number;
      startDateTime?: string;
      updateAt: string;
    };
  }) => {
    const { isRepeat, repeatType, data } = arg;
    delete queryParams.queryWord;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search: getQueryParams(queryParams),
        hash,
        mode: 'replace',
        option: 'CLEAR_DIALOG',
      },
    });

    if (isRepeat) {
      dispatch(
        resourcesActions.deleteRepeatReservation({
          data: {
            type: repeatType,
            ...data,
            startDateTime: data.startDateTime ?? '',
          },
          location,
        }),
      );
    } else {
      dispatch(
        resourcesActions.deleteReservation({
          id: data.id,
          updateAt: data.updateAt,
          location,
        }),
      );
    }
  };

  const renderDialog = () => {
    const { dialogMode, dialogType } = queryParams;
    if (dialogMode === 'read') {
      return (
        <ResourceLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      );
    }
    if (dialogMode === 'create') {
      return (
        <ResourceLookupDialog
          pathname={pathname}
          search={search}
          hash={hash}
          onSave={handleSave}
        />
      );
    }
    if (dialogType === 'reservationList') {
      return (
        <ReservationListDialog
          type="weekly"
          list={reservationList}
          startDate={dateFormat(startDate, 'yyyy-MM-DD HH:mm')}
        />
      );
    }

    return null;
  };

  const dateRange: {
    value: string;
    label: string;
  }[] = [];
  for (let i = 0; i < 7; i += 1) {
    const range = new Date(startDate);
    range.setDate(range.getDate() + i);
    dateRange.push({
      value: dateFormat(range, 'yyyy-MM-DD'),
      label: dateFormat(range, 'yyyy-MM-DD (dd)'),
    });
  }
  const time: { value: string; label: string }[] = [];
  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    time.push({ value: timeValue, label: timeValue });
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }

  const endToday = new Date(startDate);
  endToday.setDate(startDate.getDate() + 6);

  if (items.length === 0) return <EmptyData />;

  return (
    <EuiBody>
      <div style={{ minWidth: '700px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px',
          }}
        >
          <Button text="<<" onClick={() => handleDateClick('pprev')} />
          <Button text="<" onClick={() => handleDateClick('prev')} />
          <div
            className="eui-form-field"
            style={{
              margin: '0 5px',
              background: 'none',
              width: 'auto',
            }}
          >
            <h3 className="control">
              {`${dateFormat(startDate, 'yyyy.MM.DD')} - ${dateFormat(
                endToday,
                'yyyy.MM.DD',
              )}`}
            </h3>
          </div>
          <Button text=">" onClick={() => handleDateClick('next')} />
          <Button text=">>" onClick={() => handleDateClick('nnext')} />

          <div style={{ marginLeft: '10px' }}>
            <Button text="오늘" variant="outlined" onClick={handleTodayClick} />
            <Button
              text="표로 보기"
              variant="outlined"
              onClick={handleTableClick}
            />
          </div>
        </div>

        <div className="resource-content">
          {dateRange.map((d, index) => {
            const key = `${index}_${d.value}`;
            return (
              <div key={key}>
                <div className="resource-day-content">
                  <div
                    className="title"
                    style={{
                      maxWidth: display !== 'phone' ? '200px' : '150px',
                      width: '100%',
                    }}
                  >
                    {d.label}
                  </div>
                  <div className="header">
                    {time.map((a, i) => {
                      const timeKey = `${i}_${a.label}`;
                      return (
                        <div key={timeKey} className="timeline">
                          {a.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {items.map((item) => {
                  const endIndex = bottom.findIndex(
                    (a) => a.value === item.availableToTime,
                  );
                  const itemId = item.id; // 유형 아이디.
                  let reservation = reservationList; // 예약 리스트.
                  const title = item.name;
                  if (!p2)
                    reservation = reservationList.filter(
                      (a) => a.itemId === item.id,
                    );
                  const child: {
                    date: string;
                    width: number;
                    left: number;
                    listId: number;
                    isApproval: number;
                  }[] = [];
                  let startBlock = <></>;
                  let endBlock = <></>;
                  const today = timezoneDate();
                  formatSetDate(today);
                  const selectedDay = new Date(d.value);
                  formatSetDate(selectedDay);
                  if (today <= selectedDay) {
                    // 오늘이면서 지난 날짜 예약 불가능일 경우 - 현재보다 지난 시간은 예약 불가능.
                    if (
                      moment(today).isSame(selectedDay, 'days') &&
                      !adminBasic.isAvailablePastReservation
                    ) {
                      const availableStart = item.availableFromTime.split(':');
                      const minute = new Date().getMinutes() < 30 ? '00' : '30';
                      const hours = timezoneDate()
                        .getHours()
                        .toString()
                        .padStart(2, '0');
                      if (item.availableFromTime !== '00:00') {
                        let length = 0;
                        // 현재 시간이 사용가능 시작시간을 아직 안지난 경우
                        if (
                          timezoneDate().getHours() * 60 +
                            timezoneDate().getMinutes() <=
                          Number(availableStart[0]) * 60 +
                            Number(availableStart[1])
                        ) {
                          length = bottom.findIndex(
                            (a) => a.value === item.availableFromTime,
                          );
                        }
                        // 현재 시간이 사용가능 시작시간을 지난 경우
                        else {
                          length = bottom.findIndex(
                            (a) => a.value === `${hours}:${minute}`,
                          );
                        }
                        // 현재 시간이 사용가능 종료시간을 지난 경우
                        if (
                          item.availableToTime !== '00:00' &&
                          index > endIndex
                        )
                          length = endIndex;
                        const width = 2.083 * length;
                        startBlock = (
                          <div
                            style={{ width: `${width}%`, left: `0%` }}
                            className="block"
                          />
                        );
                      } else {
                        const length = bottom.findIndex(
                          (a) => a.value === `${hours}:${minute}`,
                        );
                        const width = 2.083 * length;
                        startBlock = (
                          <div
                            style={{ width: `${width}%`, left: `0%` }}
                            className="block"
                          />
                        );
                      }
                    }
                    // 미래일 경우 또는 지난 날짜 예약 가능일 경우
                    else if (item.availableFromTime !== '00:00') {
                      const length = bottom.findIndex(
                        (a) => a.value === item.availableFromTime,
                      );
                      const width = 2.083 * length;
                      startBlock = (
                        <div
                          key={`start_${item.id}`}
                          style={{ width: `${width}%`, left: `0%` }}
                          className="block"
                        />
                      );
                    }

                    if (item.availableToTime !== '00:00') {
                      const length = bottom.findIndex(
                        (a) => a.value === item.availableToTime,
                      );
                      const width = 2.084 * (bottom.length - length);
                      const left = 2.083 * length;
                      endBlock = (
                        <div
                          style={{ width: `${width}%`, left: `${left}%` }}
                          className="block"
                        />
                      );
                    }
                  }
                  // 과거이면서 지난 날짜 예약 가능일 경우.
                  else if (adminBasic.isAvailablePastReservation) {
                    if (item.availableFromTime !== '00:00') {
                      const length = bottom.findIndex(
                        (a) => a.value === item.availableFromTime,
                      );
                      startBlock = (
                        <div
                          style={{ width: `${2.083 * length}%`, left: `0%` }}
                          className="block"
                        />
                      );
                    }
                    if (item.availableToTime !== '00:00') {
                      const length = bottom.findIndex(
                        (a) => a.value === item.availableToTime,
                      );
                      const width = 2.084 * (bottom.length - length);
                      const left = 2.083 * length;
                      endBlock = (
                        <div
                          style={{ width: `${width}%`, left: `${left}%` }}
                          className="block"
                        />
                      );
                    }
                  }
                  // 과거이면서 지난 날짜 예약 불가능인 경우.
                  else {
                    startBlock = (
                      <div
                        style={{
                          width: `${2.083 * bottom.length}%`,
                          left: `0%`,
                        }}
                        className="block"
                      />
                    );
                  }

                  return (
                    <div
                      key={item.id}
                      className="resource-day-content"
                      style={{ height: '30px', lineHeight: '30px' }}
                    >
                      <div
                        className="title"
                        style={{
                          minWidth: display !== 'phone' ? '200px' : '150px',
                          fontWeight: 'normal',
                          maxWidth: display !== 'phone' ? '200px' : '150px',
                        }}
                      >
                        {title}
                      </div>

                      <div className="bottom">
                        {bottom.map((a, i) => {
                          reservation.forEach((z) => {
                            const start = new Date(z.startDateTime);
                            const end = new Date(z.endDateTime);
                            const formatStart = dateFormat(start, 'yyyy-MM-DD');
                            const formatEnd = dateFormat(end, 'yyyy-MM-DD');
                            const formatToday = d.value;
                            // 예약일이 하루이면서 조회 날짜와 동일한 경우.
                            // 예약일이 이틀 이상이면서 예약 시작일이 조회 날짜와 동일한 경우.
                            if (
                              formatStart === formatToday &&
                              start.getHours() ===
                                Number(a.value.split(':')[0]) &&
                              start.getMinutes() ===
                                Number(a.value.split(':')[1])
                            ) {
                              let length = bottom.length - i;
                              if (formatEnd === formatToday) {
                                const days =
                                  (end.getDate() - start.getDate()) * 24 * 60;
                                const hours =
                                  (end.getHours() - start.getHours()) * 60;
                                const minutes =
                                  Math.round(
                                    (end.getMinutes() - start.getMinutes()) /
                                      10,
                                  ) * 10;
                                length = (days + hours + minutes) / 30;
                              }
                              const width = 2.084 * length;
                              const left = 2.083 * i;
                              child.push({
                                date: d.value,
                                width,
                                left,
                                listId: Number(z.id),
                                isApproval: z.isApproval,
                              });
                            }
                            // 예약일이 삼일 이상이면서 조회 날짜가 예약 시작, 종료일 중간인 경우.
                            if (
                              moment(d.value).isBetween(start, end, 'days') &&
                              a.value === '00:00'
                            ) {
                              const width = 2.084 * bottom.length;
                              child.push({
                                date: d.value,
                                width,
                                left: 0,
                                listId: Number(z.id),
                                isApproval: z.isApproval,
                              });
                            }
                            // 예약일이 이틀 이상이면서 예약 종료일이 조회날짜인 경우.
                            if (
                              formatEnd === formatToday &&
                              formatEnd !== formatStart &&
                              a.value === '00:00'
                            ) {
                              const hours = end.getHours() * 60;
                              const minutes = end.getMinutes();
                              const length = (hours + minutes) / 30;
                              const width = 2.084 * length;
                              child.push({
                                date: d.value,
                                width,
                                left: 0,
                                listId: Number(z.id),
                                isApproval: z.isApproval,
                              });
                            }
                          });

                          const bottomKey = `${i}_${a.label}`;
                          return (
                            <div
                              key={bottomKey}
                              className="timeline"
                              onClick={() =>
                                handleClick({
                                  date: d.value,
                                  time: a.value,
                                  id: 0,
                                  itemId,
                                  itemName: title,
                                })
                              }
                            />
                          );
                        })}
                        {startBlock}
                        {endBlock}
                        {child.length > 0 &&
                          child.map((a) => {
                            return (
                              <div
                                key={a.listId}
                                style={{
                                  width: `${a.width}%`,
                                  left: `${a.left}%`,
                                  borderLeft:
                                    a.left === 0 ? '0' : '1px solid #ddd',
                                }}
                                // eslint-disable-next-line prettier/prettier
                                className={a.isApproval === 1 ? 'selected' : 'selected approval'}
                                onClick={() =>
                                  handleClick({
                                    date: a.date,
                                    id: a.listId,
                                    itemId,
                                    itemName: title,
                                  })
                                }
                              />
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {renderDialog()}
      </div>
    </EuiBody>
  );
}

export default ResourceWeeklyContentContainer;
