import React from 'react';
import ReactDOM from 'react-dom';

const portals = document.getElementById('portals');

interface State {
  x: number;
  y: number;
  rect: {
    x: number;
    y: number;
    bottom: number;
    left: number;
    right: number;
    top: number;
    height: number;
    width: number;
  };
}

interface Props {
  overflow?: string;
  containerClassname?: string; // menu-container 추가 classname
  point: { x: number; y: number; width?: number; height?: number };
  width?: number;
  minWidth?: number;
  height?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;

  onClose(): void;
}

class Menu extends React.Component<Props, State> {
  element: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      rect: {
        x: 0,
        y: 0,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        height: 0,
        width: 0,
      },
    };

    this.element = document.createElement('div');

    let className = 'eui-context-menu';
    if (props.className) className += ` ${props.className}`;
    if (props.size) className += ` ${props.size}`;

    this.element.setAttribute('class', className);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(): void {
    this.element.setAttribute('data-state', 'enter');
    portals?.appendChild(this.element);
    document.addEventListener('mousedown', this.handleOutsideClick);

    // 모바일이 아닐 때만 스크롤 이벤트 추가
    if (document.documentElement.getAttribute('data-device') === 'pc')
      window.addEventListener('scroll', this.handleWindowScroll, true);

    const { point } = this.props;
    const rect = this.element.getBoundingClientRect();

    const x =
      point.x + rect.width > document.documentElement.clientWidth ? 1 : 0;
    const y =
      point.y + rect.height + (point.height || 0) >
      document.documentElement.clientHeight
        ? 1
        : 0;

    this.setState((prevState) => {
      return { ...prevState, x, y, rect };
    });
  }

  componentWillUnmount(): void {
    portals?.removeChild(this.element);
    document.removeEventListener('mousedown', this.handleOutsideClick);
    window.removeEventListener('scroll', this.handleWindowScroll, true);
  }

  handleOutsideClick(event: MouseEvent): void {
    const { point, onClose } = this.props;

    if (this.element.contains(event.target as HTMLElement)) {
      // console.log('element?.current?.contains(event.currentTarget)', true);
    } else if (
      (event.target as HTMLElement).classList.contains('eui-dim-cover')
    ) {
      // console.log('classList.contains', true);
      onClose();
    } else if (
      point.width !== undefined &&
      point.width > 0 &&
      point.height !== undefined &&
      point.height > 0 &&
      point.x <= event.clientX &&
      point.x + point.width >= event.clientX &&
      point.y <= event.clientY &&
      point.y + point.height >= event.clientY
    ) {
      // console.log('rect in point', true);
    } else {
      // console.log('dispatch(menuDestroy())');
      onClose();
    }
  }

  handleWindowScroll(event: Event): void {
    /** 스크롤 했을 때 오른쪽 클릭한 컴포넌트와 현재 컴포넌트가 다를 경우 창을 닫음. */
    if (!this.element.contains(event.target as HTMLElement)) {
      this.props.onClose();
    }
  }

  handleAnimationEnd(): void {
    if (this.element.getAttribute('data-state') === 'enter') {
      this.element.removeAttribute('data-state');
    } else if (this.element.getAttribute('data-state') === 'leave') {
      this.props.onClose();
      this.element.removeAttribute('data-state');
    }
  }

  handleClose(): void {
    this.element.setAttribute('data-state', 'leave');
  }

  render(): React.ReactPortal {
    const { point, children } = this.props;
    const { x, y, rect } = this.state;
    const documentWidth = document.documentElement.clientWidth;
    const documentHeight = document.documentElement.clientHeight;
    const clientHeight = rect.height;

    let left;
    let right;
    let top;
    let bottom;

    if (x === 1) {
      left = 'auto;';
      right = `${documentWidth - point.x - (point.width || 0)}px;`;
    } else {
      left = `${point.x}px;`;
      right = 'auto;';
    }

    if (y === 1) {
      if (point.y > clientHeight) {
        top = 'auto;';
        bottom = `${documentHeight - point.y}px;`;
      } else if (documentHeight - point.y + (point.height || 0) <= 200) {
        top = '0;';
        bottom = `${documentHeight - point.y}px;`;
      } else {
        top = `${point.y + (point.height || 0)}px;`;
        bottom = '0;';
      }
    } else {
      top = `${point.y + (point.height || 0)}px;`;
      bottom = 'auto;';
    }

    let style = `left: ${left} right: ${right} top: ${top} bottom: ${bottom}`;
    if (this.props.width) style += ` width: ${this.props.width}px;`;
    if (this.props.minWidth) style += ` min-width: ${this.props.minWidth}px;`;
    if (this.props.height) style += ` height: ${this.props.height}px;`;
    if (this.props.overflow) style += ` ${this.props.overflow}`;

    this.element.setAttribute('style', style);

    let containerClassname = 'menu-container';
    if (this.props.containerClassname)
      containerClassname += ` ${this.props.containerClassname}`;
    return ReactDOM.createPortal(
      <>
        <div className="menu-overlay" onClick={this.handleClose} />
        <div
          className={containerClassname}
          onAnimationEnd={this.handleAnimationEnd}
        >
          {children}
        </div>
      </>,
      this.element,
    );
  }
}

export default Menu;
