import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function ApprovalArchiveSettingsRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`ApprovalArchiveSettingsRoute(props)`, props);

  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    const route = { pathname, search, hash, clearErrors: true };
    if (mount) dispatch(sessionActions.setRoute(route));

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default ApprovalArchiveSettingsRoute;
