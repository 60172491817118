/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg } from '../../groupware-common/types';
import { b62, getPathMap } from '../../groupware-common/utils';
import { appError } from '../../groupware-webapp/stores/common/utils';
import folderApi, { itemApi } from '../apis/resource/v1/folder';

const name = 'resource/folders';

/** 자원 아이템 폴더 객체 타입 */
export interface ResourceFolderItem {
  companyId: number;
  id: number;
  parentId: number;
  seq: number;
  name: string;
  description: string;
  updateAt: string;
}

/** 자원 아이템 리스트 조회. */
export interface ResourceListItem {
  companyId: number; // 회사 아이디.
  id: number; // 자원 아이템 아이디.
  folderId: number; // 폴더 아이디.
  name: string; // 자원명.
  seq: number; // 순번.
  useApprove: boolean; // 승인 사용 여부. (true: 승인제, false: 예약제)
  useRental: boolean; // 반납 사용 여부.
  isAdmin?: boolean; // 담당자 여부.
  useTimeAvailable: boolean; // 사용 시간 제한 여부.
  availableFromTime: string; // 사용 제한 시작 시간.
  availableToTime: string; // 사용 제한 종료 시간.
  updateAt: string; // 수정 날짜.
}

/** 자원 아이템 단일 조회. */
export interface ResourceItem {
  companyId: number; // 회사 아이디.
  id: number; // 자원 아이템 아이디.
  folderId: number; // 폴더 아이디.
  name: string; // 자원명.
  status: boolean; // 사용여부.
  seq: number; // 순번.
  useApprove: boolean; // 승인 사용 여부. (true: 승인제, false: 예약제)
  useRental: boolean; // 대여/반납 사용 여부.
  useTimeAvailable: boolean; // 사용 시간 제한 여부.
  description: string | null; // 설명.
  availableFromTime: string; // 사용 가능 시작 시간.
  availableToTime: string; // 사용 가능 종료 시간.
  managers: {
    companyId: number;
    id: number; // 자원 아이템 아이디.
    referenceCompanyId: number; // 사용자 회사 아이디.
    referenceId: number; // 사용자 아이디.
    referenceType: number; // 참조 유형 (1: 회사, 2: 조직, 3: 직원)
    updateAt: string;
  }[]; // 담당자.
  users: {
    companyId: number;
    id: number; // 자원 아이템 아이디.
    referenceCompanyId: number; // 사용자 회사 아이디.
    referenceId: number; // 사용자 아이디.
    referenceType: number; // 참조 유형 (1: 회사, 2: 조직, 3: 직원)
    updateAt: string;
  }[]; // 사용자.
  exceptioners: {
    companyId: number;
    id: number; // 자원 아이템 아이디.
    referenceCompanyId: number; // 사용자 회사 아이디.
    referenceId: number; // 사용자 아이디.
    referenceType: number; // 참조 유형 (1: 회사, 2: 조직, 3: 직원)
    updateAt: string;
  }[]; // 예외자.
  imagePath: string; // 자원 이미지 경로
  updateAt: string; // 수정 날짜.
}

interface State {
  list: ResourceFolderItem[];
  items: {
    userList: ResourceListItem[]; // 사용자권한 아이템 리스트
    list: ResourceListItem[]; // 모든 아이템 리스트
    view: ResourceItem | undefined;
  };
}

const initialState: State = {
  list: [],
  items: {
    userList: [],
    list: [],
    view: undefined,
  },
};

/** 자원 아이템 폴더 리스트 조회. */
const findFolderList = createAsyncThunk(
  `${name}/findFolderList`,
  async (
    arg: {
      parentId?: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await folderApi.list(arg.parentId);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 폴더 등록. */
const createFolder = createAsyncThunk(
  `${name}/createFolder`,
  async (
    arg: {
      parentId: number;
      name: string;
      description: string;
    } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { relayLocation } = arg;
      const data = await folderApi.create({
        parentId: arg.parentId,
        name: arg.name,
        description: arg.description,
      });
      if (relayLocation !== undefined) {
        const pathname = `${relayLocation.pathname}/${b62(data.id)}`;
        await dispatch(
          findFolderList({
            route: {
              ...relayLocation,
              pathname,
            },
          }),
        );
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 폴더 수정. */
const updateFolder = createAsyncThunk(
  `${name}/updateFolder`,
  async (
    arg: {
      data: {
        id: number;
        parentId: number;
        name: string;
        description: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data, route } = arg;
      await folderApi.update(data);
      if (route !== undefined) await dispatch(findFolderList({}));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 폴더 삭제. */
const deleteFolder = createAsyncThunk(
  `${name}/deleteFolder`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      await folderApi.delete({ id, updateAt });
      dispatch(findFolderList({}));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 폴더 순서 변경. */
const sortFolder = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data, route } = arg;
      await folderApi.sort(data);
      if (route !== undefined) await dispatch(findFolderList({}));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 리스트 조회. */
const findItemList = createAsyncThunk(
  `${name}/findItemList`,
  async (
    arg: {
      folderId?: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = (await itemApi.list(arg.folderId))
        .map((a) => ({
          ...a,
          availableFromTime: a.useTimeAvailable ? a.availableFromTime : '00:00',
          availableToTime: a.useTimeAvailable ? a.availableToTime : '00:00',
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 사용자 리스트 조회. */
const findUserItemList = createAsyncThunk(
  `${name}/findUserItemList`,
  async (
    arg: {
      organizationId: number;
      employeeId: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = (
        await itemApi.userList({
          organizationId: arg.organizationId,
          employeeId: arg.employeeId,
        })
      )
        .map((a) => ({
          ...a,
          availableFromTime: a.useTimeAvailable ? a.availableFromTime : '00:00',
          availableToTime: a.useTimeAvailable ? a.availableToTime : '00:00',
        }))
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 뷰 조회. */
const findItemView = createAsyncThunk(
  `${name}/findItemView`,
  async (
    arg: {
      id: number;
      updateAt?: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const data = await itemApi.view({
        id: arg.id,
        updateAt: arg.updateAt,
      });
      return {
        ...data,
        imagePath: data.imagePath
          ? `/api/resource/static${data.imagePath}`
          : '', // 사진 정보 이미지 경로.
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 등록. */
const createItem = createAsyncThunk(
  `${name}/createItem`,
  async (
    arg: {
      data: {
        folderId: number;
        name: string;
        status: boolean;
        useApprove: boolean;
        useRental: boolean;
        useTimeAvailable: boolean;
        availableFromTime: string;
        availableToTime: string;
        description: string;
        managers: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
        }[];
        users: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
        }[];
        exceptioners: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
        }[];
        image?: string;
      };
    } & {
      relayLocation?: {
        pathname: string;
        search?: string;
        hash?: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { relayLocation } = arg;
      const data = await itemApi.create(arg.data);
      const list = await itemApi.list();
      if (relayLocation !== undefined) {
        const pathname = `${getPathMap(
          '/*/*/*/:id',
          relayLocation.pathname,
        )}/${b62(data.id)}`;
        await dispatch(
          findItemView({
            id: data.id,
            route: {
              ...relayLocation,
              pathname,
            },
          }),
        );
      }
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 수정. */
const updateItem = createAsyncThunk(
  `${name}/updateItem`,
  async (
    arg: {
      data: {
        companyId: number;
        id: number;
        folderId: number;
        name?: string;
        status?: boolean;
        seq?: number;
        useApprove?: boolean;
        useRental?: boolean;
        useTimeAvailable?: boolean;
        description?: string;
        availableFromTime?: string;
        availableToTime?: string;
        managers?: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
          lookupDeleteAt?: string;
        }[];
        users?: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
          lookupDeleteAt?: string;
        }[];
        exceptioners?: {
          referenceCompanyId: number;
          referenceId: number;
          referenceType: number;
          lookupDeleteAt?: string;
        }[];
        image?: string;
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await itemApi.update(arg.data);
      if (arg.route !== undefined) {
        dispatch(findItemList({}));
        await dispatch(findItemView({ id: arg.data.id }));
      }
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 삭제. */
const deleteItem = createAsyncThunk(
  `${name}/deleteItem`,
  async (
    arg: {
      id: number;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { id, updateAt } = arg;
      const data = await itemApi.delete({ id, updateAt });
      dispatch(findItemList({}));
      return data;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 자원 아이템 순서 변경. */
const sortItem = createAsyncThunk(
  `${name}/sortItem`,
  async (
    arg: {
      data: {
        id: number;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data, route } = arg;
      await itemApi.sort(data);
      if (route !== undefined) await dispatch(findItemList({}));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const folderReducer = createSlice({
  name,
  initialState,
  reducers: {
    itemClear(state) {
      state.items.view = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.list = payload;
      })
      .addCase(findItemList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.items.list = payload;
      })
      .addCase(findUserItemList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.items.userList = payload;
      })
      .addCase(findItemView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.items.view = payload;
      })
      .addCase(createItem.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.items.list = payload;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.items.view = undefined;
      });
  },
});

export default folderReducer.reducer;

export const itemActions = {
  list: findItemList,
  userList: findUserItemList,
  view: findItemView,
  create: createItem,
  update: updateItem,
  delete: deleteItem,
  sort: sortItem,
};

export const folderActions = {
  list: findFolderList,
  create: createFolder,
  update: updateFolder,
  delete: deleteFolder,
  sort: sortFolder,
};
