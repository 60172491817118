import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { directoryPreferencesActions } from '../../../../stores/directory/preferences';

function DirectoryPreferencesRoute({ pathname }: { pathname: string }): null {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;

    Promise.all([
      dispatch(directoryPreferencesActions.findAdministrators()),
      dispatch(directoryPreferencesActions.find()),
    ]).then(() => {
      const route = { pathname, clearErrors: true };
      if (mount) dispatch(sessionActions.setRoute(route));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname]);

  return null;
}

export default DirectoryPreferencesRoute;
