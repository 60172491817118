import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { PermissionType } from '../../../../stores/calendar/calendars';

export type AuthorityType =
  | 'COMPANY'
  | 'ORGANIZATION'
  | 'SUB_ORGANIZATION'
  | 'EMPLOYEE';

export type SystemIdType =
  | 'ATTENDANCE'
  | 'HOLIDAY_KOREA'
  | 'HOLIDAY_USA'
  | 'HOLIDAY_JAPAN'
  | 'HOLIDAY_CHINA';

interface CalendarReturnType {
  companyId: number;
  id: number;
  color: string;
  updateAt: string;
}

interface CalendarUserPermissionType {
  useRead: boolean; // 읽기.
  useWrite: boolean; // 쓰기.
  useControl: boolean; // 제어. - 관리자 역할.
}

interface CalendarFolderView {
  companyId: number;
  id: number;
  seq: number;
  originName?: string;
  name: string;
  description: string;
  color: string;
  status: boolean;
  useExposeCreator: boolean;
  excluders: PermissionType[];
  users: {
    referenceCompanyId: number; // 사용자 회사 아이디
    referenceId: number; // 사용자 아이디 (organization | employee)
    referenceType: string; // 참조 유형
    permission: CalendarUserPermissionType;
  }[];
  creatorId?: number;
  updateAt: string;
}

/** 캘린더 저장(수정) 데이터 객체 */
interface SaveCalendarData {
  id?: number;
  name: string;
  description: string;
  color?: string;
  status: boolean;
  useExposeCreator?: boolean;
  excluders: PermissionType[];
  users: {
    referenceCompanyId: number; // 사용자 회사 아이디
    referenceId: number; // 사용자 아이디 (organization | employee)
    referenceType: string; // 참조 유형
    permission: CalendarUserPermissionType;
  }[];
  updateAt?: string;
}

/** 관리자 - 공유 캘린더 조회. */
async function findCompanyCal(): Promise<
  {
    companyId: number;
    id: number;
    seq: number;
    name: string;
    color: string;
    status: boolean;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 공유 캘린더 단일 조회. */
async function findCompanyCalView(arg: {
  id: number;
}): Promise<CalendarFolderView> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars/${arg.id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 공유 캘린더 저장. */
async function createCompanyCal(
  arg: SaveCalendarData,
): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 공유 캘린더 수정. */
async function updateCompanyCal(
  arg: SaveCalendarData,
): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 공유 캘린더 삭제. */
async function deleteCompanyCal(arg: {
  id: number;
  updateAt: string;
}): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 시스템 구독 캘린더 조회. */
async function findSystemSubCal(): Promise<
  {
    system: SystemIdType;
    name: string;
    isAdded: boolean;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars/systems`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 구독 캘린더 조회. */
async function findSubCal(): Promise<
  {
    id: number;
    seq: number;
    name: string;
    systemId: SystemIdType;
    color: string;
    status: boolean;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars/externals`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 구독 캘린더 단일 조회. */
async function findSubCalView(arg: {
  systemId: SystemIdType;
  id: number;
}): Promise<{
  id: number;
  systemId: SystemIdType;
  seq: number;
  name: string;
  color: string;
  description: string;
  status: boolean;
  useExposeAnniversary?: boolean;
  managerAuthority?: AuthorityType;
  generalUserAuthority?: AuthorityType;
  updateAt: string;
}> {
  const { systemId, id } = arg;
  try {
    const { host, headers } = getApiConfig();
    const type = systemId === 'ATTENDANCE' ? 'attendance' : 'holiday';
    const url = `${host}/api/calendar/v1/adminconsole/calendars/externals/${type}/${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 구독 캘린더 생성. */
async function createSubCal(arg: {
  systemId: SystemIdType;
  useExposeAnniversary?: boolean;
  managerAuthority?: AuthorityType;
  generalUserAuthority?: AuthorityType;
}): Promise<CalendarReturnType> {
  const {
    systemId,
    useExposeAnniversary,
    managerAuthority,
    generalUserAuthority,
  } = arg;
  try {
    const { host, headers } = getApiConfig();
    const data = {
      systemId,
      managerAuthority,
      generalUserAuthority,
      useExposeAnniversary,
    };
    const type = systemId === 'ATTENDANCE' ? 'attendance' : 'holiday';
    const url = `${host}/api/calendar/v1/adminconsole/calendars/externals/${type}`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 구독 캘린더 수정. */
async function updateSubCal(arg: {
  systemId: SystemIdType;
  params: {
    id: number;
    color: string;
    name: string;
    description: string;
    status: boolean;
    useExposeAnniversary?: boolean;
    managerAuthority?: AuthorityType;
    generalUserAuthority?: AuthorityType;
    updateAt: string;
  };
}): Promise<CalendarReturnType> {
  const { systemId, params } = arg;
  try {
    const { host, headers } = getApiConfig();
    const type = systemId === 'ATTENDANCE' ? 'attendance' : 'holiday';
    const url = `${host}/api/calendar/v1/adminconsole/calendars/externals/${type}`;
    const response = await axios.put(url, params, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 관리자 - 구독 캘린더 삭제. */
async function deleteSubCal(arg: {
  id: number;
  updateAt: string;
}): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/adminconsole/calendars/externals`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 공유 캘린더 목록 조회. */
async function findSharedCalendarList(employeeid: number): Promise<
  {
    id: number;
    seq: number;
    name: string;
    isVisible: boolean;
    color: string;
    status: boolean;
    useExposeCreator: boolean;
    originalName: string;
    originalColor: string;
    isCommon: boolean;
    isInitialAtUserStarted: boolean;
    permissions: CalendarUserPermissionType;
    creatorId: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeid}/calendars/shared`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 내 캘린더 목록 조회. */
async function findMyCalendarList(employeeid: number): Promise<
  {
    id: number;
    seq: number;
    name: string;
    isVisible: boolean;
    color: string;
    status: boolean;
    useExposeCreator: boolean;
    originalName: string;
    originalColor: string;
    isCommon: boolean;
    isInitialAtUserStarted: boolean;
    permissions: CalendarUserPermissionType;
    creatorId: number;
    createAt: string;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeid}/calendars/mine`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 구독 캘린더 목록 조회. */
async function findUserSubCalendarList(employeeid: number): Promise<
  {
    id: number;
    systemId: SystemIdType;
    seq: number;
    name: string;
    isVisible: boolean;
    color: string;
    status: boolean;
    originalName: string;
    originalColor: string;
    useExposeCreator: null;
    isCommon: null;
    isInitialAtUserStarted: null;
    permissions: null;
    creatorId: null;
    createAt: null;
    updateAt: string;
  }[]
> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeid}/calendars/externals`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 단일 조회. */
async function findCalView(arg: { id: number }): Promise<CalendarFolderView> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/${arg.id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 구독 캘린더 단일 조회. */
async function findUserSubCalView(arg: {
  type: SystemIdType;
  employeeId: number;
  id: number;
}): Promise<{
  id: number;
  systemId: SystemIdType;
  useExposeAnniversary?: boolean;
  managerAuthority?: AuthorityType;
  generalUserAuthority?: AuthorityType;
  updatAt: string;
}> {
  try {
    const { employeeId, id } = arg;
    const { host, headers } = getApiConfig();
    const type = arg.type === 'ATTENDANCE' ? 'attendance' : 'holiday';
    const url = `${host}/api/calendar/v1/users/${employeeId}/calendars/externals/${type}/${id}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 저장. */
async function createMyCal(arg: SaveCalendarData): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 간단 저장. */
async function simpleCreateMyCal(arg: {
  name: string;
}): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars/simply`;
    const response = await axios.post(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 수정. */
async function updateMyCal(arg: SaveCalendarData): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars`;
    const response = await axios.put(url, arg, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 삭제. */
async function deleteMyCal(arg: {
  id: number;
  updateAt: string;
}): Promise<CalendarReturnType> {
  try {
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/calendars`;
    const response = await axios.delete(url, { headers, data: arg });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 보기 여부 변경. */
async function changeChecked(arg: {
  employeeId: number;
  id: number;
  isVisible: boolean;
}): Promise<{
  id: number;
  isVisible: boolean;
}> {
  try {
    const { employeeId, id, isVisible } = arg;
    const data = { value: isVisible ? 1 : 0 };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/calendars/${id}/visible`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 색상 변경. */
async function changeColor(arg: {
  employeeId: number;
  id: number;
  color: string;
}): Promise<{
  id: number;
  color: string;
}> {
  try {
    const { employeeId, id, color } = arg;
    const data = { value: color };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/calendars/${id}/color`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 이름 변경. */
async function changeName(arg: {
  employeeId: number;
  id: number;
  name: string;
}): Promise<{
  id: number;
  name: string;
}> {
  try {
    const { employeeId, id, name } = arg;
    const data = { value: name };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/calendars/${id}/name`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 - 캘린더 사용여부 변경. */
async function changeStatus(arg: {
  employeeId: number;
  id: number;
  status: boolean;
}): Promise<{
  id: number;
  status: boolean;
}> {
  try {
    const { employeeId, id, status } = arg;
    const data = { value: status ? 1 : 0 };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/calendars/${id}/useable`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** TODO 사용자 - 캘린더 작성자명 표시여부 변경. */
async function changeUseExposeCreator(arg: {
  employeeId: number;
  id: number;
  useExposeCreator: boolean;
}): Promise<{
  id: number;
  useExposeCreator: boolean;
}> {
  try {
    const { employeeId, id, useExposeCreator } = arg;
    const data = { value: useExposeCreator ? 1 : 0 };
    const { host, headers } = getApiConfig();
    const url = `${host}/api/calendar/v1/users/${employeeId}/calendars/${id}/expose`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const calendarsApi = {
  companyCal: findCompanyCal,
  companyCalView: findCompanyCalView,
  systemSubCal: findSystemSubCal,
  subCal: findSubCal,
  subCalView: findSubCalView,

  createCompanyCal,
  updateCompanyCal,
  deleteCompanyCal,

  createSubCal,
  updateSubCal,
  deleteSubCal,

  sharedCalendarList: findSharedCalendarList,
  myCalendarList: findMyCalendarList,
  userSubCalendarList: findUserSubCalendarList,
  calView: findCalView,
  userSubCalView: findUserSubCalView,

  createMyCal,
  simpleCreateMyCal,
  updateMyCal,
  deleteMyCal,

  changeChecked,
  changeColor,
  changeName,
  changeStatus,
  changeUseExposeCreator,
};

export default calendarsApi;
