import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../components/button/Button';
import Drawer from '../../../components/drawer/Drawer';
import DrawerAction from '../../../components/drawer/DrawerAction';
import DrawerBody from '../../../components/drawer/DrawerBody';
import Icon from '../../../components/icon/Icon';

/**
 * 순서변경 팝업
 *
 * - [title] 팝업타이틀
 * - [list] 순서를 변경할 데이터 리스트
 * - [onChange] 받은 [list] 데이터를 순서가 변경된 리스트 배열로 반환
 * */
function ChangeOrder<T extends { id: number | string; label?: string }>(props: {
  title: string;
  list: T[];
  onChange(list: T[]): void;
  onClose(): void;
}): JSX.Element {
  const [list, setList] = useState(props.list);

  const { title, onChange, onClose } = props;

  return (
    <Drawer title={title} onClose={onClose}>
      <DrawerBody>
        <ReactSortable
          className="ui-change-order"
          list={list}
          setList={setList}
          animation={200}
          handle="i"
        >
          {list.map(({ id, label = '' }) => (
            <div key={id} className="list-item">
              <Icon icon="drag" />
              <span>{label}</span>
            </div>
          ))}
        </ReactSortable>
      </DrawerBody>
      <DrawerAction>
        <Button
          text="저장"
          variant="contained"
          onClick={() => onChange(list)}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default React.memo(ChangeOrder);
