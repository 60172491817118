import React from 'react';
import ComponentSwitchBasic from './ComponentSwitchBasic';
import ComponentSwitchLabel from './ComponentSwitchLabel';
import ComponentSwitchSize from './ComponentSwitchSize';
import ComponentSwitchGrouped from './ComponentSwitchGrouped';

function ComponentSwitch(): JSX.Element {
  return (
    <>
      <ComponentSwitchBasic />
      <ComponentSwitchLabel />
      <ComponentSwitchSize />
      <ComponentSwitchGrouped />
    </>
  );
}

export default ComponentSwitch;
