import React from 'react';
import { getText } from '../../groupware-common/utils';
import Button from '../button/Button';

function AddAttachForm(props: {
  data: {
    id: number;
    subject: string;
  }[];
  onRemove(id: number): void;
  onAllRemove(): void;
  onOpen(): void;
}): JSX.Element {
  const { data, onRemove, onAllRemove, onOpen } = props;

  return (
    <div className="eui-document-form">
      {data.length > 0 && (
        <>
          {data.map((x) => (
            <div className="list-item" key={x.id}>
              <div className="title">
                <div className="subject">{x.subject}</div>
              </div>
              <Button
                className="button delete"
                text={getText('삭제')}
                iconType
                icon="times"
                onClick={() => onRemove(x.id)}
                size="sm"
                color="secondary"
              />
            </div>
          ))}
        </>
      )}
      <div className="document-action" style={{ marginTop: '8px' }}>
        <Button
          className="document-choice"
          icon="document-plus"
          text="양식 추가"
          variant="outlined"
          size="sm"
          onClick={onOpen}
        />
        {data.length > 0 && (
          <Button
            className="document-choice"
            text="전체삭제"
            iconType
            icon="trash-full"
            size="sm"
            onClick={onAllRemove}
          />
        )}
      </div>
    </div>
  );
}

export default AddAttachForm;
