import React from 'react';
import { getText } from '../../../../groupware-common/utils';
import { ApprovalLineCodeProps } from '../../root/approval/ApprovalType';
import { ApprovalLineType } from '../dialogs/ApprovalLineDialogContainer';

function getApprovalLineCode(type: string): ApprovalLineCodeProps {
  switch (type) {
    case 'draft':
      return 'drafter';
    case 'agree':
      return 'agree';
    case 'receive':
      return 'receiver';
    case 'approval':
      return 'approval';
    case 'audit':
      return 'auditor';
    default:
      // return '';
      return 'drafter';
  }
}

function getApprovalLineFlatItems(
  approvalLine: ApprovalLineType,
  ignoreGroupName = false,
  includeEmptyGroup = false,
): {
  id: string;
  seq?: number;
  code: ApprovalLineCodeProps;
  sort: boolean | undefined;
  name: string;
  approval: boolean | undefined;
  option: boolean | undefined;
  arbitraryDecision: boolean | undefined;
  focus: boolean;
  act:
    | 'draft'
    | 'back'
    | 'approval'
    | 'surrogateApproval'
    | 'defer'
    | 'arbitraryDecision'
    | 'return'
    | 'retrieve'
    | 'hold'
    | 'meet'
    | 'receipt'
    | 'none'
    | undefined;
}[] {
  // console.log(`getApprovalLineFlatItems(approvalLine)`, approvalLine);
  try {
    let approverSeq = 0; // 결재자 순번.
    let approvalSeq = 0; // 결재 순번.
    const result = approvalLine.groups
      .map((group) => {
        if (group.items.length === 0) {
          if (ignoreGroupName || !includeEmptyGroup) return [];
          return {
            id: `${group.id}`,
            code: getApprovalLineCode(group.type),
            name: `${getText(`approval:approvalLineGroup.${group.type}`)}`,
            sort: undefined,
            approval: undefined,
            option: undefined,
            arbitraryDecision: undefined,
            act: undefined,
            focus: false,
          };
        }

        const groupName = ignoreGroupName
          ? ''
          : `${getText(`approval:approvalLineGroup.${group.type}`)}:`;

        const parallel =
          (group.type === 'agree' || group.type === 'receive') &&
          group.items.length > 1 &&
          group.parallel;

        if ((group.type === 'agree' || group.type === 'receive') && parallel)
          approverSeq += 1;

        switch (group.type) {
          case 'draft':
            return group.items.map((item) => {
              if (!parallel) approverSeq += 1;
              const {
                approval = undefined,
                option = undefined,
                arbitraryDecision = undefined,
                act,
                actAt,
              } = {
                ...item,
              };
              // retrieve: 회수인 경우.
              if (act === 'retrieve') approvalSeq = -1;
              if (actAt === undefined && approvalSeq === 0)
                approvalSeq = approverSeq;

              // console.log(`approverSeq:${approverSeq}approvalSeq:${approvalSeq}:parallel:${parallel}:foucs:${approvalSeq === approverSeq}`);
              return {
                id: `${group.id}/${item.id}`,
                seq: approverSeq,
                code: getApprovalLineCode(group.type),
                sort: parallel,
                name:
                  item.employeeId !== undefined
                    ? `${groupName}${item.employeeName}`
                    : `${groupName}${item.organizationName}`,
                approval,
                option,
                arbitraryDecision,
                focus: false,
                act,
              };
            });
          case 'approval':
            return group.items.map((item) => {
              if (!parallel) approverSeq += 1;
              const {
                approval = undefined,
                option = undefined,
                arbitraryDecision = undefined,
                act,
                actAt,
              } = {
                ...item,
              };
              // return: 반려인 경우.
              if (act === 'return') approvalSeq = approverSeq - 1;
              // hold: 보류, meet: 대면 인 경우.
              if (act === 'hold' || act === 'meet') approvalSeq = approverSeq;
              if (actAt === undefined && approvalSeq === 0)
                approvalSeq = approverSeq;

              // console.log(`approverSeq:${approverSeq}approvalSeq:${approvalSeq}:parallel:${parallel}:foucs:${approvalSeq === approverSeq}`);
              return {
                id: `${group.id}/${item.id}`,
                seq: approverSeq,
                code: getApprovalLineCode(group.type),
                sort: parallel,
                name:
                  item.employeeId !== undefined
                    ? `${groupName}${item.employeeName}`
                    : `${groupName}${item.organizationName}`,
                approval,
                option,
                arbitraryDecision,
                focus: approvalSeq === approverSeq,
                act,
              };
            });
          case 'agree':
            return group.items.map((item) => {
              if (!parallel) approverSeq += 1;
              const {
                approval = undefined,
                option = undefined,
                arbitraryDecision = undefined,
                act,
                actAt,
              } = {
                ...item,
              };
              // 합의 선택이 아니고 반려인 경우.
              if (act === 'return' && option !== true)
                approvalSeq = approverSeq - 1;
              // hold: 보류, meet: 대면 인 경우.
              if (act === 'hold' || act === 'meet') approvalSeq = approverSeq;
              if (actAt === undefined && approvalSeq === 0)
                approvalSeq = approverSeq;

              // console.log(`approverSeq:${approverSeq}approvalSeq:${approvalSeq}:parallel:${parallel}:foucs:${approvalSeq === approverSeq}`);
              // console.log(`item.id:${item.id},act:${act},actAt:${actAt}`);

              return {
                id: `${group.id}/${item.id}`,
                seq: approverSeq,
                code: getApprovalLineCode(group.type),
                sort: parallel,
                name:
                  item.employeeId !== undefined
                    ? `${groupName}${item.employeeName}`
                    : `${groupName}${item.organizationName}`,
                approval,
                option,
                arbitraryDecision,
                focus:
                  approvalSeq === approverSeq &&
                  (act === 'hold' || act === 'meet' || act === undefined),
                act,
              };
            });
          case 'audit':
          case 'receive':
          default:
            return group.items.map((item) => {
              if (!parallel) approverSeq += 1;
              const {
                approval = undefined,
                option = undefined,
                arbitraryDecision = undefined,
                act,
                actAt,
              } = {
                ...item,
              };
              // return: 반려인 경우.
              if (act === 'return') approvalSeq = approverSeq - 1;
              // hold: 보류, meet: 대면, receipt: 접수인 경우.
              if (act === 'hold' || act === 'meet' || act === 'receipt')
                approvalSeq = approverSeq;
              if (actAt === undefined && approvalSeq === 0)
                approvalSeq = approverSeq;

              // console.log(`approverSeq:${approverSeq}approvalSeq:${approvalSeq}:parallel:${parallel}:foucs:${approvalSeq === approverSeq}`);
              return {
                id: `${group.id}/${item.id}`,
                seq: approverSeq,
                code: getApprovalLineCode(group.type),
                sort: parallel,
                name:
                  item.employeeId !== undefined
                    ? `${groupName}${item.employeeName}`
                    : `${groupName}${item.organizationName}`,
                approval,
                option,
                arbitraryDecision,
                focus: approvalSeq === approverSeq && item.act !== 'approval',
                act,
              };
            });
        }
      })
      .flat();
    // console.log(`getApprovalLineFlatItems:result`, result);

    // console.log(`----------`, result);
    // // 행위가 회수, 합의 필수 반려이거나(완료 문서)
    // console.log(`----------1`, result.find(({ act, option }) => act === 'retrieve' || (act === 'return' && option === false)));
    // // 행위가 보류, 대면, 접수가 아니고 행위가 없는 항목이 없는 경우(완료 문서)
    // console.log(`----------2`, result.find(({ act }) => act === 'hold' || act === 'meet' || act === 'receipt' || act === undefined));

    const complete =
      result.find(
        ({ act, code, option }) =>
          act === 'retrieve' ||
          (act === 'return' && option === false) ||
          (act === 'return' && code === 'receiver'),
      ) !== undefined ||
      result.find(
        ({ act }) =>
          act === 'hold' ||
          act === 'meet' ||
          act === 'receipt' ||
          act === undefined,
      ) === undefined;

    // console.log(`getApprovalLineFlatItems(approvalLine):complete`, complete);
    // console.log(`getApprovalLineFlatItems(approvalLine):result`, result);

    if (includeEmptyGroup || complete)
      return result.map((a) => ({ ...a, focus: false }));
    return result;
  } catch (error) {
    // console.log(`getApprovalLineFlatItems(approvalLine):error`, error);
    return [];
  }
}

/**
 * 결재선 플랫
 * @param props 프롭스
 * @returns 엘리먼트
 */
function ApprovalLineFlat(props: {
  optionalNotation?: boolean; // 결재선에 전결, 또는 선택 표시 유,무
  className?: string;
  approvalLine: ApprovalLineType;
  ignoreGroupName?: boolean;
  includeEmptyGroup?: boolean;
}): JSX.Element {
  // console.log(`ApprovalLineFlat(props)`, props);
  const {
    optionalNotation,
    approvalLine,
    ignoreGroupName = false,
    includeEmptyGroup = false,
  } = props;
  const items = getApprovalLineFlatItems(
    approvalLine,
    ignoreGroupName,
    includeEmptyGroup,
  );
  let warpClassname = 'eui-approval-line';
  if (props.className) warpClassname += ` ${props.className}`;
  return (
    <span className={warpClassname}>
      {items.map((x, index) => {
        const arbitraryDecision =
          x.code === 'approval' &&
          (optionalNotation || x.act === 'arbitraryDecision')
            ? x.arbitraryDecision
            : false;
        const option =
          x.code === 'agree' && (optionalNotation || x.act === 'return')
            ? x.option
            : false;
        let classname = `item sequential ${x.code}`;
        if (x.sort) classname += ' sort';
        if (
          index > 0 &&
          x.seq &&
          items[index - 1].seq &&
          x.seq - 1 === items[index - 1].seq &&
          x.code === items[index - 1].code
        )
          classname += ' next'; // 같은 그룹(수신 또는 합의) 여러 개가 병렬일 경우 구분 선 수정
        if (option) classname += ' agree-option';
        if (x.focus) classname += ' focus';
        if (x.act === 'return') classname += ' return'; // 반려
        if (x.act === 'hold') classname += ' hold'; // 보류
        if (x.act === 'meet') classname += ' meet'; // 대면
        if (x.act === 'receipt') classname += ' receipt'; // 접수

        return (
          <span key={x.id} className={classname}>
            {x.name}
            {x.approval && <em>[결재]</em>}
            {option && <em>[선택]</em>}
            {arbitraryDecision && <em>[전결]</em>}
            {x.act === 'hold' && <em className="state">보류</em>}
            {x.act === 'meet' && <em className="state">대면</em>}
            {x.act === 'receipt' && <em className="state">접수</em>}
          </span>
        );
      })}
    </span>
  );
}

export default ApprovalLineFlat;

// export { getApprovalLineFlatItems };
