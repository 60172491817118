/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocateArg, Module, ModuleItem } from '../../groupware-common/types';
import { appError } from '../../groupware-webapp/stores/common/utils';
import { RootState } from '../../groupware-webapp/app/store';
import { IconType } from '../../groupware-common/types/icon';
import serviceMenuApi from '../apis/module';
import { convertModuleForamt } from '../../groupware-common/utils';

const name = 'service/module';

export interface ModulePermission {
  referenceCompanyId: number; // 사용자 회사 아이디
  referenceId: number; // 사용자 아이디
  referenceType: string; // 참조 유형 (company | group | organization | employee)
  createAt?: string;
  isDeleted?: boolean;
}

export interface MenuItemDetail extends ModuleItem {
  isInitial: boolean;
  includers: ModulePermission[];
  excluders: ModulePermission[];
}

interface CategoryItem {
  type: 'default' | 'setting';
  id: string | number;
  name: string;
  icon: IconType;
}

const categories: CategoryItem[] = [
  // 관리자 - 메뉴.
  { type: 'setting', id: 7001, name: '기본 설정', icon: 'folder' },
  { type: 'setting', id: 7002, name: '메뉴 설정', icon: 'folder' },
  // { type: 'setting', id: 7003, name: '용량 관리', icon: 'folder' },
];

interface State {
  category: CategoryItem[];
  adminconsole: {
    list: ModuleItem[];
    view: MenuItemDetail | undefined;
  };
}

const initialState: State = {
  category: categories,
  adminconsole: {
    list: [],
    view: undefined,
  },
};

const findList = createAsyncThunk(
  `${name}/findList`,
  async (arg: { companyId: number } & LocateArg, { rejectWithValue }) => {
    try {
      const { companyId } = arg;
      const list = await serviceMenuApi.findList({ companyId });
      return list.map((module) => convertModuleForamt(module));
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const findView = createAsyncThunk(
  `${name}/findView`,
  async (
    arg: { companyId: number; module: string } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { companyId, module } = arg;
      const view = await serviceMenuApi.findView({ companyId, module });
      return {
        ...view,
        id: view.module,
        code: view.module.toLowerCase() as Module,
        textId: `module.${arg.module}`,
      };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const update = createAsyncThunk(
  `${name}/update`,
  async (
    arg: {
      data: {
        companyId: number;
        module: string;
        status: boolean;
        includers: ModulePermission[];
        excluders: ModulePermission[];
        updateAt: string;
      };
    } & LocateArg,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { route, data } = arg;
      const { view } = (getState() as RootState).service.menu.adminconsole;

      // 변경 사항이 없을 때 백엔드 호출하지 않음.
      if (
        data.includers.length <= 0 &&
        data.excluders.length <= 0 &&
        data.status === view?.status
      ) {
        if (route) dispatch(findList({ companyId: data.companyId, route }));
        return;
      }

      const response = await serviceMenuApi.update({
        ...data,
        includers: data.includers.length <= 0 ? undefined : data.includers,
        excluders: data.excluders.length <= 0 ? undefined : data.excluders,
      });
      if (route) dispatch(findList({ companyId: data.companyId, route }));
      return { data: response };
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

/** 메뉴 순서변경. */
const sortMenu = createAsyncThunk(
  `${name}/sortFolder`,
  async (
    arg: {
      companyId: number;
      list: {
        module: string;
        seq: number;
        updateAt: string;
      }[];
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { route, ...data } = arg;
      const list = await serviceMenuApi.sortMenu(data);
      return list;
    } catch (ex) {
      return rejectWithValue(appError(ex));
    }
  },
);

const menuSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.adminconsole.list = payload;
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.adminconsole.view = payload;
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        if (payload !== undefined && state.adminconsole.view) {
          const { data } = payload;
          state.adminconsole.view = { ...state.adminconsole.view, ...data };
        }
      })
      .addCase(sortMenu.fulfilled, (state, { payload }) => {
        if (payload !== undefined)
          state.adminconsole.list = state.adminconsole.list.map((x) => {
            const item = payload.find((y) => x.id === y.module);
            if (item) return { ...x, ...item };
            return x;
          });
      });
  },
});

export default menuSlice.reducer;

export const serviceMenuActions = {
  findList,
  findView,
  update,
  sortMenu,
};
