/* eslint-disable no-useless-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../../components/alert/FeedBack';
import Button from '../../../../../components/button/Button';
import Chip from '../../../../../components/chip/Chip';
import ChipGroup from '../../../../../components/chip/ChipGroup';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import Menu from '../../../../../components/menu/Menu';
import DropMenu from '../../../../../components/selectField/DropMenu';
import SelectField from '../../../../../components/selectField/SelectField';
import TextField from '../../../../../components/textfield/TextField';
import { DirectoryTreeItemArg } from '../../../../../components/tree/DirectoryTree';
import Tree from '../../../../../components/tree/Tree';
import { IconType } from '../../../../../groupware-common/types/icon';
import {
  conversionToBytes,
  readableBytes,
  readableBytesMaxGB,
} from '../../../../../groupware-common/utils/ui';
import DirectoryMenuTreeContainer from '../../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getEmployeeName,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { preferencesActions } from '../../../../stores/document/preferences';

function DocumentPreferencesContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const { basic, administrators: initAdministrators } = useSelector(
    (state: RootState) => state.document.preferences,
  );
  const folders = useSelector(
    (state: RootState) => state.document.folders.adminconsole.list,
  );
  const categories = useSelector(
    (state: RootState) => state.document.documents.category,
  ).filter((a) => a.type === 'setting');

  const byteOptions: {
    value: string;
    label: string;
  }[] = [
    { value: 'KB', label: 'KB' },
    { value: 'MB', label: 'MB' },
    { value: 'GB', label: 'GB' },
  ];

  const treeFolderItems: {
    id: number | 'all';
    parentId: number;
    text: string;
    icon?: IconType;
  }[] = [
    { id: 'all', parentId: 0, text: '모든문서함', icon: 'folder' as const },
    ...folders
      .filter((a) => a.status) // 사용 가능한 폴더 리스트만 보일 수 있도록 필터링
      .map((a) => ({
        id: a.id,
        parentId: a.parentId,
        seq: a.seq,
        text: a.name,
        icon: 'folder' as const,
      }))
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
  ];

  const initialState = {
    saveing: false,
    change: false,
    basicFolder:
      basic.defaultFolderId === 0
        ? '모든 문서함'
        : folders.find((a) => a.id === basic.defaultFolderId)?.name ?? '',
    basicFolderId:
      basic.defaultFolderId === 0
        ? ('all' as number | 'all')
        : (basic.defaultFolderId as number | 'all'),
    newPostPeriod: basic.newPostPeriod.toString(),
    allocableCapacity: readableBytes(basic.allocableCapacity, 1),
    capacityInUse: readableBytes(basic.capacityInUse, 1),
    capacityLimit: readableBytesMaxGB(basic.capacityLimit).capacity.toString(),
    capacityLimitSpec: readableBytesMaxGB(basic.capacityLimit).byte,
    numberOfAttachments: basic.numberOfAttachments.toString(),
    attachmentsCapacity: readableBytesMaxGB(
      basic.attachmentsCapacity,
      1,
    ).capacity.toString(),
    attachmentsCapacitySpec: readableBytesMaxGB(basic.attachmentsCapacity, 1)
      .byte,
    administrators: employees
      .filter(
        (a) =>
          a.companyId === principal.companyId &&
          initAdministrators.some(({ employeeId }) => a.id === employeeId),
      )
      .map((a) => {
        const updateAt =
          initAdministrators.find(({ employeeId }) => a.id === employeeId)
            ?.updateAt ?? '1000-01-01';
        return {
          companyId: a.companyId,
          employeeId: a.id,
          organizationId: a.representativeOrganizationId,
          avatar: a.avatar,
          updateAt,
        };
      }),
    administratorsMenuPoint: undefined as
      | { x: number; y: number; width: number; height: number }
      | undefined,
    basicFolderMenuPoint: undefined as
      | { x: number; y: number; width: number; height: number }
      | undefined,
    validation: '',
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mount = true;

    async function run() {
      const {
        basicFolderId,
        newPostPeriod,
        numberOfAttachments,
        administrators,
      } = state;

      const capacityLimit = conversionToBytes(
        Number(state.capacityLimit),
        state.capacityLimitSpec,
      );
      const attachmentsCapacity = conversionToBytes(
        Number(state.attachmentsCapacity),
        state.attachmentsCapacitySpec,
      );
      const save = await dispatch(
        preferencesActions.updatePreferences({
          defaultFolderId: basicFolderId === 'all' ? 0 : basicFolderId,
          newPostPeriod: Number(newPostPeriod),
          capacityLimit,
          numberOfAttachments: Number(numberOfAttachments),
          attachmentsCapacity,
          updateAt: basic.updateAt,
        }),
      );
      if (!mount) return;
      if (save.type.endsWith('rejected')) {
        setState((prevState) => ({ ...prevState, saveing: false }));
        return;
      }

      const removeAdministrators = initAdministrators
        .filter(
          (a) =>
            administrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId, updateAt }) => ({ employeeId, updateAt }));
      const remove = await dispatch(
        preferencesActions.removeAdministrators(removeAdministrators),
      );

      if (!mount) return;
      if (remove.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      const appendAdministrators = administrators
        .filter(
          (a) =>
            initAdministrators.find((b) => a.employeeId === b.employeeId) ===
            undefined,
        )
        .map(({ employeeId }) => ({ employeeId }));
      const append = await dispatch(
        preferencesActions.appendAdministrators(appendAdministrators),
      );

      if (!mount) return;
      if (append.type.endsWith('rejected')) {
        setState((prevState) => ({
          ...prevState,
          saveing: false,
        }));
        return;
      }

      if (!mount) return;
      setState((prevState) => ({
        ...prevState,
        change: false,
        saveing: false,
        administrators: administrators.map((v) => {
          if (v.updateAt) return v;
          const updateAt =
            (append.payload as { employeeId: number; updateAt: string }[]).find(
              (b) => b.employeeId === v.employeeId,
            )?.updateAt ?? '';
          return { ...v, updateAt };
        }),
      }));
    }

    if (state.saveing) run();

    return () => {
      mount = false;
    };
  }, [state.saveing]);

  const stringReplace = (value: string) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  };

  /** 저장 용량 변경. */
  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/[^0-9.]/g) !== null) return;
    const capacityLimit = stringReplace(event.target.value);
    setState((prevState) => ({ ...prevState, change: true, capacityLimit }));
  };

  /** 저장 용량 단위 변경. */
  const handleCapacitySpecChange = (value: 'KB' | 'MB' | 'GB') => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      capacityLimitSpec: value,
    }));
  };

  /** 파일 첨부 개수 변경. */
  const handleFileCountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.match(/[^0-9]/g) !== null) return;
    const numberOfAttachments = stringReplace(event.target.value);
    if (parseInt(numberOfAttachments, 10) > 100) {
      setState((prev) => ({
        ...prev,
        validation: '첨부 개수는 최대 100개까지 입니다.',
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      change: true,
      numberOfAttachments,
    }));
  };

  /** 파일 첨부 용량 변경. */
  const handleFileCapacityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.match(/[^0-9.]/g) !== null) return;
    const attachmentsCapacity = stringReplace(event.target.value);
    setState((prevState) => ({
      ...prevState,
      change: true,
      attachmentsCapacity,
    }));
  };

  /** 파일 첨부 용량 단위 변경. */
  const handleFileCapacitySpecChange = (value: 'KB' | 'MB' | 'GB') => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      attachmentsCapacitySpec: value,
    }));
  };

  /** 기본설정 저장. */
  const handleSave = () => {
    const { administrators } = state;
    if (administrators.length === 0) {
      const validation = '관리자를 등록하세요.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    setState((prevState) => ({ ...prevState, saveing: true }));
  };

  /** 스낵바 닫기. */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 디렉토리 트리 메뉴 토글 */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { administratorsMenuPoint } = state;
    if (event !== undefined && administratorsMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        administratorsMenuPoint: undefined,
      }));
    }
  };

  /** 관리자 삭제 */
  const handleAdministratorsDelete = (employeeId: number) => {
    setState((prevState) => ({
      ...prevState,
      change: true,
      administrators: prevState.administrators.filter(
        (a) => a.employeeId !== employeeId,
      ),
    }));
  };

  /** 관리자 추가 */
  const handleAdministratorsAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;
    if (extra.type !== 'employee') return;

    const { companyId, employeeId } = extra;

    setState((prevState) => {
      const { administrators } = prevState;
      const administratorsMenuPoint = undefined;
      if (administrators.some((a) => a.employeeId === employeeId))
        return { ...prevState, administratorsMenuPoint };

      const employee = employees.find(
        (a) => a.companyId === companyId && a.id === employeeId,
      );
      if (employee === undefined)
        return { ...prevState, administratorsMenuPoint };

      const updateAt =
        initAdministrators.find((a) => a.employeeId === employeeId)?.updateAt ??
        '';

      return {
        ...prevState,
        change: true,
        administrators: [
          ...administrators,
          {
            companyId,
            employeeId,
            organizationId: employee.representativeOrganizationId,
            avatar: employee.avatar,
            updateAt,
          },
        ],
      };
    });
  };

  /** 기본 문서함 설정 메뉴 토글. */
  const handleBasicFolderMenuToggle = (event?: React.MouseEvent) => {
    const { basicFolderMenuPoint } = state;
    if (event && !basicFolderMenuPoint) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        basicFolderMenuPoint: { x, y, width, height },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        basicFolderMenuPoint: undefined,
      }));
    }
  };

  /** 기본 문서함 선택. */
  const handleClickBasicFolder = (basicFolderId: 'all' | number) => {
    const basicFolder =
      basicFolderId === 'all'
        ? '모든 문서함'
        : folders.find((a) => a.id === basicFolderId)?.name ?? '';
    if (basicFolderId === -1 || basicFolderId === 0) {
      setState((prev) => ({
        ...prev,
        basicFolderMenuPoint: undefined,
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      basicFolder,
      basicFolderId,
      basicFolderMenuPoint: undefined,

      change: basicFolderId !== state.basicFolderId,
    }));
  };

  /** 새로운 글 표시 기간 변경. */
  const handleChangeNewPostPeriodRange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.match(/[^0-9]/g) !== null) return;
    const newPostPeriod = stringReplace(event.target.value);
    setState((prev) => ({
      ...prev,
      newPostPeriod,
      change: true,
    }));
  };

  const {
    saveing,
    change,
    validation,
    allocableCapacity,
    capacityInUse,
    capacityLimit,
    capacityLimitSpec,
    attachmentsCapacity,
    attachmentsCapacitySpec,
    numberOfAttachments,
    administrators,
    administratorsMenuPoint,
  } = state;
  const title = categories.find((a) => a.id === 6001)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting.Item title="기본 문서함">
          <DropMenu
            label="문서함 설정"
            value={state.basicFolder}
            onClick={handleBasicFolderMenuToggle}
          />
          {state.basicFolderMenuPoint && (
            <Menu
              point={state.basicFolderMenuPoint}
              onClose={handleBasicFolderMenuToggle}
            >
              <Tree
                items={treeFolderItems}
                selectedId={state.basicFolderId}
                onItemClick={handleClickBasicFolder}
              />
            </Menu>
          )}
        </EuiSetting.Item>
        <EuiSetting.Item title="새로운 글 아이콘 표시">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <TextField
              width={50}
              value={state.newPostPeriod}
              onChange={handleChangeNewPostPeriodRange}
            />
            <span style={{ marginLeft: '3px', fontWeight: 'bold' }}>일</span>
          </div>
        </EuiSetting.Item>
        <EuiSetting.Item title="저장 용량">
          <EuiSetting.ItemGroup>
            <span className="ui-setting-item-group-capacity-font">
              {`※ 할당 가능 용량 : ${allocableCapacity}`}
            </span>
            <span className="ui-setting-item-group-capacity-font">
              {`※ 사용 중인 용량 : ${capacityInUse}`}
            </span>
          </EuiSetting.ItemGroup>
          <EuiSetting.ItemGroup>
            <div className="ui-setting-item-group-align-items">용량 한도 :</div>
            <TextField
              width={60}
              type="text"
              value={capacityLimit}
              size="sm"
              onChange={handleCapacityChange}
            />
            <SelectField
              data={byteOptions}
              value={capacityLimitSpec}
              onChange={handleCapacitySpecChange}
            />
          </EuiSetting.ItemGroup>
        </EuiSetting.Item>
        <EuiSetting.Item title="파일 첨부">
          <EuiSetting.ItemGroup>
            <div className="ui-setting-item-group-align-items">
              <span>첨부 개수 :</span>
              <span style={{ fontWeight: 'bold', padding: '0 12px' }}>
                최대
              </span>
              <TextField
                width={60}
                type="text"
                value={numberOfAttachments}
                size="sm"
                onChange={handleFileCountChange}
              />
              <span style={{ fontWeight: 'bold', padding: '0 5px' }}>개</span>
            </div>
          </EuiSetting.ItemGroup>
          <EuiSetting.ItemGroup>
            <div className="ui-setting-item-group-align-items">첨부 용량 :</div>
            <TextField
              width={70}
              type="text"
              value={attachmentsCapacity}
              size="sm"
              onChange={handleFileCapacityChange}
            />
            <SelectField
              data={byteOptions}
              value={attachmentsCapacitySpec}
              onChange={handleFileCapacitySpecChange}
            />
          </EuiSetting.ItemGroup>
        </EuiSetting.Item>
        <EuiSetting.Item title="관리자">
          <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
            {administrators.map(
              ({ companyId, employeeId, organizationId, avatar }) => (
                <Chip
                  key={`${companyId}/${employeeId}`}
                  label={getEmployeeName(companyId, employeeId)}
                  etc={getOrganizationName(companyId, organizationId)}
                  avatar={avatar}
                  onDelete={() => handleAdministratorsDelete(employeeId)}
                />
              ),
            )}
            {administratorsMenuPoint && (
              <DirectoryMenuTreeContainer
                point={administratorsMenuPoint}
                typeToFilter="employee"
                onItemClick={handleAdministratorsAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </EuiSetting.Item>
        <EuiToolbar>
          <EuiToolbar.Left>
            {change && (
              <Button
                text="저장"
                variant="contained"
                loading={saveing}
                onClick={handleSave}
              />
            )}
            {!saveing && change && (
              <Button text="취소" onClick={() => setState(initialState)} />
            )}
          </EuiToolbar.Left>
        </EuiToolbar>
      </EuiBody>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default DocumentPreferencesContainer;
