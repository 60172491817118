import React from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Avatar from '../../../../../components/avatar/Avatar';

function ComponentAvatarImage(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Image avatars">
      <Avatar name="홍길동" image="noFound.jpg" />
      <Avatar name="김길동" image="/images/faces/faces_2.jpg" />
      <Avatar name="이길동" image="/images/faces/faces_3.jpg" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentAvatarImage;
