import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { SnackbarOrigin } from '@material-ui/core/Snackbar/Snackbar';
import Button from '../button/Button';
import CreatePortal from '../createPortal/CreatePortal';

function FeedBack(props: {
  text: string | React.ReactNode;
  anchorOrigin?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
  onClose(): void;
  hiddenClose?: boolean;
}): JSX.Element {
  const [message, setMessage] = useState(props.text);

  useEffect(() => {
    if (props.text !== '') setMessage(props.text);
  }, [props.text]);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') return;
    props.onClose();
  };

  let anchorOrigin: SnackbarOrigin;

  switch (props.anchorOrigin) {
    case 'topLeft':
      anchorOrigin = {
        vertical: 'top',
        horizontal: 'left',
      };
      break;
    case 'topCenter':
      anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
      };
      break;
    case 'topRight':
      anchorOrigin = {
        vertical: 'top',
        horizontal: 'right',
      };
      break;
    case 'bottomLeft':
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
      };
      break;
    case 'bottomCenter':
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
      };
      break;
    case 'bottomRight':
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
      };
      break;
    default:
      anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
      };
  }

  const open = props.text !== '';

  return (
    <CreatePortal>
      <Snackbar
        key={`${message ?? ''}`}
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={3000}
        message={message}
        onClose={handleSnackbarClose}
        action={
          !props.hiddenClose && (
            <Button
              text="닫기"
              iconType
              icon="times-small"
              onClick={handleSnackbarClose}
              color="inherit"
            />
          )
        }
      />
    </CreatePortal>
  );
}

export default FeedBack;
