import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import Button from '../../../../../components/button/Button';
import {
  getPathMap,
  getQueryParams,
  getText,
} from '../../../../../groupware-common/utils';
import EuiBody from '../../../../../components/layout/EuiBody';
import UserInfo from '../../../../../components/user/UserInfo';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import ApprovalViewPermissionContentWrite from './ApprovalViewPermissionContentWrite';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { approvalViewPermissionActions as approvalActions } from '../../../../stores/approval/viewPermission';
import { getName } from '../../../../../groupware-webapp/stores/common/utils';
import Search from '../../../../../components/search/Search';

/* 조회권 컨텐츠 영역 및 이벤트 */
function ApprovalViewPermissionContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathmap = getPathMap('/*/*/*', props.pathname);
  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();

  /* 리덕스 데이터 가져오기 */
  const items = useSelector(
    (state: RootState) => state.approval2.viewPermission.viewpermission,
  );
  const totalCnt = useSelector(
    (state: RootState) => state.approval2.viewPermission.totalCount,
  );
  /*
  const companies = useSelector(
    (state: RootState) => state.directory.company.list.data.items,
  );
  */
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobPositions = useSelector(
    (state: RootState) => state.directory.jobPosition.list.data.items,
  );
  const jobDuties = useSelector(
    (state: RootState) => state.directory.jobDuty.list.data.items,
  );
  const categories = useSelector(
    (state: RootState) => state.approval2.document.category.list.data.items,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6006)?.name;

  /* 조회권 리스트 바인딩 */
  const newItems = items.map((a) => {
    const drafter = employees.find((x) => x.id === a.drafterId);
    const drafterOrganization = organizations.find(
      (x) => x.id === a.drafterOrganizationId,
    );
    const viewer = employees.find((x) => x.id === a.referenceId);
    const viewerOrganization = organizations.find((x) =>
      a.referenceType === 1 ? x.id === a.referenceId : '',
    );

    let drafterName = '';
    let drafterJobPositionName = '';
    let drafterJobDutyName = '';
    let draftOrganizationName = '';
    let drafterAvatar = '';

    if (drafter !== undefined) {
      const { companyId, id, jobPositionId, avatar } = drafter;
      drafterName = getName(employees, companyId, id, '');
      drafterJobPositionName = getName(
        jobPositions,
        companyId,
        jobPositionId,
        '',
      );
      const jobDutyId = 0;
      drafterJobDutyName = getName(jobDuties, companyId, jobDutyId, '');
      drafterAvatar = avatar;
    }

    if (drafterOrganization !== undefined) {
      const { companyId, id } = drafterOrganization;
      draftOrganizationName = getName(organizations, companyId, id, '');
    }

    let viewerName = '';
    let viewerJobPositionName = '';
    let viewerJobDutyName = '';
    let viewerOrganizationName = '';
    let viewerAvatar = '';

    if (viewer !== undefined) {
      const { companyId, id, jobPositionId, avatar } = viewer;
      viewerName = getName(employees, companyId, id, '');
      viewerJobPositionName = getName(
        jobPositions,
        companyId,
        jobPositionId,
        '',
      );
      viewerJobDutyName = getName(jobDuties, companyId, 0, '');
      viewerAvatar = avatar;
    }

    if (viewerOrganization !== undefined) {
      const { companyId, id } = viewerOrganization;
      viewerOrganizationName = getName(organizations, companyId, id, '');
    }

    return {
      ...a,
      drafterName,
      drafterJobPositionName,
      drafterJobDutyName,
      draftOrganizationName,
      drafterAvatar,
      viewerName,
      viewerJobPositionName,
      viewerJobDutyName,
      viewerOrganizationName,
      viewerAvatar,
    };
  });

  /* 검색조건  */
  const searchCategory = [
    { value: 'subject', label: '제목' },
    { value: 'drafter', label: '기안' },
    { value: 'viewers', label: '조회' },
  ];

  /* 리스트 헤더 */
  interface Column {
    id: 'checked' | 'subject' | 'drafter' | 'viewers' | 'date';
    label: string;
    width?: number | 'auto';
    align?: 'center' | 'right';
  }

  /* 리스트 헤더의 정의 */
  const columns: Column[] = [
    { id: 'checked', label: '', width: 80 },
    { id: 'subject', label: '제목', width: 'auto' },
    { id: 'drafter', label: '기안', width: 200 },
    { id: 'viewers', label: '조회', width: 200 },
    { id: 'date', label: '등록일', width: 100 },
  ];

  const [state, setState] = useState<{
    writeVisible: boolean;
  }>({
    writeVisible: false,
  });

  /* 조회권 등록 이벤트 */
  const handleClickAdd = () => {
    setState((prevState) => ({
      ...prevState,
      writeVisible: true,
    }));
  };

  /* 조회권 등록 닫기 이벤트 */
  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      writeVisible: false,
    }));
  };

  /* Drawer 조회권 등록 */
  const getDrawer = () => {
    if (state.writeVisible)
      return (
        <ApprovalViewPermissionContentWrite
          onSave={handleSave}
          onClose={handleClose}
        />
      );

    return null;
  };
  /* 검색 이벤트 */
  const handleSearch = (arg: { keyword: string; filter: string }) => {
    const { keyword, filter } = arg;
    if (keyword === undefined) {
      dispatch(
        approvalActions.viewPermission({
          route: {
            pathname: pathmap,
            search: `p=1`,
          },
        }),
      );
    } else {
      dispatch(
        approvalActions.viewPermission({
          route: {
            pathname: pathmap,
            search: `category=${filter}&keyword=${keyword}&p=1`,
          },
        }),
      );
    }
  };

  /* 체크박스 이벤트 */
  const handleChangeChecked = (
    id: number,
    referenceId: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      approvalActions.setViewPermissionListItemChecked({
        id,
        referenceId,
        checked: event.target.checked,
      }),
    );
  };

  /* 조회권 삭제 이벤트 */
  const handleDel = () => {
    const data = items
      .filter((x) => x.checked)
      .map((a) => {
        return {
          id: a.id,
          referenceCompanyId: a.referenceCompanyId,
          referenceId: a.referenceId,
          referenceType: a.referenceType,
          updateAt: a.updateAt,
        };
      });

    dispatch(
      approvalActions.viewPermissionDel({
        data,
      }),
    );
  };

  const handlePrePage = () => {
    const pageNo = (queryParams.pageNo || 1) - 1;
    if (pageNo < 1) return;

    const { category, keyword } = queryParams;

    if (keyword === undefined) {
      dispatch(
        approvalActions.viewPermission({
          route: {
            pathname: pathmap,
            search: `p=${pageNo}`,
          },
        }),
      );
    } else {
      dispatch(
        approvalActions.viewPermission({
          route: {
            pathname: pathmap,
            search: `category=${category}&keyword=${keyword}&p=${pageNo}`,
          },
        }),
      );
    }
  };

  const handleNextPage = () => {
    if (newrowsperpage >= totalCnt) return;

    const pageNo = (queryParams.pageNo || 1) + 1;

    const { category, keyword } = queryParams;

    if (keyword === undefined) {
      dispatch(
        approvalActions.viewPermission({
          route: {
            pathname: pathmap,
            search: `p=${pageNo}`,
          },
        }),
      );
    } else {
      dispatch(
        approvalActions.viewPermission({
          route: {
            pathname: pathmap,
            search: `category=${category}&keyword=${keyword}&p=${pageNo}`,
          },
        }),
      );
    }
  };

  /* 조회권 등록 이벤트 */
  const handleSave = (
    drafterId: number | undefined,
    drafterCompanyId: number | undefined,
    referenceId: number | undefined,
    referenceCompanyId: number | undefined,
    referenceType: number | undefined,
  ) => {
    if (drafterId === undefined) {
      window.alert('기안자를 선택하세요.');
      return;
    }

    if (referenceId === undefined) {
      window.alert('조회자를 선택하세요.');
      return;
    }

    const data = {
      drafterId,
      drafterCompanyId,
      referenceId,
      referenceCompanyId,
      referenceType,
    };
    dispatch(approvalActions.viewPermissionSave(data));
    handleClose();
  };

  const refpageNo = queryParams.pageNo || 1;
  const refrowsperpage = (queryParams.rowsPerPage || 15) * refpageNo;
  const newrefpageNo = refpageNo === 1 ? 1 : refrowsperpage - 15;
  const newrowsperpage = refrowsperpage >= totalCnt ? totalCnt : refrowsperpage;

  /* 컨텐츠 */
  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <Search keyword="" options={searchCategory} onSearch={handleSearch} />
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          <EuiHeader.ToolbarLeft>
            <Button
              text="조회권 등록"
              icon="plus"
              variant="contained"
              onClick={handleClickAdd}
            />
            <Button
              text="삭제"
              iconType
              icon="trash-full"
              onClick={handleDel}
            />
          </EuiHeader.ToolbarLeft>
          <EuiHeader.ToolbarRight>
            <div className="eui-pagination">
              <span className="page-count total">
                {newrefpageNo} ‑ {newrowsperpage} of {totalCnt}
              </span>
              <Button
                text={getText('이전')}
                className="page-action"
                iconType
                icon="chevron-left"
                onClick={handlePrePage}
              />
              <Button
                text={getText('다음')}
                className="page-action"
                iconType
                icon="chevron-right"
                onClick={handleNextPage}
              />
            </div>
          </EuiHeader.ToolbarRight>
        </EuiHeader.Toolbar>
      </EuiHeader>
      <EuiBody>
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.id}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {newItems.map((row) => {
              return (
                <tr key={`${row.id}_${row.referenceId}`}>
                  <td>
                    <Checkbox
                      checked={row.checked}
                      onChange={(event) =>
                        handleChangeChecked(row.id, row.referenceId, event)
                      }
                    />
                  </td>
                  <td>{row.subject}</td>
                  <td>
                    <UserInfo
                      name={row.drafterName}
                      from={row.draftOrganizationName}
                      avatar={row.drafterAvatar}
                    />
                  </td>
                  <td>
                    {row.referenceType === 2 ? (
                      <UserInfo
                        name={row.viewerName}
                        avatar={row.viewerAvatar}
                      />
                    ) : (
                      <UserInfo
                        name={row.viewerOrganizationName}
                        icon="sitemap-fill"
                      />
                    )}
                  </td>
                  <td>{row.updateAt}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {getDrawer()}
      </EuiBody>
    </>
  );
}

export default React.memo(ApprovalViewPermissionContainer);
