import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import SelectField from '../../../../../components/selectField/SelectField';

function ComponentSelectBasic(): JSX.Element {
  const options: {
    value: string;
    label: string;
  }[] = [
    { value: 'button', label: 'Button' },
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'select', label: 'Select' },
    { value: 'autocomplete', label: 'Autocomplete' },
  ];
  const [state, stateState] = useState('button');

  return (
    <ComponentSampleUI.Content title="Basic select">
      <SelectField data={options} value={state} onChange={stateState} />
    </ComponentSampleUI.Content>
  );
}

export default ComponentSelectBasic;
