import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import Button from '../../../../../components/button/Button';
import Dialog from '../../../../../components/dialog/Dialog';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import DataGrid, {
  DataGridColDef,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import DialogToolbar from '../../../../../components/dialog/DialogToolbar';
import Search from '../../../../../components/search/Search';
import approvalFormApi from '../../../../apis/approval/v1/form';
import FormBuilder from '../../../../stores/approval/FormBuilder';
import ApprovalWorkFormPreview from './ApprovalWorkFormPreview';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { appError } from '../../../../../groupware-webapp/stores/common/utils';

type Props = {
  onConfirm(arg: { id: number; name: string }): void;
  onClose(): void;
};

function ApprovalWorkForm(props: Props): JSX.Element {
  const folders = useSelector(
    (state: RootState) => state.approval2.form.folder.list.data.items,
  );
  const scrollbar = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [state, setState] = useState(() => {
    return {
      loading: true,
      searchCode: 'form', // 'folder' | 'form',
      searchWord: '',
      items: [] as {
        companyId: number;
        id: number;
        folderName: string;
        name: string;
      }[],
      pagination: {
        no: 1,
        row: 15,
        total: -1,
      },
      preview: false,
      previewId: 0,
      content: '',
      formName: '',
    };
  });

  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    let mount = true;

    const { searchCode, searchWord, pagination } = state;
    const { no: pageNo, row: rowsPerPage } = pagination;

    async function run() {
      const total = await approvalFormApi.fetchTotalCount({
        searchCode,
        searchWord,
      });
      const items = await approvalFormApi.fetchList({
        pageNo,
        rowsPerPage,
        searchCode,
        searchWord,
      });
      if (mount) {
        setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            items: items.map(({ companyId, id, folderId, name }) => ({
              companyId,
              id,
              folderName: folders.find((v) => v.id === folderId)?.name ?? '',
              name,
            })),
            pagination: {
              ...pagination,
              total,
            },
          };
        });
        scrollbar.current?.scrollTo(0, 0);
      }
    }

    run();
    return () => {
      mount = false;
    };
  }, [
    state.pagination.no,
    state.pagination.row,
    state.searchCode,
    state.searchWord,
  ]);

  useEffect(() => {
    const { previewId } = state;
    let mount = true;

    async function run() {
      try {
        if (previewId === 0) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
          return;
        }
        const form = await approvalFormApi.fetchView(previewId);
        if (!mount) return;
        setState((prevState) => ({
          ...prevState,
          loading: false,
          content: form.contents,
          formName: form.name,
        }));
        scrollbar.current?.scrollTo(0, 0);
      } catch (ex) {
        dispatch(sessionActions.error(appError(ex)));
      }
    }
    run();
    return () => {
      mount = false;
    };
  }, [state.preview]);

  const handleSearch = (arg: { keyword: string; filter: string }) => {
    // console.log(`handleSearch(arg)`, arg);
    const { keyword, filter } = arg;
    const { searchCode, searchWord } = state;
    if (searchCode !== filter || searchWord !== keyword)
      setState((prevState) => ({
        ...prevState,
        loading: true,
        searchCode: filter,
        searchWord: keyword,
        pagination: {
          ...prevState.pagination,
          no: 1,
        },
      }));
  };

  const handleSelected = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    setSelectedId(id);
  };

  /** 선택 */
  const handleConfirm = () => {
    const { items } = state;
    const item = items.find((a) => a.id === selectedId);
    if (item) props.onConfirm({ id: item.id, name: item.name });
  };

  /** 목록개수 변경. */
  const handleChangeRowLength = (row: number) => {
    const pagination = { ...state.pagination, no: 1, row };
    setState((prevState) => ({ ...prevState, pagination }));
  };

  /** 데이터 그리드 클릭. */
  const handleDataGridClick = (arg: {
    code: string;
    id?: number;
    event: React.MouseEvent;
  }) => {
    // console.log(`handleDataGridClick(arg)`, arg);
    const { code } = arg;

    if (code === 'prePage') {
      const no = state.pagination.no - 1;
      const pagination = { ...state.pagination, no };
      setState((prevState) => ({ ...prevState, loading: true, pagination }));
      return;
    }
    if (code === 'nextPage') {
      const no = state.pagination.no + 1;
      const pagination = { ...state.pagination, no };
      setState((prevState) => ({ ...prevState, loading: true, pagination }));
    }
    if (code === 'subject') {
      const { id } = arg;
      if (id === undefined) return;
      setState((prevState) => ({
        ...prevState,
        loading: true,
        preview: true,
        previewId: id,
      }));
    }
  };

  const { loading, searchCode, searchWord, items, pagination } = state;

  const columns: DataGridColDef[] = [
    { field: 'radio', label: '', width: 60 },
    { field: 'category', label: '분류', width: 130 },
    { field: 'subject', label: '양식명', minWidth: 200 },
  ];

  const rows: DataGridRowsProps<number> = items.map((v) => {
    return [
      {
        type: 'radio' as const,
        id: v.id,
        value: v.id === selectedId,
        name: 'item',
      },
      {
        type: 'text' as const,
        value: v.folderName,
      },
      { type: 'subject' as const, value: v.name, id: v.id },
    ];
  });

  const renderPreviewBody = () => {
    const { formName, content } = state;
    const html = FormBuilder.viewingPreview({ content });

    return (
      <>
        <ApprovalWorkFormPreview
          html={html}
          title={formName}
          scrollbar={scrollbar}
          loading={loading}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              preview: false,
              previewId: 0,
              content: '',
              formName: '',
            }));
          }}
        />
      </>
    );
  };

  const renderListBody = () => {
    return (
      <>
        <DialogHeader>
          <DialogTitle>결재양식 선택</DialogTitle>
        </DialogHeader>
        <DialogToolbar>
          <DialogToolbar.Left>{null}</DialogToolbar.Left>
          <DialogToolbar.Right>
            <Search
              keyword={searchWord}
              options={[
                { value: 'form', label: '양식명' },
                // TODO 백엔드 조회 기능 구현 안됨.
                // { value: 'folder', label: '분류' },
              ]}
              filter={searchCode}
              onSearch={handleSearch}
            />
          </DialogToolbar.Right>
        </DialogToolbar>
        <DialogBody>
          <DataGrid
            scrollbar={scrollbar}
            caption="결재양식 리스트"
            loading={loading}
            columns={columns}
            rows={rows}
            pagination={{
              ...pagination,
              onChangeRow: handleChangeRowLength,
            }}
            onChecked={handleSelected}
            onClick={handleDataGridClick}
          />
        </DialogBody>
        <DialogFooter>
          <Button text="취소" color="secondary" onClick={props.onClose} />
          <Button
            text="선택"
            variant="contained"
            onClick={handleConfirm}
            disabled={loading || !items.find((a) => a.id === selectedId)}
          />
        </DialogFooter>
      </>
    );
  };

  const { preview } = state;

  return (
    <Dialog overflow="hidden" size="lg">
      {preview ? renderPreviewBody() : renderListBody()}
    </Dialog>
  );
}

export default ApprovalWorkForm;
