import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';

function ComponentCheckboxButtonSimple(): JSX.Element {
  const [options, setOptions] = useState<
    { value: string; label: string; checked: boolean }[]
  >([
    { checked: true, value: 'monday', label: '월요일' },
    { checked: false, value: 'tuesday', label: '화' },
    { checked: false, value: 'wednesday', label: '수' },
    { checked: false, value: 'thursday', label: '목' },
    { checked: false, value: 'friday', label: '금' },
    { checked: false, value: 'saturday', label: '토' },
    { checked: false, value: 'sunday', label: '일' },
  ]);

  const handleOptionsWeekChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOptions((prevState) =>
      prevState.map((x) =>
        x.value === event.target.name
          ? { ...x, checked: event.target.checked }
          : x,
      ),
    );
  };

  return (
    <ComponentSampleUI.Content title="Simple">
      <CheckboxGroup legend="요일" type="simple">
        {options.map((x) => {
          return (
            <Checkbox
              key={x.value}
              name={x.value}
              label={x.label}
              checked={x.checked}
              onChange={handleOptionsWeekChange}
            />
          );
        })}
      </CheckboxGroup>
    </ComponentSampleUI.Content>
  );
}

export default ComponentCheckboxButtonSimple;
