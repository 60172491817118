import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiBody from '../../../../../components/layout/EuiBody';
import {
  createQueryString,
  getPathMap,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import DataGrid, {
  DataGridAppendItemDef,
  DataGridColDef,
  DataGridMoreActionProps,
  DataGridRowsProps,
} from '../../../../../components/data/DataGrid';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import PostWrite from '../../../../../components/post/PostWrite';
import SelectField from '../../../../../components/selectField/SelectField';
import Button from '../../../../../components/button/Button';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import { attendanceHolidayActions } from '../../../../stores/attendance/holiday';
import FeedBack from '../../../../../components/alert/FeedBack';
import {
  dateFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';

function AttendanceHolidayYearContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const pathmap = getPathMap('/*/*/*', props.pathname);
  const style: React.CSSProperties = {
    marginTop: '4px',
    marginLeft: '10px',
  };

  const [state, setState] = useState<{
    holidayDate: Date;
    holidayName: string;
    isIncludeSaturday: boolean;
    isIncludeSunday: boolean;
    isIncludeHolidayKorea: boolean;
    validation: string;
  }>(() => {
    return {
      holidayDate: timezoneDate(),
      loading: true,
      holidayName: '',
      isIncludeSaturday: false,
      isIncludeSunday: false,
      isIncludeHolidayKorea: false,
      validation: '',
    };
  });

  const dispatch = useAppDispatch();
  const queryParams = getQueryParams(props.search);
  const data = useSelector((s: RootState) => s.attendance.holiday.list.data);
  const holidayYear = useSelector(
    (s: RootState) => s.attendance.holiday.list.data.year,
  );
  const categories = useSelector(
    (s: RootState) => s.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6003)?.name ?? '';
  const { pageNo = 1 } = queryParams;
  const thisYear = timezoneDate().getFullYear();

  const { isIncludeSaturday, isIncludeSunday, isIncludeHolidayKorea } = state;

  const moreActions: DataGridMoreActionProps[] = [
    {
      code: 'delete',
      label: '삭제',
      icon: 'trash-full',
    },
  ];

  const columns: DataGridColDef[] = [
    { field: 'date', label: '일자', width: 250 },
    { field: 'dayOfWeek', label: '요일', width: 200 },
    { field: 'subject', label: '휴일명', minWidth: 250 },
    { field: 'more', label: '', width: 100, align: 'right' },
  ];

  const handleAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id: string;
  }) => {
    const { code, id } = arg;
    /** 이전 페이지. */
    if (code === 'prePage') {
      queryParams.pageNo = pageNo - 1;
      const route = {
        pathname: `${props.pathname}`,
        search: getQueryParams(queryParams),
      };
      dispatch(attendanceHolidayActions.yearList({ year: holidayYear, route }));
    }

    /** 다음 페이지. */
    if (code === 'nextPage') {
      queryParams.pageNo = pageNo + 1;
      const route = {
        pathname: `${props.pathname}`,
        search: getQueryParams(queryParams),
      };
      dispatch(attendanceHolidayActions.yearList({ year: holidayYear, route }));
    }

    /** 삭제 */
    if (code === 'delete') {
      if (Number(holidayYear) < thisYear) {
        const validation = '지난 연도의 공휴일은 삭제할 수 없습니다.';
        setState((prevState) => ({ ...prevState, validation }));
        return;
      }

      const item = data.items.find((a) => a.holiday === id);
      if (item === undefined) return;
      const { holiday, holidayName, updateAt } = item;
      const params = { ...queryParams };
      delete queryParams.pageNo;
      const route = {
        pathname: props.pathname,
        search: createQueryString(params),
      };
      dispatch(
        attendanceHolidayActions.yearDelete({
          holiday,
          holidayName,
          holidayYear,
          updateAt,
          route,
        }),
      );
    }
  };

  /* 목록개수 변경 */
  const handleChangeRowLength = (value: number) => {
    const route = {
      pathname: `${pathmap}`,
      search: createQueryString({ pageNo: 1, rowsPerPage: value }, queryParams),
    };
    dispatch(attendanceHolidayActions.yearList({ year: holidayYear, route }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /* 휴일 변경 */
  const handleHolidayChange = (holidayDate: Date) => {
    setState((prev) => ({ ...prev, holidayDate }));
  };

  /* 휴일명에서 Enter 클릭 */
  const handleHolidayNameKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleHolidayAppend();
    }
  };

  /* 휴일명 변경 */
  const handleHolidayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({ ...prev, holidayName: event.target.value }));
  };

  /* 휴일 개별 추가 */
  const handleHolidayAppend = () => {
    const { holidayDate, holidayName } = state;

    if (Number(holidayYear) < thisYear) {
      const validation = '지난 연도의 공휴일은 등록할 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    if (holidayName.trim() === '' || holidayName === undefined) {
      const validation = '휴일명을 입력하세요.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    if (holidayDate === undefined) {
      const validation = '날짜를 입력하세요.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    const holiday = `${moment(holidayDate).format('YYYY-MM-DD')}`;
    const params = { ...queryParams };
    delete queryParams.pageNo;
    const route = {
      pathname: props.pathname,
      search: createQueryString(params),
    };
    const arg = { holiday, holidayName, holidayYear, route };
    dispatch(attendanceHolidayActions.yearAppend(arg));
    handleCancelChange();
  };

  /** 휴일명 초기화 */
  const handleCancelChange = () => {
    setState((prevState) => ({
      ...prevState,
      holidayName: '',
    }));
  };

  const week = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ] as string[];

  const rows: DataGridRowsProps<string> = data.items.map((l) => {
    return [
      { type: 'text' as const, value: dateFormat(l.holiday, 'll') },
      { type: 'text' as const, value: `${week[new Date(l.holiday).getDay()]}` },
      { type: 'text' as const, value: l.holidayName },
      { type: 'more' as const, id: l.holiday },
    ];
  });

  const appendItemRows: DataGridAppendItemDef<string> = [
    {
      type: 'date' as const,
      value: state.holidayDate,
      format: ['yyyy-MM-dd', 'yyyyMMdd'],
      onChange: handleHolidayChange,
    },
    {
      type: 'text' as const,
      value: `${
        state.holidayDate === null ? '' : week[state.holidayDate.getDay()]
      }`,
      maxLength: 20,
      readonly: true,
    },
    {
      type: 'text' as const,
      value: state.holidayName,
      maxLength: 20,
      onChange: handleHolidayNameChange,
      onKeyDown: handleHolidayNameKeyDown,
    },
    {
      type: 'button' as const,
      iconType: true,
      icon: 'plus',
      onClick: handleHolidayAppend,
    },
  ];

  /** 휴일연도 (-4년, + 1년) */
  const getYear = thisYear - 5;
  const holidayYears: { value: string; label: string }[] = [];
  for (let i = 1; i <= 5; i += 1) {
    const setYear = getYear + i;
    holidayYears.push({ value: `${setYear}`, label: `${setYear}년` });
  }
  holidayYears.push({ value: `${thisYear + 1}`, label: `${thisYear + 1}년` });

  /** 휴일연도 변경 */
  const handleHolidayYearChange = (value: string) => {
    state.holidayDate.setFullYear(Number(value));
    setState((prev) => ({
      ...prev,
      holidayYear: value,
      holidayDate: state.holidayDate,
    }));
    const route = {
      pathname: `${pathmap}`,
      search: createQueryString({ pageNo: 1, rowsPerPage: 15 }, queryParams),
    };
    dispatch(attendanceHolidayActions.yearList({ year: value, route }));
  };
  const handleHolidaySaveAll = () => {
    if (Number(holidayYear) < thisYear) {
      const validation = '지난 연도의 공휴일은 등록할 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    const params = { ...queryParams };
    delete params.pageNo;
    delete params.rowsPerPage;
    const route = {
      pathname: `${props.pathname}`,
      search: createQueryString(params),
    };
    const arg = {
      holidayYear,
      isIncludeSaturday,
      isIncludeSunday,
      isIncludeHolidayKorea,
      route,
    };
    dispatch(attendanceHolidayActions.yearAllSave(arg));
  };

  const handleSaturdayChange = () => {
    setState((prevState) => ({
      ...prevState,
      isIncludeSaturday: !prevState.isIncludeSaturday,
    }));
  };

  const handleSundayChange = () => {
    setState((prevState) => ({
      ...prevState,
      isIncludeSunday: !prevState.isIncludeSunday,
    }));
  };

  const handleKoreanHolidayChange = () => {
    setState((prevState) => ({
      ...prevState,
      isIncludeHolidayKorea: !prevState.isIncludeHolidayKorea,
    }));
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <PostWrite>
          <PostWrite.Item>
            <SelectField
              data={holidayYears}
              value={`${holidayYear}`}
              onChange={handleHolidayYearChange}
            />
            <Button
              text="생성"
              variant="contained"
              onClick={handleHolidaySaveAll}
            />
            <CheckboxGroup>
              <span style={style}>
                <Checkbox
                  label="토요일"
                  checked={isIncludeSaturday}
                  onChange={handleSaturdayChange}
                />
                <Checkbox
                  label="일요일"
                  checked={isIncludeSunday}
                  onChange={handleSundayChange}
                />
                <Checkbox
                  label="대한민국 공휴일"
                  checked={isIncludeHolidayKorea}
                  onChange={handleKoreanHolidayChange}
                />
              </span>
            </CheckboxGroup>
          </PostWrite.Item>
        </PostWrite>
        <DataGrid
          caption="휴일등록"
          columns={columns}
          rows={rows}
          pagination={{
            no: queryParams.pageNo || 1,
            total: data.totalCount,
            row: queryParams.rowsPerPage || 15,
            onChangeRow: handleChangeRowLength,
          }}
          useAddListItems
          appendItemRow={appendItemRows}
          onClick={handleAction}
          moreActions={moreActions}
        />
      </EuiBody>
      <FeedBack text={state.validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default AttendanceHolidayYearContainer;
