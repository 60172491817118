import React, { useEffect } from 'react';
import { CustomNumbers, DirectoryType } from '../../groupware-common/types';
import {
  getAvatarPath,
  getParentItems,
  hangul,
} from '../../groupware-common/utils';
import Tree from './Tree';
import EmptyData from '../data/EmptyData';
import MenuItem from '../menu/MenuItem';
import {
  getCompanyName,
  getEmployeeName,
  getJobDutyName,
  getJobPositionName,
  getOrganizationName,
} from '../../groupware-directory/stores/directory';

// TypeScript 4.1 부터 지원됨.
// type DirectoryTreeId = `${string}` | `${string}_${string}` | `${string}_${string}_${string}`;
type DirectoryTreeId = string;

function getDirectoryIcon(
  type: DirectoryType,
): 'company' | 'sitemap' | 'person' {
  if (type === 'company') return 'company';
  if (type === 'organization') return 'sitemap';
  if (type === 'employee') return 'person';
  return 'person';
}

interface OrganizationExtra {
  type: 'company' | 'organization';
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
}

interface EmployeeExtra {
  type: 'employee';
  companyId: number;
  companyName: string;
  organizationId: number;
  organizationName: string;
  employeeId: number;
  employeeName: string;
  jobPositionId: number;
  jobPositionName: string;
  jobDutyId: number;
  jobDutyName: string;
  jobClassType: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobClassName: string;
  avatar: string;
}

export function getDirectoryTreeId(
  companyId: number,
  organizationId?: number,
  employeeId?: number,
): DirectoryTreeId {
  if (employeeId) {
    return organizationId
      ? `${companyId}_${organizationId}_${employeeId}`
      : `${companyId}_${employeeId}`;
  }
  return organizationId
    ? `${companyId}_${organizationId}`
    : `${companyId}_${companyId}`;
}

export function getDirectoryTreeItems({
  companies,
  organizations = [],
  organizationEmployees = [],
  employees = [],
  jobClassType = 'jobposition',
  jobPositions = [],
  jobDuties = [],
}: {
  companies: { id: number }[];
  organizations?: {
    companyId: number;
    id: number;
    parentId: number;
    seq: number;
  }[];
  organizationEmployees?: {
    companyId: number;
    id: number;
    employeeId: number;
    jobDutyId: number;
  }[];
  employees?: {
    companyId: number;
    id: number;
    representativeOrganizationId: number;
    jobPositionId: number;
    jobRank: number;
    updateAt: string;
  }[];
  jobClassType?: 'jobposition' | 'jobduty' | 'jobposition+jobduty';
  jobPositions?: { companyId: number; id: number; seq: number }[];
  jobDuties?: { companyId: number; id: number; seq: number }[];
}): {
  id: string;
  parentId: string;
  text: string;
  icon: 'company' | 'sitemap' | 'person';
  avatar?: string | undefined;
  strings: string[][];
  updateAt: string;
  extra: OrganizationExtra | EmployeeExtra;
}[] {
  const result: {
    id: string;
    parentId: string;
    text: string;
    icon: 'company' | 'sitemap' | 'person';
    avatar?: string;
    strings: string[][];
    updateAt: string;
    extra: OrganizationExtra | EmployeeExtra;
  }[] = [];

  companies.forEach((company) => {
    const companyId = company.id;
    const companyName = getCompanyName(companyId);

    organizationEmployees
      .filter((a) => a.companyId === companyId)
      .map((a) => {
        const { id: organizationId, employeeId, jobDutyId } = a;

        const employee = employees.find(
          (v) => v.companyId === companyId && v.id === employeeId,
        );
        if (employee === undefined) return null;

        const { jobPositionId, jobRank } = employee;

        const jobClassSeq =
          (jobClassType === 'jobduty'
            ? jobDuties.find(
                (v) => v.companyId === companyId && v.id === jobDutyId,
              )?.seq
            : jobPositions.find(
                (v) => v.companyId === companyId && v.id === jobPositionId,
              )?.seq) || CustomNumbers.SMALLINT_MAX;

        return {
          companyId,
          organizationId,
          employeeId,
          employeeName: getEmployeeName({ companyId, employeeId }),
          jobPositionId,
          jobDutyId,
          jobRank,
          updateAt: employee.updateAt,

          jobClassSeq,
          // TODO 직원 순서 정렬용 입사 일자 추가 작업 필요.
          // enterDate?: string;
        };
      })
      .flatMap((a) => (a ? [a] : []))
      .sort((a, b) => {
        // 직위 또는 직책 순번이 낮을수록 위로.
        if (a.jobClassSeq > b.jobClassSeq) return 1;
        if (a.jobClassSeq < b.jobClassSeq) return -1;

        // 직위 또는 직위+직책 정렬인 경우.
        if (
          jobClassType === 'jobposition' ||
          jobClassType === 'jobposition+jobduty'
        ) {
          // 직급이 높을수록 위로.
          if (a.jobRank < b.jobRank) return 1;
          if (a.jobRank > b.jobRank) return -1;
        }

        if (a.employeeName < b.employeeName) return -1;
        if (a.employeeName > b.employeeName) return 1;

        return 0;
      })
      .forEach((a) => {
        const {
          organizationId,
          employeeId,
          employeeName,
          jobPositionId,
          jobDutyId,
          // jobRank, // TODO 추후 추가 예정.
          updateAt,
        } = a;

        const treeId = `${companyId}_${organizationId}_${employeeId}`;

        const organizationName = getOrganizationName({
          companyId,
          organizationId,
        });

        const jobPositionName = getJobPositionName(
          companyId,
          jobPositionId,
          '',
        );
        const jobDutyName = getJobDutyName(companyId, jobDutyId, '');

        let jobClassName;
        switch (jobClassType) {
          case 'jobposition':
            jobClassName = jobPositionName;
            break;
          case 'jobduty':
            jobClassName = jobDutyName;
            break;
          case 'jobposition+jobduty': {
            if (jobPositionName !== '' && jobDutyName !== '')
              jobClassName = `${jobPositionName}/${jobDutyName}`;
            else jobClassName = jobPositionName || jobDutyName || '';
            break;
          }
          default:
            jobClassName = jobPositionName;
            break;
        }
        const text =
          jobClassName === ''
            ? employeeName
            : `${employeeName} ${jobClassName}`;

        const avatar = getAvatarPath(companyId, employeeId);

        result.push({
          id: treeId,
          parentId: `${companyId}_${organizationId}`,
          text,
          icon: getDirectoryIcon('employee'),
          avatar,
          strings: hangul.d(employeeName),
          updateAt,
          extra: {
            type: 'employee',
            companyId,
            companyName,
            organizationId,
            organizationName,
            employeeId,
            employeeName,
            jobPositionId,
            jobPositionName,
            jobDutyId,
            jobDutyName,
            jobClassType,
            jobClassName,
            avatar,
          },
        });
      });

    organizations
      .filter((a) => a.companyId === companyId)
      .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1)
      .forEach(({ id: organizationId, parentId }) => {
        const id = `${companyId}_${organizationId}`;
        if (companyId === organizationId && parentId === 0)
          result.splice(0, 0, {
            id,
            parentId: '0',
            text: companyName,
            icon: getDirectoryIcon('company'),
            strings: hangul.d(companyName),
            updateAt: '',
            extra: {
              type: 'company',
              companyId,
              companyName,
              organizationId,
              organizationName: companyName,
            },
          });
        else {
          const text = getOrganizationName({ companyId, organizationId });
          result.push({
            id,
            parentId: `${companyId}_${parentId}`,
            text,
            icon: getDirectoryIcon('organization'),
            strings: hangul.d(text),
            updateAt: '',
            extra: {
              type: 'organization',
              companyId,
              companyName,
              organizationId,
              organizationName: text,
            },
          });
        }
      });
  });

  return result;
}

export interface DirectoryTreeItem {
  id: string;
  parentId: string;
  text: string;
  icon: 'company' | 'person' | 'sitemap';
  avatar?: string | undefined;
  strings: string[][];
  updateAt: string;
  extra: OrganizationExtra | EmployeeExtra;
}

export interface DirectoryTreeItemArg {
  item: DirectoryTreeItem;
  paths: {
    type: DirectoryType;
    id: number;
    name: string;
    treeId: string;
    text: string;
  }[];
}

function DirectoryTree(props: {
  selectedId?: string;
  compose?: boolean;
  items: DirectoryTreeItem[];
  listItems?: DirectoryTreeItem[];
  typeToFilter?:
    | ('company' | 'organization' | 'employee')
    | ('company' | 'organization' | 'employee')[];
  filter?: string;
  onItemClick?(arg: DirectoryTreeItemArg): void;
}): JSX.Element {
  useEffect(() => {
    if (props.compose) {
      const moveToScroll = document.querySelector(
        'a.eui-nav-item.tree-item.expanded.selected',
      );
      if (moveToScroll) moveToScroll.scrollIntoView();
    }
  }, []);

  const handleItemClick = (id: string) => {
    const { items, onItemClick } = props;
    if (!onItemClick) return;

    // console.log(`handleItemClick(id: '${id}')`);
    const item = items.find((a) => a.id === id);
    // console.log(`handleItemClick:item`, item);
    if (item === undefined) return;
    // console.log(`handleItemClick:extra`, item.extra);

    const paths = getParentItems(items, id).map((a) => {
      const { id: treeId, text, extra } = a;
      const { type } = extra;
      if (extra.type === 'employee')
        return {
          type,
          id: extra.employeeId,
          name: extra.employeeName,
          treeId,
          text,
        };
      return {
        type,
        id: extra.organizationId,
        name: extra.organizationName,
        treeId,
        text,
      };
    });

    onItemClick({ item, paths });
  };

  const getTree = () => {
    const { selectedId, items } = props;
    return (
      <Tree
        selectedId={selectedId}
        items={items}
        onItemClick={handleItemClick}
      />
    );
  };

  const getList = () => {
    const { selectedId, items, listItems, filter = '', typeToFilter } = props;

    const typeToFiloterArrayOrNot = Array.isArray(typeToFilter)
      ? typeToFilter
      : [typeToFilter];

    const strings = hangul.d(filter);
    const list = listItems
      ? listItems
          .filter(
            (a) =>
              typeToFilter === undefined ||
              typeToFiloterArrayOrNot.includes(a.extra.type),
          )
          .filter((x) => x.strings && hangul.test(x.strings, strings))
      : items
          .filter(
            (a) =>
              typeToFilter === undefined ||
              typeToFiloterArrayOrNot.includes(a.extra.type),
          )
          .filter((x) => x.strings && hangul.test(x.strings, strings));
    if (list.length === 0) return <EmptyData message="검색결과가 없습니다." />;

    return (
      <>
        {list.map(({ id, text, icon, avatar, updateAt, extra }) => {
          return (
            <MenuItem
              key={updateAt ? `${id}/${updateAt}` : `${id}`}
              label={
                extra.type === 'employee' ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          lineHeight: '20px',
                        }}
                      >
                        {text}
                      </div>
                      <div
                        style={{
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          marginTop: '-2px',
                          fontSize: '11px',
                          lineHeight: '18px',
                          color: 'var(--secondary-text-color)',
                        }}
                      >
                        {extra.organizationName}
                      </div>
                    </div>
                  </>
                ) : (
                  text
                )
              }
              avatar={avatar}
              icon={avatar ? undefined : icon}
              selected={id === selectedId}
              onClick={() => handleItemClick(id)}
            />
          );
        })}
      </>
    );
  };

  const { filter = '' } = props;
  return !filter ? getTree() : getList();
}

export default DirectoryTree;
