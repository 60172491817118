/* eslint-disable consistent-return */
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  CacheEntity,
  CacheMeta,
  LocateArg,
} from '../../../groupware-common/types';
import {
  PendingAction,
  RejectedAction,
} from '../../../groupware-webapp/stores/types';
import {
  asyncRequestContains,
  requestAppend,
} from '../../../groupware-webapp/stores/utils';
import systemLinkFormApi, {
  formFolderApi,
} from '../../apis/systemlink/v1/form';
import {
  b62,
  getPathParams,
  getQueryParams,
} from '../../../groupware-common/utils';
import { appError } from '../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../groupware-webapp/stores/session';

/** 양식 */
const NAME = 'systemlink/form';

function thunkPending(action: AnyAction): action is PendingAction {
  const { type } = action;
  return type.indexOf(`${NAME}/`) === 0 && type.endsWith('/pending');
}

function thunkRejected(action: AnyAction): action is RejectedAction {
  const { type } = action;
  return type.indexOf(`${NAME}/`) === 0 && type.endsWith('/rejected');
}

type FolderItem = {
  id: number;
  parentId: number;
  seq: number;
  name: string;
  updateAt: string;
};

interface Item {
  checked: boolean;
  companyId: number;
  id: number;
  folderId: number;
  isEnable: boolean;
  name: string;
  description: string;
  createAt: string;
  updateAt: string;
}

interface State {
  requests: { id: string; type: string; arg: unknown }[];
  items: Item[];
  item:
    | {
        id: number;
        folderId: number;
        isEnable: boolean;
        name: string;
        contents: string;
        description: string;
        createAt: string;
        updateAt: string;
      }
    | undefined;
  totalCount: number;

  folder: {
    list: CacheEntity<{
      items: FolderItem[];
    }>;
  };
}

const initialState: State = {
  requests: [],
  items: [],
  item: undefined,
  totalCount: 0,

  folder: {
    list: {
      meta: { lastUpdateAt: '', timestamp: 0 },
      data: { items: [] },
    },
  },
};

/** 양식 목록 전체 조회. */
const findFormAll = createAsyncThunk(
  `${NAME}/findFormAll`,
  async (_: void, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;

      const total = await systemLinkFormApi.totalCount({});
      const items = (
        await systemLinkFormApi.list({ pageNo: 1, rowsPerPage: total })
      ).map((v) => ({ ...v, checked: false }));

      return { meta, data: { items, totalCount: items.length } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 목록 조회. */
const findList = createAsyncThunk(
  `${NAME}/findList`,
  async (
    arg: {
      folderId?: number;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;

      const { folderId, route } = arg;
      const {
        pageNo = 1,
        rowsPerPage = 15,
        searchCode,
        searchWord,
      } = getQueryParams(route?.search ?? '');

      const totalCount = await systemLinkFormApi.totalCount({
        folderId,
        searchCode,
        searchWord,
      });

      const items = (
        await systemLinkFormApi.list({
          folderId,
          pageNo,
          rowsPerPage,
          searchCode,
          searchWord,
        })
      ).map((v) => ({ ...v, checked: false }));

      return { meta, data: { items, totalCount } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 조회 */
const findView = createAsyncThunk(
  `${NAME}/findView`,
  async (
    arg: {
      formId: number;
    },
    { rejectWithValue },
  ) => {
    try {
      let meta: CacheMeta | undefined;

      const { formId } = arg;
      const item = await systemLinkFormApi.view(formId);
      return { meta, data: { ...item, contents: item.content } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 생성. */
const createForm = createAsyncThunk(
  `${NAME}/createForm`,
  async (
    arg: {
      folderId: number;
      isEnable: boolean;
      name: string;
      description: string;
      content: string;
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { isEnable, name, description, content } = arg;
      await systemLinkFormApi.create({
        folderId: arg.folderId,
        isEnable,
        name,
        description,
        content,
      });

      const { route } = arg;
      if (route !== undefined) {
        const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
        const folderId =
          pathParams.p1 === undefined ? undefined : b62(pathParams.p1);
        await dispatch(findList({ folderId, route }));
      }
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 수정. */
const updateForm = createAsyncThunk(
  `${NAME}/updateForm`,
  async (
    arg: {
      id: number;
      folderId: number;
      isEnable: boolean;
      name: string;
      description: string;
      content: string;
      updateAt: string;
    } & LocateArg,
    { rejectWithValue },
  ) => {
    try {
      const { id, folderId, isEnable, name, description, content, updateAt } =
        arg;
      const form = await systemLinkFormApi.update({
        id,
        folderId,
        isEnable,
        name,
        description,
        content,
        updateAt,
      });
      return { folderId, isEnable, name, description, ...form };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

/** 양식 삭제. */
const deleteForm = createAsyncThunk(
  `${NAME}/deleteFolder`,
  async (
    arg: {
      data:
        | { id: number; updateAt: string }
        | { id: number; updateAt: string }[];
    } & LocateArg,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data, route } = arg;
      if (Array.isArray(data)) {
        const result = await systemLinkFormApi.delete(data);
        if (result.length !== data.length) {
          if (route !== undefined) {
            const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
            const folderId =
              pathParams.p1 === undefined ? undefined : b62(pathParams.p1);

            await dispatch(findList({ folderId, route }));
            return rejectWithValue(
              appError({
                error:
                  '사용 중인 양식이 존재하여 일부 양식은 삭제 실패하였습니다.',
              }),
            );
          }
        }
      } else await systemLinkFormApi.delete(data);

      if (route !== undefined) {
        const pathParams = getPathParams(`/*/*/*/:p1`, route.pathname);
        const folderId =
          pathParams.p1 === undefined ? undefined : b62(pathParams.p1);

        await dispatch(findList({ folderId, route }));
      }
    } catch (ex) {
      await dispatch(sessionActions.setDialog());
      return rejectWithValue(ex);
    }
  },
);

/** 폴더 목록 조회. */
const findFolderList = createAsyncThunk(
  `${NAME}/findFolderList`,
  async (_: LocateArg | void, { rejectWithValue }) => {
    try {
      let meta: CacheMeta | undefined;
      const items = await formFolderApi.fetchList();

      return { meta, data: { items } };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const formSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    checked(
      state,
      action: PayloadAction<{ id: number | 'all'; checked: boolean }>,
    ) {
      const { id, checked } = action.payload;
      if (state.items) {
        if (id === 'all') {
          state.items = state.items.map((x) => {
            if (x.checked === checked) return x;
            return { ...x, checked };
          });
        } else {
          const index = state.items.findIndex((x) => x.id === id);
          if (index > -1) {
            state.items[index] = {
              ...state.items[index],
              checked,
            };
          }
        }
      }
    },
    formListClear(state) {
      state.items = [];
    },
    formViewClear(state) {
      state.item = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findFolderList.fulfilled, (state, { payload }) => {
        if (payload?.meta !== undefined) state.folder.list.meta = payload.meta;
        if (payload?.data !== undefined) state.folder.list.data = payload.data;
      })
      .addCase(findView.fulfilled, (state, { payload }) => {
        if (payload !== undefined) state.item = payload.data;
      })
      .addCase(findFormAll.fulfilled, (state, { payload }) => {
        if (payload?.data !== undefined) {
          state.items = payload.data.items;
          state.totalCount = payload.data.totalCount;
        }
      })
      .addCase(findList.fulfilled, (state, { payload }) => {
        if (payload?.data !== undefined) {
          state.items = payload.data.items;
          state.totalCount = payload.data.totalCount;
        }
      })
      .addCase(updateForm.fulfilled, (state, { payload }) => {
        state.items = state.items.map((v) => {
          if (v.id === payload.id) return { ...v, ...payload };
          return v;
        });
      })
      .addMatcher(thunkPending, requestAppend)
      .addMatcher(thunkRejected, (state, action) => {
        const { requests } = state;
        const { requestId } = action.meta;
        // 비동기 요청 배열 중 요청 아이디가 있는 경우.
        if (asyncRequestContains(requests, requestId)) {
          // 요청 거부로 요청 배열 중 요청 아이디 제외.
          state.requests = requests.filter((x) => x.id !== requestId);
        }
      });
  },
});

export default formSlice.reducer;

export const formFolderActions = {
  list: findFolderList,
};

export const formActions = {
  clear: formSlice.actions.formListClear,
  viewClear: formSlice.actions.formViewClear,
  checked: formSlice.actions.checked,
  all: findFormAll,
  list: findList,
  view: findView,
  create: createForm,
  update: updateForm,
  delete: deleteForm,
};
