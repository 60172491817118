import React from 'react';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import EuiSetting from '../../../../components/layout/EuiSetting';
import PostView from '../../../../components/post/PostView';
import { getText } from '../../../../groupware-common/utils';
import { AuthorityType } from '../../../apis/calendar/v1/calendars';
import { CalendarSubFolderView as SubFolderView } from '../../../stores/calendar/calendars';

function CalendarSubFolderView(props: {
  paths: string[];
  view: SubFolderView;
  onUpdate(): void;
  onDelete(): void;
}): JSX.Element {
  const { paths, view, onUpdate, onDelete } = props;

  const valueToAutority = (value: AuthorityType) => {
    let authority = '';
    switch (value) {
      case 'COMPANY':
        authority = '회사';
        break;
      case 'ORGANIZATION':
        authority = '부서';
        break;
      case 'SUB_ORGANIZATION':
        authority = '부서 (하위부서 포함)';
        break;
      case 'EMPLOYEE':
        authority = '개인';
        break;
      default:
        authority = '';
        break;
    }
    return authority;
  };

  return (
    <>
      <EuiSetting.Header title="캘린더 정보">
        <Button
          text={getText('수정')}
          iconType
          icon="edit"
          onClick={onUpdate}
        />
        <Button
          text={getText('구독해지')}
          iconType
          icon="trash-full"
          onClick={onDelete}
        />
      </EuiSetting.Header>
      <EuiSetting.Content>
        <PostView>
          <PostView.Head>
            <Breadcrumbs className="view-path">
              {paths.map((text, i, array) => {
                const key = `i${i}/${text}`;
                const current = i === array.length - 1;
                return (
                  <Breadcrumbs.Item key={key} text={text} current={current} />
                );
              })}
            </Breadcrumbs>
          </PostView.Head>
          <PostView.Body>
            <PostView.Title>{view.name}</PostView.Title>
            <PostView.Category type="text">
              <PostView.CategoryList>
                <PostView.CategoryItem title="색상">
                  <Chip
                    style={{ height: '24px', padding: '0 12px' }}
                    color={view.color}
                    label=""
                  />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="설명">
                  <PostView.CategoryValue value={view.description} />
                </PostView.CategoryItem>
                <PostView.CategoryItem title="사용여부">
                  <PostView.CategoryValue
                    value={view.status ? '사용함' : '사용안함'}
                  />
                </PostView.CategoryItem>
                {view.systemId === 'ATTENDANCE' ? (
                  <>
                    <PostView.CategoryItem title="부서장 보기권한">
                      <PostView.CategoryValue
                        value={valueToAutority(
                          view.managerAuthority ?? 'SUB_ORGANIZATION',
                        )}
                      />
                    </PostView.CategoryItem>
                    <PostView.CategoryItem title="사원 보기권한">
                      <PostView.CategoryValue
                        value={valueToAutority(
                          view.generalUserAuthority ?? 'EMPLOYEE',
                        )}
                      />
                    </PostView.CategoryItem>
                  </>
                ) : (
                  <PostView.CategoryItem title="캘린더 내용">
                    <PostView.CategoryValue
                      value={
                        view.useExposeAnniversary
                          ? '공휴일 및 기타 휴일'
                          : '공휴일만'
                      }
                    />
                  </PostView.CategoryItem>
                )}
              </PostView.CategoryList>
            </PostView.Category>
          </PostView.Body>
        </PostView>
      </EuiSetting.Content>
    </>
  );
}

export default CalendarSubFolderView;
