import React from 'react';
import { getPathParams } from '../../groupware-common/utils';
import SettingInformationContainer from '../pages/root/setting/information/SettingInformationContainer';
import SettingPreferencesContainer from '../pages/root/setting/preferences/SettingPreferencesContainer';

function SettingLocateContainer(props: {
  pathname: string;
  search: string;
  locationKey: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, locationKey: key } = props;
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );

  // 기본 정보.
  if (folderId === 'information')
    return <SettingInformationContainer pathname={pathname} search={search} />;
  // 알림 설정
  // if (folderId === 'alarm') return <div>알림 설정</div>;
  // 환경 설정.
  return <SettingPreferencesContainer />;
}

export default SettingLocateContainer;
