import React, { useState } from 'react';
import Button from '../button/Button';
import Avatar from '../avatar/Avatar';
import { dateSimplify } from '../../groupware-common/utils/ui';
import Menu from '../menu/Menu';
import TextField from '../textfield/TextField';
import MenuItem from '../menu/MenuItem';
import CommentWrite from './CommentWrite';

export interface CommentItemProps {
  id: number;
  parentId: number;
  from?: {
    key: string;
    name: string;
  };
  message: string;
  writer: {
    key: string;
    name: string; // 이름
    organization: string; // 부서
    class: string; // 직위 혹은 직책
    avatar: string; // 아바타
  };
  user?: {
    key: string;
    name: string; // 이름
    organization: string; // 부서
    class: string; // 직위 혹은 직책
    avatar: string; // 아바타
  };
  createAt: string;
  updateAt: string;
  status?: 'delete';
  useOption: boolean; // 옵션 사용 여부 (수정, 삭제)
  children?: React.ReactNode;

  onAdd?(arg: {
    id?: number;
    parentId: number;
    message: string;
    parentWriterKey?: string;
  }): void;

  onDelete?(id: number, parentId: number): void;
}

function CommentItem(props: CommentItemProps): JSX.Element {
  const {
    id,
    parentId,
    from,
    message,
    writer,
    user,
    updateAt,
    status,
    useOption,
    onAdd,
    onDelete,
  } = props;

  const [state, setState] = useState<{
    point: { x: number; y: number; width: number; height: number };
    visible: boolean;
    writeView: boolean;
    changeView: boolean;
    message: string;
    selectedId: number;
  }>({
    point: { x: 0, y: 0, width: 0, height: 0 },
    visible: false,
    writeView: false,
    changeView: false,
    message,
    selectedId: 0,
  });

  const handleMenu = (event: React.MouseEvent) => {
    if (state.visible) handleClose();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        point: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        visible: true,
      }));
    }
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleOpenChange = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
      changeView: true,
    }));
  };

  const handleChangeMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      message: event.target.value,
    }));
  };

  const handleChange = () => {
    if (message !== '' && onAdd) {
      onAdd({
        id,
        parentId,
        message: state.message,
        parentWriterKey: from?.key,
      });
    }
    handleCancelChange();
  };

  const handleCancelChange = () => {
    setState((prevState) => ({
      ...prevState,
      changeView: false,
    }));
  };

  const handleDelete = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
    }));
    if (onDelete) onDelete(id, parentId);
  };

  // 답글 버튼 클릭시
  const handleOpenWrite = () => {
    setState((prevState) => ({
      ...prevState,
      writeView: true,
    }));
  };

  const handleCancelWrite = () => {
    setState((prevState) => ({
      ...prevState,
      writeView: false,
    }));
  };

  /** 답글 추가. */
  const handleReplyAdd = (comment: string) => {
    if (onAdd === undefined) return;

    // 답글의 답글 추가인 경우.
    if (parentId && id)
      onAdd({ parentId, message: comment, parentWriterKey: writer.key });
    // 답글 추가인 경우.
    else onAdd({ parentId: id, message: comment });
  };

  const fromName = from?.name || '';
  const htmlMessage = message.replaceAll('\n', '<br/>');
  return (
    <>
      <div className="comment-item">
        <div className="comment-content">
          <div className="head">
            <Avatar
              name={writer.name || '***'}
              image={writer.avatar || ''}
              className="comment-avatar"
            />
            <div className="user">
              {writer.name === '' ? (
                <strong>***</strong>
              ) : (
                <>
                  <strong>{writer.name}</strong>
                  <em>{writer.class}</em>
                </>
              )}
            </div>
          </div>
          {state.changeView ? (
            <div className="body">
              <div className="comment-change">
                <TextField
                  count
                  maxLength={500}
                  multiline
                  value={state.message}
                  onChange={handleChangeMessage}
                />
                <div className="change-action">
                  <Button
                    text="수정"
                    variant="contained"
                    size="sm"
                    onClick={handleChange}
                  />
                  <Button text="취소" size="sm" onClick={handleCancelChange} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="body">
                <p className="comment-text">
                  {fromName !== '' ? <em>@{fromName}</em> : null}
                  {status === 'delete' ? (
                    <del>삭제된 댓글입니다.</del>
                  ) : (
                    // eslint-disable-next-line react/no-danger
                    <span dangerouslySetInnerHTML={{ __html: htmlMessage }} />
                  )}
                </p>
              </div>
              <div className="footer">
                <time className="time" title={dateSimplify(updateAt, 'full')}>
                  {dateSimplify(updateAt)}
                </time>
                {onAdd && (
                  <Button
                    text="답글"
                    onClick={handleOpenWrite}
                    color="secondary"
                    size="xs"
                  />
                )}
              </div>
            </>
          )}
          {/** 답글쓰기 */}
          {state.writeView && user && onAdd && (
            <CommentWrite
              user={user}
              onAdd={handleReplyAdd}
              onCancel={handleCancelWrite}
            />
          )}
          {useOption && onAdd && (
            <Button
              text="옵션"
              iconType
              icon="ellipsis-h"
              className="comment-option"
              onClick={handleMenu}
              color="secondary"
            />
          )}
        </div>
        {props.children && (
          <div className="comment-replies">{props.children}</div>
        )}
      </div>
      {state.visible && (
        <Menu point={state.point} onClose={handleClose} size="xs">
          <MenuItem label="수정" icon="edit" onClick={handleOpenChange} />
          <MenuItem label="삭제" icon="trash-full" onClick={handleDelete} />
        </Menu>
      )}
    </>
  );
}

export default CommentItem;
