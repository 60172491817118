import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../groupware-common/apis/common/v1';

async function findPreferences(arg: { companyId: number }): Promise<{
  initialModule: string;
  updateAt: string;
}> {
  try {
    const { companyId } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/general`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

async function update(arg: {
  companyId: number;
  initialModule: string;
  updateAt: string;
}): Promise<{
  initialModule: string;
  updateAt: string;
}> {
  try {
    const { companyId, ...data } = arg;
    const { headers, host } = getApiConfig();
    const url = `${host}/api/preferences/v1/adminconsole/companies/${companyId}/general`;
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const serviceBasicApi = {
  findPreferences,
  update,
};

export default serviceBasicApi;
