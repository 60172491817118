import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import EmptyData from '../../../../../components/data/EmptyData';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import SelectField from '../../../../../components/selectField/SelectField';
import Pagination from '../../../../../groupware-approval/pages/root/approval/common/components/Pagination';
import { Column } from '../../../../../groupware-common/ui/type';
import { getQueryParams, go } from '../../../../../groupware-common/utils';
import { timezoneDate } from '../../../../../groupware-common/utils/ui';
import {
  getOrganizationName,
  useDirectory,
} from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import AttendanceContentBodyListItem from '../content/AttendanceContentBodyListItem';
import EuiToolbar from '../../../../../components/layout/EuiToolbar';
import AttendanceDayOffDialog from '../../../common/AttendanceDayOffDialog';
import { AccountDayOffStatusListItem } from '../../../../stores/attendance/dayOffStatus';

function AttendanceDayOffAccountStatusContainer(props: {
  pathname: string;
  search: string;
  title: string;
}): JSX.Element {
  const { pathname, search, title } = props;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const directory = useDirectory();

  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const organizationIds = principal.affiliatedOrganizations
    .filter((a) => a.manager === true)
    .map(({ id }) => id);
  const list = useSelector(
    (state: RootState) => state.attendance.dayOffStatus.list,
  );
  const items = list.items as AccountDayOffStatusListItem[];
  const { totalCount } = list;
  const currentYear = timezoneDate().getFullYear();

  /** (부서장 권한 존재) 겸직부서일 경우 겸직부서 리스트.  */
  const selectData = organizationIds.map((a) => {
    const organizationName = getOrganizationName(principal.companyId, a);
    return {
      value: `${principal.companyId}_${a}`,
      label: organizationName,
    };
  });

  const columns: Column<PostListItemType>[] = [
    // eslint-disable-next-line prettier/prettier
    { name: 'employeeNo', text: '사번', type: display === 'phone' ? 'post' : 'form', visible: true },
    { name: 'user', text: '이름', type: 'user', visible: true, disable: true },
    { name: 'enteringDate', text: '입사일자', type: 'date', visible: true },
    { name: 'years', text: '근속연수', type: 'post', visible: true },
    { name: 'days', text: '근속일수', type: 'post', visible: true },
    { name: 'revision', text: '월차', type: 'post', visible: true },
    { name: 'occured', text: '연차', type: 'post', visible: true },
    { name: 'total', text: '총연차', type: 'post', visible: true },
    { name: 'extinction', text: '소멸', type: 'post', visible: true },
    { name: 'transfer', text: '이월', type: 'post', visible: true },
    { name: 'adjust', text: '조정', type: 'post', visible: true },
    { name: 'use', text: '사용', type: 'post', visible: true },
    { name: 'rest', text: '잔여', type: 'post', visible: true },
  ];

  const selectedYearOptions: { value: string; label: string }[] = [];
  const getYear = currentYear - 4;
  for (let i = 0; i < 5; i += 1) {
    const value = getYear + i;
    selectedYearOptions.push({
      value: value.toString(),
      label: value.toString(),
    });
  }
  selectedYearOptions.push({
    value: (currentYear + 1).toString(),
    label: (currentYear + 1).toString(),
  });

  const [selectedYear, setSelectedYear] = useState(
    queryParams.status ?? currentYear.toString(),
  );

  const handleChangeFilter = (value: string) => {
    setSelectedYear(value);
  };

  /** 검색. */
  const handleSearch = () => {
    delete queryParams.pageNo;
    queryParams.status = selectedYear;

    go(pathname, getQueryParams(queryParams));
  };

  /** 헤드 액션 */
  const handleAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;

    // 이전 페이지인 경우.
    if (code === 'prevPage') {
      const pageNo = (queryParams.pageNo || 1) - 1;
      if (pageNo > 0) {
        if (pageNo > 1) queryParams.pageNo = pageNo;
        else delete queryParams.pageNo;
        go(pathname, queryParams);
      }
      return;
    }
    // 다음 페이지인 경우.
    if (code === 'nextPage') {
      const pageNo = (queryParams.pageNo || 1) + 1;
      if ((queryParams.rowsPerPage || 1 * pageNo) < totalCount) {
        queryParams.pageNo = pageNo;
        go(pathname, queryParams);
      }
    }
  };

  const handleDialogClose = () => {
    dispatch(sessionActions.setDialog());
  };

  /** 겸직부서일 경우 부서 변경. */
  const handleChange = (value: string) => {
    delete queryParams.pageNo;
    queryParams.directoryKeyword = value;

    go(pathname, getQueryParams(queryParams));
  };

  /** 사용 연차 상세정보 대화상자 열기. */
  const handleStatusClick = (name: string, month: string, id?: number) => {
    if (id === undefined) return;
    queryParams.dialogType = 'detail';
    queryParams.id = id;
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedYear(queryParams.status ?? currentYear.toString());
    scrollbar.current?.scrollTo(0, 0);
  }, [pathname, search]);

  const renderDialog = () => {
    const { dialogType } = queryParams;

    if (dialogType === 'detail') {
      return (
        <AttendanceDayOffDialog
          type="account"
          search={search}
          onClose={handleDialogClose}
        />
      );
    }
    // 오류.
    return null;
  };

  const renderHead = () => {
    return (
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
        <EuiHeader.Search>
          <div className="eui-search">
            <div className="basic-search">
              <SelectField
                style={{ flexGrow: 1 }}
                data={selectedYearOptions}
                value={selectedYear}
                onChange={handleChangeFilter}
                className="filter"
              />
              <Button
                text="검색"
                iconType
                icon="search"
                className="submit"
                onClick={handleSearch}
              />
            </div>
          </div>
        </EuiHeader.Search>
        <EuiHeader.Toolbar>
          <EuiToolbar.Left>
            <></>
          </EuiToolbar.Left>
          <EuiToolbar.Right>
            {totalCount > 0 && (
              <Pagination
                no={queryParams.pageNo ?? 1}
                rows={queryParams.rowsPerPage ?? 15}
                count={totalCount}
                onPrev={(event) => handleAction({ code: 'prevPage', event })}
                onNext={(event) => handleAction({ code: 'nextPage', event })}
              />
            )}
          </EuiToolbar.Right>
        </EuiHeader.Toolbar>
      </EuiHeader>
    );
  };

  const renderBody = () => {
    const message = '검색된 데이터가 없습니다.';

    return items.length > 0 ? (
      items.map((data, i) => {
        const a = data as AccountDayOffStatusListItem;
        const employeeNo =
          directory.employees.find(({ id }) => id === a.employeeId)?.no ?? ''; // 사번.
        const directoryData = getDirectoryData({
          ...directory,
          companyId: a.companyId,
          organizationId: queryParams.directoryKeyword
            ? Number(queryParams.directoryKeyword.split('_')[1])
            : Number(selectData[0].value.split('_')[1]),
          employeeId: a.employeeId,
        });

        const item = {
          employeeNo,
          user: {
            name: directoryData.employeeName,
            organization: directoryData.organizationName,
            job: directoryData.jobPositionName,
            avatar: directoryData.avatar ?? '',
          },
          enteringDate: a.enterDate,
          years: `${a.workingYears}년`,
          days: a.workingDays,
          revision: a.revisionOccuredLeavesCount,
          occured: a.occuredLeavesCount,
          total: a.totalOccuredLeavesCount,
          extinction: a.lapseLeavesCount,
          transfer: a.carryoverLeavesCount,
          adjust: a.modifiedLeavesCount,
          use: a.usedLeavesCount,
          rest: a.remainedLeavesCount,
        };
        const key = `/i${i}/${a.companyId}/${a.employeeId}/}`;
        return (
          <AttendanceContentBodyListItem
            organizationStatus
            dayOff
            key={key}
            id={a.employeeId}
            columns={columns}
            item={item}
            onStatusClick={handleStatusClick}
          />
        );
      })
    ) : (
      <EmptyData message={message} />
    );
  };

  return (
    <>
      {renderHead()}
      <EuiToolbar>
        <EuiToolbar.Left>
          {/* 겸직자일 경우 - 겸직부서 선택 가능. */}
          {organizationIds.length > 1 && (
            <SelectField
              data={selectData}
              value={queryParams.directoryKeyword ?? selectData[0].value}
              onChange={handleChange}
            />
          )}
        </EuiToolbar.Left>
      </EuiToolbar>
      <EuiBody>
        <PostList
          name={title ?? ''}
          type="full"
          style={display !== 'phone' ? { minWidth: '1510px' } : undefined}
        >
          <PostList.Head>
            {columns.map(({ name, type: colunmType, text }) => (
              <PostList.Cell
                key={name}
                name={text}
                type={colunmType}
                style={
                  colunmType === 'post' && display !== 'phone'
                    ? {
                        minWidth: '110px',
                        width: '7%',
                        justifyContent: 'center',
                      }
                    : undefined
                }
              />
            ))}
          </PostList.Head>
          <PostList.Body scrollableNodeProps={scrollbar}>
            {renderBody()}
          </PostList.Body>
        </PostList>
      </EuiBody>
      {renderDialog()}
    </>
  );
}

export default AttendanceDayOffAccountStatusContainer;
