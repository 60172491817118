import axios from 'axios';
import {
  apiError,
  getApiConfig,
} from '../../../../../groupware-common/apis/common/v1';
import { Language } from '../../../../../groupware-common/types';

export type DesignType = 'LIGHT' | 'DARK' | 'SYSTEM';

export interface PreferenceItem {
  language: Language;
  timeZone: number;
  designTheme: DesignType;
  colorTheme: string;
  updateAt: string;
}

export const initialPreference = (arg: {
  primaryColor?: string;
  theme?: DesignType;
}): PreferenceItem => {
  const { primaryColor, theme } = arg;
  return {
    language: 'ko-KR',
    timeZone: 9,
    designTheme: theme ?? 'SYSTEM',
    colorTheme: primaryColor ?? '8a48fd',
    updateAt: '1000-01-01T00:00:00.000000',
  };
};

/** 개인 환경설정 조회. */
async function fetchSetting(arg: {
  companyId: number;
  employeeId: number;
}): Promise<PreferenceItem> {
  try {
    const { companyId, employeeId } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${companyId}/employees/${employeeId}/personal`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 개인 환경설정 저장. */
async function saveSetting(arg: {
  companyId: number;
  employeeId: number;
  data: {
    language?: Language;
    timeZone?: number;
    designTheme?: DesignType;
    colorTheme?: string;
    updateAt: string;
  };
}): Promise<PreferenceItem> {
  try {
    const { companyId, employeeId, data } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${companyId}/employees/${employeeId}/personal`;
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 색상 테마 저장. */
async function saveThemeColor(arg: {
  companyId: number;
  employeeId: number;
  colorTheme: string;
}): Promise<PreferenceItem> {
  try {
    const { companyId, employeeId, colorTheme } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${companyId}/employees/${employeeId}/personal/color`;
    const response = await axios.post(url, { colorTheme }, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 디자인 테마 저장. */
async function saveThemeMode(arg: {
  companyId: number;
  employeeId: number;
  designTheme: DesignType;
}): Promise<PreferenceItem> {
  try {
    const { companyId, employeeId, designTheme } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${companyId}/employees/${employeeId}/personal/design`;
    const response = await axios.post(url, { designTheme }, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

/** 사용자 언어 저장.  */
async function saveLanguage(arg: {
  companyId: number;
  employeeId: number;
  language: string;
}): Promise<PreferenceItem> {
  try {
    const { companyId, employeeId, language } = arg;
    const { host, headers } = getApiConfig();
    const url = `${host}/api/directory/v1/companies/${companyId}/employees/${employeeId}/personal/language`;
    const response = await axios.post(url, { language }, { headers });
    return response.data;
  } catch (e) {
    throw apiError(e);
  }
}

const settingApi = {
  fetchSetting,
  saveSetting,
  saveThemeColor,
  saveThemeMode,
  saveLanguage,
};

export default settingApi;
