/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../../../../components/button/Button';
import TextField from '../../../../../components/textfield/TextField';
import PostWrite from '../../../../../components/post/PostWrite';
import {
  getParentItems,
  getPathMap,
  getQueryParams,
  go,
  goBack,
  utils,
} from '../../../../../groupware-common/utils';
import { FileUploadProps } from '../../../../../groupware-common/types';
import AddAttachDocument from '../../../../../components/attachments/AddAttachDocument';
import ApprovalAttachedDocumentSelectDrawerContainer from '../../../../../groupware-approval/pages/root/approval/compose/ApprovalAttachedDocumentSelectDrawerContainer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ApprovalLineDialogContainer, {
  ApprovalLineGroupItemEmployeeType,
  ApprovalLineGroupItemOrganizationType,
  ApprovalLineGroupItemsAgreeType,
  ApprovalLineGroupItemsApprovalType,
  ApprovalLineType,
  getApprovalLineDrafter,
  getLastApprover,
  SharePermissionType,
} from '../../../../../groupware-approval/pages/common/dialogs/ApprovalLineDialogContainer';
import {
  getDirectoryData,
  getName,
} from '../../../../../groupware-webapp/stores/common/utils';
import DraftRequestDialogContainer, {
  DraftRequestConfirmArg,
} from '../../../../../groupware-approval/pages/root/approval/compose/DraftRequestDialogContainer';
import {
  directoryEqualityFn,
  directorySelector,
  getOrganizationName,
} from '../../../../../groupware-directory/stores/directory';
import { ApiError } from '../../../../../groupware-common/types/error';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';
import {
  dateFormat,
  dateTimeFormat,
  initialDate,
  timeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import { approvalActText } from '../../../../../groupware-approval/pages/common/drawers/ApprovalStatusDrawer';
import MemoizeDivElement from '../../../../../groupware-approval/pages/root/approval/common/components/MemoizeDivElement';
import ApprovalLineFlat from '../../../../../groupware-approval/pages/common/components/ApprovalLineFlat';
import { getJobClassName } from '../../../../../groupware-directory/apis/directory/v1';
import FeedBack from '../../../../../components/alert/FeedBack';
import { getDirectoryTreeItems } from '../../../../../components/tree/DirectoryTree';
import ApprovalAttachedDocumentSelectDialog from '../../../../../groupware-approval/pages/root/approval/compose/ApprovalAttachedDocumentSelectDialog';
import { attachedDocumentActions } from '../../../../../groupware-approval/stores/approval/attachedDocument';
import AttendanceComposeWorkSelectContainer, {
  AttendanceComposeWorkSelectConfirmArg,
} from './AttendanceComposeWorkSelectContainer';
import attendancesApi from '../../../../apis/attendance/v1/documents';
import attendanceWorkApi from '../../../../apis/attendance/v1/work';
import { documentActions } from '../../../../../groupware-approval/stores/approval/document';
import attendanceFormApi from '../../../../apis/attendance/v1/form';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import dayOffStatusApi from '../../../../apis/attendance/v1/dayOffStatus';
import AttendancePreferencesApi from '../../../../apis/attendance/v1/preferences';
import NavigationGuard from '../../../../../components/prompt/NavigationGuard';
import { history } from '../../../../..';
import AddProgressAttachments from '../../../../../components/attachments/AddProgressAttachments';
import fileApi from '../../../../../groupware-common/apis/file/v1';

type Props = {
  pathname: string;
  search: string;
};

/** 채번 전 문서번호 매크로 표시 replace */
function documentMacroReplace(documentNo: string): string {
  return documentNo
    .replace(/{년도}/gm, `년도`)
    .replace(/{월}/gm, `월`)
    .replace(/{순번}/gm, '');
}

/** 매크로 필수 입력값 입력안했을 경우 */
function createComposeStyle(
  data: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
) {
  const element = data;
  element.focus();
  element.style.background = '#ffebee';
}

function AttendanceComposeContainer(props: Props): JSX.Element {
  /** 최상위 부서 찾기. */
  const findTopLevelOrganization = (arg: {
    organizations: { companyId: number; id: number; parentId: number }[];
    companyId: number;
    id: number;
  }) => {
    const { organizations, companyId, id } = arg;
    const parents = getParentItems(organizations, id);

    if (parents.length > 1 && parents[1].id !== id) {
      return {
        id: parents[1].id,
        name: getOrganizationName({ companyId, organizationId: parents[1].id }),
      };
    }
    return undefined;
  };

  /** 문서번호 변경. */
  const replaceDocumentNo = (arg: {
    documentNo: string;
    workName: string;
    formName: string;
    organizationName: string;
    employeeName: string;
  }) => {
    const { documentNo, workName, formName, organizationName, employeeName } =
      arg;
    return documentNo
      .replace(/{(업무명)}/gm, workName)
      .replace(/{(양식명)}/gm, formName)
      .replace(/{(조직명)}/gm, organizationName)
      .replace(/{(기안자)}/gm, employeeName)
      .replace(/┼/gm, '');
  };

  /** 결재상신 시 업로드 파일 객체 생성 */
  const createUploadFiles = (attachedFiles: FileUploadProps[]) => {
    return attachedFiles
      .filter(
        (a): a is { id: string; name: string; size: number; file: File } =>
          a.file !== undefined,
      )
      .map((a) => {
        return {
          id: parseInt(a.id, 10),
          file: a.file,
        };
      });
  };
  /** 첨부파일 객체 생성 */
  const createAttachedFiles = (data: {
    attachedFiles: FileUploadProps[];
    isReDraftDocuments?: boolean;
    documentId?: number;
    uploadPath: string;
  }): {
    path: string;
    id: number;
    documentId?: number;
    seq: number;
    name: string;
    size: number;
    delete?: boolean;
    copy?: boolean;
  }[] => {
    const { attachedFiles, isReDraftDocuments, documentId, uploadPath } = data;

    return attachedFiles.map((a, i) => {
      return {
        path: a.file !== undefined ? uploadPath : '',
        id: parseInt(a.id, 10),
        documentId:
          isReDraftDocuments && a.file === undefined && documentId
            ? documentId
            : undefined,
        seq: i + 1,
        name: a.name,
        size: a.size,
        copy: isReDraftDocuments && a.file === undefined ? true : undefined,
      };
    });
  };

  /** 결재그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineApprovalFiltered = (
    items: ApprovalLineGroupItemsApprovalType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 합의그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineAgreeFiltered = (
    items: ApprovalLineGroupItemsAgreeType,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재,합의 제외 그룹 결재선 생성 시 조직도와 일치하지 않는 정보 필터링 */
  const createApprovalLineDefaultFiltered = (
    items: Array<
      ApprovalLineGroupItemOrganizationType | ApprovalLineGroupItemEmployeeType
    >,
  ) => {
    return items.filter((item) =>
      directoryTreeItems.find(
        (z) =>
          z.id ===
          (item.employeeId !== undefined
            ? `${item.companyId}_${item.organizationId}_${item.employeeId}`
            : `${item.companyId}_${item.organizationId}`),
      ),
    );
  };

  /** 결재선 생성 시 직원 정보 생성. */
  const createApprovalLineEmployeeData = (data: {
    companyId: number;
    organizationId?: number;
    employeeId: number;
  }) => {
    const { companyId, organizationId, employeeId } = data;
    const employeeData = getDirectoryData({
      ...directory,
      companyId,
      organizationId,
      employeeId,
    });
    return {
      companyId: employeeData.companyId,
      organizationId: employeeData.organizationId,
      employeeId: employeeData.employeeId,
      companyName: employeeData.companyName,
      organizationName: employeeData.organizationName,
      employeeName: employeeData.employeeName,
      jobPositionId: employeeData.jobPositionId,
      jobPositionName: employeeData.jobPositionName,
      jobClassType: employeeData.jobClassType,
      jobDutyId: employeeData.jobDutyId,
      jobDutyName: employeeData.jobDutyName,
      avatar: employeeData.avatar,
    };
  };

  const getOrganizationData = ({
    companyId,
    organizationId,
  }: {
    companyId: number;
    organizationId: number;
  }) => {
    const { organizations } = directory;
    return {
      companyId,
      companyName: getName(organizations, companyId, companyId, ''),
      organizationId,
      organizationName: getName(organizations, companyId, organizationId, ''),
    };
  };

  /** 결재선 생성 시 조직 정보 생성. */
  const createApprovalLineOrganizationData = (
    companyId: number,
    organizationId: number,
  ) => {
    const organizationData = getOrganizationData({
      companyId,
      organizationId,
    });
    return {
      companyId: organizationData.companyId,
      organizationId: organizationData.organizationId,
      companyName: organizationData.companyName,
      organizationName: organizationData.organizationName,
    };
  };

  /** 00:00 형태 string 시간,분으로 변환. */
  function stringToTime(date: string) {
    const start = parseInt(date.split(':')[0], 10);
    const end = parseInt(date.split(':')[1], 10);
    return { start, end };
  }

  /** 날짜계산 후 분으로 환산. */
  function getTimeToMinutes(startDate: Date, endDate: Date) {
    const minutes = (endDate.getTime() - startDate.getTime()) / 1000 / 60;
    return minutes;
  }

  const queryParams = getQueryParams(props.search);

  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const organizationIds = principal.affiliatedOrganizations.map(({ id }) => id);
  const directory = useSelector(directorySelector, directoryEqualityFn);
  const directoryTreeItems = useMemo(
    () => getDirectoryTreeItems({ ...directory }),
    [directory],
  );
  const isExists = useSelector(
    (state: RootState) => state.attendance.preferences.standard.isExists,
  );
  const attendanceCodeList = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );
  const workingHours = useSelector(
    (state: RootState) => state.attendance.preferences.workingHours,
  );
  const holiday = useSelector(
    (state: RootState) => state.attendance.holiday.list.data.items,
  );

  const { start: fixedWorkHours, end: fixedWorkMins } = stringToTime(
    workingHours.workTime,
  );
  const { start: fixedOffHours, end: fixedOffMins } = stringToTime(
    workingHours.offTime,
  );

  /** 현재 날짜와 일치하는 휴일 찾기. */
  function findHoliday(date: Date) {
    return holiday.some((a) => moment(a.holiday).isSame(date, 'days'));
  }

  const companyView = useSelector((s: RootState) => {
    return s.directory.company.view.data;
  });

  const employeeView = useSelector((s: RootState) => {
    return s.directory.employee.view.data;
  });

  // 최상위 조직 이름 사용 여부.
  const useTopLevelOrganization = useSelector(
    (state: RootState) =>
      state.approval2.preferences.documentNo?.useTopLevelOrganization ?? false,
  );
  const {
    numberOfAttachments: maxFileCount,
    attachmentsCapacity: maxFileCapacity,
  } = useSelector((state: RootState) => state.approval2.preferences.basic);

  const contentRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<{
    builder: FormBuilder;
    validation: string;
    contenttype: 'select' | 'compose';
    drawertype?: 'attacheddocument';
    dialogtype:
      | 'draftrequest'
      | 'approvalline'
      | 'changesave'
      | 'attacheddocumentdialog'
      | undefined;
    subject: string;

    // 개발
    draftOrganizationId: number;
    draftTopLevelOrganizationId: number;
    draftTopLevelOrganizationName?: string;
    workId: number;
    formId: number;
    attendanceCode: number; // 근태 코드.
    workName: string;
    formName: string;
    documentNo: string;
    retentionPeriod: number;
    contents: string;
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
    attachedFiles: FileUploadProps[];
    uploadPath: string;
    attachedDocuments: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[];
    useAttachFile: number; // 첨부 파일 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
    useAttachDocument: number; // 첨부 문서 사용 여부 - 0: 사용 안 함, 1: 사용, 2: 필수',
    useOpinion: boolean; // 의견 사용 여부 - 0: 사용 안 함, 1: 사용',
    useComment: boolean; // 댓글 사용 여부 - 0: 사용 안 함, 1: 사용',

    workDocumentNo?: string; // 업무 문서 번호.
    drawerAttachedCompanyId?: number; // 첨부문서 추가 시 팝업 조회 문서 정보.
    drawerAttachedId?: number; // 첨부문서 추가 시 팝업 조회 문서 정보.
    drawerAttachedSelected?: boolean; // 첨부문서 추가 시 팝업 조회 문서 정보.
    ///
    errors: ApiError[];
    attendanceMacros: {
      start: string;
      end: string;
      workTime: string;
      offTime: string;
      dayOff: string; // 연차 타입 (기간)
      dayOffTotalTime: string; // 연차 사용 시간 (단일 연차)
      reason: string;
      rangeStart: string; // 기간_시작일
      rangeEnd: string; // 기간_종료일
    };

    remain: string; // 잔여연차.
    substitute: string; // 잔여대휴.

    isDayOffCreated: boolean; // 연차 생성여부.
  }>(() => {
    const builder = new FormBuilder();

    let contenttype: 'select' | 'compose';

    if (queryParams.contentMode === 'create') contenttype = 'select';
    else contenttype = 'compose';
    let workDocumentNo: string | undefined;
    const draftOrganizationId = principal.organizationId;
    const approvalLine: ApprovalLineType = { version: '0.1', groups: [] };
    let draftTopLevelOrganizationName: string | undefined;

    return {
      builder,
      validation: '',
      dialogtype: undefined,

      subject: '',

      // 개발
      contenttype,
      // workselect --
      draftOrganizationId,
      draftTopLevelOrganizationId: 0,
      draftTopLevelOrganizationName,
      workId: 0,
      formId: 0,
      workName: '',
      formName: '',
      attendanceCode: 0,
      documentNo: '',
      retentionPeriod: 0,
      contents: '',
      approvalLine,
      referencePermission: undefined,
      viewPermission: undefined,
      // -- workselect

      attachedFiles: [],
      uploadPath: `${Date.now()}`,
      attachedDocuments: [],
      drawerAttachedCompanyId: undefined,
      drawerAttachedId: undefined,
      drawerAttachedSelected: undefined,

      useAttachFile: 0,
      useAttachDocument: 0,
      useOpinion: false,
      useComment: false,

      workDocumentNo,
      ///
      errors: [],
      attendanceMacros: {
        start: '',
        end: '',
        workTime: '',
        offTime: '',
        dayOff: '',
        dayOffTotalTime: '',
        reason: '',
        rangeStart: '',
        rangeEnd: '',
      },

      remain: '',
      substitute: '',
      isDayOffCreated: false,
    };
  });
  const [reload, setReload] = useState(true);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록; deprecated
  };

  useEffect(() => {
    if (state.contenttype === 'compose') {
      setReload(true);
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();
      if (reload) {
        (() => {
          window.addEventListener('beforeunload', handlePrevClose);
        })();

        return () => {
          window.removeEventListener('beforeunload', handlePrevClose);
        };
      }
      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    setReload(false);
    return undefined;
  }, [state.contenttype]);

  useEffect(() => {
    async function remainCount(employeeId: number) {
      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();
      const remain = isExists
        ? await dayOffStatusApi.remainCount({ employeeId })
        : 0;
      const subs = await attendancesApi.substituteList({
        standardYear: timezoneDate().getFullYear().toString(),
        searchCode: 'employee',
        searchWord: `${principal.companyId}_${principal.organizationId}_${principal.employeeId}`,
        pageNo: 1,
        rowsPerPage: 15,
      });
      setState((prev) => ({
        ...prev,
        remain: timeFormat(remain, expressionUnit),
        substitute: timeFormat(
          subs.length > 0 ? subs[0].remainedAlternative : 0,
          expressionUnit,
        ),
        isDayOffCreated: isExists,
      }));
    }
    remainCount(principal.employeeId);
  }, []);

  /** 시작일, 종료일의 일수 차이 계산  */
  const handleDayDiff = (
    startDateFormat: Date,
    endDateFormat: Date,
  ): number => {
    const timeDiff = moment(endDateFormat).diff(startDateFormat, 'days') + 1;
    return timeDiff;
  };

  /** 매크로 타입과 근태 기간을 입력 받아 분 단위로 사용 시간을 계산 */
  const handleUseMinutesFromDayOff = (
    operationType: number | undefined, // 근태 기능 구분 코드
    startDateFormat: Date,
    endDateFormat: Date,
  ): number => {
    const {
      dayOff,
      dayOffTotalTime,
      rangeStart: macroRangeStart,
      rangeEnd: macroRangeEnd,
      start: macroStart,
      end: macroEnd,
      workTime: macroWorkTime,
      offTime: macroOffTime,
    } = state.attendanceMacros;

    const { totalWorkTime } = workingHours;

    // 근태 매크로가 연차인 경우.
    if (operationType === 1) {
      // 단일 시간 연차
      if (dayOffTotalTime !== '') return parseInt(dayOffTotalTime, 10);
      if (dayOff === '연차') {
        const days = handleDayDiff(startDateFormat, endDateFormat);
        let totalDays = days;
        for (let i = 0; i < days; i += 1) {
          const newDate = new Date(startDateFormat);
          newDate.setDate(newDate.getDate() + i);
          if (findHoliday(newDate)) totalDays -= 1;
        }
        return 480 * totalDays;
      }
      // 근태 매크로가 반차일 때
      if (dayOff === '오전반차' || dayOff === '오후반차') {
        return 240;
      }
    }
    if (macroRangeStart !== '' && macroRangeEnd !== '') {
      const days = handleDayDiff(startDateFormat, endDateFormat);
      let totalDays = days;
      for (let i = 0; i < days; i += 1) {
        const newDate = new Date(startDateFormat);
        newDate.setDate(newDate.getDate() + i);
        /** 근태코드가 대휴발생이 아니면서 기간 중 휴일이 존재할 경우 휴일 제외. */
        if (operationType !== 3 && findHoliday(newDate)) totalDays -= 1;
      }
      if (operationType === 3) return totalDays * (24 * 60);
      return totalDays * totalWorkTime;
    }
    if (macroStart !== '' || macroEnd !== '') {
      if (macroWorkTime !== '' && macroOffTime !== '') {
        const { start: macroWorkHour, end: macroWorkMinutes } =
          stringToTime(macroWorkTime);
        const { start: macroOffHour, end: macroOffMinutes } =
          stringToTime(macroOffTime);
        let workStartDate = new Date(startDateFormat);
        let workEndDate = new Date(endDateFormat);
        // 근태 기능 작동이 없을 경우 입력받은 시간이 고정 출.퇴근시간 외일 경우 고정 출.퇴근시간으로 세팅
        if (operationType !== 3) {
          const sfixedWorkTime = new Date(startDateFormat);
          sfixedWorkTime.setHours(fixedWorkHours, fixedWorkMins);
          const sfixedOffTime = new Date(startDateFormat);
          sfixedOffTime.setHours(fixedOffHours, fixedOffMins);
          const efixedWorkTime = new Date(endDateFormat);
          efixedWorkTime.setHours(fixedWorkHours, fixedWorkMins);
          const efixedOffTime = new Date(endDateFormat);
          efixedOffTime.setHours(fixedOffHours, fixedOffMins);

          if (moment(workStartDate).isBefore(sfixedWorkTime))
            workStartDate = sfixedWorkTime;
          else if (moment(workStartDate).isAfter(sfixedOffTime))
            workStartDate = sfixedOffTime;

          if (moment(workEndDate).isBefore(efixedWorkTime))
            workEndDate = efixedWorkTime;
          else if (moment(workEndDate).isAfter(efixedOffTime))
            workEndDate = efixedOffTime;
        }
        const totalday = moment(workEndDate).diff(workStartDate, 'days'); // 종료일 - 시작일.
        /** 시작일 다음날이 종료일인 경우와 시작일과 종료일이 이틀 이상 차이나는 경우 */
        if (totalday >= 1) {
          let time1 = 0;
          let time2 = 0;
          workingHours.breakeTime.forEach((a) => {
            const { start: startHours, end: startMins } = stringToTime(a.start);
            const { start: endHours, end: endMins } = stringToTime(a.end);
            const workBreakeStart = new Date(workStartDate);
            workBreakeStart.setHours(startHours, startMins);
            const workBreakeEnd = new Date(workStartDate);
            workBreakeEnd.setHours(endHours, endMins);

            const offBreakeStart = new Date(workEndDate);
            offBreakeStart.setHours(startHours, startMins);
            const offBreakeEnd = new Date(workEndDate);
            offBreakeEnd.setHours(endHours, endMins);

            /** 근무시간 안에 해당하는 휴게시간일 경우. */
            if (
              fixedWorkHours * 60 + fixedWorkMins <
                startHours * 60 + startMins &&
              endHours * 60 + endMins < fixedOffHours * 60 + fixedOffMins
            ) {
              // 출근시간 <= 휴게시작시간
              if (moment(workStartDate).isSameOrBefore(workBreakeStart))
                time1 += getTimeToMinutes(workBreakeStart, workBreakeEnd);
              // 휴게시작시간 < 출근시간 < 휴게종료시간
              if (
                moment(workStartDate).isBetween(workBreakeStart, workBreakeEnd)
              )
                time1 += getTimeToMinutes(workStartDate, workBreakeEnd);
              // 휴게종료시간 <= 퇴근시간
              if (moment(offBreakeEnd).isSameOrBefore(workEndDate))
                time2 += getTimeToMinutes(offBreakeStart, offBreakeEnd);
              // 휴게시작시간 < 퇴근시간 < 휴게종료시간
              if (moment(workEndDate).isBetween(offBreakeStart, offBreakeEnd))
                time2 += getTimeToMinutes(offBreakeStart, workEndDate);
            }
          });
          let first =
            (fixedOffHours - macroWorkHour) * 60 +
            (fixedOffMins - macroWorkMinutes) -
            time1;
          let last =
            (macroOffHour - fixedWorkHours) * 60 +
            (macroOffMinutes - fixedWorkMins) -
            time2;
          if (operationType === 3) {
            first = (24 - macroWorkHour) * 60 - macroWorkMinutes;
            last = macroOffHour * 60 + macroOffMinutes;
          } else if (findHoliday(workStartDate)) first = 0;
          else if (findHoliday(workEndDate)) last = 0;
          if (totalday > 1) {
            let middle = totalday - 1;
            for (let i = 1; i < totalday; i += 1) {
              const newDate = new Date(workStartDate);
              newDate.setDate(newDate.getDate() + i);
              if (operationType !== 3 && findHoliday(newDate)) middle -= 1;
            }
            if (operationType === 3) middle *= 24 * 60;
            else middle *= totalWorkTime;
            return first + middle + last;
          }
          return first + last;
        }

        /** 시작일과 종료일이 같은 경우. */
        let workOneday = getTimeToMinutes(workStartDate, workEndDate);
        let breaketime = 0;
        workingHours.breakeTime.forEach((a) => {
          const { start: startHours, end: startMins } = stringToTime(a.start);
          const { start: endHours, end: endMins } = stringToTime(a.end);
          const workBreakeStart = new Date(workStartDate); // 휴게 시작시간.
          workBreakeStart.setHours(startHours, startMins);
          const workBreakeEnd = new Date(workStartDate); // 휴게 종료시간.
          workBreakeEnd.setHours(endHours, endMins);
          /** 근무시간 안에 해당하는 휴게시간일 경우. */
          if (
            fixedWorkHours * 60 + fixedWorkMins < startHours * 60 + startMins &&
            endHours * 60 + endMins < fixedOffHours * 60 + fixedOffMins
          ) {
            // 출근시간 < 휴게시간 < 퇴근시간
            if (
              moment(workStartDate).isBefore(workBreakeStart) &&
              moment(workBreakeEnd).isBefore(workEndDate)
            )
              breaketime = getTimeToMinutes(workBreakeStart, workBreakeEnd);
            // 휴게시작시간 <= 출,퇴근시간 <= 휴게종료시간
            if (
              moment(workBreakeStart).isSameOrBefore(workStartDate) &&
              moment(workEndDate).isSameOrBefore(workBreakeEnd)
            )
              breaketime = getTimeToMinutes(workStartDate, workEndDate);
            // 휴게시작시간 <= 출근시간 < 휴게종료시간 && 휴게종료시간 < 퇴근시간
            if (
              moment(workStartDate).isBetween(
                workBreakeStart,
                workBreakeEnd,
                undefined,
                '[)',
              ) &&
              moment(workBreakeEnd).isBefore(workEndDate)
            )
              breaketime = getTimeToMinutes(workStartDate, workBreakeEnd);
            // 출근시간 < 휴게시작시간 && 휴게시작시간 < 퇴근시간 <= 휴게종료시간
            if (
              moment(workEndDate).isBetween(
                workBreakeStart,
                workBreakeEnd,
                undefined,
                '(]',
              ) &&
              moment(workStartDate).isBefore(workBreakeStart)
            )
              breaketime = getTimeToMinutes(workBreakeStart, workEndDate);
          }
        });
        workOneday -= breaketime;
        if (operationType === 3) workOneday += breaketime;
        if (operationType !== 3 && findHoliday(workStartDate)) workOneday = 0;
        return workOneday;
      }
      const days = handleDayDiff(startDateFormat, endDateFormat);
      let totalDays = days;
      for (let i = 0; i < days; i += 1) {
        const newDate = new Date(startDateFormat);
        newDate.setDate(newDate.getDate() + i);
        if (operationType !== 3 && findHoliday(newDate)) totalDays -= 1;
      }
      if (operationType === 3) return totalDays * (24 * 60);
      return totalDays * totalWorkTime;
    }
    // 근태 매크로가 시작시간, 종료시간일 때
    return 0;
  };

  /** 근태 사용 기간 계산 */
  const handleUseAttendanceDate = (
    isDayOff: boolean,
  ): {
    startDateFormat: Date | null;
    endDateFormat: Date | null;
  } => {
    const {
      dayOff,
      rangeStart,
      rangeEnd,
      start,
      end,
      workTime: macroWorkTime,
      offTime: macroOffTime,
    } = state.attendanceMacros;
    const { workTime, offTime } = workingHours;
    let startDateFormat = null;
    let endDateFormat = null;
    const { start: workHour, end: workMinutes } = stringToTime(workTime);
    const { start: offHour, end: offMinutes } = stringToTime(offTime);
    if (isDayOff) {
      const useTimeType = document.querySelector(
        'gw-fb-element[data-attendance="ATTENDANCE/USETIME"]',
      );
      // 시간 단위 연차 사용하는 경우
      if (useTimeType) {
        startDateFormat = new Date(`${start} ${macroWorkTime}:00`);
        endDateFormat = new Date(`${start} ${macroOffTime}:00`);
        return { startDateFormat, endDateFormat };
      }
      // 기간 단위 연차 사용하는 경우
      if (dayOff === '연차') {
        startDateFormat = new Date(`${rangeStart} ${workTime}:00`);
        endDateFormat = new Date(`${rangeEnd} ${offTime}:00`);
      } else if (dayOff === '오전반차') {
        const morningOffStart = `${workHour + 4}`.padStart(2, '0');
        const morningOffMinute = `${workMinutes}`.padStart(2, '0');
        startDateFormat = new Date(`${rangeStart} ${workTime}:00`);
        endDateFormat = new Date(
          `${rangeEnd} ${morningOffStart}:${morningOffMinute}:00`,
        );
      } else {
        const afternoonOffEnd = `${offHour - 4}`.padStart(2, '0');
        const afternoonOffMinute = `${offMinutes}`.padStart(2, '0');
        startDateFormat = new Date(
          `${rangeStart} ${afternoonOffEnd}:${afternoonOffMinute}:00`,
        );
        endDateFormat = new Date(`${rangeEnd} ${offTime}:00`);
      }
    } else {
      const startTime = macroWorkTime !== '' ? macroWorkTime : workTime;
      const endTime = macroOffTime !== '' ? macroOffTime : offTime;
      if (rangeStart !== '' && rangeEnd !== '') {
        startDateFormat = new Date(`${rangeStart} ${workTime}:00`);
        endDateFormat = new Date(`${rangeEnd} ${offTime}:00`);
      }
      if (start !== '' && end !== '') {
        startDateFormat = new Date(`${start} ${startTime}:00`);
        endDateFormat = new Date(`${end} ${endTime}:00`);
      }
      if (start !== '' && end === '') {
        startDateFormat = new Date(`${start} ${startTime}:00`);
        endDateFormat = new Date(`${start} ${endTime}:00`);
      }
    }
    return { startDateFormat, endDateFormat };
  };

  /** 업무 선택 */
  const handleWorkSelected = (arg: AttendanceComposeWorkSelectConfirmArg) => {
    // console.log(`handleWorkSelected(arg)`, arg);
    const {
      draftOrganizationId,
      workId,
      workName,
      attendanceCode,
      formId,
      formName,
      documentNo: workDocumentNo,
      retentionPeriod,
      approvalLine,
      referencePermission,
      viewPermission,
      useAttachFile,
      useAttachDocument,
      useOpinion,
      useComment,

      contents,
    } = arg;

    const { organizationName, employeeName } = getDirectoryData({
      ...directory,
      ...{
        companyId: principal.companyId,
        organizationId: draftOrganizationId,
        employeeId: principal.employeeId,
      },
    });
    // console.log(`organizationName:`, organizationName);
    // console.log(`employeeName:`, employeeName);

    // 작성자 최상위 조직 아이디.
    let draftTopLevelOrganizationId = 0;
    let draftTopLevelOrganizationName: string | undefined;
    if (useTopLevelOrganization) {
      const topLevelOrganization = findTopLevelOrganization({
        organizations: directory.organizations,
        companyId: principal.companyId,
        id: draftOrganizationId,
      });
      if (topLevelOrganization) {
        draftTopLevelOrganizationId = topLevelOrganization.id;
        draftTopLevelOrganizationName = topLevelOrganization.name;
      }
    }

    setState((prevState) => ({
      ...prevState,
      contenttype: 'compose',
      draftOrganizationId,
      draftTopLevelOrganizationId,
      draftTopLevelOrganizationName,
      workId,
      formId,
      workName,
      formName,
      attendanceCode,
      documentNo: replaceDocumentNo({
        documentNo: workDocumentNo,
        workName,
        formName,
        organizationName: draftTopLevelOrganizationName ?? organizationName,
        employeeName,
      }),
      retentionPeriod,
      contents,
      approvalLine: {
        ...approvalLine,
        groups: approvalLine.groups.map((group) => {
          switch (group.type) {
            case 'draft':
              return {
                ...group,
                items: [
                  {
                    id: `${Date.now()}`,
                    approval: false,
                    ...createApprovalLineEmployeeData({
                      companyId: principal.companyId,
                      organizationId: draftOrganizationId,
                      employeeId: principal.employeeId,
                    }),
                  },
                ],
              };
            case 'approval':
              return {
                ...group,
                items: createApprovalLineApprovalFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
            case 'agree':
              return {
                ...group,
                items: createApprovalLineAgreeFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
            default:
              return {
                ...group,
                items: createApprovalLineDefaultFiltered(group.items).map(
                  (item) => {
                    const { companyId, organizationId } = item;
                    if (item.employeeId === undefined) {
                      return {
                        ...item,
                        ...createApprovalLineOrganizationData(
                          companyId,
                          organizationId,
                        ),
                      };
                    }
                    return {
                      ...item,
                      ...createApprovalLineEmployeeData({
                        companyId,
                        organizationId,
                        employeeId: item.employeeId,
                      }),
                    };
                  },
                ),
              };
          }
        }),
      },
      referencePermission,
      viewPermission,
      useAttachFile,
      useAttachDocument,
      useOpinion,
      useComment,

      workDocumentNo,
    }));
  };

  /** 기안 요청 (상신) 대화상자 열기 */
  const handleDraftRequestDialogOpen = () => {
    const {
      subject,
      approvalLine,
      useAttachFile,
      attachedFiles,
      useAttachDocument,
      attachedDocuments,
    } = state;
    let validation = '';
    let start = '';
    let end = '';
    let rangeStart = '';
    let rangeEnd = '';
    let workTime = '';
    let offTime = '';
    let dayOff = '';
    let dayOffTotalTime = '';
    let reason = '';

    if (subject.trim() === '') validation = '제목을 입력하세요.';
    else if (
      !approvalLine.groups.some((g) => g.type === 'draft' && g.items.length > 0)
    )
      validation = '기안자를 설정하세요.';
    // 기안자 결재가 아니고 기안자 외 결재자가 없는 경우.
    else if (
      !approvalLine.groups.some(
        (g) => g.type === 'draft' && g.approval && g.items[0]?.approval,
      ) &&
      !approvalLine.groups.some((g) => g.type !== 'draft' && g.items.length > 0)
    )
      validation = '기안자 외 결재자를 선택하여야 합니다.';
    else if (
      approvalLine.groups.find(
        (group) => group.type === 'draft' && group.items[0].approval === false,
      ) &&
      approvalLine.groups.find(
        (group) =>
          group.type !== 'draft' && group.required && group.items.length === 0,
      ) !== undefined
    )
      validation = '필수 결재선을 설정하세요.';
    else if (useAttachFile === 2 && attachedFiles.length === 0)
      validation = '첨부 파일을 추가하세요.';
    else if (useAttachDocument === 2 && attachedDocuments.length === 0)
      validation = '첨부 문서를 추가하세요.';

    if (contentRef.current === null) return;
    try {
      contentRef.current
        .querySelectorAll('gw-fb-element[data-role="attendance"]')
        .forEach((a) => {
          const macro = a.getAttribute('data-attendance');
          const required = a.getAttribute('required');
          if (macro === 'ATTENDANCE/START') {
            const input = a.querySelector('input');
            if (input === null) return;
            if (required === 'true' && input?.value === '') {
              createComposeStyle(input);
              validation = '시작일은 필수 입력값입니다.';
              throw new Error();
            }
            start = input.value;
          } else if (macro === 'ATTENDANCE/END') {
            const input = a.querySelector('input');
            if (input === null) return;
            if (required === 'true' && input?.value === '') {
              createComposeStyle(input);
              validation = '종료일은 필수 입력값입니다.';
              throw new Error();
            }
            end = input.value;
          } else if (macro === 'ATTENDANCE/WORKTIME') {
            const control = a.querySelector('select');
            if (control === null) return;
            const controlValue = control.options[control.selectedIndex].value;
            if (required === 'true' && controlValue === '') {
              createComposeStyle(control);
              validation = '출근시간은 필수 입력값입니다.';
              throw new Error();
            }
            workTime = controlValue;
          } else if (macro === 'ATTENDANCE/OFFTIME') {
            const control = a.querySelector('select');
            if (control === null) return;
            const controlValue = control.options[control.selectedIndex].value;
            if (required === 'true' && controlValue === '') {
              createComposeStyle(control);
              validation = '퇴근시간은 필수 입력값입니다.';
              throw new Error();
            }
            offTime = controlValue;
          } else if (macro === 'ATTENDANCE/REASON') {
            const textarea = a.querySelector('textarea');
            if (textarea === null) return;
            if (required === 'true' && textarea?.value === '') {
              createComposeStyle(textarea);
              validation = '사유는 필수 입력값입니다.';
              throw new Error();
            }
            reason = textarea.value;
          } else if (macro === 'ATTENDANCE/DATE_RANGE') {
            const inputs = a.querySelectorAll('input');
            inputs.forEach((z) => {
              const input = z;
              if (required === 'true' && input.value === '') {
                createComposeStyle(input);
                validation = '기간에서 시작일과 종료일은 필수 입력값입니다.';
                throw new Error();
              }
            });
            if (inputs.length > 1) {
              rangeStart = inputs[0].value;
              rangeEnd = inputs[1].value;
            } else {
              rangeStart = inputs[0].value;
              rangeEnd = inputs[0].value;
            }
          } else if (macro === 'ATTENDANCE/DAYOFF') {
            const date = contentRef.current?.querySelector(
              'gw-fb-element[data-attendance="ATTENDANCE/DATE_RANGE"]',
            );
            const select = a.querySelector('select');
            if (select && date) {
              const selected = select.options[select.selectedIndex].value;
              if (selected === '연차') {
                const inputs = date.querySelectorAll('input');
                inputs.forEach((z) => {
                  const input = z;
                  if (required === 'true' && input.value === '') {
                    createComposeStyle(input);
                    validation = '연차 기간은 필수 입력값입니다.';
                    throw new Error();
                  }
                });
                rangeStart = inputs[0].value;
                rangeEnd = inputs[1].value;
              } else {
                const input = date.querySelector('input');
                if (input === null) return;
                if (required === 'true' && input?.value === '') {
                  createComposeStyle(input);
                  validation = '반차 기간은 필수 입력값입니다.';
                  throw new Error();
                }
                rangeStart = input.value;
                rangeEnd = input.value;
              }
              dayOff = selected;
            }
          } else if (macro === 'ATTENDANCE/USETIME') {
            const control = a.querySelector('select');
            if (control === null) return;
            const controlValue = control.options[control.selectedIndex].value;
            dayOffTotalTime = controlValue;
          }
        });
    } catch (e) {
      //
    }

    if (validation !== '')
      setState((prevState) => ({ ...prevState, validation }));
    else
      setState((prevState) => ({
        ...prevState,
        dialogtype: 'draftrequest',
        attendanceMacros: {
          ...prevState.attendanceMacros,
          start,
          end,
          rangeStart,
          rangeEnd,
          dayOff,
          dayOffTotalTime,
          reason,
          workTime,
          offTime,
        },
      }));
  };

  /** 결재선 대화상자 열기 */
  const handleApprovalLineDialogOpen = () => {
    setState((prevState) => ({ ...prevState, dialogtype: 'approvalline' }));
  };

  /** 첨부문서 자세히 보기 대화상자 */
  const handleAttachedDocumentSelectDialogOpen = (arg: {
    companyId: number;
    id: number;
    selected: boolean;
  }) => {
    const { companyId, id, selected } = arg;
    setState((prevState) => ({
      ...prevState,
      dialogtype: 'attacheddocumentdialog',
      drawerAttachedCompanyId: companyId,
      drawerAttachedId: id,
      drawerAttachedSelected: selected,
    }));
  };

  /** 첨부문서 자세히 보기 대화상자에서 문서 선택 또는 해제 */
  const handleApprovahAttachedDocumentClick = (arg: {
    id: number;
    checked: boolean;
  }) => {
    const { id, checked } = arg;
    dispatch(
      attachedDocumentActions.setListItemChecked({ id, checked: !checked }),
    );
    setState((prevState) => ({
      ...prevState,
      dialogtype: undefined,
      drawerAttachedCompanyId: undefined,
      drawerAttachedId: undefined,
      drawerAttachedSelected: undefined,
    }));
  };

  /** 취소 */
  const handleCancel = () => {
    if (queryParams.contentType === 'workPlan') {
      goBack();
      return;
    }
    setState((prevState) => ({ ...prevState, contenttype: 'select' }));
  };

  /** 대화상자 닫기 */
  const handleDialogClose = () => {
    if (state.drawerAttachedId !== undefined) {
      setState((prevState) => ({
        ...prevState,
        dialogtype: undefined,
        drawerAttachedCompanyId: undefined,
        drawerAttachedId: undefined,
        drawerAttachedSelected: undefined,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      dialogtype: undefined,
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 제목 변경 */
  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('event.target.value', event.target.value);
    setState((prevState) => ({ ...prevState, subject: event.target.value }));
  };

  /** 파일 업로드 진행상태 업데이트 */
  const handleUploadProgress = (event: ProgressEvent, id: string) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    setState((prev) => ({
      ...prev,
      attachedFiles: prev.attachedFiles.map((x) => {
        if (x.id === id) {
          return { ...x, progress };
        }
        return x;
      }),
    }));
  };

  /** 첨부파일 업로드 */
  const handleAttachedFileUpload = async (
    uploadingFiles: FileUploadProps[],
  ) => {
    try {
      const files = createUploadFiles(uploadingFiles);
      setState((prev) => ({
        ...prev,
        attachedFiles: [...prev.attachedFiles, ...uploadingFiles],
      }));
      const fileAsyncFunc = files.map(async (x) => {
        await fileApi.uploadOne({
          path: state.uploadPath,
          file: x,
          module: 'attendance',
          onProgress: handleUploadProgress,
        });
      });

      Promise.all(fileAsyncFunc)
        .then((response) => {
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              attachedFiles: prev.attachedFiles.map((attachedFile) => {
                const uploadingFileIndex = uploadingFiles.findIndex(
                  (x) => x.id === attachedFile.id,
                );
                if (uploadingFileIndex !== -1) {
                  return {
                    ...uploadingFiles[uploadingFileIndex],
                    progress: 100,
                    isUploaded: true,
                  };
                }
                return attachedFile;
              }),
            }));
          }, 100);
        })
        .catch((e) => {
          setState((prev) => ({
            ...prev,
            attachedFiles: prev.attachedFiles.map((attachedFile) => {
              const uploadingFileIndex = uploadingFiles.findIndex(
                (x) => x.id === attachedFile.id,
              );
              if (uploadingFileIndex !== -1) {
                return {
                  ...uploadingFiles[uploadingFileIndex],
                  progress: undefined,
                  isUploaded: false,
                  isFail: true,
                };
              }
              return attachedFile;
            }),
          }));
        });
    } catch (e) {
      dispatch(sessionActions.error(e as ApiError));
    }
  };

  /** 첨부 파일 순서 변경 */
  const handleAttachedFileSortable = (arg: FileUploadProps[]) => {
    setState((prevState) => ({
      ...prevState,
      attachedFiles: [...arg],
    }));
  };

  /** 첨부 파일 삭제 */
  const handleAttachedFileRemove = (id: string) => {
    // console.log(`handleAttachedFileRemove(id)`, id);
    // console.log(`handleAttachedFileRemove(id):state`, state);
    setState((prevState) => ({
      ...prevState,
      attachedFiles: prevState.attachedFiles.filter((x) => x.id !== id),
    }));
  };

  /** 첨부 문서 선택 드로워 열기 */
  const handleAttachedDocumentSelectDrawerOpen = () => {
    const drawertype = 'attacheddocument';
    setState((prevState) => ({ ...prevState, drawertype }));
  };

  /** 첨부 문서 선택 드로워 닫기 */
  const handleAttachedDocumentSelectDrawerClose = () => {
    setState((prevState) => ({ ...prevState, drawertype: undefined }));
  };

  /** 첨부 문서 선택 확인 */
  const handleAttachedDocumentSelectDrawerConfirm = (arg: {
    items: { companyId: number; id: number; no: string; subject: string }[];
  }) => {
    // console.log(`handleAttachedDocumentSelectDrawerConfirm(arg)`, arg);
    const { items } = arg;
    setState((prevState) => {
      const { attachedDocuments } = prevState;
      let validation = '';

      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (
          attachedDocuments.findIndex(
            ({ companyId, id }) =>
              companyId === item.companyId && id === item.id,
          ) > -1
        ) {
          validation = `${item.subject} 는(은) 이미 선택된 문서 입니다.`;
          break;
        }
      }

      if (validation !== '') return { ...prevState, validation };
      return {
        ...prevState,
        attachedDocuments: [...attachedDocuments, ...items],
        drawertype: undefined,
      };
    });
  };

  /** 첨부 문서 순서 변경 */
  const handleAttachedDocumentSortable = (
    arg: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[],
  ) => {
    setState((prevState) => ({
      ...prevState,
      attachedDocuments: [...arg],
    }));
  };

  /** 첨부 문서 삭제 */
  const handleAttachedDocumentRemove = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      attachedDocuments: prevState.attachedDocuments.filter((a) => a.id !== id),
    }));
  };

  /** 첨부 문서 전체 삭제 */
  const handleAttachedDocumentRemoveAll = () => {
    setState((prevState) => ({ ...prevState, attachedDocuments: [] }));
  };

  /** 결재선 저장 */
  const handleApprovalLineSave = (arg: {
    approvalLine: ApprovalLineType;
    referencePermission?: SharePermissionType;
    viewPermission?: SharePermissionType;
  }) => {
    // console.log(`handleApprovalLineSave(arg)`, arg);
    const { approvalLine, referencePermission, viewPermission } = arg;
    setState((prevState) => ({
      ...prevState,
      approvalLine,
      referencePermission,
      viewPermission,
      dialogtype: undefined,
    }));
  };

  /** 기안 요청 (상신) */
  const handleDraftRequest = async (arg: DraftRequestConfirmArg) => {
    if (contentRef.current === null) {
      const validation = '작성 문서를 읽어올 수 없습니다.';
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => !x.isUploaded) > -1
    ) {
      const validation = '파일 전송 중에는 문서를 저장할 수 없습니다.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }
    if (
      state.attachedFiles.length > 0 &&
      state.attachedFiles.findIndex((x) => x.isFail) > -1
    ) {
      const validation = '전송 실패한 파일을 삭제 후 다시 시도 해주세요.';
      setState((prev) => ({ ...prev, validation }));
      return;
    }

    const {
      draftTopLevelOrganizationId,
      workId,
      formId,
      documentNo,
      subject,
      approvalLine,
      referencePermission,
      viewPermission,
      attachedDocuments,
      attendanceMacros,
    } = state;

    const notOrganization = organizationIds.some(
      (a) => a === getApprovalLineDrafter(approvalLine)?.organizationId,
    );
    if (notOrganization === false) {
      // 기안 조직이 사용자 정보에 일치하는 조직과 없을 경우 결재 상신 오류처리.
      const validation =
        '기안자 정보와 현재 사용자 정보가 일치하지 않아 결재를 상신할 수 없습니다.';
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }

    // 근태 구분 타입.
    const operationType = attendanceCodeList.find(
      (item) => item.id === state.attendanceCode,
    )?.operationType;
    const workView = await attendanceWorkApi.fetchView({
      affiliatedCompanyId: undefined,
      id: workId,
    });
    const formView = await attendanceFormApi.view(formId);

    const contents = FormBuilder.createViewHtml({
      element: contentRef.current,
    });

    const attachedFiles: {
      path: string;
      id: number;
      documentId?: number;
      seq: number;
      name: string;
      size: number;
      delete?: boolean;
    }[] = createAttachedFiles({
      attachedFiles: state.attachedFiles,
      uploadPath: state.uploadPath,
    });

    const { startDateFormat, endDateFormat } = handleUseAttendanceDate(
      operationType === 1,
    );

    if (startDateFormat === null || endDateFormat === null) {
      const validation = '시작일자 혹은 종료일자는 필수 입력 값입니다.';
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }

    if (startDateFormat >= endDateFormat) {
      const validation = '종료일자가 시작일자보다 작을 수 없습니다.';
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }

    // 단일 시간 연차일 때 입력 시간이 한시간 단위가 아닌 경우 validaion
    if (operationType === 1 && attendanceMacros.dayOffTotalTime !== '') {
      const diffMSec = endDateFormat.getTime() - startDateFormat.getTime();
      const diffMin = diffMSec / (60 * 1000);
      if (diffMin % 60) {
        const workTime = document
          .querySelector('gw-fb-element[data-attendance="ATTENDANCE/WORKTIME"]')
          ?.querySelector('select');
        const offTime = document
          .querySelector('gw-fb-element[data-attendance="ATTENDANCE/OFFTIME"]')
          ?.querySelector('select');
        if (workTime && offTime) {
          createComposeStyle(workTime);
          createComposeStyle(offTime);
        }
        const validation =
          '연차 입력 시간이 한 시간 단위가 아닌 경우 근태 상신할 수 없습니다.';
        setState((prevState) => ({
          ...prevState,
          validation,
          dialogtype: undefined,
        }));
        return;
      }
    }
    const useMinutes = handleUseMinutesFromDayOff(
      operationType,
      startDateFormat,
      endDateFormat,
    );

    if (useMinutes <= 0) {
      const validation = '사용 시간이 올바르지 않습니다.';
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }

    const startDateTime = dateFormat(
      initialDate(startDateFormat),
      'yyyy-MM-DD[T]HH:mm:ss.SSS',
    );
    const endDateTime = dateFormat(
      initialDate(endDateFormat),
      'yyyy-MM-DD[T]HH:mm:ss.SSS',
    );

    if (operationType === undefined) {
      const validation = '양식에 근태 코드가 설정되어 있지 않습니다.';
      setState((prevState) => ({
        ...prevState,
        validation,
        dialogtype: undefined,
      }));
      return;
    }

    let useLeaveType:
      | 'LEAVE_DAY'
      | 'LEAVE_AM'
      | 'LEAVE_PM'
      | 'LEAVE_HOUR'
      | null = null;
    // 근태코드가 연차 차감일 때
    if (operationType === 1) {
      if (
        attendanceMacros.dayOff === '' &&
        attendanceMacros.dayOffTotalTime !== ''
      ) {
        useLeaveType = 'LEAVE_HOUR';
      }
      if (attendanceMacros.dayOff === '연차') {
        useLeaveType = 'LEAVE_DAY';
      } else if (attendanceMacros.dayOff === '오전반차') {
        useLeaveType = 'LEAVE_AM';
      } else if (attendanceMacros.dayOff === '오후반차') {
        useLeaveType = 'LEAVE_PM';
      }
    }

    // 근태 전송 데이터
    const data = {
      employeeId: principal.employeeId,
      workId,
      startDateTime,
      endDateTime,
      useMinutes,
      useLeaveType,
      subject,
      remark: attendanceMacros.reason,
    };
    const { search } = props;
    const pathname = `${getPathMap('/*/*', props.pathname)}`;
    const location = utils.getLocation({
      target: props,
      source: {
        pathname,
        search,
        mode: 'replace',
        option: 'CLEAR_CONTENTS',
      },
    });
    setReload(false);
    // 근태문서 임시 상신
    attendancesApi
      .createTemp(data)
      .then((result) => {
        if ((result as { error?: string }).error === undefined) {
          const { affiliatedCompanyId, id } = result;

          // 문서 저장 데이터
          const param = {
            publicOrNot: arg.publicOrNot,
            useUrgent: arg.useUrgent,
            archiveFolderId: arg.archiveFolderId,
            opinion: arg.opinion,
            draftTopLevelOrganizationId,
            workId,
            workUpdateAt: workView.updateAt,
            formId,
            formUpdateAt: formView.updateAt,
            no: documentNo,
            subject,
            contents,
            approvalLine,
            referencePermission,
            viewPermission,
            attachedFiles: attachedFiles.length > 0 ? attachedFiles : undefined,
            attachedDocuments:
              attachedDocuments.length > 0 ? attachedDocuments : undefined,
            linkType: 'ATTENDANCE' as const,
            linkId: JSON.stringify({ companyId: principal.companyId, id }),
          };
          // console.log(`AttendanceComposeContainer createTemp after`, param);
          dispatch(documentActions.createLinked({ param, location }));
        }
      })
      .catch((e) => {
        dispatch(sessionActions.error(e as ApiError));
        setState((prev) => ({ ...prev, dialogtype: undefined }));
      });
  };

  /** 콘텐트 로드. */
  const handleContentLoad = useCallback(
    (arg: { element: HTMLDivElement }) => {
      const { contents } = state;
      const { element } = arg;
      const operationType = attendanceCodeList.find(
        (a) => a.id === state.attendanceCode,
      )?.operationType;

      element.innerHTML = FormBuilder.createComposeHtml({
        html: contents,
        isDayOff: operationType === 1,
        workingHours,
        getMacro,
      });
    },
    [
      queryParams.contentMode,
      state.documentNo,
      state.contents,
      state.draftOrganizationId,
    ],
  );

  const getMacro = (arg: { id: string }) => {
    const {
      documentNo,
      subject,
      retentionPeriod,
      approvalLine,
      referencePermission,
      viewPermission,
      workDocumentNo,
      remain,
      substitute,
      workName,
    } = state;

    const { id } = arg;
    // 잔여 연차
    if (id === 'ATTENDANCE/REMAIN_COUNT') return remain;
    // 잔여 대휴
    if (id === 'ATTENDANCE/REMAIN_SUBSTITUTE') return substitute;
    // 회사명. _CMP_NAME_
    if (id === 'DIRECTORY/COMAPNY') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.companyName ?? '';
    }
    // 결재 회사 로고.
    if (id === 'APPROVAL/COMPANY_LOGO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.companyId !== undefined
        ? `/approval-static/image/${drafter?.companyId}/companylogo`
        : undefined;
    }
    // 회사 우편번호.
    if (id === 'DIRECTORY/COMPANY_POSTALCODE') {
      return companyView?.postalCode ?? '';
    }
    // 회사주소.
    if (id === 'DIRECTORY/COMPANY_ADDRESS') {
      return companyView?.address ?? '';
    }
    // 회사전화.
    if (id === 'DIRECTORY/COMPANY_PHONE_NO') {
      return companyView?.phoneNo ?? '';
    }
    // 회사팩스.
    if (id === 'DIRECTORY/COMPANY_FAX_NO') {
      return companyView?.faxNo ?? '';
    }
    // 오늘날짜.
    if (id === 'COMMON/TODAY') return dateTimeFormat(new Date(), 'YYYY-MM-DD');
    /* 최종결재자 명칭. */
    if (id === 'APPROVAL/LAST_APPROVER') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.employeeId ? data.employeeName : '';
    }
    /* 최종결재자 부서명. */
    if (id === 'APPROVAL/LAST_APPROVER_ORGANIZATION') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.organizationName ?? '';
    }
    /* 최종결재자 직위. */
    if (id === 'APPROVAL/LAST_APPROVER_JOBPOSITION') {
      const lastApprover = getLastApprover(approvalLine);
      const data = Array.isArray(lastApprover)
        ? lastApprover[lastApprover.length - 1]
        : lastApprover;
      return data?.employeeName ? data.jobPositionName : '';
    }
    /* 기안자 회사 전화번호 */
    if (id === 'APPROVAL/DRAFTER_COMPANY_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.companyPhoneNo : '';
    }
    /* 기안자 폰번호 */
    if (id === 'APPROVAL/DRAFTER_MOBILE_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.mobilePhoneNo : '';
    }
    /* 기안자 내선번호 */
    if (id === 'APPROVAL/DRAFTER_EXTENSION_PHONE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.extensionPhoneNo : '';
    }
    /* 기안자 주소 */
    if (id === 'APPROVAL/DRAFTER_ADDRESS') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? employeeView?.address : '';
    }
    /* 기안자 사번 */
    if (id === 'APPROVAL/DRAFTER_EMPLOYEE_NO') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees.find((x) => x.id === drafter?.employeeId)?.no ?? ''
      );
    }
    /* 기안자 EMAIL */
    if (id === 'APPROVAL/DRAFTER_EMAIL') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees.find((x) => x.id === drafter?.employeeId)?.email ??
        ''
      );
    }
    /* 기안자 아이디 */
    if (id === 'APPROVAL/DRAFTER_ID') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return (
        directory.employees
          .find((x) => x.id === drafter?.employeeId)
          ?.email.split('@')[0] ?? ''
      );
    }
    // 기안자. _NAME_
    if (id === 'APPROVAL/DRAFTER') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId
        ? drafter?.employeeName
        : drafter?.organizationName;
    }
    // 기안자 조직. _DEPT_NAME_
    if (id === 'APPROVAL/DRAFTER_ORGANIZATION') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.organizationName ?? '';
    }
    // 기안자 직위. _POSITION_
    if (id === 'APPROVAL/DRAFTER_JOBPOSITION') {
      const drafter = getApprovalLineDrafter(approvalLine);
      return drafter?.employeeId ? drafter?.jobPositionName : '';
    }
    // 기안일. _TODAY_
    if (id === 'APPROVAL/DRAFT_DATE') {
      const drafter = getApprovalLineDrafter(approvalLine);
      const actAt = drafter?.actAt ?? new Date();
      return dateTimeFormat(actAt, 'YYYY-MM-DD');
    }
    // 문서제목. _DOC_TITLE_
    if (id === 'APPROVAL/DOCUMENT_SUBJECT') {
      return subject;
    }

    // 결재그룹, 합의그룹, 수신그룹, 감사그룹.
    if (
      id === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_APPROVAL_GROUP/INCLUDE_DRAFTER' ||
      id === 'APPROVAL/APPROVALLINE_AGREE_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_RECEIVE_GROUP' ||
      id === 'APPROVAL/APPROVALLINE_AUDIT_GROUP'
    ) {
      const types: ('draft' | 'approval' | 'agree' | 'audit' | 'receive')[] =
        [];

      if (id.endsWith('/INCLUDE_DRAFTER')) types.push('draft');

      switch (id) {
        case 'APPROVAL/APPROVALLINE_APPROVAL_GROUP':
        case 'APPROVAL/APPROVALLINE_APPROVAL_GROUP/INCLUDE_DRAFTER':
          types.push('approval');
          break;
        case 'APPROVAL/APPROVALLINE_AGREE_GROUP':
          types.push('agree');
          break;
        case 'APPROVAL/APPROVALLINE_RECEIVE_GROUP':
          types.push('receive');
          break;
        case 'APPROVAL/APPROVALLINE_AUDIT_GROUP':
          types.push('audit');
          break;
        default:
          break;
      }

      return approvalLine.groups
        .map((group) => {
          if (types.includes(group.type)) return group.items;
          return [];
        })
        .flat()
        .map((a) => {
          let jobClass = '';
          let approvalOfficer = '';
          if (a.employeeId !== undefined) {
            jobClass = getJobClassName(
              a.jobClassType,
              a.jobPositionName,
              a.jobDutyName,
            );
            approvalOfficer = a.macroName ?? '';
          }

          let act = approvalActText(a.act);
          // 대리 결재인 경우. (직원 결재이고 결재 담당자가 아니고 결재자가 있는 경우)
          if (
            a.employeeId !== undefined &&
            a.macroId === undefined &&
            a.approverEmployeeId !== undefined
          ) {
            if (a.act === 'approval')
              act = approvalActText('surrogateApproval');
            else if (a.act === 'return')
              act = approvalActText('surrogateReturn');
          }

          let approverJobClassName = '';
          if (
            a.approverJobClassType &&
            a.approverJobPositionName &&
            a.approverJobDutyName
          )
            approverJobClassName = getJobClassName(
              a.approverJobClassType,
              a.approverJobPositionName,
              a.approverJobDutyName,
            );
          return {
            company: a.companyName,
            organization:
              a.employeeName === undefined && a.approverEmployeeName
                ? `${a.organizationName}<br>${a.approverEmployeeName} ${approverJobClassName}`
                : a.organizationName,
            employee: a.employeeName ?? '',
            jobClass,
            act,
            actAt:
              a.actAt !== undefined
                ? dateTimeFormat(a.actAt, 'YYYY-MM-DD')
                : '',
            approvalOfficer, // 결재 담당자.
            approver: a.approverEmployeeName ?? '',
          };
        });
    }
    // 결재조직, 합의조직, 수신조직, 감사조직.
    if (
      id === 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST' ||
      id === 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST'
    ) {
      let type = '';
      switch (id) {
        case 'APPROVAL/APPROVALLINE_APPROVAL_ORGANIZATION_LIST':
          type = 'approval';
          break;
        case 'APPROVAL/APPROVALLINE_AGREE_ORGANIZATION_LIST':
          type = 'agree';
          break;
        case 'APPROVAL/APPROVALLINE_RECEIVE_ORGANIZATION_LIST':
          type = 'receive';
          break;
        case 'APPROVAL/APPROVALLINE_AUDIT_ORGANIZATION_LIST':
          type = 'audit';
          break;
        default:
          type = '';
          break;
      }

      return Array.from(
        new Set(
          approvalLine.groups
            .map((group) => {
              if (group.type !== type || group.items.length === 0) return [];
              return group.items;
            })
            .flat()
            .map((a) => a.organizationName),
        ),
      ).join(',');
    }
    // 참조.
    if (id === 'APPROVAL/REFERENCE_PERMISSION_LIST') {
      if (referencePermission === undefined) return '';
      return referencePermission.groups
        .map(({ items }) => items)
        .flat()
        .map((a) => {
          if (a.employeeId === undefined) return a.organizationName;
          return a.macroName || a.employeeName;
        })
        .join(',');
    }
    // 조회.
    if (id === 'APPROVAL/VIEW_PERMISSION_LIST') {
      if (viewPermission === undefined) return '';
      return viewPermission.groups
        .map(({ items }) => items)
        .flat()
        .map((a) => {
          if (a.employeeId === undefined) return a.organizationName;
          return a.macroName || a.employeeName;
        })
        .join(',');
    }
    // 업무명.
    if (id === 'APPROVAL/WORK_NAME') {
      return workName;
    }
    // 결재 항목
    if (id === 'APPROVAL/APPROVALLINE_DESIGNATION_ITEM') {
      return approvalLine.groups
        .filter(({ type }) => type !== 'draft')
        .map((group) => {
          switch (group.type) {
            case 'approval':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'agree':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            case 'receive':
              return {
                ...group,
                items: group.items.map((a) => ({
                  ...a,
                  id: group.type,
                })),
              };
            default:
              return group;
          }
        })
        .map((a) => {
          return a.items;
        })
        .flat()
        .map((a) => {
          let jobClass = '';
          let jobPosition = '';
          let jobDuty = '';

          let approvalOfficer = '';
          if (a.employeeId !== undefined) {
            jobClass = getJobClassName(
              a.jobClassType,
              a.jobPositionName,
              a.jobDutyName,
            );
            approvalOfficer = a.macroName ?? '';
            jobPosition = a.jobPositionName;
            jobDuty = a.jobDutyName;
          }
          let act = approvalActText(a.act);
          // 대리 결재인 경우. (직원 결재이고 결재 담당자가 아니고 결재자가 있는 경우)
          if (
            a.employeeId !== undefined &&
            a.macroId === undefined &&
            a.approverEmployeeId !== undefined
          ) {
            if (a.act === 'approval')
              act = approvalActText('surrogateApproval');
            else if (a.act === 'return')
              act = approvalActText('surrogateReturn');
          }

          let approverJobClassName = '';
          if (
            a.approverJobClassType &&
            a.approverJobPositionName &&
            a.approverJobDutyName
          )
            approverJobClassName = getJobClassName(
              a.approverJobClassType,
              a.approverJobPositionName,
              a.approverJobDutyName,
            );
          return {
            company: a.companyName,
            organization:
              a.employeeName === undefined && a.approverEmployeeName
                ? `${a.organizationName}<br>${a.approverEmployeeName} ${approverJobClassName}`
                : a.organizationName,
            employee: a.employeeName ?? '',
            jobClass,
            act,
            actAt:
              a.actAt !== undefined
                ? dateTimeFormat(a.actAt, 'YYYY-MM-DD')
                : '',
            approvalOfficer, // 결재 담당자.
            approver: a.approverEmployeeName ?? '',
            jobPosition,
            jobDuty,
            type: a.id,
          };
        });
    }
    /* 문서번호. */
    if (id === 'APPROVAL/DOCUMENT_NO') {
      return {
        value: documentMacroReplace(documentNo),
        define: workDocumentNo,
      };
    }
    /* 보존기간. */
    // if (id === '(_DOC_TIME_)') {
    if (id === 'APPROVAL/DOCUMENT_RETENTION_PERIOD') {
      // console.log('ApprovalComposeContainer retentionPeriod', retentionPeriod);
      let retention = '영구';
      if (retentionPeriod !== 0) {
        const year = Math.floor(retentionPeriod / 365);
        retention = year > 0 ? `${year} 년 ` : '';
        const month = (retentionPeriod % 365) / 30;
        retention += month > 0 ? `${month} 개월` : '';
      }
      return retention;
    }

    if (id === '(_EDITOR_)') return undefined;

    return undefined;
  };

  /** 콘텐트 렌더링 */
  const renderContent = () => {
    const { contentMode: mode } = queryParams;
    const { contenttype, attachedFiles, attachedDocuments } = state;

    if (contenttype === 'select' && mode === 'create') {
      const { draftOrganizationId } = state;
      return (
        <AttendanceComposeWorkSelectContainer
          draftOrganizationId={draftOrganizationId}
          onConfirm={handleWorkSelected}
          isDayOffCreated={state.isDayOffCreated}
        />
      );
    }

    // 작성 또는 수정인 경우.
    if (contenttype === 'compose' && mode === 'create') {
      const { subject, approvalLine, useAttachFile, useAttachDocument } = state;

      const includeEmptyGroup = mode === 'create';
      //
      if (contentRef.current !== null) {
        FormBuilder.binding({
          element: contentRef.current,
          getMacro,
        });
      }

      return (
        <PostWrite name="posting" fullSize>
          <PostWrite.Toolbar onCancel={handleCancel}>
            {mode === 'create' && (
              <>
                <Button
                  text="근태상신"
                  variant="contained"
                  onClick={handleDraftRequestDialogOpen}
                />
                <Button text="결재선" onClick={handleApprovalLineDialogOpen} />
              </>
            )}
          </PostWrite.Toolbar>
          <PostWrite.Body>
            <PostWrite.Content>
              <PostWrite.Title>
                <TextField
                  value={subject}
                  onChange={handleSubjectChange}
                  placeholder="제목을 입력하세요"
                />
              </PostWrite.Title>
              <PostWrite.Item>
                <ApprovalLineFlat
                  approvalLine={approvalLine}
                  includeEmptyGroup={includeEmptyGroup}
                  className="item-value"
                />
              </PostWrite.Item>
              <PostWrite.Edit>
                <MemoizeDivElement
                  ref={contentRef}
                  className="editor-html"
                  onLoad={handleContentLoad}
                />
              </PostWrite.Edit>
            </PostWrite.Content>
            {useAttachFile !== 0 || useAttachDocument !== 0 ? (
              <PostWrite.Side>
                {useAttachFile ? (
                  <AddProgressAttachments
                    data={attachedFiles}
                    maxCount={maxFileCount}
                    maxCapacity={maxFileCapacity}
                    onRemove={handleAttachedFileRemove}
                    onFileUpload={handleAttachedFileUpload}
                    onSortable={handleAttachedFileSortable}
                  />
                ) : null}
                {useAttachDocument !== 0 ? (
                  <PostWrite.Item title="첨부문서">
                    <AddAttachDocument
                      data={attachedDocuments.map((a) => {
                        return {
                          ...a,
                          no: documentMacroReplace(a.no),
                        };
                      })}
                      onRemove={handleAttachedDocumentRemove}
                      onAllRemove={handleAttachedDocumentRemoveAll}
                      onSortable={handleAttachedDocumentSortable}
                      onOpen={handleAttachedDocumentSelectDrawerOpen}
                    />
                  </PostWrite.Item>
                ) : null}
              </PostWrite.Side>
            ) : null}
          </PostWrite.Body>
        </PostWrite>
      );
    }

    return null;
  };

  /** 대화상자 렌더링 */
  const renderDialog = () => {
    const { dialogtype } = state;
    // console.log(`renderDialog():dialogtype: '${dialogtype}'`);

    let result = null;

    if (dialogtype === 'approvalline') {
      const { approvalLine, referencePermission, viewPermission } = state;
      result = (
        <ApprovalLineDialogContainer
          approvalLine={approvalLine}
          referencePermission={referencePermission}
          viewPermission={viewPermission}
          onSave={handleApprovalLineSave}
          onCancel={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'draftrequest') {
      // console.log(`state`, state);

      const { useOpinion, draftOrganizationId } = state;
      result = (
        <DraftRequestDialogContainer
          attendance
          organizationId={draftOrganizationId}
          useOpinion={useOpinion}
          onConfirm={handleDraftRequest}
          onCancel={handleDialogClose}
        />
      );
    }

    if (dialogtype === 'attacheddocumentdialog') {
      return (
        <ApprovalAttachedDocumentSelectDialog
          companyId={state.drawerAttachedCompanyId}
          id={state.drawerAttachedId}
          checked={state.drawerAttachedSelected}
          onClick={handleApprovahAttachedDocumentClick}
          onClose={handleDialogClose}
        />
      );
    }

    return result;
  };

  const { validation, drawertype } = state;

  return (
    <>
      {renderContent()}
      {drawertype && (
        <ApprovalAttachedDocumentSelectDrawerContainer
          onClick={handleAttachedDocumentSelectDialogOpen}
          onConfirm={handleAttachedDocumentSelectDrawerConfirm}
          onCancel={handleAttachedDocumentSelectDrawerClose}
        />
      )}
      {renderDialog()}
      <FeedBack text={validation} onClose={handleSnackbarClose} />
      <NavigationGuard
        pathname={props.pathname}
        search={props.search}
        reload={reload}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (reload) {
            return true;
          }
          return false;
        }}
      />
    </>
  );
}

export default AttendanceComposeContainer;
