/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Button from '../../../../../components/button/Button';
import Icon from '../../../../../components/icon/Icon';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import PostList, {
  PostListItemType,
} from '../../../../../components/post/PostList';
import { b62, go } from '../../../../../groupware-common/utils';
import { Column } from '../../../../../groupware-common/ui/type';
import { RootState } from '../../../../../groupware-webapp/app/store';
import attendancesApi from '../../../../apis/attendance/v1/documents';
import EmptyData from '../../../../../components/data/EmptyData';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import AttendanceContentBodyListItem from '../content/AttendanceContentBodyListItem';
import AttendancePreferencesApi from '../../../../apis/attendance/v1/preferences';
import dayOffStatusApi from '../../../../apis/attendance/v1/dayOffStatus';
import 'react-circular-progressbar/dist/styles.css';
import {
  dateFormat,
  dateTimeFormat,
  timeFormat,
  timezoneDate,
} from '../../../../../groupware-common/utils/ui';
import Loading from '../../../../../components/loading/Loading';

type DayOffType = {
  total: {
    format: string;
    minutes: number;
  };
  use: {
    format: string;
    minutes: number;
  };
  remain: {
    format: string;
    minutes: number;
  };
};

function AttendanceDashboardContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;

  const directory = useDirectory();
  const display = useSelector((state: RootState) => state.session.display);
  const principal = useSelector((state: RootState) => state.session.principal);
  const isExists = useSelector(
    (state: RootState) => state.attendance.preferences.standard.isExists,
  );
  const leave = useSelector(
    (state: RootState) => state.attendance.preferences.standard.leave,
  );
  const attendanceCode = useSelector(
    (state: RootState) => state.attendance.preferences.attendanceCode.list,
  );
  const categories = useSelector(
    (state: RootState) => state.attendance.attendances.folder.folders,
  ).filter((a) => a.type === 'default');
  const title = categories.find((a) => a.id === 'main')?.name ?? '';

  function formatStatus(status: number) {
    let data = '';
    if (status === 1) data = 'proceeding';
    else if (status === 3) data = 'done';
    else if (status === 4) data = 'return';
    else if (status === 44) data = 'cancel';
    return data;
  }

  function formatApplyType(useType: number) {
    const attendance = attendanceCode.find((a) => a.id === useType);
    if (attendance) return attendance.name;
    return '';
  }

  const [state, setState] = useState<{
    dayOff?: DayOffType;
    list?: {
      companyId: number;
      applyId: number;
      approvalId: number;
      applyType: string;
      subject: string;
      startDate: string;
      endDate: string;
      useMinutes: string;
      status: string;
    }[];
    loading: boolean;
  }>({
    loading: true,
  });
  const defaultColumns: Column<PostListItemType>[] = [
    { name: 'applyType', text: '구분', type: 'form', visible: true },
    { name: 'startDate', text: '시작날짜', type: 'date', visible: true },
    { name: 'endDate', text: '종료날짜', type: 'date', visible: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'subject', text: '제목', type: 'post', visible: true, disable: true },
    // eslint-disable-next-line prettier/prettier
    { name: 'drafter', text: '기안자', type: 'user', visible: display === 'phone' },
    { name: 'useMinutes', text: '사용', type: 'folder', visible: true },
    { name: 'status', text: '결재상태', type: 'status', visible: true },
  ];

  const handleMore = (menu: string) => {
    go(`/attendance/${menu}`);
  };
  const handleListItemClick = (id: number) => {
    go(`/attendance/detail/${b62(id)}`);
  };

  useEffect(() => {
    let mount = true;
    async function run() {
      const { expressionUnit } =
        await AttendancePreferencesApi.findAttendanceBasic();
      const list = (
        await attendancesApi.detailedList({
          employeeId: principal.employeeId,
          pageNo: 1,
          rowsPerPage: 8,
        })
      ).map((a) => {
        const status = formatStatus(a.status);
        const applyType = formatApplyType(a.useType);
        return {
          companyId: a.companyId,
          applyId: a.useId,
          approvalId: a.approvalId,
          applyType,
          subject: a.subject,
          startDate: dateTimeFormat(a.startDate, 'yyyy-MM-DD'),
          endDate: dateTimeFormat(a.endDate, 'yyyy-MM-DD'),
          useMinutes: timeFormat(a.useMinutes, expressionUnit),
          status,
        };
      });

      let dayOff: DayOffType | undefined;
      if (isExists && leave) {
        dayOff =
          leave.leaveOccurType === 1
            ? (
                await dayOffStatusApi.enterList({
                  standardDate: dateFormat(new Date(), 'yyyy-MM-DD'),
                  searchCode: 'employee',
                  searchWord: `${principal.companyId}_${principal.organizationId}_${principal.employeeId}`,
                  pageNo: 1,
                  rowsPerPage: 15,
                })
              ).map((a) => {
                const total =
                  a.revisionOccursLeaves +
                  a.occursLeaves +
                  a.modifingLeaves -
                  a.lapseLeaves;
                return {
                  total: {
                    format: timeFormat(total, expressionUnit),
                    minutes: total,
                  },
                  use: {
                    format: timeFormat(a.useLeaves, expressionUnit),
                    minutes: a.useLeaves,
                  },
                  remain: {
                    format: timeFormat(a.remainingLeaves, expressionUnit),
                    minutes: a.remainingLeaves,
                  },
                };
              })[0]
            : (
                await dayOffStatusApi.accountList({
                  standardYear: timezoneDate().getFullYear().toString(),
                  searchCode: 'employee',
                  searchWord: `${principal.companyId}_${principal.organizationId}_${principal.employeeId}`,
                  pageNo: 1,
                  rowsPerPage: 15,
                })
              ).map((a) => {
                const totalMinutes =
                  a.revisionOccuredLeavesCount +
                  a.occuredLeavesCount +
                  a.modifiedLeavesCount +
                  a.carryoverLeavesCount -
                  a.lapseLeavesCount;
                return {
                  total: {
                    format: timeFormat(totalMinutes, expressionUnit),
                    minutes: totalMinutes,
                  },
                  use: {
                    format: timeFormat(a.usedLeavesCount, expressionUnit),
                    minutes: a.usedLeavesCount,
                  },
                  remain: {
                    format: timeFormat(a.remainedLeavesCount, expressionUnit),
                    minutes: a.remainedLeavesCount,
                  },
                };
              })[0];
      }
      if (mount)
        setState({
          dayOff,
          list,
          loading: false,
        });
    }

    run();
    return () => {
      mount = false;
    };
  }, [isExists]);

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <div className="ui-attendance-dashboard">
          {/* 연차현황 */}
          <div className="attendance-dashboard-head">
            <div className="attendance-grid-head">
              <div className="attendance-grid-title">
                <Icon icon="user-circle" />
                연차현황
              </div>
              {/* TODO 개인 연차현황 화면 개발 필요. */}
              {/* <Button
                className="more"
                text="더보기"
                iconType
                icon="plus-small"
                color="secondary"
                onClick={() => handleMore('dayOffStatus')}
              /> */}
            </div>
            <div className="attendance-grid-body">
              {state.dayOff ? (
                <>
                  <div className="attendance-grid-items">
                    <div className="circle-items">
                      <CircularProgressbar
                        text="총 연차"
                        value={1}
                        maxValue={1}
                        strokeWidth={8}
                        styles={buildStyles({ pathColor: '#2196F3' })}
                      />
                    </div>
                    <div className="attendance-grid-item-word">
                      {`${state.dayOff?.total.format ?? ''}`}
                    </div>
                  </div>
                  <div className="attendance-grid-items">
                    <div className="circle-items">
                      <CircularProgressbar
                        text="사용 연차"
                        value={
                          (state.dayOff?.use.minutes ?? 1) /
                          (state.dayOff?.total.minutes ?? 1)
                        }
                        maxValue={1}
                        strokeWidth={10}
                        styles={buildStyles({ pathColor: '#EA2D49' })}
                      />
                    </div>
                    <div className="attendance-grid-item-word">
                      {`${state.dayOff?.use.format ?? ''}`}
                    </div>
                  </div>
                  <div className="attendance-grid-items">
                    <div className="circle-items">
                      <CircularProgressbar
                        text="잔여 연차"
                        value={
                          (state.dayOff?.remain.minutes ?? 1) /
                          (state.dayOff?.total.minutes ?? 1)
                        }
                        maxValue={1}
                        strokeWidth={10}
                        styles={buildStyles({ pathColor: '#FEC106' })}
                      />
                    </div>
                    <div className="attendance-grid-item-word">
                      {`${state.dayOff?.remain.format ?? ''}`}
                    </div>
                  </div>
                </>
              ) : (
                <EmptyData message="생성된 연차가 없습니다." />
              )}
            </div>
          </div>
          {/* 신청내역 */}
          <div className="attendance-dashboard-body">
            <div className="attendance-grid-head">
              <div className="attendance-grid-title">
                <Icon icon="document-fill" />
                신청내역
              </div>
              <Button
                className="more"
                text="더보기"
                iconType
                icon="plus-small"
                color="secondary"
                onClick={() => handleMore('detail')}
              />
            </div>
            <div className="attendance-grid-body">
              {state.list && state.list.length > 0 ? (
                <PostList
                  name="근태현황"
                  type={display === 'phone' ? 'split' : 'full'}
                >
                  <PostList.Head>
                    {defaultColumns
                      .filter(({ visible }) => visible)
                      .map(({ name, type: colunmType, text }) => (
                        <PostList.Cell
                          key={name}
                          name={text}
                          type={colunmType}
                          align={
                            colunmType === 'status' || name === 'useMinutes'
                              ? 'center'
                              : undefined
                          }
                        />
                      ))}
                  </PostList.Head>
                  <PostList.Body>
                    {state.list.map(
                      (
                        {
                          applyId: id,
                          approvalId,
                          applyType,
                          startDate,
                          endDate,
                          subject,
                          useMinutes,
                          status,
                        },
                        i,
                      ) => {
                        const directoryData = getDirectoryData({
                          ...directory,
                          ...principal,
                        });
                        const item = {
                          applyType,
                          startDate,
                          endDate,
                          subject,
                          useMinutes,
                          status,
                          drafter: {
                            name: directoryData.employeeName,
                            organization: directoryData.organizationName,
                            job: directoryData.jobPositionName,
                            avatar: directoryData.avatar ?? '',
                          },
                        };
                        const key = `/i${i}/${id}/}`;
                        return (
                          <AttendanceContentBodyListItem
                            key={key}
                            id={id}
                            approvalId={approvalId}
                            columns={defaultColumns.filter(
                              ({ visible }) => visible,
                            )}
                            item={item}
                            onClick={handleListItemClick}
                          />
                        );
                      },
                    )}
                  </PostList.Body>
                </PostList>
              ) : (
                <EmptyData />
              )}
            </div>
          </div>
        </div>
        {state.loading && <Loading />}
      </EuiBody>
    </>
  );
}

export default AttendanceDashboardContainer;
