import React from 'react';
import { useSelector } from 'react-redux';
import { getPathParams, getQueryParams } from '../../groupware-common/utils';
import AttendanceComposeContainer from '../pages/root/attendance/compose/AttendanceComposeContainer';
import AttendanceContentContainer from '../pages/root/attendance/content/AttendanceContentContainer';
import AttendanceDashboardContainer from '../pages/root/attendance/dashboard/AttendanceDashboardContainer';
import AttendanceDayOffStatusContainer from '../pages/root/attendance/dayOffStatus/AttendanceDayOffStatusContainer';
import AttendanceNoticeContainer from '../pages/root/attendance/notice/AttendanceNoticeContainer';
import AttendanceTeamsNoticeViewContainer from '../pages/root/attendance/notice/view/AttendanceTeamsNoticeViewContainer';
import AttendanceOrganizationStatusContainer from '../pages/root/attendance/organizationStatus/AttendanceOrganizationStatusContainer';
import AttendanceSubstituteContainer from '../pages/root/attendance/substitute/AttendanceSubstituteContainer';
import { RootState } from '../../groupware-webapp/app/store';

function AttendanceLocateContainer(props: {
  pathname: string;
  search: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, locationKey } = props;

  const { p1 } = getPathParams('/*/:p1', pathname);
  const { contentMode } = getQueryParams(search);
  const resource = useSelector((state: RootState) => state.session.resource);

  if (resource === 'teams' && p1 === 'alert') {
    return (
      <AttendanceTeamsNoticeViewContainer
        pathname={pathname}
        search={search}
        key={locationKey}
      />
    );
  }
  if (contentMode === 'create')
    return (
      <AttendanceComposeContainer
        pathname={pathname}
        search={search}
        key={locationKey}
      />
    );

  // 대시보드
  if (p1 === undefined)
    return <AttendanceDashboardContainer pathname={pathname} search={search} />;
  if (p1 === 'organizationDays' || p1 === 'organizationMonths')
    return (
      <AttendanceOrganizationStatusContainer
        pathname={pathname}
        search={search}
      />
    );
  if (p1 === 'notice')
    return <AttendanceNoticeContainer pathname={pathname} search={search} />;
  if (p1 === 'dayOffStatus')
    return (
      <AttendanceDayOffStatusContainer pathname={pathname} search={search} />
    );
  if (p1 === 'substitute')
    return (
      <AttendanceSubstituteContainer pathname={pathname} search={search} />
    );
  return <AttendanceContentContainer pathname={pathname} search={search} />;
}

export default AttendanceLocateContainer;
