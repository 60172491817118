import React, { useMemo, useRef, useState } from 'react';
import Menu from '../menu/Menu';
import { IconType } from '../../groupware-common/types/icon';
import { Country } from '../../groupware-common/types';
import Icon from '../icon/Icon';
import Avatar from '../avatar/Avatar';

function SelectField(props: {
  style?: React.CSSProperties;
  data: {
    value: string;
    label: string;
    icon?: IconType;
    avatar?: string;
    flag?: Country;
    add?: string;
    disabled?: boolean;
  }[];
  value: string;
  label?: string;
  variant?: 'filled' | 'outlined' | 'underline';
  size?: 'sm' | 'xs';
  block?: boolean;
  className?: string;
  disabled?: boolean;
  native?: boolean;
  onChange(value: string): void;
}): JSX.Element {
  const timerRef = React.useRef<number>();
  const optionAreaRef = useRef<HTMLDivElement>(null);
  const optionRef = useMemo(
    () =>
      Array(props.data.length)
        .fill(0)
        .map(() => React.createRef<HTMLDivElement>()),
    [],
  );

  const [state, setState] = useState<{
    menuPoint: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    menuVisible: boolean;
    width: number;
    pressed: boolean;
    currentIndex?: number;
  }>({
    menuPoint: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    menuVisible: false,
    width: 0,
    pressed: false,
  });

  const createCurrentIndex = () => {
    return props.data.findIndex((x) => x.value === props.value);
  };

  const { currentIndex = createCurrentIndex() } = state;

  let classname = 'eui-select';
  if (props.size) classname += ` ${props.size}`;
  if (props.variant) classname += ` ${props.variant}`;
  if (props.className) classname += ` ${props.className}`;
  if (props.block) classname += ' block';
  if (props.label) classname += ' hasLabel';
  if (props.disabled) classname += ' disabled';
  if (props.native) classname += ' native';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const currentOption = optionRef[currentIndex]?.current;
    const target = optionAreaRef.current;
    if (state.menuVisible) {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (currentIndex === 0) return;

        setState((prevState) => ({
          ...prevState,
          currentIndex: currentIndex - 1,
        }));

        if (currentOption && target) {
          target.scrollTo(
            0,
            currentOption.offsetTop - currentOption.clientHeight,
          );
        }
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (currentIndex === props.data.length - 1) return;

        setState((prevState) => ({
          ...prevState,
          currentIndex: currentIndex + 1,
        }));

        if (currentOption && target) {
          target.scrollTo(
            0,
            currentOption.offsetTop + currentOption.clientHeight,
          );
        }
      }

      if (event.key === 'Enter' || event.key === ' ') {
        const value = props.data[currentIndex]?.value;
        if (value === undefined) return;
        props.onChange(value);
        if (timerRef.current) clearTimeout(timerRef.current);
      }

      if (event.key === 'Escape' || event.key === 'Tab') {
        setState((prevState) => ({
          ...prevState,
          menuVisible: false,
          pressed: false,
          currentIndex: props.data.findIndex((x) => x.value === props.value),
        }));
        if (timerRef.current) clearTimeout(timerRef.current);
      }
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      const rect = event.currentTarget.getBoundingClientRect();
      event.preventDefault();
      setState((prevState) => ({
        ...prevState,
        menuPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        menuVisible: true,
        width: rect.width,
        pressed: true,
      }));
      timerRef.current = window.setTimeout(() => {
        const current = optionRef[currentIndex]?.current;
        if (current && optionAreaRef.current) {
          optionAreaRef.current.scrollTo(0, current.offsetTop);
        }
      }, 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange(event.target.value);
  };

  const handleToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (state.menuVisible) handleMenuClose();
    else {
      const rect = event.currentTarget.getBoundingClientRect();
      setState((prevState) => ({
        ...prevState,
        menuPoint: {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        },
        menuVisible: true,
        width: rect.width,
        pressed: true,
      }));

      timerRef.current = window.setTimeout(() => {
        const currentOption = optionRef[currentIndex]?.current;
        if (currentOption && optionAreaRef.current) {
          optionAreaRef.current.scrollTo(0, currentOption.offsetTop);
        }
      }, 1);
    }
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      menuVisible: false,
      pressed: false,
      width: 0,
      currentIndex: undefined,
    }));
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const handleSelected = (value: string) => {
    props.onChange(value);
    setState((prevState) => ({
      ...prevState,
      currentIndex: props.data.findIndex((x) => x.value === value),
    }));
    handleMenuClose();
  };

  const badge = () => {
    const label = props.data.find((x) => x.value === props.value)?.label;
    const flag = props.data.find((x) => x.value === props.value)?.flag;
    const avatar = props.data.find((x) => x.value === props.value)?.avatar;
    const icon = props.data.find((x) => x.value === props.value)?.icon;
    if (flag) {
      return <i className="select-badge" data-country-flag={flag} />;
    }
    if (avatar && label) {
      return <Avatar name={label} image={avatar} />;
    }
    if (icon) {
      return <Icon icon={icon} />;
    }
    return null;
  };

  return (
    <>
      <div className={classname} style={props.style}>
        {props.native ? (
          <>
            <select
              value={props.value}
              onChange={handleChange}
              disabled={props.disabled}
            >
              {props.data.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <span
              className={`select-toggle ${props.disabled ? 'disabled' : ''}`}
            >
              {props.data.find((x) => x.value === props.value)?.label}
            </span>
          </>
        ) : (
          <button
            type="button"
            className="select-toggle"
            onClick={handleToggle}
            disabled={props.disabled}
            aria-pressed={state.pressed}
            onKeyDown={handleKeyDown}
          >
            {props.label && (
              <strong className="select-label">{props.label}</strong>
            )}
            {badge()}
            <span className="select-value">
              {props.data.find((x) => x.value === props.value)?.label}
              {props.data.find((x) => x.value === props.value)?.add && (
                <em>{props.data.find((x) => x.value === props.value)?.add}</em>
              )}
            </span>
          </button>
        )}
      </div>
      {state.menuVisible && (
        <Menu
          minWidth={state.width}
          point={state.menuPoint}
          onClose={handleMenuClose}
          className="eui-select-menu"
        >
          <div className="select-option-area" ref={optionAreaRef}>
            {props.data.map((x, i) => {
              let itemClassName = 'option-item';
              if (x.value === props.value) itemClassName += ' selected';
              if (i === state.currentIndex) itemClassName += ' focus';

              return (
                <div
                  key={x.value}
                  className={itemClassName}
                  onClick={() => handleSelected(x.value)}
                  aria-disabled={x.disabled}
                  ref={optionRef[i]}
                >
                  {x.flag && <i className="flag" data-country-flag={x.flag} />}
                  {x.avatar && (
                    <Avatar
                      name={x.label}
                      image={x.avatar}
                      className="avatar"
                    />
                  )}
                  {x.icon && <Icon icon={x.icon} className="badge" />}
                  <div className="label">
                    {x.label}
                    {x.add && <em>{x.add}</em>}
                  </div>
                </div>
              );
            })}
          </div>
        </Menu>
      )}
    </>
  );
}

export default SelectField;
