import React, { useState } from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';
import ChipGroup from '../../../../../components/chip/ChipGroup';

function ComponentChipFilter(): JSX.Element {
  const [state, setState] = useState<{
    filter: {
      id: number;
      label: string;
      checked: boolean;
      disabled?: boolean;
    }[];
  }>({
    filter: [
      { id: 1, label: '패션의류', checked: false },
      { id: 2, label: '신발/잡화', checked: true },
      { id: 3, label: '화장품/헤어', checked: false },
      { id: 4, label: '식품', checked: true },
      { id: 5, label: '생활/주방', checked: false },
      { id: 6, label: '가구/침구', checked: false },
      { id: 7, label: '컴퓨터/전자', checked: false, disabled: true },
    ],
  });

  const handleChangeFilter = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      filter: prevState.filter.map((x) =>
        x.id === id ? { ...x, checked: !x.checked } : x,
      ),
    }));
  };

  return (
    <ComponentSampleUI.Content title="Filter">
      <ChipGroup>
        {state.filter.map((x) => (
          <Chip
            key={x.id}
            label={x.label}
            selected={x.checked}
            onClick={() => handleChangeFilter(x.id)}
            disabled={x.disabled}
          />
        ))}
      </ChipGroup>
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipFilter;
