import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { organizationActions } from '../../../../stores/attendance/organizationStatus';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';

function AttendanceOrganizationStatusRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );

  useEffect(() => {
    dispatch(attendancePreferencesActions.attendanceCodeList()).then(() => {
      if (folderId === 'organizationMonths') {
        dispatch(
          organizationActions.monthList({
            search,
            route: {
              pathname,
              search,
            },
          }),
        );
      } else {
        dispatch(
          organizationActions.dayList({
            search,
            route: {
              pathname,
              search,
            },
          }),
        );
      }
    });
  }, [dispatch, pathname, search]);

  return null;
}

export default AttendanceOrganizationStatusRoute;
