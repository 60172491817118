import React, { useState } from 'react';
import Button from '../button/Button';
import Avatar from '../avatar/Avatar';
import TextField from '../textfield/TextField';

export interface CommentItemProps {
  user: {
    name: string; // 이름
    organization: string; // 부서
    class: string; // 직위 혹은 직책
    avatar: string; // 아바타
  };

  onAdd(comment: string): void;

  onCancel(): void;
}

function CommentWrite(props: CommentItemProps): JSX.Element {
  const [state, setState] = useState<{
    comment: string;
  }>({
    comment: '',
  });

  const handleChangeComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({
      ...prevState,
      comment: event.target.value,
    }));
  };

  const handleClickAdd = () => {
    if (state.comment !== '') {
      setState((prevState) => ({
        ...prevState,
        comment: '',
      }));
      props.onAdd(state.comment);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      comment: '',
      changeView: false,
    }));
    props.onCancel();
  };

  return (
    <div className="comment-write">
      <Avatar name={props.user.name} image={props.user.avatar} />
      <div className="write-form">
        <TextField
          count
          maxLength={500}
          multiline
          value={state.comment}
          onChange={handleChangeComment}
        />
        <div className="write-action">
          <Button
            text="등록"
            variant="contained"
            onClick={handleClickAdd}
            disabled={state.comment === ''}
            size="sm"
          />
          <Button
            text="취소"
            onClick={handleCancel}
            color="secondary"
            size="sm"
          />
        </div>
      </div>
    </div>
  );
}

export default CommentWrite;
