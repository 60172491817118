/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EuiHeader from '../../../components/layout/EuiHeader';
import EuiBody from '../../../components/layout/EuiBody';
import EuiSetting from '../../../components/layout/EuiSetting';
import { RootState, useAppDispatch } from '../../../groupware-webapp/app/store';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import {
  b62,
  getParentItems,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../groupware-common/utils';
import { systemlinkWorkActions } from '../../stores/systemlink/work';
import SplitUnselected from '../../../components/split/SplitUnselected';
import FeedBack from '../../../components/alert/FeedBack';
import {
  ApprovalLineType,
  SharePermissionType,
} from '../../../groupware-approval/pages/adminconsole/approval/common/containers/WorkApprovalLineDialogContainer';
import SystemLinkWorkContentFolderView from './SystemLinkWorkContentFolderView';
import SystemLinkWorkContentView from './SystemLinkWorkContentView';
import SystemLinkWorkEditWork from './SystemLinkWorkEditWork';
import SystemLinkWorkContentList from './SystemLinkWorkContentList';

function SystemLinkWorkContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  // console.log(`${SystemLinkWorkContainer.name}.render:props`, props);

  const dispatch = useAppDispatch();

  /** 업무 폴더 배열. */
  const folders = useSelector(
    (state: RootState) => state.systemlink.work.folder.list.data.items,
  );

  /** 업무 배열. */
  const works = useSelector(
    (state: RootState) => state.systemlink.work.list.data.items,
  );

  /** 업무 뷰. */
  const work = useSelector(
    (state: RootState) => state.systemlink.work.view.data,
  );
  const categories = useSelector(
    (state: RootState) => state.systemlink.preferences.category,
  );

  const pathParams = getPathParams('/*/*/*/:id', props.pathname);
  const queryParams = getQueryParams(props.search);

  const selectedId = b62(pathParams.id);

  const [state, setState] = useState<{
    filter: string;
    completeMessage: string;
  }>({
    filter: '',
    completeMessage: '',
  });

  /** 트리 아이템 생성. */
  const items = useMemo(() => {
    const result = [
      ...folders
        .map((a) => {
          return {
            seq: a.seq,
            id: a.id,
            parentId: a.parentId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'folder' as const,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
      ...works
        .map((a) => {
          return {
            seq: a.seq,
            id: a.id,
            parentId: a.folderId,
            text: a.name,
            strings: hangul.d(a.name),
            icon: 'clipboard-edit' as const,
          };
        })
        .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1),
    ];
    return result;
  }, [works]);

  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, completeMessage: '' }));
  };

  const handleTreeItemFilter = (filter: string) => {
    setState((prevState) => ({ ...prevState, filter }));
  };

  const handleTreeItemClick = (id: number) => {
    const item = items.find((a) => a.id === id);
    const route = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/${b62(id)}`,
    };

    if (item?.icon === 'folder') dispatch(sessionActions.setRoute(route));
    if (item?.icon === 'clipboard-edit')
      dispatch(systemlinkWorkActions.view({ id, route }));
  };

  const handleCloseView = () => {
    const route = {
      pathname: `${getPathMap('/*/*/:id', props.pathname)}`,
    };
    dispatch(sessionActions.setRoute(route));
  };

  const handlePopupClose = () => {
    dispatch(sessionActions.search());
  };

  /** 액션. */
  const handleAction = (arg: { code: string; event: React.MouseEvent }) => {
    const { code } = arg;
    // 업무 수정인 경우.
    if (code === 'work/update') {
      const type = 'work';
      const mode = 'update';
      dispatch(sessionActions.setDrawer({ type, mode }));
    }
  };

  /** 업무 수정. */
  const handleWorkSave = (arg: {
    id: number;
    isEnable: boolean;
    name: string;
    formId: number;
    receiptFormId: number;
    formName: string;
    documentNo: string;
    retentionPeriod: number;
    approvalLine: ApprovalLineType;
    referencePermission: SharePermissionType | undefined;
    viewPermission: SharePermissionType | undefined;
    useAttachFile: number;
    useAttachDocument: number;
    useOpinion: boolean;
    useComment: boolean;
    description: string;
  }) => {
    const { id } = arg;
    const updateAt = work?.updateAt;
    if (updateAt === undefined) return;
    const relayLocation = {
      pathname: `${getPathMap('/*/*/*/:id', props.pathname)}/${b62(id)}`,
    };
    dispatch(
      systemlinkWorkActions.save({
        ...arg,
        id,
        status: arg.isEnable ? 1 : 2,
        updateAt,
        relayLocation,
      }),
    );
  };

  const renderContent = () => {
    const item = items.find(({ id }) => id === selectedId);

    if (item === undefined) return <SplitUnselected />;
    if (item.icon === 'folder')
      return (
        <EuiSetting.Right onClose={handleCloseView}>
          <SystemLinkWorkContentFolderView
            paths={getParentItems(items, selectedId).map(({ text }) => text)}
            name={item.text}
          />
        </EuiSetting.Right>
      );
    if (work === null || work === undefined)
      return <SplitUnselected label="찾을 수 없습니다" />;

    return (
      <EuiSetting.Right onClose={handleCloseView}>
        <SystemLinkWorkContentView
          paths={getParentItems(items, work.id).map(({ text }) => text)}
          name={work.name}
          isEnable={work.status === 1}
          useAttachFile={work.useAttachFile}
          retentionPeriod={work.retentionPeriod}
          formName={work.formName}
          receiptFormName={work.receiptFormName}
          approvalLine={work.approvalLine}
          referencePermission={work.referencePermission}
          viewPermission={work.viewPermission}
          description={work.description}
          onAction={handleAction}
        />
      </EuiSetting.Right>
    );
  };

  const renderDrawer = () => {
    const { drawerType, drawerMode } = queryParams;

    // 업무 수정인 경우.
    if (drawerType === 'work' && drawerMode === 'update') {
      if (work === null || work === undefined) return null;

      return (
        <SystemLinkWorkEditWork
          folderId={work.folderId}
          folderName={items.find((a) => a.id === work.folderId)?.text || ''}
          onSave={handleWorkSave}
          onClose={handlePopupClose}
          id={work.id}
          name={work.name}
          formId={work.formId}
          formName={work.formName}
          receiptFormId={work.receiptFormId}
          receiptFormName={work.receiptFormName}
          documentNo={work.documentNo}
          isEnable={work.status === 1}
          retentionPeriod={work.retentionPeriod}
          approvalLine={work.approvalLine}
          referencePermission={work.referencePermission}
          viewPermission={work.viewPermission}
          useAttachFile={work.useAttachFile}
          description={work.description}
        />
      );
    }
    return null;
  };

  const { filter, completeMessage } = state;
  const title = categories.find((a) => a.id === 6003)?.name ?? '';
  return (
    <>
      <EuiHeader>
        <EuiHeader.Content>
          <EuiHeader.Title>{title}</EuiHeader.Title>
        </EuiHeader.Content>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <SystemLinkWorkContentList
              items={items}
              selectedId={selectedId}
              filter={filter}
              onFilter={handleTreeItemFilter}
              onSelect={handleTreeItemClick}
            />
          </EuiSetting.Left>
          {renderContent()}
        </EuiSetting>
      </EuiBody>
      {renderDrawer()}
      <FeedBack text={completeMessage} onClose={handleSnackbarClose} />
    </>
  );
}

export default SystemLinkWorkContainer;
