/* eslint-disable */
import { useRef, EffectCallback, DependencyList, useEffect } from 'react';

export default function useNoInitialEffect(
  effect: EffectCallback,
  dependencies?: DependencyList,
) {
  // 초기 렌더링일 때 true
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns: void | (() => void) = () => {};

    // 초기 렌더링일 때 참조를 false로 설정
    // 다음 렌더링부터 이펙트 실행
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }
    // 이펙트 실행
    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
    return undefined;
  }, dependencies);
}
