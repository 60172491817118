import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import PostWrite from '../../../../../components/post/PostWrite';
import RadioGroup from '../../../../../components/radio/RadioGroup';
import TextField from '../../../../../components/textfield/TextField';
import {
  getPathParams,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import { documentActions } from '../../../../stores/approval/document';
import { getCurrentApprover } from '../../../common/dialogs/ApprovalLineDialogContainer';
import Button from '../../../../../components/button/Button';

/** 결재 행위 유형 */
type Act =
  | 'approval' // 승인
  | 'return' // 반려
  | 'back' // 전단계 반려
  | 'hold' // 보류
  | 'meet' // 대면
  | 'defer' // 후결
  | 'arbitraryDecision'; // 전결

function ApprovalTeamsApproveContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { propsFolderId } = getPathParams<{ propsFolderId?: string }>(
    `/*/:propsFolderId/*`,
    props.pathname,
  );
  const queryParams = getQueryParams(props.search);

  const teams =
    useSelector((state: RootState) => state.session.resource) === 'teams';
  const dispatch = useAppDispatch();

  const principal = useSelector((state: RootState) => state.session.principal);
  const organizationIds = principal.affiliatedOrganizations.map(({ id }) => id);
  const designators = useSelector(
    (state: RootState) => state.approval2.surrogateApproval.designators.data,
  );
  const companyId = queryParams.affiliatedCompanyId
    ? parseInt(queryParams.affiliatedCompanyId, 10)
    : principal.companyId;
  const view = useSelector(
    (state: RootState) => state.approval2.document.view.data,
  );
  const directory = useDirectory();

  const [state, setState] = useState(() => {
    return {
      buttons: [
        { value: 'approval' as const, label: '승인' },
        { value: 'return' as const, label: '반려' },
      ] as { value: Exclude<Act, 'none' | 'defer'>; label: string }[],
      act: 'approval' as Act,
      error: false,
      opinion: '',
      validation: '',
    };
  });

  /** 모듈 닫기. */
  const handleClose = () => {
    if (teams) {
      microsoftTeams.initialize();
      microsoftTeams.tasks.submitTask();
    }
  };

  /** 결재 처리 값 변경. */
  const handleApprovalProcessValueChange = (act: Act) => {
    setState((prevState) => ({ ...prevState, act }));
  };

  /** 의견 변경. */
  const handleOpinionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prevState) => ({ ...prevState, opinion: event.target.value }));
  };

  if (view === undefined || view === null) return <div>오류</div>;

  const { buttons, act, opinion, error } = state;

  /** 현재 결재자 */
  const currentApprover = getCurrentApprover({
    approvalLine: view.approvalLine,
    companyId,
    organizationIds,
    employeeId: principal.employeeId,
    designatorIds: designators.map(({ designatorId }) => designatorId),
    approvalFolder: propsFolderId === 'approve',
  });

  /** 결재 가능한 문서가 아닐 경우. */
  if (view.status !== 'PROGRESS') {
    let message = '';
    if (view.status === 'COMPLETE') message = '결재가 완료된 문서입니다.';
    else if (view.status === 'REJECT') message = '반려된 문서입니다.';
    else if (view.status === 'WITHDRAW')
      message = '기안자가 회수한 문서입니다.';
    return (
      <div
        className="eui-dialog sm"
        style={{
          padding: 0,
          bottom: 'auto',
        }}
      >
        <div className="dialog-container" style={{ height: '268px' }}>
          <DialogBody>
            <div className="eui-alert-message" style={{ paddingTop: '100px' }}>
              {message}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button text="확인" variant="contained" onClick={handleClose} />
          </DialogFooter>
        </div>
      </div>
    );
  }

  /** 현재 결재자가 아닌 경우. */
  if (currentApprover === undefined)
    return (
      <div
        className="eui-dialog sm"
        style={{
          padding: 0,
          bottom: 'auto',
        }}
      >
        <div className="dialog-container" style={{ height: '268px' }}>
          <DialogBody>
            <div className="eui-alert-message" style={{ paddingTop: '100px' }}>
              현재 결재자가 아닙니다.
            </div>
          </DialogBody>
          <DialogFooter>
            <Button text="확인" variant="contained" onClick={handleClose} />
          </DialogFooter>
        </div>
      </div>
    );

  /** 결재. */
  const handleConfirm = () => {
    let approverType: 'organization' | 'employee' | 'surrogater' =
      'organization';
    if (currentApprover.employeeId === undefined) approverType = 'organization';
    else if (currentApprover.employeeId === principal.employeeId)
      approverType = 'employee';
    else if (currentApprover?.employeeId !== principal.employeeId)
      approverType = 'surrogater';

    if (propsFolderId === undefined) return;

    if (view === null || view === undefined) return;

    const organizationId =
      approverType === 'organization'
        ? currentApprover.organizationId
        : undefined;
    const designatorId =
      approverType === 'surrogater' ? currentApprover.employeeId : undefined;

    const { id, parentCompanyId, parentId, updateAt } = view;

    const directoryData =
      approverType === 'organization' || approverType === 'surrogater'
        ? getDirectoryData({
            ...directory,
            ...principal,
            organizationId,
          })
        : undefined;

    const data = {
      approverType,
      companyId: principal.companyId,
      id,
      parentCompanyId,
      parentId,
      updateAt,
      act,
      opinion,

      organizationId,
      designatorId,
      approver: directoryData,
    };

    if (act === 'approval') {
      // 결재일 때
      dispatch(documentActions.approval({ data })).then((result) => {
        if (result.type.endsWith('fulfilled')) {
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask();
        } else if (result.type.endsWith('rejected')) {
          setState((prev) => ({
            ...prev,
            error: true,
          }));
        }
      });
    } else if (act === 'return') {
      // 반려일 때
      dispatch(documentActions.reject({ data })).then((result) => {
        if (result.type.endsWith('fulfilled')) {
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask();
        } else if (result.type.endsWith('rejected')) {
          setState((prev) => ({
            ...prev,
            error: true,
          }));
        }
      });
    }
  };

  return error ? (
    <></>
  ) : (
    <div
      className="eui-dialog sm"
      style={{
        padding: 0,
        bottom: 'auto',
      }}
    >
      <div className="dialog-container">
        <DialogBody>
          <PostWrite>
            <PostWrite.Item title="결재처리">
              <RadioGroup
                data={buttons}
                value={act}
                name="type"
                onChange={handleApprovalProcessValueChange}
              />
            </PostWrite.Item>
            <PostWrite.Item className="comment-item" title="결재의견">
              <TextField
                count
                maxLength={500}
                multiline
                rows="2"
                value={opinion}
                onChange={handleOpinionChange}
              />
            </PostWrite.Item>
          </PostWrite>
        </DialogBody>
        <DialogFooter>
          <Button text="취소" onClick={handleClose} />
          <Button text="결재" variant="contained" onClick={handleConfirm} />
        </DialogFooter>
      </div>
    </div>
  );
}

export default ApprovalTeamsApproveContainer;
