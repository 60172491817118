import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPathParams, resourceUpdate } from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import SettingInformationRoute from '../pages/root/setting/information/SettingInformationRoute';
import SettingPreferencesRoute from '../pages/root/setting/preferences/SettingPreferencesRoute';

function SettingLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search, locationKey: key, hash } = props;
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{ folderId?: string }>(
    '/*/:folderId',
    pathname,
  );
  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  useEffect(() => {
    resourceUpdate(['directory', 'jobclass'], [currentLanguage]);
  }, [dispatch]);

  // 기본 정보.
  if (folderId === 'information')
    return (
      <SettingInformationRoute pathname={pathname} search={search} key={key} />
    );
  return (
    <SettingPreferencesRoute pathname={pathname} search={search} key={key} />
  );
}

export default SettingLocateRoute;
