import i18n from 'i18next';
import Backend, { BackendOptions, RequestCallback } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { basename } from 'path';
import axios from 'axios';
import store from './store';
import { i18nActions } from '../stores/i18n';
import { Language } from '../../groupware-common/types';
import { getApiConfig } from '../../groupware-common/apis/common/v1';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'currentOnly',
    /*
    resources: {
      'ko-KR': {
        translation: {
          '1': '이사',
          '2': '부장',
          '3': '차장',
          '4': '과장',
          '5': '대리',
          '6': '사원',
        }
      }
    },
    */
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/api/locales/missingkey/all',
      parsePayload: (
        namespace: string,
        key: string,
        fallbackValue?: string,
      ) => {
        return { namespace, key, fallbackValue };
      },
      crossDomain: true,
      withCredentials: true,
      request: (
        options: BackendOptions,
        url: string,
        payload: { namespace: string; key: string; fallbackValue: string },
        callback: RequestCallback,
      ): void => {
        // backend load
        if (payload === undefined) {
          let host = '';
          let config;
          const namespace = basename(url).slice(0, -5).toLowerCase();
          switch (namespace) {
            case 'directory':
            case 'jobclass': {
              // const jwt =
              //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJncm91cHdhcmUiLCJpYXQiOjE2MDQyOTkzMjYsImV4cCI6MjE0NzQ4MzY0NywiZWlkIjoxLCJjaWQiOjUwMDF9.ipf0vKykJGuA1k-JPJucbV_VoyeVBtesGfZPr27ifFw';
              // host = store.getState().i18n.host;
              // config = { headers: { Authorization: `Bearer ${jwt}` } };
              const apiConfig = getApiConfig();
              host = `${apiConfig.host}/api`;
              config = { headers: apiConfig.headers };
              break;
            }
            default:
              host = '';
              config = undefined;
              break;
          }
          axios
            .get(`${host}${url}`, config)
            .then((response) => {
              const { status, data } = response;
              const lastUpdateAt =
                data.lastUpdateAt ?? '1000-01-01T00:00:00.000000';
              store.dispatch(
                i18nActions.localeTimestampUpdate({
                  language: i18n.language as Language,
                  namespace,
                  lastUpdateAt,
                  timestamp: Date.now(),
                }),
              );
              callback(null, { status, data });
            })
            .catch(() => {
              callback(null, { status: 404, data: '' });
            });
          return;
        }

        // backend add
        const { namespace, key } = payload;
        const property = key.split('.');
        const [companyId, id] = property[property.length - 1]
          .split('_')
          .map((a) => parseInt(a, 10));

        if (Number.isNaN(companyId) || Number.isNaN(id) || id === undefined)
          return;

        const { host, headers } = getApiConfig();
        axios
          .get<{ code: string; value: string }[]>(
            `${host}${url}?companyId=${companyId}&id=${id}`,
            { headers },
          )
          .then((response) => {
            const { data } = response;
            data.forEach((a) => {
              // console.log(`missingkey --------- : `, a);
              i18n.addResource(a.code, namespace, key, `${a.value}`);
            });
            const date = new Date();
            store.dispatch(
              i18nActions.localeTimestampUpdate({
                language: i18n.language as Language,
                namespace,
                lastUpdateAt: date.toISOString(),
                timestamp: Date.now(),
              }),
            );
          });
      },
      customHeaders: () => {
        const { headers } = getApiConfig();
        return headers;
      },
    },
    preload: ['ko-KR'], // preload 설정 시 해당 언어 모두 로드.
    lng: 'ko-KR',
    fallbackLng: ['ko-KR'],
    saveMissing: true,

    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
