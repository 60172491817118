import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { adjustActions } from '../../../../stores/attendance/adjust';

function AttendanceAdjustRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();
  const { folderId } = getPathParams<{ folderId: string }>(
    '/*/*/:folderId',
    pathname,
  );
  useEffect(() => {
    let mount = true;
    if (mount) {
      dispatch(
        adjustActions.list({
          folderId,
          search,
          route: {
            pathname,
            search,
          },
        }),
      );
    }

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search]);
  return null;
}

export default AttendanceAdjustRoute;
