import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import PostList from '../../../../../components/post/PostList';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import DrawerToolbar from '../../../../../components/drawer/DrawerToolbar';
import EmptyData from '../../../../../components/data/EmptyData';
import { attachedDocumentActions } from '../../../../stores/approval/attachedDocument';
import ApprovalDocumentListItem from './ApprovalDocumentListItem';
import {
  deepEqual,
  getAvatarPath,
  getQueryParams,
} from '../../../../../groupware-common/utils';
import Pagination from '../common/components/Pagination';
import { getEmployeeName } from '../../../../../groupware-directory/stores/directory';
import Loading from '../../../../../components/loading/Loading';
import Search from '../../../../../components/search/Search';
import { documentMacroReplace } from './ApprovalComposeContainer';
import { getApprovalLineDrafter } from '../../../common/dialogs/ApprovalLineDialogContainer';

/**
 * 걸재 첨부 문서 선택 드로워 컨테이너.
 * @param onConfirm 확인 이벤트.
 * @param onCancel 취소 이벤트.
 */
function ApprovalAttachedDocumentSelectDrawerContainer(props: {
  onClick(arg: { companyId: number; id: number; selected: boolean }): void;
  onConfirm?(arg: {
    items: {
      companyId: number;
      id: number;
      no: string;
      subject: string;
    }[];
    event: React.MouseEvent;
  }): void;
  onCancel(): void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const attachedDocumentList = useSelector(
    (state: RootState) => state.approval2.attachedDocument.list.data,
  );
  const scrollbar = useRef<HTMLDivElement>(null);
  const queryParams = getQueryParams(attachedDocumentList.search);

  const reduxTimestamps = useSelector((state: RootState) => ({
    attachedDocumentList: state.approval2.attachedDocument.list.meta.timestamp,
  }));

  const principal = useSelector((s: RootState) => s.session.principal);

  const [timestamps] = useState({ ...reduxTimestamps });
  const [state, setState] = useState<{
    keyword: string;
    filter: string;
  }>({
    keyword: '',
    filter: 'SUBJECT',
  });

  useEffect(() => {
    let mount = true;

    Promise.all([dispatch(attachedDocumentActions.listClear())]).then(() => {
      if (mount) dispatch(attachedDocumentActions.list({ search: '' }));
    });

    return () => {
      mount = false;
    };
  }, [dispatch]);

  /** 확인 클릭. */
  const handleConfirmClick = (event: React.MouseEvent) => {
    // console.log(`handleConfirmClick(event)`, event);
    const items = attachedDocumentList.items
      .filter((a) => a.checked)
      .map(({ id, no, subject }) => {
        return { companyId: principal.companyId, id, no, subject };
      });
    // console.log(`handleConfirmClick(event):items`, items);
    if (items === undefined || items.length === 0) {
      // TODO: 유효성 구현해야 함.
      // 선택된 항목이 없습니다.
      return;
    }

    const { onConfirm } = props;
    if (onConfirm) onConfirm({ items, event });
  };

  /** 검색. */
  const handleSearch = (arg: { keyword: string; filter: string }) => {
    const { keyword, filter } = arg;
    setState({ keyword, filter });
    const params = { ...queryParams };
    params.searchCode = filter === '' ? undefined : filter;
    params.searchWord = keyword === '' ? undefined : keyword;
    delete params.pageNo;
    dispatch(
      attachedDocumentActions.list({ search: getQueryParams(params) }),
    ).then(() => {
      scrollbar.current?.scrollTo(0, 0);
    });
  };

  /** 첨부문서 내용 자세히 보기 대화상자 */
  const handleAttachedDocumentDialog = (arg: {
    companyId: number;
    id: number;
    selected: boolean;
  }) => {
    props.onClick(arg);
  };

  /** 이전. */
  const handlePrev = () => {
    const pageNo = (queryParams.pageNo || 1) - 1;
    if (pageNo > 0) {
      if (pageNo > 1) queryParams.pageNo = pageNo;
      else delete queryParams.pageNo;
      dispatch(
        attachedDocumentActions.list({ search: getQueryParams(queryParams) }),
      ).then(() => {
        scrollbar.current?.scrollTo(0, 0);
      });
    }
  };

  /** 다음. */
  const handleNext = () => {
    const pageNo = (queryParams.pageNo || 1) + 1;
    queryParams.pageNo = pageNo;
    dispatch(
      attachedDocumentActions.list({ search: getQueryParams(queryParams) }),
    ).then(() => {
      scrollbar.current?.scrollTo(0, 0);
    });
  };

  /** 아이템 체크 변경. */
  const handleItemCheckedChange = (arg: {
    id: number;
    checked: boolean;
    event: React.ChangeEvent<HTMLInputElement>;
  }) => {
    // console.log(`handleItemCheckedChange(arg)`, arg);
    const { id, checked } = arg;
    dispatch(attachedDocumentActions.setListItemChecked({ id, checked }));
  };

  const loading = deepEqual(timestamps, reduxTimestamps);

  const confirmVisible =
    attachedDocumentList.items.find(({ checked }) => checked) !== undefined;

  const { totalCount } = attachedDocumentList;
  const { keyword, filter } = state;
  const checkedCount = attachedDocumentList.items.filter(
    (x) => x.checked,
  ).length;

  return (
    <Drawer title="첨부문서" size="xs" onClose={props.onCancel}>
      <Search
        className="drawer-search"
        keyword={keyword}
        options={[
          { value: 'SUBJECT', label: '제목' },
          { value: 'NUMBER', label: '문서번호' },
          { value: 'WORK', label: '업무명' },
          { value: 'FORM', label: '양식명' },
          { value: 'DRAFTER', label: '기안자명' },
        ]}
        filter={filter}
        onSearch={handleSearch}
        full
      />
      <DrawerToolbar>
        <DrawerToolbar.Left>
          {checkedCount > 0 && (
            <span className="selected-count">
              <em>{checkedCount}</em> <span>개 선택됨</span>
            </span>
          )}
        </DrawerToolbar.Left>
        <DrawerToolbar.Right>
          <Pagination
            no={queryParams.pageNo || 1}
            rows={queryParams.rowsPerPage || 15}
            count={totalCount}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </DrawerToolbar.Right>
      </DrawerToolbar>
      <DrawerBody scrollbar={scrollbar}>
        <PostList name="approval" type="split">
          <PostList.Body>
            {loading ? (
              <Loading />
            ) : (
              <>
                {attachedDocumentList.items.length > 0 ? (
                  attachedDocumentList.items.map((a) => {
                    const {
                      id,
                      workName,
                      subject,
                      createAt,

                      approvalLine,

                      // 리덕스 추가 필드
                      checked,
                      // 작업 필드
                      importance, // 중요도
                    } = a;
                    const no = documentMacroReplace(a.no);
                    const drafter = getApprovalLineDrafter(approvalLine);

                    const {
                      companyId = 0,
                      organizationName = '',
                      employeeId = 0,
                      employeeName = '',
                    } = drafter ?? {};
                    const jobClassType = drafter?.employeeId
                      ? drafter.jobClassType
                      : ('none' as const);
                    const jobPositionName = drafter?.employeeId
                      ? drafter.jobPositionName
                      : '';
                    const jobDutyName = drafter?.employeeId
                      ? drafter.jobDutyName
                      : '';

                    return (
                      <ApprovalDocumentListItem
                        key={id}
                        id={id}
                        companyId={companyId}
                        selected={checked}
                        items={[
                          {
                            type: 'checkbox' as const,
                            value: checked,
                            onChange: handleItemCheckedChange,
                          },
                          { type: 'workname' as const, value: workName },
                          { type: 'documentno' as const, value: no },
                          {
                            type: 'subject' as const,
                            value: subject,
                            urgent: importance === 2,
                          },
                          {
                            type: 'employee' as const,
                            value:
                              employeeId === 0
                                ? organizationName
                                : getEmployeeName({
                                    name: employeeName,
                                    type: jobClassType,
                                    jobPosition: jobPositionName,
                                    jobDuty: jobDutyName,
                                  }),
                            organizationName:
                              employeeId === 0 ? undefined : organizationName,
                            avatar:
                              employeeId === 0
                                ? ''
                                : getAvatarPath(companyId, employeeId),
                            icon: employeeId === 0 ? 'sitemap-fill' : undefined,
                          },
                          { type: 'date', value: createAt, dateType: true },
                        ]}
                        approvalLine={approvalLine}
                        onClick={handleAttachedDocumentDialog}
                      />
                    );
                  })
                ) : (
                  <EmptyData message="검색결과가 없습니다." />
                )}
              </>
            )}
          </PostList.Body>
        </PostList>
      </DrawerBody>
      <DrawerAction>
        {confirmVisible ? (
          <Button
            text="선택완료"
            variant="contained"
            onClick={handleConfirmClick}
          />
        ) : null}
      </DrawerAction>
    </Drawer>
  );
}

export default ApprovalAttachedDocumentSelectDrawerContainer;
