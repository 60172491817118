import React from 'react';
import DialogBody from '../dialog/DialogBody';
import DialogFooter from '../dialog/DialogFooter';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';

function PromptMessage(props: {
  children: React.ReactNode;
  submitLabel?: string;
  submitColor?: 'danger' | 'secondary' | 'inherit';
  submitNoDuplication?: boolean;
  onSubmit(): void;
  onCancel?(): void;
}): JSX.Element {
  return (
    <Dialog size="xs">
      <DialogBody>
        <div className="eui-alert-message eui-PromptMessage-message">
          {props.children}
        </div>
      </DialogBody>
      <DialogFooter>
        {props.onCancel && <Button text="취소" onClick={props.onCancel} />}
        <Button
          noDuplication={props.submitNoDuplication}
          color={props.submitColor}
          text={props.submitLabel || '확인'}
          variant="contained"
          onClick={props.onSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default PromptMessage;
