import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AttendanceNoticeDocumentViewDialog from './AttendanceNoticeDocumentViewDialog';
import AttendanceNoticeDocumentEditDialog from './AttendanceNoticeDocumentEditDialog';
import { AttendanceNotice } from './AttendanceNoticeContainer';
import {
  RootState,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import { employeeActions } from '../../../../../groupware-directory/stores/directory/employee';
import FormBuilder from '../../../../../groupware-approval/stores/approval/FormBuilder';

function AttendanceNoticeDialog(props: {
  view: AttendanceNotice;
  usePlanButtonVisible: boolean;
  search: string;
  onSave(contents: string, updateAlertForm?: string): void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const principal = useSelector((state: RootState) => state.session.principal);

  // 사용 계획서 제출 클릭 이벤트.
  const handleUsePlanClick = (
    rest: string,
    isAlertFormSignature: boolean,
    content?: string,
  ) => {
    dispatch(employeeActions.findView({ id: principal.employeeId })).then(
      (result) => {
        if ((result as { error?: string }).error === undefined) {
          setState((prevState) => ({
            ...prevState,
            type: 'create',
            rest,
            isAlertFormSignature,
            alertForm: content,
          }));
        }
      },
    );
  };

  // 사용 계획서 저장 클릭 이벤트.
  const handleUsePlanSave = (contents: string, signature?: string) => {
    const getMacro = (arg: { id: string }) => {
      const { id } = arg;
      /** 연차촉진 대상자서명 */
      if (id === 'ATTENDANCE/SENDNOTICE_SIGNATURE') return signature ?? '';
      return undefined;
    };

    let updateAlertForm = state.alertForm;
    if (state.isAlertFormSignature && state.alertForm && signature) {
      updateAlertForm = FormBuilder.binding({
        html: state.alertForm,
        getMacro,
      });
    }

    props.onSave(contents, updateAlertForm);
  };

  const [state, setState] = useState(() => {
    return {
      type: 'read' as 'read' | 'create',
      rest: '',
      isAlertFormSignature: false,
      alertForm: undefined as string | undefined,
    };
  });
  const { type } = state;
  if (type === 'create') {
    return (
      <AttendanceNoticeDocumentEditDialog
        search={props.search}
        onClick={handleUsePlanSave}
        rest={state.rest}
        isAlertFormSignature={state.isAlertFormSignature}
      />
    );
  }
  return (
    <AttendanceNoticeDocumentViewDialog
      view={props.view}
      usePlanButtonVisible={props.usePlanButtonVisible}
      search={props.search}
      onClick={handleUsePlanClick}
    />
  );
}

export default AttendanceNoticeDialog;
