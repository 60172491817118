import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import FeedBack from '../../../../components/alert/FeedBack';
import Button from '../../../../components/button/Button';
import Chip from '../../../../components/chip/Chip';
import ChipGroup from '../../../../components/chip/ChipGroup';
import CustomDatePicker from '../../../../components/date/CustomDatePicker';
import Menu from '../../../../components/menu/Menu';
import PostWrite from '../../../../components/post/PostWrite';
import DropMenu from '../../../../components/selectField/DropMenu';
import SelectField from '../../../../components/selectField/SelectField';
import TextField from '../../../../components/textfield/TextField';
import {
  DirectoryTreeItem,
  DirectoryTreeItemArg,
  getDirectoryTreeId,
} from '../../../../components/tree/DirectoryTree';
import Tree from '../../../../components/tree/Tree';
import { DirectoryType } from '../../../../groupware-common/types';
import { IconType } from '../../../../groupware-common/types/icon';
import { getQueryParams, hangul } from '../../../../groupware-common/utils';
import {
  dateFormat,
  initialDate,
  timezoneDate,
} from '../../../../groupware-common/utils/ui';
import DirectoryMenuTreeContainer from '../../../../groupware-directory/containers/DirectoryMenuTreeContainer';
import {
  getCompanyName,
  getOrganizationName,
  useDirectory,
} from '../../../../groupware-directory/stores/directory';
import {
  RootState,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import { sessionActions } from '../../../../groupware-webapp/stores/session';
import { resourcesActions } from '../../../stores/resources';
import DetailRepeatDialog, {
  dateToWeekString,
  formatRepeatDaysType,
  RepeatDaysType,
  summaryType,
} from '../../../../components/repeatDialog/DetailRepeatDialog';
import { makeTreeData } from '../../root/resource/common/ResourceDrawer';

function ResourceAdminComposeContainer(props: {
  pathname: string;
  search: string;
  hash: string;
}): JSX.Element {
  const { pathname, search, hash } = props;
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const directory = useDirectory();
  const organizations = useSelector(
    (state: RootState) => state.directory.organization.list.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );

  const principal = useSelector((state: RootState) => state.session.principal);
  const display = useSelector((state: RootState) => state.session.display);
  const folders = useSelector((state: RootState) => state.resource.folder.list);
  const items = useSelector(
    (state: RootState) => state.resource.folder.items.list,
  );

  const reservation = useSelector(
    (state: RootState) => state.resource.resources.view,
  );

  const bottom: { value: string; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const timeValue = i.toString().padStart(2, '0');
    bottom.push({ value: `${timeValue}:00`, label: `${timeValue}:00` });
    bottom.push({ value: `${timeValue}:30`, label: `${timeValue}:30` });
  }
  /** 트리 아이템 생성. */
  const data = useMemo(() => {
    const result = [
      ...folders.map((a) => {
        return {
          id: a.id,
          parentId: a.parentId,
          text: a.name,
          strings: hangul.d(a.name),
          icon: 'folder' as const,
          seq: a.seq,
        };
      }),
      ...items.map((a) => {
        return {
          id: a.id,
          parentId: a.folderId,
          text: a.name,
          strings: hangul.d(a.name),
          // eslint-disable-next-line prettier/prettier
          icon: a.useApprove ? 'document-check' as const : 'document-clock' as const,
          seq: a.seq,
        };
      }),
    ];
    return makeTreeData(result);
  }, [folders, items]);

  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const parse: {
    start: string;
    end: string;
    employeeId: number;
    title: string;
    remark: string;
    originStart: string;
    originEnd: string;
    repeatType?: string;
    isDirectorySelected: boolean;
  } = queryParams.queryWord ? JSON.parse(queryParams.queryWord) : undefined;
  const todayStart = timezoneDate();
  if (todayStart.getMinutes() < 30) todayStart.setMinutes(0);
  else todayStart.setMinutes(30);
  const todayEnd = new Date(todayStart);
  todayEnd.setMinutes(todayStart.getMinutes() + 30);

  const initialState = reservation
    ? {
        title: parse ? parse.title : '',
        remark: parse ? parse.remark : '',
        start: parse ? new Date(parse.start) : todayStart,
        end: parse ? new Date(parse.end) : todayEnd,
        itemId: queryParams.id ?? 0,
        itemName: queryParams.id
          ? items.find((a) => a.id === queryParams.id)?.name ?? ''
          : '',
        resourceMenuPoint: undefined,
        isPopupVisible: false,
        repeatState: 'false',
        repeat: reservation.repeat && {
          frequency: reservation.repeat.frequency,
          cycle: reservation.repeat.cycle.toString(),
          repeatDays: {
            mon: reservation.repeat.repeatDays.some((a) => a === week[1]),
            tue: reservation.repeat.repeatDays.some((a) => a === week[2]),
            wed: reservation.repeat.repeatDays.some((a) => a === week[3]),
            thu: reservation.repeat.repeatDays.some((a) => a === week[4]),
            fri: reservation.repeat.repeatDays.some((a) => a === week[5]),
            sat: reservation.repeat.repeatDays.some((a) => a === week[6]),
            sun: reservation.repeat.repeatDays.some((a) => a === week[0]),
          },
          monthRepeatStandard: reservation.repeat.monthRepeatStandard,
          endType:
            reservation.repeat.endDate !== '9999-12-31'
              ? '반복 종료일'
              : '계속 반복',
          endDay: reservation.repeat.endDate,
          endCount: 1,
        },
        summary: reservation.repeat
          ? summaryType(timezoneDate(reservation.startDateTime), {
              frequency: reservation.repeat.frequency,
              cycle: reservation.repeat.cycle.toString(),
              repeatDays: reservation.repeat.repeatDays,
              monthRepeatStandard: reservation.repeat.monthRepeatStandard,
              endType:
                reservation.repeat.endDate !== '9999-12-31'
                  ? '반복 종료일'
                  : '계속 반복',
              endDay: reservation.repeat.endDate,
              endCount: 1,
            })
          : undefined,
        alarms:
          reservation.alarms.length > 0
            ? reservation.alarms.map((a) => ({
                id: a.id,
                type: a.type,
                timeUnit: a.timeUnit,
                ammount: a.ammount,
                updateAt: a.updateAt,
              }))
            : [],
        shareMenuPoint: undefined,
        sharers:
          reservation.sharers.length > 0
            ? reservation.sharers.map((a) => ({
                referenceCompanyId: a.referenceCompanyId,
                referenceId: a.referenceId,
                referenceType: a.referenceType,
              }))
            : [],
        employeeId: parse ? parse.employeeId : reservation.employeeId,
        directoryMenuPoint: undefined,
      }
    : {
        title: parse ? parse.title : '',
        remark: parse ? parse.remark : '',
        start: parse ? new Date(parse.start) : todayStart,
        end: parse ? new Date(parse.end) : todayEnd,
        itemId: queryParams.id ?? 0,
        itemName: queryParams.id
          ? items.find((a) => a.id === queryParams.id)?.name ?? ''
          : '',
        resourceMenuPoint: undefined,
        isPopupVisible: false,
        repeatState: 'false',
        repeat: undefined,
        summary: undefined,
        alarms: [],
        shareMenuPoint: undefined,
        sharers: [],
        employeeId: parse ? parse.employeeId : principal.employeeId,
        directoryMenuPoint: undefined,
      };
  const [state, setState] = useState<{
    title: string;
    remark: string;
    start: Date;
    end: Date;
    itemId: number;
    itemName: string;
    resourceMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    isPopupVisible: boolean;
    repeatState: string;
    repeat?: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: string; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    };
    summary?: {
      one: string;
      two: string;
      three: string;
    };
    alarms: {
      id: number;
      type: string;
      timeUnit: string;
      ammount: number;
      updateAt?: string;
      lookupDeleteAt?: string;
    }[];
    shareMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
    sharers: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string;
    }[];
    employeeId: number;
    directoryMenuPoint:
      | { x: number; y: number; width: number; height: number }
      | undefined;
  }>(initialState);
  const [validation, setValidation] = useState('');
  const [reload, setReload] = useState(true);

  const handlePrevClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''; // Chrome에서 동작하도록; deprecated
  };

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', handlePrevClose);
    })();
    if (reload) {
      (() => {
        window.addEventListener('beforeunload', handlePrevClose);
      })();

      return () => {
        window.removeEventListener('beforeunload', handlePrevClose);
      };
    }
    return () => {
      window.removeEventListener('beforeunload', handlePrevClose);
    };
  }, [queryParams.contentMode]);

  /** 반복예약 리스트 구하기. */
  const makeRepeatList = (date: Date) => {
    if (dateToWeekString(date) === '다섯')
      return [
        { value: 'false', label: '반복 안함' },
        { value: 'days', label: '매일' },
        { value: 'weeks', label: `매주 ${week[date.getDay()]}요일` },
        {
          value: 'lastMonths',
          label: `매월 마지막 ${week[date.getDay()]}요일`,
        },
        {
          value: 'years',
          label: `매년 ${dateFormat(date, 'MM월 DD일')}`,
        },
        { value: 'weekdays', label: `주중 매일(월-금)` },
        { value: 'etc', label: `맞춤...` },
      ];
    const aWeekLater = new Date(date);
    aWeekLater.setDate(date.getDate() + 7);
    if (aWeekLater.getMonth() === date.getMonth())
      return [
        { value: 'false', label: '반복 안함' },
        { value: 'days', label: '매일' },
        { value: 'weeks', label: `매주 ${week[date.getDay()]}요일` },
        {
          value: 'months',
          label: `매월 ${dateToWeekString(date)}번째 ${
            week[date.getDay()]
          }요일`,
        },
        {
          value: 'years',
          label: `매년 ${dateFormat(date, 'MM월 DD일')}`,
        },
        { value: 'weekdays', label: `주중 매일(월-금)` },
        { value: 'etc', label: `맞춤...` },
      ];
    return [
      { value: 'false', label: '반복 안함' },
      { value: 'days', label: '매일' },
      { value: 'weeks', label: `매주 ${week[date.getDay()]}요일` },
      {
        value: 'months',
        label: `매월 ${dateToWeekString(date)}번째 ${week[date.getDay()]}요일`,
      },
      {
        value: 'lastMonths',
        label: `매월 마지막 ${week[date.getDay()]}요일`,
      },
      {
        value: 'years',
        label: `매년 ${dateFormat(date, 'MM월 DD일')}`,
      },
      { value: 'weekdays', label: `주중 매일(월-금)` },
      { value: 'etc', label: `맞춤...` },
    ];
  };
  const initialSelected = makeRepeatList(state.start);
  const [repeatSelected, setRepeatSelected] = useState(initialSelected);

  useEffect(() => {
    if (reservation && reservation.repeat) {
      const summary = summaryType(timezoneDate(reservation.startDateTime), {
        frequency: reservation.repeat.frequency,
        cycle: reservation.repeat.cycle.toString(),
        repeatDays: reservation.repeat.repeatDays,
        monthRepeatStandard: reservation.repeat.monthRepeatStandard,
        endType:
          reservation.repeat.endDate !== '9999-12-31'
            ? '반복 종료일'
            : '계속 반복',
        endDay: reservation.repeat.endDate,
        endCount: 0,
      });
      const value =
        reservation.repeat.frequency === 1
          ? `${summary.one}${summary.three}`
          : `${summary.one}${summary.two}${summary.three}`;
      const selected = initialSelected.find((a) => a.label === value);
      if (selected) {
        setState((prev) => ({
          ...prev,
          repeatState: selected.value,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          repeatState: 'add',
        }));
        initialSelected.splice(6, 0, { value: 'add', label: `${value}` });
      }
      setRepeatSelected(initialSelected);
    }
  }, [data]);

  const alarmTypeData = [
    { value: 'NONE', label: '- 선택 -' },
    { value: 'ALARM', label: '알람' },
    // { value: 'MAIL', label: '메일' }, // CHECKLIST 구현 안됨.
  ];
  const timeTypeData = [
    { value: 'MINUTE', label: '분전' },
    { value: 'HOUR', label: '시간전' },
    { value: 'DAY', label: '일전' },
    { value: 'WEEK', label: '주전' },
  ];

  const renderDialog = () => {
    if (state.isPopupVisible) {
      return (
        <DetailRepeatDialog
          resource
          start={state.start}
          repeat={state.repeat}
          summary={state.summary}
          onSave={handleRepeatSave}
          onClose={handleRepeatClose}
        />
      );
    }
    return null;
  };

  const handleClick = (event?: React.MouseEvent) => {
    const { resourceMenuPoint } = state;
    if (event !== undefined && resourceMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        resourceMenuPoint: { x, y, width, height },
      }));
    } else setState((prev) => ({ ...prev, resourceMenuPoint: undefined }));
  };

  const handleItemClick = (id: number, parentId?: number, icon?: IconType) => {
    if (icon === ('folder' as const)) return;
    const itemName = items.find((a) => a.id === id)?.name ?? '';
    setState((prev) => ({
      ...prev,
      itemId: id,
      itemName,
      resourceMenuPoint: undefined,
    }));
  };

  /** 예약명 변경. */
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  /** 날짜 변경. */
  const handleChangeDate = (date: Date | null, type: string) => {
    if (date === null) return;
    date.setSeconds(0);
    if (type === 'start') {
      let { end } = state;
      if (end <= date) {
        end = new Date(date);
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
        end.setSeconds(0);
      }
      setState((prev) => ({
        ...prev,
        start: date,
        end,
        repeatState: 'false',
        repeat: undefined,
        summary: undefined,
      }));
      setRepeatSelected(makeRepeatList(date));
    } else {
      setState((prev) => ({
        ...prev,
        start: date < prev.start ? date : prev.start,
        end: date,
      }));
    }
  };

  /** 시간 변경. */
  const handleChangeTime = (value: string, type: string) => {
    const { start, end } = state;
    const newTime = Number(value.split(':')[0]);
    const newMinute = Number(value.split(':')[1]);
    if (type === 'start') {
      const date = new Date(start);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (end <= date) {
        end.setHours(date.getHours());
        end.setMinutes(date.getMinutes() + 30);
      }
      setState((prev) => ({ ...prev, start: date, end }));
    } else {
      const date = new Date(end);
      date.setHours(newTime);
      date.setMinutes(newMinute);
      if (date <= start) {
        start.setHours(date.getHours());
        start.setMinutes(date.getMinutes() - 30);
      }
      setState((prev) => ({ ...prev, start, end: date }));
    }
  };

  /** 신청 사유 변경. */
  const handleChangeRemark = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      remark: event.target.value,
    }));
  };

  /** 예약자 조직도 오픈. */
  const handleReservationMenuToggle = (event?: React.MouseEvent) => {
    const { directoryMenuPoint } = state;
    if (event !== undefined && directoryMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        directoryMenuPoint: { x, y, width, height },
      }));
    } else setState((prev) => ({ ...prev, directoryMenuPoint: undefined }));
  };

  /** 예약자 선택. (대리 예약.) */
  const handleReservationNameSelected = (arg: {
    item: DirectoryTreeItem;
    paths: {
      type: DirectoryType;
      id: number;
      name: string;
      treeId: string;
      text: string;
    }[];
  }) => {
    if (arg.item.extra.type === 'employee') {
      const parseData = arg.item.id.split('_');
      setState((prev) => ({ ...prev, employeeId: Number(parseData[2]) }));
    }
    handleReservationMenuToggle();
  };

  /** 반복일 변경. */
  const handleChangeRepeatUse = (value: string) => {
    if (value !== 'add' && value !== 'etc') setRepeatSelected(initialSelected);
    if (value === 'false')
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: undefined,
        summary: undefined,
      }));
    else if (value === 'days') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 1,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 0,
          endType: '계속 반복',
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: '매일',
          two: '',
          three: '',
        },
      }));
    } else if (value === 'weeks') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 2,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 0,
          endType: '계속 반복',
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: '매주',
          two: ` ${week[state.start.getDay()]}요일`,
          three: '',
        },
      }));
    } else if (value === 'months') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 3,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 1,
          endType: '계속 반복',
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: '매월',
          two: ` ${dateToWeekString(state.start)}번째 ${
            week[state.start.getDay()]
          }요일`,
          three: '',
        },
      }));
    } else if (value === 'lastMonths') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 3,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 2,
          endType: '계속 반복',
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: '매월',
          two: ` 마지막 ${week[state.start.getDay()]}요일`,
          three: '',
        },
      }));
    } else if (value === 'years') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 4,
          cycle: '1',
          repeatDays: formatRepeatDaysType(state.start),
          monthRepeatStandard: 0,
          endType: '계속 반복',
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: '매년',
          two: ` ${dateFormat(state.start, 'MM월 DD일')}`,
          three: '',
        },
      }));
    } else if (value === 'weekdays') {
      setState((prev) => ({
        ...prev,
        repeatState: value,
        repeat: {
          frequency: 2,
          cycle: '1',
          repeatDays: {
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: false,
            sun: false,
          },
          monthRepeatStandard: 0,
          endType: '계속 반복',
          endDay: '9999-12-31',
          endCount: 1,
        },
        summary: {
          one: '매주',
          two: ` 월요일 화요일 수요일 목요일 금요일`,
          three: '',
        },
      }));
    } else if (value === 'etc') {
      setState((prev) => ({
        ...prev,
        isPopupVisible: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        repeatState: value,
      }));
    }
  };

  /** 반복설정 저장 */
  const handleRepeatSave = (
    repeat: {
      frequency: number; // 반복 빈도.
      cycle: string; // 반복 주기.
      repeatDays: RepeatDaysType; // 반복일. (반복빈도 매주일 경우)
      monthRepeatStandard: number; // 반복 마감일. (반복빈도 매월일 경우)
      endType: string; // 종료일
      endDay: string; // 반복 종료일 - 날짜
      endCount: number; // 반복 종료일 - 횟수
    },
    summary: {
      one: string;
      two: string;
      three: string;
    },
  ) => {
    const { one, two, three } = summary;
    let selectedData =
      repeat.frequency === 1 ? `${one}${three}` : `${one}${two}${three}`;
    if (selectedData === '매주 월요일 화요일 수요일 목요일 금요일')
      selectedData = '주중 매일(월-금)';
    let repeatState = 'add';
    const selectedRepeat = initialSelected.find(
      (a) => a.label === selectedData,
    );
    if (selectedRepeat) {
      repeatState = selectedRepeat.value;
      setRepeatSelected(initialSelected);
    } else {
      repeatState = 'add';
      initialSelected.splice(6, 0, { value: 'add', label: `${selectedData}` });
      setRepeatSelected(initialSelected);
    }
    setState((prev) => ({
      ...prev,
      isPopupVisible: false,
      repeatState,
      repeat,
      summary,
    }));
  };

  /** 반복설정 대화상자 닫기 */
  const handleRepeatClose = () => {
    setState((prev) => ({ ...prev, isPopupVisible: false }));
  };

  /** 공유자 조직도 오픈. */
  const handleDirectoryTreeMenuToggle = (event?: React.MouseEvent) => {
    const { shareMenuPoint } = state;
    if (event !== undefined && shareMenuPoint === undefined) {
      const { x, y, width, height } =
        event.currentTarget.getBoundingClientRect();
      setState((prev) => ({
        ...prev,
        shareMenuPoint: { x, y, width, height },
      }));
    } else setState((prev) => ({ ...prev, shareMenuPoint: undefined }));
  };

  /** 알림 타입 변경. */
  const handleChangeAlarmType = (value: string, id: number) => {
    const alarms = state.alarms.map((a) => {
      if (a.id === id)
        return {
          ...a,
          type: value,
        };
      return a;
    });
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 알림 추가. */
  const handleAlarmClick = () => {
    setState((prev) => ({
      ...prev,
      alarms: [
        ...prev.alarms,
        {
          id: timezoneDate().getTime(),
          type: 'NONE',
          ammount: 0,
          timeUnit: 'MINUTE',
        },
      ],
    }));
  };

  /** 알림 시간 변경 */
  const handleChangeAlarmTime = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
  ) => {
    const time = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    const alarms = state.alarms.map((a) => {
      if (a.id === id)
        return {
          ...a,
          ammount: Number(time),
        };
      return a;
    });
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 알림 시간 타입 변경. */
  const handleChangeAlarmTimeType = (value: string, id: number) => {
    const alarms = state.alarms.map((a) => {
      if (a.id === id)
        return {
          ...a,
          timeUnit: value,
        };
      return a;
    });
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 알림 삭제 */
  const handleAlarmDelete = (id: number) => {
    const alarms = state.alarms.filter((a) => a.id !== id);
    setState((prev) => ({ ...prev, alarms }));
  };

  /** 공유자 삭제. */
  const handleSharerDelete = (id: number) => {
    setState((prev) => ({
      ...prev,
      sharers: prev.sharers.filter((a) => a.referenceId !== id),
    }));
  };

  /** 공유자 추가. */
  const handleSharerAppend = (arg: DirectoryTreeItemArg) => {
    const { extra } = arg.item;

    if (extra.type === 'company') {
      const { companyId } = extra;
      setState((prev) => {
        if (
          prev.sharers.some(
            (a) => a.referenceType === 1 && a.referenceId === companyId,
          )
        )
          return { ...prev, shareMenuPoint: undefined };
        return {
          ...prev,
          sharers: [
            ...prev.sharers,
            {
              referenceCompanyId: companyId,
              referenceId: companyId,
              referenceType: 1,
            },
          ],
        };
      });
    }
    if (extra.type === 'organization') {
      const { companyId, organizationId } = extra;
      setState((prev) => {
        if (
          prev.sharers.some(
            (a) => a.referenceType === 2 && a.referenceId === organizationId,
          )
        )
          return { ...prev, shareMenuPoint: undefined };

        const organization = organizations.find(
          (a) => a.companyId === companyId && a.id === organizationId,
        );
        if (organization === undefined)
          return { ...prev, shareMenuPoint: undefined };

        return {
          ...prev,
          sharers: [
            ...prev.sharers,
            {
              referenceCompanyId: companyId,
              referenceId: organization.id,
              referenceType: 2,
            },
          ],
        };
      });
    } else if (extra.type === 'employee') {
      const { companyId, employeeId } = extra;
      setState((prev) => {
        if (prev.sharers.some((a) => a.referenceId === employeeId))
          return { ...prev, shareMenuPoint: undefined };

        const employee = employees.find(
          (a) => a.companyId === companyId && a.id === employeeId,
        );
        if (employee === undefined)
          return { ...prev, shareMenuPoint: undefined };

        return {
          ...prev,
          change: true,
          sharers: [
            ...prev.sharers,
            {
              referenceCompanyId: companyId,
              referenceId: employeeId,
              referenceType: 3,
            },
          ],
        };
      });
    }
  };

  /** 필수 입력값 확인 이벤트. */
  const handleEssential = () => {
    if (state.itemId === 0 || state.itemName === '') {
      setValidation(() => '자원 아이템을 선택하세요.');
      return;
    }
    if (state.title.trim() === '') {
      setValidation('제목을 입력하세요.');
      return;
    }
    if (state.alarms.find((a) => a.type === 'NONE')) {
      setValidation('알림 타입을 지정하세요.');
      return;
    }
    if (state.alarms.find((a) => a.ammount === 0))
      setValidation('알림 시간을 지정하세요.');
  };

  /** 예약 등록. */
  const handleSave = () => {
    delete queryParams.pageNo;
    delete queryParams.contentMode;
    const route = { pathname, search: getQueryParams(queryParams), hash };
    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const arg = {
      itemId: state.itemId,
      employeeId: state.employeeId,
      name: state.title,
      remark: state.remark,
      startDateTime: dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      endDateTime: dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS'),
      repeat: state.repeat
        ? {
            frequency: state.repeat.frequency,
            cycle: parseInt(state.repeat.cycle, 10),
            monthRepeatStandard:
              state.repeat.frequency === 3
                ? state.repeat.monthRepeatStandard
                : undefined,
            endDate:
              state.repeat.endType === '반복 종료일'
                ? dateFormat(initialDate(state.repeat.endDay), 'yyyy-MM-DD')
                : '9999-12-31',
            days:
              state.repeat.frequency === 2
                ? state.repeat.repeatDays
                : undefined,
          }
        : undefined,
      sharers:
        state.sharers.length > 0
          ? state.sharers.map((a) => ({
              referenceCompanyId: a.referenceCompanyId,
              referenceId: a.referenceId,
              referenceType: a.referenceType,
            }))
          : [],
      alarms:
        state.alarms.length > 0
          ? state.alarms.map((a) => ({
              type: a.type,
              timeUnit: a.timeUnit,
              ammount: a.ammount,
            }))
          : [],
    };
    setReload(false);
    dispatch(resourcesActions.saveReservation({ data: arg })).then((result) => {
      if ((result as { error?: string }).error === undefined)
        dispatch(
          resourcesActions.adminReservationList({
            search: getQueryParams(queryParams),
            isDirectorySelected: parse.isDirectorySelected ?? false,
            route,
          }),
        );
    });
  };

  /** 예약 수정 */
  const handleUpdate = () => {
    if (!reservation) return;
    delete queryParams.pageNo;
    delete queryParams.contentMode;
    const route = { pathname, search: getQueryParams(queryParams), hash };
    let repeat:
      | {
          frequency: number;
          cycle: number;
          monthRepeatStandard?: number;
          endDate: string;
          days?: RepeatDaysType;
          updateAt?: string;
          isDelete?: boolean; // 반복객체 사용 유무
        }
      | undefined = state.repeat
      ? {
          frequency: state.repeat.frequency,
          cycle: parseInt(state.repeat.cycle, 10),
          monthRepeatStandard:
            state.repeat.frequency === 3
              ? state.repeat.monthRepeatStandard
              : undefined,
          endDate:
            state.repeat.endType === '반복 종료일'
              ? dateFormat(initialDate(state.repeat.endDay), 'yyyy-MM-DD')
              : '9999-12-31',
          days:
            state.repeat.frequency === 2 ? state.repeat.repeatDays : undefined,
          updateAt: undefined,
          isDelete: false,
        }
      : undefined;
    const oldRepeat = reservation.repeat
      ? {
          frequency: reservation.repeat.frequency,
          cycle: reservation.repeat.cycle,
          monthRepeatStandard:
            reservation.repeat.frequency === 3
              ? reservation.repeat.monthRepeatStandard
              : undefined,
          endDate: reservation.repeat.endDate,
          days:
            reservation.repeat.repeatDays.length === 0
              ? undefined
              : {
                  mon: reservation.repeat.repeatDays.some((a) => a === week[1]),
                  tue: reservation.repeat.repeatDays.some((a) => a === week[2]),
                  wed: reservation.repeat.repeatDays.some((a) => a === week[3]),
                  thu: reservation.repeat.repeatDays.some((a) => a === week[4]),
                  fri: reservation.repeat.repeatDays.some((a) => a === week[5]),
                  sat: reservation.repeat.repeatDays.some((a) => a === week[6]),
                  sun: reservation.repeat.repeatDays.some((a) => a === week[0]),
                },
          updateAt: undefined,
          isDelete: false,
        }
      : undefined;
    if (JSON.stringify(oldRepeat) === JSON.stringify(repeat))
      repeat = undefined;
    else if (oldRepeat) {
      if (repeat)
        repeat = { ...repeat, updateAt: reservation.repeat?.updateAt };
      else repeat = { ...oldRepeat, isDelete: true };
    }

    let newAlarms: {
      id?: number;
      type: string;
      timeUnit: string;
      ammount: number;
      updateAt?: string;
      lookupDeleteAt?: string;
    }[] = [...state.alarms];
    const newSharers: {
      referenceCompanyId: number;
      referenceId: number;
      referenceType: number;
      lookupDeleteAt?: string | undefined;
    }[] = [...state.sharers];

    if (reservation.sharers.length > 0) {
      reservation.sharers.forEach((a) => {
        if (
          state.sharers.find((b) => b.referenceId === a.referenceId) ===
          undefined
        ) {
          newSharers.push({
            referenceCompanyId: a.referenceCompanyId,
            referenceId: a.referenceId,
            referenceType: a.referenceType,
            lookupDeleteAt: a.updateAt,
          });
        }
      });
      reservation.sharers.forEach((a) => {
        const index = newSharers.findIndex(
          (b) => b.referenceId === a.referenceId && !b.lookupDeleteAt,
        );
        if (index !== -1) newSharers.splice(index, 1);
      });
    }
    if (reservation.alarms.length > 0) {
      reservation.alarms.forEach((a) => {
        if (state.alarms.find((b) => b.id === a.id) === undefined) {
          newAlarms.push({
            id: a.id,
            type: a.type,
            timeUnit: a.timeUnit,
            ammount: a.ammount,
            lookupDeleteAt: a.updateAt,
          });
        }
      });
    }
    newAlarms = newAlarms.map((a) => {
      if (!a.updateAt && !a.lookupDeleteAt)
        return {
          type: a.type,
          timeUnit: a.timeUnit,
          ammount: a.ammount,
        };
      return a;
    });

    const start = initialDate(state.start);
    const end = initialDate(state.end);
    const startDateTime = moment(reservation.startDateTime).isSame(start)
      ? undefined
      : dateFormat(start, 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    const endDateTime = moment(reservation.endDateTime).isSame(end)
      ? undefined
      : dateFormat(end, 'yyyy-MM-DD[T]HH:mm:ss.SSS');
    setReload(false);
    // 반복 예약 수정인 경우.
    if (parse.repeatType) {
      const update = {
        id: reservation.id,
        itemId: state.itemId,
        employeeId: state.employeeId,
        name: state.title,
        remark: reservation.remark === state.remark ? undefined : state.remark,
        startDateTime,
        endDateTime,
        repeat,
        sharers: newSharers.length === 0 ? undefined : newSharers,
        alarms: newAlarms.length === 0 ? undefined : newAlarms,
        updateAt: reservation.updateAt,
      };
      dispatch(
        resourcesActions.updateRepeatReservation({
          type: parse.repeatType,
          data: update,
        }),
      ).then((result) => {
        if ((result as { error?: string }).error === undefined)
          dispatch(
            resourcesActions.adminReservationList({
              search: getQueryParams(queryParams),
              isDirectorySelected: parse.isDirectorySelected ?? false,
              route,
            }),
          );
      });
    }
    // 일반 예약 수정인 경우.
    else {
      const arg = {
        id: reservation.id,
        itemId: state.itemId,
        employeeId: state.employeeId,
        name: state.title,
        remark: reservation.remark === state.remark ? undefined : state.remark,
        startDateTime,
        endDateTime,
        repeat,
        sharers: newSharers.length === 0 ? undefined : newSharers,
        alarms: newAlarms.length === 0 ? undefined : newAlarms,
        updateAt: reservation.updateAt,
      };
      dispatch(resourcesActions.updateReservation({ data: arg })).then(
        (result) => {
          if ((result as { error?: string }).error === undefined)
            dispatch(
              resourcesActions.adminReservationList({
                search: getQueryParams(queryParams),
                isDirectorySelected: parse.isDirectorySelected ?? false,
                route,
              }),
            );
        },
      );
    }
  };

  const handleClose = () => {
    delete queryParams.contentMode;
    dispatch(resourcesActions.reservationViewClear());
    dispatch(sessionActions.search(getQueryParams(queryParams)));
  };

  const writer = getDirectoryData({
    ...directory,
    companyId: principal.companyId,
    employeeId: state.employeeId,
  });
  const startTime = state.start.getHours().toString().padStart(2, '0');
  const startMinute = state.start.getMinutes().toString().padStart(2, '0');
  const endTime = state.end.getHours().toString().padStart(2, '0');
  const endMinute = state.end.getMinutes().toString().padStart(2, '0');
  return (
    <div className="reservation-create">
      <PostWrite name="reservation" fullSize>
        <PostWrite.Toolbar onCancel={handleClose}>
          {state.itemId === 0 ||
          state.title.trim() === '' ||
          state.alarms.find((a) => a.type === 'NONE') ||
          state.alarms.find((a) => a.ammount === 0) ? (
            <Button text="저장" variant="contained" onClick={handleEssential} />
          ) : (
            <Button
              noDuplication
              text="저장"
              variant="contained"
              onClick={reservation ? handleUpdate : handleSave}
            />
          )}
        </PostWrite.Toolbar>
        <div className="resource-message">
          <ul>
            {/* eslint-disable-next-line prettier/prettier */}
            <li>자원의 사용을 예약합니다. 예약승인이 필요한 자원의 경우에는 신청 후 자원담당자의 승인을 얻어야 최종적으로 예약이 완료됩니다.</li>
            {/* eslint-disable-next-line prettier/prettier */}
            <li>예약승인 시 공유자의 공유받은 예약조회에 예약내용이 공유되어 보여집니다.</li>
            {/* eslint-disable-next-line prettier/prettier */}
            <li>반복은 시작일을 기준으로 설정합니다. 시작일의 요일 혹은 시작일의 날짜를 기준으로 반복됩니다.</li>
          </ul>
        </div>
        <PostWrite.Item required title="자원명">
          <DropMenu
            value={state.itemName}
            onClick={
              reservation
                ? () => setValidation('예약 수정 시 자원 수정은 불가능합니다.')
                : handleClick
            }
            label="자원 선택"
          />
          {state.resourceMenuPoint && (
            <Menu
              point={state.resourceMenuPoint}
              onClose={() =>
                setState((prev) => ({ ...prev, resourceMenuPoint: undefined }))
              }
            >
              <div className="ui-organization-select">
                <div className="body-panel">
                  <Tree items={data} onItemClick={handleItemClick} />
                </div>
              </div>
            </Menu>
          )}
        </PostWrite.Item>
        <PostWrite.Item required title="제목">
          <TextField value={state.title} onChange={handleChangeTitle} />
        </PostWrite.Item>
        <PostWrite.Item title="신청사유">
          <TextField
            multiline
            rows={2}
            value={state.remark}
            onChange={handleChangeRemark}
          />
        </PostWrite.Item>
        <PostWrite.Item title="예약자">
          <DropMenu
            value={`${writer.organizationName}/${writer.employeeName}`}
            label="예약자 선택"
            pressed={state.directoryMenuPoint !== undefined}
            onClick={handleReservationMenuToggle}
          />
          {state.directoryMenuPoint && (
            <DirectoryMenuTreeContainer
              deduplication
              point={state.directoryMenuPoint}
              selectedId={getDirectoryTreeId(
                writer.companyId,
                writer.organizationId,
                writer.employeeId,
              )}
              typeToFilter="employee"
              onItemClick={handleReservationNameSelected}
              onClose={handleReservationMenuToggle}
            />
          )}
        </PostWrite.Item>
        <PostWrite.Item required title="사용기간">
          <div
            style={{
              display: 'inline-flex',
              flexDirection: display === 'phone' ? 'column' : undefined,
            }}
          >
            {display === 'phone' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '5px',
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    시작
                  </span>
                  <CustomDatePicker
                    controlClassName={state.repeat ? 'disabled' : undefined}
                    disabled={!!state.repeat}
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.start}
                    startDate={state.start}
                    endDate={state.end}
                    selectsStart
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'start')
                    }
                  />
                  <SelectField
                    data={bottom}
                    value={`${startTime}:${startMinute}`}
                    onChange={(value) => handleChangeTime(value, 'start')}
                  />
                </div>
                <div
                  style={
                    display === 'phone'
                      ? {
                          display: 'flex',
                          flexDirection: 'row',
                        }
                      : undefined
                  }
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    종료
                  </span>
                  <CustomDatePicker
                    width={110}
                    dateFormat="yyyy-MM-dd"
                    selected={state.end}
                    startDate={state.start}
                    endDate={state.end}
                    minDate={state.start}
                    selectsEnd
                    onChange={(date: Date | null) =>
                      handleChangeDate(date, 'end')
                    }
                  />
                  <SelectField
                    data={bottom}
                    value={`${endTime}:${endMinute}`}
                    onChange={(value) => handleChangeTime(value, 'end')}
                  />
                </div>
              </>
            ) : (
              <>
                <CustomDatePicker
                  controlClassName={state.repeat ? 'disabled' : undefined}
                  disabled={!!state.repeat}
                  width={110}
                  dateFormat="yyyy-MM-dd"
                  selected={state.start}
                  startDate={state.start}
                  endDate={state.end}
                  selectsStart
                  onChange={(date: Date | null) =>
                    handleChangeDate(date, 'start')
                  }
                />
                <SelectField
                  data={bottom}
                  value={`${startTime}:${startMinute}`}
                  onChange={(value) => handleChangeTime(value, 'start')}
                />
                <span style={{ margin: '2px 10px' }}> ~ </span>
                <CustomDatePicker
                  width={110}
                  dateFormat="yyyy-MM-dd"
                  selected={state.end}
                  startDate={state.start}
                  endDate={state.end}
                  minDate={state.start}
                  selectsEnd
                  onChange={(date: Date | null) =>
                    handleChangeDate(date, 'end')
                  }
                />
                <SelectField
                  data={bottom}
                  value={`${endTime}:${endMinute}`}
                  onChange={(value) => handleChangeTime(value, 'end')}
                />
              </>
            )}
          </div>
        </PostWrite.Item>
        <PostWrite.Item title="반복예약">
          <SelectField
            disabled={parse?.repeatType === 'piece' ? true : undefined}
            data={repeatSelected}
            value={state.repeatState}
            onChange={handleChangeRepeatUse}
          />
          {parse?.repeatType === 'piece' && (
            // eslint-disable-next-line prettier/prettier
            <div style={{ display: 'inline-flex', margin: '5px 10px', color: 'var(--secondary-text-color)' }}>
              ※ 이 일정만 수정할 경우, 반복을 설정 할 수 없습니다.
            </div>
          )}
        </PostWrite.Item>
        <PostWrite.Item title="알림">
          {state.alarms.length > 0 && (
            <div style={{ marginBottom: '5px' }}>
              {state.alarms.map((a) => (
                <div key={a.id} style={{ marginTop: '5px' }}>
                  <SelectField
                    data={alarmTypeData}
                    value={a.type}
                    onChange={(value) => handleChangeAlarmType(value, a.id)}
                  />
                  <span style={{ margin: '2.5px' }} />
                  <TextField
                    width={80}
                    value={a.ammount.toString()}
                    onChange={(event) => handleChangeAlarmTime(event, a.id)}
                  />
                  <span style={{ margin: '2.5px' }} />
                  <SelectField
                    data={timeTypeData}
                    value={a.timeUnit}
                    onChange={(value) => handleChangeAlarmTimeType(value, a.id)}
                  />
                  <Button
                    text="삭제"
                    iconType
                    icon="trash-fill"
                    size="sm"
                    onClick={() => handleAlarmDelete(a.id)}
                  />
                </div>
              ))}
            </div>
          )}
          <Button text="+ 알림추가" onClick={handleAlarmClick} />
        </PostWrite.Item>
        <PostWrite.Item title="공유자">
          <ChipGroup add="추가" onAdd={handleDirectoryTreeMenuToggle}>
            {state.sharers.map(
              ({ referenceCompanyId, referenceId, referenceType }) => {
                if (referenceType !== 3)
                  return (
                    <Chip
                      key={`${referenceCompanyId}/${referenceId}`}
                      label={getOrganizationName(
                        referenceCompanyId,
                        referenceId,
                      )}
                      etc={getCompanyName(referenceCompanyId)}
                      icon={
                        referenceType === 2
                          ? ('sitemap-fill' as const)
                          : ('company' as const)
                      }
                      onDelete={() => handleSharerDelete(referenceId)}
                    />
                  );
                const directoryData = getDirectoryData({
                  ...directory,
                  companyId: referenceCompanyId,
                  employeeId: referenceId,
                });
                return (
                  <Chip
                    key={`${referenceCompanyId}/${referenceId}`}
                    label={directoryData.employeeName}
                    etc={directoryData.organizationName}
                    avatar={directoryData.avatar}
                    onDelete={() => handleSharerDelete(referenceId)}
                  />
                );
              },
            )}
            {state.shareMenuPoint && (
              <DirectoryMenuTreeContainer
                deduplication
                point={state.shareMenuPoint}
                typeToFilter={['organization', 'employee']}
                onItemClick={handleSharerAppend}
                onClose={handleDirectoryTreeMenuToggle}
              />
            )}
          </ChipGroup>
        </PostWrite.Item>
      </PostWrite>
      {renderDialog()}
      <FeedBack text={validation} onClose={() => setValidation('')} />
    </div>
  );
}

export default ResourceAdminComposeContainer;
