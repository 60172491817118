import React from 'react';

function Divider(props: {
  orientation?: 'vertical'; // 수직
  className?: string;
}): JSX.Element {
  let classname = 'eui-divider';
  if (props.orientation) classname += ` ${props.orientation}`;
  if (props.className) classname += ` ${props.className}`;

  return <hr className={classname} />;
}

export default Divider;
