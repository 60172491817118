import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationProps } from '../../groupware-common/types';
import ApprovalContentBodyViewTest from '../../groupware-approval/pages/root/approval/ApprovalContentBodyViewTest';
import ComponentSample from '../pages/test/ComponentSample';

function DevelopmentRouter({ location }: LocationProps): JSX.Element {
  // console.log(`${DevelopmentRouter.name}.render`);

  useTranslation();

  const uris = location.pathname.substring(1).split('/');
  const module = `${uris[0]}/${uris[1]}`;

  let content = null;
  switch (module) {
    case 'development/components':
      content = <ComponentSample />;
      break;
    case 'development/test':
      content = <ApprovalContentBodyViewTest type="nosplit" />;
      break;
    default:
      content = <div>404</div>;
      break;
  }

  return <>{content}</>;
}

export default DevelopmentRouter;
