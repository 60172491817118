/**
 * 유형에 따른 직위, 직책 이름을 반환.
 * @param type 표시 유형.
 * @param jobPosition 직위 이름.
 * @param jobDuty 직책 이름.
 * @returns 유형에 따른 직위, 직책 이름.
 */
function getJobClassName(
  type: 'none' | 'jobposition' | 'jobduty' | 'jobposition+jobduty',
  jobPosition: string,
  jobDuty: string,
): string {
  switch (type) {
    case 'none':
      return '';
    case 'jobposition':
      return jobPosition;
    case 'jobduty':
      return jobDuty;
    default: {
      if (jobPosition !== '' && jobDuty !== '')
        return `${jobPosition}/${jobDuty}`;
      return jobPosition || jobDuty || '';
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getJobClassName };
