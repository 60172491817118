import React, { useState } from 'react';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import { getText } from '../../../../../groupware-common/utils';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';

type Props = {
  modecheck: string;
  foderValue?:
    | {
        id: number;
        companyId: number;
        employeeId: number;
        seq: number;
        name: string;
        updateAt: string;
      }
    | undefined;
  onSave?(arg: { status: number; name: string }): void;
  onModSave?(arg: {
    id: number;
    status: number;
    name: string;
    updateAt: string;
  }): void;
  onClose(): void;
};

function ApprovalPreferencesFolderEdit(props: Props): JSX.Element {
  const [state, setState] = useState<{
    id: number;
    name: string;
    updateAt: string;
  }>({
    id:
      props.modecheck === 'add' || props.foderValue === undefined
        ? 0
        : props.foderValue.id,
    name:
      props.modecheck === 'add' || props.foderValue === undefined
        ? ''
        : props.foderValue.name,
    updateAt:
      props.modecheck === 'add' || props.foderValue === undefined
        ? ''
        : props.foderValue.updateAt,
  });

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));
  };

  const handleSave = () => {
    // console.log(' handleSave  dumy.modecheck ', dumy.modecheck);
    if (props.modecheck === 'edit' && props.onModSave !== undefined) {
      const arg1 = {
        id: state.id,
        status: 1,
        name: state.name,
        updateAt: state.updateAt,
      };
      props.onModSave(arg1);
    }
    if (props.modecheck === 'add' && props.onSave !== undefined) {
      const arg2 = {
        status: 1,
        name: state.name,
      };
      props.onSave(arg2);
    }
    // console.log(' handlesave xxxxxxxxxxxxxxxxxx    state: ', state);
    props.onClose();
  };

  return (
    <Drawer title="개인 결재함 등록" onClose={props.onClose}>
      <DrawerBody>
        <PostWrite>
          <PostWrite.Item title="명칭">
            <TextField value={state.name} onChange={handleChangeName} />
          </PostWrite.Item>
        </PostWrite>
      </DrawerBody>
      <DrawerAction>
        <Button
          text={getText('저장')}
          variant="contained"
          onClick={handleSave}
        />
      </DrawerAction>
    </Drawer>
  );
}

export default React.memo(ApprovalPreferencesFolderEdit);
