import React, { useState } from 'react';
import CustomDatePicker from '../../../../../components/date/CustomDatePicker';
import ComponentSampleUI from '../ComponentSampleUI';

function TestCalendar(): JSX.Element {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <ComponentSampleUI.Content title="Date time">
      <CustomDatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        dateFormat="yyyy/MM/dd a h:mm"
        showTimeSelect
      />
    </ComponentSampleUI.Content>
  );
}

export default TestCalendar;
