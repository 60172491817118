import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { statusActions } from '../../../../stores/attendance/dayOffStatus';

function AttendanceDayOffStautsRoute(props: {
  pathname: string;
  search: string;
}): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const route = { pathname, search };
    dispatch(statusActions.list({ search, route }));
  }, [dispatch, pathname, search]);

  return null;
}

export default AttendanceDayOffStautsRoute;
