import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { folderActions } from '../../../../stores/document/folders';
import { trashActions } from '../../../../stores/document/trash';

function DocumentTrashRoute(props: { pathname: string; search: string }): null {
  const { pathname, search } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(folderActions.list()).then(() => {
      dispatch(trashActions.list({ search, route: { pathname, search } }));
    });
  }, [dispatch, pathname, search]);

  return null;
}

export default DocumentTrashRoute;
