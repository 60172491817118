import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { approverMacroActions } from '../../../../stores/approval/approverMacro';

function ApprovalApproverMacroRoute(props: {
  pathname: string;
  search: string;
}): null {
  // console.log(`${ApprovalApproverMacroRoute.name}.render(props)`, props);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const { pathname, search } = props;

    dispatch(
      approverMacroActions.list({
        arg: { search },
        route: { pathname, search },
      }),
    );
  }, [dispatch, props]);

  return null;
}

export default ApprovalApproverMacroRoute;
