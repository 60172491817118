import { useEffect } from 'react';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';

function AttendanceDashboardRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  const { pathname, search, hash } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    Promise.all([
      dispatch(attendancePreferencesActions.attendanceCodeList()),
    ]).then(() => {
      const route = { pathname, search, hash, clearErrors: true };
      if (mount) dispatch(sessionActions.setRoute(route));
    });

    return () => {
      mount = false;
    };
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default AttendanceDashboardRoute;
