import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteConfirmation from '../../../../../components/alert/DeleteConfirmation';
import FeedBack from '../../../../../components/alert/FeedBack';
import EuiBody from '../../../../../components/layout/EuiBody';
import EuiHeader from '../../../../../components/layout/EuiHeader';
import EuiSetting from '../../../../../components/layout/EuiSetting';
import SplitUnselected from '../../../../../components/split/SplitUnselected';
import {
  b62,
  createQueryString,
  getPathMap,
  getPathParams,
  getQueryParams,
  hangul,
} from '../../../../../groupware-common/utils';
import {
  RootState as R,
  useAppDispatch,
} from '../../../../../groupware-webapp/app/store';
import ChangeOrder from '../../../../../groupware-webapp/pages/popup/ChangeOrder';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { sessionActions } from '../../../../../groupware-webapp/stores/session';
import { formActions, formFolderActions } from '../../../../stores/form';
import BoardFormEdit from './BoardFormEdit';
import BoardFormFolder from './BoardFormFolder';
import BoardFormFolderEdit from './BoardFormFolderEdit';
import BoardFormList from './BoardFormList';

function BoardFormContainer(props: {
  pathname: string;
  search: string;
}): JSX.Element {
  const { pathname, search } = props;
  const pathmap = getPathMap('/*/*/*', pathname);
  const { propsFolderId, propsFormId } = getPathParams<{
    propsFolderId?: number;
    propsFormId?: number;
  }>(`/*/*/*/:propsFolderId$base62/:propsFormId$base62`, pathname);
  const queryParams = getQueryParams(search);

  const dispatch = useAppDispatch();

  const folders = useSelector((state: R) => state.boards.form.folder.list);
  const forms = useSelector((state: R) => state.boards.form.list);
  const totalCount = useSelector((state: R) => state.boards.form.totalCount);
  const categories = useSelector(
    (state: R) => state.boards.board.category,
  ).filter((a) => a.type === 'setting');
  const title = categories.find((a) => a.id === 6005)?.name ?? '';

  const folderItems = folders
    .map(({ id, parentId, seq, name: text, updateAt }) => {
      return {
        id,
        parentId,
        seq,
        text,
        strings: hangul.d(text),
        icon: 'folder' as const,
        updateAt,
      };
    })
    .sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1);
  const formItems = forms.map((a) => ({
    checked: a.checked,
    id: a.id,
    category: folders.find((b) => b.id === a.folderId)?.name ?? '',
    subject: a.name,
    status: a.status === 1,
    createAt: a.createAt,
    updateAt: a.updateAt,
  }));

  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollbar.current?.scrollTo(0, 0);
  }, [queryParams.pageNo, queryParams.rowsPerPage, queryParams.searchWord]);

  const { pageNo = 1, rowsPerPage = 15 } = queryParams;
  const [validation, setValidation] = useState('');

  /** 폴더 리스트 클릭. */
  const handleSelectedFolder = (id: number) => {
    const route = {
      pathname: `${pathmap}/${b62(id)}`,
      search: createQueryString({ pageNo: 1, rowsPerPage }),
    };
    dispatch(
      formActions.formList({
        folderId: id,
        route,
      }),
    );
  };

  // 모바일에서만 사용
  const handleFormListClose = () => {
    const path = getPathMap('/*/*/*', props.pathname);
    dispatch(sessionActions.setRoute({ pathname: path }));
  };

  /** 양식 폴더 액션. */
  const handleFolderListAction = (arg: {
    code: string;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    id?: number;
  }) => {
    const { code } = arg;
    if (code === 'folder/create')
      dispatch(
        sessionActions.setDrawer({
          type: 'folder',
          mode: 'create',
        }),
      );
    if (code === 'form/create') {
      if (folders.length === 0) {
        setValidation('양식 분류를 하나 이상 생성해 주세요.');
        return;
      }
      dispatch(
        sessionActions.setDialog({
          type: 'form',
          mode: 'create',
        }),
      );
    }
  };

  /** 양식 검색. */
  const handleFormListSearch = (arg: { keyword: string; filter?: string }) => {
    dispatch(
      formActions.formList({
        folderId: propsFolderId,
        route: {
          pathname,
          search: createQueryString(
            { pageNo: 1, searchCode: 'form', searchWord: arg.keyword },
            queryParams,
          ),
        },
      }),
    );
  };

  /** 양식 액션. */
  const handleFormListAction = (arg: { code: string; id?: number }) => {
    const { code, id } = arg;

    if (code === 'folder/update')
      dispatch(sessionActions.setDrawer({ type: 'folder', mode: 'update' }));
    if (code === 'folder/delete')
      dispatch(sessionActions.setDialog({ type: 'folder', mode: 'delete' }));
    if (code === 'orderFolder')
      dispatch(sessionActions.setDrawer({ type: code }));

    if (code === 'subject' && id) {
      const route = {
        pathname: `${props.pathname}/${b62(id)}`,
        search: createQueryString(
          { dialogType: 'form', dialogMode: 'update' },
          queryParams,
        ),
      };
      dispatch(sessionActions.setRoute(route));
    }
    if (code === 'nextPage') {
      if (!propsFolderId) return;
      dispatch(
        formActions.formList({
          folderId: propsFolderId,
          route: {
            pathname,
            search: createQueryString({ pageNo: pageNo + 1 }, queryParams),
          },
        }),
      );
    }
    if (code === 'prePage') {
      if (!propsFolderId) return;
      dispatch(
        formActions.formList({
          folderId: propsFolderId,
          route: {
            pathname,
            search: createQueryString({ pageNo: pageNo - 1 }, queryParams),
          },
        }),
      );
    }

    if (code === 'form/delete')
      dispatch(sessionActions.setDialog({ type: 'form', mode: 'delete' }));
    if (code === 'moveForm') dispatch(sessionActions.setDialog({ type: code }));
  };

  /** 양식 체크 액션. */
  const handleChangeCheckbox = (arg: {
    id: number | 'all';
    checked: boolean;
  }) => {
    dispatch(formActions.formChecked(arg));
  };

  /** 양식 목록 개수 변경. */
  const handleFormListChangeRowsPerPage = (value: number) => {
    dispatch(
      formActions.formList({
        folderId: propsFolderId,
        route: {
          pathname,
          search: createQueryString(
            { pageNo: 1, rowsPerPage: value },
            queryParams,
          ),
        },
      }),
    );
  };

  /** 양식 폴더 저장 */
  const handleFolderSave = (arg: { parentId: number; name: string }) => {
    delete queryParams.drawerMode;
    delete queryParams.drawerType;
    const { parentId, name } = arg;
    const route = { pathname, search: getQueryParams(queryParams) };
    dispatch(
      formFolderActions.create({
        name,
        parentId,
        route,
      }),
    );
  };

  /** 양식 폴더 수정. */
  const handleFolderUpdate = (arg: {
    id: number;
    parentId: number;
    name: string;
    updateAt: string;
  }) => {
    delete queryParams.drawerMode;
    delete queryParams.drawerType;
    const { id, parentId, name, updateAt } = arg;
    const route = { pathname, search: getQueryParams(queryParams) };
    dispatch(
      formFolderActions.update({
        id,
        name,
        parentId,
        updateAt,
        route,
      }),
    );
  };

  /** 양식 폴더 삭제. */
  const handleDeleteFolder = () => {
    const folder = folders.find((a) => a.id === propsFolderId);
    if (!folder) return;
    delete queryParams.dialogMode;
    delete queryParams.dialogType;
    dispatch(
      formFolderActions.delete({
        id: folder.id,
        updateAt: folder.updateAt,
        route: {
          pathname: pathmap,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 양식 폴더 순서변경 */
  const handleChangeFolderSeq = (
    list: {
      id: number;
      label: string;
    }[],
  ) => {
    delete queryParams.drawerType;
    const data = list.map((a, i) => {
      const updateAt = folders.find(({ id }) => id === a.id)?.updateAt ?? '';
      return {
        id: a.id,
        seq: i + 1,
        updateAt,
      };
    });
    dispatch(
      formFolderActions.sort({
        data,
        route: {
          pathname,
          search: getQueryParams(queryParams),
        },
      }),
    );
  };

  /** 양식 저장 */
  const handleFormSave = (arg: {
    folderId: number;
    content: string;
    description: string;
    name: string;
    status: boolean;
  }) => {
    const route = { pathname: `${pathmap}/${b62(arg.folderId)}` };
    dispatch(formActions.createForm({ data: arg, route }));
  };

  /** 양식 수정 */
  const handleFormUpdate = (arg: {
    id: number;
    folderId: number;
    content: string;
    description: string;
    name: string;
    status: boolean;
    updateAt: string;
  }) => {
    const originForm = forms.find((a) => a.id === arg.id);
    const route =
      originForm?.folderId === arg.folderId
        ? {
            pathname,
            search: createQueryString(
              { pageNo: 1, dialogMode: undefined, dialogType: undefined },
              queryParams,
            ),
          }
        : {
            pathname: `${pathmap}/${b62(arg.folderId)}`,
            search: createQueryString({ rowsPerPage: queryParams.rowsPerPage }),
          };
    dispatch(formActions.updateForm({ data: arg, route }));
  };

  /** 양식 삭제 */
  const handleFormDelete = () => {
    const data = forms
      .filter(({ checked }) => checked)
      .map((a) => ({
        id: a.id,
        updateAt: a.updateAt,
      }));

    const route = {
      pathname,
      search: createQueryString(
        { pageNo: 1, dialogMode: undefined, dialogType: undefined },
        queryParams,
      ),
    };

    dispatch(formActions.deleteForm({ data, route }));
  };

  /** 양식 폴더 이동 */
  const handleChangFormFolder = (folderId: number) => {
    if (propsFolderId === folderId) {
      setValidation('원본 폴더와 대상 폴더가 동일합니다.');
      return;
    }

    const data = forms
      .filter(({ checked }) => checked)
      .map((a) => ({
        id: a.id,
        folderId,
        updateAt: a.updateAt,
      }));
    const route = {
      pathname: `${pathmap}/${b62(folderId)}`,
      route: createQueryString({ rowsPerPage: queryParams.rowsPerPage }),
    };
    dispatch(formActions.moveForm({ data, route }));
  };

  /** 대화상자 닫기 이벤트. */
  const handleCloseDialog = () => {
    dispatch(sessionActions.setDialog());
  };

  /** 드로워 닫기 이벤트. */
  const handleCloseDrawer = () => {
    dispatch(sessionActions.setDrawer());
  };

  const renderDialog = () => {
    const { dialogType: type, dialogMode: mode } = queryParams;

    if (type === 'form' && mode === 'create')
      return (
        <BoardFormEdit
          folders={folderItems}
          folderId={propsFolderId}
          onClose={handleCloseDialog}
          onSave={handleFormSave}
        />
      );

    if (type === 'form' && mode === 'update')
      return (
        <BoardFormEdit
          folders={folderItems}
          folderId={propsFolderId}
          id={propsFormId}
          onClose={handleCloseDialog}
          onSave={handleFormUpdate}
        />
      );

    if (type === 'form' && mode === 'delete') {
      const data = forms.filter(({ checked }) => checked);
      if (data.length === 0) return null;

      if (data.length === 1)
        return (
          <DeleteConfirmation
            onCancel={handleCloseDialog}
            onSubmit={handleFormDelete}
          >
            <strong>&apos;{data[0].name}&apos;</strong> 을(를) 정말
            삭제하시겠습니까?
          </DeleteConfirmation>
        );
      return (
        <DeleteConfirmation
          onCancel={handleCloseDialog}
          onSubmit={handleFormDelete}
        >
          <strong>&apos;{data[0].name}&apos;</strong> 외 {data.length - 1} 건을
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    if (type === 'moveForm')
      return (
        <TreePicker
          title="양식 이동"
          disabledId={propsFolderId}
          list={folderItems}
          onSelected={handleChangFormFolder}
          onClose={handleCloseDialog}
        />
      );

    if (type === 'folder' && mode === 'delete') {
      const folder = folders.find((a) => a.id === propsFolderId);
      if (!folder) return null;
      return (
        <DeleteConfirmation
          onSubmit={handleDeleteFolder}
          onCancel={handleCloseDialog}
        >
          <strong>&apos;{folder.name}&apos;</strong> 폴더를 정말
          삭제하시겠습니까?
        </DeleteConfirmation>
      );
    }

    return null;
  };

  const renderDrawer = () => {
    const { drawerType: type, drawerMode: mode } = queryParams;

    if (type === 'folder' && mode === 'create')
      return (
        <BoardFormFolderEdit
          folders={folderItems}
          parentId={propsFolderId}
          onClose={handleCloseDrawer}
          onSave={handleFolderSave}
        />
      );

    if (type === 'folder' && mode === 'update') {
      const folder = folders.find((a) => a.id === propsFolderId);
      if (!folder) return null;
      return (
        <BoardFormFolderEdit
          id={folder.id}
          parentId={folder.parentId}
          name={folder.name}
          updateAt={folder.updateAt}
          folders={folderItems}
          onClose={handleCloseDrawer}
          onSave={handleFolderUpdate}
        />
      );
    }

    if (type === 'orderFolder') {
      const folder = folders.find((a) => a.id === propsFolderId);
      if (!folder) return null;
      const list = folderItems
        .filter((a) => a.parentId === folder.parentId)
        .map((a) => ({
          id: a.id,
          label: a.text,
        }));
      return (
        <ChangeOrder
          title="순서변경"
          list={list}
          onChange={handleChangeFolderSeq}
          onClose={handleCloseDrawer}
        />
      );
    }

    return null;
  };

  return (
    <>
      <EuiHeader>
        <EuiHeader.Title>{title}</EuiHeader.Title>
      </EuiHeader>
      <EuiBody>
        <EuiSetting>
          <EuiSetting.Left>
            <BoardFormFolder
              title="게시양식"
              items={folderItems}
              selectedId={propsFolderId}
              onSelected={handleSelectedFolder}
              onClick={handleFolderListAction}
            />
          </EuiSetting.Left>
          {propsFolderId ? (
            <EuiSetting.Right onClose={handleFormListClose}>
              <BoardFormList
                pathname={pathname}
                scroll={scrollbar}
                title={folders.find((a) => a.id === propsFolderId)?.name ?? ''}
                items={formItems}
                onSearch={handleFormListSearch}
                onClick={handleFormListAction}
                onChangeChecked={handleChangeCheckbox}
                pagination={{
                  no: pageNo,
                  row: rowsPerPage,
                  total: totalCount,
                  onChangeRow: handleFormListChangeRowsPerPage,
                }}
              />
            </EuiSetting.Right>
          ) : (
            <SplitUnselected />
          )}
        </EuiSetting>
      </EuiBody>
      {renderDialog()}
      {renderDrawer()}
      <FeedBack
        text={validation}
        onClose={() => {
          setValidation('');
        }}
      />
    </>
  );
}

export default BoardFormContainer;
