import React from 'react';
import Button from '../button/Button';

function Authentication(props: { children: React.ReactNode }): JSX.Element {
  return (
    <div className="eui-authentication">
      <div className="authentication-container">
        <div className="authentication-card">
          <div className="authentication-dot top-right">
            <div className="h">
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
            </div>
            <div className="v">
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
            </div>
          </div>
          <div className="authentication-dot bottom-left">
            <div className="v">
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
            </div>
            <div className="h">
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
            </div>
          </div>
          <div className="authentication-content">
            <div className="authentication-form">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Title(props: { label: string }): JSX.Element {
  return <h1 className="authentication-title">{props.label}</h1>;
}

function Logo(props: { src: string; alt: string }): JSX.Element {
  return (
    <h1 className="authentication-logo">
      <img src={props.src} alt={props.alt} />
    </h1>
  );
}

function Form(props: {
  onSubmit?(event: React.FormEvent<HTMLFormElement>): void;
  children: React.ReactNode;
}): JSX.Element {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.onSubmit) props.onSubmit(event);
  };
  return <form onSubmit={handleSubmit}>{props.children}</form>;
}

function Option(props: { children: React.ReactNode }): JSX.Element {
  return <div className="authentication-option">{props.children}</div>;
}

function Submit(props: { label: string; loading?: boolean }): JSX.Element {
  return (
    <Button
      submit
      text={props.label}
      variant="contained"
      block
      loading={props.loading}
    />
  );
}

function Link(props: {
  label: string;
  onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}): JSX.Element {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    props.onClick(event);
  };
  return (
    <div className="authentication-action">
      <a href="#" className="link" onClick={handleClick}>
        {props.label}
      </a>
    </div>
  );
}

Authentication.Title = Title;
Authentication.Logo = Logo;
Authentication.Form = Form;
Authentication.Option = Option;
Authentication.Submit = Submit;
Authentication.Link = Link;

export default Authentication;
