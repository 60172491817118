import React, { useState } from 'react';

function Nav(props: {
  title?: string;
  expanded?: boolean;
  children?: React.ReactNode;
  onCreate?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  onSetting?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}): JSX.Element {
  const [show, setShow] = useState(
    props.expanded === undefined ? true : props.expanded,
  );

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      {props.title && (
        <div className="eui-nav-head">
          <h4 className="head-title">{props.title}</h4>
          <div className="head-action">
            {props.onCreate && (
              <button
                type="button"
                className="button add"
                aria-label={`${props.title} 추가`}
                onClick={props.onCreate}
              />
            )}
            {props.onSetting && (
              <button
                type="button"
                className="button setting"
                aria-label={`${props.title} 설정`}
                onClick={props.onSetting}
              />
            )}
            <button
              type="button"
              className="button toggle"
              aria-label={show ? '접기' : '펼치기'}
              onClick={handleShow}
              aria-expanded={show}
            />
          </div>
        </div>
      )}
      {show && <div className="eui-nav-list">{props.children}</div>}
    </>
  );
}

export default Nav;
