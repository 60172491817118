import React, { useState } from 'react';
import Drawer from '../../../../../components/drawer/Drawer';
import DrawerBody from '../../../../../components/drawer/DrawerBody';
import PostWrite from '../../../../../components/post/PostWrite';
import TextField from '../../../../../components/textfield/TextField';
import DrawerAction from '../../../../../components/drawer/DrawerAction';
import Button from '../../../../../components/button/Button';
import { getText } from '../../../../../groupware-common/utils';
import DropMenu from '../../../../../components/selectField/DropMenu';
import TreePicker from '../../../../../groupware-webapp/pages/popup/TreePicker';
import { IconType } from '../../../../../groupware-common/types/icon';
import FeedBack from '../../../../../components/alert/FeedBack';

type CreateProps = {
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  folderPaths: string[];
  onSave(arg: { id?: number; parentId: number; name: string }): void;
  onClose(): void;
  id?: undefined;
  parentId: number;
  name?: undefined;
};

type UpdateProps = {
  folders: {
    id: number;
    parentId: number;
    text: string;
    icon: IconType;
  }[];
  folderPaths: string[];
  onSave(arg: { id?: number; parentId: number; name: string }): void;
  onClose(): void;
  id: number;
  parentId: number;
  name: string;
};

function ApprovalWorkEditFolder(props: CreateProps | UpdateProps): JSX.Element {
  const [state, setState] = useState(() => {
    if (props.id === undefined)
      return {
        validation: '',
        paths: props.folderPaths,
        parentSelectDialogVisible: false,
        parentId: props.parentId || 0,
        name: props.name || '',
      };
    return {
      validation: '',
      paths: props.folderPaths,
      parentSelectDialogVisible: false,
      parentId: props.parentId,
      name: props.name,
    };
  });

  /** 이름 변경. */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: event.target.value }));
  };

  /** 상위 선택 대화 상자 열기. */
  const handleParentSelectDialogOpen = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: true,
    }));
  };

  /** 상위 선택 대화 상자 닫기. */
  const handleParentSelectDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      parentSelectDialogVisible: false,
    }));
  };

  /** 상위 선택 대화 상자 확인. */
  const handleParentSelectDialogConfirm = (
    id: number,
    text: string,
    path: string[],
  ) => {
    // console.log(`handleParentSelectDialogConfirm(id: "${id}", text: "${text}", path)`, path);
    setState((prevState) => ({
      ...prevState,
      paths: path,
      parentId: id,
      parentSelectDialogVisible: false,
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  const handleSave = () => {
    // console.log(`handleSave()`);
    // console.log(`state`, state);

    const { id, onSave } = props;
    const { parentId, name } = state;

    let validation = '';
    if (name.trim() === '') validation = '분류명을 입력하세요.';
    if (validation !== '') {
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }
    onSave({ id, parentId, name });
  };

  const renderDialog = () => {
    const { parentSelectDialogVisible, parentId } = state;
    if (parentSelectDialogVisible) {
      const { folders } = props;
      return (
        <TreePicker
          title="상위 분류 선택"
          list={folders}
          selectedId={parentId}
          rootId={0}
          onSelected={handleParentSelectDialogConfirm}
          onClose={handleParentSelectDialogClose}
        />
      );
    }
    return null;
  };

  const { id, onClose } = props;
  const { validation, paths, name } = state;

  const title = id === undefined ? '분류 등록' : '분류 수정';

  return (
    <>
      <Drawer title={title} size="sm" onClose={onClose}>
        <DrawerBody>
          <PostWrite>
            <PostWrite.Item title="상위 분류">
              <DropMenu
                value={paths}
                label="상위 분류 선택"
                onClick={handleParentSelectDialogOpen}
              />
            </PostWrite.Item>
            <PostWrite.Item required title="분류명">
              <TextField value={name} onChange={handleNameChange} />
            </PostWrite.Item>
          </PostWrite>
          {renderDialog()}
        </DrawerBody>
        <DrawerAction>
          <Button
            text={getText('저장')}
            variant="contained"
            onClick={handleSave}
          />
        </DrawerAction>
      </Drawer>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default ApprovalWorkEditFolder;
