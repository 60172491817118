import React from 'react';
import ComponentCalendarDate from './ComponentCalendarDate';
import ComponentCalendarTime from './ComponentCalendarTime';
import ComponentCalendarDateTime from './ComponentCalendarDateTime';
import ComponentCalendarDateSelect from './ComponentCalendarDateSelect';
import ComponentCalendarDateScroll from './ComponentCalendarDateScroll';
import ComponentCalendarDateTime2 from './ComponentCalendarDateTime2';
import ComponentCalendarRange from './ComponentCalendarRange';

function ComponentCalendar(): JSX.Element {
  return (
    <>
      <ComponentCalendarDate />
      <ComponentCalendarDateSelect />
      <ComponentCalendarDateScroll />
      <ComponentCalendarTime />
      <ComponentCalendarRange />
      <ComponentCalendarDateTime />
      <ComponentCalendarDateTime2 />
    </>
  );
}

export default ComponentCalendar;
