import React from 'react';
import Divider from '../divider/Divider';

function MenuDivider(props: { className?: string }): JSX.Element {
  let classname = 'nav-divider';
  if (props.className) classname += ` ${props.className}`;
  return <Divider className={classname} />;
}

export default MenuDivider;
