import { useEffect } from 'react';

import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { attendanceHolidayActions } from '../../../../stores/attendance/holiday';

function AttendanceHolidayBasicRoute(props: { pathname: string }): null {
  const { pathname } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([dispatch(attendanceHolidayActions.clear())]).then(() => {
      dispatch(attendanceHolidayActions.basicList({ route: { pathname } }));
    });
  }, [dispatch, pathname]);

  return null;
}

export default AttendanceHolidayBasicRoute;
