import React, { useState } from 'react';
import DaumPostcode, { AddressData } from 'react-daum-postcode';
import TextField from '../textfield/TextField';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogHeader from '../dialog/DialogHeader';
import DialogTitle from '../dialog/DialogTitle';

function calculateAddress(arg: {
  defaultAddress: string;
  detailAddress: string;
  buildingName: string;
}): string {
  const { defaultAddress, detailAddress, buildingName } = arg;
  let result = defaultAddress;
  if (buildingName) result += ` (${buildingName})`;
  if (detailAddress) result += `, ${detailAddress}`;
  return result;
}

type Props = {
  postalCode?: string;
  address?: string;
  onChange?(arg: { postalCode: string; address: string }): void;
};

function DaumPostCode(dummy: Props): JSX.Element {
  const props = {
    ...dummy,
    postalCode: dummy.postalCode || '',
    address: dummy.address || '',
  };

  const [state, setState] = useState<{
    addressSearchDialog: boolean;
  }>({
    addressSearchDialog: false,
  });

  const addresses = props.address.split(', ');
  const defaultAddress = addresses[0] || '';
  const detailAddress = addresses[1] || '';

  /** 주소 검색 대화 상자 열기 */
  const handleAddressSearchDialogOpen = () => {
    setState((prevState) => ({ ...prevState, addressSearchDialog: true }));
  };

  /** 주소 검색 대화 상자 닫기 */
  const handleAddressSearchDialogClose = () => {
    setState((prevState) => ({ ...prevState, addressSearchDialog: false }));
  };

  /** 주소 검색 완료 */
  const handleAddressSearchComplete = (data: AddressData) => {
    // console.log('handleAddressSearchComplete', data);
    const { zonecode: postalCode, address, buildingName } = data;
    const { onChange } = props;
    if (onChange)
      onChange({
        postalCode,
        address: calculateAddress({
          defaultAddress: address,
          detailAddress,
          buildingName,
        }),
      });

    setState((prevState) => ({ ...prevState, addressSearchDialog: false }));
  };

  /** 상세 주소 변경 */
  const handleDetailedAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { postalCode, onChange } = props;
    if (onChange) {
      const address =
        event.target.value === ''
          ? defaultAddress
          : `${defaultAddress}, ${event.target.value}`;
      onChange({ postalCode, address });
    }
  };

  const { postalCode } = props;
  const { addressSearchDialog } = state;

  return (
    <div className="eui-address-edit">
      <TextField
        value={postalCode}
        placeholder="우편번호"
        readonly
        className="code"
      />
      <Button
        text="우편번호 검색"
        iconType
        icon="search"
        onClick={handleAddressSearchDialogOpen}
        className="button"
      />
      {addressSearchDialog && (
        <Dialog size="xs" onClose={handleAddressSearchDialogClose}>
          <DialogHeader>
            <DialogTitle>주소검색</DialogTitle>
          </DialogHeader>
          <DaumPostcode
            style={{ height: '466px' }}
            autoClose
            onComplete={handleAddressSearchComplete}
          />
        </Dialog>
      )}
      <TextField
        value={defaultAddress}
        placeholder="기본주소"
        readonly
        className="address"
      />
      <TextField
        value={detailAddress}
        placeholder="상세주소"
        onChange={handleDetailedAddressChange}
        className="detailAddress"
      />
    </div>
  );
}

export default DaumPostCode;
