import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldError(): JSX.Element {
  const [value, stateValue] = useState('Error');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <ComponentSampleUI.Content title="Error">
      <TextField value={value} onChange={handleChange} error />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldError;
