import { useEffect } from 'react';
import {
  AppDispatchAbort,
  useAppDispatch,
} from '../../../../groupware-webapp/app/store';
import {
  b62,
  getPathMap,
  getPathParams,
} from '../../../../groupware-common/utils';
import { documentActions } from '../../../stores/approval/document';

function ApprovalContentRoute(props: {
  pathname: string;
  search: string;
}): null {
  // console.log(`${ApprovalContentRoute.name}.render(props)`, props);

  const { pathname, search } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    let mount = true;
    let promise: AppDispatchAbort | undefined;

    if (mount) {
      const pathmap = getPathMap('/*', pathname);
      const { folderId, itemId } = getPathParams<{
        folderId?: number;
        itemId?: number;
      }>(`/*/:folderId$base62/:itemId$base62`, pathname);

      // 팀즈 알림일 때
      if (folderId !== undefined && itemId !== undefined) {
        if (b62(folderId) === 'document' || b62(folderId) === 'approve') {
          promise = dispatch(
            documentActions.linkedView({
              id: itemId,
              route: {
                pathname: `${pathmap}/${b62(folderId)}/${b62(itemId)}`,
                search,
              },
            }),
          );
        } else {
          promise = dispatch(
            documentActions.view({
              folderId,
              id: itemId,
              route: {
                pathname: `${pathmap}/${b62(folderId)}/${b62(itemId)}`,
                search,
              },
            }),
          );
        }
      } else if (folderId !== undefined) {
        promise = dispatch(
          documentActions.list({
            folderId,
            search,
            route: {
              pathname: `${pathmap}/${b62(folderId)}`,
              search,
            },
          }),
        );
      }
    }

    return () => {
      mount = false;
      if (promise !== undefined) promise.abort();
    };
  }, [dispatch, pathname, search]);

  return null;
}

export default ApprovalContentRoute;
