import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  b62,
  getPathParams,
  getQueryParams,
  resourceUpdate,
} from '../../groupware-common/utils';
import { RootState, useAppDispatch } from '../../groupware-webapp/app/store';
import DocumentComposeRoute from '../pages/root/document/compose/DocumentComposeRoute';
import DocumentContentRoute from '../pages/root/document/content/DocumentContentRoute';
import { FolderListItem, folderActions } from '../stores/document/folders';
import { preferencesActions } from '../stores/document/preferences';

function DocumentLocateRoute(props: {
  pathname: string;
  search: string;
  hash: string;
  locationKey: string;
}): JSX.Element {
  const { pathname, search, hash, locationKey: key } = props;
  const dispatch = useAppDispatch();
  const { module, folderId } = getPathParams('/:module/:folderId', pathname);
  const queryParams = getQueryParams(search);

  const { employeeId } = useSelector(
    (state: RootState) => state.session.principal,
  );

  const currentLanguage = useSelector(
    (state: RootState) => state.session.basicSetting.currentLanguage,
  );

  const [state, setState] = useState<{
    defaultFolderId: number;
    folders: FolderListItem[];
  }>({
    defaultFolderId: 0,
    folders: [],
  });

  useEffect(() => {
    resourceUpdate(['directory', 'jobclass', 'document'], [currentLanguage]);
    Promise.all([
      dispatch(preferencesActions.findPreferencesForUser()).then(
        ({ payload }) => {
          const { defaultFolderId } = payload as { defaultFolderId: number };
          setState((prev) => ({ ...prev, defaultFolderId }));
        },
      ),
      dispatch(folderActions.userFolderList({ employeeId })).then(
        ({ payload }) => {
          const folders = payload as FolderListItem[];
          setState((prev) => ({ ...prev, folders }));
        },
      ),
    ]);
  }, [dispatch]);

  const { defaultFolderId, folders } = state;
  const isExist =
    defaultFolderId !== 0 &&
    folders.length > 0 &&
    folders.some((a) => a.id === defaultFolderId);
  if (module === 'document' && !folderId && search === '' && isExist) {
    return <Redirect to={`/document/${b62(defaultFolderId)}`} />;
  }
  if (
    queryParams.contentMode === 'create' ||
    queryParams.contentMode === 'update'
  ) {
    return (
      <DocumentComposeRoute
        pathname={pathname}
        search={search}
        hash={hash}
        key={key}
      />
    );
  }
  // 리스트.
  return <DocumentContentRoute pathname={pathname} search={search} key={key} />;
}

export default DocumentLocateRoute;
