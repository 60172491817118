import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/button/Button';
import DialogHeader from '../../../../../components/dialog/DialogHeader';
import DialogTitle from '../../../../../components/dialog/DialogTitle';
import DialogBody from '../../../../../components/dialog/DialogBody';
import DialogFooter from '../../../../../components/dialog/DialogFooter';
import Dialog from '../../../../../components/dialog/Dialog';
import DirectoryTree, {
  DirectoryTreeItemArg,
  getDirectoryTreeItems,
} from '../../../../../components/tree/DirectoryTree';
import SimpleSearch from '../../../../../components/search/SimpleSearch';
import { useDirectory } from '../../../../../groupware-directory/stores/directory';
import CheckboxGroup from '../../../../../components/checkbox/CheckboxGroup';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import UserInfo from '../../../../../components/user/UserInfo';
import { IconType } from '../../../../../groupware-common/types/icon';
import { getDirectoryData } from '../../../../../groupware-webapp/stores/common/utils';
import FeedBack from '../../../../../components/alert/FeedBack';
import { RootState } from '../../../../../groupware-webapp/app/store';
import { PermissionUserType } from '../../../../stores/folder';

type Props = {
  users?: PermissionUserType[];
  onSave(user: PermissionUserType[]): void;
  onClose(): void;
};

export interface PermissionItem {
  companyId: number;
  treeId: string;
  employeeId: number;
  organizationId: number;
  name: string;
  organizationName?: string;
  referenceType: string;
  avatar?: string;
  options: {
    isRead: boolean;
    isWrite: boolean;
  };
  updateAt: string;
}

function BoardPermissionDialogContainer(props: Props): JSX.Element {
  const directory = useDirectory();
  const employees = useSelector(
    (s: RootState) => s.directory.employee.list.data.items,
  );
  const display = useSelector((s: RootState) => s.session.display);

  const treeItems = useMemo(
    () =>
      getDirectoryTreeItems({
        ...directory,
      }),
    [directory],
  );

  const items = treeItems.filter((a, index) => {
    if (a.extra.type === 'employee') {
      const id = a.extra.employeeId;
      const organizationId = employees.find(
        (x) => x.id === id,
      )?.representativeOrganizationId;
      if (organizationId) {
        return (
          treeItems.findIndex((x) => {
            return (
              x.id.split('_')[2] === a.id.split('_')[2] &&
              x.id.split('_')[1] === organizationId.toString()
            );
          }) === index
        );
      }
    }
    return (
      treeItems.findIndex((x) => {
        return (
          x.id.split('_')[x.id.split('_').length - 1] ===
          a.id.split('_')[a.id.split('_').length - 1]
        );
      }) === index
    );
  });

  const { users } = props;

  const initialState =
    users !== undefined
      ? {
          treeSelectedId: undefined,
          permissionList: users.map((a): PermissionItem => {
            let treeId = '';
            if (a.referenceType === 'EMPLOYEE') {
              const DirectoryData = getDirectoryData({
                ...directory,
                companyId: a.referenceCompanyId,
                employeeId: a.referenceId,
              });
              treeId = `${a.referenceCompanyId}_${DirectoryData.organizationId}_${a.referenceId}`;
            } else {
              treeId = `${a.referenceCompanyId}_${a.referenceId}`;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const item = treeItems.find((x) => x.id === treeId)!;
            const { extra } = item;
            return {
              companyId: a.referenceCompanyId,
              treeId,
              name: item.text,
              avatar: item.avatar,
              employeeId: extra.type === 'employee' ? extra.employeeId : 0,
              organizationId: extra.organizationId,
              organizationName:
                extra.type === 'employee' ? extra.organizationName : undefined,
              referenceType: extra.type.toUpperCase(),
              options: { isRead: a.options.isRead, isWrite: a.options.isWrite },
              updateAt: '',
            };
          }),
          validation: '',
        }
      : {
          treeSelectedId: undefined,
          permissionList: [],
          validation: '',
        };

  const [state, setState] = useState<{
    treeSelectedId: string | undefined;
    permissionList: PermissionItem[];
    validation: string | React.ReactNode;
  }>(initialState);
  const [filter, setFilter] = useState('');

  /** 디렉터리 트리 항목 필터 */
  const handleTreeItemFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  /** 디렉터리 트리 항목 클릭 */
  const handleTreeItemClick = (arg: DirectoryTreeItemArg) => {
    // console.log('handleTreeItemClick(arg)', arg);
    const {
      item,
      item: { extra },
    } = arg;

    // 중복 값이 있을 경우
    if (
      state.permissionList.find(
        (a) =>
          a.treeId === item.id ||
          (item.extra.type === 'employee' &&
            item.extra.employeeId === a.employeeId),
      )
    )
      return;

    setState((prev) => {
      return {
        ...prev,
        treeSelectedId: item.id,
        permissionList: [
          ...prev.permissionList,
          {
            companyId: item.extra.companyId,
            treeId: item.id,
            name:
              extra.type === 'employee'
                ? extra.employeeName
                : extra.organizationName,
            avatar: item.avatar,
            employeeId: extra.type === 'employee' ? extra.employeeId : 0,
            organizationId: extra.organizationId,
            organizationName:
              extra.type === 'employee' ? extra.organizationName : undefined,
            referenceType: extra.type.toUpperCase(),
            options: { isRead: false, isWrite: false },
            updateAt: item.updateAt,
          },
        ],
      };
    });
  };

  /** 권한 목록 항목 클릭  */
  const handleUserPermissionSelect = (treeId: string) => {
    setState((prev) => ({
      ...prev,
      treeSelectedId: treeId,
    }));
  };

  /** 권한 목록 항목 삭제  */
  const handleUserPermissionDelete = (
    treeId: string,
    event: React.MouseEvent,
  ) => {
    event?.stopPropagation();
    setState((prev) => ({
      ...prev,
      treeSelectedId: undefined,
      permissionList: prev.permissionList.filter((a) => a.treeId !== treeId),
    }));
  };

  /** 권한 목록 항목 전체 삭제  */
  const handleUserPermissionDeleteAll = () => {
    setState((prev) => ({
      ...prev,
      treeSelectedId: undefined,
      permissionList: [],
    }));
  };

  /** 권한 체크박스 선택 */
  const handleOptionPermissionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const treeId = event.target.name.split('/')[0];
    const option = event.target.name.split('/')[1];

    setState((prev) => ({
      ...prev,
      validation: '',
      permissionList: permissionList.map((user) => {
        if (user.treeId === treeId) {
          // 쓰기 권한이 있을 경우 읽기 권한 체크
          if (event.target.checked && option === 'isWrite') {
            return {
              ...user,
              options: {
                ...user.options,
                isRead: event.target.checked,
                [option]: event.target.checked,
              },
            };
          }
          // 읽기 권한 체크해제했을 때 쓰기 권한 체크해제
          if (!event.target.checked && option === 'isRead') {
            return {
              ...user,
              options: {
                [option]: event.target.checked,
                isWrite: false,
              },
            };
          }

          return {
            ...user,
            options: {
              ...user.options,
              [option]: event.target.checked,
            },
          };
        }
        return user;
      }),
    }));
  };

  /** 스낵바 닫기 */
  const handleSnackbarClose = () => {
    setState((prevState) => ({ ...prevState, validation: '' }));
  };

  /** 저장 */
  const handleSave = () => {
    const { onSave } = props;
    const { permissionList } = state;

    const arg = permissionList.map((a) => {
      return {
        referenceCompanyId: a.companyId,
        referenceId:
          a.referenceType === 'EMPLOYEE' ? a.employeeId : a.organizationId,
        referenceType: a.referenceType,
        options: { ...a.options, isMove: false },
      };
    });

    // 권한 체크가 되어 있지 않을 경우
    if (
      arg.length > 0 &&
      arg.find((b) => {
        if (b.options.isRead) return false;
        if (b.options.isWrite) return false;
        return true;
      })
    ) {
      const validation = `등록된 권한이 없습니다.`;
      setState((prevState) => ({ ...prevState, validation }));
      return;
    }

    onSave(arg);
  };

  const permissionLabel = (value: string) => {
    switch (value) {
      case 'isRead':
        return '읽기';
      case 'isWrite':
        return '쓰기';
      default:
        return '';
    }
  };

  const { treeSelectedId, permissionList, validation } = state;
  return (
    <>
      <Dialog size="lg" className="ui-dialog-selection">
        <DialogHeader>
          <DialogTitle>공유자 선택</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div
            className="ui-dialog-selection-root"
            style={display !== 'pc' ? { flexDirection: 'column' } : undefined}
          >
            <div
              className="select-area"
              style={
                display !== 'pc'
                  ? { width: '100%', marginBottom: '5px' }
                  : undefined
              }
            >
              <div className="area-item tree-area">
                <div className="item-head">
                  <div className="title">조직도</div>
                </div>
                <div className="item-toolbar">
                  <SimpleSearch
                    keyword={filter}
                    onSearch={handleTreeItemFilter}
                  />
                </div>
                <div className="item-body">
                  <DirectoryTree
                    selectedId={treeSelectedId}
                    items={treeItems}
                    listItems={items}
                    filter={filter}
                    onItemClick={handleTreeItemClick}
                  />
                </div>
              </div>
            </div>
            <div className="selected-area">
              <div className="area-item tree-area">
                <div className="item-head">
                  <div className="title">선택목록</div>
                </div>
                <div className="item-body document-selection-item">
                  {permissionList.map((a) => {
                    const icon = (): IconType | undefined => {
                      if (a.referenceType === 'COMPANY') return 'company';
                      if (a.referenceType === 'ORGANIZATION')
                        return 'sitemap-fill';
                      if (a.referenceType === 'EMPLOYEE') return undefined;
                      return undefined;
                    };
                    return (
                      <>
                        <div
                          key={a.treeId}
                          className="selected-item"
                          aria-selected={a.treeId === treeSelectedId}
                          onClick={() => handleUserPermissionSelect(a.treeId)}
                        >
                          <div className="content">
                            <UserInfo
                              className="permission-user-item"
                              key={`${a.treeId}_avatar`}
                              name={a.name}
                              avatar={
                                a.referenceType === 'EMPLOYEE' ? a.avatar : ''
                              }
                              icon={icon()}
                            />
                            <CheckboxGroup type="simple">
                              {Object.entries(a.options).map(([key, value]) => {
                                return (
                                  <Checkbox
                                    key={`${a.treeId}/${key}`}
                                    name={`${a.treeId}/${key}`}
                                    label={permissionLabel(key)}
                                    checked={value}
                                    onChange={handleOptionPermissionChange}
                                  />
                                );
                              })}
                            </CheckboxGroup>
                          </div>
                          <Button
                            text="삭제"
                            iconType
                            icon="trash-full"
                            onClick={(event) =>
                              handleUserPermissionDelete(a.treeId, event)
                            }
                            color="secondary"
                            size="sm"
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="item-toolbar">
                  <Button
                    text="전체삭제"
                    variant="outlined"
                    size="sm"
                    onClick={handleUserPermissionDeleteAll}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button text="취소" color="secondary" onClick={props.onClose} />
          <Button text="추가" variant="contained" onClick={handleSave} />
        </DialogFooter>
      </Dialog>
      <FeedBack text={validation} onClose={handleSnackbarClose} />
    </>
  );
}

export default BoardPermissionDialogContainer;
