import { useEffect } from 'react';
import { useAppDispatch } from '../../../groupware-webapp/app/store';
import { sessionActions } from '../../../groupware-webapp/stores/session';
import { formFolderActions } from '../../stores/systemlink/form';
import {
  systemlinkWorkActions,
  systemlinkWorkFolderActions,
} from '../../stores/systemlink/work';
import { preferencesActions } from '../../../groupware-approval/stores/approval/preferences';

function SystemLinkWorkRoute(props: {
  pathname: string;
  search: string;
  hash: string;
}): null {
  // console.log(`${SystemLinkWorkRoute.name}.render`);

  const dispatch = useAppDispatch();

  const { pathname, search, hash } = props;

  useEffect(() => {
    Promise.all([
      dispatch(preferencesActions.findApprovalType()),
      dispatch(formFolderActions.list()),
      dispatch(systemlinkWorkFolderActions.list()),
      dispatch(systemlinkWorkActions.list({})),
    ]).then(() => {
      const route = { pathname, search, hash, clearErrors: true };
      dispatch(sessionActions.setRoute(route));
    });
  }, [dispatch, pathname, search, hash]);

  return null;
}

export default SystemLinkWorkRoute;
