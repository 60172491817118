import React from 'react';
import Chip from '../../../../../components/chip/Chip';
import ComponentSampleUI from '../ComponentSampleUI';

function ComponentChipColor(): JSX.Element {
  return (
    <ComponentSampleUI.Content title="Color chip">
      <Chip label="success" theme="success" />
      <Chip label="error" theme="error" />
      <Chip label="cancel" theme="cancel" />
      <Chip label="warning" theme="warning" />
    </ComponentSampleUI.Content>
  );
}

export default ComponentChipColor;
