import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Drawer from '../../../../components/drawer/Drawer';
import DrawerBody from '../../../../components/drawer/DrawerBody';
import DrawerToolbar from '../../../../components/drawer/DrawerToolbar';
import Loading from '../../../../components/loading/Loading';
import Search from '../../../../components/search/Search';
import { dateSimplify } from '../../../../groupware-common/utils/ui';
import { RootState } from '../../../../groupware-webapp/app/store';
import { getDirectoryData } from '../../../../groupware-webapp/stores/common/utils';
import documentViewerApi from '../../../apis/approval/v1/document-viewer';
import Pagination from '../../root/approval/common/components/Pagination';
import { getAvatarPath } from '../../../../groupware-common/utils';
import EmptyData from '../../../../components/data/EmptyData';
import UserInfo from '../../../../components/user/UserInfo';
import DrawerList from '../../../../components/drawer/DrawerList';
import { getJobClassName } from '../../../../groupware-directory/apis/directory/v1';

function ApprovalDocumentViewerDrawer(props: {
  viewId: number;
  onClose(): void;
}): JSX.Element {
  const { viewId: id, onClose } = props;
  const scrollbar = useRef<HTMLDivElement>(null);

  const organizationEmployees = useSelector(
    (state: RootState) => state.directory.organization.employees.data.items,
  );
  const employees = useSelector(
    (state: RootState) => state.directory.employee.list.data.items,
  );
  const jobClassType = useSelector(
    (state: RootState) => state.directory.preferences.jobClassType,
  );

  const getEmployeeData = (
    companyId: number,
    organizationId: number,
    employeeId: number,
  ) => {
    return getDirectoryData({
      organizationEmployees,
      employees,
      jobClassType,
      companyId,
      organizationId,
      employeeId,
    });
  };

  const [state, setState] = useState<{
    loading: boolean;
    viewer: string;
    no: number;
    viewList:
      | {
          affiliatedCompanyId?: number;
          id: number;
          employeeName: string;
          employeeJobClassName: string;
          employeeOrganizationName: string;
          employeeAvatar: string;
          createAt: string;
          updateAt: string;
        }[]
      | undefined;
    viewTotalCount: number | undefined;
  }>({
    loading: true,
    viewer: '',
    no: 1,
    viewList: undefined,
    viewTotalCount: undefined,
  });

  const setStateViewerList = (
    list: {
      affiliatedCompanyId?: number;
      id: number;
      employeeCompanyId: number;
      employeeId: number;
      createAt: string;
      updateAt: string;
    }[],
  ) => {
    return list.map((a) => {
      const employeeOrganizationId =
        employees.find(
          (b) => b.companyId === a.employeeCompanyId && b.id === a.employeeId,
        )?.representativeOrganizationId || 0;
      const data = getEmployeeData(
        a.employeeCompanyId,
        employeeOrganizationId,
        a.employeeId,
      );
      return {
        affiliatedCompanyId: a.affiliatedCompanyId,
        id: a.id,
        employeeName: data.employeeName,
        employeeJobClassName: getJobClassName(
          data.jobClassType,
          data.jobPositionName,
          data.jobDutyName,
        ),
        employeeOrganizationName: data.organizationName,
        employeeAvatar: getAvatarPath(data),
        createAt: a.createAt,
        updateAt: a.updateAt,
      };
    });
  };

  const { loading, viewer, no, viewList, viewTotalCount } = state;
  useEffect(() => {
    async function run() {
      const totalCount = await documentViewerApi.totalcount({ id });
      const documentViewer = setStateViewerList(
        await documentViewerApi.findAll({ id, pageNo: 1, rowsPerPage: 15 }),
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        viewList: documentViewer,
        viewTotalCount: totalCount,
      }));
    }
    run();
  }, []);

  /** 검색하기 */
  const handleSearch = async (arg: { keyword: string }) => {
    const totalCount = await documentViewerApi.totalcount({
      id,
      viewer: arg.keyword,
    });
    const documentViewer = setStateViewerList(
      await documentViewerApi.findAll({
        id,
        pageNo: 1,
        rowsPerPage: 15,
        viewer: arg.keyword,
      }),
    );
    setState((prevState) => ({
      ...prevState,
      viewer: arg.keyword,
      no: 1,
      loading: false,
      viewTotalCount: totalCount,
      viewList: documentViewer,
    }));
    scrollbar.current?.scrollTo(0, 0);
  };

  /** 이전 페이지 */
  const handlePrev = async () => {
    const pageNo = state.no - 1;
    if (pageNo > 0) {
      const totalCount = await documentViewerApi.totalcount({
        id,
        viewer,
      });
      const documentViewer = setStateViewerList(
        await documentViewerApi.findAll({
          id,
          pageNo,
          rowsPerPage: 15,
          viewer,
        }),
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
        no: pageNo,
        viewList: documentViewer,
        viewTotalCount: totalCount,
      }));
      scrollbar.current?.scrollTo(0, 0);
    }
  };

  /** 다음 페이지 */
  const handleNext = async () => {
    const pageNo = state.no + 1;
    const totalCount = await documentViewerApi.totalcount({ id, viewer });
    const documentViewer = setStateViewerList(
      await documentViewerApi.findAll({ id, pageNo, rowsPerPage: 15, viewer }),
    );
    setState((prevState) => ({
      ...prevState,
      loading: false,
      no: pageNo,
      viewList: documentViewer,
      viewTotalCount: totalCount,
    }));
    scrollbar.current?.scrollTo(0, 0);
  };

  return (
    <Drawer title="조회확인" size="xs" onClose={onClose}>
      <Search
        className="drawer-search"
        keyword={viewer}
        onSearch={handleSearch}
        full
      />
      <DrawerToolbar>
        <DrawerToolbar.Left>
          <span className="selected-count">
            <em>{viewTotalCount}</em> <span>명</span>
          </span>
        </DrawerToolbar.Left>
        <DrawerToolbar.Right>
          {viewTotalCount ? (
            <Pagination
              no={no}
              rows={15}
              count={viewTotalCount}
              onPrev={handlePrev}
              onNext={handleNext}
            />
          ) : null}
        </DrawerToolbar.Right>
      </DrawerToolbar>
      <DrawerBody scrollbar={scrollbar}>
        <DrawerList>
          {loading && <Loading />}
          {viewList && viewList.length > 0 ? (
            viewList.map((a) => {
              const employeeId = a.employeeAvatar.split('/').slice(-1)[0];
              return (
                <DrawerList.Item key={`${a.id}_${employeeId}`}>
                  <div className="ui-viewer">
                    <UserInfo
                      avatar={a.employeeAvatar}
                      name={`${a.employeeName} ${a.employeeJobClassName}`}
                      from={a.employeeOrganizationName}
                    />
                    <dl className="time">
                      <dt>최초</dt>
                      <dd>{dateSimplify(a.createAt, 'fullNumber')}</dd>
                    </dl>
                    <dl className="time">
                      <dt>최종</dt>
                      <dd>{dateSimplify(a.updateAt, 'fullNumber')}</dd>
                    </dl>
                  </div>
                </DrawerList.Item>
              );
            })
          ) : (
            <EmptyData />
          )}
        </DrawerList>
      </DrawerBody>
    </Drawer>
  );
}

export default ApprovalDocumentViewerDrawer;
