import React from 'react';

const ComponentSampleUI = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <div className="ui-component">{children}</div>;
};

const Item = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name: string;
}) => {
  return (
    <div className="component-item">
      <div className="component-name">{name}</div>
      {children}
    </div>
  );
};

const Content = ({
  children,
  fill,
  title,
}: {
  title?: string;
  fill?: boolean;
  children: React.ReactNode;
}) => {
  const style: React.CSSProperties | undefined = fill
    ? { backgroundColor: '#f4f6f8' }
    : undefined;
  return (
    <div className="component-item">
      {title && <h2 className="item-title">{title}</h2>}
      <div className="item-content" style={style}>
        {children}
      </div>
    </div>
  );
};

// This is optional, but shows the explicit link between the components-sample
ComponentSampleUI.Item = Item;
ComponentSampleUI.Content = Content;

export default ComponentSampleUI;
