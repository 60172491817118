import { useEffect } from 'react';
import { getPathParams } from '../../../../../groupware-common/utils';
import { useAppDispatch } from '../../../../../groupware-webapp/app/store';
import { attendanceDetailStatusActions } from '../../../../stores/attendance/detailStatus';
import { attendancePreferencesActions } from '../../../../stores/attendance/preferences';
import { attendancesActions } from '../../../../stores/attendance/attendances';

function AttendanceDetailStatusRoute(props: {
  pathname: string;
  search: string;
}): null {
  const dispatch = useAppDispatch();
  const { pathname, search } = props;
  const { id } = getPathParams<{ id?: number }>(`/*/*/*/:id$base62`, pathname);
  useEffect(() => {
    Promise.all([
      dispatch(attendancePreferencesActions.attendanceCodeList()),
      dispatch(attendancesActions.viewClear()),
    ]).then(() => {
      if (id) {
        dispatch(attendancesActions.view({ id }));
      } else {
        dispatch(
          attendanceDetailStatusActions.findList({
            route: { pathname, search },
          }),
        );
      }
    });
  }, [dispatch, props]);
  return null;
}

export default AttendanceDetailStatusRoute;
