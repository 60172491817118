import React, { useState } from 'react';
import ComponentSampleUI from '../ComponentSampleUI';
import TextField from '../../../../../components/textfield/TextField';

function ComponentTextFieldIcon(): JSX.Element {
  const [value, stateValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    stateValue(event.target.value);
  };

  return (
    <ComponentSampleUI.Content title="Small">
      <TextField
        value={value}
        placeholder="Small"
        onChange={handleChange}
        size="sm"
      />
    </ComponentSampleUI.Content>
  );
}

export default ComponentTextFieldIcon;
